import React from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { getTimeAgo } from 'utils';
import { getImageDimensions } from 'v2/services/image';

import Button from 'components/button';

import Avatar from 'v2/components/ui/atoms/Avatar';

import colours from 'css/base/_colours.module.scss';

export default function CompanyProfilePersonInfoContainer({
  name,
  photoURL,
  jobTitle,
  lastActive,
  positions,
  showButton,
  onClick
}: $TSFixMe) {
  return (
    <ProfileItem
      className={ClassNames('person_info_container', { clickable: onClick && !showButton })}
      onClick={
        onClick && !showButton
          ? () => onClick()
          : () => {
              return;
            }
      }
    >
      <Avatar
        photoURL={photoURL}
        resizePhotoDimensions={getImageDimensions('no-crop-logo-small')}
        width={80}
        firstname={name}
        lastActive={lastActive}
      />
      <ProfileInfo className="person_details">
        <Name className="account_name">
          <h4>{name}</h4>
        </Name>
        {jobTitle && <OtherInfo className="job">{jobTitle}</OtherInfo>}
        {positions && positions.length > 0 && (
          <OtherInfo className="position">Hiring a {positions[0].position}</OtherInfo>
        )}
        {lastActive && (
          <LastActive className="activity">Active {getTimeAgo(lastActive, true)}</LastActive>
        )}
        {showButton && <Button text="View Profile" action={onClick} buttonStyle="stroke_blue" />}
      </ProfileInfo>
    </ProfileItem>
  );
}

const ProfileItem = styled.div`
  text-align: center;
  padding: 0;

  &.clickable {
    cursor: pointer;

    &:hover .person_details .account_name h4 {
      border-bottom: 2px solid;
    }
  }

  .profile_picture .profile_picture_initials {
    font-size: 26px;
  }
`;

const ProfileInfo = styled.div`
  display: block;
  margin: auto;

  .button {
    margin-top: 15px;
  }
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: ${({ theme: { typography } }) => typography.bold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 15px 0 5px;
  text-align: center;
  vertical-align: text-bottom;

  h4 {
    transition: all 0.2s ease-in-out;
    line-height: 1.2;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    margin: 0;
    padding: 0;
    display: inline-block;
  }
`;

const OtherInfo = styled.div`
  text-align: center;
  vertical-align: text-bottom;
`;

const LastActive = styled.div`
  font-size: 14px;
  color: ${colours.activeDarkShade};
  margin-top: 10px;
`;
