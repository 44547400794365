import { getMillisecondsByType } from 'utils';
import { TimeIntervalPlural } from 'types';

export const getTimeDifference = (
  start: Date,
  end: Date,
  returnIn: TimeIntervalPlural = 'seconds'
): number => {
  try {
    const startTime = start?.getTime();
    const endTime = end?.getTime();

    const differenceInMilliseconds = endTime - startTime;

    return Math.floor(differenceInMilliseconds / getMillisecondsByType(returnIn));
  } catch (err) {
    // gracefully handle cases if start/end times are invalid numbers
    return 0;
  }
};
