import { OperatingCountry } from '@cohiretech/common-types';

import { selectLocalisationCountry, useAppSelector } from 'store';
import { getLocalisationCountryFromURL } from 'v2/services/common';

export default function useLocalisation(preferredCountry?: OperatingCountry) {
  const localisationCountry = useAppSelector(selectLocalisationCountry);

  if (preferredCountry) {
    return preferredCountry;
  }

  const URLLocale = getLocalisationCountryFromURL(window.location.pathname);

  return URLLocale || localisationCountry || OperatingCountry.UK;
}
