import { WorkEligibilityLocation, WorkEligibilityType } from '@cohiretech/common-types';

import { VISA_SPONSORSHIP_ORIGINS } from 'fetcher';
import { pluralise, prettyTextFromArray, setAcronym } from 'utils';
import { compose } from 'utils/fn';
import { pluck } from 'utils/object';
import { getFullPositionLocationLabel } from 'v2/services/tools/positionInfo';

import { PositionType } from '..';

import { BlockingCriteriaStatus } from './ApplyButtonTooltip.helpers';

export type BlockingCriteriaTooltipProps = BlockingCriteriaStatus & {
  position: PositionType;
};

export const getLocationLabelByBlockedByCriteria = ({
  blockedByCriteria,
  blockingCriteria,
  position
}: BlockingCriteriaTooltipProps) => {
  const { workEligibilities, locations, remoteOptions, remoteDays } = blockingCriteria;

  switch (blockedByCriteria) {
    case 'workEligibility':
      const workEligibilityLocations = workEligibilities!.reduce((acc, { type, location }) => {
        if (type === WorkEligibilityType.Permanent) {
          const locationLabel =
            location === WorkEligibilityLocation.Other
              ? VISA_SPONSORSHIP_ORIGINS.OTHER
              : location.toUpperCase();

          acc.push(locationLabel);
        }
        return acc;
      }, [] as string[]);

      return prettyTextFromArray(workEligibilityLocations, 'or');
    case 'location':
      const locationLabels = locations!.map(
        compose<typeof locations[number], string>(setAcronym, pluck('label'))
      );

      return prettyTextFromArray(locationLabels, 'or');
    case 'remote':
    case 'remoteDays':
      // Using position info as the location criteria are met at this point.
      const { locationCity, remoteLocationContinents, remoteLocationCountries } = position;

      const locationLabel = getFullPositionLocationLabel({
        locationCity,
        remoteLocationContinents,
        remoteLocationCountries,
        remote: remoteOptions || []
      });

      const remoteDayLabel = remoteDays ? `${pluralise(remoteDays, 'day', 'days')} remote max` : '';
      return remoteDays
        ? locationLabel.replace('Hybrid', `Hybrid (${remoteDayLabel})`)
        : locationLabel;
    default:
      break;
  }
};
