import axios, { AxiosResponse, Method } from 'axios';

import { ExtendedAxiosRequestConfig, ExtendedAxiosResponse } from 'types';

function axiosWithID<T>(
  config: ExtendedAxiosRequestConfig,
  method: Method
): Promise<ExtendedAxiosResponse<T>> {
  return axios({
    ...config,
    method
  })
    .then((response: AxiosResponse<T>) => {
      return response as ExtendedAxiosResponse<T>;
    })
    .catch((error: any) => {
      return Promise.reject(error);
    });
}

export function axiosGetWithID<T>(
  config: Omit<ExtendedAxiosRequestConfig, 'headers'>
): Promise<ExtendedAxiosResponse<T>> {
  return axiosWithID<T>(config as ExtendedAxiosRequestConfig, 'get');
}

export function axiosPostWithID<T>(
  config: ExtendedAxiosRequestConfig
): Promise<ExtendedAxiosResponse<T>> {
  return axiosWithID<T>(config, 'post');
}

export function axiosPutWithID<T>(
  config: ExtendedAxiosRequestConfig
): Promise<ExtendedAxiosResponse<T>> {
  return axiosWithID<T>(config, 'put');
}

export function axiosDeleteWithID<T>(
  config: ExtendedAxiosRequestConfig
): Promise<ExtendedAxiosResponse<T>> {
  return axiosWithID<T>(config, 'delete');
}
