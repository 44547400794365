import { OperatingCountry } from '@cohiretech/common-types';

import SourcingUK from 'images/sourcing_visual_uk.svg';
import SourcingUS from 'images/sourcing_visual_us.svg';
import ApplicantsUK from 'images/applicants_visual_uk.svg';
import ApplicantsUS from 'images/applicants_visual_us.svg';
import PipelineUK from 'images/pipeline_visual_uk.svg';
import PipelineUS from 'images/pipeline_visual_us.svg';
import DirectHireUK from 'images/hire_visual_uk.svg';
import DirectHireUS from 'images/hire_visual_us.svg';
import SourcingUKMobile from 'images/sourcing_visual_uk.png';
import SourcingUSMobile from 'images/sourcing_visual_us.png';
import ApplicantsUKMobile from 'images/applicants_visual_uk.png';
import ApplicantsUSMobile from 'images/applicants_visual_us.png';
import PipelineUKMobile from 'images/pipeline_visual_uk.png';
import PipelineUSMobile from 'images/pipeline_visual_us.png';
import DirectHireUKMobile from 'images/hire_visual_uk.png';
import DirectHireUSMobile from 'images/hire_visual_us.png';
import MessagingUK from 'images/messaging_visual_uk.svg';
import MessagingUS from 'images/messaging_visual_us.svg';
import MessagingUKMobile from 'images/messaging_visual_uk.png';
import MessagingUSMobile from 'images/messaging_visual_us.png';
import DarkSourcingUK from 'images/dark_sourcing_visual_uk.svg';
import DarkSourcingUS from 'images/dark_sourcing_visual_us.svg';
import DarkApllicantsUK from 'images/dark_applicants_visual_uk.svg';
import DarkApllicantsUS from 'images/dark_applicants_visual_us.svg';
import DarkPipelineUK from 'images/dark_pipeline_visual_uk.svg';
import DarkPipelineUS from 'images/dark_pipeline_visual_us.svg';
import DarkDirectHireUK from 'images/dark_hire_visual_uk.svg';
import DarkDirectHireUS from 'images/dark_hire_visual_us.svg';
import DarkSourcingUKMobile from 'images/dark_sourcing_visual_uk.png';
import DarkSourcingUSMobile from 'images/dark_sourcing_visual_us.png';
import DarkApllicantsUKMobile from 'images/dark_applicants_visual_uk.png';
import DarkApllicantsUSMobile from 'images/dark_applicants_visual_us.png';
import DarkPipelineUKMobile from 'images/dark_pipeline_visual_uk.png';
import DarkPipelineUSMobile from 'images/dark_pipeline_visual_us.png';
import DarkDirectHireUKMobile from 'images/dark_hire_visual_uk.png';
import DarkDirectHireUSMobile from 'images/dark_hire_visual_us.png';
import DarkMessagingUK from 'images/dark_messaging_visual_uk.svg';
import DarkMessagingUS from 'images/dark_messaging_visual_us.svg';
import DarkMessagingUKMobile from 'images/dark_messaging_visual_uk.png';
import DarkMessagingUSMobile from 'images/dark_messaging_visual_us.png';

const UK_IMAGES = {
  sourcing: { desktop: SourcingUK, mobile: SourcingUKMobile },
  applicants: { desktop: ApplicantsUK, mobile: ApplicantsUKMobile },
  pipeline: { desktop: PipelineUK, mobile: PipelineUKMobile },
  directHire: { desktop: DirectHireUK, mobile: DirectHireUKMobile },
  messaging: { desktop: MessagingUK, mobile: MessagingUKMobile }
};

const US_IMAGES = {
  sourcing: { desktop: SourcingUS, mobile: SourcingUSMobile },
  applicants: { desktop: ApplicantsUS, mobile: ApplicantsUSMobile },
  pipeline: { desktop: PipelineUS, mobile: PipelineUSMobile },
  directHire: { desktop: DirectHireUS, mobile: DirectHireUSMobile },
  messaging: { desktop: MessagingUS, mobile: MessagingUSMobile }
};

const DARK_UK_IMAGES = {
  sourcing: { desktop: DarkSourcingUK, mobile: DarkSourcingUKMobile },
  applicants: { desktop: DarkApllicantsUK, mobile: DarkApllicantsUKMobile },
  pipeline: { desktop: DarkPipelineUK, mobile: DarkPipelineUKMobile },
  directHire: { desktop: DarkDirectHireUK, mobile: DarkDirectHireUKMobile },
  messaging: { desktop: DarkMessagingUK, mobile: DarkMessagingUKMobile }
};

const DARK_US_IMAGES = {
  sourcing: { desktop: DarkSourcingUS, mobile: DarkSourcingUSMobile },
  applicants: { desktop: DarkApllicantsUS, mobile: DarkApllicantsUSMobile },
  pipeline: { desktop: DarkPipelineUS, mobile: DarkPipelineUSMobile },
  directHire: { desktop: DarkDirectHireUS, mobile: DarkDirectHireUSMobile },
  messaging: { desktop: DarkMessagingUS, mobile: DarkMessagingUSMobile }
};

export const getImageMap = (country: OperatingCountry | undefined, darkMode: boolean) => {
  switch (country) {
    case OperatingCountry.US:
      return darkMode ? DARK_US_IMAGES : US_IMAGES;
    case OperatingCountry.UK:
    default:
      return darkMode ? DARK_UK_IMAGES : UK_IMAGES;
  }
};
