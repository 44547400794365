import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'store/store';
import { isEmpty } from 'utils';

import { selectJobTitleOptions } from './common.selectors';
import { fetchJobTitleOptions } from './common.thunks';

export const useJobTitleOptionsAndSkills = (jobTitles?: string[]) => {
  const jobTitleOptions = useAppSelector(selectJobTitleOptions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchJobTitleOptions());
  }, []);

  const exampleSkills = useMemo(() => {
    if (isEmpty(jobTitleOptions) || isEmpty(jobTitles)) return [];

    return jobTitleOptions.reduce((acc, { value, popularSkill }) => {
      if (jobTitles!.includes(value)) acc.push(popularSkill);
      return acc;
    }, [] as string[]);
  }, [jobTitleOptions, jobTitles]);

  return { jobTitleOptions, exampleSkills };
};
