import { CSSProperties, ReactNode, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import ClassNames from 'classnames';

import { isEmpty } from 'utils';

import { NoScrollbar } from 'v2/components/utility/Scroll';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import SearchOption, { Option, SearchOptionProps } from './SearchOption';

export type SearchOptions<V = string> = Option<V>[];

type StyledProps = Pick<CSSProperties, 'maxHeight'>;

export type SearchOptionListProps<V = string> = {
  className?: string;
  searchOptions: SearchOptions<V>;
  emptyMessage?: ReactNode;
  focusedIndex?: number;
} & StyledProps &
  Omit<SearchOptionProps<V>, keyof Option | 'focused'>;

export default function SearchOptionList<V = string>({
  className,
  searchOptions,
  emptyMessage,
  focusedIndex,
  maxHeight,
  ...other
}: SearchOptionListProps<V>) {
  const searchOptionsContainerRef = useRef<HTMLDivElement>(null);
  const { loading, skeletonProps } = other.loadingState || {};

  useEffect(() => {
    searchOptionsContainerRef.current?.scrollTo({ top: 0 });
  }, [searchOptions]);

  const options: SearchOptions<V> = useMemo(() => {
    if (!loading || searchOptions) return searchOptions;
    return Array.from({ length: Number(skeletonProps?.count) });
  }, [searchOptions, loading]);

  return (
    <SearchOptionsContainer
      ref={searchOptionsContainerRef}
      className={ClassNames('search_option_list', className)}
      maxHeight={maxHeight}
    >
      <ConditionalRender
        predicate={!isEmpty(options)}
        fallback={<EmptyMessage>{emptyMessage}</EmptyMessage>}
      >
        {options.map((option, index) => (
          <SearchOption<V>
            key={`${option?.value}_${index}`}
            focused={focusedIndex === index}
            {...option}
            {...other}
          />
        ))}
      </ConditionalRender>
    </SearchOptionsContainer>
  );
}

const SearchOptionsContainer = styled.div<StyledProps>`
  ${({ theme: { media }, maxHeight }) => css`
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;

    max-height: ${maxHeight || 'none'};
    overflow: auto;
    ${NoScrollbar}

    ${media.mobile`
      max-height: calc(100vh - 160px);
    `}
  `}
`;

const EmptyMessage = styled.div`
  width: 100%;
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  color: var(--text-body-tertiary);
`;
