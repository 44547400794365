import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCompanyUser } from 'fetcher';
import { AppDispatch, setCompanyUser, useAppDispatch } from 'store';

export const updateMemberProfile = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
  'services/company/updateMemberProfile',
  async (_, { dispatch }) => {
    const companyUser = await getCompanyUser();
    dispatch(setCompanyUser(companyUser));
  }
);

export const useUpdateMemberProfile = () => {
  const dispatch = useAppDispatch();
  return async () => {
    await dispatch(updateMemberProfile());
  };
};
