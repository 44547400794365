import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { mobile } from 'responsiveConfig';
import { getIsDarkMode_FIXME } from 'cookieManager';
import { setCompanyTracking } from 'fetcher';

import DialogWindow from 'components/dialogwindow';

import typography from 'css/base/_typography.module.scss';

import MessagingToolIcon from 'images/messaging-tool.svg';
import CandidateAccept from 'images/candidate-accept.png';
import CandidateAcceptDark from 'images/candidate-accept-dark.png';
import CompanyAccept from 'images/company-accept.png';
import CompanyAcceptDark from 'images/company-accept-dark.png';

type Props = {
  visible: boolean;
  onClose: () => void;
  triggerElement: string;
};

export default function SourcingCreditsPopup({ visible, onClose, triggerElement }: Props) {
  const darkMode = getIsDarkMode_FIXME();

  const [currentStep, setCurrentStep] = useState(0);

  const trackPopupView = async () => {
    await setCompanyTracking('view_sourcing_explainer_popup', { triggeredFrom: triggerElement });
  };

  useEffect(() => {
    if (visible) trackPopupView();
  }, [visible]);

  const popupSteps = [
    {
      topIcon: { src: MessagingToolIcon, alt: 'The hiring tool for finding work' },
      title: 'How do sourcing credits work?',
      subTitle: [
        'A sourcing credit is used when there is mutual interest between you and a candidate. ',
        <strong key="bold">
          This happens when a candidate accepts your message request, or you accept theirs.
        </strong>,
        "You are then given access to the candidate's CV, contact details and you're able to send them to your Application Tracking System (ATS)."
      ],
      primaryButton: {
        text: 'See how it works',
        icon: 'icon_arrow_right',
        action: () => setCurrentStep(currentStep + 1)
      }
    },
    {
      title: 'Outbound sourcing',
      subTitle: 'When a candidate accepts your message request, a sourcing credit is used.',
      bodyImage: {
        src: darkMode ? CandidateAcceptDark : CandidateAccept,
        alt: 'Jenny accepted a message request from Bennedict.'
      },
      primaryButton: {
        text: 'Next',
        icon: 'icon_arrow_right',
        action: () => setCurrentStep(currentStep + 1)
      }
    },
    {
      title: 'Inbound sourcing',
      subTitle: "When you accept a candidate's message request, a sourcing credit is used.",
      bodyImage: {
        src: darkMode ? CompanyAcceptDark : CompanyAccept,
        alt: 'Incoming request from John Edwards being accepted.'
      },
      primaryButton: {
        text: 'Next',
        icon: 'icon_arrow_right',
        action: () => setCurrentStep(currentStep + 1)
      }
    },
    {
      title: 'Sourcing recredits',
      children: (
        <div className="left_alignment">
          <p>
            We proactively recredit your account when we notice instances of a candidate accepting a
            request to say they are not looking for work or are not interested. On rare occasions
            where this may be missed, you can request a sourcing recredit via your inbox in any of
            the following scenarios:
          </p>
          <ul className="disc_style">
            <li>The candidate accepted, but said they're not looking for work.</li>
            <li>The candidate accepted, but said they are not interested in your position.</li>
            <li>The candidate accepted, but said they already found a job.</li>
            <li>The candidate is not looking for permanent position.</li>
            <li>The information on the candidate's profile was misleading or incomplete.</li>
          </ul>
          <p>
            You can click the <RedoIcon className="icon_redo" /> <strong>Request recredit</strong>{' '}
            from the top of your message thread to request a recredit or email{' '}
            <a href="mailto:support@cord.co" aria-label="Go to your email client" className="link">
              support@cord.co
            </a>{' '}
            providing the person's full name and reason.
          </p>
          <p>
            Your request will be reviewed and processed within 1 working day. The status of your
            request will be visible in your inbox.
          </p>
        </div>
      ),
      primaryButton: { text: 'Close', action: () => onClose() }
    }
  ];

  return (
    <SourcingCreditsDialogWindow
      id="sourcing_credits_popup"
      visible={visible}
      showCloseIcon
      onClose={() => onClose()}
      multiStepOptions={{
        steps: popupSteps,
        currentStep,
        onStepChange: (step: number) => setCurrentStep(step)
      }}
      {...popupSteps[currentStep]}
    />
  );
}

const SourcingCreditsDialogWindow = styled(DialogWindow)`
  &#sourcing_credits_popup {
    .pop_up_dialog {
      width: 710px;

      .pop_up_top_icon {
        max-height: 120px;
      }

      @media only screen and (max-width: ${mobile}) {
        width: 92%;
      }
    }
  }
`;

const RedoIcon = styled.span`
  &:before {
    font-size: ${typography.normal};
    font-weight: ${typography.black};
  }
`;
