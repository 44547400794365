import React, { PropsWithChildren, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';

import { generateCompanyURL } from 'utils';

import Banner, { Props as BannerProps } from 'components/banner';
import LinkWithIcon from 'components/linkwithicon';
import Tooltip from 'components/tooltip';

import Avatar from 'v2/components/ui/atoms/Avatar';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import CompanySubcard, { Subitem, AdditionalItem } from './CompanySubcard';
import MetricsList, { Metrics } from './Metrics';
import {
  Card,
  Ranking,
  CompanyInfo,
  CompanyDetails,
  ShareButtonWrapper
} from './CompanyCard.styled';

const DOMAIN = process.env.REACT_APP_CORD_MAIN_DOMAIN;

export type Props = {
  className?: string;
  company: {
    id: number;
    name: string;
    info: string;
    tagLine?: string;
    photoUrl: string;
    lastActive: Date | string;
  };
  metrics: Metrics;
  banner?: PropsWithChildren<BannerProps>;
  subitems?: { type: 'position' | 'person'; items: Array<Subitem | AdditionalItem> };
  ranking?: number;
  /** JY:
   * The current version of share button is a simple one. It will be updated in next sprint
   * and I will consider how to structure it for the component re-usability.
   */
  showShareButton?: boolean;
};

export default function CompanyCard({
  className,
  company: { id, name, info, tagLine, photoUrl, lastActive },
  metrics,
  banner,
  subitems,
  ranking,
  showShareButton
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const pathname = generateCompanyURL(location, { companyName: name, companyID: id });

  const onCopy = () => {
    setCopied(true);

    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Card id={`company_card_${id}`} className={className} showRanking={!!ranking}>
      <ConditionalRender predicate={ranking}>
        <Ranking>
          <span>#{ranking}</span>
        </Ranking>
      </ConditionalRender>
      <CompanyInfo className="company_info">
        <Link to={{ pathname }} className="profile_picture_wrapper">
          <Avatar photoURL={photoUrl} width={90} lastActive={lastActive} />
        </Link>
        <CompanyDetails className="company_details" expandable={!!subitems}>
          <div>
            <Link to={{ pathname }} className="company_profile_link">
              <span className="company_name">{name}</span>
              <span className="icon_page_preview" />
            </Link>
            <ConditionalRender predicate={showShareButton}>
              <CopyToClipboard text={`${DOMAIN}${location.pathname}#${id}`} onCopy={onCopy}>
                <ShareButtonWrapper className="share_button_wrapper" showTooltip={copied}>
                  <button className="share_button primary">
                    <span className="icon_share" />
                    Share
                    <Tooltip text="URL copied!" position="bottom" />
                  </button>
                </ShareButtonWrapper>
              </CopyToClipboard>
            </ConditionalRender>
          </div>
          <div className="company_location">{info}</div>
          <ConditionalRender predicate={tagLine}>{tagLine}</ConditionalRender>
        </CompanyDetails>
        <ConditionalRender predicate={subitems}>
          <LinkWithIcon
            text={expanded ? 'Less info' : 'More info'}
            icon={expanded ? 'icon_minimize' : 'icon_add'}
            action={() => setExpanded(!expanded)}
          />
        </ConditionalRender>
        <ConditionalRender predicate={banner}>
          <Banner {...banner} />
        </ConditionalRender>
        <MetricsList id={id} metrics={metrics} spread={!banner} />
      </CompanyInfo>
      <ConditionalRender predicate={expanded}>
        {subitems?.items.map((subitem, idx) => (
          <CompanySubcard
            key={`${id}-${idx}`}
            type={subitems.type}
            item={subitem}
            companyName={name}
          />
        ))}
      </ConditionalRender>
    </Card>
  );
}
