import styled from 'styled-components';

import PageSection from 'components/pagesection';

export const FeaturesPageSection = styled(PageSection)`
  .bento_grid {
    margin-top: 4rem;
  }

  .bento_box {
    max-height: 540px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    padding: 3.5rem 4rem;
    border-radius: 2rem;
    border: 1px solid var(--border-divider-soft);
    background: var(--background-block-primary);
    box-shadow: 0px 12px 48px 0px var(--shadow-soft);
    overflow: hidden;

    ${({ theme: { media } }) => media.mobile` 
      padding: 2rem 2.5rem;
      max-height: 600px;
    `}

    .bento_box_img {
      flex-shrink: 0;
      flex-grow: 1;
      border-radius: 2rem;
      width: calc(100% + 4rem);
      object-fit: cover;
      object-position: top left;

      &:not(.no_shadow_img) {
        box-shadow: 0 12px 48px 0 var(--shadow-soft);
        border: 1px solid var(--border-divider-soft);
      }
    }

    &.align_bottom_right {
      padding-bottom: 0;

      .bento_box_img {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom: 0;
        border-right: 0;

        &.reposition_img {
          margin-left: -3rem;
          margin-top: -2rem;
          width: calc(100% + 16rem);
          max-height: 360px;
        }
      }
    }

    &.align_top_left {
      padding-top: 0;
      align-items: flex-end;

      .bento_box_img {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top: 0;
        border-left: 0;

        &.reposition_img {
          margin-left: -3rem;
          margin-top: -2rem;
        }
      }
    }

    .bento_box_content {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: start;
      text-align: left;
      gap: 0.5rem;

      [class^='icon_'] {
        font-size: 1.75rem;
        font-weight: bold;
        margin-bottom: 0.25rem;
      }

      h4 {
        font-size: 1.25rem;
        font-weight: ${({ theme: { typography } }) => typography.bold};
      }

      p {
        font-size: 1.125rem;
        margin: 0;
        color: var(--text-body-tertiary);
      }
    }
  }
`;
