import React from 'react';
import { withRouter } from 'react-router';

import { isMobileWidth } from 'responsiveConfig';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps: $TSFixMe) {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location } = this.props;

    if (
      (/u\//.test(location.pathname) ||
        /u\//.test(prevProps.location.pathname) ||
        (isMobileWidth && new RegExp('/messages').test(location.pathname))) &&
      location.pathname !== '/signup' &&
      location.pathname !== '/sign-up' &&
      location.pathname !== '/login'
    ) {
      return;
    } else if (
      location.pathname !== prevProps.location.pathname ||
      (location.hash !== prevProps.location.hash &&
        location.hash !== '' &&
        prevProps.location.hash !== '')
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

// @ts-expect-error TS(2345) FIXME: Argument of type 'typeof ScrollToTop' is not assig... Remove this comment to see the full error message
export default withRouter(ScrollToTop);
