import React from 'react';
import ClassNames from 'classnames';

import { getIsDarkMode_FIXME } from 'cookieManager';

import './style.scss';

type Props = $TSFixMe;

export default function SplitStepsSection({ steps, largeTitle }: Props) {
  const darkMode = getIsDarkMode_FIXME();

  return (
    <div className={ClassNames('full_width_steps_layout', { dark: darkMode })}>
      {steps.map(({ step, title, content }: $TSFixMe) => (
        <div className="split_cont steps_cont">
          <div className="left split_panel left_alignment">
            <div className="position_right">
              <div className={ClassNames('step_no', { large_step_no: largeTitle })}>{step}</div>
              {title && <h3 className="step_title">{title}</h3>}
            </div>
          </div>
          <div className="right split_panel left_alignment">
            <div className="position_left">
              <div className="split_panel_item">{content}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
