import { CandidateBubbleName } from 'store/ui';

import { TourBubbleProps } from 'components/tourbubble';

export const CANDIDATE_TOUR_BUBBLE_CONTENT: {
  [bubbleName in CandidateBubbleName]: Pick<TourBubbleProps, 'title' | 'body'>;
} = {
  discover: {
    title: 'Your homepage',
    body: "We've built you a personalised homepage based on your skills and interests. You'll find relevant positions, people and companies here."
  },
  search: {
    title: 'Search positions',
    body: 'Use the search tool and filters to find any position on cord. Save your searches and get notified when new positions are posted.'
  },
  savedsearch: {
    title: 'Build & Save Searches',
    body: 'You can build multiple searches and save them for easy access. You will get notified of new positions posted matching any of your searches.'
  },
  map: {
    title: 'Map view',
    body: 'View positions on a map and search for positions in a specific area.'
  },
  insights: {
    title: 'Insights',
    body: 'Position, profile and salary insights to increase your chances of success and maximise earning potential.'
  },
  messages: {
    title: 'Your messages',
    body: 'All your conversations with companies hiring in one place. Any message requests from companies about open positions will come in here.'
  },
  tracker: {
    title: 'Your application tracker',
    body: 'Manage all your applications and interviews in one place. Add external applications and track your progress on cord.'
  },
  profile: {
    title: 'Your profile',
    body: 'View and edit your skills, education & experience and see how your profile looks to a company.'
  },
  templates: {
    title: 'Message templates',
    body: 'Create templates for your message requests using variables taken from the company and position profiles.'
  },
  settings: {
    title: 'Your account settings',
    body: 'Control your profile privacy, block any company from finding you, manage your login details and and notification settings.'
  }
};
