import { getLocalStorageItem } from 'cookieManager';
import { DemoCompanyTour } from 'store';

export type BubbleName =
  | 'stream'
  | 'results'
  | 'sorting'
  | 'activity'
  | 'profile'
  | 'message'
  | 'messenger';

export const INITIAL_DEMO_COMPANY_TOUR = [
  {
    name: 'stream',
    page: { hash: '#stream', pathname: '/company/discover/stream' },
    show: true,
    shown: false
  },
  {
    name: 'results',
    page: { hash: '#results', pathname: '/company/discover/stream' },
    shown: false
  },
  {
    name: 'message',
    page: { hash: '#message', pathname: '/company/discover/stream' },
    shown: false
  },
  { name: 'messenger', page: { hash: '#messenger', pathname: '/company/discover' }, shown: false }
];

export const getDemoCompanyTour = (
  demoCompanyTour: typeof INITIAL_DEMO_COMPANY_TOUR = getLocalStorageItem('demoCompanyTour') ||
    INITIAL_DEMO_COMPANY_TOUR
) => {
  const nextBubbleLocation = getLocalStorageItem('nextDemoBubbleLocation');

  return demoCompanyTour.map(tour => ({
    ...tour,
    show: tour.name === 'stream' || nextBubbleLocation?.hash === `#${tour.name}`
  }));
};

export const getShowBubbleInfo = (
  bubbleName: BubbleName,
  demoCompanyTour: DemoCompanyTour,
  conditions: {
    demoCompany?: boolean;
    isUnpaidAccount?: boolean;
    otherCondition?: boolean;
  }
) => {
  const { demoCompany, isUnpaidAccount, otherCondition = true } = conditions;
  const showBubbles = (demoCompany || isUnpaidAccount) && otherCondition;

  if (!showBubbles) return { showBubble: false, bubbleShown: false };

  const { show = false, shown = false } =
    demoCompanyTour.find(({ name }) => name === bubbleName) || {};

  return {
    showBubble: show || window.location.hash === `#${bubbleName}`,
    bubbleShown: shown
  };
};
