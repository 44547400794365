import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { mobile } from 'responsiveConfig';

import ArrowLink from 'components/arrowlink';
import Button from 'components/button';
import DialogWindow from 'components/dialogwindow';
import VideoItem from 'components/videoitem';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type ROIVideoProps = {
  showBookDemo?: boolean;
  applyGradientColour?: boolean;
  handleClickBookDemo?: () => void;
  className?: string;
};

const ROIVideo = ({
  showBookDemo,
  applyGradientColour,
  className,
  handleClickBookDemo
}: ROIVideoProps) => {
  const { pathname } = useLocation();
  const [showROIVideoPopup, setShowROIVideoPopup] = useState(false);

  return (
    <>
      <ButtonContainer className={ClassNames(className, { page_view: showBookDemo })}>
        <Button
          text="Watch ROI case studies"
          icon="icon_play"
          action={() => setShowROIVideoPopup(true)}
          buttonStyle={applyGradientColour ? 'fill_cord_gradient' : undefined}
        />
        <ConditionalRender predicate={showBookDemo}>
          <ArrowLink
            to={{ pathname: '/request-demo', state: { from: pathname } }}
            onClick={handleClickBookDemo}
          >
            <span className={ClassNames({ blue_gradient_text: applyGradientColour })}>
              Book a demo
            </span>
          </ArrowLink>
        </ConditionalRender>
      </ButtonContainer>
      <ConditionalRender predicate={showROIVideoPopup}>
        <DialogWindow
          id="roi_video_popup"
          visible
          showCloseIcon
          onClose={() => setShowROIVideoPopup(false)}
        >
          <VideoItem
            videoUrl="https://www.youtube.com/embed/O43VQWKCCCw"
            showVideoPlayer
            youtubeOptions={{ width: '100%', height: '600', title: 'ROI video' }}
          />
        </DialogWindow>
      </ConditionalRender>
    </>
  );
};

export default ROIVideo;

const ButtonContainer = styled.div`
  &.page_view {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 45px;
    margin: 20px auto;

    @media only screen and (max-width: ${mobile}) {
      flex-direction: column;
      gap: 24px;
    }
  }

  .arrow_link {
    margin: 0;
    font-size: ${({ theme: { typography } }) => typography.normal};
  }
`;
