import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

import { getSavedSearchIDFromURL } from 'utils';

import SearchOptionList, {
  SearchOptionListProps
} from 'v2/components/ui/molecules/SearchOptionList';

import { useSearchBarContext } from '../../SimpleSearchBar.context';

import SavedSearchesHeader, {
  BREADCRUMBS_FONT_SIZE,
  BREADCRUMBS_PADDING
} from './SavedSearchesHeader';
import { getSavedSearchOption } from './SavedSearches.helpers';

export default function SavedSearches() {
  const history = useHistory();
  const { currentTab, savedSearches, setFocused, toggleDeleteSavedSearchPopup } =
    useSearchBarContext();
  const isNewTab = currentTab === 'new';

  const getSavedSearchOptions = useCallback(
    (includeDescription: boolean) => {
      if (savedSearches) return savedSearches.map(getSavedSearchOption(includeDescription));
      return Array.from({ length: 3 }, () => ({ value: -1, icon: 'icon_search' }));
    },
    [savedSearches]
  );

  const searchOptionListPropsByTab: Omit<SearchOptionListProps<number>, 'onClick'> = useMemo(() => {
    const searchOptions = getSavedSearchOptions(!isNewTab);

    if (isNewTab) return { searchOptions, maxHeight: '148px' };
    return {
      searchOptions,
      selected: getSavedSearchIDFromURL(history.location.pathname),
      maxHeight: `calc(400px - ${BREADCRUMBS_PADDING} * 2 - ${BREADCRUMBS_FONT_SIZE})`
    };
  }, [currentTab, getSavedSearchOptions]);

  return (
    <div>
      <SavedSearchesHeader />
      <SearchOptionList
        {...searchOptionListPropsByTab}
        loadingState={{ loading: !savedSearches, skeletonProps: { labelWidth: 170, height: 20 } }}
        emptyMessage="You haven't saved any searches yet."
        sidePadding={isNewTab ? '0.75rem' : undefined}
        onClick={id => {
          setFocused(false);
          history.push(`/candidate/search/${id}`);
        }}
        onDelete={id => toggleDeleteSavedSearchPopup(id)}
      />
    </div>
  );
}
