import { CompanyTemplate } from '@cohiretech/common-types';

export enum CandidateTemplateType {
  Request = 'request',
  Accept = 'accept',
  Decline = 'decline'
}

export interface TemplateDetails {
  name: string;
  template: string;
  type?: CandidateTemplateType;
  companyInviteTypeID?: number;
}

export type CompanyTemplateWithAssociate = {
  isAssociated: boolean;
  aiGenerated: CompanyTemplate['ai_generated'];
} & Required<CompanyTemplate>;

export type CompanyTemplateInput = Partial<
  Omit<CompanyTemplateWithAssociate, 'companyInviteTypeIDs'>
> & {
  companyInviteTypeID?: number;
};

export type CompanyTemplateDetails = Omit<CompanyTemplateWithAssociate, 'templateID' | 'name'>;

export type TemplateVariables = { variable: string; value: string }[];

export type CompanyTemplateVariables = {
  candidateVariables: TemplateVariables;
  otherVariables: TemplateVariables;
  aiVariables?: TemplateVariables;
};
