import { ObjectValues } from 'types';
import { isUndefined } from 'utils/fn';

export const removeItemByIndex = <T>(arr: T[], start: number) => {
  // check if index is out of bounds
  if (start === -1 || isUndefined(arr[start])) return arr;

  const result = [...arr];
  result.splice(start, 1);

  return result;
};

export const removeItem = <T>(arr: T[], item: T) => {
  const start = arr.indexOf(item);

  return removeItemByIndex(arr, start);
};

export const removeObjectItem = <T extends object>(
  arr: T[],
  key: keyof T,
  value: ObjectValues<T>
) => {
  const start = arr.findIndex(item => item[key] === value);

  return removeItemByIndex(arr, start);
};
