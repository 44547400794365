import { CompanyType } from '@cohiretech/common-types';

import {
  isCandidateProAllowed as isCandidateProAllowedFn,
  useAppDispatch,
  useAppSelector
} from 'store';
import { isEmpty } from 'utils';
import { SubscriptionPlan } from 'types';
import { isSubscriptionExpired } from 'store/services/company/subscriptionPlan';

import {
  selectCandidatePrimarySkills,
  selectCandidatePro,
  selectCandidateProfile,
  selectCandidateSecondarySkills,
  selectCompanyID,
  selectCompanyProfile,
  selectCompanyType,
  selectCompanyUser,
  selectDemoCompany,
  selectIsProfileUpdated,
  selectPermissions,
  selectProfile,
  selectSubscriptionDetailsAndPricing,
  selectUserType
} from './user.selectors';
import { setIsProfileUpdated as setIsProfileUpdatedAction } from './user';

export const useCandidateProfile = () => {
  return useAppSelector(selectCandidateProfile);
};

export const useCompanyProfile = () => {
  return useAppSelector(selectCompanyProfile);
};

export const useUserProfile = () => {
  return useAppSelector(selectProfile);
};

export const useDemoCompany = () => {
  return !!useAppSelector(selectDemoCompany);
};

export const useIsProfileUpdated = (): [boolean, (isUpdated: boolean) => void] => {
  const isProfileUpdated = useAppSelector(selectIsProfileUpdated);
  const dispatch = useAppDispatch();
  const setIsProfileUpdated = (isUpdated: boolean) => {
    dispatch(setIsProfileUpdatedAction(isUpdated));
  };

  return [isProfileUpdated, setIsProfileUpdated];
};

export const usePermissions = () => {
  return useAppSelector(selectPermissions);
};

export const useCompanyUser = () => {
  return useAppSelector(selectCompanyUser);
};

export const useCompanyID = () => {
  return useAppSelector(selectCompanyID);
};

export const useUserType = () => {
  const userType = useAppSelector(selectUserType);

  return {
    userType,
    isCompany: userType === 'company',
    isCandidate: userType === 'candidate'
  };
};

export const useCompanyType = () => {
  const companyType = useAppSelector(selectCompanyType);

  const isAgency = companyType === CompanyType.Agency;
  const isExternal = companyType === CompanyType.External;
  const isDirect = companyType === CompanyType.Direct;

  return {
    companyType,
    isAgency,
    isExternal,
    isDirect
  };
};

export const useCandidatePro = () => {
  const profile = useCandidateProfile();
  const isCandidateProAllowed = isCandidateProAllowedFn(profile);
  const isCandidateProEnabled = useAppSelector(selectCandidatePro);

  return {
    isCandidateProAllowed,
    isCandidateProEnabled
  };
};

export const useCandidateSkills = () => {
  const primarySkills = useAppSelector(selectCandidatePrimarySkills);
  const secondarySkills = useAppSelector(selectCandidateSecondarySkills);

  return { primarySkills, secondarySkills };
};

export const useSubscriptionInfo = () => {
  const details = useAppSelector(selectSubscriptionDetailsAndPricing);

  if (!details || isEmpty(details)) return {};

  const {
    subscription: { pricingCountry } = {},
    subscriptionPlan: { plan } = {},
    pricing: { billingFrequency, mode } = {}
  } = details;

  const currentPlanDetails = { plan: plan as SubscriptionPlan, billingFrequency, mode };
  const isExpired = isSubscriptionExpired(plan);

  return {
    currentPlanDetails,
    pricingCountry,
    isExpired
  };
};
