import { isFunction } from 'utils/fn';

type KeyExtractor<T = any> = ((item: T) => string) | string;

export function groupBy<T = any>(
  keyExtractor: KeyExtractor<T>
): (list: T[]) => Record<string, T[]> {
  return (list: T[]) => {
    return list.reduce((result, item) => {
      const key = isFunction(keyExtractor)
        ? keyExtractor(item)
        : (item[keyExtractor as keyof T] as string);

      // this is the first time we've seen this key
      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(item);
      return result;
    }, {} as Record<string, T[]>);
  };
}
