import { CandidateSavedSearchInterface } from '@cohiretech/common-types';

import { getTimeAgo } from 'utils';

import { SearchOption } from 'v2/components/ui/molecules/SearchOptionList';

export const getSavedSearchOption =
  (includeDescription: boolean) =>
  ({ id, searchName, createDate }: CandidateSavedSearchInterface) => {
    const option: SearchOption<number> = {
      value: id,
      icon: 'icon_search',
      label: searchName
    };

    if (includeDescription) option.description = `Saved ${getTimeAgo(createDate)}`;
    return option;
  };
