import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { RequestDemoForm as TRequestDemoForm } from 'types';
import { companyRequestAccessSignUp } from 'fetcher';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { NO_FORM_BOOK_DEMO_LINK } from 'consts';

import { ConfirmationBlock } from 'views/landing/founderprogram/SpecialEnquiryForm';

import Block from 'components/block';
import Dropdown from 'components/dropdown';
import ErrorMessage from 'components/errormessage';
import Field from 'components/field';
import PhoneNumberField from 'components/phonenumberfield';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import CVSuccessIcon from 'images/upload-cv-success.svg';

import HubSpotCalendar from '../hubspotcalendar';

import { FieldItem, FIELD_GROUPS, validate } from './helper';

export default function RequestDemoForm() {
  const phone = useRef();
  const [form, setForm] = useState<TRequestDemoForm>(getLocalStorageItem('requestDemoForm') || {});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(
    !!form?.email && !!form?.firstName && !!form?.lastName
  );

  const submit = async () => {
    const { phoneNumber, phoneCountryCode } = phone.current || {
      phoneNumber: '',
      phoneCountryCode: +44
    };

    setLoading(true);
    setLocalStorageItem('requestDemoForm', form);

    const validationMessage = validate(form);

    if (!validationMessage) {
      const { status } = await companyRequestAccessSignUp({
        ...form,
        phoneNumber,
        phoneCountryCode
      });

      if (status === 'success') {
        setSubmitted(true);
        if (form.jobType === 'Contract') removeLocalStorageItem('requestDemoForm');
      }
    }

    setErrorMessage(validationMessage || 'Something went wrong. Try again.');
    setLoading(false);
  };

  const updateForm = (newForm: TRequestDemoForm) => {
    setLocalStorageItem('requestDemoForm', newForm);
    setForm(newForm);
  };

  const renderFieldGroup = (fieldGroup: FieldItem[]) =>
    fieldGroup.map(({ key, label, placeholder }) => (
      <Field
        key={key}
        label={label}
        attributes={{ 'aria-label': label.toLowerCase() }}
        placeholder={placeholder}
        value={(form[key] as string) || ''}
        onChange={value => updateForm({ ...form, [key]: value })}
      />
    ));

  if (submitted) {
    if (form.jobType === 'Contract') {
      return (
        <ConfirmationBlock
          title={[
            <img key="submitted" src={CVSuccessIcon} alt="Request submitted" width="70" />,
            'Thanks for your request'
          ]}
          description="A member of our team will be in touch to schedule a product demo and show you around cord."
          className="center_alignment"
        />
      );
    }

    return (
      <HubSpotCalendar
        link={NO_FORM_BOOK_DEMO_LINK}
        firstName={form.firstName}
        lastName={form.lastName}
        email={form.email}
      />
    );
  }

  return (
    <FormBlock
      button={{
        text: 'Schedule a demo',
        action: submit,
        loading,
        className: 'twenty_px_font'
      }}
    >
      {FIELD_GROUPS.map((fieldGroup, idx) => {
        if (fieldGroup.length === 1) return renderFieldGroup(fieldGroup);

        return (
          <div key={`field_row_${idx}`} className="field_row two_cols">
            {renderFieldGroup(fieldGroup)}
          </div>
        );
      })}
      <PhoneNumberField
        ref={phone}
        question="Phone number"
        currentPhoneNumber={form?.phoneNumber}
        currentCountryCode={form?.phoneCountryCode || +44}
        country="United Kingdom"
      />
      <div className="field">
        <label className="question" htmlFor="job_type_dropdown">
          Type of position(s) hiring for*
        </label>
        <Dropdown
          id="job_type_dropdown"
          className="small_arrow"
          selected={form?.jobType}
          selectMessage="Eg. Permanent"
          list={[
            { label: 'Permanent', value: 'Permanent' },
            { label: 'Contract', value: 'Contract' },
            { label: 'Both', value: 'Both' }
          ]}
          onSelect={({ value }) => updateForm({ ...form, jobType: value })}
        />
      </div>
      <ConditionalRender predicate={errorMessage}>
        <ErrorMessage type="critical">{errorMessage}</ErrorMessage>
      </ConditionalRender>
    </FormBlock>
  );
}

const FormBlock = styled(Block)`
  margin-top: 0;
  padding: 24px 32px 32px;

  .field {
    padding: 8px 0;
  }

  .checkbox_wrapper {
    margin: 8px 0 24px;
  }

  .button {
    width: 100%;
  }
`;
