import React, { lazy } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import ClassNames from 'classnames';

import { generateStoryURL, isEmpty } from 'utils';
import { removeImageDimensions } from 'v2/services/image';
import { retryImport } from 'utils/fn/retry';

import Loader from 'components/loader';
import Seo from 'components/seo';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import CustomerStoryFilters from 'v2/components/ui/organisms/CustomerStoryFilters';
import Spacing from 'v2/components/ui/styles/Spacing';

import { useCustomerStoriesContext } from './CustomerStories.context';

import './style.scss';

const StoryBlockList = lazy(() => retryImport(() => import('components/storyblocklist')));

export type CustomerStoriesProps = RouteComponentProps;

export default function CustomerStories(props: CustomerStoriesProps) {
  const location = useLocation();
  const { loading, stories } = useCustomerStoriesContext();

  return (
    <div id="stories" className="stories_page_layout">
      <div className="intro_wrapper">
        <div className={ClassNames('page_view', 'center_alignment')}>
          <span className="small_heading">Customer stories</span>
          <Spacing height="03" />
          <h1 className="heading">The hiring tool behind high-growth tech companies</h1>
          <h2 className="subheading">
            Browse all 160+ stories from companies of all sizes using cord to make their direct
            hires.
          </h2>
        </div>
        <ConditionalRender
          predicate={!loading}
          fallback={
            <div className={ClassNames('page_view', 'center_alignment')}>
              <Loader className="stories_loader medium" />
            </div>
          }
        >
          <CustomerStoryFilters />
          <StoryBlockList
            items={stories}
            emptyMessage="No customer stories published yet"
            columns={3}
            generateURL={(storyData: $TSFixMe) => generateStoryURL(location, storyData, true)}
            // Can possibly be removed if history is extracted in StoryItem
            {...props}
          />
        </ConditionalRender>
      </div>
      <Seo
        title="Customer Stories"
        description={
          "Don't worry, you're in good company. Hundreds of companies have hired people through cord. Read some of their stories & feedback below."
        }
        image={!isEmpty(stories) ? removeImageDimensions(stories[0].coverPhotoURL) : ''}
        width="1200"
        height="800"
        path={location.pathname}
        contentType="website"
      />
    </div>
  );
}
