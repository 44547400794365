import { setLocalStorageItem } from 'cookieManager';
import { getMillisecondsByType } from 'utils';

// For when a new company is created and the first owner user is entering their account
export const setInitialSetupModeLS = () => {
  const dayMilliseconds = getMillisecondsByType('days');

  setLocalStorageItem('initial_setup_mode', true);
  setLocalStorageItem('first_owner_user', true);
  setLocalStorageItem('show_onboarding_bubble', true, 14 * dayMilliseconds);
  setLocalStorageItem('initial_setup_step', 1);
};
