import { CompanyRole, CompanySubscriptionPlan } from '@cohiretech/common-types';

import {
  Permissions,
  SubscriptionPlan,
  NewPricingOptions,
  AgencySubscriptionPlans,
  V5PricingOptions,
  PayPerHirePricingOptions
} from 'types';
import {
  PRICING_OPTIONS,
  V1_PRICING_EXTERNAL,
  V3_PRICING_OPTIONS,
  V4_PRICING_OPTIONS
} from 'consts';

type ExtendedSubscriptionPlan =
  | SubscriptionPlan
  | 'sourcing_basic_expired_lock'
  | 'sourcing_pro_expired_lock'
  | 'sourcing_pro_position_expired_lock'
  | SubscriptionPlan[];

type PermissionType =
  | { type: 'plan'; name: ExtendedSubscriptionPlan }
  | { type: 'user'; name: CompanyRole };

type PermissionSets = Array<PermissionType & { permissions: Permissions }>;

const SEARCH_PERMISSIONS = {
  jobTitleFilter: true,
  experienceFilter: true,
  industryExperienceFilter: true,
  companySizeExperienceFilter: true,
  skillFilter: true,
  jobTypeFilter: true,
  salaryFilter: true,
  remoteWorkFilter: true,
  keywordsInput: true,
  lastActiveFilter: true,
  locationInput: true,
  genderFilter: true,
  showOnlyOptions: true,
  showOptionsFilter: true,
  sortByLastActive: true,
  sortByMostRecentlyAdded: true
};

// Permissions that are not in use including includePassivePeople have been removed.
const COMMON_PERMISSIONS = {
  ...SEARCH_PERMISSIONS,
  messaging: true,
  companyAnalytics: true
};

// Include this to basic_permissions
/*
  savedSearches: true,
  billing: true

 */

const BASIC_PERMISSIONS = {
  ...COMMON_PERMISSIONS,
  search: true,
  seeTemplates: true,
  writeTemplates: true,
  addTeamMembers: true,
  editPositions: true,
  deletePositions: true,
  pausePositions: true,
  createPositions: true,
  editProfile: true,
  manageIncoming: true,
  manageOutgoing: true,
  manageMatches: true,
  manageApplications: true,
  calendarIntegration: true,
  shortlisting: true
};

const INACTIVE_PLAN_PERMISSIONS = {
  ...BASIC_PERMISSIONS,
  deleteTeamMembers: true,
  deleteTeamAccount: true,
  atsIntegration: true,
  teamAnalytics: true,
  positionAnalytics: true,
  salaryBenchmarkingTool: true
};

/**
 * @description Returns a set of permissions categorised according with the type of user and their role, and the plan they use.
 */

const COMPANY_PERMISSION_SETS: PermissionSets = [
  {
    type: 'plan',
    name: CompanySubscriptionPlan.Basic,
    permissions: INACTIVE_PLAN_PERMISSIONS
  },
  {
    type: 'plan',
    name: [
      PRICING_OPTIONS.earlyHires.value,
      PRICING_OPTIONS.startUp.value,
      PRICING_OPTIONS.enterprise.value, // viewer permissions are being updated in getPermissions() below
      PRICING_OPTIONS.enterprisePerUser.value,
      NewPricingOptions.Scaling,
      NewPricingOptions.ScalingV2,
      NewPricingOptions.ScalingV2Annual,
      NewPricingOptions.Unlimited,
      NewPricingOptions.UnlimitedV2,
      ...V3_PRICING_OPTIONS,
      ...V4_PRICING_OPTIONS,
      V5PricingOptions.V5Standard,
      V1_PRICING_EXTERNAL,
      AgencySubscriptionPlans.V1,
      PayPerHirePricingOptions.PayPerHire
    ],
    permissions: {
      ...INACTIVE_PLAN_PERMISSIONS,
      savedSearches: true,
      slackIntegration: true,
      billing: true
    }
  },
  {
    type: 'plan',
    name: [
      NewPricingOptions.Starter,
      NewPricingOptions.StarterV2,
      NewPricingOptions.StarterV2Annual
    ],
    permissions: {
      ...BASIC_PERMISSIONS,
      savedSearches: true,
      deleteTeamMembers: true,
      deleteTeamAccount: true,
      billing: true
    }
  },
  {
    type: 'plan',
    name: [
      NewPricingOptions.Growth,
      NewPricingOptions.GrowthPlus,
      NewPricingOptions.GrowthV2,
      NewPricingOptions.GrowthPlusV2,
      NewPricingOptions.GrowthV2Annual,
      NewPricingOptions.GrowthPlusV2Annual
    ],
    permissions: {
      ...BASIC_PERMISSIONS,
      savedSearches: true,
      deleteTeamMembers: true,
      deleteTeamAccount: true,
      salaryBenchmarkingTool: true,
      atsIntegration: true,
      slackIntegration: true,
      billing: true
    }
  },
  {
    type: 'plan',
    name: 'sourcing_pro_expired_lock',
    permissions: {
      ...COMMON_PERMISSIONS,
      search: true,
      shortlisting: true,
      seeTemplates: true,
      writeTemplates: true,
      addTeamMembers: true,
      deleteTeamMembers: true,
      editProfile: true,
      manageIncoming: true,
      manageOutgoing: true,
      manageMatches: true,
      manageApplications: true,
      deleteTeamAccount: true,
      atsIntegration: true,
      slackIntegration: true,
      calendarIntegration: true,
      billing: true,
      teamAnalytics: true,
      positionAnalytics: true,
      salaryBenchmarkingTool: true
    }
  },
  {
    type: 'plan',
    name: [CompanySubscriptionPlan.Expired, CompanySubscriptionPlan.Suspended],
    permissions: {
      ...INACTIVE_PLAN_PERMISSIONS,
      slackIntegration: true,
      billing: true
    }
  },
  {
    type: 'plan',
    name: 'sourcing_pro_position_expired_lock',
    permissions: {
      ...COMMON_PERMISSIONS,
      search: true,
      shortlisting: true,
      seeTemplates: true,
      writeTemplates: true,
      addTeamMembers: true,
      deleteTeamMembers: true,
      editProfile: true,
      manageIncoming: true,
      manageOutgoing: true,
      manageMatches: true,
      manageApplications: true,
      deleteTeamAccount: true,
      atsIntegration: true,
      slackIntegration: true,
      calendarIntegration: true,
      billing: true,
      teamAnalytics: true,
      positionAnalytics: true,
      salaryBenchmarkingTool: true
    }
  },
  {
    type: 'plan',
    name: 'sourcing_basic_expired_lock',
    permissions: {
      ...COMMON_PERMISSIONS,
      search: true,
      shortlisting: true,
      manageIncoming: true,
      manageOutgoing: true,
      manageMatches: true,
      manageApplications: true,
      editProfile: true,
      deleteTeamAccount: true,
      billing: true,
      calendarIntegration: true,
      teamAnalytics: true,
      positionAnalytics: true,
      salaryBenchmarkingTool: true
    }
  },
  {
    type: 'user',
    name: CompanyRole.Owner,
    permissions: {
      ...INACTIVE_PLAN_PERMISSIONS,
      savedSearches: true,
      showEmptySearch: true,
      slackIntegration: true,
      billing: true
    }
  },
  {
    type: 'user',
    name: CompanyRole.FullAccess,
    permissions: {
      ...BASIC_PERMISSIONS,
      savedSearches: true,
      showEmptySearch: true,
      teamAnalytics: true,
      positionAnalytics: true,
      salaryBenchmarkingTool: true,
      atsIntegration: true,
      slackIntegration: true,
      billing: true
    }
  },
  {
    type: 'user',
    name: CompanyRole.Admin,
    permissions: {
      ...COMMON_PERMISSIONS,
      seeTemplates: true,
      writeTemplates: true,
      addTeamMembers: true,
      deleteTeamMembers: true,
      editPositions: true,
      deletePositions: true,
      pausePositions: true,
      createPositions: true,
      editProfile: true,
      manageIncoming: true,
      manageOutgoing: true,
      deleteTeamAccount: true,
      atsIntegration: true,
      slackIntegration: true,
      calendarIntegration: true,
      billing: true,
      teamAnalytics: true,
      positionAnalytics: true,
      salaryBenchmarkingTool: true
    }
  },
  {
    type: 'user',
    name: CompanyRole.Viewer,
    permissions: {
      ...COMMON_PERMISSIONS,
      search: true,
      savedSearches: true,
      showEmptySearch: true,
      shortlisting: true,
      teamAnalytics: true,
      positionAnalytics: true,
      salaryBenchmarkingTool: true
    }
  }
];

/** @description Returns an object with all permissions apply for logged in user based on their role and the plan they're using */
export const getPermissions = (userRole: CompanyRole, plan?: string): Permissions => {
  try {
    const accountPermissions = COMPANY_PERMISSION_SETS.find(
      ({ type, name }) =>
        type === 'plan' && (name === plan || name.includes(plan as SubscriptionPlan))
    )!.permissions;
    const userPermissions = COMPANY_PERMISSION_SETS.find(
      ({ type, name }) => type === 'user' && name === userRole
    )!.permissions;
    const permissions: Permissions = {};

    for (const userPermission of Object.keys(userPermissions) as (keyof Permissions)[]) {
      for (const accountPermission of Object.keys(accountPermissions)) {
        if (userPermission === accountPermission) permissions[userPermission] = true;
      }
    }

    // Remove search and saved search permissions for per user seat plan "Viewers"
    const isPerUserSeatPlan =
      plan === PRICING_OPTIONS.enterprisePerUser.value || plan === AgencySubscriptionPlans.V1;

    if (userRole === CompanyRole.Viewer && isPerUserSeatPlan) {
      permissions.search = false;
      permissions.savedSearches = false;
      permissions.shortlisting = false;
      permissions.showEmptySearch = true;
    }

    return permissions;
  } catch (error) {
    return {} as Permissions;
  }
};
