import { CompanyAccountShortlistInterface } from '@cohiretech/common-types';

export const getTotalShortlistedCandidatesCount = (
  shortlists: CompanyAccountShortlistInterface[]
) => {
  let totalCount = 0;

  for (const shortlist of shortlists) {
    totalCount += shortlist.count;
  }

  return totalCount;
};

export const getShortlistsCounterColour = (darkMode: boolean, isOnAShortlistingPage: boolean) => {
  if (darkMode) {
    return isOnAShortlistingPage ? 'dark_blue' : 'white';
  } else {
    return isOnAShortlistingPage ? 'white' : 'dark_blue';
  }
};
