import styled, { css } from 'styled-components';

import Dropdown from 'components/dropdown';

import { GradientText } from './Utilities';

const ColouredDropdownSelectorStyling = css`
  ${({ theme: { typography } }) => css`
    padding: 0.75rem 1rem;
    border-radius: 1.25rem;

    #selected_label {
      font-weight: ${typography.semibold};
    }
  `}
`;

const ColouredDropdownListStyling = css`
  margin-top: 0.25rem;
  padding: 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 2rem 0 rgba(11, 54, 88, 0.08);

  .item {
    border-radius: 0.5rem;
  }
`;

export const DropdownInNoteAreaCont = styled(Dropdown)`
  ${({ theme: { fontColour, colours, typography } }) => css`
    // add line separator for the last "+ create new template" option
    &#templates_dropdown .dropdown_list .item:last-child {
      border-top: 1px solid ${colours.semiTransparentFontColour};
    }

    &&.inline_dropdown {
      .dropdown_selector {
        padding: 0.25rem 0;

        #selected_label {
          display: flex;
          gap: 0.25rem;
          align-items: center;
          color: ${fontColour};

          &.unselected {
            font-weight: ${typography.semibold};
          }

          [class^='icon_'] {
            font-weight: ${typography.black};
          }
        }

        .icon_simple_arrow_down {
          margin-left: 0.5rem;
        }
      }

      .dropdown_list {
        min-width: 260px;
      }
    }
  `}
`;

export const LightPrimaryDropdown = styled(Dropdown)`
  ${({ theme: { darkMode, colours } }) => css`
    &.dropdown_wrapper {
      .dropdown_selector,
      .dropdown_list {
        border: none;

        #selected_label,
        .icon_simple_arrow_down,
        .item_text {
          ${GradientText}
        }
      }

      .dropdown_selector {
        ${ColouredDropdownSelectorStyling}
      }

      .dropdown_list {
        ${ColouredDropdownListStyling}

        .item.selected {
          background-color: ${darkMode ? colours.darkerBgColour : colours.white};
        }
      }

      &:not(.dark) {
        .dropdown_selector,
        .dropdown_list {
          background: ${colours.lightPrimaryColour};
        }

        .item:hover {
          background-color: ${colours.white};
        }
      }
    }
  `}
`;
