import React, { useCallback, useState } from 'react';

import { CandidateTracking } from '@cohiretech/common-types';

import { getLocalStorageItem } from 'cookieManager';
import { useSearchParams } from 'hooks/useSearchParams';
import { trackCandidateAction } from 'fetcher';
import { isEmpty } from 'utils/fn';

import Tabs, { TabType } from 'components/tabs';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import InsightsLoader from './InsightsLoader';
import PositionInsights from './PositionInsights';
import PositionOverview from './PositionOverview';
import { usePositionProfile } from './PositionProfile.context';

const TABS: TabType[] = [
  { title: 'Overview', value: 0 },
  {
    title: (
      <>
        <span>Insights</span>
      </>
    ),
    value: 1
  }
];

export default function PositionContent() {
  const [currentTab, setCurrentTab] = useState(0);
  const { insightsData, showInsights, loadingInfo, setLoadingDetails } = usePositionProfile();
  const globalFirstLoad = !getLocalStorageItem(btoa('globalFirstLoadDone'));

  const goToInsightsOnLoad = useCallback((params: URLSearchParams) => {
    const tab = params.get('tab');
    if (tab === 'insights') {
      setCurrentTab(1);
      setLoadingDetails({ canFetchData: true });
    }
  }, []);
  const { updateSearchParams } = useSearchParams(goToInsightsOnLoad);

  const selectTab = (tab: number) => {
    setCurrentTab(tab);
    if (tab === 1) {
      trackCandidateAction(CandidateTracking.ViewPositionInsights);
      setLoadingDetails({ canFetchData: true });
      updateSearchParams({ tab: 'insights' });
    } else {
      updateSearchParams({ tab: '' });
    }
  };

  return (
    <>
      <ConditionalRender predicate={showInsights}>
        <Tabs current={currentTab} tabs={TABS} onChange={tab => selectTab(Number(tab.value))} />
      </ConditionalRender>
      <ConditionalRender predicate={currentTab === 0}>
        <PositionOverview goToInsightsTab={() => selectTab(1)} />
      </ConditionalRender>
      <ConditionalRender predicate={currentTab === 1}>
        <ConditionalRender
          predicate={!loadingInfo.isLoading && !isEmpty(insightsData)}
          fallback={<InsightsLoader firstLoad={globalFirstLoad} />}
        >
          <PositionInsights />
        </ConditionalRender>
      </ConditionalRender>
    </>
  );
}
