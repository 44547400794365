export const REGIONS_ARRAY = [
  {
    addressComponents: [
      {
        longText: 'Europe',
        shortText: 'Europe',
        types: ['region']
      }
    ],
    formattedAddress: 'Europe',
    location: {
      latitude: 54.526,
      longitude: 15.2551
    },
    placeId: 'ChIJhdqtz4aI7UYRefD8s-aZ73I'
  },
  {
    addressComponents: [
      {
        longText: 'Africa',
        shortText: 'Africa',
        types: ['region']
      }
    ],
    formattedAddress: 'Africa',
    location: {
      latitude: -8.7832,
      longitude: 34.5085
    },
    placeId: 'ChIJ1fWMlApsoBARs_CQnslwghA'
  },
  {
    addressComponents: [
      {
        longText: 'Asia',
        shortText: 'Asia',
        types: ['region']
      }
    ],
    formattedAddress: 'Asia',
    location: {
      latitude: 34.0479,
      longitude: 100.6197
    },
    placeId: 'ChIJV-jLJIrxYzYRWfSg0_xrQak'
  },
  {
    addressComponents: [
      {
        longText: 'South America',
        shortText: 'South America',
        types: ['region']
      }
    ],
    formattedAddress: 'South America',
    location: {
      latitude: -8.7832,
      longitude: -55.4915
    },
    placeId: 'ChIJtTRdNRw0CZQRK-PGyc8M1Gk'
  },
  {
    addressComponents: [
      {
        longText: 'Australia and Oceania',
        shortText: 'Australia and Oceania',
        types: ['region']
      }
    ],
    formattedAddress: 'Australia and Oceania',
    location: {
      latitude: -25.2744,
      longitude: 133.7751
    },
    placeId: ''
  },
  {
    addressComponents: [
      {
        longText: 'North America',
        shortText: 'North America',
        types: ['region']
      }
    ],
    formattedAddress: 'North America',
    location: {
      latitude: 54.526,
      longitude: -105.2551
    },
    placeId: 'ChIJnXKOaXELs1IRgqNhl4MoExM'
  },
  {
    addressComponents: [
      {
        longText: 'Northern Europe',
        shortText: 'Northern Europe',
        types: ['region']
      }
    ],
    formattedAddress: 'Northern Europe, Europe',
    location: {
      latitude: 50.378472,
      longitude: 14.970598
    },
    placeId: 'ChIJzQXUe835BEYR6DU1-Y0GpRQ'
  },
  {
    addressComponents: [
      {
        longText: 'Southern Europe',
        shortText: 'Southern Europe',
        types: ['region']
      }
    ],
    formattedAddress: 'Southern Europe, Europe',
    location: {
      latitude: 41.2745,
      longitude: -1.212132
    },
    placeId: 'ChIJg5194U61ZA0R04C0hznzxVw'
  },
  {
    addressComponents: [
      {
        longText: 'Eastern Europe',
        shortText: 'Eastern Europe',
        types: ['region']
      }
    ],
    formattedAddress: 'Eastern Europe, Europe',
    location: {
      latitude: 52.005515,
      longitude: 37.958749
    },
    placeId: 'ChIJ-6BSGSeG3EMRllG2gblbMBU'
  },
  {
    addressComponents: [
      {
        longText: 'Western Europe',
        shortText: 'Western Europe',
        types: ['region']
      }
    ],
    formattedAddress: 'Western Europe, Europe',
    location: {
      latitude: 50.378472,
      longitude: 14.970598
    },
    placeId: 'ChIJA48fJNqxVQ0RLgXZOYT2BLI'
  },
  {
    addressComponents: [
      {
        longText: 'Midwestern, USA',
        shortText: 'Midwestern, USA',
        types: ['region']
      }
    ],
    formattedAddress: 'Midwestern, USA',
    location: {
      latitude: 35.449507,
      longitude: -97.396702
    },
    placeId: ''
  },
  {
    addressComponents: [
      {
        longText: 'Northeast, USA',
        shortText: 'Northeast, USA',
        types: ['region']
      }
    ],
    formattedAddress: 'Northeast, USA',
    location: {
      latitude: 43.299429,
      longitude: -74.217933
    },
    placeId: ''
  },
  {
    addressComponents: [
      {
        longText: 'Southern, USA',
        shortText: 'Southern, USA',
        types: ['region']
      }
    ],
    formattedAddress: 'Southern, USA',
    location: {
      latitude: 33.83608,
      longitude: -81.1637
    },
    placeId: ''
  },
  {
    addressComponents: [
      {
        longText: 'Western, USA',
        shortText: 'Western, USA',
        types: ['region']
      }
    ],
    formattedAddress: 'Western, USA',
    location: {
      latitude: 40.691613,
      longitude: -112.00105
    },
    placeId: ''
  }
];

export const getRegionsAutocomplete = (input: string) => {
  const matchingRegions = REGIONS_ARRAY.filter(({ formattedAddress }) =>
    formattedAddress.toLowerCase().trim().startsWith(input.trim().toLocaleLowerCase())
  );

  return matchingRegions.map(({ placeId, formattedAddress }) => {
    return {
      id: placeId,
      name: formattedAddress
    };
  });
};
