import { trackEvent } from 'tracking-utils';
import { AnyFunction } from 'types';
import { noop } from 'utils/fn';
import { getCordCV } from 'v2/services/fetchers/candidate/cordCV';

export const downloadCordCV = async (openInNewTab = true, onError: AnyFunction = noop) => {
  const response = await getCordCV();
  if (response.status === 'failure') {
    onError(response.message);
    return;
  }

  trackEvent('cv_downloaded', 'CV Downloaded', 'cord CV');
  const { url } = response.data;

  if (url) {
    if (openInNewTab) window.open(url, '_blank');
    return url;
  }
  onError('No CV URL found');
  return;
};
