import axios, { AxiosResponse } from 'axios';

import {
  CompanySizeType,
  CompanyStoryBenefits,
  CompanyStoryLocation,
  CompanyStoryOrderBy,
  CompanyType,
  FullStoryDTO,
  PublicStoryDTO
} from '@cohiretech/common-types';

import { APIResponse } from 'types';

const PUBLIC_STORY = process.env.REACT_APP_PUBLIC_STORY!;
const PUBLIC_INDUSTRY_STORY = process.env.REACT_APP_PUBLIC_INDUSTRY_STORY!;

type GetCompanyStoriesParams = {
  companyType?: CompanyType.Direct | CompanyType.Agency;
  type?: 'all' | 'video' | 'text';
  companySizeTypes?: CompanySizeType[];
  benefits?: CompanyStoryBenefits[];
  locations?: CompanyStoryLocation[];
  industries?: string[];
  containsHighlights?: boolean;
  notable?: boolean;
  orderBy?: CompanyStoryOrderBy;
  ignoreTags?: boolean;
};

export const getCompanyStories = async (
  params: GetCompanyStoriesParams = {}
): Promise<APIResponse<PublicStoryDTO[]>> => {
  try {
    const { data } = await axios.get<AxiosResponse<PublicStoryDTO[]>>(PUBLIC_STORY, { params });
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getCompanyStory = async (id: $TSFixMe): Promise<APIResponse<FullStoryDTO>> => {
  try {
    const { data } = await axios.get<AxiosResponse<FullStoryDTO>>(`${PUBLIC_STORY}/${id}`);
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getValidStoryIndustries = async (): Promise<APIResponse<string[]>> => {
  try {
    const { data } = await axios.get<AxiosResponse<string[]>>(PUBLIC_INDUSTRY_STORY);
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
