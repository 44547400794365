import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { PricingMode, Frequency } from '@cohiretech/common-types';

import {
  NewPricingOptions,
  PricingCountry,
  PricingData,
  PricingOptionType,
  SubscriptionPlan,
  UpgradeAction
} from 'types';
import { isUnlimitedPlan } from 'utils/companyUtils';
import { mobile } from 'responsiveConfig';

import Button, { Props as ButtonProps } from 'components/button';
import Dropdown from 'components/dropdown';
import ErrorMessage from 'components/errormessage';
import PricingPlanFeature from 'components/pricingplansnew/pricingplancard/pricingplanfeature';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

type Props = PricingOptionType & {
  planPeriod: Frequency;
  pricingType: PricingCountry;
  darkMode: boolean;
  selected?: boolean;
  requested?: boolean;
  isPreviousPlan?: boolean;
  loading?: boolean;
  errorMessage?: string;
  onSelect: (
    plan: SubscriptionPlan,
    planDetails: { urlPath: string; mode: PricingMode; action?: UpgradeAction }
  ) => void;
  className?: string | string[];
  showDemoLinks: boolean;
};

export default function PricingPlanCard({
  planPeriod,
  pricingType,
  label,
  value,
  description,
  positions,
  users,
  features,
  isPreviousPlan,
  className,
  onSelect,
  darkMode,
  showDemoLinks,
  selected,
  requested,
  loading,
  errorMessage,
  ...rest
}: Props) {
  const conversations = rest[pricingType][planPeriod]!.conversations;
  const [selectedConversation, setSelectedConversation] = useState(conversations[0]);
  const [planTitle, setPlanTitle] = useState(label);

  useEffect(() => {
    setSelectedConversation(conversations[0]);
  }, [conversations]);

  useEffect(() => {
    const isGrowthPlusPlan = [
      NewPricingOptions.GrowthPlus,
      NewPricingOptions.GrowthPlusV2
    ].includes(selectedConversation.plan);

    setPlanTitle(isGrowthPlusPlan ? `${label}+` : label);
  }, [selectedConversation]);

  const getButtonProps = () => {
    const { action, onTrack, urlPath, mode, upgradeAction, ...restButtonProps } =
      selectedConversation?.button as ButtonProps & {
        urlPath: string;
        onTrack: () => void;
        mode: PricingMode;
        upgradeAction: UpgradeAction;
      };

    const handleClick = () => {
      onTrack?.();

      if (action) action();
      else onSelect(selectedConversation.plan, { urlPath, mode, action: upgradeAction });
    };

    return { ...restButtonProps, action: handleClick, loading: selected && loading };
  };

  return (
    <PricingPlan className="pricing_plan">
      {isPreviousPlan && <PreviousBanner>your previous plan</PreviousBanner>}
      <PricingPlanBlock
        className={ClassNames('pricing_plan_card', className, {
          dark: darkMode,
          no_top_radius: isPreviousPlan
        })}
      >
        <div className="plan_header">
          <h3 className="plan_title">{planTitle}</h3>
          <p className="plan_description">{description}</p>
        </div>
        <PriceWrapper>
          <ConditionalRender predicate={selectedConversation?.hasDiscount}>
            <s className="red_text">{selectedConversation.price}</s>
          </ConditionalRender>
          <h4 className="plan_price">
            {selectedConversation.discountedPrice || selectedConversation.price}
            <span>/month{planPeriod === Frequency.annual && ', billed yearly'}</span>
          </h4>
        </PriceWrapper>
        <Dropdown
          id="conversations_dropdown"
          className="price_conversation_dropdown small_arrow"
          list={conversations}
          selected={selectedConversation.value}
          onSelect={convo => setSelectedConversation(convo as PricingData['conversations'][0])}
          hideArrow={conversations.length === 1}
          disabled={conversations.length === 1}
        />
        <div
          className={ClassNames('plan_button_wrapper', {
            min_height:
              isUnlimitedPlan(value as NewPricingOptions) &&
              planPeriod === Frequency.monthly &&
              showDemoLinks
          })}
        >
          {selected && requested ? (
            <div className="confirmation_message">
              A member of the team will be in touch within 1 working day.
            </div>
          ) : (
            <Button className="eighteen_px_font" {...getButtonProps()} />
          )}
          {selectedConversation?.link && (
            <Link
              to={selectedConversation?.link?.to || ''}
              aria-label={selectedConversation?.link?.text}
              className="link"
              onClick={() => selectedConversation?.link?.onClick?.()}
            >
              {selectedConversation?.link?.text}
            </Link>
          )}
          {errorMessage && selected && <ErrorMessage type="critical">{errorMessage}</ErrorMessage>}
        </div>
      </PricingPlanBlock>
      <PlanFeatures>
        <strong>Includes:</strong>
        <div className="features_list" role="list">
          {features.map(feature => (
            <PricingPlanFeature
              key={`${selectedConversation.value}-${feature.name}`}
              feature={feature}
            />
          ))}
        </div>
      </PlanFeatures>
    </PricingPlan>
  );
}

const PlanFeatures = styled.div`
  margin-top: 0.5rem;
  padding: 1.25rem;

  .features_list {
    margin: 0.5rem 0;
  }
`;

export const PricingPlan = styled.div`
  position: relative;
  text-align: left;
  flex: 1;

  @media only screen and (max-width: ${mobile}) {
    max-width: inherit;
  }

  .feature_name,
  .price_conversation_dropdown .dropdown_selector span {
    cursor: pointer;

    &:hover .tooltip_wrapper {
      padding-bottom: 0;
      display: block;
      text-align: left;

      .tooltip:after {
        display: none;
      }
    }
  }
`;

export const PreviousBanner = styled.div`
  background-color: ${colours.semiTransparentPrimaryColour};
  color: ${colours.primaryColour};
  text-transform: uppercase;
  letter-spacing: 0.3px;
  text-align: center;
  font-weight: ${typography.black};
  font-size: ${typography.mobile};
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding: 0 0.25rem;
  height: 1.75rem;
  line-height: 1.75rem;
  box-sizing: border-box;
  margin-top: -1.75rem;
`;

export const PricingPlanBlock = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 22rem;
  border-radius: 1.25rem;
  box-shadow: 0 0 20px -5px ${colours.dropshadow};
  background: white;

  &.no_top_radius {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &.dark {
    box-shadow: 0 0 30px -5px ${colours.darkDropshadow};
    background: ${colours.darkBgColour};

    &.grey_background {
      background: ${colours.darkerBgColour};
      color: ${colours.lightFontColour};
    }
  }

  .plan_title {
    font-size: 1.25rem;
    margin: 0;
  }

  .plan_description {
    margin: 0.25rem 0;
  }

  .price_conversation_dropdown {
    color: ${colours.primaryColour};

    .dropdown_selector {
      margin: 0.5rem 0;
      background-color: ${colours.transparentPrimaryColour} !important;
      border: 0;
      font-weight: ${typography.bold};
    }

    .dropdown_list {
      background-color: ${colours.lightPrimaryColour};
      border: 0;

      .item {
        &:hover,
        &.selected {
          background-color: ${colours.transparentPrimaryColour};
        }

        .item_text {
          display: block;
        }
      }
    }
  }

  .plan_button_wrapper {
    margin-top: auto;

    &.min_height {
      min-height: 5.75rem;
    }

    .confirmation_message {
      margin-top: 0.5rem;
      text-align: center;
      opacity: 0.8;
    }

    .button {
      display: block;
      width: 100%;
    }

    .link {
      width: fit-content;
      margin: 1rem auto 0;
      display: block;
    }
  }
`;

export const PriceWrapper = styled.div`
  color: ${colours.primaryColour};
  margin-top: 1.5rem;

  .plan_price {
    font-size: 2rem;
    margin: 0;
    line-height: 1;

    span {
      font-size: 1rem;
    }
  }

  small {
    font-size: 0.7rem;
  }
`;
