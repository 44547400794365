import React, { PropsWithChildren, ForwardedRef } from 'react';
import styled, { css } from 'styled-components';

import { getIsDarkMode_FIXME } from 'cookieManager';
import { macBookScreen, mobile, smallDesktop, laptop, smallLaptop } from 'responsiveConfig';

import colours from 'css/base/_colours.module.scss';

type OwnProps = {
  id?: string;
  className?: string;
  truncated?: boolean;
  bordered?: boolean;
  highlightRowOnHover?: boolean;
  overflowVisible?: boolean;
  fixedHeader?: boolean;
  containerRef?: ForwardedRef<HTMLDivElement>;
};

type Props = PropsWithChildren<OwnProps> & typeof Table.defaultProps;

export default class Table extends React.Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    truncated: false,
    bordered: true,
    highlightRowOnHover: true,
    overflowVisible: false
  };

  render() {
    const {
      id,
      className,
      children,
      truncated = false,
      fixedHeader = false,
      bordered = true,
      highlightRowOnHover = true,
      overflowVisible = false,
      containerRef
    } = this.props;
    const darkMode = getIsDarkMode_FIXME();

    return (
      <TableWrapper
        ref={containerRef}
        className="table_container"
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        overflowVisible={overflowVisible}
      >
        <UITable
          id={id}
          className={className}
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          truncated={truncated}
          fixedHeader={fixedHeader}
          bordered={bordered}
          highlightRowOnHover={highlightRowOnHover}
          dark={darkMode}
        >
          {children}
        </UITable>
      </TableWrapper>
    );
  }
}

const TableWrapper = styled.div`
  overflow: ${props => ((props as $TSFixMe).overflowVisible ? 'inherit' : 'auto')};
  margin-bottom: 10px;

  @media only screen and (max-width: ${mobile}) {
    overflow: auto;
  }
`;

const UITable = styled.table`
  width: 100%;

  @media only screen and (max-width: ${mobile}) {
    table-layout: auto;
  }

  tr:hover {
    background-color: ${props =>
      (props as $TSFixMe).highlightRowOnHover && (props as $TSFixMe).dark
        ? colours.darkerBgColour
        : (props as $TSFixMe).highlightRowOnHover
        ? colours.lightBgColour
        : 'transparent'};
  }

  ${props =>
    (props as $TSFixMe).fixedHeader &&
    css`
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; /* even columns width , fix width of table too*/
      }

      tbody {
        display: block; /* to enable vertical scrolling */
        max-height: calc(80vh - 100px);
        overflow-y: auto; /* keeps the scrollbar even if it doesn't need it; display purpose */

        @media only screen and (max-width: ${smallDesktop}) {
          max-height: calc(70vh - 100px);
        }

        @media only screen and (max-width: ${macBookScreen}) {
          max-height: calc(70vh - 120px);
        }

        @media only screen and (max-width: ${laptop}) {
          max-height: calc(60vh - 100px);
        }

        @media only screen and (max-width: ${smallLaptop}) {
          max-height: calc(80vh - 160px);
        }
      }
    `}

  thead tr:hover, tfoot tr:hover {
    background-color: transparent;
  }

  th {
    position: relative;
    border-bottom: 2px solid;
    font-weight: bold;
    text-align: left;
    padding: 8px 4px;
    vertical-align: middle;

    &:not(.sortable):not(.with_tooltip) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.text_right {
      text-align: right;
    }

    &.text_center {
      text-align: center;
    }

    &.sortable {
      cursor: pointer;

      &:hover {
        background-color: ${props =>
          (props as $TSFixMe).dark ? colours.darkerBgColour : colours.lightBgColour};
      }
    }

    .tooltip_wrapper {
      width: 200px;
      margin-top: 8px;

      .tooltip {
        text-align: left;
        line-height: 1.1;
      }
    }

    &:hover .tooltip_wrapper {
      display: block;
    }
  }

  tbody,
  tfoot {
    border-bottom: 2px solid;
  }

  td {
    border: 0;
    padding: 10px 4px;
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
    overflow-wrap: break-word;

    &.text_right {
      text-align: right;
    }

    &.text_center {
      text-align: center;
    }

    &.text_bold {
      font-weight: ${({ theme: { typography } }) => typography.semibold};
    }

    &.clickable {
      cursor: pointer;
    }

    .table_input,
    .table_dropdown {
      margin: 0 !important;
      background-color: white;
    }

    .action_icon {
      position: relative;
      margin: 0 8px;
      opacity: 0.8;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      vertical-align: middle;
      font-weight: ${({ theme: { typography } }) => typography.bold};

      &:hover {
        opacity: 1;

        .tooltip_wrapper {
          display: block;
          opacity: 1;
        }
      }

      &.icon_delete,
      &.icon_failed {
        color: ${colours.errorColour};

        &:hover {
          color: ${colours.errorDarkShade};
        }
      }

      &.icon_successful {
        color: ${colours.primaryColour};

        &:hover {
          color: ${colours.primaryDarkShade};
        }
      }

      &.icon_send {
        color: ${colours.activeColour};

        &:hover {
          color: ${colours.activeDarkShade};
        }
      }

      &.icon_cancel {
        display: inline-block;
        font-size: 24px;
        color: ${colours.inputsColour};
        vertical-align: text-bottom;
      }

      &.icon_close {
        font-size: 20px;
      }

      &.icon_tick,
      &.icon_freeze {
        cursor: pointer;
        color: ${colours.primaryColour};
      }
    }
  }

  ${props =>
    !(props as $TSFixMe).bordered &&
    css`
      tbody,
      tfoot {
        border-bottom: 0;
      }

      td {
        padding: 7px 5px 7px 0;
        vertical-align: top;
        font-weight: ${({ theme: { typography } }) => typography.regular};
        white-space: normal;

        @media only screen and (max-width: ${mobile}) {
          padding: 5px 5px 15px 0;
          display: block;
          width: 100%;

          &:first-child {
            padding-bottom: 0;
          }
        }
      }
    `}

  ${props =>
    (props as $TSFixMe).dark &&
    css`
      td .table_input,
      td .table_dropdown {
        background-color: ${colours.darkBgColour};
      }
    `}

  ${props =>
    (props as $TSFixMe).truncated &&
    css`
      table-layout: fixed;

      td:not(.with_tooltip) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      @media only screen and (max-width: ${mobile}) {
        table-layout: auto;
      }
    `}
`;
