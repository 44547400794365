import React, { SVGProps, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

type Props = {
  SVGComponent: React.ComponentType<SVGProps<SVGSVGElement>>;
  elementID: string;
  keyframes: string;
};

const ScrollAnimator = ({ SVGComponent, elementID, keyframes }: Props) => {
  const scrollRef = useRef<SVGSVGElement | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const linkScroll = linkScrollToElement(scrollRef, parentRef, elementID);
    window.addEventListener('scroll', linkScroll);

    return () => window.removeEventListener('scroll', linkScroll);
  }, [scrollRef.current]);

  return (
    <AnimationController
      className={classNames('center_alignment')}
      elementID={elementID}
      keyframes={keyframes}
      ref={parentRef}
    >
      <SVGComponent ref={scrollRef} />
    </AnimationController>
  );
};

const linkScrollToElement =
  (
    scrollRef: React.RefObject<SVGSVGElement>,
    parentRef: React.RefObject<HTMLDivElement>,
    elementID: string
  ) =>
  () => {
    const element = scrollRef.current;
    if (!element) return;

    const { height, bottom } = element.getBoundingClientRect();
    const relativeY = 1 - bottom / (window.innerHeight + height);
    parentRef.current?.style.setProperty(`--scroll-${elementID}`, `${relativeY}`);
  };

const AnimationController = styled.div<{ elementID: string; keyframes: string }>`
  ${({ elementID, keyframes }) => css`
    #${elementID} {
      animation: ${elementID}_animation 1000ms linear 1 normal forwards;
      animation-play-state: paused;
      animation-delay: ${`calc(var(--scroll-${elementID}) * -1s)`};
      animation-iteration-count: 1;
      animation-fill-mode: both;
    }

    @keyframes ${elementID}_animation {
      ${keyframes}}
    }
  `}
`;

export default ScrollAnimator;
