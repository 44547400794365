import React from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { getIsDarkMode_FIXME } from 'cookieManager';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

type Props = {
  className?: string;
  text?: string;
  textBackground?: 'white' | 'grey';
  fontSize?: 'normal' | 'small';
  dividerColour?: 'inputsColour' | 'lightFontColour';
};

type TextProps = {
  background: Props['textBackground'];
  fontSize: Props['fontSize'];
};

type DividerProps = {
  dividerColour: Props['dividerColour'];
};

export default function LineDivider({
  className,
  text = '',
  textBackground = 'white',
  fontSize = 'normal',
  dividerColour = 'inputsColour'
}: Props) {
  const darkMode = getIsDarkMode_FIXME();

  return (
    <Wrapper className={ClassNames(className, 'divider_wrapper')}>
      <Divider className="divider" dividerColour={dividerColour} />
      <ConditionalRender predicate={text}>
        <Text background={textBackground} fontSize={fontSize} className={darkMode ? 'dark' : ''}>
          {text}
        </Text>
      </ConditionalRender>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const Divider = styled.div<DividerProps>`
  height: 1px;
  margin: 25px 0;
  background-color: ${({ theme, dividerColour }) => theme.colours[dividerColour!]};
`;

const Text = styled.span<TextProps>`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
  font-size: ${({ theme, fontSize }) => theme.typography[fontSize!]};
  color: ${colours.inputsColour};
  text-align: center;
  background-color: ${props => (props.background === 'grey' ? colours.lightBgColour : 'white')};

  &.dark {
    background-color: ${colours.darkerBgColour};
  }
`;
