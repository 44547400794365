import React, { useState, useEffect } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { CompanySubscriptionPlan, Frequency } from '@cohiretech/common-types';

import { V4PricingOptions, PricingCountry, CurrentPlanDetails, UpgradeAction } from 'types';
import { getCurrencyFromPricingCountry, commify } from 'utils';
import { checkIfSubscriptionExpired, setBookDemoTracking } from 'utils/companyUtils';
import { getV4BasicPrice } from 'utils/pricingUtils';
import { getActivateBtnProps, getRequestDemoLink } from 'v2/services/tools/pricing';
import {
  handleUpgrade,
  handlePlanEnquiry,
  handleReactivation,
  proceedToPayment,
  Result
} from 'v2/services/company/subscription';
import media from 'styles/media';

import ArrowLink from 'components/arrowlink';
import Badge from 'components/badge';
import BulletList, { Bullet } from 'components/bulletlist';
import Button, { Props as ButtonProps } from 'components/button';
import ErrorMessage from 'components/errormessage';
import LinkWithIcon from 'components/linkwithicon';
import PaymentMethodPopup, { Props as PaymentPopupProps } from 'components/paymentmethodpopup';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

import { GrowthPlan } from '../helper';

type PricingOption = {
  plan: string;
  label: string;
  description: string;
  featureTitle: string;
  features: string[];
  button: ButtonProps;
};

type Props = {
  pricingOption: PricingOption;
  pricingType: PricingCountry;
  planPeriod: Frequency;
  currentPlanDetails?: CurrentPlanDetails;
  growthTiers: GrowthPlan;
  isUnpaidAccount?: boolean;
  darkMode: boolean;
  demoCompany: boolean;
};

type PaymentPopup = { show: boolean } & Result['paymentData'];

type Action = UpgradeAction | 'annual' | 'reactivation';

const ERROR_MESSAGE = 'Something went wrong. Try again.';
const CONFIRMATION_MESSAGE = 'A member of the team will be in touch soon.';

export default function PricingPlanCard({
  pricingOption,
  pricingType,
  planPeriod,
  currentPlanDetails,
  growthTiers: tiers,
  darkMode,
  demoCompany
}: Props) {
  const { plan, label, description, featureTitle, features, button } = pricingOption;
  const { plan: currentPlan, billingFrequency, isUnpaidAccount } = currentPlanDetails || {};

  const [growthTiers, setGrowthTiers] = useState(tiers);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentPopup, setPaymentPopup] = useState<Partial<PaymentPopup>>({ show: false });

  const isMonthlyPlan = planPeriod === Frequency.monthly;
  const isEnterprise = plan === 'enterprise';
  const isSubscriptionExpired = checkIfSubscriptionExpired(currentPlan);
  const selectedPlan = isEnterprise ? V4PricingOptions.V4Unlimited : V4PricingOptions.V4Growth;
  const {
    price = 0,
    hasDiscount,
    discountedPrice = 0,
    urlPath = '',
    mode,
    enquired
  } = growthTiers?.[planPeriod] || {};
  const activateButtonProps = getActivateBtnProps(
    {
      plan: selectedPlan,
      frequency: planPeriod,
      pricingCountry: pricingType
    },
    isUnpaidAccount
  );

  useEffect(() => {
    setGrowthTiers(tiers);
  }, [tiers]);

  const getPrice = (isDiscounted?: boolean) => {
    if (isEnterprise) return getV4BasicPrice(pricingType, selectedPlan);

    const currency = getCurrencyFromPricingCountry(pricingType);
    return `${currency}${commify(Math.round(isDiscounted ? discountedPrice : price))}`;
  };

  const isCurrentPlanSelected = () => {
    if (!currentPlanDetails || billingFrequency !== planPeriod) return false;
    return currentPlanDetails?.plan === selectedPlan;
  };

  const handleSelect = async (action: Action) => {
    setLoading(true);

    let result: Result;

    switch (action) {
      case 'upgrade':
        result = await handleUpgrade(selectedPlan, urlPath, mode);
        break;
      case 'reactivation':
        result = await handleReactivation(selectedPlan, urlPath, pricingType);
        break;
      default:
        result = await handlePlanEnquiry(urlPath, planPeriod, action, isSubscriptionExpired, mode);

        if (result.status === 'success') {
          const copiedGrowthTiers = { ...growthTiers };
          Object.assign(copiedGrowthTiers[planPeriod], { enquired: true });

          setGrowthTiers(copiedGrowthTiers);
        }

        break;
    }

    const { status, errorMsg, paymentData } = result;

    if (paymentData) setPaymentPopup({ show: true, ...paymentData });
    setErrorMessage(status === 'failure' ? errorMsg || ERROR_MESSAGE : '');
    setLoading(false);
  };

  // For existing users
  const getButtonAction = (): { text: string; action: Action } | undefined => {
    if (isSubscriptionExpired) {
      return { text: 'Reactivate subscription', action: isMonthlyPlan ? 'reactivation' : 'annual' };
    }
    if (!isMonthlyPlan) return { text: 'Enquire now', action: 'annual' };
  };

  const getButtonProps = (): ButtonProps => {
    if (isCurrentPlanSelected() && !demoCompany) {
      return { ...button, text: 'Your current plan', disabled: true };
    }
    if (demoCompany || !currentPlan || isEnterprise) {
      return {
        ...button,
        link: getRequestDemoLink({
          plan: selectedPlan,
          pricingCountry: pricingType,
          frequency: planPeriod
        })
      };
    }
    if (currentPlan === CompanySubscriptionPlan.Basic) {
      return {
        ...button,
        ...activateButtonProps
      };
    }

    const { text, action } = getButtonAction() || {};

    return {
      ...button,
      text,
      action: action ? () => handleSelect(action) : undefined,
      disabled: enquired
    };
  };

  const isFounderProgram = new RegExp(/(-discount-25$)|(^v4-growth-founder)/).test(
    currentPlanDetails?.urlPath || ''
  );
  const formattedPrice = getPrice(hasDiscount);
  const showSecondaryButton =
    !isEnterprise && (!currentPlan || currentPlan === CompanySubscriptionPlan.Basic || demoCompany);

  return (
    <PricingPlan className={ClassNames(plan, 'pricing_plan_card')} dark={darkMode}>
      <h3 className="plan_title">{label}</h3>
      <p className="plan_description">{description}</p>
      <ConditionalRender predicate={hasDiscount && isMonthlyPlan && !isEnterprise}>
        <OriginalPrice>{getPrice()}</OriginalPrice>
        <ConditionalRender predicate={isFounderProgram}>
          <Badge key="discount" text="25% Discount" />
        </ConditionalRender>
      </ConditionalRender>
      <PlanPrice className="plan_price lightblue_text" isSingleLine={!isMonthlyPlan}>
        <ConditionalRender predicate={isEnterprise && isMonthlyPlan}>
          Starting from
        </ConditionalRender>
        <ConditionalRender predicate={!(isEnterprise && !isMonthlyPlan)} fallback={<h4>Custom</h4>}>
          <div>
            <h4>{formattedPrice}</h4>/month{isMonthlyPlan ? '' : ', billed annually'}
          </div>
        </ConditionalRender>
      </PlanPrice>
      <FeatureList shorter={isEnterprise}>
        <h4>{featureTitle}</h4>
        {features.map((feature, idx) => (
          <Bullet key={`${plan}_feature_${idx}`} iconCircleColour="light">
            {feature}
          </Bullet>
        ))}
      </FeatureList>
      <ButtonWrapper className="plan_button_wrapper">
        <Button className="eighteen_px_font" loading={loading} {...getButtonProps()} />
        <ConditionalRender predicate={showSecondaryButton}>
          <ConditionalRender
            predicate={currentPlan !== CompanySubscriptionPlan.Basic || demoCompany}
            fallback={
              <LinkWithIcon
                icon="icon_demo"
                text="Book a demo"
                action={() => setBookDemoTracking('click', !!currentPlan)}
                link="/request-demo?plan=V4_growth"
              />
            }
          >
            <ArrowLink to={activateButtonProps.link} onClick={activateButtonProps.action}>
              Create your account
            </ArrowLink>
          </ConditionalRender>
        </ConditionalRender>
      </ButtonWrapper>
      <ConditionalRender predicate={errorMessage || (!isEnterprise && enquired)}>
        <ErrorMessage type={errorMessage ? 'critical' : undefined}>
          {errorMessage || CONFIRMATION_MESSAGE}
        </ErrorMessage>
      </ConditionalRender>
      <ConditionalRender predicate={paymentPopup.show}>
        <CardPaymentPopup
          plan={{
            planInfo: { label, price: formattedPrice, urlPath },
            cycle: planPeriod,
            pricingType
          }}
          onClose={() => setPaymentPopup({ ...paymentPopup, show: false })}
          {...(paymentPopup as PaymentPopup)}
        />
      </ConditionalRender>
    </PricingPlan>
  );
}

type PopupProps = {
  plan: Pick<PaymentPopupProps, 'planInfo' | 'cycle' | 'pricingType'>;
  onClose: () => void;
} & Omit<PaymentPopup, 'show'>;

const CardPaymentPopup = ({ plan, cards, sessionID, stripeRegion, onClose }: PopupProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handlePayment = async () => {
    const { status, errorMsg } = await proceedToPayment(sessionID, stripeRegion);

    if (status === 'failure') setErrorMessage(errorMsg || ERROR_MESSAGE);
  };

  const { planInfo, cycle, pricingType } = plan;

  return (
    <PaymentMethodPopup
      planInfo={planInfo}
      cycle={cycle}
      pricingType={pricingType}
      cards={cards}
      handleClose={onClose}
      proceedToPayment={() => handlePayment()}
      errorMessage={errorMessage}
      setErrorMessage={message => setErrorMessage(message)}
    />
  );
};

const PricingPlan = styled.div<{ dark: boolean }>`
  width: 50%;
  padding: 2.25rem;
  box-sizing: border-box;
  background: ${({ dark }) => (dark ? colours.darkBgColour : 'white')};
  box-shadow: 0px 0px 30px ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(49, 71, 95, 0.2)')};
  border-radius: 20px;
  text-align: initial;

  .plan_description {
    margin: 0 0 20px;
  }

  &.growth {
    z-index: 2;
  }

  &.enterprise {
    background: ${({ dark }) => (dark ? '#283d54' : colours.fontColour)};
    color: white;
    border-radius: 0 20px 20px 0;
    box-shadow: 0px 0px 20px rgba(49, 71, 95, 0.1);
  }

  ${media.mobile`
    width: 100% !important;
    padding: 30px;

    &.enterprise {
      border-radius: 20px;
    }

    &.growth .button {
      width: 100%;
    }
  `}

  ${media.mobileMin`
    &.enterprise {
      margin-left: -8px;
      padding: 1.75rem 2.25rem;
    }
  `}

  ${media.tabletLandscapeMin`
    max-width: 490px; 

    &.enterprise {
      max-width: 460px;
    }
  `}
`;

const OriginalPrice = styled.span`
  color: ${colours.errorColour};
  text-decoration: line-through solid ${colours.errorColour};
`;

const PlanPrice = styled.div<{ isSingleLine: boolean }>`
  margin-bottom: 6px;
  font-size: ${typography.normal};

  h4 {
    display: inline-block;
    font-size: 36px;
  }

  ${({ isSingleLine }) =>
    !isSingleLine &&
    css`
      ${media.mobileMin`
        min-height: 70px;
        position: relative;

        div {
          position: absolute;
          bottom: 0;
        }
      `}
    `}
`;

const FeatureList = styled(BulletList)<{ shorter: boolean }>`
  margin: ${({ shorter }) => (shorter ? '1.25rem 0 2rem' : '1.5rem 0 2.5rem')};
  display: flex;
  flex-direction: column;
  gap: 10px;

  h4 {
    font-size: ${typography.normal};
    font-weight: ${typography.bold};
  }

  .bullet_item {
    padding-bottom: 0;

    .icon_bullet {
      padding-right: 10px;
    }

    .bullet_content p {
      margin: 0;
    }

    ${media.mobile`
      .icon_bullet {
        font-size: ${typography.normal};
      }

      .bullet_content p {
        font-size: ${typography.small};
      }
    `}
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  ${media.mobile`
    flex-direction: column;
    gap: 1.25rem;

    .button {
      width: 100%;
    }
  `}
`;
