import React, { lazy, Suspense } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ClassNames from 'classnames';

import { CompanySubscriptionPlan } from '@cohiretech/common-types';

import * as fetcher from 'fetcher';
import { retry } from 'utils';
import { isPositionLimitReached } from 'utils/companyUtils';
import { CompanyProfile, PositionInProfile } from 'types';
import { editAgencyPosition } from 'v2/services/fetchers/company/agencyPosition';
import { identifyCompanyType } from 'v2/services/company/companyType';
import { segmentTrack } from 'tracking-utils';

import { LeftMenuProps } from 'components/topnav';
import Button from 'components/button';
import CompanyEditPositionHiringTeam from 'components/companyeditposition/hiringteam';
import DialogWindow from 'components/dialogwindow';
import ErrorMessage from 'components/errormessage';

import AddCareerPagePopup from 'v2/components/AddCareerPagePopup';

import DiscoverMenu from './discovermenu';

import '../style.scss';
// @ts-expect-error TS(2322) FIXME: Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
const MultiStageToggle = lazy(() => retry(() => import('components/multistagetoggle')));

export type Props = {
  profile: CompanyProfile;
  pathname: RouteComponentProps['location']['pathname'];
  location: RouteComponentProps['location'];
  liveLeadsListRef?: React.RefObject<HTMLDivElement>;
} & Omit<LeftMenuProps, 'profile' | 'admin' | 'changeTheme'>;

type AssociatedPositionPopupShow =
  | {
      show: true;
      type: keyof typeof associatedPositionPopupDetails;
    }
  | {
      show: false;
      type?: keyof typeof associatedPositionPopupDetails;
    };

type State = {
  changingJobStatus: boolean;
  jobStatusChanged: boolean;
  associatedPositionPopup: AssociatedPositionPopupShow & {
    loading?: boolean;
    errorMessage?: string;
  };
  showAddCareerPagePopup: boolean;
};

const associatedPositionPopupDetails = {
  noOneAssociated: {
    title: 'Choose a hiring team',
    subTitle:
      "The position you're trying to open has no hiring team. Select the members you want to be in the hiring team for this position."
  },
  userNotAssociated: {
    title: 'Join the hiring team?',
    subTitle:
      'You’re not currently associated with this position. Do you want to join the hiring team?'
  }
};

export default class CompanyMenu extends React.Component<Props, State> {
  team: $TSFixMe;
  constructor(props: Props) {
    super(props);

    this.state = {
      changingJobStatus: false,
      jobStatusChanged: false,
      associatedPositionPopup: { show: false, loading: false, errorMessage: '' },
      showAddCareerPagePopup: false
    };

    this.team = React.createRef();
  }

  getPositionID() {
    const { pathname } = this.props;

    const positionID = /^\/company\/position\/[0-9]+/.test(pathname) && pathname.match(/\d+/)?.[0];

    return Number(positionID || -1);
  }

  async resumePosition() {
    const {
      profile,
      demoCompany,
      onPositionPause,
      history,
      pathname,
      toggleStartUsingCordPopup,
      bringTopNavForward
    } = this.props;
    const { changingJobStatus, showAddCareerPagePopup } = this.state;
    const { positions, inactivePlan } = profile;

    const positionID = this.getPositionID();
    const position = positions.find(({ id }) => id === positionID)!;

    if (position.members?.length === 0) {
      this.setState({ associatedPositionPopup: { show: true, type: 'noOneAssociated' } });
      bringTopNavForward(true);
      return;
    }

    if (inactivePlan && !position.externalURL && !showAddCareerPagePopup) {
      this.setState({ showAddCareerPagePopup: true });
      bringTopNavForward(true);
      return;
    }

    if (!changingJobStatus && !demoCompany) {
      segmentTrack('position_opened', {
        locationInProduct: 'position_page',
        listingID: position.id
      });

      this.setState({ changingJobStatus: true });

      const { status } = await fetcher.resumePosition(position.id);

      this.setState(({ associatedPositionPopup }) => ({
        changingJobStatus: false,
        jobStatusChanged: status === 'success',
        associatedPositionPopup: { ...associatedPositionPopup, show: false, loading: false },
        showAddCareerPagePopup: false
      }));
      bringTopNavForward(false);

      setTimeout(() => {
        if (status === 'success') {
          onPositionPause(position.id, true);

          if (!position.members.includes(profile.companyUser.accountID!)) {
            this.setState({ associatedPositionPopup: { show: true, type: 'userNotAssociated' } });
            bringTopNavForward(true);
          }
        }

        history.push(pathname.replace('edit', 'view'));

        this.setState({ jobStatusChanged: false });
      }, 400);
    } else if (demoCompany) toggleStartUsingCordPopup(true, { source: 'Activate position' });
  }

  async makePositionVisible(positionID: number, isAgency: boolean) {
    const { onPositionVisible, history, pathname, demoCompany, toggleStartUsingCordPopup } =
      this.props;

    if (!demoCompany) {
      segmentTrack('position_unhidden', {
        locationInProduct: 'position_page',
        listingID: positionID
      });
      this.setState({ changingJobStatus: true });

      const editStrategy = isAgency ? editAgencyPosition : fetcher.editPosition;
      const { status } = await editStrategy({ searchHidden: false }, positionID);

      this.setState({ changingJobStatus: false, jobStatusChanged: status === 'success' });

      setTimeout(() => {
        if (status === 'success') onPositionVisible(positionID);
        history.push(pathname.replace('edit', 'view'));

        this.setState({ jobStatusChanged: false });
      }, 400);
    } else toggleStartUsingCordPopup(true, { source: 'Make position visible' });
  }

  async saveHiringTeam(positionID: number, isAgency: boolean) {
    const team = this.team.current;
    const { members, primaryMember } = team.state;
    const { getCompanyData } = this.props;

    if (members.length === 0) {
      this.setState(({ associatedPositionPopup }) => ({
        associatedPositionPopup: {
          ...associatedPositionPopup,
          errorMessage: 'Please select at least one team member for this position.'
        }
      }));

      return;
    }

    this.setState(({ associatedPositionPopup }) => ({
      associatedPositionPopup: { ...associatedPositionPopup, loading: true }
    }));

    const editStrategy = isAgency ? editAgencyPosition : fetcher.editPosition;
    const { status, message } = await editStrategy({ members, primaryMember }, positionID);

    if (status === 'success') {
      await getCompanyData();
      await this.resumePosition();
    } else {
      this.setState(({ associatedPositionPopup }) => ({
        associatedPositionPopup: { ...associatedPositionPopup, errorMessage: message }
      }));
    }
  }

  associatePosition(positionID: number) {
    const { onMemberPositionsUpdate } = this.props;

    this.hideAssociatedPositionPopup();

    onMemberPositionsUpdate(positionID);
  }

  hideAssociatedPositionPopup() {
    const { bringTopNavForward } = this.props;

    this.setState(({ associatedPositionPopup }) => ({
      associatedPositionPopup: { ...associatedPositionPopup, show: false, errorMessage: '' }
    }));
    bringTopNavForward(false);
  }

  renderProfileTopNav = (profileType: $TSFixMe) => {
    const { pathname, demoCompany, darkMode, toggleStartUsingCordPopup } = this.props;
    const isEditPage = pathname.endsWith('edit');
    const buttonProps = {
      text: isEditPage ? 'View Profile' : 'Edit Profile',
      link: isEditPage ? pathname.replace(/\/edit/, '') : demoCompany ? '#' : `${pathname}/edit`,
      buttonStyle: darkMode ? 'stroke_white' : 'stroke_dark_blue',
      ...(!isEditPage && demoCompany
        ? {
            action: () =>
              toggleStartUsingCordPopup(true, {
                source: `Edit ${profileType === 'company' ? 'Company ' : ''}Profile`
              })
          }
        : {})
    };

    return (
      <div className="company_menu">
        <div className="right">
          <Button {...buttonProps} />
        </div>
      </div>
    );
  };

  renderActivatePositionButton = (position?: PositionInProfile | null) => {
    const { changingJobStatus, jobStatusChanged } = this.state;
    const { demoCompany, toggleStartUsingCordPopup, profile, history } = this.props;

    if (position?.status === 'archived') {
      const {
        positions,
        subscriptionDetailsAndPricing: { subscriptionPlan }
      } = profile;
      const planLimitReached = isPositionLimitReached(positions, subscriptionPlan);

      return (
        <Button
          text={jobStatusChanged ? 'Re-opened!' : 'Re-open Position'}
          buttonStyle="stroke_blue"
          action={
            demoCompany
              ? () => toggleStartUsingCordPopup(true, { source: 'Re-open Position' })
              : planLimitReached
              ? () => history.push('/pricing?trigger=position')
              : () => this.resumePosition()
          }
          loading={changingJobStatus}
        />
      );
    }

    return null;
  };

  renderPositionTopNav = () => {
    const { changingJobStatus, jobStatusChanged, associatedPositionPopup, showAddCareerPagePopup } =
      this.state;
    const { pathname, profile, demoCompany, darkMode, toggleStartUsingCordPopup } = this.props;
    const { show, type, loading = false, errorMessage = '' } = associatedPositionPopup;
    const { members, positions, companyUser, companyType } = profile;
    const positionID = this.getPositionID();
    const position = positions ? positions.find(({ id }) => id === positionID) : null;
    const { isAgency } = identifyCompanyType(companyType);

    return (
      <div className="company_menu">
        <div className={ClassNames('right', 'two_buttons_cont')}>
          {this.renderActivatePositionButton(position)}
          {position && position.status === 'active' && position.searchHidden && (
            <Button
              text={jobStatusChanged ? 'Done!' : 'Make Position Visible'}
              buttonStyle="stroke_blue"
              action={
                demoCompany
                  ? () => toggleStartUsingCordPopup(true, { source: 'Make Position Visible' })
                  : () => this.makePositionVisible(positionID, isAgency)
              }
              loading={changingJobStatus}
            />
          )}
          {pathname.endsWith('view') ? (
            <Button
              text="Edit Position"
              link={pathname.replace('view', 'edit')}
              buttonStyle={darkMode ? 'stroke_white' : 'stroke_dark_blue'}
              action={() =>
                segmentTrack('position_edit_page_viewed', {
                  locationInProduct: 'position_page',
                  listingID: positionID
                })
              }
            />
          ) : (
            <Button
              text="View Position"
              link={pathname.replace('edit', 'view')}
              buttonStyle={darkMode ? 'stroke_white' : 'stroke_dark_blue'}
            />
          )}
        </div>
        {show && (
          <DialogWindow
            id={type === 'noOneAssociated' ? 'hiring_team_popup' : 'user_associated_popup'}
            title={associatedPositionPopupDetails[type].title}
            subTitle={associatedPositionPopupDetails[type].subTitle}
            visible={show}
            loading={loading}
            showCloseIcon
            onClose={() => this.hideAssociatedPositionPopup()}
            primaryButton={
              type === 'noOneAssociated'
                ? {
                    text: 'Save & Open Position',
                    action: () => this.saveHiringTeam(positionID, isAgency)
                  }
                : { text: 'Join the hiring team', action: () => this.associatePosition(positionID) }
            }
            skipLink={
              type === 'noOneAssociated'
                ? undefined
                : { text: "No, I don't want to join the hiring team" }
            }
          >
            {type === 'noOneAssociated' && (
              <CompanyEditPositionHiringTeam
                key={position!.position}
                ref={this.team}
                allMembers={members}
                members={[companyUser.accountID!]}
                primaryMember={position!.primaryMember}
              />
            )}
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </DialogWindow>
        )}
        <AddCareerPagePopup
          show={showAddCareerPagePopup}
          positionID={positionID}
          resumePosition={() => this.resumePosition()}
        />
      </div>
    );
  };

  renderPositionsViewTopNav = () => {
    const { darkMode } = this.props;

    return (
      <div className="company_menu">
        <div className={ClassNames('right', 'two_buttons_cont')}>
          <Button
            text="Add position"
            link="/company/create-position"
            buttonStyle={darkMode ? 'stroke_white' : 'stroke_dark_blue'}
            icon="icon_add"
            action={() =>
              segmentTrack('add_position_clicked', {
                locationInProduct: 'positions_view'
              })
            }
          />
        </div>
      </div>
    );
  };

  render() {
    const { pathname, permissions, profile, darkMode, toggleReactivationModal, initialSetupMode } =
      this.props;
    const { companyUser = {}, inactivePlan } = profile;
    const isSubscriptionExpired = inactivePlan === CompanySubscriptionPlan.Expired;
    const isAccountSuspended = inactivePlan === CompanySubscriptionPlan.Suspended;

    const calendarConnected =
      companyUser.outlookCalendarStatus === 'active' ||
      companyUser.googleCalendarStatus === 'active';
    const isCompanyProfilePage = /^\/company\/profile/.test(pathname);
    const isMemberProfilePage = /^\/company\/member/.test(pathname);
    const isPositionPage =
      /^\/company\/position\/[0-9]+\//.test(pathname) &&
      (pathname.endsWith('edit') || permissions.editPositions);
    const isPositionsViewPage = /^\/company\/positions/.test(pathname);

    if (new RegExp('/company/discover').test(pathname)) {
      return <DiscoverMenu {...this.props} />;
    }

    if (new RegExp('/company/templates').test(pathname) && permissions.seeTemplates) {
      return (
        <div className="company_menu">
          <Suspense fallback="">
            <MultiStageToggle
              size="large"
              width="100px"
              stages={[
                { url: '/company/templates', name: 'Outreach', exact: true },
                { url: '/company/templates/accept', name: 'Accept' },
                { url: '/company/templates/decline', name: 'Decline' }
              ]}
            />
          </Suspense>
        </div>
      );
    }

    if (
      (new RegExp('/company/events/create').test(pathname) ||
        new RegExp('/company/events/edit/').test(pathname)) &&
      permissions.calendarIntegration
    ) {
      return (
        <div className="company_menu">
          <div className="right">
            <Button
              text="View Events"
              link="/company/events"
              buttonStyle={darkMode ? 'stroke_white' : 'stroke_dark_blue'}
            />
          </div>
        </div>
      );
    }

    if (
      new RegExp('/company/events').test(pathname) &&
      permissions.calendarIntegration &&
      calendarConnected
    ) {
      return (
        <div className="company_menu">
          <div className="right">
            <Button
              text="+ New Interview Event"
              link="/company/events/create"
              buttonStyle={darkMode ? 'stroke_white' : 'stroke_dark_blue'}
            />
          </div>
        </div>
      );
    }

    if (
      ((isCompanyProfilePage && permissions.editProfile) || isMemberProfilePage) &&
      !initialSetupMode
    ) {
      return this.renderProfileTopNav(isCompanyProfilePage ? 'company' : 'member');
    }

    if (isPositionPage && !initialSetupMode) {
      return this.renderPositionTopNav();
    }

    if (isPositionsViewPage && !initialSetupMode) {
      return this.renderPositionsViewTopNav();
    }

    if ((isSubscriptionExpired || isAccountSuspended) && !pathname.includes('/analytics')) {
      //Not render this in analytics because date pickers are visible
      return (
        <div className="company_menu">
          <div className="right">
            <Button
              text={
                isSubscriptionExpired ? 'Reactivate your subscription' : 'Unfreeze your account'
              }
              action={() => toggleReactivationModal()}
            />
          </div>
        </div>
      );
    }

    return <div />;
  }
}
