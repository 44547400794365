import React from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { Props } from './MultiStageToggle.helpers';
import MultiStageToggleItem from './components/MultiStageToggleItem';

export default function MultiStageToggle({ className, ...props }: Props) {
  const basic = props.type === 'basic_pills_toggle';
  const toggleClassName = ClassNames('multi_stage_toggle', className, { basic });
  props.type = props.type || 'links_toggle';

  if (props.type === 'links_toggle') {
    return (
      <ToggleWrapper className={toggleClassName}>
        {props.stages.map(stage => (
          <MultiStageToggleItem key={`${stage.name}`} {...props} {...stage} />
        ))}
      </ToggleWrapper>
    );
  }

  const Wrapper = props.type === 'text_toggle' ? ToggleWrapper : TogglePillsWrapper;

  return (
    <Wrapper className={toggleClassName}>
      {props.stages.map(stage => (
        <MultiStageToggleItem key={stage.name || stage.stage} {...props} {...stage} />
      ))}
    </Wrapper>
  );
}

const ToggleWrapper = styled.div`
  position: relative;
`;

const TogglePillsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  border-radius: 30px;
  width: fit-content;
  padding: 10px;
  gap: 15px;

  &.basic {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  ${({ theme: { darkMode, colours, media } }) => css`
    background-color: ${darkMode ? colours.darkBgColour : 'transparent'};
    box-shadow: 0px 0px 20px ${darkMode ? colours.darkDropshadow : colours.dropshadow};

    ${media.smallLaptop`
      justify-content: space-around;
    `}
  `}
`;
