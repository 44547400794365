import { ReactNode } from 'react';

type Props = {
  predicate: any;
  children: any;
  fallback?: ReactNode;
};

const ConditionalRenderPure: React.VFC<Props> = ({ predicate, children, fallback }) =>
  predicate ? children : fallback || null;

export default ConditionalRenderPure;
