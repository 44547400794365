import { useAppDispatch, useAppSelector } from 'store';

import { setShowHidePerStreamPrompt } from './discover';
import {
  selectAllSearchResultsCounts,
  selectCandidatePool,
  selectDiscoverLoading,
  selectSearchItems,
  selectShowHidePerStreamPrompt
} from './discover.selectors';

export const useHidePerStreamPrompt = () => {
  const dispatch = useAppDispatch();
  const showHidePerStream = useAppSelector(selectShowHidePerStreamPrompt);

  return {
    showHidePerStream,
    setShowHidePerStream: (show: boolean) => {
      dispatch(setShowHidePerStreamPrompt(show));
    }
  };
};

export const useCompanyDiscover = () => {
  const searchResultsCounts = useAppSelector(selectAllSearchResultsCounts);
  const searchItems = useAppSelector(selectSearchItems);
  const candidatePool = useAppSelector(selectCandidatePool);
  const loading = useAppSelector(selectDiscoverLoading);

  return { candidatePool, searchItems, searchResultsCounts, loading };
};
