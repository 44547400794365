const hasOwnProperty = Object.prototype.hasOwnProperty;

export type ReducerCallback<K extends keyof any, V, A, T> = T extends undefined
  ? (acc: A, value: V, key: K, object: Record<K, V>) => A
  : (this: T, acc: A, value: V, key: K, object: Record<K, V>) => A;

/**
 * Reduces an object's key-value pairs using a callback function and returns the accumulated or reduced value.
 *
 * @template K - The type of the keys in the input object.
 * @template V - The type of the values in the input object.
 * @template A - The type of the accumulator.
 * @template T - The type of the `this` context for the callback function (optional).
 * @param {Record<K, V> | null | undefined} object - The input object to be reduced.
 * @param {ReducerCallback<K, V, A, T>} callback - The reducer function that is called for each key-value pair in the object.
 * @param {A} [acc] - The initial accumulator value (optional). If not provided, an empty object is used.
 * @param {T} [context] - The `this` context for the callback function (optional).
 * @returns {A} - The reduced value which is the empty object if the input object is `null` or `undefined`.
 */
export function reduceObject<K extends keyof any, V, A, T extends undefined | {} = undefined>(
  object: Record<K, V> | null | undefined,
  callback: ReducerCallback<K, V, A, T>,
  acc = {} as A,
  context?: T
): A {
  if (!object) {
    return acc;
  }
  let result = acc;
  for (const name in object) {
    if (hasOwnProperty.call(object, name)) {
      result = (callback as Function).call(context, result, object[name], name, object);
    }
  }
  return result;
}
