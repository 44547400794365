import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { isEmpty } from 'utils';
import { getJobTitleOptions } from 'v2/services/fetchers/public/jobTitles';

export const fetchJobTitleOptions = createAppAsyncThunk(
  'common/fetchJobTitleOptions',
  async () => getJobTitleOptions(),
  {
    condition: (_, { getState }) => {
      const jobTitleOptions = getState().common.jobTitleOptions;

      return isEmpty(jobTitleOptions);
    }
  }
);
