import {
  CompanyType,
  CompanyTypeExtended,
  CompanyTypeSearchQueryParam
} from '@cohiretech/common-types';

const { Direct, External, Agency } = CompanyType;
const { All } = CompanyTypeExtended;

export const identifyCompanyType = (companyType?: CompanyType) => ({
  isDirect: companyType === Direct,
  isExternal: companyType === External,
  isAgency: companyType === Agency
});

export const COMPANY_TYPES = [Direct, External, Agency];

export const COMPANY_TYPE_WITH_ALL: CompanyTypeSearchQueryParam[] = [All, ...COMPANY_TYPES];

export const COMPANY_TYPE_ICON_MAP: { [key in CompanyTypeSearchQueryParam]: string } = {
  [Direct]: 'icon_company',
  [External]: 'icon_web_search',
  [Agency]: 'icon_handshake',
  [All]: 'icon_briefcase'
};
