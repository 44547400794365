import React, { useMemo } from 'react';

import { isEmpty } from 'utils';
import { pluck } from 'utils/object';
import {
  checkIfLastActiveFilter,
  checkIfRemoteFilter,
  checkIfSalaryFilter,
  checkIfWorkingTimezoneFilter,
  getSelectedLastActive
} from 'v2/services/tools/positionSearchFilter';
import { candidateSearchFilters } from 'fetcher';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';

import { FilterSelector, FilterSelectorProps } from 'v2/components/ui/styles/DropdownSelector';

import { SelectedItemsTooltip } from './SelectedItemsTooltip';
import {
  getRemoteLabel,
  getSelectorLabel,
  getWorkingTimezoneLabel
} from './FilterDropdownSelector.helpers';

const { seniority } = candidateSearchFilters;

export default function FilterDropdownSelector() {
  const {
    isOpen,
    operator,
    selectedItems,
    filterName,
    filterOptionsGroups,
    toggleFilterPanel,
    removeSearchItem
  } = useExpandableFilter();

  const filterOptions = filterOptionsGroups?.[0].options;
  const attr = selectedItems?.[0]?.attribute || operator.attribute || filterOptions?.[0].attribute;

  const getSelectedItemsLabel = () => {
    const count = selectedItems?.length;
    if (!count) return '';

    if (checkIfWorkingTimezoneFilter(attr)) return getWorkingTimezoneLabel(selectedItems);
    if (checkIfLastActiveFilter(attr)) return getSelectedLastActive(selectedItems)!;
    if (checkIfRemoteFilter(attr)) return getRemoteLabel(selectedItems);

    const isAllSelected = count === filterOptions?.length;
    if (isAllSelected) return `Any ${filterName.toLowerCase()}`;

    if (attr === seniority.attribute) return selectedItems.map(pluck('label')).join(', ');

    const initLabel = selectedItems[0].label;
    const labelSuffix = checkIfSalaryFilter(attr) ? '+' : count !== 1 && ` + ${count - 1} more`;
    return initLabel + (labelSuffix || '');
  };

  const filterSelectorProps: FilterSelectorProps = useMemo(() => {
    const selectedItemsLabel = getSelectedItemsLabel();
    const text = getSelectorLabel(filterName, selectedItemsLabel, attr);
    const hasSelection = !isEmpty(selectedItems);
    const icon = hasSelection ? 'icon_close' : `icon_simple_arrow_${isOpen ? 'up' : 'down'}`;

    return {
      text,
      icon,
      hasSelection,
      action: e => {
        if ((e?.target as Element).closest('.icon_close')) {
          removeSearchItem?.(attr!);
          return;
        }

        toggleFilterPanel();
      }
    };
  }, [selectedItems]);

  return (
    <FilterSelector
      {...filterSelectorProps}
      buttonStyle="stroke_dark_blue"
      iconPosition="right"
      className="filter_dropdown_selector"
      tooltip={<SelectedItemsTooltip />}
    />
  );
}
