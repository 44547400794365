import { css } from 'styled-components';

export const SelectOptionStyling = ({
  padding = '0.5rem',
  borderRadius = '0.5rem',
  iconSize = '1.125rem'
} = {}) => css`
  ${({ theme: { typography } }) => css`
    display: flex;
    align-items: center;
    padding: ${padding};
    border-radius: ${borderRadius};

    > [class*='icon_'] {
      font-size: ${iconSize};
    }

    &:hover,
    &:focus-visible,
    &.focused {
      background: var(--background-option-primary-hover);
    }

    &.selected,
    &.active {
      background: var(--background-option-primary-selected);
      color: var(--text-option-primary-selected);
      font-weight: ${typography.semibold};

      [class*='icon_'] {
        font-weight: ${typography.black};
      }
    }
  `}
`;

export const LeftIconSelectOptionStyling = ({
  padding = '0.5rem',
  borderRadius = '0.5rem',
  iconSize = '1.125rem'
}) => css`
  ${SelectOptionStyling({ padding, borderRadius, iconSize })}
  gap: 0.5rem;
`;
