/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import { PropertyChain } from 'types';
import { isObject, isFunction, ensureArrayForm } from 'utils/fn';

export function flatten<T = Record<string, any>>(obj: T, path: PropertyChain = []) {
  const result: Record<string, any> = {};
  path = ensureArrayForm(path);

  if (!isObject(obj)) return result;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newPath = path.concat(key);
      const value = obj[key];

      if (isObject(value)) {
        Object.assign(result, flatten(value, newPath));
      } else if (!isFunction(value)) {
        result[newPath.join('.')] = value;
      }
    }
  }

  return result;
}
