import { createAsyncThunk } from '@reduxjs/toolkit';

import { recordPWAEvent } from 'fetcher';
import { AppDispatch, RootState, ProfileState, selectProfile } from 'store';
import { getPWADisplayMode } from 'utils';
import { getAccountID } from 'v2/services/common';

export const recordPWAOpened = createAsyncThunk<
  void,
  ProfileState,
  { dispatch: AppDispatch; state: RootState }
>('services/common/recordPWAOpened', async (newProfile, { getState }) => {
  const state = getState();
  const profile = selectProfile(state);

  const userID = getAccountID(newProfile) || getAccountID(profile);
  if (!userID) return;

  await recordPWAEvent('PWA_OPENED', { userID });
});

export const checkPWAOpened = createAsyncThunk<
  void,
  ProfileState,
  { dispatch: AppDispatch; state: RootState }
>('services/common/checkPWAOpened', async (newProfile, { dispatch, getState }) => {
  const state = getState();
  const profile = selectProfile(state);
  if (profile && newProfile) return;

  const displayMode = getPWADisplayMode();

  if (displayMode === 'standalone') await dispatch(recordPWAOpened(newProfile));
});
