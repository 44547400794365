import React, { PropsWithChildren } from 'react';
import { NavLink as Link } from 'react-router-dom';
import ClassNames from 'classnames';

import './style.scss';

export type Props = {
  arrowPosition?: 'left' | 'right';
  to?: string | object;
  className?: string;
  absoluteURL?: string;
  onClick?: () => void;
};

function ArrowLink({
  arrowPosition = 'right',
  to,
  className,
  absoluteURL,
  onClick,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const getElementChildren = (): JSX.Element => {
    return (
      <>
        {arrowPosition === 'left' && <span className="icon_arrow_left" />}
        {children}
        {arrowPosition === 'right' && <span className="icon_arrow_right" />}
      </>
    );
  };

  if (absoluteURL) {
    return (
      <a
        className={ClassNames('arrow_link', className)}
        href={absoluteURL}
        onClick={onClick || (() => {})}
        aria-label={`Open ${children} in a new tab`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {getElementChildren()}
      </a>
    );
  } else if (to) {
    return (
      <Link className={ClassNames('arrow_link', className)} to={to} onClick={onClick || (() => {})}>
        {getElementChildren()}
      </Link>
    );
  } else {
    return (
      <button type="button" className={ClassNames('arrow_link', className)} onClick={onClick}>
        {getElementChildren()}
      </button>
    );
  }
}

export default ArrowLink;
