import styled, { css } from 'styled-components';

import BulletList, { Bullet } from 'components/bulletlist';

export const ESSUPageStyling = css`
  .left_column_content {
    flex-grow: 1;
    text-align: left;

    ${({ theme: { media } }) => media.mobileMin`
      padding-right: 2rem;
    `}
  }

  .page_section_title {
    margin-bottom: 0.75rem;
    padding: 0;
  }

  .page_section_subtitle {
    margin: 0.75rem auto 1.25rem;
    font-size: ${({ theme: { typography } }) => typography.header};
  }

  .company_logos_section {
    display: flex;
    gap: 0.75rem;

    ${({ theme: { media } }) => media.mobileMin`
      margin-top: 0;
    `}

    .company_logos_description {
      margin: 0;
    }

    &,
    .company_logos {
      padding: 0;
    }
  }

  // From this point, the value of flex-direction is row.
  ${({ theme: { media } }) => media.tabletMin`
    align-items: flex-start;
  `}

  ${({ theme: { media } }) => media.tabletLandscapeMin`
    align-items: unset;
  `}

  ${({ theme: { media, typography } }) => media.smallLaptopMin`  
    .page_section_title {
      font-size: ${typography.hugeheader};
      line-height: 4.75rem;
    }
  `}
`;

export const ESSUProgramList = styled(BulletList)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-bottom: 0;
  padding-right: 0;

  ${({ theme: { media } }) => media.mobileMin`
    margin-bottom: 2rem;
  `}
`;

export const ESSUProgram = styled(Bullet)`
  padding-bottom: 0;

  .bullet_content h3 {
    font-weight: ${({ theme: { typography } }) => typography.bold};

    ${({ theme: { media } }) => media.smallLaptopMin`
      font-size: 1.25rem;
    `}
  }
`;
