import styled, { css } from 'styled-components';

import { BubbleProps } from './Bubble';

type BubbleWrapProps = Pick<BubbleProps, 'show' | 'noPadding'>;

const ARROW_SIZE = '16px';
const DEFAULT_PADDING = '12px';

export const BubbleWrap = styled.div<BubbleWrapProps>`
  position: absolute;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  padding: ${({ noPadding }) => (noPadding ? '0' : DEFAULT_PADDING)};
  border-radius: 8px;
  background-color: ${({ theme: { colours, darkMode } }) =>
    darkMode ? colours.darkerBgColour : colours.white};

  ${({ theme: { colours, darkMode, fn } }) => css`
    color: ${({ theme: { colours, darkMode } }) =>
      darkMode ? colours.primaryColour : colours.fontColour};
    background-color: ${darkMode ? colours.darkerBgColour : colours.white};
    box-shadow: ${darkMode
      ? `0px 0 20px -2px ${fn.opaqueColour(colours.black, 0.3)}`
      : `0px 0px 24px ${fn.opaqueColour(colours.fontColour, 0.12)}`};
  `}

  ${({ show }) => css`
    opacity: ${show ? 1 : 0};
    pointer-events: ${show ? 'all' : 'none'};
  `}
`;

export const BubbleArrow = styled.div<Pick<BubbleProps, 'placement'>>`
  text-align: left;
  visibility: hidden;

  &,
  &::before {
    position: absolute;
    width: ${ARROW_SIZE};
    height: ${ARROW_SIZE};
    background: inherit;
  }

  &::before {
    content: '';
    visibility: visible;
    transform: rotate(45deg);
    border-radius: 2px;
  }

  ${({ placement, theme: { darkMode, colours } }) => {
    const shadowColor = darkMode ? colours.darkDropshadow : colours.primaryColourShadow;
    return css`
      ${placement?.startsWith('top') && ArrowBottom(shadowColor)};
      ${placement?.startsWith('bottom') && ArrowTop(shadowColor)};
      ${placement?.startsWith('left') && ArrowRight(shadowColor)};
      ${placement?.startsWith('right') && ArrowLeft(shadowColor)};
    `;
  }}
`;

const ArrowRight = (color: string) => css`
  right: calc(-${ARROW_SIZE} / 2);
  &::before {
    box-shadow: 5px -5px 10px -5px ${color};
  }
`;

const ArrowLeft = (color: string) => css`
  left: calc(-${ARROW_SIZE} / 2);
  &::before {
    box-shadow: -5px 5px 10px -5px ${color};
  }
`;

const ArrowTop = (color: string) => css`
  top: calc(-${ARROW_SIZE} / 2);
  &::before {
    box-shadow: -5px -5px 10px -5px ${color};
  }
`;

const ArrowBottom = (color: string) => css`
  bottom: calc(-${ARROW_SIZE} / 2);
  &::before {
    box-shadow: 5px 5px 10px -5px ${color};
  }
`;
