import React from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { SizeVariant } from 'types';

import { GradientText } from 'v2/components/ui/styles';
import { NoScrollbar } from 'v2/components/utility/Scroll';

type Props = {
  tags: string[] | (string | JSX.Element)[][];
  showAll?: boolean;
  showMax?: number;
  size?: SizeVariant;
  tagClassName?: string | string[];
  className?: string;
  colour?: 'darkblue' | 'purpleGradient' | 'cordGradient';
  fill?: boolean;
  noWrap?: boolean;
};

export default function Tags({
  tags,
  showAll = true,
  showMax = 8,
  size = 'medium',
  tagClassName,
  className,
  colour = 'darkblue',
  fill = false,
  noWrap
}: Props) {
  return (
    <TagsWrapper className={ClassNames('tags_wrapper', className)} size={size} noWrap={noWrap}>
      {showAll
        ? tags.map((tag, index) => (
            <Tag
              className={ClassNames('tag', tagClassName, { fill })}
              key={`${tag}_${index}`}
              size={size}
              colour={colour}
            >
              {Array.isArray(tag) ? tag.map(t => t) : tag}
            </Tag>
          ))
        : tags.slice(0, showMax).map((tag, index) => (
            <Tag
              className={ClassNames('tag', tagClassName, { fill })}
              key={`${tag}_${index}`}
              size={size}
              colour={colour}
            >
              {tag}
            </Tag>
          ))}
    </TagsWrapper>
  );
}

const LargeTag = css`
  padding: 0.5rem 0.75rem;
  font-size: ${({ theme: { typography } }) => typography.smallheader};
`;

const MediumTag = css`
  padding: 0.25rem 0.5rem;
  font-size: ${({ theme: { typography } }) => typography.normal};
`;

const SmallTag = css`
  padding: 0.25rem 0.5rem;
  font-size: ${({ theme: { typography } }) => typography.small};
`;

const XSmallTag = css`
  padding: 0.25rem 6px;
  font-size: ${({ theme: { typography } }) => typography.mobile};
`;

const TagsWrapper = styled.div<Pick<Props, 'size' | 'noWrap'>>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${props => (props?.size === 'large' ? '0.5rem' : '0.25rem')};
  margin-top: ${props => (props?.size === 'large' ? '1rem' : '0.25rem')};
  position: relative;

  ${props =>
    props?.noWrap &&
    css`
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: auto;
      scrollbar-width: 0.35rem;
      padding-bottom: 0.25rem;
      transition: all 0.5s ease-out;

      /* For Chrome, Safari, and Opera */
      ::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
      }

      /* Firefox scrollbar */
      /* Hide the scrollbar track and thumb */
      html {
        scrollbar-width: 0.4rem;
      }

      &:not(:hover) {
        ${NoScrollbar}
      }
    `}
`;

const Tag = styled.div.attrs((props: Pick<Props, 'size' | 'colour'>) => ({
  ...props,
  gradientTextColour: props.colour
}))`
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  border: 1px solid;
  border-radius: 20px;

  ${props => props?.size === 'medium' && MediumTag}
  ${props => props?.size === 'large' && LargeTag}

  ${props =>
    props?.size === 'small' &&
    css`
      ${SmallTag}

      [class^='icon'] {
        font-size: 14px;
        font-weight: bold;
      }
    `}

  ${props => props?.size === 'xsmall' && XSmallTag}

  ${props =>
    props?.colour === 'purpleGradient' &&
    css`
      border-color: ${({ theme }) => theme.colours.informationColour};

      &.fill {
        color: ${({ theme }) => theme.colours.informationColour};
        border-color: transparent;
        background: var(--gradient-secondary-transparent);
      }

      &:not(.fill) {
        ${GradientText}
      }
    `}

  ${props =>
    props?.colour === 'cordGradient' &&
    css`
      border-color: ${({ theme }) => theme.colours.primaryDarkShade};

      &.fill {
        color: ${({ theme }) => theme.colours.primaryDarkShade};
        border-color: transparent;
        background: var(--gradient-primary-transparent);
      }

      &:not(.fill) {
        ${GradientText}
      }
    `}
`;
