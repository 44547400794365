/**
 * Iterates over the properties of an object, applies the provided callback function to each value,
 * and returns an array of the transformed values.
 *
 * @template T The type of the input object.
 * @template U The type of the transformed values.
 * @param {T} object The input object to map.
 * @param {(value: T[keyof T], key: keyof T, object: T) => U} callback The function to apply to each value in the object.
 * @param {unknown} [context] An optional context to bind the callback function to.
 * @returns {U[]} An array of the transformed values.
 *
 * @example
 * const object = { a: 1, b: 2, c: 3 };
 * const callback = (value) => value * 2;
 * const result = mapObject(object, callback); // [2, 4, 6]
 */
export function mapObject<T extends object, U>(
  object: T,
  callback: (value: T[keyof T], key: keyof T, object: T) => U,
  context?: unknown
): U[] {
  if (!object) {
    return [];
  }
  const result: U[] = [];
  for (const name in object) {
    if (Object.prototype.hasOwnProperty.call(object, name)) {
      result.push(callback.call(context, object[name], name, object));
    }
  }
  return result;
}
