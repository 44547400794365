import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router';
import { Location } from 'history';
import ClassNames from 'classnames';

import { PricingSubscriptionPlan } from '@cohiretech/common-types';

import { CompanyProfile, PricingCountry, SubscriptionPlan } from 'types';
import { getCompanyReactivationPlan } from 'fetcher';
import { getQueryStringObject } from 'utils';
import {
  isPositionLimitReached,
  getPricingComponent,
  checkIfSubscriptionExpired
} from 'utils/companyUtils';
import { checkIfInactivePlan } from 'v2/services/company/pricing';
import { useDarkMode } from 'hooks/useDarkMode';
import { ProfileState, useCompanyType } from 'store';

import Seo from 'components/seo';
import Loader from 'components/loader';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type Props = {
  location: Location;
  profile: ProfileState;
  demoCompany?: boolean;
  pricingPortal?: boolean;
};

export default function Pricing({ location, profile, demoCompany, pricingPortal }: Props) {
  /** Agencies don't have any pricing/subscription updates so we exit/redirect early */
  const { isAgency } = useCompanyType();
  if (isAgency) return <Redirect to="/company/discover" />;

  /** For everyone else... */
  const {
    positions = [],
    subscriptionDetailsAndPricing,
    signupDate
  } = (profile as CompanyProfile) || {};
  const { subscription, subscriptionPlan, pricing } = subscriptionDetailsAndPricing || {};
  const currentPlan = subscriptionPlan?.plan as SubscriptionPlan | undefined;
  const { urlPath, billingFrequency, mode } = pricing || {};
  const pricingCountry = subscription?.pricingCountry as PricingCountry | undefined;
  const hasDiscount = pricingCountry ? pricing?.[pricingCountry]?.hasDiscount : undefined;
  const { isUnpaidAccount } = checkIfInactivePlan(profile, !!demoCompany) || {};

  const darkMode = useDarkMode();
  const [title, setTitle] = useState('');
  const [previousPlan, setPreviousPlan] = useState<SubscriptionPlan>();
  const [reactivationOptions, setReactivationOptions] = useState<PricingSubscriptionPlan[]>([]);

  const PricingComponent = useRef<any>();
  const Pricing = PricingComponent.current;

  useEffect(() => {
    document.body.classList.add('public');
    setPricingComponent();
  }, []);

  const setPricingComponent = async () => {
    let plan = currentPlan;

    if (checkIfSubscriptionExpired(plan)) plan = (await getPreviousPlan()) || plan;

    const { component, title } = getPricingComponent({
      plan,
      isToUpgrade: positionLimitReached && upgradeTrigger
    });

    PricingComponent.current = component;
    setTitle(title);
  };

  const getPreviousPlan = async () => {
    const { subscriptionPricing } = await getCompanyReactivationPlan();
    const { previousSubscriptionPlan, availablePlans } = subscriptionPricing;

    setPreviousPlan(previousSubscriptionPlan?.plan);
    setReactivationOptions(availablePlans);

    return previousSubscriptionPlan?.plan;
  };
  const positionLimitReached = isPositionLimitReached(positions, subscriptionPlan);
  const upgradeTrigger = getQueryStringObject(location?.search || '')?.trigger || '';
  const currentPlanDetails = currentPlan && {
    plan: currentPlan,
    billingFrequency,
    urlPath,
    hasDiscount,
    mode,
    signupDate,
    isUnpaidAccount
  };

  return (
    <div id="startups">
      <ConditionalRender
        predicate={!!Pricing}
        fallback={<Loader className={ClassNames('medium', { dark: darkMode })} />}
      >
        <Pricing
          title={title}
          location={location}
          showCharitySection
          showVideoSection
          showBookADemo
          showStorySection
          darkMode={darkMode}
          pricingCountry={pricingCountry}
          previousPlan={previousPlan}
          availablePlans={reactivationOptions}
          currentPlanDetails={currentPlanDetails}
          demoCompany={demoCompany}
          pricingPortal={pricingPortal}
        />
      </ConditionalRender>
      <Seo
        title={`Pricing - ${title}`}
        description="No long term commitments. Pay monthly users can cancel or pause their account at anytime."
        path={location.pathname}
        contentType="website"
        image="https://assets.co-hire.com/make-direct-hires.png"
        robots={pricingPortal ? 'none' : undefined}
      />
    </div>
  );
}
