import React, { useState } from 'react';
import styled from 'styled-components';

import { PricingOptionType } from 'types';

import ProductScreenPopup from 'components/productscreenpopup';
import Tooltip from 'components/tooltip';

import typography from 'css/base/_typography.module.scss';

type Props = {
  feature: PricingOptionType['features'][number];
};

export default function PricingPlanFeature({ feature }: Props) {
  const [showPopup, setShowPopup] = useState(false);

  const { name, tooltip, popup } = feature;

  return (
    <PlanFeature key={name} className="feature_item" role="listitem">
      <span className="icon_tick" />
      <span className="feature_name" {...(popup ? { onClick: () => setShowPopup(true) } : {})}>
        {name}
        <Tooltip text={tooltip.text} width={tooltip.wider ? '205px' : '170px'} />
        {popup && <span className="icon_image" />}
      </span>
      <ProductScreenPopup
        visible={showPopup}
        title={name}
        subtitle={popup?.subtitle || ''}
        image={{ fileName: popup?.image || '', alt: name }}
        onClose={() => setShowPopup(false)}
      />
    </PlanFeature>
  );
}

const PlanFeature = styled.div`
  margin: 15px 0;
  overflow-x: visible;

  .icon_tick {
    font-size: ${typography.smallheader};
    vertical-align: middle;
    margin-right: 12px;
  }

  .feature_name {
    position: relative;

    .tooltip_wrapper {
      left: 0%;
      transform: none;
    }

    .icon_image {
      opacity: 0;
      position: absolute;
      right: -30px;
      vertical-align: middle;
    }

    &:hover .icon_image {
      opacity: 1;
    }
  }
`;
