import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

export type Props = {
  tagline: {
    icon: { name: string; src?: string };
    text: ReactNode;
  };
  heading: string;
  subheading: string;
  description?: ReactNode;
};

export default function IntroWithTagline({
  tagline: { icon, text },
  heading,
  subheading,
  description
}: Props) {
  return (
    <div className="intro_wrapper">
      <TaglineCont>
        <ConditionalRender predicate={icon.src} fallback={<span className={icon.name} />}>
          <img src={icon.src} alt={icon.name} aria-hidden="true" />
        </ConditionalRender>
        <div className="tagline uppercase">{text}</div>
      </TaglineCont>
      <Heading className="heading">{heading}</Heading>
      <Subheading className="subheading">{subheading}</Subheading>
      {description}
    </div>
  );
}

const TaglineCont = styled.div`
  margin-bottom: 0.5rem;

  ${({ theme: { media, colours, typography } }) => css`
    color: ${colours.primaryColour};
    font-size: ${typography.smallheader};
    font-weight: ${typography.bold};

    [class^='icon_'] {
      font-size: ${typography.smallheader};
      font-weight: ${typography.black};
    }

    ${media.mobile`
      font-size: ${typography.small};
    `}
  `}

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  img,
  [class^='icon_'] {
    margin-right: 0.5rem;
  }

  .tagline {
    display: inline-block;
  }
`;

const Heading = styled.h1`
  font-size: 60px;
  line-height: 1.3;

  ${({ theme: { media } }) => media.mobile`
    font-size: 38px;
  `}
`;

const Subheading = styled.h2`
  margin: 0.5rem auto 0.75rem;
`;
