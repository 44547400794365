import React from 'react';
import styled, { css } from 'styled-components';
import ClassNames from 'classnames';
import { NavLink as Link } from 'react-router-dom';
import { Location } from 'history';

import { Testimonial } from 'types';
import { getMonthAndYear, generateCompanyURL } from 'utils';
import { isAgenciesPage, isCompaniesLandingPage } from 'utils/url';
import { useDarkMode } from 'hooks/useDarkMode';
import { mobile, tabletLandscape } from 'responsiveConfig';

import Avatar from 'v2/components/ui/atoms/Avatar';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

type Props = Testimonial & {
  companyLogoURL?: string;
  itemWidth?: string;
  testimonialType?: 'candidate' | 'company';
  itemColumns?: number;
  slider?: boolean;
  location: Location;
  className?: string;
};

export default function TestimonialItem({
  firstName,
  lastName,
  photoURL,
  companyLogo,
  companyLogoURL,
  companyID,
  companyName,
  jobTitle,
  testimonial,
  createDate,
  itemWidth,
  testimonialType,
  itemColumns,
  slider,
  location,
  className
}: Props) {
  const isCandidateTestimonial = testimonialType === 'candidate';
  const displayedName = isCandidateTestimonial ? firstName : `${firstName} ${lastName}`;
  const isFeaturedQuote =
    isCompaniesLandingPage(location.pathname) || isAgenciesPage(location.pathname);
  const showPersonPictureOnly = isCandidateTestimonial || isFeaturedQuote;
  const darkMode = useDarkMode();

  const getCompanyName = () => {
    const companyURL =
      companyName && companyID && !isFeaturedQuote
        ? generateCompanyURL(location, { companyName, companyID })
        : '';

    if (companyURL) {
      return (
        <Link key="company_link" className="company_name link" to={companyURL}>
          {companyName}
        </Link>
      );
    }

    return companyName;
  };

  const renderCompanyName = () => {
    if (!isCandidateTestimonial) {
      const displayedCompanyName = getCompanyName();

      if (displayedCompanyName) return [' at ', displayedCompanyName];
    }

    return null;
  };

  return (
    <Item
      className={ClassNames('item', 'testimonial_item', className, {
        dark: darkMode,
        two_cols_item: itemColumns === 2,
        three_cols_item: itemColumns === 3,
        slider_item: slider
      })}
      itemWidth={itemWidth}
    >
      <ItemWrapper isFeaturedQuote={isFeaturedQuote} className="testimonial_item_content_wrapper">
        <Person className="person_details">
          <div className="photos_wrapper">
            {showPersonPictureOnly ? (
              <Avatar
                className="person_picture"
                width={50}
                photoURL={photoURL}
                firstname={firstName}
              />
            ) : (
              <>
                <Avatar
                  className="person_picture"
                  width={50}
                  photoURL={photoURL}
                  firstname={firstName}
                  lastname={lastName}
                />
                <Avatar
                  className="company_logo"
                  width={50}
                  photoURL={companyLogo}
                  firstname={companyName}
                />
              </>
            )}
          </div>
          <div className={ClassNames('info', { company_info: !isCandidateTestimonial })}>
            <h3 className="name">{displayedName}</h3>
            <div className="job_title">
              {jobTitle}
              {renderCompanyName()}
            </div>
          </div>
        </Person>
        <div className="testimonial">{testimonial}</div>
        <ConditionalRender
          predicate={isFeaturedQuote}
          fallback={<div className="date">{getMonthAndYear(createDate)}</div>}
        >
          <div className="company_logo_wrapper">
            <img
              src={companyLogo || companyLogoURL}
              alt={companyName}
              className="company_logo"
              loading="lazy"
            />
          </div>
        </ConditionalRender>
      </ItemWrapper>
    </Item>
  );
}

const FeaturedQuoteWrapperStyling = css`
  min-height: 280px;
  flex-direction: column-reverse;
  align-items: start;

  .person_details {
    width: 100%;
    margin: auto 0 0;

    img {
      filter: grayscale(100%);
    }

    .company_info {
      width: calc(100% - 60px);
    }
  }

  .company_logo_wrapper {
    height: 40px;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    .company_logo {
      max-height: 100%;
    }
  }

  .testimonial {
    font-style: italic;
  }
`;

const Person = styled.div`
  margin-bottom: 15px;

  .photos_wrapper {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;

    .company_logo {
      margin-left: -15px;
    }
  }

  .info {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 60px);

    &.company_info {
      width: calc(100% - 95px);
    }

    .name {
      margin: 0 0 2px;
    }

    .name,
    .job_title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const ItemWrapper = styled.div<{ isFeaturedQuote: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 260px;

  ${({ isFeaturedQuote }) => isFeaturedQuote && FeaturedQuoteWrapperStyling}
`;

const Item = styled.div<{ itemWidth?: number | string }>`
  position: relative;
  padding: 1.75rem;
  border-radius: 20px;
  background-color: ${colours.lightBgColour};
  display: inline-block;
  box-sizing: border-box;
  margin: 10px 0 30px;
  vertical-align: top;
  text-align: left;

  ${({ itemWidth }) =>
    itemWidth &&
    css`
      width: ${itemWidth}%;
      min-width: ${itemWidth}%;
    `}

  &.dark {
    background-color: ${colours.darkBgColour};
  }

  .testimonial {
    white-space: initial;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .date {
    color: var(--text-body-tertiary);
    margin-top: auto;
  }

  &.two_cols_item {
    width: 48%;
    margin: 1% 0 3% 4%;

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }

  &.three_cols_item {
    width: 31.33%;
    margin: 2% 0 2% 3%;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    @media only screen and (max-width: ${tabletLandscape}) {
      width: 48%;
      margin: 2% 0 2% 4%;

      &:nth-child(3n + 1) {
        margin-left: 4%;
      }

      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }

    @media only screen and (max-width: ${mobile}) {
      display: block;
      width: 100%;
      margin: 15px 0;

      &:nth-child(3n + 1),
      &:nth-child(2n + 1) {
        margin: 15px 0;
      }
    }
  }

  @media only screen and (max-width: ${mobile}) {
    display: block;
    width: 100%;
    margin: 15px 0;
    padding: 20px;
    min-height: unset;

    .date {
      position: relative;
      bottom: 0;
    }
  }

  &.slider_item {
    margin: 0 4% 15px 0;
    display: inline-block;
  }
`;
