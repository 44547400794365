import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import ClassNames from 'classnames';

import { CompanyStoryOrderBy, PublicStoryDTO } from '@cohiretech/common-types';

import { isEmpty } from 'utils';
import { addDimensionsOnImages, storiesImageParams } from 'v2/services/image';
import { getCompanyStories } from 'v2/services/fetchers/public/companyStories';
import { getCompanyStoryLocationName } from 'v2/services/common';
import useLocalisation from 'hooks/useLocalisation';
import { toArray } from 'utils/array';

import Loader from 'components/loader';
import PageSection from 'components/pagesection';
import StoryCarousel from 'components/storycarousel';
import MultiStageToggle from 'components/multistagetoggle';
import { Props as ButtonProps } from 'components/button';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import PulseIcon from 'images/dot-pulse-animation.svg';

import { StoryStage, STORY_TABS, getParams } from './tabs';

type Props = {
  darkMode: boolean;
} & Pick<RouteComponentProps, 'history' | 'location'>;

const SourcingStories = ({ darkMode, history, location }: Props) => {
  const [activeTab, setActiveTab] = React.useState(STORY_TABS[0]);
  const [stories, setStories] = useState<PublicStoryDTO[]>([]);
  const button = getButtonProps();
  const arrowLink = getArrowLink({ history });
  const localisationCountry = useLocalisation();

  const handleActiveTab = useCallback((stage: StoryStage) => {
    const tab = STORY_TABS.find(tab => tab.stage === stage);
    if (tab) setActiveTab(tab);
    if (stage === 'all') history.push('/stories');
  }, []);

  useEffect(() => {
    async function fetchStories() {
      const stage = activeTab.stage;
      if (stage === 'all') return;

      const locations = toArray(getCompanyStoryLocationName(localisationCountry));
      const { data = [] } = await getCompanyStories({
        ...getParams(stage),
        type: 'all',
        containsHighlights: true,
        orderBy: CompanyStoryOrderBy.PriorityScore,
        locations
      });

      const storiesWithResizedImages = data?.map((story: PublicStoryDTO) =>
        addDimensionsOnImages(story, storiesImageParams())
      );

      setStories(storiesWithResizedImages || []);
    }
    fetchStories();
  }, [activeTab]);

  return (
    <PageSection
      widthClassName="page_view"
      className="center_alignment"
      button={button}
      arrowLink={arrowLink}
    >
      <h2 className="page_section_title">
        The hiring tool behind <br /> high-growth companies
      </h2>
      <ConditionalRender
        predicate={!isEmpty(stories)}
        fallback={<Loader className={ClassNames('medium', { dark: darkMode })} />}
      >
        <MultiStageToggle
          type="pills_toggle"
          currentStage={activeTab.stage}
          stages={STORY_TABS}
          onStageSelect={handleActiveTab}
        />
        <StoryCarousel
          stories={stories.slice(0, 6) as PublicStoryDTO[]}
          history={history}
          location={location}
          darkMode={darkMode}
        />
      </ConditionalRender>
    </PageSection>
  );
};

const getArrowLink = ({ history }: Pick<RouteComponentProps, 'history'>) => ({
  onClick: () => history.push('/hires'),
  children: (
    <span className="story_arrow_link">
      <img src={PulseIcon} alt="Pulse Icon" />
      &nbsp; Live hires
    </span>
  )
});

const getButtonProps = (): ButtonProps => ({
  text: 'See 160+ customer stories',
  className: 'twenty_px_font story_button',
  link: '/stories',
  icon: 'icon_video',
  iconPosition: 'left'
});

export default SourcingStories;
