/**
 * @param {dateObject} date
 * @returns {string} "10th of June"
 */

import { getOrdinalSuffixFromDay } from './getOrdinalSuffixFromDay';

/* istanbul ignore next */
export const getPrettyDayAndMonthString = (date: Date | string = new Date()) => {
  if (date === 'N/A') return 'N/A';

  const dateObj = new Date(date);

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString('en-GB', { month: 'long' });

  return `${day}${getOrdinalSuffixFromDay(day)} of ${month}`;
};
