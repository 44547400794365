import React, { ComponentType, PropsWithChildren, HTMLProps } from 'react';
import ClassNames from 'classnames';
import { NavLink as Link, NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { SearchResponseListing, UserType } from '@cohiretech/common-types';

import { Dimensions } from 'types';
import { useDarkMode } from 'hooks/useDarkMode';
import { getInitials } from 'utils';
import { getResizedImageURL } from 'v2/services/image';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { useUserType } from 'store';

import ActiveBubble from 'components/activebubble';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

import Invisible from 'images/invisible.svg';
import { ReactComponent as RecommendedBadge } from 'images/recommended-badge-simple.svg';

import { RecommendedBadgeWrapper } from './Avatar.styled';

/**
 * Previous ResponsiveRoundPhoto which was combined with this component required both properties
 * width & mobileWidth in string. To make sure this works correctly, types are more specified.
 */
type MobileWidth = { mobileWidth: string };
type Widths =
  | ({ width: string } & MobileWidth)
  | ({ width: string | number } & Partial<MobileWidth>); // JY: Should check how adding string affects

export type AvatarProps = PropsWithChildren<
  {
    photoURL?: string | null;
    alt?: string;
    firstname?: string | null;
    lastname?: string;
    loading?: boolean;
    photoLink?: string;
    linkTitle?: string;
    onClick?: HTMLProps<HTMLDivElement>['onClick'];
    fallbackPhotoURL?: string;
    className?: string;
    blurred?: boolean;
    hidden?: boolean;
    resizePhotoDimensions?: Dimensions;
    lastActive?: Date | string;
    recommended?: boolean;
  } & Widths
>;

type StyledProps = Pick<AvatarProps, 'width' | 'blurred'> & { isResponsive?: boolean };
type PhotoWrapperType<C> = ComponentType<
  (C extends string ? NavLinkProps : HTMLProps<HTMLDivElement>) & StyledProps
>;

const getWidthValue = (width: AvatarProps['width']) => {
  if (typeof width === 'string' && width.endsWith('%')) return width;
  return `${width}px`;
};

function Avatar({
  photoURL,
  alt,
  firstname,
  lastname = '',
  loading,
  width,
  mobileWidth,
  photoLink,
  linkTitle,
  onClick,
  fallbackPhotoURL,
  className,
  children,
  blurred,
  hidden,
  resizePhotoDimensions,
  lastActive,
  recommended
}: AvatarProps) {
  const { isCandidate } = useUserType();
  // mobileWidth was used in the ResponsiveRoundPhoto which was combined with this component.
  const isResponsive = !!mobileWidth;
  const isMobile = isResponsive ? useMedia([mediaQueries.mobile]) : false;
  const responsiveWidth = isMobile ? mobileWidth! : getWidthValue(width);
  const commonClass = ClassNames('profile_picture', className);

  const PhotoWrapper: PhotoWrapperType<typeof photoLink> = photoLink ? LinkedPhoto : Photo;
  const darkMode = useDarkMode();
  const fullName = firstname ? `${firstname}${lastname ? ` ${lastname}` : ''}` : lastname;
  const initials = getInitials(firstname, lastname);
  const resizedPhotoURL = resizePhotoDimensions
    ? getResizedImageURL(photoURL || '', resizePhotoDimensions)
    : photoURL;

  if (loading) {
    return (
      <Photo className={ClassNames('loading_profile_picture', commonClass)} width={responsiveWidth}>
        <Skeleton circle width={width} height={width} duration={2} />
      </Photo>
    );
  }

  return (
    <PhotoWrapper
      className={ClassNames(commonClass, { no_photo: !photoURL, dark: darkMode })}
      width={responsiveWidth}
      blurred={blurred}
      isResponsive={isResponsive}
      {...(photoLink
        ? { to: photoLink, title: linkTitle || `View ${fullName}`, onClick }
        : { onClick })}
    >
      <ConditionalRender
        predicate={resizedPhotoURL || fallbackPhotoURL}
        fallback={<Initials className="profile_picture_initials">{initials}</Initials>}
      >
        <img
          src={resizedPhotoURL || fallbackPhotoURL}
          className={ClassNames({ blurred }, 'avatar_img')}
          alt={alt || `${fullName} on cord`}
          loading="lazy"
          {...(isResponsive ? {} : { width, height: width })}
        />
        {blurred && <Initials className="profile_picture_initials">{initials}</Initials>}
        {/* WIP: The condition for <Photo> to render <Hidden> was blurred && hidden */}
        {hidden && <OverlayIcon src={Invisible} alt="Hidden" />}
      </ConditionalRender>
      {children}
      <ConditionalRender
        predicate={!isCandidate && recommended}
        fallback={lastActive && <ActiveBubble lastActive={lastActive as string} />}
      >
        <RecommendedBadgeWrapper className="recommended_badge_wrapper">
          <RecommendedBadge className="recommended_badge" />
          <Tooltip text="Recommended by cord" position="right" />
        </RecommendedBadgeWrapper>
      </ConditionalRender>
    </PhotoWrapper>
  );
}

export default Avatar;

type AvatarType = UserType.Company | UserType.CompanyAccount;

type PartialListing = Partial<Omit<SearchResponseListing, 'associatedMemberDetails'>> & {
  associatedMemberDetails: Partial<SearchResponseListing['associatedMemberDetails']>;
};

export type GetAvatarPropsParams = {
  type: AvatarType;
  listing: PartialListing;
  defaultProps?: { [key in AvatarType]?: Partial<AvatarProps> };
};

export const getAvatarPropsFromListing = ({
  type,
  listing: { companyLogo, companyName, associatedMemberDetails },
  defaultProps
}: GetAvatarPropsParams): Partial<AvatarProps> => {
  if (type === UserType.Company) {
    return { ...defaultProps?.[type], photoURL: companyLogo, alt: `${companyName} logo` };
  }

  const { photoURL, memberName, lastActive } = associatedMemberDetails || {};
  return { ...defaultProps?.[type], photoURL, firstname: memberName, lastActive };
};

const styles = css<StyledProps>`
  position: relative;
  display: inline-block;
  border-radius: 44%;
  background-color: ${({ theme: { darkMode } }) => (darkMode ? colours.darkerBgColour : 'white')};
  vertical-align: middle;
  text-align: center;
  width: ${({ width }) => width};

  ${({ blurred }) =>
    blurred &&
    css`
      overflow: hidden;
    `}

  .avatar_img {
    width: 100%;
    height: 100%;
    border-radius: 44%;
    object-fit: cover;
    background-color: transparent;
  }

  ${({ width, isResponsive }) =>
    isResponsive
      ? css`
          .avatar_img {
            position: absolute;
            top: 0;
            left: 0;
          }

          &:after {
            display: block;
            content: '';
            padding-bottom: 100%;
          }
        `
      : css`
          height: ${width};
        `}

  &.no_photo {
    background-color: var(--background-avatar-blank);
  }
`;

const Photo = styled.div<StyledProps>`
  ${styles}

  &.company_logo .avatar_img {
    object-fit: contain;
  }

  &.loading_profile_picture {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .blurred {
    filter: blur(10px);
    opacity: 0.45;
  }
`;

const CleanLink = ({ isResponsive, ...props }: StyledProps & NavLinkProps) => <Link {...props} />;

const LinkedPhoto = styled(CleanLink)<StyledProps>`
  ${styles}
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &.company_logo {
    &,
    .avatar_img {
      border-radius: 50%;
    }
  }

  &.company_logo .avatar_img {
    object-fit: contain;
  }

  &:hover {
    box-shadow: 0 0 20px -5px ${({ theme: { darkMode } }) => (darkMode ? colours.darkDropshadow : 'rgba(49, 71, 95, 0.3)')};
  }

  &.no_photo {
    border: 0;
  }
`;

const Initials = styled.span`
  font-weight: ${({ theme: { typography } }) => typography.bold};
  opacity: 0.3;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-body-tertiary);
`;

const OverlayIcon = styled.img`
  opacity: 0.2;
  position: absolute;
  width: 40%;
  height: 40%;
  left: 30%;
  top: 30%;
  object-fit: contain;
  z-index: 1;
`;
