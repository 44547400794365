import styled, { css } from 'styled-components';
import { CSSProperties } from 'react';

import { TooltipWrapper } from 'components/tooltip';

import { Ellipsis } from 'v2/components/ui/styles';

export const DeleteIcon = styled(TooltipWrapper)`
  &.icon_delete {
    ${({ theme: { typography } }) => css`
      display: none;
      color: var(--text-body-tertiary);
      font-size: ${typography.smallheader};
      font-weight: ${typography.black};

      &:hover {
        color: var(--background-accent-negative-base);
      }
    `};
  }
`;

export const SearchOptionButton = styled.button<{
  selected: boolean;
  sidePadding: CSSProperties['padding'];
}>`
  width: 100%;
  padding: 0.5rem ${({ sidePadding }) => sidePadding};
  border-radius: 0.5rem;
  background: ${({ selected }) =>
    selected ? 'var(--background-searchoption-selected)' : 'var(--background-searchoption-base)'};

  &:hover,
  &.focused {
    background: var(--background-searchoption-hover);

    ${DeleteIcon} {
      display: inline;
    }
  }
`;

export const SearchOptionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  min-width: 0;

  [class^='icon_'] {
    font-size: ${({ theme: { typography } }) => typography.smallheader};
    font-weight: ${({ theme: { typography } }) => typography.semibold};

    &.border {
      padding: ${({ theme: { typography } }) => `calc((2.25rem - ${typography.smallheader}) / 2)`};
      border-radius: 1rem;
      border: 0.5px solid var(--border-divider-soft);
      background: var(--background-block-primary);
    }
  }

  [class^='icon_'],
  .profile_picture {
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .profile_picture {
    border: 0.5px solid var(--border-divider-soft);
  }
`;

export const LabelWrapper = styled.div<{ fullWidth: boolean; bold: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  min-width: 0;

  ${({ fullWidth, bold, theme: { typography } }) => css`
    .search_option_label {
      ${Ellipsis}
      width: ${fullWidth ? '100%' : 'unset'};
      font-weight: ${bold ? typography.semibold : typography.regular};
    }

    .search_option_description {
      color: var(--text-body-secondary);
      font-size: ${typography.small};
    }
  `}
`;

export const Count = styled.span`
  color: var(--text-body-tertiary);
`;
