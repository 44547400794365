import styled, { css } from 'styled-components';

import Checkbox from 'components/checkbox';
import { ExpandableFilterProps } from 'components/expandablefilter/ExpandableFilter.helpers';

import { BoldText } from 'v2/components/ui/styles';

import { CheckboxOptionsProps } from './CheckboxOption';

const CHECKBOX_OPTION_HEIGHT = '35px';

type StyledProps = CheckboxOptionsProps & {
  showUpTo: number;
  isExpanded?: boolean;
  appearance?: ExpandableFilterProps['appearance'];
};

export const OptionsTitle = styled(BoldText)`
  padding-left: 0.5rem;
`;

export const OptionCont = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ appearance }) =>
    appearance !== 'filterTagDropdown' ? '0.5rem' : '0.25rem'};
  transition: max-height 0.2s ease-in-out;

  ${({ showUpTo, isExpandable, isExpanded, isSelectionOnly }) => {
    if (isSelectionOnly) {
      return css`
        border-bottom: 1px solid var(--border-divider-soft);
      `;
    }

    return css`
      max-height: ${`calc(${CHECKBOX_OPTION_HEIGHT} * ${isExpanded ? showUpTo * 3 : showUpTo})`};
      overflow: ${!isExpandable || isExpanded ? 'auto' : 'hidden'};

      ::-webkit-scrollbar {
        width: 6px;
      }
    `;
  }}
`;

export const OperatorToggleCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem;
  font-size: var(--type-sm);
`;

export const Option = styled(Checkbox)<{ appearance?: ExpandableFilterProps['appearance'] }>`
  padding: 0.5rem;
  border-radius: 0.5rem;
  ${({ appearance }) =>
    appearance !== 'filterTagDropdown' &&
    css`
      margin-bottom: -0.25rem;
    `}

  input[type='checkbox'].checkbox_input,
  .icon_tick {
    left: 0.5rem;
  }

  label {
    line-height: normal;

    &:has(span.description) {
      max-width: 100%;
      box-sizing: border-box;

      span.label {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  ${({ checked }) =>
    checked &&
    css`
      &.bold {
        padding: 0.5rem;
      }
    `}

  &:hover {
    background: var(--background-option-primary-hover);
  }
`;
