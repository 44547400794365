import React, { useEffect, useState, PropsWithChildren } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { StringOrElements } from 'types';

import colors from 'css/base/_colours.module.scss';

export type Props = {
  className?: string;
  text?: StringOrElements;
  appearance?: 'info_light' | 'info' | 'warning' | 'error';
  icon?: string;
  show?: boolean;
  onHide?: () => void;
  nowrap?: boolean;
  fitContent?: boolean;
  size?: 'sm' | 'lg';
};

function Banner({
  className,
  text,
  appearance = 'info',
  icon = 'icon_tip',
  show = true,
  onHide,
  nowrap,
  fitContent,
  children,
  size = 'sm'
}: PropsWithChildren<Props>) {
  const [backgroundColor, setBackgrnd] = useState('var(--background-block-brand)');
  const [fontColor, setFontColour] = useState('var(--text-block-brand)');

  useEffect(() => {
    const bgColors = {
      info: 'var(--background-block-brand)',
      error: 'var(--background-block-error)',
      warning: colors.semiTransparentWarningColour,
      info_light: 'var(--background-block-brand)'
    };
    const fontColors = {
      info: 'var(--text-block-brand)',
      error: 'var(--text-block-error)',
      warning: colors.warningColour,
      info_light: 'var(--text-block-brand)'
    };
    setBackgrnd(bgColors[appearance]);
    setFontColour(fontColors[appearance]);
  }, []);

  return (
    <BannerWrapper
      className={ClassNames('banner', className, { hidden: !show }, { nowrap })}
      aria-hidden={!show}
      show={show}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      fitContent={fitContent}
      size={size}
    >
      <span id="icon_type" className={ClassNames('icon', icon)} />
      {(text || children) && (
        <Text data-testid="text" className={ClassNames('banner_text', { nowrap })}>
          {text || children}
        </Text>
      )}
      {onHide && <span tabIndex={0} role="button" className="icon icon_close" onClick={onHide} />}
    </BannerWrapper>
  );
}

type BannerWrapperProps = {
  backgroundColor: string;
  fontColor: string;
  show?: boolean;
  fitContent?: boolean;
  size?: Props['size'];
};

const BannerWrapper = styled.div<BannerWrapperProps>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 1rem;
  color: ${({ fontColor }) => fontColor};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.typography.medium};
  padding: ${({ size }) => (size === 'sm' ? '0.5rem 0.75rem' : '0.75rem 1rem')};
  display: ${({ show }) => (show ? 'flex' : 'none')};

  &.nowrap {
    overflow: hidden;
    border-radius: 0.75rem;
  }

  .icon {
    align-self: center;
    font-weight: ${({ theme }) => theme.typography.bold};

    &:not(.icon_close) {
      margin-right: 0.5rem;
    }
  }

  .icon_close {
    margin-left: auto;
    padding: 0.5rem;
  }

  ${({ fitContent }) =>
    fitContent &&
    css`
      width: fit-content;
      margin: 0 auto;
      border-radius: var(--radius-md);
      padding: var(--spacing-03) var(--spacing-04);
      align-items: center;
      gap: var(--spacing-02);
    `}
`;

const Text = styled.div`
  text-align: left;
  align-self: center;

  &.nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default Banner;
