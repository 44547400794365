import { CompanySubscriptionPlan } from '@cohiretech/common-types';

import { ProfileState, isCompanyProfile } from 'store';
import { InactivePlan } from 'types';
import { checkIfSubscriptionExpired, checkIfAccountIsSuspended } from 'utils/companyUtils';
import { getCurrentPlan } from 'utils/pricingUtils';

const { Basic, Expired, Suspended } = CompanySubscriptionPlan;

export const checkIfInactivePlan = (
  profile: ProfileState,
  demoCompany: boolean
):
  | {
      inactivePlan: InactivePlan;
      isSubscriptionExpired?: boolean;
      isAccountSuspended?: boolean;
      isUnpaidAccount?: boolean;
    }
  | undefined => {
  if (!isCompanyProfile(profile)) return;
  const plan = getCurrentPlan(profile);
  const { companySuspension } = profile || {};

  if (checkIfAccountIsSuspended(plan as CompanySubscriptionPlan, companySuspension?.status)) {
    return { inactivePlan: Suspended, isAccountSuspended: true };
  }
  if (checkIfSubscriptionExpired(plan)) {
    return { inactivePlan: Expired, isSubscriptionExpired: true };
  }
  if (!demoCompany && plan === Basic) return { inactivePlan: Basic, isUnpaidAccount: true };
};
