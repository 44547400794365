import React, { useState } from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';
import { swipeXAxisOnStart, swipeXAxisOnMove, swipeXAxisOnEnd } from 'swipeControl';

import { isMobileWidth, macBookScreen, mobile } from 'responsiveConfig';

import VideoItem from 'components/videoitem';
import CarouselArrows from 'components/carouselarrows';

import Avatar from 'v2/components/ui/atoms/Avatar';

import colours from 'css/base/_colours.module.scss';

export default function VideoCarousel({ videos }: $TSFixMe) {
  const videosDisplayed = videos.slice(0, 10);

  const [videoShown, setVideoShown] = useState(0);

  const prevEnabled = videoShown > 0;
  const nextEnabled = videoShown < videosDisplayed.length - 1;

  const onPrev = () => {
    if (prevEnabled) setVideoShown(videoShown - 1);
  };

  const onNext = () => {
    if (nextEnabled) setVideoShown(videoShown + 1);
  };

  return (
    <VideoCarouselCont className="video_carousel">
      {videosDisplayed.map(
        (
          {
            id,
            title,
            firstName,
            lastName,
            jobTitle,
            companyName,
            photoURL,
            companyLogo,
            videoTestimonialURL,
            videoURL
          }: $TSFixMe,
          index: $TSFixMe
        ) => {
          const storyPerson = firstName ? `${firstName} ${lastName}` : companyName;
          const videoName = title || storyPerson;
          const showVideo = videoShown === index;
          const desktopVideoHeight = showVideo ? 340 : 300;
          const mobileVideoHeight = desktopVideoHeight - 40;

          return (
            <VideoBox
              key={id}
              className={ClassNames('video_box', {
                show: showVideo,
                next: videoShown + 1 === index,
                previous: videoShown - 1 === index
              })}
              onTouchStart={e => swipeXAxisOnStart(e)}
              onTouchMove={e => swipeXAxisOnMove(e)}
              onTouchEnd={e => swipeXAxisOnEnd(e, onNext, onPrev)}
            >
              <VideoItem
                videoUrl={videoTestimonialURL || videoURL}
                youtubeOptions={{
                  width: '100%',
                  height: isMobileWidth ? mobileVideoHeight : desktopVideoHeight,
                  title: `${videoName} story video`
                }}
              />
              <VideoInfo className="video_information">
                <Avatar
                  photoURL={photoURL || companyLogo}
                  width={42}
                  firstname={title || firstName}
                  lastname={lastName}
                />
                <VideoDetails className="video_details">
                  <h4 className="strong video_carousel_title">{videoName}</h4>
                  {jobTitle && (
                    <span>
                      {jobTitle}
                      {companyName ? ` at ${companyName}` : ''}
                    </span>
                  )}
                  {!firstName && companyName && <span>{companyName}</span>}
                </VideoDetails>
              </VideoInfo>
            </VideoBox>
          );
        }
      )}
      <CarouselArrows
        prevEnabled={prevEnabled}
        nextEnabled={nextEnabled}
        onPrev={onPrev}
        onNext={onNext}
      />
    </VideoCarouselCont>
  );
}

const VideoCarouselCont = styled.div`
  position: relative;
  height: 410px;

  @media only screen and (max-width: ${macBookScreen}) {
    margin: 50px auto;
  }

  @media only screen and (max-width: ${mobile}) {
    height: 370px;
  }
`;

const VideoBox = styled.div`
  background-color: ${colours.fontColour};
  border-radius: 30px;
  box-shadow: 0 0 20px ${colours.darkerBgColour};
  position: absolute;
  z-index: -1;
  width: 100%;
  transition: all 0.1s ease-in;

  .video_information {
    display: none;
  }

  .video_wrapper {
    background-color: ${colours.fontColour};
  }

  &.next {
    opacity: 0.2;
    z-index: 0;
    right: -30px;
    top: 30px;

    @media only screen and (max-width: ${macBookScreen}) {
      top: 0;
      right: 0;
    }
  }

  &.previous {
    opacity: 0.2;
    z-index: 0;
  }

  &.show {
    z-index: 1;
    opacity: 1;

    .video_information {
      display: block;
    }

    .video_wrapper {
      border-radius: 30px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const VideoInfo = styled.div`
  padding: 15px;

  .profile_picture {
    background-color: transparent;
  }
`;

const VideoDetails = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
  box-sizing: border-box;
  max-width: calc(100% - 110px); //avoid hiding the arrows

  > span,
  .video_carousel_title {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
