import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import structuredClone from '@ungap/structured-clone';

import { castDraft } from 'store';
import { toArray } from 'utils/array';
import { isArray, isDefined } from 'utils/fn';
import { pluck } from 'utils/object';
import {
  CandidatesInStreamCount,
  PositionViewConversationsGraph,
  PositionViewListingActivity,
  PositionViewListings,
  PositionViewStreams,
  UserScope
} from 'v2/services/fetchers/company/positionview';

import {
  Freshness,
  PositionsFilterParams,
  PositionsState,
  PositionViewData
} from './positions.types';

const initLoading = {
  listings: { loading: false, hasFetched: false, hasNextPage: true },
  activity: { loading: false, hasFetched: false },
  conversations: { loading: false, hasFetched: false },
  streams: { loading: false, hasFetched: false },
  candidateCount: { loading: false, hasFetched: false, perListing: {} }
};

const initLoadQueue = {
  activity: [],
  streams: [],
  candidateCount: [],
  conversations: [],
  listings: []
};

type InitialStateOptions = {
  hasRetrievedCached?: boolean;
  activeTab?: UserScope;
};

export const getInitialPositionsState = (options: InitialStateOptions): PositionsState => {
  const { hasRetrievedCached = false, activeTab = 'user' } = options;
  return structuredClone({
    activeTab,
    params: {
      status: true
    },
    fresh: {
      isReloading: true,
      user: {
        loadStates: initLoading,
        loadQueue: initLoadQueue
      },
      other: {
        loadStates: initLoading,
        loadQueue: initLoadQueue
      },
      all: {
        loadStates: initLoading,
        loadQueue: initLoadQueue
      }
    },
    cached: {
      hasRetrievedCached,
      user: {},
      other: {},
      all: {}
    }
  });
};

export const positions = createSlice({
  name: 'positions',
  initialState: getInitialPositionsState({}),
  reducers: {
    resetAllPositions: () => getInitialPositionsState({}),
    resetFreshPositions: state => {
      state.fresh = getInitialPositionsState({ hasRetrievedCached: true }).fresh;
    },
    resetPositionsCached: state => {
      state.cached = getInitialPositionsState({}).cached;
    },
    resetTabListings: (state, action: PayloadAction<UserScope>) => {
      const userScope = action.payload;
      state.fresh[userScope] = getInitialPositionsState({
        activeTab: state.activeTab,
        hasRetrievedCached: true
      }).fresh[userScope];
    },
    setActivePositionsTab: (state, action: PayloadAction<UserScope>) => {
      const userScope = action.payload;
      state.activeTab = userScope;
      state.fresh[userScope].loadStates.activity.hasFetched = false;

      const listingIDs =
        (state.fresh[userScope].listings?.values?.map(pluck('listingID')) as number[]) || [];
      state.fresh[userScope].loadQueue.activity = [...listingIDs];
      state.fresh[userScope].loadQueue.streams = [...listingIDs];
    },
    setActivity: (
      state,
      action: PayloadAction<{
        userScope: UserScope;
        data: PositionViewListingActivity[];
        freshness?: Freshness;
      }>
    ) => {
      const { userScope, data = [], freshness: ripeness } = action.payload;

      state.fresh[userScope].loadQueue.activity = [];

      const freshness: Freshness[] = toArray(ripeness ?? ['fresh', 'cached']);
      freshness.forEach(fresh => {
        if (!state[fresh][userScope].activity) state[fresh][userScope].activity = {};
        data.forEach(activity => {
          state[fresh][userScope].activity![activity.listingID] = activity;
        });
      });
    },
    setListingsLoadQueue: (state, action: PayloadAction<{ userScope?: UserScope }>) => {
      const { userScope = state.activeTab } = action.payload;
      state.fresh[userScope].loadQueue.listings = [
        ...state.fresh[userScope].loadQueue.listings,
        userScope
      ];
    },
    setListings: (
      state,
      action: PayloadAction<{
        userScope: UserScope;
        data: PositionViewListings;
        freshness?: Freshness;
        page?: number;
      }>
    ) => {
      const { userScope, data, page, freshness: ripeness } = action.payload;
      data.values.forEach(({ listingID }) => {
        state.fresh[userScope].loadQueue.activity.push(listingID);
        state.fresh[userScope].loadQueue.streams.push(listingID);
        state.fresh[userScope].loadQueue.conversations.push(listingID);
      });

      const freshness: Freshness[] = toArray(ripeness ?? ['fresh', 'cached']);
      freshness.forEach(fresh => {
        if (isDefined(page) && page > 0 && isArray(state[fresh][userScope].listings?.values)) {
          data.values.forEach(listing => {
            state[fresh][userScope].listings!.values!.push(listing);
          });
          return;
        }
        state[fresh][userScope].listings = data;
      });
    },
    setStreams: (
      state,
      action: PayloadAction<{
        userScope: UserScope;
        data: PositionViewStreams;
        freshness?: Freshness;
      }>
    ) => {
      const { userScope, data, freshness: ripeness } = action.payload;
      const listingIDs = Object.keys(data).map(Number);
      state.fresh[userScope].loadQueue.streams = [];

      const freshness: Freshness[] = toArray(ripeness ?? ['fresh', 'cached']);
      freshness.forEach(fresh => {
        if (!state[fresh][userScope].streams) state[fresh][userScope].streams = {};
        listingIDs.forEach(listingID => {
          state[fresh][userScope].streams![listingID] = [...data[listingID]];
        });
      });
    },
    setConversations: (
      state,
      action: PayloadAction<{
        userScope: UserScope;
        data: PositionViewConversationsGraph;
        freshness?: Freshness;
      }>
    ) => {
      const { userScope, data, freshness: ripeness } = action.payload;
      const listingIDs = Object.keys(data).map(Number);
      state.fresh[userScope].loadQueue.streams = [];

      const freshness: Freshness[] = toArray(ripeness ?? ['fresh', 'cached']);
      freshness.forEach(fresh => {
        if (!state[fresh][userScope].conversations) state[fresh][userScope].conversations = {};
        listingIDs.forEach(listingID => {
          state[fresh][userScope].conversations![listingID] = [...data[listingID]];
        });
      });
    },
    setHasFetched: (
      state,
      action: PayloadAction<{ userScope: UserScope; status: PositionViewData }>
    ) => {
      const { userScope, status } = action.payload;
      state.fresh[userScope].loadStates[status].hasFetched = true;
    },
    setHasNextPage: (
      state,
      action: PayloadAction<{ userScope: UserScope; status: PositionViewData; value: boolean }>
    ) => {
      const { userScope, status, value } = action.payload;
      state.fresh[userScope].loadStates[status].hasNextPage = value;
    },
    setPositionStreamCandidateCount: (
      state,
      action: PayloadAction<{
        userScope: UserScope;
        listingID: number;
        value: CandidatesInStreamCount;
      }>
    ) => {
      const { userScope, listingID, value } = action.payload;
      const candidateCounts = state.fresh[userScope].candidateCount || {};
      candidateCounts[listingID] = { ...candidateCounts[listingID], ...value };
      state.fresh[userScope].candidateCount = candidateCounts;
    },
    setHasRetrievedCached: (state, action: PayloadAction<boolean>) => {
      state.cached.hasRetrievedCached = action.payload;
    },
    // Todo: Switch out for safe version / adding validation
    setUnsafeCached: (
      state,
      action: PayloadAction<{ userScope: UserScope; loader: PositionViewData; value: any }>
    ) => {
      const { userScope, loader, value } = action.payload;
      state.cached[userScope][loader] = value;
    },
    setPositionsFilterParams: (state, action: PayloadAction<Partial<PositionsFilterParams>>) => {
      const newState = getInitialPositionsState({
        activeTab: state.activeTab,
        hasRetrievedCached: true
      });
      newState.params = { ...state.params, ...action.payload };
      return newState;
    },
    togglePositionsLoader: (
      state,
      action: PayloadAction<{
        loader: PositionViewData;
        userScope: UserScope;
        value?: boolean;
        listingID?: number;
      }>
    ) => {
      const { loader, userScope, value, listingID } = action.payload;
      const loadStates = castDraft(state.fresh[userScope].loadStates);

      // initState should be falsy for all except candidateCount
      if (listingID && loadStates[loader].perListing) {
        loadStates[loader].perListing![listingID] =
          value ?? !loadStates[loader].perListing![listingID];
        return;
      }

      state.fresh[userScope].loadStates[loader].loading =
        value ?? !state.fresh[userScope].loadStates[loader].loading;
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action => {
          return action.type === positions.actions.setPositionsFilterParams.type;
        },
        state => {
          state.fresh.isReloading = true;
        }
      )
      .addMatcher(
        action => {
          return action.type === positions.actions.setListings.type;
        },
        (state, action) => {
          state.fresh.isReloading = undefined;
          const userScope = action.payload.userScope as UserScope;
          const tab = state.fresh[userScope];
          const listingsData = tab.listings;
          const nOfListings = listingsData?.values?.length || 0;
          if (nOfListings === listingsData?.total) {
            state.fresh[userScope].loadStates.listings.hasNextPage = false;
          }
        }
      );
  }
});

// Action creators
export const {
  resetAllPositions,
  resetPositionsCached,
  resetTabListings,
  setActivePositionsTab,
  setActivity,
  setHasFetched,
  setHasNextPage,
  setHasRetrievedCached,
  setListings,
  setPositionStreamCandidateCount,
  setStreams,
  setConversations,
  setUnsafeCached,
  setPositionsFilterParams,
  setListingsLoadQueue,
  togglePositionsLoader
} = positions.actions;

export default positions.reducer;
