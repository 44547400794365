import { useEffect, useState } from 'react';

import { SkillRetrievalKey } from '@cohiretech/common-types';

import { classifySalesAndCoreSkills } from 'v2/services/common/skills';
import { Skills, getSkillOptions } from 'v2/services/fetchers/public/skillOptions';
import { parseSkillOptions } from 'utils';

/**
 * Custom React hook to fetch skillOptions with retry and abort-controller on unmount.
 *
 * Hook returns the skill options list as an array of strings.
 * The hook also sets up an AbortController to abort fetching upon unmounting the component
 * or during cleanup phase of the containing component.
 *
 * @returns {string[]} An array of skill options as strings.
 */
export const useSkillOptions = <C extends SkillRetrievalKey>(category?: C) => {
  const [skillOptions, setSkillOptions] = useState<string[]>([]);
  const [parsedSkillOptions, setParsedSkillOptions] = useState<
    { label: string; value: string; id: string }[]
  >([]);
  const [salesExperienceOptions, setSalesExperienceOptions] = useState<string[]>([]);

  useEffect(() => {
    const controller = new AbortController();
    const getSkillList = async () => {
      const { status, data } = await getSkillOptions({
        abortSignal: controller.signal,
        category
      });

      if (status === 'success') {
        let skillList = data;
        let salesExperienceSkills: string[] = [];

        if (category === 'SkillToCategory') {
          [salesExperienceSkills, skillList] = classifySalesAndCoreSkills(
            data as Skills<'SkillToCategory'>
          );
        }

        setSkillOptions(skillList as string[]);
        setSalesExperienceOptions(salesExperienceSkills);

        const parsedOptions = parseSkillOptions(skillList);
        setParsedSkillOptions(parsedOptions);
      }
    };

    getSkillList();
    return () => {
      controller.abort();
    };
  }, [category]);

  return { skillOptions, salesExperienceOptions, parsedSkillOptions };
};
