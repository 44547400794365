/* eslint-disable @typescript-eslint/no-unused-vars */

import { OnRouteChangeProps } from 'types';
import { noop } from 'utils/fn';

export const HS_PUBLIC_SCRIPT_ROUTES = ['/pricing'];

const HubspotWidget = {
  close: noop,
  load: noop,
  open: noop,
  refresh: noop,
  status: () => ({ loaded: false }),
  remove: noop
};

const getWidget = () => {
  // @ts-ignore - we are checking to see if object exists
  const widget = window.HubSpotConversations?.widget;
  if (!widget) return HubspotWidget;

  return widget;
};

const candidateStrategy = (props: OnRouteChangeProps) => {
  const widget = getWidget();
  const { loaded } = widget.status() || {};

  if (!loaded) return;

  widget.stop();
  widget.remove();
};

const companyStrategy = (props: OnRouteChangeProps) => {
  const { demoCompany, isExpired } = props;
  const widget = getWidget();
  const { loaded } = widget.status() || {};

  if (demoCompany || isExpired) {
    if (!loaded) widget.load();
    else widget.refresh();
  } else {
    widget.remove();
  }
};

const publicStrategy = (props: OnRouteChangeProps) => {
  const widget = getWidget();
  const { loaded } = widget.status() || {};

  const { location } = props;
  if (HS_PUBLIC_SCRIPT_ROUTES.includes(location.pathname) && !loaded) widget.load();
  else widget.refresh();
};

export const handleHubspotWidget = (props: OnRouteChangeProps) => {
  const { isCandidate, isCompany } = props;
  if (isCandidate) return candidateStrategy(props);
  if (isCompany) return companyStrategy(props);
  return publicStrategy(props);
};
