import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { ListingInterface } from '@cohiretech/common-types';

import { mobile } from 'responsiveConfig';
import { BasicOptionStrict } from 'types';

import {
  PositionEditContext,
  PositionEditContextState
} from 'views/company/position/PositionEdit.context';

import Dropdown from 'components/dropdown';
import DialogWindow from 'components/dialogwindow';
import Tooltip from 'components/tooltip';

import { RangeContainer, Range, RangeDropdown } from 'v2/components/ui/molecules/RangeField';
import FieldErrorMessage from 'v2/components/ui/molecules/FieldErrorMessage';
import { InvalidDropdownStyling } from 'v2/components/ui/styles/FieldValidation';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

export type EditPositionSalaryRangeProps = Pick<
  ListingInterface,
  'salaryMin' | 'salaryMax' | 'currency' | 'salaryVisible'
> & {
  salaryOptions: BasicOptionStrict<number>[];
  currencyOptions: BasicOptionStrict[];
  question: ReactNode;
  formSubmitted?: boolean;
  isCandidateATPosition?: boolean;
};

type State = $TSFixMe;

export default class CompanyEditPositionLogisticsSalaryRange extends React.Component<
  EditPositionSalaryRangeProps,
  State
> {
  constructor(props: EditPositionSalaryRangeProps) {
    super(props);

    this.state = {
      salaryMin: props.salaryMin === 0 ? 0 : props.salaryMin || -1,
      salaryMax: props.salaryMax || -1,
      currency: props.currency || '',
      salaryVisible: props.salaryVisible === undefined ? true : props.salaryVisible,
      showUndisclosedSalaryPopup: false
    };
  }

  getAnswer() {
    const { salaryMin, salaryMax, currency, salaryVisible } = this.state;
    return { salaryMin, salaryMax, currency, salaryVisible };
  }

  updateSalaryVisible(checked: $TSFixMe) {
    this.setState({ salaryVisible: !checked, showUndisclosedSalaryPopup: checked });
  }

  getErrorMessage = (missingSalaries: boolean, currency: string) => {
    return missingSalaries
      ? 'Please select a min and max base salary'
      : !currency
      ? 'Please select a currency'
      : '';
  };

  render() {
    const { salaryMin, salaryMax, currency, salaryVisible, showUndisclosedSalaryPopup } =
      this.state;
    const { currencyOptions, salaryOptions, question, formSubmitted, isCandidateATPosition } =
      this.props;
    const { showOTEField } = (this.context as PositionEditContextState) || {};
    const missingSalaries = salaryMin === -1 || salaryMax === -1;

    return (
      <div className="field">
        <label className="question">
          {question}
          <ConditionalRender predicate={!isCandidateATPosition}>
            <span className="icon_help">
              <Tooltip position="right" tooltipStyle="light">
                Input base salary excluding bonuses, commission and OTE.
                {showOTEField ? '' : ' Add these in the job description.'}
              </Tooltip>
            </span>
          </ConditionalRender>
        </label>
        <RangeContainer className="salary" isFlexbox>
          <Range className="salary_range" isFlexbox>
            <RangeDropdown
              id="min_salary"
              className={ClassNames('salary_dropdown', 'small_arrow')}
              selected={salaryMin}
              selectMessage="Minimum Salary"
              list={
                salaryMax <= 0
                  ? salaryOptions
                  : salaryOptions.filter(({ value }: $TSFixMe) => value < salaryMax)
              }
              onSelect={(item: $TSFixMe) => this.setState({ salaryMin: item.value })}
              invalidInput={formSubmitted && salaryMin === -1}
            />
            <span className="salary_to">to</span>
            <RangeDropdown
              id="max_salary"
              className={ClassNames('salary_dropdown', 'small_arrow')}
              selected={salaryMax}
              selectMessage="Maximum Salary"
              list={
                salaryMin <= 0
                  ? salaryOptions
                  : salaryOptions.filter(({ value }: $TSFixMe) => value > salaryMin)
              }
              onSelect={(item: $TSFixMe) => this.setState({ salaryMax: item.value })}
              invalidInput={formSubmitted && salaryMax === -1}
            />
          </Range>
          <Currency className="currency">
            <CurrencyDropdown
              id="currency_dropdown"
              className={ClassNames('currency_dropdown', 'small_arrow')}
              selected={currency}
              selectMessage="Currency"
              list={currencyOptions}
              onSelect={(item: $TSFixMe) => this.setState({ currency: item.value })}
              invalidInput={formSubmitted && !currency}
            />
          </Currency>
        </RangeContainer>
        <ConditionalRender predicate={!isCandidateATPosition}>
          <CheckboxContainer>
            <FieldErrorMessage
              show={formSubmitted && (missingSalaries || !currency)}
              message={this.getErrorMessage(missingSalaries, currency)}
            />
            {salaryVisible ? (
              <p className="bottom_description">
                Salary range helps relevant candidates find you.{' '}
                <button
                  type="button"
                  className="link"
                  onClick={() => this.updateSalaryVisible(salaryVisible)}
                >
                  Want your salary range to be hidden?
                </button>
              </p>
            ) : (
              <p className="bottom_description">
                <strong>Your salary range is hidden from candidates. </strong>Please still input an
                appropriate salary range, it will be used to generate your default search criteria
                for this position.{' '}
                <button
                  type="button"
                  className="link"
                  onClick={() => this.updateSalaryVisible(salaryVisible)}
                >
                  Make salary visible to candidates.
                </button>
              </p>
            )}
            <DialogWindow
              id="salary_visible_popup"
              title="Hiding salaries lowers response rate"
              visible={showUndisclosedSalaryPopup}
              showCloseIcon
              onClose={() => this.setState({ showUndisclosedSalaryPopup: false })}
              primaryButton={{
                text: 'Keep salary visible',
                action: () =>
                  this.setState({ salaryVisible: true, showUndisclosedSalaryPopup: false })
              }}
              skipLink={{ text: 'I still wish to hide salary' }}
            >
              Positions that don't list salary ranges get significantly lower response rates to
              messages, and fewer incoming requests. To get the most out of your cord account, you
              should always list a salary range when possible.
            </DialogWindow>
          </CheckboxContainer>
        </ConditionalRender>
      </div>
    );
  }
}

CompanyEditPositionLogisticsSalaryRange.contextType = PositionEditContext;

const Currency = styled.div`
  width: 20%;

  @media only screen and (max-width: ${mobile}) {
    display: none;
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 10px;
`;

export const CurrencyDropdown = styled(Dropdown)<{ invalidInput?: boolean }>`
  ${InvalidDropdownStyling}
`;
