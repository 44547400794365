import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { isPublicPage } from 'utils/url';
import { clearLSItemsOnLogout } from 'v2/services/common/localStorage';

const REFRESH_TOKEN = process.env.REACT_APP_USER_REFRESH_TOKEN!;

let isTokenRefreshInProgress: boolean;
let pendingRefreshRequest: Promise<AxiosResponse>;

export const handleExpiredToken = async (config: AxiosRequestConfig) => {
  try {
    if (!isTokenRefreshInProgress) {
      isTokenRefreshInProgress = true;

      pendingRefreshRequest = axios.post(REFRESH_TOKEN);
      await pendingRefreshRequest;

      isTokenRefreshInProgress = false;
    } else {
      await pendingRefreshRequest;
    }

    return axios.request(config);
  } catch (error: any) {
    clearLSItemsOnLogout();

    if (!isLoginPage() && !isPublicPage()) {
      window.location.href = '/login';
    }
  }
};

const isLoginPage = () => window.location.pathname === '/login';
