import React from 'react';
import styled from 'styled-components';

import { OperatingCountry } from '@cohiretech/common-types';

import { NewPricingOptions, SubscriptionPlan, AgencySubscriptionPlans } from 'types';
import { PRICING_OPTIONS } from 'consts';

import CreateAccountProfile from 'components/createaccountprofile';
import Seo from 'components/seo';

import colours from 'css/base/_colours.module.scss';

type Props = {
  sessionID: string;
  companyName: string;
  country: OperatingCountry;
  subscriptionPlan: SubscriptionPlan;
};

const { enterprise, enterprisePerUser } = PRICING_OPTIONS;

export default function CompanySignUp({
  sessionID,
  companyName,
  country,
  subscriptionPlan
}: Props) {
  const validTypeformSubscriptionPlans: SubscriptionPlan[] = [
    enterprise.value,
    enterprisePerUser.value,
    NewPricingOptions.ScalingV2
  ];
  const showTypeform =
    country === OperatingCountry.US || validTypeformSubscriptionPlans.includes(subscriptionPlan);
  const isAgency = AgencySubscriptionPlans.V1 === subscriptionPlan;

  const renderSignUpContent = () => {
    if (showTypeform) {
      const emailBody =
        "We've successfully paid for using cord and it would be great if you can help set-up our account by filling in this form: https://v7x6ybnej9n.typeform.com/to/OfWsMIzL";

      return (
        <div className="typeform_cont">
          <div
            className="typeform"
            data-tf-widget="OfWsMIzL"
            data-tf-iframe-props="title=Build your cord account"
            data-tf-medium="snippet"
          />
          <div>
            If you're not the best person to create your cord account.
            <a
              href={`mailto:?subject=Setup our cord account&body=${emailBody}`}
              className="link"
              title="Open a survey in a new tab"
              target="_blank"
              rel="noopener noreferrer"
            >
              Share this form with a colleague
            </a>
          </div>
          <Seo script={[{ src: '//embed.typeform.com/next/embed.js' }]} />
        </div>
      );
    } else {
      return (
        <CreateAccountProfile companyName={companyName} sessionID={sessionID} isAgency={isAgency} />
      );
    }
  };

  const subheading = showTypeform
    ? 'We just need a few details to start setting up your cord account right away.'
    : "Let's start setting up your cord account.";

  return (
    <CompanySignUpCont className="min_view center_alignment">
      <h1 className="heading">Welcome to cord!</h1>
      <h2 className="subheading">{subheading}</h2>
      {renderSignUpContent()}
    </CompanySignUpCont>
  );
}

const CompanySignUpCont = styled.div`
  h2 {
    max-width: 450px;
  }

  .size_form,
  .user_profile {
    max-width: 450px;
    margin: auto;
  }

  .size_form .button {
    width: 100%;
    margin-top: 4px;
  }

  .typeform_cont {
    .typeform {
      height: 430px;
      margin-bottom: 30px;
      border-radius: 30px;
      box-shadow: 0px 0px 30px ${colours.dropshadow};
    }

    .link {
      margin-left: 5px;
    }
  }
`;
