import React from 'react';
import { History } from 'history';
import { useLocation } from 'react-router';

import { OperatingCountry } from '@cohiretech/common-types';

import useLocalisation from 'hooks/useLocalisation';
import { isAgenciesPage } from 'utils/url';
import { getForCompaniesPageLocalisation } from 'utils/companyUtils';

import SelfServeDemoForm from 'components/selfservedemoform';
import ArrowLink from 'components/arrowlink';
import { getLogosDescription } from 'components/companylogos';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import SanFranciscoPricingBanner from 'v2/components/ui/organisms/SanFranciscoPricingBanner';
import VideoItem from 'v2/components/ui/organisms/VideoItem';
import ConditionalWrapper from 'v2/components/utility/ConditionalWrapper';

import VideoThumbnail from 'images/videoitem_for_companies.jpg';

import { scrollToBookDemoForm } from '../testimonials/helpers';

import {
  BannerWrapper,
  ForCompaniesLogoSection,
  TitleSection,
  TopSection
} from './PipelineHero.styled';

type Props = {
  heading?: string;
  subheading?: string;
  history: History;
  noOfActiveUsers: string;
};

const PipelineHero = ({
  heading = 'See relevant candidates',
  subheading = 'Run a search to see the relevant, active candidates looking for work on cord right now.',
  history,
  noOfActiveUsers
}: Props) => {
  const country = useLocalisation();
  const location = useLocation();
  const pricingCountry = getForCompaniesPageLocalisation(location.pathname) as OperatingCountry;
  const showSanFranBanner = [pricingCountry, country].includes(OperatingCountry.US);
  const showButtonTooltip = country === OperatingCountry.US && noOfActiveUsers;
  const isForAgenciesPage = isAgenciesPage(location.pathname);
  const logosText = getLogosDescription();

  return (
    <TopSection showBanner={showSanFranBanner}>
      <SanFranciscoPricingBanner show={showSanFranBanner} spacing="05" />
      <TitleSection className="page_view">
        <ConditionalWrapper predicate={showSanFranBanner} Wrapper={BannerWrapper}>
          <div className="left_cont">
            <h1>{heading}</h1>
            <h2>{subheading}</h2>
            <SelfServeDemoForm
              expandable={false}
              submitText={`Search ${noOfActiveUsers} candidates`}
              color="dark"
              history={history}
              submitTooltipText={
                showButtonTooltip ? `${noOfActiveUsers} candidates looking for work in the US` : ''
              }
            />
            <ConditionalRender
              predicate={isForAgenciesPage}
              fallback={<ArrowLink to="/request-demo">Book a demo</ArrowLink>}
            >
              <ArrowLink onClick={scrollToBookDemoForm}>Book a demo</ArrowLink>
            </ConditionalRender>
          </div>
          <VideoItem
            videoUrl="https://www.youtube.com/watch?v=eIwHqBtQtEs"
            coverPhotoURL={VideoThumbnail}
            youtubeOptions={{ aspectRatio: '735 / 510', width: '100%', title: 'Hiring on cord' }}
            playIcon="light_blue"
          />
        </ConditionalWrapper>
      </TitleSection>
      <ForCompaniesLogoSection
        className="page_view"
        description={logosText}
        logoHeight="40px"
        limit={isForAgenciesPage ? 5 : 14}
        lightColor
      />
    </TopSection>
  );
};

export default PipelineHero;
