import styled, { css } from 'styled-components';

import { Bounds } from 'types';

type ScrollProps = {
  bounds?: Bounds;
  invisible?: boolean;
};

const Scroll = styled.div<ScrollProps>`
  position: absolute;
  overflow: auto;
  left: ${({ bounds }) => bounds?.left || 0}px;
  right: ${({ bounds }) => bounds?.right || 0}px;
  top: ${({ bounds }) => bounds?.top || 0}px;
  bottom: ${({ bounds }) => bounds?.bottom || 0}px;

  ${({ invisible }) => invisible && NoScrollbar}
`;

export const NoScrollbar = css`
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */

  /* For Chrome, Safari, and Opera */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* Optional: Style the scrollbar track and thumb */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  /* Firefox scrollbar */
  /* Hide the scrollbar track and thumb */
  html {
    scrollbar-width: none;
  }

  /* Optional: Style the scrollbar track and thumb */
  html {
    scrollbar-color: transparent transparent;
  }
`;

export default Scroll;
