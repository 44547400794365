import React from 'react';
import styled from 'styled-components';

import { ColourWay } from 'types';

import ProgressBar from 'components/progressbar';
import Tags from 'components/tags';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { GradientText, GradientTextProps } from 'v2/components/ui/styles';

import { getFitScoreTags } from '../FitScoreOverview/FitScoreOverview.helper';

type FitScoreColor = Extract<ColourWay, 'purpleGradient' | 'cordGradient'>;

type PercentageOrPoints =
  | {
      weightPercentage: number;
      weightPoints?: never;
    }
  | {
      weightPercentage?: never;
      weightPoints: number;
    };

type FitScoreLevelProps = {
  data: any;
  overallPercentage: number;
  title: string;
  type?: FitScoreColor;
  hideToolTip?: boolean;
} & PercentageOrPoints;

const FitScoreLevel = ({
  data,
  overallPercentage = 0,
  title,
  weightPercentage,
  weightPoints,
  hideToolTip = false,
  type = 'purpleGradient'
}: FitScoreLevelProps) => {
  const { matchingTags, nonMatchingTags } = getFitScoreTags(title, data);
  const symbol = weightPoints ? '' : '%';
  const overAllPoints = weightPoints
    ? `${Math.round((weightPoints / 100) * overallPercentage)}/${weightPoints}`
    : '';

  return (
    <StyledFitScoreLevel>
      <FitScoreLevelPercentage>
        <ProgressTitle gradientTextColour={type} className="progress_title">
          <LevelName>
            {title}
            <ConditionalRender predicate={!hideToolTip}>
              <HelpIcon gradientTextColour={type} className="icon_help">
                <Tooltip position="top" tooltipStyle="dark">
                  Weighting {`${weightPoints || weightPercentage}${symbol}`} of match score
                </Tooltip>
              </HelpIcon>
            </ConditionalRender>
          </LevelName>
          <strong className="percentage">
            {overAllPoints || overallPercentage}
            {symbol}
          </strong>
        </ProgressTitle>
        <ProgressBar hideProgressLabel progress={overallPercentage} colour={type} height="4px" />
      </FitScoreLevelPercentage>
      <FitScoreLevelCriteria>
        <Tags size="small" colour={type} tags={matchingTags} />
        <Tags size="small" colour={type} fill tags={nonMatchingTags} />
      </FitScoreLevelCriteria>
    </StyledFitScoreLevel>
  );
};

export default FitScoreLevel;

const ProgressTitle = styled.div<GradientTextProps>`
  && {
    ${GradientText}
  }
`;

const HelpIcon = styled.span<GradientTextProps>`
  && {
    ${GradientText}

    font-size: ${({ theme }) => theme.typography.small};

    .tooltip_wrapper {
      -webkit-text-fill-color: ${({ theme: { darkMode, colours } }) =>
        darkMode ? colours.darkBgColour : 'white'};
    }
  }
`;

const LevelName = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFitScoreLevel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
`;
const FitScoreLevelPercentage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  font-size: 0.875rem;

  .progress_title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    font-weight: ${({ theme }) => theme.typography.bold};

    strong {
      font-weight: ${({ theme }) => theme.typography.black};
    }
  }

  .progress_footnote {
    font-style: italic;
  }
`;
const FitScoreLevelCriteria = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .tags_wrapper {
    margin: 0;

    .tag {
      position: relative;

      [class^='icon_'] {
        margin-left: 0;
        font-size: ${({ theme }) => theme.typography.small};
        font-weight: ${({ theme }) => theme.typography.regular};

        .tooltip_wrapper {
          -webkit-text-fill-color: ${({ theme: { darkMode, colours } }) =>
            darkMode ? colours.darkBgColour : 'white'};
        }
      }

      &.fill {
        opacity: 0.8;
      }
    }
  }
`;
