export const PEOPLE_HUB_LINK = 'https://people.cord.co';

// Categories
export const PEOPLE_SUPPORT_HUB = 'qpeAQUcf8ufAsYg3bSkLXQ';

// Guides
export const PROFILE_VERIFICATION_GUIDE = '7mAjfkm9jGqQ6riKvieXC5';
export const PROFILE_BUILDING_GUIDE = 'g453MQ8RiqwDxALm9BVUEc';
export const PROFILE_SELF_BUILD_GUIDE = 'b2ayDNLJt9mugVryJWdA7y';
export const BLOCK_COMPANIES_GUIDE = 'fbx8QVPaGnGgWRf5rEc6be';
export const EFFECTIVE_MESSAGE_GUIDE = 'tuu7mnVo6jnAgstvdkmP4A';
export const INSIGHTS_EXPLAINER_DOC = 'cj5itoTtLvvGJ8gFUESb6h';
export const PROFILE_VISIBILITY_GUIDE = '38Lr9NJ6ZB7oUf2m8Pp1cp';
