import { UserProfileType } from 'types';
import { isNull } from 'utils/fn';

export function setBodyCSSVariable(
  variableName: string,
  newValue: string,
  type: UserProfileType = 'public'
) {
  const isNoSpecifiedBodyTag = isNull(type) || type === 'public';
  const userType = isNoSpecifiedBodyTag ? '' : `.${type}`;
  const body = document.querySelector(`body${userType}`) as HTMLBodyElement;
  if (!body) return;

  const variable = `--${type}-${variableName}`;
  body.style.setProperty(variable, newValue);
}
