import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useCandidateProfile } from 'store';

import SearchOptionList from 'v2/components/ui/molecules/SearchOptionList';

import { useSearchBarContext } from '../../SimpleSearchBar.context';

import { getRelavantSearchTerms } from './AutoSuggest.helpers';

export default function MobileAutoSuggest() {
  const profile = useCandidateProfile();
  const { startNewSearch } = useSearchBarContext();
  const relavantSearchTerms = useMemo(() => getRelavantSearchTerms(profile), [profile]);

  return (
    <div>
      <MobileTitle className="small_text">Relevant search terms</MobileTitle>
      <SearchOptionList
        searchOptions={relavantSearchTerms}
        onClick={(_, option) => startNewSearch(option)}
      />
    </div>
  );
}

const MobileTitle = styled.h3`
  margin: 0.5rem 0.5rem calc(0.5rem + 2px);
`;
