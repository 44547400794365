import { isNil } from 'utils/fn';

export const noticePeriodToDays = (noticePeriod: string) => {
  switch (noticePeriod) {
    case 'Immediately':
      return 0;
    case '1 day':
      return 1;
    case '1 week':
      return 7;
    case '2 weeks':
      return 14;
    case '3 weeks':
      return 21;
    case '1 month':
      return 30;
    case '2 months':
      return 60;
    case '3 months':
      return 90;
    case 'More than 3 months':
      return 91;
    default:
      return null;
  }
};

export const NOTICE_PERIOD_MAP = {
  Immediately: 0,
  '1 day': 1,
  '1 week': 7,
  '2 weeks': 14,
  '3 weeks': 21,
  '1 month': 30,
  '2 months': 60,
  '3 months': 90,
  'More than 3 months': 91
};

export const noticePeriodToString = (days: number) => {
  if (isNil(days)) return 'More than 3 months';
  switch (true) {
    case days === 0:
      return 'Immediately';
    case days === 1:
      return '1 day';
    case days < 7:
      return `${days} days`;
    case days === 7:
      return '1 week';
    case days < 30:
      return `${Math.floor(days / 7)} weeks`;
    case days === 30:
      return '1 month';
    case days < 91:
      return `${Math.floor(days / 30)} months`;
    default:
      return 'More than 3 months';
  }
};
