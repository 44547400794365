import { datadogLogs } from '@datadog/browser-logs';

import { capitalizeSentences } from 'utils/string';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from 'cookieManager';

export const candidateGetForm = () => {
  try {
    const form = getLocalStorageItem('signup_form');
    return form;
  } catch (error: any) {
    datadogLogs.logger.error(error.message);
    return null;
  }
};

export const candidateClearForm = () => {
  removeLocalStorageItem('signup_form');
};

export const candidateUpdateForm = (
  { firstName, lastName, email, ...detail }: $TSFixMe,
  callbackFunc: $TSFixMe
) => {
  const form = candidateGetForm() || {};

  Object.assign(
    form,
    firstName && { firstName: capitalizeSentences(firstName) },
    lastName && { lastName: capitalizeSentences(lastName) },
    email && { email: email.toLowerCase() },
    { ...detail }
  );

  setLocalStorageItem('signup_form', form);
  if (callbackFunc) callbackFunc(form);
};
