import axios from 'axios';

import { CandidateSavedSearchInterface } from '@cohiretech/common-types';

import { APIResponse } from 'types';

const CANDIDATE_SAVED_SEARCH = process.env.REACT_APP_CANDIDATE_SAVED_SEARCH!;

// CORD-4597 Check types
export const getCandidateSavedSearches = async () => {
  try {
    const { data } = await axios.get<{ data: CandidateSavedSearchInterface[] }>(
      CANDIDATE_SAVED_SEARCH!
    );

    return data.data;
  } catch (error) {
    return [];
  }
};

export const updateCandidateSavedSearchOrder = async (feedOrder: $TSFixMe) => {
  try {
    const { data } = await axios.put(`${CANDIDATE_SAVED_SEARCH}/order`, { feedOrder });

    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const createCandidateSavedSearch = async (
  searchName: $TSFixMe,
  searchParam: $TSFixMe,
  notificationSettings: $TSFixMe
): Promise<APIResponse<{ feedID: number }>> => {
  try {
    const params: Omit<CandidateSavedSearchInterface, 'id'> = {
      searchName,
      searchParam: { params: searchParam },
      ...notificationSettings
    };
    const { data } = await axios.post<typeof params, { data: { data: { feedID: number } } }>(
      CANDIDATE_SAVED_SEARCH,
      params
    );

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const updateCandidateSavedSearch = async (
  id: $TSFixMe,
  searchName: $TSFixMe,
  searchParam: $TSFixMe,
  notificationSettings: $TSFixMe
): Promise<APIResponse> => {
  try {
    const params = {
      id,
      searchName,
      searchParam: { params: searchParam },
      ...notificationSettings
    };
    const { data } = await axios.put(CANDIDATE_SAVED_SEARCH, params);

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const removeCandidateSavedSearch = async (id: $TSFixMe) => {
  try {
    const { data } = await axios.delete(CANDIDATE_SAVED_SEARCH, {
      data: { id }
    });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
