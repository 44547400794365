import React, { FunctionComponent, SVGProps } from 'react';
import styled from 'styled-components';

import { isSmallLaptopWidth, smallLaptop } from 'responsiveConfig';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import ScrollAnimator from 'v2/components/utility/ScrollAnimator';

import { ReactComponent as CordLeftToRight } from 'images/cord-pipeline-animation-ltr.svg';
import { ReactComponent as CordRightToLeft } from 'images/cord-pipeline-animation-rtl.svg';

type CordAnimationProps = {
  margin?: string;
};

export const CordAnimationRTL = ({ margin = '-43px auto -75px' }: CordAnimationProps) => (
  <CordAnimation
    margin={margin}
    SVGComponent={CordRightToLeft}
    elementID="cord_rtl_ball"
    keyframes={kfCordRightToLeft}
  />
);

export const CordAnimationLTR = ({ margin = '-95px auto -66px' }: CordAnimationProps) => (
  <CordAnimation
    margin={margin}
    SVGComponent={CordLeftToRight}
    elementID="cord_ltr_ball"
    keyframes={kfCordLeftToRight}
  />
);

type Props = {
  SVGComponent: FunctionComponent<SVGProps<SVGSVGElement>>;
  margin: string;
  keyframes: string;
  elementID: string;
};

const CordAnimation = ({ SVGComponent, margin, keyframes, elementID }: Props) => (
  <ConditionalRender predicate={!isSmallLaptopWidth}>
    <CordAnimationWrapper margin={margin}>
      <ScrollAnimator SVGComponent={SVGComponent} elementID={elementID} keyframes={keyframes} />
    </CordAnimationWrapper>
  </ConditionalRender>
);

const CordAnimationWrapper = styled.div<{ margin: string }>`
  width: 768px;
  margin: ${({ margin }) => margin};

  @media only screen and (max-width: ${smallLaptop}) {
    display: none;
  }
`;

const kfCordRightToLeft = `
  0% {
    offset-distance: 0%;
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  40% {
    offset-distance: 49.26335%;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  100% {
    offset-distance: 100%;
  }
`;

const kfCordLeftToRight = `
  0% {
    offset-distance: 0%;
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1)
  }
  33.333333% {
    offset-distance: 49.76482%;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1)
  }
  100% {
    offset-distance: 100%
  }
`;
