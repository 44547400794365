import { isArray, isNumber, isObject } from 'utils/fn';

type Interval =
  | {
      min: number;
      max: number;
    }
  | Array<number>;

export class InvalidIntervalError extends Error {
  name = 'InvalidIntervalError';
  message = 'Invalid interval';

  constructor(int: Interval | number | unknown, message?: string) {
    super(`Invalid interval: ${int}`);

    if (message) {
      this.message = message;
    }
  }

  toString() {
    return `${this.name}: ${this.message}`;
  }
}

export const randomInt = (int: Interval | number) => {
  let min = 0;
  let max = 0;

  if (validateArrayInterval(int)) {
    [min, max] = int;
  } else if (validateObjectInterval(int)) {
    min = int.min;
    max = int.max;
  } else if (isNumber(int) && int > 0) {
    max = int;
  } else {
    throw new InvalidIntervalError(int);
  }

  return Math.floor(Math.random() * (max - min)) + min;
};

const validateArrayInterval = (int: unknown): int is Array<number> => {
  if (!isArray(int)) {
    return false;
  }

  if (int.length !== 2) {
    throw new InvalidIntervalError(int, 'Interval should be an array of length 2');
  }

  if (isNumber(int[0]) && isNumber(int[1]) && int[0] > int[1]) {
    throw new InvalidIntervalError(int, 'Min should not be greater than max');
  }

  return true;
};

const validateObjectInterval = (int: unknown): int is { min: number; max: number } => {
  if (!isObject(int)) {
    return false;
  }

  if (!('min' in int) || !('max' in int)) {
    throw new InvalidIntervalError(int, 'Interval should be an object with min and max properties');
  }

  if (isNumber(int.min) && isNumber(int.max) && int.min > int.max) {
    throw new InvalidIntervalError(int, 'Min should not be greater than max');
  }

  return true;
};
