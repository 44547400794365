import React, { useState } from 'react';
import styled from 'styled-components';

import DialogWindow from 'components/dialogwindow';
import ErrorMessage from 'components/errormessage';

import CandidateATPositionForm, {
  CandidateATCreatedPosition,
  CandidateATPositionFormProvider,
  useCandidateATPositionFormContext
} from 'v2/components/CandidateATPositionForm';

import { CandidateATPositionPopupStyling } from '../ApplicationTracker/components/ImportPositionPopup';

type EditATPositionPopupProps = {
  position: CandidateATCreatedPosition;
  fetchCandidateATPosition: () => Promise<void>;
  closeEditPositionPopup: () => void;
};

function EditApplicationTrackerPositionPopup({
  position,
  fetchCandidateATPosition,
  closeEditPositionPopup
}: EditATPositionPopupProps) {
  const [errorMessage, setErrorMessage] = useState('');

  const { saving, candidateATPositionFormRef, saveCandidateATPosition } =
    useCandidateATPositionFormContext();

  const updateCandidateCreatedATPosition = async () => {
    await saveCandidateATPosition({ setErrorMessage, closePopup: closeEditPositionPopup });
    fetchCandidateATPosition();
  };

  return (
    <EditCandidateATPositionPopup
      id="edit_candidate_at_position_popup"
      title="Edit position"
      subTitle="Update the position information"
      visible
      primaryButton={{
        text: 'Save position',
        buttonStyle: 'fill_secondary_gradient',
        action: updateCandidateCreatedATPosition
      }}
      secondaryButton={{
        text: 'Cancel',
        buttonStyle: 'ghost_grey',
        action: closeEditPositionPopup
      }}
      loading={saving}
      showCloseIcon
      bottomNote={<ErrorMessage>{errorMessage}</ErrorMessage>}
      onClose={closeEditPositionPopup}
    >
      <CandidateATPositionForm
        ref={candidateATPositionFormRef}
        initialPosition={position as CandidateATCreatedPosition}
      />
    </EditCandidateATPositionPopup>
  );
}

export default function EditApplicationTrackerPositionPopupWithContext(
  props: EditATPositionPopupProps
) {
  return (
    <CandidateATPositionFormProvider initialPosition={props.position}>
      <EditApplicationTrackerPositionPopup {...props} />
    </CandidateATPositionFormProvider>
  );
}

const EditCandidateATPositionPopup = styled(DialogWindow)`
  &#edit_candidate_at_position_popup {
    ${CandidateATPositionPopupStyling}

    .buttons_wrapper {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      .ghost_grey {
        min-width: unset;
      }
    }
  }
`;
