import axios from 'axios';

import {
  SearchResponse,
  SearchResponseCompany,
  SearchResponseListing
} from '@cohiretech/common-types';

import { APICancelledResponse, APIResponse, Prettify, Stream } from 'types';
import { getSearchTerms } from 'utils';
import { candidateSearchFilters } from 'fetcher';
import { SearchStates } from 'v2/services/candidate';

import { axiosGetWithID } from '../axiosWithRequestID';

const COMPANY_EXTERNAL_LISTING_SEARCH = process.env.REACT_APP_COMPANY_EXTERNAL_LISTING_SEARCH!;
const COMPANY_LISTING_SAVED_SEARCH = process.env.REACT_APP_COMPANY_LISTING_SAVED_SEARCH!;

type LiveLeadsResults =
  | APIResponse<
      Prettify<SearchResponse<SearchResponseCompany | Omit<SearchResponseListing, 'datePosted'>>>
    >
  | APICancelledResponse;

export const fetchLiveLeads = async ({
  searchItems,
  page,
  category,
  viewType,
  companyType
}: any): Promise<LiveLeadsResults> => {
  const requestId = `agency_search_request_${viewType}_${searchItems.length}`;
  const params = {
    page,
    category,
    view: viewType,
    companyType
  };

  const stringParameters = [
    candidateSearchFilters.previouslyDealtWith.attribute,
    candidateSearchFilters.lastActiveValue.attribute,
    candidateSearchFilters.lastActiveType.attribute,
    candidateSearchFilters.salary.attribute,
    candidateSearchFilters.salaryVisible.attribute,
    candidateSearchFilters.sortBy.attribute,
    candidateSearchFilters.hidden.attribute,
    candidateSearchFilters.lowerTimeOffset.attribute,
    candidateSearchFilters.upperTimeOffset.attribute,
    candidateSearchFilters.workEligibility.attribute
  ];

  const searchTermsObj = { ...params, ...getSearchTerms(searchItems, stringParameters) };

  try {
    const { data } = await axiosGetWithID<LiveLeadsResults>({
      url: COMPANY_EXTERNAL_LISTING_SEARCH,
      params: searchTermsObj,
      requestId
    });

    if (!data?.data) throw new Error('No data');

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return {
      status: axios.isCancel(error) ? 'cancelled' : 'failure',
      message: error?.response?.data
    };
  }
};

export const fetchSavedLiveLeadSearches = async (): Promise<APIResponse<Stream[]>> => {
  try {
    const { data } = await axios.get<APIResponse<Stream[]>>(COMPANY_LISTING_SAVED_SEARCH);
    if (data?.data) return { status: 'success', data: data?.data };
    return { status: 'success', data: [] };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const createLiveLeadSearch = async (searchName: string, searchParams: any) => {
  try {
    const { data } = await axios.post<APIResponse<{ savedSearchID: number }>>(
      COMPANY_LISTING_SAVED_SEARCH,
      {
        searchName,
        searchParam: { params: searchParams }
      }
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const updateLiveLeadSearch = async (id: string, searchName: string, searchParams: any) => {
  try {
    const { data } = await axios.put<APIResponse<SearchStates>>(
      `${COMPANY_LISTING_SAVED_SEARCH}/${id}`,
      {
        searchName,
        searchParam: { params: searchParams }
      }
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const deleteLiveLeadSearch = async (id: string) => {
  try {
    const { data } = await axios.delete<APIResponse<SearchStates>>(
      `${COMPANY_LISTING_SAVED_SEARCH}/${id}`
    );
    return { status: data.status };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getLiveLeadSearch = async (id: string) => {
  try {
    const { data } = await axios.get<APIResponse<SearchStates>>(
      `${COMPANY_LISTING_SAVED_SEARCH}/${id}`
    );
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return [];
  }
};
