import React from 'react';

import TestimonialItem from 'components/testimonialitem';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import DemoButtonSection from 'v2/components/DemoButtonSection';

const TestimonialsBlockList = (props: $TSFixMe) => {
  const { columns, items, testimonialType } = props;

  const sections = divideIntoSections(items, columns);

  return (
    <div className="testimonial_block_list_wrapper">
      {sections.map((section: $TSFixMe) => {
        return (
          <>
            <div className="testimonial_block_list">
              {section.map((item: $TSFixMe, index: $TSFixMe) => (
                <TestimonialItem
                  key={item.id}
                  {...item}
                  {...props}
                  itemColumns={columns}
                  index={index}
                />
              ))}
            </div>
            <ConditionalRender predicate={testimonialType === 'company'}>
              <DemoButtonSection type="make_direct_hires" className="bottom_marketing_section" />
            </ConditionalRender>
          </>
        );
      })}
    </div>
  );
};

const divideIntoSections = (items: $TSFixMe, columns: $TSFixMe) =>
  items.reduce((acc: $TSFixMe, item: $TSFixMe, index: $TSFixMe) => {
    const sectionIndex = Math.floor(index / (4 * columns));
    if (!acc[sectionIndex]) {
      acc[sectionIndex] = [];
    }
    acc[sectionIndex].push(item);
    return acc;
  }, []);

export default TestimonialsBlockList;
