import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { FlexContainer } from 'components/commonstyles';

type Props = {
  amount: number;
  iconCount?: number;
};

export default function SkeletonCompanyCards({ amount, iconCount = 3 }: Props) {
  return (
    <SkeletonWrapper>
      {Array.from({ length: amount }).map((_, idx) => (
        <div className="container" key={idx}>
          <div className="company_details">
            <div className="round_photo">
              <Skeleton />
            </div>
            <FlexContainer className="text_details" flexDirection="column" gap="5px">
              <Skeleton height="20px" />
              <Skeleton height="15px" />
            </FlexContainer>
          </div>
          <div className="banner">
            <Skeleton height="30px" />
          </div>
          <div className="icons">
            {Array.from({ length: iconCount }).map((_, iconIdx) => (
              <Skeleton key={`icon_${iconIdx}`} width="75px" />
            ))}
          </div>
        </div>
      ))}
    </SkeletonWrapper>
  );
}

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .container {
    position: relative;
    padding: 20px;
    box-shadow: 0 0 15px -2px rgb(49 71 95 / 20%);
    background-color: ${({ theme: { darkMode, colours } }) =>
      darkMode ? colours.darkBgColourLight : 'white'};
    border-radius: 15px;
  }

  .text_details {
    margin-left: 15px;
    text-align: left;

    span {
      width: 100%;

      &:first-child > span {
        width: 60%;
      }
    }
  }

  .company_details {
    display: flex;
    align-items: center;
  }

  .banner {
    margin-top: 15px;
  }

  .icons {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 30px;
    gap: 15px;
  }

  .round_photo .react-loading-skeleton {
    height: 60px;
    width: 60px;
    border-radius: 44%;
  }

  ${({ theme: { media } }) => media.mobileMin`
    .container {
      display: flex;
      justify-content: space-between;
      padding-right: 30px;
    }

    .text_details span {
      width: 90%;
    }
 
    .company_details {
      width: 100%;
    }

    .banner {
      position: absolute;
      right: 30px;
      bottom: 20px;
      width: 400px;
    }

    .icons {
      margin-top: 25px;
    }

    .round_photo .react-loading-skeleton {
      height: 90px;
      width: 90px;
    }
  `}
`;
