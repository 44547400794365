import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { CandidateStatus, CandidateTracking } from '@cohiretech/common-types';

import { useCandidateProfile } from 'store';
import { PROFILE_VERIFICATION_GUIDE } from 'consts';
import { editProfileInfo, setVisible, trackCandidateAction } from 'fetcher';
import { useCandidateData } from 'store/services';

import StatusBlockToggle, { StatusRef } from 'views/candidate/account/status';

import DialogWindow from 'components/dialogwindow';

import ShowHelpkitButton from 'v2/components/ShowHelpkitButton';

import { ReactComponent as ClapIcon } from 'images/clap.svg';
import { ReactComponent as StatusActiveIcon } from 'images/status-active.svg';
import { ReactComponent as StatusPassiveIcon } from 'images/status-passive.svg';

type WelcomeBackPopupProps = {
  show: boolean;
  onClose: () => void;
};

export const openStatusPopupProps = {
  svgIcon: <StatusActiveIcon className="status_icon pop_up_top_icon" />,
  title: 'Almost there...',
  subTitle:
    "Your profile will be reviewed and your status will change to “Open to opportunities” once the review is complete. This shouldn't take long.",
  children: (
    <p key="paragraph1">
      <em className="grey_text">
        Nb. In order to strike the right balance between the number of people and positions on cord,
        every reactivating profile is reviewed. This may take up to 24 hours.{' '}
        <ShowHelpkitButton
          key="Find out more"
          articleId={PROFILE_VERIFICATION_GUIDE}
          buttonText="Find out more"
        />
      </em>
    </p>
  )
};

export default function WelcomeBackPopup({ show, onClose }: WelcomeBackPopupProps) {
  const profile = useCandidateProfile();
  const history = useHistory();
  const statusRef = useRef<StatusRef>();
  const { reloadCandidateData } = useCandidateData();

  const [currentStep, setCurrentStep] = useState(0);
  const [newStatus, setNewStatus] = useState(profile?.status);

  if (!profile) return null;

  const onStatusSave = async () => {
    const status = statusRef?.current?.status;

    setNewStatus(status);

    await trackCandidateAction(CandidateTracking.ViewReactivationPopup);
    await editProfileInfo({ status });
    if (status === CandidateStatus.Active) await setVisible(true);

    setCurrentStep(currentStep + 1);
    reloadCandidateData();
  };

  const steps = [
    {
      svgIcon: <ClapIcon className="clap_icon pop_up_top_icon" />,
      title: 'Welcome back to cord!',
      subTitle:
        'Are you looking for work this time around? Set your status to start using cord again.',
      children: (
        <StatusBlockToggle
          ref={statusRef}
          status={profile?.status}
          visible={profile?.visible}
          getCandidateData={reloadCandidateData}
        />
      ),
      primaryButton: {
        text: 'Next',
        icon: 'icon_arrow_right',
        action: () => onStatusSave()
      }
    },
    ...(newStatus === CandidateStatus.Passive
      ? [
          {
            svgIcon: <StatusPassiveIcon className="status_icon pop_up_top_icon" />,
            title: 'Your status will remain as "Not looking"',
            subTitle:
              'If you start looking for work in the future, you can change your status to "Open to opportunities" from your account dropdown so you can message companies hiring directly.',
            primaryButton: {
              text: 'Close',
              action: () => onClose()
            },
            secondaryButton: {
              text: 'Back',
              buttonStyle: 'ghost_dark_blue',
              action: () => setCurrentStep(currentStep - 1)
            }
          }
        ]
      : [
          {
            ...openStatusPopupProps,
            primaryButton: {
              text: 'Update your profile',
              action: () => history.push('/candidate/account?cv_prompt=true')
            },
            secondaryButton: {
              text: 'Back',
              buttonStyle: 'ghost_dark_blue',
              action: () => setCurrentStep(currentStep - 1)
            }
          }
        ])
  ];

  return (
    <StyledWelcomeBackPopup
      id="welcome_back_popup"
      visible={show}
      multiStepOptions={{
        steps,
        currentStep,
        onStepChange: (step: number) => setCurrentStep(step)
      }}
      showCloseIcon={currentStep > 0}
      onClose={onClose}
      {...steps[currentStep]}
    />
  );
}

export const StyledWelcomeBackPopup = styled(DialogWindow)`
  &.pop_up_cont .pop_up_dialog {
    .clap_icon {
      max-height: 100px;
    }

    .status_icon {
      max-height: 72px;
    }
  }
`;
