import { createLogger } from 'redux-logger';

import { isLocalDevelopment } from 'v2/services/app';

import { isReduxLoggerEnabled } from 'v2/components/utility/DevTools';

const logger = createLogger({
  predicate: getState => isReduxLoggerEnabled(getState),
  collapsed: true,
  duration: true
});

export default {
  logger,
  devTools: isLocalDevelopment()
};
