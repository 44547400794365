import axios from 'axios';

import {
  AwardType,
  AwardCompanySize,
  AwardSeason,
  AwardLeaderboardEntryInterface,
  AwardReportInterface
} from '@cohiretech/common-types';

import { APIResponse } from 'types';

const AWARDS_LEADERBOARDS = process.env.REACT_APP_AWARDS_LEADERBOARDS!;
const AWARDS_REPORTS = process.env.REACT_APP_AWARDS_REPORTS!;

type MostResponsiveData = {
  responseRate: number;
  receivedCount: number;
  respondedCount: number;
  activePositionsCount: number;
};

type FastestToRespondData = {
  receivedCount: number;
  activePositionsCount: number;
  avgResponseTimeHours: number;
};

type DiversityAdvocateData = {
  peopleSourced: number;
  femalesSourced: number;
  activePositionsCount: number;
  percentageOverAverage: number;
  percentageFemalesSourced: number;
};

export type AwardDataType = {
  [AwardType.MostResponsive]: MostResponsiveData;
  [AwardType.FastestToRespond]: FastestToRespondData;
  [AwardType.DiversityAdvocate]: DiversityAdvocateData;
};

export type AwardLeaderboard = AwardLeaderboardEntryInterface & AwardDataType[AwardType];

export const getLiveLeaderboard = async (
  award: AwardType,
  companySize: AwardCompanySize
): Promise<APIResponse<AwardLeaderboard[]>> => {
  try {
    const { data } = await axios.get<{ data: AwardLeaderboard[] }>(
      `${AWARDS_LEADERBOARDS}/${award}/${companySize}`
    );

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getLiveLeaderboardPosition = async (
  award: AwardType,
  companySize: AwardCompanySize,
  fresh: boolean = false
): Promise<APIResponse<number>> => {
  const params = fresh ? { params: { freshness: 'fresh' } } : {};
  try {
    const { data: axiosData } = await axios.get<{ data: number }>(
      `${AWARDS_LEADERBOARDS}/${award}/${companySize}/position`,
      params
    );
    return { status: 'success', data: axiosData.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getReport = async (
  companySize: AwardCompanySize,
  award: AwardType,
  season: AwardSeason,
  year: number
): Promise<APIResponse<AwardLeaderboard[]>> => {
  try {
    const { data } = await axios.get<{ data: AwardReportInterface }>(
      `${AWARDS_REPORTS}/${award}/${companySize}/${season}/${year}`
    );

    return { status: 'success', data: data.data.data as AwardLeaderboard[] };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
