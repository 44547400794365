import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { CandidateInsightsDeclineReasons } from '@cohiretech/common-types';

import { colours } from 'styles/theme/common';
import { isEmpty } from 'utils';
import { repeat } from 'utils/array';
import { pluck } from 'utils/object';
import { useDarkMode } from 'hooks/useDarkMode';

import { usePositionProfile } from '../PositionProfile.context';
import { InsightsBlock } from '../InsightsBlock';

const SUB_TITLE = 'Most common decline reasons';
const DEFAULT_TITLE = 'See why applications are being declined for this position ';
const NON_EMPTY_TITLE = 'If any could apply to you, address it in your outreach message';
const EMPTY_TITLE = 'No requests declined yet for this position';
const CTA_TEXT = 'Check your profile';
const EMPTY_DATA = [
  { reason: '', count: 0 },
  { reason: '', count: 0 },
  { reason: '', count: 0 },
  { reason: '', count: 0 },
  { reason: '', count: 0 }
];

export const DeclineReasonsInsightsBlock = () => {
  const { insightsData } = usePositionProfile();

  let declineReasons = insightsData.position?.declinedConversationsReasons || [];
  const isEmptyState = isEmpty(declineReasons);
  declineReasons = declineReasons.sort(byTotal).slice(0, 5);

  if (isEmptyState) {
    declineReasons = EMPTY_DATA as CandidateInsightsDeclineReasons[];
  }

  const title = isEmptyState ? EMPTY_TITLE : NON_EMPTY_TITLE;

  return (
    <InsightsBlock
      size="medium"
      subTitle={SUB_TITLE}
      title={title}
      defaultTitle={DEFAULT_TITLE}
      cta={isEmptyState ? undefined : CTA}
    >
      <ColumnChart
        data={declineReasons.map(pluck('count')) as number[]}
        categories={declineReasons.map(pluck('reason')) as string[]}
      />
    </InsightsBlock>
  );
};

const ColumnChart = ({ data, categories }: { data?: number[]; categories?: string[] }) => {
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const { isDummyData } = usePositionProfile();
  const darkMode = useDarkMode();

  useEffect(() => {
    setOptions(constructOptions(categories, data, isDummyData, darkMode));
    if (!data) return;
    setSeries([{ name: 'Reasons for decline count', data }]);
  }, [data, categories]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={290} />
    </div>
  );
};

const constructOptions = (
  categories: string[] = [],
  data: number[] = [],
  isDummyData: boolean,
  darkMode: boolean
): ApexOptions => {
  const labelFormatter = formatLabels();
  const colorStops = repeat(barColorLight, categories.length);
  const labelColors = repeat(colours.primaryColour, categories.length);

  return {
    chart: {
      height: 290,
      parentHeightOffset: 0,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    grid: {
      show: true,
      padding: { left: 2, right: 2, top: -10, bottom: 0 },
      borderColor: darkMode ? colours.fontColour : colours.lightFontColour,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 15,
        borderRadiusApplication: 'end',
        barHeight: '65%',
        distributed: true,
        dataLabels: {
          position: 'bottom'
        }
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      offsetX: 6,
      formatter: labelFormatter,
      style: {
        fontSize: '12px',
        colors: labelColors,
        fontFamily: '"Figtree", Helvetica, Arial, sans-serif'
      }
    },
    legend: {
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        colorStops
      }
    },
    tooltip: {
      enabled: true
    },
    theme: { mode: darkMode ? 'dark' : 'light' },
    xaxis: {
      categories,
      type: 'category',
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        formatter: (val: any) => val.toFixed(0),
        rotate: 0,
        style: {
          fontSize: '10px',
          colors: repeat('#869DB1', categories.length)
        }
      },
      crosshairs: {
        show: false
      }
    },
    yaxis: {
      max: getMax(data) || 16,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        formatter: (val: number) => `${val}`,
        maxWidth: 0
      }
    }
  };
};

const CTA = {
  text: CTA_TEXT,
  link: '/candidate/account'
};

const barColorLight = [
  {
    offset: 30,
    color: colours.primaryColour,
    opacity: 0.12
  },
  {
    offset: 111,
    color: '#7B7EE6',
    opacity: 0.12
  }
];

const formatLabels = () => (val: number, opt: any) => {
  const label = opt.w.globals.labels[opt.dataPointIndex];
  return label;
};

const getMax = (data: number[]) => {
  return Math.max(...data);
};

const byTotal = (a: { count: number }, b: { count: number }) => {
  return b.count - a.count;
};
