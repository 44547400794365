import React from 'react';
import ClassNames from 'classnames';

import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { useExpandableFilter } from '../../ExpandableFilter.context';
import OperatorToggle from '../OperatorToggle';

import { FilterTitle, FilterTitleCont } from './ExpandableFilterTitle.styled';

export default function ExpandableFilterTitle() {
  const { isOpen, helpIcon, filterName, appearance, totalSelection, icon, toggleFilterPanel } =
    useExpandableFilter();

  const isDropdownStyle = appearance === 'dropdown';
  const toggleIcon = getToggleIcon(isDropdownStyle, isOpen);

  return (
    <FilterTitleCont className="header_cont">
      <FilterTitle
        icon={toggleIcon}
        iconPosition={isDropdownStyle ? 'right' : 'left'}
        text={
          <>
            <span className="filter_label">
              <ConditionalRender predicate={icon}>
                <span className={icon} />
              </ConditionalRender>
              {filterName}
            </span>
            {totalSelection ? (
              <span className="total_selected">({totalSelection} Selected)</span>
            ) : null}
            {helpIcon && (
              <span className="icon_help">
                <Tooltip text={helpIcon.text} position={helpIcon.position} tooltipStyle="light" />
              </span>
            )}
          </>
        }
        color="dark"
        className={ClassNames('header', { expanded: isOpen })}
        action={toggleFilterPanel}
      />
      <OperatorToggle />
    </FilterTitleCont>
  );
}

const getToggleIcon = (isDropdownStyle: boolean, isOpen: boolean) => {
  if (isDropdownStyle) return isOpen ? 'icon_simple_arrow_up' : 'icon_simple_arrow_down';
  return isOpen ? 'icon_minimize' : 'icon_add';
};
