import React from 'react';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { mobile } from 'responsiveConfig';
import {
  getTimeAgo,
  getResponseRate,
  getDuration,
  formatNumber,
  getCurrencySymbol,
  getPendingRequests,
  getApplicationScoreLabel
} from 'utils';

import Tooltip from 'components/tooltip';

const getStatus = (isCandidate: $TSFixMe, status: $TSFixMe) => {
  if (isCandidate) return status === 'active' ? 'open' : 'not looking';
  return status;
};

export default function ProfileStats({
  lastActive,
  responseRate,
  avgResponseTime,
  conversations,
  outstandingApplications,
  requests,
  datePosted,
  lastCheckedDate,
  minSalary,
  companySize,
  currency,
  status,
  score,
  noticePeriod,
  isCandidate,
  onStatClick,
  loading
}: $TSFixMe) {
  if (loading) {
    return (
      <StatsWrapper className="stats_wrapper">
        <Item className="stat_item">
          <Label>
            <Skeleton width={60} height={15} duration={2} />
          </Label>
          <Stat>
            <Skeleton width={80} height={15} duration={2} />
          </Stat>
        </Item>
        <Item className="stat_item">
          <Label>
            <Skeleton width={60} height={15} duration={2} />
          </Label>
          <Stat>
            <Skeleton width={80} height={15} duration={2} />
          </Stat>
        </Item>
        <Item className="stat_item">
          <Label>
            <Skeleton width={60} height={15} duration={2} />
          </Label>
          <Stat>
            <Skeleton width={80} height={15} duration={2} />
          </Stat>
        </Item>
      </StatsWrapper>
    );
  } else {
    return (
      <StatsWrapper className="stats_wrapper">
        {lastActive?.label && (
          <Item className="stat_item">
            <Label>
              Active
              {lastActive.tooltip ? (
                <span className="icon_help">
                  <Tooltip>{lastActive.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className="green_text">{getTimeAgo(lastActive.label, true)}</Stat>
          </Item>
        )}
        {status?.label && (
          <Item className="stat_item">
            <Label>
              Status
              {status.tooltip ? (
                <span className="icon_help">
                  <Tooltip>{status.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className={status.label === 'active' ? 'lightblue_text' : 'red_text'}>
              {getStatus(isCandidate, status.label)}
            </Stat>
          </Item>
        )}
        {datePosted?.label && (
          <Item className="stat_item">
            <Label>Posted</Label>
            <Stat className="green_text">{getTimeAgo(datePosted.label, false)}</Stat>
          </Item>
        )}
        {(lastCheckedDate?.label || datePosted?.label) && (
          <Item className="stat_item">
            <Label>
              Checked
              {lastCheckedDate?.tooltip ? (
                <span className="icon_help">
                  <Tooltip>{lastCheckedDate.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className="lightblue_text">
              {getTimeAgo(lastCheckedDate?.label || datePosted?.label, false)}
            </Stat>
          </Item>
        )}
        {(responseRate?.label >= 0 || responseRate?.label === 'N/A') && (
          <Item className="stat_item">
            <Label>
              Responds to
              {responseRate.tooltip ? (
                <span className="icon_help">
                  <Tooltip width="290px">{responseRate.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className={responseRate.label === 'N/A' ? 'grey_text' : 'lightblue_text'}>
              {getResponseRate(responseRate.label)}
            </Stat>
          </Item>
        )}
        {score?.label && score?.label !== 'N/A' && (
          <Item className="stat_item">
            <Label>
              Role fit
              {score.tooltip ? (
                <span className="icon_help">
                  <Tooltip width="290px">{score.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className="lightblue_text">{getApplicationScoreLabel(score.label)}</Stat>
          </Item>
        )}
        {avgResponseTime?.label && (
          <Item className="stat_item">
            <Label>
              Responds in
              {avgResponseTime.tooltip ? (
                <span className="icon_help">
                  <Tooltip>{avgResponseTime.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className={avgResponseTime.label === 'N/A' ? 'grey_text' : 'orange_text'}>
              {getDuration(avgResponseTime.label)}
            </Stat>
          </Item>
        )}
        {requests?.label > 0 && (
          <Item className="stat_item">
            <Label>
              Requests
              {requests.tooltip ? (
                <span className="icon_help">
                  <Tooltip>{requests.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className="lightblue_text">{requests.label} received</Stat>
          </Item>
        )}
        {outstandingApplications?.label !== undefined && (
          <Item className="stat_item">
            <Label>
              Requests
              {outstandingApplications.tooltip ? (
                <span className="icon_help">
                  <Tooltip>{outstandingApplications.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className="grey_text">
              {getPendingRequests(outstandingApplications.label, conversations.label)}
            </Stat>
          </Item>
        )}
        {conversations?.label > 0 && (
          <Item className="stat_item">
            <Label>
              Conversations
              {conversations.tooltip ? (
                <span className="icon_help">
                  <Tooltip>{conversations.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat className="purple_text">{conversations.label} started</Stat>
          </Item>
        )}
        {minSalary?.label && (
          <Item className="stat_item">
            <Label>Exp. Salary</Label>
            <Stat className="green_text">
              {getCurrencySymbol(currency) + formatNumber(minSalary.label)}
            </Stat>
          </Item>
        )}
        {noticePeriod?.label && (
          <Item className="stat_item">
            <Label>
              Availability
              {noticePeriod.tooltip ? (
                <span className="icon_help">
                  <Tooltip>{noticePeriod.tooltip}</Tooltip>
                </span>
              ) : (
                ''
              )}
            </Label>
            <Stat
              className="purple_text"
              onClick={() => onStatClick('noticePeriod')}
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              clickable={noticePeriod.tooltip}
              showTooltip={!noticePeriod.label}
            >
              {noticePeriod.label || 'N/A'}
              {noticePeriod.tooltip && (
                <Tooltip position="bottom">
                  {noticePeriod.label ? 'Update your availability' : 'Add your availability'}
                </Tooltip>
              )}
            </Stat>
          </Item>
        )}
        {companySize?.label && (
          <Item className="stat_item">
            <Label>Size</Label>
            <Stat className="lightblue_text">{companySize.label} employees</Stat>
          </Item>
        )}
      </StatsWrapper>
    );
  }
}

const StatsWrapper = styled.div`
  padding: 35px 0 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: center;

  @media only screen and (max-width: ${mobile}) {
    padding: 30px 0 0;
  }
`;

const Item = styled.div`
  position: relative;
  margin: 0 20px 30px;

  @media only screen and (max-width: ${mobile}) {
    margin: 0 10px 20px;
  }
`;

const Label = styled.div`
  font-weight: ${({ theme: { typography } }) => typography.bold};
  font-size: 14px;
`;

const Stat = styled.div`
  padding: 5px 0 0;

  ${props =>
    (props as $TSFixMe).showTooltip &&
    css`
      > .tooltip_wrapper {
        display: block;
        opacity: 1;
      }
    `}

  ${props =>
    (props as $TSFixMe).clickable &&
    css`
      cursor: pointer;
      position: relative;

      &:hover > .tooltip_wrapper {
        display: block;
        opacity: 1;
      }
    `}

  @media only screen and (max-width: ${mobile}) {
    padding: 5px 0;
  }
`;
