import styled from 'styled-components';

import media from 'styles/media';

export const Banner = styled.div`
  position: relative;
  background: ${({ theme: { colours } }) => colours.transparentCordGradient};
  padding: 1rem 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: space-between;
`;

export const BodyContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .radial_chart {
    margin-top: -1.25rem;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  * {
    text-align: left;
  }

  ${media.mobile`
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    align-items: flex-start;

    .link {
      margin-top: 0.5rem;
    }
  `}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-weight: bold;
`;

export const Body = styled.div`
  font-weight: ${({ theme: { typography } }) => typography.bold};

  ${media.mobile`
    font-weight: normal;
  `}
`;

export const MatchScoreContainer = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile`
    display: none;
  `}

  .loader_wrapper {
    width: 20px;
    height: 20px;
  }

  .radial_chart {
    position: absolute;
    right: 0.25rem;
  }
`;

export const MatchScore = styled.strong`
  font-size: 14px;
  margin-right: 1rem;

  &.extra_margin_right {
    margin-right: 2.75rem;
  }
`;
