import React, { lazy, Suspense, useState } from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';
import { Location } from 'history';

import { ListingStatus } from '@cohiretech/common-types';

import { generateCompanyURL, generatePositionURL, generatePersonsURL, retry } from 'utils';
import { isMobileWidth, mobile, smallLaptop } from 'responsiveConfig';
import { isString } from 'utils/fn/typeGuards';

import PopOver from 'components/popover';
import CopyText from 'components/copytext';
import ShareButtons from 'components/sharebuttons';
import Tooltip from 'components/tooltip';

import Breadcrumbs from 'v2/components/ui/molecules/Breadcrumbs';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

// @ts-expect-error TS(2322) FIXME: Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
const MultiStageToggle = lazy(() => retry(() => import('components/multistagetoggle')));

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

export const SHARE_URL_TYPE = {
  COMPANY: 'COMPANY',
  USER: 'USER',
  POSITION: 'POSITION'
};

type Props = {
  location: Location;
  menuIconClicked: boolean;
  companyName: string;
  positionID?: number;
  companyID: number;
  positionName?: string;
  accountID?: number;
  title: React.ReactNode;
  isMobileTitle: boolean;
  memberName: string;
  pageStages: any;
  shareURLType: string;
  darkMode: boolean;
  isPositionHidden?: boolean;
  positionStatus?: ListingStatus;
};

export default function CompanySideBarPageTitle({
  location,
  menuIconClicked,
  companyName,
  positionID,
  companyID,
  positionName,
  accountID,
  title,
  positionStatus,
  isMobileTitle,
  memberName,
  pageStages,
  shareURLType,
  darkMode,
  isPositionHidden
}: Props) {
  const [showSharePopOver, setShowSharePopOver] = useState(false);

  const getShareURL = () => {
    switch (shareURLType) {
      case SHARE_URL_TYPE.COMPANY:
        return `${domain}${generateCompanyURL(location, { companyName, companyID }, true)}`;
      case SHARE_URL_TYPE.USER:
        return `${domain}${generatePersonsURL(
          location,
          { personsName: memberName, personsID: accountID!, companyName },
          true
        )}`;
      case SHARE_URL_TYPE.POSITION:
        return `${domain}${generatePositionURL(
          location,
          { companyName, positionID: positionID!, positionName: positionName! },
          true
        )}`;
      default:
        return null;
    }
  };

  const shareURL = getShareURL();

  const toggleSharePopOver = () => {
    if (showSharePopOver) {
      setTimeout(() => {
        setShowSharePopOver(false);
      }, 1500);
    } else setShowSharePopOver(true);
  };

  return (
    <PageTitleWrapper
      className={ClassNames('page_title', {
        hide: menuIconClicked,
        mobile_title: isMobileTitle,
        dark: darkMode
      })}
    >
      {Array.isArray(title) ? <Breadcrumbs breadcrumbs={title} /> : title}
      {pageStages && pageStages.length > 0 && (
        <Suspense fallback=" ">
          <MultiStageToggle stages={pageStages} />
        </Suspense>
      )}
      <ConditionalRender predicate={isString(shareURL) && positionStatus === ListingStatus.Active}>
        <span className="icon_share" onClick={toggleSharePopOver}>
          <PopOver show={showSharePopOver} darkMode={darkMode}>
            <strong>{`Share your ${shareURLType?.toLowerCase()} profile`}</strong>
            <CopyText id="share_profile_url" text={shareURL!} darkMode={darkMode} />
            <ShareButtons
              // @ts-expect-error TS(2322) FIXME: Type '{ shareURL: string; linkElementID: string; l... Remove this comment to see the full error message
              shareURL={shareURL}
              linkElementID="profile_link"
              linkedin={{ text: 'Post' }}
              facebook={{ text: 'Share' }}
              twitter={{
                text: 'Tweet',
                message: "We're hiring on cord. Check out our profile and open positions."
              }}
              responsiveCheck={{ isMaxScreenWidth: isMobileWidth, maxScreenWidth: mobile }}
            />
          </PopOver>
        </span>
      </ConditionalRender>
      <ConditionalRender predicate={positionStatus === ListingStatus.PendingReview}>
        <span className="icon_pending">
          <Tooltip position="bottom" text="This position is being reviewed." />
        </span>
      </ConditionalRender>
      <ConditionalRender predicate={positionStatus === ListingStatus.Archived}>
        <span className="icon_closed">
          <Tooltip text="This position is closed." position="bottom" />
        </span>
      </ConditionalRender>
      {isPositionHidden && (
        <span className="icon_hide">
          <Tooltip text="This position is invisible on cord." position="bottom" />
        </span>
      )}
    </PageTitleWrapper>
  );
}

const PageTitleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  padding: 1.5rem;
  font-size: 24px;
  line-height: 40px;
  font-weight: ${typography.bold};
  white-space: nowrap;
  opacity: 1;
  transition: all 0.3s ease-in-out;

  .page_title_text {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100vw - 260px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.mobile_title {
    display: none;
  }

  @media only screen and (max-width: ${mobile}) {
    font-size: 22px;

    .page_title_text {
      max-width: calc(100vw - 120px);
    }
  }

  @media only screen and (max-width: ${smallLaptop}) {
    position: fixed;
    left: 0;
    padding: 0.75rem;
    margin-left: 4rem;
    z-index: 1;

    &.mobile_title {
      display: block;
    }

    &.hide {
      opacity: 0;
    }
  }

  .icon_share {
    cursor: pointer;
    @media only screen and (max-width: ${mobile}) {
      display: none;
    }
  }

  nav ol {
    margin: 0;

    .breadcrumbs_link {
      font-weight: ${typography.black};
    }

    li:last-child {
      font-weight: ${typography.regular};
    }
  }

  > [class^='icon'] {
    font-weight: ${typography.black};
    position: relative;
    vertical-align: middle;
    margin-left: 0.75rem;
    font-size: 24px;
    opacity: 0.8;

    &.icon_share {
      color: ${colours.primaryColour};
    }

    &:hover,
    &:focus-visible {
      opacity: 1;

      .tooltip_wrapper {
        display: block;
      }
    }
  }

  .multi_stage_toggle {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 1rem;

    @media only screen and (max-width: ${mobile}) {
      vertical-align: middle;
    }
  }
`;
