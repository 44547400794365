import { setLocalStorageItem } from 'cookieManager';
import { getMillisecondsByType } from 'utils';

// For when a new user joins an existing company account
export const setSetupModeLS = (showOnboardingBubble = true) => {
  const dayMilliseconds = getMillisecondsByType('days');

  setLocalStorageItem('setup_mode', true);
  setLocalStorageItem('setup_step', 1);
  if (showOnboardingBubble) {
    setLocalStorageItem('show_onboarding_bubble', true, 14 * dayMilliseconds);
  }
};
