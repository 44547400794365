import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ApplicationCandidateStartStatus as ACSS } from '@cohiretech/common-types';

import { identifyCompanyType } from 'v2/services/company/companyType';
import { generatePersonsURL, getTimeAgo } from 'utils';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import LinkWithIcon from 'components/linkwithicon';
import LabelWithIcon from 'components/labelwithicon';

import Avatar from 'v2/components/ui/atoms/Avatar';
import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { BoldText } from 'v2/components/ui/styles/StyledText';
import { useMarkApplicationPopup } from 'v2/components/MarkApplicationPopup';

import Company from 'images/company.png';

import { usePositionProfile } from '../../PositionProfile.context';

import PositionProfileTopLeftButton from './PositionProfileTopLeftButton';

export default function PositionProfileTopSection() {
  const {
    associatedMemberDetails,
    companyName,
    companyLogo,
    companyURL,
    updateProfileHistory,
    loading,
    companyType,
    applicationDetails,
    isCandidateCreatedATPosition,
    lastUpdatedStageDate
  } = usePositionProfile();

  const { memberName = '', photoURL, accountID = 0 } = associatedMemberDetails || {};
  const location = useLocation();
  const memberProfileURL = generatePersonsURL(location, {
    personsName: memberName,
    personsID: accountID,
    companyName
  });

  const { showMarkApplicationPopup } = useMarkApplicationPopup();
  const isMobile = useMedia([mediaQueries.mobile]);

  const { appliedExternallyDate, applicationCandidateStartStatus } = applicationDetails || {};
  const { isAgency, isExternal } = identifyCompanyType(companyType);
  const avatarWidth = isMobile ? 100 : 140;

  return (
    <PositionProfileTop className="position_profile_top_section" avatarHeight={`${avatarWidth}px`}>
      <ProfilePictures
        className="profile_pictures"
        isCandidateCreatedATPosition={isCandidateCreatedATPosition}
      >
        <Avatar
          className="company_photo"
          width={avatarWidth}
          photoURL={companyLogo}
          fallbackPhotoURL={isCandidateCreatedATPosition ? '' : Company}
          firstname={companyName}
          photoLink={companyURL}
          onClick={updateProfileHistory}
          linkTitle={`View ${companyName}'s profile`}
          loading={loading}
        />
        <ConditionalRender predicate={isAgency}>
          <Avatar
            className="user_photo"
            width={avatarWidth}
            photoURL={photoURL}
            firstname={memberName}
            photoLink={memberProfileURL}
            onClick={updateProfileHistory}
            linkTitle={`View ${memberName}`}
            loading={loading}
            resizePhotoDimensions={{ width: 150 }}
          />
        </ConditionalRender>
      </ProfilePictures>
      <ActionsContainer className="actions_container">
        <PositionProfileTopLeftButton />
        <ConditionalRender predicate={isExternal}>
          <ConditionalRender
            predicate={applicationCandidateStartStatus === ACSS.AppliedExternally}
            fallback={
              <LinkWithIcon
                icon="icon_upload_successful"
                text={isMobile ? '' : 'Mark as applied'}
                color="grey"
                action={() => showMarkApplicationPopup('confirmation')}
              />
            }
          >
            <ConditionalRender predicate={!isMobile}>
              <LabelWithIcon
                icon="icon_apply"
                text={[
                  <BoldText key="icon_label_text">Applied</BoldText>,
                  ` ${getTimeAgo(appliedExternallyDate)}`
                ]}
                tag
                color="secondary"
                size="small"
              />
            </ConditionalRender>
          </ConditionalRender>
        </ConditionalRender>
        <ConditionalRender predicate={isCandidateCreatedATPosition && !isMobile}>
          <LabelWithIcon
            icon="icon_apply"
            text={`Added ${getTimeAgo(lastUpdatedStageDate)}`}
            tag
            color="secondary"
            size="small"
          />
        </ConditionalRender>
      </ActionsContainer>
    </PositionProfileTop>
  );
}

const PROFILE_PICTURES_MARGIN_TOP = '-75px';
const PROFILE_PICTURES_MARGIN_TOP_MOBILE = '-4rem';

const PositionProfileTop = styled.div<{ avatarHeight: string }>`
  ${({ avatarHeight, theme: { media } }) => css`
    position: relative;
    height: calc(${avatarHeight} + ${PROFILE_PICTURES_MARGIN_TOP});

    ${media.mobile`
      height: calc(${avatarHeight} + ${PROFILE_PICTURES_MARGIN_TOP_MOBILE});
    `}
  `}
`;

const ActionsContainer = styled.div`
  ${({ theme: { typography, media } }) => css`
    padding: 0.75rem 1.5rem;
    display: flex;
    position: relative;

    .link_with_icon,
    .label_with_icon {
      .icon {
        font-size: var(--type-sm-md);
        font-weight: ${typography.black};
      }
    }

    ${media.mobile`
      padding: 0 1.25rem;
    `}
  `}
`;

const ProfilePictures = styled.div<{ isCandidateCreatedATPosition: boolean }>`
  width: 100%;
  position: absolute;
  top: ${PROFILE_PICTURES_MARGIN_TOP};

  .company_photo .avatar_img {
    object-fit: contain;
  }

  .profile_picture:last-child:not(:first-child) {
    margin-left: -2rem;
  }

  .react-loading-skeleton {
    border-radius: 44% !important;
  }

  ${({ theme: { media } }) => media.mobile`
    top: ${PROFILE_PICTURES_MARGIN_TOP_MOBILE};
    
    .profile_picture {
      z-index: 10;

      &:last-child:not(:first-child) {
        margin-left: -1rem;
      }
    }
  `}

  ${({ isCandidateCreatedATPosition }) =>
    isCandidateCreatedATPosition &&
    css`
      .company_photo.no_photo .profile_picture_initials {
        font-size: var(--type-6xl);
      }
    `}
`;
