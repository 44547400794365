import React, { Fragment, PropsWithChildren, ReactNode } from 'react';
import ClassNames from 'classnames';
import { ReactComponentLike } from 'prop-types';

type ConditionalWrapperProps = PropsWithChildren<{
  predicate: boolean;
  Wrapper: ReactComponentLike | ReactNode;
  Fallback?: ReactComponentLike | ReactNode;
  id?: string;
  className?: string;
}>;

type TagProps = PropsWithChildren<{
  tag: any;
  id?: string;
  className?: string;
}>;

const RenderHtml = ({ tag: Tag, id, className, children }: TagProps) => (
  <Tag id={id} className={ClassNames('conditional_wrapper', className)}>
    {children}
  </Tag>
);

const CloneAndRenderReactElement = ({ tag: Tag, children }: Pick<TagProps, 'tag' | 'children'>) =>
  React.cloneElement(Tag, {}, children);

const RenderFragment = ({ tag: Tag, children }: Pick<TagProps, 'tag' | 'children'>) => (
  <Tag>{children}</Tag>
);

const ConditionalWrapper = ({
  predicate,
  Wrapper,
  Fallback = Fragment,
  id,
  className,
  children
}: ConditionalWrapperProps) => {
  const renderComponentOrHtmlTag = (Tag: any) => {
    const tagProps = { tag: Tag, id, className };

    if (Tag === Fragment) {
      return <RenderFragment {...tagProps}>{children}</RenderFragment>;
    } else if (React.isValidElement(Tag)) {
      return <CloneAndRenderReactElement {...tagProps}>{children}</CloneAndRenderReactElement>;
    } else {
      return <RenderHtml {...tagProps}>{children}</RenderHtml>;
    }
  };

  return predicate ? renderComponentOrHtmlTag(Wrapper) : renderComponentOrHtmlTag(Fallback);
};

export default ConditionalWrapper;
