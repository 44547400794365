import React from 'react';
import styled from 'styled-components';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { CORD_LOGO, CORD_MARK } from 'consts';

export default function HomeLinkImage() {
  const isTablet = useMedia([mediaQueries.tablet]);

  return <CordMark src={isTablet ? CORD_MARK : CORD_LOGO} alt="cord logo" height="40" />;
}

const CordMark = styled.img`
  vertical-align: middle;
`;
