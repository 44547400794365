import React from 'react';
import ClassNames from 'classnames';

import { useDarkMode } from 'hooks/useDarkMode';

import PageSection from 'components/pagesection';
import Seo from 'components/seo';
import SplitStepsSection from 'components/splitstepssection';

export default function ConversationCode({ location }: $TSFixMe) {
  const darkMode = useDarkMode();
  return (
    <div id="conversations_code_page">
      <div className="intro_wrapper">
        <div className="min_view center_alignment">
          <h1 className="heading">Mission</h1>
          <h2 className="subheading">
            cord's mission is to increase the number of people doing their best work.
          </h2>
          <div className="description">
            <p>
              We make that happen by enabling the most simple transaction on earth - a conversation.
              Creating a space for meaningful conversations to take place means ensuring that the
              space is a safe one.
            </p>
            <p>
              This means we have a zero tolerance approach to any type of abuse, discrimination,
              unsafe behaviour or any other violations of our guidelines. By continuously holding
              ourselves and all cord users to the same high standard, we strive to ensure that
              everyone has the same opportunity to truly be themselves and do their best work.
            </p>
          </div>
        </div>
      </div>
      <PageSection
        title="Conversation values"
        subTitle={[
          'cord is a place that people can ',
          <strong key="trust" className="underline white">
            trust
          </strong>,
          ' and that feels ',
          <strong key="safe" className="underline white">
            safe
          </strong>,
          '. For this to happen, our users must treat each other with ',
          <strong key="respect" className="underline white">
            respect
          </strong>,
          '. Our 3 conversation values are built around these principles.'
        ]}
        className={ClassNames('center_alignment', 'conversation_values', 'blue_background')}
        widthClassName="min_view"
      />
      <SplitStepsSection
        largeTitle
        steps={[
          {
            step: 'Respect',
            content: (
              <ul>
                <li>Do not be hateful or discriminatory.</li>
                <li>Do not harass, abuse, bully, troll or cause unjustified nuisance.</li>
                <li>Do not write statements that are defamatory, libellous, or untrue.</li>
                <li>Do not engage in unwanted advances.</li>
                <li>
                  Do not write statements that are obscene, pornographic, violent or otherwise may
                  offend human dignity.
                </li>
              </ul>
            )
          },
          {
            step: 'Trust',
            content: (
              <ul>
                <li>
                  Do not create a fake profile, use falsified information about yourself or
                  otherwise misrepresent your identity.
                </li>
                <li>Do not use cord to promote third party services.</li>
                <li>
                  Do not share images of or information about other people without their consent.
                </li>
                <li>Do not reveal anyone else’s private information (doxing).</li>
              </ul>
            )
          },
          {
            step: 'Safety',
            content: (
              <ul>
                <li>Do not message or post content involving harmful or shocking material.</li>
                <li>
                  Do not engage in, encourage or facilitate any illegal activities such as threats
                  of violence or terrorism.
                </li>
                <li>Comply with the law and respect the rights of other users and of cord.</li>
                <li>Do not access cord in an unauthorised manner.</li>
              </ul>
            )
          }
        ]}
      />
      <PageSection widthClassName="min_view" className="center_alignment" title="Reporting">
        <div className="description">
          <p>
            With the help of our users, cord moderates content and takes appropriate actions in
            relation to those who do not respect the conversation values.
          </p>
          <p>
            Behaviour and content can be reported by users or our Trust & Safety Team and/or their
            automated tools. If you see something you think might violate our policies or makes you
            feel unsafe, please report it to us.
          </p>
          <p>
            In every conversation you have two options - you can report the user or you can report
            and block them. If you did not block the user when first reporting them, you still have
            the option to do that later.
          </p>
          <p>
            When submitting your report, you can pick from the list of proposed reasons or choose
            the ‘something else’ option if you do not see what you are looking for exactly. You will
            then have space to describe the precise reason for the report. If at any point you would
            like to contact us to give additional details, you can do so at{' '}
            <a href="mailto:trustandsafety@cord.co" className="link">
              trustandsafety@cord.co
            </a>
            .
          </p>
          <p>
            All reports are anonymous; the other user will not be notified that you reported them.
            If you block a user, they will see in the conversation that they have been blocked.
          </p>
        </div>
      </PageSection>
      <PageSection
        widthClassName="min_view"
        className={ClassNames('center_alignment', {
          grey_background: !darkMode,
          dark_blue_background: darkMode
        })}
        title="Review & Enforcement"
      >
        <div className="description">
          <p>
            Every report is reviewed by our Trust & Safety Team. We will reach out to confirm the
            receipt of your report. We typically will only follow up in situations when we need more
            information on our investigation, but please rest assured that we will thoroughly review
            your report.
          </p>
          <p>
            When identifying a violation of our terms and policies, we will take the necessary
            action, which may include:
            <ul className="appearance_disc">
              <li>
                Informing a user that a violation of cord’s terms was identified and the may have to
                adjust their behaviour in order to not be penalised in the future;
              </li>
              <li>Temporarily or permanently suspending an account;</li>
              <li>Removing an account from cord.</li>
            </ul>
          </p>
          <p>
            Based on the severity of the offence and the number of prior reports, an account
            suspension can be for a defined amount of time or be a permanent one.
          </p>
        </div>
      </PageSection>
      <Seo
        title="Conversations Code"
        description="cord is a place that people can trust and that feels safe. For this to happen, our users must treat each other with respect. Our 3 conversation values are built around these principles."
        path={location.pathname}
        contentType="website"
      />
    </div>
  );
}
