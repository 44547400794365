import { History } from 'history';

import { JobTitleOrderingValues } from '@cohiretech/common-types';

import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { getFilterOptions, getSavedSearchIDFromURL, isEmpty } from 'utils';
import {
  getCandidateSavedSearches,
  removeCandidateSavedSearch,
  updateCandidateSavedSearch
} from 'v2/services/fetchers/candidate/savedSearch';
import { getSearchOptionsByCategories } from 'v2/services/fetchers/candidate/searchOptions';
import { getCurrencySymbolFromProfile } from 'v2/services/tools/salary';

import { setCurrentSearch, setSavedSearchToDelete } from './candidateSearch';
import { UpdateCandidateSavedSearchParams, findSavedSearchById } from './candidateSearch.helpers';

export const fetchCandidateSearchOptions = createAppAsyncThunk(
  'candidateSearch/fetchSearchOptions',
  async () => {
    const response = await getSearchOptionsByCategories();
    return response;
  },
  {
    condition: (_, { getState }) => {
      const { searchOptions } = getState().candidateSearch;
      return isEmpty(searchOptions);
    }
  }
);

export const fetchCandidateFilterOptions = createAppAsyncThunk(
  'candidateSearch/fetchFilterOptions',
  async (isHiringInsights: boolean | undefined, { getState }) => {
    const { profile, userType } = getState().user;
    const currencySymbol = getCurrencySymbolFromProfile(profile);

    const response = await getFilterOptions({
      isCandidate: userType === 'candidate',
      currency: currencySymbol,
      isHiringInsights,
      jobTitleSortBy: JobTitleOrderingValues.Label
    });
    return response;
  },
  {
    condition: (_, { getState }) => {
      const { filterOptions } = getState().candidateSearch;
      return isEmpty(filterOptions);
    }
  }
);

export const fetchCandidateSavedSearches = createAppAsyncThunk(
  'candidateSearch/fetchSavedSearches',
  async () => {
    const response = await getCandidateSavedSearches();
    return response;
  },
  {
    condition: (reload: boolean | undefined, { getState }) => {
      const { savedSearches } = getState().candidateSearch;
      return reload || isEmpty(savedSearches);
    }
  }
);

export const saveCandidateSavedSearch = createAppAsyncThunk(
  'candidateSearch/saveSavedSearch',
  async (
    { id, searchName, searchParam, notificationSettings }: UpdateCandidateSavedSearchParams,
    { getState }
  ) => {
    const { savedSearches } = getState().candidateSearch;
    const savedSearch = findSavedSearchById(savedSearches, id).value;

    const response = await updateCandidateSavedSearch(
      id,
      searchName || savedSearch.searchName,
      searchParam || savedSearch.searchParam.params,
      notificationSettings
    );

    return response;
  }
);

export const deleteCandidateSavedSearch = createAppAsyncThunk(
  'candidateSearch/deleteSavedSearch',
  async (history: History, { dispatch, getState }) => {
    const { savedSearchToDelete } = getState().candidateSearch;
    const { id } = savedSearchToDelete!;

    const { status } = await removeCandidateSavedSearch(id);

    if (status === 'success') {
      dispatch(setSavedSearchToDelete());
      dispatch(fetchCandidateSavedSearches(true));

      const { pathname } = history.location;
      const isCurrentSavedSearch = id === getSavedSearchIDFromURL(pathname);

      if (isCurrentSavedSearch) {
        dispatch(setCurrentSearch());
        history.push(`/candidate/search/new`);
      }
    }
  }
);
