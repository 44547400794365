import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { DefaultCV } from '@cohiretech/common-types';

import { editProfileInfo } from 'fetcher';
import { noop, throttle, noopAsync, noFilter } from 'utils/fn';
import { useSearchParams } from 'hooks/useSearchParams';
import { usePrevious } from 'hooks/usePrevious';

import TourBubble from 'components/tourbubble';

import Bubble from 'v2/components/ui/atoms/Bubble';
import RadioButtons, { RadioButtonOption } from 'v2/components/ui/molecules/RadioButtons';

import {
  UPLOAD_CV_OPTION,
  createCVMenuOptions,
  markSelected,
  onCordCV
} from './CVDropdown.helpers';
import { Divider, MenuLink } from './CVDropdown.styled';

export type CVDropdownProps = {
  defaultCV?: DefaultCV;
  openCordCV: () => Promise<void>;
  isVerified: boolean;
  toggleCVUploaderPopUp: (value: boolean) => void;
  toggleCVSettings?: (value: boolean) => void;
  originalCVURL?: string;
};

const CVDropdown = ({
  defaultCV = DefaultCV.Cord,
  openCordCV = noopAsync,
  isVerified,
  toggleCVUploaderPopUp = noop,
  toggleCVSettings,
  originalCVURL
}: CVDropdownProps) => {
  const location = useLocation();
  const { searchParams, updateSearchParams } = useSearchParams();

  const [showMenu, setShowMenu] = useState(false);
  const [showCVUpdateBubble, toggleCVUpdateBubble] = useState(searchParams.cv_prompt === 'true');
  const [radioOptions, setRadioOptions] = useState<RadioButtonOption[]>(
    createCVMenuOptions({ openCordCV, toggleCVSettings, originalCVURL })
  );
  const pointerRef = useRef<HTMLDivElement>(null);
  const prevOriginalCVURL = usePrevious(originalCVURL);

  const toggleMenu = useCallback(
    throttle((value: boolean) => setShowMenu(value), 200),
    []
  );

  const setSelectedOption = (value: RadioButtonOption['value'], cvMenuOptions = radioOptions) => {
    const options = markSelected(cvMenuOptions, value).filter(isVerified ? noFilter : onCordCV);
    setRadioOptions(options);
  };

  const setDefaultCV = async ({ value }: RadioButtonOption) => {
    const { status } = await editProfileInfo({ defaultCV: value });

    if (status === 'success') {
      setSelectedOption(value);
    }
  };

  const handleSelect = useCallback(throttle(setDefaultCV, 200), []);

  const handleUploadCV = () => {
    toggleCVUploaderPopUp(true);
    setShowMenu(false);
    clearLocationSearch();
  };

  const clearLocationSearch = () => {
    updateSearchParams({ cv_prompt: '' });
  };

  useEffect(() => {
    let cvMenuOptions = radioOptions;

    if (prevOriginalCVURL !== originalCVURL) {
      cvMenuOptions = createCVMenuOptions({ openCordCV, toggleCVSettings, originalCVURL });
    }

    setSelectedOption(defaultCV as string, cvMenuOptions);
  }, [defaultCV, originalCVURL]);

  useEffect(() => {
    toggleCVUpdateBubble(searchParams.cv_prompt === 'true');
  }, [location.search]);

  return (
    <>
      <span className="icon_cv" onClick={() => toggleMenu(!showMenu)} ref={pointerRef} />
      <TourBubble
        show={showCVUpdateBubble}
        title="Upload your up to date CV"
        body="Your CV might be outdated. Upload the latest version, so companies can see your most recent experience."
        buttonText="Upload new CV"
        onButtonClick={handleUploadCV}
        onClose={() => {
          clearLocationSearch();
          toggleCVUpdateBubble(false);
        }}
        align="center"
        position="bottom"
      />
      <Bubble
        show={showMenu}
        placement="bottom"
        anchorElem={pointerRef}
        noPadding
        onClose={() => showMenu && toggleMenu(false)}
      >
        <div>
          <RadioButtons
            options={radioOptions}
            onChange={handleSelect}
            selected={radioOptions.find(option => option.badge)}
          />
          <Divider />
          <MenuLink icon="icon_upload" text={UPLOAD_CV_OPTION.label} action={handleUploadCV} />
        </div>
      </Bubble>
    </>
  );
};

export default CVDropdown;
