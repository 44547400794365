import { PositionMetadata } from 'types';
import { useAppDispatch, useAppSelector } from 'store/store';

import { PositionType } from 'views/positionprofile';

import {
  cacheNavigatablePosition as cacheNavigatablePositionAction,
  clearCachedNavigatablePositions as clearCachedNavigatablePositionsAction,
  setNavigatablePositionIDs as setNavigatablePositionIDsAction,
  cacheNavigatablePositionMetadata as cacheNavigatablePositionMetadataAction
} from './navigatablePositions';
import {
  selectCachedNavigatablePositions,
  selectCachedNavigatablePositionsMetadata,
  selectNavigatablePositionsIDs,
  selectShouldShowNavigatablePositions
} from './navigatablePositions.selectors';

export const useNavigatablePositionsStore = () => {
  const navigatablePositionsIDs = useAppSelector(selectNavigatablePositionsIDs) as number[];
  const cachedNavigatablePositions = useAppSelector(selectCachedNavigatablePositions);
  const shouldShowNavigatablePositions = useAppSelector(selectShouldShowNavigatablePositions);
  const cachedNavigatablePositionsMetadata = useAppSelector(
    selectCachedNavigatablePositionsMetadata
  );

  const dispatch = useAppDispatch();
  const cacheNavigatablePosition = (position: PositionType) => {
    dispatch(cacheNavigatablePositionAction(position));
  };
  const cacheNavigatablePositionMetadata = (metadata: PositionMetadata) => {
    dispatch(cacheNavigatablePositionMetadataAction(metadata));
  };
  const clearCachedNavigatablePositions = () => {
    dispatch(clearCachedNavigatablePositionsAction());
  };
  const setNavigatablePositionIDs = (ids: number[]) => {
    dispatch(setNavigatablePositionIDsAction(ids));
  };

  return {
    navigatablePositionsIDs,
    setNavigatablePositionIDs,
    cachedNavigatablePositions,
    cachedNavigatablePositionsMetadata,
    cacheNavigatablePosition,
    cacheNavigatablePositionMetadata,
    clearCachedNavigatablePositions,
    shouldShowNavigatablePositions
  };
};
