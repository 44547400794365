import { LooseObject } from 'types';

/**
 * Creates a function that accepts an object and returns a new object with only the properties specified in the keys array.
 * Any keys in the keys array that are not found in the object are ignored.
 *
 * @param {PropertyKey[]}  keys The array of keys to filter the object properties.
 * @return {function} A new function that will filter an object based on the keys provided.
 */
export const pick =
  (keys: PropertyKey[]) =>
  (obj: LooseObject): LooseObject =>
    keys.reduce<LooseObject>((result: LooseObject, key: PropertyKey) => {
      if (key in obj) {
        result[key] = obj[key];
      }
      return result;
    }, {});
