import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ClassNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

import { SearchCategoryViewTypeEnum } from '@cohiretech/common-types';

import { search } from 'v2/services/fetchers/candidate/search';
import { addDimensionsOnImages, getImageDimensions } from 'v2/services/image';
import { ImageResizeParam } from 'types';

import MultiAvatarPhotos from 'components/multiavatarphotos';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { defaultSorting } from '../../homepage.helpers';

type Props = {
  to: LinkProps['to'];
  onClick?: LinkProps['onClick'];
  icon?: string;
};

const logoDimentions = getImageDimensions('logo');
const imageParams: ImageResizeParam[] = [{ type: 'logo', dimensions: logoDimentions }];

export default function PeopleBanner({ to, onClick, icon = 'icon_send' }: Props) {
  const [people, setPeople] = useState([]);

  useEffect(() => {
    const getPopularPeople = async () => {
      const { values } = await search({
        category: 'popularPeople',
        page: 0,
        passiveSearch: false,
        publicSearch: true,
        viewType: SearchCategoryViewTypeEnum.People,
        searchItems: [defaultSorting]
      });

      setPeople(addDimensionsOnImages(values, imageParams));
    };

    getPopularPeople();
  }, []);

  const photosData = people?.slice(0, 4).map(({ id, name, photoURL, companyName }) => ({
    id,
    name,
    photoURL,
    lastName: companyName
  }));

  return (
    <PeopleBannerCont to={to} onClick={onClick}>
      <ConditionalRender predicate={photosData}>
        <MultiAvatarPhotos
          className="people_banner_photos"
          photosData={photosData}
          photosWidth={32}
          maxPhotosShown={4}
        />
        <span className="blue_gradient_text">Message the people hiring directly</span>
        <span className={ClassNames('icon blue_gradient_icon', icon)} />
      </ConditionalRender>
    </PeopleBannerCont>
  );
}

const PeopleBannerCont = styled(Link)`
  transition: all 0.2s ease-in-out;
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.75rem;
  border-radius: 2rem;
  background: var(--gradient-primary-transparent);
  ${({ theme: { typography } }) => css`
    font-weight: ${typography.semibold};
    font-size: ${typography.smallheader};
  `};

  .people_banner_photos {
    margin-right: 0;
    margin-top: 0;
  }

  span {
    text-align: left;
  }

  .icon {
    font-weight: bold;
    transition: all 0.2s ease-in-out;
  }

  &:hover .icon {
    margin-left: 0.25rem;
  }
`;
