import {
  PricingSubscriptionPlan,
  PricingCountryDetails,
  Frequency,
  OperatingCountry,
  PricingMode
} from '@cohiretech/common-types';

import { V3PricingOptions, PricingCountry } from 'types';
import { getIsLocalisedPricingPage } from 'utils/companyUtils';
import { trackCreateAccountEvents } from 'tracking-utils';
import { CHECKOUT_URL_PATHS, V3_PRICE_LIST, V3_TIER_PLAN_MAP } from 'consts';
import useLocalisation from 'hooks/useLocalisation';

import { Props as ButtonProps } from 'components/button';

type Plan = {
  urlPath: string;
  mode?: PricingMode;
  enquired?: boolean;
} & Partial<PricingCountryDetails>;

export type GrowthTiers = { [key in Frequency]: { [key in V3PricingOptions]: Plan } };

const getDefaultGrowthTiers = (country: PricingCountry, hasDiscount?: boolean) => {
  const createTiers = (frequency: Frequency) => {
    const tiers = {} as GrowthTiers[Frequency];

    Object.entries(V3_TIER_PLAN_MAP).forEach(([credits, plan]) => {
      const { price, discountedPrice } = V3_PRICE_LIST[country][+credits];

      tiers[plan] = {
        price: frequency === Frequency.monthly ? price : discountedPrice, // WIP: Should be updated when building annual plans on V3 pricing.
        hasDiscount,
        discountedPrice,
        urlPath: CHECKOUT_URL_PATHS[plan]
      };
    });

    return tiers;
  };

  const allTiers = {} as GrowthTiers;

  Object.values(Frequency).forEach(frequency => {
    allTiers[frequency] = createTiers(frequency);
  });

  return allTiers;
};

export const getUpdatedGrowthTiers = (
  country: PricingCountry,
  availablePlans?: PricingSubscriptionPlan[],
  hasDiscount?: boolean
) => {
  const tiers = getDefaultGrowthTiers(country, hasDiscount);

  availablePlans?.forEach(({ pricing, subscriptionPlan: { plan } }) => {
    if (!plan) return;

    const { urlPath, mode, billingFrequency } = pricing;
    const { price, hasDiscount, discountedPrice } = pricing[country] || {};

    tiers[billingFrequency][plan as V3PricingOptions] = {
      price,
      hasDiscount,
      discountedPrice,
      urlPath,
      mode
    };
  });

  return tiers;
};

export const getButtonLink = (
  plan: V3PricingOptions,
  pricingType: PricingCountry,
  period?: Frequency
): ButtonProps['link'] => ({
  pathname: plan === V3PricingOptions.V3Unlimited ? '/request-demo' : '/trial',
  search: `?plan=${plan}`,
  state: { from: window?.location?.pathname, pricingType, period }
});

export const getActivateButtonProps = (
  plan: V3PricingOptions,
  period: Frequency,
  country: PricingCountry
): ButtonProps => ({
  text: 'Activate account',
  link: { pathname: `/${country.toLowerCase()}/checkout/v3-growth-20` },
  action: () => trackCreateAccountEvents(`${plan} | ${period} | ${country}`, 'click_to_activate')
});

export const getPricingType = (pathname?: string) => {
  const isLocalisedPricingPage = getIsLocalisedPricingPage(pathname);

  return (
    isLocalisedPricingPage
      ? isLocalisedPricingPage.toUpperCase()
      : useLocalisation() || OperatingCountry.UK
  ) as PricingCountry;
};
