import {
  CompanyStoryBenefits,
  CompanyStoryLocation,
  CompanySizeType
} from '@cohiretech/common-types';

import {
  IndustryOptions,
  StoryBenefitOptions,
  StoryFilter,
  StoryFilterState,
  StoryLocationOptions,
  StorySizeOptions
} from 'types';

import { ExpandableFilterProps } from 'components/expandablefilter';

export const initState = (state: StoryFilterState) =>
  Object.entries(INITIAL_STORY_FILTER_STATE).reduce((acc, [key, filterState]) => {
    acc[key as StoryFilter] = {
      ...filterState,
      ...state[key as StoryFilter]
    };
    return acc;
  }, {} as StoryFilterState);

const FILTER_OPTIONS: ExpandableFilterProps = {
  appearance: 'dropdown',
  filterName: '',
  selectedItems: [],
  showSearchInput: false,
  totalSelection: 0
};

const SizeOptions: StorySizeOptions[] = [
  { value: CompanySizeType.StartUp, label: 'Start-up', attribute: 'companySizeTypes' },
  { value: CompanySizeType.EarlyHire, label: 'Scale-up', attribute: 'companySizeTypes' },
  { value: CompanySizeType.Enterprise, label: 'Enterprise', attribute: 'companySizeTypes' }
];

const LocationOptions: StoryLocationOptions[] = [
  { value: CompanyStoryLocation.UnitedStates, label: 'United States', attribute: 'locations' },
  { value: CompanyStoryLocation.UnitedKingdom, label: 'United Kingdom', attribute: 'locations' },
  { value: CompanyStoryLocation.Remote, label: 'Europe', attribute: 'locations' }
];

const BenefitOptions: StoryBenefitOptions[] = [...Object.values(CompanyStoryBenefits)].map(
  value => ({
    value,
    label: value,
    attribute: 'benefits'
  })
);

export const INITIAL_STORY_FILTER_STATE: StoryFilterState = {
  companySizeTypes: {
    ...FILTER_OPTIONS,
    attribute: 'companySizeTypes',
    filterName: 'Company size',
    filterOptions: SizeOptions
  },
  industries: {
    ...FILTER_OPTIONS,
    attribute: 'industries',
    filterName: 'Industry',
    filterOptions: [] as IndustryOptions[]
  },
  locations: {
    ...FILTER_OPTIONS,
    attribute: 'locations',
    filterName: 'Location',
    filterOptions: LocationOptions
  },
  benefits: {
    ...FILTER_OPTIONS,
    attribute: 'benefits',
    filterName: 'Main benefits',
    filterOptions: BenefitOptions
  }
};
