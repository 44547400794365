import React from 'react';
import ClassNames from 'classnames';

import { useDarkMode } from 'hooks/useDarkMode';

import BulletList, { Bullet } from 'components/bulletlist';
import BadgeWithIcon from 'components/badgewithicon';
import ResponsiveImage from 'components/responsiveimage';

import { HeaderContent } from './pageheader';

import { PipelineCompanyStats } from '.';

type MessagingSectionProps = {
  src: { desktop: string; mobile: string };
  stats: PipelineCompanyStats;
};

const MessagingSection = ({ src, stats }: MessagingSectionProps) => {
  const darkMode = useDarkMode();
  return (
    <div className="pipeline_chapter">
      <MessagingSectionHeader isTablet />
      <div className={ClassNames('left_cont', 'sourcing_pipeline', 'resp_cont')}>
        <MessagingSectionHeader />
        <BulletList>
          <Bullet title="Effortless message templates">
            Access a bank of successful message templates and build your ones with smart variables
            and personalisation intros.
          </Bullet>
          <Bullet title="4x better responses">
            Advanced message templating, combined with smart follow-ups mean outreach messages turn
            into people in your pipeline.
          </Bullet>
        </BulletList>
        <div className={ClassNames('multiple_badges', { dark_badges: darkMode })}>
          <BadgeWithIcon
            icon="icon_users"
            title={stats.activeCompanyAccount}
            text="people hiring right now"
          />
          <BadgeWithIcon icon="icon_like" title="52%" text="positive response rate" />
        </div>
      </div>
      <div className={ClassNames('right_cont', 'image_resp', 'sourcing_pipeline', 'resp_cont')}>
        <ResponsiveImage
          src={src.desktop}
          alt="Message sent to an engineer on cord"
          smallSrc={src.mobile}
          className="sourcing_img"
          width="100%"
        />
      </div>
    </div>
  );
};

const MessagingSectionHeader = ({ isTablet }: { isTablet?: true }) => (
  <HeaderContent title="Message active people" isTablet={isTablet}>
    Thousands of highly responsive people make sourcing as simple as sending a message. Smart
    message templates & calendar invites get interviews booked straight in your diary.
  </HeaderContent>
);

export default MessagingSection;
