import React, { useEffect, useRef, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';

import { OperatingCountry, SearchResponseCompany } from '@cohiretech/common-types';

import { mediaQueries } from 'responsiveConfig';
import useMedia from 'hooks/useMedia';
import useLocalisation from 'hooks/useLocalisation';
import { US_LOCATION_SEARCH_FILTER } from 'consts';

import CompanyBlockList from 'components/companyblocklist';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import NoJobs from 'images/no-jobs.svg';

import { companyCategories, CompanyTabQueries } from '../homepage.helpers';

import {
  CompanySection,
  CompanySectionHeader,
  CompanyCategoriesWrapper,
  CompanyResults,
  Arrow
} from './companycategories.styled';

type Props = {
  companies: SearchResponseCompany[];
  loading: boolean;
};

export default function CompanyCategoriesSection({ companies, loading }: Props) {
  const [arrowVisibility, setArrowVisibility] = useState({ left: false, right: true });
  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const scrollableWrapperRef = useRef<HTMLDivElement | null>(null);

  const country = useLocalisation();

  const isMobile = useMedia([mediaQueries.mobile]);

  useEffect(() => {
    const scrollableWrapper = scrollableWrapperRef?.current;

    if (scrollableWrapper) {
      const widthDifference = scrollableWrapper.scrollWidth - scrollableWrapper.clientWidth;
      const scrollPosition = scrollableWrapper.scrollLeft;

      if (widthDifference > 0) {
        setArrowVisibility({
          left: scrollPosition > 0,
          right: scrollPosition < widthDifference
        });
      }
    }

    if (country === OperatingCountry.US) {
      companyCategories.forEach(({ searchItems }) => searchItems.push(US_LOCATION_SEARCH_FILTER));
    }
  }, []);

  const scrollToEnd = () => {
    scrollableWrapperRef.current?.scrollTo({
      top: 0,
      left: lastElementRef.current?.offsetLeft,
      behavior: 'smooth'
    });
    setArrowVisibility({ left: true, right: false });
  };

  const scrollToStart = () => {
    scrollableWrapperRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setArrowVisibility({ left: false, right: true });
  };

  return (
    <CompanySection className="company_section">
      <CompanySectionHeader className="company_categories_header">
        <div className="page_view">
          <ConditionalRender predicate={arrowVisibility.left && !isMobile}>
            <Arrow className="arrow arrow_left" onClick={() => scrollToStart()}>
              <span className="icon_simple_arrow_left" />
            </Arrow>
          </ConditionalRender>
          <CompanyCategoriesWrapper ref={scrollableWrapperRef}>
            {companyCategories.map(({ name, icon, query }) => (
              <Link
                key={query}
                to={`?category=${query}`}
                className="company_category_tab"
                isActive={(_, { search }) =>
                  search === `?category=${query}` || (query === CompanyTabQueries.Tech && !search)
                }
              >
                <span className={icon} />
                {name}
              </Link>
            ))}
            <div ref={lastElementRef} id="last_element" />
          </CompanyCategoriesWrapper>
          <ConditionalRender predicate={arrowVisibility.right && !isMobile}>
            <Arrow className="arrow arrow_right" onClick={() => scrollToEnd()}>
              <span className="icon_simple_arrow_right" />
            </Arrow>
          </ConditionalRender>
        </div>
      </CompanySectionHeader>
      <CompanyResults className="company_results">
        <div className="page_view">
          <CompanyBlockList
            items={companies}
            loading={loading}
            columns={3}
            maximum={9}
            empty={{
              icon: { src: NoJobs, alt: 'No companies found' },
              title: 'No companies found.',
              description: "We couldn't find any companies in this category.",
              button: {
                text: 'See companies hiring',
                link: '/search/companies',
                className: 'eighteen_px_font'
              }
            }}
          />
        </div>
      </CompanyResults>
    </CompanySection>
  );
}
