import styled, { css } from 'styled-components';

import { Wrapper as LinkWithIconWrapper } from 'components/linkwithicon';

export const CompaniesListStyling = css`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 3.25rem;
`;

const OverflowEllipsisStyling = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Card = styled.div<{ showRanking?: boolean }>`
  background-color: ${({ theme: { darkMode, colours } }) =>
    darkMode ? colours.darkBgColourLight : 'white'};
  box-shadow: 0 0 15px -2px rgb(49 71 95 / 20%);
  padding: 20px 30px 20px 20px;
  border-radius: 15px;

  ${({ theme: { media } }) => media.mobile`
    padding: 20px;
  `}

  ${({ showRanking }) =>
    showRanking &&
    css`
      position: relative;
    `}

  [class^="icon_"] {
    font-size: 20px;
  }

  .icon_page_preview {
    font-weight: ${({ theme: { typography } }) => typography.black};
    margin-left: 0.5rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .profile_picture_wrapper:hover + .company_details,
  .company_profile_link:hover {
    .icon_page_preview {
      opacity: 100;
    }

    &,
    & + {
      .share_button_wrapper {
        margin-left: 0.75rem;
      }
    }
  }

  .extra_positions_label {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid
      ${({ theme: { darkMode, colours } }) =>
        darkMode ? colours.fontColourDarkShade : colours.lightBgColour};
  }
`;

export const Ranking = styled.div`
  width: 46px;
  height: 46px;
  position: absolute;
  top: -11px;
  left: -9px;
  display: flex;
  background: #e5f1fb;
  border-radius: 18px;

  ${({ theme: { colours, typography } }) => css`
    font-weight: ${typography.black};
    font-size: ${typography.smallheader};
    color: ${colours.primaryDarkShade};
  `}

  span {
    margin: auto;
  }
`;

export const CompanyInfo = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-auto-flow: column;

  ${({ theme: { media } }) => media.mobile`
    grid-template-columns: 60px;
    grid-auto-flow: row;
  `}

  .profile_picture_wrapper {
    grid-row: 1 / 3;

    .profile_picture {
      background-color: ${({ theme: { darkMode, colours } }) =>
        darkMode ? colours.darkBgColourLight : 'white'};

      ${({ theme: { media } }) => media.mobile`
        height: 60px;
        width: 60px;
      `}

      .avatar_img {
        object-fit: contain;
      }
    }

    ${({ theme: { media } }) => media.mobile`
      grid-row: 1 / 2;
    `}
  }

  .banner {
    position: absolute;
    bottom: 0;
    right: 0;
    font-weight: ${({ theme: { typography } }) => typography.regular};
    padding: 5px 10px 5px 5px;
    max-width: 65%;

    ${({ theme: { media } }) => media.mobile`
      position: static;
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      max-width: 100%;
    `}
  }

  .banner_text {
    font-size: ${({ theme: { typography } }) => typography.small};
    align-self: center;
    display: flex;
    gap: 4px;

    .position_name {
      font-weight: ${({ theme: { typography } }) => typography.bold};
      ${OverflowEllipsisStyling}
    }

    ${({ theme: { media, typography } }) => media.mobile`
      font-size: ${typography.small};
    `}
  }

  .link_with_icon {
    margin-left: 15px;
    align-items: center;
    margin-top: auto;

    .icon {
      font-size: ${({ theme: { typography } }) => typography.normal};
    }

    ${({ theme: { media } }) => media.mobile`
      grid-column: 1 / 3;
      grid-row: 4 / 5;
      justify-content: center;
      margin-left: 0;
    `}
  }
`;

export const CompanyDetails = styled.div<{ expandable: boolean }>`
  max-width: 24.5rem;
  grid-row: ${({ expandable }) => (expandable ? '1 / 2' : '1 / 3')};
  text-align: left;
  margin-left: 1rem;
  overflow: hidden;

  .company_name {
    max-width: 70%;
    display: inline-block;
    vertical-align: text-bottom;
    font-size: 20px;
    font-weight: ${({ theme: { typography } }) => typography.black};
    text-align: left;
  }

  .company_location {
    margin: 1.5px 0 2px;
  }

  .company_name,
  .company_location {
    ${OverflowEllipsisStyling}
  }

  ${({ theme: { media, typography } }) => media.mobile`
    align-self: center;

    .company_name {
      max-width: 50%;
    }

    .company_location {
      font-size: ${typography.small};
    }
  `}
`;

export const ShareButtonWrapper = styled.div<{ showTooltip: boolean }>`
  margin-left: -1rem;
  transition: margin-left 0.3s ease-in-out;
  display: inline-block;
  position: relative;

  .share_button {
    ${LinkWithIconWrapper}
    gap: 0.5rem;
  }

  ${({ showTooltip }) =>
    showTooltip &&
    css`
      .tooltip_wrapper {
        display: block;
      }
    `}
`;
