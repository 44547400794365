import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { mobile } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';

import Badge from 'components/badge';
import Button, { Props as ButtonProps } from 'components/button';
import ErrorMessage from 'components/errormessage';

import colours from 'css/base/_colours.module.scss';

export type EmptyBoxProps = {
  id?: string;
  className?: string;
  icon?: {
    src: string;
    alt: string;
  };
  title?: string;
  newFeature?: boolean;
  description?: string | $TSFixMe[];
  button?: ButtonProps;
  secondButton?: ButtonProps;
  errorMessage?: string;
  bottomNote?: string;
};

export default function EmptyBox({
  id,
  className,
  icon,
  title,
  newFeature,
  description,
  button,
  secondButton,
  errorMessage,
  bottomNote,
  children
}: PropsWithChildren<EmptyBoxProps>) {
  const darkMode = useDarkMode();

  return (
    <EmptyBlock id={id} className={classNames('empty_block', className, { dark: darkMode })}>
      <div className="min_view">
        {icon && <EmptyIcon src={icon.src} alt={icon.alt} className="empty_block_image" />}
        {title && (
          <EmptyTitle>
            {Array.isArray(title) ? title.map(e => e) : title}
            {newFeature && <Badge text="new" />}
          </EmptyTitle>
        )}
        {description && (
          <EmptyDesc>
            {Array.isArray(description) ? description.map(e => e) : description}
          </EmptyDesc>
        )}
        {button && <EmptyButton {...button} />}
        {secondButton && <EmptyButton {...secondButton} />}
        {children && children}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {bottomNote && <EmptyBottomNote>{bottomNote}</EmptyBottomNote>}
      </div>
    </EmptyBlock>
  );
}

const EmptyBlock = styled.div`
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 15px;
  margin: 15px auto;

  @media only screen and (max-width: ${mobile}) {
    padding: 15px;
  }

  &.dark {
    background-color: ${colours.darkBgColour};
  }
`;

const EmptyIcon = styled.img`
  max-height: 140px;
  margin-bottom: 20px;

  @media only screen and (max-width: ${mobile}) {
    max-height: 110px;
  }
`;

const EmptyTitle = styled.h1`
  font-size: 28px;
  font-weight: ${({ theme: { typography } }) => typography.bold};
  margin: 0 0 20px;

  @media only screen and (max-width: ${mobile}) {
    font-size: 24px;
  }
`;

const EmptyDesc = styled.h2`
  font-size: 20px;
  line-height: 1.4;
  margin: 0;

  @media only screen and (max-width: ${mobile}) {
    font-size: 18px;
  }
`;

const EmptyButton = styled(Button)`
  margin-top: 40px;
`;

const EmptyBottomNote = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 14px;
  font-weight: ${({ theme: { typography } }) => typography.semibold};
`;
