import axios from 'axios';

import {
  GooglePlaceAddressComponentType,
  GooglePrimaryTypes,
  LocationAutocompleteItem
} from '@cohiretech/common-types';

import { APIResponse, GooglePlaceDetails } from 'types';

const LOCATION_AUTOCOMPLETE = process.env.REACT_APP_PUBLIC_LOCATION_AUTOCOMPLETE;
const LOCATION_PLACE_DETAILS = process.env.REACT_APP_PUBLIC_LOCATION_PLACE;

type LocationAutocompleteParams = {
  input: string;
  types: (GooglePlaceAddressComponentType | GooglePrimaryTypes)[];
  sessionToken: string;
};

export const getLocationAutocompleteResults = async (
  params: LocationAutocompleteParams
): Promise<APIResponse<LocationAutocompleteItem[]>> => {
  try {
    const { data } = await axios.get(`${LOCATION_AUTOCOMPLETE}`, { params: { ...params } });
    return { status: 'success', data: data?.data?.locations };
  } catch (error: any) {
    return { status: 'failure', data: [], ...error };
  }
};

export const getLocationPlaceDetails = async (
  params: Omit<google.maps.places.PlaceDetailsRequest, 'fields'>
): Promise<APIResponse<GooglePlaceDetails>> => {
  const { placeId, ...rest } = params;
  try {
    const { data } = await axios.get(`${LOCATION_PLACE_DETAILS}/${placeId}`, {
      params: {
        ...rest
      }
    });
    return { status: 'success', data: data?.data?.place };
  } catch (error: any) {
    return { status: 'failure', data: null, ...error };
  }
};
