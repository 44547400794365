import { useState, useEffect } from 'react';

import { toArray } from 'utils/array';

/**
 * Hook that returns a value based on the first media query that matches
 * @param queries array of media queries
 * @param values array of values to return if the corresponding media query matches
 * @param defaultValue value to return if no media query matches
 * @returns value of the first media query that matches
 */
export default function useMedia(
  queries: string | string[],
  values: any[] = toArray(queries).map(() => true),
  defaultValue: any = false
) {
  const queryList = toArray(queries).map(q => window.matchMedia(q));

  // defined outside of useEffect to receive updated values
  const getValue = () => {
    const index = queryList.findIndex(mql => mql?.matches);
    return index === -1 ? defaultValue : values[index];
  };

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);

    // set a listener for each media query with above handler as callback
    queryList.forEach(mql => mql?.addEventListener('change', handler));

    return () => queryList.forEach(mql => mql?.removeEventListener('change', handler));
  }, []);

  return value;
}
