import React from 'react';
import ClassNames from 'classnames';
import { NavLink as Link } from 'react-router-dom';
import { Location } from 'history';

import { getIsDarkMode_FIXME } from 'cookieManager';
import { COMPANIES_HUB_LINK, CORD_MARK, PEOPLE_HUB_LINK } from 'consts';
import { isForPeoplePage } from 'utils/url';

import './style.scss';

const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

const PEOPLE_SUB_HEADING =
  'cord is the job search platform that gives you direct access to hiring teams inside technology companies in the UK, USA and remote across Europe.';
const COMPANIES_SUB_HEADING =
  'cord is the hiring tool used by technology-led companies to find active people, fill their pipelines and make direct hires.';

type Props = {
  location: Location;
};

export default function Footer({ location }: Props) {
  const { pathname } = location || window.location;
  const noBorderStyle =
    pathname === '/' ||
    new RegExp('people').test(pathname) ||
    new RegExp('companies').test(pathname) ||
    new RegExp('invited').test(pathname) ||
    new RegExp('pricing').test(pathname) ||
    new RegExp('team').test(pathname) ||
    new RegExp('stories').test(pathname) ||
    new RegExp('slack').test(pathname) ||
    new RegExp('brand').test(pathname) ||
    new RegExp('techhub').test(pathname) ||
    new RegExp('conversations').test(pathname) ||
    new RegExp('partners').test(pathname) ||
    new RegExp('salary-tool').test(pathname) ||
    new RegExp('hires').test(pathname) ||
    new RegExp('request-demo').test(pathname) ||
    new RegExp('trial').test(pathname) ||
    new RegExp('founder-program').test(pathname);

  const noFooter =
    new RegExp('/survey').test(pathname) || /\/(candidate|company|admin)/.test(pathname);
  const smallFooter =
    new RegExp('/invited').test(pathname) || new RegExp('/get-access').test(pathname);
  const darkMode = getIsDarkMode_FIXME();
  const subHeading = isForPeoplePage() ? PEOPLE_SUB_HEADING : COMPANIES_SUB_HEADING;

  const getRightFooterSection = () => {
    return (
      <section className="right_panel">
        <a
          className="footer_link"
          aria-label="Open Privacy Policy in a new tab"
          href={`${domain}/privacy`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy
        </a>
        <a
          className="footer_link"
          aria-label="Open Terms in a new tab"
          href={`${domain}/terms`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms
        </a>
        <a
          className="footer_link"
          aria-label="Open Cookies in a new tab"
          href={`${domain}/cookies`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Cookies
        </a>
        <a
          className="footer_icon"
          title="Follow us on Twitter"
          href="https://twitter.com/corddotco"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="icon_twitter_square" />
        </a>
        <a
          className="footer_icon"
          title="Follow us on Instagram"
          href="https://www.instagram.com/corddotco/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="icon_instagram" />
        </a>
        <a
          className="footer_icon"
          title="Connect with us on Linkedin"
          href="https://www.linkedin.com/company/corddotco/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="icon_linkedin_square" />
        </a>
        <a
          className="footer_icon"
          title="Listen to the Best Work podcast"
          href="https://open.spotify.com/show/47cg1oZ168qqHutOrWGUNW"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="icon_spotify" />
        </a>
      </section>
    );
  };

  if (smallFooter) {
    return (
      <footer id="short_footer" className={darkMode ? 'dark_blue_background' : 'grey_background'}>
        <div className="page_view footer_section">
          <img src={CORD_MARK} alt="cord mark" width="40" height="40" loading="lazy" />
          {getRightFooterSection()}
        </div>
      </footer>
    );
  } else if (!noFooter) {
    return (
      <footer id="footer" className={ClassNames('page_view', { dark: darkMode })}>
        <section className={ClassNames('footer_section', { no_border_top: noBorderStyle })}>
          <section className="left_panel">
            <img
              src={CORD_MARK}
              alt="cord mark"
              width="70"
              height="70"
              style={{ marginBottom: '10px' }}
              loading="lazy"
            />
            <div className="description">{subHeading}</div>
          </section>
          <section className="right_panel">
            <div className="menu_panel">
              <div className="menu_heading">How it works</div>
              <Link className="footer_link" to="/people" activeClassName="active">
                For People
              </Link>
              <Link className="footer_link" to="/companies" activeClassName="active">
                For Companies
              </Link>
              <Link className="footer_link" to="/agencies" activeClassName="active">
                For Agencies
              </Link>
              <Link className="footer_link" to="/pricing" activeClassName="active">
                Pricing
              </Link>
              <Link className="footer_link" to="/stories" activeClassName="active">
                Customer Stories
              </Link>
              <Link className="footer_link" to="/hires" activeClassName="active">
                Live Hires
              </Link>
              <Link className="footer_link" to="/testimonials/companies" activeClassName="active">
                What companies are saying
              </Link>
              <Link className="footer_link" to="/testimonials/people" activeClassName="active">
                What people are saying
              </Link>
            </div>
            <div className="menu_panel">
              <div className="menu_heading">Support</div>
              <a
                className="footer_link"
                href={COMPANIES_HUB_LINK}
                aria-label="Open company knowledge hub in a new tab"
                target="_blank"
                rel="noopener noreferrer"
              >
                Company Knowledge Hub
              </a>
              <a
                className="footer_link"
                href={PEOPLE_HUB_LINK}
                aria-label="Open people knowledge hub in a new tab"
                target="_blank"
                rel="noopener noreferrer"
              >
                People Knowledge Hub
              </a>
              <Link className="footer_link" to="/contact" activeClassName="active">
                Contact
              </Link>
              <Link className="footer_link" to="/sitemap" activeClassName="active">
                Sitemap
              </Link>
              <Link className="footer_link" to="/search" activeClassName="active">
                Search
              </Link>
              <Link className="footer_link" to="/techhub" activeClassName="active">
                Tech hub
              </Link>
              <Link className="footer_link" to="/hiring-insights" activeClassName="active">
                Hiring Insights
              </Link>
            </div>
            <div className="menu_panel">
              <div className="menu_heading">cord</div>
              <Link className="footer_link" to="/about" activeClassName="active">
                Our Mission
              </Link>
              <Link className="footer_link" to="/team" activeClassName="active">
                Our Team
              </Link>
              <Link className="footer_link" to="/brand" activeClassName="active">
                Our Brand
              </Link>
              <Link className="footer_link" to="/careers" activeClassName="active">
                Work at cord
              </Link>
              <Link className="footer_link" to="/partners" activeClassName="active">
                Partners
              </Link>
              <Link className="footer_link" to="/conversations" activeClassName="active">
                Conversation Code
              </Link>
            </div>
          </section>
        </section>
        <section className="footer_section bottom_section">
          <section className="left_panel">
            <div className="description">&copy; co-hire ltd. Build number: {buildNumber}</div>
          </section>
          {getRightFooterSection()}
        </section>
      </footer>
    );
  } else {
    return null;
  }
}
