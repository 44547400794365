import { ATS_STAGES } from 'consts/candidate/ats';

import { CandidateApplicationTrackerStage as CandidateATStage } from '@cohiretech/common-types';

import { CandidateATSItem } from 'v2/services/fetchers/candidate/ats';

export const INITIAL_APPLICATION_BOARD_STAGE_DATA = {
  items: [] as CandidateATSItem[],
  resultsCount: 0,
  loading: false,
  nextPage: 0,
  endPage: false,
  reload: false
};

type ApplicationBoardStageData = typeof INITIAL_APPLICATION_BOARD_STAGE_DATA;

type ApplicationBoardStagesData = { [stage in CandidateATStage]: ApplicationBoardStageData };

export const INITIAL_CANDIDATE_ATS_STATE = {
  stagesState: ATS_STAGES.reduce((acc, stage) => {
    acc[stage] = { ...INITIAL_APPLICATION_BOARD_STAGE_DATA };

    return acc;
  }, {} as ApplicationBoardStagesData)
};
