import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import ClassNames from 'classnames';
import { swipeXAxisOnStart, swipeXAxisOnMove, swipeXAxisOnEnd } from 'swipeControl';

import { PublicStoryDTO } from '@cohiretech/common-types';

import { generateStoryURL } from 'utils';
import { mobile, smallMobile } from 'responsiveConfig';

import { FlexContainer } from 'components/commonstyles';
import ArrowLink from 'components/arrowlink';

import Avatar from 'v2/components/ui/atoms/Avatar';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

type Props = {
  stories: PublicStoryDTO[];
  location: RouteComponentProps['location'];
  darkMode: boolean;
};

export default function StorySlider({ stories, location, darkMode }: Props) {
  const [currentIdx, setCurrentIdx] = useState(0);

  const handleChangeSlide = (direction: 'prev' | 'next') => {
    const lastIdx = stories.length - 1;

    if (direction === 'prev') setCurrentIdx(currentIdx ? currentIdx - 1 : lastIdx);
    else setCurrentIdx(currentIdx === lastIdx ? 0 : currentIdx + 1);
  };

  const story = stories[currentIdx];

  const {
    storyID,
    companyName,
    coverPhotoURL,
    storyLogo,
    quote,
    photoURL,
    fullName,
    jobTitle,
    highlights,
    videoURL
  } = story;

  return (
    <FlexContainer justifyContent="center" alignItems="center">
      <SliderArrow
        className={ClassNames({ dark: darkMode }, 'icon_arrow_left')}
        onClick={() => {
          handleChangeSlide('prev');
        }}
      />
      <StoryWrapper
        className={ClassNames({ dark: darkMode })}
        onTouchStart={e => swipeXAxisOnStart(e)}
        onTouchMove={e => swipeXAxisOnMove(e)}
        onTouchEnd={e =>
          swipeXAxisOnEnd(
            e,
            () => handleChangeSlide('next'),
            () => handleChangeSlide('prev')
          )
        }
      >
        <LeftPanel className="left_panel">
          <img src={coverPhotoURL} alt={`${companyName} Story Cover`} className="cover_photo" />
          <StoryQuote className="story_quote">
            <div>
              <img src={storyLogo} alt={`${companyName} Logo`} className="company_logo" />
            </div>
            <div className="quote">"{quote}"</div>
            <FlexContainer className="quote_by">
              <Avatar photoURL={photoURL} firstname={fullName} width={45} />
              <div>
                <div className="full_name">{fullName}</div>
                <div className="job_title">{jobTitle}</div>
              </div>
            </FlexContainer>
          </StoryQuote>
        </LeftPanel>
        <RightPanel className="right_panel">
          <h3>{highlights[0].title}</h3>
          <p className="highlight_description">{highlights[0].description}</p>
          <FlexContainer className="link_to_story">
            <span className={ClassNames({ dark: darkMode }, 'line')} />
            <ArrowLink to={generateStoryURL(location, { id: storyID, companyName }, true)}>
              {!videoURL ? 'Watch video' : 'Read their story'}
            </ArrowLink>
          </FlexContainer>
        </RightPanel>
      </StoryWrapper>
      <SliderArrow
        className={ClassNames({ dark: darkMode }, 'icon_arrow_right')}
        onClick={() => {
          handleChangeSlide('next');
        }}
      />
    </FlexContainer>
  );
}

const StoryWrapper = styled(FlexContainer)`
  max-width: 930px;
  min-height: 420px;
  border-radius: 50px;
  overflow: hidden;
  background: white;
  box-shadow: 0px 0px 30px ${colours.dropshadow};

  .story_quote,
  .right_panel {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .quote_by,
    .link_to_story {
      margin-top: auto;
    }

    @media only screen and (max-width: ${mobile}) {
      padding: 32px 24px;
    }

    @media only screen and (max-width: ${smallMobile}) {
      padding: 32px 20px;
    }
  }

  &.dark {
    background: ${colours.darkBgColour};
    box-shadow: 0px 0px 30px ${colours.darkDropshadow};
  }

  @media only screen and (max-width: ${mobile}) {
    display: block;
    border-radius: 30px;
  }
`;

const LeftPanel = styled.div`
  width: 65%;
  min-height: inherit;
  position: relative;
  background: linear-gradient(90deg, #192d42 0%, ${colours.fontColour} 100%);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 150px;
  overflow: hidden;

  .cover_photo {
    width: 100%;
    min-height: 100%;
    position: absolute;
    opacity: 0.1;
    object-fit: cover;
  }

  @media only screen and (max-width: ${mobile}) {
    width: 100%;
    min-height: 288px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 100px;
  }
`;

const RightPanel = styled.div`
  width: 35%;
  min-height: 420px;
  padding: 30px 50px 45px 30px;

  h3 {
    margin: 0 0 2px 16px;
    font-size: 70px;
  }

  .highlight_description {
    margin: 0 0 0 16px;
    font-size: 20px;
    font-weight: ${typography.bold};
  }

  .link_to_story {
    .line {
      width: calc(100% - 160px);
      height: 2px;
      background: ${colours.fontColour};

      &.dark {
        background: ${colours.lightFontColour};
      }
    }

    .arrow_link {
      width: 150px;
      margin-right: 0;
      padding-bottom: 3px;
      font-size: ${typography.normal};

      .icon_arrow_right {
        font-size: ${typography.small};
        line-height: inherit;
      }

      &:hover {
        color: ${colours.primaryColour};
      }
    }
  }

  @media only screen and (max-width: ${mobile}) {
    width: 100%;
    min-height: 220px;
    box-sizing: border-box;

    h3 {
      margin: 0;
      font-size: 50px;
    }

    .highlight_description {
      margin: 6px 0 40px;
      font-size: ${typography.smallheader};
    }

    .link_to_story {
      .line {
        width: calc(100% - 155px);
      }

      .arrow_link {
        width: 155px;
      }
    }
  }
`;

const StoryQuote = styled.div`
  min-height: inherit;
  padding: 45px;
  color: white;
  font-size: 20px;

  .company_logo {
    max-width: 155px;
    max-height: 40px;
    object-fit: cover;
    filter: brightness(0) invert(1);
  }

  .quote {
    margin: 30px 0;
  }

  .quote_by {
    font-size: ${typography.normal};

    .profile_picture {
      margin-right: 15px;

      .avatar_img {
        filter: grayscale(100%);
      }
    }

    .full_name {
      font-weight: ${typography.black};
    }
  }

  @media only screen and (max-width: ${mobile}) {
    font-size: ${typography.smallheader};

    .quote {
      margin-top: 22px;
    }

    .quote_by {
      .profile_picture {
        display: none;
      }

      .job_title {
        font-size: ${typography.small};
      }
    }
  }

  @media only screen and (max-width: ${smallMobile}) {
    padding-bottom: 35px !important;

    .company_logo {
      max-width: 96px;
      max-height: 25px;
    }
  }
`;

const SliderArrow = styled.div`
  width: 52px;
  height: 52px;
  background: white;
  border-radius: 50%;
  box-shadow: -5px 0px 20px ${colours.dropshadow};
  text-align: center;
  font-weight: ${typography.black} !important;
  z-index: 1;
  cursor: pointer;

  &:before {
    line-height: 52px;
  }

  &[class$='left'] {
    margin-right: -28px;
  }

  &[class$='right'] {
    margin-left: -28px;
  }

  &.dark {
    color: ${colours.fontColour};
  }

  @media only screen and (max-width: ${mobile}) {
    display: none;
  }
`;
