import React from 'react';
import ClassNames from 'classnames';

import { mediaQueries } from 'responsiveConfig';
import useMedia from 'hooks/useMedia';
import { useDarkMode } from 'hooks/useDarkMode';

import BulletList, { Bullet } from 'components/bulletlist';
import BadgeWithIcon from 'components/badgewithicon';
import ResponsiveImage from 'components/responsiveimage';

import { HeaderContent } from './pageheader';

type GetApplicantsProps = {
  src: { desktop: string; mobile: string };
};

const GetApplicants = ({ src }: GetApplicantsProps) => {
  const darkMode = useDarkMode();
  const isMobileWidth = useMedia([mediaQueries.mobile]);
  return (
    <div className="pipeline_chapter">
      <GetApplicantsHeader isTablet />
      <div className={ClassNames('left_cont', 'sourcing_pipeline', 'resp_cont')}>
        <GetApplicantsHeader />
        <BulletList>
          <Bullet title="Only relevant applications">
            Every person who applies to you has experience working in a technology/product company.
            No more irrelevant applications.
          </Bullet>
          <Bullet title="All information upfront">
            Every applicant comes with essential information upfront. Clear salary expectations,
            skills, experience, education & visa status saves you hours each week.
          </Bullet>
        </BulletList>
        <div className={ClassNames('multiple_badges', { dark_badges: darkMode })}>
          <BadgeWithIcon
            icon="icon_successful_message"
            title={isMobileWidth ? '1 in 5' : '1 in 5 applications'}
            text={`${
              isMobileWidth ? 'applications result in interviews' : 'result in an interview'
            }`}
          />
          <BadgeWithIcon
            icon="icon_hired"
            title={isMobileWidth ? '73%' : '73% of hires'}
            text={`${isMobileWidth ? 'of hires ' : 'were '}made from applications`}
          />
        </div>
      </div>
      <div className={ClassNames('right_cont', 'image_resp', 'sourcing_pipeline', 'resp_cont')}>
        <ResponsiveImage
          src={src.desktop}
          alt="Get quality applications"
          smallSrc={src.mobile}
          className="sourcing_img"
          width="100%"
        />
      </div>
    </div>
  );
};

const GetApplicantsHeader = ({ isTablet }: { isTablet?: true }) => (
  <HeaderContent title="Get quality applications" isTablet={isTablet}>
    Simply post your position and instantly receive messages from qualified, experienced people
    within seconds.
  </HeaderContent>
);

export default GetApplicants;
