import {
  CANDIDATE_SEARCH_FILTERS,
  CANDIDATE_SORT_BY_OPTIONS
} from 'consts/candidate/positionSearch';

import {
  CompanyType,
  SearchResponse,
  SearchResponseListing,
  SearchSortByEnum
} from '@cohiretech/common-types';

import { SearchParams, search } from 'v2/services/fetchers/candidate/search';
import { COMPANY_TYPES } from 'v2/services/company/companyType';
import { setLocalStorageItem } from 'cookieManager';
import { CandidateProfile, SearchItem } from 'types';

import { fetchLiveLeads } from '../fetchers/company/liveLeads';
import { unwrapAPIResponse } from '../fetchers/apiTools';

const { Direct, External, Agency } = CompanyType;

export type SearchState = {
  searchResultIds?: $TSFixMe[];
  searchResults: $TSFixMe[];
  searchResultsCount: number;
  nextPage: number;
  endPage: boolean;
  uniqueIdentifier?: string;
};

const INITIAL_SEARCH_STATE: SearchState = {
  searchResultIds: [],
  searchResults: [],
  searchResultsCount: 0,
  nextPage: 0,
  endPage: false,
  uniqueIdentifier: ''
};

export const INITIAL_SEARCH_STATES = {
  [Direct]: INITIAL_SEARCH_STATE,
  [External]: INITIAL_SEARCH_STATE,
  [Agency]: INITIAL_SEARCH_STATE
};

export type SearchStates = typeof INITIAL_SEARCH_STATES;

export const getDefaultSorting = (params?: {
  isPublicSearch?: boolean;
  isAgency?: boolean;
  newSearchItem?: SearchItem;
}) => {
  const { keyword, sortBy, companyName } = CANDIDATE_SEARCH_FILTERS;
  const { isPublicSearch, isAgency, newSearchItem } = params || {};

  let value = SearchSortByEnum.ResponseRate;
  if (isAgency) value = SearchSortByEnum.DatePosted;
  else if (!isPublicSearch) {
    value = SearchSortByEnum.RankedRelevance;

    const isKeywordOrCompanyName =
      newSearchItem && [keyword.attribute, companyName.attribute].includes(newSearchItem.attribute);

    if (isKeywordOrCompanyName) value = SearchSortByEnum.Relevance;
  }

  const label =
    CANDIDATE_SORT_BY_OPTIONS.find(option => option.value === value)?.label || sortBy.label;

  return { attribute: sortBy.attribute, value, label };
};

export const getUniqueIdentifierKey = (companyType = Direct) =>
  companyType === Direct ? 'uniqueIdentifier' : `${companyType}UniqueIdentifier`;

const processInitSearchRes = (
  { ids, resultsCount, values, ui }: SearchResponse<SearchResponseListing>,
  companyType: CompanyType
) => {
  setLocalStorageItem(getUniqueIdentifierKey(companyType), ui);

  return {
    searchResultIds: ids,
    searchResults: values,
    searchResultsCount: resultsCount,
    nextPage: 1,
    endPage: values.length < 10,
    uniqueIdentifier: ui
  };
};

export const searchAll = async (
  searchParams: Omit<SearchParams, 'isHiringInsights'>,
  profile: CandidateProfile | undefined,
  isHiringInsights?: boolean,
  isAgency?: boolean
): Promise<SearchStates> => {
  const isVerifiedCandidate = profile?.accepted;

  const companyTypes = COMPANY_TYPES.filter(type => {
    if (isAgency) return type === External;

    switch (type) {
      case External:
        return !!isVerifiedCandidate;
      default:
        return true;
    }
  });

  const responses = await Promise.all(
    companyTypes.map(companyType => {
      const params: SearchParams = { ...searchParams, companyType };
      if (companyType === Direct) params.isHiringInsights = isHiringInsights;

      if (isAgency) {
        return unwrapAPIResponse(fetchLiveLeads(params));
      }
      return search(params);
    })
  );

  const searchStates = {} as SearchStates;

  companyTypes.forEach((type, idx) => {
    searchStates[type] = processInitSearchRes(responses[idx], type);
  });

  return searchStates;
};

export const searchNext = async (
  params: SearchParams,
  state: SearchState,
  isAgency?: boolean
): Promise<SearchState> => {
  const { searchResults, nextPage, uniqueIdentifier } = state;

  let values = [];
  if (isAgency) {
    const { status, data } = await fetchLiveLeads({
      ...params,
      page: nextPage,
      ui: uniqueIdentifier
    });
    if (status === 'success') {
      values = data.values;
    }
  } else {
    const { values: newValues } = await search({
      ...params,
      page: nextPage,
      ui: uniqueIdentifier
    });
    values = newValues;
  }

  return {
    ...state,
    searchResults: searchResults.concat(values),
    nextPage: nextPage + 1,
    endPage: values.length < 10
  };
};
