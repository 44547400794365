import React, { ReactNode } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { useDarkMode } from 'hooks/useDarkMode';
import { mobile } from 'responsiveConfig';
import { isString } from 'utils/fn';

import Badge from 'components/badge';
import Tooltip, { Props as TooltipProps, TooltipWrapper } from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import NotificationCounterBubble from 'v2/components/ui/atoms/NotificationCounterBubble';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type CheckboxProps = {
  className?: string | string[];
  checkboxID?: string;
  label: ReactNode;
  checked?: boolean;
  partiallyChecked?: boolean;
  onChange: (e: HTMLInputElement['checked']) => void;
  showNewLabel?: boolean;
  notification?: number;
  tooltip?: TooltipProps & { showHelpIcon?: boolean };
  type?: 'normal' | 'bold' | '';
  disabled?: boolean;
};

function Checkbox({
  className,
  checkboxID,
  label,
  checked,
  partiallyChecked,
  onChange,
  showNewLabel,
  notification,
  tooltip,
  type,
  disabled
}: CheckboxProps) {
  const darkMode = useDarkMode();

  // Todo: temporary solution, whole component should be refactored anyway
  const getTitle = (label: ReactNode) => {
    if (typeof label === 'string') return label;
    if (Array.isArray(label)) return label.filter(l => isString(l)).join(', ');
    return `${checkboxID}label`;
  };

  return (
    <CheckboxWrapper
      className={ClassNames(className, 'checkbox_wrapper', type, { dark: darkMode })}
      disabled={disabled}
    >
      <input
        id={checkboxID}
        type="checkbox"
        checked={checked}
        className="checkbox_input"
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
      />
      <Icon className={ClassNames('checkbox', 'icon_tick')} />
      {partiallyChecked && !checked && <span className="partially_checked" />}
      <label htmlFor={checkboxID} className="checkbox_label" title={getTitle(label)}>
        <span className={ClassNames('label', { notification_visible: notification })}>
          {Array.isArray(label) ? label.map(option => option) : label}
        </span>
        {showNewLabel && <Badge text="new" />}
        <NotificationCounterBubble className={!checked ? 'not_visible' : ''}>
          {notification}
        </NotificationCounterBubble>
        <ConditionalRender predicate={tooltip}>
          <ConditionalRender predicate={tooltip?.showHelpIcon} fallback={<Tooltip {...tooltip} />}>
            <TooltipWrapper className="icon_help">
              <Tooltip {...tooltip} />
            </TooltipWrapper>
          </ConditionalRender>
        </ConditionalRender>
      </label>
    </CheckboxWrapper>
  );
}

export default Checkbox;

const CheckboxWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      .checkbox,
      .label {
        opacity: 0.6;
        cursor: not-allowed;
      }
    `}

  @media only screen and (max-width: ${mobile}) {
    display: flex;
    align-items: center;
  }

  input[type='checkbox'] {
    position: absolute;
    display: inline-block;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0.01;
    vertical-align: middle;
    z-index: 1;
    padding: 0;
    margin: 3px;
    border: 0;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    &:checked + .checkbox {
      font-size: 14px !important;
      color: ${colours.fontColour};
    }

    &:focus + .checkbox {
      border: 2px solid;
      line-height: 17px !important;
    }
  }

  .partially_checked {
    position: absolute;
    left: 6px;
    top: 11px;
    width: 12px;
    height: 2px;
    background: ${colours.fontColour};
    border-radius: 5px;
  }

  label {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding-left: 35px;
    user-select: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    line-height: 1.5;

    .label_with_icon {
      margin-left: 8px;
    }

    .description {
      font-weight: ${typography.semibold};
      font-size: 14px;
      margin-left: 5px;

      @media only screen and (max-width: ${mobile}) {
        display: block;
        margin-left: 0;
      }
    }

    > .tooltip_wrapper {
      opacity: 0;
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s ease-in;
      width: calc(100% - 20px);
    }

    &:hover {
      opacity: 1 !important;

      > .tooltip_wrapper {
        opacity: 1;
        max-height: 100px;
      }
    }
  }

  &.right {
    float: right;

    label {
      padding-right: 0;
      padding-left: 40px;

      @media only screen and (max-width: ${mobile}) {
        padding-left: 30px;
      }
    }
  }

  .notification_counter {
    position: absolute;
    top: 2px;

    &.not_visible {
      opacity: 0.4;
    }
  }

  &.bold {
    padding: 5px 0;

    label {
      font-weight: ${typography.semibold};
      font-size: 16px;
    }

    .checkbox {
      border-width: 2px;
      line-height: 17px !important;
    }
  }

  &.dark {
    .checkbox {
      border: 1px solid ${colours.lightFontColour};
    }

    .partially_checked {
      background-color: ${colours.lightFontColour};
    }

    input[type='checkbox']:checked + .checkbox {
      color: ${colours.lightFontColour};
      font-size: 0.6em;
    }
  }
`;

const Icon = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  width: 20px;
  line-height: 19px !important;
  height: 20px;
  margin: 2px;
  text-align: center;
  border: 1px solid;
  border-radius: 5px;
  font-size: 0 !important;
  font-weight: bold !important;
  box-sizing: border-box;
`;
