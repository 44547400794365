import React from 'react';

import { getPercentage } from 'utils';
import { colours } from 'styles/theme/common';

import RadialBarChart from 'v2/components/RadialBarChart';

import { InsightsBlock } from '../InsightsBlock';
import { usePositionProfile } from '../PositionProfile.context';

const SUB_TITLE = 'Your profile match score';
const DISCLAIMER =
  'Calculated by comparing your  profile with the position. Informed by over 100K application outcomes.';
const DEFAULT_TITLE = 'See how your profile matches the position';

export const MatchScoreBlock = () => {
  const { insightsData } = usePositionProfile();
  const matchScore = getPercentage(insightsData.candidate?.matchScore?.matchScore);
  const title = getTitle(matchScore);

  return (
    <InsightsBlock
      size="small"
      subTitle={SUB_TITLE}
      title={title}
      defaultTitle={DEFAULT_TITLE}
      disclaimer={DISCLAIMER}
    >
      <RadialBarChart
        series={[matchScore]}
        labels={['match']}
        fillType="gradient"
        gradientColours={[colours.primaryColour, '#7B7EE6']}
        trackColour={colours.transparentPrimaryColour}
        valueFontSize="28px"
        labelOffsetY={15}
        noPadding
        {...getRadialBarChartSizeProps()}
      />
    </InsightsBlock>
  );
};

const getRadialBarChartSizeProps = () => {
  return {
    height: 130,
    width: 160,
    hollowSize: '70%',
    valueOffsetY: -15
  };
};

const getTitle = (matchScore: number) => {
  switch (true) {
    case matchScore > 80:
      return 'This position looks like a great match';

    case matchScore > 60:
      return 'This position looks like a good match';

    case matchScore > 40:
      return 'This position looks like a fair match';

    case matchScore > 20:
    default:
      return 'This position looks like not a great match';
  }
};
