import React, { useState, useEffect, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import ClassNames from 'classnames';
import { companyGetForm, companyClearForm, companyUpdateForm } from 'companySignupForm';

import media from 'styles/media';
import { getTotalNumberOfCandidates } from 'fetcher';
import { verifyEmailToClaimCompany } from 'v2/services/fetchers/company/claimCompany';
import {
  ExternalCompanyAutocomplete,
  getExternalCompaniesAutocomplete
} from 'v2/services/fetchers/company/getExternalCompaniesAutocomplete';
import { setLocalStorageItem } from 'cookieManager';
import { commify, debounce, getDomainFromURL, isEmpty } from 'utils';

import AutoComplete from 'components/autocomplete';
import Button, { Props as ButtonProps } from 'components/button';
import ErrorMessage from 'components/errormessage';
import Loader from 'components/loader';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

type ExternalCompanyAutocompleteOption = {
  label: string;
  value: string;
  companyID: number;
};

type SubmitButton = Pick<ButtonProps, 'text' | 'buttonStyle'> & { tooltip?: string };

export type Props = {
  expandable: boolean;
  color?: 'white' | 'dark';
  onExpand?: (expand: boolean) => void;
  submitButton?: SubmitButton;
};

type State = {
  email: string;
  errorMessage: ReactNode;
  sending: boolean;
  sent: boolean;
  expanded: boolean;
  externalCompanies: ExternalCompanyAutocomplete[];
  selectedCompany: ExternalCompanyAutocompleteOption;
  searchTerm: string;
  noOfActiveUsers: string;
};

const HelpIconTooltip = ({ text }: { text: string }) => (
  <span className="icon_help">
    <Tooltip text={text} position="bottom" width="150px" />
  </span>
);

const getSubmitButtonProps = (
  color: Props['color'],
  noOfActiveUsers: string,
  submitButton?: SubmitButton
): ButtonProps => {
  const { text, buttonStyle, tooltip } = submitButton || {};

  return {
    text: text || `See ${noOfActiveUsers} live candidates`,
    buttonStyle: buttonStyle || (color === 'white' ? 'fill_white' : 'fill_blue'),
    className: 'twenty_px_font',
    tooltip: tooltip ? <HelpIconTooltip text={tooltip} /> : null
  };
};

export default function CreateOrClaimCompanyForm({
  expandable,
  color = 'white',
  submitButton,
  onExpand
}: Props) {
  const [state, setState] = useState<State>({
    email: '',
    errorMessage: '',
    sending: false,
    sent: false,
    expanded: false,
    externalCompanies: [],
    selectedCompany: {} as ExternalCompanyAutocompleteOption,
    searchTerm: '',
    noOfActiveUsers: ''
  });

  useEffect(() => {
    async function getNoOfActiveUsers() {
      const activeCandidates = await getTotalNumberOfCandidates('active_count');
      setState({ ...state, noOfActiveUsers: commify(activeCandidates) || '' });
    }

    getNoOfActiveUsers();
    getFormData();
  }, []);

  const fetchExternalCompanies = async (searchTerm: string) => {
    if (searchTerm === '') {
      return setState({
        ...state,
        searchTerm: '',
        externalCompanies: [],
        selectedCompany: {} as ExternalCompanyAutocompleteOption
      });
    }

    const response = await getExternalCompaniesAutocomplete(searchTerm);
    if (response.status === 'success') {
      setState({ ...state, searchTerm, externalCompanies: response.data });
    }
  };

  const debouncedFetchExternalCompanies = debounce(fetchExternalCompanies, 200);

  const getFormData = () => {
    const { email } = companyGetForm();

    setState({ ...state, email: email || '' });
  };

  const submitRequest = async () => {
    setState({ ...state, sending: true });

    const errorMessage = await validateForm();

    if (errorMessage) setState({ ...state, errorMessage, sending: false });
    else handleClaimProfile();
  };

  const handleClaimProfile = async () => {
    const form = companyGetForm();

    setState({ ...state, sending: true });

    const companyID = state.selectedCompany.companyID;
    const response = companyID
      ? await verifyEmailToClaimCompany(email, companyID)
      : await verifyEmailToClaimCompany(email, undefined, state.searchTerm);

    if (response.status === 'success') {
      companyClearForm();

      setLocalStorageItem('referrerDetails', {
        ...form.referrerDetails,
        fromCreateOrClaimProfileForm: true
      });
      setState({ ...state, errorMessage: '', sending: false, sent: true });
    } else {
      let errMessage: string | ReactNode[] = response.message;

      if (errMessage.includes('sign in')) {
        const SIGN_IN_LINK = (
          <Link key="Sign in link" className="lightblue_link" to="/login">
            sign in
          </Link>
        );

        errMessage = response.message.split('sign in');
        errMessage.splice(1, 0, SIGN_IN_LINK);
      }

      setState({ ...state, errorMessage: errMessage, sending: false });
    }
  };

  const resendEmail = async () => {
    await handleClaimProfile();
    setState({ ...state, sending: true });

    setTimeout(() => {
      setState({ ...state, sending: false });
    }, 3000);
  };

  const updateForm = (form: { email?: string }) => {
    companyUpdateForm(form);

    setState({ ...state, ...form });
  };

  const validateForm = async () => {
    const { selectedCompany, email } = state;

    if (isEmpty(selectedCompany)) return 'Please select/enter a company.';
    if (isEmpty(email)) return 'Please fill in your company email.';
  };

  const handleExpand = () => {
    if (onExpand) onExpand(!state.expanded);

    setState({ ...state, expanded: !state.expanded });
  };

  const handleOnEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') submitRequest();
  };

  const {
    errorMessage,
    selectedCompany,
    email,
    sending,
    sent,
    expanded,
    externalCompanies,
    searchTerm,
    noOfActiveUsers
  } = state;

  const parsedExternalCompanies: ExternalCompanyAutocompleteOption[] = externalCompanies.map(
    ({ name, url, companyID }) => {
      return {
        label: `${name}${url ? ` (${getDomainFromURL(url)})` : ''}`,
        value: name,
        companyID
      };
    }
  );
  const companyName = selectedCompany?.value || searchTerm;
  const isExistingCompany = !!selectedCompany?.value;
  const submitButtonProps = getSubmitButtonProps(color, noOfActiveUsers, submitButton);

  if (sent) {
    return (
      <div className="lead_capture_form claim_profile_form">
        <EmailSentContainer expandable>
          <Note>GREAT!</Note>
          <EmailSentHeading>We sent you an email!</EmailSentHeading>
          <p>
            Verify your email address to{' '}
            {isExistingCompany
              ? `get access to ${companyName}'s profile`
              : `claim ${companyName}'s profile`}{' '}
            and see live candidates.
          </p>
          <ConditionalRender predicate={!sending} fallback={<ResendLoader className="small" />}>
            <p>
              Didn't receive an email? Check your spam or{' '}
              <ResendLink className="link" onClick={resendEmail}>
                resend
              </ResendLink>
            </p>
          </ConditionalRender>
        </EmailSentContainer>
      </div>
    );
  }

  if (expandable) {
    return (
      <div
        className={ClassNames('lead_capture_form', 'claim_profile_form', 'inline', { expanded })}
      >
        <div className={ClassNames('inputs_section', { show: expanded })}>
          <AutoComplete
            className={ClassNames(
              'border_input',
              { blue_border_dropdown: color === 'dark' },
              { dark_blue: color === 'dark' },
              { white_border_dropdown: color === 'white' }
            )}
            id="show_active_select_position_autocomplete"
            list={parsedExternalCompanies}
            showSelectionInInput
            onSelect={item =>
              setState({ ...state, selectedCompany: item as ExternalCompanyAutocompleteOption })
            }
            onChange={value => debouncedFetchExternalCompanies(value)}
            selectedItems={[selectedCompany]}
            placeholder="Select your company*"
            allowUnknownWordSelection
          />
          <input
            placeholder="Enter your company email*"
            type="text"
            className={ClassNames(
              'border_input',
              { white_border: color === 'white' },
              'email_input'
            )}
            onKeyPress={e => handleOnEnter(e)}
            value={email}
            onChange={({ target }) => updateForm({ email: target.value.trim() })}
          />
        </div>
        <Button
          loading={sending}
          action={expanded ? submitRequest : handleExpand}
          {...submitButtonProps}
        />
        <div
          className={ClassNames('icon_close', { show: expanded }, { white: color === 'white' })}
          onClick={() => handleExpand()}
        />
      </div>
    );
  } else {
    return (
      <div className="lead_capture_form claim_profile_form">
        <AutoComplete
          className={ClassNames(
            'border_input',
            { blue_border_dropdown: color === 'dark' },
            { dark_blue: color === 'dark' },
            { white_border_dropdown: color === 'white' }
          )}
          id="show_active_select_position_autocomplete"
          list={parsedExternalCompanies}
          showSelectionInInput
          onSelect={item =>
            setState({ ...state, selectedCompany: item as ExternalCompanyAutocompleteOption })
          }
          onChange={value => debouncedFetchExternalCompanies(value)}
          selectedItems={[selectedCompany]}
          placeholder="Select your company*"
          allowUnknownWordSelection
        />
        <input
          placeholder="Enter your company email*"
          name="email"
          type="text"
          className={ClassNames('border_input', { white_border: color === 'white' }, 'email_input')}
          onKeyPress={e => handleOnEnter(e)}
          value={email}
          onChange={({ target }) => updateForm({ email: target.value.trim() })}
        />
        <Button loading={sending} action={submitRequest} {...submitButtonProps} />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </div>
    );
  }
}

const EmailSentContainer = styled.div<{ expandable: boolean }>`
  ${({ expandable }) => expandable && 'margin: 0 auto'};
  width: 360px;
  padding: 1.25rem;
  border-radius: 1rem;
  color: white;
  background-color: ${({ theme }) => theme.colours.fontColour};
  box-shadow: 0 0 1.5rem
    ${({ theme }) => (theme.darkMode ? 'rgba(15, 27, 41, 0.08)' : 'rgba(15, 27, 41, 0.35)')};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${media.mobile`
    width: auto;
  `}
`;

const Note = styled.span`
  ${({ theme }) => css`
    color: ${theme.colours.primaryColour};
    font-size: ${theme.typography.mobile};
    font-weight: ${theme.typography.bold};
  `}
`;

const EmailSentHeading = styled.h3`
  margin: 1rem 0 0;
`;

const ResendLoader = styled(Loader)`
  margin-block: 1.25rem;
`;

export const ResendLink = styled.a`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colours.primaryColour} !important;
  }
`;
