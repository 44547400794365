import styled, { css } from 'styled-components';

import LinkWithIcon from 'components/linkwithicon';

const CV_MENU_MIN_WIDTH = '284px';

export const Divider = styled.div`
  border-bottom: 1px solid
    ${({ theme: { colours, darkMode } }) =>
      darkMode ? colours.darkBgColourLight : colours.lightBgColour};
  margin: 0.5em 0 0;
`;

export const MenuLink = styled(LinkWithIcon)`
  gap: 0.5em;
  min-width: ${CV_MENU_MIN_WIDTH};
  ${({ theme: { colours, darkMode, typography } }) => {
    const bgColour = darkMode ? colours.darkBgColourLight : colours.lightBgColour;
    const color = darkMode ? colours.lightFontColour : colours.fontColour;

    return css`
      &:hover {
        background-color: ${bgColour};
      }

      &.primary {
        width: 100%;
        color: ${color};
        font-weight: ${typography.regular};
        padding: 0.75em;

        &:hover span {
          color: ${color};
        }

        .icon {
          font-weight: ${typography.regular};
          color: ${color};
        }
      }
    `;
  }};
`;

export const CVOptions = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-left: 1em;
`;
