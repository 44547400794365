import React from 'react';
import styled from 'styled-components';

import media from 'styles/media';

import { HubSpotCalendarProps, useCalendarURL } from './HubSpotCalendar.hooks';

const HubSpotCalendar = (props: HubSpotCalendarProps) => {
  const calendarURL = useCalendarURL(props);

  return (
    <IFrameWrapper>
      <iframe title="Book a Demo" src={calendarURL} width="650" height="500" />
      <script
        type="text/javascript"
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      />
    </IFrameWrapper>
  );
};

const IFrameWrapper = styled.div`
  width: 100%;
  height: 550px;
  overflow: hidden;

  ${media.smallMobile`
    height: 500px;
  `}

  > iframe {
    width: 125% !important;
    height: 125% !important;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
`;

export default HubSpotCalendar;
