import React from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';
import { RouteComponentProps } from 'react-router';

import { mobile, tabletLandscape, smallLaptop } from 'responsiveConfig';
import * as fetcher from 'fetcher';
import { emailFormat } from 'utils';
import { ProfileState } from 'store';

import Textarea from 'views/landing/pmfsurvey/textarea';

import Button from 'components/button';
import ErrorMessage from 'components/errormessage';
import RadioButtons from 'components/radiobuttons';
import Seo from 'components/seo';

import colours from 'css/base/_colours.module.scss';

type Props = {
  surveyType: 'candidate' | 'company_account';
  profile: ProfileState;
} & RouteComponentProps;

type State = $TSFixMe;

const lastQuestionWithInvitePrompt = [
  'Enjoyed using cord? Create personalised invite links for your friends ',
  <a
    href="https://cord.co/candidate/invites"
    aria-label="Open candidate invites page in a new tab"
    key="candidate_invites_page_link"
    className="link"
    target="_blank"
    rel="noreferrer noopener"
  >
    here
  </a>,
  '.',
  <br />,
  <br />,
  "If you're interested in taking part in any of our user sessions, please include your email address here. If not, hit submit to finish the survey."
];

export default class PMFSurvey extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { surveyType } = props;

    this.state = {
      current: 0,
      surveyID: -1,
      questions: [
        {
          question: [
            'How would you feel if you could ',
            <strong key="strong">no longer use</strong>,
            ' cord? *'
          ],
          type: 'close',
          options: [
            { label: 'Very disappointed', id: 'very', value: 'Very disappointed' },
            { label: 'Somewhat disappointed', id: 'somewhat', value: 'Somewhat disappointed' },
            {
              label: "Not disappointed (it really isn't that useful)",
              id: 'not',
              value: 'Not disappointed'
            },
            {
              label: 'N/A - I no longer use cord',
              id: 'not_applicable',
              value: 'N/A - I no longer use cord'
            }
          ],
          optional: false,
          answer: '',
          id: 'feeling_q',
          attribute: 'noLongerUse'
        },
        {
          question: 'What is the main benefit you receive from cord? *',
          type: 'open',
          optional: false,
          answer: '',
          id: 'benefit_q',
          attribute: 'mainBenefit'
        },
        {
          question: 'How can we improve cord for you? *',
          type: 'open',
          optional: false,
          answer: '',
          id: 'improve_q',
          attribute: 'improvement'
        },
        ...(surveyType === 'candidate'
          ? [
              {
                question: 'How would you describe cord to a friend?',
                type: 'open',
                optional: true,
                answer: '',
                id: 'describe_q',
                attribute: 'describeCord'
              }
            ]
          : []),
        {
          question: 'Have you recommended cord to anyone? *',
          type: 'close',
          options: [
            { label: 'Yes', id: 'yes' },
            { label: 'No', id: 'no' }
          ],
          optional: false,
          answer: '',
          id: 'recommend_q',
          attribute: 'haveRecommended'
        },
        {
          question:
            "If you're interested in taking part in any of our user sessions, please include your email address here. If not, hit submit to finish the survey.",
          type: 'email',
          optional: true,
          answer: '',
          id: 'email_q',
          attribute: 'furtherContactEmail',
          error: ''
        }
      ],
      survey: {
        noLongerUse: '',
        mainBenefit: '',
        improvement: '',
        haveRecommended: '',
        describeCord: '',
        furtherContactEmail: ''
      },
      completed: false,
      loading: false
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.handlePageExit = this.handlePageExit.bind(this);
  }

  getSurveyID() {
    const { location } = this.props;

    const surveyID = location.pathname.split('/')[2];

    return surveyID;
  }

  async validateSurveyID(surveyID: $TSFixMe) {
    const { history, surveyType } = this.props;

    async function validate(surveyID: $TSFixMe) {
      if (surveyType === 'company_account') return fetcher.validateCompanyAccountSurveyID(surveyID);
      return fetcher.validateSurveyID(surveyID);
    }

    const { status, data } = await validate(surveyID);

    if (status === 'failure') history.push('/');
    else {
      this.setState({ surveyID, completed: data.status === 'complete' });
      // history.push('/survey'); // causing page to reload to candidate/discover if user logged in TOINVESTIGATE
      document.addEventListener('wheel', this.onWheel);
      if (data.status !== 'complete') window.addEventListener('beforeunload', this.handlePageExit);
    }
  }

  onWheel(e: $TSFixMe) {
    if (e.isTrusted && e.deltaY >= 100) this.onNext();
    else if (e.isTrusted && e.deltaY <= -100) this.onPrevious();
  }

  getSurveyResponses() {
    const { surveyID } = this.state;
    const survey = { ...this.state.survey };

    survey.haveRecommended = `${survey.haveRecommended}. ${survey.describeCord}`;
    delete survey.describeCord;

    return { id: surveyID, ...survey };
  }

  async submitSurvey() {
    const { surveyType } = this.props;
    const { questions } = this.state;
    const unAnsweredQuestion = questions.findIndex(
      ({ answer, optional }: $TSFixMe) => !answer && !optional
    );

    async function submit(survey: $TSFixMe) {
      if (surveyType === 'company_account') return fetcher.submitCompanyAccountSurvey(survey);
      return fetcher.submitSurvey(survey);
    }

    if (unAnsweredQuestion > -1) this.jumpToQuestion(unAnsweredQuestion);
    else {
      this.setState({ loading: true });

      const survey = this.getSurveyResponses();
      const { status } = await submit(survey);

      if (status === 'success') {
        this.setState({ completed: true, loading: false });
        window.removeEventListener('beforeunload', this.handlePageExit);
      }
    }
  }

  jumpToQuestion(index: $TSFixMe) {
    this.setState({ current: index });
  }

  componentDidMount() {
    const surveyID = this.getSurveyID();

    this.validateSurveyID(surveyID);
  }

  handlePageExit = (e: $TSFixMe) => {
    const { questions } = this.state;
    const surveyQuestions = questions.filter(({ type }: $TSFixMe) => type !== 'email');
    const mandatoryQuestions = surveyQuestions.filter(
      ({ optional }: $TSFixMe) => optional === false
    );
    const mandatoryQuestionsAnswered = surveyQuestions.filter(
      ({ answer, optional }: $TSFixMe) => optional === false && answer
    );

    if (mandatoryQuestions.length === mandatoryQuestionsAnswered.length) this.submitSurvey();

    e.preventDefault = true;
    e.cancelBubble = true;
    e.returnValue = 'Are you sure you want to exit without submiting your responses?';
  };

  componentWillUnmount() {
    document.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('beforeunload', this.handlePageExit);
  }

  onPrevious() {
    const { questions, current } = this.state;
    const prevIndex = current > 0 ? current - 1 : current;
    const prevQuestionId = questions[prevIndex].id;
    const prevQuestionElement = document.getElementById(prevQuestionId);
    const currentQuestionId = questions[current].id;
    const currentQuesntionElement = document.getElementById(currentQuestionId);

    this.setState({ current: prevIndex }, () => {
      if (prevQuestionElement) prevQuestionElement.focus();
      if (currentQuesntionElement) currentQuesntionElement.blur();
    });
  }

  onNext() {
    const { questions, current } = this.state;
    const nextIndex = current < questions.length - 1 ? current + 1 : current;
    const nextQuestionId = questions[nextIndex].id;
    const nextQuestionElement = document.getElementById(nextQuestionId);
    const currentQuestionId = questions[current].id;
    const currentQuesntionElement = document.getElementById(currentQuestionId);

    if (current === questions.length - 1) this.submitSurvey();
    else {
      this.setState({ current: nextIndex }, () => {
        if (nextQuestionElement) nextQuestionElement.focus();
        if (currentQuesntionElement) currentQuesntionElement.blur();
      });
    }
  }

  onAnswer(index: $TSFixMe, value: $TSFixMe, moveNext = false) {
    const { surveyType } = this.props;
    const questions = [...this.state.questions];
    const survey = { ...this.state.survey };
    const question = questions[index];
    const followupQuestion =
      question.followupQuestion && question.followupQuestion.answerRequired === value;

    question.answer = value;
    survey[question.attribute] = value;

    // If a candidate answers 'Very disappointed', replace the last question with a copy that includes a line prompting them to invite other candidates
    if (surveyType === 'candidate' && question.answer === 'Very disappointed') {
      questions[questions.length - 1].question = lastQuestionWithInvitePrompt;
    }

    // If question type = email then validate email
    if (question.type === 'email' && !emailFormat.test(value)) {
      question.error = 'Please enter a valid email address.';
    } else question.error = '';

    // If followup question then add it in questions
    if (followupQuestion && questions[index + 1].id !== question.followupQuestion.id) {
      questions.splice(index + 1, 0, question.followupQuestion);
    }

    this.setState({ questions, survey }, () => {
      if (moveNext) setTimeout(() => this.onNext(), 500);
    });
  }

  getQuestion(question: $TSFixMe, index: $TSFixMe) {
    const { answer, type, options, optional, id, error } = question;

    switch (type) {
      case 'open':
        return (
          <Textarea
            // @ts-expect-error TS(2322) FIXME: Type '{ value: any; optional: any; elementID: any;... Remove this comment to see the full error message
            value={answer}
            optional={optional}
            elementID={id}
            onNext={() => this.onNext()}
            onChange={(value: $TSFixMe) => this.onAnswer(index, value)}
            placeholder="Type your answer here..."
          />
        );
      case 'email':
        return (
          <>
            <Textbox
              value={answer}
              id={id}
              onChange={e => this.onAnswer(index, e.target.value)}
              onKeyDown={e => this.handleEnter(e)}
              placeholder="someone@gmail.com"
              type="email"
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        );
      default:
        return (
          <RadioButtons
            view="tiles"
            options={options}
            selected=""
            onSelect={label => this.onAnswer(index, label, true)}
            name={id}
          />
        );
    }
  }

  handleEnter(e: $TSFixMe) {
    if (e.key === 'Enter' && !e.shiftKey) this.onNext();
  }

  handleKeyDown(e: $TSFixMe) {
    switch (e.key) {
      case 'ArrowUp':
        this.onPrevious();
        break;
      case 'ArrowDown':
        this.onNext();
        break;
      default:
        break;
    }
  }

  render() {
    const { location, profile } = this.props;
    const { current, questions, loading, completed } = this.state;

    // remove the email from the counter
    const surveyQuestions = questions.filter(({ type }: $TSFixMe) => type !== 'email');
    const totalAnswered = surveyQuestions.filter(({ answer }: $TSFixMe) => answer).length;

    if (completed) {
      return (
        <SurveyPage id="pmf_survey">
          <IntroWrapper className="intro_wrapper">
            <div className="intro min_extended_view">
              <h1 className="heading">Thanks for completing the survey!</h1>
              <h2 className="subheading">
                We're building our roadmap based on our users feedback, and your insight will have a
                direct impact on cord's product. We are truly grateful.
              </h2>
              <Button
                text="Homepage"
                link="/"
                icon="icon_arrow_right"
                iconPosition="right"
                className="eighteen_px_font"
              />
              {!profile && (
                <Button
                  text="Login"
                  link="/login"
                  icon="icon_arrow_right"
                  iconPosition="right"
                  className="eighteen_px_font"
                  buttonStyle="stroke_blue"
                />
              )}
            </div>
          </IntroWrapper>
        </SurveyPage>
      );
    } else {
      return (
        <SurveyPage id="pmf_survey">
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <IntroWrapper className="intro_wrapper" inFirstQ={current === 0}>
            <div className="intro min_extended_view">
              <h1 className="heading">Your feedback matters</h1>
              <h2 className="subheading">
                Thanks for helping us improve cord. This short survey will take only 50 seconds.
              </h2>
            </div>
          </IntroWrapper>
          <Survey className="survey_cont min_extended_view">
            {questions.map((q: $TSFixMe, index: $TSFixMe) => (
              <Question
                className={ClassNames('question', { show: current === index })}
                key={q.question}
              >
                <Title className="label">{q.question}</Title>
                {this.getQuestion(q, index)}
                {q.type !== 'close' && (q.optional || q.answer) && (
                  <ButtonWrapper>
                    <Button
                      className="eighteen_px_font"
                      text={index === questions.length - 1 ? 'Submit' : 'Next'}
                      icon={index === questions.length - 1 ? 'icon_tick' : 'icon_arrow_right'}
                      iconPosition="right"
                      loading={loading}
                      action={() => this.onNext()}
                    />
                    <ButtonTip>
                      Press <strong>Enter ↵</strong>
                    </ButtonTip>
                  </ButtonWrapper>
                )}
              </Question>
            ))}
          </Survey>
          <ProgressBarWrapper>
            <ProgressLabel>
              {totalAnswered}/{surveyQuestions.length} questions completed
            </ProgressLabel>
            <ProgressBar>
              {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
              <Progress width={`${(totalAnswered * 100) / surveyQuestions.length}%`} />
            </ProgressBar>
            <Arrow
              onClick={() => this.onPrevious()}
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              active={current > 0}
              className="icon_simple_arrow_up"
            />
            <Arrow
              onClick={() => this.onNext()}
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              active={current < questions.length - 1}
              className="icon_simple_arrow_down"
            />
          </ProgressBarWrapper>
          <Seo
            title="Product Market Fit"
            description="Help us prioritise what to build and ensure that cord always meets your needs, by answering this short survey"
            path={location.pathname}
            contentType="website"
          />
        </SurveyPage>
      );
    }
  }
}

const SurveyPage = styled.div`
  height: calc(100vh - 100px);

  @media only screen and (max-width: ${mobile}) {
    height: calc(100vh - 85px);
  }
`;

const Arrow = styled.div`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;
  width: 40px;
  height: 30px;
  text-align: center;
  opacity: 0.5;
  font-size: 34px !important;
  color: ${colours.primaryColour};

  ${props =>
    props &&
    (props as $TSFixMe).active &&
    css`
      cursor: pointer;
      opacity: 0.9;

      &:hover {
        opacity: 1;
        color: ${colours.primaryDarkShade};
      }
    `}
`;

const ProgressBarWrapper = styled.div`
  position: fixed;
  bottom: 25px;
  width: 1000px;
  left: calc(50% - 500px);

  @media only screen and (max-width: ${tabletLandscape}) {
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    background-color: white;
    z-index: 2;
  }
`;

const ProgressLabel = styled.div`
  display: inline;
  font-weight: ${({ theme: { typography } }) => typography.bold};
  margin: 10px 0;
  color: ${colours.primaryColour};
`;

const ProgressBar = styled.div`
  display: inline-block;
  height: 5px;
  background-color: ${colours.lightBgColour};
  margin-right: 20px;
  width: calc(100% - 120px);
`;

const Progress = styled.div`
  height: 5px;
  background-color: ${colours.primaryColour};
  width: ${props => props && (props as $TSFixMe).width};
`;

const IntroWrapper = styled.div`
  padding: 30px 0 0;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  max-height: fit-content;
  overflow: initial;

  .heading {
    font-size: 60px;

    @media only screen and (max-width: ${mobile}) {
      font-size: 40px;
    }
  }

  .subheading {
    margin-top: 30px;

    @media only screen and (max-width: ${mobile}) {
      margin: 20px 0 0;
    }
  }

  .stroke_blue {
    margin-left: 20px;
  }

  @media only screen and (max-width: ${smallLaptop}) {
    padding-top: 20px;

    ${props =>
      (props as $TSFixMe).inFirstQ &&
      css`
        padding-top: 1vh;
      `}

    .subheading {
      margin: 2vh auto 3vh;
    }
  }

  @media only screen and (max-width: ${mobile}) {
    ${props =>
      props &&
      (props as $TSFixMe).hide &&
      css`
        opacity: 0;
        max-height: 0;
        overflow: hidden;
      `}

    .subheading {
      margin: 10px auto 0;
    }

    .button {
      margin-top: 40px;
    }
  }
`;

const Survey = styled.div`
  position: relative;
`;

const Question = styled.div`
  opacity: 0;
  position: absolute;
  width: 100%;
  transition: all 0.3s ease-in-out;
  top: 100px;
  max-height: 0;
  overflow: hidden;
  z-index: -1;

  &.show {
    opacity: 1;
    top: 0;
    z-index: 1;
    max-height: fit-content;
    overflow: initial;

    @media only screen and (max-width: ${mobile}) {
      padding: 40px 0 100px;
      width: calc(100% - 40px);
    }
  }

  .error_message {
    color: ${colours.primaryColour};
    font-size: 14px;
    opacity: 1;
    font-weight: ${({ theme: { typography } }) => typography.bold};
    margin: 10px 0 30px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  .button {
    display: inline-block;
    vertical-align: middle;
  }
`;

const ButtonTip = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  font-size: 14px;
  font-weight: ${({ theme: { typography } }) => typography.regular};
`;

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`;

const Textbox = styled.input`
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid;
  font-size: 18px;
  max-width: 600px;
  color: ${colours.primaryColour};

  &:focus {
    border: 0;
    outline: 0;
  }

  @media only screen and (max-width: ${mobile}) {
    width: 100%;
    max-width: 100%;
  }

  &::placeholder {
    color: ${colours.primaryColour};
  }
`;
