import { useEffect, useState } from 'react';

import { AwardSeason, AwardType } from '@cohiretech/common-types';

import { ImageResizeParam } from 'types';
import { AwardDataType, AwardLeaderboard, getReport } from 'v2/services/fetchers/common/awards';
import { pluralise, secondsToDays, setAcronym } from 'utils';
import { addDimensionsOnImages, getImageDimensions } from 'v2/services/image';

import { CompanyCardProps } from 'v2/components/ui/molecules/CompanyCard';
import { Metrics } from 'v2/components/ui/molecules/CompanyCard/Metrics';

import {
  AWARD_SEASON_AVERAGE,
  AwardSeasonYear,
  AwardsPageParams,
  PARAM_MAP,
  getAwardSeasonText
} from '../Awards.helpers';

const getMetrics = <A extends AwardType>(
  award: A,
  data: AwardDataType[A],
  season: AwardSeasonYear
): Metrics => {
  if (award === AwardType.MostResponsive) {
    const { receivedCount, responseRate } = data as AwardDataType[AwardType.MostResponsive];
    const rr = Number(responseRate);

    return [
      {
        icon: 'icon_inbox',
        text: pluralise(receivedCount, 'application', 'applications')
      },
      {
        icon: 'icon_successful_message',
        text: `${rr === 100 ? rr : rr.toFixed(2)}% responsive`
      }
    ];
  }

  if (award === AwardType.FastestToRespond) {
    const { receivedCount, avgResponseTimeHours } =
      data as AwardDataType[AwardType.FastestToRespond];

    return [
      {
        icon: 'icon_inbox',
        text: pluralise(receivedCount, 'application', 'applications')
      },
      {
        icon: 'icon_responsive',
        text: `${secondsToDays(avgResponseTimeHours * 60 * 60)} response time`
      }
    ];
  }

  if (award === AwardType.DiversityAdvocate) {
    const { percentageOverAverage } = data as AwardDataType[AwardType.DiversityAdvocate];

    const awardSeason = getAwardSeasonText(season);
    const average = Math.round(AWARD_SEASON_AVERAGE[season][award]);

    return [
      {
        icon: 'icon_line_bars',
        text: `${Math.floor(percentageOverAverage)}% over avg`,
        tooltip: `The % of female candidates sourced over the average for ${awardSeason} (${average}%).`
      }
    ];
  }

  return [];
};

const IMAGE_PARAM: ImageResizeParam = {
  type: 'logo',
  dimensions: getImageDimensions('no-crop-logo')
};

const convertCompanies = (
  companies: AwardLeaderboard[],
  awardType: AwardType,
  season: AwardSeasonYear
): CompanyCardProps[] =>
  companies.map(company => {
    const {
      companyId,
      companyName,
      locationLabel,
      industries,
      tagLine,
      photoUrl,
      ...metrics
    }: AwardLeaderboard = addDimensionsOnImages(company, IMAGE_PARAM);

    return {
      company: {
        id: companyId,
        name: companyName,
        info: `${setAcronym(locationLabel)} · ${industries[0]}`,
        tagLine,
        photoUrl,
        lastActive: ''
      },
      metrics: getMetrics(awardType, metrics, season)
    };
  });

export const useLeaderboard = (params: AwardsPageParams) => {
  const { season, award, size } = params;

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<CompanyCardProps[]>([]);

  useEffect(() => {
    const getLeadingCompanies = async () => {
      setLoading(true);

      const [awardSeason, year] = season.split('-') as [AwardSeason, number];
      const awardType = PARAM_MAP.award[award];
      const { data } = await getReport(PARAM_MAP.size[size], awardType, awardSeason, year);

      if (data) setCompanies(convertCompanies(data, awardType, season));

      setLoading(false);
    };

    getLeadingCompanies();
  }, [size]);

  return { loading, companies };
};
