import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PositionMetadata } from 'types';
import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';

import { PositionType } from 'views/positionprofile';

import { MarkApplicationPopup, NavigatablePositionsState } from './navigatablePositions.helpers';

const initialState = {
  navigatablePositionsIDs: getLocalStorageItem('navigatablePositionIDs') || [],
  cachedNavigatablePositions: {},
  cachedNavigatablePositionsMetadata: {}
} as NavigatablePositionsState;

const navigatablePositionsSlice = createSlice({
  name: 'navigatablePositions',
  initialState,
  reducers: {
    setNavigatablePositionIDs: (state, action: PayloadAction<number[]>) => {
      setLocalStorageItem('navigatablePositionIDs', action.payload);
      state.navigatablePositionsIDs = action.payload;
    },
    cacheNavigatablePosition: (state, action: PayloadAction<PositionType>) => {
      state.cachedNavigatablePositions[action.payload.id] = action.payload;
    },
    cacheNavigatablePositionMetadata: (state, action: PayloadAction<PositionMetadata>) => {
      state.cachedNavigatablePositionsMetadata[action.payload.id] = action.payload;
    },
    clearCachedNavigatablePositions: state => {
      state.cachedNavigatablePositions = {};
      state.cachedNavigatablePositionsMetadata = {};
    },
    setMarkApplicationPopup: (state, action: PayloadAction<MarkApplicationPopup>) => {
      state.markApplicationPopup = action.payload;
    }
  }
});

export const {
  setNavigatablePositionIDs,
  cacheNavigatablePosition,
  cacheNavigatablePositionMetadata,
  clearCachedNavigatablePositions,
  setMarkApplicationPopup
} = navigatablePositionsSlice.actions;
export default navigatablePositionsSlice.reducer;
