import React from 'react';
import styled, { css } from 'styled-components';

import { SizeVariant } from 'types';

import LinkWithIcon, { LinkWithIconProps } from 'components/linkwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { usePositionProfile } from './PositionProfile.context';
import { ApexChartsCommonStyling } from './insights/PositionInsights.styled';

type DisclaimerOrCTAProps =
  | {
      size: Extract<SizeVariant, 'small'>;
      disclaimer: string;
      cta?: never;
    }
  | {
      size: Extract<SizeVariant, 'medium' | 'large'>;
      cta?: Pick<LinkWithIconProps, 'text' | 'link'>;
      disclaimer?: never;
    };

type InsightsBlockProps = {
  className?: string;
  subTitle: string;
  title: string;
  defaultTitle?: string;
  blur?: boolean;
  children: React.ReactNode;
} & DisclaimerOrCTAProps;

export const InsightsBlock = ({
  className,
  subTitle,
  title,
  size,
  disclaimer,
  cta,
  defaultTitle,
  blur,
  children
}: InsightsBlockProps) => {
  const { isDummyData } = usePositionProfile();
  const isBlurred = blur ?? isDummyData;

  return (
    <InsightsBlockContainer className={className} size={size}>
      <InsightsSubTitle>{subTitle}</InsightsSubTitle>
      <InsightsTitle>{isDummyData ? defaultTitle : title}</InsightsTitle>
      <InsightsData blur={isBlurred}>
        <ConditionalRender predicate={isDummyData}>
          <MockDataLabel>Mock Data</MockDataLabel>
        </ConditionalRender>
        {children}
      </InsightsData>
      <ConditionalRender
        predicate={cta && !isDummyData}
        fallback={disclaimer ? <InsightsDisclaimer>{disclaimer}</InsightsDisclaimer> : null}
      >
        <CTA icon="icon_arrow_right" iconPosition="right" text={cta?.text} link={cta?.link} />
      </ConditionalRender>
    </InsightsBlockContainer>
  );
};

const InsightsData = styled.div<{ blur: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  ${ApexChartsCommonStyling}
  ${props =>
    props?.blur &&
    css`
      filter: blur(6px);
    `}
`;

const MockDataLabel = styled.span`
  position: absolute;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  top: calc(50% - 20px);
  font-size: 20px;
  font-weight: bold;
  opacity: 0.4;
`;

const InsightsSubTitle = styled.div`
  ${({ theme: { typography, fn } }) => css`
    font-size: ${typography.small};
    font-weight: ${typography.bold};
    ${fn.getRadialGradientTextStyling('cordGradient')}
    margin-bottom: 6px;
  `}
`;

export const InsightsTitle = styled.div`
  font-size: 1.125rem;
  font-weight: ${({ theme }) => theme.typography.bold};
`;

const InsightsDisclaimer = styled.div`
  font-size: ${({ theme }) => theme.typography.small};
  color: ${({ theme }) => theme.colours.secondaryColour};
  align-self: flex-end;
`;

const InsightsBlockContainer = styled.div<Pick<InsightsBlockProps, 'size'>>`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1.125rem;
  padding: 1.5rem 1.25rem;
  box-shadow: 0px 4px 32px 0px
    ${({ theme: { colours, darkMode } }) =>
      darkMode ? colours.darkDropshadow : colours.dropshadow};

  ${({ size }) =>
    size === 'small' &&
    css`
      flex: 1 0 0;
      height: 22rem;
      min-width: 180px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      flex: 1 0;
      height: 30rem;
      min-width: 220px;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      flex: 100%;
    `}

  button {
    font-size: 14px;
    padding: 0;
    margin-top: 0.75rem;
    justify-content: center;
  }
`;

const CTA = styled(LinkWithIcon)`
  justify-content: center;

  ${({ theme: { typography, fn } }) => css`
    ${fn.getRadialGradientTextStyling('cordGradient')}

    .icon {
      font-size: ${typography.smallheader};
    }

    button {
      font-size: ${typography.small};
      padding: 0;
      margin-top: 0.75rem;
      justify-content: center;
    }
  `}
`;
