import React from 'react';
import ClassNames from 'classnames';

import { useDarkMode } from 'hooks/useDarkMode';

import './style.scss';

export default function Loader({ className }: $TSFixMe) {
  const darkMode = useDarkMode();
  return (
    <div
      className={ClassNames(className, 'loader_wrapper', { dark: darkMode })}
      data-testid="loader"
    >
      <div className="loader" />
    </div>
  );
}
