import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Currency } from '@cohiretech/common-types';

import { getCurrencyOptions, getSalaryOptions } from 'fetcher';
import { formatNumber } from 'utils';

import Field from 'components/field';
import RichEditor from 'components/richeditor';
import CompanyEditPositionLogisticsSalaryRange from 'components/companyeditposition/logistics/salaryrange';
import SelectSeniorityLevel from 'components/selectquestion/SelectSeniorityLevel';
import CompanyEditPositionInterests from 'components/companyeditposition/interests';

import FieldErrorMessage from 'v2/components/ui/molecules/FieldErrorMessage';

import ConditionalRender from '../utility/ConditionalRender';

import { useCandidateATPosition } from './CandidateATPositionForm.hooks';
import { ATPositionForm, ATPositionFormWrapper } from './CandidateATPositionForm.styled';
import CandidateATPositionLocationFields from './CandidateATPositionLocationFields';
import {
  CandidateATPositionFormHandler,
  CandidateATPositionFormProps
} from './CandidateATPositionForm.helpers';

const SALARY_OPTIONS = getSalaryOptions().map(salary => ({
  label: formatNumber(salary, true),
  value: salary
}));

const CURRENCY_OPTIONS = getCurrencyOptions().map(currency => ({
  label: currency,
  value: currency
}));

export const CandidateATPositionForm = forwardRef<
  CandidateATPositionFormHandler,
  CandidateATPositionFormProps
>(({ initialPosition }, ref) => {
  const candidateATPosition = useCandidateATPosition(initialPosition);
  const {
    position,
    formSubmitted,
    interestsRef,
    salaryRangeRef,
    updatePosition,
    validateAndPreparePosition
  } = candidateATPosition;

  const [showDescErrorMessage, setShowDescErrorMessage] = useState(false);

  useImperativeHandle(ref, () => ({ validateAndPreparePosition }), [position]);

  const {
    candidateApplicationTrackerCreatedListingID,
    companyName,
    title,
    jobTitles,
    seniorities,
    primarySkills,
    secondarySkills,
    salaryMin,
    salaryMax,
    currency,
    description
  } = position;
  const create = !candidateApplicationTrackerCreatedListingID;

  return (
    <ATPositionFormWrapper className="at_position_form_wrapper">
      <ATPositionForm className="at_position_form">
        <Field
          label="Company name"
          attributes={{ 'aria-label': 'company name' }}
          placeholder="Enter company name"
          value={companyName}
          onChange={value => updatePosition({ companyName: value })}
          noInputMessage="Company name can't be left blank"
          formSubmitted={formSubmitted}
        />
        <Field
          label="Position title"
          attributes={{ 'aria-label': 'position title' }}
          placeholder="Enter position title"
          value={title}
          onChange={value => updatePosition({ title: value })}
          noInputMessage="Position title can't be left blank"
          formSubmitted={formSubmitted}
        />
        <ConditionalRender
          predicate={create}
          fallback={
            <CompanyEditPositionInterests
              ref={interestsRef}
              jobTitles={jobTitles}
              seniorities={seniorities}
              primarySkills={primarySkills}
              secondarySkills={secondarySkills}
              formSubmitted={formSubmitted}
            />
          }
        >
          <SelectSeniorityLevel
            question="Experience level"
            selected={seniorities || []}
            setSeniorities={seniorities => updatePosition({ seniorities })}
            noneSelectedMessage="Experience level must be selected"
            formSubmitted={formSubmitted}
          />
        </ConditionalRender>
        <CandidateATPositionLocationFields {...candidateATPosition} />
        <CompanyEditPositionLogisticsSalaryRange
          ref={salaryRangeRef}
          question={['Salary range', <span key="optional">(optional)</span>]}
          salaryOptions={SALARY_OPTIONS}
          currencyOptions={CURRENCY_OPTIONS}
          salaryMax={salaryMax}
          salaryMin={salaryMin}
          currency={currency || Currency.GBP}
          isCandidateATPosition
        />
        <div className="field">
          <label className="question" htmlFor="company_description_editor">
            Job description
          </label>
          <RichEditor
            id="company_description_editor"
            value={description}
            bold
            italics
            orderedList
            unorderedList
            link
            user="candidate"
            placeholder="Enter the job description..."
            onChange={description => updatePosition({ description })}
            onBlur={() => setShowDescErrorMessage(true)}
          />
          <FieldErrorMessage
            show={(formSubmitted || showDescErrorMessage) && !description}
            message="Please enter a job description"
          />
        </div>
      </ATPositionForm>
    </ATPositionFormWrapper>
  );
});

export default CandidateATPositionForm;
