// WIP - After release: Find a way to use it for previous new pricing features(EXCLUSIVE_FEATURES)
import React, { CSSProperties } from 'react';
import { ColourKey } from 'styled-components';

import { OperatingCountry } from '@cohiretech/common-types';

import { PricingCountry, PricingVer, RequiredToOptional, SubscriptionPlan } from 'types';
import { PRICING_PLAN_OPTIONS } from 'consts';

import { Props as ButtonProps } from 'components/button';
import Badge from 'components/badge';

import { PricingPlanCardType } from 'v2/components/ui/organisms/PricingPlansSection/components/PricingPlanCard';

import { Feature as FeatureItem } from './FeatureList/FeatureList';

type FeatureComparisonTableHeaderContent = {
  [key in PricingPlanCardType]: {
    plan: SubscriptionPlan;
    label: string;
    price?: string;
    buttonStyle?: ButtonProps['buttonStyle'];
  };
};

/**
 * The component will retrieve the header content using getCompareFeaturesTableHeaderContent.
 * However, for Pricing V4, the headerContent will be manually provided instead of being retrieved
 * from the function.
 */
type FeatureComparisonTablePartialProps = {
  pricingVer: PricingVer;
  headerContent: FeatureComparisonTableHeaderContent;
};

export type FeatureComparisonTableProps = {
  pricingCountry: PricingCountry;
  currentPlan?: SubscriptionPlan;
  textAlign?: Extract<CSSProperties['textAlign'], 'center'>;
  tickColour?: ColourKey;
} & (
  | RequiredToOptional<FeatureComparisonTablePartialProps, 'headerContent'>
  | RequiredToOptional<FeatureComparisonTablePartialProps, 'pricingVer'>
);

type Features = {
  [key in 'usage' | 'features' | 'service' | 'compliance']: {
    category: string;
    list: FeatureItem[];
  };
};

export const getCompareFeaturesTableHeaderContent = (
  pricingVer: PricingVer,
  pricingCountry: PricingCountry
) => {
  const headerContent = {} as FeatureComparisonTableHeaderContent;
  const pricingOptions = PRICING_PLAN_OPTIONS[pricingVer][pricingCountry || OperatingCountry.UK];

  Object.entries(pricingOptions).forEach(([key, { defaultPlan, label, button }]) => {
    const planType = key as PricingPlanCardType;

    headerContent[planType] = {
      plan: defaultPlan,
      label,
      buttonStyle: button.buttonStyle
    };
  });

  return headerContent;
};

export const getAllFeatures = (): Features => ({
  usage: {
    category: 'Usage',
    list: [
      { name: 'Positions', growth: 'Up to 6', enterprise: 'Custom' },
      { name: 'Inbound applications' },
      { name: 'Outbound messages' },
      { name: 'Hires' },
      {
        name: 'Searches & Streams',
        tooltip: {
          text: 'Streams are saved searches of active candidates looking for work which can be associated with a position and shared with other team members.'
        }
      },
      { name: 'Users' },
      {
        name: 'People views',
        tooltip: {
          text: 'Access all people on cord and view unlimited profiles without extra charges.'
        }
      }
    ]
  },
  features: {
    category: 'Features & Integrations',
    list: [
      {
        name: 'Message templates',
        tooltip: {
          text: 'Build outreach, accept and decline templates using variables from candidates profile. '
        },
        popup: {
          subtitle:
            'Build your own templates using variables to personalise your outreach messages. Schedule automatic follow-ups to be sent if no reply is received.',
          image: 'advanced_message_templating_screenshot.png'
        }
      },
      {
        name: 'Scheduled follow-ups',
        tooltip: {
          text: 'Schedule and personalise automatic follow-ups to be sent if no reply is received. '
        },
        popup: {
          subtitle:
            'Schedule and personalise automatic follow-ups to be sent if no reply is received.',
          image: 'advanced_message_templating_screenshot.png'
        }
      },
      { name: ['AI smart feedback', <Badge key="new" text="new" />] },
      {
        name: 'Interview scheduler',
        tooltip: {
          text: 'Integrate with your Google or Outlook calendar, set your availability and get calls & interviews booked directly in your diary. '
        },
        popup: {
          subtitle:
            'Integrate your Google or Outlook calendar, set your availability and get calls & interviews booked directly in your diary.',
          image: 'interview_scheduler_screenshot.png'
        }
      },
      {
        name: 'Salary benchmarking tool',
        tooltip: {
          text: 'Benchmark your positions and candidates in your pipeline against thousands of other tech & product people. '
        },
        popup: {
          subtitle:
            'Benchmark your positions and candidates in your pipeline against thousands of other tech & product people.',
          image: 'sbt_screenshot.png'
        }
      },
      {
        name: 'Slack integration',
        tooltip: {
          text: 'Get instant notifications when someone new matches your criteria or sends you a message request. '
        },
        popup: {
          subtitle:
            'Get instant notifications as soon as someone new matches your criteria or sends you a message request for a position on cord.',
          image: 'slack_integration_screenshot.png'
        }
      },
      {
        name: 'ATS integration',
        tooltip: {
          text: 'Link your ATS and cord positions and get the people you source sent directly to your ATS. Available ATSs: Workable, Greenhouse, Lever, Teamtailor, and Ashby. '
        },
        popup: {
          subtitle: 'Instantly send people sourced from cord directly to your ATS.',
          image: 'ats_integration_screenshot.png'
        }
      },
      {
        name: 'Sourcing without bias',
        tooltip: {
          text: "Removes unconscious bias in your sourcing by masking candidates' names and profile photos. "
        },
        popup: {
          subtitle:
            "This control removes unconscious bias in your sourcing by masking candidates' names and profile photos.",
          image: 'sourcing_without_bias_screenshot.png'
        }
      },
      {
        name: 'Under-represented groups filters',
        tooltip: {
          text: 'Search for female candidates and people from minority backgrounds. '
        },
        popup: {
          subtitle: 'Search for female candidates and people from minority backgrounds.',
          image: 'under_represented_groups_filters_screenshot.png'
        }
      },
      {
        name: 'Advanced sourcing analytics',
        tooltip: {
          text: "Understand your hiring team's success metrics and usage with cord's analytics dashboard. "
        },
        popup: {
          subtitle:
            "Understand your hiring team's usage & performance. See analytics per user, per position or for the whole account.",
          image: 'advanced_team_analytics_screenshot.png'
        }
      },
      {
        name: 'Position advertising',
        tooltip: {
          text: 'Programmatic advertising of your positions across a network of paid job boards and channels at no cost to you.'
        }
      },
      { name: 'Diversity Analytics', growth: false }
    ]
  },
  service: {
    category: 'Service & support',
    list: [
      { name: 'Customer support' },
      { name: 'Self-service resources' },
      { name: 'Onboarding' },
      { name: 'Dedicated success manager', growth: false },
      { name: 'Invoice billing', growth: false, tooltip: { text: 'Available on annual option' } }
    ]
  },
  compliance: {
    category: 'Compliance & security',
    list: [
      { name: 'GDPR compliance' },
      {
        name: 'Bespoke procurement & security',
        growth: false,
        tooltip: { text: 'Tailored agreement and security questionnaire.' }
      },
      { name: 'SSO (Single sign-on)', growth: false },
      { name: 'SLAs (Service-level agreements)', growth: false },
      { name: 'Audit Logs', growth: false }
    ]
  }
});
