import React from 'react';

import { ListingStatus, RemoteOption } from '@cohiretech/common-types';

import {
  CandidateFullInfo,
  CompanyProfile,
  Profile,
  PositionInProfile,
  CompanyUser,
  VisaRemoteInfo
} from 'types';
import { isEmpty } from 'utils';
import { partition } from 'utils/array';
import { isUndefined } from 'utils/fn';

import { Option } from 'components/buttondropdown';

export const parsePositions = (positions: PositionInProfile[]) =>
  positions.map(
    ({
      position,
      salaryMin,
      salaryMax,
      salaryVisible,
      seniorities,
      currency,
      id,
      locationCity,
      remote
    }) => ({
      name: position,
      maxSalary: salaryMax,
      minSalary: salaryMin,
      salaryVisible,
      seniorities,
      currency,
      id,
      locationCity,
      remote
    })
  );

export const getGroupedAssociatedPositions = (
  positions: PositionInProfile[],
  accountID?: number
) => {
  if (isEmpty(positions)) return [];
  return partition(positions, ({ members }) => members.includes(accountID!));
};

export const getAssociatedPositions = (positions: PositionInProfile[], accountID?: number) => {
  const [associatedPositions] = getGroupedAssociatedPositions(positions, accountID);
  return associatedPositions;
};

export const getNotAssociatedPositions = (positions: PositionInProfile[], accountID?: number) => {
  const [, notAssociatedPositions] = getGroupedAssociatedPositions(positions, accountID);
  return notAssociatedPositions;
};

export const getSolelyAssociatedPositions = (
  positions: PositionInProfile[],
  accountID?: number
) => {
  const [associatedPositions] = getGroupedAssociatedPositions(positions, accountID);
  return associatedPositions.filter(({ members }) => members.length === 1);
};

const isLivePosition = ({ status, postReviewStatus }: PositionInProfile) =>
  status === ListingStatus.Active ||
  (postReviewStatus === ListingStatus.Active && status !== ListingStatus.Archived);

export const getAssociatedLivePositions = (
  positions: PositionInProfile[],
  companyUser: CompanyUser = {},
  demoCompany?: boolean
) => {
  if (isEmpty(positions)) return [];
  if (demoCompany) return positions.filter(isLivePosition);

  const [associatedPositions] = getGroupedAssociatedPositions(positions, companyUser.accountID);
  return associatedPositions.filter(isLivePosition);
};

type AssociatedOptions = {
  associatedTo?: number;
  notAssociatedTo?: number;
};

type FilterOptions = {
  inStatus?: ListingStatus;
  notInStatus?: ListingStatus;
} & AssociatedOptions;

function filterPositions(positions: PositionInProfile[], options?: FilterOptions) {
  if (isEmpty(positions)) return [];

  return positions.filter(({ status, members }) => {
    const { inStatus, notInStatus, associatedTo, notAssociatedTo } = options || {};

    if (inStatus && status !== inStatus) return false;
    if (notInStatus && status === notInStatus) return false;
    if (associatedTo && members.indexOf(associatedTo) === -1) return false;
    if (notAssociatedTo && members.indexOf(notAssociatedTo) !== -1) return false;

    return true;
  });
}

export function getLiveApprovedPositions(
  positions: PositionInProfile[],
  options: AssociatedOptions = {}
) {
  return filterPositions(positions, { ...options, inStatus: ListingStatus.Active });
}

export function getNotRejectedPositions(
  positions: PositionInProfile[],
  options: AssociatedOptions = {}
) {
  return filterPositions(positions, { ...options, notInStatus: ListingStatus.Rejected });
}

function checkIfAllUserPositionsAreUnderReview(positions: PositionInProfile[], accountID?: number) {
  const userAssociatedPositions = getAssociatedLivePositions(positions, { accountID });
  const underReviewPositions = userAssociatedPositions.filter(
    ({ status }) => status === ListingStatus.PendingReview
  );

  return (
    !isEmpty(userAssociatedPositions) &&
    userAssociatedPositions.length === underReviewPositions.length
  );
}

function getPositionDropdownOptions(
  appliedListings: CandidateFullInfo['appliedListing'] = [],
  positions: PositionInProfile[] = []
): Option[] {
  return positions.map(({ id, internalLabel, position, ...rest }) => ({
    label: internalLabel,
    value: id,
    name: position,
    disabled: !!appliedListings?.find(
      ({ listing_id, listingID }) => listing_id === id || listingID === id
    ),
    details: { ...rest }
  }));
}

export function buildExpandedPositionDropdownOptions(
  profile: Profile,
  appliedListing: CandidateFullInfo['appliedListing'],
  disableCreatePosition: boolean = false
): { positionOptions: Option[]; otherPositionCount: number } | undefined {
  if (!profile || profile.isCandidate) return;

  const { companyUser: { accountID } = {}, positions = [] } = profile as CompanyProfile;

  const associatedPositions = getLiveApprovedPositions(positions, { associatedTo: accountID });
  const positionOptions = getPositionDropdownOptions(appliedListing, associatedPositions);
  const otherPositionOptions = [
    {
      label: ['Create a position', <span key="add_icon" className="icon_add" />],
      value: -1,
      disabled: disableCreatePosition
    }
  ];

  if (positionOptions.length < positions.length) {
    otherPositionOptions.unshift({
      label: [
        'Select other positions',
        <span key="upload_successful_icon" className="icon_upload_successful" />
      ],
      value: -2,
      disabled: false
    });
  }

  if (isEmpty(positionOptions)) {
    const label = checkIfAllUserPositionsAreUnderReview(positions, accountID)
      ? 'All your positions are under review. Please wait for them to be verified.'
      : 'There are no active positions associated to your profile';

    positionOptions.push({ label, disabled: true });
  }

  positionOptions.push(...otherPositionOptions);

  return { positionOptions, otherPositionCount: otherPositionOptions.length };
}

export const isRemote = (remote: RemoteOption[] = []) => {
  return remote.includes(RemoteOption.Remote);
};

export const isArchived = (position: PositionInProfile | null) => {
  const { status } = position || {};
  return status === ListingStatus.Archived;
};

export const VISA_REMOTE_INFO_KEYS = [
  'locationCity',
  'locationObject',
  'remoteLocationContinents',
  'remoteLocationCountries',
  'remote',
  'remoteDays',
  'visaSponsorshipAvailable'
] as const;

export const getVisaRemoteInfo = (position: PositionInProfile) =>
  VISA_REMOTE_INFO_KEYS.reduce((acc, key) => {
    const value = position[key];

    return isUndefined(value) ? acc : { ...acc, [key]: value };
  }, {} as VisaRemoteInfo);
