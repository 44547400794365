import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { colours } from 'styles/theme/common';
import { getPercentage } from 'utils';
import { SizeVariant } from 'types';

import Loader from 'components/loader';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import RadialBarChart from 'v2/components/RadialBarChart';

import { usePositionProfile } from '../PositionProfile.context';
import { getProfileMatchScore } from '../PositionInsights.helpers';

import {
  Banner,
  BodyContainer,
  Title,
  Body,
  MatchScore,
  TextContainer,
  MatchScoreContainer
} from './InsisgtsCallout.styled';

export default function InsightsCallout({ goToInsightsTab }: { goToInsightsTab: () => void }) {
  const isMobile = useMedia([mediaQueries.mobile]);
  const { id } = usePositionProfile();
  const [matchScoreData, setMatchScoreData] = useState({ matchScore: 0, isLoading: true });

  useEffect(() => {
    const setProfileMatchScoreData = async () => {
      const data = await getProfileMatchScore(id);
      setMatchScoreData({
        matchScore: data?.data?.candidate?.matchScore?.matchScore || 0,
        isLoading: false
      });
    };
    setProfileMatchScoreData();
  }, []);

  const matchScore = getPercentage(matchScoreData.matchScore);

  return (
    <Banner>
      <BodyContainer>
        <ConditionalRender
          predicate={isMobile}
          fallback={<span className="icon_insights blue_gradient_text" />}
        >
          <ConditionalRender
            predicate={!matchScoreData.isLoading}
            fallback={<Loader className="small" />}
          >
            <ProfileMatchScore size="medium" matchScore={matchScore} />
          </ConditionalRender>
        </ConditionalRender>
        <TextContainer>
          <ConditionalRender predicate={isMobile}>
            <Title>
              <span className="icon_insights blue_gradient_text" />
              <span className="blue_gradient_text">Profile match score</span>
            </Title>
          </ConditionalRender>
          <Body className="blue_gradient_text">
            See how {isMobile ? 'your score compares' : 'you compare'} with successful applicants.
          </Body>
          <button onClick={goToInsightsTab} className="link">
            View Insights
          </button>
        </TextContainer>
      </BodyContainer>
      <MatchScoreContainer>
        <MatchScore
          className={classNames('blue_gradient_text', {
            extra_margin_right: !matchScoreData.isLoading
          })}
        >
          Match score
        </MatchScore>
        <ConditionalRender
          predicate={!matchScoreData.isLoading}
          fallback={<Loader className="small" />}
        >
          <ProfileMatchScore size="small" matchScore={matchScore} />
        </ConditionalRender>
      </MatchScoreContainer>
    </Banner>
  );
}

const ProfileMatchScore = ({ size, matchScore }: { size: SizeVariant; matchScore: number }) => {
  return (
    <RadialBarChart
      series={[matchScore]}
      labels={[]}
      showLabel={false}
      fillType="gradient"
      gradientColours={[colours.primaryColour, '#7B7EE6']}
      trackColour={colours.transparentPrimaryColour}
      noPadding
      {...(size === 'small'
        ? {
            valueFontSize: '14px',
            height: 52,
            width: 52,
            hollowSize: '38%',
            valueOffsetY: 5
          }
        : {
            valueFontSize: '18px',
            height: 80,
            width: 80,
            hollowSize: '55%',
            valueOffsetY: 6
          })}
    />
  );
};
