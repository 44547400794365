import { useEffect } from 'react';

export const useClickDetector = (
  ref: React.RefObject<HTMLElement> | null,
  onClickOutside: (e: MouseEvent) => void,
  onClickInside?: (e: MouseEvent) => void
) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref?.current) {
        if (!ref.current.contains(event.target as Node)) onClickOutside?.(event);
        else onClickInside?.(event);
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [onClickOutside, onClickInside, ref?.current]);
};
