import CustomerStories, { CustomerStoriesProps } from './CustomerStories';
import CustomerStoriesProvider from './CustomerStories.context';

const CustomerStoriesWithContext = (props: CustomerStoriesProps) => {
  return (
    <CustomerStoriesProvider>
      <CustomerStories {...props} />
    </CustomerStoriesProvider>
  );
};

export default CustomerStoriesWithContext;
