// prettier-ignore
export const ALLOWED_CANDIDATE_DESCRIPTION_TAGS =
  ['a', 'b', 'i', 'em', 'u', 'blockquote', 'div', 'p', 'span', 'ul', 'ol', 'li', 'strong', 'br', 'ins', 'del', 'mark'];

// prettier-ignore
export const STRIPPED_CANDIDATE_DESCRIPTION_TAGS =
  ['a', 'b', 'i', 'em', 'u', 'blockquote', 'div', 'p', 'span', 'ul', 'ol', 'li', 'strong', 'br'];

// prettier-ignore
export const ALLOWED_COMPANY_PASTE_TAGS =
  ['a', 'b', 'i', 'em', 'u', 'blockquote', 'div', 'p', 'span', 'ul', 'ol', 'li', 'strong', 'br', 'pre'];

// prettier-ignore
export const ALLOWED_CANDIDATE_PASTE_TAGS =
  ['div', 'p', 'span', 'ul', 'ol', 'li', 'br'];
