import React, { PropsWithChildren } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { mobile } from 'responsiveConfig';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type Props = {
  align?: string;
  type?: 'warning' | 'critical';
  onClick?: () => void;
  className?: string;
};

type StyledError = Pick<Props, 'align' | 'type'>;

function ErrorMessage({ children, align, type, onClick, className }: PropsWithChildren<Props>) {
  return (
    <ConditionalRender predicate={children}>
      <Error
        onClick={
          onClick ||
          (() => {
            return;
          })
        }
        align={align}
        type={type}
        className={ClassNames('error_message', className)}
      >
        {children}
      </Error>
    </ConditionalRender>
  );
}

export default ErrorMessage;

const Error = styled.div<StyledError>`
  clear: both;
  float: none;
  font-weight: ${({ theme: { typography } }) => typography.regular};
  opacity: 0.7;
  margin: 15px 0;
  ${props =>
    props.type === 'critical' &&
    css`
      color: var(--text-body-error);
    `}
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  @media only screen and (max-width: ${mobile}) {
    font-size: 14px;
  }
`;
