import { useMemo, useState } from 'react';

import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { addExternalPositionReport } from 'v2/services/fetchers/candidate/position';
import { isSuccess } from 'v2/services/fetchers/apiTools';

import { DialogWindowProps } from 'components/dialogwindow';
import { usePositionProfile } from 'components/positionprofile/PositionProfile.context';

import ClosedPosition from 'images/close-position-light.svg';

export const useFlagPositionIssuePopup = (
  triggerSource: 'flag_position_button' | 'mark_application_prompt',
  onClose: () => void
): DialogWindowProps => {
  const { id, setFlagged } = usePositionProfile();

  const [loading, setLoading] = useState(false);

  const flagPositionIssue = async () => {
    setLoading(true);

    const response = await addExternalPositionReport(id);

    setLoading(false);

    if (isSuccess(response)) {
      setFlagged(true);
      const flaggedIssuePositions = getLocalStorageItem<number[]>('flaggedIssuePositions') || [];
      flaggedIssuePositions.push(id);
      setLocalStorageItem('flaggedIssuePositions', flaggedIssuePositions);

      onClose();
    }
  };

  const flagPositionIssuePopupProps = useMemo(() => {
    switch (triggerSource) {
      case 'flag_position_button':
        return {
          topIcon: { src: ClosedPosition, alt: 'Closed position' },
          title: 'Is this position closed?',
          subTitle: 'Let us know if this position is no longer accepting applications.',
          primaryButton: {
            text: 'Yes, position is closed',
            buttonStyle: 'fill_cord_gradient',
            action: flagPositionIssue
          },
          skipLink: { text: 'Cancel', onClick: onClose }
        };
      default:
        return {
          title: 'Is the position still open?',
          subTitle: 'Let us know if this position is still accepting applications.',
          primaryButton: {
            text: 'Yes, position is still open',
            buttonStyle: 'fill_cord_gradient',
            action: onClose
          },
          skipLink: { text: 'No, position is closed', onClick: flagPositionIssue }
        };
    }
  }, [triggerSource, onClose]);

  return {
    ...flagPositionIssuePopupProps,
    visible: true,
    loading,
    showCloseIcon: true,
    onClose
  };
};
