import axios from 'axios';

import { SearchResponseListing } from '@cohiretech/common-types';

import { PositionMetadata, APIResponse, RequiredToOptional } from 'types';

const CANDIDATE_EXTERNAL_POSITION_APPLY = process.env.REACT_APP_CANDIDATE_EXTERNAL_POSITION_APPLY!;
const REACT_APP_CANDIDATE_APPLIED_POSITIONS = process.env.REACT_APP_CANDIDATE_APPLIED_POSITIONS!;

type PositionMetadataSubset = Pick<PositionMetadata, 'companyID' | 'ui'>;

export type ApplyExternalListingParams = RequiredToOptional<PositionMetadataSubset, 'ui'> & {
  listingID: PositionMetadata['id'];
  searchPosition?: PositionMetadata['search_position'];
};

export const applyExternalListing = async (
  params: ApplyExternalListingParams
): Promise<APIResponse> => {
  try {
    await axios.post(CANDIDATE_EXTERNAL_POSITION_APPLY, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getApplied = async (): Promise<APIResponse<SearchResponseListing[]>> => {
  try {
    const { data } = await axios.get<{ data: SearchResponseListing[] }>(
      REACT_APP_CANDIDATE_APPLIED_POSITIONS
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
