import React, { useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import { CardDetails, Frequency } from '@cohiretech/common-types';

import { companyReactivateSubscription } from 'fetcher';
import { capitalizeSentences } from 'utils/string';
import { PricingCountry } from 'types';

import DialogWindow from 'components/dialogwindow';
import PaymentMethod from 'components/paymentmethodpopup/paymentsmethod';

import './style.scss';

export type Props = {
  cards: CardDetails[];
  planInfo: { [key in 'label' | 'price' | 'urlPath']: string };
  cycle: Frequency;
  pricingType: PricingCountry;
  proceedToPayment: () => void;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  handleClose: () => void;
};

export default function PaymentMethodPopup({
  cards,
  planInfo,
  cycle,
  errorMessage,
  pricingType,
  handleClose,
  proceedToPayment,
  setErrorMessage
}: Props) {
  const [card, setCard] = useState(cards[0]?.cardID || '');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { label, price, urlPath } = planInfo;

  const reactivateSubscription = async () => {
    if (errorMessage) setErrorMessage('');

    setLoading(true);

    const { status } = await companyReactivateSubscription(urlPath, pricingType, card);

    setLoading(false);

    if (status === 'success') history.push('/company/discover?status=success');
    else setErrorMessage('Invalid card details. Choose a different card or try again.');
  };

  return (
    <DialogWindow
      id="payment_method_popup"
      title="Select payment method"
      subTitle={[
        <div key="label">{`${label}: ${capitalizeSentences(cycle)} Subscription`}</div>,
        <strong key="price">{`${price} per ${
          cycle === 'monthly' ? 'month' : 'year'
        } + VAT`}</strong>
      ]}
      visible
      primaryButton={{ text: 'Reactivate subscription', loading, action: reactivateSubscription }}
      loading={loading}
      showCloseIcon
      onClose={handleClose}
    >
      <PaymentMethod
        cards={cards}
        proceedToPayment={proceedToPayment}
        errorMessage={errorMessage}
        selected={card}
        onSelect={value => setCard(value as string)}
      />
    </DialogWindow>
  );
}
