/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';

import { APIResponse } from 'types';
import { isEmpty } from 'utils';
import { toArray } from 'utils/array';
import { isSuccess } from 'v2/services/fetchers/apiTools';

const DEFAULT_ERROR_MSG = 'Sorry, an error occurred while fetching data';
type FetchOptions<K, T> = {
  args?: K;
  dependencies?: any[];
  predicate?: boolean;
  onSuccess?: (data?: T) => void;
  onError?: (response: APIResponse<T>) => void;
  defaultError?: string;
};
export type BasicResponse<T = undefined> = { data?: T; error?: string; isLoading: boolean };

export type UseAxiosFetchResponse<T> = BasicResponse<T> & {
  refetch: (fnArgs: any) => Promise<BasicResponse<T>>;
  fetch: (fnArgs: any) => Promise<BasicResponse<T>>;
};

type HookState<T> = {
  data?: T;
  isLoading: boolean;
  error?: string;
};

export const useAxiosFetch = <T, K = any>(
  fetcher: (...args: K[]) => Promise<APIResponse<T>>,
  options: FetchOptions<K, T>
) => {
  const {
    args = [],
    dependencies = [],
    predicate = false,
    onSuccess,
    onError,
    defaultError
  } = options;
  const [state, setState] = useState<HookState<T>>({ isLoading: predicate });
  const { data, isLoading, error } = state;

  const manualFetch = useCallback(
    async (...fnArgs: K[]) => {
      setState(prevState => ({ ...prevState, isLoading: true, error: undefined }));
      const argsToUse = isEmpty(fnArgs) ? toArray(args) : fnArgs;

      const response = await fetcher(...argsToUse);

      if (isSuccess(response)) {
        onSuccess?.(response?.data);
        setState({ data: response.data, error: undefined, isLoading: false });
      } else {
        const error = response?.message || defaultError || DEFAULT_ERROR_MSG;
        onError?.(response);
        setState({ data: undefined, error, isLoading: false });
      }

      return { data: response.data, error: response.message, isLoading: false } as BasicResponse<T>;
    },
    [...dependencies, predicate]
  );

  const fetchData = useCallback(
    async (...fnArgs: K[]) => {
      if (!predicate) return;

      return manualFetch(...fnArgs);
    },
    [...dependencies, predicate]
  );

  useEffect(() => {
    fetchData();
  }, [...dependencies, predicate]);

  return {
    data,
    error,
    isLoading,
    refetch: fetchData,
    fetch: manualFetch
  } as UseAxiosFetchResponse<T>;
};
