import React, { CSSProperties } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { CompanyType, UserType } from '@cohiretech/common-types';

import { prettyTextFromArray } from 'utils';
import { identifyCompanyType } from 'v2/services/company/companyType';

import Tooltip from 'components/tooltip';

import Avatar, {
  AvatarProps,
  GetAvatarPropsParams,
  getAvatarPropsFromListing
} from 'v2/components/ui/atoms/Avatar';

import colours from 'css/base/_colours.module.scss';

import LockedPhoto from 'images/locked-photo.png';

type NameKey = 'name' | 'memberName' | 'firstname';

export type MultiAvatarPhotosProps = {
  className?: string;
  photosData: (Partial<AvatarProps> & { id?: number | string } & {
      [key in NameKey]?: AvatarProps['firstname'];
    })[];
  photosWidth?: number;
  resizePhotoDimensions?: AvatarProps['resizePhotoDimensions'];
  maxPhotosShown?: number;
  tooltipText?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  isLocked?: boolean;
};

const MultiAvatarPhotos = ({
  className,
  photosData,
  photosWidth = 20,
  resizePhotoDimensions,
  maxPhotosShown = 3,
  tooltipText,
  tooltipPosition = 'top',
  isLocked
}: MultiAvatarPhotosProps) => {
  const remainingPhotos = photosData.length - maxPhotosShown;
  const photosDataToRender =
    photosData.length > maxPhotosShown ? photosData.slice(0, maxPhotosShown) : photosData;
  const photosNames = photosData.map(({ name, memberName }) => name || memberName);

  return (
    <MembersPhotos className={ClassNames(className, 'multi_avatar_photos')}>
      {photosDataToRender.map(({ id, width, name, memberName, photoURL, ...avatarProps }, idx) => (
        <Avatar
          key={`${id}_${name || memberName || idx}`}
          width={width || photosWidth}
          firstname={name || memberName}
          resizePhotoDimensions={resizePhotoDimensions}
          photoURL={isLocked && idx !== 0 ? LockedPhoto : photoURL}
          {...avatarProps}
        />
      ))}
      {remainingPhotos > 0 && (
        <Count className="remaining_photos_count" width={photosWidth}>
          +{remainingPhotos}
        </Count>
      )}
      {tooltipText && (
        <Tooltip position={tooltipPosition}>
          {/* @ts-expect-error TS(2345) FIXME: Argument of type '(string | undefined)[]' is not a... Remove this comment to see the full error message */}
          <span className="photos_names">{prettyTextFromArray(photosNames, 'and')}</span>{' '}
          {tooltipText}
        </Tooltip>
      )}
    </MembersPhotos>
  );
};

export default MultiAvatarPhotos;

const { Company, CompanyAccount } = UserType;

export const getMultiAvatarPhotoDataByCompanyType = (
  companyType: CompanyType,
  params: Omit<GetAvatarPropsParams, 'type'>
): MultiAvatarPhotosProps['photosData'] => {
  const { isDirect, isExternal } = identifyCompanyType(companyType);

  if (isDirect) return [getAvatarPropsFromListing({ type: CompanyAccount, ...params })];
  if (isExternal) return [getAvatarPropsFromListing({ type: Company, ...params })];
  return [
    getAvatarPropsFromListing({ type: CompanyAccount, ...params }),
    getAvatarPropsFromListing({ type: Company, ...params })
  ];
};

export const getAgencyPhotosStyling = (
  marginLeft: CSSProperties['marginLeft'],
  reverse?: boolean
) => {
  return css`
    display: inline-flex;

    .profile_picture {
      margin-left: 0;

      &:first-child {
        z-index: 1;

        .avatar_img {
          object-fit: cover;
        }
      }
    }

    ${reverse
      ? css`
          flex-direction: row-reverse;

          .profile_picture:first-child {
            margin-left: ${marginLeft};
          }
        `
      : css`
          .profile_picture:last-child {
            margin-left: ${marginLeft};
          }
        `};
  `;
};

const MembersPhotos = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
  margin-top: -5px;

  &:hover .tooltip_wrapper {
    display: block;

    .photos_names {
      font-weight: ${({ theme: { typography } }) => typography.semibold};
    }
  }

  .profile_picture {
    color: ${colours.fontColour};
    font-size: 10px;

    &:not(:first-child) {
      margin-left: -0.5rem;
    }
  }
`;

const Count = styled.span<{ width: number }>`
  position: relative;
  display: inline-block;
  border-radius: 44%;
  background-color: ${colours.fontColour};
  color: white;
  font-size: 12px;
  text-align: center;
  ${({ width }) => css`
    width: ${width}px;
    height: ${width}px;
    line-height: ${width}px; ;
  `}
  vertical-align: middle;
  margin-left: -8px;
`;
