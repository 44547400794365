import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import {
  CompanyType,
  OperatingCountry,
  SearchCategoryViewTypeEnum,
  SearchResponseCompany
} from '@cohiretech/common-types';

import { useCompanyUser } from 'store';
import { getTotalNumberOfCandidates } from 'fetcher';
import { commify, isEmpty } from 'utils';
import { isUSByLocalStorage } from 'utils/localisationUtils';
import { useDarkMode } from 'hooks/useDarkMode';
import { AGENCIES_ROUND_ROBIN_LINK } from 'consts';
import { search } from 'v2/services/fetchers/candidate/search';

import PipelineHero from 'views/landing/forcompanies/pipelinehero';
import SourcingPipeline from 'views/landing/forcompanies/sourcingpipeline';
import TechAndProductPositions from 'views/landing/forcompanies/positions';

import Seo from 'components/seo';
import 'views/landing/forcompanies/style.scss';
import TwoColumnSection from 'components/twocolumnsection';
import CompanyBlockList from 'components/companyblocklist';
import Loader from 'components/loader';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import HubSpotCalendar from '../requestdemo/hubspotcalendar';
import Testimonials from '../forcompanies/testimonials';

const leftColumnProps = {
  cta: 'GET STARTED ON CORD',
  title: 'Book a demo',
  subtitle:
    "Book a 1-1 demo with an expert who can show you how agencies are using cord to source quality candidates you can't find elsewhere."
};

function ForAgenciesPage() {
  const { memberName, accountEmail } = useCompanyUser() || {};

  const history = useHistory();
  const location = useLocation();
  const darkMode = useDarkMode();
  const [noOfActiveUsers, setNoOfActiveUsers] = useState('');
  const [agencies, setAgencies] = useState<SearchResponseCompany[]>([]);

  useEffect(() => {
    async function getNoOfActiveUsers() {
      const country = isUSByLocalStorage() ? OperatingCountry.US : undefined;

      const activeCandidates = await getTotalNumberOfCandidates('active_count', country);
      setNoOfActiveUsers(commify(activeCandidates) || '');
    }

    getNoOfActiveUsers();
  }, []);

  useEffect(() => {
    async function fetchAgencies() {
      const firstPage = await search({
        page: 0,
        viewType: SearchCategoryViewTypeEnum.Company,
        publicSearch: true,
        companyType: CompanyType.Agency,
        passiveSearch: false,
        searchItems: []
      });

      const secondPage = await search({
        page: 1,
        viewType: SearchCategoryViewTypeEnum.Company,
        publicSearch: true,
        companyType: CompanyType.Agency,
        passiveSearch: false,
        searchItems: []
      });

      const agencies = [...firstPage.values, ...secondPage.values].slice(
        0,
        12
      ) as SearchResponseCompany[];

      setAgencies(agencies);
    }

    fetchAgencies();
  }, []);

  return (
    <>
      <PipelineHero
        heading="Source quality candidates"
        subheading="Join the recruitment agencies using cord to source candidates you won't find elsewhere."
        noOfActiveUsers={noOfActiveUsers}
        history={history}
      />
      <Testimonials
        title={"You're in good company"}
        subTitle=" "
        location={location}
        darkMode={darkMode}
      />
      <AgenciesList className="page_section center_alignment">
        <div className="page_view">
          <h2 className="page_section_title">
            Join the leading agencies accessing an exclusive talent pool
          </h2>

          <ConditionalRender
            predicate={!isEmpty(agencies)}
            fallback={<Loader className="medium" />}
          >
            <CompanyBlockList columns={3} items={agencies} />
          </ConditionalRender>
        </div>
      </AgenciesList>
      <SourcingPipeline noOfActiveUsers={noOfActiveUsers} />
      <TechAndProductPositions />

      <TwoColumnSection className="with_form" leftColumn={leftColumnProps}>
        <HubSpotCalendar
          link={AGENCIES_ROUND_ROBIN_LINK}
          firstName={memberName || ''}
          email={accountEmail || ''}
        />
      </TwoColumnSection>
      <Seo
        title="Make direct hires"
        description="cord enables Founders and Hiring teams to make direct hires by sourcing and messaging active, pre-verified people who are looking for permanent work in the UK, USA and remote across Europe."
        path="/agencies"
        contentType="website"
        script={[{ src: 'https://www.googleoptimize.com/optimize.js?id=OPT-PJSHB2Z' }]}
        image="https://assets.co-hire.com/make-direct-hires.png"
      />
    </>
  );
}

export default ForAgenciesPage;

const AgenciesList = styled.div`
  .page_section_title {
    width: 70%;
  }
`;
