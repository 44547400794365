import { getLocalStorageItem } from 'cookieManager';
import { assocPath, isBoolean } from 'utils/fn';
import { flatten } from 'utils/object';

import { DevFeatures } from 'v2/components/utility/DevTools';

import { TOGGLER_TREE } from './ui.consts';

export const loadPersistedDevFeatures = () => {
  const persistedMap = getLocalStorageItem<DevFeatures>('dev-features');
  if (!persistedMap) return TOGGLER_TREE;

  const flattenedMap = flatten(persistedMap);
  const toggleMap = TOGGLER_TREE;
  let devFeatures = toggleMap.dev;

  const hasPersistedFeatures = Object.values(flattenedMap).some(Boolean);
  if (!hasPersistedFeatures) return toggleMap;

  Object.entries(flattenedMap).forEach(([route, value]) => {
    if (isBoolean(value) && value) {
      devFeatures = assocPath(devFeatures, route, true) as DevFeatures;
    }
  });

  toggleMap.dev = devFeatures;

  return toggleMap;
};
