import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import LabelWithIcon, { LabelWithIconProps } from 'components/labelwithicon';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

export type Metrics = Array<LabelWithIconProps & { tooltip?: ReactNode }>;

type Props = {
  id: number;
  metrics: Metrics;
  spread?: boolean;
};

export default function MetricsList({ id, metrics, spread = true }: Props) {
  return (
    <MetricsCont className="metrics" spread={spread}>
      {metrics.map(({ icon, text, tooltip }, idx) => (
        <LabelWithIcon key={`${id}-metrics-${idx}`} icon={icon} size="large" text={text} vertical>
          <ConditionalRender predicate={tooltip}>
            <span className="icon_help small">
              <Tooltip position="bottom" width="255px">
                {tooltip}
              </Tooltip>
            </span>
          </ConditionalRender>
        </LabelWithIcon>
      ))}
    </MetricsCont>
  );
}

const MetricsCont = styled.div<{ spread: boolean }>`
  display: flex;
  gap: 30px;
  font-weight: ${({ theme: { typography } }) => typography.bold};
  margin-left: 0.75rem;

  ${({ spread }) =>
    spread &&
    css`
      grid-row: 1 / 3;
      align-self: center;
    `}

  ${({ theme: { media } }) => media.mobile`
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    justify-content: space-between;
    margin: 15px 0 25px;
    padding: 0 5px;
    gap: 8px;

    .label_with_icon {
      width: 100%;
    }
  `}

  .icon_help:hover .tooltip_wrapper .tooltip {
    text-align: center;
  }
`;
