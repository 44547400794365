import { css } from 'styled-components';

import { ColourWay } from 'types';

export const Ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FlexEllipsis = (maxLines: number = 1) => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${maxLines};
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type GradientTextProps = {
  gradientTextColour?: Extract<ColourWay, 'cordGradient' | 'purpleGradient'>;
};

const BGGradient = css<GradientTextProps>`
  ${({ gradientTextColour }) => {
    switch (gradientTextColour) {
      case 'purpleGradient':
        return css`
          background: var(--gradient-secondary-base);
        `;
      case 'cordGradient':
      default:
        return css`
          background: var(--gradient-primary-base);
        `;
    }
  }}
`;

export const GradientText = css<GradientTextProps>`
  ${BGGradient}
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
