import { useMemo, useRef, useState } from 'react';

import { ListingInterface } from '@cohiretech/common-types';

import {
  getExternalListingByURL,
  importCandidateApplicationTrackerListing
} from 'v2/services/fetchers/candidate/applicationTrackerPosition';
import { APIResponse } from 'types';
import { isSuccess } from 'v2/services/fetchers/apiTools';
import { getPosition } from 'fetcher';
import { ERROR_MESSAGE } from 'consts';
import { isValidURL } from 'v2/services/tools/url';

import { CandidateATCreatedPosition } from 'v2/components/CandidateATPositionForm';

import { useApplicationTrackerContext } from '../../ApplicationTracker.context';

import {
  FAILED_TO_IMPORT_LISTING,
  InputMethod,
  POSITION_DOES_NOT_EXIST,
  URLCheckState
} from './ImportPositionPopup.helpers';

const DOMAIN = process.env.REACT_APP_CORD_MAIN_DOMAIN!;

export const useImportATSPosition = () => {
  const { position, setPosition } = useApplicationTrackerContext();

  const { url = '' } = position || {};

  const [urlCheckState, setURLCheckState] = useState<URLCheckState>('idle');
  const [inputMethod, setInputMethod] = useState<InputMethod>();
  const currentStep = useMemo(() => (urlCheckState ? 0 : 1), [urlCheckState]);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const controllerRef = useRef<AbortController>();

  const handleStepChange = (params?: {
    urlCheckState?: URLCheckState;
    inputMethod?: InputMethod;
  }) => {
    setFormSubmitted(false);

    setURLCheckState(params?.urlCheckState);
    if (params?.inputMethod) setInputMethod(params?.inputMethod);
  };

  const handleManualEntryClick = () => {
    if (position?.stage) {
      setPosition({ stage: position.stage });
      handleStepChange({ inputMethod: 'manual' });
    } else setFormSubmitted(true);
  };

  const checkPositionURL = async () => {
    const valid = position?.stage && isValidURL(url);

    setFormSubmitted(!valid);

    if (!valid) return;

    processPositionURL('finding');
  };

  const processPositionURL = async (action: Extract<URLCheckState, 'finding' | 'extracting'>) => {
    controllerRef.current = new AbortController();
    setURLCheckState(action);

    const isFindingOnCord = action === 'finding';
    const isCordPositionURL =
      url.startsWith(DOMAIN) && new RegExp(/\/u\/.*\/jobs\/[0-9]+/).test(url);
    const cordPositionID = isCordPositionURL && Number(url.match(/\d+/)![0]);

    let res: APIResponse<ListingInterface>;

    if (cordPositionID) {
      const data = await getPosition(cordPositionID, true, '', 0, true);
      res = data ? { status: 'success', data } : { status: 'failure', message: ERROR_MESSAGE };
    } else {
      const executeAction = isFindingOnCord
        ? getExternalListingByURL
        : importCandidateApplicationTrackerListing;

      res = await executeAction(url, controllerRef.current.signal);
    }

    const allowNextStep =
      isSuccess(res) || (!isFindingOnCord && res.message === FAILED_TO_IMPORT_LISTING);

    if (allowNextStep) {
      setPosition(prevPosition => ({ stage: prevPosition.stage, ...res.data }));
      handleStepChange({ inputMethod: isFindingOnCord ? 'found' : 'extracted' });
      return;
    }

    if (isFindingOnCord && res.message === POSITION_DOES_NOT_EXIST) {
      processPositionURL('extracting');
    }
  };

  const handleCancelClick = () => {
    setURLCheckState('idle');
    controllerRef.current?.abort();
  };

  return {
    urlCheckState,
    inputMethod,
    currentStep,
    formSubmitted,
    updatePosition: (position: Partial<CandidateATCreatedPosition>) => {
      setPosition(prevPosition => ({ ...prevPosition, ...position }));
    },
    setURLCheckState,
    handleStepChange,
    handleManualEntryClick,
    checkPositionURL,
    handleCancelClick
  };
};
