import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { GradientText } from 'v2/components/ui/styles';

type Props = {
  metric: ReactNode;
  description: string;
  icon: string;
  className?: string;
};

export default function Metric({ metric, description, icon, className }: Props) {
  return (
    <MetricCard className={`metric_card ${className}`}>
      <MetricIcon className="metric_icon" gradientTextColour="cordGradient">
        <span className={icon} />
      </MetricIcon>
      <MetricBody className="metric_body">
        <strong className="metric">{metric}</strong>
        <p className="metric_description">{description}</p>
      </MetricBody>
    </MetricCard>
  );
}

export const MetricCard = styled.div`
  display: flex;
  flex: 1;
  padding: 1.75rem;
  align-items: center;
  align-self: stretch;
  text-align: left;
  gap: 1rem;
  border-radius: 1.5rem;
  border: 1px solid var(--border-divider-soft);
  background: var(--background-block-primary);
  box-shadow: 0px 12px 48px 0px var(--shadow-soft);
`;

const MetricIcon = styled.div`
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  background: var(--gradient-primary-transparent);

  [class^='icon_'] {
    font-size: 1.5rem;
    font-weight: bold;

    &:before {
      ${GradientText}
    }
  }
`;

const MetricBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  strong {
    font-size: ${({ theme: { typography } }) => typography.header};
    font-weight: ${({ theme: { typography } }) => typography.bold};
  }

  p {
    margin: 0;
    color: var(--text-body-tertiary);
  }
`;
