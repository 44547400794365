import React, { ReactNode, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';

import { CompanyClaimState } from '@cohiretech/common-types';

import { verifyEmailToClaimCompany } from 'v2/services/fetchers/company/claimCompany';

import Button from 'components/button';
import DialogWindow from 'components/dialogwindow';
import ErrorMessage from 'components/errormessage';
import Field from 'components/field';

import SuccessIcon from 'images/successful-icon-thick.svg';

import ConditionalRender from '../utility/ConditionalRender';

import {
  ClaimProfileLink,
  ClaimTopIcon,
  NoSubscriptionNote,
  ResendEmailNote,
  ResendLink,
  ResendLoader,
  Wrapper
} from './ClaimCompanyProfile.styled';
import ValueProposition from './ValueProposition';

export type Props = {
  companyID: number;
  companyName: string;
  claimState: CompanyClaimState;
  isCompanyProfile?: boolean;
};

export default function ClaimCompanyProfile({
  companyID,
  companyName,
  claimState,
  isCompanyProfile
}: Props) {
  const [showClaimProfilePopup, setShowClaimProfilePopup] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<ReactNode>('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleClaimProfile = async () => {
    if (!email) return setErrorMessage('Please enter your email address.');

    setLoading(true);

    const response = await verifyEmailToClaimCompany(email, companyID);

    if (response.status === 'success') {
      setErrorMessage('');
      setShowClaimProfilePopup(false);
      setEmailSent(true);
    } else {
      let errMessage: string | ReactNode[] = response.message;

      if (errMessage.includes('sign in')) {
        const SIGN_IN_LINK = (
          <Link key="Sign in link" className="lightblue_link" to="/login">
            sign in
          </Link>
        );

        errMessage = response.message.split('sign in');
        errMessage.splice(1, 0, SIGN_IN_LINK);
      }

      setErrorMessage(errMessage);
    }

    setLoading(false);
  };

  const resendEmail = async () => {
    await handleClaimProfile();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  return (
    <Wrapper isCompanyProfile={isCompanyProfile}>
      <span>Hiring internally for {companyName}?</span>
      <ClaimProfileLink
        text={
          claimState === CompanyClaimState.JoinAllowed
            ? 'Join account'
            : 'Claim cord profile for free'
        }
        icon="icon_claim"
        action={() => setShowClaimProfilePopup(true)}
      />
      <ConditionalRender predicate={!emailSent}>
        <DialogWindow
          visible={showClaimProfilePopup}
          topCode={<ClaimTopIcon className="icon_claim" />}
          title={`Claim ${companyName}'s cord profile`}
          subTitle={`Enter your email address to get access to ${companyName}'s cord profile.`}
          showCloseIcon
          onClose={() => setShowClaimProfilePopup(false)}
        >
          <Field
            type="email"
            placeholder="Enter your company email*"
            value={email}
            onChange={(value: string) => setEmail(value)}
            onEnter={handleClaimProfile}
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Button
            text={`Claim ${companyName}'s profile`}
            action={handleClaimProfile}
            loading={loading}
          />
          <NoSubscriptionNote>
            <em>No subscription required.</em>
          </NoSubscriptionNote>
          <ValueProposition />
        </DialogWindow>
      </ConditionalRender>
      <ConditionalRender predicate={emailSent}>
        <DialogWindow
          visible={emailSent}
          topIcon={{ src: SuccessIcon, alt: 'success', role: 'presentation', width: 100 }}
          title="We sent you an email!"
          subTitle={`Verify your email address and get access to ${companyName}'s cord profile.`}
          showCloseIcon
          onClose={() => setEmailSent(false)}
        >
          <ConditionalRender predicate={!loading} fallback={<ResendLoader className="small" />}>
            <ResendEmailNote>
              Didn't receive an email? Check your spam or{' '}
              <ResendLink className="link" onClick={resendEmail}>
                resend
              </ResendLink>
            </ResendEmailNote>
          </ConditionalRender>
          <ValueProposition />
        </DialogWindow>
      </ConditionalRender>
    </Wrapper>
  );
}
