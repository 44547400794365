import { createAsyncThunk } from '@reduxjs/toolkit';
import { MessageFilter } from '@cohiretech/common-types';

import { MESSAGE_VIEW, getCompanyNotifications } from 'fetcher';
import {
  AppDispatch,
  RootState,
  selectMessageFilter,
  setAssociatedSelfTotalNotifications,
  setCompanyNotifications,
  setTotalNotifications
} from 'store';
import {
  getAssociatedCompanyNotificationsCount,
  getSelectedCompanyNotificationsCount,
  setNotificationsOnTab
} from 'utils';

export const reloadCompanyNotifications = createAsyncThunk<
  { totalNotifications: number; notifications: any[] },
  MessageFilter | undefined,
  { dispatch: AppDispatch; state: RootState }
>('services/company/reloadNotifications', async (messageFilter, { dispatch, getState }) => {
  const state = getState();
  const location = window.location;
  const msgFilter = messageFilter || selectMessageFilter(state);

  const { data: notifications } = await getCompanyNotifications(msgFilter);
  const totalNotifications = getSelectedCompanyNotificationsCount(notifications);

  setNotificationsOnTab(totalNotifications, location.pathname);

  dispatch(setCompanyNotifications(notifications));
  dispatch(setTotalNotifications(totalNotifications));

  const associatedSelfTotalNotifications = await getAssociatedSelfTotalNotifications(
    notifications,
    msgFilter
  );
  dispatch(setAssociatedSelfTotalNotifications(associatedSelfTotalNotifications));

  return { totalNotifications, notifications };
});

export const getAssociatedSelfTotalNotifications = async (
  notifications: any[],
  messageFilter: 'self' | 'team'
) => {
  let associatedSelfTotalNotifications = 0;
  if (messageFilter === MESSAGE_VIEW.USER) {
    associatedSelfTotalNotifications = getAssociatedCompanyNotificationsCount(notifications);
  } else {
    const { data: selfNotifications } = await getCompanyNotifications(MESSAGE_VIEW.USER);
    associatedSelfTotalNotifications = getAssociatedCompanyNotificationsCount(selfNotifications);
  }

  return associatedSelfTotalNotifications;
};
