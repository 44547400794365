/* eslint-disable no-console */
import { Location } from 'history';
// eslint-disable-next-line import/no-extraneous-dependencies
import { JobPosting } from 'schema-dts';

import {
  formatDate,
  removeTag,
  generateCompanyURL,
  isExclusivelyRemote,
  getMonthsOfExperience,
  isEmpty
} from 'utils';
import { capitalizeWords } from 'utils/string';
import { EU_COUNTRIES, JOB_TYPES } from 'fetcher';
import { unique } from 'utils/array';

// Example format
/* <script type="application/ld+json">
  {
    "@context" : "http://schema.org/",
    "@type" : "JobPosting",
    "title" : "Fitter and Turner",
    "description" : "<p>Widget assembly role for pressing wheel assemblies.</p>
      <p><strong>Educational Requirements:</strong> Completed level 2 ISTA
      Machinist Apprenticeship.</p>
      <p><strong>Required Experience:</strong> At
      least 3 years in a machinist role.</p>",
    "identifier": {
      "@type": "PropertyValue",
      "name": "MagsRUs Wheel Company",
      "value": "1234567"
    },
    "datePosted" : "2017-01-18",
    "validThrough" : "2017-03-18T00:00",
    "employmentType" : "CONTRACTOR",
    "hiringOrganization" : {
      "@type" : "Organization",
      "name" : "MagsRUs Wheel Company",
      "sameAs" : "http://www.magsruswheelcompany.com",
      "logo" : "http://www.example.com/images/logo.png"
    },
    "jobLocation" : {
      "@type" : "Place",
      "address" : {
        "@type" : "PostalAddress",
        "streetAddress" : "555 Clancy St",
        "addressLocality" : "Detroit",
        "addressRegion" : "MI",
        "postalCode" : "48201",
        "addressCountry": "US"
      }
    },
    "baseSalary": {
      "@type": "MonetaryAmount",
      "currency": "USD",
      "value": {
        "@type": "QuantitativeValue",
        "value": 40.00,
        "unitText": "HOUR"
      }
    }
  }
</script> */

const getAddressCountry = (location: string) => {
  const lowerCasedLocation = location.toLowerCase();
  if (lowerCasedLocation.includes('united kingdom') || lowerCasedLocation.includes('uk')) {
    return 'GB';
  } else if (lowerCasedLocation.includes('united states') || lowerCasedLocation.includes('us')) {
    return 'US';
  }
  return '';
};

const getPostalCode = (location: string) => {
  const ukPostcodeRegex = /[A-Za-z]{1,2}\d{1,2}(?:\s?(?:\d?\w{2}))?/;
  const otherCountriesPostcodeRegex = /\d{4,}/; // US (5), Germany (5), France (5), Netherlands (4), Denmark (4)

  if (location.includes('United Kingdom') || location.includes('UK')) {
    return location.match(ukPostcodeRegex)?.[0] || '';
  }
  return location.match(otherCountriesPostcodeRegex)?.[0] || '';
};

const getRemoteCountries = (
  remoteLocationCountries: string[],
  remoteLocationContinents: string[]
) => {
  const remoteCountries = [];

  if (!isEmpty(remoteLocationCountries)) remoteCountries.push(...remoteLocationCountries);
  if (remoteLocationContinents.includes('Europe')) remoteCountries.push(...EU_COUNTRIES);
  if (remoteLocationContinents.includes('North America')) {
    remoteCountries.push('United States', 'Canada');
  }
  if (isEmpty(remoteLocationContinents) && isEmpty(remoteLocationCountries)) {
    remoteCountries.push(...EU_COUNTRIES, 'United States', 'Canada');
  }

  return unique(remoteCountries);
};

export const generateJobPostingSchema = (data: $TSFixMe, pathname: $TSFixMe) => {
  try {
    const structuredData: JobPosting = {
      '@type': 'JobPosting'
    };
    const employmentTypeOptions = [
      { initial: JOB_TYPES.PERMANENT, converted: 'FULL_TIME' },
      { initial: JOB_TYPES.PART_TIME, converted: 'PART_TIME' },
      { initial: JOB_TYPES.FREELANCE_CONTRACT, converted: 'CONTRACTOR' },
      { initial: JOB_TYPES.FTC, converted: 'CONTRACTOR' },
      { initial: JOB_TYPES.INTERNSHIP, converted: 'INTERN' }
    ];

    const {
      benefit,
      companyID,
      companyLogo,
      companyName,
      currency,
      datePosted,
      description,
      industry,
      position,
      jobType,
      locationCity,
      locationObject,
      primarySkills,
      salaryMin,
      salaryMax,
      secondarySkills,
      location,
      url,
      id,
      remote,
      remoteLocationCountries,
      remoteLocationContinents,
      seniorities
    } = data;
    const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;
    const companyURL = generateCompanyURL({} as Location, { companyName, companyID }, true);
    const remoteOnly = isExclusivelyRemote(remote);
    const formatedSeniorities = seniorities.map((s: string) => capitalizeWords(s)).join(', ');
    const allowedContinents =
      remoteLocationContinents?.filter((c: string) => ['Europe', 'North America'].includes(c)) ||
      [];
    const remoteCountries = getRemoteCountries(remoteLocationCountries, allowedContinents);

    removeTag('script', 'id', 'job_posting');

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.id = 'job_posting';

    // Create the job postings structured data
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    structuredData['@context'] = 'http://schema.org';
    structuredData.url = `${domain}${pathname}`;
    structuredData.title = position;
    structuredData.image = companyLogo;
    structuredData.skills = `Core: ${primarySkills.join(', ')} - Other: ${secondarySkills.join(
      ', '
    )}`;
    structuredData.validThrough = new Date(
      new Date().setMonth(new Date().getMonth() + 1)
    ).toISOString();
    structuredData.jobBenefits = benefit;
    structuredData.datePosted = formatDate(datePosted);
    structuredData.description = `${description}\nCore Skills: ${primarySkills.join(
      ', '
    )}\nOther Skills: ${secondarySkills.join(', ')}\nSeniority: ${formatedSeniorities}`;
    structuredData.employmentType = employmentTypeOptions
      .filter(({ initial }) => initial === jobType)
      .map(({ converted }) => converted);
    structuredData.experienceRequirements = {
      '@type': 'OccupationalExperienceRequirements',
      monthsOfExperience: getMonthsOfExperience(seniorities)
    };
    structuredData.industry = industry.join(', ');
    //SALARY
    structuredData.baseSalary = {
      '@type': 'MonetaryAmount',
      currency,
      value: {
        '@type': 'QuantitativeValue',
        minValue: salaryMin,
        maxValue: salaryMax,
        value: ((salaryMax + salaryMin) / 2).toString(),
        unitText: 'YEAR'
      }
    };

    // HIRING ORGANISATION
    structuredData.hiringOrganization = {
      '@type': 'Organization',
      name: companyName,
      sameAs: url,
      url: `${domain}${companyURL}`,
      logo: companyLogo
    };

    //IDENTIFIER
    structuredData.identifier = {
      '@type': 'PropertyValue',
      name: companyName,
      value: id
    };

    // REMOTE LOCATIONS
    if (remote.includes('remote')) {
      structuredData.jobLocationType = 'TELECOMMUTE';
      if (!isEmpty(remoteCountries)) {
        structuredData.applicantLocationRequirements = remoteCountries.map((country: string) => {
          return {
            '@type': 'Country',
            name: country
          };
        });
      }
    }

    // PHYSICAL LOCATION
    if (!remoteOnly) {
      structuredData.jobLocation = {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressLocality: locationCity,
          addressRegion: `${new RegExp('London').test(locationCity) ? 'Greater London' : ''}`,
          postalCode: getPostalCode(location),
          streetAddress: location,
          addressCountry: locationObject?.country || getAddressCountry(location)
        }
      };
    }

    script.innerText = JSON.stringify(structuredData);

    document.head.appendChild(script);
  } catch (error) {
    console.log(error, 'Failed to generate structured data for job posting');
  }
};
