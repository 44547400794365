/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import YouTube from 'react-youtube';

import { segmentTrack } from 'tracking-utils';
import { getIsDarkMode_FIXME } from 'cookieManager';
import { isTabletWidth } from 'responsiveConfig';

import colours from 'css/base/_colours.module.scss';

import Play from 'images/play.png';

type Props = {
  videoUrl: string;
  fallbackPhotoURL?: string;
  showVideoPlayer?: boolean;
  youtubeOptions?: {
    width: string;
    height?: string | number;
    title: string;
    allowFullScreen?: boolean;
  };
  id?: string;
};

type State = $TSFixMe;

export default class VideoItem extends React.Component<Props, State> {
  youTubeRef: $TSFixMe;
  constructor(props: Props) {
    super(props);

    this.state = {
      showIframe: false,
      paused: false,
      eventArr: [],
      defaultYoutubeOptions: { width: '100%', height: '320' }
    };

    this.youTubeRef = React.createRef();
    this.checkEventArr.bind(this);
  }

  handleVideoClick(ev: $TSFixMe) {
    ev.stopPropagation();
    ev.preventDefault();
    this.setState({ showIframe: true });
  }

  handleVideoPausedClick(ev: $TSFixMe) {
    ev.stopPropagation();
    ev.preventDefault();
    this.youTubeRef.current.internalPlayer.playVideo();
    this.setState({ paused: false });
  }

  /**
   * Returns a string for the event action based on the current youtube state
   * @param {number} state
   */
  getEventActionFromState(state: $TSFixMe) {
    switch (state) {
      case -1:
        return 'unstarted';
      case 0:
        return 'ended';
      case 1:
        return 'playing';
      case 2:
        return 'paused';
      case 3:
        return 'buffering';
      case 5:
        return 'video cued';
      default:
        return '';
    }
  }

  handleStateChange(event: $TSFixMe) {
    const { showVideoPlayer, youtubeOptions } = this.props;
    const currState = event.target.getPlayerState();
    const eventAction = this.getEventActionFromState(currState);

    if (youtubeOptions?.title && eventAction) {
      segmentTrack(eventAction, {
        category: 'Videos',
        label: youtubeOptions.title
      });
    }

    if (!showVideoPlayer) {
      this.setState(({ eventArr }: $TSFixMe) => ({ eventArr: eventArr.concat(currState) }));
      this.checkEventArr();
    }
  }

  checkEventArr() {
    const { eventArr } = this.state;

    setTimeout(() => {
      if (eventArr[eventArr.length - 1] === 2) this.setState({ paused: true });
      else if (eventArr[eventArr.length - 1] === 3) this.checkEventArr();
      else if (eventArr[eventArr.length - 1] === 0) this.setState({ showIframe: false });
    }, 500);
  }

  getYouTubeId(url: $TSFixMe) {
    if (new RegExp('youtube').test(url)) {
      let ID = '';
      const splitUrl = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

      if (splitUrl[2] !== undefined) ID = splitUrl[2].split(/[^0-9a-z_-]/i)[0];
      else ID = url;

      return ID.toString();
    }
    return null;
  }

  onReady(event: $TSFixMe) {
    const { showIframe } = this.state;

    if (showIframe) event.target.playVideo();
    else event.target.pauseVideo();
  }

  componentDidUpdate(prevProps: {}) {
    if ((prevProps as $TSFixMe).youtubeOptions !== (this.props as $TSFixMe).youtubeOptions) {
      this.setState({ showIframe: false });
    }
  }

  render() {
    const { videoUrl, showVideoPlayer, fallbackPhotoURL, id } = this.props;
    const { showIframe, paused, defaultYoutubeOptions } = this.state;
    const youtubeID = this.getYouTubeId(videoUrl);
    const youtubeOptions = (this.props as $TSFixMe).youtubeOptions || defaultYoutubeOptions;
    const darkMode = getIsDarkMode_FIXME();

    if ((isTabletWidth || !showVideoPlayer) && !showIframe) {
      return (
        <VideoWrapper
          dark={darkMode}
          onClick={ev => this.handleVideoClick(ev)}
          className="video_wrapper"
          youtubeOptions={youtubeOptions}
        >
          <VideoImage
            className="video_thumbnail"
            src={youtubeID ? `https://img.youtube.com/vi/${youtubeID}/0.jpg` : fallbackPhotoURL}
            width={youtubeOptions.width}
            height={youtubeOptions.height}
            alt="Video Thumbnail"
            loading="lazy"
          />
          <PlayIcon
            className="video_play"
            width="75"
            height="77"
            src={Play}
            alt="Play Icon"
            loading="lazy"
          />
        </VideoWrapper>
      );
    } else if (showVideoPlayer || showIframe) {
      return (
        <VideoWrapper dark={darkMode} className="video_wrapper" youtubeOptions={youtubeOptions}>
          {youtubeID ? (
            <YouTube
              videoId={youtubeID}
              id={id}
              opts={youtubeOptions}
              onReady={e => this.onReady(e)}
              onStateChange={event => this.handleStateChange(event)}
              ref={this.youTubeRef}
            />
          ) : (
            <iframe
              width="100%"
              height="100%"
              title={videoUrl}
              src={videoUrl}
              onError={e => console.log(e)}
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
          {paused && (
            <PausedContainer
              onClick={ev => this.handleVideoPausedClick(ev)}
              youtubeOptions={youtubeOptions}
              className="video_paused"
            />
          )}
        </VideoWrapper>
      );
    }
  }
}

const VideoWrapper = styled.div<{ youtubeOptions: Props['youtubeOptions']; dark: boolean }>`
  position: relative;
  text-align: center;
  height: ${({ youtubeOptions }) => youtubeOptions?.height}px;
  background-color: ${({ dark }) => (dark ? colours.darkerBgColour : colours.lightFontColour)};
  color: ${({ dark }) => (dark ? colours.fontColour : colours.inputsColour)};
  border-radius: 20px;
  overflow: hidden;

  &:before {
    position: absolute;
    content: 'Loading ...';
    font-weight: ${({ theme: { typography } }) => typography.bold};
    font-size: 18px;
    left: 0;
    width: 100%;
    top: 45%;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

const PausedContainer = styled.div<{ youtubeOptions: Props['youtubeOptions'] }>`
  position: absolute;
  width: 100%;
  height: ${({ youtubeOptions }) => youtubeOptions?.height}px;
`;

const PlayIcon = styled.img`
  position: absolute;
  z-index: 1;
  right: 50%;
  bottom: 50%;
  margin-right: -37px;
  margin-bottom: -38px;
  cursor: pointer;
`;

const VideoImage = styled.img`
  position: relative;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
