import { RefObject } from 'react';

import { StringOrElements } from 'types';

import { TextEnhancerAction } from './TextEnhancer.actions';
import { AIAction } from './TextEnhancer.helpers';

type TextEnhancerState = {
  textEditor: RefObject<HTMLDivElement>;
  errorMessage: StringOrElements;
  lastAIAction: AIAction | null;
  status: 'success' | 'error' | null;
  highlightMarked: boolean;
  isLoading: boolean;
  showCustomPrompt: boolean;
  isEnhanced: boolean;
};

export const initialState: TextEnhancerState = {
  textEditor: {} as RefObject<HTMLDivElement>,
  errorMessage: '',
  lastAIAction: null,
  status: null,
  highlightMarked: true,
  isLoading: false,
  showCustomPrompt: false,
  isEnhanced: false
};

export const reducer = (
  state: TextEnhancerState,
  action: TextEnhancerAction
): TextEnhancerState => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_TEXT_EDITOR':
      return { ...state, textEditor: payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: payload };
    case 'SET_SHOW_CUSTOM_PROMPT':
      return { ...state, showCustomPrompt: payload };
    case 'SET_ATTEMPT_ENHANCE_TEXT':
      return {
        ...state,
        lastAIAction: payload,
        status: null,
        errorMessage: ''
      };
    case 'SET_FAILED_ENHANCE_TEXT':
      return {
        ...state,
        status: 'error',
        errorMessage: payload
      };
    case 'SET_SUCCESS_ENHANCE_TEXT':
      return {
        ...state,
        status: 'success'
      };
    case 'SET_IS_HIGHLIGHTED':
      return {
        ...state,
        highlightMarked: payload
      };
    case 'SET_IS_ENHANCED':
      return {
        ...state,
        isEnhanced: payload
      };
    default:
      return state;
  }
};
