import { useRef, useState } from 'react';
import YouTube, { YouTubeEvent } from 'react-youtube';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { segmentTrack } from 'tracking-utils';
import { isFunction } from 'utils/fn';

import PlayWhite from 'images/play.png';
import PlayIconLightBlue from 'images/play-icon.svg';

import { PausedContainer, PlayIcon, VideoImage, VideoWrapper } from './VideoItem.styled';
import { getVideoImage } from './VideoItem.helpers';

export type VideoItemProps = {
  videoUrl: string;
  fallbackPhotoURL?: string;
  coverPhotoURL?: string;
  showVideoPlayer?: boolean;
  youtubeOptions?: {
    width: string;
    height?: string | number;
    title: string;
    allowFullScreen?: boolean;
    aspectRatio?: string | number;
  };
  id?: string;
  playIcon?: 'white' | 'light_blue';
};

const INITIAL_STATE = {
  showIframe: false,
  paused: false,
  eventArr: []
};

const DEFAULT_YOUTUBE_OPTIONS = {
  width: '100%',
  height: '320'
};

export default function VideItem({
  videoUrl,
  fallbackPhotoURL,
  coverPhotoURL,
  showVideoPlayer,
  youtubeOptions,
  id,
  playIcon = 'white'
}: VideoItemProps) {
  const isTablet = useMedia(mediaQueries.tablet);
  const [state, setStateDirectly] = useState(INITIAL_STATE);
  const youTubeRef = useRef<YouTube>(null);
  const { paused, showIframe } = state;

  const setState = (
    newState:
      | Partial<typeof INITIAL_STATE>
      | ((prevState: typeof INITIAL_STATE) => Partial<typeof INITIAL_STATE>)
  ) => {
    if (isFunction(newState)) setStateDirectly(prev => ({ ...prev, ...newState(prev) }));
    else setStateDirectly(prev => ({ ...prev, ...newState }));
  };

  const onReady = (event: YouTubeEvent) => {
    const { showIframe } = state;

    if (showIframe) event.target.playVideo();
    else event.target.pauseVideo();
  };

  const getEventActionFromState = (state: number) => {
    switch (state) {
      case -1:
        return 'unstarted';
      case 0:
        return 'ended';
      case 1:
        return 'playing';
      case 2:
        return 'paused';
      case 3:
        return 'buffering';
      case 5:
        return 'video cued';
      default:
        return '';
    }
  };

  const handleStateChange = (event: YouTubeEvent) => {
    const currState = event.target.getPlayerState();
    const eventAction = getEventActionFromState(currState);

    if (youtubeOptions?.title && eventAction) {
      segmentTrack(eventAction, {
        category: 'Videos',
        label: youtubeOptions.title
      });
    }

    if (!showVideoPlayer) {
      setState(({ eventArr }) => ({ eventArr: eventArr.concat(currState) }));
      checkEventArr();
    }
  };

  const checkEventArr = () => {
    const { eventArr } = state;

    setTimeout(() => {
      if (eventArr[eventArr.length - 1] === 2) setState({ paused: true });
      else if (eventArr[eventArr.length - 1] === 3) checkEventArr();
      else if (eventArr[eventArr.length - 1] === 0) setState({ showIframe: false });
    }, 500);
  };

  const getYouTubeId = (url: $TSFixMe) => {
    if (new RegExp('youtube').test(url)) {
      let ID = '';
      const splitUrl = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

      if (splitUrl[2] !== undefined) ID = splitUrl[2].split(/[^0-9a-z_-]/i)[0];
      else ID = url;

      return ID.toString();
    }
    return undefined;
  };

  const handleVideoClick = (ev: $TSFixMe) => {
    ev.stopPropagation();
    ev.preventDefault();
    setState({ showIframe: true });
  };

  const handleVideoPausedClick = (ev: $TSFixMe) => {
    if (!youTubeRef.current) return;
    ev.stopPropagation();
    ev.preventDefault();
    youTubeRef.current.internalPlayer.playVideo();
    setState({ paused: false });
  };

  const youtubeID = getYouTubeId(videoUrl);
  const width = youtubeOptions?.width || DEFAULT_YOUTUBE_OPTIONS.width;
  const height = youtubeOptions?.height || DEFAULT_YOUTUBE_OPTIONS.height;
  const videoImage = getVideoImage(youtubeID, coverPhotoURL, fallbackPhotoURL);

  if ((isTablet || !showVideoPlayer) && !showIframe) {
    return (
      <VideoWrapper
        onClick={handleVideoClick}
        className="video_wrapper"
        height={height}
        width={width}
        aspectRatio={youtubeOptions?.aspectRatio}
      >
        <VideoImage
          className="video_thumbnail"
          src={videoImage}
          width={width}
          height={height}
          alt="Video Thumbnail"
          loading="lazy"
        />
        <PlayIcon
          className="video_play"
          width="75"
          height="77"
          src={playIcon === 'white' ? PlayWhite : PlayIconLightBlue}
          alt="Play Icon"
          loading="lazy"
        />
      </VideoWrapper>
    );
  } else if (showVideoPlayer || showIframe) {
    return (
      <VideoWrapper
        className="video_wrapper"
        height={height}
        width={width}
        aspectRatio={youtubeOptions?.aspectRatio}
      >
        {youtubeID ? (
          <YouTube
            videoId={youtubeID}
            id={id}
            opts={youtubeOptions}
            onReady={onReady}
            onStateChange={handleStateChange}
            ref={youTubeRef}
          />
        ) : (
          <iframe
            width="100%"
            height="100%"
            title={videoUrl}
            src={videoUrl}
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
        {paused && (
          <PausedContainer
            onClick={handleVideoPausedClick}
            height={height}
            className="video_paused"
          />
        )}
      </VideoWrapper>
    );
  }
  return null;
}
