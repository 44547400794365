import React, { ErrorInfo } from 'react';
import styled, { css } from 'styled-components';

import { datadogLogs } from '@datadog/browser-logs';

import { getIsDarkMode_FIXME } from 'cookieManager';

import colours from 'css/base/_colours.module.scss';

type Props = { inline?: boolean };

export default class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to Datadog
    datadogLogs.logger.error(error.message, { errorInfo, fromErrorBoundary: true });
  }

  render() {
    const { inline, children } = this.props;
    const darkMode = getIsDarkMode_FIXME();

    if (this.state.hasError) {
      return (
        <ErrorWrapper className="page_view" inline={inline} dark={darkMode}>
          <h3>We couldn't load this :(</h3>
          Check your internet connection and try reloading the page.
        </ErrorWrapper>
      );
    }

    return children;
  }
}

const ErrorWrapper = styled.div<Props & { dark: boolean }>`
  text-align: center;
  background-color: ${({ dark }) => (dark ? colours.darkBgColour : colours.lightBgColour)};
  border-radius: 25px;

  h3 {
    margin: 0 auto 5px;

    ${({ inline }) =>
      inline &&
      css`
        display: inline-block;
        margin: 0 10px 0 0;
      `}
  }
`;
