import axios from 'axios';

import { SavedSearchTrackingDTO } from '@cohiretech/common-types';

const SEARCH_TRACK_VIEW = process.env.REACT_APP_SEARCH_TRACK_VIEW;

export const trackSearchView = async (metadata: SavedSearchTrackingDTO) => {
  try {
    await axios.post(`${SEARCH_TRACK_VIEW}`, metadata);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
