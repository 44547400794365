import { DateArgument } from 'types';

export const isToday = (date: DateArgument | Date) => {
  const today = new Date();
  const other = new Date(date);

  return (
    today.getDate() === other.getDate() &&
    today.getMonth() === other.getMonth() &&
    today.getFullYear() === other.getFullYear()
  );
};
