/* eslint-disable no-console */
import { CompanyType, UserType } from '@cohiretech/common-types';
// import { CompanyType, LocationInterface, UserType } from '@cohiretech/common-types';

import { CandidateProfile, CompanyProfile, DeepReadonly } from 'types';

import { ProfileState } from './user.types';

export function isCandidateProfile(profile: ProfileState): profile is CandidateProfile {
  return profile?.profileType === UserType.Candidate;
}

export function candidateSelector(profile: ProfileState) {
  return isCandidateProfile(profile) ? (profile as DeepReadonly<CandidateProfile>) : null;
}

export function isCompanyProfile(profile: ProfileState): profile is CompanyProfile {
  return profile?.profileType === UserType.Company;
}

export function companySelector(profile: ProfileState) {
  return isCompanyProfile(profile) ? (profile as DeepReadonly<CompanyProfile>) : null;
}

export function isAgencyProfile(profile: ProfileState): profile is CompanyProfile {
  return isCompanyProfile(profile) ? profile?.companyType === CompanyType.Agency : false;
}

export function isCandidateProAllowed(profile: ProfileState) {
  if (!isCandidateProfile(profile)) return false;

  const isCandidateRejected = profile?.rejected;
  // const isTechCandidate = profile?.isTechCandidate;

  // const location = profile?.location as LocationInterface;
  // return isVerifiedCandidate && isTechCandidate && location?.country === 'United Kingdom';
  return !isCandidateRejected;
}

export function isCompanyIntegratedToATS(profile: ProfileState): profile is CompanyProfile {
  return isCompanyProfile(profile) ? !!profile?.companyUser?.atsIntegration : false;
}
