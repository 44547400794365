import React, { ReactNode } from 'react';
import ClassNames from 'classnames';

import { BadgeProps } from 'components/badge';
import Tooltip, { Props as TooltipProps } from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import {
  InfoIcon,
  RadioBadge,
  RadioButtonLabel,
  RadioContainer,
  RadioInput,
  RadioMark,
  RadioText
} from './RadioButtons.styled';

export type RadioButtonOption = {
  label: string;
  value: string;
  id?: string;
  badge?: BadgeProps;
  helpTooltip?: TooltipProps;
  children?: ReactNode;
};

type RadioButtonsProps = {
  className?: string;
  options: RadioButtonOption[];
  selected?: RadioButtonOption;
  onChange: (selected: RadioButtonOption) => void;
};

const RadioButtons: React.FC<RadioButtonsProps> = ({ className, options, selected, onChange }) => {
  const handleOnChange = (option: RadioButtonOption) => () => {
    if (option.value === selected?.value) return;
    onChange(option);
  };

  return (
    <RadioContainer className={className}>
      {options.map(option => {
        const { label, value, helpTooltip, badge, children = null } = option;
        const checked = selected?.value === value;
        return (
          <RadioButtonLabel
            key={value}
            className={ClassNames('radio_button_label', { selected: checked })}
            onClick={handleOnChange(option)}
          >
            <RadioText className="radio_text">{label}</RadioText>
            <ConditionalRender predicate={helpTooltip}>
              <InfoIcon className="icon_help">
                <Tooltip {...helpTooltip} />
              </InfoIcon>
            </ConditionalRender>
            <ConditionalRender predicate={badge}>
              <RadioBadge {...badge!} />
            </ConditionalRender>
            <RadioInput type="radio" checked={checked} readOnly />
            <RadioMark className="radio_mark" />
            {children}
          </RadioButtonLabel>
        );
      })}
    </RadioContainer>
  );
};

export default RadioButtons;
