/**
 * Returns a new array that contains only the non-null, non-undefined and non-NaN elements of the input array.
 *
 * @template T - The type of the elements in the array.
 * @param {T[]} arr - The input array.
 * @returns {T[]} - A new array that contains only the non-null and non-undefined elements of the input array.
 */
export function compact<T>(arr: (T | any)[]): NonNullable<T>[] {
  const result: NonNullable<T>[] = [];
  for (let i = 0; i < arr.length; ++i) {
    const elem = arr[i];
    if (elem != null && !Number.isNaN(elem)) {
      result.push(elem);
    }
  }
  return result;
}
