import { MilliSeconds } from 'types';

export class MaxRetriesExceededError extends Error {
  constructor(
    public maxRetries: number,
    public lastError?: Error | null,
    message = `Max retries (${maxRetries}) exceeded`
  ) {
    super(message);
    this.name = 'MaxRetriesExceededError';
  }
}

export class OperationCancelledError extends Error {
  constructor(message = 'Operation was cancelled') {
    super(message);
    this.name = 'OperationCancelledError';
  }
}

export const backoff = (
  attempt: number,
  initialDelay: MilliSeconds,
  maxDelay: MilliSeconds
): MilliSeconds => {
  const delay = initialDelay * 2 ** attempt;
  return delay > maxDelay ? maxDelay : delay;
};

const handleCancel = (reject: (reason?: any) => void) => {
  reject(new OperationCancelledError());
};

export const delayWithCancellation = async (
  attempt: number,
  initialDelay: MilliSeconds,
  maxDelay: MilliSeconds,
  signal?: AbortSignal
): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (signal) {
      signal.addEventListener('abort', () => handleCancel(reject), { once: true });
    }

    setTimeout(resolve, backoff(attempt, initialDelay, maxDelay));
  });
};
