import React from 'react';
import styled from 'styled-components';
import { NavLink as Link, useLocation } from 'react-router-dom';
import ClassNames from 'classnames';

import { getIsDarkMode_FIXME } from 'cookieManager';

import { generateInsightsURL } from 'views/landing/insights/helpers';

const InsightsClipItem = ({ style, clip }: $TSFixMe) => {
  const { uid, topic, involvedPeople, featuredPersonID, featuredPersonType, title, previewPhoto } =
    clip;
  const darkMode = getIsDarkMode_FIXME();
  const location = useLocation();

  const featuredPerson = involvedPeople?.find(
    (person: $TSFixMe) =>
      person?.id === featuredPersonID && person?.type === featuredPersonType?.toLowerCase()
  );

  return (
    <InsightCardItem
      className={ClassNames('square_card_item clickable insights_clip_item', { dark: darkMode })}
      style={style}
      to={{
        pathname: generateInsightsURL({ contentType: 'clip', topic, uid }),
        search: location.search,
        state: { previousPath: location.pathname }
      }}
    >
      <div
        className="clip_image"
        style={{
          backgroundImage: `linear-gradient(0deg, #0f1b29 0%, rgba(40, 61, 84, 0) 100%), url('${
            previewPhoto?.url || ''
          }')`
        }}
      />
      <DescriptionWrapper className="article_subtitle">
        <h3>{title?.text}</h3>
        <h4>{featuredPerson && `${featuredPerson?.firstname}, ${featuredPerson?.jobTitle}`}</h4>
      </DescriptionWrapper>
    </InsightCardItem>
  );
};

export default InsightsClipItem;

const DescriptionWrapper = styled.div`
  position: absolute;
  width: 90%;
  color: white;
  bottom: 25px;
  left: 20px;
  white-space: pre-line;
`;

const InsightCardItem = styled(Link)`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  transition: all 0.2s ease-in-out !important;
  height: 400px;
  padding: 0 !important;

  .clip_image {
    border-radius: 20px;
    height: 100%;
    max-width: 100%;
    background-size: cover;
    background-position: center;
  }
`;
