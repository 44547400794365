import { ObjectValues, Path } from 'types';
import { toArray } from 'utils/array';

import { path } from './path';

export const equalsPick =
  <T = Record<string, unknown>>(route: Path | string) =>
  (value: unknown) =>
  (objs: T[]): T | undefined => {
    const found = objs.find(obj => path(obj as Record<string, unknown>, toArray(route)) === value);
    return found;
  };

export const equalsPickByKey =
  <T extends Object>(objs: T[]) =>
  (key: keyof T) =>
  (value: ObjectValues<T>): T | undefined => {
    const found = objs.find(obj => obj[key] === value);
    return found;
  };
