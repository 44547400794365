import axios from 'axios';

import { CategorisedListingsDTO } from '@cohiretech/common-types';

import { APIResponse } from 'types';

const POSITION = process.env.REACT_APP_POSITION!;

export const getSearchOptionsByCategories = async (): Promise<
  APIResponse<CategorisedListingsDTO>
> => {
  try {
    const { data } = await axios.get<{ data: CategorisedListingsDTO }>(
      `${POSITION}/categories/candidate`
    );

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
