import { WorkEligibilityLocation } from '@cohiretech/common-types';

import { getWorkEligibilityOptions } from 'fetcher';
import { SearchItems } from 'types';
import { isPartialMatch } from 'utils/object';

type WorkEligibilityValue = ReturnType<typeof getWorkEligibilityOptions>[number]['value'];

const lowercaseLocation = (location: string) => {
  switch (location) {
    case 'UK':
      return WorkEligibilityLocation.UK;
    case 'US':
      return WorkEligibilityLocation.US;
    case 'EU':
      return WorkEligibilityLocation.EU;
    default:
      return location;
  }
};

export const WORK_ELIGIBILITY_OPTIONS = getWorkEligibilityOptions('Allow temp. visas').map(
  option => {
    const optionCopy = { ...option };
    const { value, description } = optionCopy;
    optionCopy.value.location = lowercaseLocation(value.location);

    if (description) {
      optionCopy.description.value.location = lowercaseLocation(description.value.location);
    }

    return optionCopy;
  }
);

export const mapWorkEligibilitiesToOptionFormat = (workEligibilities: WorkEligibilityValue[]) => {
  const selected: SearchItems = [];

  workEligibilities.forEach(workEligibility => {
    const partialEligibilityMatch = isPartialMatch(workEligibility);

    for (const option of WORK_ELIGIBILITY_OPTIONS) {
      const { value, description } = option;
      let match;

      if (partialEligibilityMatch(value)) match = option;
      if (description && partialEligibilityMatch(description.value)) match = description;

      if (match) {
        selected.push({ ...match, attribute: 'workEligibilities' });
        break;
      }
    }
  });

  return selected;
};
