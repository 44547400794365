import React, { useState } from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { mobile } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';
import { useStartUsingCordPopup } from 'store';

import DialogWindow from 'components/dialogwindow';
import { Wrapper } from 'components/commonstyles/Wrapper';

import { BoldText } from 'v2/components/ui/styles';
import Spacing from 'v2/components/ui/styles/Spacing';

import typography from 'css/base/_typography.module.scss';

import StartUpBlockContent from './popupbody';

interface Props {
  title?: string;
  isPopup?: boolean;
  styles?: {
    position: string;
    bottom: string;
    zIndex: string;
    padding: string;
    background: string;
  };
}

export default function StartUsingCordPopup({ title, styles, isPopup = true }: Props) {
  const darkMode = useDarkMode();
  const [isPopupVisible, setPopupVisibility] = useState(true);
  const [, setShowStartUsingCordPopup] = useStartUsingCordPopup();

  const onClose = () => {
    setPopupVisibility(false);

    setShowStartUsingCordPopup(false);
  };

  if (isPopup) {
    return (
      <DialogWindow
        id="start_using_cord_popup"
        visible={isPopupVisible}
        showCloseIcon
        onClose={onClose}
        topCode={
          <>
            <BoldText className="lightblue_text">Make direct hires on cord</BoldText>
            <Spacing height="01" />
          </>
        }
        title={title || 'See all of cord in a 1:1 demo'}
        subTitle="Get your questions answered, see every candidate on cord & find out how to get started in a personalised 1-1 demo."
      >
        <StartUpBlockContent />
      </DialogWindow>
    );
  }
  return (
    <Wrapper
      id="start_using_block_content"
      className={ClassNames({ dark: darkMode })}
      backdrop="blur(1px)"
      width="inherit"
      {...styles}
    >
      <Title>{title}</Title>
      <>
        <h2 className="sub_title">
          Get your questions answered, see every candidate on cord & find out how to get started in
          a personalised 1-1 demo.
        </h2>
        <StartUpBlockContent />
      </>
    </Wrapper>
  );
}

const Title = styled.h2`
  font-size: 38px;
  margin: 0 auto 15px;
  padding-top: 120px;
  text-align: center;
  font-weight: ${typography.black};

  @media only screen and (max-width: ${mobile}) {
    font-size: 28px;
  }
`;
