import { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { CompanyType } from '@cohiretech/common-types';

import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { mobile } from 'responsiveConfig';
import { getShortLastActiveText, getResponseTime, getTimeAgo } from 'utils';
import { identifyCompanyType } from 'v2/services/company/companyType';

import TourBubble from 'components/tourbubble';
import Badge from 'components/badge';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import DownloadCordCV from 'v2/components/ui/molecules/DownloadCordCV';
import Avatar from 'v2/components/ui/atoms/Avatar';

import Company from 'images/company.png';

type Props = {
  personsPhotoURL: string;
  personsName: string;
  personsJobTitle: string;
  lastActive: string;
  responseTime: Record<string, any> | string;
  postedDate?: string;
  showTourBubble?: boolean;
  companyLogo?: string;
  positionName?: string;
  companyName?: string;
  companyType?: CompanyType;
  isUserLoggedIn?: boolean;
  companyURL?: string;
  personsURL?: string;
  onProfileClick: () => void;
  showPersonOnly?: boolean;
};

export default function PositionOverview({
  companyURL,
  personsURL,
  onProfileClick,
  personsPhotoURL,
  personsName,
  lastActive,
  personsJobTitle,
  postedDate,
  companyName,
  responseTime,
  showTourBubble,
  companyLogo,
  isUserLoggedIn,
  positionName,
  companyType,
  showPersonOnly
}: Props) {
  const shouldShowBubble = getLocalStorageItem('showPersonBubble') === null;
  const respondsIn =
    responseTime === 'N/A'
      ? 'Position recently posted'
      : `Typically responds ${getResponseTime(responseTime)}`;

  const { isExternal, isAgency } = identifyCompanyType(companyType);

  const [showBubble, setBubbleVisibility] = useState(shouldShowBubble);

  const closeBubble = () => {
    setLocalStorageItem('showPersonBubble', false);
    setBubbleVisibility(false);
  };

  const renderDetails = () => {
    const activityInfo: ReactNode[] = showPersonOnly ? [] : [`Message ${personsName}`];
    const lastActiveLabel = lastActive ? `Active ${getShortLastActiveText(lastActive)}` : '';

    if (respondsIn || lastActiveLabel) activityInfo.push(respondsIn || lastActiveLabel);

    return (
      <>
        <TitleWrapper>
          <ConditionalRender
            predicate={!showPersonOnly}
            fallback={
              <strong>
                Message {personsName} at {companyName}
              </strong>
            }
          >
            <strong>
              {positionName}
              {!isAgency ? ` at ${companyName}` : ''}
            </strong>
          </ConditionalRender>
          <ConditionalRender predicate={isAgency}>
            <Badge icon="icon_handshake" text="Managed by agency" type="info_light" />
          </ConditionalRender>
        </TitleWrapper>
        <ConditionalRender predicate={isExternal} fallback={activityInfo.join(`\u00A0 · \u00A0`)}>
          {!isUserLoggedIn ? (
            `Position posted ${getTimeAgo(postedDate)}`
          ) : (
            <CVTextPrompt>
              <span>You can use your cord CV when applying</span> <DownloadCordCV />
            </CVTextPrompt>
          )}
        </ConditionalRender>
      </>
    );
  };

  return (
    <PositionOverviewWrapper className="position_overview_cont">
      <ProfilePictures>
        <ConditionalRender predicate={!showPersonOnly}>
          <Avatar
            className="company_logo"
            width={48}
            photoURL={companyLogo}
            fallbackPhotoURL={Company}
            firstname={companyName}
            photoLink={companyURL}
            onClick={onProfileClick}
            linkTitle={`View ${companyName}'s profile`}
          />
        </ConditionalRender>
        <ConditionalRender predicate={!isExternal}>
          <Avatar
            className="user_photo"
            width={48}
            photoURL={personsPhotoURL}
            firstname={personsName}
            photoLink={personsURL}
            onClick={onProfileClick}
            linkTitle={`View ${personsName}`}
          />
        </ConditionalRender>
      </ProfilePictures>
      <ProfileDetails className="profile_details">{renderDetails()}</ProfileDetails>
      {showTourBubble && (
        <TourBubble
          show={showBubble}
          title={`Message ${personsName} directly`}
          body={`${personsName} is the ${personsJobTitle} at ${companyName} and is the best person to message if you’re interested in this position.`}
          position="top"
          align="left"
          size="large"
          buttonText="Got it!"
          onButtonClick={closeBubble}
          onClose={closeBubble}
        />
      )}
    </PositionOverviewWrapper>
  );
}

const ProfilePictures = styled.div`
  .profile_picture:not(:first-child) {
    margin-left: -16px;
  }
`;

const PositionOverviewWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 0.5rem;
  text-align: left;
  align-items: center;
  flex: 1;
  min-width: 0;

  .profile_picture {
    display: inline-block;
    background-color: ${({ theme }) => theme.colours.lightBgColour};
    transition: all 0.1s ease;
    vertical-align: middle;
  }

  @media only screen and (max-width: ${mobile}) {
    display: none;
  }
`;

const ProfileDetails = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  min-width: 0;

  strong {
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CVTextPrompt = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-width: 0;

  > span {
    margin-right: 0.5rem;
  }
`;
