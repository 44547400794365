import React, { useState } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { ListingInterface } from '@cohiretech/common-types';

import { mobile } from 'responsiveConfig';

import Button from 'components/button';
import Tooltip, { Props as TooltipProps } from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

export type Option = {
  label: string | (string | React.ReactNode)[];
  value?: number;
  name?: string;
  disabled?: boolean;
  details?: Partial<ListingInterface>;
};

type Props = {
  options: Option[];
  label: string;
  onSelect: (value: number, option?: Omit<Option, 'value'>) => void;
  darkMode?: boolean;
  tooltip?: TooltipProps;
  disabled?: boolean;
  separatedLastItemsCount?: number;
};

type StyledDropdownListProps = Pick<Props, 'separatedLastItemsCount'>;

function ButtonDropdown({
  options,
  label,
  onSelect,
  darkMode,
  tooltip,
  disabled,
  separatedLastItemsCount
}: Props) {
  const [visible, setVisible] = useState(false);

  const hide = (e: MouseEvent) => {
    setVisible(false);
    document.removeEventListener('click', hide);
    e.stopPropagation();
  };

  const toggleDropdown = (show: boolean) => {
    if (show) document.addEventListener('click', hide);

    setVisible(show);
  };

  return (
    <DropdownWrapper
      aria-expanded={visible ? 'true' : 'false'}
      className={ClassNames('dropdown_button_wrapper', { disabled })}
    >
      <Button
        text={label}
        className="dropdown_button"
        disabled={disabled}
        action={() => toggleDropdown(!visible)}
        icon="icon_simple_arrow_down"
        iconPosition="right"
      />
      <DropdownList
        className={ClassNames('dropdown_button_list', { show: visible, dark: darkMode })}
        separatedLastItemsCount={separatedLastItemsCount}
      >
        {options.map(({ label, value, name, disabled, details, ...rest }) => (
          <Item
            key={`${value}_${label}`}
            className={ClassNames(
              'dropdown_button_item',
              { disabled },
              { placeholder_text: !value && disabled }
            )}
            separatedLastItemsCount={separatedLastItemsCount}
            onClick={
              disabled
                ? () => {
                    return;
                  }
                : () => onSelect(value!, { name, label, ...details, ...rest })
            }
          >
            {Array.isArray(label) ? label.map(e => e) : label}
          </Item>
        ))}
      </DropdownList>
      <ConditionalRender predicate={tooltip}>
        <Tooltip {...tooltip} />
      </ConditionalRender>
    </DropdownWrapper>
  );
}

export default ButtonDropdown;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;

  &.disabled {
    opacity: 0.7;

    &:hover {
      opacity: 1;

      > .tooltip_wrapper {
        opacity: 1;
        display: block;
      }
    }
  }

  .button {
    width: 100%;
    text-align: left;

    .icon_simple_arrow_down {
      float: right;
      font-size: 20px !important;
      font-weight: bold;
      margin-left: 25px !important;

      @media only screen and (max-width: ${mobile}) {
        margin-left: 13px !important;
      }
    }
  }
`;

const DropdownList = styled.div<StyledDropdownListProps>`
  position: absolute;
  text-align: left;
  left: 0;
  width: 100%;
  display: none;
  z-index: 1;
  overflow: hidden;
  max-height: 0;
  background-color: white;
  border: 1px solid ${colours.lightFontColour};
  border-radius: 5px;
  box-sizing: border-box;

  &.dark {
    background-color: ${colours.darkBgColour};
    border-color: ${colours.darkerBgColour};
    box-shadow: 0 5px 15px -5px rgba(black, 0.2);

    .dropdown_button_item:hover {
      background-color: ${colours.darkerBgColour};
    }

    .dropdown_button_item.placeholder_text {
      color: ${colours.lightFontColour};
    }

    ${props =>
      props &&
      props.separatedLastItemsCount &&
      css`
        .dropdown_button_item:nth-last-child(${props.separatedLastItemsCount}) {
          border-top: 1px solid ${colours.darkerBgColour};
        }
      `}
  }

  &.show {
    max-height: 365px;
    display: block;
    overflow-y: auto;
  }
`;

const Item = styled.div<StyledDropdownListProps>`
  padding: 13px 20px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:not(.placeholder_text) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    background: ${colours.lightBgColour};
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: transparent;
    }

    &.placeholder_text {
      color: ${colours.inputColour};
    }
  }

  ${props =>
    props &&
    props.separatedLastItemsCount &&
    css`
      &:nth-last-child(${props.separatedLastItemsCount}) {
        border-top: 1px solid ${colours.lightFontColour};
      }

      [class^='icon_'] {
        float: right;
      }
    `}
`;
