import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminAccountInterface } from '@cohiretech/common-types';

import { RootState } from 'store';
import { DeepReadonly } from 'types';

export interface AdminState {
  account: AdminAccountInterface | null;
}

const initialState: AdminState = {
  account: null
};

export const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminAccount: (state, action: PayloadAction<AdminAccountInterface | null>) => {
      state.account = action.payload;
    }
  }
});

// Action creators
export const { setAdminAccount } = admin.actions;

// Selectors
const selectAdmin = (state: RootState) => state.admin;

export const selectAdminAccount = createSelector(
  selectAdmin,
  admin => admin.account as DeepReadonly<AdminAccountInterface> | null
);

// Thunks (async actions & side-effects)

export default admin.reducer;
