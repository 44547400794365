import React, { useState } from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { JobTitleOption } from '@cohiretech/common-types';

import { mobile } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';

import SelectQuestion from 'components/selectquestion';

import colours from 'css/base/_colours.module.scss';

type Props = {
  title: string;
  options: JobTitleOption[];
  selected: string[];
  multiple?: boolean;
  columns?: number;
  onSelect?: (value: JobTitleOption) => void;
  onDeselect?: (value: JobTitleOption) => void;
  disabled?: boolean;
};

export default function ExpandableSelectField({
  title,
  multiple,
  columns,
  onSelect,
  onDeselect,
  selected,
  disabled,
  options
}: Props) {
  const darkMode = useDarkMode();
  const [isOpen, setIsOpen] = useState(false);
  const optionsSelected = (selected as $TSFixMe)
    // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
    .map(option => options.find(({ value }) => value === option)?.label)
    // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
    .filter(label => !!label);

  const handleSelect = (value: string) => {
    const option = options.find(({ value: optionValue }) => optionValue === value);
    if (!option) return;
    if (selected.includes(value)) {
      onDeselect?.(option);
    } else {
      onSelect?.(option);
    }
  };

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <ExpandableSection className="expandable_section" dark={darkMode}>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <Title className="title" onClick={() => setIsOpen(!isOpen)} dark={darkMode}>
        {title}
        {optionsSelected.length > 0 && (
          <span className="selected"> ({optionsSelected.join(', ')})</span>
        )}
        <span
          className={ClassNames('arrow', {
            icon_simple_arrow_up: isOpen,
            icon_simple_arrow_down: !isOpen
          })}
        />
      </Title>
      {isOpen && (
        <SelectQuestion
          showQuestion={false}
          columns={columns}
          multiple={multiple}
          options={options}
          selected={selected}
          // @ts-expect-error TS(2322) FIXME: Type '{ showQuestion: false; columns: number | und... Remove this comment to see the full error message
          disableSelection={disabled}
          toggle={value => handleSelect(value)}
        />
      )}
    </ExpandableSection>
  );
}

const ExpandableSection = styled.div`
  border-bottom: 1px solid;

  &:first-child {
    border-top: 1px solid;
  }

  .select_options {
    margin-bottom: 10px;
  }
`;

const Title = styled.button`
  position: relative;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 0;

  &:hover,
  &:focus-visible {
    background-color: ${props =>
      (props as $TSFixMe).dark ? colours.darkBgColour : colours.lightBgColour};
    border: none;
    outline: 0;
  }

  .arrow {
    position: absolute;
    right: 0;
    top: calc(50% - 11px);
  }

  .selected {
    font-weight: bold;
    color: ${colours.primaryColour};
    font-size: 14px;
    margin-left: 5px;

    @media only screen and (max-width: ${mobile}) {
      margin-left: 0;
      display: block;
      width: calc(100% - 22px);
    }
  }
`;
