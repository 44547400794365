import React from 'react';
import styled from 'styled-components';

import media from 'styles/media';

import CompanyLogos, { getLogosDescription } from 'components/companylogos';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

export default function CompanyLogosRow({ show }: { show: boolean }) {
  const description = getLogosDescription();

  return (
    <ConditionalRender predicate={show}>
      <LogosSection limit={5} logoHeight="24px" description={description} />
    </ConditionalRender>
  );
}

const LogosSection = styled(CompanyLogos)`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 0;
  max-width: 482px;

  .company_logos {
    padding: 10px 0;
  }

  ${media.tablet`
    align-items: center;
    align-self: center;
    margin-top: 20px;

    > p {
      margin: 0;
    }
  `}
`;
