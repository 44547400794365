import React from 'react';
import { Link } from 'react-router-dom';
import { CANDIDATE_SEARCH_FILTERS } from 'consts/candidate/positionSearch';

import { useCandidateProfile } from 'store';
import { isEmpty } from 'utils';

import Tooltip from 'components/tooltip';

import { BoldText } from 'v2/components/ui/styles';

import {
  BlockingCriteriaTooltipProps,
  getLocationLabelByBlockedByCriteria
} from './BlockingCriteriaTooltip.helpers';

const WORK_ELIGIBILITY_FILTERS = {
  attribute: CANDIDATE_SEARCH_FILTERS.workEligibility.attribute,
  value: true,
  label: CANDIDATE_SEARCH_FILTERS.workEligibility.label
};

export default function BlockingCriteriaTooltip(props: BlockingCriteriaTooltipProps) {
  const { workEligibilities: candidateWorkEligibilities } = useCandidateProfile() || {};

  const locationLabel = getLocationLabelByBlockedByCriteria(props);

  switch (props.blockedByCriteria) {
    case 'workEligibility':
      return (
        <Tooltip width="260px">
          This position can't sponsor visas. The company has blocked applications from people who
          are <BoldText>not eligible to work in {locationLabel} without visa</BoldText>.{' '}
          <Link
            className="link"
            to={{
              pathname: '/candidate/search/new',
              state: !isEmpty(candidateWorkEligibilities) ? WORK_ELIGIBILITY_FILTERS : undefined
            }}
          >
            Find positions that sponsor
          </Link>
        </Tooltip>
      );
    case 'location':
      return (
        <Tooltip width="260px">
          The company has blocked applications from people{' '}
          <BoldText>outside of {locationLabel}</BoldText>. If you're within that area or willing to
          relocate,{' '}
          <Link className="link" to="/candidate/account">
            check your profile
          </Link>
          .
        </Tooltip>
      );
    case 'remote':
    case 'remoteDays':
      return (
        <Tooltip width="260px">
          The company has blocked applications from people who are{' '}
          <BoldText>not open to {locationLabel}</BoldText>. If you're able to do that,{' '}
          <Link className="link" to="/candidate/account">
            update your profile
          </Link>
          .
        </Tooltip>
      );
    default:
      return null;
  }
}
