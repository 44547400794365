import React, { CSSProperties } from 'react';
import ClassNames from 'classnames';

import { BasicOption, OptionalToRequired } from 'types';

import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import Avatar, { AvatarProps } from 'v2/components/ui/atoms/Avatar';

import {
  Count,
  DeleteIcon,
  LabelContainer,
  LabelWrapper,
  SearchOptionButton,
  SearchOptionContent
} from './SearchOption.styled';
import SkeletonSearchOption, { SkeletonSearchOptionProps } from './SkeletonSearchOption';

export type Option<V = string> = OptionalToRequired<BasicOption<string, V>, 'value'> & {
  count?: number | string;
  description?: string;
  icon?: string;
  logo?: AvatarProps;
  attribute?: string;
};

export type SearchOptionProps<V = string> = {
  selected?: V;
  focused: boolean;
  loadingState?: { loading: boolean; skeletonProps: SkeletonSearchOptionProps };
  sidePadding?: CSSProperties['padding'];
  onClick: (value: V, option: Option<V>) => void;
  onDelete?: (value: V, option: Option<V>) => void;
} & Option<V>;

export default function SearchOption<V = string>({
  selected,
  focused,
  loadingState,
  sidePadding = '0.5rem',
  onClick,
  onDelete,
  ...option
}: SearchOptionProps<V>) {
  const { value, count, description, icon, logo, label } = option;
  const { loading, skeletonProps } = loadingState || {};

  const renderIconOrLogo = () => {
    if (icon) return <span className={ClassNames(icon, { border: !!description })} />;
    if (logo) return <Avatar {...logo} />;
    return null;
  };

  if (loading) {
    return <SkeletonSearchOption iconOrLogo={renderIconOrLogo()} {...skeletonProps} />;
  }

  return (
    <SearchOptionButton
      className={ClassNames('search_option', { focused })}
      selected={value === selected}
      sidePadding={sidePadding}
      onClick={e => {
        if ((e.target as Element).closest('.icon_delete')) return;
        onClick(value, option);
      }}
    >
      <SearchOptionContent className="search_option_content">
        <LabelContainer className="search_option_label_container">
          {renderIconOrLogo()}
          <LabelWrapper fullWidth={!description} bold={!!(icon || logo)}>
            <div className="search_option_label">{label}</div>
            <ConditionalRender predicate={description}>
              <div className="search_option_description">{description}</div>
            </ConditionalRender>
          </LabelWrapper>
        </LabelContainer>
        <ConditionalRender predicate={count}>
          <Count>{count}</Count>
        </ConditionalRender>
        <ConditionalRender predicate={onDelete}>
          <DeleteIcon className="icon_delete" onClick={() => onDelete!(value, option)}>
            <Tooltip
              text="Delete"
              position="left"
              bgColor="var(--background-accent-negative-base)"
            />
          </DeleteIcon>
        </ConditionalRender>
      </SearchOptionContent>
    </SearchOptionButton>
  );
}
