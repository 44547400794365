import React, { PropsWithChildren, useEffect, useState } from 'react';
import ClassNames from 'classnames';
import { NavLink as Link } from 'react-router-dom';
import { swipeXAxisOnStart, swipeXAxisOnMove, swipeXAxisOnEnd } from 'swipeControl';

import { isMobileWidth, isTabletWidth } from 'responsiveConfig';

import { FlexContainer, SliderWrapper } from 'components/commonstyles';
import Loader from 'components/loader';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type SliderContainerProps = {
  isLoading?: boolean;
  title?: string;
  tooltip?: React.ReactNode;
  seeMoreLink?: {
    text: string;
    onClick?: () => void;
    path?: string;
  };
  showArrows?: boolean;
  itemType?: string;
  itemWidth: number;
  itemsCount?: number;
  columns?: number;
  className?: string;
};

type MarginOptionsType = {
  [key: string]: number;
  people_item: number;
  testimonial_item: number;
  insights_clip_item: number;
};

export const marginOptions: MarginOptionsType = {
  default: 1,
  people_item: 1.3,
  testimonial_item: 4,
  insights_clip_item: 2
};

const SliderContainer = ({
  isLoading,
  title,
  tooltip,
  seeMoreLink,
  showArrows = true,
  itemType = 'general',
  itemWidth,
  itemsCount = 0,
  columns = 3,
  className = '',
  children
}: PropsWithChildren<SliderContainerProps>) => {
  const [traverse, setTraverse] = useState({
    slideTo: 0,
    translateX: 0
  });

  const [layout, setLayout] = useState({
    slideToLimit: 0,
    margin: 1
  });

  useEffect(() => {
    let slideToLimit = Math.ceil(itemsCount / 2) - 2;

    if (itemType === 'insights_discussion_item') {
      slideToLimit = isTabletWidth ? Math.ceil(itemsCount / 2) - 1 : slideToLimit;
    }

    setLayout({
      ...layout,
      slideToLimit,
      margin: marginOptions[itemType] || marginOptions.default
    });
  }, [itemType]);

  const onPrev = () => {
    const { slideTo, translateX } = traverse;
    const { margin } = layout;

    if (slideTo > 0) {
      const translate = itemWidth + margin;

      setTraverse({
        translateX: translateX + translate,
        slideTo: slideTo - 1
      });
    }
  };

  const onNext = () => {
    const { slideTo, translateX } = traverse;
    const { margin } = layout;

    if (slideTo < itemsCount - (columns || 0)) {
      const translate = itemWidth + margin;

      setTraverse({
        translateX: translateX - translate,
        slideTo: slideTo + 1
      });
    }
  };

  const renderSeeMoreLink = () =>
    seeMoreLink ? (
      <Link className="link" onClick={seeMoreLink.onClick} to={seeMoreLink?.path || ''}>
        {seeMoreLink.text}
      </Link>
    ) : null;

  return (
    <SliderWrapper
      slideTo={traverse.slideTo}
      slideToLimit={layout.slideToLimit}
      translateX={traverse.translateX}
      itemType={itemType}
      itemsCount={itemsCount}
      columns={columns}
      title={title}
      showSeeMoreLink={!!seeMoreLink?.text}
    >
      <div className={ClassNames('listing_slider_list', className)}>
        <ConditionalRender
          predicate={!isLoading}
          fallback={<Loader className="listing_slider_loader medium" />}
        >
          <FlexContainer className="listing_slider_header">
            {title && (
              <h3 className="listing_slider_title">
                {title} {tooltip}
              </h3>
            )}
            <div className="listing_slider_navigation">
              {!isMobileWidth && renderSeeMoreLink()}
              {showArrows && (
                <div onClick={onPrev} className="scroll_arrow left_arrow">
                  <span className="icon_arrow_left" />
                </div>
              )}
              {showArrows && (
                <div onClick={onNext} className="scroll_arrow right_arrow">
                  <span className="icon_arrow_right" />
                </div>
              )}
            </div>
          </FlexContainer>
          <div
            className="listing_slider_overflow"
            onTouchStart={e => swipeXAxisOnStart(e)}
            onTouchMove={e => swipeXAxisOnMove(e)}
            onTouchEnd={e => swipeXAxisOnEnd(e, onNext, onPrev)}
          >
            <div className="listing_slider_list_wrapper">{children}</div>
          </div>
          <ConditionalRender predicate={isMobileWidth && seeMoreLink}>
            <FlexContainer className="listing_slider_header" justifyContent="center">
              <div className="listing_slider_navigation">{renderSeeMoreLink()}</div>
            </FlexContainer>
          </ConditionalRender>
        </ConditionalRender>
      </div>
    </SliderWrapper>
  );
};

export default SliderContainer;
