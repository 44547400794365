let touchStart: $TSFixMe;
let touchRight = 0;
let touchLeft = 9999;

function getXAxisMovement(event: $TSFixMe) {
  return event.touches[0].pageX;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
function getYAxisMovement(event: $TSFixMe) {
  return event.touches[0].pageY;
}

export function swipeXAxisOnStart(event: $TSFixMe) {
  touchStart = getXAxisMovement(event);
}

export function swipeXAxisOnMove(event: $TSFixMe) {
  const XAxisMovement = getXAxisMovement(event);

  if (XAxisMovement < touchStart) touchLeft = XAxisMovement;
  else if (XAxisMovement > touchStart) touchRight = XAxisMovement;
}

function swipeLeftDetection() {
  return touchLeft < touchStart - 100;
}

function swipeRightDetection() {
  return touchRight > touchStart + 100;
}

function swipeXAxisDirection() {
  const swipeLeft = swipeLeftDetection();
  const swipeRight = swipeRightDetection();

  if (swipeLeft) return 'left';
  else if (swipeRight) return 'right';
  else return '';
}

export function swipeXAxisOnEnd(
  event: $TSFixMe,
  onSwipeLeftFn: $TSFixMe,
  onSwipeRightFn: $TSFixMe
) {
  const direction = swipeXAxisDirection();

  switch (direction) {
    case 'left':
      event.preventDefault();
      onSwipeLeftFn();
      break;
    case 'right':
      event.preventDefault();
      onSwipeRightFn();
      break;
    default:
      break;
  }

  // reset touch data
  touchStart = 0;
  touchLeft = 9999;
  touchRight = 0;
}
