import React from 'react';

import {
  ApplicationMatchScoreInterface,
  Currency,
  WorkEligibility
} from '@cohiretech/common-types';

import { formatSalary, getCurrencySymbol, isEmpty, setAcronym } from 'utils';
import { capitalizeFirst } from 'utils/string';
import { getPlainWorkEligibilityLabel } from 'v2/services/common';
import { AnyObject } from 'types';
import { mapObject } from 'utils/object';

import Tooltip from 'components/tooltip';

type SalaryAttribute = {
  candidateSalary: number;
  currency: Currency;
};

export const getFitScoreTags = (
  levelName: string,
  matchData: ApplicationMatchScoreInterface['levelOneMetaData']
) => {
  switch (true) {
    case levelName.startsWith('Logistics'):
      return getLevelOneTags(matchData);
    case levelName.startsWith('Experience'):
      return getLevelTwoTags(matchData);
    case levelName.startsWith('Interests'):
      return getLevelThreeTags(matchData);
    default:
      return { matchingTags: [], nonMatchingTags: [] };
  }
};

export const getLevelOneTags = (
  levelOneMetaData?: ApplicationMatchScoreInterface['levelOneMetaData']
) => {
  const levelOneMatchingTags: (string | JSX.Element)[][] = [];
  const levelOneNonMatchingTags: (string | JSX.Element)[][] = [];

  if (!isEmpty(levelOneMetaData)) {
    mapObject(levelOneMetaData!, (value, key) => {
      switch (key) {
        case 'remote':
          value.forEach(({ match, attribute }) => {
            const tag = [getIconElement(match), capitalizeFirst(attribute as string)];
            if (match) levelOneMatchingTags.push(tag);
            else levelOneNonMatchingTags.push(tag);
          });
          return;
        case 'salary':
          value.forEach(({ match, attribute }) => {
            const tag = [
              // @ts-expect-error
              getIconElement(match, attribute.fullMatch),
              formatSalary(
                (attribute as SalaryAttribute).candidateSalary,
                getCurrencySymbol((attribute as SalaryAttribute).currency)
              ),
              getSalaryTooltip(match, attribute)
            ];
            if (match) levelOneMatchingTags.push(tag);
            else levelOneNonMatchingTags.push(tag);
          });
          return;
        case 'location':
          value.forEach(({ match, attribute }) => {
            const tag = [getIconElement(match), setAcronym(attribute as string)];
            if (match) levelOneMatchingTags.push(tag);
            else levelOneNonMatchingTags.push(tag);
          });
          return;
        case 'workEligibilities':
          value.forEach(({ match, attribute }) => {
            const tag = [
              getIconElement(match),
              getPlainWorkEligibilityLabel(attribute as WorkEligibility)
            ];
            if (match) levelOneMatchingTags.push(tag);
            else levelOneNonMatchingTags.push(tag);
          });
          return;
        default:
          return;
      }
    });
  }

  return { matchingTags: levelOneMatchingTags, nonMatchingTags: levelOneNonMatchingTags };
};

export const getLevelTwoTags = (
  levelTwoMetaData?: ApplicationMatchScoreInterface['levelTwoMetaData']
) => {
  const levelOneMatchingTags: (string | JSX.Element)[][] = [];
  const levelOneNonMatchingTags: (string | JSX.Element)[][] = [];

  if (!isEmpty(levelTwoMetaData)) {
    mapObject(levelTwoMetaData!, (value, key) => {
      levelTwoMetaData![key].forEach(({ match, attribute }) => {
        const tag = [getIconElement(match), getPrefix(key), capitalizeFirst(attribute as string)];
        if (match) levelOneMatchingTags.push(tag);
        else levelOneNonMatchingTags.push(tag);
      });
    });
  }

  return { matchingTags: levelOneMatchingTags, nonMatchingTags: levelOneNonMatchingTags };
};

export const getLevelThreeTags = (
  levelThreeMetaData?: ApplicationMatchScoreInterface['levelThreeMetaData']
) => {
  const levelOneMatchingTags: (string | JSX.Element)[][] = [];
  const levelOneNonMatchingTags: (string | JSX.Element)[][] = [];

  if (!isEmpty(levelThreeMetaData)) {
    mapObject(levelThreeMetaData!, (value, key) => {
      levelThreeMetaData![key].forEach(({ match, attribute }) => {
        const tag = [getIconElement(match), getPrefix(key), capitalizeFirst(attribute as string)];
        if (match) levelOneMatchingTags.push(tag);
        else levelOneNonMatchingTags.push(tag);
      });
    });
  }

  return { matchingTags: levelOneMatchingTags, nonMatchingTags: levelOneNonMatchingTags };
};

const getSalaryTooltip = (match: boolean, attribute: any) => {
  if (!match) return '';
  if (attribute.fullMatch === undefined) return '';

  if (!attribute.fullMatch) {
    const amountExceeded = attribute.candidateSalary - attribute.listingSalary;
    return (
      <span className="icon_help purple_gradient_text">
        <Tooltip position="bottom" tooltipStyle="dark">
          <strong>Close match:</strong> Exceeds position salary by{' '}
          {formatSalary(amountExceeded, getCurrencySymbol((attribute as SalaryAttribute).currency))}
        </Tooltip>
      </span>
    );
  }
  return '';
};

const SIZE_PREFIX = 'Size: ';
const INDUSTRY_PREFIX = 'Industry: ';

const PREFIX_MAP: AnyObject = {
  companySize: SIZE_PREFIX,
  sizeExperience: SIZE_PREFIX,
  companyIndustry: INDUSTRY_PREFIX,
  industryExperience: INDUSTRY_PREFIX,
  companyFundingStage: 'Stage: '
};

const getPrefix = (key: string) => {
  return PREFIX_MAP[key] || '';
};

const getIconElement = (match: boolean, fullMatch?: boolean) => {
  if (match) {
    if (fullMatch !== undefined) {
      return <span key="icon" className={fullMatch ? 'icon_success' : 'icon_tilde'} />;
    }
    return <span key="icon" className="icon_success" />;
  }
  return <span key="icon" className="icon_close" />;
};

export const getRadialBarChartSizeProps = () => {
  return {
    height: 110,
    width: 70,
    hollowSize: '55%',
    valueOffsetY: -15
  };
};
