import React from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { PricingCountry } from 'types';
import useMedia from 'hooks/useMedia';
import useLocalisation from 'hooks/useLocalisation';
import media from 'styles/media';
import { mediaQueries } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { getLogos } from './helpers';

export { getLogosDescription } from './helpers';

type Props = {
  limit?: number;
  lightColor?: boolean;
  logoHeight?: string;
  preferredCountry?: PricingCountry;
  description?: string;
  className?: string;
};

export default function CompanyLogos({
  limit,
  lightColor,
  logoHeight = '37',
  preferredCountry,
  description,
  className
}: Props) {
  const { state } = useLocation<{ pricingType?: PricingCountry }>();
  const darkMode = useDarkMode();
  const isMobile = useMedia([mediaQueries.mobile]);
  const country = useLocalisation(preferredCountry || state?.pricingType);
  const visibleLogos = getLogos(country).slice(0, limit);

  return (
    <CompanyLogosRow className={ClassNames(className, 'company_logos_section center_alignment')}>
      <ConditionalRender predicate={description}>
        <p className={ClassNames('company_logos_description', { left_alignment: !isMobile })}>
          {description}
        </p>
      </ConditionalRender>
      <CompanyLogosContainer className="company_logos">
        {visibleLogos.map(logo => (
          <img
            key={`${logo.name}_logo`}
            src={logo[lightColor ?? darkMode ? 'light' : 'dark']}
            alt={logo.name}
            height={logoHeight}
            className="company_logo"
            loading="lazy"
          />
        ))}
      </CompanyLogosContainer>
    </CompanyLogosRow>
  );
}

const CompanyLogosRow = styled.div`
  padding: 30px 0;
`;

const CompanyLogosContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet`
    display: block;
  `}

  .company_logo {
    opacity: 0.9;

    ${media.tabletLandscape`
      max-height: 30px;
    `}

    ${media.tablet`
      margin: 10px;
    `}

    &:hover {
      opacity: 1;
    }
  }
`;
