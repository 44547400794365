import React, { ReactNode } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { ObjectValues, SizeVariant, SizeVariantCSS } from 'types';

import Tooltip from 'components/tooltip';
import ErrorMessage from 'components/errormessage';

export type ToggleProps = {
  active: boolean;
  name?: string;
  onToggle: (on: boolean) => void;
  type?: 'normal' | 'inverted' | 'inverted_bg' | 'purple' | 'cord_gradient';
  size?: SizeVariant;
  disabled?: boolean;
  disabledMessage?: { message: string; position: 'top' | 'bottom' | 'left' | 'right' };
  offColour?: 'red' | 'grey';
  title?: ReactNode;
  description?: ReactNode;
  errorMessage?: string;
  className?: string;
};

const ContainerSizes: SizeVariantCSS = {
  xsmall: '30px',
  small: '40px',
  medium: '48px',
  large: '64px'
} as const;

const ToggleSizes: SizeVariantCSS = {
  xsmall: '16px',
  small: '20px',
  medium: '24px',
  large: '32px'
} as const;

function Toggle({
  active = false,
  name,
  onToggle,
  type = 'normal',
  size = 'medium',
  disabled,
  disabledMessage,
  offColour = 'red',
  title,
  description,
  errorMessage,
  className
}: ToggleProps) {
  const contSize = ContainerSizes[size];
  const toggleSize = ToggleSizes[size];
  const hasLabel = title || description;
  const handleClick = disabled ? undefined : () => onToggle(!active);

  const toggleCont = (
    <ToggleCont
      aria-label="Toggle"
      aria-pressed={active}
      className={ClassNames('toggle_wrapper', hasLabel ? '' : className)}
      id={`toggle_wrapper_${name}`}
      colourType={type}
      on={active.toString()}
      offColour={offColour}
      size={contSize}
      onClick={hasLabel ? undefined : handleClick}
      disabled={disabled}
    >
      <Toggler
        className="toggle"
        id={`toggle_${name}`}
        type={type}
        on={active.toString()}
        offColour={offColour}
        contSize={contSize}
        size={toggleSize}
        disabled={disabled}
      />
      {disabled && disabledMessage?.message && (
        <Tooltip text={disabledMessage?.message} position={disabledMessage?.position || 'top'} />
      )}
    </ToggleCont>
  );

  if (title || description) {
    return (
      <ToggleParent
        className={ClassNames('toggle_parent', className)}
        type={type}
        active={active}
        onClick={handleClick}
      >
        <label className="toggle_label" htmlFor={`toggle_${name}`}>
          {title && <h3>{title}</h3>}
          {description && <div>{description}</div>}
        </label>
        {toggleCont}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </ToggleParent>
    );
  } else {
    return toggleCont;
  }
}

export default Toggle;

type ToggleContProps = Pick<ToggleProps, 'offColour'> & {
  colourType: ToggleProps['type'];
  on: string;
  size: ObjectValues<typeof ContainerSizes>;
};

const ToggleCont = styled.button<ToggleContProps>`
  box-sizing: content-box;
  position: relative;
  border-radius: 24px;
  cursor: pointer;
  width: ${props => props.size};
  min-width: ${props => props.size};
  max-width: ${props => props.size};
  padding: 4px;
  outline-offset: 3px;

  ${({ size, theme: { media } }) =>
    size === ToggleSizes.large &&
    media.mobile`
    width: ${ToggleSizes.medium};
    min-width: ${ToggleSizes.medium};
    max-width: ${ToggleSizes.medium};
  `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        opacity: 1;

        .tooltip_wrapper {
          display: block;
          opacity: 1;
        }
      }
    `}

  ${props =>
    props.colourType === 'inverted' &&
    props.on === 'true' &&
    css`
      background-color: ${props.offColour !== 'red'
        ? 'var(--background-toggle-negative)'
        : 'var(--background-toggle-inactive)'};
      outline-color: ${props.offColour !== 'red'
        ? 'var(--background-toggle-negative)'
        : 'var(--background-toggle-inactive)'};
    `}

  ${props =>
    props.colourType === 'inverted_bg' &&
    props.on === 'true' &&
    css`
      background-color: var(--background-toggle-active);
      outline-color: var(--background-toggle-active);
    `}

  ${props =>
    props.colourType === 'inverted' &&
    props.on === 'false' &&
    css`
      background-color: var(--background-toggle-active);
      outline-color: var(--background-toggle-active);
    `}

  ${props =>
    props.colourType === 'inverted_bg' &&
    props.on === 'false' &&
    css`
      background-color: ${props.offColour === 'red'
        ? 'var(--background-toggle-negative)'
        : 'var(--background-toggle-inactive)'};
      outline-color: ${props.offColour === 'red'
        ? 'var(--background-toggle-negative)'
        : 'var(--background-toggle-inactive)'};
    `}
  
  ${props =>
    props.colourType === 'normal' &&
    css`
      background-color: var(--background-block-primary);
      outline-color: var(--background-block-primary);
    `}

  ${({ theme: { colours }, colourType, on }) => {
    if (colourType === 'purple') {
      return css`
        background: ${on === 'true'
          ? 'var(--gradient-secondary-base)'
          : 'var(--background-toggle-inactive)'};
      `;
    }

    if (colourType === 'cord_gradient') {
      return css`
        background: ${on === 'true' ? colours.cordGradient : 'var(--background-toggle-inactive)'};
      `;
    }
  }}
`;

type TogglerProps = Pick<ToggleProps, 'type' | 'disabled' | 'offColour'> & {
  on: string;
  contSize: ToggleContProps['size'];
  size: ObjectValues<typeof ToggleSizes>;
};

const Toggler = styled.div<TogglerProps>`
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 50%;
  width: ${props => props.size};
  height: ${props => props.size};
  margin-left: ${props => (props.on === 'false' ? '0' : `calc(100% - ${props.size})`)};

  ${({ on, size, theme: { media } }) =>
    size === ToggleSizes.large &&
    media.mobile`
    width: ${ToggleSizes.medium};
    height: ${ToggleSizes.medium};
    margin-left: ${on === 'false' ? '0' : `calc(100% - ${ToggleSizes.medium})`};
  `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  ${props =>
    (props.type === 'inverted' || props.type === 'inverted_bg') &&
    css`
      background-color: var(--background-block-primary);
    `}

  ${props =>
    props.type === 'normal' &&
    props.on === 'false' &&
    css`
      background-color: ${props.offColour === 'red'
        ? 'var(--background-toggle-negative)'
        : 'var(--background-toggle-inactive)'};
    `}

  ${props =>
    props.type === 'normal' &&
    props.on === 'true' &&
    css`
      background-color: var(--background-toggle-active);
    `}

  ${({ type }) =>
    type === 'purple' &&
    css`
      background-color: var(--background-block-primary);
    `}

  ${({ type }) =>
    type === 'cord_gradient' &&
    css`
      background-color: var(--background-block-primary);
    `}
`;

const ToggleParent = styled.div<Pick<ToggleProps, 'active' | 'type'>>`
  &,
  & * {
    cursor: pointer;
  }

  ${({ active, type, theme: { fn } }) => {
    if (type === 'purple') {
      if (active) {
        return css`
          background: var(--gradient-secondary-transparent);

          .toggle_label {
            ${fn.getRadialGradientTextStyling('purpleGradient')}
          }
        `;
      }

      return css`
        background: var(--background-accent-tertiary-soft);

        .toggle_label:not(.icon_help) {
          color: var(--text-toggle-inactive);
        }
      `;
    }

    if (type === 'cord_gradient') {
      if (active) {
        return css`
          .toggle_label div *:not(.badge, .badge span) {
            ${fn.getRadialGradientTextStyling('cordGradient')};
          }
        `;
      }

      return css`
        .toggle_label {
          color: var(--text-body-tertiary);

          .badge {
            opacity: 0.6;
          }
        }
      `;
    }
  }}
`;
