import styled, { css } from 'styled-components';

import RangeFilter from 'components/rangefilter';

import { ExpandableFilterProps } from './ExpandableFilter.helpers';

export const ExpandableFilterCont = styled.div`
  position: relative;
`;

const FilterDropdownListStyling = css`
  width: 280px;
  position: absolute;
  z-index: 3;
  margin-top: 0.5rem;
  border-radius: 1rem;
  background: var(--background-block-primary);
  box-shadow: 0 0.25rem 2rem 0 var(--shadow-focused);
`;

type FilterContentProps = {
  showScroll?: boolean;
  hasOptionList?: boolean;
} & Pick<ExpandableFilterProps, 'showSearchInput' | 'appearance'>;

export const FilterContent = styled.div<FilterContentProps>`
  ${({ showScroll, showSearchInput, appearance, hasOptionList }) => {
    const isFilterTagDropdown = appearance === 'filterTagDropdown';

    return css`
      max-width: 0;
      max-height: 0;
      opacity: 0;
      transition: ${isFilterTagDropdown ? 'opacity' : 'all'} 0.2s ease-in;

      &.open {
        max-width: unset;
        max-height: unset;
        opacity: 1;
        padding: ${hasOptionList
          ? showSearchInput
            ? '0.25rem 0.5rem'
            : '0.5rem'
          : '0.5rem 1rem 1rem'};
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        ${showScroll &&
        !showSearchInput &&
        css`
          overflow: auto;
        `}

        ${isFilterTagDropdown && FilterDropdownListStyling}
      }

      &:not(.open) {
        overflow: hidden;
      }
    `;
  }}
`;

export const RangeSelector = styled(RangeFilter)`
  .range {
    width: 100%;
    padding: 0.25rem 0 0;
  }

  .min_value,
  .max_value {
    font-size: var(--type-sm-md);

    [class^='icon_'] {
      font-size: var(--type-md);
    }
  }
`;
