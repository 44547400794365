import React from 'react';
import ClassNames from 'classnames';
import styled, { DefaultTheme, css, ThemedStyledProps } from 'styled-components';

import Toggle, { ToggleProps } from 'components/toggle';
import Block from 'components/block';

type StyleProps = {
  colour?: keyof DefaultTheme['colours'];
  extraPadding?: boolean;
  noBoxShadow?: boolean;
} & Pick<ToggleProps, 'active'>;

type BlockToggleProps = {
  className?: string;
  name: string;
} & StyleProps &
  Pick<ToggleProps, 'size' | 'offColour' | 'title' | 'description' | 'onToggle'>;

export default function BlockToggle({
  className,
  name,
  active,
  size,
  offColour,
  title,
  description,
  onToggle,
  ...styleProps
}: BlockToggleProps) {
  return (
    <StyledBlock className={ClassNames(className, { active })} active={active} {...styleProps}>
      <Toggle
        name={name}
        active={active}
        size={size}
        offColour={offColour}
        title={title}
        description={description}
        onToggle={onToggle}
      />
    </StyledBlock>
  );
}

const getColourStyling = (props: ThemedStyledProps<StyleProps, DefaultTheme>) => {
  const {
    active,
    colour,
    theme: { colours, fn }
  } = props;

  switch (colour) {
    case 'transparentPrimaryColour':
      return css`
        color: ${active ? colours.primaryDarkShade : colours.inputsColour};
        background-color: ${active
          ? colours.transparentPrimaryColour
          : fn.opaqueColour('rgb(102, 120, 140)', 0.1)};
      `;
    default:
      return css`
        background-color: ${active ? colours.primaryColour : colours.errorColour};

        &,
        .toggle_label .link {
          color: ${colours.white};
        }
      `;
  }
};

const ToggleLabelStyling = css`
  .link {
    opacity: 0.8;

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }

  ${({ theme: { typography } }) => css`
    font-size: ${typography.small};
  `}

  ${({ theme: { media } }) => media.mobile`
    .hidden {
      display: none;
    }
  `}
`;

const StyledBlock = styled(Block)<StyleProps>`
  &&&.block {
    padding: ${({ extraPadding }) => (extraPadding ? '30px 35px' : '0.75rem 1rem')};
    text-align: left;
    transition: all 0.2s ease-in-out;
    line-height: 1.3;
    ${props => getColourStyling(props)};

    .toggle_label {
      ${ToggleLabelStyling};

      ${({ extraPadding, theme: { typography } }) =>
        !extraPadding &&
        css`
          h3 {
            font-size: ${typography.normal};
          }
        `}
    }

    ${({ noBoxShadow }) =>
      noBoxShadow &&
      css`
        box-shadow: none;
      `}

    ${({ theme: { media } }) => media.mobile`
      padding: 1.25rem !important;
    `}
  }
`;
