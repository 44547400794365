import React from 'react';
import ClassNames from 'classnames';

import Button from 'components/button';
import Badge from 'components/badge';
import ErrorMessage from 'components/errormessage';

import './style.scss';

const PricingCard = ({
  title,
  discount,
  description,
  features,
  price,
  button,
  disabled,
  darkMode,
  currentTab,
  className,
  recommendedText,
  errorMessage
}: $TSFixMe) => {
  const priceRegex = /^(£|\$)[0-9,]+$/g;

  return (
    <div className={ClassNames('plan', className, { dark: darkMode, disabled })}>
      {recommendedText && <div className="recommended_bar lightblue_text">{recommendedText}</div>}
      <div className="plan_details">
        <h3 className="plan_title">
          {typeof title === 'object' ? title[currentTab] : title}
          {currentTab === 'annual' && discount?.[currentTab] && (
            <Badge text={discount[currentTab]} />
          )}
        </h3>
        <div className="plan_description">{description}</div>
        <h4 className="price">
          {price[currentTab].oldAmount && (
            <span className="old_price lightblue_text">{price[currentTab].oldAmount}</span>
          )}
          {price[currentTab].amount}{' '}
          {priceRegex.test(price[currentTab].amount) && price[currentTab].paid && (
            <span className="pm">{price[currentTab].paid}</span>
          )}
        </h4>
        {price[currentTab].savings && (
          <div className="plan_savings">Save {price[currentTab].savings} vs monthly</div>
        )}
        {errorMessage ? (
          <ErrorMessage type="critical">{errorMessage}</ErrorMessage>
        ) : (
          <div className="plan_features">{features[currentTab]}</div>
        )}
        <Button className="twenty_px_font" disabled={disabled} {...button[currentTab]} />
      </div>
    </div>
  );
};

export default PricingCard;
