import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { NavLink as Link, useHistory } from 'react-router-dom';

import { setLocalStorageItem } from 'cookieManager';
import { AnyFunction } from 'types';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type PositionProfileTopNavProps = {
  loading: boolean;
  companyName: string;
  position: string;
  companyURL?: string;
  isDirect?: boolean;
  profileHistoryLength: number;
  showAsLandingPage?: boolean;
  closeProfile: AnyFunction;
};

export default function PositionProfileTopNav({
  loading,
  companyName,
  position,
  companyURL = '',
  isDirect,
  profileHistoryLength,
  showAsLandingPage,
  closeProfile
}: PositionProfileTopNavProps) {
  const history = useHistory();
  const { location } = history;

  const goBack = () => {
    setLocalStorageItem('profileHistoryLength', profileHistoryLength - 1);

    if (location.key === undefined) closeProfile();
    else history.goBack();
  };

  return (
    <div className="topnav_wrapper">
      <div className="topnav_container profile_view">
        <ConditionalRender predicate={!showAsLandingPage}>
          <ConditionalRender
            predicate={!loading}
            fallback={<Skeleton width={300} height={30} duration={2} />}
          >
            {profileHistoryLength !== 1 && (
              <div onClick={() => goBack()} className="left icon_arrow_left" />
            )}
            <h2 className="topnav">
              <ConditionalRender predicate={isDirect}>
                <Link
                  className="company_name"
                  to={companyURL}
                  onClick={() =>
                    setLocalStorageItem('profileHistoryLength', profileHistoryLength + 1)
                  }
                >
                  {companyName},
                </Link>{' '}
                {position}
              </ConditionalRender>
            </h2>
            <div onClick={() => closeProfile()} className="right icon_close" />
          </ConditionalRender>
        </ConditionalRender>
      </div>
    </div>
  );
}
