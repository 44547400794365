import { RefObject } from 'react';

import { StringOrElements } from 'types';

import { AIAction } from './TextEnhancer.helpers';

export type TextEnhancerAction =
  | {
      type: 'SET_TEXT_EDITOR';
      payload: RefObject<HTMLDivElement>;
    }
  | {
      type: 'SET_IS_LOADING';
      payload: boolean;
    }
  | {
      type: 'SET_SHOW_CUSTOM_PROMPT';
      payload: boolean;
    }
  | {
      type: 'SET_ATTEMPT_ENHANCE_TEXT';
      payload: AIAction;
    }
  | {
      type: 'SET_FAILED_ENHANCE_TEXT';
      payload: StringOrElements;
    }
  | {
      type: 'SET_SUCCESS_ENHANCE_TEXT';
      payload: AIAction;
    }
  | {
      type: 'SET_IS_HIGHLIGHTED';
      payload: boolean;
    }
  | {
      type: 'SET_IS_ENHANCED';
      payload: boolean;
    };

export const setTextEditorAction = (textEditor: RefObject<HTMLDivElement>): TextEnhancerAction => ({
  type: 'SET_TEXT_EDITOR',
  payload: textEditor
});

export const setCustomPromptAction = (value: boolean): TextEnhancerAction => ({
  type: 'SET_SHOW_CUSTOM_PROMPT',
  payload: value
});

export const setIsLoadingAction = (value: boolean): TextEnhancerAction => ({
  type: 'SET_IS_LOADING',
  payload: value
});

export const setAttemptEnhanceTextAction = (value: AIAction): TextEnhancerAction => ({
  type: 'SET_ATTEMPT_ENHANCE_TEXT',
  payload: value
});

export const setFailedEnhanceTextAction = (value: JSX.Element): TextEnhancerAction => ({
  type: 'SET_FAILED_ENHANCE_TEXT',
  payload: value
});

export const setSuccessEnhanceTextAction = (value: AIAction): TextEnhancerAction => ({
  type: 'SET_SUCCESS_ENHANCE_TEXT',
  payload: value
});

export const setIsHighlightedAction = (value: boolean): TextEnhancerAction => ({
  type: 'SET_IS_HIGHLIGHTED',
  payload: value
});

export const setIsEnhancedAction = (value: boolean): TextEnhancerAction => ({
  type: 'SET_IS_ENHANCED',
  payload: value
});
