import styled from 'styled-components';

type WrapperProps = {
  position?: string;
  width?: string;
  height?: string;
  textAlign?: string;
  top?: string;
  background?: string;
  zIndex?: string;
  display?: string;
  bottom?: string;
  backdrop?: string;
  padding?: string;
};

export const Wrapper = styled.div<WrapperProps>`
  position: ${({ position }) => position || 'initial'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  text-align: ${({ textAlign }) => textAlign || 'initial'};
  top: ${({ top }) => top || ''};
  bottom: ${({ bottom }) => bottom || ''};
  z-index: ${({ zIndex }) => zIndex || ''};
  background: ${({ background }) => background || 'initial'};
  display: ${({ display }) => display || ''};
  backdrop-filter: ${({ backdrop }) => backdrop || ''};
  padding: ${({ padding }) => padding || ''};
`;
