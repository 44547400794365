import { PositionProfileViewProps } from 'views/positionprofile';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import {
  NavigatablePosition,
  NavigatablePositionsWrapper,
  NavigateNext,
  NavigatePrevious
} from './NavigatablePositions.styled';
import NavigatablePositionsProvider, {
  useNavigatablePositionsContext
} from './NavigatablePositions.context';

const NavigatablePositions = () => {
  const {
    positionProfileViewProps,
    allowNavigatePreviousButton,
    allowNavigateNextButton,
    leftNavigatablePositionID,
    middleNavigatablePositionID,
    rightNavigatablePositionID,
    isMobile,
    navigateToPrevious,
    navigateToNext
  } = useNavigatablePositionsContext();

  return (
    <NavigatablePositionsWrapper
      allowNavigatePreviousButton={allowNavigatePreviousButton}
      allowNavigateNextButton={allowNavigateNextButton}
    >
      <NavigatablePosition
        key={leftNavigatablePositionID}
        {...positionProfileViewProps}
        xPosition="left"
        navigatablePositionID={leftNavigatablePositionID}
      />
      <NavigatablePosition
        key={middleNavigatablePositionID}
        {...positionProfileViewProps}
        xPosition="middle"
        navigatablePositionID={middleNavigatablePositionID}
      />
      <NavigatablePosition
        key={rightNavigatablePositionID}
        {...positionProfileViewProps}
        xPosition="right"
        navigatablePositionID={rightNavigatablePositionID}
      />
      <ConditionalRender predicate={allowNavigatePreviousButton && !isMobile}>
        <NavigatePrevious
          icon="icon_arrow_left"
          ariaLabel="navigate to previous position"
          onClick={navigateToPrevious}
        />
      </ConditionalRender>
      <ConditionalRender predicate={allowNavigateNextButton && !isMobile}>
        <NavigateNext
          icon="icon_arrow_right"
          ariaLabel="navigate to next position"
          onClick={navigateToNext}
        />
      </ConditionalRender>
    </NavigatablePositionsWrapper>
  );
};

export default function NavigatablePositionsWithContext(props: PositionProfileViewProps) {
  return (
    <NavigatablePositionsProvider {...props}>
      <NavigatablePositions />
    </NavigatablePositionsProvider>
  );
}
