import axios from 'axios';

import {
  CandidateSignupVerificationInterface,
  CandidateSignupVerificationStatus,
  AdminCandidateSearchOrder,
  CandidateSelfBuildStatus
} from '@cohiretech/common-types';

import { APIResponse } from 'types';

type CandidateSignupReturnType = {
  results: CandidateSignupVerificationInterface[];
  resultsCount: number;
};

type CandidateSignupParams = Partial<{
  page: number;
  order: AdminCandidateSearchOrder;
  adminIDs: number[];
  onlyUnclaimed: boolean;
  search: string;
}>;

const ADMIN_CANDIDATE_SIGNUP = process.env.REACT_APP_ADMIN_CANDIDATE_SIGNUP;
const ADMIN_CANDIDATE_DETAILS = process.env.REACT_APP_ADMIN_CANDIDATE_DETAILS;
const ADMIN_CANDIDATE_ACCEPT_NOTE = process.env.REACT_APP_ADMIN_CANDIDATE_ACCEPT_NOTE;
const ADMIN_ACCEPT_CANDIDATE = process.env.REACT_APP_ADMIN_ACCEPT_CANDIDATE;
const ADMIN_VERIFY_CANDIDATE = process.env.REACT_APP_ADMIN_VERIFY_CANDIDATE;
const ADMIN_WAITLIST_CANDIDATE = process.env.REACT_APP_ADMIN_WAITLIST_CANDIDATE;
const ADMIN_REJECT_CANDIDATE = process.env.REACT_APP_ADMIN_REJECT_CANDIDATE;
const ADMIN_VERIFY_REACTIVATED_CANDIDATE = process.env.REACT_APP_ADMIN_VERIFY_REACTIVATED_CANDIDATE;
const ADMIN_REJECT_REACTIVATED_CANDIDATE = process.env.REACT_APP_ADMIN_REJECT_REACTIVATED_CANDIDATE;
const ADMIN_REBUILD_REACTIVATED_CANDIDATE =
  process.env.REACT_APP_ADMIN_REBUILD_REACTIVATED_CANDIDATE;

/* istanbul ignore next */
export const getCandidatesSignedUp = async (
  verificationStatus: CandidateSignupVerificationStatus,
  options: CandidateSignupParams = {}
): Promise<APIResponse<CandidateSignupReturnType>> => {
  const {
    page = 0,
    order = AdminCandidateSearchOrder.OldestFirst,
    adminIDs,
    onlyUnclaimed = false,
    search
  } = options;

  const params = {
    page,
    order,
    filter_admin_id: adminIDs,
    filter_only_unclaimed: onlyUnclaimed,
    filter_keyword: search
  };

  try {
    const { data } = await axios.get<{ data: CandidateSignupReturnType }>(
      `${ADMIN_CANDIDATE_SIGNUP}/${verificationStatus}`,
      { params }
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.message };
  }
};

/* istanbul ignore next */
export const adminVerifyCandidate = async (
  candidateID: number,
  reactivated: boolean,
  profileState: {
    selfBuildStatus?: CandidateSelfBuildStatus;
    qualityScore?: number;
    requiresRebuild?: boolean;
  }
) => {
  try {
    const params = { candidateID, ...profileState };

    await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      reactivated ? ADMIN_VERIFY_REACTIVATED_CANDIDATE : ADMIN_VERIFY_CANDIDATE,
      params
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminRejectCandidate = async (candidateID: number, reactivated: boolean) => {
  try {
    const params = { candidateID };

    await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      reactivated ? ADMIN_REJECT_REACTIVATED_CANDIDATE : ADMIN_REJECT_CANDIDATE,
      params
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminAcceptCandidate = async (candidateID: number, qualityScore: number) => {
  try {
    const params = { candidateID, qualityScore };

    await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_ACCEPT_CANDIDATE,
      params
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminWaitlistCandidate = async (candidateID: number) => {
  try {
    const params = { candidateID };

    await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_WAITLIST_CANDIDATE,
      params
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminCompleteReactivationRebuilt = async (candidateID: number) => {
  try {
    await axios.post(`${ADMIN_REBUILD_REACTIVATED_CANDIDATE}`, { candidateID });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminAddProfileInfo = async (id: $TSFixMe, data: $TSFixMe) => {
  try {
    const params = { id, ...data };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(ADMIN_CANDIDATE_DETAILS, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminAddProfileNote = async (id: $TSFixMe, adminNote: $TSFixMe) => {
  try {
    const params = { id, adminNote };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_CANDIDATE_ACCEPT_NOTE, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
