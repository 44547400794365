type ThrottleFunction = (...args: any[]) => unknown;
type ThrottledFunction<T extends ThrottleFunction> = (
  this: ThisParameterType<any>,
  ...args: Parameters<T>
) => ReturnType<T>;

export type ThrottleOptions =
  | { leading: true; trailing: true }
  | { leading: true; trailing: false }
  | { leading: false; trailing: true };

const ERROR_MSG =
  'Invalid throttle options. Either leading or trailing must be true and wait must be greater than 0.';

/**
 * Returns a new function that, when called repeatedly, invokes the original function
 * at most once per `wait` milliseconds.
 *
 * If `leading` is true, the first invocation of the returned function will execute the
 * original function, regardless of how soon it is called again. If `trailing` is true,
 * the original function will be invoked once more after the last call to the returned
 * function. If both `leading` and `trailing` are true, the original function will be
 * invoked immediately, and again after the last call, with `wait` milliseconds between
 * calls, assuming the throttled function is invoked repeatedly.
 *
 * @param fn - The function to throttle.
 * @param wait - The number of milliseconds to wait before executing `fn` again.
 * @param options - The options to configure how the throttled function is invoked.
 *
 * @throws {Error} Thrown if both `leading` and `trailing` are false, or if `wait` is not
 * greater than 0.
 *
 * @returns A new function that will execute `fn` at most once per `wait` milliseconds.
 */
export function throttle<T extends ThrottleFunction>(
  fn: T,
  wait: number,
  options: ThrottleOptions = { leading: true, trailing: false }
): ThrottledFunction<T> {
  if ((!options.leading && !options.trailing) || wait <= 0) {
    throw new Error(ERROR_MSG);
  }

  let lastTime: number | undefined;
  let timeout: NodeJS.Timeout | undefined;
  let lastResult: ReturnType<T>;

  function throttledFn(
    this: ThisParameterType<any>,
    ...args: Parameters<T>
  ): ReturnType<T> | undefined {
    const currentTime = new Date().getTime();
    if (lastTime === undefined) {
      lastTime = currentTime;
      if (options.leading) {
        lastResult = fn.apply(this, args) as ReturnType<T>;
        return lastResult;
      }
    } else {
      const timeSinceLastCall = currentTime - lastTime;
      if (timeSinceLastCall < wait) {
        if (timeout !== undefined) {
          clearTimeout(timeout);
        }
        if (options.trailing) {
          timeout = setTimeout(() => {
            lastTime = currentTime;
            lastResult = fn.apply(this, args) as ReturnType<T>;
          }, wait - timeSinceLastCall) as any as NodeJS.Timeout;
        }
      } else {
        lastTime = currentTime;
        lastResult = fn.apply(this, args) as ReturnType<T>;
        return lastResult;
      }
    }
  }

  return throttledFn as ThrottledFunction<T>;
}
