import React from 'react';
import { NavLink as Link, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { UserType } from '@cohiretech/common-types';

import { setLocalStorageItem, getLocalStorageItem } from 'cookieManager';
import { generatePositionURL, generatePersonsURL } from 'utils';
import * as fetcher from 'fetcher';
import { useDarkMode } from 'hooks/useDarkMode';
import { identifyCompanyType } from 'v2/services/company/companyType';

import ActivityInfo, { InfoWrapper } from 'components/activityinfo';
import Tags from 'components/tags';
import PositionActions from 'components/positionactions';
import PositionDetailsPreview from 'components/positiondetailspreview';
import LinkWithIcon from 'components/linkwithicon';

import Avatar, { getAvatarPropsFromListing } from 'v2/components/ui/atoms/Avatar';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';
import {
  PositionItemProps,
  getActivityInfoProps,
  getSmallHeading,
  getTags
} from './PositionItem.helpers';

function ChatButton({
  listingID,
  companyID,
  companyAccountID,
  openChat,
  searchPosition
}: $TSFixMe) {
  const onChatClick = async () => {
    const applyDetails = { listingID, companyID, companyAccountID };
    const uniqueIdentifier = getLocalStorageItem('uniqueIdentifier') || '';

    const { status } = await fetcher.startApplication(
      applyDetails,
      uniqueIdentifier,
      searchPosition
    );

    if (status === 'success') openChat();
  };

  return (
    <button type="button" className="chat_button" onClick={onChatClick}>
      <span className="icon_chat_bubble" />
      <span className="chat_button_label">Message</span>
    </button>
  );
}

const { Company, CompanyAccount } = UserType;

export default function PositionItem(props: PositionItemProps) {
  const {
    id,
    shortlistID,
    shortlistType,
    listingID,
    associatedMemberDetails,
    position,
    pictureUrls,
    companyName,
    companyLogo,
    currency,
    locationCity,
    salaryMax,
    salaryMin,
    primarySkills,
    salaryVisible,
    seniorities,
    status,
    searchPosition,
    columns,
    style,
    loading,
    updateItemDetails,
    openChat,
    companyID,
    showSaveHideActions = true,
    showViewPositionButton,
    remote,
    remoteLocationContinents,
    remoteLocationCountries,
    companyType,
    oteSalaryMin,
    oteSalaryMax,
    oteSalaryMaxUncapped,
    onClick
  } = props;

  const darkMode = useDarkMode();
  const history = useHistory();
  const locationData = history.location;
  const goToSignup = () => history.push('/signup');

  const { isDirect, isExternal } = identifyCompanyType(companyType);
  const activityInfoProps = getActivityInfoProps(props);

  const onShortlisted = (shortlistID: $TSFixMe, shortlistType: $TSFixMe) => {
    if (updateItemDetails) updateItemDetails({ shortlistID, shortlistType });
  };

  const renderCardDetails = (positionURL: string) => {
    const isPositionSaved = shortlistID && shortlistType === fetcher.SHORTLIST_TYPES.LIKE;
    const tags = getTags(primarySkills!);
    const ote = { oteSalaryMin, oteSalaryMax, oteSalaryMaxUncapped };

    if (!isDirect) {
      const smallHeading = getSmallHeading(props);
      const listing = { companyLogo, companyName, associatedMemberDetails };

      return (
        <>
          <SecondaryCardDetails className="card_details secondary">
            <Avatar
              className="card_profile_picture"
              width={64}
              {...getAvatarPropsFromListing({
                type: isExternal ? Company : CompanyAccount,
                listing
              })}
            >
              <ConditionalRender predicate={!isExternal}>
                <AgencyCompanyLogo
                  width={24}
                  {...getAvatarPropsFromListing({ type: Company, listing })}
                />
              </ConditionalRender>
            </Avatar>
            <div className="card_info">
              <h4 className="card_small_heading">{smallHeading}</h4>
              <h3 className="card_title">{position}</h3>
              <PositionDetailsPreview
                className="card_subtitle"
                location={locationCity}
                remoteLocationContinents={remoteLocationContinents}
                remoteLocationCountries={remoteLocationCountries}
                remoteOptions={remote}
                salary={{ salaryMin, salaryMax, currency, salaryVisible, seniorities }}
                ote={ote}
              />
            </div>
          </SecondaryCardDetails>
          {tags && tags.length > 0 && <Tags tags={tags} noWrap />}
          <ActivityInfo {...activityInfoProps} />
        </>
      );
    }

    return (
      <div className="card_info">
        {isPositionSaved && status === 'active' && !isExternal && (
          <ChatButton
            listingID={listingID}
            companyID={companyID}
            companyAccountID={associatedMemberDetails?.accountID}
            searchPosition={searchPosition}
            openChat={() => openChat!(positionURL)}
          />
        )}
        <h3 className="card_title">
          <strong>{position}</strong>, {companyName}
        </h3>
        <PositionDetailsPreview
          className="card_subtitle"
          location={locationCity}
          remoteLocationContinents={remoteLocationContinents}
          remoteLocationCountries={remoteLocationCountries}
          remoteOptions={remote}
          salary={{ salaryMin, salaryMax, currency, salaryVisible, seniorities }}
          ote={ote}
        />
        {tags && tags.length > 0 && <Tags tags={tags} noWrap />}
        <ActivityInfo {...activityInfoProps} />
      </div>
    );
  };

  if (loading) {
    return (
      <div
        className={ClassNames('square_card_item', 'position_item', {
          dark: darkMode,
          four_cols_item: columns === 4,
          three_cols_item: columns === 3
        })}
        style={style}
      >
        <div className="card_elements_wrapper">
          <ConditionalRender predicate={isDirect}>
            <div className="cover_photo">
              <Skeleton height={250} duration={2} />
            </div>
          </ConditionalRender>
          <div className={ClassNames('card_details', { secondary: !isDirect })}>
            <ConditionalRender predicate={!isDirect}>
              <div className="loading_picture">
                <Skeleton circle width={70} height={70} duration={2} />
              </div>
            </ConditionalRender>
            <div className="card_info">
              <h3 className="card_title loading_title">
                <Skeleton height={25} duration={2} />
              </h3>
              <div className="card_subtitle">
                <Skeleton height={15} duration={2} />
              </div>
              <InfoWrapper className="activity_info">
                <Skeleton width={150} height={15} duration={2} />
              </InfoWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    const picture = (pictureUrls && pictureUrls[0]) || companyLogo;
    const positionData = { companyName, positionID: listingID, positionName: position };
    const personData = associatedMemberDetails?.memberName
      ? {
          personsName: associatedMemberDetails!.memberName,
          personsID: associatedMemberDetails!.accountID,
          companyName
        }
      : {};
    const positionURL = generatePositionURL(locationData, positionData);

    const onItemClicked = (e: $TSFixMe, searchPosition: $TSFixMe) => {
      if (showViewPositionButton) {
        e.preventDefault();
        return;
      }

      if (
        e.target.className.includes('person') ||
        e.target.className.includes('profile') ||
        e.target.className.includes('icon') ||
        e.target.className.includes('chat')
      ) {
        e.preventDefault();
      }
      onClick?.();
      setLocalStorageItem('jobSearchPosition', searchPosition);
      setLocalStorageItem('profileHistoryLength', 1);
    };

    return (
      <PositionCard
        id={id?.toString() || `position_item_${listingID}`}
        className={ClassNames('square_card_item', 'position_item', {
          dark: darkMode,
          four_cols_item: columns === 4,
          three_cols_item: columns === 3,
          secondary: !isDirect
        })}
        style={style}
      >
        <span className="card_elements_wrapper">
          {personData.personsID && isDirect && (
            <Link
              className={ClassNames('person_details', { dark: darkMode })}
              to={generatePersonsURL(locationData, personData)}
            >
              <Avatar
                width={36}
                photoURL={associatedMemberDetails!.photoURL}
                firstname={associatedMemberDetails!.memberName}
              />
              <div className="person_info">
                {associatedMemberDetails!.memberName} at {companyName}
              </div>
              <span className={ClassNames('person_arrow', 'icon_arrow_right')} />
            </Link>
          )}
          <PositionItemLinkWrapper
            className={ClassNames('positionitem_link_wrapper', {
              show_view_position_button: showViewPositionButton
            })}
            to={{ pathname: positionURL, search: locationData.search }}
            onClick={e => onItemClicked(e, searchPosition)}
          >
            <ConditionalRender predicate={isDirect}>
              <img
                className="cover_photo"
                src={picture}
                alt={`${companyName} is hiring a ${position}`}
                loading="lazy"
                width="390"
                height="250"
              />
            </ConditionalRender>
            {showSaveHideActions && (
              <PositionActions
                listingID={listingID}
                shortlistID={shortlistID}
                type={shortlistType}
                onShortlisted={(s: $TSFixMe, t: $TSFixMe) => onShortlisted(s, t)}
                goToSignup={goToSignup}
                showHide
              />
            )}
            <ConditionalRender predicate={showViewPositionButton}>
              <ViewPositionButton
                icon="icon_preview"
                text="View position"
                action={() => window.open(positionURL, '_blank')}
              />
            </ConditionalRender>
            {renderCardDetails(positionURL)}
          </PositionItemLinkWrapper>
        </span>
      </PositionCard>
    );
  }
}

const PositionCard = styled.div`
  &.secondary {
    &,
    .card_small_heading,
    .icon {
      font-size: ${({ theme: { typography } }) => typography.small};
    }
  }
`;

const SecondaryCardDetails = styled.div`
  &.card_details {
    .card_info {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    + .tags_wrapper {
      margin-top: 0.5rem;
    }
  }
`;

const AgencyCompanyLogo = styled(Avatar)`
  position: absolute;
  top: -1px;
  right: -2px;
`;

const PositionItemLinkWrapper = styled(Link)`
  &.show_view_position_button {
    cursor: default;
  }
`;

const ViewPositionButton = styled(LinkWithIcon)`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 1;
`;
