import React from 'react';
import styled from 'styled-components';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import LinkWithIcon from 'components/linkwithicon';
import Toggle from 'components/toggle';
import LabelWithIcon from 'components/labelwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { useTextEnhancer } from '../../TextEnhancer.context';

const Controls = () => {
  const {
    redoLastEnhance,
    status,
    isLoading,
    cancelEnhanceText,
    lastAIAction,
    highlightMarked,
    toggleHighlightMarked
  } = useTextEnhancer();

  const isMobile = useMedia(mediaQueries.mobile);

  return (
    <ControlsWrapper>
      <ConditionalRender predicate={isLoading}>
        <LinkWithIcon icon="icon_cancel" text="Cancel" color="grey" action={cancelEnhanceText} />
      </ConditionalRender>
      <ConditionalRender
        predicate={status === 'success' && lastAIAction?.value !== 'fixSpellingGrammar'}
      >
        <LinkWithIcon icon="icon_shuffle" text="Regenerate" color="grey" action={redoLastEnhance} />
      </ConditionalRender>
      <ConditionalRender
        predicate={status === 'success' && lastAIAction?.value === 'fixSpellingGrammar'}
      >
        <Toggle
          description={toggleLabel(highlightMarked, isMobile)}
          name="highlight"
          active={highlightMarked}
          onToggle={toggleHighlightMarked}
          type="inverted_bg"
          size="small"
          offColour="grey"
        />
      </ConditionalRender>
    </ControlsWrapper>
  );
};

const toggleLabel = (highlightMarked: boolean, isMobile: boolean) => (
  <LabelWithIcon
    icon="icon_highlighter"
    text={isMobile ? ' ' : 'Highlight changes'}
    color={highlightMarked ? 'blue' : 'grey'}
    htmlFor="toggle_highlight"
  />
);

const ControlsWrapper = styled.div`
  ${({ theme: { media } }) => media.mobile`
    align-self: flex-end;
    margin-bottom: 0.25em;
  `}

  .toggle_parent {
    font-weight: ${({ theme: { typography } }) => typography.semibold};

    .toggle_label {
      margin-right: 8px;
    }
  }

  .icon {
    font-weight: ${({ theme: { typography } }) => typography.black};
    margin-right: 0.5rem;
  }
`;

export default Controls;
