import axios from 'axios';

import { APIResponse } from 'types';

const CANDIDATE_CORD_CV = process.env.REACT_APP_CANDIDATE_CORD_CV!;

export const getCordCV = async (): Promise<APIResponse<{ url: string }>> => {
  try {
    const { data } = await axios.get<{ data: { url: string } }>(CANDIDATE_CORD_CV);
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
