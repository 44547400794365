import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ClassNames from 'classnames';

import { getTestimonials } from 'fetcher';
import { getIsDarkMode_FIXME } from 'cookieManager';

import ListingSlider from 'components/listingslider';
import Loader from 'components/loader';
import PageSection from 'components/pagesection';

import TrustpilotStars from 'images/trustpilot-stars.svg';

type State = $TSFixMe;

export default class ReviewsSection extends React.Component<{} & RouteComponentProps, State> {
  constructor(props: {} & RouteComponentProps) {
    super(props);

    this.state = {
      testimonials: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getDynamicData();
  }

  async getDynamicData() {
    // @ts-expect-error TS(2345) FIXME: Argument of type '"candidate"' is not assignable t... Remove this comment to see the full error message
    const textTestimonials = await getTestimonials('candidate', 0, 'text');

    const testimonials = textTestimonials ? textTestimonials.values : [];

    this.setState({ testimonials, loading: false });
  }

  render() {
    const { testimonials, loading } = this.state;
    // @ts-expect-error TS(2339) FIXME: Property 'trustpilotReviews' does not exist on typ... Remove this comment to see the full error message
    const { trustpilotReviews, trustpilotScore, button, ...restProps } = this.props;
    const darkMode = getIsDarkMode_FIXME();

    if (loading) return <Loader className={ClassNames('medium', { dark: darkMode })} />;
    else if (testimonials && testimonials.length > 0) {
      return (
        <PageSection
          title="What people are saying"
          widthClassName="page_view"
          className={ClassNames('center_alignment', {
            grey_background: !darkMode,
            dark_blue_background: darkMode
          })}
          subTitle={[
            `${trustpilotReviews || 1036} Trustpilot reviews  \u00A0·\u00A0  ${
              trustpilotScore || 4.8
            } \u00A0`,
            <img
              key="image"
              height="20"
              src={TrustpilotStars}
              alt="cord has a five stars rating on Trustpilot"
            />
          ]}
          button={{ ...button, buttonStyle: darkMode ? 'fill_blue' : 'fill_dark_blue' }}
        >
          <ListingSlider
            itemType="testimonial_item"
            items={testimonials}
            columns={3}
            testimonialType="candidate"
            {...restProps}
          />
        </PageSection>
      );
    } else return null;
  }
}
