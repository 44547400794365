import { SkillCategory, SkillSubCategorySales, SkillWithCategory } from '@cohiretech/common-types';

import { SalesSkillsBySubcategory } from 'hooks/useCandidateSalesAndCoreSkills';
import { CandidateProfile, PositionInProfile } from 'types';
import { intersect, partition, unique } from 'utils/array';

export const getAllSkills = (profile: PositionInProfile | CandidateProfile) => {
  const {
    primarySkills = [],
    secondarySkills = [],
    relevantSkills = [],
    techStack = []
  } = profile as PositionInProfile;
  const allSkills = primarySkills.concat(secondarySkills, relevantSkills, techStack);

  return unique(allSkills);
};

export const getMatchingSkills = (
  positionSkills: ReturnType<typeof getAllSkills>,
  candidateProfile: CandidateProfile
) => {
  const candidateSkills = getAllSkills(candidateProfile);

  return intersect<string>(positionSkills, candidateSkills);
};

const { DealSize, SalesCycle, CompanyType, Channel } = SkillSubCategorySales;

const SALES_EXP_SUB_CATEGORIES = [DealSize, SalesCycle, CompanyType, Channel];

export const checkIfSalesExperienceSkill = (skillCategories?: SkillWithCategory[string]) => {
  const { category, subCategory } = skillCategories || {};

  return (
    category === SkillCategory.Sales &&
    !!subCategory &&
    SALES_EXP_SUB_CATEGORIES.includes(subCategory)
  );
};

export const classifySalesAndCoreSkills = (
  skillWithCategory: SkillWithCategory,
  skills?: string[]
) => {
  const target = skills || Object.keys(skillWithCategory);
  return partition(target, skill => checkIfSalesExperienceSkill(skillWithCategory[skill]));
};

export const getSalesSkillsBySubcategory = (
  skills: string[],
  skillWithCategory?: SkillWithCategory
) =>
  (skills || []).reduce((salesSkills, skill) => {
    const isSalesExperienceSkill = checkIfSalesExperienceSkill(skillWithCategory?.[skill]);

    if (isSalesExperienceSkill) {
      const { subCategory } = skillWithCategory![skill];
      salesSkills[subCategory!] = [...(salesSkills[subCategory!] || []), skill];
    }

    return salesSkills;
  }, {} as SalesSkillsBySubcategory);
