import React, { useMemo, useState } from 'react';

import { CompanyType } from '@cohiretech/common-types';

import { DialogWindowProps } from 'components/dialogwindow';
import PositionItem from 'components/positionitem';
import ErrorMessage from 'components/errormessage';

import CandidateATPositionForm, {
  CandidateATPositionFormProvider,
  useCandidateATPositionFormContext
} from 'v2/components/CandidateATPositionForm';

import { useApplicationTrackerContext } from '../../ApplicationTracker.context';

import { useImportATSPosition } from './ImportPositionPopup.hooks';
import PrePositionDetails from './PrePositionDetails';
import { ImportATSPositionPopup, TopLoader } from './ImportPositionPopup.styled';
import { DUPLICATE_RECORD_FOUND, getFormHeading } from './ImportPositionPopup.helpers';

function ImportPositionPopup() {
  const { toggleImportPositionPopup } = useApplicationTrackerContext();
  const props = useImportPositionPopupProps();

  return (
    <ImportATSPositionPopup
      id="import_ats_position_popup"
      visible
      showCloseIcon
      onClose={() => toggleImportPositionPopup(false)}
      {...props}
    />
  );
}

export default function ImportPositionPopupWithContext() {
  const { position } = useApplicationTrackerContext();

  return (
    <CandidateATPositionFormProvider initialPosition={position}>
      <ImportPositionPopup />
    </CandidateATPositionFormProvider>
  );
}

const useImportPositionPopupProps = (): Omit<DialogWindowProps, 'visible'> => {
  const { position, toggleImportPositionPopup } = useApplicationTrackerContext();
  const { saving, candidateATPositionFormRef, saveCandidateATPosition } =
    useCandidateATPositionFormContext();
  const {
    urlCheckState,
    inputMethod,
    currentStep,
    formSubmitted,
    updatePosition,
    setURLCheckState,
    handleStepChange,
    handleManualEntryClick,
    checkPositionURL,
    handleCancelClick
  } = useImportATSPosition();

  const [errorMessage, setErrorMessage] = useState('');

  const isDuplicateError = errorMessage === DUPLICATE_RECORD_FOUND;

  const multiStepOptions: DialogWindowProps['multiStepOptions'] = useMemo(() => {
    const link = {
      text: currentStep ? 'Back' : 'Cancel',
      onClick: currentStep ? () => setURLCheckState('idle') : handleCancelClick
    };

    return {
      steps: [{}, { onNextDisabled: !inputMethod }],
      currentStep,
      onStepChange: step => {
        handleStepChange({ urlCheckState: step ? undefined : 'idle' });
      },
      color: 'secondary',
      ...(urlCheckState !== 'idle' ? { link } : {})
    };
  }, [urlCheckState, inputMethod]);

  const primaryButton: DialogWindowProps['primaryButton'] = useMemo(() => {
    if (currentStep === 0) {
      return {
        text: 'Create position',
        buttonStyle: 'fill_secondary_gradient',
        icon: 'icon_sparkles',
        iconPosition: 'left',
        disabled: urlCheckState !== 'idle',
        action: checkPositionURL
      };
    }

    return {
      text: 'Add to board',
      buttonStyle: 'fill_secondary_gradient',
      disabled: isDuplicateError,
      action: () =>
        saveCandidateATPosition({
          setErrorMessage,
          closePopup: () => toggleImportPositionPopup(false)
        })
    };
  }, [urlCheckState, isDuplicateError, checkPositionURL, saveCandidateATPosition]);

  switch (urlCheckState) {
    case 'idle':
      return {
        title: 'Add a position',
        subTitle: 'Import a position to your application management board',
        children: (
          <PrePositionDetails
            formSubmitted={formSubmitted}
            updatePosition={updatePosition}
            handleManualEntryClick={handleManualEntryClick}
          />
        ),
        primaryButton,
        multiStepOptions
      };
    case 'finding':
      return {
        topCode: <TopLoader />,
        title: 'Checking the position URL...',
        subTitle:
          "We're checking if a position exists on cord that matches the position URL submitted.",
        primaryButton,
        multiStepOptions
      };
    case 'extracting':
      return {
        topCode: <TopLoader />,
        title: 'Extracting position information...',
        subTitle: "You'll be able to edit the position information and add more where needed.",
        primaryButton,
        multiStepOptions
      };
    default:
      const displayErrorMessage = isDuplicateError
        ? "The position you're trying to add already exists."
        : errorMessage;

      switch (inputMethod) {
        case 'found':
          return {
            title: "It's a match!",
            subTitle: 'We found a position on cord that matches the URL',
            children: (
              // @ts-expect-error The position doesn't need to include all the required properties.
              <PositionItem
                loading={false}
                {...position}
                listingID={position.id}
                companyType={CompanyType.External}
                showSaveHideActions={false}
                showViewPositionButton
              />
            ),
            loading: saving,
            primaryButton,
            multiStepOptions,
            bottomNote: <ErrorMessage type="critical">{displayErrorMessage}</ErrorMessage>
          };
        case 'manual':
        case 'extracted':
          const heading = getFormHeading(inputMethod);

          return {
            ...heading,
            children: (
              <CandidateATPositionForm
                ref={candidateATPositionFormRef}
                initialPosition={position}
              />
            ),
            loading: saving,
            primaryButton,
            multiStepOptions,
            bottomNote: <ErrorMessage>{displayErrorMessage}</ErrorMessage>
          };
        default:
          return {};
      }
  }
};
