import { WorkEligibility, WorkEligibilityType } from '@cohiretech/common-types';

import { CandidateFullInfo, PositionInProfile } from 'types';
import { getCurrencySymbol, isEmpty } from 'utils';
import { capitalizeSentences } from 'utils/string';
import { compact, intersect, unique } from 'utils/array';
import { equals, path } from 'utils/fn';
import { isPartialMatch } from 'utils/object';

type PositionDetails = PositionInProfile & { name: string; industry: string[] };

type MatchingCriteriaProps = {
  candidateDetails: Partial<CandidateFullInfo>;
  positionDetails: PositionDetails;
  user: 'candidate' | 'company';
};

type MatchingCriteria = {
  jobTitles: string[];
  seniorities: string[];
  skills: {
    primary: string[];
    secondary: string[];
    any: string[];
    overlap: string[];
    preferences: string[];
  };
  industries: string[];
  remote: string[];
  locations: string[];
  salary: string[];
};

export function getWorkEligibilityMatch(
  candidateWE: WorkEligibility[] = [],
  positionWE: WorkEligibility[] = [],
  isVisaSponsorshipAvailable: boolean
) {
  if (isVisaSponsorshipAvailable) return true;
  let returnValue = false;

  const isIneligible = equals(WorkEligibilityType.Ineligible);
  const isUnknown = equals(WorkEligibilityType.Unknown);
  const isAllowed = (pwe: WorkEligibility, cwe: WorkEligibility) =>
    isPartialMatch(pwe, ['location', 'type'])(cwe) ||
    ((isUnknown(cwe.type) || isUnknown(pwe.type)) && pwe.location === cwe.location);

  if (!isEmpty(candidateWE) && !isEmpty(positionWE)) {
    candidateWE.forEach(cwe => {
      if (isIneligible(cwe.type)) return;

      if (positionWE.find(pwe => isAllowed(pwe, cwe))) returnValue = true;
    });
  }

  return returnValue;
}

/** Only supports candidate atm, but should be expanded to cover companyUsers as well.
 *  This should deprecate getMatchingCriteria.
 */
export const generateMatchingCriteria = ({
  candidateDetails: candidate,
  positionDetails: position,
  user = 'candidate'
}: MatchingCriteriaProps) => {
  const currency = getCurrencySymbol(candidate?.currency);

  if (user !== 'candidate') {
    throw new Error('generateMatchingCriteria only supports candidate users at the moment.');
  }

  const jobTitles = intersect<string>(candidate?.jobTitles, position?.jobTitles);
  const seniorities = intersect(candidate?.seniorities, position?.seniorities).map(
    // @ts-expect-error TS(2345) FIXME: Argument of type '(string?: string) => string' is ... Remove this comment to see the full error message
    capitalizeSentences
  );
  const industries = intersect<string>(candidate?.companyIndustries, position?.industry);
  // @ts-expect-error TS(2345) FIXME: Argument of type '(string?: string) => string' is ... Remove this comment to see the full error message
  const remote = intersect(candidate?.remote, position?.remote).map(capitalizeSentences);

  // Skills
  const primary = intersect<string>(candidate?.primarySkills, position?.primarySkills);
  const secondary = intersect<string>(candidate?.secondarySkills, position?.secondarySkills);
  const overlap = intersect<string>(candidate?.primarySkills, position?.secondarySkills);
  const preferences = intersect<string>(candidate?.skillPreferences, position?.primarySkills);
  const any = unique([...primary, ...secondary, ...overlap, ...preferences]);
  const skills = { primary, secondary, overlap, any, preferences };

  const salary = getIsCandidateSalaryInRange(
    currency,
    position.salaryMin,
    position.salaryMax,
    candidate.salaryMin
  );

  // Todo: Location (this needs improving but no time atm)
  const candidateLocations = compact<string>(
    [
      candidate?.locationCity,
      candidate?.locationPreferences?.map(location =>
        typeof location !== 'number' ? location?.label : undefined
      )
    ].flat()
  );
  const locations = candidateLocations.filter(location => location === position?.locationCity);

  return {
    jobTitles,
    seniorities,
    skills,
    industries,
    remote,
    salary,
    locations
  } as MatchingCriteria;
};

const getIsCandidateSalaryInRange = (
  currency: string,
  salaryMin?: number,
  salaryMax?: number,
  candidateSalaryMin?: number
) => {
  if (!candidateSalaryMin) return [];
  if (!salaryMin || !salaryMax) return [`${currency}${candidateSalaryMin}`]; // if salary undisclosed then don't compare

  return candidateSalaryMin <= salaryMax ? [`${currency}${candidateSalaryMin}`] : [];
};

const IMPORTANT_MATCHING_CRITERIA = [
  ['jobTitles'],
  ['seniorities'],
  ['skills', 'primary'],
  ['skills', 'any'],
  ['salary']
];

const STRING_MATCHING_CRITERIA = [
  ['jobTitles'],
  ['seniorities'],
  ['skills', 'any'],
  ['salary'],
  ['remote'],
  ['industries']
];

export const parseGeneralMatchingCriteria = (matchingCriteria: MatchingCriteria) => {
  const results = STRING_MATCHING_CRITERIA.map(criteria => {
    return path<any[]>(matchingCriteria, criteria);
  });

  return results;
};

export const matchingCriteriaToString = (matchingCriteria: MatchingCriteria) => {
  const results = parseGeneralMatchingCriteria(matchingCriteria).flat();
  return results.join(', ');
};

export const notMatchingCriteriaToString = (matchingCriteria: MatchingCriteria) => {
  const { skills, seniorities, jobTitles, salary, remote } = matchingCriteria;
  const noSkills = isEmpty(skills?.any) ? 'No skills in common. ' : '';
  const noCoreSkills = isEmpty(skills?.primary) && !noSkills ? 'No core skills in common. ' : '';
  const noSeniorities = isEmpty(seniorities) ? 'Seniority does not match. ' : '';
  const noJobTitles = isEmpty(jobTitles) ? 'Job title does not match. ' : '';
  const noSalary = isEmpty(salary) ? 'Salary expectation is over the offered range. ' : '';
  const noRemote = isEmpty(remote) ? 'Remote preferences do not match. ' : '';

  return `${noCoreSkills}${noSkills}${noSeniorities}${noJobTitles}${noSalary}${noRemote}`;
};

export const parseImportantMissingCriteria = (matchingCriteria: MatchingCriteria) => {
  const results = IMPORTANT_MATCHING_CRITERIA.map(criteria => {
    return path<any[]>(matchingCriteria, criteria);
  });

  return results;
};

export const isAllImportantCriteriaMissing = (matchingCriteria: MatchingCriteria) => {
  return parseImportantMissingCriteria(matchingCriteria)
    .map(criteria => isEmpty(criteria))
    .every(Boolean);
};

export const isSomeImportantCriteriaMissing = (matchingCriteria: MatchingCriteria) => {
  return parseImportantMissingCriteria(matchingCriteria)
    .map(criteria => isEmpty(criteria))
    .some(Boolean);
};

export const isSomeCriteriaInCommon = (matchingCriteria: MatchingCriteria) => {
  const results = parseGeneralMatchingCriteria(matchingCriteria).flat();
  return results && results?.length > 0;
};
