import React, { lazy, useEffect, useState } from 'react';
import { Location } from 'history';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

import { CompanyType, PublicStoryDTO } from '@cohiretech/common-types';

import media from 'styles/media';
import { isAgenciesPage } from 'utils/url';
import { noop } from 'utils/fn';
import { addDimensionsOnImages, storiesImageParams } from 'v2/services/image';
import { generateStoryURL } from 'utils';
import { retryImport } from 'utils/fn/retry';
import { getCompanyStories } from 'v2/services/fetchers/public/companyStories';

import PageSection from 'components/pagesection';
import ListingSlider from 'components/listingslider';
import Button from 'components/button';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { getFeaturedQuotes, scrollToBookDemoForm } from './helpers';

const StoryBlockList = lazy(() => retryImport(() => import('components/storyblocklist')));

type Props = {
  title?: string;
  subTitle?: string;
  location: Location;
  darkMode: boolean;
};

export default function Testimonials({
  title = 'Top tech companies make their direct hires on cord',
  subTitle = 'Companies on cord save up to 10x on their traditional recruitment costs.',
  location,
  darkMode
}: Props) {
  const isForAgenciesPage = isAgenciesPage(location.pathname);
  const [stories, setStories] = useState<PublicStoryDTO[]>([]);
  const history = useHistory();
  const { pathname } = useLocation();
  const featuredQuotes = getFeaturedQuotes(pathname);

  useEffect(() => {
    if (!isForAgenciesPage) return;
    async function fetchStories() {
      const { data = [] } = await getCompanyStories({
        companyType: CompanyType.Agency
      });

      const storiesWithResizedImages = data.map((story: PublicStoryDTO) =>
        addDimensionsOnImages(story, storiesImageParams())
      );

      setStories(storiesWithResizedImages || []);
    }
    fetchStories();
  }, [isForAgenciesPage]);

  return (
    <QuotesSection
      title={title}
      subTitle={subTitle}
      widthClassName="page_view"
      className={`center_alignment ${darkMode ? 'dark_blue_background' : 'grey_background'}`}
    >
      <ConditionalRender predicate={isForAgenciesPage}>
        <StoryBlockList
          items={stories.slice(0, 3)}
          type="company"
          emptyMessage="No customer stories published yet"
          columns={3}
          generateURL={storyData => generateStoryURL(location, storyData, true)}
          history={history}
          hideDemoButtons
        />
      </ConditionalRender>
      <QuoteListingSlider
        itemType="testimonial_item"
        items={featuredQuotes}
        columns={3}
        location={location}
      />
      <Button
        text="Book a demo"
        buttonStyle={darkMode ? 'stroke_white' : 'stroke_dark_blue'}
        icon="icon_demo"
        link={isForAgenciesPage ? '#' : '/request-demo'}
        action={isForAgenciesPage ? scrollToBookDemoForm : noop}
        className="twenty_px_font"
      />
    </QuotesSection>
  );
}

const QuotesSection = styled(PageSection)`
  &.page_section {
    padding: 40px 0;

    .page_section_title {
      ${media.mobileMin`
        width: 90%;
      `}

      ${media.tabletLandscapeMin`
        width: 65%;
      `}
      
      ${media.smallLaptopMin`
        width: 55%;
      `}
    }

    .button {
      margin: 1.75rem 0 0;
    }

    &.dark_blue_background {
      color: ${({ theme: { fontColour } }) => fontColour};

      .testimonial_item {
        background: ${({ theme: { colours } }) => colours.lightFontColour};
        color: ${({ theme: { colours } }) => colours.fontColour};
      }

      .button {
        color: white;
      }
    }
  }
`;

const QuoteListingSlider = styled(ListingSlider)`
  .listing_slider_list_wrapper {
    display: flex;
    align-items: stretch;

    .testimonial_item > div {
      height: 100%;
    }
  }

  .scroll_arrow {
    top: calc(50% - 20px) !important;
    background: ${({ theme: { darkMode, colours } }) =>
      darkMode ? colours.darkBgColour : colours.lightBgColour};

    &.left_arrow {
      box-shadow: 10px 0px 10px rgba(49, 71, 95, 0.08) !important;
    }

    &.right_arrow {
      box-shadow: -10px 0px 10px rgba(49, 71, 95, 0.08) !important;
    }

    span[class^='icon_arrow'] {
      color: ${({ theme: { fontColour } }) => fontColour};
    }
  }

  .testimonial {
    line-height: 21px;
    margin-bottom: 1rem;
  }
`;
