import React from 'react';
import styled, { css } from 'styled-components';

import { SelectedPricing } from 'types';
import { getDisplayPriceSetup } from 'v2/services/tools/pricing';

import Badge from 'components/badge';

import { StandardTier } from 'v2/components/ui/organisms/PricingPlansSection';
import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { GradientText, GradientTextProps } from 'v2/components/ui/styles';

import { checkIfMonthlyPlan } from './PricingPlanCard.helpers';

type PlanPriceProps = {
  selectedPricing: SelectedPricing;
  plan?: StandardTier;
};

export default function PlanPrice({ selectedPricing, plan }: PlanPriceProps) {
  const { frequency, pricingCountry: pricingType } = selectedPricing;
  const { price, discountedPrice, discountLabel } = plan || {};

  const { originalPrice, displayPrice } = getDisplayPriceSetup({
    price,
    discountedPrice,
    pricingCountry: pricingType
  });

  const isMonthlyPlan = checkIfMonthlyPlan(frequency);
  const appendedFrequencyOrInfo = `/month${isMonthlyPlan ? '' : ', billed annually'}`;

  return (
    <div className="plan_price_container">
      <ConditionalRender
        predicate={price}
        fallback={
          <Price className="plan_price" gradientTextColour="cordGradient">
            Contact us
          </Price>
        }
      >
        <ConditionalRender predicate={originalPrice}>
          <OriginalPriceContainer className="original_price_container">
            <OriginalPrice className="original_price" gradientTextColour="cordGradient">
              {originalPrice}
            </OriginalPrice>
            <ConditionalRender predicate={discountLabel}>
              <Badge text={discountLabel!} type="info_light" />
            </ConditionalRender>
          </OriginalPriceContainer>
        </ConditionalRender>
        <Price className="plan_price" gradientTextColour="cordGradient">
          {displayPrice}
          <span className="frequency">{appendedFrequencyOrInfo}</span>
        </Price>
      </ConditionalRender>
    </div>
  );
}

const OriginalPriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .badge {
    margin: 0;
  }
`;

const OriginalPrice = styled.span<GradientTextProps>`
  position: relative;
  font-size: 1.25rem;
  opacity: 0.6;
  ${GradientText}

  // line-through with a gradient colour
    &:before {
    content: '';
    width: 100%;
    position: absolute;
    top: 50%;
    background: var(--gradient-primary-base);
    height: 1px;
  }
`;

const Price = styled.div<GradientTextProps>`
  ${({ theme: { typography } }) => css`
    margin-top: -2px;
    font-size: 2rem;
    ${GradientText}

    .frequency {
      font-size: ${typography.small};
    }
  `}
`;
