import { DefaultTheme } from 'styled-components';

import media from 'styles/media';

import { colours, commonFns, grid, typography } from './common';

export const lightTheme: DefaultTheme = {
  darkMode: false,
  fontColour: colours.fontColour,
  bg: colours.lightBgColour,
  container: 'white',
  fn: {
    ...commonFns,
    shadow: (alpha: number = 0.2) => `0 0 15px -2px rgba(49, 71, 95, ${alpha})`
  },
  colours,
  grid,
  typography,
  media
};

export const darkTheme: DefaultTheme = {
  darkMode: true,
  fontColour: colours.lightFontColour,
  bg: colours.darkerBgColour,
  container: colours.darkBgColour,
  fn: {
    ...commonFns,
    shadow: (alpha: number = 0.2) => `0 0 15px -2px rgba(0, 0, 0, ${alpha})`
  },
  colours,
  grid,
  typography,
  media
};
