import { isArray, isObject, isString } from './typeGuards';

/**
 * Checks if a value is considered empty.
 *
 * - If the value is a string, it checks if it is an empty string.
 * - If the value is an array, it checks if it contains no items.
 * - If the value is an object, it checks if it contains no keys.
 * - Returns true if the value is null or undefined.
 *
 * @param {T} value - The value to check for emptiness.
 * @returns {boolean} Returns true if the value is considered empty; false otherwise.
 */
export const isEmpty = <T = any>(value: T): boolean => {
  if (!value) return true;

  if (isString(value)) {
    return !value || /^\s*$/.test(value);
  }

  if (isArray(value)) {
    return value.length === 0;
  }

  if (isObject(value)) {
    return Object.getOwnPropertyNames(value).length === 0;
  }

  return false;
};

/**
 * Strictly checks if a value is considered "empty"
 * by only treating a select few types as empty.
 *
 * - If the value is a string, it checks if it is an empty string.
 * - If the value is an array, it checks if it contains no items.
 * - If the value is an object, it checks if it contains no keys.
 * - All other values are considered as non-empty.
 *
 * @param {T} value - The value to strictly check for emptiness.
 * @returns {boolean} Returns true if the value is considered strictly empty; false otherwise.
 */
export const isStrictlyEmpty = <T = any>(value: T): boolean => {
  if (isString(value)) {
    return value === '';
  }

  if (isArray(value)) {
    return value.length === 0;
  }

  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }

  return false;
};
