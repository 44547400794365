import { PropertyChain } from 'types';

import { ensureArrayForm } from './path';

export type IndexedObj = Record<string | number, any>;

/**
 * Associates a value with a given path of keys in an object.
 * If the keys do not exist in the object, the function will create them.
 * If the value is undefined, the function will create the path without setting a value.
 *
 * This function does not mutate the original object. Instead, it creates
 * and returns a new object with the new key-value association.
 *
 * @type {T} - The type of the object.
 * @param {IndexedObj} obj - The initial object.
 * @param {PropertyChain} path - The path of keys within the object.
 * @param {any} [value] - The value to be associated with the given path. If not provided,
 * the function will create the path without setting a value.
 *
 * @returns {IndexedObj} A new object with the new key-value association.
 **/
export const assocPath = <T extends IndexedObj>(obj: T, path: PropertyChain, value?: any): T => {
  const arrayPath = ensureArrayForm(path);
  if (path.length === 0) return obj;

  // Create shallow copy of original object and get the first key in the path.
  const newObj: T = { ...obj };
  const key: keyof T = arrayPath[0];

  // Recursively traverse the object until we reach the end of the path.
  if (arrayPath.length > 1) {
    newObj[key] = assocPath(
      newObj[key] !== undefined ? { ...newObj[key] } : ({} as T[keyof T]),
      arrayPath.slice(1),
      value
    );
  } else {
    newObj[key] = value;
  }

  return newObj as T;
};
