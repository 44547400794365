/* eslint-disable no-console */
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable'; // For IE 9-11 support
import 'array-flat-polyfill'; // For Array.prototype.flat and Array.prototype.flatMap support
import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import App from 'app';

import { datadogLogs } from '@datadog/browser-logs';

import store from 'store';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n/config';

const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER;
const DATADOG_ENVIRONMENT = process.env.REACT_APP_DATADOG_ENV;

datadogLogs.init({
  clientToken: DATADOG_CLIENT_TOKEN!,
  service: 'client-web',
  site: 'datadoghq.eu',
  env: DATADOG_ENVIRONMENT,
  version: BUILD_NUMBER,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  sampleRate: 100
});

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
serviceWorkerRegistration.register();
