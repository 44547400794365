import { candidateSearchFilters } from 'fetcher';

export const getSearchPages = () => {
  return [
    {
      title: '.NET Developer',
      category: 'Developer Jobs',
      filter: '.NET',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: '.net-developer'
    },
    {
      title: 'Android Developer',
      category: 'Developer Jobs',
      filter: 'Android',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'android-developer'
    },
    {
      title: 'Angular Developer',
      category: 'Developer Jobs',
      filter: 'Angular',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'angular-developer'
    },
    {
      title: 'AI Developer',
      category: 'Developer Jobs',
      filter: 'Artificial Intelligence',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'ai-developer'
    },
    {
      title: 'Artificial Intelligence Developer',
      category: 'Developer Jobs',
      filter: 'Artificial Intelligence',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'artificial-intelligence-developer'
    },
    {
      title: 'Automation Test Engineer',
      category: 'Developer Jobs',
      filter: 'QA Automation Tester',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'automation-test-engineer'
    },
    {
      title: 'AWS Engineer',
      category: 'Developer Jobs',
      filter: 'AWS',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'aws-engineer'
    },
    {
      title: 'Back End Developer',
      category: 'Developer Jobs',
      filter: 'Back End',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'back-end-developer'
    },
    {
      title: 'Back End Engineer',
      category: 'Developer Jobs',
      filter: 'Back End',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'back-end-engineer'
    },
    {
      title: 'Big Data Developer',
      category: 'Developer Jobs',
      filter: 'Big Data Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'big-data-developer'
    },
    {
      title: 'Big Data Engineer',
      category: 'Developer Jobs',
      filter: 'Big Data Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'big-data-engineer'
    },
    {
      title: 'Blockchain Developer',
      category: 'Developer Jobs',
      filter: 'Blockchain',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'blockchain-developer'
    },
    {
      title: 'C Developer',
      category: 'Developer Jobs',
      filter: 'C',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'c-developer'
    },
    {
      title: 'C# Developer',
      category: 'Developer Jobs',
      filter: 'C#',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'c#-developer'
    },
    {
      title: 'C++ Developer',
      category: 'Developer Jobs',
      filter: 'C++',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'c++-developer'
    },
    {
      title: 'Cloud Developer',
      category: 'Developer Jobs',
      filter: 'Cloud Developer',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'cloud-developer'
    },
    {
      title: 'CTO',
      category: 'Developer Jobs',
      filter: 'CTO',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'cto'
    },
    {
      title: 'Data Science Developer',
      category: 'Developer Jobs',
      filter: 'Data Scientist',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'data-science-developer'
    },
    {
      title: 'Data Engineer',
      category: 'Developer Jobs',
      filter: 'Data Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'data-engineer'
    },
    {
      title: 'Deep Learning Developer',
      category: 'Developer Jobs',
      filter: 'Deep Learning Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'deep-learning-developer'
    },
    {
      title: 'Engineering Manager',
      category: 'Developer Jobs',
      filter: 'DevOps Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'engineering-manager'
    },
    {
      title: 'Head of Engineering',
      category: 'Developer Jobs',
      filter: 'Head of Engineering',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'head-of-engineering'
    },
    {
      title: 'VP of Engineering',
      category: 'Developer Jobs',
      filter: 'VP of Engineering',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'vp-of-engineering'
    },
    {
      title: 'DevOps Engineer',
      category: 'Developer Jobs',
      filter: 'DevOps Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'devops-engineer'
    },
    {
      title: 'Django Developer',
      category: 'Developer Jobs',
      filter: 'Django',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'django-developer'
    },
    {
      title: 'Erland Developer',
      category: 'Developer Jobs',
      filter: 'Erland',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'erland-developer'
    },
    {
      title: 'Front End Developer',
      category: 'Developer Jobs',
      filter: 'Front End',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'front-end-developer'
    },
    {
      title: 'Front End Engineer',
      category: 'Developer Jobs',
      filter: 'Front End',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'front-end-engineer'
    },
    {
      title: 'Full Stack Developer',
      category: 'Developer Jobs',
      filter: 'Full Stack',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'full-stack-developer'
    },
    {
      title: 'Go Developer',
      category: 'Developer Jobs',
      filter: 'Go',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'go-developer'
    },
    {
      title: 'Golang Developer',
      category: 'Developer Jobs',
      filter: 'Go',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'golang-developer'
    },
    {
      title: 'Groovy Developer',
      category: 'Developer Jobs',
      filter: 'Groovy',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'groovy-developer'
    },
    {
      title: 'Hadoop Developer',
      category: 'Developer Jobs',
      filter: 'Hadoop',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'hadoop-developer'
    },
    {
      title: 'Haskell Developer',
      category: 'Developer Jobs',
      filter: 'Haskell',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'haskell-developer'
    },
    {
      title: 'Infrastructure',
      category: 'Developer Jobs',
      filter: 'DevOps Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'infrastrucure'
    },
    {
      title: 'iOS Developer',
      category: 'Developer Jobs',
      filter: 'iOS',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'ios-developer'
    },
    {
      title: 'Java Developer',
      category: 'Developer Jobs',
      filter: 'Java',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'java-developer'
    },
    {
      title: 'JavaScript Developer',
      category: 'Developer Jobs',
      filter: 'JavaScript',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'javascript-developer'
    },
    {
      title: 'Laravel Developer',
      category: 'Developer Jobs',
      filter: 'Laravel',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'laravel-developer'
    },
    {
      title: 'Machine Learning Engineer',
      category: 'Developer Jobs',
      filter: 'Machine Learning Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'machine-learning-engineer'
    },
    {
      title: 'Magento Developer',
      category: 'Developer Jobs',
      filter: 'Magento',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'magento-developer'
    },
    {
      title: 'Mobile Developer',
      category: 'Developer Jobs',
      filter: 'Mobile Developer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'mobile-developer'
    },
    {
      title: 'Node Developer',
      category: 'Developer Jobs',
      filter: 'Node.js',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'nodejs-developer'
    },
    {
      title: 'Node.js Developer',
      category: 'Developer Jobs',
      filter: 'Node.js',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'node-developer'
    },
    {
      title: 'Objective-C Developer',
      category: 'Developer Jobs',
      filter: 'Objective C',
      attribute: candidateSearchFilters.skill.attribute,
      query: 'objective-c-developer'
    },
    {
      title: 'Perl Developer',
      category: 'Developer Jobs',
      filter: 'Perl',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'perl-developer'
    },
    {
      title: 'PHP Developer',
      category: 'Developer Jobs',
      filter: 'PHP',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'php-developer'
    },
    {
      title: 'Python Developer',
      category: 'Developer Jobs',
      filter: 'Python',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'python-developer'
    },
    {
      title: 'Python Engineer',
      category: 'Developer Jobs',
      filter: 'Python',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'python-engineer'
    },
    {
      title: 'QA Engineer',
      category: 'Developer Jobs',
      filter: 'QA Engineer',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'qa-engineer'
    },
    {
      title: 'React Developer',
      category: 'Developer Jobs',
      filter: 'React',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'react-developer'
    },
    {
      title: 'React.js Developer',
      category: 'Developer Jobs',
      filter: 'React',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'reactjs-developer'
    },
    {
      title: 'React Native Developer',
      category: 'Developer Jobs',
      filter: 'React Native',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'react-native-developer'
    },
    {
      title: 'Ruby Developer',
      category: 'Developer Jobs',
      filter: 'Ruby',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'ruby-developer'
    },
    {
      title: 'Ruby on Rails Developer',
      category: 'Developer Jobs',
      filter: 'Ruby on Rails',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'ruby-on-rails-developer'
    },
    {
      title: 'Scala Developer',
      category: 'Developer Jobs',
      filter: 'Scala',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'scala-developer'
    },
    {
      title: 'Senior Developer',
      category: 'Developer Jobs',
      filter: 'senior',
      attribute: candidateSearchFilters.seniority.attribute,
      query: 'senior-developer'
    },
    {
      title: 'Software Developer',
      category: 'Developer Jobs',
      filter: 'Software Developer',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'software-developer'
    },
    {
      title: 'Swift Developer',
      category: 'Developer Jobs',
      filter: 'Swift',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'swift-developer'
    },
    {
      title: 'Symfony Developer',
      category: 'Developer Jobs',
      filter: 'Symfony',
      attribute: candidateSearchFilters.orPrimarySkill.attribute,
      query: 'symfony-developer'
    },
    {
      title: 'System Administrator',
      category: 'Developer Jobs',
      filter: 'System Administrator',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'system-administrator'
    },
    {
      title: 'Tech Lead',
      category: 'Developer Jobs',
      filter: 'Tech Lead',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'tech-lead'
    },
    {
      title: 'Technical Lead',
      category: 'Developer Jobs',
      filter: 'Technical Lead',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'technical-lead'
    },
    {
      title: 'Team Lead',
      category: 'Developer Jobs',
      filter: 'Team Lead',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'team-lead'
    },
    {
      title: 'Web Developer',
      category: 'Developer Jobs',
      filter: 'Web Developer',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'web-developer'
    },
    {
      title: 'Junior Developer',
      category: 'Developer Jobs',
      filter: 'junior',
      attribute: candidateSearchFilters.seniority.attribute,
      query: 'junior-developer'
    },
    {
      title: 'Senior Developer',
      category: 'Developer Jobs',
      filter: 'senior',
      attribute: candidateSearchFilters.seniority.attribute,
      query: 'senior-developer'
    },
    {
      title: 'Zend Developer',
      category: 'Developer Jobs',
      filter: 'Zend',
      attribute: candidateSearchFilters.primarySkill.attribute,
      query: 'zend-developer'
    },
    {
      title: 'UI Designer',
      category: 'Designer Jobs',
      filter: 'UI Designer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'ui-designer'
    },
    {
      title: 'UX Designer',
      category: 'Designer Jobs',
      filter: 'UX Designer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'ux-designer'
    },
    {
      title: 'UI Developer',
      category: 'Designer Jobs',
      filter: 'UI Developer',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'ui-developer'
    },
    {
      title: 'UI/UX Designer',
      category: 'Designer Jobs',
      filter: 'UX Researcher',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'ui-ux-designer'
    },
    {
      title: 'UX Researcher',
      category: 'Designer Jobs',
      filter: 'UX Researcher',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'ux-researcher'
    },
    {
      title: 'Web Designer',
      category: 'Designer Jobs',
      filter: 'Web Designer',
      attribute: candidateSearchFilters.keyword.attribute,
      query: 'web-designer'
    },
    {
      title: 'Big Data Engineer',
      category: 'Data Jobs',
      filter: 'Big Data Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'big-data-engineer'
    },
    {
      title: 'Data/Insight Analyst',
      category: 'Data Jobs',
      filter: 'Data/Insight Analyst',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'data-analyst'
    },
    {
      title: 'Data Engineer',
      category: 'Data Jobs',
      filter: 'Data Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'data-engineer'
    },
    {
      title: 'Data Scientist',
      category: 'Data Jobs',
      filter: 'Data Scientist',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'data-scientist'
    },
    {
      title: 'Deep Learning Engineer',
      category: 'Data Jobs',
      filter: 'Deep Learning Engineer',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'deep-learning-engineer'
    },
    {
      title: 'Product Manager',
      category: 'Manager Jobs',
      filter: 'Product Manager',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'product-manager'
    },
    {
      title: 'Product Owner',
      category: 'Manager Jobs',
      filter: 'Head of Product',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'product-owner'
    },
    {
      title: 'Head of Product',
      category: 'Manager Jobs',
      filter: 'Head of Product',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'head-of-product'
    },
    {
      title: 'Project Manager',
      category: 'Manager Jobs',
      filter: 'Project Manager',
      attribute: candidateSearchFilters.jobTitle.attribute,
      query: 'project-manager'
    },
    {
      title: 'Augmented Reality',
      category: 'Industries',
      filter: 'Augmented Reality',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'augmented-reality'
    },
    {
      title: 'Automotive',
      category: 'Industries',
      filter: 'Automotive',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'automotive'
    },
    {
      title: 'Design',
      category: 'Industries',
      filter: 'Design',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'design'
    },
    {
      title: 'E-Commerce',
      category: 'Industries',
      filter: 'E-Commerce',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'e-commerce'
    },
    {
      title: 'E-Learning',
      category: 'Industries',
      filter: 'E-Learning',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'e-learning'
    },
    {
      title: 'Engineering',
      category: 'Industries',
      filter: 'Engineering',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'engineering'
    },
    {
      title: 'Data',
      category: 'Industries',
      filter: 'Data',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'data'
    },
    {
      title: 'Finance',
      category: 'Industries',
      filter: 'Finance',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'finance'
    },
    {
      title: 'FinTech',
      category: 'Industries',
      filter: 'FinTech',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'fintech'
    },
    {
      title: 'Fitness',
      category: 'Industries',
      filter: 'Fitness',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'fitness'
    },
    {
      title: 'Gaming',
      category: 'Industries',
      filter: 'Gaming',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'gaming'
    },
    {
      title: 'HealthTech',
      category: 'Industries',
      filter: 'HealthTech',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'healthtech'
    },
    {
      title: 'Marketing',
      category: 'Industries',
      filter: 'Marketing and Advertising',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'marketing'
    },
    {
      title: 'Manufacturing',
      category: 'Industries',
      filter: 'Manufacturing',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'manufacturing'
    },
    {
      title: 'Virtual Reality',
      category: 'Industries',
      filter: 'Virtual Reality',
      attribute: candidateSearchFilters.industry.attribute,
      query: 'virtual-reality'
    },
    {
      title: 'Remote',
      category: 'Remote jobs',
      filter: 'remote',
      attribute: candidateSearchFilters.remoteWorking.attribute,
      query: 'remote'
    }
  ];
};
