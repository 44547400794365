import axios, { AxiosResponse } from 'axios';

import { AgencyListingInterface } from '@cohiretech/common-types';

import { InputAgencyClientCompany } from 'types';

const COMPANY_AGENCY_POSITION = process.env.REACT_APP_COMPANY_AGENCY_POSITION;

type CreateAgencyPositionResponse = { data: AgencyListingInterface };

type AgencyPositionInterface = AgencyListingInterface & {
  members?: number[];
};

type CreateAgencyListing = Omit<AgencyListingInterface, 'agencyClientCompany'> & {
  agencyClientCompany?: InputAgencyClientCompany;
};

export const createAgencyPosition = async (position: CreateAgencyListing) => {
  try {
    if (position.agencyClientCompany?.id) delete position.agencyClientCompany.id;

    const { data } = await axios.post<
      CreateAgencyListing,
      AxiosResponse<CreateAgencyPositionResponse>
    >(`${COMPANY_AGENCY_POSITION}`, position);
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const editAgencyPosition = async (position: AgencyPositionInterface, listingID: number) => {
  try {
    const { data } = await axios.put(`${COMPANY_AGENCY_POSITION}/${listingID}`, position);
    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
