/* eslint-disable no-console */
import { createSelector } from '@reduxjs/toolkit';
import { MessageFilter } from '@cohiretech/common-types';

import { RootState } from 'store';
import { pluck } from 'utils/object';
import { equalsPick } from 'utils/fn';
import { PositionInProfile } from 'types';

import { UpdatedNotificationResult } from 'v2/pages/company/Messages/Messages.types';

import { ProfileState } from './user.types';
import {
  candidateSelector,
  companySelector,
  isCandidateProAllowed,
  isCandidateProfile,
  isCompanyProfile
} from './user.helpers';

const getUser = (state: RootState) => state.user;

/** Select profile: Uses assertions for type safety */
export const selectProfile = createSelector(getUser, user => user.profile as ProfileState);
export const selectCandidateProfile = createSelector(selectProfile, candidateSelector);
export const selectCompanyProfile = createSelector(selectProfile, companySelector);

export const selectIsCandidate = createSelector(selectProfile, isCandidateProfile);
export const selectIsCompany = createSelector(selectProfile, isCompanyProfile);

export const selectIntegrationRedirectURL = createSelector(
  getUser,
  user => user.integrationRedirectURL
);
export const selectIsProfileUpdated = createSelector(getUser, user => user.isProfileUpdated);
export const selectNotifications = createSelector(
  selectCompanyProfile,
  profile => profile?.notifications || []
);
export const selectLocalisationCountry = createSelector(getUser, user => user.localisationCountry);
export const selectPermissions = createSelector(getUser, user => user.permissions);
export const selectUserType = createSelector(getUser, user => user.userType);
export const selectDemoCompany = createSelector(getUser, user => user.demoCompany);
export const selectIsUnpaidAccount = createSelector(
  selectCompanyProfile,
  profile => profile?.isUnpaidAccount
);
export const selectPositions = createSelector(
  selectCompanyProfile,
  profile => profile?.positions || []
);
export const selectSubscriptionDetailsAndPricing = createSelector(
  selectCompanyProfile,
  profile => profile?.subscriptionDetailsAndPricing
);
export const selectPosition = (id: number) =>
  createSelector(selectPositions, equalsPick<PositionInProfile>('id')(id));
export const selectPositionMembers = (id: number) =>
  createSelector(selectPosition(id), pluck('members'));
export const selectPositionName = (id: number) =>
  createSelector(selectPosition(id), pluck('position'));
export const selectPositionSearchHidden = (id: number) =>
  createSelector(selectPosition(id), pluck('searchHidden'));
export const selectRemoteLocations = createSelector(selectPositions, positions =>
  positions?.map((p: PositionInProfile) => ({
    [p.id]: [p.remoteLocationContinents, p.remoteLocationCountries]
  }))
);
export const selectRemoteLocation = (id: number) =>
  createSelector(selectRemoteLocations, locations => locations?.find((l: any) => l[id]));
export const selectPositionInvisible = (id: number) =>
  createSelector(selectPosition(id), pluck('invisible'));
export const selectCompanyUser = createSelector(
  selectCompanyProfile,
  profile => profile?.companyUser
);
export const selectCompanyName = createSelector(
  selectCompanyProfile,
  profile => profile?.companyName
);
export const selectCompanyType = createSelector(
  selectCompanyProfile,
  profile => profile?.companyType
);
export const selectIsAgencyAccount = createSelector(selectCompanyType, type => type === 'agency');
export const selectMessageFilter = createSelector(
  selectCompanyUser,
  user => user?.messageFilter as MessageFilter
);
export const selectTotalHiringSeat = createSelector(
  selectCompanyProfile,
  user => user?.totalHiringSeat
);
export const selectCompanyID = createSelector(selectCompanyProfile, user => user?.companyID || -1);

export const selectCompanyNotifications = createSelector(
  selectCompanyProfile,
  profile => (profile?.notifications || []) as UpdatedNotificationResult[]
);

export const selectCompanyStreams = createSelector(
  selectCompanyProfile,
  profile => profile?.companyStreams || []
);

export const selectCandidateLocation = createSelector(
  selectCandidateProfile,
  profile => profile?.location
);

export const selectIsCandidateProAllowed = createSelector(selectCandidateProfile, profile =>
  isCandidateProAllowed(profile)
);

export const selectCandidatePro = createSelector(getUser, user => user.candidatePro);

export const selectCandidatePrimarySkills = createSelector(
  selectCandidateProfile,
  profile => profile?.primarySkills || []
);

export const selectCandidateSecondarySkills = createSelector(
  selectCandidateProfile,
  profile => profile?.secondarySkills || []
);

export const selectPricingRegion = createSelector(getUser, user => user.pricingRegion);

export const selectCandidateLocationWorkPreferences = createSelector(
  selectCandidateProfile,
  profile => profile?.locationWorkPreferences
);
