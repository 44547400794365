/**
 * Returns a function that compares its argument to the provided value.
 * @template T
 * @param {T} a - The value to compare against.
 * @returns {(b: T) => boolean} A function that takes a value and returns true if it is equal to `a`, false otherwise.
 */
export const equals =
  <T = any>(a: T) =>
  (b: T): boolean =>
    a === b;
