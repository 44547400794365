import React from 'react';
import styled, { css } from 'styled-components';

import IconButton, { IconButtonProps } from 'components/iconbutton';

type SubmitArrowProps = {
  valid: boolean;
  onSubmit: () => void;
} & Pick<IconButtonProps, 'tabIndex'>;

export default function SubmitArrowButton({ valid, tabIndex, onSubmit }: SubmitArrowProps) {
  return (
    <SubmitArrow
      icon="icon_arrow_right"
      ariaLabel="Submit"
      tabIndex={tabIndex}
      valid={valid}
      onClick={onSubmit}
    />
  );
}

const SubmitArrow = styled(IconButton)<{ valid: boolean }>`
  &.icon_button {
    color: var(--text-body-tertiary);
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    ${({ valid }) =>
      valid &&
      css`
        opacity: 0.9;
        color: var(--text-accent-secondary-base);
        font-weight: bold !important;

        &:hover {
          opacity: 1;
          color: var(--text-accent-secondary-hover);
          background-color: unset;
        }
      `}
  }
`;
