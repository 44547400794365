import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { getLocalStorageItem } from 'cookieManager';
import { Locale } from 'types';

import RESOURCES from './translations';
import { getSupportedLocaleFromCountry } from './i18nUtils';

const byCountryDetector = {
  name: 'byCountryDetector',

  lookup() {
    const country = getLocalStorageItem('country');
    const locale = getSupportedLocaleFromCountry(country);
    if (country !== null) return locale;
  }
};

const CustomLanguageDetector = new LanguageDetector();
CustomLanguageDetector.addDetector(byCountryDetector);

i18n
  .use(CustomLanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    resources: RESOURCES,
    fallbackLng: Locale.en_UK,
    supportedLngs: [Locale.en_UK, Locale.en_US],
    detection: {
      caches: ['localStorage'],
      order: ['byCountryDetector', 'localStorage', 'navigator']
    }
  });
