import React from 'react';
import styled from 'styled-components';

import colours from 'css/base/_colours.module.scss';

type HamburgerProps = {
  isOpen?: boolean;
  color?: 'light' | 'dark';
  onClick?: () => void;
};

const HamburgerMenu = ({
  isOpen = false,
  color = 'light',
  onClick = () => null
}: HamburgerProps) => {
  return (
    <HamburgerBread color={color} onClick={onClick}>
      <HamburgerPatty color={color} isOpen={isOpen} animationCSS={rotateCSS('cw')} />
      <HamburgerPatty color={color} isOpen={isOpen} animationCSS="display: none" />
      <HamburgerPatty color={color} isOpen={isOpen} animationCSS={rotateCSS('ccw')} />
    </HamburgerBread>
  );
};

const rotateCSS = (direction: 'cw' | 'ccw') => `
  position: absolute;
  top: 25%;
  left: 0;
  transform: rotate(${direction === 'cw' ? 45 : -45}deg);
`;

const HamburgerBread = styled.button<{ color: 'light' | 'dark' }>`
  position: relative;
  display: block;
  z-index: 10;
  cursor: pointer;
  color: ${({ color, theme: { darkMode } }) =>
    darkMode ? colours.lightFontColour : color ? colours.primaryColour : colours.darkPrimaryColour};
  transition: all 0.3s ease-in-out;
  width: 20px;
  height: 26px;
`;

const HamburgerPatty = styled.div<{
  color: 'light' | 'dark';
  isOpen: boolean;
  animationCSS: string;
}>`
  height: 2px;
  width: 25px;
  background-color: ${({ color, theme: { darkMode } }) =>
    darkMode
      ? colours.lightFontColour
      : color === 'light'
      ? colours.primaryColour
      : colours.darkPrimaryColour};
  margin: 5px 0;

  ${({ isOpen, animationCSS }) => isOpen && animationCSS}
  transition: all 0.3s ease-in-out;
  width: 22px;
`;

export default HamburgerMenu;
