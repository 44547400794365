import React from 'react';

import { CandidateStatus } from '@cohiretech/common-types';

import { useCandidateProfile } from 'store';

import Tooltip from 'components/tooltip';

import { PositionType } from '..';

import { BlockingCriteriaStatus } from './ApplyButtonTooltip.helpers';
import BlockingCriteriaTooltip from './BlockingCriteriaTooltip';

type ApplyButtonTooltipProps = {
  position: PositionType;
  blockingCriteriaStatus: BlockingCriteriaStatus;
};

export default function ApplyButtonTooltip({
  position,
  blockingCriteriaStatus
}: ApplyButtonTooltipProps) {
  const { status } = useCandidateProfile()!;

  if (blockingCriteriaStatus) {
    return <BlockingCriteriaTooltip {...blockingCriteriaStatus} position={position} />;
  }

  if (status === CandidateStatus.Passive) {
    return (
      <Tooltip width="260px">
        Your status is set to "Not looking". Change it to "Open to opportunities" to be able to
        message companies directly on cord.
      </Tooltip>
    );
  }

  return null;
}
