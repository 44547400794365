import { Location } from 'history';

type Pathname = Location['pathname'];

const currentPage = () => window.location.pathname;

export const isDiscoverPage = (pathname = currentPage()) =>
  new RegExp(/^\/company\/discover/).test(pathname);

export const isMessagesPage = (pathname = currentPage()) =>
  new RegExp(/^\/company\/messages/).test(pathname) ||
  new RegExp(/^\/candidate\/messages/).test(pathname);

export const isTechHubPage = (pathname = currentPage()) => new RegExp(/^\/techhub/).test(pathname);

export const isSavedSearch = (pathname: Pathname) => {
  return /^\/candidate\/search\/[0-9]+/.test(pathname);
};

export const isNewSearch = (pathname: Pathname) => {
  return /^\/candidate\/search\/new/.test(pathname);
};

export const isOnNewSearchPage = (pathname: Pathname) => {
  return /^\/candidate\/search\/new$/.test(pathname);
};
