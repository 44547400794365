import axios from 'axios';

import { JobTitleOption, JobTitleOrderingValues } from '@cohiretech/common-types';

import { APIResponse } from 'types';

const REACT_APP_PUBLIC_JOB_TITLES = process.env.REACT_APP_PUBLIC_JOB_TITLES;

type JobCategory = {
  category: string;
  options: JobTitleOption[];
};

export type JobCategoryOptions = JobCategory[];

export const getJobCategoryOptions = async (): Promise<APIResponse<JobCategoryOptions>> => {
  try {
    const { data } = await axios.get<{ data: JobCategoryOptions }>(
      `${REACT_APP_PUBLIC_JOB_TITLES}`
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getJobTitleOptions = async (
  sortBy?: JobTitleOrderingValues
): Promise<JobTitleOption[]> => {
  try {
    const { data } = await axios.get<{ data: JobTitleOption[] }>(
      `${REACT_APP_PUBLIC_JOB_TITLES}/list`,
      { params: { sortBy } }
    );
    return data.data;
  } catch (error: any) {
    return [];
  }
};
