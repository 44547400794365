import {
  CategorisedListingsDTO,
  CompanyType,
  ListingsByCompaniesDTO,
  ListingsByJobTitleDTO
} from '@cohiretech/common-types';

import { pluralise } from 'utils';

import { AvatarProps } from 'v2/components/ui/atoms/Avatar';

import {
  PositionSearchOptions,
  SEARCH_CATEGORY_INFO,
  SearchCategory,
  SearchOptionsByCategory,
  getFilterAttributeByCategory
} from '../candidateSearch.helpers';

const INITIAL_SEARCH_OPTIONS: PositionSearchOptions = {
  byCategory: {} as SearchOptionsByCategory,
  keyword: []
};

type ExtendedOptionDTO = ListingsByJobTitleDTO & ListingsByCompaniesDTO;

const buildCompanyLogo =
  ({ label, companyLogo }: Pick<ListingsByCompaniesDTO, 'label' | 'companyLogo'>) =>
  (width: number): AvatarProps => ({
    className: 'company_logo',
    photoURL: companyLogo,
    alt: `${label} logo`,
    width,
    resizePhotoDimensions: { width: width * 2 }
  });

const getPositionsCountLabel = (activePositionsCount?: string) => {
  if (!activePositionsCount) return;

  const count = Number(activePositionsCount);

  return Number.isNaN(count)
    ? `${activePositionsCount} positions`
    : pluralise(count, 'position', 'positions');
};

const getCompanyTypeDesc = (companyType: CompanyType) => {
  switch (companyType) {
    case CompanyType.Direct:
      return 'Hiring directly on cord';
    case CompanyType.External:
      return 'Hiring elsewhere, indexed by cord';
    case CompanyType.Agency:
      return 'Agency recruiting on cord';
    default:
      break;
  }
};

const parseSearchOption =
  (category: SearchCategory) =>
  ({ label, activePositionsCount, value, tags, companyLogo, companyType }: ExtendedOptionDTO) => {
    const isCompanyOption = category === 'companies';
    const isExternalCompany = isCompanyOption && companyType !== CompanyType.Direct;
    const { icon, label: description } = SEARCH_CATEGORY_INFO[category];
    const getCompanyLogo = buildCompanyLogo({ label, companyLogo });

    const option = {
      label,
      value: value || label,
      attribute: getFilterAttributeByCategory(category)
    };

    const categorizedOption = !isExternalCompany && {
      ...option,
      ...(isCompanyOption ? { logo: getCompanyLogo(24) } : {}),
      count: getPositionsCountLabel(activePositionsCount)
    };

    const keywordOption = {
      ...option,
      ...(isCompanyOption ? { logo: getCompanyLogo(36) } : { icon }),
      description: isCompanyOption ? getCompanyTypeDesc(companyType) : description,
      tags: tags || [label]
    };

    return { categorizedOption, keywordOption };
  };

export const parseCategorisedOptions = (options: CategorisedListingsDTO) => {
  const entries = Object.entries(options) as [SearchCategory, ExtendedOptionDTO[]][];

  const results = entries.reduce((acc, [category, optionsByCategory]) => {
    acc.byCategory[category] = [];

    optionsByCategory.forEach(option => {
      const { categorizedOption, keywordOption } = parseSearchOption(category)(option);

      if (categorizedOption) acc.byCategory[category].push(categorizedOption);
      acc.keyword.push(keywordOption);
    });

    return acc;
  }, INITIAL_SEARCH_OPTIONS);

  return results;
};
