import React from 'react';
import { Route, Redirect, RouteProps, RouteChildrenProps } from 'react-router-dom';
import { TransitionState } from 'app';

import { isProfileURL, storeReferrerTrackingDetails } from 'utils';
import { trackPageView } from 'tracking-utils';

type Props = RouteProps &
  Partial<RouteChildrenProps> &
  TransitionState & {
    className?: string;
  };
export default class PublicRoute extends React.Component<Props> {
  componentDidMount() {
    if (this.props && (this.props as $TSFixMe).location) {
      const location = (this.props as $TSFixMe).location;
      storeReferrerTrackingDetails(location.search);
      trackPageView(location);
    }
  }

  componentDidUpdate(prevProps: any) {
    const { location: prevLocation } = prevProps;
    const { location } = this.props;

    if (location && prevLocation && location.pathname !== prevLocation.pathname) {
      trackPageView(location);
    }
  }

  render() {
    const { component: Component, admin, profile, demoCompany, permissions, ...rest } = this.props;

    return (
      <Route
        render={props =>
          admin ? (
            <Redirect
              to={{
                pathname: '/admin/dashboard',
                state: { from: props.location },
                search: props.location?.search ?? ''
              }}
            />
          ) : profile ? (
            profile.isCandidate ? (
              <Redirect
                to={{
                  pathname:
                    props.location && isProfileURL(props.location.pathname)
                      ? `/candidate/discover${props.location.pathname}`
                      : '/candidate/discover',
                  state: { from: props.location },
                  search: props.location?.search ?? ''
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: `/company/${
                    profile && permissions && !permissions.search && !permissions.showEmptySearch
                      ? 'messages'
                      : demoCompany
                      ? 'discover/search'
                      : 'discover'
                  }`,
                  state: { from: props.location },
                  search: props.location?.search ?? ''
                }}
              />
            )
          ) : (
            // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
            <Component {...props} {...rest} />
          )
        }
      />
    );
  }
}
