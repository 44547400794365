import React, {
  createContext,
  useContext,
  RefObject,
  PropsWithChildren,
  useReducer,
  useRef,
  useEffect
} from 'react';
import axios, { CancelTokenSource } from 'axios';

import { StringOrElements } from 'types';
import { formatTextGPT } from 'v2/services/fetchers/candidate/formatTextGPT';
import { trackEvent } from 'tracking-utils';

import { initialState, reducer } from './TextEnhancer.reducer';
import {
  setTextEditorAction,
  setCustomPromptAction,
  setIsLoadingAction,
  setAttemptEnhanceTextAction,
  setFailedEnhanceTextAction,
  setSuccessEnhanceTextAction,
  setIsHighlightedAction,
  setIsEnhancedAction
} from './TextEnhancer.actions';
import {
  AIAction,
  aiActions,
  cleanHTML,
  copySelectionAsHTML,
  ErrorOnEnhanceText,
  getUserSelection,
  markDifferences,
  removeEmptyElements,
  removeHighlightedTags,
  replaceSelectionWithHTML,
  restoreSelection,
  saveSelection,
  selectAllContent
} from './TextEnhancer.helpers';

export interface TextEnhancerContextState {
  cancelEnhanceText: () => void;
  enhanceText: (action: AIAction) => Promise<string | undefined>;
  errorMessage: StringOrElements;
  handleCustomPrompt: (userInput: string) => void;
  highlightMarked: boolean;
  isLoading: boolean;
  lastAIAction: AIAction | null;
  redoLastEnhance: () => void;
  setTextEditor: (textEditor: RefObject<HTMLDivElement>) => void;
  showCustomPrompt: boolean;
  status: 'success' | 'error' | null;
  textEditor: RefObject<HTMLDivElement> | null;
  toggleCustomPrompt: (value?: boolean) => void;
  toggleHighlightMarked: (value?: boolean) => void;
  isEnhanced: boolean;
}

const TextEnhancerContext = createContext<TextEnhancerContextState | undefined>(undefined);

interface TextEnhancerProviderProps {}

const TextEnhancerProvider = ({ children }: PropsWithChildren<TextEnhancerProviderProps>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    isLoading,
    textEditor,
    showCustomPrompt,
    errorMessage,
    lastAIAction,
    status,
    highlightMarked,
    isEnhanced
  } = state;
  const cancelToken = useRef<CancelTokenSource>();

  useEffect(() => {
    cancelToken.current = axios.CancelToken?.source();
    return () => {
      cancelToken.current?.cancel();
    };
  }, []);

  const toggleCustomPrompt = (value = !showCustomPrompt) => {
    dispatch(setCustomPromptAction(value));
  };

  const setTextEditor = (textEditor: RefObject<HTMLDivElement>) => {
    dispatch(setTextEditorAction(textEditor));
  };

  const toggleLoading = (value = !isLoading) => {
    dispatch(setIsLoadingAction(value));
  };

  const toggleHighlightMarked = (value = !highlightMarked) => {
    dispatch(setIsHighlightedAction(value));
  };

  const setIsEnhanced = (value: boolean) => {
    dispatch(setIsEnhancedAction(value));
  };

  const isSpellingGrammar = (action: AIAction | null) => action?.value === 'fixSpellingGrammar';

  const enhanceText = async (action: AIAction, userInput?: string) => {
    if (!textEditor.current) {
      // eslint-disable-next-line no-console
      console.warn('No text editor connected to TextEnhancerProvider');
      return;
    }

    // When custom prompt is selected, bring up the prompt modal
    if (action.value === 'custom' && !userInput) {
      toggleCustomPrompt(true);
      return;
    }

    toggleLoading(true);
    const previousAction = lastAIAction;
    dispatch(setAttemptEnhanceTextAction(action));
    trackEvent(`ai_prompt_${action.value}`, `AI Prompt ${action.value}`, 'Text Enhancer', {
      userInput
    });

    const selection = getUserSelection();
    const textBox = textEditor.current;

    // If nothing is selected, select the whole text box
    if (selection?.isCollapsed) {
      selectAllContent(textBox, selection);
    }

    // Save the current selection
    const savedSelection = saveSelection(selection!);

    let html = copySelectionAsHTML();
    if (isSpellingGrammar(previousAction)) {
      html = removeHighlightedTags(html);
    }

    html = removeEmptyElements(html);

    // Get the enhanced text from the API
    const response = await formatTextGPT({
      promptName: action.value,
      input: html,
      userInput,
      cancelToken: cancelToken.current?.token
    });

    // Handle errors and cancelations
    if (response.status !== 'success') {
      toggleLoading(false);

      // The user cancelled so we need a new cancel token
      if (response.status === 'cancel') {
        cancelToken.current = axios.CancelToken.source();
        return;
      }

      const error = (
        <ErrorOnEnhanceText
          text={response.message}
          handleClick={() => enhanceText(action, userInput)}
          tryAgain={action.value !== 'custom'}
        />
      );

      // Set error message and status
      dispatch(setFailedEnhanceTextAction(error));
      return;
    }

    // Set success message and status
    dispatch(setSuccessEnhanceTextAction(action));

    let sanitizedHTML = cleanHTML(response.data.formattedText);
    if (isSpellingGrammar(action)) {
      sanitizedHTML = markDifferences(html, sanitizedHTML);
    }

    // Restore selection and replace the text
    try {
      restoreSelection(selection!, savedSelection);
      replaceSelectionWithHTML(sanitizedHTML, textBox);

      // If the user is fixing spelling and grammar, deselect the text
      if (isSpellingGrammar(action)) {
        selection?.collapseToEnd();
      }

      setIsEnhanced(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }

    toggleLoading(false);
    return sanitizedHTML;
  };

  const handleCustomPrompt = async (userInput: string) => {
    const customAction = aiActions.find(action => action.value === 'custom');
    if (!customAction) return;

    const enhancedHTML = await enhanceText(customAction, userInput);
    return enhancedHTML;
  };

  const redoLastEnhance = async () => {
    if (!lastAIAction) return;

    const enhancedHTML = await enhanceText(lastAIAction);
    return enhancedHTML;
  };

  const cancelEnhanceText = () => {
    cancelToken.current?.cancel();
  };

  const value = {
    cancelEnhanceText,
    enhanceText,
    errorMessage,
    handleCustomPrompt,
    highlightMarked,
    isLoading,
    lastAIAction,
    redoLastEnhance,
    setTextEditor,
    showCustomPrompt,
    status,
    textEditor,
    toggleCustomPrompt,
    toggleHighlightMarked,
    isEnhanced
  };

  return <TextEnhancerContext.Provider value={value}>{children}</TextEnhancerContext.Provider>;
};

const useTextEnhancer = () => {
  const context = useContext(TextEnhancerContext);
  if (!context) {
    throw new Error('useTextEnhancer must be used within a TextEnhancerProvider');
  }
  return context;
};

export { TextEnhancerProvider, useTextEnhancer };
