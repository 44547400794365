import { DefaultTheme, ThemedStyledProps, css } from 'styled-components';

import { SizeVariant } from 'types';

export type TabStyleProps = {
  active?: boolean;
  appearance?: 'pill' | 'underlined' | 'boxed';
  size?: SizeVariant;
  bgColour?: 'inverted';
  iconPosition?: 'right';
  inverted?: boolean;
  hasTooltip?: boolean;
};

type StyleParams = ThemedStyledProps<TabStyleProps, DefaultTheme>;

const getTabStylingByAppearance = ({ active, appearance, inverted, theme }: StyleParams) => {
  const { colours } = theme;

  switch (appearance) {
    case 'pill':
      if (inverted) {
        return css`
          color: ${active ? colours.white : colours.inputsColour};

          ${active &&
          css`
            border-radius: 1.5rem;
            background: ${colours.fontColour};
          `}
        `;
      }

      return css`
        flex: 1;
        justify-content: center;
        padding: 0.25rem 0.5rem;

        ${active &&
        css`
          border-radius: 2rem;
          box-shadow: 0 0 1.5rem 0 var(--shadow-soft);
          background: var(--background-block-primary);
          color: var(--text-body-primary);
        `}
      `;
    case 'underlined':
      return css`
        padding: 0.5rem 0;

        ${Underline}
      `;
    default:
      break;
  }
};

const Underline = css<StyleParams>`
  ${({ active, size }) => {
    if (['large', 'medium'].includes(size!)) {
      return active
        ? css`
            box-shadow: inset 0 -4px 0 0 var(--border-tab-underline);
          `
        : css`
            &:hover {
              box-shadow: inset 0 -2px 0 0 var(--border-tab-underline);
            }
          `;
    }

    return active
      ? css`
          box-shadow: inset 0 -2px 0 0 var(--border-tab-underline);
        `
      : css`
          &:hover {
            box-shadow: inset 0 -1px 0 0 var(--border-tab-underline);
          }
        `;
  }}
`;

const getTabStylingBySize = ({ appearance, active, size, theme }: StyleParams) => {
  const { typography } = theme;

  switch (size) {
    case '2xsmall':
      return css`
        font-size: var(--type-xs);
      `;
    case 'xsmall':
      return css`
        gap: 0.25rem;
        font-size: ${typography.small};

        [class^='icon_'] {
          font-size: ${typography.normal};
        }

        ${appearance === 'pill'
          ? css`
              font-size: ${typography.normal};
              flex: 1;
              justify-content: center;
            `
          : css`
              padding: 0.25rem 0;
            `}
      `;
    case 'small':
      if (appearance === 'underlined') {
        return css`
          font-size: ${typography.normal};
          font-weight: ${active ? typography.bold : typography.regular};
        `;
      }
      return css`
        font-size: ${typography.normal};

        [class^='icon_'] {
          font-size: 1.25rem;
        }
      `;
    case 'large':
      if (appearance !== 'pill') {
        return css`
          font-size: var(--type-lg-xl);
          font-weight: ${active ? typography.bold : typography.regular};
        `;
      }
      return css`
        padding: 0.75rem 1rem;
      `;
    default:
      break;
  }
};

const getTabsWrapperStylingBySize = ({ size }: StyleParams) => {
  switch (size) {
    case 'xsmall':
      return css`
        gap: 0.75rem;
      `;
    default:
      break;
  }
};

const getTabsWrapperStylingByAppearance = ({
  appearance,
  inverted,
  theme: { darkMode, colours, fontColour, fn, media }
}: StyleParams) => {
  switch (appearance) {
    case 'pill':
      if (inverted) {
        return css`
          padding: 0.5rem;
          gap: 0.25rem;
          border-radius: 2rem;
          background: ${darkMode ? colours.darkBgColour : colours.white};
          box-shadow: 0 0.25rem 2rem 0 ${fn.opaqueColour(fontColour, 0.08)};
        `;
      }
      return css`
        align-items: flex-start;
        align-content: flex-start;
        align-self: stretch;
        white-space: nowrap;
        padding: 0;
        gap: 0.25rem;
        border-radius: 1.5rem;
        background: var(--background-tabs-container-default);

        ${media.mobile`
          overflow: visible;
        `}
      `;
    default:
      break;
  }
};

export const getTabStyling = (props: StyleParams) => {
  const { theme, active, hasTooltip } = props;
  const { typography } = theme;

  return css`
    &&.tab_item {
      padding: 0.5rem 0.75rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      color: var(--text-body-primary);
      font-weight: ${active ? typography.semibold : typography.regular};

      &.active {
        span[class^='icon_'] {
          ${({ theme: { fn } }) => fn.slightlyBolderIcon()}
        }
      }

      .badge {
        margin: 0;
      }
      /** JY: After release - when refactoring Tabs
        * Some stylings above might be added to override current stylings, which should be deleted.
      */

      ${getTabStylingByAppearance(props)}
      ${getTabStylingBySize(props)};
      ${hasTooltip &&
      css`
        position: relative;
        :hover .tooltip_wrapper {
          display: block;
        }
      `}
    }
  `;
};

export const getTabsWrapperStyling = (props: StyleParams) => {
  return css`
    margin: 0;
    gap: 1.5rem;

    ${({ theme: { media } }) => media.mobile`
      white-space: nowrap;
      overflow: scroll;
    `}

    ${getTabsWrapperStylingBySize(props)}
    ${getTabsWrapperStylingByAppearance(props)}
  `;
};
