import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { HiresCompany, LiveHiresSortBy } from '@cohiretech/common-types';

import useMedia from 'hooks/useMedia';
import { ImageResizeParam } from 'types';
import { getLiveHires } from 'fetcher';
import { addDimensionsOnImages, getImageDimensions } from 'v2/services/image';
import { isEmpty } from 'utils';
import { isMobileWidth, mediaQueries } from 'responsiveConfig';

import Button from 'components/button';
import Dropdown from 'components/dropdown';
import ErrorMessage from 'components/errormessage';

import SkeletonCompanyCards from 'v2/components/ui/molecules/SkeletonCompanyCards';
import CompanyCard, { CompaniesListStyling } from 'v2/components/ui/molecules/CompanyCard';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { getHiresCount, getCompanyCardProps } from './helpers';

const imageParams: ImageResizeParam[] = [
  { type: 'logo', dimensions: getImageDimensions('no-crop-logo') }
];

const numberOfCompaniesShownByMedia = isMobileWidth ? 3 : 5;

const HiresCompaniesSection = () => {
  const [companies, setCompanies] = useState<HiresCompany[]>([]);
  const [numberOfCompaniesShown, setNumberOfCompaniesShown] = useState(
    numberOfCompaniesShownByMedia
  );
  const [loading, setLoading] = useState(true);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [error, setError] = useState('');
  const [sortBy, setSortBy] = useState(LiveHiresSortBy.MostRecentHires);

  useEffect(() => {
    fetchLiveHires();
  }, []);

  const fetchLiveHires = async (sortByOption: LiveHiresSortBy = sortBy) => {
    setLoading(true);

    if (sortByOption !== sortBy) setSortBy(sortByOption);

    const res = await getLiveHires(sortByOption);

    if (res.status === 'success') {
      setCompanies(addDimensionsOnImages(res.data, imageParams));
    } else {
      setError(
        "We couldn't load this. Please try to refresh the page or report it at support@cord.co."
      );
    }

    setLoading(false);
  };

  const onShowMore = () => {
    setShowMoreLoading(true);
    setTimeout(() => {
      setNumberOfCompaniesShown(numberOfCompaniesShown + numberOfCompaniesShownByMedia);
      setShowMoreLoading(false);
    }, 300);
  };

  const displayShowMoreButton = companies.length > numberOfCompaniesShown;
  const isMobile = useMedia([mediaQueries.mobile]);

  return (
    <Wrapper>
      <HiresHeaderWrapper>
        <ConditionalRender predicate={!isEmpty(companies)}>
          <span>
            <strong>{getHiresCount(companies)} hires</strong> {!isMobile && 'in last 12 months'}
          </span>
        </ConditionalRender>
        <Dropdown
          id="hires_sort_by"
          className="small_arrow inline_dropdown"
          selected={sortBy}
          selectMessage="Sort by"
          list={[
            { label: 'Most recent hires', value: LiveHiresSortBy.MostRecentHires },
            { label: 'Most hires', value: LiveHiresSortBy.MostHires },
            { label: 'Lowest cph', value: LiveHiresSortBy.LowestCostPerHire }
          ]}
          onSelect={item => fetchLiveHires(item.value)}
        />
      </HiresHeaderWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <div className="companies_list">
        {loading ? (
          <SkeletonCompanyCards amount={numberOfCompaniesShown} />
        ) : (
          companies
            .slice(0, numberOfCompaniesShown)
            .map(company => <CompanyCard key={company.id} {...getCompanyCardProps(company)} />)
        )}
        {showMoreLoading && <SkeletonCompanyCards amount={numberOfCompaniesShownByMedia} />}
      </div>
      {displayShowMoreButton && (
        <Button
          text="Show more hires"
          icon="icon_add"
          buttonStyle="stroke_blue"
          className="show_more_button eighteen_px_font"
          action={onShowMore}
        />
      )}
    </Wrapper>
  );
};

export default HiresCompaniesSection;

const HiresHeaderWrapper = styled.div`
  padding: 0 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropdown_wrapper.inline_dropdown .dropdown_list {
    width: 10rem;
    right: 0;
  }
`;

const Wrapper = styled.div`
  .companies_list {
    ${CompaniesListStyling}
  }

  .show_more_button {
    margin-bottom: 3.25rem;

    ${({ theme: { media } }) => media.mobile`
      margin-bottom: 2.5rem;
    `}
  }
`;
