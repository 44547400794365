import React, { useEffect, useRef, useState } from 'react';

import { getRandomItemFromArray } from 'utils';

import ClickDetector from 'v2/components/utility/ClickDetector';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { useTextEnhancer } from '../../TextEnhancer.context';

import {
  CustomPromptContainer,
  IconWrapper,
  PromptIdea,
  TextArea,
  TextContainer
} from './CustomPrompt.styled';
import { PROMPT_IDEAS } from './CustomPrompt.helpers';

type CustomPromptProps = {
  placeholder: string;
};

const CustomPrompt = ({ placeholder }: CustomPromptProps) => {
  const [text, setText] = useState('');
  const [promptIdea] = useState(getRandomItemFromArray(PROMPT_IDEAS));
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { showCustomPrompt, toggleCustomPrompt, handleCustomPrompt } = useTextEnhancer();

  useEffect(() => {
    if (!textAreaRef.current) return;

    // Reset height to 20px to get the scrollHeight
    textAreaRef.current.style.height = '20px';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  }, [text, showCustomPrompt]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [showCustomPrompt]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { key, ctrlKey, metaKey } = event;
    if (key === 'Enter') {
      if (ctrlKey || metaKey) {
        return;
      }
      event.preventDefault();
      handleSubmit();
      return;
    }
    if (key === 'Escape') {
      toggleCustomPrompt(false);
    }
  };

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setText(value);
  };

  const handleSubmit = () => {
    handleCustomPrompt(text);
    toggleCustomPrompt(false);
  };

  return (
    <ConditionalRender predicate={showCustomPrompt}>
      <ClickDetector onClickOutside={() => toggleCustomPrompt()}>
        <CustomPromptContainer>
          <TextContainer>
            <IconWrapper>
              <span className="icon_edit" />
            </IconWrapper>
            <TextArea
              ref={textAreaRef}
              placeholder={placeholder}
              value={text}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
            />
            <IconWrapper>
              <button onClick={handleSubmit}>
                <span className="icon_send" />
              </button>
            </IconWrapper>
          </TextContainer>
          <PromptIdea>{promptIdea}</PromptIdea>
        </CustomPromptContainer>
      </ClickDetector>
    </ConditionalRender>
  );
};

export default CustomPrompt;
