import { getLocalStorageItem } from 'cookieManager';
import { AnyFunction, CompanyUser } from 'types';
import { noFilter } from 'utils/fn';

export type FeatureName = 'new_inbox' | 'smart_declines';

type FeatureMap = Record<
  FeatureName,
  {
    name: FeatureName;
    description: string;
    enabled: boolean;
    predicatefn: AnyFunction<boolean, any>;
  }
>;

const FEATURE_MAP: FeatureMap = {
  new_inbox: {
    name: 'new_inbox',
    description: 'Inbox Beta V1',
    enabled: true,
    predicatefn: noFilter
  },
  smart_declines: {
    name: 'smart_declines',
    description: 'Smart Decline Beta V1',
    enabled: true,
    predicatefn: noFilter
  }
};

const FEATURE_NAME_MAP: { [key in FeatureName]: string } = {
  new_inbox: 'inbox_beta_v1',
  smart_declines: 'smart_decline_beta_v1'
};

export const isFeatureOn = (featureName: FeatureName, preferences: CompanyUser['preferences']) => {
  if (preferences?.[featureName] !== undefined) return preferences[featureName];

  // This has been added as beta feature status(on/off) was initially controlled by local storage.
  const enabledLocally = getLocalStorageItem(FEATURE_NAME_MAP[featureName] || featureName);

  if (enabledLocally !== null) return enabledLocally;

  return true;
};

export const getFeature = (featureName: FeatureName) => FEATURE_MAP[featureName];

type Preferences = CompanyUser['preferences'];

type ConsiderPreferences =
  | { considerPreferences?: true; preferences: Preferences }
  | { considerPreferences: false; preferences?: Preferences };

type IsFeatureEnabledParams = { featureName: FeatureName; arg?: any } & ConsiderPreferences;

export const isFeatureEnabled = ({
  featureName,
  arg,
  considerPreferences = true,
  preferences
}: IsFeatureEnabledParams) => {
  if (considerPreferences && !isFeatureOn(featureName, preferences)) return false;

  return getFeature(featureName)?.enabled && getFeature(featureName).predicatefn(arg);
};
