import React from 'react';
import Helmet from 'react-helmet';

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;
const cordImage = process.env.REACT_APP_CORD_OG_IMAGE;
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export type Props = {
  title?: string;
  description?: string;
  path?: string;
  contentType?: string;
  image?: string;
  script?: { src: string }[];
  width?: string;
  height?: string;
  robots?: string;
  icon?: string;
};

const absoluteUrl = (path?: string) => `${domain}${path}`;

const getMetaTags = ({
  title,
  description,
  url,
  contentType,
  image,
  width,
  height,
  robots
}: Props & { url: string }) => {
  const metaTags = [
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    { itemprop: 'image', content: image || cordImage },
    { name: 'description', content: description },
    { property: 'og:title', content: `${title} - cord` },
    { property: 'og:type', content: contentType },
    { property: 'og:url', content: url },
    { property: 'og:description', content: description },
    { property: 'og:locale', content: 'en_EN' },
    { property: 'og:image', content: image || cordImage },
    { property: 'og:image:secure_url', content: image || cordImage },
    { property: 'og:image:width', content: width || '1200' },
    { property: 'og:image:height', content: height || '800' },
    { property: 'fb:app_id', content: facebookAppId },
    { name: 'twitter:site', content: '@corddotco' },
    { name: 'twitter:title', content: `${title} - cord` },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: '@corddotco' },
    { name: 'twitter:image:src', content: image || cordImage },
    { name: 'robots', content: robots || 'index, follow, nocache' }
  ];

  if (image) metaTags.push({ name: 'twitter:card', content: 'summary_large_image' });
  else metaTags.push({ name: 'twitter:card', content: 'summary' });

  return metaTags;
};

const getLinks = (path?: string, icon?: string) => {
  const link = [{ rel: 'canonical', href: absoluteUrl(path) }];

  if (icon) link.push({ rel: 'shortcut icon', href: icon });

  return link;
};

const Seo = ({
  title,
  description,
  path,
  contentType,
  image,
  script,
  width,
  height,
  robots,
  icon
}: Props) => (
  <Helmet
    defer={false}
    title={title}
    link={getLinks(path, icon)}
    meta={getMetaTags({
      title,
      description,
      contentType,
      url: absoluteUrl(path),
      image,
      width,
      height,
      robots
    })}
    script={script}
  />
);

export default Seo;
