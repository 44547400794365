import React, { useEffect, useState } from 'react';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { OperatingCountry, Frequency, StripeRegion } from '@cohiretech/common-types';

import { isTabletWidth } from 'responsiveConfig';
import {
  getPaymentSessionDetails,
  getCompanyReactivationPlan,
  getUserLocalisation,
  getPaymentCards,
  companyReactivateAccount
} from 'fetcher';
import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { PricingCountry } from 'types';
import { checkIfSubscriptionExpired, createStripeModule } from 'utils/companyUtils';
import { PRICING_OPTIONS, GENERIC_BOOK_DEMO_LINK } from 'consts';
import { isEmpty } from 'utils';

import ArrowLink from 'components/arrowlink';
import Badge from 'components/badge';
import Button from 'components/button';
import Tabs from 'components/tabs';
import PageSection from 'components/pagesection';
import PricingCard from 'components/pricingcard';
import PaymentMethodPopup from 'components/paymentmethodpopup';
import CompanyLogos from 'components/companylogos';
import Seo from 'components/seo';

import CodeYourFuture from 'images/codeyourfuture-coders.jpg';

import { getPlans, getEnterprisePlans, getReactivationPlans } from './pricingOptions';

import './style.scss';

const CAPTION = {
  monthly:
    'Built for Talent Teams and Hiring Managers who want flexibility. One flat monthly cost.',
  annual:
    'Built for Talent Teams and Hiring Managers who want predictability. One flat annual cost.'
};

const TABS = [
  { title: 'Monthly', value: 'monthly' },
  { title: ['Annual', <Badge key="discount" text="25% off" />], value: 'annual' }
];

type PricingOption = keyof typeof PRICING_OPTIONS;

export default function PricingPlans({
  showVideoSection,
  title,
  className,
  companySize,
  location = {},
  bottomCaption,
  smallBottomCaption = false,
  bottomButton,
  showCharitySection,
  showBookADemo,
  enterpriseOnly,
  isPopup,
  darkMode,
  currentPlanDetails,
  availablePlans,
  pricingCountry,
  previousPlan,
  ...otherProps
}: $TSFixMe) {
  const isSubscriptionExpired = checkIfSubscriptionExpired(currentPlanDetails?.plan);
  const localisedPricingType = location?.pathname?.includes('/us/')
    ? OperatingCountry.US
    : OperatingCountry.UK;

  const [currentTab, setCurrentTab] = useState(Frequency.monthly);
  const [pricingType, setPricingType] = useState<PricingCountry>(
    pricingCountry || localisedPricingType
  );

  const getVisiblePlans = (): any => {
    if (enterpriseOnly) return getEnterprisePlans(pricingType);
    if (isSubscriptionExpired) {
      return getReactivationPlans(previousPlan, availablePlans, pricingCountry || pricingType);
    }
    return getPlans(isPopup, companySize, pricingType);
  };

  const [plans, setPlans] = useState(getVisiblePlans());

  // States below are related to reactivation.
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PricingOption>();
  const [sessionID, setSessionID] = useState(null);
  const [stripeRegion, setStripeRegion] = useState(StripeRegion.UK);
  const [cards, setCards] = useState([]);
  const [showPaymentMethodPopup, setShowPaymentMethodPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(otherProps.errorMessage || null);
  const [popupErrorMessage, setPopupErrorMessage] = useState('');
  const [rightPlanRequested, setRightPlanRequested] = useState(false);
  const [annualPlanRequested, setAnnualPlanRequested] = useState(false);

  const getUserLocation = async () => {
    if (location?.pathname?.includes('/uk/') || location?.pathname?.includes('/us/')) return;

    const preferCountry = await getUserLocalisation();

    if (preferCountry && preferCountry !== OperatingCountry.EU && preferCountry !== pricingType) {
      setPricingType(preferCountry);
      setPlans(getVisiblePlans());
    }
  };

  useEffect(() => {
    if (!isSubscriptionExpired) getUserLocation();
    else {
      if (isEmpty(availablePlans)) setRecommendedPlan();
      const reactivationPlan = getLocalStorageItem('reactivationPlan');
      if (reactivationPlan) setSelectedPlan(reactivationPlan);
    }
  }, []);

  const setRecommendedPlan = async () => {
    const { subscriptionPricing } = await getCompanyReactivationPlan();
    const { previousSubscriptionPlan, availablePlans, pricingCountry } = subscriptionPricing;

    setPlans(getReactivationPlans(previousSubscriptionPlan, availablePlans, pricingCountry));
  };

  const proceedToPayment = async (
    sessionID: $TSFixMe,
    stripeRegion: StripeRegion,
    isPaymentMethodPopup: $TSFixMe
  ) => {
    const stripe = createStripeModule(stripeRegion);
    const { error } = await stripe.redirectToCheckout({ sessionId: sessionID });

    if (error) {
      if (isPaymentMethodPopup) setPopupErrorMessage(error.message);
      else setErrorMessage(error.message);
    }
  };

  const handleClickMonthlyPlan = async (urlPath: string, selected: PricingOption) => {
    const [paymentSessionData, cardsData] = await Promise.all([
      getPaymentSessionDetails(urlPath, pricingType, 'company/discover', 'reactivation'),
      getPaymentCards()
    ]);
    const newSessionID = paymentSessionData?.sessionID;
    const stripeRegion = paymentSessionData?.stripeRegion;
    const isReactivatingUSCompanyFromUKStripe =
      paymentSessionData?.isReactivatingUSCompanyFromUKStripe;

    if (!newSessionID || !stripeRegion || cardsData.status === 'failure') {
      setButtonLoading(false);
      setErrorMessage(!newSessionID ? paymentSessionData?.message : cardsData.message);
      return;
    }

    setSessionID(newSessionID);
    setStripeRegion(stripeRegion);
    setCards(cardsData);
    setButtonLoading(false);
    setLocalStorageItem('reactivationPlan', selected);

    if (cardsData.length === 0 || isReactivatingUSCompanyFromUKStripe) {
      proceedToPayment(newSessionID, stripeRegion, false);
    } else setShowPaymentMethodPopup(true);
  };

  const handleClickAnnualPlan = async (urlPath: string) => {
    await companyReactivateAccount(urlPath);

    setButtonLoading(false);
    setAnnualPlanRequested(true);
  };

  // Select a pricing plan to reactivate
  const handleClickPlan = async (selected: PricingOption) => {
    setButtonLoading(true);
    setSelectedPlan(selected);

    const urlPath = PRICING_OPTIONS[selected][pricingType][currentTab].urlPath;

    if (currentTab === 'monthly') await handleClickMonthlyPlan(urlPath, selected);
    else await handleClickAnnualPlan(urlPath);
  };

  const notifyTeam = async () => {
    setRightPlanRequested(true);
    await companyReactivateAccount();
  };

  const handleClosePopup = () => {
    setShowPaymentMethodPopup(false);
    setPopupErrorMessage('');
  };

  const getReactivateButtonProps = (planID: $TSFixMe, disabled: $TSFixMe) => {
    const requested = !!(currentTab === 'annual' && annualPlanRequested && selectedPlan === planID);

    return {
      action:
        disabled || requested ? null : (event: $TSFixMe) => handleClickPlan(event.currentTarget.id),
      loading: buttonLoading && selectedPlan === planID,
      ...(requested ? { disabled: true, text: 'Will be in touch' } : {})
    };
  };

  const getBottomCaption = () => {
    if (!isSubscriptionExpired) return bottomCaption;

    if (rightPlanRequested) {
      return 'The team has been informed and will be in touch shortly. Keep an eye on your emails.';
    }
    return [
      "Having trouble or don't see the right plan available? ",
      <button key="link" className="link" onClick={notifyTeam}>
        Notify us
      </button>
    ];
  };

  const PricingPlansCards = ({ plans, currentTab, darkMode }: $TSFixMe) => {
    return (
      <div className="three_cols_items">
        {plans.map((plan: $TSFixMe) => {
          const recommendedText = plan.recommended
            ? isSubscriptionExpired
              ? 'Your previous plan'
              : 'For companies your size'
            : '';
          const showErrorMessage = !!(
            plan.id === selectedPlan &&
            errorMessage &&
            currentTab === 'monthly'
          );

          if (isSubscriptionExpired) {
            plan.button[currentTab] = {
              ...plan.button[currentTab],
              ...getReactivateButtonProps(plan.id, plan.disabled)
            };
          }

          return (
            <PricingCard
              key={plan.title}
              className="three_cols_item"
              {...plan}
              darkMode={darkMode}
              currentTab={currentTab}
              recommendedText={recommendedText}
              {...(showErrorMessage ? { errorMessage } : {})}
            />
          );
        })}
      </div>
    );
  };

  const { showCompanyLogos } = otherProps;
  const isEarlyHiresShownAsOther =
    ((isPopup && !companySize) || !isPopup) &&
    currentTab === 'monthly' &&
    !isSubscriptionExpired &&
    !enterpriseOnly;
  const planDetails = selectedPlan && PRICING_OPTIONS[selectedPlan];
  const { newPrice = '', urlPath = '' } = planDetails?.[pricingType][currentTab] || {};

  return (
    <>
      <div
        className={ClassNames(className, 'center_alignment', { no_padding: isPopup })}
        id="plans"
      >
        <div className={isPopup ? '' : 'page_view'}>
          {title && <h1 className="heading">{title}</h1>}
          <Tabs
            className="pricing_tabs"
            current={currentTab}
            tabs={TABS}
            onChange={({ value }) => setCurrentTab(value as Frequency)}
          />
          {!isSubscriptionExpired && (
            <h2 className={isPopup ? 'pop_up_sub_title' : 'subheading'}>
              {enterpriseOnly
                ? 'For hiring teams who love cord for its flexibility. No hiring fees.'
                : CAPTION[currentTab]}
            </h2>
          )}
          <PricingPlansCards plans={plans} currentTab={currentTab} darkMode={darkMode} />
          {isEarlyHiresShownAsOther && (
            <div className="other_plan">
              Companies with 5 or less employees can{' '}
              <strong>claim a 50% early hires discount on the Start-up & Scale-up plan.</strong>{' '}
              <Link className="link" to="/request-demo?plan=Early%20Hires">
                Apply for discount
              </Link>
            </div>
          )}
          {(bottomCaption || isSubscriptionExpired) && (
            <h2
              className={ClassNames('subheading', 'bottom_caption', {
                small_text: isPopup || smallBottomCaption
              })}
            >
              {getBottomCaption()}
            </h2>
          )}
          {bottomButton && (
            <Button
              text={bottomButton.text}
              buttonStyle="stroke_blue"
              className="twenty_px_font"
              link={bottomButton.link}
            />
          )}
          {showCompanyLogos && <CompanyLogos logoHeight="30" preferredCountry={pricingType} />}
        </div>
      </div>
      {showBookADemo && !isTabletWidth && (
        <div className="center_alignment">
          <iframe
            title="Book a Demo"
            src={`${GENERIC_BOOK_DEMO_LINK}?embed=true`}
            width="800"
            height="700"
          />
          <script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          />
        </div>
      )}
      {showVideoSection && (
        <div className="page_view">
          <iframe
            title="ROI video"
            className="full_width_video"
            width="100%"
            src="https://www.youtube.com/embed/O43VQWKCCCw"
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
      {showCharitySection && (
        <PageSection
          className={ClassNames('charity_cont', {
            grey_background: !darkMode,
            dark_blue_background: darkMode
          })}
          widthClassName="page_view"
        >
          <div className="left_cont">
            <h2 className="small_page_section_title">
              Don't pay hiring fees, give money to charity
            </h2>
            <h3 className="page_section_subtitle">
              We don't charge hiring fees when someone gets hired. In fact, we do the opposite. We
              give money to charity on your behalf. For every person hired through cord, we donate a
              week's worth of coding to someone from an underprivileged background via
              CodeYourFuture.
            </h3>
            <ArrowLink to="/partners/2-codeyourfuture">See our partnership</ArrowLink>
          </div>
          <div className="right_cont">
            <img width="100%" src={CodeYourFuture} alt="CodeYourFuture" loading="lazy" />
          </div>
        </PageSection>
      )}
      {showPaymentMethodPopup && (
        <PaymentMethodPopup
          planInfo={{ label: planDetails!.label, price: newPrice, urlPath }}
          cycle={currentTab}
          pricingType={pricingType}
          cards={cards}
          handleClose={handleClosePopup}
          proceedToPayment={() => {
            proceedToPayment(sessionID, stripeRegion, true);
          }}
          errorMessage={popupErrorMessage}
          setErrorMessage={errorMessage => setPopupErrorMessage(errorMessage)}
        />
      )}
      <Seo script={[{ src: 'https://js.stripe.com/v3/' }]} />
    </>
  );
}
