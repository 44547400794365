import React, { useEffect, useRef, useState } from 'react';

import { PromptText, PromptWrapper } from './Menu.styled';

type CyclingTextProps = {
  strings: string[];
};

const ELLIPSIS_ANIMATION_DURATION = 4000;
const FADE_OUT_DURATION = 200;

const CyclingText = ({ strings }: CyclingTextProps) => {
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [fadingOut, setFadingOut] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const changeText = () => {
      setFadingOut(true);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setCurrentStringIndex(prevIndex => (prevIndex + 1) % strings.length);
        setFadingOut(false);
      }, FADE_OUT_DURATION);
    };

    const interval = setInterval(changeText, ELLIPSIS_ANIMATION_DURATION);

    return () => {
      clearInterval(interval);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [strings]);

  return (
    <PromptWrapper>
      <PromptText
        fadingOut={fadingOut}
        fadeDuration={FADE_OUT_DURATION / 1000}
        ellipsisDuration={ELLIPSIS_ANIMATION_DURATION / 1000}
      >
        {strings[currentStringIndex]}
      </PromptText>
    </PromptWrapper>
  );
};

export default CyclingText;
