import {
  CandidateSelfBuildStatus as CSBS,
  CandidateSignupVerificationStatus as CSVS,
  CandidateFullProfileDTO,
  UserType
} from '@cohiretech/common-types';

import { CandidateProfile, KeyStatusBeforeLive } from 'types';
import { isEmpty } from 'utils';

import { extractMonthYear } from '../common';

import { decodeCandidateExperience, decodeCandidateExperienceForCandidate } from './experiences';

export type CandidateProfileKey = keyof CandidateProfile;

export const MANDATORY_PROFILE_INFO_FIELDS = {
  firstName: 'First name & Last name',
  headline: 'headline',
  location: 'current location',
  originalCVURL: 'CV'
};

export const MANDATORY_FIELDS: { [key in CandidateProfileKey]?: string } = {
  ...MANDATORY_PROFILE_INFO_FIELDS,
  jobTitles: 'job titles',
  seniorities: 'seniority level',
  primarySkills: 'core skills',
  skillPreferences: 'skill preferences',
  experiences: 'at least 1 experience',
  languages: 'at least 1 language'
};

export const isFilledIn = (profile: CandidateProfile, field: CandidateProfileKey) => {
  switch (field) {
    case 'firstName':
      return profile.firstName && profile.lastName;
    case 'languages':
      return profile.languages.find(({ name, code, proficiency }) => name && code && proficiency);
    default:
      return !isEmpty(profile[field]);
  }
};

export const getMissingFields = (profile: CandidateProfile, mandatoryFields = MANDATORY_FIELDS) =>
  (Object.keys(mandatoryFields) as CandidateProfileKey[])
    .filter(field => !isFilledIn(profile, field))
    .map(field => MANDATORY_FIELDS[field]!);

export const getLocationLabel = (profile: CandidateProfile) => {
  if (profile.locationCity) return profile.locationCity;

  return profile.location?.label || '';
};

export const getStatusBeforeLive = (profile?: CandidateProfile | null): KeyStatusBeforeLive => {
  if (profile) {
    const { accepted, verified, rejected, allowEdit } = profile;

    if (!accepted && !verified && !rejected && !allowEdit) return CSVS.Pending;
    if (rejected && !allowEdit) return CSVS.Rejected;
    if (accepted && !verified && !allowEdit) return CSVS.Accepted;
  }
};

export const shouldShowSelfBuild = (profile?: CandidateProfile) => {
  const status = getStatusBeforeLive(profile);

  if (status && status !== CSVS.Rejected) {
    const { selfBuildStatus } = profile!;

    return selfBuildStatus === CSBS.Inactive || selfBuildStatus === CSBS.Active;
  }
};

export const decodeProfileForCandidate = (
  {
    expectedSalaryMin,
    jobTitles,
    primarySkills,
    secondarySkills,
    behanceURL,
    websiteURL,
    linkedinURL,
    githubURL,
    stackURL,
    educations,
    occupations,
    achievements,
    projects,
    publications,
    invisible,
    ...data
  }: CandidateFullProfileDTO,
  profileType: UserType
) => {
  return {
    profileType,
    isCandidate: profileType === UserType.Candidate,
    salaryMin: expectedSalaryMin,
    jobTitles: jobTitles || [],
    primarySkills: primarySkills || [],
    secondarySkills: secondarySkills || [],
    linkedin: linkedinURL,
    github: githubURL,
    stackOverflow: stackURL,
    website: websiteURL,
    behance: behanceURL,
    visible: !invisible,
    educations: educations.map(e => {
      const startYear = extractMonthYear(e.startDate).year;
      const endYear = extractMonthYear(e.endDate).year;

      return {
        id: e.ceID,
        institution: e.institution,
        subject: e.subject,
        grade: e.grade,
        startYear,
        endYear,
        description: e.summary
      };
    }),
    experiences: occupations.map(decodeCandidateExperienceForCandidate),
    projects: projects.map(p => {
      const { month: startMonth, year: startYear } = extractMonthYear(p.startDate);
      const { month: endMonth, year: endYear } = extractMonthYear(p.endDate);

      return {
        id: p.id,
        title: p.title,
        url: p.url,
        description: p.description,
        current: p.current,
        startMonth,
        startYear,
        endMonth,
        endYear
      };
    }),
    awards: achievements.map(a => {
      const { month, year } = extractMonthYear(a.achieveDate);

      return {
        id: a.id,
        title: a.title,
        description: a.description,
        issuer: a.issuer,
        month,
        year
      };
    }),
    publications: publications.map(p => {
      const { month, year } = extractMonthYear(p.publishDate);

      return {
        id: p.id,
        title: p.title,
        publisher: p.publisher,
        url: p.url,
        authors: p.authors,
        description: p.description,
        month,
        year
      };
    }),
    ...data
  };
};

export const decodeProfile = (
  {
    firstname,
    lastname,
    photo_url,
    relocate_locations,
    job_type,
    exp_salary_min,
    job_titles,
    primary_skills,
    secondary_skills,
    behance_url,
    website_url,
    linkedin_url,
    github_url,
    stack_url,
    education,
    occupation,
    achievement,
    project,
    publication,
    invisible,
    phone_number,
    original_cv_url,
    avg_response,
    last_active,
    applied_listing,
    location_city,
    company_funding_stages,
    company_industries,
    company_sizes,
    mfa_enabled,
    template_variables,
    ...data
  }: $TSFixMe,
  profileType: UserType
) => {
  return {
    profileType,
    isCandidate: profileType === UserType.Candidate,
    firstName: firstname,
    lastName: lastname,
    photoURL: photo_url,
    relocateLocations:
      relocate_locations &&
      relocate_locations.map((location: $TSFixMe) => location.replace(' (require visa)', '')),
    jobType: job_type,
    salaryMin: exp_salary_min,
    jobTitles: job_titles || [],
    primarySkills: primary_skills || [],
    secondarySkills: secondary_skills || [],
    phoneNumber: phone_number,
    linkedin: linkedin_url,
    github: github_url,
    stackOverflow: stack_url,
    website: website_url,
    behance: behance_url,
    locationCity: location_city,
    companyFundingStages: company_funding_stages,
    companyIndustries: company_industries,
    companySizes: company_sizes,
    mfaEnabled: mfa_enabled,
    educations: education.map((e: $TSFixMe) => {
      const startYear = extractMonthYear(e.start_date || e.startDate).year;
      const endYear = extractMonthYear(e.end_date || e.endDate).year;

      return {
        id: e.ceID,
        institution: e.institution,
        subject: e.subject,
        grade: e.grade,
        startYear,
        endYear,
        description: e.summary
      };
    }),
    experiences: occupation?.[0]?.startDate
      ? occupation.map(decodeCandidateExperienceForCandidate)
      : occupation.map(decodeCandidateExperience),
    projects: project.map((p: $TSFixMe) => {
      const { month: startMonth, year: startYear } = extractMonthYear(p.start_date || p.startDate);
      const { month: endMonth, year: endYear } = extractMonthYear(p.end_date || p.endDate);

      return {
        id: p.id,
        title: p.title,
        url: p.url,
        description: p.description,
        current: p.current,
        startMonth,
        startYear,
        endMonth,
        endYear
      };
    }),
    awards: achievement.map((a: $TSFixMe) => {
      const { month, year } = extractMonthYear(a.achieve_date || a.achieveDate);

      return {
        id: a.id,
        title: a.title,
        description: a.description,
        issuer: a.issuer,
        month,
        year
      };
    }),
    publications: publication.map((p: $TSFixMe) => {
      const { month, year } = extractMonthYear(p.publish_date || p.publishDate);

      return {
        id: p.id,
        title: p.title,
        publisher: p.publisher,
        url: p.url,
        authors: p.authors,
        description: p.description,
        month,
        year
      };
    }),
    ...(applied_listing && {
      appliedListing: applied_listing.map((a: $TSFixMe) => {
        return {
          applicationID: a.application_id,
          companyDate: a.company_date,
          companyName: a.company_name,
          candidateDate: a.candidate_date,
          companyInterested: a.company_interested,
          candidateInterested: a.candidate_interested,
          listingID: a.listing_id,
          companyContact: a.company_contact,
          companyContactDate: a.company_contact_date,
          companyPrematchMessage: a.company_prematch_message,
          candidateDeclineMessage: a.candidate_decline_message,
          candidatePrematchMessage: a.candidate_prematch_message,
          candidateContact: a.candidate_contact,
          candidateContactDate: a.candidate_contact_date,
          conversationArchived: a.conversation_archived,
          position: a.position,
          messageHistory: a.messageHistory
        };
      })
    }),
    visible: !invisible,
    originalCVURL: original_cv_url,
    avgResponseTime: avg_response,
    lastActive: last_active,
    templateVariables: template_variables,
    ...data
  };
};
