import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Location } from 'history';

import { mediaQueries } from 'responsiveConfig';
import { NavigationLink } from 'types';
import { NAV_MAP } from 'consts';
import { isAbsoluteURL } from 'utils/url';
import useMedia from 'hooks/useMedia';

import Accordion from 'components/accordion';
import Button from 'components/button';
import HamburgerMenu from 'components/hamburgermenu';
import HeaderLink from 'components/headerlink';
import NavigationPanel from 'components/navigationbubble';
import MenuModal from 'components/menumodal';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

import CTAButton from '../ctabutton';
import SigninLink from '../signinlink';

type NavigationProps = {
  location: Location;
  darkMode: boolean;
  isUserLoggedin: boolean;
};

const LandingNavigation = ({ location, darkMode, isUserLoggedin }: NavigationProps) => {
  const showHamburgerMenu = useMedia([mediaQueries.tablet]);

  return showHamburgerMenu ? (
    <MobileLandingNavigation darkMode={darkMode} isUserLoggedin={isUserLoggedin} />
  ) : (
    <DesktopLandingNavigation
      location={location}
      darkMode={darkMode}
      isUserLoggedin={isUserLoggedin}
    />
  );
};

const DesktopLandingNavigation = ({ location, darkMode, isUserLoggedin }: NavigationProps) => {
  const [open, setOpen] = useState<string>();
  const isTablet = useMedia([mediaQueries.tabletLandscape]);

  const toggleOpen = (category: string) => () => {
    if (open === category) {
      setOpen(undefined);
    } else {
      setOpen(category);
    }
  };

  return (
    <>
      {Object.keys(NAV_MAP).map(category => {
        const { links, visibleOnDesktop } = NAV_MAP[category as keyof typeof NAV_MAP];
        const isOpen = open === category;
        const mainLink = links.find(link => link.main);

        return (
          <ConditionalRender predicate={visibleOnDesktop} key={category}>
            <span onMouseEnter={() => setOpen(category)} onMouseLeave={() => setOpen(undefined)}>
              <ConditionalRender
                predicate={isTablet}
                fallback={
                  <HeaderLink to={mainLink?.path} className="public_menu_item">
                    {category}
                  </HeaderLink>
                }
              >
                <HeaderLink onTouchStart={toggleOpen(category)} className="public_menu_item">
                  {category}
                </HeaderLink>
              </ConditionalRender>
              <NavigationPanel
                links={links}
                open={isOpen}
                darkMode={darkMode}
                location={location}
                handleClick={toggleOpen(category)}
              />
            </span>
          </ConditionalRender>
        );
      })}
      <ConditionalRender predicate={!isUserLoggedin}>
        <SigninLink />
      </ConditionalRender>
    </>
  );
};

const MobileLandingNavigation = ({
  darkMode,
  isUserLoggedin
}: Pick<NavigationProps, 'darkMode' | 'isUserLoggedin'>) => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  return (
    <>
      <HamburgerMenu isOpen={isHamburgerMenuOpen} onClick={toggleHamburgerMenu} />
      <MenuModal visible={isHamburgerMenuOpen} darkMode={darkMode}>
        <MenuWrapper>
          {Object.keys(NAV_MAP).map(category => {
            const { links } = NAV_MAP[category as keyof typeof NAV_MAP];

            return (
              <Accordion
                key={category}
                id={category}
                title={category}
                description={<AccordionNavLinks links={links} handleClick={toggleHamburgerMenu} />}
              />
            );
          })}
          <ConditionalRender predicate={!isUserLoggedin}>
            <SigninLink handleClick={toggleHamburgerMenu} />
          </ConditionalRender>
          <ButtonWrapper>
            <CTAButton handleClick={toggleHamburgerMenu} />
            <Button
              text="Contact us"
              className="eighteen_px_font"
              link="/contact"
              buttonStyle={darkMode ? 'ghost_dark_blue' : 'fill_white'}
              action={toggleHamburgerMenu}
            />
          </ButtonWrapper>
        </MenuWrapper>
      </MenuModal>
    </>
  );
};

type AccordionNavLinksProps = {
  links: NavigationLink[];
  handleClick: () => void;
};

const AccordionNavLinks = ({ links, handleClick }: AccordionNavLinksProps) => (
  <>
    {links.map(link => (
      <ConditionalRender
        key={link.label}
        predicate={isAbsoluteURL(link.path)}
        fallback={
          <Link to={link.path} className="lightblue_text" onClick={handleClick}>
            {link.label}
          </Link>
        }
      >
        <a href={link.path} className="lightblue_text">
          {link.label}
        </a>
      </ConditionalRender>
    ))}
  </>
);

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 16px;

  > * {
    flex-grow: 1;
  }

  @media ${mediaQueries.mobile} {
    flex-direction: column;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  color: ${colours.primaryColour};

  .accordion {
    border: none;
  }

  a.lightblue_text {
    display: block;
    font-weight: ${typography.regular};
    line-height: 32px;
    color: ${colours.primaryColour};
    margin: 6px 0;
  }
`;

export default LandingNavigation;
