import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { useNavigatablePositionsStore } from 'store/navigatablePositions';
import { applyExternalListing } from 'v2/services/fetchers/candidate/application';
import { getPosition } from 'fetcher';
import { PositionMetadata } from 'types';
import { useFlagPositionIssuePopup } from 'hooks/candidate/useFlagPositionIssuePopup';

import DialogWindow, { DialogWindowProps } from 'components/dialogwindow';
import { usePositionProfile } from 'components/positionprofile/PositionProfile.context';

import ConditionalRender from '../utility/ConditionalRender';

import {
  useMarkApplicationPopup,
  useMarkApplicationPopupSubtitle
} from './MarkApplicationPopup.hooks';

type MarkApplicationPopupProps = Pick<PositionMetadata, 'id' | 'companyID'>;

function MarkApplicationPopup(props: MarkApplicationPopupProps) {
  const [loading, setLoading] = useState(false);
  const [skipMarkApplicationPrompt, setSkipMarkApplicationPrompt] = useState(false);
  const [showPositionStatusCheck, setShowPositionStatusCheck] = useState(false);

  const { show = false, type, hideMarkApplicationPopup } = useMarkApplicationPopup();
  const subtitle = useMarkApplicationPopupSubtitle(props.id);
  const { cachedNavigatablePositionsMetadata, cacheNavigatablePosition } =
    useNavigatablePositionsStore();
  const { id } = usePositionProfile();
  const flagPositionIssuePopupProps = useFlagPositionIssuePopup('mark_application_prompt', () =>
    hideMarkApplicationPopup(skipMarkApplicationPrompt)
  );

  const markAsApplied = async () => {
    setLoading(true);

    const {
      id: listingID,
      companyID,
      ui,
      search_position: searchPosition
    } = cachedNavigatablePositionsMetadata[id] || props;

    const { status } = await applyExternalListing({ listingID, companyID, ui, searchPosition });

    if (status === 'success') {
      const updatedPosition = await getPosition(listingID, true, ui, searchPosition, false);
      cacheNavigatablePosition(updatedPosition);
      hideMarkApplicationPopup(skipMarkApplicationPrompt);
    }

    setLoading(false);
  };

  const conditionalProps = useMemo<Omit<DialogWindowProps, 'visible'>>(() => {
    const markAsAppliedBtn = {
      text: 'Yes, mark as applied',
      buttonStyle: 'fill_cord_gradient',
      action: markAsApplied
    };

    if (type === 'confirmation') {
      return { primaryButton: markAsAppliedBtn, skipLink: { text: 'Cancel' } };
    }

    return {
      primaryButton: {
        text: "No, I haven't applied",
        buttonStyle: 'stroke_cord_gradient',
        action: () => setShowPositionStatusCheck(true)
      },
      secondaryButton: markAsAppliedBtn,
      checkbox: {
        checkboxID: 'skip_mark_application_prompt',
        label: "Don't show this again",
        checked: skipMarkApplicationPrompt,
        onChange: setSkipMarkApplicationPrompt
      }
    };
  }, [type, skipMarkApplicationPrompt]);

  const popupProps = showPositionStatusCheck
    ? flagPositionIssuePopupProps
    : {
        id: 'mark_application_popup',
        title: 'Applied to this position?',
        subTitle: subtitle,
        visible: show,
        loading,
        showCloseIcon: true,
        onClose: () => hideMarkApplicationPopup(skipMarkApplicationPrompt),
        ...conditionalProps
      };

  return <MarkAsAppliedPopup {...popupProps} />;
}

export default function MarkApplicationPopupWrapper(props: MarkApplicationPopupProps) {
  const params = useParams<{ id: string }>();
  const { show = false } = useMarkApplicationPopup();

  return (
    <ConditionalRender predicate={show && props.id === Number(params.id)}>
      <MarkApplicationPopup {...props} />
    </ConditionalRender>
  );
}

const MarkAsAppliedPopup = styled(DialogWindow)`
  &#mark_application_popup {
    .pop_up_title {
      margin-bottom: 0.25rem;
      line-height: normal;
    }

    .pop_up_sub_title {
      margin: 0.25rem auto 1.5rem;
    }

    .checkbox_wrapper {
      margin-top: 1rem;
    }

    .button.fill_cord_gradient {
      padding: 0.75rem 1.25rem;
    }

    ${({ theme: { media } }) => media.mobileMin`
      .pop_up_dialog {
        width: 544px;
      }

      .pop_up_title {
        font-size: var(--type-3xl);
      }
    `}
  }
`;
