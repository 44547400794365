import { PropertyChain } from 'types';

import { isString } from './typeGuards';

/**
 * Returns the value associated with a map path, or a fallback value if the path is not found.
 *
 * @template T - The type of the value associated with the map path.
 * @param {{ [key: string]: any }} obj - The map to traverse.
 * @param {(string | number)[] | string} path - An array of string/number segments, or a string of . separated segments representing the path to traverse. Segments that are not strings or numbers will be ignored.
 * @param {T} fallbackValue - The value to return if the path is not found, defaults to undefined.
 * @returns {T} - The value associated with the map path, or the fallback value if the path is not found.
 */
export const path = <T>(
  obj: Record<string, any>,
  path: PropertyChain,
  fallbackValue?: T
): T | undefined => {
  let current = obj;
  let segments: (string | number)[];

  if (isString(path)) {
    segments = path.split('.').map(parseInts);
  } else {
    segments = path;
  }

  for (const segment of segments) {
    if (current?.[segment] !== undefined) {
      current = current[segment];
    } else {
      return fallbackValue;
    }
  }

  if (current === undefined) return current as undefined;
  return current as T;
};

export const ensureArrayForm = (path: PropertyChain): (string | number)[] => {
  if (typeof path === 'string') {
    if (path === '') return [];
    return path.split('.');
  } else {
    return path;
  }
};

export const ensureStringForm = (path: PropertyChain): string => {
  if (typeof path === 'string') {
    return path;
  } else {
    return path.join('.');
  }
};

const parseInts = (segment: string | number): string | number => {
  if (isString(segment) && isValidPositiveInteger(segment)) {
    return Number(segment);
  }
  return segment;
};

const isValidPositiveInteger = (value: string): boolean => {
  const regExp = /^[1-9]\d*$/;
  return regExp.test(value);
};
