import React from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { mobile } from 'responsiveConfig';
import { isEmpty } from 'utils';

import SelectableButton from 'components/selectablebutton';

import FieldErrorMessage from 'v2/components/ui/molecules/FieldErrorMessage';
import { StyledNote } from 'v2/components/ui/styles/FieldValidation';

type OptionValue = any;

export type SelectQuestionProps = {
  showQuestion?: boolean;
  question?: string | $TSFixMe[];
  options: {
    label?: string;
    value?: OptionValue;
  }[];
  columns?: number;
  mobileColumns?: number;
  toggle: (selection: OptionValue) => void;
  multiple?: boolean;
  optional?: boolean;
  maxSelection?: number;
  restrictionLabel?: string;
  description?: string;
  selected: $TSFixMe[] | string | number | boolean | undefined;
  showOptionsDescription?: boolean;
  bottomNote?: string;
  noneSelectedMessage?: string;
  formSubmitted?: boolean;
};

type Props = SelectQuestionProps & typeof SelectQuestion.defaultProps;

type mobileColumnsProps = Pick<SelectQuestionProps, 'mobileColumns'>;

type WrapperProps = mobileColumnsProps;
type FormButtonProps = mobileColumnsProps;

class SelectQuestion extends React.Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = { showQuestion: true };

  constructor(props: Props) {
    super(props);

    this.isOptionSelected = this.isOptionSelected.bind(this);
  }

  getColumnsClassName(columns: $TSFixMe) {
    switch (columns) {
      case 2:
        return 'two_cols';
      case 3:
        return 'three_cols';
      case 4:
        return 'four_cols';
      case 5:
        return 'five_cols';
      case 6:
        return 'six_cols';
      default:
        return '';
    }
  }

  isValueSelected(value: $TSFixMe) {
    const { selected } = this.props;

    if (Array.isArray(value)) {
      const existingValues = value.filter(
        v => (selected as $TSFixMe).findIndex((s: $TSFixMe) => s === v) > -1
      );

      return existingValues.length > 0;
    } else {
      return (selected as $TSFixMe).includes(value);
    }
  }

  showLongLabelTooltip(label: $TSFixMe) {
    const { columns } = this.props;
    const threeColsMaxVisibleCharacters =
      window.innerWidth < 900 && window.innerWidth > 650 ? 30 : 33;
    const fourColsMaxVisibleCharacters =
      window.innerWidth < 900 && window.innerWidth > 650 ? 25 : 28;

    if (
      (columns === 3 && label.length > threeColsMaxVisibleCharacters) ||
      (columns === 4 && label.length > fourColsMaxVisibleCharacters)
    ) {
      return label;
    }
  }

  isOptionSelected(value: $TSFixMe) {
    const { multiple, selected } = this.props;

    return multiple ? this.isValueSelected(value) : selected === value;
  }

  renderSelectOptions(className: $TSFixMe) {
    const {
      options,
      toggle,
      // @ts-expect-error TS(2339) FIXME: Property 'disableSelection' does not exist on type... Remove this comment to see the full error message
      disableSelection,
      showOptionsDescription = false,
      mobileColumns = 1,
      // @ts-expect-error TS(2339) FIXME: Property 't' does not exist on type 'Readonly<Prop... Remove this comment to see the full error message
      t,
      selected,
      noneSelectedMessage,
      formSubmitted
    } = this.props;

    return (
      <div className="select_options">
        {showOptionsDescription
          ? options.map(({ label, value, description, tooltip, disabled }: $TSFixMe) => (
              <Wrapper
                key={value}
                mobileColumns={mobileColumns}
                className={ClassNames('select_option_wrapper', className)}
              >
                <SelectableButton
                  tooltip={tooltip}
                  disabled={disabled || (disableSelection && !this.isOptionSelected(value))}
                  formInput
                  className={className}
                  selected={this.isOptionSelected(value)}
                  onClick={() => toggle(value)}
                >
                  {t(label)}
                </SelectableButton>
                {description && <Desc className="select_option_desc">{description}</Desc>}
              </Wrapper>
            ))
          : options.map(
              ({
                label,
                value,
                disabled,
                tooltip = this.showLongLabelTooltip(label)
              }: $TSFixMe) => (
                <FormButton
                  mobileColumns={mobileColumns}
                  key={label}
                  disabled={disabled || (disableSelection && !this.isOptionSelected(value))}
                  tooltip={tooltip}
                  formInput
                  className={className}
                  selected={this.isOptionSelected(value)}
                  onClick={() => toggle(value)}
                >
                  {t(label)}
                </FormButton>
              )
            )}
        <FieldErrorMessage
          show={formSubmitted && isEmpty(selected) && !!noneSelectedMessage}
          message={noneSelectedMessage}
          noMarginTop
        />
      </div>
    );
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'fieldID' does not exist on type 'Readonl... Remove this comment to see the full error message
      fieldID,
      showQuestion,
      question,
      columns = 2,
      multiple = false,
      optional = false,
      maxSelection = 1,
      restrictionLabel,
      description,
      bottomNote,
      // @ts-expect-error TS(2339) FIXME: Property 'isSignup' does not exist on type 'Readon... Remove this comment to see the full error message
      isSignup,
      selected,
      noneSelectedMessage,
      formSubmitted
    } = this.props;

    const invalidInput = Boolean(formSubmitted && isEmpty(selected) && noneSelectedMessage);
    const className = this.getColumnsClassName(columns);

    if (showQuestion) {
      return (
        <div id={fieldID} className={ClassNames({ field: !isSignup })}>
          <label
            aria-label={`${Array.isArray(question) ? question![0] : question}`}
            className="question"
          >
            {question}
            {optional && <span>(optional)</span>}
            {description && <span className="description">{description}</span>}
            {multiple && (
              <StyledNote className="note" invalidInput={invalidInput}>
                Choose {maxSelection === 1 ? 'multiple' : `up to ${maxSelection}`}
                {restrictionLabel ? ` ${restrictionLabel}` : ''}
              </StyledNote>
            )}
          </label>
          {this.renderSelectOptions(className)}
          {bottomNote && <BottomNote>{bottomNote}</BottomNote>}
        </div>
      );
    } else {
      return this.renderSelectOptions(className);
    }
  }
}

export const FormInput = css`
  margin: 5px 0 10px 15px;

  &.six_cols {
    width: calc((100% - 75px) / 6);

    &:nth-child(6n + 1) {
      margin-left: 0;
    }

    @media only screen and (max-width: ${mobile}) {
      width: calc((100% - 24px) / 3);
      margin: 4px 0 8px 12px;

      &:nth-child(6n + 1) {
        margin: 4px 0 8px 12px;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
  }

  &.five_cols {
    width: calc((100% - 60px) / 5);

    &:nth-child(5n + 1) {
      margin-left: 0;
    }

    @media only screen and (max-width: ${mobile}) {
      width: calc((100% - 12px) / 2);
      margin: 4px 0 8px 12px;

      &:nth-child(5n + 1) {
        margin: 4px 0 8px 12px;
      }

      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }
  }

  &.four_cols {
    width: calc((100% - 45px) / 4);

    &:nth-child(4n + 1) {
      margin-left: 0;
    }

    @media only screen and (max-width: ${mobile}) {
      width: calc((100% - 12px) / 2);
      margin: 4px 0 8px 12px;

      &:nth-child(4n + 1) {
        margin: 4px 0 8px 12px;
      }

      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }
  }

  &.three_cols {
    width: calc((100% - 30px) / 3);

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    ${props =>
      props && (props as $TSFixMe).mobileColumns === 2
        ? css`
            @media only screen and (max-width: ${mobile}) {
              width: calc((100% - 15px) / 2);
              margin: 4px 0 8px 12px;

              &:nth-child(3n + 1) {
                margin-left: 12px;
              }

              &:nth-child(2n + 1) {
                margin-left: 0;
              }
            }
          `
        : css`
            @media only screen and (max-width: ${mobile}) {
              width: 100%;
              display: block;
              margin-left: 0;
              margin-right: 0;
            }
          `}
  }

  &.two_cols {
    width: calc((100% - 15px) / 2);

    &:nth-child(2n + 1) {
      margin-left: 0;
    }

    @media only screen and (max-width: ${mobile}) {
      width: calc((100% - 12px) / 2);
      margin: 4px 0 8px 12px;
    }
  }
`;

const FormButton = styled(SelectableButton)<FormButtonProps>`
  ${FormInput}
`;

const Wrapper = styled.div<WrapperProps>`
  display: inline-block;
  text-align: center;

  .form_input {
    display: block;
    width: 100% !important;
    margin: 0 !important;
  }

  ${FormInput}
`;

const Desc = styled.div`
  margin: 15px 0;
  font-size: 14px;
`;

const BottomNote = styled.div`
  font-weight: ${({ theme: { typography } }) => typography.regular};
  font-style: italic;
  font-size: 14px;
  margin: 5px 0 10px;
`;

export default withTranslation()(SelectQuestion);
