import { ReactNode } from 'react';

import { APIResponse, PricingCountry, SpecialEnquiryFormType } from 'types';
import { applyFounderProgram } from 'fetcher';
import {
  applyToSanFrancisco,
  initSanFranciscoEnquiry
} from 'v2/services/fetchers/public/sanFranciscoApplication';
import { SAN_FRAN_ONBOARD_LINK } from 'consts';

import { CheckboxProps } from 'components/checkbox';
import { Props as ButtonProps } from 'components/button';

type FormKeys = keyof SpecialEnquiryFormType;

export type FieldItem = { key: FormKeys; label: string; placeholder: string };

export type FormInfo = {
  title?: string;
  fieldGroups: FieldItem[][];
  checkbox?: Omit<CheckboxProps, 'onChange'>;
  buttonText: string;
  onApply: (form: SpecialEnquiryFormType) => Promise<APIResponse>;
  onInit?: (form: SpecialEnquiryFormType) => Promise<APIResponse>;
  getConfirmationProps: (props: ConfirmationProps) => {
    title: string;
    description: ReactNode;
    button?: ButtonProps;
  };
};
export type ConfirmationProps = Partial<{
  pricingType: PricingCountry;
}>;

export type FormType = 'standard' | 'san-fran';

const STANDARD_INFO = {
  buttonText: 'Apply for Founder Program',
  checkbox: {
    checkboxID: 'confirm_eligibility',
    label: 'I confirm that my company has less than 25 employees'
  },
  onApply: applyFounderProgram,
  getConfirmationProps: ({ pricingType }: ConfirmationProps) => ({
    title: 'Your application has been submitted',
    description: (
      <span>
        A member of our team will now review your application and come back to you within 48 hours.
        <br />
        In the meantime, you can book your demo below.
      </span>
    ),
    button: {
      text: 'Book a demo',
      icon: 'icon_demo',
      link: { pathname: '/request-demo', state: { from: '/founder-program', pricingType } },
      className: 'twenty_px_font'
    }
  })
};

const STANDARD_FIELD_GROUPS: FieldItem[][] = [
  [
    { key: 'firstName', label: 'First name', placeholder: 'Eg. Mario' },
    { key: 'lastName', label: 'Last name', placeholder: 'Eg. Johnson' }
  ],
  [{ key: 'email', label: 'Work email address', placeholder: 'Eg. mario@cord.co' }],
  [{ key: 'jobTitle', label: 'Job title', placeholder: 'Eg. CEO' }],
  [
    { key: 'companyName', label: 'Company name', placeholder: 'Eg. cord' },
    { key: 'companyWebsite', label: 'Company website', placeholder: 'Eg. www.cord.co' }
  ]
];

const SAN_FRAN_INFO = {
  title: 'Apply for free access',
  buttonText: 'Submit',
  onApply: applyToSanFrancisco,
  onInit: initSanFranciscoEnquiry,
  getConfirmationProps: () => ({
    title: 'Access requested!',
    description: (
      <span>
        Your account is being built and you’ll receive login details shortly. Book an onboard to get
        access faster
      </span>
    ),
    button: {
      text: 'Book a demo',
      icon: 'icon_demo',
      action: () => {
        window.open(SAN_FRAN_ONBOARD_LINK, '_blank');
      },
      className: 'twenty_px_font'
    }
  })
};

const SAN_FRAN_FIELD_GROUPS: FieldItem[][] = [
  [{ key: 'firstName', label: 'First name', placeholder: 'Eg. Mario' }],
  [{ key: 'email', label: 'Work email address', placeholder: 'Eg. mario@cord.co' }],
  [{ key: 'jobTitle', label: 'Job title', placeholder: 'Eg. CEO' }]
];

const FORM_MAPPER: Record<FormType, FormInfo> = {
  standard: { fieldGroups: STANDARD_FIELD_GROUPS, ...STANDARD_INFO },
  'san-fran': { fieldGroups: SAN_FRAN_FIELD_GROUPS, ...SAN_FRAN_INFO }
};

export const getFormInfo = (formType: FormType): FormInfo => {
  return FORM_MAPPER[formType] || FORM_MAPPER.standard;
};

export const getFieldGroupKeys = (fieldGroup: FieldItem[][]): FormKeys[] =>
  fieldGroup.flatMap(group => group.map(({ key }) => key));

const VALIDATION_MESSAGES: Record<FormKeys, string> = {
  firstName: 'Please enter your first name.',
  lastName: 'Please enter your last name.',
  email: 'Please enter your email address.',
  jobTitle: 'Please enter your job title.',
  companyName: "Please enter your company's name.",
  companyWebsite: "Please enter your company's website.",
  confirm: 'Please confirm that your company is eligible for the Founder Program.'
};

export const validate = (form: SpecialEnquiryFormType, keys: FormKeys[]) => {
  for (const key of keys) {
    if (!form[key]) return VALIDATION_MESSAGES[key] || `Please enter your ${key}.`;
  }
};
