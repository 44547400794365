import {
  PricingSubscriptionPlan,
  PricingCountryDetails,
  Frequency,
  PricingMode
} from '@cohiretech/common-types';

import { V4PricingOptions, PricingCountry } from 'types';
import { CHECKOUT_URL_PATHS, V4_GROWTH_PRICE } from 'consts';

type Plan = {
  urlPath: string;
  mode?: PricingMode;
  enquired?: boolean;
} & Partial<PricingCountryDetails>;

export type GrowthPlan = { [key in Frequency]: Plan };

const getDefaultGrowthPlan = (country: PricingCountry, hasDiscount?: boolean) => {
  const growthPlan = {} as GrowthPlan;
  const { price, discountedPrice } = V4_GROWTH_PRICE[country];

  Object.values(Frequency).forEach(frequency => {
    growthPlan[frequency] = {
      price: frequency === Frequency.monthly ? price : discountedPrice, // WIP: Should be updated when building annual plans on V3 pricing.
      hasDiscount,
      discountedPrice,
      urlPath: CHECKOUT_URL_PATHS[V4PricingOptions.V4Growth]
    };
  });

  return growthPlan;
};

export const getUpdatedGrowthPlan = (
  country: PricingCountry,
  availablePlans?: PricingSubscriptionPlan[],
  hasDiscount?: boolean
) => {
  const growthPlan = getDefaultGrowthPlan(country, hasDiscount);
  const { pricing } =
    availablePlans?.find(({ subscriptionPlan: { plan } }) => plan === V4PricingOptions.V4Growth) ||
    {};

  if (pricing) {
    const { urlPath, mode, billingFrequency } = pricing;
    const { price, hasDiscount, discountedPrice } = pricing[country] || {};

    growthPlan[billingFrequency] = {
      price,
      hasDiscount,
      discountedPrice,
      urlPath,
      mode
    };
  }

  return growthPlan;
};
