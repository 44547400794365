import { useEffect } from 'react';

import { useAfterMount } from 'hooks/useAfterMount';
import {
  setCustomCSSVariable as setCustomCSSVariableAction,
  setToggler,
  useAppDispatch,
  useAppSelector
} from 'store';
import { isArray, isString, isUndefined } from 'utils/fn';
import { UserProfileType } from 'types';

import { TogglerRoute } from './ui.consts';
import {
  selectShowReactivationModal,
  selectInitialSetupMode,
  selectShowStartUsingCordPopup,
  selectSetupMode,
  selectToggler,
  selectCustomCSSVariables
} from './ui.selectors';
import { toggleReactivationModal, toggleStartUsingCordPopup } from './ui.thunks';

export const useShowReactivationModal = (): [boolean, ToggleReactivationModal] => {
  const showReactivationModal = useAppSelector(selectShowReactivationModal);
  const dispatch = useAppDispatch();

  const setShowReactivationModal = (show?: boolean, ctaDetails?: { source: string }) => {
    dispatch(toggleReactivationModal({ show, ctaDetails }));
  };

  return [showReactivationModal, setShowReactivationModal];
};

export const useSetupMode = () => {
  const setupMode = useAppSelector(selectSetupMode);
  const initialSetupMode = useAppSelector(selectInitialSetupMode);

  return {
    setupMode,
    initialSetupMode,
    isSetupMode: setupMode || initialSetupMode
  };
};

export type ToggleReactivationModal = (show?: boolean, ctaDetails?: { source: string }) => void;

export const useStartUsingCordPopup = (): [boolean, ToggleStartUsingCordPopup] => {
  const showStartUsingCordPopup = useAppSelector(selectShowStartUsingCordPopup);
  const dispatch = useAppDispatch();

  const setShowStartUsingCordPopup = (show?: boolean, ctaDetails?: { source: string }) => {
    dispatch(toggleStartUsingCordPopup({ show, ctaDetails }));
  };

  return [showStartUsingCordPopup, setShowStartUsingCordPopup];
};

export type ToggleStartUsingCordPopup = (show?: boolean, ctaDetails?: { source: string }) => void;

// A toggle connected to the state, that can be used for anything while developing
export const useDevToggle = () => {
  return useAppSelector(selectToggler('dev.devToggle'));
};

type UseTogglerProps = {
  route: TogglerRoute;
  initial?: boolean;
  onFalse?: (value?: boolean) => void;
  onTrue?: (value?: boolean) => void;
};

const selectRoute = (route: TogglerRoute | UseTogglerProps) => {
  if (isRoute(route)) {
    return route;
  }
  return route.route;
};

const isRoute = (maybeRoute: TogglerRoute | UseTogglerProps): maybeRoute is TogglerRoute => {
  return isString(maybeRoute) || isArray(maybeRoute);
};

export const useToggler = (togglerRoute: TogglerRoute | UseTogglerProps) => {
  const route = selectRoute(togglerRoute);

  const dispatch = useAppDispatch();
  const togglerValue = useAppSelector(selectToggler(route));

  const toggle = (value?: boolean) => {
    dispatch(setToggler({ route, value }));
  };

  useEffect(() => {
    if (isRoute(togglerRoute) || !togglerRoute?.initial) return;
    toggle(togglerRoute.initial);
  }, []);

  useAfterMount(() => {
    if (isUndefined(togglerValue) || isRoute(togglerRoute)) return;

    if (togglerValue) {
      togglerRoute.onTrue?.(togglerValue);
    } else if (!togglerValue) {
      togglerRoute.onFalse?.(togglerValue);
    }
  }, [togglerValue]);

  return {
    value: togglerValue,
    toggle
  };
};

export const useCustomCSSVariables = () => {
  const cssVariables = useAppSelector(selectCustomCSSVariables);
  const dispatch = useAppDispatch();
  const setCustomCSSVariable = (name: string, value: string, type: UserProfileType = null) => {
    dispatch(setCustomCSSVariableAction({ name, value, type }));
  };

  return [cssVariables, setCustomCSSVariable] as const;
};
