import React from 'react';
import ClassNames from 'classnames';
import InputRange, { InputRangeProps } from 'react-input-range';
import styled, { css } from 'styled-components';

import { SizeVariant } from 'types';
import { HIGHEST_SALARY_BAND_VALUE } from 'fetcher';
import { getIsDarkMode_FIXME } from 'cookieManager';
import { noop } from 'utils/fn';
import { formatNumber } from 'utils';

import 'react-input-range/lib/css/index.css';

import Badge from 'components/badge';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

type StyledProps = {
  showValueLabel?: boolean;
  sliderSize?: Extract<SizeVariant, 'large'>;
  colour?: 'secondary';
  labelPosition?: 'top';
  hideActiveTrack?: boolean;
  hideMaxLabel?: boolean;
  minLabelIcon?: string;
};

export type RangeFilterProps = {
  range: InputRangeProps['value'];
  filterName: string;
  onSelect: (min: number, max?: number) => void;
  selectOnChange?: boolean;
  prefix?: string;
  suffix?: string;
  type?: 'remote_days' | 'salary' | 'timezone' | 'radius';
  showRange?: boolean;
  showHeader?: boolean;
  showNewBadge?: boolean;
  className?: string;
  getLabel?: (value: InputRangeProps['value']) => InputRangeProps['value'] | string;
  disabled?: boolean;
  showMarkers?: boolean;
  helpTooltip?: React.ReactNode;
  expandable?: boolean;
  styledProps?: StyledProps;
} & Required<Pick<InputRangeProps, 'minValue' | 'maxValue' | 'step'>>;

type State = $TSFixMe;

export default class RangeFilter extends React.Component<RangeFilterProps, State> {
  setRange: $TSFixMe;

  constructor(props: RangeFilterProps) {
    super(props);

    this.state = {
      showRange: props.showRange || false,
      range: props.range ?? { min: '', max: '' }
    };
  }

  showRange() {
    this.setState((prevState: $TSFixMe) => ({
      showRange: !prevState.showRange
    }));
  }

  componentDidMount() {
    const { range } = this.props;

    this.setState({ range });
  }

  componentDidUpdate(prevProps: RangeFilterProps) {
    const { range } = this.props;

    if (prevProps.range !== range && range !== this.state.range) this.setState({ range });
  }

  getLabel(value: $TSFixMe) {
    const { prefix, type, suffix } = this.props;

    if (type === 'timezone' && !prefix && !suffix) return `UTC ${value}:00`;
    if (type === 'salary') {
      const formattedSalary = formatNumber(value, true);
      const isHighestSalary = value === HIGHEST_SALARY_BAND_VALUE;
      return `${prefix || ''}${formattedSalary}${(!isHighestSalary && suffix) || ''}`;
    }

    switch (value) {
      case 0:
        if (type === 'remote_days') return 'Onsite';
        return `${prefix || ''}${value}${suffix || ''}`;
      case 1:
        if (type === 'remote_days') return '1 day remote';
        return `${prefix || ''}${value}${suffix || ''}`;
      case 5:
        if (type === 'remote_days') return '100% Remote';
        return `${prefix || ''}${value}${suffix || ''}`;
      default:
        return `${prefix || ''}${value}${suffix || ''}`;
    }
  }

  selectValue(value: InputRangeProps['value'], isOnChange?: boolean) {
    const { onSelect } = this.props;

    if (isOnChange) this.setState({ range: value });

    if (typeof value === 'number') {
      onSelect(value);
    } else {
      onSelect(value.min, value.max);
    }
  }

  generateMarks() {
    const { minValue, maxValue, step = 10, getLabel } = this.props;
    const { range } = this.state;
    const min = minValue ?? range.min;
    const max = maxValue || range.max;
    const markers = [];

    let mark = min + step;

    while (mark < max) {
      markers.push(
        <span key={mark} className="range_marker">
          {getLabel?.(mark)}
        </span>
      );
      mark += step;
    }

    return markers;
  }

  render() {
    const {
      minValue,
      maxValue,
      step,
      filterName,
      type,
      helpTooltip,
      showHeader,
      showNewBadge,
      className,
      selectOnChange,
      getLabel,
      disabled,
      showMarkers,
      expandable = true
    } = this.props;
    const { showRange, range } = this.state;
    const { hideMaxLabel, minLabelIcon, ...styledProps } = this.props.styledProps || {};

    const darkMode = getIsDarkMode_FIXME();

    const minLabel = getLabel?.(minValue) ?? this.getLabel(range.min ?? range);
    const maxLabel = getLabel?.(maxValue) || this.getLabel(range.max);
    const markers = showMarkers ? this.generateMarks() : [];

    return (
      <RangeInput className={ClassNames(className, 'range_input')} disabled={disabled}>
        {showHeader && (
          <div
            className={ClassNames('header', { expanded: showRange })}
            onClick={expandable ? () => this.showRange() : noop}
          >
            {filterName}
            {helpTooltip && <span className="icon_help">{helpTooltip}</span>}
            <ConditionalRender predicate={showNewBadge}>
              <Badge text="New" />
            </ConditionalRender>
          </div>
        )}
        <FilterCont className={ClassNames('filter_content', { show: showRange })}>
          <Range className={ClassNames('range', type, { dark: darkMode })} {...styledProps}>
            <div className="input_range_wrapper">
              <InputRange
                formatLabel={value => this.getLabel(value)}
                minValue={minValue}
                maxValue={maxValue}
                value={range}
                onChange={
                  selectOnChange
                    ? value => this.selectValue(value, true)
                    : value => this.setState({ range: value })
                }
                onChangeComplete={value => this.selectValue(value, false)}
                step={step || 10}
                disabled={disabled}
              />
              {showMarkers && (
                <Markers className="markers" markersCount={markers.length}>
                  {markers.map(marker => marker)}
                </Markers>
              )}
            </div>
            <RangeLabelCont className="range_label_container">
              {range.min === range.max && range.min === 5 ? (
                ''
              ) : (
                <ValueLabel className="min_value">
                  {minLabel === 'Onsite' ? (
                    <Checkbox
                      className={ClassNames('fake_checkbox', { ticked: range.min === range.max })}
                      onClick={
                        range.min === range.max
                          ? () => this.setRange({ min: 0, max: 5 })
                          : () => this.setRange({ min: 0, max: 0 })
                      }
                    >
                      {minLabel}
                    </Checkbox>
                  ) : (
                    <>
                      <ConditionalRender predicate={minLabelIcon}>
                        <span className={minLabelIcon} />
                      </ConditionalRender>
                      {minLabel}
                    </>
                  )}
                </ValueLabel>
              )}
              {range.min === range.max && range.max === 0 ? (
                ''
              ) : (
                <ValueLabel className={ClassNames('max_value', { display_none: hideMaxLabel })}>
                  {maxLabel === '100% Remote' ? (
                    <Checkbox
                      className={ClassNames('fake_checkbox', { ticked: range.min === range.max })}
                      onClick={
                        range.min === range.max
                          ? () => this.setRange({ min: 0, max: 5 })
                          : () => this.setRange({ min: 5, max: 5 })
                      }
                    >
                      {maxLabel}
                    </Checkbox>
                  ) : (
                    maxLabel
                  )}
                </ValueLabel>
              )}
            </RangeLabelCont>
          </Range>
        </FilterCont>
      </RangeInput>
    );
  }
}

const RangeInput = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;

      .header .icon_help:hover .tooltip_wrapper {
        display: none;
      }

      &,
      .header .icon_help,
      [class^='input-range'] {
        cursor: not-allowed;
      }

      .input-range.input-range--disabled .input-range__slider:hover {
        transform: none;
      }
    `}
`;

const markersPositionsStyles = (markersCount: number) => {
  let styles = '';

  for (let i = 1; i <= markersCount; i++) {
    styles += `
      &:nth-child(${i}) {
        left: ${(100 / (markersCount + 1)) * i}%;
      }
    `;
  }

  return css`
    ${styles}
  `;
};

const Markers = styled.span<{ markersCount: number }>`
  .range_marker {
    position: absolute;
    top: 0;
    opacity: 0.2;
    font-size: 14px;
    display: inline-block;
    z-index: 0;

    ${props => markersPositionsStyles(props?.markersCount)}

    &:after {
      position: absolute;
      content: '';
      display: inline-block;
      height: 0.4rem;
      width: 2px;
      background: ${colours.inputsColour};
      bottom: -15px;
      left: 50%;
    }
  }
`;

const getSliderWidth = (sliderSize: StyledProps['sliderSize']) =>
  sliderSize === 'large' ? '1rem' : '0.8rem';

const getInputRangeStylingByColour = (colour: StyledProps['colour']) => {
  if (colour === 'secondary') {
    return css`
      .input-range__slider {
        background: var(--text-accent-secondary-base);
        border: 2px solid var(--text-accent-secondary-base);
      }

      .input-range__track--background {
        background: var(--background-accent-secondary-soft);
      }

      .input-range__track--active {
        background: var(--background-accent-secondary-base);
      }
    `;
  }

  return css`
    .input-range__slider {
      background: var(--text-body-brand);
      border: 2px solid var(--text-body-brand);
    }

    .input-range__track--background {
      background: var(--background-accent-primary-soft);
    }

    .input-range__track--active {
      background: var(--background-accent-primary-base);
    }
  `;
};

const INPUT_RANGE_TRACK_HEIGHT = '0.4rem';
export const INPUT_RANGE_SLIDER_MARGIN_LEFT = '-0.5rem';

const Range = styled.div<StyledProps>`
  ${({
    showValueLabel,
    sliderSize,
    labelPosition,
    hideActiveTrack,
    colour,
    theme: { colours, typography }
  }) => {
    const sliderWidth = getSliderWidth(sliderSize);

    return css`
      width: calc(100% - 15px);
      margin: auto;
      padding: 10px 0 0;
      position: relative;
      display: flex;
      flex-direction: ${labelPosition === 'top' ? 'column-reverse' : 'column'};
      gap: calc(${`0.75rem - (${sliderWidth} - ${INPUT_RANGE_TRACK_HEIGHT}) / 2`});

      .input-range {
        height: 1.15rem;
        ${getInputRangeStylingByColour(colour)};

        .input-range__label {
          display: none;

          ${showValueLabel &&
          css`
            &.input-range__label--value {
              display: block;
              top: calc(${getSliderWidth(sliderSize)} / 2);
              color: ${colours.primaryColour};
              font-family: ${typography.fontFamily};
              font-weight: ${typography.bold};
            }
          `}
        }

        .input-range__label-container {
          left: -0.3rem;
        }

        .input-range__slider {
          height: ${sliderWidth};
          width: ${sliderWidth};
          z-index: 1;
          margin-left: -0.15rem;
          margin-top: -0.8rem;

          &:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
          }
        }

        .input-range__track {
          height: ${INPUT_RANGE_TRACK_HEIGHT};
          margin-right: 0.5rem;
        }

        .input-range__track--active {
          z-index: 1;
          ${hideActiveTrack && 'opacity: 0;'}
        }
      }
    `;
  }}
`;

const FilterCont = styled.div`
  max-height: 1000vh;
  opacity: 1;
  transition: all 0.3s ease-in-out;

  .search_input {
    margin: 10px 0 5px;
  }

  &:not(.show) {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
`;

const RangeLabelCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ValueLabel = styled.span`
  &:not(.display_none) {
    display: flex;
  }

  align-items: flex-end;
  font-size: 13px;
  line-height: 1;

  [class^='icon_'] {
    margin-right: 0.5rem;
  }
`;

const Checkbox = styled.span`
  color: ${colours.primaryColour};
`;
