/* eslint-disable no-console */
import axios from 'axios';

import { APIResponse, PositionInProfile } from 'types';

const COMPANY_PV_LISTINGS = process.env.REACT_APP_COMPANY_POSITION_VIEW_LISTINGS!;
const COMPANY_PV_APPLICATIONS = process.env.REACT_APP_COMPANY_POSITION_VIEW_APPLICATIONS!;
const COMPANY_PV_STREAMS = process.env.REACT_APP_COMPANY_POSITION_VIEW_STREAMS!;
const COMPANY_PV_CONVERSATIONS_GRAPH =
  process.env.REACT_APP_COMPANY_POSITION_VIEW_CONVERSATIONS_GRAPH!;

export type UserScope = 'user' | 'other' | 'all';

export type PositionViewListing = {
  position: PositionInProfile['position'];
  internalLabel: PositionInProfile['internalLabel'];
  listingID: PositionInProfile['id'];
  locationCity: PositionInProfile['locationCity'];
  photoURLs: string[];
  remote: PositionInProfile['remote'];
  remoteLocationCountries: PositionInProfile['remoteLocationCountries'];
  remoteLocationContinents: PositionInProfile['remoteLocationContinents'];
  searchHidden: PositionInProfile['searchHidden'];
  status: PositionInProfile['status'];
  companyAccounts:
    | {
        name: string;
        photoURL: string;
        companyAccountID: string;
      }[]
    | null;
};

type ListingID = PositionInProfile['id'];

export type PositionViewListingActivity = {
  listingID: ListingID;
  receivedCount: number;
  pendingReceivedCount: number;
  outboundCount: number;
  pendingOutboundCount: number;
  hiredCount: number;
  conversationCount: number;
  unseenConversationCount: number;
  nonArchivedConversationCount: number;
};

export type PositionViewStream = {
  id: number;
  searchName: string;
  associated: boolean;
};

export type ConversationGraphDatapoint = {
  conversationCount: number;
  week: Date;
};

export type PositionViewStreams = Record<ListingID, PositionViewStream[]>;

export type PositionViewConversationsGraph = Record<ListingID, ConversationGraphDatapoint[]>;

export type PositionViewListings = { total: number; values: PositionViewListing[] };

type ListingsResponse = APIResponse<PositionViewListings>;

type FetchParams = Partial<{
  userScope: UserScope;
  page: number;
  status: 'open' | 'closed';
  listingIDs: number[];
  searchIDs: number[];
}>;

type UseParams<K extends keyof FetchParams> = Required<Pick<FetchParams, K>> & Partial<FetchParams>;

export const getCompanyPositionViewListings = async ({
  userScope,
  page,
  status
}: UseParams<'userScope' | 'page' | 'status'>): Promise<ListingsResponse> => {
  try {
    const { data } = await axios.get<ListingsResponse>(`${COMPANY_PV_LISTINGS}`, {
      params: { userScope, page, status }
    });

    if (!data.data) throw new Error('No data returned');

    return { status: 'success', data: data.data };
  } catch (error: any) {
    console.warn(error);
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

type ApplicationsResponse = APIResponse<PositionViewListingActivity[]>;

export const getCompanyPositionViewApplications = async ({
  listingIDs
}: UseParams<'listingIDs'>): Promise<ApplicationsResponse> => {
  try {
    const { data } = await axios.get<ApplicationsResponse>(`${COMPANY_PV_APPLICATIONS}`, {
      params: { listingIDs }
    });
    if (!data.data) throw new Error('No data returned');

    return { status: 'success', data: data.data };
  } catch (error: any) {
    console.warn(error);
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

type StreamsResponse = APIResponse<PositionViewStreams>;

export const getCompanyPositionViewStreams = async ({
  listingIDs
}: UseParams<'listingIDs'>): Promise<StreamsResponse> => {
  try {
    const { data } = await axios.get<StreamsResponse>(`${COMPANY_PV_STREAMS}`, {
      params: { listingIDs }
    });
    if (!data.data) throw new Error('No data returned');

    return { status: 'success', data: data.data };
  } catch (error: any) {
    console.warn(error);
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

type ConversationsGraphResponse = APIResponse<PositionViewConversationsGraph>;

export const getCompanyPositionViewConversationsGraph = async ({
  listingIDs
}: UseParams<'listingIDs'>): Promise<ConversationsGraphResponse> => {
  try {
    const { data } = await axios.get<ConversationsGraphResponse>(
      `${COMPANY_PV_CONVERSATIONS_GRAPH}`,
      {
        params: { listingIDs }
      }
    );
    if (!data.data) throw new Error('No data returned');

    return { status: 'success', data: data.data };
  } catch (error: any) {
    console.warn(error);
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export type CandidatesInStreamCount = Record<
  number,
  {
    candidateCount: number;
    newCandidateCount: number;
  }
>;

type CandidatesInStreamCountResponse = APIResponse<CandidatesInStreamCount>;

export const getCandidatesInStreamCount = async ({
  searchIDs
}: UseParams<'searchIDs'>): Promise<CandidatesInStreamCountResponse> => {
  try {
    const { data } = await axios.get<CandidatesInStreamCountResponse>(
      `${COMPANY_PV_LISTINGS}/saved-search/candidates`,
      { params: { searchIDs } }
    );
    if (!data.data) throw new Error('No data returned');

    return { status: 'success', data: data.data };
  } catch (error: any) {
    console.warn(error);
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
