import React from 'react';
import styled from 'styled-components';

import { getPercentage } from 'utils';

import FitScoreLevel from 'v2/components/FitScoreLevel';

import { InsightsBlock } from '../InsightsBlock';
import { usePositionProfile } from '../PositionProfile.context';

const SUB_TITLE = 'Profile match score breakdown';
const DEFAULT_TITLE = 'Identify gaps in your profile to improve your chances of success';

export const MatchScoreBreakdownBlock = () => {
  const { insightsData } = usePositionProfile();

  const fitScoreData = insightsData.candidate?.matchScore;

  return (
    <InsightsBlock
      size="large"
      subTitle={SUB_TITLE}
      title={DEFAULT_TITLE}
      defaultTitle={DEFAULT_TITLE}
    >
      <FitScoreBreakdown>
        <FitScoreLevel
          data={fitScoreData?.levelOneMetaData}
          overallPercentage={getPercentage(fitScoreData?.levelOneScore)}
          title="Logistics"
          weightPoints={35}
          type="cordGradient"
          hideToolTip
        />
        <FitScoreLevel
          data={fitScoreData?.levelTwoMetaData}
          overallPercentage={getPercentage(fitScoreData?.levelTwoScore)}
          title="Experience"
          weightPoints={55}
          type="cordGradient"
          hideToolTip
        />
        <FitScoreLevel
          data={fitScoreData?.levelThreeMetaData}
          overallPercentage={getPercentage(fitScoreData?.levelThreeScore)}
          title="Interests"
          weightPoints={10}
          type="cordGradient"
          hideToolTip
        />
      </FitScoreBreakdown>
    </InsightsBlock>
  );
};

const FitScoreBreakdown = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  flex: 1 0 0;
  align-self: stretch;
`;
