import { CompanyAccountHideLevel } from '@cohiretech/common-types';

import { CompanySearchOtherTerms } from 'types';
import {
  selectCandidatePool,
  selectCompanyUser,
  selectDemoCompany,
  selectIsNextResultsLoadEnabled,
  selectShowReactivationModal
} from 'store';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { getLocalStorageItem } from 'cookieManager';
import { advanceSearchCandidates, companySearchFilters } from 'fetcher';
import { getCandidateSearchCount } from 'v2/services/fetchers/company/searchCount';
import { toggleCandidateVisibility as hideOrUnhideCandidate } from 'v2/services/company/streams';

import { SearchParams, ToggleCandidateVisibilityThunkParams } from './discover.types';
import { updateQueryParams } from './discover.helpers';

export const searchCandidates = createAppAsyncThunk(
  'discover/searchCandidates',
  async (arg: SearchParams, { getState }) => {
    const { history, searchItems, streamID, streamName, isStreamModifying } = arg;
    const state = getState();
    const { passiveSearch } = state.discover;
    const shouldUpdateQueryParams = isStreamModifying ?? !passiveSearch;
    const candidatePool = selectCandidatePool(state);

    if (shouldUpdateQueryParams) updateQueryParams(history, searchItems);

    const results = await advanceSearchCandidates({
      searchTerms: searchItems,
      otherTerms: { page: 0, passive: passiveSearch },
      requestID: streamID ? `stream_${streamID}_request` : '',
      streamName,
      streamID
    });

    results.searchResultsCounts = {
      resultCount: results.results_count,
      activeCount: !candidatePool ? results.results_count : 0,
      passiveCount: candidatePool === 'passive' ? results.results_count : 0,
      totalCount: candidatePool === 'all' ? results.results_count : 0
    };

    return results;
  }
);

export const getSearchResultCounts = createAppAsyncThunk(
  'discover/getSearchResultCounts',
  async (arg: Omit<SearchParams, 'history'>, { getState }) => {
    const { searchItems, streamID, streamName, candidateStatus } = arg;
    const state = getState();
    const { passiveSearch } = state.discover;
    const filtersWithoutCandidatePool = searchItems.filter(
      ({ attribute }) => attribute !== companySearchFilters.candidatePool.attribute
    ); // remove candidate pool filter to always get all counts for search

    const countResults = await getCandidateSearchCount({
      searchTerms: filtersWithoutCandidatePool,
      otherTerms: {
        page: 0,
        passive: passiveSearch,
        ...(candidateStatus && { candidate_status: candidateStatus })
      },
      requestID: streamID ? `stream_${streamID}_count_request` : '',
      streamName,
      streamID
    });

    return countResults.data;
  }
);

export const getNextCandidateResults = createAppAsyncThunk.withTypes<{
  fulfilledMeta: { shouldBeEndPage: boolean };
}>()(
  'discover/getNextCandidateResults',
  async (streamID: number | undefined, { getState, fulfillWithValue }) => {
    const state = getState();
    const demoCompany = selectDemoCompany(state);
    const showReactivationBlock = selectShowReactivationModal(state);

    const { searchType, searchItems, nextPage, uniqueIdentifier } = state.discover;

    const otherTerms: CompanySearchOtherTerms = { page: nextPage, ui: uniqueIdentifier };
    if (searchType === 'stream') otherTerms.passive = false;

    const { values } = await advanceSearchCandidates({
      searchTerms: searchItems,
      otherTerms,
      requestID: streamID ? `stream_${streamID}_request` : '',
      streamID
    });

    return fulfillWithValue(values, { shouldBeEndPage: demoCompany || showReactivationBlock });
  },
  {
    condition: (_, { getState }) => {
      const state = getState();

      return selectIsNextResultsLoadEnabled(state);
    }
  }
);

const shouldShowHidePerStreamPrompt = (hideLevel?: CompanyAccountHideLevel) =>
  hideLevel !== CompanyAccountHideLevel.CompanySavedSearch &&
  !getLocalStorageItem('hidePerStreamShown');

export const toggleCandidateVisibility = createAppAsyncThunk(
  'discover/toggleCandidateVisibility',
  async (arg: ToggleCandidateVisibilityThunkParams, { getState }) => {
    const state = getState();
    const companyUser = selectCompanyUser(state);
    const hideOnStreamPage = !arg.unhide && arg.streamID && arg.showUndo;

    if (hideOnStreamPage && shouldShowHidePerStreamPrompt(companyUser?.hideLevel)) return;

    const { searchResults, searchResultsCounts } = state.discover;

    /* 
      TODO: If the function below (original name 'toggleCandidateVisibility') is removed from 
      CompanyLists (src/views/company/saved), the function should be moved here and use 
      builder.addCase to update the discover state so it won't require using structuredClone.
    */
    return hideOrUnhideCandidate({
      ...arg,
      searchResults: structuredClone(searchResults),
      searchResultsCount: searchResultsCounts.resultCount
    });
  }
);
