import {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef
} from 'react';

import {
  CompanySizeType,
  CompanyStoryBenefits,
  CompanyStoryLocation,
  PublicStoryDTO
} from '@cohiretech/common-types';

import {
  getCompanyStories,
  getValidStoryIndustries
} from 'v2/services/fetchers/public/companyStories';
import { addDimensionsOnImages, storiesImageParams } from 'v2/services/image';
import { Attribute, StoryFilter, StoryFilterState } from 'types';
import { isEmpty } from 'utils';
import { flattenDependencies } from 'utils/array';
import { isSuccess } from 'v2/services/fetchers/apiTools';
import { useSearchParams } from 'hooks/useSearchParams';
import { assocPath } from 'utils/fn';
import { pluck } from 'utils/object';

import { FilterOptions } from 'components/expandablefilter';

type CustomerStoriesContextObject = {
  loading: boolean;
  stories: PublicStoryDTO[];
  filters: StoryFilterState;
  setFilters: Dispatch<SetStateAction<StoryFilterState>>;
  addFilter: (key: Attribute, label: string, value: any) => void;
  removeFilter: (key: Attribute, value: any) => void;
};

const CustomerStoriesContext = createContext<CustomerStoriesContextObject | null>(null);

export default function CustomerStoriesProvider({ children }: PropsWithChildren<{}>) {
  const [stories, setStories] = useState<PublicStoryDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<StoryFilterState>({} as StoryFilterState);
  const hasInitialized = useRef(false);
  const { searchParams, updateSearchParams } = useSearchParams();
  const { industries, locations, companySizeTypes, benefits } = searchParams;
  const isURLParamsEmpty =
    isEmpty(industries) && isEmpty(locations) && isEmpty(companySizeTypes) && isEmpty(benefits);
  const depString = flattenDependencies(Object.values(filters));

  const getStories = async () => {
    const { industries, locations, companySizeTypes, benefits } = parseFilters(filters);
    const { data = [] } = await getCompanyStories({
      industries,
      locations,
      companySizeTypes,
      benefits,
      ignoreTags: true
    });

    const storiesWithResizedImages = data.map(story =>
      addDimensionsOnImages(story, storiesImageParams())
    );

    setStories(storiesWithResizedImages);
    setLoading(false);
  };

  const getIndustyFilters = async () => {
    const response = await getValidStoryIndustries();
    if (isSuccess(response)) {
      const filterOptions = response.data.map(buildOption('industries'));
      setFilters(prev => {
        return assocPath(prev, ['industries', 'filterOptions'], filterOptions);
      });
    }
  };

  const addFilter = (key: Attribute, label: string, value: any) => {
    setFilters((prev: StoryFilterState) => {
      const current = prev[key as StoryFilter];
      const option = (current.filterOptions! as FilterOptions).find(
        option => option.value === value
      );
      if (!option) return prev;

      const selectedItems = [...current.selectedItems!, option];
      updateSearchParams({ [key]: selectedItems.map(pluck('value')).join(',') });
      return assocPath(prev, [key, 'selectedItems'], selectedItems);
    });
  };

  const removeFilter = (key: Attribute, value: any) => {
    setFilters(prev => {
      const current = prev[key as StoryFilter];
      const selectedItems = current?.selectedItems!.filter(item => item.value !== value);
      updateSearchParams({ [key]: selectedItems.map(item => item.value).join(',') });
      return assocPath(prev, [key, 'selectedItems'], selectedItems);
    });
  };

  useEffect(() => {
    if (isEmpty(filters.industries?.filterOptions)) getIndustyFilters();
    if (hasInitialized?.current) {
      getStories();
      return;
    }

    if (!isURLParamsEmpty) {
      Object.entries({ companySizeTypes, industries, locations, benefits }).forEach(
        ([attribute, str]) => {
          if (!str) return;
          const selectedItems = str.split(',').map(buildOption(attribute as StoryFilter));
          setFilters(prev => assocPath(prev, [attribute, 'selectedItems'], selectedItems));
        }
      );
    }

    hasInitialized.current = true;
  }, [depString]);

  const value = {
    loading,
    stories,
    filters,
    setFilters,
    addFilter,
    removeFilter
  };

  return (
    <CustomerStoriesContext.Provider value={value}>{children}</CustomerStoriesContext.Provider>
  );
}

const buildOption = (attribute: StoryFilter) => (value: string) => ({
  value,
  label: value,
  attribute
});

const parseFilters = (filters: StoryFilterState) => {
  const parsedFilters = {} as any;
  for (const key in filters) {
    const currentlySelected = filters[key as StoryFilter].selectedItems;
    if (!isEmpty(currentlySelected)) {
      parsedFilters[key as StoryFilter] = currentlySelected!.map(filter => filter.value);
    }
  }

  return parsedFilters as {
    companySizeTypes?: CompanySizeType[];
    industries?: string[];
    locations?: CompanyStoryLocation[];
    benefits?: CompanyStoryBenefits[];
  };
};

export const useCustomerStoriesContext = () => {
  const customerStoriesContext = useContext(CustomerStoriesContext);
  if (!customerStoriesContext) {
    throw new Error('useCustomerStoriesContext must be used within a Provider');
  }
  return customerStoriesContext;
};
