import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { PlatformStatistic, TestimonialType, UserType } from '@cohiretech/common-types';

import { Testimonial } from 'types';
import { getPublicCompanyStats, getTestimonials } from 'fetcher';
import { getPercentage, isEmpty } from 'utils';

import TestimonialItem from 'components/testimonialitem';
import PageSection from 'components/pagesection';

import { ReactComponent as TrustpilotStar } from 'images/trustpilot-star.svg';

import Metric from '../components/metriccard';

import { ContentWrapper, TestimonialsWrapper } from './testimonialssection.styled';

type Stats = PlatformStatistic & { trustpilotScore: number };

export default function TestimonialsSection() {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [stats, setStats] = useState<Stats>();

  const location = useLocation();

  useEffect(() => {
    const getStats = async () => {
      const companyStats = await getPublicCompanyStats();

      setStats(companyStats?.data || null);
    };

    const getRecentTestimonials = async () => {
      const { values } = await getTestimonials(UserType.Candidate, 0, TestimonialType.Text);

      const mostRecentTestimonials = values.slice(0, 9);

      setTestimonials(mostRecentTestimonials);
    };

    getRecentTestimonials();
    getStats();
  }, []);

  // const metrics = getHomepageMetrics(stats);

  return (
    <PageSection
      className="testimonials_section center_alignment"
      widthClassName="page_view"
      title="What people are saying"
      button={{
        text: 'View all testimonials',
        buttonStyle: 'stroke_cord_gradient',
        link: '/testimonials/people'
      }}
    >
      <ContentWrapper>
        {/* Separate sections for metrics & testimonials */}
        {/* <MetricsWrapper className="metrics_wrapper">
          {metrics.map((metric, index) => (
            <Metric className="center_scale_animation" key={index} {...metric} />
          ))}
        </MetricsWrapper>
        <TestimonialsWrapper className="testimonial_wrapper">
          {testimonials.map(testimonial => (
            <TestimonialItem
              className="center_scale_animation"
              key={testimonial.id}
              location={location}
              {...testimonial}
              testimonialType="candidate"
            />
          ))}
        </TestimonialsWrapper> */}

        {/* Combined section for metrics & testimonials */}
        <TestimonialsWrapper className="testimonial_wrapper">
          <Metric
            className="top_left_scale_animation"
            icon="icon_users"
            metric="2,000+"
            description="users joining every week"
          />
          {!isEmpty(testimonials) &&
            testimonials
              .slice(0, 2)
              .map(testimonial => (
                <TestimonialItem
                  className="top_left_scale_animation"
                  key={testimonial.id}
                  location={location}
                  {...testimonial}
                  testimonialType="candidate"
                />
              ))}
          <Metric
            className="bottom_left_scale_animation"
            icon="icon_briefcase"
            metric="40,000+"
            description="open positions"
          />
          {!isEmpty(testimonials) && testimonials[3] && (
            <TestimonialItem
              className="top_center_scale_animation"
              key={testimonials[3].id}
              location={location}
              {...testimonials[3]}
              testimonialType="candidate"
            />
          )}
          <Metric
            className="center_scale_animation"
            icon="icon_conversation"
            metric="3 million+"
            description="messages exchanged so far"
          />
          {!isEmpty(testimonials) &&
            testimonials
              .slice(3, 4)
              .map(testimonial => (
                <TestimonialItem
                  className="bottom_center_scale_animation"
                  key={testimonial.id}
                  location={location}
                  {...testimonial}
                  testimonialType="candidate"
                />
              ))}
          <Metric
            className="bottom_center_scale_animation"
            icon="icon_successful_message"
            metric={getPercentage(stats?.averageResponseRate || 0.8, true)}
            description="response rate from companies"
          />
          {!isEmpty(testimonials) &&
            testimonials
              .slice(4, 6)
              .map(testimonial => (
                <TestimonialItem
                  className="top_right_scale_animation"
                  key={testimonial.id}
                  location={location}
                  {...testimonial}
                  testimonialType="candidate"
                />
              ))}
          <Metric
            className="bottom_right_scale_animation"
            icon="icon_trophy"
            metric={
              <>
                {stats?.trustpilotScore || 4.8}{' '}
                <TrustpilotStar key="image" width="20" height="20" />
              </>
            }
            description="score on Trustpilot"
          />
        </TestimonialsWrapper>
      </ContentWrapper>
    </PageSection>
  );
}
