import { ReactNode } from 'react';
import styled from 'styled-components';

import { CandidateBubbleName } from 'store/ui';
import { CandidateProfile } from 'types';
import { shouldShowSelfBuild } from 'v2/services/candidate';
import { getCurrencyFromLocation } from 'utils';

import Badge from 'components/badge';

export type MenuItem = {
  path: string;
  label: ReactNode;
  icon: string;
  bubbleName?: CandidateBubbleName;
};

export const getTopMenuItems = (profile: CandidateProfile): MenuItem[] => {
  const isProfileIncomplete = shouldShowSelfBuild(profile);
  const isProfileRejected = profile.rejected;
  const currency = getCurrencyFromLocation(profile.locationCity);

  return [
    {
      path: 'account',
      label: [
        'Profile',
        isProfileIncomplete && (
          <PendingProfileBadge
            key="pending_profile_badge"
            text="Pending Completion"
            icon="icon_clock"
          />
        )
      ],
      icon: 'icon_user',
      bubbleName: 'profile'
    },
    ...(isProfileRejected
      ? []
      : [
          {
            path: 'templates',
            label: 'Message templates',
            icon: 'icon_template'
          }
        ]),
    { path: 'search/saved', label: 'Liked positions', icon: 'icon_thumbs_up' },
    { path: 'search/hidden', label: 'Not right positions', icon: 'icon_thumbs_down' },
    { path: 'invites', label: 'Invite friends', icon: 'icon_like' },
    { path: 'referrals', label: `Earn ${currency}100`, icon: 'icon_gift' }
  ];
};

const PendingProfileBadge = styled(Badge)`
  &.badge {
    margin: 0 0 0 0.5rem;

    .icon_clock {
      margin-right: 0.25rem;
      font-size: ${({ theme: { typography } }) => typography.mobile};
      vertical-align: text-bottom;
    }
  }
`;
