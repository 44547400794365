import React from 'react';

import { trackSignupClicks } from 'tracking-utils';
import { useDarkMode } from 'hooks/useDarkMode';

import Button from 'components/button';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { BentoGrid } from 'v2/components/ui/styles';

import AllPositionsInOnePlace from 'images/homepage/all-positions-in-one-place.svg';
import AllPositionsInOnePlaceDark from 'images/homepage/all-positions-in-one-place-dark.svg';
import DirectMessaging from 'images/homepage/direct-messaging-preview.png';
import DirectMessagingDark from 'images/homepage/direct-messaging-preview-dark.png';
import ManagementBoard from 'images/homepage/management-board.svg';
import ManagementBoardDark from 'images/homepage/management-board-dark.svg';
import InsightsPreview from 'images/homepage/insights-preview.svg';
import InsightsPreviewDark from 'images/homepage/insights-preview-dark.svg';

import { FeaturesPageSection } from './featuressection.styled';

export default function FeaturesSection() {
  const darkMode = useDarkMode();

  return (
    <FeaturesPageSection
      className="features_section center_alignment"
      widthClassName="page_view"
      title="Everything you need to find work"
      subTitle="Surface every relevant position, direct message the people hiring and manage all your applications in one place."
      subTitleClassName="min_view"
    >
      <Button
        text="Get started"
        icon="icon_arrow_right"
        iconPosition="right"
        link="/signup"
        buttonStyle="fill_cord_gradient"
        action={() => trackSignupClicks('Homepage - Features section CTA')}
      />
      <BentoGrid className="bento_grid">
        <div className="bento_box bento_box_secondary align_bottom_right top_left_scale_animation">
          <div className="bento_box_content">
            <span className="icon_search blue_gradient_icon" />
            <h4 className="bento_box_title">Every position in one place</h4>
            <p className="bento_box_description">
              We index the web for open positions that might be relevant to you and bring them all
              in one place.
            </p>
          </div>
          <ConditionalRender
            predicate={darkMode}
            fallback={
              <img
                src={AllPositionsInOnePlace}
                alt="Positions you might like split into tabs based on their type"
                className="bento_box_img"
                loading="lazy"
              />
            }
          >
            <img
              src={AllPositionsInOnePlaceDark}
              alt="Positions you might like split into tabs based on their type"
              className="bento_box_img"
              loading="lazy"
            />
          </ConditionalRender>
        </div>
        <div className="bento_box bento_box_secondary align_bottom_right top_right_scale_animation">
          <div className="bento_box_content">
            <span className="icon_apply blue_gradient_icon" />
            <h4 className="bento_box_title">Direct messaging</h4>
            <p className="bento_box_description">
              No more CV blackholes. Message people hiring directly and see their activity live on
              cord.
            </p>
          </div>
          <ConditionalRender
            predicate={darkMode}
            fallback={
              <img
                src={DirectMessaging}
                alt="A sent message to a hiring manager"
                className="bento_box_img"
                loading="lazy"
              />
            }
          >
            <img
              src={DirectMessagingDark}
              alt="A sent message to a hiring manager"
              className="bento_box_img"
              loading="lazy"
            />
          </ConditionalRender>
        </div>
        <div className="bento_box bento_box_primary align_top_left bottom_left_scale_animation">
          <ConditionalRender
            predicate={darkMode}
            fallback={
              <img
                src={ManagementBoard}
                alt="A board of positions in different stages"
                className="bento_box_img"
                loading="lazy"
              />
            }
          >
            <img
              src={ManagementBoardDark}
              alt="A board of positions in different stages"
              className="bento_box_img"
              loading="lazy"
            />
          </ConditionalRender>
          <div className="bento_box_content">
            <span className="icon_board blue_gradient_icon" />
            <h4 className="bento_box_title">Application management</h4>
            <p className="bento_box_description">
              Manage all your applications and interviews in one place.
            </p>
          </div>
        </div>
        <div className="bento_box bento_box_tertiary align_bottom_right bottom_right_scale_animation">
          <div className="bento_box_content">
            <span className="icon_insights blue_gradient_icon" />
            <h4 className="bento_box_title">Advanced insights</h4>
            <p className="bento_box_description">
              Know your worth and see how you compare with other applicants.
            </p>
          </div>
          <ConditionalRender
            predicate={darkMode}
            fallback={
              <img
                src={InsightsPreview}
                alt="Visualisation of a profile match score for a position and score comparison with other applicants"
                className="bento_box_img no_shadow_img reposition_img"
                loading="lazy"
              />
            }
          >
            <img
              src={InsightsPreviewDark}
              alt="Visualisation of a profile match score for a position and score comparison with other applicants"
              className="bento_box_img no_shadow_img reposition_img"
              loading="lazy"
            />
          </ConditionalRender>
        </div>
      </BentoGrid>
    </FeaturesPageSection>
  );
}
