import { toArray } from 'utils/array/toArray';

/**
 * QueryParam type representing a search parameter with an optional value.
 * @typedef {Object} QueryParam
 * @property {string} param - The search parameter key.
 * @property {string} [value] - The optional search parameter value.
 */
export type QueryParam = { param: string; value?: string | number };

/**
 * Appends one or more query parameters to the given URL.
 * If a parameter key already exists in the URL, its value will be updated.
 * Preserves the order of existing parameters and appends new parameters at the end.
 *
 * @param {string} url - The input URL to which query parameters will be added.
 * @param {QueryParam | QueryParam[]} queryParams - A single QueryParam object or an array of QueryParam objects.
 * @returns {string} - The modified URL with the specified query parameters added or updated.
 */
export const appendQueryParam = (url: string, queryParams: QueryParam | QueryParam[]): URL => {
  const urlObject = new URL(url);
  const arrayParams = toArray(queryParams);

  for (const { param, value } of arrayParams) {
    if (!urlObject.searchParams.has(param)) {
      urlObject.searchParams.append(param, `${value ?? ''}`);
    } else if (value !== undefined) {
      urlObject.searchParams.set(param, `${value}`);
    }
  }

  return urlObject;
};
