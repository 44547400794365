import React from 'react';
import ClassNames from 'classnames';
import { NavLink as Link } from 'react-router-dom';

import { setLocalStorageItem, getLocalStorageItem } from 'cookieManager';
import * as fetcher from 'fetcher';

import EditAccountProfile from 'components/editaccountprofile';
import Loader from 'components/loader';
import Seo from 'components/seo';

type State = $TSFixMe;

export default class JoinTeam extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      userProfile: null,
      loading: true,
      verifyError: '',
      verificationCode: null,
      needToSetPassword: true
    };
  }

  async componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location, history } = this.props;
    const verifyCode = location.search !== '' ? location.search.split('=')[1] : '';

    if (verifyCode) {
      const { status, data } = await fetcher.verifyUserEmail(verifyCode, 'company_team_account');

      if (status === 'success') {
        const userProfile = await fetcher.getPublicCompanyUser(verifyCode);
        setLocalStorageItem('userEmail', userProfile.email);
        this.setState({
          userProfile,
          loading: false,
          verificationCode: verifyCode,
          needToSetPassword: data.needToSetPassword
        });
      } else {
        this.setState({
          loading: false,
          verifyError: 'Whoops, something went wrong :('
        });
      }
    } else {
      history.push('/');
    }
  }

  async login(email: $TSFixMe, password: $TSFixMe) {
    const { status } = await fetcher.login(email, password, false);
    // @ts-expect-error TS(2339) FIXME: Property 'onLoginStatusChange' does not exist on t... Remove this comment to see the full error message
    const { onLoginStatusChange, history } = this.props;
    const setupMode = getLocalStorageItem('setup_mode') || false;

    if (status === 'failure') {
      history.push('/login');
    } else {
      await onLoginStatusChange();

      if (setupMode) history.push('/company/setup/positions');
      else history.push('/company/profile');
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className, location } = this.props;
    const { userProfile, verifyError, loading, verificationCode, needToSetPassword } = this.state;

    if (loading) {
      return <Loader className={ClassNames('verify_new_users', 'medium')} />;
    } else if (!needToSetPassword) {
      return (
        <div id="join" className={className}>
          <div className="intro_wrapper">
            <div className="min_view">
              <h1 className="heading">Hey {userProfile.memberName}!</h1>
              <h2 className="subheading small_subheading">
                You've already joined your team account on cord and created your password. You can
                now <Link to="/login">login</Link> or{' '}
                <Link to="/forgot-password">reset your password</Link> if you don't remember it.
              </h2>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="join" className={className}>
          {verifyError !== '' ? (
            <div className="min_view">
              <h1 className="heading">{verifyError}</h1>
              <h2 className="subheading">We couldn't verify your account. Can you try again?</h2>
            </div>
          ) : (
            <div className="min_view center_alignment">
              <h1 className="heading">Welcome!</h1>
              <h2 className="subheading">Join your team's account on cord.</h2>
              <div className="form_view">
                <EditAccountProfile
                  profile={userProfile}
                  companyName={userProfile.companyName}
                  createProfile
                  verificationCode={verificationCode}
                  login={(email: $TSFixMe, password: $TSFixMe) => this.login(email, password)}
                />
              </div>
            </div>
          )}
          <Seo
            title="Join your team"
            description={"Join your team's account on cord and hire great people first."}
            path={location.pathname}
            contentType="website"
          />
        </div>
      );
    }
  }
}
