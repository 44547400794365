export const COMPANY_MESSAGE_EXAMPLES = [
  `Hi {{FIRST_NAME}},
  
  I’m {{SENDER_FIRST_NAME}}, the {{SENDER_JOB_TITLE}} at Company X. Your profile on cord stood out. Love the look of your experience at {{CURRENT_COMPANY}}.
      
  Here’s an overview of us: (Link to a YouTube video with a three-minute pitch). We’re a fully-funded startup with a rich development roadmap. We’ve won multiple awards for our white-label commerce platform, and intend to win many more. We’ve just completed the Wayra (O2/Telefonica) start-up accelerator.
      
  Would you be able to speak in the next couple of days? I’ve attached my calendar availability.
      
  Best wishes,
  {{SENDER_FIRST_NAME}}, 
  {{SENDER_JOB_TITLE}}`,
  `Hey {{FIRST_NAME}},
  
  The work you're doing at {{CURRENT_COMPANY}}, combined with your experience around {{CORE_SKILLS}} looks great and could prove a fantastic fit for our team here at company Y.
  
  Our tech stack comprises of {{POSITION_TECH_STACK}}.
      
  As a business, our mission is to bring Machine learning and Big Data to the world of personal finance. We've built a next-generation core banking platform from scratch, to be a cloud-native, modern alternative to the legacy platforms that plague the banking industry.    
  
  I'd love to discuss this further, when would be a good time to speak?
      
  Thanks, 
  {{SENDER_FIRST_NAME}}, 
  {{SENDER_JOB_TITLE}}`,
  `{{FIRST_NAME}}, I’m the {{SENDER_JOB_TITLE}} at cord and I’m impressed by your experience using {{CORE_SKILLS}} at {{CURRENT_COMPANY}}. It looks like a good fit for our {{POSITION_NAME}}.”
    
  We’ve built the first user-centered job search experience, used daily by 1000s of engineers and 100s hiring teams inside Europe’s most advanced technology companies, from bedroom start-ups to Sky, BT and Ocado. We use modern technologies- {{POSITION_TECH_STACK}} - to build a scalable product that has seen cord enable over 40,000 direct conversations so far.
  
  If that sounds interesting, book 15 mins in my calendar using the link.
  {{SENDER_FIRST_NAME}}, 
  {{SENDER_JOB_TITLE}}`,
  `Hi {{FIRST_NAME}},
   
  I’m the {{SENDER_JOB_TITLE}} at Company Z and I’m really interested in speaking to you about our {{POSITION_NAME}} position. We’re working with {{POSITION_TECH_STACK}} to help keep children stay safe online.
     
  We are a product-first company & are currently looking to grow our dev team (currently 8 people). Would you be open to an initial conversation? If so go ahead and pick a time convenient for you from the following link :)
     
  Thanks, {{SENDER_FIRST_NAME}}`,
  `Hi {{FIRST_NAME}},
    
  Your experience at {{CURRENT_COMPANY}} could make you a great fit for our {{POSITION_NAME}} role. We are a machine learning start-up transforming the media industry. We’re working with {{POSITION_TECH_STACK}}. 
  99.9% of video content is shackled to a single language. Our ambition is to make the world’s content watchable in any language. We’re translating videos by generating voices that sound like the original speaker, not only capturing the characteristics of your voice but also the way you speak.
    
  Would you be interested in arranging a call? Book in a time in my calendar.
  
  Thanks,
  {{SENDER_FIRST_NAME}}`,
  `Hi {{FIRST_NAME}} - We’re hiring for a {{POSITION_NAME}} and your {{CORE_SKILLS}} skills look great. I’d like to hear more about your time at {{CURRENT_COMPANY}}?
  
  As a {{POSITION_NAME}} you could help invent experiences in: Media, Sports personalisation and Discovery or build innovative applications using AI/ML to make new products that will be the future of media. Our goal: use AI/ML techniques to improve aspects of media consumption.
      
  I’ve attached a link to my calendar, can you book a time in for us to speak?
      
  Thanks, {{SENDER_FIRST_NAME}}`
];
