import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { setCompanyTracking } from 'fetcher';
import { mediaQueries } from 'responsiveConfig';
import { AppDispatch, RootState } from 'store/store';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { isCandidateProAllowed, selectCompanyProfile } from 'store/user';
import { setCSSReduxVariable } from 'store/ui';
import { Theme, UserProfileType } from 'types';
import { BubbleName } from 'v2/services/company/tour';
import { last } from 'utils/array';
import { setBodyCSSVariable } from 'v2/services/tools/css';

import { CandidateTour, DemoCompanyTourPage, persistTheme, setDarkMode } from './ui';
import {
  selectCandidateTour,
  selectDarkMode,
  selectDemoCompanyTour,
  selectShowReactivationModal
} from './ui.selectors';

import {
  setCandidateTour,
  setDemoTourBubbleShown,
  toggleShowReactivationModal,
  toggleShowStartUsingCordPopup
} from '.';

// Thunks (async actions & side-effects)
export const initTheme = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
  'ui/initTheme',
  async (_, { dispatch }) => {
    const theme = getLocalStorageItem<Theme>('theme');
    const prefersDarkMode = window?.matchMedia(mediaQueries.prefersDarkMode).matches;
    const useDarkMode = theme === 'dark' || (!theme && prefersDarkMode);

    dispatch(toggleDarkMode(useDarkMode));
  }
);

const persistUserTheme = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const darkMode = selectDarkMode(getState());
  dispatch(persistTheme());
  setLocalStorageItem('theme', darkMode ? 'dark' : 'light');
};

const setBodyTheme = (darkMode: boolean) => {
  const body = document.querySelector('body');
  if (!body) return;

  if (darkMode) body.classList.add('dark');
  else body.classList.remove('dark');

  body.dataset.theme = darkMode ? 'dark' : 'light';
};

export const toggleDarkMode = createAsyncThunk<
  void,
  boolean | undefined,
  { dispatch: AppDispatch; state: RootState }
>('ui/toggleDarkMode', async (darkMode, { dispatch, getState }) => {
  const isDarkMode = darkMode ?? !getState().ui.darkMode;
  setBodyTheme(isDarkMode);
  dispatch(setDarkMode(isDarkMode));
  dispatch(persistUserTheme());
});

export const persistCandidateTourBubbleShown = createAsyncThunk<
  { candidateTour: CandidateTour } | undefined,
  { bubbleName: string },
  { dispatch: AppDispatch; state: RootState }
>('ui/persistCandidateTourBubbleShown', async ({ bubbleName }, { dispatch, getState }) => {
  const state = getState();
  const { profile } = state.user;
  const candidateTour = [...selectCandidateTour(state)];
  const shouldShowInsightsTour = isCandidateProAllowed(profile);
  const insightsTourBubbleIndex = candidateTour.findIndex(({ name }) => name === 'insights');

  // Remove dashboard tour bubble if it shouldn't be shown
  if (!shouldShowInsightsTour && insightsTourBubbleIndex > -1) {
    candidateTour.splice(insightsTourBubbleIndex, 1);
  }

  const updatedCandidateTour = candidateTour.map(bubble =>
    bubble.name === bubbleName ? { ...bubble, shown: true } : bubble
  );

  if (!updatedCandidateTour.find(({ name }) => name === bubbleName)) {
    throw new Error(`CandidateBubble ${bubbleName} not found`);
  }

  dispatch(setCandidateTour(updatedCandidateTour));
  setLocalStorageItem('candidateTour', updatedCandidateTour);

  return { candidateTour: updatedCandidateTour };
});

export const toggleReactivationModal = createAsyncThunk<
  void,
  { show?: boolean; ctaDetails?: { source: string } },
  { dispatch: AppDispatch; state: RootState }
>('ui/toggleReactivationModal', async ({ show, ctaDetails }, { dispatch, getState }) => {
  const state = getState();
  const companyProfile = selectCompanyProfile(state);
  const showFromState = selectShowReactivationModal(state);

  dispatch(toggleShowReactivationModal(show));
  setLocalStorageItem('reactivationModalSeen', true);

  if ((show ?? !showFromState) && companyProfile?.isUnpaidAccount) {
    await setCompanyTracking('view-activate-account-banner', ctaDetails);
  }
});

export const toggleStartUsingCordPopup = createAsyncThunk<
  void,
  { show?: boolean; ctaDetails?: { source: string } },
  { dispatch: AppDispatch }
>('ui/toggleStartUsingCordPopup', async ({ show, ctaDetails }, { dispatch }) => {
  dispatch(toggleShowStartUsingCordPopup(show));

  if (show) await setCompanyTracking('start-using-cord', ctaDetails);
});

export const onDemoTourBubbleShown = createAppAsyncThunk(
  'ui/onDemoTourBubbleShown',
  async (
    arg: { bubbleName: BubbleName; nextBubbleLocation?: DemoCompanyTourPage },
    { dispatch, getState }
  ) => {
    const { bubbleName, nextBubbleLocation } = arg;

    dispatch(setDemoTourBubbleShown(bubbleName));

    const state = getState();
    const demoCompanyTour = selectDemoCompanyTour(state);
    const isLastItem = bubbleName === last(demoCompanyTour)?.name;

    setLocalStorageItem('demoCompanyTour', demoCompanyTour);

    if (nextBubbleLocation) setLocalStorageItem('nextDemoBubbleLocation', nextBubbleLocation);
    if (isLastItem) await setCompanyTracking('tour-completed');
  }
);

export const setCustomCSSVariable = createAsyncThunk<
  void,
  { type: UserProfileType; name: string; value: string },
  { dispatch: AppDispatch; state: RootState }
>('ui/setCustomCSSVariable', async ({ type, name, value }, { dispatch }) => {
  setBodyCSSVariable(name, value, type);
  dispatch(setCSSReduxVariable({ type, name, value }));
});
