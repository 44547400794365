/* eslint-disable func-names */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { STRIPE_TAX_TYPES } from 'consts/company/stripeTaxTypes';

import {
  CompanySubscriptionPlan,
  PricingPlanClean,
  PricingCountryDetails,
  OperatingCountry,
  StripeTaxType,
  LocationInterface
} from '@cohiretech/common-types';

import { CompanyProfile, PricingCountry } from 'types';
import {
  getCheckoutPage,
  addCompanyUserEmailSubscription,
  getPaymentSessionDetails
} from 'fetcher';
import { commify, getCurrencyFromPricingCountry, getQueryStringObject } from 'utils';
import { useUpdateLoginStatus } from 'store/services';
import { capitalizeWords } from 'utils/string';
import { createStripeModule } from 'utils/companyUtils';
import { setInitialSetupModeLS } from 'utils/localStorage';
import { trackCreateAccountEvents } from 'tracking-utils';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from 'cookieManager';

import Button from 'components/button';
import Checkbox from 'components/checkbox';
import CompanyLogos from 'components/companylogos';
import CompanySignUp from 'components/companysignup';
import Dropdown from 'components/dropdown';
import ErrorMessage from 'components/errormessage';
import Field from 'components/field';
import Loader from 'components/loader';
import Seo from 'components/seo';
import LocationInput from 'components/locationinput';

import Mark from 'images/mark-on-gradient.png';

import './style.scss';

type State = {
  loading: boolean;
  status: string;
  errorMessage: string;
  sessionID: string;
  pricing: null | (PricingPlanClean & PricingCountryDetails);
  country: PricingCountry;
  companyName: string;
  vatNumber: string;
  subscriptionPlan: CompanySubscriptionPlan;
  vatError?: string | string[];
  taxType?: StripeTaxType;
  termsAccepted: boolean;
  waitingStripe: boolean;
  locationObject: Partial<LocationInterface>;
};

interface CheckoutProps extends RouteComponentProps<{ path?: string }> {
  profile: CompanyProfile | null;
}

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

export default function Checkout({ location, match, profile }: CheckoutProps) {
  const [state, setState] = useState<State>({
    loading: false,
    waitingStripe: false,
    status: '',
    errorMessage: '',
    sessionID: '',
    pricing: null,
    country: OperatingCountry.UK,
    companyName: '',
    vatNumber: '',
    taxType: undefined,
    termsAccepted: false,
    subscriptionPlan: CompanySubscriptionPlan.Basic,
    locationObject: {}
  });

  const {
    loading,
    status,
    errorMessage,
    sessionID,
    waitingStripe,
    pricing,
    country,
    subscriptionPlan,
    companyName,
    vatNumber,
    taxType,
    termsAccepted,
    locationObject
  } = state;
  const {
    planName,
    planDescription,
    pageSubtitle,
    billingFrequency,
    price,
    discountedPrice,
    vatPrice,
    hasDiscount,
    hasVAT
  } = pricing || {};

  const history = useHistory();
  const storedCompanyName = getLocalStorageItem<string>('companyName') || '';
  const updateLoginStatus = useUpdateLoginStatus();
  const profileSubscriptionPlan = profile?.subscriptionDetailsAndPricing.subscriptionPlan.plan;

  useEffect(() => {
    async function init() {
      const { path } = match.params;
      const { status, session_id, country, plan } = getQueryStringObject(location.search) || {};

      if (status === 'success') {
        trackCreateAccountEvents(`${plan} | ${country}`, 'complete_payment');
        const companyActivating = getLocalStorageItem('company_activating');

        if (companyActivating) {
          removeLocalStorageItem('company_activating');

          setInitialSetupModeLS();

          await updateLoginStatus();

          await Promise.all([
            addCompanyUserEmailSubscription('product', 'add'),
            addCompanyUserEmailSubscription('marketing', 'add'),
            addCompanyUserEmailSubscription('product_monthly_recap', 'add')
          ]);

          history.push('/company/profile/edit');
          return;
        }

        return setState({
          ...state,
          status,
          sessionID: session_id,
          country,
          subscriptionPlan: plan as CompanySubscriptionPlan,
          companyName: storedCompanyName
        });
      }

      if (profileSubscriptionPlan === CompanySubscriptionPlan.Basic) {
        document.body.classList.add('public');
      }

      getPricing(location.pathname, path!, status);
    }

    init();
  }, []);

  const getPricing = async (pathname: string, urlPath: string, paymentStatus?: 'failure') => {
    setState({ ...state, loading: true });

    let pricing;
    let country = pathname.substring(1, 3).toUpperCase();

    const { data } = await getCheckoutPage(urlPath);

    if (data) {
      const { preferCountry = '', archived, ...pricingPlan } = data as PricingPlanClean;

      // Reference - https://www.notion.so/cordians/Checkout-by-Country-206c2b855e014b8a8ff58c42a5958b3c
      if (country !== 'UK' && country !== 'US') {
        country = data[preferCountry]
          ? preferCountry
          : data.UK
          ? OperatingCountry.UK
          : OperatingCountry.US;
      }

      if (data[country]?.price && !archived) pricing = { ...pricingPlan, ...data[country] };
    }

    if (pricing) {
      const newState = { ...state, pricing, country: country as PricingCountry, loading: false };

      if (paymentStatus) {
        newState.companyName = storedCompanyName || '';
        newState.status = paymentStatus;
        newState.errorMessage = 'Your payment failed, please try again.';
      } else {
        const { companyName = '', locationObject = {} } = profile || {};

        newState.companyName = companyName;
        newState.locationObject = locationObject;
      }

      setState(newState);
    } else {
      setState({
        ...state,
        errorMessage: 'The pricing plan is invalid or no longer active.',
        loading: false
      });
    }
  };

  const formatPrice = (cost: number, vatIncluded?: boolean) => {
    const currency = getCurrencyFromPricingCountry(country);
    const formattedPrice = commify((+cost).toFixed(2));

    return `${currency}${formattedPrice}${!vatIncluded && hasVAT ? ' + VAT' : ''}`;
  };

  const getTotalPrice = () => {
    if (hasVAT && vatPrice) return formatPrice(vatPrice, true);

    // US companies don't come with a vatPrice at the momennt since tax is variable in the US
    return formatPrice(discountedPrice || price!);
  };

  const proceedToPayment = async () => {
    const { path } = match.params;
    const { companyName, vatNumber, country, taxType, termsAccepted, locationObject } = state;
    const redirectURL = location.pathname.slice(1);

    if (!termsAccepted) {
      setState({ ...state, errorMessage: 'Please accept the terms of service to proceed' });
      return;
    }

    if (!companyName.length) {
      setState({ ...state, errorMessage: 'Please add a company name' });
      return;
    }

    setLocalStorageItem('companyName', companyName);
    setState({ ...state, waitingStripe: true });

    const source =
      profileSubscriptionPlan === CompanySubscriptionPlan.Basic
        ? 'new_signup_demo'
        : 'new_signup_organic';

    const { sessionID, stripeRegion, message } = await getPaymentSessionDetails(
      path!,
      country,
      redirectURL,
      source,
      companyName,
      locationObject,
      taxType,
      vatNumber
    );
    if (message) {
      setState({ ...state, errorMessage: message, waitingStripe: false });
      return;
    }

    const stripe = createStripeModule(stripeRegion);

    if (profileSubscriptionPlan === CompanySubscriptionPlan.Basic) {
      setLocalStorageItem('company_activating', true);
    }
    trackCreateAccountEvents(`${path} | ${companyName} | ${country}`, 'start_payment');

    stripe
      .redirectToCheckout({
        sessionId: sessionID
      })
      .then(({ error }: { [key: string]: any }) => {
        setState({ ...state, errorMessage: error.message, sessionID: '', waitingStripe: false });
      });
  };

  const showErrorMessage = (status === 'failure' || !pricing) && errorMessage;

  if (status === 'success') {
    return (
      <div id="checkout">
        <div className="intro_wrapper">
          {loading ? (
            <Loader className="medium" />
          ) : (
            <CompanySignUp
              companyName={companyName}
              sessionID={sessionID}
              country={country}
              subscriptionPlan={subscriptionPlan}
            />
          )}
        </div>
        <Seo
          title="Payment Successful"
          description="The team have been notified and will be in touch soon"
          path={`${location.pathname}${location.search}`}
          contentType="website"
          script={[{ src: 'https://js.stripe.com/v3/' }]}
        />
      </div>
    );
  } else {
    return (
      <div id="checkout">
        <div className="intro_wrapper">
          {loading ? (
            <Loader className="medium" />
          ) : (
            <div className="min_view center_alignment">
              <h1 className="heading">
                {showErrorMessage ? 'Something went wrong' : 'Make direct hires'}
              </h1>
              <h2 className="subheading">{showErrorMessage ? errorMessage : pageSubtitle}</h2>
            </div>
          )}
        </div>
        {pricing && (
          <div className="checkout_wrapper page_view">
            <div className="checkout_item">
              <Field
                label="Company Name"
                id="company_name"
                value={companyName}
                type="text"
                onChangeValidation
                placeholder="Eg. Slack"
                onChange={(value: string) => setState({ ...state, companyName: value })}
              />
              <div className="field">
                <Label className="question" htmlFor="tax_id_type">
                  Location
                </Label>
                <LocationInput
                  defaultValue={locationObject?.label}
                  allowAddress
                  noRegions
                  onSelect={(_, locationObject) =>
                    setState(prevState => ({ ...prevState, locationObject }))
                  }
                />
              </div>
              {hasVAT ? (
                <>
                  <div className="field">
                    <Label className="question" htmlFor="tax_id_type">
                      Tax ID Type<span key="optional"> (optional)</span>
                    </Label>
                    <Dropdown
                      id="tax_id_type"
                      allowSearch
                      searchType="start"
                      className="small_arrow"
                      selected={taxType || ''}
                      selectMessage="Tax ID Type"
                      list={STRIPE_TAX_TYPES}
                      onSelect={({ value }) => setState({ ...state, taxType: value })}
                    />
                  </div>
                  <Field
                    label={['Tax ID Number', <span key="optional">(optional)</span>]}
                    id="vat_number"
                    value={vatNumber}
                    type="text"
                    placeholder="Eg. GB23445672"
                    onChange={(value: string) =>
                      setState({ ...state, vatNumber: value.toUpperCase().trim() })
                    }
                  />
                </>
              ) : (
                <></>
              )}
              <AcceptTerms
                checkboxID="terms_policy"
                className="accept_terms"
                label={[
                  "I agree to cord's ",
                  <a
                    href={`${domain}/employer-terms`}
                    key="terms"
                    aria-label="Open Terms of Service in a new tab"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="lightblue_link"
                  >
                    Terms of Service
                  </a>
                ]}
                checked={termsAccepted}
                onChange={checked => setState({ ...state, termsAccepted: checked })}
              />
            </div>
            <div className="checkout_table">
              <div className="checkout_item">
                <div className="checkout_item_photo">
                  <img src={Mark} alt="cord mark" width="50" height="50" />
                </div>
                <div className="checkout_item_details">
                  <div className="checkout_item_name">
                    {planName}: {capitalizeWords(billingFrequency)} subscription
                  </div>
                  <div className="checkout_item_description">{planDescription}</div>
                  <div className="checkout_item_price">
                    <div className={ClassNames({ old_price: hasDiscount }, 'price')}>
                      {formatPrice(price!)}
                    </div>
                    {hasDiscount && (
                      <div className="new_price price">{formatPrice(discountedPrice!)}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="proceed_payment">
              <div className="total_price">
                <span>Total:</span>
                {getTotalPrice()}
              </div>
              <Button
                iconPosition="right"
                icon="icon_arrow_right"
                text="Proceed to payment"
                loading={waitingStripe}
                className="eighteen_px_font pay_button"
                action={() => proceedToPayment()}
              />
              {/** Show error message here only if there's an error in the checkout page not during the payment */}
              {status !== 'failure' && errorMessage && (
                <ErrorMessage type="critical">{errorMessage}</ErrorMessage>
              )}
            </div>
          </div>
        )}
        <div className="page_view">
          <CompanyLogos preferredCountry={country} />
        </div>
        <Seo
          title="Checkout"
          description="cord checkout. Pay to start using cord."
          path={`${location.pathname}${location.search}`}
          contentType="website"
          robots="none"
          image="https://assets.co-hire.com/make-direct-hires.png"
          script={[{ src: 'https://js.stripe.com/v3/' }]}
        />
      </div>
    );
  }
}

const Label = styled.label`
  display: block;
  font-weight: ${({ theme: { typography } }) => typography.semibold};
`;

const AcceptTerms = styled(Checkbox)`
  padding: 8px 0 24px;
`;
