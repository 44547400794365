import styled, { css, keyframes } from 'styled-components';

import { CSSValueUnit } from 'types';

const DEFAULT_ELLIPSIS_DURATION = 3;

type AnimatedEllipsisProps = {
  ellipsisDuration?: number;
  fontSize?: CSSValueUnit;
};

export const ellipsisAnim = keyframes`
  0% { content: "." }
  25% { content: ".." }
  50% { content: "..." }
  100% { content: "" }
`;

export const AnimatedEllipsis = styled.span<AnimatedEllipsisProps>`
  display: inline-block;
  text-align: left;
  margin-left: 1px;
  min-width: 1rem;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`};

  &::after {
    ${({ ellipsisDuration = DEFAULT_ELLIPSIS_DURATION }) => css`
      content: '';
      animation: ${ellipsisAnim} ${ellipsisDuration}s steps(${ellipsisDuration}, end) infinite;
    `}
`;
