import { useAppDispatch, useAppSelector } from 'store/store';
import { useCandidateProfile } from 'store/user';
import { editCandidatePreferences } from 'fetcher';
import { useCandidateData } from 'store/services/candidate/fetchData';
import {
  setMarkApplicationPopup,
  selectMarkApplicationPopup,
  MarkApplicationPopupType
} from 'store/navigatablePositions';
import { useCandidateATS } from 'store';
import { isPartialMatch } from 'utils/object';

export const useMarkApplicationPopup = () => {
  const dispatch = useAppDispatch();
  const markApplicationPopup = useAppSelector(selectMarkApplicationPopup);

  const { preferences } = useCandidateProfile() || {};
  const { reloadCandidateData } = useCandidateData();

  const showMarkApplicationPopup = (type: MarkApplicationPopupType) => {
    let delay = 0;

    if (type === 'prompt') {
      if (preferences?.skipMarkApplicationPrompt) return;
      delay = 3000;
    }

    setTimeout(() => {
      dispatch(setMarkApplicationPopup({ show: true, type }));
    }, delay);
  };

  const hideMarkApplicationPopup = (skipMarkApplicationPrompt?: boolean) => {
    if (skipMarkApplicationPrompt) {
      editCandidatePreferences({ preferences: { ...preferences, skipMarkApplicationPrompt } });
      reloadCandidateData();
    }

    dispatch(setMarkApplicationPopup({ show: false }));
  };

  return {
    show: markApplicationPopup?.show,
    type: markApplicationPopup?.type,
    showMarkApplicationPopup,
    hideMarkApplicationPopup
  };
};

export const useMarkApplicationPopupSubtitle = (listingID: number) => {
  const { items } = useCandidateATS().stagesState.applied;
  const isInAppliedStage = items.find(isPartialMatch({ listingID }));

  if (isInAppliedStage) {
    return "Mark positions you've applied to, so they're hidden from your homepage and searches.";
  }
  return " Mark positions you've applied to, so they're moved to the Applied column in your application tracker.";
};
