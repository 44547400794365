import axios from 'axios';

import {
  CandidateApplicationTrackerCreatedListingInterface as CandidateATCreatedLI,
  CandidateApplicationTrackerStage as CandidateATStage,
  ListingInterface,
  CandidateApplicationTrackerImportByURLDTO as CandidateATImportByURLDTO,
  HydratedCandidateApplicationTrackerInterface as HydratedCandidateATInterface
} from '@cohiretech/common-types';

import { APIResponse } from 'types';
import { addDimensionsOnImages } from 'v2/services/image';

const CANDIDATE_APPLICATION_TRACKER = process.env.REACT_APP_CANDIDATE_APPLICATION_TRACKER!;

export const getExternalListingByURL = async (
  url: string,
  signal: AbortSignal
): Promise<APIResponse<ListingInterface>> => {
  try {
    const { data } = await axios.get<{ data: ListingInterface }>(
      `${CANDIDATE_APPLICATION_TRACKER}/listings/external/url`,
      { params: { url }, signal }
    );

    const listing = addDimensionsOnImages(data.data, { type: 'logo', dimensions: { width: 90 } });

    return { status: 'success', data: listing };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

type CandidateATPositionImportByURL = Omit<CandidateATImportByURLDTO, 'remote'> &
  Pick<ListingInterface, 'remote'>;

export const importCandidateApplicationTrackerListing = async (
  url: string,
  signal: AbortSignal
): Promise<APIResponse<CandidateATPositionImportByURL>> => {
  try {
    const { data } = await axios.post(
      `${CANDIDATE_APPLICATION_TRACKER}/import`,
      { url },
      { signal }
    );

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const addListingToCandidateApplicationTracker = async (params: {
  listingID: number;
  stage: CandidateATStage;
}): Promise<APIResponse> => {
  try {
    const { data } = await axios.post(CANDIDATE_APPLICATION_TRACKER, params);

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const addCandidateCreatedApplicationTrackerListing = async (params: {
  listing: Omit<CandidateATCreatedLI, 'id'>;
  stage: CandidateATStage;
}): Promise<APIResponse> => {
  try {
    const { data } = await axios.post(`${CANDIDATE_APPLICATION_TRACKER}/candidate-created`, params);

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getCandidateATItemByID = async (
  itemID: number
): Promise<APIResponse<HydratedCandidateATInterface>> => {
  try {
    const { data } = await axios.get<{ data: HydratedCandidateATInterface }>(
      `${CANDIDATE_APPLICATION_TRACKER}/${itemID}`
    );

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const updateCandidateCreatedATListing = async (
  itemID: number,
  update: Omit<CandidateATCreatedLI, 'id'>
): Promise<APIResponse> => {
  try {
    await axios.patch(`${CANDIDATE_APPLICATION_TRACKER}/candidate-created/${itemID}`, update);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
