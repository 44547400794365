import React from 'react';

import { HiresCompany, HiresPosition } from '@cohiretech/common-types';

import { getRemoteOptions, getSeniorityOptions } from 'fetcher';
import {
  setAcronym,
  isDateWithinDays,
  getTimeAgo,
  pluralise,
  formatNumber,
  getCurrencySymbol,
  getCurrencyIcon
} from 'utils';

import { CompanyCardProps, Subitem, AdditionalItem } from 'v2/components/ui/molecules/CompanyCard';

export const getHiresCount = (companies: HiresCompany[]) => {
  const totalHiredCount = companies.reduce((acc, { hiredCount }) => acc + hiredCount, 0);

  return totalHiredCount;
};

const getUnassociatedHiresCount = (hiresCount: number, positions: HiresPosition[]) => {
  const positionHiresCount = positions.reduce((acc, { hiredCount }) => acc + hiredCount, 0);

  return hiresCount - positionHiresCount;
};

export const getCompanyCardProps = (company: HiresCompany) => {
  const { locationLabel, size, hiredPositionsCount, hiredCount, positions, costPerHire, currency } =
    company;
  const latestHire = positions[0];
  const latestHireWithin5Days = isDateWithinDays(5, latestHire.lastHiredDate as string);

  const props: CompanyCardProps = {
    company: {
      ...company,
      info: `${setAcronym(locationLabel)} · ${size} empl.`
    },
    metrics: [
      {
        icon: 'icon_job',
        text: pluralise(hiredPositionsCount, 'position', 'positions')
      },
      {
        icon: 'icon_hired',
        text: pluralise(hiredCount, 'hire', 'hires')
      },
      {
        icon: getCurrencyIcon(currency),
        text: `${getCurrencySymbol(currency)}${formatNumber(costPerHire)} cph`,
        tooltip: (
          <>
            Cost per hire (CPH) is calculated using the total number of hires made across the
            subscription period.
            <br />
            <em>
              I.e. If a company on cord is active for 2 months (£789/month) and makes 3 hires, they
              have achieved a £526 cph.
            </em>
          </>
        )
      }
    ],
    subitems: {
      type: 'position',
      items: getHiresPotisionList(hiredCount, positions)
    }
  };

  if (latestHireWithin5Days) {
    props.banner = {
      icon: 'icon_bullet',
      appearance: 'info_light',
      nowrap: true,
      children: (
        <>
          <div className="position_name">{latestHire.name}</div>
          <div>{` hired ${getTimeAgo(latestHire.lastHiredDate)}`}</div>
        </>
      )
    };
  }

  return props;
};

const getHiresPotisionList = (hiredCount: number, positions: HiresPosition[]) => {
  const hiresPositionList: Array<Subitem | AdditionalItem> = positions.map(getHiresPotisionItem);
  const unassociatedHiresCount = getUnassociatedHiresCount(hiredCount, positions);

  if (unassociatedHiresCount > 0) {
    hiresPositionList.push({
      text: (
        <span>
          <strong>+{pluralise(unassociatedHiresCount, 'more hire', 'more hires')}</strong>{' '}
          unassociated to a position
        </span>
      ),
      isAdditional: true
    });
  }

  return hiresPositionList;
};

const SENIORITY_OPTIONS = getSeniorityOptions();
const REMOTE_OPTIONS = getRemoteOptions();

const getHiresPotisionItem = (position: HiresPosition): Subitem => {
  const { id, name, seniorities, remote, lastHiredDate, hiredCount } = position;

  const seniorityLabel = seniorities
    .map(seniority => SENIORITY_OPTIONS.find(({ value }) => value === seniority)?.label)
    .join('-');

  const remoteLabel = remote
    .map(type => REMOTE_OPTIONS.find(({ value }) => value === type)?.label)
    .join('/');

  const hiredInLast5Days = isDateWithinDays(5, lastHiredDate as string);

  return {
    id,
    name,
    badge: hiredInLast5Days
      ? {
          text: `hired ${getTimeAgo(lastHiredDate)}`,
          type: 'info_light'
        }
      : undefined,
    label: `${seniorityLabel} · ${remoteLabel}`,
    metrics: [
      {
        icon: 'icon_hired',
        text: pluralise(hiredCount, 'hire', 'hires')
      }
    ]
  };
};
