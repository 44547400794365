import styled from 'styled-components';

import Badge from 'components/badge';

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme: { colours, darkMode } }) =>
    darkMode ? colours.lightFontColour : colours.fontColour};
  font-weight: ${({ theme }) => theme.typography.regular};
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 0.75em 0.75em 0.375em 2.375em;
  height: 2.5em;
  white-space: nowrap;

  &:hover input:not(:checked) ~ span::after {
    display: block;
    background-color: ${({ theme: { colours, darkMode } }) =>
      darkMode ? colours.inputsDarkShade : colours.lightFontColour};
  }
`;

export const RadioText = styled.span`
  font-size: 1em;
  line-height: 1.5em;
  margin-top: 2px;
`;

export const InfoIcon = styled.span`
  &.icon_help {
    margin: 0 0 0 4px;
  }
`;

export const RadioBadge = styled(Badge)`
  &.badge {
    margin: 0 0 0 0.5em;
  }
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    &:after {
      display: block;
    }
  }
`;

export const RadioMark = styled.span`
  position: absolute;
  left: 0.75em;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  border: 1.5px solid
    ${({ theme: { colours, darkMode } }) =>
      darkMode ? colours.lightFontColour : colours.fontColour};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.75em;
    height: 0.75em;
    border-radius: 50%;
    background-color: ${({ theme: { colours, darkMode } }) =>
      darkMode ? colours.lightFontColour : colours.fontColour};
    display: none;
  }
`;
