import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ClassNames from 'classnames';

import { CandidateStatus } from '@cohiretech/common-types';

import { editProfileInfo, setVisible } from 'fetcher';

import BlockToggle from 'v2/components/ui/molecules/BlockToggle';

import { StatusProps, getToggleLabel } from './helpers';

export type StatusRef = {
  status: CandidateStatus;
};

const StatusBlockToggle = forwardRef((props: StatusProps, ref) => {
  const [status, setStatus] = useState(props.status || CandidateStatus.Active);

  const onStatusChange = async (active: boolean) => {
    const newStatus = active ? CandidateStatus.Active : CandidateStatus.Passive;

    setStatus(newStatus);

    if (props?.updateStatusOnChange) {
      const { status: apiStatus } = await editProfileInfo({ status: newStatus });
      if (active) await setVisible(active);

      if (apiStatus === 'success') props.getCandidateData?.();
      else setStatus(props.status);
    }
  };

  useImperativeHandle(ref, () => ({ status }), [status]);

  const { title, description } = getToggleLabel({ ...props, status });

  return (
    <BlockToggle
      className={ClassNames('profile_block status_block', props.className)}
      name="status"
      active={status === CandidateStatus.Active}
      size="large"
      title={title}
      description={description}
      onToggle={on => onStatusChange(on)}
    />
  );
});

export default StatusBlockToggle;
