export const validateEmailAddress = {
  candidateSignup: true,
  candidateChangeEmail: false,
  companySignup: true,
  companyRequestDemo: true,
  companyLiveDemo: true,
  companyInviteUser: false,
  companyChangeEmail: false,
  adminCreateCompany: false,
  adminCreateDemo: false
};
