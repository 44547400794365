import React, { useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import { useCandidateProfile } from 'store';
import { COUNTRY_CODES } from 'utils/localisation';

import AutoComplete from 'components/autocomplete';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type Props = {
  note?: string;
  question?: string;
  currentPhoneNumber?: string;
  currentCountryCode?: number;
  country?: string;
};

const countryCodeOptions = COUNTRY_CODES.map(({ name, dialCode }) => ({
  label: dialCode,
  HTMLLabel: (
    <div key={`${dialCode}_${name}`}>
      {dialCode}
      <span className="small_text">({name})</span>
    </div>
  ),
  value: Number(dialCode.slice(1)),
  name
}));

const getCountryCode = (currentCountryCode?: number, country?: string) => {
  const countryCodes = countryCodeOptions.filter(({ value, name }) =>
    currentCountryCode ? value === currentCountryCode : name === 'United Kingdom'
  );

  if (countryCodes.length === 1 || !country) return countryCodes[0];
  return countryCodes.find(({ name }) => name === country) || countryCodes[0];
};

export const PhoneNumberField = forwardRef(
  ({ note, question, currentPhoneNumber, currentCountryCode, country }: Props, ref) => {
    const { allowAgencyRecruiter } = useCandidateProfile() || {};
    const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber || '');
    const [phoneCountryCode, setPhoneCountryCode] = useState(
      getCountryCode(currentCountryCode, country)
    );

    useImperativeHandle(
      ref,
      () => {
        const countryCode = phoneCountryCode?.value;

        return {
          phoneCountryCode: countryCode ? Number(countryCode) : null,
          phoneNumber: phoneNumber || null
        };
      },
      [phoneCountryCode, phoneNumber]
    );

    return (
      <div className="field">
        {question && (
          <label aria-label={question} className="question">
            {question}
            <ConditionalRender
              predicate={allowAgencyRecruiter}
              fallback={note && <span>({note.toLowerCase()})</span>}
            >
              <span className="description">
                Will only be shared with an employer after a message request is accepted. cord will
                never share your phone number with an agency recruiter, apart from when you book a
                phone call invite.
              </span>
            </ConditionalRender>
          </label>
        )}
        <FieldWrapper>
          <AutoComplete
            id="phone_country_code"
            placeholder="Code"
            list={countryCodeOptions}
            selectedItems={[phoneCountryCode || '']}
            onSelect={item =>
              setPhoneCountryCode(
                item as { label: string; value: number; name: string; HTMLLabel: JSX.Element }
              )
            }
            showSelectionInInput
          />
          <input
            type="tel"
            className="phone_number"
            placeholder="Eg. 7894503982"
            value={phoneNumber}
            onChange={({ target }) => setPhoneNumber(target.value)}
          />
        </FieldWrapper>
      </div>
    );
  }
);

export default PhoneNumberField;

const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  .phone_number {
    margin-left: 10px;
  }

  .autocomplete_cont {
    min-width: 100px;
  }
`;
