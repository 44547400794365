import { batch } from 'react-redux';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AppDispatch,
  isCandidateProfile,
  removeCandidateTourBubble,
  setLoader,
  setProfile,
  setToggler,
  setUserType,
  toggleShowCandidateTour,
  useAppDispatch
} from 'store';
import { identifyCandidate } from 'tracking-utils';
import { CandidateProfile } from 'types';
import { setUser } from 'v2/services/app';
import { getCandidateProfile } from 'v2/services/fetchers/candidate/getCandidateProfile';

import { checkPWAOpened } from '..';

export const fetchCandidateData = createAsyncThunk<
  { profile: CandidateProfile | null },
  boolean | undefined,
  { dispatch: AppDispatch }
>('services/candidate/fetchData', async (initialLoad = false, { dispatch }) => {
  dispatch(setToggler({ route: 'loaders.fetchCandidateData', value: true }));
  const profile = await getCandidateProfile(initialLoad);

  if (!isCandidateProfile(profile)) return { profile };

  if (initialLoad) {
    runOnInitialLoad(profile);
  }

  dispatch(checkPWAOpened(profile));
  if (!profile?.isTechCandidate) dispatch(removeCandidateTourBubble('insights'));

  batch(() => {
    dispatch(setProfile(profile));
    dispatch(setUserType('candidate'));
    dispatch(
      toggleShowCandidateTour(
        profile?.homepageSetupComplete && !profile?.verified && !profile.verifiedDate
      )
    );
    dispatch(setLoader({ value: false }));
  });

  dispatch(setToggler({ route: 'loaders.fetchCandidateData', value: false }));
  return { profile };
});

const runOnInitialLoad = (profile: CandidateProfile) => {
  identifyCandidate(profile);
  setUser({
    name: `${profile.firstName} ${profile.lastName}`,
    email: profile.email,
    id: profile.id
  });
};
export const useCandidateData = () => {
  const dispatch = useAppDispatch();

  const reloadCandidateData = async () => {
    await dispatch(fetchCandidateData());
  };

  return { reloadCandidateData };
};
