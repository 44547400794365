import { getMinsHrsDaysWeeksMonthsfromTime, pluralise } from 'utils';

export function getLengthOfTime({
  days,
  hours,
  minutes
}: ReturnType<typeof getMinsHrsDaysWeeksMonthsfromTime>) {
  if (days > 0) return pluralise(days, 'day', 'days');
  if (hours > 0) return pluralise(hours, 'hour', 'hours');
  return pluralise(minutes, 'minute', 'minutes');
}

type MonthYear = {
  month: number;
  year: number;
};

export const extractMonthYear = (dateString: Date | string | null | undefined): MonthYear => {
  if (!dateString) {
    return { month: -1, year: -1 };
  }
  const date = new Date(dateString);
  const month = date.getMonth(); // Note: getMonth() returns 0-11 for Jan-Dec
  const year = date.getFullYear();
  return { month, year };
};
