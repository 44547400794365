import { ApexOptions } from 'apexcharts';

import { Currency, PositionSalaryRanges } from '@cohiretech/common-types';

import { formatSalary, getCurrencySymbol, getSalaryRange } from 'utils';
import { colours, typography } from 'styles/theme/common';

import { BAR_COLOR_DARK, BAR_COLOR_LIGHT } from '../PositionInsights.styled';

export const shouldShowSalaryComparisonBlock = (
  currency: Currency,
  salaryRanges?: PositionSalaryRanges
) => currency === Currency.GBP && salaryRanges?.market.avg;

export const getSalaryComparisonBlockTitle = ({ market, position }: PositionSalaryRanges) => {
  const currencySymbol = getCurrencySymbol();

  if (position?.visible) {
    const difference = position.max - market.max;

    if (difference > 0) {
      const formattedDiff = formatSalary(difference, currencySymbol);
      return `The max salary of this position is ${formattedDiff} more than similar positions`;
    }
  }

  const marketMin = formatSalary(market.min, currencySymbol);
  const marketMax = formatSalary(market.max, currencySymbol);

  return `Similar positions have a min salary of ${marketMin} and a max salary of ${marketMax}`;
};

const getSalaryComparisonChartInfo = ({ market, position }: PositionSalaryRanges) => {
  const categories: (keyof PositionSalaryRanges)[] = ['market'];
  let { min: xAxisMin, max: xAxisMax } = market;

  if (position?.visible) {
    categories.push('position');

    const { min, max } = position;

    if (xAxisMin > min) xAxisMin = min;
    if (xAxisMax < max) xAxisMax = max;
  }

  return { categories, xAxisRange: { min: Math.max(xAxisMin - 10000, 0), max: xAxisMax + 10000 } };
};

const getSalaryComparisonChartOptions = (
  xAxisRange: Pick<ApexXAxis, 'min' | 'max'>,
  barCount: number,
  darkMode: boolean
) => {
  const options = { ...getSalaryComparisonDefaultOptions(darkMode) };

  options.plotOptions!.bar!.barHeight = `${DEFAULT_BAR_HEIGHT * barCount}%`;
  options.xaxis = Object.assign(options.xaxis!, xAxisRange);

  return options;
};

/*
 * FYI, hidden bars for the range 0 - min (Series 0, 2) have been added
 * to achieve a bar chart that starts from the middle (min - max)
 */
export const getSalaryComparisonChartProps = (
  salaryRanges: PositionSalaryRanges,
  darkMode: boolean
) => {
  const series: ApexAxisChartSeries = [];
  const { categories, xAxisRange } = getSalaryComparisonChartInfo(salaryRanges);

  categories.forEach(category => {
    const range = salaryRanges[category];
    if (!range) return;

    const { min, max } = range;

    (['min', 'max'] as (keyof typeof range)[]).forEach(key => {
      series.push({
        name: `${category} ${key}`,
        group: `${category}`,
        data: [key === 'min' ? min : max - min]
      });
    });
  });

  return {
    series,
    options: getSalaryComparisonChartOptions(xAxisRange, categories.length, darkMode)
  };
};

const getSalaryRangeLabel: ApexDataLabels['formatter'] = (
  value,
  { seriesIndex, dataPointIndex, w }
) => {
  const isMinSeries = !(seriesIndex % 2);
  if (isMinSeries) return '';

  const min = w.config.series[seriesIndex - 1].data[dataPointIndex];
  return getSalaryRange(min, Number(value + min), undefined, undefined, undefined, 'short');
};

// DEFAULT_VALUES
const DATA_LABEL_COLORS = ['transparent', colours.primaryColour, 'transparent', colours.white];
const COLOR_STOPS = [[], BAR_COLOR_LIGHT, [], BAR_COLOR_DARK];
const DEFAULT_BAR_HEIGHT = 20;

const getSalaryComparisonDefaultOptions = (darkMode: boolean): ApexOptions => {
  return {
    chart: {
      fontFamily: `${typography.fontFamily}, sans-serif`,
      stacked: true,
      toolbar: { show: false }
    },
    stroke: { width: 5, colors: ['transparent'] },
    theme: { mode: darkMode ? 'dark' : 'light' },
    dataLabels: {
      formatter: getSalaryRangeLabel,
      style: { colors: DATA_LABEL_COLORS },
      offsetY: -2
    },
    fill: {
      type: 'gradient',
      gradient: { colorStops: COLOR_STOPS }
    },
    grid: {
      borderColor: darkMode ? colours.fontColour : colours.lightFontColour,
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
      padding: { top: -18, bottom: -8 }
    },
    legend: { show: false },
    tooltip: { enabled: false },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 15,
        borderRadiusWhenStacked: 'all',
        barHeight: `${DEFAULT_BAR_HEIGHT}%`
      }
    },
    xaxis: {
      tickAmount: 5,
      labels: { formatter: val => formatSalary(Number(val), '') },
      axisBorder: { show: true },
      axisTicks: { show: true },
      title: { text: 'Advertised salary range' }
    },
    yaxis: { show: false }
  };
};
