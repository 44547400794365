import { KeyboardEvent, KeyboardEventHandler, useMemo, useState } from 'react';

import { escapeRegExp, sortAutocompleteResults } from 'utils';
import { SearchItem } from 'v2/services/fetchers/candidate/search';
import { isEmpty } from 'utils/fn';
import { KeywordOption, KeywordOptions, RequiredToOptional } from 'types';
import { lastIndex } from 'utils/array';

type Keyword = string;

type KeywordState = { keyword: Keyword; cursor: number };

type OptionByKeyword = RequiredToOptional<KeywordOption, 'tags'>;

type OnSubmit = (option: OptionByKeyword) => void;

export type AutocompleteState = {
  searchValue: Keyword;
  cursor: number;
  searchKeywordResults: OptionByKeyword[];
  setKeywordState: (state: KeywordState) => void;
  autocompleteKeyword: (word: Keyword) => KeywordOptions;
  getExactKeywordMatch: (word: Keyword) => string;
  handleKeyDown: (onSubmit: OnSubmit) => KeyboardEventHandler<HTMLInputElement>;
};

export type AutocompleteParams = {
  searchItems: SearchItem[];
  keywordOptions: KeywordOptions;
  buildOptionByKeyword?: (word: Keyword) => OptionByKeyword;
};

export function useAutocomplete({
  searchItems,
  keywordOptions = [],
  buildOptionByKeyword
}: AutocompleteParams): AutocompleteState {
  const [keywordState, setKeywordState] = useState({ keyword: '', cursor: 0 });
  const { keyword, cursor } = keywordState;

  const availableOptions = useMemo(() => {
    if (isEmpty(searchItems)) return keywordOptions;
    return keywordOptions.filter(({ value }) => !searchItems.some(item => item.value === value));
  }, [keywordOptions, searchItems]);

  const autocompleteKeyword = (word: Keyword) => {
    let results = availableOptions.filter(({ tags }) =>
      tags.some(label => new RegExp(escapeRegExp(word.toLowerCase())).test(label.toLowerCase()))
    );

    if (word) results = sortAutocompleteResults(word, results, 'label');

    return results;
  };

  const searchKeywordResults = useMemo(() => {
    const results: OptionByKeyword[] = autocompleteKeyword(keyword);

    if (buildOptionByKeyword) results.unshift(buildOptionByKeyword(keyword));

    return results;
  }, [keyword]);

  const getExactKeywordMatch = (word: Keyword) => {
    const exactMatch = availableOptions.find(({ tags }) =>
      tags.some(label => word.toLowerCase() === label.toLowerCase())
    );

    return exactMatch ? exactMatch.label : '';
  };

  const handleOnEnter = (word: Keyword, onSubmit: OnSubmit) => {
    const focusedOption =
      searchKeywordResults[cursor] || searchKeywordResults[0] || buildOptionByKeyword?.(word);

    if (focusedOption) {
      onSubmit(focusedOption);

      setKeywordState({ keyword: '', cursor: 0 });
    }
  };

  const handleKeyDown = (onSubmit: OnSubmit) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (!['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) return;

    if (e.key === 'ArrowUp' && cursor > 0) {
      setKeywordState(prevState => ({ ...prevState, cursor: prevState.cursor - 1 }));
      return;
    }

    if (e.key === 'ArrowDown' && cursor !== lastIndex(searchKeywordResults)) {
      setKeywordState(prevState => ({ ...prevState, cursor: prevState.cursor + 1 }));
      return;
    }

    if (e.key === 'Enter') {
      handleOnEnter((e.target as HTMLInputElement).value, onSubmit);
    }
  };

  return {
    searchValue: keyword,
    cursor,
    searchKeywordResults,
    setKeywordState,
    autocompleteKeyword,
    getExactKeywordMatch,
    handleKeyDown
  };
}
