/* eslint-disable no-redeclare */

/**
 * When provided with a single key, returns a function that takes an object and returns the value at the
 * given key, or undefined if the object is null or undefined.
 * When provided with multiple keys, returns a function that takes an object and returns an array of values
 * at the given keys, or an array of undefined if the object is null or undefined.
 *
 * @template T - The object type
 * @template K - The keys of T
 * @param {K} key - A single key
 * @returns {(obj: T | null | undefined) => T[K] | undefined} - A function that takes an object and returns a single value or undefined
 */
function pluck<K extends keyof T, T = any>(key: K): (obj: T) => T[K];

/**
 * @template T - The object type
 * @template K - The keys of T
 * @param {...K[]} keys - Multiple keys
 * @returns {(obj: T | null | undefined) => (T[K] | undefined)[]} - A function that takes an object and returns an array of values or undefined
 */
function pluck<K extends keyof T, T = any>(
  ...keys: K[]
): (obj: T | null | undefined) => (T[K] | undefined)[];

function pluck<K extends keyof T, T = any>(...keys: K[]) {
  return (obj: T | null | undefined): (T[K] | undefined)[] | T[K] | undefined => {
    if (!obj) {
      return keys.length === 1 ? undefined : Array(keys.length).fill(undefined);
    }

    const values = keys.map((key: K) => obj[key]);
    return keys.length === 1 ? (values[0] as T[K]) : (values as T[K][]);
  };
}

export { pluck };
