import axios from 'axios';

import {
  CandidateApplicationTrackerGetByStageDTO as CandidateATGetByStageDTO,
  CandidateApplicationTrackerStage as CandidateATStage
} from '@cohiretech/common-types';

import { APIResponse, APISuccessResponse } from 'types';

const CANDIDATE_APPLICATION_TRACKER = process.env.REACT_APP_CANDIDATE_APPLICATION_TRACKER!;

type BaseCandidateATSItem = CandidateATGetByStageDTO['values'][number];

export type CandidateATSItem = Omit<BaseCandidateATSItem, 'lastUpdatedStageDate'> & {
  lastUpdatedStageDate: string;
};

type CandidateATSStageData = Pick<CandidateATGetByStageDTO, 'resultsCount'> & {
  values: CandidateATSItem[];
};

export const getCandidateATSStageData = async (
  stage: CandidateATStage,
  page = 0
): Promise<APIResponse<CandidateATSStageData>> => {
  try {
    const { data } = await axios.get<{ data: CandidateATSStageData }>(
      `${CANDIDATE_APPLICATION_TRACKER}/stage/${stage}`,
      { params: { page } }
    );

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getCandidateATSStageDataUpToPage = async (
  stage: CandidateATStage,
  currentPage: number
): Promise<APIResponse<CandidateATSStageData>> => {
  try {
    const pages = Array.from({ length: currentPage + 1 });
    const responses = (await Promise.all(
      pages.map((_, page) => getCandidateATSStageData(stage, page))
    )) as APISuccessResponse<CandidateATSStageData>[];

    const data = responses.reduce((acc, { data: { resultsCount, values } }) => {
      acc.values = (acc.values || []).concat(values);
      acc.resultsCount = resultsCount;

      return acc;
    }, {} as CandidateATSStageData);

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const updateCandidateATSItemStage = async (
  itemID: number,
  stage: CandidateATStage
): Promise<APIResponse> => {
  try {
    await axios.patch(`${CANDIDATE_APPLICATION_TRACKER}/${itemID}/stage`, { stage });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export type CandidateATSItemPatch = Pick<CandidateATSItem, 'hidden'>;

export const updateCandidateATSItem = async (
  itemID: number,
  update: CandidateATSItemPatch
): Promise<APIResponse> => {
  try {
    await axios.patch(`${CANDIDATE_APPLICATION_TRACKER}/${itemID}`, update);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
