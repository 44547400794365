import { CandidateApplicationTrackerStage as CandidateATStage } from '@cohiretech/common-types';

import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import {
  CandidateATSItem,
  CandidateATSItemPatch,
  getCandidateATSStageData,
  getCandidateATSStageDataUpToPage,
  updateCandidateATSItem,
  updateCandidateATSItemStage
} from 'v2/services/fetchers/candidate/ats';

export const fetchATSStageData = createAppAsyncThunk(
  'candidateATS/fetchAppBoardStageData',
  async (
    { stage, initialLoad }: { stage: CandidateATStage; initialLoad?: boolean },
    { getState }
  ) => {
    const { nextPage, reload } = getState().candidateATS.stagesState[stage];

    const getStrategy = reload ? getCandidateATSStageDataUpToPage : getCandidateATSStageData;
    const page = initialLoad ? 0 : nextPage;

    return getStrategy(stage, page);
  },
  {
    condition: ({ stage, initialLoad }, { getState }) => {
      const { loading, endPage } = getState().candidateATS.stagesState[stage];

      return !loading && (initialLoad || !endPage);
    }
  }
);

export type UpdateATSItemStageParams = { item: CandidateATSItem; stage: CandidateATStage };

export const updateATSItemStage = createAppAsyncThunk(
  'candidateATS/updateATSItemStage',
  async ({ item, stage }: UpdateATSItemStageParams) => {
    return updateCandidateATSItemStage(item.id, stage);
  }
);

export type UpdateATSItemParams = { item: CandidateATSItem; update: CandidateATSItemPatch };

export const updateATSItem = createAppAsyncThunk(
  'candidateATS/updateATSItem',
  async ({ item, update }: UpdateATSItemParams) => {
    return updateCandidateATSItem(item.id, update);
  }
);
