import { formatNumber } from 'utils';
import { isMobileWidth } from 'responsiveConfig';

import colours from 'css/base/_colours.module.scss';

import { GraphData } from '.';

// The length of x-axis
// The length will be percentile75 rounded up to the nearest 10.
// In case the calculated length is divisble by 10 it will be extended by 10 in length
const getGraphLength = (percentile75: number) => {
  const margin = 1000;
  const isDivisibleBy10 = percentile75 % 10 === 0;
  const nearest10 = isDivisibleBy10 ? percentile75 + 10 : Math.ceil(percentile75 / 10) * 10;
  return nearest10 + margin;
};

const getHiresGraphProps = ({ percentile25, percentile50, percentile75 }: GraphData) => {
  return {
    id: 'people_sourced_graph',
    xAxisProps: {
      tickTotal: isMobileWidth ? 3 : 9,
      tickFormat: (tick: number) => `£${formatNumber(tick)}`,
      style: {
        fontWeight: '500',
        fill: colours.inputsColour,
        fontSize: '12px'
      }
    },
    verticalGridLines: [
      // All vertical guidelines
      {
        tickTotal: 4,
        style: {
          stroke: colours.inputsColour,
          opacity: 0.6,
          strokeDasharray: '3, 3'
        }
      },
      // Main vertical line for 50th percentile
      {
        tickValues: [percentile50],
        style: {
          stroke: colours.primaryColour,
          strokeDasharray: '3, 3'
        }
      },
      // Bold marker on the main vertical line for 50th percentile
      {
        tickValues: [percentile50],
        height: 35,
        style: {
          stroke: colours.primaryColour,
          strokeWidth: 5
        }
      }
    ],
    seriesProps: {
      lineSeries: [
        // Range stroke from 25th to 50th percentile
        {
          data: [
            {
              x: percentile25,
              y: 10
            },
            {
              x: percentile50,
              y: 10
            },
            {
              x: percentile75,
              y: 10
            }
          ],
          opacity: 0.15,
          stroke: colours.primaryColour,
          style: {
            strokeWidth: 36,
            strokeLinecap: 'round'
          }
        },
        // The length of the x-axis
        {
          data: [
            {
              x: 0,
              y: 0
            },
            {
              x: getGraphLength(percentile75),
              y: 0
            }
          ],
          opacity: 0
        }
      ],
      labelSeries: {
        data: [
          // Label for 25th percentile
          {
            x: percentile25,
            xOffset: 0,
            yOffset: 1.5,
            y: 10,
            label: `£${formatNumber(percentile25)}`
          },
          // Label for 50th percentile
          {
            x: percentile50,
            y: 20,
            label: `£${formatNumber(percentile50)}`,
            style: {
              fill: colours.primaryColour,
              fontSize: '32px'
            },
            xOffset: 10,
            yOffset: 20
          },
          // Label for 75th percentile
          {
            x: percentile75,
            xOffset: 0,
            yOffset: 1.5,
            y: 10,
            label: `£${formatNumber(percentile75)}`,
            style: {
              textAnchor: 'end'
            }
          }
        ],
        style: {
          fontWeight: '700',
          fill: colours.primaryColour,
          fontSize: isMobileWidth ? '14px' : '18px'
        },
        labelAnchorY: 'middle',
        labelAnchorX: 'start'
      }
    }
  };
};

export default getHiresGraphProps;
