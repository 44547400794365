import {
  ListingApplicationBlockCriteriaInterface,
  GooglePlaceAddressComponentType,
  LocationInterface,
  ListingApplicationBlockCriteriaEntity
} from '@cohiretech/common-types';

import { SearchItems } from 'types';
import { getRemoteOptions } from 'fetcher';
import { addAttribute } from 'v2/services/tools/positionSearchFilter';
import { getRemoteDaysWithDescriptiveLabel } from 'v2/services/candidate';
import { pluck } from 'utils/object';
import { isEmpty } from 'utils';
import { isString, isUndefined } from 'utils/fn';

import { mapWorkEligibilitiesToOptionFormat } from './workEligibility';

export type BlockCriteriaType = 'workEligibilities' | 'locations' | 'remoteOptions' | 'remoteDays';

export type DisplayedBlockCriteriaType = Exclude<BlockCriteriaType, 'remoteDays'>;

export type BlockCriteria = Partial<Record<BlockCriteriaType, SearchItems>>;

export type CriteriaValidations = Record<BlockCriteriaType, boolean>;

export type MatchInfoKey = typeof MATCH_INFO_KEYS[number];

export const BLOCK_CRITERIA_TYPES: BlockCriteriaType[] = [
  'workEligibilities',
  'locations',
  'remoteOptions',
  'remoteDays'
];

export const BLOCK_CRITERIA_LABELS: Record<BlockCriteriaType, string> = {
  workEligibilities: 'Work eligibility',
  locations: 'Location',
  remoteOptions: 'Work preference',
  remoteDays: 'Work preference'
};

export const MATCH_INFO_KEYS = ['workEligibility', 'location', 'remote', 'remoteDays'] as const;

export const REMOTE_OPTIONS = getRemoteOptions().map(addAttribute('remoteOptions'));

export const REMOTE_DAYS_OPTIONS = getRemoteDaysWithDescriptiveLabel().map(
  addAttribute('remoteDays')
);

const CAMEL_TO_SNAKE_CRITERIA_TYPES: Record<
  BlockCriteriaType,
  keyof ListingApplicationBlockCriteriaEntity
> = {
  workEligibilities: 'work_eligibilities',
  locations: 'locations',
  remoteOptions: 'remote_options',
  remoteDays: 'remote_days'
};

export const CRITERIA_LOCATION_TYPES = [
  GooglePlaceAddressComponentType.admin_level_1,
  GooglePlaceAddressComponentType.admin_level_2,
  GooglePlaceAddressComponentType.country
];

export const DEFAULT_INVALID_CRITERIA_MESSAGE = "Criteria doesn't match position requirements.";

/** Reshape the raw API response for frontend consumption */
export const mapRawBlockCriteria = (rawBlockCriteria: any) => {
  const { work_eligibilities, locations, remote_options, remote_days } = rawBlockCriteria;

  return BLOCK_CRITERIA_TYPES.reduce((acc, type) => {
    const key = CAMEL_TO_SNAKE_CRITERIA_TYPES[type];

    if (isEmpty(rawBlockCriteria[key])) return acc;

    switch (type) {
      case 'workEligibilities':
        acc[type] = mapWorkEligibilitiesToOptionFormat(work_eligibilities);
        break;
      case 'locations':
        acc[type] = ((locations as LocationInterface[]) || []).map(location => ({
          label: location.formatted_address,
          value: location,
          attribute: type
        }));
        break;
      case 'remoteOptions':
        acc[type] = REMOTE_OPTIONS.filter(({ value }) => remote_options.includes(value));
        break;
      case 'remoteDays':
        acc[type] = REMOTE_DAYS_OPTIONS.filter(option => option.value === remote_days);
        break;
      default:
        break;
    }

    return acc;
  }, {} as BlockCriteria);
};

export const prepareBlockCriteriaForAPI = (blockCriteria: BlockCriteria) =>
  BLOCK_CRITERIA_TYPES.reduce((acc, type) => {
    const { [type]: items = [] } = blockCriteria;
    acc[type] = type === 'remoteDays' ? items[0]?.value : items.map(pluck('value'));

    return acc;
  }, {} as ListingApplicationBlockCriteriaInterface);

/** Compare two location objects and return true if they share a matching value for any of CRITERIA_LOCATION_TYPES */
export const isLocationMatching =
  (positionLocation: Partial<LocationInterface> | string) =>
  (criteriaLocation: Partial<LocationInterface>) =>
    CRITERIA_LOCATION_TYPES.some(type1 => {
      if (isUndefined(criteriaLocation[type1])) return false;
      if (isString(positionLocation)) return criteriaLocation[type1] === positionLocation;
      return CRITERIA_LOCATION_TYPES.some(
        type2 => criteriaLocation[type1] === positionLocation[type2]
      );
    });
