import React from 'react';
import styled from 'styled-components';

import { tablet, mobile } from 'responsiveConfig';
import { trackBookDemoEvents } from 'tracking-utils';

import PageSection from 'components/pagesection';
import HiresGraph from 'components/hiresgraph';
import ROIVideo from 'components/roivideo';

const HiresGraphSection = ({ darkMode }: { darkMode: boolean }) => {
  return (
    <SectionWrapper
      id="sourcing_calculator"
      className="center_alignment gradient_blue_background"
      title="Calculate the cost per hire you can achieve by using cord"
      subTitle="Active and verified people perform better in your pipeline than other sources, helping you hire a lot quicker."
      widthClassName="min_view"
    >
      <HiresGraph darkMode={darkMode} />
      <ROIVideo
        showBookDemo
        handleClickBookDemo={() =>
          trackBookDemoEvents('Book product tour | Live hires graph', 'click')
        }
      />
    </SectionWrapper>
  );
};

export default HiresGraphSection;

const SectionWrapper = styled(PageSection)`
  .page_section_title {
    margin-bottom: 0;

    @media only screen and (min-width: ${tablet}) {
      font-size: 45px;
    }

    @media only screen and (max-width: ${mobile}) {
      text-align: left;
    }
  }

  .page_section_subtitle {
    @media only screen and (min-width: ${tablet}) {
      margin-left: 100px;
      margin-right: 100px;
    }

    @media only screen and (max-width: ${mobile}) {
      text-align: left;
    }
  }

  .min_view {
    display: flex;
    flex-direction: column;
  }
`;
