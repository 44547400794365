import { NavigationMap, NavigationHeaders } from 'types';

import Messaging from 'images/messaging.svg';
import Sourcing from 'images/sourcing.svg';

export const NAV_MAP: NavigationMap = {
  [NavigationHeaders.ForPeople]: {
    links: [
      {
        id: 'people',
        label: 'How it works',
        path: '/people',
        icon: '',
        main: true,
        img: Messaging,
        msg: 'Direct message people hiring'
      },
      { id: 'hiring', label: 'Companies hiring', path: '/search/companies', icon: 'icon_company' },
      { id: 'techhub', label: 'Tech hub', path: '/techhub', icon: 'icon_knowledge' },
      {
        id: 'peoplesay',
        label: 'What people say',
        path: '/testimonials/people',
        icon: 'icon_feedback'
      },
      { id: 'salarytool', label: 'Salary tool', path: '/salary-tool', icon: 'icon_salary' },
      {
        id: 'peopleknowledge',
        label: 'Knowledge hub',
        path: 'https://people.cord.co',
        icon: 'icon_article'
      }
    ],
    visibleOnDesktop: true
  },
  [NavigationHeaders.ForCompanies]: {
    links: [
      {
        id: 'companies',
        label: 'How it works',
        path: '/companies',
        icon: '',
        main: true,
        img: Sourcing,
        msg: 'The hiring tool for making direct hires'
      },
      { id: 'pricing', label: 'Pricing', path: '/pricing', icon: 'icon_exchange_dollar' },
      {
        id: 'customerstories',
        label: '160+ Customer stories',
        path: '/stories',
        icon: 'icon_video'
      },
      { id: 'hires', label: 'Live hires', path: '/hires', icon: 'icon_hired' },
      {
        id: 'companiessaying',
        label: 'What companies say',
        path: '/testimonials/companies',
        icon: 'icon_feedback'
      },
      { id: 'partners', label: 'Partnerships', path: '/partners', icon: 'icon_conversation' },
      {
        id: 'livecompanies',
        label: 'See live companies',
        path: '/search/companies',
        icon: 'icon_search'
      },
      {
        id: 'bookademo',
        label: 'Book a demo',
        path: '/request-demo',
        icon: 'icon_demo'
      },
      {
        id: 'hiringinsights',
        label: 'Hiring insights',
        path: '/hiring-insights',
        icon: 'icon_article'
      }
    ],
    visibleOnDesktop: true
  },
  [NavigationHeaders.About]: {
    links: [
      { id: 'mission', label: 'Our mission', path: '/about' },
      { id: 'team', label: 'Our team', path: '/team' },
      { id: 'brand', label: 'Our brand', path: '/brand' },
      { id: 'careers', label: 'Work at cord', path: '/careers' },
      { id: 'partners', label: 'Partners', path: '/partners' },
      { id: 'conversations', label: 'Conversation code', path: '/conversations' }
    ],
    visibleOnDesktop: false
  }
};
