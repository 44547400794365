import styled, { css } from 'styled-components';

import media from 'styles/media';

import PositionProfileView from 'views/positionprofile';

import IconButton from 'components/iconbutton';

import { NavigatableXPosition } from './NavigatablePositions.helpers';

export const NavigatablePosition = styled(PositionProfileView)<{ xPosition: NavigatableXPosition }>`
  &#position_view {
    transition: all 0.3s ease-in-out;
    background: none !important;
    z-index: 11 !important;

    ${({ xPosition }) =>
      (xPosition === 'left' || xPosition === 'right') &&
      css`
        z-index: 12 !important;
        opacity: 0;
        pointer-events: none;
        transform: translateX(${xPosition === 'left' ? '-100px' : '100px'});
        overflow-y: unset;

        .button_panel {
          margin-bottom: -205px !important;
        }
      `}

    .profile_content {
      padding-bottom: 100px;
    }
  }
`;

export const PseudoElementStyling = css`
  content: '';
  position: fixed;
  background-color: var(--background-block-primary);
  box-shadow: var(--drop-shadow-soft);
  border-radius: 40px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 6rem;
  width: 880px;
  height: 100%;
  top: 50%;
  left: 49.75%;
  transform: translate(-50%, -50%);
  opacity: 0.85;
  z-index: 8;

  ${media.mobile`
    width: calc(100vw - 10px);
  `}
`;

export const NavigatablePositionsWrapper = styled.div<{
  allowNavigatePreviousButton: boolean;
  allowNavigateNextButton: boolean;
}>`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 7;
  background: var(--background-body-secondary);
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch; /* 'momentum style of scrolling on iOS'*/

  &::before {
    ${PseudoElementStyling}
    margin-top: 8rem;
    width: 920px;
    opacity: 0.75;

    ${media.mobile`
    width: 100vw
  `}
  }

  &::after {
    ${PseudoElementStyling}
  }

  ${({ allowNavigatePreviousButton }) =>
    !allowNavigatePreviousButton &&
    css`
      .icon_arrow_left {
        opacity: 0;
      }
    `}

  ${({ allowNavigateNextButton }) =>
    !allowNavigateNextButton &&
    css`
      .icon_arrow_right {
        opacity: 0;
      }
    `}

    ${media.mobile`
        z-index: 12;
    `}
`;

export const NavigateButtonStyling = css`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 28px;
  background: var(--background-block-primary);
  box-shadow: 0px 4px 16px 0px var(--shadow-soft);
  padding: 20px;
  z-index: 13;

  &:hover,
  &.dark:hover {
    box-shadow: 0px 4px 16px 0px var(--shadow-ultra-soft);
    background: var(--background-block-primary);
  }
`;

export const NavigatePrevious = styled(IconButton)`
  ${NavigateButtonStyling}
  left: calc(50vw - 560px);

  ${media.smallLaptop`
left: 2rem;
  `}
`;

export const NavigateNext = styled(IconButton)`
  ${NavigateButtonStyling}
  right: calc(50vw - 560px);

  ${media.smallLaptop`
right: 2rem;
  `}
`;
