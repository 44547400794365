import { CompanySubscriptionPlan, OperatingCountry, PricingMode } from '@cohiretech/common-types';

import {
  SubscriptionPlan,
  NewPricingOptions,
  V3PricingOptions,
  V4PricingOptions,
  PricingCountry,
  V5PricingOptions,
  PricingVer,
  PriceList,
  PayPerHirePricingOptions
} from 'types';

import { PRICING_V5_OPTIONS, V5_PRICE_LIST, V5_STANDARD_PLANS } from './pricingv5';

export const PRICING_MODE =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? PricingMode.Live : PricingMode.Test;

export const PRICING_OPTIONS = {
  earlyHires: {
    label: 'Early Hires',
    value: CompanySubscriptionPlan.EarlyHires,
    UK: {
      annual: { oldPrice: '', newPrice: '', urlPath: '' },
      monthly: { oldPrice: '', newPrice: '£394.5', urlPath: 'a28a07aa' }
    },
    US: {
      annual: { oldPrice: '', newPrice: '', urlPath: '' },
      monthly: { oldPrice: '', newPrice: '$689', urlPath: 'ussmbeh' }
    }
  },
  startUp: {
    label: 'Start-up & Scale-up',
    value: CompanySubscriptionPlan.StartUp,
    UK: {
      annual: { oldPrice: '', newPrice: '£7,890', savings: '£1,578', urlPath: '' },
      monthly: { oldPrice: '', newPrice: '£789', urlPath: 'fgpr935q' }
    },
    US: {
      annual: { oldPrice: '', newPrice: '$12,890', savings: '$2,578', urlPath: '' },
      monthly: { oldPrice: '', newPrice: '$1,289', urlPath: 'ussmb1289' }
    }
  },
  enterprise: {
    label: 'Enterprise',
    description: '(unlimited users)',
    value: CompanySubscriptionPlan.Enterprise,
    UK: {
      annual: { oldPrice: '', newPrice: '£12,890', savings: '£2,578', urlPath: '' },
      monthly: { oldPrice: '', newPrice: '£1,289', urlPath: 'edc0dade' }
    },
    US: {
      annual: { oldPrice: '', newPrice: '$24,890', savings: '$4,978', urlPath: '' },
      monthly: { oldPrice: '', newPrice: '$2,489', urlPath: '' }
    }
  },
  midMarket: {
    label: 'Mid-market',
    description: '(per hiring seat)',
    value: CompanySubscriptionPlan.EnterprisePosition,
    UK: {
      annual: { oldPrice: '', newPrice: '£12,890', savings: '£2,578', urlPath: '' },
      monthly: { oldPrice: '', newPrice: '£1,289', urlPath: 'edc0dadeb' }
    },
    US: {
      annual: { oldPrice: '', newPrice: '$24,890', savings: '$4,978', urlPath: '' },
      monthly: { oldPrice: '', newPrice: '$2,489', urlPath: '' }
    }
  },
  enterprisePerUser: {
    label: 'Enterprise',
    description: '(per hiring seat)',
    value: CompanySubscriptionPlan.EnterprisePosition,
    UK: {
      annual: { oldPrice: '', newPrice: 'Contact us', urlPath: '' },
      monthly: { oldPrice: '', newPrice: 'Contact us', urlPath: '' }
    },
    US: {
      annual: { oldPrice: '', newPrice: 'Contact us', urlPath: '' },
      monthly: { oldPrice: '', newPrice: 'Contact us', urlPath: '' }
    }
  }
};

export const PRICING_PLAN_COMMON_DETAILS = {
  [NewPricingOptions.Starter]: {
    label: 'Starter',
    description: 'Built for early-stage companies hiring their first positions.',
    positions: 2
  },
  [NewPricingOptions.Growth]: {
    label: 'Growth',
    description: 'Built for start-ups growing their tech & product team.',
    positions: 6,
    users: 4,
    extraFeature: 'Slack integration, ATS integration and a dedicated Customer Success manager'
  },
  [NewPricingOptions.Scaling]: {
    label: 'Scaling',
    description: 'Built for recruitment teams hiring multiple roles at scale.',
    positions: 10,
    users: 5,
    extraFeature: 'advanced team analytics'
  }
};

export const FALLBACK_PRICES = {
  [NewPricingOptions.Starter]: { UK: 499, US: 699 },
  [NewPricingOptions.Growth]: { UK: 899, US: 1099 },
  [NewPricingOptions.GrowthPlus]: { UK: 1199, US: 1499 },
  [NewPricingOptions.Scaling]: { UK: 1499, US: 1899 },
  [NewPricingOptions.StarterV2]: { UK: 699, US: 899 },
  [NewPricingOptions.StarterV2Annual]: { UK: Math.round(699 * 0.75), US: Math.round(899 * 0.75) },
  [NewPricingOptions.GrowthV2]: { UK: 999, US: 1299 },
  [NewPricingOptions.GrowthV2Annual]: { UK: Math.round(999 * 0.75), US: Math.round(1299 * 0.75) },
  [NewPricingOptions.GrowthPlusV2]: { UK: 1199, US: 1499 },
  [NewPricingOptions.GrowthPlusV2Annual]: {
    UK: Math.round(1199 * 0.75),
    US: Math.round(1499 * 0.75)
  },
  [NewPricingOptions.ScalingV2]: { UK: 1499, US: 1899 },
  [NewPricingOptions.ScalingV2Annual]: { UK: Math.round(1499 * 0.75), US: Math.round(1899 * 0.75) }
};

export const FALLBACK_OVERAGE_PRICES = { UK: '£50', US: '$60' };

export const V3_PRICE_LIST: {
  [country in PricingCountry]: {
    [peopleSourced: number]: { price: number; discountedPrice: number };
  };
} = {
  [OperatingCountry.UK]: {
    20: { price: 789, discountedPrice: 592 },
    30: { price: 989, discountedPrice: 742 },
    40: { price: 1139, discountedPrice: 854 },
    50: { price: 1239, discountedPrice: 929 }
  },
  [OperatingCountry.US]: {
    20: { price: 949, discountedPrice: 712 },
    30: { price: 1149, discountedPrice: 862 },
    40: { price: 1299, discountedPrice: 974 },
    50: { price: 1399, discountedPrice: 1049 }
  }
};

export const V4_GROWTH_PRICE = {
  [OperatingCountry.UK]: { price: 789, discountedPrice: 592 },
  [OperatingCountry.US]: { price: 949, discountedPrice: 712 }
};

export const V4_UNLIMITED_MIN_PRICE = {
  [OperatingCountry.UK]: 1389,
  [OperatingCountry.US]: 1749
};

export const CHECKOUT_URL_PATHS = {
  [NewPricingOptions.Starter]: 'starter-1',
  [NewPricingOptions.Growth]: 'growth-1',
  [NewPricingOptions.GrowthPlus]: 'growth-2',
  [NewPricingOptions.Scaling]: 'scaling-1',
  [NewPricingOptions.Unlimited]: '',
  [NewPricingOptions.StarterV2]: 'v2-starter-1',
  [NewPricingOptions.StarterV2Annual]: 'v2-starter-annual',
  [NewPricingOptions.GrowthV2]: 'v2-growth-1',
  [NewPricingOptions.GrowthPlusV2]: 'v2-growth-2',
  [NewPricingOptions.GrowthV2Annual]: 'v2-growth-25-annual',
  [NewPricingOptions.GrowthPlusV2Annual]: 'v2-growth-35-annual',
  [NewPricingOptions.ScalingV2]: 'v2-scaling-1',
  [NewPricingOptions.ScalingV2Annual]: 'v2-scaling-annual',
  [NewPricingOptions.UnlimitedV2]: '',
  [V3PricingOptions.V3Growth20]: 'v3-growth-20',
  [V3PricingOptions.V3Growth30]: 'v3-growth-30',
  [V3PricingOptions.V3Growth40]: 'v3-growth-40',
  [V3PricingOptions.V3Growth50]: 'v3-growth-50',
  [V3PricingOptions.V3Unlimited]: '',
  [V4PricingOptions.V4Growth]: 'v4-growth',
  [V4PricingOptions.V4Unlimited]: '',
  [V5PricingOptions.V5Standard1]: 'v5-standard-1',
  [V5PricingOptions.V5Standard2]: 'v5-standard-2',
  [V5PricingOptions.V5Standard3]: 'v5-standard-3',
  [V5PricingOptions.V5Standard4]: 'v5-standard-4',
  [V5PricingOptions.V5Standard5]: 'v5-standard-5',
  [PayPerHirePricingOptions.PayPerHire]: 'pay-per-hire'
};

export const FOUNDER_PROGRAM_URL_PATH = 'v4-growth-founder';

export const OLD_PRICING_OPTIONS = [
  CompanySubscriptionPlan.EarlyHires,
  CompanySubscriptionPlan.StartUp,
  CompanySubscriptionPlan.Enterprise,
  CompanySubscriptionPlan.EnterprisePosition
];

export const NEW_PRICING_OPTIONS_V1 = [
  NewPricingOptions.Starter,
  NewPricingOptions.Growth,
  NewPricingOptions.GrowthPlus,
  NewPricingOptions.Scaling,
  NewPricingOptions.Unlimited
];

export const NEW_PRICING_OPTIONS_V2 = [
  NewPricingOptions.StarterV2,
  NewPricingOptions.StarterV2Annual,
  NewPricingOptions.GrowthV2,
  NewPricingOptions.GrowthV2Annual,
  NewPricingOptions.GrowthPlusV2,
  NewPricingOptions.GrowthPlusV2Annual,
  NewPricingOptions.ScalingV2,
  NewPricingOptions.ScalingV2Annual,
  NewPricingOptions.UnlimitedV2
];

export const V1_PRICING_EXTERNAL = 'V1_external';

export const V3_PRICING_OPTIONS = Object.values(V3PricingOptions);

export const V4_PRICING_OPTIONS = Object.values(V4PricingOptions);

export const ALL_NEW_PRICING_OPTIONS = [...NEW_PRICING_OPTIONS_V1, ...NEW_PRICING_OPTIONS_V2];

export const UNLIMITED_PLANS: SubscriptionPlan[] = [
  NewPricingOptions.Unlimited,
  NewPricingOptions.UnlimitedV2,
  V3PricingOptions.V3Unlimited,
  V4PricingOptions.V4Unlimited,
  V5PricingOptions.V5Standard5
];

export const EXCLUSIVE_FEATURES = {
  Growth: [
    {
      name: 'Slack integration',
      tooltip: {
        text: 'Get instant notifications when someone new matches your criteria or sends you a message request.',
        wider: true
      },
      popup: {
        subtitle:
          'Get instant notifications as soon as someone new matches your criteria or sends you a message request for a position on cord.',
        image: 'slack_integration_screenshot.png'
      }
    },
    {
      name: 'ATS integration',
      tooltip: {
        text: 'Link your positions & get the people you source on cord sent directly to your ATS.',
        wider: true
      },
      popup: {
        subtitle: 'Instantly send people sourced from cord directly to your ATS.',
        image: 'ats_integration_screenshot.png'
      }
    },
    {
      name: 'Dedicated CS manager',
      tooltip: { text: 'Unlimited onboarding sessions and ongoing account support' }
    }
  ],
  Scaling: [
    {
      name: 'Advanced team analytics',
      tooltip: {
        text: "See cord stats and understand your hiring team's success metrics & usage.",
        wider: true
      },
      popup: {
        subtitle:
          "Understand your hiring team's usage & performance. See analytics per user, per position or for the whole account.",
        image: 'advanced_team_analytics_screenshot.png'
      }
    }
  ],
  Unlimited: [
    { name: 'Invoice billing', tooltip: { text: 'Available on annual option' } },
    {
      name: 'Bespoke procurement & security',
      tooltip: { text: 'Tailored agreement and security questionnaire' }
    },
    { name: 'SSO', tooltip: { text: 'Manage user access at scale with secure single-sign on' } },
    { name: 'SLA', tooltip: { text: 'Custom Service-Level Agreement' } }
  ]
};

export const V3_TIER_PLAN_MAP: { [credit: number]: V3PricingOptions } = {
  20: V3PricingOptions.V3Growth20,
  30: V3PricingOptions.V3Growth30,
  40: V3PricingOptions.V3Growth40,
  50: V3PricingOptions.V3Growth50
};

export const STANDARD_PLAN_LIST = { 5: V5_STANDARD_PLANS };

export const STANDARD_PLAN_PRICE_LIST: { [ver in PricingVer]: PriceList } = { 5: V5_PRICE_LIST };

export const PRICING_PLAN_OPTIONS = {
  5: PRICING_V5_OPTIONS
};
