import React, { HTMLProps, PropsWithChildren } from 'react';
import ClassNames from 'classnames';
import { NavLink as Link, NavLinkProps } from 'react-router-dom';

import Loader from 'components/loader';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

type Props = {
  size?: 'small' | 'medium' | 'large' | '';
  color?: 'light_grey' | 'light_blue' | 'dark_blue' | 'dark_grey' | 'white' | 'dark';
  tooltip?: string;
  rounded?: boolean;
  formInput?: boolean;
  to?: NavLinkProps['to'];
  className?: string;
  selected?: boolean;
  loading?: boolean;
  disabled?: boolean;
  draggingOver?: boolean;
  makeSpaceLeft?: boolean;
  makeSpaceRight?: boolean;
  ariaLabel?: HTMLProps<HTMLElement>['aria-label'];
  icon?: string;
} & Pick<
  HTMLProps<HTMLButtonElement>,
  'draggable' | 'onClick' | 'onDrop' | 'onDragStart' | 'onDragOver'
>;

export default function SelectableButton({
  draggable = false,
  draggingOver,
  makeSpaceLeft,
  makeSpaceRight,
  size,
  color,
  rounded,
  children,
  tooltip,
  formInput,
  to,
  className,
  selected,
  loading,
  disabled = false,
  ariaLabel,
  icon,
  onClick,
  onDrop,
  onDragStart,
  onDragOver
}: PropsWithChildren<Props>) {
  const commonClassName = ClassNames('selectable_wrapper', className, color, size, {
    form_input: formInput,
    rounded
  });

  if (to) {
    return (
      <Link className={commonClassName} to={to} activeClassName="selected" aria-label={ariaLabel}>
        {children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      tabIndex={0}
      className={ClassNames(commonClassName, {
        show_tooltip: tooltip,
        selected,
        disabled,
        make_space_left: draggingOver && makeSpaceLeft,
        make_space_right: draggingOver && makeSpaceRight
      })}
      onClick={disabled || loading ? undefined : onClick}
      aria-label={ariaLabel}
      {...(draggable ? { draggable, onDrop, onDragStart, onDragOver } : {})}
    >
      <ConditionalRender
        predicate={!loading}
        fallback={
          <>
            <Loader className={ClassNames('button_loader', 'small')} />
            <div className="hidden">{children}</div>
          </>
        }
      >
        <ConditionalRender
          predicate={tooltip && formInput}
          fallback={[
            <ConditionalRender key={`conditional_${icon}`} predicate={icon}>
              <span className={`icon_${icon}`} />
            </ConditionalRender>,
            children
          ]}
        >
          <span className="overflow_hidden">{children}</span>
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender predicate={tooltip}>
        <Tooltip text={tooltip} />
      </ConditionalRender>
    </button>
  );
}
