import { UserType } from '@cohiretech/common-types';

export const decodeCompanyProfile = ({ positions, ...rest }: $TSFixMe) => {
  return {
    profileType: UserType.Company,
    positions: positions.map(
      ({ jobTitles, primarySkills, secondarySkills, ...other }: $TSFixMe) => {
        return {
          jobTitles: jobTitles && jobTitles.length > 0 ? jobTitles.filter((t: $TSFixMe) => t) : [],
          primarySkills:
            primarySkills && primarySkills.length > 0
              ? primarySkills.filter((s: $TSFixMe) => s)
              : [],
          secondarySkills:
            secondarySkills && secondarySkills.length > 0
              ? secondarySkills.filter((s: $TSFixMe) => s)
              : [],
          ...other
        };
      }
    ),
    ...rest
  };
};
