import React, { useEffect } from 'react';
import { History, Location } from 'history';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { Frequency } from '@cohiretech/common-types';

import { CompanyProfile, PricingCountry } from 'types';
import { getLocalStorageItem } from 'cookieManager';
import { getCurrencyFromLocation, getQueryStringObject } from 'utils';
import { getV3BasicPrice } from 'utils/pricingUtils';
import { isTrialPage } from 'utils/url';
import useLocalisation from 'hooks/useLocalisation';
import useEventListener from 'hooks/useEventListener';
import { useDarkMode } from 'hooks/useDarkMode';

import BulletList, { Bullet } from 'components/bulletlist';
import CustomerStorySliderSection from 'components/customerstoryslidersection';
import PageSection from 'components/pagesection';
import Seo from 'components/seo';
import TwoColumnSection from 'components/twocolumnsection';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { BoldText } from 'v2/components/ui/styles';
import ROISection from 'v2/components/ui/organisms/ROISection';

import HubSpotCalendar from './hubspotcalendar';
import RequestDemoForm from './demoform';
import {
  trackBooking,
  getHeading,
  getSEOContent,
  FEATURE_TITLES,
  isReferredByCompany,
  REQUEST_DEMO_FEATURES,
  BOOK_A_CALL_FEATURES
} from './helpers';

type RequestDemoPageProps = {
  history: History;
  // WIP - After release: $TSFixMe
  location: Location<{ from: string; period?: Frequency; pricingType?: PricingCountry } | $TSFixMe>;
  profile?: CompanyProfile | null;
};

const RequestDemoPage = ({ history, location, profile }: RequestDemoPageProps) => {
  const darkMode = useDarkMode();
  const pricingCountry = useLocalisation(location.state?.pricingType) as PricingCountry;
  const { plan, referrer, type, c, ca_id } = getQueryStringObject(location.search) || {};
  const referredByCompany = isReferredByCompany(referrer);
  const onTrialPage = isTrialPage(location.pathname);
  const trialPrice = getV3BasicPrice(pricingCountry);
  const isBookACall = location.pathname.includes('book-a-call');
  const showForm = type === 'form';
  const currency = getCurrencyFromLocation(pricingCountry);

  useEventListener('message', e =>
    trackBooking(e, plan, location.state || {}, pricingCountry, !!profile?.companyID)
  );

  useEffect(() => {
    document.body.classList.add('public');
  }, []);

  const leftColumnProps = {
    ...getHeading(onTrialPage, referredByCompany, trialPrice, isBookACall, currency),
    children: FeatureList(onTrialPage, isBookACall)
  };

  const { c: companyID, ca_id: candidateID } = getLocalStorageItem('referrerDetails') || {};
  const referrerID = Number(c) || Number(ca_id) || companyID || candidateID;

  return (
    <RequestDemo>
      <TwoColumnSection
        className={showForm ? 'with_form' : 'with_calendar'}
        leftColumn={leftColumnProps}
      >
        <ConditionalRender
          predicate={showForm}
          fallback={
            <HubSpotCalendar referrerID={referrerID} referredByCompany={referredByCompany} />
          }
        >
          <RequestDemoForm />
        </ConditionalRender>
      </TwoColumnSection>
      <ConditionalRender predicate={onTrialPage} fallback={<ROISection />}>
        <PageSection
          className={ClassNames(
            'center_alignment',
            darkMode ? 'blue_background' : 'gradient_blue_background'
          )}
          widthClassName="min_view"
          title="Ready to get started?"
          subTitle={`Sign-up now and start using cord for ${trialPrice}/month including 20 sourcing credits/month and unlimited in your first month.`}
          subTitleClassName="small_section_description"
          button={{
            text: 'Start using cord',
            className: 'twenty_px_font',
            buttonStyle: darkMode ? 'stroke_white' : 'fill_blue',
            action: () => history.push(`${pricingCountry.toLowerCase()}/checkout/v3-growth-20`)
          }}
        />
      </ConditionalRender>
      <CustomerStorySliderSection
        showStoriesButton
        showCompanyLogos={false}
        className={onTrialPage ? 'no_radius' : ''}
      />
      <Seo
        {...getSEOContent(referredByCompany, isBookACall, currency)}
        path={`${location.pathname}${location.search}`}
        contentType="website"
        image="https://assets.co-hire.com/make-direct-hires.png"
      />
    </RequestDemo>
  );
};

const FeatureList = (onTrialPage: boolean, isBookACall: boolean) => {
  if (onTrialPage) {
    return (
      <BulletList>
        {FEATURE_TITLES.map((title, idx) => (
          <BulletItem
            key={`plan_feature_${idx}`}
            title={title}
            iconSize={28}
            iconCircleColour="light"
          />
        ))}
      </BulletList>
    );
  }

  const features = isBookACall ? BOOK_A_CALL_FEATURES : REQUEST_DEMO_FEATURES;

  return (
    <FeatureBullets>
      {features.map(({ title, description }, idx) => (
        <BulletItem key={`plan_feature_${idx}`} iconSize={28} iconCircleColour="light">
          <FeatureText>
            <BoldText>{title}</BoldText>
            {description}
          </FeatureText>
        </BulletItem>
      ))}
    </FeatureBullets>
  );
};

const FeatureText = styled.span`
  ${({ theme: { typography } }) => css`
    font-size: ${typography.smallheader};

    ${BoldText} {
      font-weight: ${typography.black};
      margin-right: 0.5rem;
    }
  `}
`;

const RequestDemo = styled.div`
  .bullet_list {
    width: 100%;
    margin-bottom: 10px;
  }

  .page_section .button.fill_blue {
    color: var(--color-neutral-white);
  }

  .page_section_title {
    font-size: 3rem;
  }
`;

const BulletItem = styled(Bullet)`
  align-items: center;
  padding-bottom: 14px;

  h3 {
    font-size: 1rem;
  }
`;

const FeatureBullets = styled(BulletList)`
  .bullet_item {
    align-items: flex-start;
  }

  p {
    margin: 0;
  }
`;

export default RequestDemoPage;
