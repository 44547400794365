import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { downloadCordCV } from 'v2/services/candidate';

import DialogWindow from 'components/dialogwindow';

type CVDownloadPromptProps = {
  isProfileUpdated: boolean;
  setProfileUpdated: (isProfileUpdated: boolean) => void;
  isVerified: boolean;
};

const CVDownloadPrompt = ({
  isProfileUpdated,
  setProfileUpdated,
  isVerified
}: CVDownloadPromptProps) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(
    !!getLocalStorageItem('dontShowCVDownloadPrompt')
  );
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadCV = async () => {
    setIsDownloading(true);
    await downloadCordCV(true);
    setIsDownloading(false);
    closePrompt();
  };

  const closePrompt = () => {
    setShowPrompt(false);
    setProfileUpdated(false);
  };

  const toggleDontShowAgain = (checked: boolean = !dontShowAgain) => {
    setLocalStorageItem('dontShowCVDownloadPrompt', checked);
    setDontShowAgain(checked);
  };

  useEffect(() => {
    if (!isProfileUpdated) return;

    const unblock = history.block(() => {
      setShowPrompt(!dontShowAgain && isVerified);
    });

    return () => unblock();
  }, [isProfileUpdated, history]);

  return (
    <CVDownloadDialog
      id="cv_download_prompt"
      title="Download your updated CV?"
      subTitle="Your cord CV is updated to include all your recent profile changes. Would you like to download your latest cord CV?"
      visible={showPrompt}
      showCloseIcon
      onClose={closePrompt}
      primaryButton={{
        text: 'Skip',
        action: closePrompt,
        buttonStyle: 'stroke_blue'
      }}
      secondaryButton={{
        text: 'Download as PDF',
        icon: 'icon_download',
        action: downloadCV,
        buttonStyle: 'primary',
        loading: isDownloading
      }}
      checkbox={{
        checkboxID: 'skip_cv_download_prompt',
        label: 'Don’t show this again',
        onChange: toggleDontShowAgain
      }}
    />
  );
};

const CVDownloadDialog = styled(DialogWindow)`
  &&& {
    .pop_up_title {
      font-size: 2em;
    }
    .icon.left {
      font-weight: ${({ theme: { typography } }) => typography.black};
      margin-right: 8px;
    }
  }
`;

export default CVDownloadPrompt;
