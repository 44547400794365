import React from 'react';
import styled from 'styled-components';
import { Location } from 'history';

import Seo from 'components/seo';

import typography from 'css/base/_typography.module.scss';

export default function Contact({ location }: { location: Location }) {
  return (
    <div id="contact">
      <div className="intro_wrapper">
        <div className="min_view center_alignment">
          <h1 className="heading">Contact us</h1>
          <Address>
            <h3>
              <strong>Support / Questions</strong>
              <div>
                <a
                  className="lightblue_link"
                  href="mailto:support@cord.co"
                  aria-label="support email address"
                >
                  support@cord.co
                </a>
              </div>
            </h3>
            <h3>
              <strong>London</strong>
            </h3>
            <div>134 Tooley Street, London SE1 2TU, UK</div>
            <h3>
              <strong>New York</strong>
            </h3>
            <div>336 W 37th Street, Suite 200, New York, NY 10018, USA</div>
          </Address>
        </div>
      </div>
      <Seo
        title="Talk to us"
        description="Get in touch with the team of cord. Ask for support or any question you have about our community"
        path={location.pathname}
        contentType="website"
      />
    </div>
  );
}

const Address = styled.address`
  font-size: ${typography.smallheader};

  h3 {
    font-size: ${typography.header};
    margin-bottom: 0;
  }
`;
