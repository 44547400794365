import { RequestDemoForm } from 'types';

export type FieldItem = { key: keyof RequestDemoForm; label: string; placeholder: string };

export const FIELD_GROUPS: FieldItem[][] = [
  [
    { key: 'firstName', label: 'First name*', placeholder: 'Eg. Mario' },
    { key: 'lastName', label: 'Last name*', placeholder: 'Eg. Johnson' }
  ],
  [{ key: 'email', label: 'Work email address*', placeholder: 'Eg. mario@cord.co' }]
];

export const validate = (form: RequestDemoForm) => {
  const { firstName, lastName, email, jobType } = form;

  if (!firstName) return 'Please enter your first name.';
  if (!lastName) return 'Please enter your last name.';
  if (!email) return 'Please enter your email address.';
  if (!jobType) return "Please select the type of position you're hiring for";
};
