import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import media from 'styles/media';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import { PageView } from 'v2/components/ui/styles';

import CompanyLogosRow from './companylogosrow';
import LeftColumn, { Props as LeftColumnProps } from './leftcolumn';

type Props = {
  leftColumn: PropsWithChildren<LeftColumnProps>;
  className?: string;
};

export default function TwoColumnSection({
  leftColumn,
  className,
  children
}: PropsWithChildren<Props>) {
  const isTablet = useMedia([mediaQueries.tablet]);

  return (
    <PageView>
      <Container className={ClassNames('columns_container', className)}>
        <LeftColumn {...leftColumn} />
        <RightColumn className="right_column">{children}</RightColumn>
        <CompanyLogosRow show={isTablet} />
      </Container>
    </PageView>
  );
}

const RightColumn = styled.div`
  flex-basis: calc(55% - 20px);
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 50px;
  margin-bottom: 70px;

  &.with_form {
    align-items: center;
    justify-content: space-between;

    .right_column {
      flex-basis: calc(45% - 20px);
    }
  }

  ${media.tabletLandscape`
    justify-content: space-between;
    margin-bottom: 40px;

    &:not(.with_form) .left_column {
      padding: 0;
      flex-basis: calc(60% - 20px);
    }

    &:not(.with_form) .right_column {
      flex-basis: calc(40% - 20px);
      min-width: 300px;
    }

    &.with_form .right_column {
      flex-basis: calc(50% - 20px);
    }
  `}

  ${media.tablet`
    flex-direction: column;
    padding-top: 0;
  `}

  ${media.smallMobile`
    margin-bottom: 20px;
  `}
`;
