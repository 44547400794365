/**
 * Get the last element of an array
 *
 * @param arr the array to get the last element of
 * @returns the last element of the array or undefined if the array is empty
 */
export function last<T = any>(arr: T[]): T | undefined {
  if (!arr.length) return undefined;
  return arr[arr.length - 1];
}

/**
 * Get the last index of an array
 *
 * @param arr the array to get the last index of
 * @returns the last index of the array or 0 if the array is empty
 */
export function lastIndex<T = any>(arr: T[]): number {
  if (!arr.length) return 0;
  return arr.length - 1;
}
