import { useEffect, useMemo, useState } from 'react';

import { WorkEligibility } from '@cohiretech/common-types';

import {
  getWorkEligibilitiesForListing,
  GetWorkEligibilitiesParams
} from 'v2/services/fetchers/company/workEligibilities';
import { isSuccess } from 'v2/services/fetchers/apiTools';
import { isEmpty } from 'utils';
import { getVisaRemoteInfo, isRemote } from 'v2/services/company/position';
import { isEqual, pluck } from 'utils/object';
import {
  BLOCK_CRITERIA_TYPES,
  BlockCriteria,
  BlockCriteriaType,
  mapRawBlockCriteria,
  mapWorkEligibilitiesToOptionFormat,
  CriteriaValidations,
  isLocationMatching,
  BLOCK_CRITERIA_LABELS,
  DEFAULT_INVALID_CRITERIA_MESSAGE
} from 'v2/services/company/ApplicationBlockCriteria';
import { PositionInProfile, VisaRemoteInfo } from 'types';
import { partition } from 'utils/array';
import { getFullPositionLocationLabel } from 'v2/services/tools/positionInfo';

import { BoldText } from 'v2/components/ui/styles';

export const useApplicationBlockCriteria = () => {
  const [blockCriteria, setBlockCriteria] = useState<BlockCriteria>({});
  const [criteriaDisplayOrder, setCriteriaDisplayOrder] = useState<BlockCriteriaType[]>([]);
  const [visaRemoteInfo, setVisaRemoteInfo] = useState<VisaRemoteInfo>({});
  const [validWorkEligibilities, setValidWorkEligibilities] = useState<WorkEligibility[]>([]);
  const [criteriaValidations, setCriteriaValidations] = useState({} as CriteriaValidations);
  const [isEditMode, setIsEditMode] = useState(false);

  const { locationObject, remoteLocationContinents, remoteLocationCountries, remote } =
    visaRemoteInfo;

  const setInitialCriteriaAndVisaInfo = (position: PositionInProfile) => {
    const initialBlockCriteria = mapRawBlockCriteria(position.applicationBlockCriteria || {});
    const shownCriteriaTypes = BLOCK_CRITERIA_TYPES.filter(type => type !== 'remoteDays');
    const [typesWithoutValues, typesWithValues] = partition(shownCriteriaTypes, type =>
      isEmpty(initialBlockCriteria[type])
    );

    setBlockCriteria(initialBlockCriteria);
    setCriteriaDisplayOrder(typesWithValues.concat(typesWithoutValues));
    updateVisaRemoteInfo(getVisaRemoteInfo(position));
    setIsEditMode(!!position.id);
  };

  const updateVisaRemoteInfo = (updatedInfo: VisaRemoteInfo) => {
    if (isEmpty(updatedInfo)) return;

    setVisaRemoteInfo(prev => ({ ...prev, ...updatedInfo }));
  };

  const getValidWorkEligibilities = async (params: GetWorkEligibilitiesParams) => {
    const res = await getWorkEligibilitiesForListing(params);

    if (isSuccess(res)) setValidWorkEligibilities(res.data);
  };

  const validateBlockCriteria = (criteriaType: BlockCriteriaType, criteria: BlockCriteria) => {
    if (isEmpty(criteria[criteriaType])) return true;

    const criteriaValues = criteria[criteriaType]!.map(pluck('value'));

    switch (criteriaType) {
      case 'workEligibilities':
        return (
          criteriaValues.length >= validWorkEligibilities.length &&
          validWorkEligibilities.every(option => criteriaValues.some(isEqual(option)))
        );
      case 'locations':
        let valid;
        const isRemoteJob = isRemote(remote);

        if (locationObject) valid = criteriaValues.some(isLocationMatching(locationObject));
        if (valid || !isRemoteJob) return valid;

        const remoteLocations = (remoteLocationContinents || []).concat(
          remoteLocationCountries || []
        );

        if (isEmpty(remoteLocations)) return true;
        return remoteLocations.every(location => criteriaValues.some(isLocationMatching(location)));
      case 'remoteOptions':
        return remote?.every(option => criteriaValues.includes(option));
      case 'remoteDays':
        return visaRemoteInfo.remoteDays ? visaRemoteInfo.remoteDays >= criteriaValues[0] : false;
      default:
        return true;
    }
  };

  const updateCriteriaValidations = (criteria: BlockCriteria) => (prev: CriteriaValidations) =>
    BLOCK_CRITERIA_TYPES.reduce(
      (acc, type) => {
        acc[type] = !!validateBlockCriteria(type, criteria);

        return acc;
      },
      { ...prev }
    );

  const getInvalidCriteriaMessage = (validations: CriteriaValidations) => {
    for (const [key, value] of Object.entries(validations)) {
      // A warning will be shown for locations, but it won't prevent saving the position.
      if (key !== 'locations' && !value) {
        const criteriaName = BLOCK_CRITERIA_LABELS[key as BlockCriteriaType];
        return `${criteriaName} ${DEFAULT_INVALID_CRITERIA_MESSAGE.toLowerCase()}`;
      }
    }

    return '';
  };

  useEffect(() => {
    const { locationObject, remoteDays, ...params } = visaRemoteInfo;

    getValidWorkEligibilities(params);
  }, [visaRemoteInfo]);

  useEffect(() => {
    // Set default criteria only when creating a position
    if (!isEditMode) {
      setBlockCriteria(prev => ({
        ...prev,
        workEligibilities: mapWorkEligibilitiesToOptionFormat(validWorkEligibilities)
      }));
    }
  }, [validWorkEligibilities, isEditMode]);

  useEffect(() => {
    setCriteriaValidations(updateCriteriaValidations(blockCriteria));
  }, [blockCriteria, visaRemoteInfo, validWorkEligibilities]);

  const warningMessage = useMemo(() => {
    const locationLabel = getFullPositionLocationLabel(visaRemoteInfo);
    const isAnywhere = locationLabel.includes('anywhere');

    return {
      workEligibilities: `${DEFAULT_INVALID_CRITERIA_MESSAGE}${
        isAnywhere
          ? ' You have selected remote employees can be based anywhere in your position requirements.'
          : ''
      }`,
      locations: [
        'This position is ',
        <BoldText key="location_label">{locationLabel}</BoldText>,
        '. Make sure the location criteria is not blocking people that could be eligible to apply.'
      ]
    };
  }, [visaRemoteInfo]);

  return {
    blockCriteria,
    criteriaDisplayOrder,
    criteriaValidations,
    warningMessage,
    setBlockCriteria,
    setInitialCriteriaAndVisaInfo,
    updateVisaRemoteInfo,
    updateCriteriaValidations,
    getInvalidCriteriaMessage
  };
};
