import { Location } from 'history';
import { CANDIDATE_SEARCH_FILTERS, INVISIBLE_FILTERS } from 'consts/candidate/positionSearch';

import {
  CandidateSavedSearchInterface,
  CompanyType,
  CompanyTypeExtended,
  CompanyTypeSearchQueryParam
} from '@cohiretech/common-types';

import { COMPANY_TYPE_ICON_MAP, COMPANY_TYPE_WITH_ALL } from 'v2/services/company/companyType';
import { capitalizeFirst } from 'utils/string';
import { formatNumber, getSavedSearchIDFromURL, pluralise } from 'utils';
import { SearchItem, SearchItems } from 'types';
import { getDefaultSorting } from 'v2/services/candidate';
import {
  ANYWHERE_OPTIONS,
  checkIfRemoteFilter,
  getLabelWithFilterTitle,
  getSearchItemIndex,
  handleRemoteItemAdd,
  hasFilterWithAttribute
} from 'v2/services/tools/positionSearchFilter';
import { isArray } from 'utils/fn';
import { isPartialMatch } from 'utils/object';

import { TabType } from 'components/tabs';

import { PositionResultsCounts } from './PositionSearchResults.types';

const { Direct, External, Agency } = CompanyType;
const { All } = CompanyTypeExtended;

const COMPANY_TYPE_TAB_TOOLTIP: { [key in CompanyTypeSearchQueryParam]?: TabType['tooltip'] } = {
  [Direct]: { text: 'Positions from companies hiring on cord that you can message directly.' },
  [External]: { text: 'Open positions found by cord on the web that you can apply to.' },
  [Agency]: { text: 'Positions managed by recruitment agencies you can message on cord.' }
};

export const SORTING_FILTER = [getDefaultSorting()];

const getTabTitle = (companyType: CompanyTypeSearchQueryParam, counts: PositionResultsCounts) => {
  const allResults = pluralise(counts.all, 'position', 'positions', true);

  if (companyType === CompanyTypeExtended.All) return allResults;
  return `${formatNumber(counts[companyType])} ${companyType.toLocaleLowerCase()}`;
};

export const getPositionTypeTabs = (
  showCounts: boolean = true,
  counts: PositionResultsCounts
): TabType<CompanyTypeSearchQueryParam>[] => {
  return COMPANY_TYPE_WITH_ALL.map(value => ({
    title: showCounts ? getTabTitle(value, counts) : capitalizeFirst(value),
    value,
    icon: COMPANY_TYPE_ICON_MAP[value],
    tooltip: COMPANY_TYPE_TAB_TOOLTIP[value]
  }));
};

// CORD-4860 After review: Move all functions to the "tools/positionSearchFilter"
const {
  lowerTimeOffset,
  upperTimeOffset,
  keyword,
  sortBy,
  lastActive,
  lastActiveValue,
  lastActiveType
} = CANDIDATE_SEARCH_FILTERS;

const filterShouldGetReplaced = (attribute: string) =>
  hasFilterWithAttribute(
    [
      'salary',
      'lastActiveValue',
      'sortBy',
      'lastActiveType',
      'keyword',
      'companyName',
      'lowerTimeOffset',
      'upperTimeOffset'
    ],
    attribute
  );

const handleSearchItemAdd = (searchItems: SearchItems, newFilter: SearchItem) => {
  const { attribute, label: stringLabel, value } = newFilter;
  const filterExists = getSearchItemIndex(searchItems, newFilter) !== -1;

  if (filterExists) return searchItems;

  const searchItemsCopy = [...searchItems];

  const filterNeedsToBeReplaced = filterShouldGetReplaced(attribute);
  const idx = filterNeedsToBeReplaced
    ? searchItemsCopy.findIndex(item => item.attribute === attribute)
    : -1;

  const label = getLabelWithFilterTitle(stringLabel, attribute);
  const searchItem = { attribute, label, value };

  if (idx !== -1) searchItemsCopy.splice(idx, 1, searchItem);
  else searchItemsCopy.push(searchItem);

  return searchItemsCopy;
};

export const handleSearchItemsAdd = (
  searchItems: SearchItems,
  newFilters: SearchItem | SearchItems
) => {
  const isArr = isArray(newFilters);
  const isLastActive = !isArr && newFilters.attribute === lastActive.attribute;
  const parsedFilters = isLastActive ? parseLastActiveItem(newFilters) : newFilters;

  if (!isArray(parsedFilters)) return handleSearchItemAdd(searchItems, parsedFilters);
  return parsedFilters.reduce((acc, item) => handleSearchItemAdd(acc, item), searchItems);
};

export const getSearchItemsOnAdd = (
  searchItems: SearchItems,
  newFilters: SearchItem | SearchItems
) => {
  const isRemoteFilter = !isArray(newFilters) && checkIfRemoteFilter(newFilters.attribute);

  if (isRemoteFilter) return handleRemoteItemAdd(searchItems, newFilters);
  return handleSearchItemsAdd(searchItems, newFilters);
};

export const addTimezoneFilter = (
  searchItems: SearchItems,
  timezoneValues: { lowerValue: number; upperValue: number },
  callbackFn: (newFilters: SearchItems) => void
) => {
  const { lowerValue, upperValue } = timezoneValues;
  const lowerTimeOffsetAttr = lowerTimeOffset.attribute;
  const upperTimeOffsetAttr = upperTimeOffset.attribute;

  const timezoneItems = [
    {
      attribute: lowerTimeOffsetAttr,
      label: `UTC ${lowerValue}:00`,
      value: lowerValue
    },
    {
      attribute: upperTimeOffsetAttr,
      label: `UTC ${upperValue}:00`,
      value: upperValue
    }
  ];

  const updatedSearchItems = getSearchItemsOnAdd(searchItems, timezoneItems);

  callbackFn(updatedSearchItems);
};

const parseLastActiveItem = (lastActiveItem: SearchItem) => {
  const { label, value } = lastActiveItem;
  const intValue = (value as string).match(/(^\d+)/)?.[0];
  const typeValue = (value as string).match(/(\w+$)/)?.[0];

  // Should send 2 parameters, such as: last_active_value : 3, last_active_type : "day"
  return [
    {
      attribute: lastActiveValue.attribute,
      label: `Last active: ${label}`,
      value: Number(intValue)
    },
    { attribute: lastActiveType.attribute, label: '', value: typeValue }
  ];
};

export const getVisibleFilters = (searchItems: SearchItems = []) =>
  searchItems.filter(
    item =>
      !(
        hasFilterWithAttribute(INVISIBLE_FILTERS, item.attribute) ||
        // Prevent from showing 2 anywhere filter tags with different attributes: remoteLocationContinents, remoteLocationCountries
        isPartialMatch(ANYWHERE_OPTIONS[1])(item)
      )
  );

export const getCurrentSavedSearch = (
  savedSearches: CandidateSavedSearchInterface[],
  location: Location
) => {
  const savedSearchID = getSavedSearchIDFromURL(location.pathname, false);
  const savedSearch = savedSearches.find(({ id }) => id === savedSearchID);

  if (savedSearch) return savedSearch;
  else return null;
};

export const parseSearchItems = (searchItems: SearchItems) => {
  return searchItems.filter(({ attribute }) =>
    Object.values(CANDIDATE_SEARCH_FILTERS).find(filter => filter.attribute === attribute)
  );
};

const isSortByAttribute = (attribute: string) =>
  attribute.includes(sortBy.attribute) || attribute.includes('sort_by');

export const getSearchName = (searchItems: SearchItems) => {
  const { attribute, label, value } =
    searchItems.find(({ attribute }) => !isSortByAttribute(attribute)) || {};

  return (attribute === keyword.attribute && value) || label || value || 'Search';
};

export const getEmptyProps = (currentTab: CompanyTypeSearchQueryParam) => {
  const allTab = currentTab === All;
  const positionType = allTab ? '' : currentTab;

  return {
    title: `No ${positionType} jobs found...`,
    description: `We haven't found any ${positionType} positions that match your filters.`,
    buttonText:
      allTab || positionType === External
        ? 'Reset your filters'
        : `Try ${positionType === Direct ? 'external' : 'direct'} positions`
  };
};
