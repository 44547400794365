import React from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import Button from 'components/button';

import NotFoundIcon from 'images/oversubscribed-icon-blue.svg';

export default function NotFound() {
  return (
    <NotFoundPage id="not_found" className={ClassNames('min_view', 'center_alignment')}>
      <Icon src={NotFoundIcon} alt="Not Found" />
      <h1 className="heading">Oops, Page not found</h1>
      <h2 className="subheading">Sorry, we can't find the page you're looking for.</h2>
      <Button text="Go back home" link="/" icon="icon_arrow_left" />
    </NotFoundPage>
  );
}

const NotFoundPage = styled.div`
  padding: 50px 0 !important;

  .button .icon_arrow_left {
    font-size: 1.1em;
  }
`;

const Icon = styled.img`
  max-height: 120px;
  margin-bottom: 30px;
`;
