import { DateArgument } from 'types';

export const hasDatePassed = (date: DateArgument, comparisonDate?: DateArgument): boolean => {
  const inputDate = new Date(date);

  if (comparisonDate) return inputDate < new Date(comparisonDate);

  const currentDate = new Date();
  return inputDate < currentDate;
};
