import React, { useEffect, useState } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';

import { SearchCategoryViewTypeEnum } from '@cohiretech/common-types';

import { trackSignupClicks } from 'tracking-utils';
import { search } from 'v2/services/fetchers/candidate/search';
import { getQueryStringObject, isProfileURL } from 'utils';

import Button from 'components/button';
import MultiAvatarPhotos from 'components/multiavatarphotos';
import Seo from 'components/seo';

import { ReactComponent as BlueBlob } from 'images/homepage/blue-blob.svg';
import { ReactComponent as PurpleBlob } from 'images/homepage/purple-blob.svg';

import PeopleBanner from './components/peoplebanner';
import {
  BlobBackgroundWrapper,
  BlurBox,
  TopSection,
  ContentWrapper,
  HomepageWrapper,
  OverlaySectionsWrapper,
  BottomCTASection
} from './homepage.styled';
import { companyCategories, CompanyTabQueries } from './homepage.helpers';
import CompanyCategoriesSection from './companiessection';
import FeaturesSection from './featuressection';
import TestimonialsSection from './testimonialssection';
import PositionSection from './positionsection';

export default function Homepage() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyTab, setCompanyTab] = useState(companyCategories[0].query);

  const location = useLocation();

  const getCompanyResults = async (companyTab: CompanyTabQueries) => {
    if (!loading) setLoading(true);

    const companyTabParams = companyCategories.find(({ query }) => query === companyTab);

    const { values } = await search({
      category: companyTabParams?.category || undefined,
      page: 0,
      passiveSearch: false,
      publicSearch: true,
      viewType: SearchCategoryViewTypeEnum.Company,
      searchItems: companyTabParams?.searchItems || [],
      companyType: companyTabParams?.companyType
    });

    setLoading(false);
    setCompanies(values);
  };

  useEffect(() => {
    const queryParams = getQueryStringObject(location.search) || {};
    const companyCategoryQueries = companyCategories.map(({ query }) => query);

    if (companyCategoryQueries.includes(queryParams.category)) {
      setCompanyTab(queryParams.category);
    }

    getCompanyResults(queryParams.category || companyTab);
  }, [location.search]);

  return (
    <HomepageWrapper id="homepage">
      <BlobBackgroundWrapper>
        <BlurBox />
        <PurpleBlob className="top_blob" />
        <BlueBlob className="bottom_blob" />
      </BlobBackgroundWrapper>
      <TopSection>
        <ContentWrapper className="min_view">
          <PeopleBanner
            to="/signup"
            onClick={() => trackSignupClicks('Homepage - People Banner')}
          />
          <h1 className="heading">A more human job search</h1>
          <h2 className="sub_heading">
            cord is the only job search platform that makes finding work
            <br />
            direct, transparent and human.
          </h2>
          <Button
            text="Get started"
            icon="icon_arrow_right"
            iconPosition="right"
            buttonStyle="fill_cord_gradient"
            link="/signup"
            className="hero_cta eighteen_px_font"
            action={() => trackSignupClicks('Homepage - Hero CTA')}
          />
          <Link to="/companies" className="lightblue_link">
            Are you hiring?
          </Link>
        </ContentWrapper>
      </TopSection>
      <OverlaySectionsWrapper>
        <CompanyCategoriesSection companies={companies} loading={loading} />
        <FeaturesSection />
        <PositionSection />
        <TestimonialsSection />
        <BottomCTASection
          className="bottom_cta_section page_view center_alignment"
          title="Talk directly to the people that matter"
          subTitle="Join the hundred of thousands of people using cord to find work"
          widthClassName="page_view dark_blue_background"
        >
          <Button
            text="Get started"
            icon="icon_arrow_right"
            iconPosition="right"
            buttonStyle="fill_cord_gradient"
            link="/signup"
            action={() => trackSignupClicks('Homepage - Bottom CTA Section')}
          />
          <div className="people_joining_wrapper">
            <MultiAvatarPhotos
              photosData={[
                { photoURL: 'https://mighty.tools/mockmind-api/content/human/75.jpg' },
                { photoURL: 'https://mighty.tools/mockmind-api/content/human/73.jpg' },
                { photoURL: 'https://mighty.tools/mockmind-api/content/human/78.jpg' },
                { photoURL: 'https://mighty.tools/mockmind-api/content/human/65.jpg' },
                { photoURL: 'https://mighty.tools/mockmind-api/content/human/62.jpg' }
              ]}
              photosWidth={24}
              maxPhotosShown={5}
            />
            +2,104 joined last week
          </div>
        </BottomCTASection>
      </OverlaySectionsWrapper>
      {!isProfileURL(location.pathname) && (
        <Seo
          title="A more human job search"
          description="cord is the job search platform that gives you direct access to hiring teams inside technology companies in the UK, USA and remote across Europe."
          path={location.pathname}
          contentType="website"
          image="https://assets.co-hire.com/seo-image-cord.jpg"
        />
      )}
    </HomepageWrapper>
  );
}
