import { ComponentType } from 'react';

import { RetryOptions, retry } from './retry';
import { MaxRetriesExceededError } from './retry.helpers';

const defaultOptions = {
  maxRetries: 7,
  delay: 500,
  stopOnError: false,
  silent: true
};

export async function retryImport<T extends ImportedComponentModule>(
  fn: () => Promise<T>,
  validate: (data: T) => boolean = validateComponentImport,
  options: RetryOptions = defaultOptions
) {
  try {
    return await retry(fn, validate, { ...defaultOptions, ...options });
  } catch (error) {
    if (error instanceof MaxRetriesExceededError) {
      return Promise.reject(error);
    }
    throw error;
  }
}

type ImportedComponentModule = {
  default: ComponentType<any>;
};

const validateComponentImport = (data: unknown): data is ImportedComponentModule => {
  return !!(data && 'default' in (data as ImportedComponentModule));
};
