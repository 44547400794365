export const decodeJobTitleValue = (jobTitle: string) => {
  if (['Back End', 'Front End', 'Full Stack'].includes(jobTitle)) return `${jobTitle} Developer`;
  else return jobTitle;
};

export const decodeJobTitles = (jobTitles: string[]) => {
  return jobTitles.map(decodeJobTitleValue);
};

const TECH_CATEGORIES_IDS = [
  7, // Software Development
  8, // Infrastructure
  9, // Data
  10, // Product & Design
  11 // QA
];

export const SALES_CATEGORY_ID = 1;

export const checkIfTechCategory = (jobCategoryID: number) =>
  TECH_CATEGORIES_IDS.includes(jobCategoryID);
