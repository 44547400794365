import { CreditRefundRequestReason } from 'types';

const {
  CandidateNotLooking,
  CandidateAlreadyFoundWork,
  CandidateNotInterested,
  LocationIssue,
  ExperienceIssue,
  VisaIssue,
  Other
} = CreditRefundRequestReason;

export const REFUND_REQUEST_REASONS = [
  { label: 'Candidate has found work', value: CandidateAlreadyFoundWork },
  { label: "Candidate isn't looking for work", value: CandidateNotLooking },
  { label: "Candidate isn't interested", value: CandidateNotInterested },
  { label: 'Experience issue', value: ExperienceIssue },
  { label: 'Location issue', value: LocationIssue },
  { label: 'Visa issue', value: VisaIssue },
  { label: 'Other', value: Other }
];
