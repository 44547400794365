import React, { useState } from 'react';
import styled from 'styled-components';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { identifyCompanyType } from 'v2/services/company/companyType';
import { useFlagPositionIssuePopup } from 'hooks/candidate/useFlagPositionIssuePopup';

import LinkWithIcon from 'components/linkwithicon';
import { usePositionProfile } from 'components/positionprofile/PositionProfile.context';
import DialogWindow from 'components/dialogwindow';
import Tooltip, { TooltipWrapper } from 'components/tooltip';
import LabelWithIcon from 'components/labelwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

export default function PositionProfileTopLeftButton() {
  const [showFlagPositionIssuePopup, toggleFlagPositionIssuePopup] = useState(false);

  const { companyType, isCandidateCreatedATPosition, flagged, showEditCandidateATPositionPopup } =
    usePositionProfile();
  const flagPositionIssuePopupProps = useFlagPositionIssuePopup('flag_position_button', () =>
    toggleFlagPositionIssuePopup(false)
  );
  const isMobile = useMedia([mediaQueries.mobile]);

  const { isExternal } = identifyCompanyType(companyType);

  if (isExternal) {
    return (
      <>
        <TopLeftButtonWrapper>
          <ConditionalRender
            predicate={flagged}
            fallback={
              <LinkWithIcon
                icon="icon_flag"
                text={isMobile ? '' : 'Flag closed position'}
                color="grey"
                action={() => toggleFlagPositionIssuePopup(true)}
              />
            }
          >
            <LabelWithIcon
              icon="icon_flag"
              text={
                <TooltipWrapper>
                  Flagged as closed
                  <Tooltip
                    tooltipStyle="light"
                    text="You flagged that this position is closed. We're checking this issue and will keep you updated."
                    position="bottom"
                    width="180px"
                  />
                </TooltipWrapper>
              }
              tag
              color="red"
            />
          </ConditionalRender>
        </TopLeftButtonWrapper>
        <ConditionalRender predicate={showFlagPositionIssuePopup}>
          <DialogWindow {...flagPositionIssuePopupProps} />
        </ConditionalRender>
      </>
    );
  }

  if (isCandidateCreatedATPosition) {
    return (
      <TopLeftButtonWrapper>
        <ConditionalRender predicate={isCandidateCreatedATPosition}>
          <LinkWithIcon
            icon="icon_edit"
            text={isMobile ? '' : 'Edit position'}
            action={showEditCandidateATPositionPopup}
          />
        </ConditionalRender>
      </TopLeftButtonWrapper>
    );
  }

  return null;
}

const TopLeftButtonWrapper = styled.div`
  flex: 1;
  text-align: left;
`;
