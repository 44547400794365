import styled, { css } from 'styled-components';

import Toggle from 'components/toggle';

type LabelToggleProps = {
  position?: 'left';
};

export const LabelToggle = styled(Toggle)<LabelToggleProps>`
  &.toggle_parent {
    ${({ position, theme: { typography } }) => css`
      flex-direction: ${position === 'left' ? 'row-reverse' : 'row'};
      gap: 0.5rem;

      .toggle_wrapper {
        border-radius: 30px;
        padding: 0.25rem;
      }

      .toggle_label {
        font-weight: ${typography.semibold};

        .badge {
          margin-left: 0.25rem;
          margin-right: 0;
        }
      }
    `}

    ${({ theme: { media } }) => media.mobile`
      justify-content: center;
  `}
  }
`;
