import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { isEmpty } from 'utils';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type NotificationCounterBubbleProps = {
  count?: number;
  className?: string;
  colour?: 'red' | 'blue' | 'dark_blue' | 'soft_white' | 'white';
};

export default function NotificationCounterBubble({
  count,
  className,
  children,
  colour = 'red'
}: PropsWithChildren<NotificationCounterBubbleProps>) {
  if (!count && isEmpty(children)) return null;
  return (
    <NotificationCounter className={ClassNames('notification_counter', className, colour)}>
      {count || children}
    </NotificationCounter>
  );
}

const NotificationCounter = styled.span`
  min-width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  display: inline-block;
  font-size: ${typography.mobile};
  font-weight: ${typography.regular};
  color: white;
  text-align: center;
  border-radius: 1.5rem;
  margin-left: 0.5rem;
  vertical-align: text-bottom;

  &.red {
    background: ${colours.errorColour};
  }

  &.dark_blue {
    background: ${colours.fontColour};
  }

  &.blue {
    background: ${colours.primaryColour};
  }

  &.soft_white {
    background: ${colours.lightFontColour};
    color: ${colours.darkerBgColour};
  }

  &.white {
    background: white;
    color: ${colours.darkerBgColour};
  }
`;
