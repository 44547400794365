import { AnyObject } from 'types';

type CompareFn<T> = (a?: T, b?: T) => boolean;

/**
 * Naively compares specified key-value pairs of two objects for equality.
 */
function compareKeyValues<T extends AnyObject>(
  obj1: Partial<T>,
  obj2: T,
  keys?: (keyof T)[],
  compareFn: CompareFn<T[keyof T]> = (a, b) => a === b
) {
  const objKeys = keys || Object.keys(obj1);
  for (let i = 0; i < objKeys.length; i++) {
    const key = objKeys[i];
    if (!compareFn(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}

const compareKeyValuesCurried =
  <T extends AnyObject = AnyObject>(
    compare: Partial<T>,
    keys?: (keyof T)[],
    compareFn?: CompareFn<T[keyof T]>
  ) =>
  (item: T) =>
    compareKeyValues(compare, item, keys, compareFn);

/**
 * Creates a curried function that checks if specified key-value pairs of an
 * object match a given partial object.
 *
 * @example
 * const matcher = isPartialMatch({ name: 'John', age: 30 });
 * matcher({ name: 'John', age: 30, city: 'New York' }); // true
 * matcher({ name: 'John', age: 25, city: 'New York' }); // false
 */
export const isPartialMatch = compareKeyValuesCurried;

/**
 * Creates a curried function that checks if two objects are deeply equal,
 * considering all key-value pairs.
 *
 * @example
 * const matcher = isEqual({ name: 'John', age: 30 });
 * matcher({ name: 'John', age: 30 }); // true
 * matcher({ name: 'John', age: 25 }); // false
 * matcher({ name: 'John', age: 30, city: 'New York' }); // false
 */
export const isEqual =
  <T extends AnyObject>(obj1: T, compareFn?: CompareFn<T[keyof T]>) =>
  (obj2: T) =>
    isPartialMatch(obj1, undefined, compareFn)(obj2) &&
    isPartialMatch(obj2, undefined, compareFn)(obj1);
