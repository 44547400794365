import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import { ListingInterface } from '@cohiretech/common-types';

import * as fetcher from 'fetcher';
import { tablet } from 'responsiveConfig';
import { getCompanyLocationLabel, populateLocationList } from 'utils';

import { usePositionEdit } from 'views/company/position/PositionEdit.context';

import AutoComplete from 'components/autocomplete';
import Dropdown from 'components/dropdown';
import SelectedItems from 'components/selecteditems';

export type RemoteLocations = Pick<
  ListingInterface,
  'remoteLocationContinents' | 'remoteLocationCountries'
>;

type RemoteLocationsFieldProps = {
  question: string;
  disabledPlaceholder: string;
  defaultRemoteLocationContinents?: string[];
  defaultRemoteLocationCountries?: string[];
};

const RemoteLocationsField = forwardRef<RemoteLocations, RemoteLocationsFieldProps>(
  (
    {
      question,
      disabledPlaceholder,
      defaultRemoteLocationContinents = [],
      defaultRemoteLocationCountries = []
    },
    ref
  ) => {
    const { updateVisaRemoteInfo } = usePositionEdit();
    const defaultLocationLabel = getCompanyLocationLabel(
      defaultRemoteLocationContinents,
      defaultRemoteLocationCountries
    );
    const [locationLabel, setLocationLabel] = useState(defaultLocationLabel);
    const [remoteLocationCountries, setRemoteLocationCountries] = useState(
      defaultRemoteLocationCountries || []
    );
    const [remoteLocationContinents, setRemoteLocationContinents] = useState(
      defaultRemoteLocationContinents || []
    );

    const setEmployeesLocations = (location: $TSFixMe) => {
      const continents = fetcher.getContinents();

      if (continents.includes(location)) {
        setRemoteLocationContinents([...remoteLocationContinents, location]);
      } else setRemoteLocationCountries([...remoteLocationCountries, location]);
    };

    const removeEmployeesLocation = (location: $TSFixMe) => {
      const remoteContinentsCopy = [...remoteLocationContinents];
      const remoteCountriesCopy = [...remoteLocationCountries];

      const continentsIndex = remoteContinentsCopy.indexOf(location);
      const countriesIndex = remoteCountriesCopy.indexOf(location);

      if (continentsIndex !== -1) {
        remoteContinentsCopy.splice(continentsIndex, 1);
        setRemoteLocationContinents(remoteContinentsCopy);
      } else if (countriesIndex !== -1) {
        remoteCountriesCopy.splice(countriesIndex, 1);
        setRemoteLocationCountries(remoteCountriesCopy);
      }
    };

    useEffect(() => {
      updateVisaRemoteInfo({ remoteLocationContinents, remoteLocationCountries });
    }, [remoteLocationContinents, remoteLocationCountries]);

    useImperativeHandle(
      ref,
      () => ({
        remoteLocationContinents,
        remoteLocationCountries
      }),
      [remoteLocationContinents, remoteLocationCountries]
    );

    return (
      <div className="field remote_locations_field">
        <label className="question">{question}</label>
        <LocationFlexWrapper>
          <Dropdown
            className="location_dropdown"
            selected={locationLabel}
            list={fetcher.getRemoteLabelLocation()}
            id="remote_location_dropdown"
            onSelect={(item: $TSFixMe) => setLocationLabel(item.value)}
          />
          <AutoComplete
            list={populateLocationList(locationLabel)}
            className="location_autocomplete"
            id="remote_location_field"
            onSelect={(location: $TSFixMe) => setEmployeesLocations(location)}
            selectedItems={remoteLocationContinents.concat(remoteLocationCountries)}
            removeSelected
            disabled={locationLabel === 'Anywhere'}
            disabledPlaceholder={disabledPlaceholder}
            placeholder="Select country or continent"
          />
        </LocationFlexWrapper>
        <SelectedItems
          selectedItems={remoteLocationContinents.concat(remoteLocationCountries)}
          columns={3}
          removeItem={(location: $TSFixMe) => removeEmployeesLocation(location)}
          ordered
        />
      </div>
    );
  }
);

export default RemoteLocationsField;

const LocationFlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .location_dropdown {
    width: 210px;
    display: inline-block;

    @media only screen and (max-width: ${tablet}) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .location_autocomplete {
    margin-left: 10px;
    flex: 1;
    display: inline-block;

    @media only screen and (max-width: ${tablet}) {
      margin-left: 0;
      width: 100%;
    }
  }

  @media only screen and (max-width: ${tablet}) {
    flex-direction: column;
  }
`;
