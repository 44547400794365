/* eslint-disable no-console */
import { isArray } from 'utils/fn';

/**
 * Get the first n element of an array
 *
 * @param arr the array to get the n first element of
 * @returns an array with the first n elements of the input array or and empty if the input is empty or not an array
 */
export const take =
  <T = any>(n: number = 1) =>
  (arr: T[]): T[] => {
    if (!isArray(arr)) {
      console.warn(`take() expects an array but got ${typeof arr}`);
      return [];
    }
    if (!arr?.length) return [];
    return arr.slice(0, n);
  };
