import { useDemoCompany, useSubscriptionInfo } from 'store';

import EmbedScript from './EmbedScript';

export const CompanyEmbedScripts = () => {
  const demoCompany = useDemoCompany();
  const { isExpired } = useSubscriptionInfo();

  return (
    <>
      <EmbedScript
        predicate={demoCompany || isExpired}
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js.hs-scripts.com/3937375.js"
      />
      <EmbedScript
        predicate={!demoCompany}
        id="helpkit-widget"
        data-project-id="cordsupport"
        type="text/javascript"
        src="https://www.helpkit.so/widget/script.js"
        async
      />
    </>
  );
};
