import { ISO_6393_LANGUAGES } from 'consts';

export const getLanguages = () => {
  return ISO_6393_LANGUAGES.map(language => ({
    label: language.name,
    id: language.iso6393,
    code: language.iso6393,
    value: language.iso6393,
    attribute: 'languages'
  }));
};
