import React, { CSSProperties, MouseEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ClassNames from 'classnames';
import SanitizedHTML from 'react-sanitized-html';

import {
  StoryInterface,
  PartnershipInterface,
  TestimonialInterface
} from '@cohiretech/common-types';

import { getMonthAndYear } from 'utils';
import { useDarkMode } from 'hooks/useDarkMode';
import { ALLOWED_COMPANY_PASTE_TAGS } from 'consts';

import { InfoWrapper } from 'components/activityinfo';

import VideoItem from 'v2/components/ui/organisms/VideoItem';

import NoImage from 'images/no-image-cover.png';

type Props = {
  history: RouteComponentProps['history'];
  columns: number;
  onTouchStart?: () => void;
  onTouchMove?: () => void;
  onTouchEnd?: () => void;
  style: CSSProperties;
  type?: 'company' | 'partner' | 'candidate';
  generateURL?: (storyData: { id: number; companyName: string }) => string;
} & Partial<StoryInterface & PartnershipInterface & TestimonialInterface>;

export default function StoryItem({
  history,
  type = 'company',
  columns,
  style,
  generateURL,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  ...item
}: Props) {
  const onItemClick = (e: MouseEvent<HTMLDivElement>, url: string) => {
    const target = e.target as HTMLElement;

    if (!target.className.includes('video')) history.push(url);
  };

  const {
    storyID,
    partnershipID,
    coverPhotoURL,
    pictureURLs,
    companyName,
    companyLogo,
    firstName,
    lastName,
    logoURL,
    photoURL,
    jobTitle,
    videoURL,
    videoTestimonialURL,
    title,
    createDate,
    description
  } = item;

  const id = storyID || partnershipID;
  const storyURL = generateURL ? generateURL({ id: id!, companyName: companyName! }) : '';
  const companyStory = type === 'company';
  const partnerPage = type === 'partner';
  const coverPhoto = coverPhotoURL || pictureURLs?.[0] || NoImage;
  const profilePicture = companyLogo || logoURL || photoURL;
  const darkMode = useDarkMode();

  return (
    <div
      key={id}
      style={style}
      className={ClassNames('square_card_item', 'story_item', {
        dark: darkMode,
        clickable: companyStory || partnerPage,
        four_cols_item: columns === 4,
        three_cols_item: columns === 3,
        two_cols_item: columns === 2,
        company_story: companyStory
      })}
      onTouchStart={
        onTouchStart ||
        (() => {
          return;
        })
      }
      onTouchMove={
        onTouchMove ||
        (() => {
          return;
        })
      }
      onTouchEnd={
        onTouchEnd ||
        (() => {
          return;
        })
      }
      onClick={
        companyStory || partnerPage
          ? e => onItemClick(e, storyURL)
          : () => {
              return;
            }
      }
    >
      {(videoURL || videoTestimonialURL) && !partnerPage ? (
        <VideoItem
          videoUrl={(videoURL || videoTestimonialURL) as string}
          playIcon="light_blue"
          youtubeOptions={{
            width: '100%',
            height: '320',
            title: `${companyName} story thumbnail video`
          }}
        />
      ) : (
        <img
          className="cover_photo"
          src={coverPhoto}
          alt={`${companyName} story using cord`}
          width="100%"
          height="320"
          loading="lazy"
        />
      )}
      {companyStory || partnerPage ? (
        <div className="card_details">
          <img
            className="card_profile_picture"
            src={profilePicture}
            alt={`${companyName} logo`}
            width="75"
            height="75"
            loading="lazy"
          />
          <div className="card_info no_ellipsis">
            <h3 className={ClassNames('card_title', { partners_title: partnerPage })}>
              {partnerPage ? title : companyName}
            </h3>
            {partnerPage ? (
              <SanitizedHTML
                className="pre_wrap_description rich_editor_text"
                allowedTags={ALLOWED_COMPANY_PASTE_TAGS}
                html={`${description!.substring(0, 80)}...`}
              />
            ) : (
              <h4 className="card_subtitle no_ellipsis">{title}</h4>
            )}
          </div>
        </div>
      ) : (
        <div className={ClassNames('card_details', 'candidate_story')}>
          <img
            className="card_profile_picture"
            src={profilePicture}
            alt={`${firstName} ${lastName} profile`}
            width="75"
            height="75"
            loading="lazy"
          />
          <div className="card_info">
            <h3 className="card_title">
              {firstName} {lastName}
            </h3>
            <h4 className="card_subtitle">
              {jobTitle} at {companyName}
            </h4>
            {createDate && (
              <InfoWrapper className="activity_info green_text">
                {getMonthAndYear(createDate)}
              </InfoWrapper>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
