import React from 'react';

import {
  WorkEligibility,
  WorkEligibilityLocation,
  WorkEligibilityType
} from '@cohiretech/common-types';

import { ValidWorkEligibilityLocations } from 'types';
import { VISA_SPONSORSHIP_ORIGINS } from 'fetcher';
import { isEmpty } from 'utils';
import { pluck } from 'utils/object';
import { isObject } from 'utils/fn';

import { Selections } from 'views/signup/questions';

import Tooltip from 'components/tooltip';

type WorkEligibilitySignupOptions = {
  [key in Exclude<WorkEligibilityLocation, WorkEligibilityLocation.Other>]: {
    label: string;
    value: string;
    tooltip?: string;
  }[];
};

export const workEligibilitySignupOptions: WorkEligibilitySignupOptions = {
  [WorkEligibilityLocation.UK]: [
    {
      label: 'Citizen/Pre-settled/Settled',
      value: `${WorkEligibilityLocation.UK}-${WorkEligibilityType.Permanent}`
    },
    {
      label: 'Temporary work visa',
      tooltip:
        "You're eligible to work in the UK for a limited period of time. Eg. Tier 1 (Exceptional Talent), Tier 2 (General), Tier 5 (Temporary worker) or Youth Mobility Scheme visas",
      value: `${WorkEligibilityLocation.UK}-${WorkEligibilityType.Temporary}`
    }
  ],
  [WorkEligibilityLocation.US]: [
    {
      label: 'Citizen/Permanent resident',
      value: `${WorkEligibilityLocation.US}-${WorkEligibilityType.Permanent}`
    },
    {
      label: 'Temporary work visa',
      tooltip:
        "You're eligible to work in the US for a limited period of time. Eg. H-1B/2 or EAD visas",
      value: `${WorkEligibilityLocation.US}-${WorkEligibilityType.Temporary}`
    }
  ],
  [WorkEligibilityLocation.EU]: [
    {
      label: 'EU citizen/Permanent resident',
      value: `${WorkEligibilityLocation.EU}-${WorkEligibilityType.Permanent}`
    },
    {
      label: 'Temporary work visa',
      tooltip: "You're eligible to work in EU for a limited period of time.",
      value: `${WorkEligibilityLocation.EU}-${WorkEligibilityType.Temporary}`
    }
  ]
};

export const getWorkEligibilityLocationFromValue = (value: string) => {
  return value.split('-')[0];
};

export const getValidWorkEligibilityLocations = (workEligibilities: WorkEligibility[]) => {
  return workEligibilities
    .filter(({ type }) => type !== WorkEligibilityType.Ineligible)
    .map(pluck('location'));
};

export const getWorkEligibilityTypes = (workEligibilities: WorkEligibility[]) => {
  return workEligibilities.map(pluck('type'));
};

export const isValidWorkEligibilityResponse = (
  workEligibilityLocations: WorkEligibilityLocation[],
  selections: Selections
) => {
  const selectedWorkEligibilityLocations = (selections as string[]).map(selection =>
    getWorkEligibilityLocationFromValue(selection)
  );

  const aWorkEligibilityTypeIsSelectedForEachLocation = workEligibilityLocations.every(location =>
    selectedWorkEligibilityLocations.includes(location.toLowerCase())
  );

  return aWorkEligibilityTypeIsSelectedForEachLocation;
};

export const getWorkEligibilitiesFromValues = (value: string | string[]): WorkEligibility[] => {
  if (typeof value === 'string' || value instanceof String) {
    const [location, type] = value.split('-');
    return [{ location, type } as WorkEligibility];
  }

  return value.map((v: string) => {
    const [location, type] = v.split('-');
    return { location, type } as WorkEligibility;
  });
};

export const removeOtherFromWorkEligibilityLocations = (
  workEligibilityLocations: WorkEligibilityLocation[]
) =>
  workEligibilityLocations.filter(
    location => location !== WorkEligibilityLocation.Other
  ) as ValidWorkEligibilityLocations[];

export const removeIneligibleFromWorkEligibilities = (workEligibilities: WorkEligibility[]) => {
  return workEligibilities.filter(({ type }) => type !== WorkEligibilityType.Ineligible);
};

export const getWorkEligibilityLabel = (
  workEligibilities: WorkEligibility[],
  includeOther = false
) => {
  if (isEmpty(workEligibilities)) return '';
  if (
    workEligibilities.length === 1 &&
    workEligibilities[0].location === WorkEligibilityLocation.Other
  ) {
    return includeOther ? VISA_SPONSORSHIP_ORIGINS.OTHER : 'Visa required for UK, EU, US';
  }

  const label: (string | React.ReactNode)[] = [];
  const validWorkEligibilities = removeIneligibleFromWorkEligibilities(workEligibilities);

  if (isEmpty(validWorkEligibilities)) return 'Visa required for UK, EU, US and Non-UK/EU/US';

  validWorkEligibilities.forEach(({ location, type }, index) => {
    const locationLabel =
      location === WorkEligibilityLocation.Other
        ? VISA_SPONSORSHIP_ORIGINS.OTHER
        : location.toUpperCase();

    label.push(locationLabel);

    if (location === WorkEligibilityLocation.Other || type === WorkEligibilityType.Unknown) {
      if (index !== validWorkEligibilities.length - 1) label.push(', ');
      return;
    }

    const workTypeLabel = workEligibilitySignupOptions[location].find(
      ({ value }: { value: string }) => value.endsWith(type)
    )!.label;

    if (type === WorkEligibilityType.Permanent) {
      label.push(
        <span key={`${location}_type`} className="contains_tooltip">
          {' '}
          (fully eligible)
          <Tooltip text={workTypeLabel} />
        </span>
      );
    }
    if (type === WorkEligibilityType.Temporary) {
      label.push(
        <span key={`${location}_type`} className="contains_tooltip">
          {' '}
          (temp. eligible)
          <Tooltip text={workTypeLabel} />
        </span>
      );
    }
    if (index !== validWorkEligibilities.length - 1) label.push(', ');
  });

  return label;
};

export const getPlainWorkEligibilityLabel = (workEligibility: WorkEligibility) => {
  if (!isObject(workEligibility)) return workEligibility as unknown as string;

  const { type, location } = workEligibility || {};
  const locationLabel =
    location === WorkEligibilityLocation.Other
      ? VISA_SPONSORSHIP_ORIGINS.OTHER
      : location.toUpperCase();

  if (type === WorkEligibilityType.Temporary) {
    return `${locationLabel} (temp. eligibile)`;
  } else if (type === WorkEligibilityType.Permanent) {
    return `${locationLabel} (fully eligible)`;
  } else if (type === WorkEligibilityType.Unknown) {
    return locationLabel;
  }

  return `${locationLabel} (requires visa)`;
};
