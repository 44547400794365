import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { openArticleAndTrack } from 'utils/companyUtils';

import Button, { Props as ButtonProps } from 'components/button';

type PropsForTracking =
  | { isCompanyUser: true; locationInProduct: string }
  | { isCompanyUser?: false; locationInProduct?: string };

type ShowHelpkitButtonProps = {
  view?: 'popover' | 'sidebar';
  type?: 'article' | 'contact';
  articleId: string;
  buttonText?: string;
} & Pick<HTMLAttributes<HTMLButtonElement>, 'className' | 'title'> &
  Omit<ButtonProps, 'type'> &
  PropsForTracking;

export default function ShowHelpkitButton({
  className = 'link',
  title,
  view = 'popover',
  type = 'article',
  articleId,
  buttonText = 'here',
  children,
  isCompanyUser,
  locationInProduct,
  ...other
}: PropsWithChildren<ShowHelpkitButtonProps>) {
  const showHelpkit = () => window.Helpkit.show(view, type, articleId);
  const handleClick = isCompanyUser
    ? () => openArticleAndTrack(articleId, locationInProduct)
    : showHelpkit;

  if (className === 'button') return <Button text={buttonText} action={handleClick} {...other} />;

  return (
    <button className={className} title={title} type="button" onClick={handleClick}>
      {children || buttonText}
    </button>
  );
}
