import styled, { css } from 'styled-components';

import { PAY_PER_HIRE_OPTIONS as OPTIONS } from 'consts';

import BulletList, { Bullet } from 'components/bulletlist';
import Button from 'components/button';

import { GradientText, GradientTextProps } from 'v2/components/ui/styles';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

const PayPerHireCard = ({ hidePrice }: { hidePrice?: boolean }) => {
  return (
    <PayPerHireCardWrapper>
      <PayPerHireColumn>
        <span>
          <h3>{OPTIONS.label}</h3>
          <span>{OPTIONS.description}</span>
        </span>
        <PayPerHirePrice gradientTextColour="cordGradient">
          <ConditionalRender predicate={!hidePrice} fallback={<PercentageOfHireText />}>
            <h4>10%</h4>&nbsp;
            <span> of first-year salary</span>
          </ConditionalRender>
        </PayPerHirePrice>
      </PayPerHireColumn>
      <PayPerHireColumn>
        <strong>{OPTIONS.featureTitle}</strong>
        <BulletList className="pay_per_hire_bulletlist">
          {OPTIONS.features.map((feature, i) => (
            <Bullet
              key={`${OPTIONS.plan}_feature_${i}`}
              title={feature}
              iconSize={16}
              iconCircleColour="light"
            />
          ))}
        </BulletList>
        <Button {...OPTIONS.button} />
      </PayPerHireColumn>
    </PayPerHireCardWrapper>
  );
};

const PercentageOfHireText = () => (
  <>
    <span>Priced as % of first-year salary</span>
  </>
);

const PayPerHireCardWrapper = styled.div`
  display: flex;
  padding: var(--spacing-09);
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;

  border-radius: var(--radius-md);
  background: var(--background-block-primary);
  box-shadow: 0px 4px var(--spacing-08) 0px rgba(11, 54, 88, 0.08);

  ${({ theme: { media } }) => css`
    ${media.mobile`
      flex-direction: column;
      gap: 1.5rem;
    `}
  `}
`;

const PayPerHirePrice = styled.span<GradientTextProps>`
  display: flex;
  align-items: baseline;
  font-size: var(--type-sm);
  h4 {
    font-size: var(--type-3xl);
  }

  ${GradientText}
`;

const PayPerHireColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  h3 {
    font-size: var(--type-lg);
    margin: 0 0 var(--spacing-01);
  }

  .pay_per_hire_bulletlist {
    margin-bottom: var(--spacing-10);

    .bullet_item {
      align-items: center;
      padding-bottom: 0;
      margin-top: var(--spacing-01);
      h3 {
        font-size: var(--type-sm-md);
        font-weight: ${({ theme: { typography } }) => typography.regular};
        margin: 0;
      }

      .icon_bullet {
        padding-right: var(--spacing-02);
      }
    }

    ${({ theme: { media } }) => media.mobile`
      margin-bottom: var(--spacing-08);

      .bullet_item {
        padding: 0.125rem 0;
      }
    `}
  }
`;

export default PayPerHireCard;
