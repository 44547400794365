/* eslint-disable no-console */
import { Reducer } from 'react';

import { MilliSeconds } from 'types';

import { isLoggingReducer } from './DevToolsManager';

type LogEntry<S = any, A = any> = {
  started: MilliSeconds;
  ended: MilliSeconds;
  startedTime: Date | null;
  endTime: Date | null;
  prevState: S;
  nextState: S;
  action: A;
  took: MilliSeconds;
};

type GenericAction = {
  type: string;
  payload?: any;
};

const COLORS = {
  title: 'inherit',
  prevState: '#9E9E9E',
  action: '#03A9F4',
  nextState: '#4CAF50',
  error: '#F20404'
};

const logReducer =
  <S, A extends GenericAction = GenericAction>(
    reducer: Reducer<S, A>,
    name: string
  ): Reducer<S, A> =>
  (state, action) => {
    if (!isLoggingReducer(name)) return reducer(state, action);

    const logEntry: LogEntry = {
      started: 0,
      ended: 0,
      startedTime: null,
      endTime: null,
      prevState: {},
      nextState: {},
      action: {},
      took: 0
    };

    logEntry.startedTime = new Date();
    logEntry.started = timer.now();

    const result = reducer(state, action);

    logEntry.ended = timer.now();
    logEntry.endTime = new Date();
    logEntry.took = logEntry.ended - logEntry.started;

    const prevState = transformState(state);
    const nextState = transformState(result);

    const title = buildHeader(action, logEntry);

    const titleCSS = `color: inherit`;
    const headerCSS = ['color: gray; font-weight: lighter;'];
    headerCSS.push(titleCSS);
    headerCSS.push('color: gray; font-weight: lighter;');
    headerCSS.push('color: gray; font-weight: lighter;');

    console.groupCollapsed(`%c ${title}`, ...headerCSS);

    /* prev state */
    let styles = `color: ${COLORS.prevState}; font-weight: bold`;
    console.log('%c prev state', styles, prevState);

    /* action */
    styles = `color: ${COLORS.action}; font-weight: bold`;
    console.log('%c action    ', styles, action);

    /* next state */
    styles = `color: ${COLORS.nextState}; font-weight: bold`;
    console.log('%c next state', styles, nextState);

    console.groupEnd();

    return result;
  };

const transformState = <T = any>(state: T): T => {
  return JSON.parse(JSON.stringify(state));
};

const timer = performance;

const formatTitle = <A extends GenericAction>(action: A, time: string, took: MilliSeconds) => {
  const parts = ['action'];

  parts.push(`%c${String(action.type)}`);
  parts.push(`%c@ ${time}`);
  parts.push(`%c(in ${took.toFixed(2)} ms)`);

  return parts.join(' ');
};

export const formatTime = (time: Date) =>
  `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(time.getSeconds(), 2)}.${pad(
    time.getMilliseconds(),
    3
  )}`;

export const repeat = (str: string, times: number) => new Array(times + 1).join(str);

export const pad = (num: number, maxLength: number) =>
  repeat('0', maxLength - num.toString().length) + num;

const buildHeader = <A extends GenericAction>(action: A, logEntry: LogEntry) => {
  const formattedTime = formatTime(logEntry.startedTime!);
  const title = formatTitle(action, formattedTime, logEntry.took);

  return title;
};

export default logReducer;
