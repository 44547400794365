import React from 'react';

import PositionProfile from './PositionProfile';
import { PositionProfileProvider } from './PositionProfile.context';
import { PositionProfileProps } from './PositionProfile.helpers';

export default function PositionProfileWithContext(props: PositionProfileProps) {
  return (
    <PositionProfileProvider {...props}>
      <PositionProfile />
    </PositionProfileProvider>
  );
}
