export const INDUSTRY_OPTIONS = [
  { label: 'Academic/Research', path: 'academic/research' },
  { label: 'Accounting', path: 'accounting' },
  { label: 'AgriTech', path: 'agritech' },
  { label: 'Analytics', path: 'analytics' },
  { label: 'App Development', path: 'app development' },
  { label: 'Architecture', path: 'architecture' },
  { label: 'Augmented Reality', path: 'augmeted reality' },
  { label: 'Automotive', path: 'automotive' },
  { label: 'Banking', path: 'banking' },
  { label: 'Big Data', path: 'big data' },
  { label: 'BioTech', path: 'biotech' },
  { label: 'Blockchain', path: 'blockchain' },
  { label: 'Charity', path: 'charity' },
  { label: 'Chemicals', path: 'chemicals' },
  { label: 'Childcare', path: 'childcare' },
  { label: 'Civic Tech', path: 'civic tech' },
  { label: 'Cloud and Infrastructure', path: 'cloud and infrastructure' },
  { label: 'Communications', path: 'communications' },
  { label: 'Computer and Network Security', path: 'computer and network security' },
  { label: 'Computer Graphics', path: 'computer graphics' },
  { label: 'Construction', path: 'construction' },
  { label: 'Consultancy', path: 'consultancy' },
  { label: 'Consumer Goods', path: 'consumer goods' },
  { label: 'Content Production', path: 'content production' },
  { label: 'CRM and Sales', path: 'crm and sales' },
  { label: 'Data', path: 'data' },
  { label: 'Dating', path: 'dating' },
  { label: 'Design', path: 'design' },
  { label: 'Digital Services', path: 'digital services' },
  { label: 'E-Commerce', path: 'e-commerce' },
  { label: 'E-Learning', path: 'e-learning' },
  { label: 'EdTech', path: 'edtech' },
  { label: 'Energy and Utilities', path: 'energy and utilities' },
  { label: 'Engineering', path: 'engineering' },
  { label: 'Entertainment', path: 'entertainment' },
  { label: 'EnviroTech', path: 'envirotech' },
  { label: 'Events', path: 'events' },
  { label: 'Fashion', path: 'fashion' },
  { label: 'Finance', path: 'finance' },
  { label: 'FinTech', path: 'fintech' },
  { label: 'Food', path: 'food' },
  { label: 'FoodTech', path: 'foodtech' },
  { label: 'Gambling', path: 'gambling' },
  { label: 'Gaming', path: 'gaming' },
  { label: 'Genealogy', path: 'genealogy' },
  { label: 'GovTech', path: 'govtech' },
  { label: 'Hardware', path: 'hardware' },
  { label: 'Health and Fitness', path: 'health and fitness' },
  { label: 'HealthTech', path: 'healthtech' },
  { label: 'Home Living', path: 'home living' },
  { label: 'Hospitality', path: 'hospitality' },
  { label: 'HR and Recruitment', path: 'hr and recruitment' },
  { label: 'Human Computer Interaction', path: 'human computer interaction' },
  { label: 'Identity and Access', path: 'identity and access' },
  { label: 'Insurance', path: 'insurance' },
  { label: 'InsurTech', path: 'insurtech' },
  { label: 'Internet', path: 'internet' },
  { label: 'Investment', path: 'investment' },
  { label: 'Kids', path: 'kids' },
  { label: 'Legal', path: 'legal' },
  { label: 'LegalTech', path: 'legaltech' },
  { label: 'Logistics', path: 'logistics' },
  { label: 'Luxury', path: 'luxury' },
  { label: 'Machine Learning and AI', path: 'machine learning and ai' },
  { label: 'Maintenance', path: 'maintenance' },
  { label: 'Manufacturing', path: 'manufacturing' },
  { label: 'Mapping', path: 'mapping' },
  { label: 'Market Research', path: 'market research' },
  { label: 'Marketing and Advertising', path: 'marketing and advertising' },
  { label: 'Marketplace', path: 'marketplace' },
  { label: 'Media and Streaming', path: 'media and streaming' },
  { label: 'Media/Broadcasting', path: 'media/broadcasting' },
  { label: 'MedTech', path: 'medtech' },
  { label: 'Mental Health', path: 'mental health' },
  { label: 'Messaging', path: 'messaging' },
  { label: 'Mobility', path: 'mobility' },
  { label: 'MusicTech', path: 'musictech' },
  { label: 'Non for Profit', path: 'non for profit' },
  { label: 'Payments', path: 'payments' },
  { label: 'Pharmaceuticals', path: 'pharmaceuticals' },
  { label: 'Politics/Policy', path: 'politics/policy' },
  { label: 'Privacy', path: 'privacy' },
  { label: 'Productivity Tools', path: 'productivity tools' },
  { label: 'PropertyTech', path: 'propertytech' },
  { label: 'Public Safety', path: 'public safety' },
  { label: 'Publishing', path: 'publishing' },
  { label: 'Real Estate', path: 'real estate' },
  { label: 'RegTech', path: 'regtech' },
  { label: 'Research', path: 'research' },
  { label: 'Retail', path: 'retail' },
  { label: 'Retail Technology', path: 'retail technology' },
  { label: 'Robotics', path: 'robotics' },
  { label: 'Security', path: 'security' },
  { label: 'Semiconductors', path: 'semiconductors' },
  { label: 'Social Enterprises', path: 'social enterprises' },
  { label: 'Social Media', path: 'social media' },
  { label: 'Software', path: 'software' },
  { label: 'Software Development', path: 'software development' },
  { label: 'Space', path: 'space' },
  { label: 'Sport', path: 'sport' },
  { label: 'SportTech', path: 'sporttech' },
  { label: 'Sustainability', path: 'sustainability' },
  { label: 'Technology', path: 'technology' },
  { label: 'Telecomms', path: 'telecomms' },
  { label: 'Ticketing', path: 'ticketing' },
  { label: 'Translation', path: 'translation' },
  { label: 'Transport', path: 'transport' },
  { label: 'Travel', path: 'travel' },
  { label: 'Video', path: 'video' },
  { label: 'Virtual Reality', path: 'virtual reality' },
  { label: 'Wellness', path: 'wellness' },
  { label: 'Workspaces', path: 'workspaces' }
];
