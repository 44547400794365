import React, { useState } from 'react';
import { useLocation } from 'react-router';
import ClassNames from 'classnames';
import styled from 'styled-components';

import Button from 'components/button';
import CreateOrClaimCompanyForm, {
  Props as ClaimProfileProps
} from 'components/createorclaimprofileform';
import PageSection from 'components/pagesection';

import { PageSectionType, getPageSectionPropsByType } from './DemoButtonSection.helpers';

type Props = { type: PageSectionType; className?: string; color?: ClaimProfileProps['color'] };

export default function DemoButtonSection({ type, className, color = 'white' }: Props) {
  const location = useLocation();
  const [formExpanded, setFormExpanded] = useState(false);

  const pageSectionProps = getPageSectionPropsByType(type, className);

  return (
    <PageSection {...pageSectionProps}>
      <ButtonContainer className="demo_buttons">
        <CreateOrClaimCompanyForm
          expandable
          onExpand={expanded => setFormExpanded(expanded)}
          color={color}
          submitButton={{
            buttonStyle: color === 'dark' ? 'fill_dark_blue' : '',
            text: 'Claim your profile for free'
          }}
        />
        <Button
          text="Book a demo"
          className={ClassNames('twenty_px_font', { display_none: formExpanded })}
          buttonStyle={color === 'dark' ? 'stroke_dark_blue' : 'stroke_white'}
          link={{ pathname: '/request-demo', state: { from: location.pathname } }}
          icon="icon_demo"
        />
      </ButtonContainer>
    </PageSection>
  );
}

const ButtonContainer = styled.div`
  max-height: 80px;

  .button {
    width: 260px;
    padding: 1rem;
    vertical-align: middle;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    ${({ theme: { media } }) => media.mobile`
      width: 100%;
      margin: 0.25rem 0;
    `}
  }
`;
