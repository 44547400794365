import { CSSProperties } from 'react';

import { ListingStatus, SearchResponseListing } from '@cohiretech/common-types';

type Props = {
  id: number;
  searchPosition: number;
  columns: number;
  style: CSSProperties;
  updateItemDetails: $TSFixMeFunction;
  openChat: $TSFixMeFunction;
  showSaveHideActions?: boolean;
  showViewPositionButton?: boolean;
  status: ListingStatus;
  onClick?: () => void;
  hideDateInfo?: boolean;
} & Omit<SearchResponseListing, 'status'>;

export type PositionItemProps =
  | ({ loading: true } & Partial<Props>)
  | ({ loading?: boolean } & Props);

export const getTags = (primarySkills: string[]) => {
  const tags = primarySkills.slice(0, 4);

  if (primarySkills.length > 4) tags.push(`+${primarySkills.length - 4}`);
  return tags;
};

export const getSmallHeading = ({
  external,
  companyName,
  associatedMemberDetails
}: PositionItemProps) => {
  const { memberName } = associatedMemberDetails || {};

  if (external || !memberName) return companyName;
  return `${memberName} from ${companyName} is recruiting for`;
};

export const getActivityInfoProps = ({
  external,
  responseRate,
  companyLastActive,
  datePosted,
  lastCheckedDate,
  hideDateInfo
}: PositionItemProps) =>
  external
    ? { datePosted, lastCheckedDate, hideDateInfo }
    : {
        responseRate,
        lastActive: companyLastActive,
        noResponseRateFallbackText: 'Recently posted!'
      };
