import {
  css,
  CSSObject,
  DefaultTheme,
  Interpolation,
  SimpleInterpolation,
  ThemeProps
} from 'styled-components';

import { mediaQueries } from 'responsiveConfig';
import { reduceObject } from 'utils/object';

type Media = keyof typeof mediaQueries;
type MediaQuery = typeof mediaQueries[Media];
type TemplateFunction = (
  first: TemplateStringsArray | CSSObject,
  ...interpolations: SimpleInterpolation[]
) => Interpolation<ThemeProps<DefaultTheme>>;

export default reduceObject<Media, MediaQuery, Record<Media, TemplateFunction>>(
  mediaQueries,
  (acc, query, label) => {
    acc[label] = (first, ...interpolations) => css`
      @media ${query} {
        ${css(first, ...interpolations)}
      }
    `;
    return acc;
  }
);
