import React from 'react';
import styled from 'styled-components';

import { mobile } from 'responsiveConfig';

import colours from 'css/base/_colours.module.scss';

export default function DataSection({ margin, items }: $TSFixMe) {
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <DataWrapper margin={margin}>
      {items.map(({ number, description }: $TSFixMe, index: $TSFixMe) => (
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <DataItem key={`${number}_${index}`} cols={items.length} margin={40 * (items.length - 1)}>
          <Number>{number}</Number>
          <Description>{description}</Description>
        </DataItem>
      ))}
    </DataWrapper>
  );
}

const DataWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: ${props => (props as $TSFixMe).margin || '0'};
  justify-content: space-between;
  text-align: left;

  @media only screen and (max-width: ${mobile}) {
    text-align: center;
    display: block;
    margin: 20px 0;
  }
`;

const DataItem = styled.div`
  flex: ${props => `calc((100% - ${(props as $TSFixMe).margin}px) / ${(props as $TSFixMe).cols})`};
  max-width: ${props =>
    `calc((100% - ${(props as $TSFixMe).margin}px) / ${(props as $TSFixMe).cols})`};

  @media only screen and (max-width: ${mobile}) {
    max-width: 200px;
    margin: 30px auto;
  }
`;

const Number = styled.h3`
  font-size: 36px;
  margin: 0 auto 10px;
  color: ${colours.primaryColour};
`;

const Description = styled.div`
  font-size: 18px;
`;
