/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { CSSProperties, ReactNode, PropsWithChildren } from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { getIsDarkMode_FIXME } from 'cookieManager';
import { getLabelFromGroupedOptions, scrollToElementInList } from 'utils';
import { BasicOption } from 'types';
import { isString } from 'utils/fn';

import Badge, { BadgeProps } from 'components/badge';

import NotificationCounterBubble from 'v2/components/ui/atoms/NotificationCounterBubble';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

let sequenceOfKeys = '';

export interface ListItem<L = ReactNode, V = any> extends BasicOption<L, V> {
  label?: L;
  value?: V;
  disabled?: boolean;
  notification?: string | number;
  badge?: string;
  badgeType?: BadgeProps['type'];
  dontHide?: boolean;
  noHoverAction?: boolean;
  price?: string;
  isDangerous?: boolean;
  className?: string;
  subItems?: ListItem<L, V>[];
}

export type DropdownProps = {
  id: string;
  className?: string;
  selected?: string | boolean | number;
  selectMessage?: ReactNode;
  list: ListItem[];
  onSelect: (item: ListItem) => void;
  hideArrow?: boolean;
  hideSelectedLabel?: boolean;
  dropdownLabel?: string;
  allowSearch?: boolean;
  searchType?: 'start' | 'any';
  isGroupedList?: boolean;
  stopEventPropagation?: boolean;
  inlineStyle?: CSSProperties;
  disabled?: boolean;
  icon?: string;
};

type State = $TSFixMe;

export default class Dropdown extends React.Component<PropsWithChildren<DropdownProps>, State> {
  // eslint-disable-next-line react/static-property-placement
  static propTypes: $TSFixMe;

  constructor(props: PropsWithChildren<DropdownProps>) {
    super(props);

    this.state = {
      visible: false
    };

    this.searchInList = this.searchInList.bind(this);
    this.hide = this.hide.bind(this);
  }

  show(e?: Event) {
    const { id, stopEventPropagation } = this.props;

    if (stopEventPropagation) e?.stopPropagation();

    this.setState({ visible: true });

    if (id) {
      setTimeout(() => scrollToElementInList(`${id}_list`, `${id}_selected_item`, 'top'), 100);
    }

    document.addEventListener('click', this.hide);
  }

  handleKeyDown(e: $TSFixMe) {
    const { visible } = this.state;
    const { allowSearch } = this.props;

    if (allowSearch) this.searchInList(e);

    switch (e.key) {
      case 'Enter': // toggles dropdown list
        e.preventDefault();
        if (visible) this.hide(e);
        else this.show();
        break;
      case 'Escape': // hides dropdown list if visible
        e.preventDefault();
        if (visible) this.hide(e);
        break;
      case 'ArrowUp': // selects previous item
        e.preventDefault();
        this.selectPreviousItem();
        break;
      case 'ArrowDown': // selects next item
        e.preventDefault();
        this.selectNextItem();
        break;
      default:
        break;
    }
  }

  handleSelect(item: ListItem) {
    if (item.subItems) return;

    const { onSelect } = this.props;
    onSelect(item);
  }

  selectPreviousItem() {
    const { id, list, selected } = this.props;
    const selectedIndex = list.findIndex(({ value }) => selected === value);
    const newSelection = selectedIndex > 0 ? list[selectedIndex - 1] : null;

    if (newSelection) {
      scrollToElementInList(`${id}_list`, `${id}_${newSelection.value}_item`, 'top');
      this.handleSelect(newSelection);
    }
  }

  selectNextItem() {
    const { id, list, selected } = this.props;
    const selectedIndex = list.findIndex(({ value }) => selected === value);
    const newSelection = selectedIndex < list.length - 1 ? list[selectedIndex + 1] : null;

    if (newSelection) {
      scrollToElementInList(`${id}_list`, `${id}_${newSelection.value}_item`, 'bottom');
      this.handleSelect(newSelection);
    }
  }

  hide(e: $TSFixMe) {
    if (e.target.id !== 'Delete' && !e.target.className.includes('dont_hide')) {
      this.setState({ visible: false });
      document.removeEventListener('click', this.hide);
      e.stopPropagation();
    }
  }

  /**
   * For usability and accessibility this function mimics the functionality of the HTML <Select> element to
   * allow the users to search within the dropdown list
   * @param {event} e
   */
  searchInList(e: $TSFixMe) {
    const { id, list, searchType } = this.props;

    sequenceOfKeys = `${sequenceOfKeys}${e.key}`;

    const { elementID, itemIndex } = this.findElementFromSearchQuery(sequenceOfKeys, searchType);

    // if item and id exist => scroll to the item and select it and clear the sequence after half a second
    if (elementID && itemIndex > -1) {
      scrollToElementInList(`${id}_list`, elementID, 'top');
      this.handleSelect(list[itemIndex]);

      setTimeout(() => {
        sequenceOfKeys = '';
      }, 1000);
    } else if (e.key === 'Enter') {
      // otherwise on enter clear sequence and hide the list
      sequenceOfKeys = '';
      this.hide(e);
    } else {
      setTimeout(() => {
        sequenceOfKeys = '';
      }, 1000);
    }
  }

  /**
   * Given the sequence of characters clicked this returns the id of the first element which includes that sequence of characters
   * @param {string} sequenceOfKeys
   */
  findElementFromSearchQuery(sequenceOfKeys: $TSFixMe, searchType: $TSFixMe) {
    const nodeList = document.getElementsByClassName('item');

    // @ts-expect-error TS(2802) FIXME: Type 'HTMLCollectionOf<Element>' can only be itera... Remove this comment to see the full error message
    for (const element of nodeList) {
      const elementID = element.id;
      const elementText = element.firstChild.innerHTML || '';
      const itemIndex = element.dataset.index ? parseInt(element.dataset.index, 10) : -1;

      const isMatching =
        searchType === 'start'
          ? elementText.toLowerCase().indexOf(sequenceOfKeys.toLowerCase()) === 0
          : elementText.toLowerCase().indexOf(sequenceOfKeys.toLowerCase()) > -1;

      if (isMatching) return { elementID, itemIndex };
    }

    return { elementID: '', itemIndex: -1 };
  }

  findLabel() {
    const { selected, list, isGroupedList, dropdownLabel } = this.props;
    let label;

    if (isGroupedList) label = getLabelFromGroupedOptions(list, selected);
    else label = list.find(({ value }) => value === selected)?.label || '';

    if (dropdownLabel && isString(label)) return `${dropdownLabel}: ${label}`;
    return label;
  }

  renderListItem(item: ListItem, index: number) {
    const { selected, id, stopEventPropagation } = this.props;
    const label = Array.isArray(item.label) ? item.label.map((label: string) => label) : item.label;
    const ariaLabel = `${id.replace(/[^A-Z0-9]+/gi, ' ')} ${label}`;

    return (
      <div
        key={`${item.value}_${index}`}
        data-index={index}
        data-testid="dropdown_item"
        id={
          selected && item.value === selected ? `${id}_selected_item` : `${id}_${item.value}_item`
        }
        className={ClassNames('item', item.className, {
          selected: (selected || selected === 0) && item.value === selected,
          disabled: item.disabled,
          dont_hide: item.dontHide,
          is_dangerous: item.isDangerous
        })}
        // @ts-expect-error TS(2322) FIXME: Type '(e: Event) => void' is not assignable to typ... Remove this comment to see the full error message
        onClick={
          item.disabled
            ? () => {
                return;
              }
            : (e: Event) => {
                if (stopEventPropagation) e.stopPropagation();
                this.handleSelect(item);
              }
        }
      >
        <ItemLabelWrapper>
          <div>
            <span
              aria-label={selected && item.value === selected ? `selected ${ariaLabel}` : ariaLabel}
              className="item_text"
            >
              {item.price && <strong className="price_label">{item.price}</strong>}
              {label}
              {item.badge && <Badge text={item.badge} type={item.badgeType} />}
            </span>
            <NotificationCounterBubble>{item.notification}</NotificationCounterBubble>
          </div>
          <ConditionalRender predicate={item.subItems}>
            <span className="icon_simple_arrow_right sublist_arrow" />
          </ConditionalRender>
        </ItemLabelWrapper>
        <ConditionalRender predicate={item.subItems}>
          <Sublist className="dropdown_list sublist">
            {item.subItems?.map((subItem, idx) => this.renderListItem(subItem, idx))}
          </Sublist>
        </ConditionalRender>
      </div>
    );
  }

  render() {
    const {
      className,
      list,
      isGroupedList,
      selectMessage,
      id,
      inlineStyle,
      hideArrow,
      hideSelectedLabel,
      disabled,
      icon
    } = this.props;
    const { visible } = this.state;
    const selectedLabel = this.findLabel();
    const darkMode = getIsDarkMode_FIXME();
    const placeholder = Array.isArray(selectMessage) ? selectMessage.map(e => e) : selectMessage;

    return (
      <div
        id={id}
        aria-expanded={visible}
        aria-disabled={disabled}
        className={ClassNames(className, 'dropdown_wrapper', { show: visible }, { dark: darkMode })}
        style={inlineStyle}
      >
        <div
          className="dropdown_selector"
          data-testid="dropdown_selector"
          aria-disabled={disabled}
          onClick={
            disabled
              ? () => {
                  return;
                }
              : // @ts-expect-error TS(2345) FIXME: Argument of type 'MouseEvent<HTMLDivElement, Mouse... Remove this comment to see the full error message
                e => this.show(e)
          }
          onKeyDown={e => this.handleKeyDown(e)}
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
          tabIndex="0"
        >
          <span
            aria-label={`${id.replace(/[^A-Z0-9]+/gi, ' ')} drop-down`}
            id="selected_label"
            className={ClassNames({ unselected: selectedLabel.length === 0, dark: darkMode })}
          >
            {icon && <span className={icon} />}
            {hideSelectedLabel ? placeholder : selectedLabel || placeholder}
          </span>
          {hideArrow ? '' : <div className="icon_simple_arrow_down" />}
        </div>
        <div id={`${id}_list`} className={ClassNames('dropdown_list', { dark: darkMode })}>
          {isGroupedList
            ? list.map(({ category, options }: $TSFixMe) => (
                <div key={category} className="grouped_items">
                  <strong className="group_title">{category}</strong>
                  {options.map((item: $TSFixMe, index: $TSFixMe) =>
                    this.renderListItem(item, index)
                  )}
                </div>
              ))
            : list.map((item, index) => this.renderListItem(item, index))}
        </div>
      </div>
    );
  }
}

const ItemLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon_simple_arrow_right {
    font-size: var(--type-xs);
  }
`;

const Sublist = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
`;
