import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useUserType } from 'store';
import { checkIfTextFilter } from 'v2/services/tools/positionSearchFilter';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';
import Button from 'components/button';

import SearchInput from 'v2/components/ui/molecules/SearchInput';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

export default function SearchBar() {
  const { isCandidate } = useUserType();
  const {
    isOpen,
    operator,
    matchingOptions,
    searchInputRef,
    showSearchInput,
    attribute,
    labelTitle,
    placeholder = '',
    selectedItems,
    handleInputUpdate,
    toggleFilterPanel,
    clearSearchKeyword,
    addSearchItem
  } = useExpandableFilter();

  const isTextFilter = checkIfTextFilter(attribute);

  useEffect(() => {
    if (isTextFilter) searchInputRef.current?.setInput(selectedItems?.[0]?.value);
  }, [isTextFilter]);

  const handleSubmit = (value: string) => {
    if (isTextFilter) {
      addSearchItem!(attribute!, `“${value}”`, value);
      clearSearchKeyword();
      toggleFilterPanel();
      return;
    }

    if (matchingOptions?.length === 1) {
      const option = matchingOptions[0];
      const attr = operator.attribute || option.attribute;
      const label = (isCandidate ? '' : `${labelTitle} `) + option.label;

      addSearchItem!(attr!, label, option.value);
      clearSearchKeyword();
    }
  };

  const handleApply = () => {
    const keyword = searchInputRef.current?.getSearchKeyword();

    if (keyword) handleSubmit(keyword);
  };

  if (!showSearchInput) return null;
  return (
    <>
      <TextInput
        ref={searchInputRef}
        className="search_input"
        placeholder={placeholder}
        searchIcon={isTextFilter ? 'icon_text' : 'icon_search'}
        isClearable={false}
        autoFocus={isOpen}
        onUpdate={isTextFilter ? undefined : handleInputUpdate}
        onSubmit={handleSubmit}
      />
      <ConditionalRender predicate={isTextFilter}>
        <ButtonCont>
          <Button text="Clear" buttonStyle="stroke_dark_blue" action={clearSearchKeyword} />
          <Button text="Apply" buttonStyle="fill_dark_blue" action={handleApply} />
        </ButtonCont>
      </ConditionalRender>
    </>
  );
}

const TextInput = styled(SearchInput)`
  &.search_input {
    border-bottom: 1px solid var(--border-divider-soft);

    &,
    input {
      margin: 0;
    }

    [class*='icon_'] {
      left: 0.5rem;
      font-weight: ${({ theme: { typography } }) => typography.black};
      font-size: var(--type-lg);
    }

    input {
      padding: 0.75rem 0.5rem 0.75rem 38px;

      &,
      &:focus {
        border: none;
      }
    }
  }
`;

const ButtonCont = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: 0.75rem;

  .button {
    flex: 1;
  }
`;
