import {
  CandidateSignupVerificationStatus as CSVS,
  CandidateLanguageProficiency,
  SalaryToolQueryParams,
  SalaryToolCategory,
  SalaryPercentilesBySeniority,
  SalaryPercentilesByYearsExperience,
  AverageSalaryGrowth,
  AverageSalaryRange,
  LocationInterface,
  WorkEligibility,
  CandidatePublicationInterface,
  ApplicationEntity,
  SalaryToolTopItem,
  ParsedCandidateLocationPreference,
  UserType,
  CandidateFullProfileDTO,
  RemoteOption
} from '@cohiretech/common-types';

import { decodeCandidateExperience } from 'v2/services/candidate';

import { TemplateVariables } from './template';

export type CandidateProfile = Omit<
  CandidateFullProfileDTO,
  // The following properties are omitted because we parse/rename them - see CandidateProfileParsedProperties
  | 'websiteURL'
  | 'linkedinURL'
  | 'githubURL'
  | 'stackURL'
  | 'behanceURL'
  | 'expectedSalaryMin'
  | 'originalCVURL'
  | 'invisible'
  | 'occupations'
  | 'achievements'
  | 'educations'
  | 'projects'
  | 'awards'
  | 'publications'
> &
  CandidateProfileParsedProperties &
  CandidateProfileAddedProperties;

type CandidateProfileParsedProperties = {
  // Parsed properties are added onto the profile in our decodeCandidateProfile function in fetcher.ts
  educations: any[];
  experiences: any[];
  projects: any[];
  awards: any[];
  publications: CandidatePublication[];
  originalCVURL: string;
  website: string;
  salaryMin: number;
  behance: string;
  github: string;
  stackOverflow: string;
  linkedin: string;
};

type CandidateProfileAddedProperties = {
  // Added onto the profile in our decodeCandidateProfile function in fetcher.ts
  profileType: UserType.Candidate;
  isCandidate?: boolean;
  visible: boolean;
  // Added onto the profile in our getCandidateProfile function in our v2 services folder
  notifications: CandidateNotificationResult;
  totalNotifications: number;
};

export type CandidateFullInfo = CandidateProfile & {
  skip_status: { skipped: boolean };
  appliedListing: { [key in 'listingID' | 'listing_id']: number }[];
  templateVariables: TemplateVariables;
  candidateHasBlocked: boolean;
  companyHasBlocked: boolean;
  currentApplication: undefined | object;
  relocate?: boolean;
  relocateLocations?: LocationInterface[];
};

export type ConversationInCandidateItem = Pick<
  ApplicationEntity,
  | 'company_id'
  | 'company_interested'
  | 'candidate_interested'
  | 'candidate_date'
  | 'company_date'
  | 'conversation_started'
> & { name: string };

export type KeyStatusBeforeLive = CSVS.Pending | CSVS.Accepted | CSVS.Rejected | undefined;

export type ILanguage = {
  id?: number;
  name?: string;
  code?: string;
  proficiency?: CandidateLanguageProficiency;
};

export interface ILanguageItem {
  id?: number;
  attribute?: string;
  value: string;
  label: string;
  proficiency: CandidateLanguageProficiency;
  code: string;
}

export type CandidateNotificationResult = {
  companyDeclinedCount: number;
  interestCount: number;
  unreadConvoMessageCount: number;
};

// Salary Tool
export type SalaryToolFilterItem = keyof Omit<SalaryToolQueryParams, 'sourceID'>;

export type SalaryToolFilter = Partial<{ [key in SalaryToolFilterItem]: any }>;

export const INSUFFICIENT_DATA = 'Insufficient data';

export type SalaryExpectation = {
  salaryBySeniority: SalaryPercentilesBySeniority | typeof INSUFFICIENT_DATA;
  salaryByYearsExperience: SalaryPercentilesByYearsExperience | typeof INSUFFICIENT_DATA;
  totalDataPoints: number;
  removedParams: SalaryToolFilterItem[];
};

export type AverageSalary = {
  averageSalaryGrowth: AverageSalaryGrowth | typeof INSUFFICIENT_DATA;
  averageSalaryRange: AverageSalaryRange | typeof INSUFFICIENT_DATA;
  highestPaidIndustries: SalaryToolTopItem[];
  highestPaidSkills: SalaryToolTopItem[];
  totalDataPoints: number;
  removedParams: SalaryToolFilterItem[];
};

export type SalaryToolResponse = {
  [SalaryToolCategory.SalaryExpectation]: SalaryExpectation;
  [SalaryToolCategory.AverageSalary]: AverageSalary;
};

export type CandidateEmailType = 'saved_search' | 'digest' | 'feature';

export type CandidateSignupForm = {
  salaryMin: number;
  currency: string;
  remote: RemoteOption[];
  workEligibilities: WorkEligibility[];
  jobType: string;
  location: LocationInterface;
  locationCity: string;
  locationWorkPreferences: ParsedCandidateLocationPreference[];
  dataSource: 'cv' | 'linkedin';
  email: string;
  password: string;
  linkedinURL: string;
  termsAccepted: boolean;
};
export interface CandidatePublication
  extends Omit<CandidatePublicationInterface, 'candidateID' | 'publishDate'> {
  month: number;
  year: number;
}

export type CandidateExperience = ReturnType<typeof decodeCandidateExperience>;
