import React, { useRef, ReactElement, cloneElement } from 'react';

import { useClickDetector } from 'hooks/useClickDetector';
import { noop } from 'utils/fn';

interface Props {
  children: ReactElement;
  onClickOutside?: (e: MouseEvent) => void;
  onClickInside?: (e: MouseEvent) => void;
}

const ClickDetector: React.FC<Props> = ({
  children,
  onClickOutside = noop,
  onClickInside = noop
}) => {
  const childRef = useRef<HTMLDivElement>(null);
  useClickDetector(childRef, onClickOutside, onClickInside);

  return cloneElement(children, { ref: childRef });
};

export function ConditionalClickDetector({
  active,
  children,
  ...props
}: Props & { active?: boolean }) {
  if (active) return <ClickDetector {...props}>{children}</ClickDetector>;
  return children;
}

export default ClickDetector;
