import { useState, useEffect } from 'react';

import { isEmpty, noop } from 'utils/fn';

type SearchParams = string | Record<string, string>;

type UseSearchParams<T = Record<string, string>> = {
  searchParams: T;
  setSearchParams: (newParams: SearchParams) => void;
  updateSearchParams: (newParams: SearchParams) => void;
  resetSearchParams: () => void;
};

export const useSearchParams = <T = Record<string, string>>(
  onUpdate: (newParams: URLSearchParams) => void = noop
): UseSearchParams<T> => {
  const [searchParams, setLocalSearchParams] = useState(
    new URLSearchParams(window.location.search)
  );

  useEffect(() => {
    onUpdate(searchParams);
    const onPopState = () => {
      const params = new URLSearchParams(window.location.search);
      setLocalSearchParams(params);
      onUpdate(params);
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [onUpdate]);

  const setSearchParams = (newParams: SearchParams | URLSearchParams) => {
    const params = new URLSearchParams(newParams);
    setLocalSearchParams(params);

    window.history.pushState({}, '', `?${params}`);
  };

  const resetSearchParams = () => {
    setSearchParams({});
  };

  const updateSearchParams = (newParams: SearchParams | URLSearchParams) => {
    const params = new URLSearchParams(newParams);
    const mergedParams = new URLSearchParams(searchParams);

    params.forEach((value, key) => {
      if (isEmpty(value)) mergedParams.delete(key);
      else mergedParams.set(key, value);
    });

    setLocalSearchParams(mergedParams);
    window.history.pushState({}, '', `?${mergedParams}`);
  };

  return {
    searchParams: Object.fromEntries(searchParams.entries()) as T,
    setSearchParams,
    updateSearchParams,
    resetSearchParams
  };
};
