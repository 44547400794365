export const COMPANIES_HUB_LINK = 'https://companies.cord.co';

// Guides
export const USER_ROLES_GUIDE = 'jTkb7ZCiwNMBePBjCRr18v';
export const BILLING_INFORMATION_GUIDE = '6LkbQ1YpNH6VYDRgfD2sfe';
export const RECREDITS_GUIDE = '9gA31p6a4xMYzG3gBHzxAG';
export const REACHING_SOURCING_LIMIT_GUIDE = 'hS4vTbMcghp2cN58aRsj5d';
export const CREATE_STREAMS_GUIDE = '3ReSLgtyx3idfQuiRJPafo';
export const CREATE_POSITIONS_GUIDE = 'djDdtt1cX1VkHX1ep88sJE';
export const NOTIFICATIONS_MANAGEMENT_GUIDE = 'pPN2mrgAfNZKLQnVUh4QzL';
export const INBOX_MANAGEMENT_GUIDE = '5NxbKcBVCqhEob23t6QXej';
export const SLACK_INTEGRATION_GUIDE = 'sZfHohkKxGntUMtByZRERh';
export const REFERRAL_PROGRAMME_GUIDE = 'om4zKLkhvax3qe13MaBDX6';
export const ANNUAL_PACKAGES_GUIDE = 'wpjrxdYaDwRyDEi5S6VuEL';
export const UNDERREPRESENTED_GROUPS_ARTICLE = 'vDNPRmejW5zMogLc22gSHW';
export const TEAMTAILOR_HUB = 'ce66i1XDo3ZErNVp2MzNsf';
export const ASHBY_HUB = 'nogvPYMWNVaenEA9TxPos3';
export const PINPOINT_HUB = 'wWgEwysR2XAKG5cgJ1buJ9';
export const RECRUITEE_HUB = 'vypfDjD3u3WivdVTzjMmQ5';
export const HIRING_LEADERS_FAQ = 'q2F1jR4EWANxMdDYgHpkEy';
export const FIT_SCORE_GUIDE = 'jCjuCWhXL8qiJwXnQkxs25';
export const AGENCY_RECRUITERS_GUIDE = 'idgoeshere';

// Expert guides
export const JOB_DESCRIPTION_GUIDE = 'keSPCpeGKx24ENFPyeuTYD';
export const OUTREACH_MESSAGES_GUIDE = 'mGVeiM74FbRC7xjLe7VQTd';
export const REDUCE_TIME_TO_MESSAGE_GUIDE = '5yehVuwzby2zFbTP4MyiDz';
export const WHY_RESPONSIVENESS_IS_IMPORTANT_GUIDE = '31JcRavbvDKGLiLLnpGMPC';
export const FOLLOW_UP_MESSAGES_EXAMPLES_GUIDE = 'mzXqwbcDXBiymDB7bcyUbo';
export const SOURCING_GUIDE = 'phkihTRsF9MHrKBh5bAhmh';
export const ATTRACTING_RIGHT_CANDIDATES_GUIDE = 't9t3Vai5j2suhJY1GhmAzr';
