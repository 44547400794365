/**
 * Creates an array of values that are in `array1` but not in `array2`.
 *
 * @template T - The type of the arrays.
 * @param {T[]} array1 - The first array.
 * @param {T[]} array2 - The second array.
 * @returns {T[]} Returns the new array of filtered values.
 *
 * @example
 * // Returns array of different elements.
 * const arr1 = [1, 2, 3, 4];
 * const arr2 = [3, 4, 5, 6];
 *
 * console.log(difference(arr1, arr2));   // => [1, 2]
 */
export function difference<T>(array1: T[], array2: T[]): T[] {
  return array1.filter(element => !array2.includes(element));
}
