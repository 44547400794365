import React, {
  KeyboardEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { ClearIcon, SearchIcon, SearchInputWrapper } from './SearchInput.styled';

type OnSubmit = (value: string) => void;
type OnKeyDown = KeyboardEventHandler<HTMLInputElement>;

export type SearchInputInstance = {
  getSearchKeyword: () => string;
  setInput: (value: string) => void;
};

type SearchInputHandlers =
  | {
      onSubmit: OnSubmit;
      onKeyDown?: never;
    }
  | {
      onSubmit?: never;
      onKeyDown: OnKeyDown;
    };

type SearchInputProps = {
  className?: string;
  placeholder: string;
  searchIcon?: string;
  clearIcon?: string;
  autoFocus?: boolean;
  alwaysFocused?: true;
  isClearable?: boolean;
  onUpdate?: (value: string) => void;
} & SearchInputHandlers;

const SearchInput = forwardRef<SearchInputInstance, SearchInputProps>(
  (
    {
      className,
      placeholder,
      searchIcon = 'icon_search',
      clearIcon,
      autoFocus,
      alwaysFocused,
      isClearable = true,
      onSubmit,
      onUpdate,
      onKeyDown
    },
    ref
  ) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [input, setInput] = useState('');

    const focusInput = () => {
      if (autoFocus) searchInputRef.current?.focus();
      return;
    };

    useEffect(() => {
      focusInput();
    }, [autoFocus]);

    useEffect(() => {
      onUpdate?.(input);
    }, [input]);

    useImperativeHandle(
      ref,
      () => ({ getSearchKeyword: () => input, setInput: value => setInput(value) }),
      [input]
    );

    return (
      <SearchInputWrapper className={className}>
        <SearchIcon className={searchIcon} />
        <input
          ref={searchInputRef}
          type="text"
          placeholder={placeholder}
          onChange={e => setInput(e.target.value)}
          onKeyDown={onKeyDown || onEnter(onSubmit)}
          onBlur={alwaysFocused && focusInput}
          value={input}
        />
        <ConditionalRender predicate={isClearable && input}>
          <ClearIcon
            className={clearIcon || undefined}
            onClick={() => {
              setInput('');
            }}
          >
            {clearIcon ? '' : 'Clear'}
          </ClearIcon>
        </ConditionalRender>
      </SearchInputWrapper>
    );
  }
);

const onEnter =
  (onSubmit: (value: string) => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit(e.currentTarget.value);
    }
  };

export default SearchInput;
