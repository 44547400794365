import React, { useMemo } from 'react';
import ClassNames from 'classnames';

import { CompanyAccountInterface, CompanyType } from '@cohiretech/common-types';

import { PositionInProfile } from 'types';
import { getResponseTime, isEmpty } from 'utils';
import { useDarkMode } from 'hooks/useDarkMode';
import { identifyCompanyType } from 'v2/services/company/companyType';

import Badge from 'components/badge';
import ButtonDropdown from 'components/buttondropdown';

import Avatar from 'v2/components/ui/atoms/Avatar';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

type PrimaryButtonOptions = Partial<PositionInProfile> & {
  name: string;
  minSalary: number;
  maxSalary: number;
};

type Props = {
  note?: string;
  className: string;
  primaryButtonAction: (positionID: number) => void;
  publicAction: (positionID: number) => void;
  userLoggedIn: boolean;
  accountBeingVerified: boolean;
  primaryButton: string;
  primaryButtonOptions: PrimaryButtonOptions[];
  isCompany: boolean;
  appliedListing: $TSFixMe;
  companyName: string;
  companyType: CompanyType;
} & Pick<CompanyAccountInterface, 'memberName' | 'memberPhotoURL' | 'jobTitle' | 'responseTime'>;

export default function ButtonPanel({
  note,
  className,
  userLoggedIn,
  publicAction,
  primaryButton,
  primaryButtonAction,
  primaryButtonOptions,
  appliedListing,
  memberName,
  jobTitle,
  responseTime,
  memberPhotoURL,
  accountBeingVerified,
  isCompany,
  companyName,
  companyType
}: Props) {
  const darkMode = useDarkMode();
  const { isAgency } = identifyCompanyType(companyType);

  const positionOptions = useMemo(() => {
    if (!isEmpty(primaryButtonOptions)) {
      return primaryButtonOptions.map(({ id, name }) => {
        return {
          label: name,
          value: id,
          disabled:
            accountBeingVerified || isCompany
              ? true
              : appliedListing
              ? appliedListing.findIndex(
                  ({ listing_id }: { listing_id: number }) => listing_id === id
                ) > -1
              : false
        };
      });
    } else return [];
  }, [primaryButtonOptions, accountBeingVerified, isCompany, appliedListing]);

  if (note) {
    return (
      <div className={ClassNames('button_panel', className)}>
        <div className="note_only">{note}</div>
      </div>
    );
  } else {
    return (
      <div className={ClassNames('button_panel', className)}>
        <div className="persons_details">
          <Avatar photoURL={memberPhotoURL} width={50} firstname={memberName} />
          <div className="profile_info">
            <strong>
              {memberName}, {jobTitle}
              <ConditionalRender predicate={isAgency}>
                <Badge icon="icon_handshake" text="Agency recruiter" type="info_light" />
              </ConditionalRender>
            </strong>
            {responseTime === 'N/A'
              ? companyName
              : `Typically responds ${getResponseTime(responseTime)}`}
          </div>
        </div>
        <ButtonDropdown
          label={primaryButton}
          darkMode={darkMode}
          options={positionOptions}
          onSelect={
            userLoggedIn ? value => primaryButtonAction(value) : value => publicAction(value)
          }
          // @ts-expect-error TS(2322) FIXME: Type '{ text: string; } | null' is not assignable ... Remove this comment to see the full error message
          tooltip={
            accountBeingVerified ? { text: 'Hold on, your information is being verified.' } : null
          }
        />
      </div>
    );
  }
}
