import { CandidateSearchResult } from 'types';
import { compose } from 'utils/fn';
import { addDimensionsOnImages, getImageDimensions } from 'v2/services/image';
import { pluck } from 'utils/object';

type CandidateInSearchResult = CandidateSearchResult['candidate'];

export const addImageDimensionsToSearchResults = (
  candidates: CandidateSearchResult[]
): CandidateSearchResult[] => {
  const imageParams = { type: 'logo', dimensions: getImageDimensions('logo-normal') } as const;
  const justCandidates = candidates.map(pluck('candidate'));
  const modifiedCandidates = addDimensionsOnImages(
    justCandidates,
    imageParams
  ) as CandidateInSearchResult[];

  return modifiedCandidates.map((candidate, index) => ({
    candidate,
    status: candidates[index].status
  }));
};

export const wrapSearchResults = (
  candidates: CandidateInSearchResult[]
): CandidateSearchResult[] => {
  return candidates.map(candidate => ({
    candidate,
    status: 'visible'
  }));
};

export const wrapAndModifySearchResults = compose<
  CandidateInSearchResult[],
  CandidateSearchResult[]
>(addImageDimensionsToSearchResults, wrapSearchResults);

export const numberOfVisibleResults = (candidates: CandidateSearchResult[]): number => {
  return candidates.filter(({ status }) => status === 'visible').length;
};

export const mapResultsToIds = (candidates: CandidateSearchResult[]) => {
  return candidates
    .filter(({ status }) => status === 'visible')
    .map(({ candidate: { id } }) => id!);
};
