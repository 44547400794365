import styled, { css } from 'styled-components';

export const CustomPromptContainer = styled.div`
  position: absolute;
  top: 3rem;
  box-sizing: border-box;
  outline: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: calc(100% - 1.75rem);
  border: none;
  background: ${({ theme }) => theme.container};
  padding: 0 1rem;
  margin: 0;
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.fn.shadow()};
`;

export const IconWrapper = styled.div`
  margin-top: 0.75rem;
`;

export const TextArea = styled.textarea`
  flex: 1;
  resize: none !important;
  outline: none;
  border: none;
  min-height: 1em; // Set an explicit initial height
  max-height: 10em;

  overflow: hidden;
  font-family: inherit;
  line-height: 1.5;
  ${css`
    rows: 1;
  `}

  &:focus {
    outline: none !important;
    border: none !important;
  }

  font-size: ${({ theme: { typography } }) => typography.normal} !important;
  padding: 0.75rem 0.5rem;
`;

export const PromptIdea = styled.div`
  margin-top: 0.5rem;
  font-size: ${({ theme: { typography } }) => typography.small};
  padding-bottom: 0.5rem;
  font-weight: ${({ theme }) => theme.typography.bold};
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
`;
