import { PricingSubscriptionPlan } from '@cohiretech/common-types';

import { setCompanyTracking } from 'fetcher';
import { PRICING_OPTIONS } from 'consts';

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

const onChoosePlan = async (plan: $TSFixMe, url: $TSFixMe) => {
  await setCompanyTracking('plan-selected', { plan });
  window.location.href = url;
};

const pricingPlans = {
  earlyHires: {
    title: PRICING_OPTIONS.earlyHires.label,
    description: '1-5 employees',
    features: {
      monthly: 'Unlimited users, positions and hires. No hiring fees. Cancel anytime.',
      annual: 'Annual option is currently unavailable for Early Hires companies.'
    }
  },
  startUp: {
    title: PRICING_OPTIONS.startUp.label,
    description: '6-500 employees',
    features: {
      monthly: 'Unlimited users, positions and hires. No hiring fees. Cancel anytime.',
      annual: 'Unlimited users, positions and hires. No hiring fees.'
    }
  },
  midMarket: {
    title: PRICING_OPTIONS.midMarket.label,
    description: '501-5000 employees',
    features: {
      monthly: ['3 user seats. Unlimited positions and hires. No hiring fees.'],
      annual: ['3 user seats. Unlimited positions and hires. No hiring fees.']
    }
  },
  enterprisePerUser: {
    title: PRICING_OPTIONS.enterprisePerUser.label,
    description: '5000+ employees',
    features: {
      monthly: ['3 user seats. Unlimited positions and hires. No hiring fees.'],
      annual: ['3 user seats. Unlimited positions and hires. No hiring fees.']
    }
  }
};

export const getPlans = (showRecommended = false, companySize: $TSFixMe, pricingType: $TSFixMe) => {
  const earlyHiresCtaText =
    showRecommended && companySize > 5
      ? 'Not available'
      : showRecommended
      ? 'Create an account'
      : 'Apply now';
  const earlyHiresCtaLink = showRecommended ? '' : '/request-demo?plan=Early Hires';
  const startupCtaText = 'Create an account';
  const getStartUpCtaLink = () => {
    if (showRecommended) return '';
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return `/checkout/${PRICING_OPTIONS.startUp[pricingType].monthly.urlPath}`;
  };

  const allPlans = [
    {
      ...pricingPlans.earlyHires,
      price: {
        monthly: {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          amount: PRICING_OPTIONS.earlyHires[pricingType].monthly.newPrice,
          paid: 'p/m',
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          oldAmount: PRICING_OPTIONS.earlyHires[pricingType].monthly.oldPrice
        },
        annual: { amount: 'Not available' }
      },
      button: {
        monthly: {
          text: earlyHiresCtaText,
          action: showRecommended
            ? () =>
                onChoosePlan(
                  PRICING_OPTIONS.earlyHires.value,
                  `${domain}/checkout/${
                    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    PRICING_OPTIONS.earlyHires[pricingType].monthly.urlPath
                  }`
                )
            : null,
          link: earlyHiresCtaLink,
          disabled: showRecommended && companySize > 5
        },
        annual: { text: 'Contact us', link: '', disabled: true }
      },
      disabled: showRecommended && companySize > 5 && companySize > 0,
      recommended: showRecommended && companySize < 6 && companySize > 0
    },
    {
      ...pricingPlans.startUp,
      price: {
        monthly: {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          amount: PRICING_OPTIONS.startUp[pricingType].monthly.newPrice,
          paid: 'p/m',
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          oldAmount: PRICING_OPTIONS.startUp[pricingType].monthly.oldPrice
        },
        annual: {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          amount: PRICING_OPTIONS.startUp[pricingType].annual.newPrice,
          paid: 'p/a',
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          savings: PRICING_OPTIONS.startUp[pricingType].annual.savings
        }
      },
      button: {
        monthly: {
          text:
            showRecommended && (companySize < 6 || companySize > 500) && companySize > 0
              ? 'Not available'
              : startupCtaText,
          action: showRecommended
            ? () =>
                onChoosePlan(
                  PRICING_OPTIONS.startUp.value,
                  `${domain}/checkout/${
                    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    PRICING_OPTIONS.startUp[pricingType].monthly.urlPath
                  }`
                )
            : null,
          link: getStartUpCtaLink()
        },
        annual: {
          text:
            showRecommended && (companySize < 6 || companySize > 500) && companySize > 0
              ? 'Not available'
              : 'Contact us',
          action: showRecommended
            ? () =>
                onChoosePlan(
                  `${PRICING_OPTIONS.startUp.value} (annual)`,
                  `${domain}/request-demo?plan=Start-up Annual`
                )
            : null,
          link: showRecommended ? '' : '/request-demo?plan=Start-up Annual'
        }
      },
      disabled: showRecommended && (companySize < 6 || companySize > 500) && companySize > 0,
      recommended: showRecommended && companySize > 6 && companySize < 501 && companySize > 0
    },
    {
      ...pricingPlans.midMarket,
      price: {
        monthly: {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          amount: PRICING_OPTIONS.midMarket[pricingType].monthly.newPrice,
          paid: 'p/m',
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          oldAmount: PRICING_OPTIONS.midMarket[pricingType].monthly.oldPrice
        },
        annual: {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          amount: PRICING_OPTIONS.midMarket[pricingType].annual.newPrice,
          paid: 'p/a',
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          savings: PRICING_OPTIONS.midMarket[pricingType].annual.savings
        }
      },
      button: {
        monthly: {
          text:
            showRecommended && companySize < 501 && companySize > 0
              ? 'Not available'
              : 'Contact us',
          action: showRecommended
            ? () =>
                onChoosePlan(
                  PRICING_OPTIONS.midMarket.value,
                  `${domain}/request-demo?plan=Mid-Market`
                )
            : null,
          link: showRecommended ? '' : '/request-demo?plan=Mid-Market'
        },
        annual: {
          text:
            showRecommended && companySize < 501 && companySize > 0
              ? 'Not available'
              : 'Contact us',
          action: showRecommended
            ? () =>
                onChoosePlan(
                  `${PRICING_OPTIONS.midMarket.value} (annual)`,
                  `${domain}/request-demo?plan=Mid-Market Annual`
                )
            : null,
          link: showRecommended ? '' : '/request-demo?plan=Mid-Market Annual'
        }
      },
      disabled: showRecommended && companySize < 501 && companySize > 0,
      recommended: showRecommended && companySize > 500 && companySize > 0
    },
    {
      ...pricingPlans.enterprisePerUser,
      price: {
        monthly: { amount: 'Contact us' },
        annual: { amount: 'Contact us' }
      },
      button: {
        monthly: {
          text:
            showRecommended && companySize < 5001 && companySize > 0
              ? 'Not available'
              : 'Contact us',
          action: showRecommended
            ? () =>
                onChoosePlan(
                  PRICING_OPTIONS.enterprisePerUser.value,
                  `${domain}/request-demo?plan=Enterprise`
                )
            : null,
          link: showRecommended ? '' : '/request-demo?plan=Enterprise'
        },
        annual: {
          text:
            showRecommended && companySize < 5001 && companySize > 0
              ? 'Not available'
              : 'Contact us',
          action: showRecommended
            ? () =>
                onChoosePlan(
                  `${PRICING_OPTIONS.enterprisePerUser.value} (annual)`,
                  `${domain}/request-demo?plan=Enterprise Annual`
                )
            : null,
          link: showRecommended ? '' : '/request-demo?plan=Enterprise Annual'
        }
      },
      disabled: showRecommended && companySize < 5001 && companySize > 0,
      recommended: showRecommended && companySize > 5000 && companySize > 0
    }
  ];

  return showRecommended && companySize > 0 && companySize < 500
    ? allPlans.slice(0, 3)
    : allPlans.slice(1, 4);
};

function isPlanRecommended(
  selectedPlan: $TSFixMe,
  previousPlan: $TSFixMe,
  availablePlans: $TSFixMe
) {
  const isPreviousAvailable = availablePlans.includes(previousPlan);

  return isPreviousAvailable && selectedPlan === previousPlan;
}

export const getReactivationPlans = (
  previousPlan: $TSFixMe,
  availablePlans: PricingSubscriptionPlan[] = [],
  pricingType: $TSFixMe
) => {
  const availablePlansNames = availablePlans.map(a => a?.subscriptionPlan?.plan); // hack to just pass the plan name. ideally, the plans shown should be constructed using the data obtained via API
  const allPlansArray = Object.keys(pricingPlans);
  let usedPlansArray = allPlansArray;
  if (
    availablePlansNames.includes('sourcing_early_hires') ||
    previousPlan === 'sourcing_early_hires'
  ) {
    usedPlansArray = allPlansArray.slice(0, 3);
  } else usedPlansArray = allPlansArray.slice(1, 4);

  return usedPlansArray.map(plan => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const { label, value } = PRICING_OPTIONS[plan];
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const { annual, monthly } = PRICING_OPTIONS[plan][pricingType];

    if (availablePlansNames?.includes('sourcing_enterprise')) {
      availablePlansNames.push('sourcing_enterprise_position');
    }

    const disabled = availablePlansNames && !availablePlansNames.includes(value);

    return {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ...pricingPlans[plan],
      id: plan,
      title: label,
      price: {
        monthly: { amount: monthly.newPrice, paid: 'p/m', oldAmount: monthly.oldPrice },
        annual:
          plan === 'earlyHires'
            ? { amount: 'Not available' }
            : { amount: annual.newPrice, paid: 'p/a', savings: annual.savings }
      },
      button: {
        monthly: { id: plan, text: disabled ? 'Not available' : 'Reactivate subscription' },
        annual: { id: plan, text: disabled ? 'Not available' : 'Notify us' }
      },
      disabled,
      recommended: isPlanRecommended(value, previousPlan, availablePlansNames)
    };
  });
};

export const getEnterprisePlans = (pricingType: $TSFixMe) => {
  return [
    {
      title: PRICING_OPTIONS.midMarket.label,
      description: '501-5000 employees',
      features: {
        monthly: ['3 user seats. Unlimited positions and hires. No hiring fees.'],
        annual: ['3 user seats. Unlimited positions and hires. No hiring fees.']
      },
      price: {
        monthly: {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          amount: PRICING_OPTIONS.midMarket[pricingType].monthly.newPrice,
          paid: 'p/m',
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          oldAmount: PRICING_OPTIONS.midMarket[pricingType].monthly.oldPrice
        },
        annual: {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          amount: PRICING_OPTIONS.midMarket[pricingType].annual.newPrice,
          paid: 'p/a',
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          savings: PRICING_OPTIONS.midMarket[pricingType].annual.savings
        }
      },
      button: {
        monthly: { text: 'Contact us', link: '/request-demo?plan=Mid-Market' },
        annual: { text: 'Contact us', link: '/request-demo?plan=Mid-Market Annual' }
      }
    },
    {
      title: PRICING_OPTIONS.enterprisePerUser.label,
      description: '5000+ employees',
      features: {
        monthly: ['3 user seats. Unlimited positions and hires. No hiring fees.'],
        annual: ['3 user seats. Unlimited positions and hires. No hiring fees.']
      },
      price: {
        monthly: { amount: 'Contact us' },
        annual: { amount: 'Contact us' }
      },
      button: {
        monthly: { text: 'Contact us', link: '/request-demo?plan=Enterprise' },
        annual: { text: 'Contact us', link: '/request-demo?plan=Enterprise Annual' }
      }
    }
  ];
};
