import { FeedInterface } from '@cohiretech/common-types';

import { getLocalStorageItem } from 'cookieManager';
import { CandidateSearchResult } from 'types';
import { hideOrUnhideCandidateAllPositions } from 'v2/services/fetchers/company/hideCandidate';

type CandidateHideOptions = {
  unhide: boolean;
  streamID?: number;
  showUndo: (name: string | undefined) => void | undefined;
};

type CandidateRemoveOptions = { shortlistID?: number };

type CandidateHideOrRemoveOptions =
  | (CandidateHideOptions & Partial<CandidateRemoveOptions>)
  | (Partial<CandidateHideOptions> & CandidateRemoveOptions);

export type ToggleCandidateVisibilityParams = {
  candidateID: number;
  searchResults: CandidateSearchResult[];
  searchResultsCount: number;
} & CandidateHideOrRemoveOptions;

export const toggleCandidateVisibility = async ({
  candidateID,
  searchResults,
  searchResultsCount,
  streamID,
  unhide = false,
  showUndo
}: ToggleCandidateVisibilityParams) => {
  const newResults = [...searchResults];
  let newCount = searchResultsCount;
  const searchPosition = newResults.findIndex(({ candidate: { id } }) => id === candidateID);

  const { status } = await hideOrUnhideBE(candidateID, searchPosition, { unhide, streamID });

  if (status === 'success') {
    newResults[searchPosition].status = unhide ? 'visible' : 'hidden';
    newCount = unhide ? searchResultsCount + 1 : searchResultsCount - 1;
    const candidateName = newResults[searchPosition].candidate.firstname;

    if (!unhide) showUndo?.(candidateName);
  }

  return { newResults, newCount, status };
};

const hideOrUnhideBE = async (
  resultID: number,
  searchPosition: number,
  options: CandidateHideOrRemoveOptions
) => {
  const uniqueIdentifier = getLocalStorageItem('uniqueIdentifier') || '';

  return hideOrUnhideCandidateAllPositions(
    resultID,
    uniqueIdentifier,
    searchPosition,
    !options.unhide,
    options.streamID
  );
};

export const getStreamDetails = (streamID: number, companyStreams: FeedInterface[]) => {
  const companyStream = (companyStreams || []).find(({ id }) => id === streamID);

  if (streamID !== -1 && companyStream) {
    const { id, searchParam, searchName, listingIDs, streamMembers } = companyStream;

    const searchItems = searchParam.params;
    const streamDetails = getLocalStorageItem('selectedStreamDetails') || {
      title: searchName,
      positions: listingIDs,
      members: streamMembers,
      id,
      searchParam: searchItems
    };

    return streamDetails;
  }

  return null;
};
