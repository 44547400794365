import React from 'react';

import * as fetcher from 'fetcher';

import ListingSlider from 'components/listingslider';
import Loader from 'components/loader';

type State = $TSFixMe;

export default class CompanySection extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = { companies: {}, loading: true };
  }

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'loadData' does not exist on type 'Readon... Remove this comment to see the full error message
    const { loadData } = this.props;

    if (loadData) this.getDynamicData();
  }

  componentDidUpdate(prevProps: {}) {
    if (
      (prevProps as $TSFixMe).loadData !== (this.props as $TSFixMe).loadData &&
      (this.props as $TSFixMe).loadData
    ) {
      this.getDynamicData();
    }
  }

  async getDynamicData() {
    // @ts-expect-error TS(2339) FIXME: Property 'remote' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { remote } = this.props;

    const sourceID = remote ? 'publicRemotePeoplePageV1' : 'publicPeoplePageV1';

    const sourceResults = await fetcher.getPublicSourcePositions(sourceID);
    const companies = sourceResults
      ? sourceResults[remote ? 'popularRemoteCompany' : 'popularCompany']
      : {};
    this.setState({ companies, loading: false });
  }

  render() {
    const { companies, loading } = this.state;

    if (loading) return <Loader className="medium" />;
    else if (companies && companies.values && companies.values.length > 0) {
      return (
        <ListingSlider
          key={companies.values}
          items={companies.values ? companies.values.slice(0, 10) : []}
          itemType="company_item"
          columns={3}
          showVideos
          {...this.props}
        />
      );
    } else return null;
  }
}
