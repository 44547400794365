import React from 'react';
import styled from 'styled-components';

import { trackBookDemoEvents } from 'tracking-utils';
import media from 'styles/media';

import ArrowLink from 'components/arrowlink';
import Button from 'components/button';
import CustomerStorySliderSection from 'components/customerstoryslidersection';
import FAQSection, { FAQSectionProps } from 'components/faqsection';
import PageSection from 'components/pagesection';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import typography from 'css/base/_typography.module.scss';

type PricingBottomSectionsProps = {
  showFAQs?: boolean;
  showVideoSection?: boolean;
  showStorySection?: boolean;
  showCharitySection?: boolean;
  isPayPerHire?: boolean;
} & Omit<FAQSectionProps, 'showROIButtons'>;

// WIP - After release: Check if it can be used for the legacy pricing as well
export default function PricingBottomSections({
  showFAQs = true,
  showVideoSection,
  showStorySection,
  showCharitySection,
  pricingCountry,
  isNewUser,
  pricingVer,
  isPayPerHire
}: PricingBottomSectionsProps) {
  return (
    <>
      <ConditionalRender predicate={showFAQs}>
        <FAQSection
          isNewUser={isNewUser}
          pricingCountry={pricingCountry}
          pricingVer={pricingVer}
          showROIButtons={showVideoSection ? 'both' : 'none'}
          isPayPerHire={isPayPerHire}
        />
      </ConditionalRender>
      <ConditionalRender predicate={showStorySection}>
        <CustomerStorySliderSection preferredCountry={pricingCountry} darkModeBgColour="dark" />
      </ConditionalRender>
      <ConditionalRender predicate={showCharitySection}>
        <CharitySection
          className="charity_cont gradient_blue_background"
          widthClassName="page_view"
        >
          <div className="left_cont">
            <h2 className="small_page_section_title">
              Don't pay hiring fees, <br /> give money to charity
            </h2>
            <h3 className="page_section_subtitle">
              We don't charge hiring fees when someone gets hired. In fact, we do the opposite. We
              give money to charity on your behalf. For every person hired through cord, we donate a
              week's worth of coding to someone from an underprivileged background via
              CodeYourFuture.
            </h3>
            <ButtonContainer className="button_container">
              <Button
                text="Book a demo"
                icon="icon_demo"
                link="/request-demo"
                action={() => trackBookDemoEvents('Book product tour | CodeYourFuture', 'click')}
              />
              <ArrowLink to="/partners/2-codeyourfuture">See our partnership</ArrowLink>
            </ButtonContainer>
          </div>
          <div className="right_cont">
            <img
              width="100%"
              src="https://assets.co-hire.com/codeyourfuture-coders.jpg"
              alt="CodeYourFuture"
              loading="lazy"
            />
          </div>
        </CharitySection>
      </ConditionalRender>
    </>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    margin-right: 35px;
    color: white !important;

    ${media.tabletLandscapeMin`
      font-size: ${typography.smallheader};
    `}
  }

  .arrow_link {
    margin-right: 0;
  }
`;

const CharitySection = styled(PageSection)`
  ${media.tabletMin`
    padding: 75px 0 85px !important;

    .page_view {
      padding: 0;

      .left_cont,
      .right_cont {
        width: calc(50% - 30px);
      }

      .left_cont {
        margin-right: 30px;

        .button_container {
          justify-content: flex-start;
        }
      }

      .right_cont {
        margin-left: 30px;
      }
    }
`}
`;
