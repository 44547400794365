/* eslint-disable no-console */
export const removeLocalStorageItem = (name: $TSFixMe) => {
  if (localStorage) localStorage.removeItem(JSON.stringify(name));
  else window.localStorage.removeItem(JSON.stringify(name));
};

export const getLocalStorageItem = <T = any>(name: string): T | null => {
  const key = JSON.stringify(name);
  const item = localStorage.getItem(key) || window.localStorage.getItem(key);
  const localStorageItem = item ? JSON.parse(item) : item;

  try {
    if (localStorageItem) {
      if (!localStorageItem.expires || localStorageItem.expires > new Date().getTime()) {
        return localStorageItem.value as T;
      }
    }
    removeLocalStorageItem(name);
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * Temporary function to get the darkMode value from local storage.
 * - getLocalStorageItem('theme') === 'dark' is used in many places.
 * - It should be replaced with useDarkMode.
 * - Search & Replace doesn't work because of imports.
 * - Remove this function when all instances are replaced.
 *
 * @returns {boolean} isDarkMode
 *
 * @deprecated Use `useDarkMode` instead.
 */
export const getIsDarkMode_FIXME = () => {
  return getLocalStorageItem('theme') === 'dark';
};

export const getLocalStorageExpiryDate = (name: string): number | null => {
  try {
    const localStorageItem =
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      JSON.parse(localStorage.getItem(JSON.stringify(name))) ||
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      JSON.parse(window.localStorage.getItem(JSON.stringify(name))) ||
      '';
    if (localStorageItem) return localStorageItem.expires;
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
export const hasLocalStorageExpired = name => {
  const localStorageItem = getLocalStorageItem(name);
  const expiryDate = getLocalStorageExpiryDate(name);

  if (!localStorageItem || !expiryDate || new Date(expiryDate) < new Date()) return true;
  return false;
};

export const setLocalStorageItem = (
  name: string,
  value: string | number | boolean | object | undefined,
  // @ts-expect-error TS(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
  duration?: number = 0
) => {
  let expires;
  if (duration) expires = new Date().getTime() + duration;

  if (localStorage) localStorage.setItem(JSON.stringify(name), JSON.stringify({ value, expires }));
  else window.localStorage.setItem(JSON.stringify(name), JSON.stringify({ value, expires }));
};

export const clearLocalStorage = () => {
  if (localStorage) localStorage.clear();
  else window.localStorage.clear();
};

// @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
export const getCookie = name => {
  const nameKey = `${name}=`;
  const cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }

    if (cookie.indexOf(nameKey) === 0) {
      return cookie.substring(nameKey.length, cookie.length);
    }
  }

  return null;
};

// @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
export const removeCookie = name => {
  // @ts-expect-error TS(2551) FIXME: Property 'toGMTString' does not exist on type 'Dat... Remove this comment to see the full error message
  document.cookie = `${name}=; expires=${new Date().toGMTString()}; path=/`;
};
