import React, { lazy, Suspense } from 'react';
import ClassNames from 'classnames';
import { candidateClearForm } from 'candidateSignupForm';

import * as fetcher from 'fetcher';
import { generateCompanyURL, generatePersonsURL, getBlockedMessage, isEmpty, retry } from 'utils';
import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from 'cookieManager';
import { hideScrollbar } from 'v2/services/ui';
import { storeApplicationAction } from 'utils/candidateUtils';

import { ApplicationDetailsLocationState } from 'views/positionprofile';

import Loader from 'components/loader';
import Seo from 'components/seo';

import ButtonPanel from './buttonpanel';

import './style.scss';

// @ts-expect-error TS(2322) FIXME: Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
const PersonProfile = lazy(() => retry(() => import('components/personprofile')));

type State = $TSFixMe;

type PersonProfileViewProps = {
  darkMode: boolean;
};

export default class PersonProfileView extends React.Component<PersonProfileViewProps, State> {
  constructor(props: PersonProfileViewProps) {
    super(props);

    const location = (props as $TSFixMe).location;

    this.state = {
      loading: true,
      user: {},
      showButtonPanel: false,
      landedFromExternalURL: location.key === undefined,
      profileHistoryLength: 1 // for handling the back and close icons
    };
  }

  getProfileID() {
    // @ts-expect-error TS(2339) FIXME: Property 'match' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { match } = this.props;
    const { id } = match.params;

    if (id) return parseInt(id, 10);
    else return -1;
  }

  async updateProfile() {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location, profile } = this.props;
    const { user } = this.state;
    const { pathname } = location;
    const profileID = this.getProfileID();

    if (profileID > -1 && user.id !== profileID) {
      const uniqueIdentifier = getLocalStorageItem('uniqueIdentifier') || '';
      const jobSearchPosition = getLocalStorageItem('jobSearchPosition') || 0;
      const profileHistoryLength = getLocalStorageItem('profileHistoryLength') || 1;
      const searchPage = new RegExp('search').test(pathname);

      const newUser = await fetcher.getCompanyMemberProfile(
        profileID,
        profile?.isCandidate,
        uniqueIdentifier,
        jobSearchPosition,
        searchPage
      );

      if (newUser) {
        this.setState({
          loading: false,
          user: newUser,
          profileHistoryLength,
          landedFromExternalURL: location.key === undefined
        });

        // Todo: do we have access to external here?
        if (newUser.accountName === 'Hiring team') return;
        this.showButtonPanel();
      } else this.goBack();
    } else this.goBack();
  }

  async messageUser(positionID: $TSFixMe) {
    // @ts-expect-error TS(2339) FIXME: Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { history, location } = this.props;
    const { user } = this.state;
    const uniqueIdentifier = getLocalStorageItem('uniqueIdentifier') || '';
    const jobSearchPosition = getLocalStorageItem('jobSearchPosition') || 0;

    const { status } = await fetcher.startApplication(
      { listingID: positionID, companyID: user.companyID, companyAccountID: user.accountID },
      uniqueIdentifier,
      jobSearchPosition
    );

    if (status === 'success') {
      history.push({
        pathname: location.pathname,
        search: `?chat=${positionID},${user.companyID}&status=open`
      });

      this.setState({ showButtonPanel: false });
    }
  }

  showButtonPanel() {
    setTimeout(() => this.setState({ showButtonPanel: true }), 300);
  }

  componentDidMount() {
    hideScrollbar(true);

    this.updateProfile();
  }

  componentWillUnmount() {
    hideScrollbar(false);
  }

  goBack() {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location, history } = this.props;
    const { profileHistoryLength } = this.state;

    setLocalStorageItem('profileHistoryLength', profileHistoryLength - 1);

    if (location.key === undefined) this.closeProfile();
    else history.goBack();
  }

  closeProfile() {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location, history } = this.props;
    const pathname = `${location.pathname.substring(0, location.pathname.indexOf('/u/'))}`;

    removeLocalStorageItem('profileHistoryLength');

    if (location.search) history.push({ pathname, hash: location.hash, search: location.search });
    else history.push(pathname);
  }

  publicApplyRequest(listingID: number) {
    // @ts-expect-error TS(2339) FIXME: Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { history } = this.props;
    const { user } = this.state;
    const position = user.positions.find(({ id }: $TSFixMe) => id === listingID) || {};

    storeApplicationAction({
      listingID,
      companyID: user.companyID,
      companyAccountID: user.accountID,
      companyName: user.companyName,
      positionName: position.name,
      personsName: user.memberName
    });
    candidateClearForm();

    history.push({
      pathname: '/signup',
      state: {
        origin: 'apply_button',
        title: `Start speaking ${user.memberName}`,
        companyName: user.companyName,
        positionName: position.name,
        person: user.memberName,
        jobTitle: user.jobTitle
      } as ApplicationDetailsLocationState
    });
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location, history, profile, darkMode, admin } = this.props;
    const { loading, showButtonPanel, profileHistoryLength, landedFromExternalURL } = this.state;
    const userInfo = this.state.user || {};
    const isCompanyUser = profile && profile.companyUser;
    const companyURLData = { companyName: userInfo.companyName, companyID: userInfo.companyID };
    const visiblePositions = userInfo?.positions
      ? userInfo.positions.filter(({ searchHidden = false }) => !searchHidden)
      : [];

    return (
      <div
        id="person_view"
        className={ClassNames({
          landing_page: landedFromExternalURL,
          dark: darkMode,
          internal_view: isCompanyUser,
          loggedIn: profile && profile.isCandidate
        })}
        onClick={e => {
          if ((e.target as $TSFixMe).id === 'person_view') this.closeProfile();
        }}
      >
        <Suspense
          fallback={<Loader className={ClassNames('person_loader', 'large', { dark: darkMode })} />}
        >
          <div className="person_dialog profile_view">
            {!landedFromExternalURL && (
              <div className="topnav_container">
                {profileHistoryLength > 1 && (
                  <div onClick={() => this.goBack()} className="left icon_arrow_left" />
                )}
                <div /> {/* To keep close icon always on the right */}
                <div onClick={() => this.closeProfile()} className="right icon_close" />
              </div>
            )}
            <div className="person_content">
              <PersonProfile
                loading={loading}
                userProfile={userInfo}
                updateProfileHistory={() =>
                  setLocalStorageItem('profileHistoryLength', profileHistoryLength + 1)
                }
                isCompany={profile && profile.companyUser}
                showEmptySections={false}
                visitCompanyProfile={() => {
                  const url = generateCompanyURL(location, companyURLData, true);
                  setLocalStorageItem('profileHistoryLength', profileHistoryLength + 1);
                  history.push(profile && profile.companyUser ? '/company/profile' : url);
                }}
                {...this.props}
              />
            </div>
            {(profile === null || (profile && !profile.rejected)) &&
              !admin &&
              !isEmpty(visiblePositions) && (
                <ButtonPanel
                  note={getBlockedMessage(
                    isCompanyUser,
                    userInfo.companyHasBlocked,
                    userInfo.candidateHasBlocked,
                    userInfo.companyName
                  )}
                  className={ClassNames('profile_view', { show: showButtonPanel, dark: darkMode })}
                  primaryButtonAction={(positionID: $TSFixMe) => this.messageUser(positionID)}
                  publicAction={(listingID: number) => this.publicApplyRequest(listingID)}
                  userLoggedIn={profile !== null}
                  accountBeingVerified={
                    profile && !profile.accepted && !profile.verified && !profile.rejected
                  }
                  primaryButton={`Message ${userInfo.memberName}`}
                  primaryButtonOptions={visiblePositions}
                  isCompany={isCompanyUser}
                  {...userInfo}
                />
              )}
          </div>
        </Suspense>
        {userInfo?.memberName && (
          <Seo
            title={`${userInfo.memberName}, ${userInfo.jobTitle} at ${userInfo.companyName}`}
            description={`${userInfo.memberName} is hiring ${
              userInfo.positions && userInfo.positions.length
                ? `for ${userInfo.positions.map(({ name }: $TSFixMe) => name).join(', ')}`
                : 'at cord'
            }. Join cord to speak directly with ${userInfo.memberName}. Jobs at ${
              userInfo.companyName
            }.`}
            path={generatePersonsURL(
              location,
              {
                personsName: userInfo.memberName,
                personsID: userInfo.id,
                companyName: userInfo.companyName
              },
              true
            )}
            contentType="website"
            image={userInfo.memberPhotoURL}
          />
        )}
      </div>
    );
  }
}
