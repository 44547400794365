import axios from 'axios';

import { APIResponse } from 'types';

const COMPANY_VERIFY_CLAIM_EMAIL = process.env.REACT_APP_COMPANY_CLAIM;

export const verifyEmailToClaimCompany = async (
  email: string,
  companyID?: number,
  companyName?: string
): Promise<APIResponse> => {
  try {
    await axios.post(`${COMPANY_VERIFY_CLAIM_EMAIL}`, { email, companyID, companyName });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
