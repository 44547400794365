import { Frequency } from '@cohiretech/common-types';

import {
  CurrentPlanDetails,
  PricingBtnAction,
  SelectedPricing,
  StandardPlan,
  SubscriptionPlan
} from 'types';
import { PaymentData } from 'v2/services/company/subscription';
import { checkIfSubscriptionExpired } from 'utils/companyUtils';

import { Props as ButtonProps } from 'components/button';

import { StandardTiers } from '../../PricingPlansSection.helpers';

export type PricingPlanCardType = 'standard' | 'enterprise';

export type PricingOption = {
  plan: PricingPlanCardType;
  defaultPlan: SubscriptionPlan;
  label: string;
  description: string;
  featureTitle: string;
  features: string[];
  button: ButtonProps;
};

export type PricingPlanCardProps = {
  pricingOption: PricingOption;
  selectedPricing: SelectedPricing;
  currentPlanDetails?: CurrentPlanDetails;
  selectedTier?: StandardTiers[Frequency][StandardPlan];
  hidePrice?: boolean;
  updateEnquiredStatus: () => void;
  showPaymentPopup: (paymentData: PaymentData) => void;
};

export const checkIfMonthlyPlan = (frequency: Frequency) => frequency === Frequency.monthly;

export const getBtnActionForExistingUser = (
  currentPlan: SubscriptionPlan,
  frequency: Frequency
): { text: string; action: PricingBtnAction } | undefined => {
  const isMonthlyPlan = checkIfMonthlyPlan(frequency);

  if (checkIfSubscriptionExpired(currentPlan)) {
    return { text: 'Reactivate subscription', action: isMonthlyPlan ? 'reactivation' : 'annual' };
  }

  if (!isMonthlyPlan) return { text: 'Enquire now', action: 'annual' };
};
