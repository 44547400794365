/* eslint-disable no-return-assign */
/* eslint-disable no-console */
import axios, { AxiosRequestConfig } from 'axios';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'mome... Remove this comment to see the full error message
import { tz } from 'moment-timezone/builds/moment-timezone-with-data';
import {
  retrieveIndexedDBData,
  storeIndexedDBData,
  retrieveIndexedDBKeys,
  clearIndexedDBData
} from 'indexedDB';
import { hashRandom } from 'react-hash-string';

import {
  ATSIntegrationState,
  CompanyAccountInterface,
  SearchSourceQueryParams,
  SearchCustomPeopleQueryParams,
  SearchSortByEnum,
  SearchResponse,
  SearchResponseCompany,
  OperatingCountry,
  MergeJobItemInterface,
  Seniority,
  RemoteOption,
  PricingPlanClean,
  StripeTaxType,
  UserType,
  CompanySizeType,
  TestimonialType,
  SalaryToolCategory,
  CompanyUpgradeSubscription,
  EmailValidationContext,
  AdminRole,
  SQLWriteLogInterface,
  SQLQueryStatus,
  CompanySuspensionStatus,
  EmailSubscriptionAndFrequencyResponse,
  HiresCompany,
  HiresJobTitleConversationStatistics,
  UpgradeRecommendationDetails,
  StripeRegion,
  LocationInterface,
  PricingMode,
  Frequency,
  UpgradeSourcingBlockReachedRecommendationDetails,
  TransactionInterface,
  SearchCategoryViewTypeEnum,
  WorkEligibilityLocation,
  WorkEligibilityType,
  LiveHiresSortBy,
  CandidateTracking,
  DepCandidateInputInterface,
  CandidateLanguageInterface,
  SkillRetrievalKey,
  CompanyCategory,
  CompanySearchOrderEnum
} from '@cohiretech/common-types';
import { datadogLogs } from '@datadog/browser-logs';

import { shouldIgnoreMessageFilter } from 'v2/services/fetchers/company/messages';
import { handleExpiredToken } from 'v2/services/fetchers/common/session';
import { Skills } from 'v2/services/fetchers/public/skillOptions';
import { clearLSItemsOnLogout } from 'v2/services/common/localStorage';
import { PRICING_OPTIONS, PRICING_MODE, companySearchFilters } from 'consts';
import {
  APIResponse,
  CompanyRoleOption,
  IntegrationType,
  Permissions,
  TemplateDetails,
  Testimonial,
  SubscriptionPricing,
  CandidateEmailType,
  AdminCustomerStory,
  ProductUserType,
  UsageStatistics,
  SubscriptionPlan,
  NewPricingOptions,
  UpgradeAction,
  SpecialEnquiryFormType,
  ImageResizeParam,
  CompanyTemplateWithAssociate,
  JobTypeOption,
  RequestDemoForm,
  CandidateExperience,
  IntegrationCategoryName,
  IntegrationCategory,
  SimplifiedDeclineReason,
  CompanyMessageType,
  ExtendedAxiosRequestConfig
} from 'types';
import { getImageDimensions, addDimensionsOnImages } from 'v2/services/image';
import {
  getSearchTerms,
  getTimeDifference,
  allSpacesRegex,
  getRandomItemFromArray,
  parseTimezoneObject,
  convertVisaObjectToCode,
  addTime,
  getReferrerTrackingDetails,
  setLocalisation,
  getMillisecondsByType
} from 'utils';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from 'cookieManager';
import { paramsSerializer } from 'v2/services/fetchers/paramsSerializer';
import { decodeCompanyProfile } from 'v2/services/company/profile';
import { decodeProfile } from 'v2/services/candidate/profile';

import { getRepoType } from 'views/landing/insights/helpers';

import Recruitee from 'images/recruitee-logo.png';
import Pinpoint from 'images/pinpoint-logo.png';
import Notion from 'images/notion-logo.png';
import Workable from 'images/workable-logo.png';
import Teamtailor from 'images/teamtailor-logo.jpg';
import Slack from 'images/slack-logo.png';
import OutlookCalendar from 'images/outlook-logo.png';
import Lever from 'images/lever-logo.png';
import Greenhouse from 'images/greenhouse-logo.png';
import GoogleCalendar from 'images/google-calendar-logo.png';
import Ashby from 'images/ashby-logo.jpg';

export { companySearchFilters } from 'consts';

type Response<T> = Promise<{
  data?: T;
  status?: 'success' | 'failure';
  message?: string | { [key in 'error' | 'message']: string };
}>;

const LOGIN = process.env.REACT_APP_LOGIN;
const COMPANY = process.env.REACT_APP_COMPANY;
const PUBLIC_POSITION = process.env.REACT_APP_PUBLIC_POSITION;
const SIGN_UP = process.env.REACT_APP_SIGN_UP;
const COMPANY_SIGN_UP = process.env.REACT_APP_COMPANY_SIGN_UP;
const DEMO_SIGN_UP = process.env.REACT_APP_DEMO_SIGN_UP;
const REQUEST_ACCESS_SIGN_UP = process.env.REACT_APP_COMPANY_REQUEST_ACCESS;
const CHECK_EMAIL = process.env.REACT_APP_CHECK_EMAIL!;
const CHECK_PHONE = process.env.REACT_APP_CHECK_PHONE;
const FORGOT_PASSWORD = process.env.REACT_APP_FORGOT_PASSWORD;
const FORGOT_PASSWORD_UPDATE = process.env.REACT_APP_FORGOT_PASSWORD_UPDATE;
const STORE_CV = process.env.REACT_APP_STORE_CV;
const VERIFY_EMAIL = process.env.REACT_APP_VERIFY_EMAIL;
const LINKEDIN_AUTH = process.env.REACT_APP_PUBLIC_LINKEDIN_AUTH;
const LIST_OF_COMPANY_NAMES = process.env.REACT_APP_PUBLIC_LIST_OF_COMPANY_NAMES;
const PUBLIC_SURVEY = process.env.REACT_APP_PUBLIC_SURVEY;
const PUBLIC_COMPANY_ACCOUNT_SURVEY = process.env.REACT_APP_PUBLIC_COMPANY_ACCOUNT_SURVEY;
const PUBLIC_PLATFORM_STATS = process.env.REACT_APP_PUBLIC_PLATFORM_STATS;
const PUBLIC_SEARCH_PEOPLE = process.env.REACT_APP_PUBLIC_SEARCH_PEOPLE!;
const PUBLIC_EMAIL_SUBSCRIPTION = process.env.REACT_APP_PUBLIC_EMAIL_SUBSCRIPTION;
const PUBLIC_CANDIDATE_LOGIN_TOKEN = process.env.REACT_APP_PUBLIC_CANDIDATE_LOGIN_TOKEN;
const PUBLIC_SKILL_RECOMMENDATION = process.env.REACT_APP_PUBLIC_SKILL_RECOMMENDATION;
const PUBLIC_ACTIVE_LISTINGS = process.env.REACT_APP_PUBLIC_ACTIVE_LISTINGS;
const PUBLIC_PWA_EVENT = process.env.REACT_APP_PUBLIC_PWA_EVENT;
const PUBLIC_LOCALISATION = process.env.REACT_APP_PUBLIC_LOCALISATION!;
const PUBLIC_INSIGHTS = process.env.REACT_APP_PUBLIC_INSIGHTS;
const PUBLIC_SALARY_TOOL = process.env.REACT_APP_PUBLIC_SALARY_TOOL!;
const PUBLIC_LIVE_HIRES = process.env.REACT_APP_PUBLIC_LIVE_HIRES;

const USER_CHANGE_EMAIL = process.env.REACT_APP_USER_CHANGE_EMAIL;
const USER_CHANGE_PASSWORD = process.env.REACT_APP_USER_CHANGE_PASSWORD;
const USER_LOGOUT = process.env.REACT_APP_USER_LOGOUT;
const USER_EMAIL_SUBSCRIPTION = process.env.REACT_APP_USER_EMAIL_SUBSCRIPTION;
const USER_MESSAGE_SEEN = process.env.REACT_APP_USER_MESSAGE_SEEN;
const USER_MFA_SETUP = process.env.REACT_APP_USER_MFA_SETUP;
const USER_MFA_DISABLE = process.env.REACT_APP_USER_MFA_DISABLE;
const USER_MFA_VALIDATE = process.env.REACT_APP_USER_MFA_VALIDATE;
const USER_REPORT = process.env.REACT_APP_USER_REPORT;

const PUBLIC_USER_SET_PASSWORD = process.env.REACT_APP_PUBLIC_USER_SET_PASSWORD;
const PUBLIC_CANDIDATE_USER = process.env.REACT_APP_PUBLIC_CANDIDATE_USER;
const PUBLIC_COMPANY_USER = process.env.REACT_APP_PUBLIC_COMPANY_USER!;
const PUBLIC_POSITION_SOURCE = process.env.REACT_APP_PUBLIC_POSITION_SOURCE;
const PUBLIC_COMPANIES_TOTAL = process.env.REACT_APP_PUBLIC_COMPANIES_TOTAL;
const PUBLIC_CANDIDATES_TOTAL = process.env.REACT_APP_PUBLIC_CANDIDATES_TOTAL;
const PUBLIC_TESTIMONIAL = process.env.REACT_APP_PUBLIC_TESTIMONIAL;
const PUBLIC_PARTNERSHIP = process.env.REACT_APP_PUBLIC_PARTNERSHIP;
const PUBLIC_PRICING = process.env.REACT_APP_PUBLIC_PRICING;
const PUBLIC_PAYMENT_SESSION_DETAILS = process.env.REACT_APP_PUBLIC_PAYMENT_SESSION_DETAILS;
const PUBLIC_FOUNDER_PROGRAM_ENQUIRY = process.env.REACT_APP_PUBLIC_FOUNDER_PROGRAM_ENQUIRY!;
const PUBLIC_CANDIDATE_INVITE = process.env.REACT_APP_PUBLIC_CANDIDATE_INVITE;

const COMPANY_PROFILE = process.env.REACT_APP_COMPANY_PROFILE;
const COMPANY_ADVANCE_SEARCH = process.env.REACT_APP_COMPANY_ADVANCE_SEARCH;
const COMPANY_VIEW_CANDIDATE = process.env.REACT_APP_COMPANY_VIEW_CANDIDATE;

const COMPANY_CANDIDATE_SEEN = process.env.REACT_APP_COMPANY_CANDIDATE_SEEN;
const COMPANY_CANDIDATE_APPLY = process.env.REACT_APP_COMPANY_CANDIDATE_APPLY;
const COMPANY_CANDIDATE_ACCEPT = process.env.REACT_APP_COMPANY_CANDIDATE_ACCEPT;
const COMPANY_CANDIDATE_CONVO_CONFIRM = process.env.REACT_APP_COMPANY_CANDIDATE_CONVO_CONFIRM;
const COMPANY_CANDIDATE_MESSAGE = process.env.REACT_APP_COMPANY_CANDIDATE_MESSAGE;
const COMPANY_MARK_CONVERSATION = process.env.REACT_APP_COMPANY_MARK_CONVERSATION;

const POSITION = process.env.REACT_APP_POSITION;
const COMPANY_APPLICATION_ARCHIVE = process.env.REACT_APP_COMPANY_APPLICATION_ARCHIVE;
const COMPANY_NOTIFICATIONS = process.env.REACT_APP_COMPANY_NOTIFICATIONS;
const COMPANY_POSITIONS_MESSAGING = process.env.REACT_APP_COMPANY_POSITIONS_MESSAGING;
const COMPANY_DELETE = process.env.REACT_APP_COMPANY_DELETE;
const COMPANY_TEMPLATE = process.env.REACT_APP_COMPANY_TEMPLATE!;
const COMPANY_QUEUED_MESSAGES = process.env.REACT_APP_COMPANY_QUEUED_MESSAGES;
const COMPANY_TEAM_MEMBERS = process.env.REACT_APP_COMPANY_TEAM_MEMBERS;
const COMPANY_SEAT = process.env.REACT_APP_COMPANY_SEAT;
const COMPANY_USER = process.env.REACT_APP_COMPANY_USER!;
const COMPANY_USER_EMAIL_SUBS = process.env.REACT_APP_COMPANY_EMAIL_SUBS;
const COMPANY_ROLES = process.env.REACT_APP_COMPANY_ROLES;
const COMPANY_WORKABLE_ACCOUNTS = process.env.REACT_APP_COMPANY_WORKABLE_ACCOUNTS!;
const COMPANY_INIT_WORKABLE = process.env.REACT_APP_COMPANY_INIT_WORKABLE;
const COMPANY_REVOKE_WORKABLE = process.env.REACT_APP_COMPANY_REVOKE_WORKABLE;
const COMPANY_ATS_JOBS = process.env.REACT_APP_COMPANY_ATS_JOBS!;
const COMPANY_SEND_CANDIDATE_ATS = process.env.REACT_APP_COMPANY_SEND_CANDIDATE_ATS;
const COMPANY_LEVER_API = process.env.REACT_APP_COMPANY_LEVER_API;
const COMPANY_INIT_LEVER = process.env.REACT_APP_COMPANY_INIT_LEVER!; // Implement
const COMPANY_REVOKE_LEVER = process.env.REACT_APP_COMPANY_REVOKE_LEVER!; // Implement
const COMPANY_INIT_NOTION = process.env.REACT_APP_COMPANY_INIT_NOTION!;
const COMPANY_REVOKE_NOTION = process.env.REACT_APP_COMPANY_REVOKE_NOTION!;
const COMPANY_INIT_GREENHOUSE = process.env.REACT_APP_COMPANY_INIT_GREENHOUSE;
const COMPANY_REVOKE_GREENHOUSE = process.env.REACT_APP_COMPANY_REVOKE_GREENHOUSE;
const COMPANY_SHORTLIST = process.env.REACT_APP_COMPANY_SHORTLIST;
const COMPANY_SHORTLIST_CANDIDATES = process.env.REACT_APP_COMPANY_SHORTLIST_CANDIDATES;
const COMPANY_CONVERSATION_CANDIDATES = process.env.REACT_APP_COMPANY_CONVERSATION_CANDIDATES;
const COMPANY_HIRE = process.env.REACT_APP_COMPANY_HIRE;
const COMPANY_ANALYTICS = process.env.REACT_APP_COMPANY_ANALYTICS;
const COMPANY_STREAM = process.env.REACT_APP_COMPANY_STREAM;
const COMPANY_REACTIVATE_ACCOUNT = process.env.REACT_APP_COMPANY_REACTIVATE_ACCOUNT;
const COMPANY_USER_SLACK_SUBSCRIPTION = process.env.REACT_APP_COMPANY_USER_SLACK_SUBSCRIPTION;
const COMPANY_INIT_SLACK = process.env.REACT_APP_COMPANY_USER_SLACK_INIT;
const COMPANY_BILLING_INFO = process.env.REACT_APP_COMPANY_BILLING_INFO;
const COMPANY_INVITES = process.env.REACT_APP_COMPANY_INVITES;
const COMPANY_GOOGLE_INTEGRATION = process.env.REACT_APP_COMPANY_GOOGLE_INTEGRATION;
const COMPANY_OUTLOOK_INTEGRATION = process.env.REACT_APP_COMPANY_OUTLOOK_INTEGRATION;
const COMPANY_MERGE_INTEGRATION = process.env.REACT_APP_COMPANY_MERGE_INTEGRATION;
const COMPANY_USER_SEQUENCES = process.env.REACT_APP_COMPANY_USER_SEQUENCES;
const COMPANY_DECLINE_REASONS = process.env.REACT_APP_COMPANY_DECLINE_REASONS!;
const COMPANY_PROVIDER_CODE = process.env.REACT_APP_COMPANY_PROVIDER_CODE;
const COMPANY_USER_POSITIONS = process.env.REACT_APP_COMPANY_USER_POSITIONS;
const COMPANY_USER_BULK_POSITIONS = process.env.REACT_APP_COMPANY_USER_BULK_POSITIONS;
const COMPANY_PAUSE_SUBSCRIPTION = process.env.REACT_APP_COMPANY_PAUSE_SUBSCRIPTION;
const COMPANY_REACTIVATE_SUBSCRIPTION = process.env.REACT_APP_COMPANY_REACTIVATE_SUBSCRIPTION;
const COMPANY_REACTIVATION_PLAN = process.env.REACT_APP_COMPANY_REACTIVATION_PLAN!;
const COMPANY_START_DEMO = process.env.REACT_APP_COMPANY_START_DEMO;
const COMPANY_DEMO_COMPANY_SIZE = process.env.REACT_APP_COMPANY_DEMO_COMPANY_SIZE;
const COMPANY_TRACKING = process.env.REACT_APP_COMPANY_TRACKING;
const COMPANY_UPDATE_STRIPE_DETAILS = process.env.REACT_APP_COMPANY_UPDATE_STRIPE_DETAILS;
const COMPANY_NEXT_PAYMENT_DATE = process.env.REACT_APP_COMPANY_NEXT_PAYMENT_DATE;
const COMPANY_PAYMENT_CARDS = process.env.REACT_APP_COMPANY_PAYMENT_CARDS;
const COMPANY_PLAN_USAGE = process.env.REACT_APP_COMPANY_PLAN_USAGE;
const COMPANY_UPGRADE = process.env.REACT_APP_COMPANY_UPGRADE;
const COMPANY_RECOMMENDATION_SOURCING_BLOCK =
  process.env.REACT_APP_COMPANY_RECOMMENDATION_SOURCING_BLOCK;
const COMPANY_SET_PAYG = process.env.REACT_APP_COMPANY_SET_PAYG!;
const COMPANY_SET_GREENHOUSE_NAME = process.env.REACT_APP_COMPANY_SET_GREENHOUSE_NAME!;
const COMPANY_SALARY_TOOL = process.env.REACT_APP_COMPANY_SALARY_TOOL!;
const COMPANY_ANNUAL_PLAN_ENQUIRY = process.env.REACT_APP_COMPANY_ANNUAL_PLAN_ENQUIRY!;

const CANDIDATE_POSITION_SEEN = process.env.REACT_APP_CANDIDATE_POSITION_SEEN;
const CANDIDATE_POSITION_APPLY = process.env.REACT_APP_CANDIDATE_POSITION_APPLY!;
const CANDIDATE_POSITION_ACCEPT = process.env.REACT_APP_CANDIDATE_POSITION_ACCEPT;
const CANDIDATE_POSITION_SKIP = process.env.REACT_APP_CANDIDATE_POSITION_SKIP;
const CANDIDATE_POSITION_DECLINE = process.env.REACT_APP_CANDIDATE_POSITION_DECLINE;
const CANDIDATE_POSITION_CONFIRM = process.env.REACT_APP_CANDIDATE_POSITION_CONFIRM;
const CANDIDATE_POSITION_MESSAGE = process.env.REACT_APP_CANDIDATE_POSITION_MESSAGE;
const CANDIDATE_SHORTLIST_POSITION = process.env.REACT_APP_CANDIDATE_SHORTLIST;
const CANDIDATE_ONBOARDING_SKIP_POSITION = process.env.REACT_APP_CANDIDATE_ONBOARDING_SKIP_POSITION;
const CANDIDATE_ONBOARDING_LIKE_POSITION = process.env.REACT_APP_CANDIDATE_ONBOARDING_LIKE_POSITION;
const CANDIDATE_ONBOARDING_COMPLETE = process.env.REACT_APP_CANDIDATE_ONBOARDING_COMPLETE;

const CANDIDATE_APPLICATIONS = process.env.REACT_APP_CANDIDATE_APPLICATIONS;
const CANDIDATE_APPLICATION_ARCHIVE = process.env.REACT_APP_CANDIDATE_APPLICATION_ARCHIVE;
const CANDIDATE_APPLICATION_START = process.env.REACT_APP_CANDIDATE_APPLICATION_START;
const CANDIDATE_NOTIFICATIONS = process.env.REACT_APP_CANDIDATE_NOTIFICATIONS;
const CANDIDATE_PROFILE = process.env.REACT_APP_CANDIDATE_PROFILE;
const CANDIDATE_INVISIBLE = process.env.REACT_APP_CANDIDATE_INVISIBLE!;
const CANDIDATE_DELETE = process.env.REACT_APP_CANDIDATE_DELETE!;
const CANDIDATE_POSITION_SOURCE = process.env.REACT_APP_CANDIDATE_POSITION_SOURCE;
const CANDIDATE_FEEDBACK = process.env.REACT_APP_USER_FEEDBACK;
const CANDIDATE_VIEW_POSITION = process.env.REACT_APP_CANDIDATE_VIEW_POSITION;
const CANDIDATE_VIEW_COMPANY = process.env.REACT_APP_CANDIDATE_VIEW_COMPANY;
const CANDIDATE_VIEW_MEMBER = process.env.REACT_APP_CANDIDATE_VIEW_MEMBER;
const CANDIDATE_EDIT_PROFILE_INFO = process.env.REACT_APP_CANDIDATE_EDIT_PROFILE_INFO;
const CANDIDATE_EDIT_PREFERENCES = process.env.REACT_APP_CANDIDATE_EDIT_PREFERENCES!;
const CANDIDATE_EDIT_EXPERIENCE = process.env.REACT_APP_CANDIDATE_EDIT_EXPERIENCE;
const CANDIDATE_EDIT_EDUCATION = process.env.REACT_APP_CANDIDATE_EDIT_EDUCATION;
const CANDIDATE_EDIT_PROJECT = process.env.REACT_APP_CANDIDATE_EDIT_PROJECT;
const CANDIDATE_EDIT_ACHIEVEMENT = process.env.REACT_APP_CANDIDATE_EDIT_ACHIEVEMENT;
const CANDIDATE_EDIT_PUBLICATION = process.env.REACT_APP_CANDIDATE_EDIT_PUBLICATION;
const CANDIDATE_BOOK_INVITE = process.env.REACT_APP_CANDIDATE_BOOK_INVITE;
const CANDIDATE_EDIT_LANGUAGE = process.env.REACT_APP_CANDIDATE_EDIT_LANGUAGE;
const CANDIDATE_TEMPLATE = process.env.REACT_APP_CANDIDATE_TEMPLATE!;
const CANDIDATE_REFERRALS = process.env.REACT_APP_CANDIDATE_REFERRALS;
const CANDIDATE_ACTION = process.env.REACT_APP_CANDIDATE_ACTION;
const CANDIDATE_SALARY_TOOL = process.env.REACT_APP_CANDIDATE_SALARY_TOOL!;
const CANDIDATE_EMAIL_NOTIFICATIONS = process.env.REACT_APP_CANDIDATE_EMAIL_NOTIFICATIONS!;
const CANDIDATE_REFRESH_PROFILE = process.env.REACT_APP_CANDIDATE_REFRESH_PROFILE!;

const ADMIN_LOGIN = process.env.REACT_APP_ADMIN_LOGIN;
const ADMIN_GODMODE_LOGIN = process.env.REACT_APP_ADMIN_GODMODE_LOGIN!;
const ADMIN = process.env.REACT_APP_ADMIN;
const ADMIN_CANDIDATE = process.env.REACT_APP_ADMIN_CANDIDATE;
const ADMIN_VERIFY_USER = process.env.REACT_APP_ADMIN_VERIFY_USER;
const ADMIN_CANDIDATE_ACCOUNT_CREATED = process.env.REACT_APP_ADMIN_CANDIDATE_ACCOUNT_CREATED;
const ADMIN_ADD_ACCOUNT = process.env.REACT_APP_ADMIN_ADD_ACCOUNT;
const ADMIN_ADVANCE_SEARCH = process.env.REACT_APP_ADMIN_ADVANCE_SEARCH;
const ADMIN_APPLICATIONS = process.env.REACT_APP_ADMIN_APPLICATIONS;
const ADMIN_VIEW_CANDIDATE = process.env.REACT_APP_ADMIN_VIEW_CANDIDATE;
const ADMIN_REVENUE = process.env.REACT_APP_ADMIN_REVENUE;
const ADMIN_LAST_ACTIVE_COMPANY = process.env.REACT_APP_ADMIN_LAST_ACTIVE_COMPANY;
const ADMIN_COMPANY_VERIFY_PENDING = process.env.REACT_APP_ADMIN_COMPANY_VERIFY_PENDING;
const ADMIN_CANDIDATE_VERIFY_PENDING = process.env.REACT_APP_ADMIN_CANDIDATE_VERIFY_PENDING;
const ADMIN_CANDIDATE_WAITLISTED = process.env.REACT_APP_ADMIN_CANDIDATE_WAITLISTED;
const ADMIN_CANDIDATE_REJECTED = process.env.REACT_APP_ADMIN_CANDIDATE_REJECTED;
const ADMIN_CANDIDATE_ACCEPTED = process.env.REACT_APP_ADMIN_CANDIDATE_ACCEPTED;
const ADMIN_CANDIDATE_DELETE = process.env.REACT_APP_ADMIN_CANDIDATE_DELETE!;
const ADMIN_UPDATE_SUBSCRIPTION = process.env.REACT_APP_ADMIN_UPDATE_SUBSCRIPTION;
const ADMIN_UPDATE_COMPANY_SUSPENSION_STATUS =
  process.env.REACT_APP_ADMIN_UPDATE_COMPANY_SUSPENSION_STATUS!;
const ADMIN_CLONE_COMPANY_ACCOUNT = process.env.REACT_APP_ADMIN_CLONE_COMPANY_ACCOUNT;
const ADMIN_UPDATE_COMPANY_EMAIL_SUBS = process.env.REACT_APP_ADMIN_UPDATE_COMPANY_EMAIL_SUBS;
const ADMIN_COMPANY_DEMO = process.env.REACT_APP_ADMIN_COMPANY_DEMO;
const ADMIN_UPDATE_CANDIDATE_EMAIL_SUBS = process.env.REACT_APP_ADMIN_UPDATE_CANDIDATE_EMAIL_SUBS;
const ADMIN_LIST_OF_SKILLS = process.env.REACT_APP_ADMIN_LIST_OF_SKILLS;
const ADMIN_DELETE_SKILLS = process.env.REACT_APP_ADMIN_DELETE_SKILLS;
const ADMIN_ADD_INTERVIEW = process.env.REACT_APP_ADMIN_ADD_INTERVIEW;
const ADMIN_LIST_OF_COMPANIES = process.env.REACT_APP_ADMIN_LIST_OF_COMPANIES;
const ADMIN_LIST_OF_CANDIDATES = process.env.REACT_APP_ADMIN_LIST_OF_CANDIDATES;
const ADMIN_LIST_OF_USERS = process.env.REACT_APP_ADMIN_LIST_OF_USERS;
const ADMIN_LISTING_OPTIONS = process.env.REACT_APP_ADMIN_LISTING_OPTIONS;
const ADMIN_UPDATE_USER_ROLE = process.env.REACT_APP_ADMIN_UPDATE_USER_ROLE;
const ADMIN_UPDATE_DATABASE = process.env.REACT_APP_ADMIN_UPDATE_DATABASE;
const ADMIN_TECH_FRONTEND_MESSAGE = process.env.REACT_APP_ADMIN_TECH_FRONTEND_MESSAGE;
const ADMIN_TESTIMONIAL = process.env.REACT_APP_ADMIN_TESTIMONIAL;
const ADMIN_STORY = process.env.REACT_APP_ADMIN_STORY;
const ADMIN_PARTNERSHIP = process.env.REACT_APP_ADMIN_PARTNERSHIP;
const ADMIN_PRICING = process.env.REACT_APP_ADMIN_PRICING;
const ADMIN_USER_DELETE = process.env.REACT_APP_ADMIN_USER_DELETE;
const ADMIN_CANDIDATE_DETAILS = process.env.REACT_APP_ADMIN_CANDIDATE_DETAILS;
const ADMIN_UPDATE_POPULAR_COMPANY = process.env.REACT_APP_ADMIN_UPDATE_POPULAR_COMPANY;
const ADMIN_UPDATE_POPULAR_COMPANY_USER = process.env.REACT_APP_ADMIN_UPDATE_POPULAR_COMPANY_USER;
const ADMIN_UPDATE_COVID_RESPONDER = process.env.REACT_APP_ADMIN_UPDATE_COVID_RESPONDER;
const ADMIN_INTERNAL_STATS = process.env.REACT_APP_ADMIN_INTERNAL_STATS;
const ADMIN_COMPANY_MANAGER = process.env.REACT_APP_ADMIN_COMPANY_MANAGER;
const ADMIN_COMPANY_STATS = process.env.REACT_APP_ADMIN_COMPANY_STATS;
const ADMIN_COMPANY_FREEZE = process.env.REACT_APP_ADMIN_COMPANY_FREEZE;
const ADMIN_COMPANY_STRIPE = process.env.REACT_APP_ADMIN_COMPANY_STRIPE;
const ADMIN_COMPANY_STRIPE_TRANSFER = process.env.REACT_APP_ADMIN_COMPANY_STRIPE_TRANSFER;
const ADMIN_COMPANY_STRIPE_DETAILS = process.env.REACT_APP_ADMIN_COMPANY_STRIPE_DETAILS;
const ADMIN_COMPANY_CREATE = process.env.REACT_APP_ADMIN_COMPANY_CREATE;
const ADMIN_STATS = process.env.REACT_APP_ADMIN_STATS;
const ADMIN_COMPANY_USERS = process.env.REACT_APP_ADMIN_COMPANY_USERS!;
const ADMIN_POSITION_TAGGING = process.env.REACT_APP_ADMIN_POSITION_TAGGING;
const ADMIN_CANDIDATE_TAGGING = process.env.REACT_APP_ADMIN_CANDIDATE_TAGGING;
const ADMIN_HIDE_COMPANY_ACCOUNT = process.env.REACT_APP_ADMIN_HIDE_COMPANY_ACCOUNT;
const ADMIN_CANDIDATE_INVITE = process.env.REACT_APP_ADMIN_CANDIDATE_INVITE;
const ADMIN_INVITE_SOURCE = process.env.REACT_APP_ADMIN_CANDIDATE_INVITE_SOURCE;
const ADMIN_CANDIDATE_REACTIVATE_EMAIL = process.env.REACT_APP_ADMIN_CANDIDATE_REENGAGE;
const ADMIN_RESET_MFA = process.env.REACT_APP_ADMIN_RESET_MFA;
const ADMIN_SUBSCRIPTION_PLAN = process.env.REACT_APP_ADMIN_SUBSCRIPTION_PLAN;
const ADMIN_PUBLIC_VISIBILITY = process.env.REACT_APP_ADMIN_PUBLIC_VISIBILITY;
const ADMIN_BLOCK_JOB_TYPE = process.env.REACT_APP_ADMIN_BLOCK_JOB_TYPE;
const ADMIN_SQL_WRITE_QUERY = process.env.REACT_APP_ADMIN_SQL_WRITE_QUERY!;
const ADMIN_ATS_RESET_FOR_DEMO = process.env.REACT_APP_ADMIN_ATS_RESET_FOR_DEMO!;
const ADMIN_COMPANY_ALLOW_SELF_SERVICE_CHURN =
  process.env.REACT_APP_ADMIN_COMPANY_ALLOW_SELF_SERVICE_CHURN;
const ADMIN_CONFIG = process.env.REACT_APP_ADMIN_CONFIG;

const CREDIT_SYSTEM_ADMIN_TRANSACTIONS = process.env.REACT_APP_CREDIT_SYSTEM_ADMIN_TRANSACTIONS;
const CREDIT_SYSTEM_ADMIN_COMPANIES = process.env.REACT_APP_CREDIT_SYSTEM_ADMIN_COMPANIES;

const PUBLIC_INSIGHTS_VOTE = process.env.REACT_APP_PUBLIC_INSIGHTS_VOTE;
const PUBLIC_USER_PROFILE = process.env.REACT_APP_PUBLIC_USER_PROFILE;

const S3_SIGN_REQUEST = process.env.REACT_APP_S3_SIGN_REQUEST;

async function onRequestResponseRejected(error: any) {
  const errorConfig = error.config as ExtendedAxiosRequestConfig;

  if (error?.response?.data?.message === 'unauthorized') {
    return handleExpiredToken(errorConfig);
  }

  if (axios.isCancel(error)) console.log(`Request ${errorConfig?.requestId} canceled`);
  else {
    datadogLogs.logger.error(error?.message, {
      error: { ...error, message: error?.response?.data?.message }
    });
  }

  return Promise.reject(error);
}

axios.defaults.paramsSerializer = paramsSerializer;

axios.interceptors.response.use(response => response, onRequestResponseRejected);

const sendPutFileXMLRequest = (file: $TSFixMe, signedRequest: $TSFixMe) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // @ts-expect-error TS(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
          resolve();
        } else {
          reject();
        }
      }
    };
    xhr.send(file);
  });
};

let companyProfile = {};
let userProfile = {} as CompanyAccountInterface;

export const SITENAME = 'cord';
export const PUSHER_UPDATE_MESSAGE = 'cord has been updated. Reload to get the new features';

export const JOB_TYPES = {
  PERMANENT: 'Permanent',
  PART_TIME: 'Part Time',
  FREELANCE_CONTRACT: 'Freelance/Contract',
  INTERNSHIP: 'Internship',
  FTC: 'FTC'
};

export const VALID_JOB_TYPES = [JOB_TYPES.PERMANENT, JOB_TYPES.PART_TIME, JOB_TYPES.FTC];

export const VISA_SPONSORSHIP_ORIGINS = {
  ALL: 'Everyone',
  UK: 'UK residents',
  US: 'US residents',
  EU: 'EU residents',
  OTHER: 'Non-UK/EU/US'
};
export const MESSAGE_VIEW = {
  TEAM: 'team',
  USER: 'self'
};
/** @deprecated Use CandidateShortlist in common-types instead */
export const SHORTLIST_TYPES = {
  LIKE: 'favourite',
  HIDE: 'hidden',
  SKIP: 'skip'
};
export const INVITE_TYPES = [
  { type: 'phone', title: 'Phone call' },
  { type: 'video-google', title: 'Video call' },
  { type: 'in-person', title: 'In Person interview' }
];
export const INTERVIEW_DURATION_OPTIONS = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
  { label: '2 hours', value: 120 },
  { label: '3 hours', value: 180 },
  { label: '4 hours', value: 240 },
  { label: '5 hours', value: 300 },
  { label: '6 hours', value: 360 }
];

export const HIGHEST_SALARY_BAND_VALUE = 505000; // We send and receive '505000' to/from the backend, but interpret this as '500,000+' on the frontend

export const parseWorableJExperience = (experience: $TSFixMe) => {
  switch (experience) {
    case 'Not Applicable':
      return '';
    case 'Internship':
      return ['entry', 'junior'];
    case 'Entry level':
      return 'entry';
    case 'Associate':
      return ['junior', 'mid'];
    case 'Mid-Senior level':
      return ['mid', 'senior'];
    case 'Director':
      return ['senior', 'lead'];
    case 'Executive':
      return ['senior', 'lead', 'leadership'];
    default:
      return '';
  }
};

export const parseWorableJobType = (employmentType: $TSFixMe) => {
  switch (employmentType) {
    case 'Full-time':
      return JOB_TYPES.PERMANENT;
    case 'Part-time':
      return JOB_TYPES.PART_TIME;
    case 'Contract':
      return JOB_TYPES.FREELANCE_CONTRACT;
    case 'Temporary':
      return JOB_TYPES.INTERNSHIP;
    case 'Other':
      return JOB_TYPES.INTERNSHIP;
    default:
      return '';
  }
};

const parseATSJobDetails = ({ title, fullDescription, experience, employmentType }: $TSFixMe) => {
  return {
    position: title,
    internalLabel: title,
    description: fullDescription,
    jobType: parseWorableJobType(employmentType),
    seniorities: parseWorableJExperience(experience)
  };
};

const updateDateInputs = (
  { startYear, startMonth, endMonth, endYear, ...rest }: CandidateExperience,
  type: 'experience' | 'project'
) => {
  const startDate = new Date();
  const endDate = new Date();

  (startDate as $TSFixMe).setYear(startYear);
  startDate.setMonth(startMonth, 15);

  if (endYear > -1) {
    (endDate as $TSFixMe).setYear(endYear);
    endDate.setMonth(endMonth, 15);
  }

  if (type === 'experience') return { start_date: startDate, end_date: endDate, ...rest };
  return { startDate, endDate, ...rest };
};

/* istanbul ignore next */
export const companyPauseAccountFeedback = async (feedback: $TSFixMe, candidateIDs: $TSFixMe) => {
  try {
    const { data } = await axios.post(`${COMPANY_PAUSE_SUBSCRIPTION}/feedback`, {
      feedback,
      candidateIDs
    });
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyPauseAccount = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_PAUSE_SUBSCRIPTION);
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyReactivateAccount = async (
  urlPath?: string,
  mode: PricingMode = PRICING_MODE,
  isRequestingFounder: boolean = false
) => {
  try {
    const { data } = await axios.post(COMPANY_REACTIVATE_ACCOUNT as string, {
      urlPath,
      mode,
      isRequestingFounder
    });
    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyPlanUsage = async () => {
  try {
    const { data } = await axios.get<{ data: UsageStatistics }>(COMPANY_PLAN_USAGE as string);
    return data.data;
  } catch (error: any) {
    return {
      positions: 0,
      conversations: 0,
      userSeats: 0,
      unlimitedCreditsExpiryDate: null,
      credits: {
        usage: 0,
        limit: 0
      }
    };
  }
};

export const getCompanyUpgradeOptions = async () => {
  try {
    const { data } = await axios.get<{ data: CompanyUpgradeSubscription }>(
      COMPANY_UPGRADE as string
    );
    return data.data;
  } catch (error: any) {
    return {
      upgrade: [],
      pricingCountry: OperatingCountry.UK,
      current: {},
      status: 'failure',
      message: error?.response?.data?.message
    };
  }
};

export const companyUpgradePlan = async (urlPath: string, mode: PricingMode = PRICING_MODE) => {
  try {
    await axios.post(COMPANY_UPGRADE as string, { urlPath, mode });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const companyRequestPlanChange = async ({
  mode = PRICING_MODE,
  ...params
}: {
  urlPath: string;
  mode?: PricingMode;
  action: UpgradeAction;
  frequency: Frequency;
}) => {
  try {
    await axios.post(`${COMPANY_UPGRADE}/enquiry` as string, { mode, ...params });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const publicLSItems = ['signup_form', 'company_signup_form', 'referrer', 'referrerDetails'];

export const signUpLocations = [
  {
    label: 'London, UK',
    tooltip: 'Onsite/Hybrid',
    value: {
      locality: 'London',
      postal_town: 'London',
      administrative_area_level_2: 'Greater London',
      administrative_area_level_1: 'England',
      country: 'United Kingdom',
      formatted_address: 'London, UK',
      label: 'London, United Kingdom'
    }
  },
  {
    label: 'New York, US',
    tooltip: 'Onsite/Hybrid',
    value: {
      locality: 'New York',
      administrative_area_level_1: 'New York',
      country: 'United States',
      formatted_address: 'New York, NY, USA',
      label: 'New York, United States'
    }
  },
  {
    label: 'San Francisco, US',
    tooltip: 'Onsite/Hybrid',
    value: {
      locality: 'San Francisco',
      administrative_area_level_1: 'California',
      country: 'United States',
      formatted_address: 'San Francisco, CA, USA',
      label: 'San Francisco, United States'
    }
  },
  { label: 'Remotely', value: { label: 'remote' } }
];

/* istanbul ignore next */
export const candidateLoginUsingToken = async (token: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(PUBLIC_CANDIDATE_LOGIN_TOKEN, { token });
    const { role } = (data as $TSFixMe).data;

    publicLSItems.forEach(item => removeLocalStorageItem(item));
    setLocalStorageItem('role', role);

    return { status: 'success', role };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const login = async (
  email: string,
  password: string,
  rememberMe: boolean,
  stripeSessionID?: string
): Promise<{
  status: string;
  message: string | { message: string };
  role?: string;
  loginToken?: string;
  id?: number;
  expiryDate?: string;
}> => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(LOGIN, { email, password, rememberMe, stripeSessionID });
    const { message } = data;
    const { role, expiryDate, id, loginToken } = data.data;

    publicLSItems.forEach(item => removeLocalStorageItem(item));
    setLocalStorageItem('role', role);

    return { status: 'success', message, role, loginToken, id, expiryDate };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const initiateGodmodeLogin = async (params: {
  email: string;
  companyName?: string;
  isAllAccess?: boolean;
}) => {
  const { email, companyName, isAllAccess = false } = params;
  try {
    clearLSItemsOnLogout(true);

    const { data } = await axios.post(ADMIN_GODMODE_LOGIN, { email, companyName, isAllAccess });
    const { role, expiryDate } = (data as $TSFixMe).data;

    setLocalStorageItem('role', role, getTimeDifference('now', expiryDate));
    setLocalStorageItem('isGodmode', true);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminLogin = async (email: $TSFixMe, password: $TSFixMe, rememberMe: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(ADMIN_LOGIN, { email, password, rememberMe });

    const { message } = data;
    const { role, expiryDate } = (data as $TSFixMe).data;

    setLocalStorageItem('role', role, getTimeDifference('now', expiryDate));

    return { status: 'success', message };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getProviderCode = async (clientID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_PROVIDER_CODE, { clientID });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getActiveListingsCount = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(PUBLIC_ACTIVE_LISTINGS);
    return (data as $TSFixMe).data.count;
  } catch (error: any) {
    return '1800+';
  }
};

/**
 * @deprecated use `getLocalisationFromServer` instead
 */
export const getUserLocalisation = async (): Promise<OperatingCountry | ''> => {
  try {
    let country = getLocalStorageItem<OperatingCountry | ''>('country');

    if (country === null) {
      const { data } = await axios.post<{ data: { preferCountry: OperatingCountry | null } }>(
        PUBLIC_LOCALISATION
      );
      country = data.data.preferCountry || '';

      setLocalisation(country);
    }

    return country;
  } catch (error) {
    return '';
  }
};

/**
 * @description Upon logout success, remove localStorage items and go back to homepage.
 * istanbul ignore next
 */
export const logout = async (demoCompany = false, redirect = true) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.get(USER_LOGOUT);

    clearLSItemsOnLogout();

    // reset templates tooltip cz it's part of the user onboard so they see it when setting up their account
    if (demoCompany) removeLocalStorageItem('templates_tooltip_viewed');

    if (redirect) window.location.href = '/';
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getDemoCompanySize = async (code: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_DEMO_COMPANY_SIZE, { params: { code } });
    return { status: 'success', companySize: (data as $TSFixMe).data.companySize };
  } catch (error: any) {
    return { status: 'failure', companySize: 0 };
  }
};

export const getCompanyProfile = async () => {
  const imageParameters: ImageResizeParam[] = [
    { type: 'view-page', dimensions: getImageDimensions('view-page') },
    { type: 'logo', dimensions: getImageDimensions('no-crop-logo') }
  ];

  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_PROFILE);
    const profile = decodeCompanyProfile((data as $TSFixMe).data);

    if (profile && profile.companyName) profile.isCandidate = false;

    companyProfile = addDimensionsOnImages(profile, imageParameters);
    return JSON.parse(JSON.stringify(companyProfile));
  } catch (error) {
    return null;
  }
};

/* istanbul ignore next */
export const getCandidateInviteDetails = async (inviteCode: $TSFixMe) => {
  try {
    const { data } = await axios.get(`${PUBLIC_CANDIDATE_INVITE}/${inviteCode}`);
    return { data: (data as $TSFixMe).data, status: 'success' };
  } catch (error: any) {
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getPublicCompanyStats = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(PUBLIC_PLATFORM_STATS);
    return { data: (data as $TSFixMe).data, status: 'success' };
  } catch (error: any) {
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const recordPWAEvent = async (action: $TSFixMe, metadata: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(PUBLIC_PWA_EVENT, { action, metadata });
    return { status: 'success' };
  } catch (error: any) {
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const validateSurveyID = async (surveyID: $TSFixMe) => {
  try {
    const { data } = await axios.get(`${PUBLIC_SURVEY}/${surveyID}`);

    return data;
  } catch (error: any) {
    return { status: 'failure' };
  }
};

/* istanbul ignore next */
export const submitSurvey = async (survey: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(PUBLIC_SURVEY, survey);

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const validateCompanyAccountSurveyID = async (surveyID: $TSFixMe) => {
  try {
    const { data } = await axios.get(`${PUBLIC_COMPANY_ACCOUNT_SURVEY}/${surveyID}`);

    return data;
  } catch (error: any) {
    return { status: 'failure' };
  }
};

/* istanbul ignore next */
export const submitCompanyAccountSurvey = async (survey: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(PUBLIC_COMPANY_ACCOUNT_SURVEY, survey);

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateEmailSubscription = async (
  emailType: $TSFixMe,
  subscribe: $TSFixMe,
  subscriptionToken: $TSFixMe
) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(PUBLIC_EMAIL_SUBSCRIPTION, {
      emailType,
      subscribe,
      subscriptionToken
    });

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getPaymentSessionDetails = async (
  urlPath: string,
  pricingCountry: $TSFixMe,
  redirectURL: string,
  source: string,
  companyName = '',
  locationObject?: Partial<LocationInterface>,
  taxType?: StripeTaxType,
  taxIdentificationNumber = ''
) => {
  try {
    const mode = PRICING_MODE;
    const params = {
      taxIdentificationNumber,
      taxType,
      urlPath,
      mode,
      pricingCountry,
      source,
      companyName,
      location: locationObject,
      ...(redirectURL ? { redirectURL } : {})
    };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(PUBLIC_PAYMENT_SESSION_DETAILS, params);

    return (data as $TSFixMe).data;
  } catch (error: any) {
    console.log(error);
    return { sessionID: null, message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getLinkedInAuth = async (frontendRedirect: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(LINKEDIN_AUTH, { frontendRedirect });

    return (data as $TSFixMe).data;
  } catch (error: any) {
    console.log(error);
    return { link: '' };
  }
};

/* istanbul ignore next */
export const getAdmin = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(ADMIN);
    return JSON.parse(JSON.stringify((data as $TSFixMe).data));
  } catch (error: any) {
    console.log(error);
    return null;
  }
};

/* istanbul ignore next */
export const getAdminCandidate = async (candidateID: number) => {
  if (candidateID) {
    try {
      const { data } = await axios.get(`${ADMIN_CANDIDATE}/${candidateID}`);
      return decodeProfile((data as $TSFixMe).data, UserType.Admin);
    } catch (error: any) {
      console.log(error);
    }
  }
};

/* istanbul ignore next */
export const postUserEmailSubscription = async (
  email_type: $TSFixMe,
  subscribe: $TSFixMe,
  verification_code: $TSFixMe
) => {
  try {
    const params = { email_type, subscribe, verification_code };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(USER_EMAIL_SUBSCRIPTION, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getTotalNumberOfCandidates = async (
  category: $TSFixMe,
  country?: OperatingCountry
) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(PUBLIC_CANDIDATES_TOTAL, { params: { category, country } });
    return (data as $TSFixMe).data.count;
  } catch (error: any) {
    console.log(error);
    return 0;
  }
};

/* istanbul ignore next */
export const getTotalNumberOfCompanies = async (category: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(PUBLIC_COMPANIES_TOTAL, { params: { category } });
    return (data as $TSFixMe).data.count;
  } catch (error: any) {
    console.log(error);
    return 0;
  }
};

/* istanbul ignore next */
export const getLastActiveCompanies = async (searchItems: $TSFixMe, page: $TSFixMe) => {
  try {
    const params = { page };

    for (let i = 0; i < searchItems.length; i++) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (params[searchItems[i].attribute] === undefined) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        params[searchItems[i].attribute] = [];
      }

      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      params[searchItems[i].attribute].push(searchItems[i].value);
    }

    const { data } = await axios.get(`${ADMIN_LAST_ACTIVE_COMPANY}`, { params });
    return data;
  } catch (error: any) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getNewCompanies = async (exclude_dealt_with: $TSFixMe) => {
  try {
    const params = { exclude_dealt_with };
    const { data } = await axios.get(`${ADMIN_COMPANY_VERIFY_PENDING}`, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getCandidatesVerifyPending = async (page: $TSFixMe) => {
  try {
    const params = { page };
    const { data } = await axios.get(`${ADMIN_CANDIDATE_VERIFY_PENDING}`, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getWaitlistedCandidates = async (page: $TSFixMe) => {
  try {
    const params = { page };
    const { data } = await axios.get(`${ADMIN_CANDIDATE_WAITLISTED}`, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getRejectedCandidates = async (page: $TSFixMe) => {
  try {
    const params = { page };
    const { data } = await axios.get(`${ADMIN_CANDIDATE_REJECTED}`, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getAcceptedCandidates = async (page: $TSFixMe) => {
  try {
    const params = { page };
    const { data } = await axios.get(`${ADMIN_CANDIDATE_ACCEPTED}`, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getCandidateApplications = async (type: $TSFixMe) => {
  try {
    const imageParameters: ImageResizeParam[] = [
      { type: 'view-page', dimensions: getImageDimensions('view-page') }
    ];
    const { data } = await axios.get(`${CANDIDATE_APPLICATIONS}/${type}`);

    return (
      (data as $TSFixMe).data.map((row: $TSFixMe) => addDimensionsOnImages(row, imageParameters)) ||
      []
    );
  } catch (error: any) {
    console.log(error);
    return [];
  }
};

/* istanbul ignore next */
export const getAdminApplications = async (id: $TSFixMe, type: $TSFixMe, company_id: $TSFixMe) => {
  try {
    const { data } = await axios.get(
      `${ADMIN_APPLICATIONS}/${type}?listing_id=${id}${company_id ? `&company=${company_id}` : ''}`
    );

    return (data as $TSFixMe).data || [];
  } catch (error: any) {
    console.log(error);
    return [];
  }
};

/* istanbul ignore next */
export const getSourcePositions = async (sourceID: $TSFixMe, sortBy: $TSFixMe) => {
  const requestId = 'private_source_request';
  const [response, expiryDate] = (await retrieveIndexedDBKeys('sourcePositions', [
    'dataKey',
    'expiryDate'
  ])) as $TSFixMe;

  try {
    const params = { sourceID, CMSResource: getRepoType(false) };
    if (sortBy) (params as $TSFixMe).sortBy = sortBy;

    // if there are recent data available in indexDB then return those
    if (response && expiryDate > new Date().getTime()) return response.data;

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ params: { sourceID: any; }; re... Remove this comment to see the full error message
    const { data } = await axios.get(`${CANDIDATE_POSITION_SOURCE}`, { params, requestId });
    const newExpiryDate = addTime(new Date(), 30, 'seconds');
    storeIndexedDBData('sourcePositions', data);
    storeIndexedDBData('sourcePositions', newExpiryDate, 'expiryDate');

    return (data as $TSFixMe).data;
  } catch (error) {
    if (response) return response.data;
    return [];
  }
};

/* istanbul ignore next */
export const getPublicSourcePositions = async (sourceID: SearchSourceQueryParams['sourceID']) => {
  const requestId = 'public_source_request';
  const country = await getUserLocalisation();
  try {
    const params: SearchSourceQueryParams = {
      sourceID,
      sortBy: SearchSortByEnum.ResponseRateLastActive,
      ...(country ? { country } : {})
    };
    const { data } = await axios.get<{
      data: Record<string, SearchResponse<SearchResponseCompany>>;
    }>(`${PUBLIC_POSITION_SOURCE}`, { params });

    return data.data;
  } catch (error) {
    if (axios.isCancel(error)) console.log(`${requestId} cancelled`);
    return {};
  }
};

/* istanbul ignore next */
export const getCompany = async (
  company_id: $TSFixMe,
  isCandidate: $TSFixMe,
  ui: $TSFixMe,
  search_position: $TSFixMe
) => {
  const params = { search_position };

  if (ui) (params as $TSFixMe).ui = ui;

  try {
    const imageParameters: ImageResizeParam[] = [
      { type: 'view-page', dimensions: getImageDimensions('view-page') },
      { type: 'logo', dimensions: getImageDimensions('no-crop-logo') }
    ];

    if (isCandidate) {
      const { data } = await axios.get(`${CANDIDATE_VIEW_COMPANY}/${company_id}`, { params });
      (data as $TSFixMe).data = addDimensionsOnImages((data as $TSFixMe).data, imageParameters);
      return { status: 'success', data: (data as $TSFixMe).data };
    } else {
      const { data } = await axios.get(`${COMPANY}/${company_id}`, { params });
      (data as $TSFixMe).data = addDimensionsOnImages((data as $TSFixMe).data, imageParameters);
      return { status: 'success', data: (data as $TSFixMe).data };
    }
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const setCompany = async (newProfile: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(COMPANY_PROFILE, newProfile);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const startCompanyDemo = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_START_DEMO);
    const { role, expiryDate } = (data as $TSFixMe).data;

    setLocalStorageItem('role', role, getTimeDifference('now', expiryDate));

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const setCompanyTracking = async (action: string, metadata?: { [key: string]: any }) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_TRACKING, { action, metadata });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyStripeSessionDetails = async (customerID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_UPDATE_STRIPE_DETAILS, customerID);
    return { sessionID: data?.sessionID, stripeRegion: data?.stripeRegion };
  } catch (error: any) {
    console.log(error);
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyNextPaymentDate = async (companyID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_NEXT_PAYMENT_DATE, { companyID });
    return (data as $TSFixMe)?.data?.nextPaymentDate;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanies = async (term: string, filter?: 'premium') => {
  try {
    const params = { term, filter };
    const { data } = await axios.get<{ data: { id: number; name: string }[] }>(
      `${ADMIN_LIST_OF_COMPANIES}`,
      { params }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getAdminUsers = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(ADMIN_LIST_OF_USERS);
    return (data as $TSFixMe).data;
  } catch (error) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getCompanyUsers = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_TEAM_MEMBERS);
    return (data as $TSFixMe).data;
  } catch (error) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getCompanyUserEmailSubscription = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_USER_EMAIL_SUBS);
    return (data as $TSFixMe).data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const getSlackNotificationSubscription = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_USER_SLACK_SUBSCRIPTION);
    return (data as $TSFixMe).data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const initSlack = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_INIT_SLACK);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const initGoogleCalendar = async () => {
  try {
    const { data } = await axios.get(`${COMPANY_GOOGLE_INTEGRATION}/init`);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const initOutlookCalendar = async () => {
  try {
    const { data } = await axios.get(`${COMPANY_OUTLOOK_INTEGRATION}/init`);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const revokeGoogleCalendar = async () => {
  try {
    const { data } = await axios.post(`${COMPANY_GOOGLE_INTEGRATION}/revoke`);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const revokeOutlookCalendar = async () => {
  try {
    const { data } = await axios.post(`${COMPANY_OUTLOOK_INTEGRATION}/revoke`);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateSlackNotification = async (
  value: $TSFixMe,
  type: $TSFixMe,
  action: $TSFixMe
) => {
  try {
    const params = { value, type, action };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_USER_SLACK_SUBSCRIPTION, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const addCompanyUserEmailSubscription = async (
  emailType: string,
  action: string,
  value?: number
) => {
  try {
    const params = { emailType, action };

    if (value) (params as $TSFixMe).value = value;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_USER_EMAIL_SUBS, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateAssociatedPositions = async (action: $TSFixMe, value: $TSFixMe) => {
  try {
    const params = { action, value };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_USER_POSITIONS, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateAssociatedBulkPositions = async (action: $TSFixMe, values: $TSFixMe) => {
  try {
    const params = { action, values };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_USER_BULK_POSITIONS, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateAssociatedTemplates = async (action: $TSFixMe, value: $TSFixMe) => {
  try {
    const params = { action, value };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_USER_SEQUENCES, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyUser = async () => {
  try {
    const { data } = await axios.get(COMPANY_USER);
    userProfile = (data as $TSFixMe).data;
    return (data as $TSFixMe).data;
  } catch (error) {
    return {};
  }
};

/* istanbul ignore next */
export const getPublicCandidateUser = async (verification_code: $TSFixMe) => {
  try {
    const params = { verification_code };
    const { data } = await axios.get(`${PUBLIC_CANDIDATE_USER}`, { params });
    return (data as $TSFixMe).data;
  } catch (error) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getPublicCompanyUser = async (verificationCode: $TSFixMe) => {
  try {
    const params = { verificationCode };
    const { data } = await axios.get(`${PUBLIC_COMPANY_USER}`, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const verifyUserEmail = async (verificationCode: $TSFixMe, verificationType: $TSFixMe) => {
  try {
    const params = { verificationCode, verificationType };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(VERIFY_EMAIL, params);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getListingOptions = async (companyID: $TSFixMe) => {
  try {
    const { data } = await axios.get(`${ADMIN_LISTING_OPTIONS}?companyID=${companyID}`);
    return data;
  } catch (error: any) {
    console.log(error);
  }
};

/* istanbul ignore next */
export const getCandidates = async (term: $TSFixMe) => {
  try {
    const { data } = await axios.get(`${ADMIN_LIST_OF_CANDIDATES}?term=${term}`);
    return data;
  } catch (error: any) {
    console.log(error);
  }
};

export const getPosition = async (
  position_id: number,
  isCandidate: boolean,
  ui: string,
  search_position: number,
  doNotTrack?: boolean
) => {
  const params: any = {};
  const imageParameters: ImageResizeParam[] = [
    { type: 'view-page', dimensions: getImageDimensions('view-page') },
    { type: 'logo', dimensions: getImageDimensions('no-crop-logo') }
  ];

  if (ui) params.ui = ui;
  if (search_position >= 0) params.search_position = search_position;
  if (doNotTrack) params.do_not_track = doNotTrack;

  if (position_id !== -1) {
    const stringPositionID = String(position_id);
    try {
      if (isCandidate) {
        const { data } = await axios.get(`${CANDIDATE_VIEW_POSITION}/${position_id}`, { params });
        (data as $TSFixMe).data = addDimensionsOnImages((data as $TSFixMe).data, imageParameters);
        storeIndexedDBData('positionDetails', data, stringPositionID);
        return (data as $TSFixMe).data;
      } else {
        const { data } = await axios.get(`${PUBLIC_POSITION}/${position_id}`, { params });
        (data as $TSFixMe).data = addDimensionsOnImages((data as $TSFixMe).data, imageParameters);
        storeIndexedDBData('positionDetails', data, stringPositionID);
        return (data as $TSFixMe).data;
      }
    } catch (error) {
      const response = await retrieveIndexedDBData('positionDetails', stringPositionID);
      if (response) return response.data;
      return null;
    }
  } else {
    return null;
  }
};

export const getCompanyMemberProfile = async (
  id: $TSFixMe,
  isCandidate: $TSFixMe,
  ui: $TSFixMe,
  search_position: $TSFixMe,
  isSearchPage: $TSFixMe
) => {
  let params = {};

  if (isSearchPage) {
    params = { search_position };
    if (ui) (params as $TSFixMe).ui = ui;
  }

  if (id !== -1) {
    try {
      if (isCandidate) {
        const { data } = await axios.get(`${CANDIDATE_VIEW_MEMBER}/${id}`, { params });
        (data as $TSFixMe).data = addDimensionsOnImages((data as $TSFixMe).data, {
          type: 'logo',
          dimensions: getImageDimensions('no-crop-logo-large')
        });

        return (data as $TSFixMe).data;
      } else {
        const { data } = await axios.get(`${COMPANY}/member/${id}`, { params });
        (data as $TSFixMe).data = addDimensionsOnImages((data as $TSFixMe).data, {
          type: 'logo',
          dimensions: getImageDimensions('no-crop-logo-large')
        });

        return (data as $TSFixMe).data;
      }
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};

/* istanbul ignore next */
export const getMessagingPositions = async () => {
  const requestId = 'message_positions';

  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_POSITIONS_MESSAGING, { requestId });

    return (data as $TSFixMe).data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const updateMessagingPositions = async (selectedIDs: $TSFixMe) => {
  try {
    await axios.put(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      COMPANY_POSITIONS_MESSAGING,
      { selectedIDs },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyNotifications = async (messageFilter: $TSFixMe) => {
  try {
    const params = { messageFilter };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_NOTIFICATIONS, { params });
    return data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const getCandidateNotifications = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(CANDIDATE_NOTIFICATIONS);
    return (data as $TSFixMe).data;
  } catch (error) {
    return {};
  }
};

/* istanbul ignore next */
export const setProfile = async (newProfile: $TSFixMe) => {
  delete newProfile.warningMessage;

  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_PROFILE, newProfile);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const editProfileInfo = async (profileInfo: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(CANDIDATE_EDIT_PROFILE_INFO, profileInfo, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const editCandidatePreferences = async (
  preferences: DepCandidateInputInterface
): Promise<APIResponse> => {
  try {
    await axios.put(CANDIDATE_EDIT_PREFERENCES, preferences, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (preferences.homepageSetupComplete) {
      setLocalStorageItem('show_onboarding_steps', true, 2 * getMillisecondsByType('days'));
    }

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const saveExperienceItem = async (
  id: $TSFixMe,
  experienceDetails: $TSFixMe,
  shouldUpdateDate: boolean = true
) => {
  try {
    const experience = shouldUpdateDate
      ? updateDateInputs(experienceDetails, 'experience')
      : experienceDetails;

    if (id) {
      await axios.put(`${CANDIDATE_EDIT_EXPERIENCE}/${id}`, experience, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(CANDIDATE_EDIT_EXPERIENCE, experience);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteExperienceItem = async (id: $TSFixMe) => {
  try {
    await axios.delete(`${CANDIDATE_EDIT_EXPERIENCE}/${id}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
/* istanbul ignore next */
export const saveLanguageItem = async (
  languageDetails: CandidateLanguageInterface[],
  id?: number
) => {
  try {
    if (id) {
      await axios.put(`${CANDIDATE_EDIT_LANGUAGE}/${id}`, languageDetails[0], {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      await axios.post(`${CANDIDATE_EDIT_LANGUAGE}`, { languages: languageDetails });
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteLanguageItem = async (id: number) => {
  try {
    await axios.delete(`${CANDIDATE_EDIT_LANGUAGE}/${id}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
/* istanbul ignore next */
export const saveEducationItem = async (id: $TSFixMe, educationDetails: $TSFixMe) => {
  try {
    if (id) {
      await axios.put(`${CANDIDATE_EDIT_EDUCATION}/${id}`, educationDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(CANDIDATE_EDIT_EDUCATION, educationDetails);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteEducationItem = async (id: $TSFixMe) => {
  try {
    await axios.delete(`${CANDIDATE_EDIT_EDUCATION}/${id}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const saveProjectItem = async (id: $TSFixMe, projectDetails: $TSFixMe) => {
  try {
    const project = updateDateInputs(projectDetails, 'project');

    if (id) {
      await axios.put(`${CANDIDATE_EDIT_PROJECT}/${id}`, project, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(CANDIDATE_EDIT_PROJECT, project);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteProjectItem = async (id: $TSFixMe) => {
  try {
    await axios.delete(`${CANDIDATE_EDIT_PROJECT}/${id}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const saveAchievementItem = async (id: $TSFixMe, achievementDetails: $TSFixMe) => {
  //convert month and year to publishDate
  const achieveDate = new Date();

  (achieveDate as $TSFixMe).setYear(achievementDetails.year);
  achieveDate.setMonth(achievementDetails.month, 15);

  achievementDetails.achieveDate = achieveDate;

  delete achievementDetails.month;
  delete achievementDetails.year;
  try {
    if (id) {
      await axios.put(`${CANDIDATE_EDIT_ACHIEVEMENT}/${id}`, achievementDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(CANDIDATE_EDIT_ACHIEVEMENT, achievementDetails);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteAchievementItem = async (id: $TSFixMe) => {
  try {
    await axios.delete(`${CANDIDATE_EDIT_ACHIEVEMENT}/${id}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const savePublicationItem = async (id: $TSFixMe, publicationDetails: $TSFixMe) => {
  //convert month and year to publishDate
  const publishDate = new Date();

  (publishDate as $TSFixMe).setYear(publicationDetails.year);
  publishDate.setMonth(publicationDetails.month, 15);

  publicationDetails.publishDate = publishDate;

  const authors = publicationDetails.authors
    .filter((item: $TSFixMe) => item)
    .map((item: $TSFixMe) => item.replace(/,/g, '').trim());
  publicationDetails.authors = authors;

  delete publicationDetails.month;
  delete publicationDetails.year;

  try {
    if (id) {
      await axios.put(`${CANDIDATE_EDIT_PUBLICATION}/${id}`, publicationDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(CANDIDATE_EDIT_PUBLICATION, publicationDetails);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deletePublicationItem = async (id: $TSFixMe) => {
  try {
    await axios.delete(`${CANDIDATE_EDIT_PUBLICATION}/${id}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCandidateReferrals = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(CANDIDATE_REFERRALS);

    return { data: (data as $TSFixMe).data, status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const generateCandidateInviteLink = async (
  name: $TSFixMe,
  endpoint: $TSFixMe,
  taggingLevelOne: $TSFixMe
) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(CANDIDATE_REFERRALS, {
      payload: { name: name.trim() },
      endpoint,
      taggingLevelOne
    });

    return { data: (data as $TSFixMe).data, status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const trackCandidateAction = (action: CandidateTracking, metadata?: any) => {
  try {
    axios.post(CANDIDATE_ACTION!, { action, ...(metadata ? { metadata } : {}) });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const setVisible = async (visible: boolean): Promise<APIResponse> => {
  try {
    await axios.post(CANDIDATE_INVISIBLE, { invisible: !visible });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const storeCV = async (
  file: File,
  firstName: string,
  lastName: string
): Promise<{ status: string; data?: { [key: string]: any }; message?: string }> => {
  try {
    const form = new FormData();
    form.append('fileUploaded', file);

    const { data } = await axios.post(
      `${STORE_CV}?firstName=${firstName}&lastName=${lastName}`,
      form
    );
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const signUp = async (form: $TSFixMe) => {
  delete form.termsAccepted;

  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(SIGN_UP, form);
    const { role, candidateID } = data.data;

    setLocalStorageItem('role', role);

    return { status: 'success', candidateID };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companySignUp = async (form: $TSFixMe, signupType?: string) => {
  delete form.termsAccepted;

  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_SIGN_UP, {
      ...form,
      signupType: signupType || 'paid'
    });
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyRequestAccessSignUp = async (form: RequestDemoForm) => {
  const referrerData = getReferrerTrackingDetails();

  form.password = `asdASD_${hashRandom()}`; // Made it more secure - used for creating the company account
  form.name = `${form.firstName?.trim()} ${form.lastName?.trim()}`;

  delete form.firstName;
  delete form.lastName;

  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(REQUEST_ACCESS_SIGN_UP, { ...form, ...referrerData });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const demoCompanySignUp = async (form: $TSFixMe) => {
  try {
    const country = await getUserLocalisation();

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(DEMO_SIGN_UP, { country, ...form });
    const { role, expiryDate } = data.data;

    setLocalStorageItem('role', role, getTimeDifference('now', expiryDate));

    return { status: 'success', role };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

type EmailCheckResult = {
  exists: boolean;
  companyExists: boolean;
  candidateExists: boolean;
  valid?: boolean;
};

/* istanbul ignore next */
export const checkEmail = async (
  email: string,
  type: 'ev' | '',
  context?: EmailValidationContext
): Promise<
  { status: 'success'; data: EmailCheckResult } | { status: 'failure'; message: string }
> => {
  try {
    const params: { email: string; type?: 'ev'; context?: EmailValidationContext } = { email };

    if (type) params.type = type;
    if (context) params.context = context;

    const { data } = await axios.get<{ data: EmailCheckResult }>(CHECK_EMAIL, { params });
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const checkPhone = async (phoneNumber: $TSFixMe, countryCode: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(CHECK_PHONE, { params: { phoneNumber, countryCode } });
    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getMFAQRCode = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(USER_MFA_SETUP);
    return (data as $TSFixMe).data;
  } catch (error) {
    return {
      qrCode:
        'https://images.samsung.com/is/image/samsung/p5/au/faq/os-pie-updates/QR-code.png?$ORIGIN_PNG$'
    }; //TOREMOVE
  }
};

/* istanbul ignore next */
export const activateMFA = async (mfaCode: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(USER_MFA_SETUP, { mfaCode });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const disableMFA = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(USER_MFA_DISABLE);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const validateMFA = async (mfaParams: $TSFixMe, mfaCode: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(USER_MFA_VALIDATE, { ...mfaParams, mfaCode });
    return { data: data.data, status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const companySignupDemo = async (code?: string) => {
  try {
    const { referrer, referrerDetails } = getReferrerTrackingDetails();
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(DEMO_SIGN_UP, { code, referrer, referrerDetails });
    const { role, expiryDate } = (data as $TSFixMe).data;

    setLocalStorageItem('role', role, getTimeDifference('now', expiryDate));

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const candidateBookInvite = async (
  companyID: $TSFixMe,
  listingID: number,
  startDate: $TSFixMe,
  endDate: $TSFixMe,
  timezone: $TSFixMe,
  messageID: $TSFixMe
) => {
  try {
    const params = { companyID, listingID, startDate, endDate, timezone, messageID };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_BOOK_INVITE, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

const parseFileName = (fileName: $TSFixMe) => {
  const hash = hashRandom();
  const nameWithoutSpaces = fileName.replace(allSpacesRegex, '-');
  const uniqueNameWithHash = `${hash}-${nameWithoutSpaces}`;

  return uniqueNameWithHash;
};

/* istanbul ignore next */
export const uploadFiles = async (files: $TSFixMe) => {
  const filesUploaded = [];

  for (const file of files) {
    const uniqueFileName = parseFileName(file.name);

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    // eslint-disable-next-line
    const { data } = await axios.get(S3_SIGN_REQUEST, {
      params: { filename: uniqueFileName, filetype: file.type }
    });
    const { signedRequest, url } = data;

    // eslint-disable-next-line
    await sendPutFileXMLRequest(file, signedRequest);
    file.url = url;
    filesUploaded.push(file);
  }

  return filesUploaded;
};

/* istanbul ignore next */
export const seenPosition = async (listingID: number, companyID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_POSITION_SEEN, { listingID, companyID });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const messageSeen = async (messageID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(USER_MESSAGE_SEEN, { messageID });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const startApplication = async (
  { listingID, companyID, companyAccountID }: $TSFixMe,
  ui: $TSFixMe,
  searchPosition: $TSFixMe
) => {
  try {
    const params = { listingID, companyID, companyAccountID, searchPosition };

    if (ui) (params as $TSFixMe).ui = ui;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_APPLICATION_START, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const applyPosition = async (
  applicationParams: $TSFixMe,
  ui: $TSFixMe,
  searchPosition: $TSFixMe,
  message: $TSFixMe
) => {
  try {
    const params = { ...applicationParams, searchPosition };

    if (ui) params.ui = ui;
    if (message) params.message = message;

    await axios.post(CANDIDATE_POSITION_APPLY, params);

    const inOnboardingProcess = getLocalStorageItem('show_onboarding_steps');

    if (inOnboardingProcess) setLocalStorageItem('first_message_sent', true);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const acceptPosition = async (listingID: number, companyID: $TSFixMe, message: $TSFixMe) => {
  try {
    const params = { listingID, companyID };

    if (message) (params as $TSFixMe).message = message;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_POSITION_ACCEPT, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const skipPosition = async (
  listingID: number,
  companyID: $TSFixMe,
  ui: $TSFixMe,
  searchPosition: $TSFixMe
) => {
  try {
    const params = { listingID, companyID, searchPosition };
    if (ui) (params as $TSFixMe).ui = ui;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_POSITION_SKIP, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const declinePosition = async (
  listingID: number,
  companyID: number,
  message: string,
  reason?: string
) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_POSITION_DECLINE, {
      listingID,
      companyID,
      message,
      declineReason: reason
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const confirmPosition = async (
  listingID: number,
  companyID: $TSFixMe,
  message: $TSFixMe
) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_POSITION_CONFIRM, { listingID, companyID, message });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const postConversationMessage = async (
  user: $TSFixMe,
  params: $TSFixMe,
  inviteDetails: $TSFixMe
) => {
  try {
    let messageID: number = 0;
    if (user === 'company') {
      const allParams = { ...params }; // separate copy of params

      delete allParams.companyID;

      if (inviteDetails.inviteTypeID) Object.assign(allParams, inviteDetails);

      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      const { data } = await axios.post(COMPANY_CANDIDATE_MESSAGE, allParams);

      messageID = data?.data?.messageID;
    } else {
      const allParams = { ...params }; // separate copy of params

      delete allParams.candidateID;

      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(CANDIDATE_POSITION_MESSAGE, allParams);
    }
    return { status: 'success', messageID };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const changeEmail = async (email: $TSFixMe, password: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(USER_CHANGE_EMAIL, {
      email,
      password
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const changePassword = async (password: $TSFixMe, newPassword: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(USER_CHANGE_PASSWORD, {
      password,
      newPassword
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const deleteCandidateAccount = async () => {
  try {
    await axios.delete(CANDIDATE_DELETE);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const adminDeleteCandidateAccount = async (email?: string, reason?: string) => {
  try {
    await axios.delete(ADMIN_CANDIDATE_DELETE, {
      data: {
        reason,
        email
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteCompanyAccount = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.delete(COMPANY_DELETE);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteCompanyPersonalAccount = async () => {
  try {
    await axios.delete(COMPANY_USER);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const sendForgotPassword = async (email: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(FORGOT_PASSWORD, { email });
    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateForgotPassword = async (forgotCode: $TSFixMe, password: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(FORGOT_PASSWORD_UPDATE, {
      forgot_code: forgotCode,
      password
    });
    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const postFeedback = async (message: string) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(CANDIDATE_FEEDBACK, { message });
    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/** @deprecated use `CANDIDATE_SEARCH_FILTERS` from src/consts/candidate/positionSearch.ts */
export const candidateSearchFilters = {
  orPrimarySkill: { label: 'Core skill', attribute: 'orPrimarySkill' },
  primarySkill: { label: 'Core skill', attribute: 'primarySkill' },
  orSkill: { label: 'Skill', attribute: 'orSkill' },
  skill: { label: 'Skill', attribute: 'skill' },
  techStack: { label: 'Tech stack/tooling', attribute: 'techStack' },
  orTechStack: { label: 'Tech stack/tooling', attribute: 'orTechStack' },
  jobTitle: { attribute: 'jobTitle' },
  jobType: { attribute: 'jobType' },
  seniority: { attribute: 'seniority' },
  salary: { label: 'Min', attribute: 'salary' },
  lowerTimeOffset: { label: 'Lower Timezone Offset', attribute: 'lowerTimeOffset' },
  upperTimeOffset: { label: 'Upper Timezone Offset', attribute: 'upperTimeOffset' },
  salaryVisible: {
    label: 'Exclude positions with undisclosed salaries',
    attribute: 'salaryVisible'
  },
  location: { attribute: 'location' },
  keyword: { attribute: 'keyword' },
  remoteWorking: { attribute: 'remote' },
  remoteLocationContinents: { attribute: 'remoteLocationContinents' },
  remoteLocationCountries: { attribute: 'remoteLocationCountries' },
  workEligibility: { label: 'Offer visa sponsorship', attribute: 'visaSponsorship' },
  previouslyDealtWith: {
    label: 'Include positions in conversation with',
    attribute: 'excludeDealtWith'
  },
  hidden: { label: 'Include positions marked as not right', attribute: 'includeHidden' },
  sortBy: { label: 'Sort by', attribute: 'sortBy' },
  lastActive: { label: 'Last active', attribute: 'lastActive' },
  companySize: { label: 'Company size', attribute: 'companySize' },
  companyName: { attribute: 'companyName' },
  industry: { attribute: 'industry' },
  boundSouth: { label: 'Selected map area', attribute: 'locationBoundSouth' },
  boundWest: { label: 'Selected map area', attribute: 'locationBoundWest' },
  boundNorth: { label: 'Selected map area', attribute: 'locationBoundNorth' },
  boundEast: { label: 'Selected map area', attribute: 'locationBoundEast' },
  sortByLat: { attribute: 'sortByLocationLat' },
  sortByLng: { attribute: 'sortByLocationLng' },
  lastActiveValue: { attribute: 'lastActiveValue' },
  lastActiveType: { attribute: 'lastActiveType' },
  externalCompanies: { attribute: 'externalCompanies' }
};

export const insightsSearchFilters = {
  orPrimarySkill: { label: 'Core skill', attribute: 'orPrimarySkill' },
  primarySkill: { label: 'Core skill', attribute: 'primarySkill' },
  orSkill: { label: 'Skill', attribute: 'orSkill' },
  skill: { label: 'Skill', attribute: 'skill' },
  techStack: { label: 'Tech stack/tooling', attribute: 'techStack' },
  orTechStack: { label: 'Tech stack/tooling', attribute: 'orTechStack' },
  topic: { label: 'Topic', attribute: 'topic' },
  jobTitle: { attribute: 'jobTitle' },
  seniority: { attribute: 'seniority' },
  keyword: { attribute: 'keyword' }
};

/* istanbul ignore next */
export const searchPeople = async (
  page: number,
  seniority?: Seniority[],
  jobTitle?: string[],
  remote?: RemoteOption,
  sortBy?: SearchSortByEnum
) => {
  try {
    const country = await getUserLocalisation();
    const params: SearchCustomPeopleQueryParams = { page, ...(country ? { country } : {}) };

    if (seniority?.length) params.seniority = seniority;
    if (jobTitle?.length) params.jobTitle = jobTitle;
    if (remote) params.remote = [remote];
    if (sortBy) params.sortBy = sortBy;

    const { data } = await axios.get(PUBLIC_SEARCH_PEOPLE, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return {
      status: 'failure',
      message: error?.response?.data?.message,
      values: [],
      resultsCount: 0
    };
  }
};

/* istanbul ignore next */
export const getCandidateEmailNotifications = async () => {
  try {
    const { data } = await axios.get<{ data: EmailSubscriptionAndFrequencyResponse }>(
      CANDIDATE_EMAIL_NOTIFICATIONS!
    );

    return data.data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateCandidateEmailNotifications = async (
  type: CandidateEmailType,
  subscribe: boolean,
  frequency?: string | null,
  savedSearchIDs?: number[] | null
) => {
  try {
    const params = { type, subscribe, frequency, savedSearchIDs };

    await axios.post(CANDIDATE_EMAIL_NOTIFICATIONS, params);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const subscribeCandidateSavedSearchNotifications = async (
  id: $TSFixMe,
  subscribe: $TSFixMe
) => {
  try {
    const { data } = await axios.post(
      `${COMPANY_STREAM}/${id}/${subscribe ? 'subscribe' : 'unsubscribe'}`
    );
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const advanceSearchCandidates = async ({
  searchTerms,
  otherTerms,
  admin,
  requestID,
  streamName,
  streamID
}: {
  searchTerms: $TSFixMe;
  otherTerms?: $TSFixMe;
  admin?: boolean;
  requestID?: string;
  streamName?: string;
  streamID?: number;
}) => {
  const requestId = requestID || `company_search_request_${JSON.stringify(searchTerms)}`;
  try {
    const params = { ...otherTerms };
    const stringParameters = [
      companySearchFilters.relocate.attribute,
      companySearchFilters.previouslyDealtWith.attribute,
      companySearchFilters.previouslySkipped.attribute,
      companySearchFilters.candidatePool.attribute,
      companySearchFilters.lastActiveValue.attribute,
      companySearchFilters.lastActiveType.attribute,
      companySearchFilters.sortBy.attribute,
      companySearchFilters.newCandidates.attribute,
      companySearchFilters.ethnicity.attribute,
      companySearchFilters.lowerTimeOffset.attribute,
      companySearchFilters.upperTimeOffset.attribute,
      companySearchFilters.radius.attribute,
      companySearchFilters.cordLive.attribute
    ];
    const searchTermsObj = { ...params, ...getSearchTerms(searchTerms, stringParameters) };

    if (streamName) searchTermsObj.stream_name = streamName;
    if (streamID) searchTermsObj.saved_search_id = streamID;
    const config = {
      params: searchTermsObj,
      requestId
    } as AxiosRequestConfig;

    if (admin) {
      const { data } = await axios.get(`${ADMIN_ADVANCE_SEARCH}`, config);

      return (data as $TSFixMe).data;
    } else {
      const { data } = await axios.get(`${COMPANY_ADVANCE_SEARCH}`, config);
      return data.data;
    }
  } catch (error: any) {
    return {
      status: axios.isCancel(error) ? 'cancelled' : 'failure',
      message: error?.response?.data?.message,
      results_count: 0
    };
  }
};

/* istanbul ignore next */
export const getCompanyStreams = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_STREAM);

    return (data as $TSFixMe).data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const updateCompanyStreamsOrder = async (feedOrder: $TSFixMe) => {
  try {
    const { data } = await axios.put(`${COMPANY_STREAM}/order`, { feedOrder });

    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const createCompanyStream = async (params: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_STREAM, params);

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateCompanyStream = async (
  id: $TSFixMe,
  searchName: $TSFixMe,
  listingIDs: $TSFixMe,
  streamUserMembers: $TSFixMe,
  searchParam: $TSFixMe
) => {
  try {
    const params = {
      id,
      searchName,
      listingIDs,
      streamUserMembers,
      searchParam: { params: searchParam }
    };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.put(COMPANY_STREAM, params);

    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const removeCompanyStream = async (id: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.delete(COMPANY_STREAM, {
      data: { id }
    });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const subscribeCompanyStreamNotifications = async (id: $TSFixMe, subscribe: $TSFixMe) => {
  try {
    const { data } = await axios.post(
      `${COMPANY_STREAM}/${id}/${subscribe ? 'subscribe' : 'unsubscribe'}`
    );
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const markHiredCandidate = async (
  candidateID: number,
  listingID: number,
  hired: $TSFixMe
) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_HIRE, { listingID, candidateID, hired });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const reportAndBlock = async (
  reporteeID: number | null,
  listingID: number,
  reasons: string[],
  description: string,
  block: boolean
) => {
  try {
    const { data } = await axios.post(USER_REPORT!, {
      reporteeID,
      listingID,
      reasons,
      description,
      block
    });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCandidatesInConversations = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_CONVERSATION_CANDIDATES);
    return (data as $TSFixMe).data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const getShortlistData = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_SHORTLIST);

    return (data as $TSFixMe).data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const getShortlistedCandidates = async (shortlistID: $TSFixMe, page: $TSFixMe) => {
  try {
    const params = { page };

    const { data } = await axios.get(`${COMPANY_SHORTLIST_CANDIDATES}/${shortlistID}`, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const removeShortlistedCandidate = async (candidateID: number, shortlistID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.delete(COMPANY_SHORTLIST_CANDIDATES, {
      data: { candidateID, shortlistID }
    });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const addShortlistedCandidate = async (
  candidateID: number,
  shortlistID: number,
  searchTrackID: string | null,
  streamID?: number
) => {
  try {
    const params = {
      candidateID,
      shortlistID,
      ...(searchTrackID ? { searchTrackID } : {}),
      ...(streamID ? { streamID } : {})
    };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_SHORTLIST_CANDIDATES, params);

    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const candidateOnboardSkipPosition = async (listingID: number, position: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_ONBOARDING_SKIP_POSITION, { listingID, position });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const candidateOnboardLikePosition = async (listingID: number, position: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_ONBOARDING_LIKE_POSITION, { listingID, position });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const candidateOnboardComplete = async (onboardingStatus: $TSFixMe) => {
  try {
    await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      CANDIDATE_ONBOARDING_COMPLETE,
      { onboardingStatus },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getShortlistPosition = async (type: $TSFixMe) => {
  try {
    const params = { type };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(CANDIDATE_SHORTLIST_POSITION, { params });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const candidateShortlistPosition = async (listingID: number, type: $TSFixMe) => {
  try {
    const uniqueIdentifier = getLocalStorageItem('uniqueIdentifier') || '';
    const jobSearchPosition = getLocalStorageItem('jobSearchPosition') || 0;

    const params = {
      listingID,
      type,
      searchTrackingID: uniqueIdentifier,
      searchResultPosition: jobSearchPosition
    };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(CANDIDATE_SHORTLIST_POSITION, params);

    await clearIndexedDBData('sourcePositions');
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const candidateRemoveShortlist = async (shortlistID: $TSFixMe) => {
  try {
    const { data } = await axios.delete(`${CANDIDATE_SHORTLIST_POSITION}/${shortlistID}`);

    await clearIndexedDBData('sourcePositions');
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

const parseAdminTaggingData = (data: $TSFixMe) => {
  return data.map(({ jobTitles, primarySkills, secondarySkills, ...rest }: $TSFixMe) => {
    return {
      jobTitles: jobTitles && jobTitles.length > 0 ? jobTitles.filter((t: $TSFixMe) => t) : [],
      primarySkills:
        primarySkills && primarySkills.length > 0 ? primarySkills.filter((s: $TSFixMe) => s) : [],
      secondarySkills:
        secondarySkills && secondarySkills.length > 0
          ? secondarySkills.filter((s: $TSFixMe) => s)
          : [],
      ...rest
    };
  });
};

export const getAdminTaggingData = async (dataType: $TSFixMe) => {
  try {
    let ENDPOINT = '';

    switch (dataType) {
      case 'position':
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        ENDPOINT = ADMIN_POSITION_TAGGING;
        break;
      case 'candidate':
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        ENDPOINT = ADMIN_CANDIDATE_TAGGING;
        break;
      default:
        break;
    }

    const { data } = await axios.get(ENDPOINT);

    return { status: 'success', data: parseAdminTaggingData((data as $TSFixMe).data) };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, data: [] };
  }
};

/* istanbul ignore next */
export const updateAdminTagging = async (dataType: $TSFixMe, params: $TSFixMe) => {
  try {
    let ENDPOINT = '';

    switch (dataType) {
      case 'position':
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        ENDPOINT = ADMIN_POSITION_TAGGING;
        break;
      case 'candidate':
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        ENDPOINT = ADMIN_CANDIDATE_TAGGING;
        break;
      default:
        break;
    }

    const { data } = await axios.put(ENDPOINT, params);
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, data: [] };
  }
};

const parseCSDashboardData = (data: $TSFixMe) => {
  return data.map(({ companyAccountEmail, companyAccountStartDate, ...rest }: $TSFixMe) => {
    if (companyAccountEmail) {
      return {
        companyAccountEmail,
        companyAccountStartDate: companyAccountStartDate || 'N/A',
        ...rest
      };
    } else return { ...rest };
  });
};

/**
 *
 * @param {string} startTime
 * @param {string} endTime
 * @param {string} dataType supports 'all-company', 'all-company-account', 'all-position' and 'company'
 * @param {integer} [id] only applicable when dataType = 'company'
 */
export const getDashboardData = async (
  startTime: $TSFixMe,
  endTime: $TSFixMe,
  dataType: $TSFixMe,
  id: $TSFixMe
) => {
  const requestId = `${dataType}-dashboard-data`;
  try {
    const params = {
      startTime: new Date(startTime).getTime(),
      endTime: new Date(endTime).getTime()
    };
    let URI = '';
    switch (dataType) {
      case 'all-company-account':
        URI = 'all';
        break;
      case 'all-position':
        URI = 'position';
        break;
      case 'company':
        URI = `${id}`;
        break;
      default:
        break;
    }
    const API_URL = `${ADMIN_COMPANY_STATS}/${URI}`;

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ params: { startTime: number; e... Remove this comment to see the full error message
    const { data } = await axios.get(API_URL, { params, requestId });
    return { status: 'success', data: parseCSDashboardData((data as $TSFixMe).data) };
  } catch (error: any) {
    if (axios.isCancel(error)) console.log(`${requestId} cancelled`);
    return {
      status: 'failure',
      cancelled: axios.isCancel(error),
      message: error?.response?.data?.message,
      data: []
    };
  }
};

/* istanbul ignore next */
export const freezeCompany = async (companyID: $TSFixMe, days: $TSFixMe) => {
  try {
    const params = { companyID, days: parseInt(days, 10) };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(ADMIN_COMPANY_FREEZE, params);
    return { status: 'success', message: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getAdminStats = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(ADMIN_STATS);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, data: null };
  }
};

/* istanbul ignore next */
export const getCompanyAnalytics = async (
  startTime: $TSFixMe,
  endTime: $TSFixMe,
  statsType: $TSFixMe
) => {
  try {
    const params = {
      startTime: new Date(startTime).getTime(),
      endTime: new Date(endTime).getTime()
    };

    const endpoint = statsType !== '' ? `${COMPANY_ANALYTICS}/${statsType}` : COMPANY_ANALYTICS;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(endpoint, { params });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, data: null };
  }
};

/* istanbul ignore next */
export const getCompanyInviteDetails = async (id: $TSFixMe) => {
  try {
    const { data } = await axios.get(`${COMPANY_INVITES}/${id}`);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, data: null };
  }
};

/* istanbul ignore next */
export const getCompanyInvites = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_INVITES);
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, data: [] };
  }
};

/* istanbul ignore next */
export const saveCompanyInvite = async (id: $TSFixMe, invite: $TSFixMe) => {
  try {
    if (id) {
      await axios.put(`${COMPANY_INVITES}/${id}`, invite, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(COMPANY_INVITES, invite, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteCompanyInvite = async (id: $TSFixMe) => {
  try {
    await axios.delete(`${COMPANY_INVITES}/${id}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyMessagePreviews = async (
  listingIDs: number[],
  messageFilter: $TSFixMe,
  messageCategory: $TSFixMe,
  searchTerm: $TSFixMe,
  isAgency: boolean,
  page?: number
) => {
  const requestId = `company_message_previews_${messageCategory}_${page}`;
  try {
    const params = { listingID: listingIDs, messageCategory, searchTerm };

    if (!shouldIgnoreMessageFilter(messageCategory, isAgency)) {
      (params as $TSFixMe).messageFilter = messageFilter;
    }
    if (page !== null || page !== undefined) (params as $TSFixMe).page = page;

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ params: { listingID: number[];... Remove this comment to see the full error message
    const { data } = await axios.get(`${COMPANY_CANDIDATE_MESSAGE}`, { params, requestId });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, data: [] };
  }
};

export const getCompanyMessageDetails = async (params: $TSFixMe, requestId: $TSFixMe) => {
  try {
    const { data } = await axios.get(
      `${COMPANY_CANDIDATE_MESSAGE}/${params.listingID}/${params.candidateID}`,
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ requestId: any; }' is not assi... Remove this comment to see the full error message
      { requestId }
    );
    const parsedData = decodeProfile((data as $TSFixMe).data, UserType.Candidate);

    return { status: 'success', data: parsedData };
  } catch (error: any) {
    return {
      status: axios.isCancel(error) ? 'cancelled' : 'failure',
      message: error?.response?.data?.message,
      data: null
    };
  }
};

/* istanbul ignore next */
export const getCandidateMessagePreviews = async (messageCategory: $TSFixMe) => {
  const requestId = `message_previews_${messageCategory}`;

  try {
    const params = { messageCategory };

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ params: { messageCategory: any... Remove this comment to see the full error message
    const { data } = await axios.get(`${CANDIDATE_POSITION_MESSAGE}`, { params, requestId });
    storeIndexedDBData(messageCategory, data);
    return { status: 'success', data: (data as $TSFixMe).data || [] };
  } catch (error: any) {
    const response = await retrieveIndexedDBData(messageCategory);
    if (response) return { status: 'success', data: response.data };
    return {
      status: axios.isCancel(error) ? 'cancelled' : 'failure',
      message: error?.response?.data?.message,
      data: []
    };
  }
};

/* istanbul ignore next */
export const getCandidateMessageDetails = async (params: $TSFixMe, requestId: $TSFixMe) => {
  const key = `${params.companyID}${params.listingID}`;

  try {
    const { data } = await axios.get(
      `${CANDIDATE_POSITION_MESSAGE}/${params.companyID}/${params.listingID}`,
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ requestId: any; }' is not assi... Remove this comment to see the full error message
      { requestId }
    );

    storeIndexedDBData('messageDetails', data, key);

    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    const response = await retrieveIndexedDBData('messageDetails', key);
    if (response) return { status: 'success', data: response.data };
    return {
      status: axios.isCancel(error) ? 'cancelled' : 'failure',
      message: error?.response?.data?.message,
      data: null
    };
  }
};

export const getCandidate = async (
  candidateID: number | string,
  isAdmin: boolean,
  ui?: string,
  searchPosition?: number,
  savedSearchID?: number
) => {
  try {
    const params = {
      candidateID,
      ui,
      searchPosition,
      savedSearchID
    };

    if (isAdmin) {
      const { data } = await axios.get(`${ADMIN_VIEW_CANDIDATE}`, { params });
      return decodeProfile((data as $TSFixMe).data, UserType.Candidate);
    } else {
      const { data } = await axios.get(`${COMPANY_VIEW_CANDIDATE}`, { params });
      (data as $TSFixMe).data = addDimensionsOnImages((data as $TSFixMe).data, {
        type: 'logo',
        dimensions: getImageDimensions('logo-large')
      });
      return decodeProfile((data as $TSFixMe).data, UserType.Candidate);
    }
  } catch (error) {
    return null;
  }
};

/* istanbul ignore next */
export const seenCandidate = async (candidateID: number, listingID: number) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_CANDIDATE_SEEN, { candidateID, listingID });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const applyCandidate = async (
  candidateID: number,
  listingID: number | null,
  trackingData: $TSFixMe,
  messages: $TSFixMe,
  invite: $TSFixMe
): Promise<APIResponse> => {
  try {
    const { uniqueIdentifier, candidateSearchPos, templateID } = trackingData;
    const { message, followupMessage } = messages;
    const { inviteTypeID } = invite;
    const params = { listingID, candidateID, candidateSearchPos, message };

    if (uniqueIdentifier) (params as $TSFixMe).ui = uniqueIdentifier;
    if (followupMessage) (params as $TSFixMe).followupMessage = followupMessage;
    if (templateID) (params as $TSFixMe).templateID = templateID;
    if (inviteTypeID) Object.assign(params, invite);

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_CANDIDATE_APPLY, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const acceptCandidate = async (
  listingID: number,
  candidateID: number,
  messages: $TSFixMe,
  templateID: $TSFixMe,
  inviteDetails: $TSFixMe
) => {
  try {
    const { message, followupMessage } = messages;
    const params = { listingID, candidateID, message };

    if (templateID) (params as $TSFixMe).templateID = templateID;
    if (followupMessage) (params as $TSFixMe).followupMessage = followupMessage;
    if (inviteDetails.inviteTypeID) Object.assign(params, inviteDetails);

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_CANDIDATE_ACCEPT, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyArchiveConversation = async (
  candidateID: number,
  listingID: number,
  archived: boolean
) => {
  try {
    const params = { candidateID, listingID, archived };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_APPLICATION_ARCHIVE, params);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const candidateArchiveConversation = async (
  companyID: number,
  listingID: number,
  archived: boolean
) => {
  try {
    const params = { companyID, listingID, archived };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(CANDIDATE_APPLICATION_ARCHIVE, params);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const markConversationStarted = async (listingID: number, candidateID: number) => {
  try {
    const params = { listingID, candidateID };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_MARK_CONVERSATION, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const confirmCandidateConversation = async (
  listingID: number,
  candidateID: number,
  message: $TSFixMe,
  inviteDetails: $TSFixMe
): Promise<{ status: string; message?: string; messageID: number }> => {
  try {
    const params = { candidateID, listingID, message };

    if (inviteDetails.inviteTypeID) Object.assign(params, inviteDetails);

    const { data } = (await axios.post(`${COMPANY_CANDIDATE_CONVO_CONFIRM}`, params)) as {
      data: { data: { messageID: number } };
    };

    return { status: 'success', messageID: data?.data?.messageID };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, messageID: 0 };
  }
};

/* istanbul ignore next */
export const cancelQueuedMessages = async (templateID: $TSFixMe, listingIDs: $TSFixMe) => {
  try {
    await axios.put(`${COMPANY_QUEUED_MESSAGES}/cancel-all`, { templateID, listingIDs });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const cancelQueuedMessage = async (id: $TSFixMe) => {
  try {
    await axios.put(`${COMPANY_QUEUED_MESSAGES}/cancel`, { id });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const editQueuedMessage = async (id: $TSFixMe, message: $TSFixMe, sendDate: $TSFixMe) => {
  try {
    await axios.put(`${COMPANY_QUEUED_MESSAGES}/edit`, { id, message, sendDate });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const editCompanyMessage = async (id: number, message: string) => {
  try {
    await axios.patch(`${COMPANY_CANDIDATE_MESSAGE}/${id}`, { message });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const sendQueuedMessage = async (id: $TSFixMe) => {
  try {
    const { data } = (await axios.post(`${COMPANY_QUEUED_MESSAGES}/send`, { id })) as {
      data: { data: { messageID: number } };
    };
    return { status: 'success', messageID: data?.data?.messageID };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const createCompanyTemplate = async (templateDetails: $TSFixMe) => {
  try {
    await axios.post(COMPANY_TEMPLATE, templateDetails);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateCompanyTemplate = async (templateID: $TSFixMe, templateDetails: $TSFixMe) => {
  try {
    const params = { ...templateDetails };
    await axios.put(`${COMPANY_TEMPLATE}/${templateID}`, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteCompanyTemplate = async (templateID: $TSFixMe) => {
  try {
    await axios.delete(`${COMPANY_TEMPLATE}/${templateID}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyTemplates = async (type?: CompanyMessageType) => {
  try {
    const params: { type?: string } = {};

    if (type) params.type = type;

    const { data } = await axios.get<{ data: CompanyTemplateWithAssociate[] }>(COMPANY_TEMPLATE, {
      params
    });
    return data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const getCandidateTemplates = async () => {
  try {
    const { data } = await axios.get(CANDIDATE_TEMPLATE);
    return data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const createCandidateTemplate = async (templateDetails: TemplateDetails) => {
  try {
    await axios.post(CANDIDATE_TEMPLATE, templateDetails);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateCandidateTemplate = async (
  templateID: number,
  templateDetails: TemplateDetails
) => {
  try {
    const params = { ...templateDetails };
    await axios.put(`${CANDIDATE_TEMPLATE}/${templateID}`, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteCandidateTemplate = async (templateID: $TSFixMe) => {
  try {
    await axios.delete(`${CANDIDATE_TEMPLATE}/${templateID}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const createDeclineReason = async (reasonDetails: $TSFixMe) => {
  try {
    const { data } = await axios.post(COMPANY_DECLINE_REASONS, reasonDetails);
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateDeclineReason = async (reasonID: $TSFixMe, reasonDetails: $TSFixMe) => {
  try {
    const params = { ...reasonDetails };
    await axios.put(`${COMPANY_DECLINE_REASONS}/${reasonID}`, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteDeclineReason = async (reasonID: $TSFixMe) => {
  try {
    await axios.delete(`${COMPANY_DECLINE_REASONS}/${reasonID}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyDeclineReasons = async () => {
  try {
    const { data } = await axios.get<{
      data: SimplifiedDeclineReason[];
    }>(COMPANY_DECLINE_REASONS);
    return data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const getCompanyBillingInfo = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_BILLING_INFO);
    return data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const createPosition = async (position: $TSFixMe) => {
  try {
    const positionData = { ...position };
    positionData.visaSponsorship = convertVisaObjectToCode(positionData.visaSponsorship);
    positionData.timezones = parseTimezoneObject(positionData.timezones);

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(POSITION, positionData);
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deletePosition = async (listingID: number) => {
  try {
    await axios.delete(`${POSITION}/${listingID}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const pausePosition = async (
  listingID: number,
  feedback?: $TSFixMe,
  candidateIDs?: $TSFixMe
): Promise<{ status: string; message?: string }> => {
  try {
    const params = { feedback, candidateIDs };
    await axios.post(`${POSITION}/pause/${listingID}`, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const resumePosition = async (listingID: number) => {
  try {
    await axios.post(`${POSITION}/resume/${listingID}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const editPosition = async (position: $TSFixMe, listingID: number) => {
  try {
    const positionData = { ...position };
    positionData.visaSponsorship = convertVisaObjectToCode(positionData.visaSponsorship);
    if (positionData.timezones) {
      positionData.timezones = parseTimezoneObject(positionData.timezones);
    }

    await axios.put(`${POSITION}/${listingID}`, positionData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateSubscription = async (
  companyID: $TSFixMe,
  subscriptionPlan: $TSFixMe,
  expiryDate: $TSFixMe,
  onboardType: $TSFixMe,
  totalHiringSeat: $TSFixMe,
  urlPath: string
) => {
  try {
    const isEnterprisePerUserPlan = subscriptionPlan === PRICING_OPTIONS.midMarket.value;
    const params = { companyID, subscriptionPlan, expiryDate, onboardType, urlPath };

    if (isEnterprisePerUserPlan) (params as $TSFixMe).totalHiringSeat = totalHiringSeat;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(ADMIN_UPDATE_SUBSCRIPTION, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success', message: (data as $TSFixMe).data.message };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updatePopularityStatus = async (companyID: $TSFixMe, popular: $TSFixMe) => {
  try {
    const params = { companyID, popular };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.put(ADMIN_UPDATE_POPULAR_COMPANY, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success', message: (data as $TSFixMe).data.message };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateCovidResponder = async (companyID: $TSFixMe, covidShow: $TSFixMe) => {
  try {
    const params = { companyID, covidShow };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const data = await axios.put(ADMIN_UPDATE_COVID_RESPONDER, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return { status: 'success', message: data ? (data.data as $TSFixMe).message : '' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updatePeoplePopularityStatus = async (
  companyAccountEmail: $TSFixMe,
  popular: $TSFixMe
) => {
  try {
    const params = { companyAccountEmail, popular };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.put(ADMIN_UPDATE_POPULAR_COMPANY_USER, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success', message: (data as $TSFixMe).data.message };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const cloneCompanyAccount = async (companyID: $TSFixMe, email: $TSFixMe) => {
  try {
    const { data } = await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_CLONE_COMPANY_ACCOUNT,
      { companyID, email },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success', message: (data as $TSFixMe).data.message };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateCompanySuspensionStatus = async (
  companyID: number,
  suspensionStatus: CompanySuspensionStatus,
  autoEnforce?: boolean,
  failedInvoicePaymentDate?: Date | string
) => {
  try {
    await axios.post(
      ADMIN_UPDATE_COMPANY_SUSPENSION_STATUS,
      { companyID, status: suspensionStatus, autoEnforce, failedInvoicePaymentDate },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateStripeDetails = async (
  companyID: $TSFixMe,
  stripeID: $TSFixMe,
  stripeSubscriptionID = '',
  stripeRegion: StripeRegion
) => {
  try {
    const { data } = await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_COMPANY_STRIPE,
      { companyID, stripeID, stripeSubscriptionID, stripeRegion },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success', message: (data as $TSFixMe).data.message };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const generateStripeBankTransfer = async (payload: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(ADMIN_COMPANY_STRIPE_TRANSFER, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success', message: data.data.message, invoiceURL: data.data.invoiceURL };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const generateStripeBankDetails = async (payload: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(ADMIN_COMPANY_STRIPE_DETAILS, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return {
      status: 'success',
      accountName: data.data.accountName,
      accountNumber: data.data.accountNumber,
      sortCode: data.data.sortCode
    };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminCreateCompany = async (
  email: string,
  name: string,
  stripeRegion: StripeRegion
) => {
  try {
    const { data } = await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_COMPANY_CREATE,
      { email, name, stripeRegion },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success', message: (data as $TSFixMe).data.message };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminCandidateInviteCode = async (
  email: $TSFixMe,
  payload: $TSFixMe,
  expirySeconds: $TSFixMe,
  taggingLevelOne: $TSFixMe,
  endpoint: $TSFixMe,
  code: $TSFixMe
) => {
  try {
    const { data } = await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_CANDIDATE_INVITE,
      { email, payload, expirySeconds, taggingLevelOne, endpoint, code },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success', code: (data as $TSFixMe).data.code };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const createCandidateInviteSource = async (campaign: $TSFixMe, source: $TSFixMe) => {
  try {
    const params = { campaign, source };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_INVITE_SOURCE, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateDatabase = async (
  environment: $TSFixMe,
  dropHubspotSchema: boolean,
  dropStripeSchema: boolean,
  snapshotType: 'automated' | 'manual'
) => {
  try {
    await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_UPDATE_DATABASE,
      { environment, dropHubspotSchema, dropStripeSchema, snapshotType },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminUpdateUserRole = async (admin_id: $TSFixMe, role: $TSFixMe) => {
  try {
    const params = { admin_id, role };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(ADMIN_UPDATE_USER_ROLE, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminDeleteUser = async (admin_id: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.delete(ADMIN_UPDATE_USER_ROLE, {
      data: { admin_id }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminBlockOrUnblockCandidate = async (params: {
  shouldBlock: boolean;
  email: string;
}) => {
  try {
    await axios.post(ADMIN_BLOCK_JOB_TYPE!, { ...params });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminAddAccount = async (
  email: $TSFixMe,
  password: $TSFixMe,
  role: $TSFixMe,
  name: $TSFixMe
) => {
  try {
    const params = { email, password, role, name };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_ADD_ACCOUNT, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminVerifyUser = async (
  userID: $TSFixMe,
  userType: $TSFixMe,
  profileState: $TSFixMe
) => {
  try {
    const params = { userID, userType, ...profileState };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_VERIFY_USER, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminUpdateCandidateDetails = async (
  whereObject: $TSFixMe,
  updateObject: $TSFixMe
) => {
  try {
    const params = Object.assign(whereObject, updateObject);

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(ADMIN_CANDIDATE_DETAILS, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminTriggerCandidateAccountCreated = async (candidate_email: $TSFixMe) => {
  try {
    await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_CANDIDATE_ACCOUNT_CREATED,
      { candidate_email },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminTriggerPassiveReengageEmail = async (email: $TSFixMe) => {
  try {
    await axios.post(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ADMIN_CANDIDATE_REACTIVATE_EMAIL,
      { email },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const blockPassiveEmailChaser = async (email: $TSFixMe) => {
  try {
    await axios.post(
      `${ADMIN_CANDIDATE_REACTIVATE_EMAIL}-cancel`,
      { email },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminResetMFA = async (paylaod: $TSFixMe) => {
  try {
    const {
      data: { status }
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    } = await axios.post(ADMIN_RESET_MFA, paylaod);
    return status;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deletePlatformUser = async (userType: $TSFixMe, email: $TSFixMe, id: $TSFixMe) => {
  try {
    const params = { userType };

    if (email) (params as $TSFixMe).email = email;
    if (id) (params as $TSFixMe).userID = id;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.delete(ADMIN_USER_DELETE, {
      data: params
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminHideCompanyUser = async (
  email: $TSFixMe,
  invisible: $TSFixMe,
  softDelete: $TSFixMe
) => {
  try {
    const params = { email, invisible, softDelete };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(ADMIN_HIDE_COMPANY_ACCOUNT, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminVerifyUserViaEmail = async (
  email: $TSFixMe,
  verify: $TSFixMe,
  invisible: $TSFixMe
) => {
  try {
    const params = { email, verify };

    if (invisible) (params as $TSFixMe).invisible = invisible;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_VERIFY_USER, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const triggerFrontendNotifications = async (event: $TSFixMe, message_data: $TSFixMe) => {
  try {
    const params = { event, message_data };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_TECH_FRONTEND_MESSAGE, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/**
 * @param {string} email
 * @param {string} email_nature
 * @param {boolean} subscribe
 * @param {string} user_type
 * @param {string} [config_value] JSON string
 */
export const adminUpdateEmailSubscription = async (
  email: $TSFixMe,
  email_nature: $TSFixMe,
  subscribe: $TSFixMe,
  user_type: $TSFixMe,
  config_value: $TSFixMe
) => {
  /* istanbul ignore next */
  try {
    const params = {
      email,
      email_nature,
      subscribe,
      ...(config_value && { config_value: JSON.parse(config_value) })
    };
    if (user_type === 'company') {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(ADMIN_UPDATE_COMPANY_EMAIL_SUBS, params);
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(ADMIN_UPDATE_CANDIDATE_EMAIL_SUBS, params);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getDemoAccounts = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(ADMIN_COMPANY_DEMO);
    return (data as $TSFixMe).data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const addDemoAccount = async (
  email: $TSFixMe,
  company_role: $TSFixMe,
  custom_message: $TSFixMe,
  validity_minute: $TSFixMe
) => {
  try {
    const params = { email, company_role, custom_message, validity_minute };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_COMPANY_DEMO, params);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateDemoAccount = async (
  company_account_id: $TSFixMe,
  validity_minute: $TSFixMe
) => {
  try {
    const params = { company_account_id, validity_minute };

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(ADMIN_COMPANY_DEMO, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const addPremInterview = async (
  interviewDate: $TSFixMe,
  candidateID: number,
  companyID: $TSFixMe,
  stage: $TSFixMe,
  listingID: number,
  description: $TSFixMe
) => {
  try {
    const params = { interviewDate, candidateID, companyID, stage, listingID, description };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_ADD_INTERVIEW, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const postRevenue = async (amount: $TSFixMe, note: $TSFixMe) => {
  try {
    const params = { amount: parseInt(amount, 10), note };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_REVENUE, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateInternalStats = async (category: $TSFixMe, value: $TSFixMe, note: $TSFixMe) => {
  try {
    const params = { category, value };

    if (category === 'featurePoint') (params as $TSFixMe).note = note;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_INTERNAL_STATS, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateCompanyManager = async (companyID: $TSFixMe, companyManager: $TSFixMe) => {
  try {
    const params = { companyID, companyManager };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_COMPANY_MANAGER, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const updateSkills = async (
  skill_name: $TSFixMe,
  skill_type: $TSFixMe,
  primary_skill_name: $TSFixMe
) => {
  try {
    const params = { skill_name, skill_type, primary_skill_name };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_LIST_OF_SKILLS, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteSkills = async (
  skill_name: $TSFixMe,
  skill_type: $TSFixMe,
  primary_skill_name: $TSFixMe
) => {
  try {
    const params = { skill_name, skill_type, primary_skill_name };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_DELETE_SKILLS, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getListOfSkills = async <C extends SkillRetrievalKey>(
  category: C
): Promise<{ data?: Skills<C> }> => {
  try {
    const { data } = await axios.get<{ data: Skills<C> }>(
      `${ADMIN_LIST_OF_SKILLS}?category=${category}`
    );
    return data;
  } catch (error) {
    return {};
  }
};

/* istanbul ignore next */
export const getRecommendedSkills = async (
  name: $TSFixMe,
  source: $TSFixMe,
  output: $TSFixMe,
  type?: string
) => {
  try {
    const params = { name, source, output, type };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(PUBLIC_SKILL_RECOMMENDATION, { params });
    return data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const getCompanyRoles = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_ROLES);
    return (data as $TSFixMe).data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const addHiringSeat = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_SEAT);
    return { status: 'success' };
  } catch (error) {
    return { status: 'failure' };
  }
};

/* istanbul ignore next */
export const removeHiringSeat = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.delete(COMPANY_SEAT);
    return { status: 'success' };
  } catch (error) {
    return { status: 'failure' };
  }
};

/* istanbul ignore next */
export const companyUserProfileUpdate = async (
  profile: CompanyAccountInterface & { verificationCode?: string }
) => {
  try {
    await axios.put(profile.verificationCode ? PUBLIC_COMPANY_USER : COMPANY_USER, profile, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyDeletePersonalAccount = async () => {
  try {
    await axios.delete(COMPANY_USER);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyUpdateUserRole = async (accountID: $TSFixMe, companyRole: $TSFixMe) => {
  try {
    const params = { accountID, companyRole };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(COMPANY_TEAM_MEMBERS, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyDeleteUser = async (accountID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.delete(COMPANY_TEAM_MEMBERS, { data: { accountID } });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyAddAccount = async (
  name: $TSFixMe,
  email: $TSFixMe,
  companyRole: $TSFixMe,
  jobTitle: $TSFixMe
) => {
  try {
    const params = { name, email, companyRole, jobTitle };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(COMPANY_TEAM_MEMBERS, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminAddCompanyUser = async (
  companyID: $TSFixMe,
  accountName: $TSFixMe,
  accountEmail: $TSFixMe,
  companyRole: $TSFixMe,
  jobTitle: $TSFixMe
) => {
  try {
    const params = { companyID, accountName, accountEmail, companyRole, jobTitle };
    await axios.post(ADMIN_COMPANY_USERS, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminCompanyUserInvite = async (accountID: $TSFixMe) => {
  try {
    await axios.post(`${ADMIN_COMPANY_USERS}/activate/${accountID}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const adminCompanyUserRemove = async (accountID: $TSFixMe) => {
  try {
    await axios.post(`${ADMIN_COMPANY_USERS}/done/${accountID}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const adminGetCompanyRoles = async () => {
  try {
    const { data } = await axios.get<{ data: CompanyRoleOption[] }>(`${ADMIN_COMPANY_USERS}/role`);
    return data.data;
  } catch (error) {
    return [];
  }
};

/* istanbul ignore next */
export const adminGetCompanyUsers = async () => {
  try {
    const { data } = await axios.get<{
      data: Array<Required<CompanyAccountInterface> & { companyName: string; inviteSent: boolean }>;
    }>(ADMIN_COMPANY_USERS);
    return data.data;
  } catch (error) {
    return [];
  }
};

export const setUserPassword = async (email_verification_code: $TSFixMe, password: $TSFixMe) => {
  try {
    const params = { email_verification_code, password };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(PUBLIC_USER_SET_PASSWORD, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const savePartnershipPage = async (id: $TSFixMe, partnershipDetails: $TSFixMe) => {
  try {
    if (id) {
      await axios.put(`${ADMIN_PARTNERSHIP}/${id}`, partnershipDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(ADMIN_PARTNERSHIP, partnershipDetails);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deletePartnershipPage = async (partnershipID: $TSFixMe) => {
  try {
    await axios.delete(`${ADMIN_PARTNERSHIP}/${partnershipID}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getPartnershipPages = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const data = await axios.get(PUBLIC_PARTNERSHIP);
    return data.data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const getAdminPartnershipPages = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const data = await axios.get(ADMIN_PARTNERSHIP);
    return data.data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const getPartnership = async (id: $TSFixMe) => {
  try {
    const data = await axios.get(`${PUBLIC_PARTNERSHIP}/${id}`);
    return data.data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getAdminPartnershipDetails = async (id: $TSFixMe) => {
  try {
    const data = await axios.get(`${ADMIN_PARTNERSHIP}/${id}`);
    return data.data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const saveCheckoutPage = async (
  pageDetails: $TSFixMe,
  mode: $TSFixMe,
  urlPath: $TSFixMe
) => {
  try {
    if (mode) {
      await axios.put(`${ADMIN_PRICING}/${mode}/${urlPath}`, pageDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(ADMIN_PRICING, pageDetails);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteCheckoutPage = async (mode: $TSFixMe, urlPath: $TSFixMe) => {
  try {
    await axios.delete(`${ADMIN_PRICING}/${mode}/${urlPath}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCheckoutPage = async (
  urlPath: string
): Promise<{ data: PricingPlanClean } | { [key: string]: any }> => {
  try {
    const mode = PRICING_MODE;
    const data = await axios.get(`${PUBLIC_PRICING}/${mode}/${urlPath}`);

    return data.data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getAdminCheckoutPages = async (): Promise<
  { data: PricingPlanClean[]; status: 'success'; message: undefined } | { [key: string]: any }
> => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const data = await axios.get(ADMIN_PRICING);
    return data.data;
  } catch (error: any) {
    return { data: [], status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getAdminCheckoutDetails = async (mode: $TSFixMe, urlPath: $TSFixMe) => {
  try {
    const data = await axios.get(`${ADMIN_PRICING}/${mode}/${urlPath}`);
    return data.data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getSubscriptionPlans = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const data = await axios.get(ADMIN_SUBSCRIPTION_PLAN, { params: { only_full_access: true } });
    return data.data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const saveCompanyStory = async (id: $TSFixMe, storyDetails: $TSFixMe) => {
  try {
    if (id) {
      storyDetails.storyID = id;
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.put(ADMIN_STORY, storyDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await axios.post(ADMIN_STORY, storyDetails);
    }
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const deleteCompanyStory = async (storyID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.delete(ADMIN_STORY, {
      data: { storyID }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getAdminCompanyStory = async (
  id: number
): Promise<{ data: AdminCustomerStory } | { status: 'failure'; message: string; data: null }> => {
  try {
    const data = await axios.get<{ data: AdminCustomerStory }>(`${ADMIN_STORY}/${id}`);
    return data.data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message, data: null };
  }
};

/* istanbul ignore next */
export const createTestimonial = async (testimonialParams: $TSFixMe) => {
  try {
    const {
      firstName,
      lastName,
      jobTitle,
      userType,
      testimonial,
      photoURL,
      companyName,
      companyID,
      videoTestimonialURL
    } = testimonialParams;
    const params = {
      firstName,
      lastName,
      jobTitle,
      userType,
      testimonial,
      photoURL,
      videoTestimonialURL,
      testimonialType: 'text'
    };

    if (videoTestimonialURL) params.testimonialType = 'video';

    if (userType === 'company' || userType === 'company-scaling') {
      (params as $TSFixMe).companyID = companyID;
    } else if (userType === 'candidate') (params as $TSFixMe).companyName = companyName;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.post(ADMIN_TESTIMONIAL, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getTestimonials = async (
  userType: UserType,
  page: number,
  testimonialType: TestimonialType,
  companySizeType?: CompanySizeType,
  pageSize?: number
) => {
  const requestId = `${userType}_testimonial_${testimonialType}`;
  try {
    const params: {
      userType: UserType;
      page: number;
      testimonialType: TestimonialType;
      companySizeType?: CompanySizeType;
      pageSize?: number;
    } = { userType, page, testimonialType };

    if (userType === UserType.Company && companySizeType) params.companySizeType = companySizeType;
    if (pageSize !== undefined) params.pageSize = pageSize;

    const { data } = await axios.get<{
      data: {
        resultsCount: number;
        values: Testimonial[];
      };
      status: string;
    }>(`${PUBLIC_TESTIMONIAL}`, { params });

    data.data.values = addDimensionsOnImages(data.data.values, {
      type: 'logo',
      dimensions: getImageDimensions('no-crop-logo-small')
    });
    return data.data;
  } catch (error) {
    /* istanbul ignore next */
    if (axios.isCancel(error)) console.log(`${requestId} cancelled`);

    return { values: [], resultsCount: 0 };
  }
};

/* istanbul ignore next */
export const validateLeverAPIKey = async (apiToken: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_LEVER_API, { apiToken });
    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failute', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const initGreenhouse = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_INIT_GREENHOUSE);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const revokeGreenhouse = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_REVOKE_GREENHOUSE);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const initWorkable = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_INIT_WORKABLE);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const revokeWorkable = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_REVOKE_WORKABLE);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const initLever = async () => {
  try {
    const { data } = await axios.get(COMPANY_INIT_LEVER);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const revokeLever = async () => {
  try {
    const { data } = await axios.post(COMPANY_REVOKE_LEVER);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const initMerge = async (integration: string | null) => {
  try {
    const params = { integration };
    const { data } = await axios.get(`${COMPANY_MERGE_INTEGRATION}/init`, { params });
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const revokeMerge = async () => {
  try {
    const { data } = await axios.put(`${COMPANY_MERGE_INTEGRATION}/revoke`);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const initNotion = async (): Promise<{
  data?: { link: string };
  status: string;
  message: string;
}> => {
  try {
    const { data } = await axios.get(COMPANY_INIT_NOTION);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const revokeNotion = async () => {
  try {
    const { data } = await axios.post(COMPANY_REVOKE_NOTION);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
// @ts-expect-error TS(7006) FIXME: Parameter 'publicToken' implicitly has an 'any' ty... Remove this comment to see the full error message
export const sendMergePublicToken = async publicToken => {
  try {
    const { data } = await axios.put(`${COMPANY_MERGE_INTEGRATION}/exchange-tokens`, {
      publicToken
    });
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getWorkableAccounts: () => Response<{
  workableStatus?: string;
  accounts?: { name: string; subdomain: string }[];
  link?: any;
}> = async () => {
  try {
    const { data } = await axios.get(COMPANY_WORKABLE_ACCOUNTS);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const postWorkableAccounts: (subdomain: string) => Response<{ [key: string]: any }> =
  async subdomain => {
    try {
      const { data } = await axios.post(COMPANY_WORKABLE_ACCOUNTS, { subdomain });
      return { status: 'success', data };
    } catch (error: any) {
      return { status: 'failure', message: error?.response?.data?.message };
    }
  };

/* istanbul ignore next */
export const getATSJobs: () => Response<MergeJobItemInterface[]> = async () => {
  try {
    const { data } = await axios.get(COMPANY_ATS_JOBS);
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getATSJobDetails = async (atsPositionID: $TSFixMe) => {
  try {
    const { data } = await axios.get(`${COMPANY_ATS_JOBS}/${atsPositionID}`);

    const parsedData = parseATSJobDetails((data as $TSFixMe).data);

    return { data: parsedData };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const sendCandidateToATS: (
  candidateID: number,
  atsPositionID: string,
  applicationID: number
) => Promise<
  | { [key: string]: any; data: { [key: string]: any } }
  | { status?: string; message?: { [key: string]: any }; data?: { [key: string]: any } }
> = async (candidateID, atsPositionID, applicationID) => {
  try {
    const params = { candidateID, atsPositionID, applicationID };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(COMPANY_SEND_CANDIDATE_ATS, params);
    return data as { [key: string]: any };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getCompanyReactivationPlan = async () => {
  try {
    const { data } = await axios.get<{ data: { subscriptionPricing: SubscriptionPricing } }>(
      COMPANY_REACTIVATION_PLAN
    );
    return data.data;
  } catch (error: any) {
    return {
      status: 'failure',
      message: error?.response?.data?.message,
      subscriptionPricing: {} as SubscriptionPricing
    };
  }
};

/* istanbul ignore next */
export const getPaymentCards = async () => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(COMPANY_PAYMENT_CARDS);
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const companyReactivateSubscription = async (
  urlPath: $TSFixMe,
  pricingCountry: $TSFixMe,
  cardID: $TSFixMe
) => {
  try {
    const mode = PRICING_MODE;
    const params = { urlPath, mode, pricingCountry, cardID };
    const { data } = await axios.post(COMPANY_REACTIVATE_SUBSCRIPTION!, params);
    return { status: 'success', ...data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const companySortByOptions = [
  { label: 'Last active', value: CompanySearchOrderEnum.LastActive },
  { label: 'Last joined', value: CompanySearchOrderEnum.RecentlyAdded },
  { label: 'Availability', value: CompanySearchOrderEnum.NoticePeriod },
  { label: 'Recommended', value: CompanySearchOrderEnum.RecommendedScore, badge: 'new' }
];

export const peopleSortByOptions = [
  { label: 'Most recently added', value: SearchSortByEnum.PeopleStartDate },
  { label: 'Last active', value: SearchSortByEnum.PeopleLastActive },
  { label: 'Most responsive', value: SearchSortByEnum.PeopleResponseRate }
];

export const positionSortByOptions = [
  { label: 'Most recently added', value: SearchSortByEnum.ListingDatePosted },
  { label: 'Last active', value: SearchSortByEnum.ListingLastActiveResponseRate },
  { label: 'Most responsive', value: SearchSortByEnum.ListingResponseRate }
];

export const techHubSearchViewTypes = [
  { label: 'Articles', value: SearchCategoryViewTypeEnum.Article },
  { label: 'Interviews', value: SearchCategoryViewTypeEnum.Story },
  { label: 'Recordings', value: SearchCategoryViewTypeEnum.EventRecording },
  { label: 'Collections', value: SearchCategoryViewTypeEnum.Collection }
];

export const hiringInsightsViewTypes = [
  { label: 'Articles', value: SearchCategoryViewTypeEnum.Article },
  { label: 'Videos', value: SearchCategoryViewTypeEnum.Story }
];

export const findingWorkSearchViewTypes = [
  { label: 'Positions', value: SearchCategoryViewTypeEnum.Listing },
  { label: 'Companies', value: SearchCategoryViewTypeEnum.Company },
  { label: 'People', value: SearchCategoryViewTypeEnum.People }
];

export const liveLeadsViewTypes = [
  { label: 'Positions', value: SearchCategoryViewTypeEnum.Listing },
  { label: 'Companies', value: SearchCategoryViewTypeEnum.Company }
];

export const limitedJobCategoryOptions = [
  {
    category: 'Development',
    options: [
      {
        value: 'Back End',
        label: 'Back End Developer',
        tags: ['Back End Developer', 'Web Developer'],
        popularSkill: 'Node.js'
      },
      {
        value: 'Front End',
        label: 'Front End Developer',
        tags: ['Front End Developer', 'UI Developer', 'Web Developer'],
        popularSkill: 'React'
      },
      {
        value: 'Full Stack',
        label: 'Full Stack Developer',
        tags: ['Full Stack Developer', 'Web Developer'],
        popularSkill: 'Typescript'
      },
      {
        value: 'Mobile Developer',
        label: 'Mobile Developer',
        tags: ['Mobile Developer', 'Android Developer', 'iOS Developer'],
        popularSkill: 'Java'
      }
    ]
  },
  {
    category: 'Infrastructure',
    options: [
      {
        value: 'DevOps Engineer',
        label: 'DevOps Engineer',
        tags: ['DevOps Engineer', 'DevOps Developer', 'Cloud'],
        popularSkill: 'AWS'
      },
      {
        value: 'Site Reliability Engineer',
        label: 'Site Reliability Engineer',
        tags: ['Site Reliability Engineer', 'Cloud'],
        popularSkill: 'Linux'
      }
    ]
  },
  {
    category: 'Data',
    options: [
      {
        value: 'Data Engineer',
        label: 'Data Engineer',
        tags: ['Data Engineer'],
        popularSkill: 'Python'
      },
      {
        value: 'Data Scientist',
        label: 'Data Scientist',
        tags: ['Data Scientist'],
        popularSkill: 'Pandas'
      },
      {
        value: 'Data/Insight Analyst',
        label: 'Data/Insight Analyst',
        tags: ['Data/Insight Analyst'],
        popularSkill: 'Tableau'
      }
    ]
  },
  {
    category: 'Product & Design',
    options: [
      {
        value: 'Product Manager',
        label: 'Product Manager',
        tags: ['Product Manager', 'Head of Product', 'Product Owner', 'Product Lead'],
        popularSkill: 'User Focused'
      },
      {
        value: 'Product Owner',
        label: 'Product Owner',
        tags: ['Product Owner', 'Head of Product', 'Product Owner', 'Product Lead'],
        popularSkill: 'User Focused'
      },
      {
        value: 'UI/UX Designer',
        label: 'UI/UX Designer',
        tags: [
          'UI/UX Designer',
          'UXD',
          'UI/UX Hybrid',
          'UX Desiger',
          'User Experience',
          'Web Designer',
          'Product Designer'
        ],
        popularSkill: 'UI/UX'
      },
      {
        value: 'UI Designer',
        label: 'UI Designer',
        tags: ['UI Designer', 'User Interface', 'Web Designer', 'Product Designer'],
        popularSkill: 'Prototyping'
      },
      {
        value: 'UX Designer',
        label: 'UX Designer',
        tags: ['UX Designer', 'UXD', 'User Experience', 'Web Designer', 'Product Designer'],
        popularSkill: 'User Research'
      }
    ]
  },
  {
    category: 'QA',
    options: [
      {
        value: 'QA Automation Tester',
        label: 'QA Automation Tester',
        tags: ['QA Automation Tester', 'Automation Tester', 'QA Tester'],
        popularSkill: 'Selenium'
      }
    ]
  }
];

export const getJobCategoryType = (category: $TSFixMe) => {
  if (
    category === 'Development' ||
    category === 'QA' ||
    category === 'Infrastructure' ||
    category === 'Support' ||
    category === 'Data'
  ) {
    return 'tech';
  } else return 'product';
};

export const getSeniorityOptions = (): {
  label: keyof typeof Seniority;
  value: Seniority;
  tooltip: string;
}[] => {
  return [
    { label: 'Entry', value: Seniority.Entry, tooltip: 'Graduate / Just starting out' },
    { label: 'Junior', value: Seniority.Junior, tooltip: '1-2 years of experience' },
    { label: 'Mid', value: Seniority.Mid, tooltip: 'Mid-level' },
    { label: 'Senior', value: Seniority.Senior, tooltip: 'Highly proficient, not managing' },
    { label: 'Lead', value: Seniority.Lead, tooltip: 'Highly proficient, some managing' },
    { label: 'Leadership', value: Seniority.Leadership, tooltip: 'Heads of, Managerial & C-Level' }
  ];
};

export const getCompanyNames = async (category?: CompanyCategory) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(LIST_OF_COMPANY_NAMES, { params: { category } });
    return data;
  } catch (error: any) {
    return { data: [] };
  }
};

export const getSalaryOptions = () => {
  return [
    15000,
    20000,
    25000,
    30000,
    35000,
    40000,
    45000,
    50000,
    55000,
    60000,
    65000,
    70000,
    75000,
    80000,
    85000,
    90000,
    95000,
    100000,
    105000,
    110000,
    115000,
    120000,
    125000,
    130000,
    135000,
    140000,
    145000,
    150000,
    155000,
    160000,
    165000,
    170000,
    175000,
    180000,
    185000,
    190000,
    195000,
    200000,
    205000,
    210000,
    215000,
    220000,
    225000,
    230000,
    235000,
    240000,
    245000,
    250000,
    260000,
    270000,
    280000,
    290000,
    300000,
    310000,
    320000,
    330000,
    340000,
    350000,
    360000,
    370000,
    380000,
    390000,
    400000,
    410000,
    420000,
    430000,
    440000,
    450000,
    460000,
    470000,
    480000,
    490000,
    500000,
    HIGHEST_SALARY_BAND_VALUE
  ];
};

export const getJobTypeOptions = (type?: string): JobTypeOption[] => {
  if (type === 'company') {
    return [
      { label: JOB_TYPES.PERMANENT, value: JOB_TYPES.PERMANENT },
      {
        label: JOB_TYPES.FTC,
        value: JOB_TYPES.FTC,
        tooltip: 'Employment contract with a fixed end date.'
      },
      {
        label: 'Part-time',
        value: JOB_TYPES.PART_TIME,
        tooltip: 'Employment contract with reduced hours.'
      },
      {
        label: JOB_TYPES.FREELANCE_CONTRACT,
        value: JOB_TYPES.FREELANCE_CONTRACT,
        disabled: true,
        tooltip: 'cord does not support individual contractor positions.'
      }
    ];
  } else {
    return [
      { label: JOB_TYPES.PERMANENT, value: JOB_TYPES.PERMANENT },
      {
        label: 'Part-time',
        value: JOB_TYPES.PART_TIME,
        tooltip: 'Looking for work as an employee with reduced hours.'
      },
      {
        label: JOB_TYPES.FTC,
        value: JOB_TYPES.FTC,
        tooltip: 'Looking for work as an employee with a fixed end date.'
      },
      {
        label: JOB_TYPES.FREELANCE_CONTRACT,
        value: JOB_TYPES.FREELANCE_CONTRACT,
        tooltip: `cord does not currently support ${JOB_TYPES.FREELANCE_CONTRACT} positions. If you're looking for ${JOB_TYPES.FREELANCE_CONTRACT} work your ability to message companies on cord will be temporarily disabled.`
      }
    ];
  }
};

export const getWorkEligibilityOptions = (tempOptionDesc = 'Include temp. eligible') => [
  {
    label: 'UK',
    value: { type: 'permanent', location: 'UK' },
    description: {
      label: 'UK (temp)',
      value: { type: 'temporary', location: 'UK' },
      description: tempOptionDesc
    }
  },
  {
    label: 'US',
    value: { type: 'permanent', location: 'US' },
    description: {
      label: 'US (temp)',
      value: { type: 'temporary', location: 'US' },
      description: tempOptionDesc
    }
  },
  {
    label: 'EU',
    value: { type: 'permanent', location: 'EU' },
    description: {
      label: 'EU (temp)',
      value: { type: 'temporary', location: 'EU' },
      description: tempOptionDesc
    }
  },
  { label: 'Non-UK/US/EU', value: { type: 'permanent', location: 'other' } }
];

export const workEligibilityOtherPlacesOption = {
  location: WorkEligibilityLocation.Other,
  type: WorkEligibilityType.Permanent
};

export const workEligibilityLocations = [
  { label: 'UK', value: WorkEligibilityLocation.UK },
  { label: 'US', value: WorkEligibilityLocation.US },
  { label: 'EU', value: WorkEligibilityLocation.EU },
  { label: 'Other places', value: WorkEligibilityLocation.Other }
];

export const visaEligibilityOrigins = [
  { label: VISA_SPONSORSHIP_ORIGINS.ALL, value: VISA_SPONSORSHIP_ORIGINS.ALL, country: '' },
  { label: VISA_SPONSORSHIP_ORIGINS.UK, value: 'UK', country: 'UK' },
  { label: VISA_SPONSORSHIP_ORIGINS.EU, value: 'EU', country: 'EU' },
  { label: VISA_SPONSORSHIP_ORIGINS.US, value: 'US', country: 'US' },
  { label: 'Other people', value: VISA_SPONSORSHIP_ORIGINS.OTHER, country: '' }
];

export const visaDetailsByCode = {
  uk_to_eu: { origin: 'UK', destination: 'EU' },
  uk_to_us: { origin: 'UK', destination: 'US' },
  eu_to_uk: { origin: 'EU', destination: 'UK' },
  eu_to_us: { origin: 'EU', destination: 'US' },
  us_to_uk: { origin: 'US', destination: 'UK' },
  us_to_eu: { origin: 'US', destination: 'EU' },
  all_to_eu: { origin: VISA_SPONSORSHIP_ORIGINS.ALL, destination: 'EU' },
  all_to_uk: { origin: VISA_SPONSORSHIP_ORIGINS.ALL, destination: 'UK' },
  all_to_us: { origin: VISA_SPONSORSHIP_ORIGINS.ALL, destination: 'US' },
  other_to_uk: { origin: VISA_SPONSORSHIP_ORIGINS.OTHER, destination: 'UK' },
  other_to_us: { origin: VISA_SPONSORSHIP_ORIGINS.OTHER, destination: 'US' },
  other_to_eu: { origin: VISA_SPONSORSHIP_ORIGINS.OTHER, destination: 'EU' }
};

export const visaDetailsByOrigin = {
  UK: { EU: 'uk_to_eu', US: 'uk_to_us' },
  EU: { UK: 'eu_to_uk', US: 'eu_to_us' },
  US: { UK: 'us_to_uk', EU: 'us_to_eu' },
  [VISA_SPONSORSHIP_ORIGINS.OTHER]: { UK: 'other_to_uk', EU: 'other_to_eu', US: 'other_to_us' },
  [VISA_SPONSORSHIP_ORIGINS.ALL]: { UK: 'all_to_uk', EU: 'all_to_eu', US: 'all_to_us' }
};

export const getCurrencyOptions = () => {
  return ['GBP', 'EUR', 'USD'];
};

export const getGenderOptions = () => {
  return ['Male', 'Female', 'Genderqueer/Non-Binary', 'Other', 'Prefer not to disclose'];
};

export const getInterviewDayOptions = () => {
  return [
    { label: 'Monday', value: 'mon', code: 1 },
    { label: 'Tuesday', value: 'tue', code: 2 },
    { label: 'Wednesday', value: 'wed', code: 3 },
    { label: 'Thursday', value: 'thu', code: 4 },
    { label: 'Friday', value: 'fri', code: 5 },
    { label: 'Mon-Fri', value: 'mon-fri', code: 5 }
  ];
};

export const getNoticeTimeOptions = () => {
  return [
    { label: 'Not on the same day', value: 99999 },
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '1 hour', value: 60 },
    { label: '2 hours', value: 120 },
    { label: '3 hours', value: 180 },
    { label: '4 hours', value: 240 },
    { label: '5 hours', value: 300 },
    { label: '6 hours', value: 360 },
    { label: '1 day', value: 1440 },
    { label: '2 days', value: 2880 },
    { label: '3 days', value: 4320 }
  ];
};

export const getBufferTimeOptions = () => {
  return [
    { label: 'No buffer time', value: 0 },
    { label: '5 minutes', value: 5 },
    { label: '10 minutes', value: 10 },
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '1 hour', value: 60 }
  ];
};

export const getRemoteLabelLocation = () => {
  return [
    { label: 'Anywhere', value: 'Anywhere' },
    { label: 'Continent/Country', value: 'Continent/Country' }
  ];
};

export const getTimezoneLocations = () => {
  return [
    '(UTC -12:00) Eniwetok, Kwajalein',
    '(UTC -11:00) Midway Island, Samoa',
    '(UTC -10:00) Hawaii',
    '(UTC -9:30) Taiohae',
    '(UTC -9:00) Alaska',
    '(UTC -8:00) Pacific Time (US & Canada)',
    '(UTC -7:00) Mountain Time (US & Canada)',
    '(UTC -6:00) Central Time (US & Canada), Mexico City',
    '(UTC -5:00) Eastern Time (US & Canada), Bogota, Lima',
    '(UTC -4:30) Caracas',
    '(UTC -4:00) Atlantic Time (Canada), Caracas, La Paz',
    '(UTC -3:30) Newfoundland',
    '(UTC -3:00) Brazil, Buenos Aires, Georgetown',
    '(UTC -2:00) Mid-Atlantic',
    '(UTC -1:00) Azores, Cape Verde Islands',
    '(UTC) London',
    '(UTC) Dublin',
    '(UTC +1:00) Paris',
    '(UTC +1:00) Barcelona',
    '(UTC +1:00) Berlin',
    '(UTC +1:00) Stockholm',
    '(UTC +2:00) Kaliningrad, South Africa',
    '(UTC +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    '(UTC +3:30) Tehran',
    '(UTC +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    '(UTC +4:30) Kabul',
    '(UTC +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    '(UTC +5:30) Bombay, Calcutta, Madras, New Delhi',
    '(UTC +5:45) Kathmandu, Pokhara',
    '(UTC +6:00) Almaty, Dhaka, Colombo',
    '(UTC +6:30) Yangon, Mandalay',
    '(UTC +7:00) Bangkok, Hanoi, Jakarta',
    '(UTC +8:00) Beijing, Perth, Singapore, Hong Kong',
    '(UTC +8:45) Eucla',
    '(UTC +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    '(UTC +9:30) Adelaide, Darwin',
    '(UTC +10:00) Eastern Australia, Guam, Vladivostok',
    '(UTC +10:30) Lord Howe Island',
    '(UTC +11:00) Magadan, Solomon Islands, New Caledonia',
    '(UTC +11:30) Norfolk Island',
    '(UTC +12:00) Auckland, Wellington, Fiji, Kamchatka',
    '(UTC +12:45) Chatham Islands',
    '(UTC +13:00) Apia, Nukualofa',
    '(UTC +14:00) Line Islands, Tokelau'
  ];
};

export const getTimezoneDistanceIcons = () => {
  return [
    { label: '+/-', value: '+/-' },
    { label: '+', value: '+' },
    { label: '-', value: '-' }
  ];
};

export const getTimezoneHours = () => {
  return [
    { label: '+/- 1 hour', value: 1 },
    { label: '+/- 2 hours', value: 2 },
    { label: '+/- 3 hours', value: 3 },
    { label: '+/- 4 hours', value: 4 },
    { label: '+/- 5 hours', value: 5 },
    { label: '+/- 6 hours', value: 6 },
    { label: '+/- 7 hours', value: 7 },
    { label: '+/- 8 hours', value: 8 },
    { label: '+/- 9 hours', value: 9 },
    { label: '+/- 10 hours', value: 10 },
    { label: '+/- 11 hours', value: 11 },
    { label: '+/- 12 hours', value: 12 }
  ];
};

export const getContinents = () => {
  return ['Europe', 'North America', 'South America', 'Asia', 'Africa', 'Australia and Oceania'];
};

export const EU_COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Norway',
  'Switzerland',
  'Iceland',
  'Liechtenstein '
];

export const getOperatingCountry = (location: string): OperatingCountry => {
  if (location.match(/United States$/i) || location.match(/US$/i) || location.match(/USA$/i)) {
    return OperatingCountry.US;
  }
  if (EU_COUNTRIES.findIndex(country => location.includes(country)) !== -1) {
    return OperatingCountry.EU;
  }

  return OperatingCountry.UK;
};

const monthOptions = [
  { label: 'Jan', value: 0 },
  { label: 'Feb', value: 1 },
  { label: 'Mar', value: 2 },
  { label: 'Apr', value: 3 },
  { label: 'May', value: 4 },
  { label: 'Jun', value: 5 },
  { label: 'Jul', value: 6 },
  { label: 'Aug', value: 7 },
  { label: 'Sep', value: 8 },
  { label: 'Oct', value: 9 },
  { label: 'Nov', value: 10 },
  { label: 'Dec', value: 11 }
];

const yearOptions: { label: number; value: number }[] = [];

const futureYear = new Date().getFullYear() + 10;
for (let i = 0; i < 60; i++) {
  const year = futureYear - i;
  yearOptions.push({ label: year, value: year });
}

export const getMonthOptions = () => {
  return monthOptions;
};

export const getLongMonthOptions = () => {
  return [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ];
};

export const getYearOptions = () => {
  return yearOptions;
};

export const getCompanySizeOptions = () => {
  return ['1 - 10', '11 - 50', '51 - 200', '201 - 500', '501 - 1000', '1001 - 5000', '5001+'];
};

export const getFundingStageOptions = () => {
  return [
    { label: 'Bootstrapped', value: ['Bootstrapped'] },
    { label: 'Seed', value: ['Seed'] },
    { label: 'Series A', value: ['Series A'] },
    {
      label: 'Series B and up',
      value: ['Series B', 'Series C', 'Series D', 'Series E', 'Series F']
    },
    { label: 'Public', value: ['Public'] },
    { label: 'Profitable & Sustainable', value: ['Profitable & Sustainable'] }
  ];
};

export const getCompanyFundingStageOptions = () => {
  return [
    { label: 'Bootstrapped', value: 'Bootstrapped' },
    { label: 'Seed', value: 'Seed' },
    { label: 'Series A', value: 'Series A' },
    { label: 'Series B', value: 'Series B' },
    { label: 'Series C', value: 'Series C' },
    { label: 'Series D', value: 'Series D' },
    { label: 'Series E', value: 'Series E' },
    { label: 'Series F', value: 'Series F' },
    { label: 'Public', value: 'Public' },
    { label: 'Profitable & Sustainable', value: 'Profitable & Sustainable' }
  ];
};

export const getRandomJobTitle = () => {
  const jobTitles = [
    'Front end Developer',
    'Data/Insight Analyst',
    'Full Stack Developer',
    'DevOps Engineer',
    'Back end Developer',
    'Data Scientist',
    'Junior Developer',
    'Data Engineer',
    'UX Designer',
    'Product Manager',
    'Machine Learning Engineer'
  ];

  return getRandomItemFromArray(jobTitles);
};

export const getRandomSkill = () => {
  const skills = [
    'JavaScript',
    'React',
    'Python',
    'Php',
    'Node.js',
    'Machine Learning',
    'Computer Vision',
    'Java',
    'C++',
    'C#',
    'Angular',
    'Computer Graphics',
    'Go',
    'Tensorflow'
  ];

  return getRandomItemFromArray(skills);
};

export const getAdminRoles = () => {
  return [
    { label: 'Super Admin', value: AdminRole.SuperAdmin },
    { label: 'People Admin', value: AdminRole.PeopleAdmin },
    { label: 'Company Admin', value: AdminRole.CompanyAdmin },
    { label: 'Sourcer', value: AdminRole.Sourcer },
    { label: 'Profile Builders & Verifiers', value: AdminRole.ProfileBuildersAndVerifiers },
    { label: 'Company Builders & Verifiers', value: AdminRole.CompanyBuildersAndVerifiers },
    { label: 'Marketing', value: AdminRole.Marketing },
    { label: 'Sales', value: AdminRole.Sales },
    { label: 'Customer Success', value: AdminRole.CustomerSuccess },
    { label: 'Talent', value: AdminRole.Talent },
    { label: 'People', value: AdminRole.People },
    { label: 'Finance', value: AdminRole.Finance },
    { label: 'Support', value: AdminRole.Support },
    { label: 'Tech', value: AdminRole.Tech },
    { label: 'CV Generator', value: AdminRole.CVGenerator }
  ];
};

export const getCandidateManagers = () => {
  return [
    { label: 'James', value: 'James' },
    { label: 'Elma', value: 'Elma' },
    { label: 'Sophie', value: 'Sophie' }
  ];
};

export const getRemoteDays = () => {
  return [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 }
  ];
};

export const getRemoteEngineerVisitOptions = () => {
  return [
    { label: 'Infrequent onsite visits', value: 'infrequent_onsite' },
    { label: 'No onsite', value: 'no_onsite' },
    { label: 'No preference', value: 'no_preference' }
  ];
};

export const getRemoteFirstOptions = () => {
  return [
    { label: '~ Fortnightly', value: 'fortnightly' },
    { label: '~ Monthly', value: 'monthly' },
    { label: '~ Quarterly', value: 'quarterly' },
    { label: 'No onsite', value: 'no_onsite' }
  ];
};

export const getRemoteOptions = (type?: UserType) => {
  if (type === UserType.Candidate) {
    return [
      {
        label: 'Onsite',
        value: RemoteOption.Onsite,
        tooltip: 'Looking to work predominantly onsite'
      },
      {
        label: 'Hybrid',
        value: RemoteOption.Hybrid,
        tooltip: 'Looking for a split between remote and onsite'
      },
      {
        label: 'Remote',
        value: RemoteOption.Remote,
        tooltip: 'Looking for work with no or infrequent onsite visits'
      }
    ];
  }
  return [
    { label: 'On-site', value: 'onsite', tooltip: 'Requires predominantly onsite work' },
    { label: 'Hybrid', value: 'hybrid', tooltip: 'Split between onsite and remote work' },
    { label: 'Remote', value: 'remote', tooltip: 'Fully remote or infrequent office visits' }
  ];
};

export function getIntegrationCategoryStatus(
  companyUser: CompanyAccountInterface,
  category: IntegrationCategory
) {
  switch (category) {
    case IntegrationCategory.ATS:
      const {
        workableStatus,
        greenhouseStatus,
        leverStatus,
        teamtailorStatus,
        ashbyStatus,
        mergeStatus,
        notionStatus,
        pinpointStatus,
        recruiteeStatus
      } = companyUser;
      return (
        [
          workableStatus,
          greenhouseStatus,
          leverStatus,
          teamtailorStatus,
          ashbyStatus,
          mergeStatus,
          notionStatus,
          pinpointStatus,
          recruiteeStatus
        ].some(status => status === ATSIntegrationState.Active) || false
      );
    case IntegrationCategory.Calendar:
      const { googleCalendarStatus, outlookCalendarStatus } = companyUser;
      return googleCalendarStatus === 'active' || outlookCalendarStatus === 'active';
    default:
      return false;
  }
}

export const getIntegrations = (
  permissions: Permissions,
  plan: SubscriptionPlan
): IntegrationType[] => {
  const commonIntegrations = [
    {
      name: 'Workable',
      logo: Workable,
      description: 'Send candidates directly to Workable',
      connected: userProfile.workableStatus === 'active',
      subdomain: userProfile.workableSubDomain || ''
    },
    {
      name: 'Greenhouse',
      logo: Greenhouse,
      description: 'Send candidates directly to Greenhouse',
      connected: userProfile.greenhouseStatus === ATSIntegrationState.Active,
      subdomain: userProfile.greenhouseSubDomain || ''
    },
    {
      name: 'Lever',
      logo: Lever,
      description: 'Send candidates directly to Lever',
      connected: userProfile.leverStatus === ATSIntegrationState.Active,
      subdomain: userProfile.leverSubDomain || ''
    },
    {
      name: 'Teamtailor',
      logo: Teamtailor,
      description: 'Send candidates directly to Teamtailor',
      connected: userProfile.teamtailorStatus === ATSIntegrationState.Active,
      subdomain: userProfile.teamtailorSubDomain || ''
    },
    {
      name: 'Ashby',
      logo: Ashby,
      description: 'Send candidates directly to Ashby',
      connected: userProfile.ashbyStatus === ATSIntegrationState.Active,
      subdomain: userProfile.ashbySubDomain || ''
    },
    {
      name: 'Notion',
      logo: Notion,
      description: 'Send candidates directly to Notion',
      connected: userProfile.notionStatus === ATSIntegrationState.Active,
      subdomain: userProfile.notionWorkspaceName || '',
      subdomainLink: userProfile.notionWorkspaceName
        ? `https://notion.com/${userProfile?.notionWorkspaceName}/${userProfile.notionDatabaseID}`
        : ''
    },
    {
      name: 'Pinpoint',
      logo: Pinpoint,
      description: 'Send candidates directly to Pinpoint',
      connected: userProfile.pinpointStatus === ATSIntegrationState.Active
    },
    {
      name: 'Recruitee',
      logo: Recruitee,
      description: 'Send candidates directly to Recruitee',
      connected: userProfile.recruiteeStatus === ATSIntegrationState.Active
    }
  ];

  const isStarter = plan === NewPricingOptions.Starter || plan === NewPricingOptions.StarterV2;

  return [
    ...(permissions.atsIntegration || isStarter
      ? [
          {
            id: 'ats_integrations',
            category: IntegrationCategoryName.ATS,
            title: 'Connect with your ATS',
            description:
              'Connecting your ATS will enable you to import positions and send candidates you source straight to your linked positions in your ATS.',
            active: getIntegrationCategoryStatus(userProfile, IntegrationCategory.ATS),
            items: commonIntegrations
          }
        ]
      : []),
    ...(permissions.slackIntegration || isStarter
      ? [
          {
            id: 'notifications_integrations',
            category: IntegrationCategoryName.Notification,
            items: [
              {
                name: 'Slack',
                logo: Slack,
                description: 'Get instant notifications on Slack about new candidates',
                connected: (userProfile as $TSFixMe).slackStatus === 'active'
              }
            ]
          }
        ]
      : []),
    ...(permissions.calendarIntegration
      ? [
          {
            id: 'calendar_integrations',
            category: IntegrationCategoryName.Calendar,
            title: 'Connect your calendar',
            description:
              'Connecting your calendar with cord enables you to set your availability to get calls & interviews booked directly into your calendar.',
            active: getIntegrationCategoryStatus(userProfile, IntegrationCategory.Calendar),
            items: [
              {
                name: 'Google Calendar',
                logo: GoogleCalendar,
                description: 'Get interviews booked directly in your Google Calendar',
                connected: userProfile.googleCalendarStatus === 'active'
              },
              {
                name: 'Outlook Calendar',
                logo: OutlookCalendar,
                description: 'Get interviews booked directly in your Outlook Calendar',
                connected: userProfile.outlookCalendarStatus === 'active'
              }
            ]
          }
        ]
      : [])
  ];
};

export const getDefaultInvite = (type: $TSFixMe) => {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const name = INVITE_TYPES.find(inviteType => inviteType.type === type).title;
  const duration = type === 'in-person' ? 120 : 30;
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const durationLabel = INTERVIEW_DURATION_OPTIONS.find(({ value }) => value === duration).label;

  return {
    name: `${name} (${durationLabel})`,
    type,
    availability: [
      { dCode: 1, day: 'mon', startTime: '09:00:00', endTime: '17:00:00' },
      { dCode: 2, day: 'tue', startTime: '09:00:00', endTime: '17:00:00' },
      { dCode: 3, day: 'wed', startTime: '09:00:00', endTime: '17:00:00' },
      { dCode: 4, day: 'thu', startTime: '09:00:00', endTime: '17:00:00' },
      { dCode: 5, day: 'fri', startTime: '09:00:00', endTime: '17:00:00' }
    ],
    timezone: tz.guess(true),
    duration,
    location: type === 'in-person' ? (companyProfile as $TSFixMe).location : '',
    noticeMinutes: 30,
    bufferMinutes: 15,
    allowedDays: 5
  };
};

export const saveDefaultCompanyInvites = async () => {
  const phoneCallInvite = getDefaultInvite('phone');
  const videoCallInvite = getDefaultInvite('video-google');
  const inPersonInvite = getDefaultInvite('in-person');

  const data = await Promise.all([
    saveCompanyInvite('', phoneCallInvite),
    saveCompanyInvite('', videoCallInvite),
    saveCompanyInvite('', inPersonInvite)
  ]);

  return data[0];
};

export const getAllInviteTypes = () => {
  return [
    { icon: 'icon_phone', label: 'Phone call', type: 'phone', title: 'Phone call' },
    { icon: 'icon_hangouts', label: 'Video call', type: 'video-google', title: 'Video call' },
    {
      icon: 'icon_map',
      label: 'In Person interview',
      type: 'in-person',
      title: 'In Person interview'
    }
  ];
};

export const getAllCompanyOnboardBubbles = () => {
  return [
    {
      name: 'templates',
      localstorage: 'templates_tooltip_viewed',
      viewed: getLocalStorageItem('templates_tooltip_viewed')
    },
    {
      name: 'streams',
      localstorage: 'streams_tooltip_viewed',
      viewed: getLocalStorageItem('streams_tooltip_viewed')
    }
  ];
};

export const getUserProfileEditParams = () => {
  const {
    memberName,
    memberPhotoURL,
    jobTitle,
    lifeAtCompany,
    yearsAtCompany,
    techStack,
    videoUrl,
    linkedinURL,
    experience,
    bio
  } = userProfile;

  return {
    name: memberName,
    photoURL: memberPhotoURL,
    videoURL: videoUrl,
    jobTitle,
    lifeAtCompany,
    yearsAtCompany,
    techStack,
    linkedinURL,
    experience,
    bio
  };
};

export const getRandomDeclineTemplate = (type = 'reason') => {
  const defaultReasonTemplates = [
    `Hey {{FIRST_NAME}},
      
  Thanks for messaging about our {{POSITION_NAME}} position. 
      
  Having looked at your profile & experience, unfortunately, I don't think it will be a good match at this stage. The reason being, {{DECLINE_REASON}}.
  
  Thanks for taking the time to find us and message ${(companyProfile as $TSFixMe).companyName}.
  
  Many thanks,
  {{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}},
  
  Thanks for sending me through your interest in our {{POSITION_NAME}} position.
  
  Unfortunately, I'll have to decline your message because {{DECLINE_REASON}}. We really appreciate your interest regardless and wish you all the best.
      
  Many thanks,
  {{SENDER_FIRST_NAME}}`,
    `Hey {{FIRST_NAME}},
  
  Having looked at your profile, unfortunately, I don't think it will be a good match at this stage. This is mainly because {{DECLINE_REASON}}.
      
  That said, I really appreciate your interest in speaking about our {{POSITION_NAME}} role and want to wish you the best of luck in your search.
      
  Thanks,
  {{SENDER_FIRST_NAME}}`,
    `Hey {{FIRST_NAME}},
  
  Having looked at your profile, unfortunately, I don't think it will be a good match at this stage. This is mainly because {{DECLINE_REASON}}.
      
  That said, I really appreciate your interest in speaking about our {{POSITION_NAME}} role and want to wish you the best of luck in your search.
      
  Thanks,
  {{SENDER_FIRST_NAME}}`,
    `Hey {{FIRST_NAME}},
  
  I really appreciate the time it took to reach out to me. 
      
  Unfortunately, after discussing with the team, we will not be proceeding with your interest. This is mainly because {{DECLINE_REASON}}.
      
  We wish you the best of luck in your job search. I'm certain you'll find the perfect role!
      
  Thanks,
  {{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}},
      
  I wanted to thank you for taking the time to send us your profile for the {{POSITION_NAME}} role. 
      
  Unfortunately at this stage we're not going to be able to move forward with it because {{DECLINE_REASON}}. 
  
  From all of us at ${(companyProfile as $TSFixMe).companyName}, good luck in your search.`,
    `Hey {{FIRST_NAME}},
  
  Thanks for the message about the {{POSITION_NAME}} role here at ${
    (companyProfile as $TSFixMe).companyName
  }. We're grateful for your interest but unfortunately, we won't be progressing it as {{DECLINE_REASON}}.
      
  Best of luck in your search, I'm sure you'll find something great.
  
  All the best,
  {{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}}. Thanks for messaging. We've decided not to take it to the next stage because {{DECLINE_REASON}}.
      
  We're grateful for you taking the time to find and message ${
    (companyProfile as $TSFixMe).companyName
  } and we wish you all the best in your search on cord.
      
  Regards,
  {{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}},
  
  Unfortunately we're not able to progress you for the {{POSITION_NAME}} position because {{DECLINE_REASON}}. 
  However, I wanted to say a personal thank you for taking the time to message us and wish you all the best in the future.
      
  Thanks,
  {{SENDER_FIRST_NAME}}`,
    `Hello {{FIRST_NAME}},
  
  Thanks for the message. Looking at your profile, I should say, it's not quite right for our {{POSITION_NAME}} role because {{DECLINE_REASON}}. Appreciate you taking the time to message us.
  
  All the best,
  {{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}},
  
  Thanks for sending us a message with your profile. It's not quite right for us because {{DECLINE_REASON}}. 
  
  I really appreciate you finding us and approaching us though, and wish you all the best in your search.
      
  Thanks,
  {{SENDER_FIRST_NAME}}`,
    `{{FIRST_NAME}}, Appreciate you messaging us about the {{POSITION_NAME}} position. However, it's not the right fit for us at the moment because {{DECLINE_REASON}}. I'm sure you'll find a great role next, so best of luck. 
  
  {{SENDER_FIRST_NAME}}`
  ];

  const defaultGenericTemplates = [
    `Hey {{FIRST_NAME}},
      
  Thanks for your interest in our {{POSITION_NAME}} position. Unfortunately, at this stage we're not going to be able to move forward with it.
      
  Please stay in touch for any future positions at ${(companyProfile as $TSFixMe).companyName}.
      
  {{SENDER_FIRST_NAME}}
      `,
    `Hi {{FIRST_NAME}},
    
  Thanks for the message about the {{POSITION_NAME}} role at ${
    (companyProfile as $TSFixMe).companyName
  }. I'm afraid we're not going to take it any further but I wish you all the best in your job search. 
      
  Regards,
  {{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}},
      
  Your experience looks impressive but it't not the right fit for what we are looking for atm. However, I wanted to say a personal thank you for taking the time to message us and wish you all the best in the future.
      
  Thanks,
  {{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}}, Thanks for your message about the {{POSITION_NAME}} job. We're not going to move forward with it this time, but wanted to wish you all the best in your search.
  
  {{SENDER_FIRST_NAME}}`,
    `Hello {{FIRST_NAME}}, 
      
  Thank you for sending us your profile. I'm not going to move forward with it right now, but on behalf of all of us at ${
    (companyProfile as $TSFixMe).companyName
  } - we wanted to thank you for taking the time to reach us.
  
  Best of luck in the future,
  {{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}},
  
  Really appreciate you taking the time to message us but we're not going to take it forward at the moment. We're really grateful for you approaching us.
  
  All the best in your job search. I'm sure you'll find a great role.
  
  Regards,
  {{SENDER_FIRST_NAME}}`,
    `{{FIRST_NAME}}, thanks for messaging us. We appreciate every message we get from candidates. Regrettably, we're not going to move forward with your profile. I wanted to send you all the best from the team at ${
      (companyProfile as $TSFixMe).companyName
    }.
  
  Good luck in your search!
  
  {{SENDER_FIRST_NAME}}`
  ];

  if (type === 'reason') return getRandomItemFromArray(defaultReasonTemplates);
  else return getRandomItemFromArray(defaultGenericTemplates);
};

export const getRandomAutoDeclineMessage = () => {
  const declineMessages = [
    `Hey {{FIRST_NAME}},
    
Thanks for your interest in our {{POSITION_NAME}} position, but unfortunately we're no longer hiring for it. 

Wish you all the best in your job search!
    
{{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}},

I wanted to let you know that we've recently closed this position so won't be taking your profile further. Hope you find what you're looking for.

Thanks,
{{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}},

Thanks a lot for messaging us about this position. Unfortunately we're no longer hiring for it so we won't be able to start a conversation at this time. 

I'd like to wish you all the best in your search, I'm sure you'll find something great. 

Best, 
{{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}} - We're no longer hiring for this role. Thanks for sending us a message. We appreciate everyone who takes the time to find us and message us, so wishing you all the best in your search. 

Regards,
{{SENDER_FIRST_NAME}}`,
    `Hello {{FIRST_NAME}},

We've just closed this position so we won't be able to take it any further. Thanks for taking the time out to message us. 

I hope you find the right role.

Best, 
{{SENDER_FIRST_NAME}}`,
    `Hi {{FIRST_NAME}}. Just to let you know we're no longer recruiting for this role. However, I wanted to thank you for taking the time to approach us.

Best, 
{{SENDER_FIRST_NAME}}`,
    "Hi {{FIRST_NAME}}. Appreciate you sending your profile for this role. I'm afraid we're no longer hiring for the position. Best of luck in the future.",
    `Hi {{FIRST_NAME}},

Thank you for messaging us. Unfortunately, we are no longer hiring for this position. I wish you all the best in your job search.

Thanks,
{{SENDER_FIRST_NAME}}`,
    `Hi{{FIRST_NAME}},

Thanks for messaging us about the position. It's unfortunately now closed. Best of luck finding your next role.

Thanks,
{{SENDER_FIRST_NAME}}`
  ];

  return getRandomItemFromArray(declineMessages);
};

export const lauraJobTitles = ['Front End', 'Full Stack', 'Back End'];
export const lauraSeniorities = ['mid', 'senior', 'lead'];
export const notificationSounds = [
  { label: 'Ding Ding', value: 'https://assets.co-hire.com/public/ding-ding.mp3' },
  { label: 'Plink', value: 'https://assets.co-hire.com/public/plink.mp3' }
];

const userTypeToSalaryToolEndpointMap = {
  candidate: CANDIDATE_SALARY_TOOL,
  company: COMPANY_SALARY_TOOL,
  public: PUBLIC_SALARY_TOOL,
  'public-unblocked': PUBLIC_SALARY_TOOL
};

/* istanbul ignore next */
export const castInsightsArticleVote = async (
  articleId: $TSFixMe,
  vote: $TSFixMe,
  userID = null,
  isHiringInsights: boolean
): Promise<
  | { status: string; userID: number }
  | { status: string; userID: undefined; data: null; message: '' }
> => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.post(PUBLIC_INSIGHTS_VOTE, {
      articleID: articleId,
      vote,
      userID,
      CMSResource: getRepoType(isHiringInsights)
    });
    return { status: 'success', userID: (data as $TSFixMe).data?.userID };
  } catch (error: any) {
    return {
      data: null,
      status: 'failure',
      userID: undefined,
      message: error?.response?.data?.message
    };
  }
};

/* istanbul ignore next */
export const getInsightsArticleVotes = async (articleID: $TSFixMe, isHiringInsights: boolean) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const response = await axios.get(PUBLIC_INSIGHTS_VOTE, {
      params: { articleID, CMSResource: getRepoType(isHiringInsights) }
    });
    return { status: 'success', vote: (response.data as $TSFixMe).data?.vote };
  } catch (error: any) {
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

export const getInsightsTags = async (isHiringInsights: boolean) => {
  try {
    const { data } = await axios.get(`${PUBLIC_INSIGHTS}/tags`, {
      params: { CMSResource: getRepoType(isHiringInsights) }
    });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getInsightByUID = async (type: string, uid: string, isHiringInsights: boolean) => {
  try {
    const { data } = await axios.get(`${PUBLIC_INSIGHTS}/${type}/${uid}`, {
      params: { CMSResource: getRepoType(isHiringInsights) }
    });
    return { data: (data as $TSFixMe).data };
  } catch (error: any) {
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getInsightsByTopic = async (
  topic: $TSFixMe,
  pageSize: $TSFixMe,
  isHiringInsights: boolean
) => {
  try {
    const { data } = await axios.get(`${PUBLIC_INSIGHTS}/${topic}`, {
      params: { pageSize, CMSResource: getRepoType(isHiringInsights) }
    });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getUserPublicProfile = async (userID: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const { data } = await axios.get(PUBLIC_USER_PROFILE, { params: userID });
    return { status: 'success', data: (data as $TSFixMe).data };
  } catch (error: any) {
    console.log(error);
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const setUserVisibility = async (payload: $TSFixMe) => {
  try {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await axios.put(ADMIN_PUBLIC_VISIBILITY, payload);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getSalaryToolData = async (
  params: { [key: string]: any },
  userType: ProductUserType,
  signal?: AbortSignal
) => {
  try {
    const { data } = await axios.get<{ data: { [category in SalaryToolCategory]: any } }>(
      userTypeToSalaryToolEndpointMap[userType],
      { params, signal }
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { data: null, status: 'failure', message: error?.response?.data?.message };
  }
};

export const setGreenhouseName = async (firstName: string, lastName: string) => {
  try {
    const { data } = await axios.post(COMPANY_SET_GREENHOUSE_NAME, {
      firstName,
      lastName
    });
    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const getSQLQueries = async (): Promise<{ data: SQLWriteLogInterface[] }> => {
  try {
    const data = await axios.get(ADMIN_SQL_WRITE_QUERY);
    return data.data;
  } catch (error) {
    return { data: [] };
  }
};

/* istanbul ignore next */
export const approveDenySQLQuery = async (
  queryID: number,
  status: SQLQueryStatus
): Promise<{ status: string; message?: string }> => {
  try {
    await axios.put(`${ADMIN_SQL_WRITE_QUERY}/${queryID}`, { status });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const postSQLQuery = async (
  query: $TSFixMe
): Promise<{ status: string; message?: string }> => {
  try {
    await axios.post(ADMIN_SQL_WRITE_QUERY, { query });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const runSQLQuery = async (queryID: number) => {
  try {
    await axios.post(`${ADMIN_SQL_WRITE_QUERY}/${queryID}/run`, {
      data: { queryID }
    });
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getLiveHires = async (
  sortBy: LiveHiresSortBy
): Promise<{ status: 'success'; data: HiresCompany[] } | { status: 'failure' }> => {
  try {
    const {
      data: { data }
    } = await axios.get(`${PUBLIC_LIVE_HIRES}/feed`, { params: { sort_by: sortBy } });

    return { status: 'success', data };
  } catch (error) {
    return { status: 'failure' };
  }
};

export const getHiresGraphData = async (
  jobTitle: string
): Promise<
  | {
      status: 'success';
      data: { jobTitles: string[]; statistics: HiresJobTitleConversationStatistics };
    }
  | { status: 'failure'; message?: string }
> => {
  const params = jobTitle ? { jobTitle } : {};
  try {
    const {
      data: { data }
    } = await axios.get(`${PUBLIC_LIVE_HIRES}/jobtitle/statistics`, { params });

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const resetDemoAccountsATSStatus = async () => {
  try {
    await axios.post(ADMIN_ATS_RESET_FOR_DEMO);
    return { status: 'success' };
  } catch (error) {
    return { status: 'failure' };
  }
};

/** API has changed so that proratedCharge, savings and totalOverage are returned in cents instead
 * of dollars. This function converts them back to dollars before they "enter the app".
 */
export const patchCentsToDollarsUpgradeRecommendation = (data: UpgradeRecommendationDetails) => {
  const { proratedCharge, savings, totalOverage, ...rest } = data;
  return {
    proratedCharge: proratedCharge / 100,
    savings: savings! / 100,
    totalOverage: totalOverage! / 100,
    ...rest
  };
};

export const getPlanUpgradeRecommendation = async () => {
  try {
    const {
      data: { data }
    } = await axios.get<{
      data: {
        shouldRecommend: boolean;
        upgradeRecommendationDetails: UpgradeRecommendationDetails;
      };
    }>(`${COMPANY_UPGRADE}/recommendation`);

    return data.shouldRecommend
      ? patchCentsToDollarsUpgradeRecommendation(data.upgradeRecommendationDetails)
      : null;
  } catch (error: any) {
    return null;
  }
};

export const getSourcingBlockUpgradePlan = async () => {
  try {
    const {
      data: { data }
    } = await axios.get<{ data: UpgradeSourcingBlockReachedRecommendationDetails }>(
      COMPANY_RECOMMENDATION_SOURCING_BLOCK!
    );

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const setCompanyAllowSelfServiceChurn = async (
  companyID: number,
  allowSelfServiceChurn: boolean
) => {
  try {
    await axios.put(`${ADMIN_COMPANY_ALLOW_SELF_SERVICE_CHURN}`, {
      companyID,
      allowSelfServiceChurn
    });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const setAdminConfig = async (configID: number, configValue: any) => {
  try {
    await axios.put(`${ADMIN_CONFIG}`, { id: configID, configValue });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

/* istanbul ignore next */
export const setRefreshProfileChoice = async ({ refreshProfile }: { refreshProfile: boolean }) => {
  try {
    await axios.put(
      CANDIDATE_REFRESH_PROFILE,
      { refreshProfile },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const applyFounderProgram = async (form: SpecialEnquiryFormType): Promise<APIResponse> => {
  try {
    await axios.post(PUBLIC_FOUNDER_PROGRAM_ENQUIRY, form);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const companyEnquireAnnualPlan = async () => {
  try {
    await axios.post(COMPANY_ANNUAL_PLAN_ENQUIRY);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure' };
  }
};

export const getCompanyWallet = async (
  id: number
): Promise<
  | { status: 'success'; data: { id: number; balance: number } }
  | { status: 'failure'; message?: string }
> => {
  try {
    const { data } = await axios.get(`${CREDIT_SYSTEM_ADMIN_COMPANIES}/${id}/wallets`);

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const getCompanyTransactions = async (
  id: number,
  page = 1,
  pageSize = 10
): Promise<
  { status: 'success'; data: TransactionInterface[] } | { status: 'failure'; message?: string }
> => {
  const params = { page, pageSize };
  try {
    const { data } = await axios.get(`${CREDIT_SYSTEM_ADMIN_COMPANIES}/${id}/transactions`, {
      params
    });

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.error };
  }
};

export const createCreditTransaction = async (
  walletID: number,
  amount: number,
  comment: string
) => {
  try {
    const { data } = await axios.post(`${CREDIT_SYSTEM_ADMIN_TRANSACTIONS}`, {
      walletID,
      amount,
      metadata: { comment }
    });

    return { status: 'success', data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const setCompanyPayAsYouGo = async (enabled: boolean) => {
  try {
    await axios.post(COMPANY_SET_PAYG, { enabled });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
