import React, { PropsWithChildren, ReactNode } from 'react';
import ClassNames from 'classnames';
import styled, { DefaultTheme, ThemedStyledProps, css } from 'styled-components';

import { CompanyType } from '@cohiretech/common-types';

import { SizeVariant, SizeVariantCSS } from 'types';
import { typography } from 'styles/theme/common';
import { isNil } from 'utils/fn';
import { capitalizeFirst } from 'utils/string';
import { COMPANY_TYPE_ICON_MAP, identifyCompanyType } from 'v2/services/company/companyType';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

export type LabelWithIconProps = {
  icon: string;
  text: ReactNode;
  tag?: boolean;
  // Figma only has 4 types of IconLabel.
  color?:
    | 'blue' // BrandIconLabel
    | 'grey' // PrimaryIconLabel
    | 'red' // NegativeIconLabel
    | 'white'
    | 'outline_blue'
    | 'cord_gradient'
    | 'secondary'; // SecondaryIconLabel
  size?: SizeVariant;
  gap?: string;
  className?: string;
  vertical?: boolean;
  htmlFor?: string;
  bold?: boolean;
};

type StyleProps = Required<Pick<LabelWithIconProps, 'tag' | 'size' | 'gap'>> &
  Pick<LabelWithIconProps, 'color' | 'htmlFor' | 'bold'>;

const LabelFontSizes: Partial<SizeVariantCSS> = {
  xsmall: typography.mobile,
  small: typography.small,
  medium: typography.normal
};

const LabelWithIcon = ({
  icon,
  text,
  tag = false,
  gap = '5px',
  color,
  size = 'medium',
  bold,
  className,
  vertical,
  htmlFor,
  children
}: PropsWithChildren<LabelWithIconProps>) => {
  if (!text) return null;
  return (
    <Wrapper
      className={ClassNames('label_with_icon', className, color)}
      tag={tag}
      color={color}
      size={size}
      gap={gap}
      bold={bold}
      htmlFor={htmlFor}
    >
      <span className={ClassNames('icon', icon, { vertical, bold })} />
      <span>{text}</span>
      {children}
    </Wrapper>
  );
};

export default LabelWithIcon;

const Wrapper = styled.div<StyleProps>`
  ${props => {
    const { theme, tag, size, gap, bold, color } = props;
    const { colours } = theme;

    return css`
      position: relative;
      display: inline-block;
      margin: 0 2px;
      > span {
        ${color === 'cord_gradient'
          ? theme.fn.getRadialGradientTextStyling('cordGradient')
          : `color: ${getColour(props)}`};
      }
      ${bold &&
      css`
        font-weight: ${({ theme: { typography } }) => typography.semibold};
      `}

      .icon {
        display: inline-block;
        vertical-align: text-bottom;
        font-size: ${getIconFontSize(props)};
        margin: ${`0 ${gap} 1px 0`};

        &.vertical {
          display: block;
          margin: ${`0 0 ${gap} 0`};
        }

        &.bold {
          font-weight: bold;
        }
      }

      &:hover .tooltip_wrapper {
        display: block;

        .tooltip {
          text-align: left;
        }
      }

      ${LabelFontSizes[size] &&
      css`
        font-size: ${LabelFontSizes[size]};
      `}

      ${tag &&
      css`
        background-color: ${getBgColour(props)};
        padding: 0.25rem 0.5rem;
        border-radius: 1.25rem;

        .red .tooltip {
          color: ${colours.errorColour};
        }
      `}

      .grey {
        opacity: 0.6;
      }

      .outline_blue {
        border: ${tag ? `2px solid ${colours.primaryColour}` : 'none'};
      }
    `;
  }}
`;

export const CompanyTypeLabelWithIcon = ({
  companyType,
  profileType
}: {
  companyType?: CompanyType;
  profileType: 'company' | 'position' | 'recruiter';
}) => {
  const { isDirect, isAgency } = identifyCompanyType(companyType);

  return (
    <ConditionalRender predicate={!isNil(companyType) && !isDirect}>
      <LabelWithIcon
        icon={COMPANY_TYPE_ICON_MAP[companyType!]}
        text={`${capitalizeFirst(companyType || '')}${
          profileType === 'company' && isAgency ? '' : ` ${profileType}`
        }`}
        tag
        size="small"
        color="blue"
      />
    </ConditionalRender>
  );
};

type StyleParams = ThemedStyledProps<StyleProps, DefaultTheme>;

const getColour = ({ color, tag, theme: { colours } }: StyleParams) => {
  switch (color) {
    case 'white':
      return tag ? '#5faae7' : colours.white;
    case 'blue':
      return 'var(--text-tag-brand)';
    case 'red':
      return 'var(--text-tag-negative)';
    case 'outline_blue':
      return colours.primaryColour;
    case 'secondary':
      return 'var(--text-tag-secondary)';
    default:
      return 'var(--text-tag-primary)';
  }
};

const getBgColour = ({ color, theme: { darkMode, colours } }: StyleParams) => {
  switch (color) {
    case 'white':
      return darkMode ? colours.darkBgColour : colours.white;
    case 'blue':
      return 'var(--background-tag-brand)';
    case 'red':
      return 'var(--background-tag-negative)';
    case 'outline_blue':
      return 'tranparent';
    default:
      return 'var(--background-tag-soft)';
  }
};

const getIconFontSize = ({ size, theme: { typography } }: StyleParams) => {
  switch (size) {
    case 'xsmall':
      return typography.small;
    case 'small':
      return typography.normal;
    case 'large':
      return typography.header;
    default:
      return typography.smallheader;
  }
};
