import React, { PropsWithChildren } from 'react';
import ClassNames from 'classnames';

import { useDarkMode } from 'hooks/useDarkMode';
import { Image } from 'types';

import Button, { Props as ButtonProps } from 'components/button';
import Tooltip from 'components/tooltip';
import Loader from 'components/loader';
import StepBubbles, { StepBubblesProps } from 'components/stepbubbles';
import Checkbox, { CheckboxProps } from 'components/checkbox';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';
import { StepsRightPanel } from './DialogWindow.styled';

type LinkType = {
  text?: string;
  onClick?: () => void;
};

export type DialogWindowProps = PropsWithChildren<{
  id?: string;
  className?: string;
  popupStyle?: string;
  title?: string | $TSFixMe[];
  subTitle?: string | $TSFixMe[] | React.ReactElement;
  visible: boolean;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  loading?: boolean;
  showCloseIcon?: boolean;
  skipLink?: LinkType;
  onClose?: $TSFixMeFunction;
  exitAlert?: string;
  bodyImage?: Image;
  topIcon?: React.ImgHTMLAttributes<HTMLImageElement>;
  svgIcon?: JSX.Element;
  topCode?: React.ReactNode;
  multiStepOptions?: Pick<StepBubblesProps, 'steps' | 'currentStep' | 'onStepChange' | 'color'> & {
    link?: LinkType;
    linkLoading?: boolean;
  };
  textAlignLeft?: boolean;
  checkbox?: CheckboxProps;
  bottomNote?: React.ReactNode;
  stopEventPropagation?: boolean;
}>;

export default function DialogWindow({
  id,
  className,
  popupStyle,
  title,
  subTitle,
  visible,
  primaryButton,
  secondaryButton,
  skipLink,
  loading,
  showCloseIcon,
  onClose,
  exitAlert,
  topIcon,
  svgIcon,
  bodyImage,
  topCode,
  multiStepOptions,
  children,
  textAlignLeft,
  checkbox,
  bottomNote,
  stopEventPropagation
}: DialogWindowProps) {
  const darkMode = useDarkMode();
  const areTwoButtons = primaryButton?.text && secondaryButton?.text;
  const { steps, currentStep, onStepChange, color, link, linkLoading } = multiStepOptions || {};

  return (
    <div
      id={id}
      data-testid={`test_${id}`}
      className={ClassNames('pop_up_cont', className, {
        blue_pop_up: popupStyle === 'blue',
        show: visible,
        dark: darkMode || id === 'newProductUpdates'
      })}
      onClick={e => {
        if (stopEventPropagation) e.stopPropagation();
      }}
    >
      <div
        className={ClassNames('pop_up_dialog', {
          overflow_unset: exitAlert,
          text_align_left: textAlignLeft
        })}
      >
        {showCloseIcon && (
          <div data-testid="close_icon" className="close_pop_up" onClick={onClose}>
            <span className="icon_close">{exitAlert && <Tooltip text={exitAlert} />}</span>
          </div>
        )}
        {svgIcon}
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        {topIcon && <img className="pop_up_top_icon" {...topIcon} />}
        {topCode && Array.isArray(topCode) ? topCode.map(e => e) : topCode}
        {title && <h1 className="pop_up_title">{title}</h1>}
        {subTitle && <h2 className="pop_up_sub_title">{subTitle}</h2>}
        {bodyImage && (
          <img
            className="pop_up_body_image"
            src={bodyImage.src}
            alt={bodyImage.alt}
            style={bodyImage.style}
          />
        )}
        {children && children}
        {areTwoButtons && !multiStepOptions && (
          <div className="buttons_wrapper">
            {loading ? (
              <Loader
                className={ClassNames('buttons_wrapper_loader', 'small', { dark: darkMode })}
              />
            ) : (
              <>
                <Button
                  text={primaryButton.text}
                  className={ClassNames('eighteen_px_font', primaryButton.className)}
                  buttonStyle={primaryButton.buttonStyle || 'fill_blue'}
                  action={primaryButton.action}
                  disabled={primaryButton.disabled}
                />
                <Button
                  text={secondaryButton.text}
                  className={ClassNames('eighteen_px_font', secondaryButton.className)}
                  buttonStyle={secondaryButton.buttonStyle || 'stroke_blue'}
                  action={secondaryButton.action}
                  disabled={secondaryButton.disabled}
                  icon={secondaryButton.icon}
                  loading={secondaryButton.loading}
                />
              </>
            )}
          </div>
        )}
        {loading && skipLink?.text && !multiStepOptions && !areTwoButtons ? (
          <Loader className={ClassNames('buttons_wrapper_loader', 'small', { dark: darkMode })} />
        ) : (
          primaryButton?.text &&
          !secondaryButton?.text &&
          !multiStepOptions && (
            <Button
              text={primaryButton.text}
              className="eighteen_px_font"
              buttonStyle={primaryButton.buttonStyle || 'fill_blue'}
              loading={loading}
              action={primaryButton.action}
              disabled={primaryButton.disabled}
              icon={primaryButton?.icon}
            />
          )
        )}
        {multiStepOptions && primaryButton?.text && (
          <div className="steps_cont flex_wrapper">
            <StepBubbles
              steps={steps!}
              currentStep={currentStep!}
              onStepChange={i => onStepChange!(i)}
              color={color}
            />
            <StepsRightPanel className="right_panel">
              {link?.text && (
                <>
                  {linkLoading ? (
                    <Loader
                      className={ClassNames('buttons_wrapper_loader', 'small', { dark: darkMode })}
                    />
                  ) : (
                    <button className="link" type="button" onClick={link?.onClick}>
                      {link?.text}
                    </button>
                  )}
                </>
              )}
              {secondaryButton && (
                <Button
                  text={secondaryButton.text}
                  className={ClassNames('eighteen_px_font', secondaryButton.className)}
                  buttonStyle={secondaryButton.buttonStyle || 'stroke_blue'}
                  action={secondaryButton.action}
                  disabled={secondaryButton.disabled}
                  icon={secondaryButton.icon}
                  loading={secondaryButton.loading}
                />
              )}
              <Button
                text={primaryButton.text}
                className="eighteen_px_font"
                buttonStyle={primaryButton.buttonStyle || 'fill_blue'}
                icon={
                  primaryButton.icon ||
                  (currentStep === (steps?.length || 0) - 1 ? '' : 'icon_arrow_right')
                }
                iconPosition={primaryButton.iconPosition || 'right'}
                loading={loading}
                action={primaryButton.action}
                disabled={primaryButton.disabled}
              />
            </StepsRightPanel>
          </div>
        )}
        {skipLink?.text && !loading && (
          <button type="button" className="link" onClick={skipLink?.onClick || onClose}>
            {skipLink.text}
          </button>
        )}
        <ConditionalRender predicate={bottomNote}>{bottomNote}</ConditionalRender>
        <ConditionalRender predicate={checkbox}>
          <Checkbox {...checkbox!} />
        </ConditionalRender>
      </div>
    </div>
  );
}
