import { PropsWithChildren, createContext, useContext, useState } from 'react';

import {
  SearchCategory,
  useCandidateSavedSearches,
  useCandidateSearchOptions,
  useNavigateToNewSearch
} from 'store';
import { AutocompleteState, useAutocomplete } from 'hooks/useAutocomplete';

import { SearchOption } from 'v2/components/ui/molecules/SearchOptionList';

import { buildSearchOptionByKeyword } from './SimpleSearchBar.helpers';

export type SearchBarTab = 'new' | 'saved';

type SearchBarContextObject = {
  focused: boolean;
  currentTab: SearchBarTab;
  setFocused: (focused: boolean) => void;
  setCurrentTab: (tab: SearchBarTab) => void;
  startNewSearch: (option: SearchOption, category?: SearchCategory) => void;
} & ReturnType<typeof useCandidateSavedSearches> &
  AutocompleteState;

const SearchBarContext = createContext<SearchBarContextObject | null>(null);

export default function SearchBarProvider({ children }: PropsWithChildren<{}>) {
  const { keywordOptions } = useCandidateSearchOptions();
  const savedSearchesState = useCandidateSavedSearches();
  const { navigateToNewSearch } = useNavigateToNewSearch();

  const keywordSearch = useAutocomplete({
    searchItems: [],
    keywordOptions,
    buildOptionByKeyword: buildSearchOptionByKeyword
  });

  const [focused, setFocused] = useState(false);
  const [currentTab, setCurrentTab] = useState<SearchBarTab>('new');

  const startNewSearch = (option: SearchOption) => {
    setFocused(false);
    navigateToNewSearch(option);
  };

  const value = {
    focused,
    currentTab,
    setFocused,
    setCurrentTab,
    startNewSearch,
    ...savedSearchesState,
    ...keywordSearch
  };

  return <SearchBarContext.Provider value={value}>{children}</SearchBarContext.Provider>;
}

export const useSearchBarContext = () => {
  const searchBarContext = useContext(SearchBarContext);
  if (!searchBarContext) {
    throw new Error('useSearchBarContext must be used within a Provider');
  }
  return searchBarContext;
};
