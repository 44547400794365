import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { FrequencyMetric } from 'types/candidate-insights-tmp';

import { useCandidateSkills } from 'store';
import { colours } from 'styles/theme/common';
import { isEmpty } from 'utils';
import { repeat } from 'utils/array';
import { pluck } from 'utils/object';
import { useDarkMode } from 'hooks/useDarkMode';

import { InsightsBlock } from '../InsightsBlock';
import { usePositionProfile } from '../PositionProfile.context';

const SUB_TITLE = 'Skills of people accepted';
const DEFAULT_TITLE = 'See what skills successful people have on their profile';
const EMPTY_TITLE = 'No people accepted yet for this position';
const CTA_TEXT = 'Check your skills';
const EMPTY_DATA = [
  { attribute: '', total: 0 },
  { attribute: '', total: 0 },
  { attribute: '', total: 0 },
  { attribute: '', total: 0 },
  { attribute: '', total: 0 }
];

export const SkillsBreakdownBlock = () => {
  const { insightsData } = usePositionProfile();

  let positionSkills = insightsData.position?.acceptedConversations?.primarySkills || [];
  const isEmptyState = isEmpty(positionSkills);
  positionSkills = positionSkills.sort(byTotal).slice(0, 5);

  if (isEmptyState) {
    positionSkills = EMPTY_DATA;
  }

  const title = getTitle(positionSkills);

  return (
    <InsightsBlock
      size="medium"
      subTitle={SUB_TITLE}
      title={title}
      defaultTitle={DEFAULT_TITLE}
      cta={CTA}
    >
      <ColumnChart
        data={positionSkills.map(pluck('total')) as number[]}
        categories={positionSkills.map(pluck('attribute')) as string[]}
      />
    </InsightsBlock>
  );
};

const ColumnChart = ({ data, categories }: { data?: number[]; categories?: string[] }) => {
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const { primarySkills } = useCandidateSkills();
  const darkMode = useDarkMode();

  useEffect(() => {
    setOptions(constructOptions(categories, data, primarySkills, darkMode));
    if (!data) return;
    setSeries([{ name: 'Accepted people', data }]);
  }, [data, categories]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={290} />
    </div>
  );
};

const constructOptions = (
  categories: string[] = [],
  data: number[] = [],
  primarySkills: string[] = [],
  darkMode: boolean
): ApexOptions => {
  // Change color of bars which represent skills that candidate has
  const colorStops = repeat(barColorLight, categories.length);
  const labelColors = repeat(colours.primaryColour as string, categories.length);
  categories.forEach((category, index) => {
    if (primarySkills.includes(category)) {
      colorStops[index] = barColorDark;
      labelColors[index] = 'white' as string;
    }
  });

  return {
    chart: {
      height: 290,
      parentHeightOffset: 0,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    grid: {
      show: true,
      padding: { left: 2, right: 2, top: -10, bottom: 0 },
      borderColor: darkMode ? colours.fontColour : colours.lightFontColour,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 15,
        borderRadiusApplication: 'end',
        barHeight: '65%',
        distributed: true,
        dataLabels: {
          position: 'bottom'
        }
      }
    },
    theme: { mode: darkMode ? 'dark' : 'light' },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      offsetX: 6,
      formatter: (val, opt) => formatLabels(val, opt),
      style: {
        fontSize: '12px',
        colors: labelColors,
        fontFamily: '"Figtree", Helvetica, Arial, sans-serif'
      }
    },
    legend: {
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        colorStops
      }
    },
    tooltip: {
      enabled: true
    },

    xaxis: {
      categories,
      type: 'category',
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        formatter: (val: any) => val.toFixed(0),
        rotate: 0,
        style: {
          fontSize: '10px',
          colors: repeat('#869DB1', categories.length)
        }
      },
      crosshairs: {
        show: false
      }
    },
    yaxis: {
      max: getMax(data),
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        formatter: (val: number) => `${val}`,
        maxWidth: 0
      }
    }
  };
};

const CTA = {
  text: CTA_TEXT,
  link: '/candidate/account#skills'
};

const getTitle = (skills: FrequencyMetric[]) => {
  if (isEmpty(skills)) return EMPTY_TITLE;

  const topSkill = getMaxItem(skills);
  return `${topSkill.attribute} is the most common skill of people accepted`;
};

const barColorLight = [
  {
    offset: 30,
    color: colours.primaryColour,
    opacity: 0.12
  },
  {
    offset: 111,
    color: '#7B7EE6',
    opacity: 0.12
  }
];

const barColorDark = [
  {
    offset: 30,
    color: colours.primaryColour,
    opacity: 1
  },
  {
    offset: 111,
    color: '#7B7EE6',
    opacity: 1
  }
];

const formatLabels = (val: string | number | number[], opt: any) => {
  const label = opt.w.globals.labels[opt.dataPointIndex];
  return label;
};

const getMax = (data: number[]) => {
  const max = Math.max(...data);
  if (max < 1) return 16;
};

const getMaxItem = (data: FrequencyMetric[]) => {
  return (data as FrequencyMetric[]).reduce(
    (max, item) => (item.total > max.total ? item : max),
    data[0]
  );
};

const byTotal = (a: { total: number }, b: { total: number }) => {
  return b.total - a.total;
};
