import { useEffect, useState } from 'react';

import { CandidateStatus } from '@cohiretech/common-types';

import { useCandidateProfile } from 'store';
import { checkBlockingCriteria } from 'v2/services/fetchers/candidate/position';
import { isFailure } from 'v2/services/fetchers/apiTools';
import { MATCH_INFO_KEYS } from 'v2/services/company/ApplicationBlockCriteria/applicationBlockCriteria';
import { identifyCompanyType } from 'v2/services/company/companyType';

import { PositionType } from '..';

import { BlockingCriteriaStatus } from './ApplyButtonTooltip.helpers';

export const useApplyButtonTooltip = (position: PositionType, isPositionInView: boolean) => {
  /*
    Optimisation: Consider saving the information in cachedNavigatablePositions to avoid calling 
    the API multiple times. Note that this cache is valid only until the position or candidate 
    profile is updated.
  */
  const [blockingCriteriaStatus, setBlockingCriteriaStatus] = useState<BlockingCriteriaStatus>();

  const profile = useCandidateProfile();
  const { id, status, verified } = profile || {};

  const { isExternal } = identifyCompanyType(position.companyType);
  const shouldCheckApplicationEligibility =
    isPositionInView && verified && position.id && !isExternal;

  const updateBlockingCriteriaStatus = async () => {
    const res = await checkBlockingCriteria(position.id, id!);
    const { block, blockingCriteria, matchInfo } = res.data || {};

    if (isFailure(res) || !block) return;

    setBlockingCriteriaStatus({
      blockingCriteria: blockingCriteria!,
      blockedByCriteria: MATCH_INFO_KEYS.find(key => matchInfo![key]?.match === false)!
    });
  };

  useEffect(() => {
    if (shouldCheckApplicationEligibility) updateBlockingCriteriaStatus();
  }, [profile, position]);

  return {
    showApplyButtonTooltip:
      shouldCheckApplicationEligibility &&
      (blockingCriteriaStatus || status === CandidateStatus.Passive),
    blockingCriteriaStatus
  };
};
