import React from 'react';
import ClassNames from 'classnames';

import Tooltip, { Props as TooltipProps } from 'components/tooltip';

type Props = {
  darkMode: boolean;
  tooltipProps?: TooltipProps;
  showSourcingPopup: () => void;
};

function PersonSourcedExplanationTooltip({ darkMode, tooltipProps, showSourcingPopup }: Props) {
  return (
    <span
      className={ClassNames('icon_help', { dark: !darkMode })}
      onClick={() => showSourcingPopup()}
    >
      <Tooltip
        {...tooltipProps}
        text={[
          'A sourcing credit is used when a candidate accepts your message request or you accept theirs. ',
          <button
            key="person_sourced_hub_link"
            className="link person_sourced_link"
            type="button"
            onClick={() => showSourcingPopup()}
          >
            Learn how
          </button>
        ]}
      />
    </span>
  );
}

export default PersonSourcedExplanationTooltip;
