export type NavigationLink = {
  id: string;
  label: string;
  path: string;
  icon?: string;
  main?: boolean;
  img?: string;
  msg?: string;
};

export enum NavigationHeaders {
  ForPeople = 'For finding work',
  ForCompanies = 'For hiring',
  About = 'About us'
}

type NavigationCategory = {
  links: NavigationLink[];
  visibleOnDesktop?: boolean;
};

export type NavigationMap = Record<NavigationHeaders, NavigationCategory>;
