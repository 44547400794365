import axios, { AxiosResponse } from 'axios';

import { CandidateInsightsOptions, CandidateInsightsResponse } from '@cohiretech/common-types';

import { APIResponse } from 'types';

const CANDIDATE_PRO_INSIGHTS = process.env.REACT_APP_CANDIDATE_PRO_INSIGHTS!;

type ProInsightsParams = { listingID: number } & Partial<CandidateInsightsOptions>;

export const getProInsights = async ({
  listingID,
  ...params
}: ProInsightsParams): Promise<APIResponse<CandidateInsightsResponse>> => {
  try {
    const { data } = await axios.get<AxiosResponse<CandidateInsightsResponse>>(
      `${CANDIDATE_PRO_INSIGHTS}/${listingID}`,
      { params }
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message || error?.message };
  }
};
