import React from 'react';

import { CandidateCVSettingsFE } from 'types';

import Badge from 'components/badge';

export const ALLOWED_CV_FORMATS = '.doc, .docx, .pdf, .html, .rtf, .txt';

const UpcomingBadge = () => <Badge text="upcoming" type="beta_feature" />;

export const DEFAULT_CV_SETTINGS: CandidateCVSettingsFE = {
  profilePicture: {
    show: true,
    text: 'Profile Picture'
  },
  seniorities: {
    show: true,
    text: 'Experience Level'
  },
  skillPreferences: {
    show: true,
    text: 'Tech Stack/Tooling'
  },
  location: {
    show: true,
    text: 'Current Location'
  },
  workEligibilities: {
    show: true,
    text: 'Work Eligibility'
  },
  phoneNumber: {
    show: true,
    text: ['Phone Number'],
    disabled: false
  },
  remote: {
    show: true,
    text: 'Work Type'
  },
  awards: {
    show: false,
    text: ['Awards', <UpcomingBadge key="awards_badge" />],
    disabled: true
  },
  timeOffset: {
    show: true,
    text: 'Work Timezones'
  },
  publications: {
    show: true,
    text: ['Publications', <UpcomingBadge key="publications_badge" />],
    disabled: true
  }
};
