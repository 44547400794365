import React from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { getPercentage, getShortLastActiveText, getTimeAgo, isEmpty } from 'utils';

import LabelWithIcon from 'components/labelwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { Ellipsis } from 'v2/components/ui/styles';

type Props = {
  responseRate?: string | number;
  lastActive?: Date;
  noResponseRateFallbackText?: string;
  datePosted?: Date;
  lastCheckedDate?: Date;
  industries?: string[];
  hideDateInfo?: boolean;
};

function ActivityInfo({
  responseRate,
  lastActive,
  noResponseRateFallbackText,
  datePosted,
  lastCheckedDate,
  industries,
  hideDateInfo
}: Props) {
  if (!isEmpty(industries)) {
    return (
      <InfoWrapper className="activity_info grey_text">
        <LabelWithIcon icon="icon_company" text={industries!.join(' · ')} />
      </InfoWrapper>
    );
  }
  return (
    <InfoWrapper className="activity_info lightblue_text">
      <ConditionalRender predicate={!hideDateInfo}>
        <ConditionalRender
          predicate={datePosted}
          fallback={
            <span className="activity green_text">Active {getShortLastActiveText(lastActive)}</span>
          }
        >
          <span className="activity green_text">Posted {getTimeAgo(datePosted)}</span>
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender predicate={responseRate}>
        {responseRate === 'N/A' ? (
          <span className="second_metric">{noResponseRateFallbackText}</span>
        ) : (responseRate as number)! >= 0 ? (
          <span className="second_metric">{getPercentage(responseRate)}% responsive</span>
        ) : (
          ''
        )}
      </ConditionalRender>
      <ConditionalRender predicate={lastCheckedDate || datePosted}>
        <span className={ClassNames({ second_metric: !hideDateInfo })}>
          Checked {getTimeAgo(lastCheckedDate || datePosted)}
        </span>
      </ConditionalRender>
    </InfoWrapper>
  );
}

export default ActivityInfo;

export const InfoWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  ${Ellipsis}

  &:hover .tooltip_wrapper {
    display: block;
  }

  .second_metric {
    margin-left: 15px;
  }
`;
