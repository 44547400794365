/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';

import { bindActionCreators } from '@reduxjs/toolkit';

import {
  loadImage,
  emailFormat,
  passwordRequirementsChecker,
  passwordMeetsRequirements
} from 'utils';
import { getResizedImageURL } from 'v2/services/image';
import { setSetupModeLS } from 'utils/localStorage';
import { CompanyUser } from 'types';
import * as fetcher from 'fetcher';
import {
  AppDispatch,
  RootState,
  selectDarkMode,
  selectIsAgencyAccount,
  selectSetupMode,
  setSetupMode
} from 'store';

import Loader from 'components/loader';
import Button from 'components/button';
import ErrorMessage from 'components/errormessage';
import Checkbox from 'components/checkbox';
import FileUploader from 'components/fileuploader';
import Field from 'components/field';
import VideoInput from 'components/videoinput';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import User from 'images/navy-user.png';

import './style.scss';

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

type State = $TSFixMe;

type EditAccountProfileProps = {
  profile: CompanyUser;
  companyName?: string;
  createProfile?: boolean;
  verificationCode?: string;
  login?: (email: string, password: string) => void;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

class EditAccountProfile extends React.Component<EditAccountProfileProps, State> {
  video: $TSFixMe;
  constructor(props: EditAccountProfileProps) {
    super(props);

    const profile = (props as $TSFixMe).profile;
    const isAgency = (props as $TSFixMe).isAgency;

    this.state = {
      loading: false,
      loadingPhoto: false,
      accountName: profile?.memberName || '',
      jobTitle: profile.jobTitle || '',
      accountPhotoURL: profile.memberPhotoURL || '',
      bio: profile.bio || '',
      yearsAtCompany: profile.yearsAtCompany || '',
      techStack: profile.techStack || '',
      experience: profile.experience || '',
      lifeAtCompany: profile.lifeAtCompany || '',
      linkedinURL: profile.linkedinURL || '',
      questionsShown: {
        yearsAtCompany: profile.yearsAtCompany,
        techStack: !isAgency && profile.techStack,
        experience: profile.experience,
        lifeAtCompany: !isAgency && profile.lifeAtCompany
      },
      password: '',
      errorMessage: '',
      termsAccepted: false
    };

    this.video = React.createRef();
  }

  getProfileData() {
    const {
      accountName,
      jobTitle,
      accountPhotoURL,
      yearsAtCompany,
      bio,
      techStack,
      experience,
      linkedinURL,
      lifeAtCompany
    } = this.state;
    const { profile, verificationCode } = this.props;
    const { videoURL } = this.video.current ? this.video.current.state : '';

    return {
      accountName,
      jobTitle,
      accountPhotoURL: accountPhotoURL || profile?.memberPhotoURL,
      verificationCode,
      videoURL,
      yearsAtCompany,
      techStack,
      experience,
      lifeAtCompany,
      bio,
      linkedinURL
    };
  }

  validate() {
    const { accountName, jobTitle, termsAccepted, password } = this.state;
    const { verificationCode, createProfile } = this.props;
    const passwordRequirements = passwordRequirementsChecker(password);
    const passwordIsValid = passwordMeetsRequirements(passwordRequirements);

    if (accountName === '') return 'Please enter your first name';
    else if (emailFormat.test(accountName)) return 'Please enter your name not your email address';
    else if (jobTitle === '') return 'Please enter your job title';
    else if (emailFormat.test(jobTitle)) {
      return 'Please enter your job title not your email address';
    } else if (verificationCode && createProfile && password === '') {
      return 'Please create a password';
    } else if (verificationCode && createProfile && !passwordIsValid) {
      return 'Password must tick all security requirements';
    } else if (verificationCode && createProfile && !termsAccepted) {
      return 'Please accept the terms of service and privacy policy to proceed';
    } else return '';
  }

  async createProfile() {
    const { password } = this.state;
    const { verificationCode, login, profile } = this.props;

    this.setState({ loading: true });

    const errorMessage = this.validate();

    if (errorMessage) this.setState({ errorMessage, loading: false });
    else {
      const userProfile = this.getProfileData();
      const data = await Promise.all([
        fetcher.companyUserProfileUpdate(userProfile),
        fetcher.postUserEmailSubscription('product', true, verificationCode),
        fetcher.postUserEmailSubscription('marketing', true, verificationCode),
        fetcher.postUserEmailSubscription('product_monthly_recap', true, verificationCode)
      ]);

      if (data[0].status === 'success') {
        setSetupModeLS();
        this.props.setSetupMode(true);

        const { status, message } = await fetcher.setUserPassword(verificationCode, password);

        if (status === 'success') {
          this.setState({ loading: false, errorMessage: '' });
          login?.(profile?.email || '', password);
        } else {
          this.setState({ errorMessage: message, loading: false });
        }
      } else {
        this.setState({ errorMessage: data[0].message, loading: false });
      }
    }
  }

  validateFiles(files: $TSFixMe) {
    if (files && files.length) {
      this.setState({ loadingPhoto: true });
      return true;
    }
    return false;
  }

  async uploadPhoto(files: $TSFixMe) {
    const { profile } = this.props;

    let accountPhotoURL = '';

    try {
      const filesUploaded = await fetcher.uploadFiles(files);
      accountPhotoURL = getResizedImageURL(filesUploaded?.[0]?.url, { width: 300 });
      await loadImage(accountPhotoURL);
    } catch (error) {
      accountPhotoURL = profile?.memberPhotoURL || User;
      console.log(error);
    }
    this.setState({ loadingPhoto: false, accountPhotoURL });
  }

  render() {
    const { profile, companyName, createProfile, setupMode, isAgency, darkMode } = this.props;
    const {
      accountName,
      jobTitle,
      accountPhotoURL,
      loadingPhoto,
      loading,
      errorMessage,
      password,
      bio,
      linkedinURL,
      questionsShown,
      yearsAtCompany,
      techStack,
      experience,
      lifeAtCompany,
      termsAccepted
    } = this.state;

    return (
      <div className={ClassNames('user_profile', { border_top: setupMode })}>
        <div className={ClassNames('field', 'photo_field')}>
          {createProfile && (
            <label className="question" htmlFor="photo_uploader">
              Profile Picture <span>(optional)</span>
            </label>
          )}
          <div
            className={ClassNames('photo_wrapper', { no_photo: !accountPhotoURL })}
            style={{
              backgroundImage: `url("${
                getResizedImageURL(accountPhotoURL, { width: 300 }) || User
              }")`
            }}
          >
            {loadingPhoto && (
              <Loader className={ClassNames('photo_loader', 'small', { dark: darkMode })} />
            )}
            <FileUploader
              className={ClassNames('photo_uploader', 'icon_edit', { dark: darkMode })}
              uploaderID="photo_uploader"
              onSelect={files => this.validateFiles(files)}
              onChange={files => this.uploadPhoto(files)}
              accept="image/*"
            />
          </div>
          {!createProfile && <em className="field_note">Show candidates who they're talking to</em>}
        </div>
        <div className="profile_details">
          <div className={ClassNames('field_row', 'two_cols')}>
            <Field
              label="First Name"
              placeholder="Eg. George"
              value={accountName}
              onChange={(value: $TSFixMe) => this.setState({ accountName: value })}
            />
            <Field
              label="Job Title"
              placeholder="Eg. Head of Engineering"
              value={jobTitle}
              onChange={(value: $TSFixMe) => this.setState({ jobTitle: value })}
            />
          </div>
          {createProfile ? (
            <>
              <Field
                label="Email"
                placeholder="Eg. george@moon.com"
                value={profile?.email}
                type="email"
                disabled
              />
              <Field
                label="Create your password"
                onChangeValidation
                placeholder="Make it strong"
                value={password}
                type="password"
                onChange={(value: $TSFixMe) => this.setState({ password: value })}
              />
              <Checkbox
                checkboxID="terms_policy"
                className="accept_terms"
                label={[
                  "I agree to cord's ",
                  <a
                    href={`${domain}/privacy`}
                    key="privacy"
                    aria-label="Open Privacy Policy in a new tab"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="lightblue_link"
                  >
                    Privacy Policy
                  </a>,
                  ' and ',
                  <a
                    href={`${domain}/employer-terms`}
                    key="terms"
                    aria-label="Open Terms of Service in a new tab"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="lightblue_link"
                  >
                    Terms of Service
                  </a>
                ]}
                checked={termsAccepted}
                onChange={checked => this.setState({ termsAccepted: checked })}
              />
            </>
          ) : (
            <>
              <div className="field">
                <label className="question" htmlFor="headline_input">
                  About you <span>(optional)</span>
                  <span className="note">({bio.length}/1000 characters limit)</span>
                </label>
                <textarea
                  id="headline_input"
                  placeholder="A few words to help candidates who start conversations with you understand who you are and what you do..."
                  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
                  rows="5"
                  value={bio}
                  onChange={({ target }) => this.setState({ bio: target.value.substring(0, 1000) })}
                />
              </div>
              <ConditionalRender predicate={!isAgency}>
                <VideoInput
                  key={profile?.videoUrl}
                  ref={this.video}
                  placeholder="Enter your cord video URL"
                  label="Profile Video"
                  description="optional"
                  videoURL={profile?.videoUrl}
                />
              </ConditionalRender>
              <Field
                label={['Linkedin URL', <span key="optional">(optional)</span>]}
                placeholder="Eg. https://www.linkedin.com/company/cord"
                value={linkedinURL}
                onChange={(value: $TSFixMe) => this.setState({ linkedinURL: value })}
              />
              <Field
                label={`How many years have you been working at ${companyName}?`}
                placeholder="Eg. 4 years"
                value={yearsAtCompany}
                hidden={!questionsShown.yearsAtCompany}
                removable
                onChange={(value: $TSFixMe) => this.setState({ yearsAtCompany: value })}
                onRemove={() => {
                  questionsShown.yearsAtCompany = false;
                  this.setState({ questionsShown, yearsAtCompany: '' });
                }}
              />
              <Field
                label="What is your tech stack/tooling on a daily basis?"
                placeholder="Eg. Git, Python, Slack, Trello"
                value={techStack}
                hidden={!questionsShown.techStack}
                removable
                onChange={(value: $TSFixMe) => this.setState({ techStack: value })}
                onRemove={() => {
                  questionsShown.techStack = false;
                  this.setState({ questionsShown, techStack: '' });
                }}
              />
              <Field
                label="How many years of experience you have in total?"
                placeholder="Eg. 6 years"
                value={experience}
                hidden={!questionsShown.experience}
                removable
                onChange={(value: $TSFixMe) => this.setState({ experience: value })}
                onRemove={() => {
                  questionsShown.experience = false;
                  this.setState({ questionsShown, experience: '' });
                }}
              />
              <Field
                label={`How would you describe life at ${companyName}?`}
                placeholder="Eg. Full of fun"
                value={lifeAtCompany}
                hidden={!questionsShown.lifeAtCompany || isAgency}
                removable
                onChange={(value: $TSFixMe) => this.setState({ lifeAtCompany: value })}
                onRemove={() => {
                  questionsShown.lifeAtCompany = false;
                  this.setState({ questionsShown, lifeAtCompany: '' });
                }}
              />
              {(!questionsShown.yearsAtCompany ||
                !questionsShown.techStack ||
                !questionsShown.experience ||
                !questionsShown.lifeAtCompany) && (
                <>
                  <em className="field_note">
                    Other questions candidates ask that you can add to your profile
                  </em>
                  <div className="questions_wrapper">
                    {!questionsShown.yearsAtCompany && (
                      <div
                        className="other_question"
                        onClick={() => {
                          questionsShown.yearsAtCompany = true;
                          this.setState({ questionsShown });
                        }}
                      >
                        How many years have you been working at {companyName}?{' '}
                        <span className="icon_add" />
                      </div>
                    )}
                    {!questionsShown.techStack && !isAgency && (
                      <div
                        className="other_question"
                        onClick={() => {
                          questionsShown.techStack = true;
                          this.setState({ questionsShown });
                        }}
                      >
                        What is your tech stack/tooling on a daily basis?{' '}
                        <span className="icon_add" />
                      </div>
                    )}
                    {!questionsShown.experience && (
                      <div
                        className="other_question"
                        onClick={() => {
                          questionsShown.experience = true;
                          this.setState({ questionsShown });
                        }}
                      >
                        How many years of experience you have in total?{' '}
                        <span className="icon_add" />
                      </div>
                    )}
                    {!questionsShown.lifeAtCompany && !isAgency && (
                      <div
                        className="other_question"
                        onClick={() => {
                          questionsShown.lifeAtCompany = true;
                          this.setState({ questionsShown });
                        }}
                      >
                        How would you describe life at {companyName}? <span className="icon_add" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
        {createProfile && (
          <div className="button_section">
            <Button
              text="Join your team on cord"
              className="full_width_button"
              action={() => this.createProfile()}
              loading={loading}
            />
            <div className="bottom_note">
              We will send you a monthly product recap email to enrich your experience using cord.
              You can opt-out at any point in your account settings.
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  darkMode: selectDarkMode(state),
  setupMode: selectSetupMode(state),
  isAgency: selectIsAgencyAccount(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators({ setSetupMode }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  EditAccountProfile
);
