import { LocationInterface } from '@cohiretech/common-types';

import { SearchItem, SearchItems } from 'types';
import { companySearchFilters as SEARCH_FILTERS } from 'fetcher';
import { toArray } from 'utils/array';
import { isPartialMatch } from 'utils/object';

export const applySearchItem = (
  { attribute, label, value }: SearchItem,
  searchFilters: SearchItems,
  onSearch: (searchItems: SearchItems) => Promise<void>
) => {
  let searchItems = [...searchFilters];

  if (existsInSearchItems({ attribute, value }, searchItems)) return;

  if (filterShouldGetReplaced(attribute)) {
    searchItems = searchItems.filter(
      ({ attribute: filterAttribute }) => filterAttribute !== attribute
    );
  }

  searchItems.push({ attribute, label, value });
  onSearch(searchItems);
};

const filterShouldGetReplaced = (attribute: string) =>
  [
    SEARCH_FILTERS.gender.attribute,
    SEARCH_FILTERS.sortBy.attribute,
    SEARCH_FILTERS.radius.attribute,
    SEARCH_FILTERS.candidatePool.attribute
  ].includes(attribute);

type PartialSearchItem = Pick<SearchItem, 'attribute' | 'value'> &
  Partial<Pick<SearchItem, 'label'>>;

export const existsInSearchItems = (
  items: PartialSearchItem | PartialSearchItem[],
  searchItems: SearchItems
) => {
  return toArray(items).some(({ attribute, value }) =>
    searchItems.some(item => {
      if (attribute !== item.attribute) return false;

      switch (attribute) {
        case SEARCH_FILTERS.location.attribute:
          return (
            (value as LocationInterface).formatted_address ===
            (item.value as LocationInterface).formatted_address
          );
        case SEARCH_FILTERS.workEligibility.attribute:
          return isPartialMatch(value, ['location', 'type'])(item.value);
        default:
          return value === item.value;
      }
    })
  );
};
