import React from 'react';

import { Seniority } from '@cohiretech/common-types';

import { getSeniorityOptions } from 'fetcher';

import Selectquestion, { SelectQuestionProps } from '.';

const SENIORITY_OPTIONS = getSeniorityOptions();

type SelectSeniorityLevelProps = {
  selected: string[];
  setSeniorities: (seniorities: string[]) => void;
} & Pick<
  SelectQuestionProps,
  'question' | 'mobileColumns' | 'restrictionLabel' | 'noneSelectedMessage' | 'formSubmitted'
>;

export default function SelectSeniorityLevel({
  restrictionLabel = 'adjacent levels',
  setSeniorities,
  ...props
}: SelectSeniorityLevelProps) {
  const selectSeniority = (seniority: Seniority) => {
    const seniorities = [...props.selected];

    const index = seniorities.findIndex(s => s === seniority);
    const allowedOptions = getAllowedSeniorityOptions(seniorities);

    if (index > -1 && !(index === 1 && seniorities.length === 3)) seniorities.splice(index, 1);
    else if (seniorities.length < 3 && allowedOptions.indexOf(seniority) > -1) {
      seniorities.push(seniority);
    }

    setSeniorities(seniorities);
  };

  return (
    <Selectquestion
      options={SENIORITY_OPTIONS}
      columns={3}
      maxSelection={3}
      restrictionLabel={restrictionLabel}
      multiple
      toggle={selectSeniority}
      {...props}
    />
  );
}

const getAllowedSeniorityOptions = (seniorities: string[]) => {
  let allowed: Seniority[] = [];

  if (seniorities.length === 0) allowed = SENIORITY_OPTIONS.map(({ value }) => value);
  else {
    seniorities.forEach(item => {
      const index = SENIORITY_OPTIONS.findIndex(({ value }) => value === item);
      const previous = index - 1 > -1 ? SENIORITY_OPTIONS[index - 1].value : '';
      const next = index + 1 < SENIORITY_OPTIONS.length ? SENIORITY_OPTIONS[index + 1].value : '';

      if (previous && seniorities.indexOf(previous) === -1) allowed.push(previous);
      if (next && seniorities.indexOf(next) === -1) allowed.push(next);
    });
  }

  return allowed;
};
