import React, { useState } from 'react';
import styled from 'styled-components';

import { SELF_SERVE_DEMO_LINK } from 'consts';
import { useDarkMode } from 'hooks/useDarkMode';
import { randomInt } from 'utils/math';

import HubSpotCalendar from 'views/landing/requestdemo/hubspotcalendar';

import StepBubbles from 'components/stepbubbles';

import Avatar from 'v2/components/ui/atoms/Avatar';

import typography from 'css/base/_typography.module.scss';

import StarlingLight from 'images/white-starling-bank.svg';
import StarlingDark from 'images/blue-starling-bank.svg';
import GoustoLight from 'images/white-gousto.png';
import GoustoDark from 'images/blue-gousto.png';
import LoveHolidaysLight from 'images/white-loveholidays.svg';
import LoveHolidaysDark from 'images/blue-loveholidays.svg';
import RVULight from 'images/white-rvu.svg';
import RVUDark from 'images/blue-rvu.svg';
import MuzzLight from 'images/white-muzz.svg';
import MuzzDark from 'images/blue-muzz.svg';
import CleoLight from 'images/white-cleo.svg';
import CleoDark from 'images/blue-cleo.svg';
import JakeStarling from 'images/jake_starling.png';
import SamGousto from 'images/sam_gousto.png';
import OscarLoveHolidays from 'images/oscar_loveholidays.png';
import LukeCleo from 'images/luke_cleo.png';
import FroyaRVU from 'images/froya_rvu.png';
import ShahzadMuzz from 'images/shahzad_muzz.png';

const photoURLDimensions = { width: 30, height: 30 };
const COMPANY_TESTIMONIALS = [
  {
    QUOTE:
      'We made 10 hires in six months, reduced our time-to-hire to 25 days, and saved approximately £15,000 per hire compared to agency costs.',
    NAME: 'Jake, Talent Acquisition Partner',
    LOGO: { light: StarlingLight, dark: StarlingDark },
    PHOTO_URL: JakeStarling,
    HEIGHT: 28
  },
  {
    QUOTE:
      'cord is our go-to platform. We made 10 quality hires, increased our response rate to 48% and saved £100,000 in recruitment costs.',
    NAME: 'Sam, Tech Talent Lead',
    LOGO: { light: GoustoLight, dark: GoustoDark },
    PHOTO_URL: SamGousto,
    HEIGHT: 28
  },
  {
    QUOTE: 'We found a diverse pool of candidates, made 8 hires and saved £120,000',
    NAME: 'Oscar, Head of Talent Acquisition at loveholidays',
    LOGO: { light: LoveHolidaysLight, dark: LoveHolidaysDark },
    PHOTO_URL: OscarLoveHolidays,
    HEIGHT: 32
  },
  {
    QUOTE:
      'We made 6 hires on cord. The cost per hire is impressively low, at £2,000. Far better than other recruitment methods.',
    NAME: 'Luke, Talent Lead',
    LOGO: { light: CleoLight, dark: CleoDark },
    PHOTO_URL: LukeCleo,
    HEIGHT: 32
  },
  {
    QUOTE: 'We’ve made 11 high-quality senior hires within 12 months, saving us over £160,000',
    NAME: 'Frøya, Lead Talent Acquisition Partner',
    LOGO: { light: RVULight, dark: RVUDark },
    PHOTO_URL: FroyaRVU,
    HEIGHT: 26
  },
  {
    QUOTE:
      'We made 20 hires, drastically decreased our time to hire and found a sustainable source of quality engineers',
    NAME: 'Shahzad, Founder & CEO',
    LOGO: { light: MuzzLight, dark: MuzzDark },
    PHOTO_URL: ShahzadMuzz,
    HEIGHT: 20
  }
];

export default () => {
  const darkMode = useDarkMode();
  const [currentTestimonial, setCurrentTestimonial] = useState(
    randomInt(COMPANY_TESTIMONIALS.length)
  );

  const { HEIGHT, LOGO, PHOTO_URL, QUOTE, NAME } = COMPANY_TESTIMONIALS[currentTestimonial];
  const CompanyLogo = LOGO[`${darkMode ? 'light' : 'dark'}`];

  return (
    <>
      <BlocksWrapper>
        <HubSpotCalendar link={SELF_SERVE_DEMO_LINK} />
      </BlocksWrapper>
      <TestimonialsCarousel>
        <span className="icon_quote" />
        <img
          key="_logo"
          src={CompanyLogo}
          alt="test logo"
          height={HEIGHT}
          className="company_logo"
          loading="lazy"
        />
        <TheQuote>"{QUOTE}"</TheQuote>
        <QuoteInfo>
          <Avatar
            photoURL={PHOTO_URL}
            resizePhotoDimensions={photoURLDimensions}
            width={24}
            alt={`Testimonial from ${NAME}`}
          />
          {NAME}
        </QuoteInfo>
        <StepBubbles
          steps={COMPANY_TESTIMONIALS}
          currentStep={currentTestimonial}
          color={darkMode ? 'white' : 'dark_blue'}
          align="center"
          onStepChange={(i: $TSFixMe) => setCurrentTestimonial(i)}
        />
      </TestimonialsCarousel>
    </>
  );
};

const BlocksWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: top;
  gap: 20px;
`;

const TheQuote = styled.div`
  margin: var(--spacing-03) 0;
  font-size: ${({ theme: { typography } }) => typography.smallheader};
`;

const TestimonialsCarousel = styled.div`
  text-align: center;

  > span {
    display: block;
    margin-bottom: 15px;
    font-size: 30px;
  }
`;

const QuoteInfo = styled.div`
  font-weight: ${typography.black};
  margin: 10px 0 15px;

  .profile_picture {
    margin-right: 8px;
  }
`;
