import axios from 'axios';

import { ListingInterface, WorkEligibility } from '@cohiretech/common-types';

import { APIResponse } from 'types';

const POSITION = process.env.REACT_APP_POSITION!;

export type GetWorkEligibilitiesParams = Partial<
  Pick<
    ListingInterface,
    | 'locationCity'
    | 'visaSponsorshipAvailable'
    | 'remote'
    | 'remoteLocationContinents'
    | 'remoteLocationCountries'
  >
>;

export const getWorkEligibilitiesForListing = async (
  params: GetWorkEligibilitiesParams
): Promise<APIResponse<WorkEligibility[]>> => {
  try {
    const { data } = await axios.post<{ data: WorkEligibility[] }>(
      `${POSITION}/work-eligibilities`,
      params
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
