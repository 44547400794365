import React, { ReactNode } from 'react';
import { NavLink as Link, NavLinkProps } from 'react-router-dom';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { noop } from 'utils/fn';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import InlineLoader from 'v2/components/ui/atoms/InlineLoader';
import { GradientText, GradientTextProps } from 'v2/components/ui/styles/Utilities';

export type LinkWithIconProps = {
  id?: string;
  icon: string;
  iconPosition?: 'right' | 'left';
  text: ReactNode;
  color?: 'primary' | 'red' | 'grey' | 'white' | 'dark' | 'cord_gradient';
  action?: (e: React.MouseEvent) => void;
  link?: NavLinkProps['to'];
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  stopEventPropagation?: boolean;
};

function LinkWithIcon({
  id,
  icon,
  iconPosition = 'left',
  text,
  color = 'primary',
  action,
  link = '',
  disabled = false,
  loading,
  className,
  stopEventPropagation
}: LinkWithIconProps) {
  const handleAction = (e: React.MouseEvent) => {
    if (stopEventPropagation) e.stopPropagation();
    action?.(e);
  };

  if (link) {
    return (
      <LinkWrapper
        id={id}
        className={ClassNames('link_with_icon', { disabled }, color, className)}
        to={link}
        onClick={disabled ? noop : e => handleAction(e)}
        iconposition={iconPosition}
      >
        <span className={ClassNames('icon', icon)} />
        <span className="link_text">{text}</span>
      </LinkWrapper>
    );
  } else {
    return (
      <ButtonWrapper
        id={id}
        className={ClassNames('link_with_icon', { disabled }, color, className)}
        onClick={disabled ? noop : e => handleAction(e)}
        iconposition={iconPosition}
        gradientTextColour={color === 'cord_gradient' ? 'cordGradient' : undefined}
      >
        <ConditionalRender predicate={!loading} fallback={<InlineLoader />}>
          <span className={ClassNames('icon', icon)} />
        </ConditionalRender>
        <span className="link_text">{text}</span>
      </ButtonWrapper>
    );
  }
}

export default LinkWithIcon;

export const Wrapper = css`
  ${({ theme: { typography, colours } }) => css`
    span {
      transition: all 0.2s ease-in-out;
    }

    font-weight: ${typography.semibold};
    display: flex;
    align-items: center;
    gap: 5px;

    &.primary {
      color: ${colours.primaryColour};

      &:hover,
      &:focus-visible {
        span {
          color: ${colours.primaryDarkShade};
        }
      }
    }

    &.red {
      color: ${colours.errorColour};

      &:hover,
      &:focus-visible {
        span {
          color: ${colours.errorDarkShade};
        }
      }
    }

    &.grey {
      color: var(--text-accent-tertiary-base);

      &:hover,
      &:focus-visible {
        span {
          color: var(--text-accent-tertiary-hover);
        }
      }
    }

    &.dark {
      color: var(--text-accent-secondary-base);

      &:hover,
      &:focus-visible {
        span:not(.badge, .badge span) {
          color: var(--text-accent-secondary-hover);
        }
      }
    }

    &.cord_gradient span {
      ${GradientText}
    }

    &.white {
      color: white;
    }

    .icon {
      font-weight: ${typography.black};
      font-size: 20px;

      &.icon_add {
        font-size: 18px;
      }
    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  `}
`;

type WrapperProps = {
  iconposition: LinkWithIconProps['iconPosition'];
} & GradientTextProps;

const ButtonWrapper = styled.button<WrapperProps>`
  ${Wrapper}
  ${props =>
    props?.iconposition === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
`;

const LinkWrapper = styled(Link)<WrapperProps>`
  ${Wrapper}
  ${props =>
    props?.iconposition === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
`;
