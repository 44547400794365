import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { InvalidFieldStyling } from './FieldValidation';

type InputWrapperProps = { disabled?: boolean; invalidInput: boolean };

const FOCUSED_INPUT_WRAPPER_BORDER_WIDTH = '2px';

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  border: 1px solid var(--border-input-base);
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0 0.75rem;

  ${InvalidFieldStyling}

  &:focus-within {
    border: ${FOCUSED_INPUT_WRAPPER_BORDER_WIDTH} solid var(--border-input-focused);
  }

  > label [class^='icon_'] {
    vertical-align: text-top;
  }

  input {
    margin: 0;
    border: 0;
    padding: 0 0.25rem;

    &:focus {
      border: 0 !important;
      outline: 0 !important;
    }
  }

  &.with_selected_items {
    flex-wrap: wrap;
    padding: 0 0.25rem;

    .selected_items {
      padding: 0.25rem 0;
      gap: 0.25rem;
    }

    input {
      flex: 1 0 50%;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: var(--background-input-disabled);
    `}
`;

export const FlexInputWrapper = styled(InputWrapper)<Pick<CSSProperties, 'borderRadius'>>`
  min-width: 0;
  display: flex;
  align-items: center;
  flex: 1 0 0;
  padding-right: 0;

  &,
  input {
    border-radius: ${({ borderRadius }) => borderRadius || '0.5rem'};
  }
`;
