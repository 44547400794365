import React, { useCallback } from 'react';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { debounce } from 'utils/fn';

import { TabType } from 'components/tabs';
import Button from 'components/button';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { SearchBarTab, useSearchBarContext } from '../../SimpleSearchBar.context';

import {
  SearchBarTabs,
  SearchBox,
  SearchInputButton,
  SearchInputButtonContent,
  SearchInputContainer
} from './SearchBarContols.styled';

const SEARCH_BAR_TABS: TabType<SearchBarTab>[] = [
  { title: 'Start new search', value: 'new' },
  { title: 'Saved searches', value: 'saved' }
];

export default function SearchBarControls() {
  const isMobile = useMedia([mediaQueries.mobile]);
  const {
    focused,
    currentTab,
    setFocused,
    setKeywordState,
    setCurrentTab,
    startNewSearch,
    handleKeyDown
  } = useSearchBarContext();
  const placeholder = 'Search positions';

  const handleInputUpdate = useCallback(
    debounce((value: string) => {
      if (value && currentTab === 'saved') setCurrentTab('new');
      setKeywordState({ keyword: value, cursor: 0 });
    }, 200),
    [currentTab]
  );

  return (
    <ConditionalRender
      predicate={focused}
      fallback={
        <SearchInputButton id="search_input_button" onClick={() => setFocused(true)}>
          <SearchInputButtonContent>
            <span className="icon_search" />
            <ConditionalRender predicate={!isMobile}>{placeholder}</ConditionalRender>
          </SearchInputButtonContent>
        </SearchInputButton>
      }
    >
      <SearchInputContainer>
        <SearchBox
          className="search_box"
          placeholder={placeholder}
          clearIcon="icon_close"
          autoFocus
          alwaysFocused
          onKeyDown={handleKeyDown(startNewSearch)}
          onUpdate={handleInputUpdate}
        />
        <ConditionalRender predicate={isMobile}>
          <Button text="Cancel" buttonStyle="ghost_dark_blue" action={() => setFocused(false)} />
        </ConditionalRender>
      </SearchInputContainer>
      <ConditionalRender predicate={isMobile}>
        <SearchBarTabs
          current={currentTab}
          tabs={SEARCH_BAR_TABS}
          onChange={({ value }) => setCurrentTab(value as SearchBarTab)}
          appearance="underlined"
        />
      </ConditionalRender>
    </ConditionalRender>
  );
}
