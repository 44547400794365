import React, { ReactNode, useEffect } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import MultiStageToggle from 'components/multistagetoggle';
import Tooltip, { TooltipWrapper } from 'components/tooltip';

import CompanyCard, { CompaniesListStyling } from 'v2/components/ui/molecules/CompanyCard';
import ConditionalRender from 'v2/components/utility/ConditionalRender';
import SkeletonCompanyCards from 'v2/components/ui/molecules/SkeletonCompanyCards';

import { AwardsPageParams, SizeParam } from '../Awards.helpers';

import { useLeaderboard } from './Leaderboard.helpers';

const COMPANY_SIZE_STAGES: { component: ReactNode; stage: SizeParam }[] = [
  {
    component: (
      <TooltipWrapper>
        Small companies<Tooltip>1-50 company size</Tooltip>
      </TooltipWrapper>
    ),
    stage: 'small-companies'
  },
  {
    component: (
      <TooltipWrapper>
        Mid-sized companies<Tooltip>51-500 company size</Tooltip>
      </TooltipWrapper>
    ),
    stage: 'midsize-companies'
  },
  {
    component: (
      <TooltipWrapper>
        Large companies<Tooltip>501+ company size</Tooltip>
      </TooltipWrapper>
    ),
    stage: 'large-companies'
  }
];

export default function Leaderboard() {
  const history = useHistory();
  const { hash } = useLocation();
  const params = useParams<AwardsPageParams>();
  const { loading, companies } = useLeaderboard(params);

  const { award, size } = params;
  const metricsCount = award === 'diversity-advocate' ? 1 : 2;

  useEffect(() => {
    if (loading) return;

    const companyID = hash.replace('#', '');

    if (companyID) {
      const element = document.getElementById(`company_card_${companyID}`);

      if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [loading]);

  const handleStateSelect = (stage: SizeParam) => {
    history.push(generatePath('/awards/:season/:award/:size', { ...params, size: stage }));
  };

  if (!companies && !loading) return null;

  return (
    <LeaderboardCont>
      <MultiStageToggle
        type="pills_toggle"
        stages={COMPANY_SIZE_STAGES}
        currentStage={size}
        onStageSelect={handleStateSelect}
      />
      <div className="companies_list">
        <ConditionalRender
          predicate={!loading}
          fallback={<SkeletonCompanyCards amount={20} iconCount={metricsCount} />}
        >
          {companies.map((props, idx) => (
            <LeaderboardCard
              key={props.company.id}
              className="leaderboard_card"
              ranking={idx + 1}
              showShareButton
              {...props}
            />
          ))}
        </ConditionalRender>
      </div>
    </LeaderboardCont>
  );
}

const LeaderboardCont = styled.div`
  .multi_stage_toggle {
    margin: 0.75rem auto 3.25rem;

    .multi_stage_toggle_item {
      font-weight: ${({ theme: { typography } }) => typography.semibold};
    }
  }

  .companies_list {
    ${CompaniesListStyling}
  }
`;

const LeaderboardCard = styled(CompanyCard)`
  &.leaderboard_card {
    padding-right: 1.25rem;

    .company_info {
      align-items: center;

      .company_location {
        font-size: ${({ theme: { typography } }) => typography.small};
        opacity: 0.6;
      }
    }

    .metrics {
      gap: 1.25rem;
    }
  }
`;
