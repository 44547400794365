import styled, { css } from 'styled-components';

import { ColourWay } from 'types';

type UIBlockProps = {
  borderRadius?: string;
  margin?: string;
  shadow?: boolean;
  type?: Extract<ColourWay, 'lightblue'>;
};

// Todo: Refactor and use themes for UIBlock, if this color confirmed
const LightBlue = css`
  background: linear-gradient(
    88deg,
    rgba(95, 170, 231, 0.08) 6.21%,
    rgba(75, 124, 219, 0.08) 98.58%
  );
`;

export const UIBlock = styled.div<UIBlockProps>`
  ${({ theme, borderRadius = '0.75rem', margin, shadow = true }) => css`
    background-color: ${theme.container};
    margin: ${margin || 0};
    border-radius: ${borderRadius};
    box-shadow: ${shadow ? theme.fn.shadow() : 'none'};
  `};

  ${({ type }) => type === 'lightblue' && LightBlue};
`;
