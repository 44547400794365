import React from 'react';
import styled, { css } from 'styled-components';

import { PricingCountry } from 'types';
import { segmentTrack, trackBookDemoEvents } from 'tracking-utils';
import { setCompanyTracking } from 'fetcher';
import { checkIfESSUProgramPage, isFoundersPage, isSanFranLandingPage } from 'utils/url';
import { FALLBACK_OVERAGE_PRICES } from 'consts';
import useLocalisation from 'hooks/useLocalisation';
import { useDarkMode } from 'hooks/useDarkMode';

import ROIVideo from 'components/roivideo';
import PageSection from 'components/pagesection';
import Accordion from 'components/accordion';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

import {
  getFAQsOnESSUPage,
  getFAQsOnFounders,
  getFAQsOnPricing,
  getFAQsOnSanFranPage,
  getPayPerHireFAQs
} from './helper';

export type FAQSectionProps = {
  isNewUser?: boolean;
  pricingVer?: 3 | 4 | 5;
  pricingCountry?: PricingCountry;
  showROIButtons?: 'both' | 'single' | 'none';
  isPayPerHire?: boolean;
};

export default function FAQSection({
  isNewUser,
  pricingVer,
  pricingCountry,
  showROIButtons = 'none',
  isPayPerHire
}: FAQSectionProps) {
  const darkMode = useDarkMode();
  const country = useLocalisation(pricingCountry) as PricingCountry;
  const overagePrice = FALLBACK_OVERAGE_PRICES[country];

  const showFoundersFAQ = isFoundersPage();
  const getFAQs = showFoundersFAQ
    ? getFAQsOnFounders
    : isPayPerHire
    ? getPayPerHireFAQs
    : checkIfESSUProgramPage()
    ? getFAQsOnESSUPage
    : isSanFranLandingPage()
    ? getFAQsOnSanFranPage
    : getFAQsOnPricing;

  const clickFAQ = (faqID: string) => () => {
    if (isNewUser) {
      segmentTrack('click', {
        category: 'FAQ Viewed',
        label: faqID
      });
    } else setCompanyTracking('faq-viewed', { faqID });
  };

  return (
    <FAQContainer
      className="faq_section"
      widthClassName="min_view"
      title={showFoundersFAQ ? 'Founder Program FAQ' : 'Frequently asked questions'}
      titleClassName="center_alignment"
    >
      {getFAQs(overagePrice, pricingVer).map(question => (
        <FAQItem
          key={question.id}
          {...question}
          onExpand={clickFAQ(question.id)}
          darkMode={darkMode}
        />
      ))}
      <ConditionalRender predicate={showROIButtons !== 'none'}>
        <ROIVideo
          showBookDemo={showROIButtons === 'both'}
          applyGradientColour={pricingVer && pricingVer > 4}
          handleClickBookDemo={() => trackBookDemoEvents('Book product tour | FAQ', 'click')}
          className={showROIButtons === 'single' ? 'center_alignment' : ''}
        />
      </ConditionalRender>
    </FAQContainer>
  );
}

const FAQContainer = styled(PageSection)`
  &.faq_section {
    padding: 60px 0;
  }

  .min_view {
    padding: 0 20px;
  }

  .page_section_title {
    margin-bottom: 45px;
    font-size: 36px;
  }

  .center_alignment {
    text-align: center;
    margin-top: 1rem;
  }
`;

const FAQItem = styled(Accordion)<{ darkMode?: boolean }>`
  summary {
    justify-content: space-between;
    gap: 0.5rem;

    .arrow_icon {
      position: static;
    }
  }

  ${({ darkMode }) =>
    !darkMode &&
    css`
      border-bottom: 1px solid ${colours.lightFontColour};
    `}

  .founder_requirement {
    margin: 0;

    .middle_dot {
      margin-right: 20px;
    }
  }
`;
