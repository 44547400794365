import React, { useState, MouseEvent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { swipeXAxisOnStart, swipeXAxisOnMove, swipeXAxisOnEnd } from 'swipeControl';

import { PublicStoryDTO } from '@cohiretech/common-types';

import { generateStoryURL, pluralise } from 'utils';
import { tablet } from 'responsiveConfig';

import { FlexContainer } from 'components/commonstyles';
import VideoItem from 'components/videoitem';
import ArrowLink from 'components/arrowlink';
import StepBubbles from 'components/stepbubbles';
import CarouselArrows from 'components/carouselarrows';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

const USAGE: {
  key: keyof PublicStoryDTO['usageMetrics'];
  singularlabel: string;
  pluralLabel: string;
  icon: string;
}[] = [
  { key: 'hires', singularlabel: 'direct hire', pluralLabel: 'direct hires', icon: 'hired' },
  {
    key: 'sourced',
    singularlabel: 'person sourced',
    pluralLabel: 'people sourced',
    icon: 'search'
  },
  { key: 'positions', singularlabel: 'position', pluralLabel: 'positions', icon: 'article' },
  { key: 'users', singularlabel: 'user', pluralLabel: 'users', icon: 'user' }
];

type ConditionalProps =
  | ({ isStoryPage: true } & Partial<RouteComponentProps>)
  | ({ isStoryPage?: false } & Pick<RouteComponentProps, 'history' | 'location'>);

type Props = {
  stories: PublicStoryDTO[];
  darkMode: boolean;
} & ConditionalProps;

export default function StoryCarousel({
  stories,
  darkMode,
  isStoryPage,
  history,
  location
}: Props) {
  const [currentIdx, setCurrentIdx] = useState(0);
  const story = stories[currentIdx];
  const {
    title,
    coverPhotoURL,
    videoURL,
    highlights,
    storyLogo,
    usageMetrics,
    companyName,
    locationCity
  } = story;
  const usageWithData = USAGE.filter(({ key }) => !!usageMetrics?.[key]);

  useEffect(() => {
    setCurrentIdx(0);
  }, [stories]);

  const handleClickRightPanel = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as Element;

    const isStepClicked = target.closest('.steps');
    const isArrowClicked = target.closest('.carousel_arrows');

    if (isStepClicked || isArrowClicked) return;

    const { storyID, companyName } = story;

    history!.push(generateStoryURL(location!, { id: storyID, companyName }, true));
  };

  const handleClickArrow = (direction: 'prev' | 'next') => {
    const lastIdx = stories.length - 1;

    if (direction === 'prev') setCurrentIdx(currentIdx ? currentIdx - 1 : lastIdx);
    else setCurrentIdx(currentIdx === lastIdx ? 0 : currentIdx + 1);
  };

  return (
    <CSCarousel
      className="page_view"
      dark={darkMode}
      onTouchStart={e => swipeXAxisOnStart(e)}
      onTouchMove={e => swipeXAxisOnMove(e)}
      onTouchEnd={e =>
        swipeXAxisOnEnd(
          e,
          () => handleClickArrow('next'),
          () => handleClickArrow('prev')
        )
      }
    >
      <RightPanel
        className="right_panel"
        isStoryPage={isStoryPage}
        {...(isStoryPage ? {} : { onClick: handleClickRightPanel })}
      >
        {isStoryPage && videoURL ? (
          <VideoItem
            videoUrl={videoURL}
            showVideoPlayer
            fallbackPhotoURL={coverPhotoURL}
            youtubeOptions={{ width: '100%', title: `${companyName} story video` }}
          />
        ) : (
          <img className="cover_photo" src={coverPhotoURL} alt={companyName} loading="lazy" />
        )}
        {!isStoryPage && (
          <ContentWrapper className="content_wrapper">
            <FlexContainer className="header" justifyContent="space-between" alignItems="normal">
              <img src={storyLogo} alt={`${companyName}_logo`} className="company_logo" />
              <span className={videoURL ? 'icon_video' : 'icon_article'} />
            </FlexContainer>
            <div className="footer">
              <div className="story_title">{title}</div>
              {/* Doesn't need to add onClick function as handleClickRightPanel will take users to the customer story page */}
              <ArrowLink
                onClick={() => {
                  return;
                }}
              >
                {videoURL ? 'Watch the video' : 'Read the story'}
              </ArrowLink>
              <FlexContainer justifyContent="space-between" alignItems="flex-end">
                <StepBubbles
                  steps={stories}
                  currentStep={currentIdx}
                  onStepChange={step => setCurrentIdx(step)}
                  color="white"
                />
                <CarouselArrows
                  onPrev={() => handleClickArrow('prev')}
                  onNext={() => handleClickArrow('next')}
                  color="white"
                  haveBorder
                />
              </FlexContainer>
            </div>
          </ContentWrapper>
        )}
      </RightPanel>
      <LeftPanel className="left_panel">
        <div className="highlights">
          {highlights.map(({ title, description }, idx) => (
            <div key={`highlight-${idx}`} className="highlight_item">
              <h2>{title}</h2>
              {description}
            </div>
          ))}
        </div>
        {usageWithData.length ? (
          <div className="usage">
            <h3>cord usage</h3>
            {usageWithData.map(({ key, singularlabel, pluralLabel, icon }) => (
              <div key={`usage_metric_${key}`} className="usage_item">
                <span className={`icon_${icon}`} />
                {pluralise(usageMetrics[key] as number, singularlabel, pluralLabel, true)}
              </div>
            ))}
            <div className="usage_item">
              <span className="icon_map" />
              {locationCity}
            </div>
          </div>
        ) : null}
        {!isStoryPage && (
          <StepBubbles
            steps={stories}
            currentStep={currentIdx}
            onStepChange={step => setCurrentIdx(step)}
            color={darkMode ? 'white' : 'dark_blue'}
          />
        )}
      </LeftPanel>
    </CSCarousel>
  );
}

const CSCarousel = styled.div<{ dark: boolean }>`
  height: 670px;
  margin: 20px auto !important;
  padding: 0 !important;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 50px;
  box-shadow: 0px 0px 30px ${({ dark }) => (dark ? colours.darkDropshadow : colours.dropshadow)};
  text-align: left;

  .step.active {
    width: 24px;
    border-radius: 20px;
  }

  @media only screen and (max-width: ${tablet}) {
    height: auto;
    display: block;
    border-radius: 30px;
  }
`;

const LeftPanel = styled.div`
  width: calc(25% - 60px);
  padding: 50px;
  position: relative;

  .highlight_item {
    margin-bottom: 40px;

    h2 {
      margin: 0 auto 5px;
      font-size: 40px;
      font-weight: ${typography.black};
    }
  }

  .usage {
    position: absolute;
    bottom: 50px;

    h3 {
      margin-bottom: 5px;
      font-size: ${typography.normal};
    }

    .usage_item {
      line-height: 33px;

      span[class^='icon_'] {
        margin-right: 10px;
        font-size: ${typography.smallheader};
        vertical-align: middle;
      }
    }
  }

  .steps {
    display: none;
  }

  @media only screen and (max-width: ${tablet}) {
    width: calc(100% - 32px);
    padding: 24px 0 32px 32px;

    .highlight_item {
      width: 85%;
      margin-bottom: 20px;

      h2 {
        font-size: 30px;
      }
    }

    .usage {
      display: none;
    }

    .steps {
      margin-top: 45px;
      padding-right: 32px;
      display: block;
      text-align: center;
    }
  }
`;

const RightPanel = styled.div<{ isStoryPage?: boolean }>`
  width: 75%;
  height: auto;
  position: relative;
  color: white;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;

  .video_wrapper,
  .content_wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .video_wrapper,
  .cover_photo {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }

  @media only screen and (max-width: ${tablet}) {
    width: 100%;
    min-height: 320px;
    display: flex;

    .video_wrapper,
    .cover_photo {
      height: auto !important;
    }

    .arrow_link {
      display: none;
    }
  }

  ${({ isStoryPage }) =>
    !isStoryPage &&
    css`
      background: linear-gradient(0deg, #192d42 0%, #192d42 30%, rgba(49, 71, 95, 0.8) 100%);

      .video_wrapper,
      .cover_photo {
        opacity: 0.2;

        .video_play {
          display: none;
        }
      }

      &:hover {
        .video_wrapper,
        .cover_photo {
          opacity: 0.3;
        }

        .footer .arrow_link {
          margin-top: 15px;
          max-height: 25px;
          opacity: 1;
          transition: max-height 0.3s ease-in-out;
        }
      }

      @media only screen and (min-width: ${tablet}) {
        border-radius: 50px;
      }
    `}
`;

const ContentWrapper = styled.div`
  padding: 60px;

  &,
  .footer {
    width: calc(100% - 120px);
  }

  .header {
    height: auto;

    .company_logo {
      max-width: 190px;
      max-height: 60px;
      filter: brightness(0) invert(1);
    }

    .icon_video {
      font-size: 24px;
    }
  }

  .footer {
    position: absolute;
    bottom: 60px;

    .story_title {
      width: 60%;
      font-size: 26px;
      font-weight: ${typography.black};
    }

    .arrow_link {
      display: block;
      color: white;
      max-height: 0;
      opacity: 0;
    }

    .carousel_arrows {
      position: static;
      height: 45px;
    }
  }

  @media only screen and (max-width: ${tablet}) {
    padding: 30px;

    &,
    .footer {
      width: calc(100% - 60px);
    }

    .header .company_logo {
      max-width: 110px;
      max-height: 35px;
    }

    .footer {
      bottom: 30px;

      .story_title {
        width: 100%;
        font-size: ${typography.smallheader};
      }

      .steps,
      .carousel_arrows {
        display: none;
      }
    }
  }
`;
