import styled, { css } from 'styled-components';

export const InvalidFieldStyling = css<{ invalidInput: boolean }>`
  ${({ invalidInput }) =>
    invalidInput &&
    css`
      border-color: ${({ theme }) => theme.colours.errorColour};
      background-color: ${({ theme }) => theme.colours.errorSemiTransparentShade};

      &::placeholder,
      input::placeholder {
        color: ${({ theme }) => theme.colours.errorColour};
      }

      &:focus,
      input:focus {
        border-color: ${({ theme }) => theme.colours.errorColour};
        background-color: transparent;
      }
    `}
`;

export const InvalidDropdownStyling = css<{ invalidInput?: boolean }>`
  &&& {
    ${({ invalidInput }) =>
      invalidInput &&
      css`
        .dropdown_selector {
          border-color: ${({ theme }) => theme.colours.errorColour};
          background-color: ${({ theme }) => theme.colours.errorSemiTransparentShade};

          span {
            color: ${({ theme }) => theme.colours.errorColour};
          }
        }

        &.show {
          .dropdown_selector {
            background-color: transparent;
          }
        }
      `}
  }
`;

export const StyledInput = styled.input<{ invalidInput: boolean }>`
  ${InvalidFieldStyling}
`;

export const StyledNote = styled.span<{ invalidInput: boolean }>`
  color: ${({ theme, invalidInput }) =>
    invalidInput ? theme.colours.errorColour : theme.colours.primaryColour} !important;
`;
