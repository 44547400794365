import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/button';

type Props = {
  darkMode: boolean;
  pathname: RouteComponentProps['location']['pathname'];
};

export default function AdminMenu({ darkMode, pathname }: Props) {
  const renderAdminMenu = (text: string, link: string) => {
    return (
      <div className="admin_menu">
        <div className="right">
          <Button
            text={text}
            link={link}
            buttonStyle={darkMode ? 'stroke_white' : 'stroke_dark_blue'}
          />
        </div>
      </div>
    );
  };

  if (pathname === '/admin/company/stories') {
    return renderAdminMenu('+ New Story', '/admin/company/stories/create');
  } else if (new RegExp('/admin/company/stories/create').test(pathname)) {
    return renderAdminMenu('View Stories', '/admin/company/stories');
  }
  if (pathname === '/admin/company/partnerships') {
    return renderAdminMenu('+ New Partnership', '/admin/company/partnerships/create');
  } else if (new RegExp('/admin/company/partnerships/create').test(pathname)) {
    return renderAdminMenu('View Partnerships', '/admin/company/partnerships');
  } else if (new RegExp('/admin/company/checkout/create').test(pathname)) {
    return renderAdminMenu('View Checkout Links', '/admin/company/checkout');
  } else if (new RegExp('/admin/company/checkout').test(pathname)) {
    return renderAdminMenu('+ New Checkout Page', '/admin/company/checkout/create');
  } else if (pathname === '/admin/tech/sql-editor') {
    return renderAdminMenu('+ New SQL Write query', '/admin/tech/sql-editor/create');
  } else if (new RegExp('/admin/tech/sql-editor/create').test(pathname)) {
    return renderAdminMenu('View SQL queries', '/admin/tech/sql-editor');
  } else {
    return <div />;
  }
}
