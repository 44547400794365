import React from 'react';
import styled from 'styled-components';

import { isValidURL } from 'v2/services/tools/url';

import Dropdown from 'components/dropdown';
import Field from 'components/field';
import LabelWithIcon from 'components/labelwithicon';

import FieldErrorMessage from 'v2/components/ui/molecules/FieldErrorMessage';
import { CandidateATCreatedPosition } from 'v2/components/CandidateATPositionForm';

import { APPLICATION_STAGES, getStageDropdownList } from '../../ApplicationTracker.helpers';
import { useApplicationTrackerContext } from '../../ApplicationTracker.context';

type PrePositionDetailsProps = {
  formSubmitted: boolean;
  updatePosition: (position: Partial<CandidateATCreatedPosition>) => void;
  handleManualEntryClick: () => void;
};

export default function PrePositionDetails({
  formSubmitted,
  updatePosition,
  handleManualEntryClick
}: PrePositionDetailsProps) {
  const { position } = useApplicationTrackerContext();
  const { stage, url } = position || {};
  const { label, icon } = (stage && APPLICATION_STAGES[stage]) || {};

  return (
    <div>
      <div className="field">
        <div className="question">Stage</div>
        <Dropdown
          id="ats_position_stage"
          list={getStageDropdownList()}
          selected={stage}
          selectMessage={
            icon ? <LabelWithIcon icon={icon} text={`Stage: ${label}`} /> : 'Select stage'
          }
          hideSelectedLabel
          onSelect={item => updatePosition({ stage: item.value })}
        />
        <FieldErrorMessage show={!stage && formSubmitted} message="Please select a stage." />
      </div>
      <URLField
        label="Position URL"
        type="url"
        value={url}
        placeholder="Enter the URL of the position you want to import"
        footnote={
          <>
            <FieldErrorMessage
              show={formSubmitted && !!url && !isValidURL(url)}
              message="The position URL provided is not valid."
              noMarginTop
            />
            Don't know the position URL?{' '}
            <button className="link" onClick={handleManualEntryClick}>
              Enter information manually
            </button>
          </>
        }
        formSubmitted={formSubmitted}
        noInputMessage="Position URL can't be left blank"
        onChange={value => updatePosition({ url: value })}
      />
    </div>
  );
}

const URLField = styled(Field)`
  .footnote {
    padding-top: 0.5rem;
    font-size: var(--type-xs);
    font-style: normal;
    color: var(--text-input-note);

    .link {
      color: var(--text-body-primary);
    }

    .field_error_message_wrapper {
      padding-bottom: 0.25rem;
    }
  }
`;
