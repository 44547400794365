import { css } from 'styled-components';

export const colours = {
  primaryColour: 'rgb(78, 154, 217)', // 'var(--color-primary-base)',
  primaryDarkShade: 'var(--color-primary-50)',
  lightPrimaryColour: 'rgb(230, 241, 250)', // 'var(--color-primary-10)',
  darkPrimaryColour: 'var(--color-primary-80)',
  secondaryColour: 'var(--color-grey-60)',
  primaryColourShadow: 'var(--color-transparent-light-primary-40)',
  semiTransparentPrimaryColour: 'var(--color-transparent-light-primary-20)',
  transparentPrimaryColour: 'var(--color-transparent-light-primary-10)',
  purpleGradient: 'var(--gradient-secondary-base)',
  purpleGradientShade: 'var(--gradient-secondary-shade)',
  transparentPurpleGradient: 'var(--gradient-secondary-transparent)',
  darkBlueGradient: 'var(--gradient-primary-shade)',
  cordGradient: 'var(--gradient-primary-base)',
  transparentCordGradient: 'var(--gradient-primary-transparent)',
  fontColour: 'rgb(11, 54, 88)', // 'var(--color-primary-70)',
  fontColourDarkShade: 'var(--color-primary-80)',
  transparentFontColour: 'var(--color-transparent-dark-primary-10)',
  semiTransparentFontColour: 'var(--color-transparent-dark-primary-20)',
  lightFontColour: 'var(--color-grey-10)',
  transparentLightFontColour: 'var(--color-transparent-light-grey-10)',
  inputsColour: 'var(--color-grey-60)',
  inputsDarkShade: 'var(--color-grey-70)',
  lightBgColour: 'rgb(241, 245, 248)', // 'var(--color-grey-10)',
  darkerBgColour: 'rgb(3, 18, 31)', // 'var(--color-primary-100)',
  darkBgColour: 'rgb(8, 26, 42)', // 'var(--color-primary-90)',
  darkBgColourLight: 'var(--color-primary-80)',
  dropshadow: 'var(--color-transparent-dark-primary-10)',
  darkDropshadow: 'rgba(0, 0, 0, 0.5)',
  activeColour: 'var(--color-activity-base)',
  activeLightShade: 'var(--color-activity-40)',
  activeDarkShade: 'var(--color-activity-50)',
  errorColour: 'var(--color-negative-base)',
  errorLightShade: 'var(--color-negative-40)',
  errorSemiTransparentShade: 'var(--color-transparent-negative-20)',
  errorDarkShade: 'var(--color-negative-50)',
  warningColour: 'var(--color-warning-base)',
  warningLightShade: 'var(--color-warning-40)',
  semiTransparentWarningColour: 'var(--color-transparent-warning-20)',
  informationColour: 'var(--color-secondary-base)',
  informationLightShade: 'var(--color-secondary-40)',
  gradientBgColour: 'var(--gradient-primary-base)',
  outlook: '#0070cc',
  googleCalendar: '#4484f1',
  facebook: '#1876F2',
  facebookDarkShade: '#0f67da',
  linkedin: '#0A66C2',
  linkedinDarkShade: '#0755a2',
  whatsapp: '#25D366',
  whatsappDarkShade: '#18bd56',
  twitter: '#1da1f2',
  twitterDarkShade: '#178cd5',
  black: 'var(--color-neutral-black)',
  white: 'rgb(255, 255, 255)' // 'var(--color-neutral-white)'
} as const;

export const grid = {
  companysidebarwidth: '280px',
  formwidth: '450px',
  smallpagewidth: '750px',
  smallextendedpagewidth: '1060px',
  normalpagewidth: '1270px',
  largepagewidth: '1400px',
  positionpagewidth: '840px',
  profilepagewidth: '850px',
  pricingpagewidth: '880px'
} as const;

function extractRGB(str: string) {
  return str.match(/\d+,\s*\d+,\s*\d+/)?.[0] || '0, 0, 0';
}

export const commonFns = {
  opaqueColour: (colour: string, alpha: number = 0.2) => `rgba(${extractRGB(colour)}, ${alpha})`,
  getRadialGradientTextStyling: (colourKey: keyof typeof colours) => css`
    background: ${({ theme: { colours } }) => colours[colourKey]};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  // Adding a thin border around the icon to make it softly border.
  // Use it on it's own when you want to create medium bold icons or with font-weight: bold; if you want extra bold icons
  slightlyBolderIcon: (colourVar: string = 'var(--text-body-primary)') =>
    css`
      text-shadow: 0 0 ${colourVar};
    `
} as const;

export const typography = {
  fontFamily: 'Figtree',
  iconsFamily: 'Cord Icons',
  tiny: '10px',
  mobile: '12px',
  small: '14px',
  normal: '16px',
  smallheader: '18px',
  header: '22px',
  bigheader: '34px',
  biggerheader: '55px',
  hugeheader: '65px',
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 800
} as const;
