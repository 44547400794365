import { OperatingCountry } from '@cohiretech/common-types';

import { US_LOCATION_SEARCH_FILTER } from 'consts';
import { getLocalStorageItem } from 'cookieManager';

import { defaultSearchItems } from './companyUtils';

/**
 * To be deprecated
 **/

const localiseDemoCompany = (demoCompany: boolean) => {
  return demoCompany && isUSByLocalStorage();
};

const localisePayingCompany = (locationCountry?: string, pricingCountry?: OperatingCountry) => {
  const isPricingCountryUS = pricingCountry === OperatingCountry.US;

  const isLocationUS = locationCountry?.includes('United States');

  return isPricingCountryUS && isLocationUS;
};

/**
 * @deprecated
 */
export const determineLocalisationCountry = (
  demoCompany: boolean,
  locationCountry?: string,
  pricingCountry?: OperatingCountry
): OperatingCountry | undefined => {
  if (localiseDemoCompany(demoCompany) || localisePayingCompany(locationCountry, pricingCountry)) {
    return OperatingCountry.US;
  }
};

/**
 * @deprecated
 */
export const getLocalisedDefaultSearchItems = (country: OperatingCountry) => {
  switch (country) {
    case 'US':
      return [...defaultSearchItems, US_LOCATION_SEARCH_FILTER];
    default:
      return defaultSearchItems;
  }
};

/**
 * @deprecated
 */
export const isUSByLocalStorage = () => getLocalStorageItem('country') === 'US';
