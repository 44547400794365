import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Location } from 'history';
import ClassNames from 'classnames';
import styled from 'styled-components';

import {
  OperatingCountry,
  PricingSubscriptionPlan,
  PricingMode,
  Frequency,
  CompanySubscriptionPlan,
  StripeRegion
} from '@cohiretech/common-types';

import {
  NewPricingOptions,
  PricingCountry,
  PricingPlanType,
  PricingOptionsType,
  SubscriptionPlan,
  CurrentPlanDetails,
  UpgradeAction
} from 'types';
import {
  getUserLocalisation,
  companyReactivateAccount,
  getCompanyReactivationPlan,
  getPaymentSessionDetails,
  getPaymentCards,
  companyRequestPlanChange,
  companyUpgradePlan,
  getCompanyUpgradeOptions
} from 'fetcher';
import { isEmpty } from 'utils';
import {
  checkIfSubscriptionExpired,
  isUnlimitedPlan,
  createStripeModule,
  handleUpgradeCompletion
} from 'utils/companyUtils';
import { getAnnualPlanName } from 'utils/pricingUtils';
import { getLocalStorageItem, setLocalStorageItem, getIsDarkMode_FIXME } from 'cookieManager';
import { CHECKOUT_URL_PATHS } from 'consts';

import Badge from 'components/badge';
import CompanyLogos from 'components/companylogos';
import Dropdown from 'components/dropdown';
import Loader from 'components/loader';
import PaymentMethodPopup from 'components/paymentmethodpopup';
import PricingBottomSections from 'components/pricingbottomsections';
import Seo from 'components/seo';
import Tabs from 'components/tabs';

import PricingPlanCard from './pricingplancard';
import { getNewPricingOptions } from './data';

type Props = {
  isPopup?: boolean;
  className?: string | string[];
  title?: string;
  showBookADemo?: boolean;
  showVideoSection?: boolean;
  showStorySection?: boolean;
  showCharitySection?: boolean;
  darkMode: boolean;
  location?: Location;
  companySize?: number;
  bottomCaption?: string | string[] | (string | Element)[];
  showCompanyLogos?: boolean;
  smallBottomCaption?: boolean;
  currentPlanDetails?: CurrentPlanDetails;
  pricingCountry?: PricingCountry;
  availablePlans?: PricingSubscriptionPlan[];
  previousPlan?: SubscriptionPlan;
  showFAQs?: boolean;
  errorMessage?: string;
};

type CaptionType = {
  monthly: string;
  annual: string;
};

const TABS = [
  { title: 'Pay monthly', value: Frequency.monthly },
  { title: ['Pay annually', <Badge key="discount" text="25% off" />], value: Frequency.annual }
];

const CAPTION: CaptionType = {
  monthly: 'For hiring teams who want flexibility. No hiring fees. Cancel anytime.',
  annual: 'For talent teams who want predictability. One flat annual cost.'
};

export default function PricingPlans({
  isPopup,
  className,
  currentPlanDetails,
  title,
  showVideoSection,
  showStorySection,
  showCharitySection,
  bottomCaption,
  showCompanyLogos,
  smallBottomCaption,
  availablePlans,
  pricingCountry,
  ...otherProps
}: Props) {
  const location = useLocation();
  const darkMode = getIsDarkMode_FIXME();
  const isSubscriptionExpired = checkIfSubscriptionExpired(currentPlanDetails?.plan as string);
  const localisedPricingType = location?.pathname?.includes('/us/')
    ? OperatingCountry.US
    : OperatingCountry.UK;

  const [pricingOptions, setPricingOptions] = useState<PricingOptionsType | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] =
    useState<{ plan: NewPricingOptions; period: Frequency } | null>(null);
  const [previousPlan, setPreviousPlan] = useState(otherProps.previousPlan);
  const [planRequested, setPlanRequested] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sessionID, setSessionID] = useState(null);
  const [stripeRegion, setStripeRegion] = useState(StripeRegion.UK);
  const [cards, setCards] = useState([]);
  const [showPaymentMethodPopup, setShowPaymentMethodPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(otherProps.errorMessage || '');
  const [popupErrorMessage, setPopupErrorMessage] = useState('');
  const [pricingType, setPricingType] = useState<PricingCountry>(
    pricingCountry || localisedPricingType
  );
  const [currentTab, setCurrentTab] = useState<Frequency>(
    currentPlanDetails?.billingFrequency || Frequency.monthly
  );

  const onPlanSelect = async (
    plan: NewPricingOptions,
    planDetails: { urlPath: string; mode: PricingMode; action?: UpgradeAction }
  ) => {
    const { urlPath = CHECKOUT_URL_PATHS[plan as NewPricingOptions], mode } = planDetails || {};

    setSelectedPlan({ plan, period: currentTab });
    setButtonLoading(true);

    if (isSubscriptionExpired) {
      if (currentTab === 'annual') {
        const { status } = await companyReactivateAccount(urlPath, mode);
        setButtonLoading(false);
        if (status === 'success') {
          setPlanRequested(true);
          setErrorMessage('');
        } else setErrorMessage('Something went wrong. Try again.');
      } else {
        const [sessionIDData, cardsData] = await Promise.all([
          getPaymentSessionDetails(urlPath, pricingType, 'company/discover', 'reactivation'),
          getPaymentCards()
        ]);
        const newSessionID = sessionIDData?.sessionID;
        const stripeRegion = sessionIDData?.stripeRegion;
        const isReactivatingUSCompanyFromUKStripe =
          sessionIDData?.isReactivatingUSCompanyFromUKStripe;

        if (!newSessionID || !stripeRegion || cardsData.status === 'failure') {
          setButtonLoading(false);
          setErrorMessage(
            !newSessionID || !stripeRegion ? sessionIDData?.message : cardsData.message
          );
          return;
        }

        setSessionID(newSessionID);
        setStripeRegion(stripeRegion);
        setCards(cardsData);
        setButtonLoading(false);
        setLocalStorageItem('reactivationPlan', plan);

        if (cardsData.length === 0 || isReactivatingUSCompanyFromUKStripe === true) {
          proceedToPayment(newSessionID, stripeRegion);
        } else setShowPaymentMethodPopup(true);
      }
    } else if (
      currentPlanDetails?.plan &&
      currentPlanDetails?.plan !== CompanySubscriptionPlan.Basic
    ) {
      if (currentTab === 'annual' || planDetails?.action === 'downgrade') {
        const { action = 'upgrade' } = planDetails;
        const { status } = await companyRequestPlanChange({
          urlPath,
          mode,
          action,
          frequency: currentTab
        });
        setButtonLoading(false);
        if (status === 'success') {
          setPlanRequested(true);
          setErrorMessage('');
        } else setErrorMessage('Something went wrong. Try again.');
      } else {
        const { status } = await companyUpgradePlan(urlPath, mode);

        setButtonLoading(false);

        if (status === 'failure') setErrorMessage('Something went wrong. Try again.');
        else handleUpgradeCompletion(plan, location.pathname);
      }
    } else {
      window.location.href = `/${pricingType.toLowerCase()}/checkout/${urlPath}`;
    }
  };

  const getUserLocation = async () => {
    if (location?.pathname?.includes('/uk/') || location?.pathname?.includes('/us/')) return;

    const preferCountry = await getUserLocalisation();

    if (preferCountry && preferCountry !== OperatingCountry.EU && preferCountry !== pricingType) {
      setPricingType(preferCountry);
    }
  };

  const getPricingOptions = async () => {
    let plans = availablePlans;

    if (isSubscriptionExpired && isEmpty(plans)) {
      const { subscriptionPricing } = await getCompanyReactivationPlan();
      const { previousSubscriptionPlan, availablePlans, pricingCountry } = subscriptionPricing;

      setPricingType(pricingCountry);
      setPreviousPlan(previousSubscriptionPlan?.plan);

      plans = availablePlans;
    } else if (!isSubscriptionExpired && currentPlanDetails?.plan) {
      const { upgrade } = await getCompanyUpgradeOptions();

      plans = upgrade;
    }

    const pricingPlans = getNewPricingOptions(currentPlanDetails, plans, isSubscriptionExpired);
    setLoading(false);
    setPricingOptions(pricingPlans as PricingOptionsType);
  };

  useEffect(() => {
    getPricingOptions();

    if (!isSubscriptionExpired && !pricingCountry) getUserLocation();
    else {
      const reactivationPlan = getLocalStorageItem('reactivationPlan');
      if (reactivationPlan) setSelectedPlan({ plan: reactivationPlan, period: currentTab });
    }
  }, []);

  const proceedToPayment = async (
    sessionId: $TSFixMe,
    stripeRegion: StripeRegion,
    isPaymentMethodPopup?: boolean
  ) => {
    const stripe = createStripeModule(stripeRegion);
    const result = await stripe.redirectToCheckout({ sessionId });

    if (result.error) {
      if (isPaymentMethodPopup) setPopupErrorMessage(result.error.message);
      else setErrorMessage(result.error.message);
    }
  };

  const handleClosePopup = () => {
    setShowPaymentMethodPopup(false);
    setPopupErrorMessage('');
  };

  const getPlanInfo = () => {
    const { plan, period } = selectedPlan!;

    const isGrowthPlusPlan =
      plan === NewPricingOptions.GrowthPlus || plan === NewPricingOptions.GrowthPlusV2;
    const key = isGrowthPlusPlan
      ? plan === NewPricingOptions.GrowthPlus
        ? NewPricingOptions.Growth
        : NewPricingOptions.GrowthV2
      : plan;

    const { label } = pricingOptions![key];
    const { price } =
      pricingOptions![key][pricingCountry!][period].conversations[isGrowthPlusPlan ? 1 : 0];
    const urlPath = CHECKOUT_URL_PATHS[plan];

    return { label, price, urlPath };
  };

  const isPlanSelected = (planName: PricingPlanType) => {
    const { plan, period } = selectedPlan!;
    const isGrowthPlan =
      planName === NewPricingOptions.Growth || planName === NewPricingOptions.GrowthV2;
    const isGrowthPlusPlanSelected =
      plan === NewPricingOptions.GrowthPlus || plan === NewPricingOptions.GrowthPlusV2;
    const annualPlanName = getAnnualPlanName(planName);

    if (period === currentTab) {
      return (
        plan === planName || (isGrowthPlan && isGrowthPlusPlanSelected) || plan === annualPlanName
      );
    }
    return false;
  };

  return (
    <PricingPlansNew className={ClassNames(className, { no_padding: isPopup })} id="plans">
      <div className={ClassNames('center_alignment', { page_view: !isPopup })}>
        {title && <h1 className="heading">{title}</h1>}
        <Tabs
          current={currentTab}
          tabs={TABS}
          onChange={({ value }) => setCurrentTab(value as Frequency)}
        />
        <h2 className={isPopup ? 'pop_up_sub_title' : 'subheading'}>{CAPTION[currentTab]}</h2>
        {!isSubscriptionExpired && (
          <Dropdown
            className="small_arrow' inline_dropdown float_right"
            selected={pricingType}
            list={[
              { label: 'GBP', value: OperatingCountry.UK },
              { label: 'USD', value: OperatingCountry.US }
            ]}
            id="pricing_type_dropdown"
            onSelect={({ value }) => setPricingType(value)}
          />
        )}
        <br className="clear_float" />
        {loading ? (
          <Loader className={ClassNames('medium', { dark: darkMode })} />
        ) : (
          <PricingPlansWrapper>
            {pricingOptions &&
              (Object.keys(pricingOptions) as PricingPlanType[]).map(planName => (
                <PricingPlanCard
                  key={pricingOptions[planName].value}
                  planPeriod={currentTab}
                  pricingType={pricingType}
                  className={isUnlimitedPlan(planName) ? 'grey_background' : ''}
                  darkMode={darkMode}
                  selected={selectedPlan ? isPlanSelected(planName) : false}
                  requested={planRequested}
                  isPreviousPlan={previousPlan === planName}
                  loading={buttonLoading}
                  errorMessage={errorMessage}
                  showDemoLinks={
                    !currentPlanDetails?.plan ||
                    currentPlanDetails?.plan === CompanySubscriptionPlan.Basic
                  }
                  onSelect={(
                    plan: SubscriptionPlan,
                    planDetails: { urlPath: string; mode: PricingMode; action?: UpgradeAction }
                  ) => onPlanSelect(plan as NewPricingOptions, planDetails)}
                  {...pricingOptions[planName]}
                />
              ))}
          </PricingPlansWrapper>
        )}
        {bottomCaption && (
          <h2
            className={ClassNames('subheading', 'bottom_caption', {
              small_text: isPopup || smallBottomCaption
            })}
          >
            {bottomCaption}
          </h2>
        )}
        {showCompanyLogos && <CompanyLogos logoHeight="30" preferredCountry={pricingType} />}
      </div>
      <PricingBottomSections
        showVideoSection={showVideoSection}
        showStorySection={showStorySection}
        showCharitySection={showCharitySection}
        pricingCountry={pricingType}
        isNewUser={!currentPlanDetails?.plan}
      />
      {showPaymentMethodPopup && (
        <PaymentMethodPopup
          planInfo={getPlanInfo()}
          cycle={currentTab}
          pricingType={pricingType}
          cards={cards}
          handleClose={handleClosePopup}
          proceedToPayment={() => {
            proceedToPayment(sessionID, stripeRegion, true);
          }}
          errorMessage={popupErrorMessage}
          setErrorMessage={errorMessage => {
            setPopupErrorMessage(errorMessage);
          }}
        />
      )}
      <Seo script={[{ src: 'https://js.stripe.com/v3/' }]} />
    </PricingPlansNew>
  );
}

const PricingPlansWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  margin: 0.5rem auto 2rem;
  flex-wrap: wrap;
`;

const PricingPlansNew = styled.div`
  .faq_section {
    padding-top: 0;
  }
`;
