import React from 'react';

import * as fetcher from 'fetcher';
import {
  getQueryStringObject,
  passwordRequirementsChecker,
  passwordMeetsRequirements
} from 'utils';

import Button from 'components/button';
import ErrorMessage from 'components/errormessage';
import Seo from 'components/seo';
import Field from 'components/field';

import './style.scss';

type State = $TSFixMe;

export default class PasswordReset extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      password: '',
      success: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      password: '',
      success: false,
      errorMessage: ''
    });
  }

  validatePassword(password: $TSFixMe) {
    const passwordRequirements = passwordRequirementsChecker(password);
    const passwordIsValid = passwordMeetsRequirements(passwordRequirements);

    if (password === '') return 'Please enter a new password';
    else if (!passwordIsValid) return 'Password must tick all security requirements';
    else return '';
  }

  async changePassword(password: $TSFixMe) {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location } = this.props;
    const { search } = location;

    const errorMessage = this.validatePassword(password);

    if (errorMessage) this.setState({ errorMessage });
    else {
      const queryObject = getQueryStringObject(search);
      const forgotCode = (queryObject as $TSFixMe).forgot_code;

      this.setState({ loading: true });

      const { status, message } = await fetcher.updateForgotPassword(forgotCode, password);

      this.setState({
        loading: false,
        success: status === 'success',
        errorMessage: (message && message.message) || ''
      });
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className, location } = this.props;
    const { loading, password, success, errorMessage } = this.state;

    return (
      <div id="candidate_password_reset" className={className}>
        <div className="min_view">
          <h1 className="heading">Password Reset</h1>
          <div className="block">
            <div className="password_reset_wrapper">
              {success ? (
                <div className="success">Your password has been reset, please login</div>
              ) : (
                <div>
                  <Field
                    label="Create a new password"
                    value={password}
                    type="password"
                    onChangeValidation
                    onChange={(value: $TSFixMe) => this.setState({ password: value })}
                  />
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                  <Button
                    text="Reset"
                    loading={loading}
                    action={() => this.changePassword(password)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Seo
          title="Password Reset"
          description="Reset your password and login to cord to connect with startups hiring in the UK, USA and remote across Europe."
          path={location.pathname}
          contentType="website"
        />
      </div>
    );
  }
}
