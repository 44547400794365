import styled, { css } from 'styled-components';

import Dropdown from 'components/dropdown';

import { InvalidDropdownStyling } from '../../styles/FieldValidation';

const FlexboxStyling = css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const RangeContainer = styled.div<{ isFlexbox: boolean }>`
  ${({ isFlexbox, theme: { media } }) =>
    isFlexbox &&
    css`
      ${FlexboxStyling}
      ${media.mobile`
        flex-direction: column;
        align-items: normal;
      `}
    `}
`;

export const Range = styled.div<{ isFlexbox: boolean }>`
  ${({ isFlexbox, theme: { media } }) =>
    isFlexbox
      ? css`
          ${FlexboxStyling}
          flex: 1 0 0;
        `
      : css`
          .range_input {
            flex: 1;
            ${media.mobileMin`
              width: 40%;
            `}
          }
        `}
`;

export const RangeDropdown = styled(Dropdown)<{ invalidInput?: boolean }>`
  flex: 1;
  ${InvalidDropdownStyling}
`;
