import React from 'react';
import { useLocation } from 'react-router';

import { trackSalaryToolCTAClicks, trackSignupClicks } from 'tracking-utils';
import { isForCompaniesPage, isHomePage, isSalaryToolPage } from 'utils/url';

import Button from 'components/button';

type CTAProps = {
  text?: string;
  handleClick?: () => void;
};

const CTAButton = ({ text, handleClick = () => null }: CTAProps) => {
  const isCompaniesPage = isForCompaniesPage();
  const location = useLocation();

  const buttonText = text || (isCompaniesPage ? 'Book a demo' : 'Get started');

  const onClick = () => {
    getAction();
    handleClick();
  };

  return (
    <Button
      text={buttonText}
      className="eighteen_px_font"
      link={{ pathname: getLink(isCompaniesPage), state: { from: location.pathname } }}
      action={onClick}
    />
  );
};

const getLink = (isCompaniesPage: boolean) => {
  if (isCompaniesPage) return '/request-demo';

  return isSalaryToolPage() ? '/salary-tool-signup' : '/signup';
};

const getAction = () => {
  if (isSalaryToolPage()) return trackSalaryToolCTAClicks('SBT - Get started topnav');
  if (isHomePage()) return trackSignupClicks('Homepage - Get started topnav');

  return trackSignupClicks('Get Started');
};

export default CTAButton;
