/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';

import { selectToggler, useAppSelector, useCompanyID, useCompanyType } from 'store';
import { trackEvent } from 'tracking-utils';

type TrackProperties = {
  pathname: string;
  companyType?: string;
  fromElement?: string | null;
  rawString: string | null;
  candidateID: number | null;
  companyID: number | null;
};

const candidateProfileOpen = /\/company\/.+\/u\/candidate\/\d+($|\?)/;
const searchOrStreamOpen = /\/company\/discover\/(search|stream)($|\/\d+|\?.+)/;
const candidateFromURL = /\/u\/candidate\/(\d+)/;

const ProfileInformationTracker = () => {
  const { pathname } = useLocation() || {};
  const { companyType } = useCompanyType();
  const companyID = useCompanyID();
  const isLoggerEnabled = useAppSelector(selectToggler('dev.logger.copyTracker'));

  const copyEventHandler = useCallback(() => {
    if (!candidateProfileOpen.test(pathname) && !searchOrStreamOpen.test(pathname)) return;

    const selection = window.getSelection()!;
    const selectedNode = selection.anchorNode;

    let copiedElement =
      selectedNode!.nodeType === 3 ? selectedNode?.parentElement : (selectedNode as Element);

    if (!copiedElement?.id) {
      copiedElement = findParentWithID(copiedElement);
    }

    const rawString = selection?.toString()?.trim();
    if (!rawString) return;

    const candidateID = Number(candidateFromURL.exec(pathname)?.[1]) || null;

    const fromElement = copiedElement?.id;
    const properties: TrackProperties = {
      pathname,
      companyType,
      fromElement,
      rawString,
      candidateID,
      companyID
    };

    trackEvent('copy', 'Copy Candidate Info', 'Candidate Profile', properties);

    if (!isLoggerEnabled) return;
    logTracking(selection, copiedElement, properties);
  }, [isLoggerEnabled, pathname]);

  useEffect(() => {
    document.addEventListener('copy', copyEventHandler);
    return () => {
      document.removeEventListener('copy', copyEventHandler);
    };
  }, [isLoggerEnabled, pathname]);

  return <div style={{ display: 'none', height: 0, width: 0 }} />;
};

const findParentWithID = (element?: Element | null): Element | null => {
  while (element && !element.id) {
    element = element.parentElement;
  }
  return element || null;
};

const logTracking = (
  selection: Selection,
  copiedElement: Element | null,
  properties: TrackProperties
) => {
  console.groupCollapsed(`%cCopy Candidate Info`, titleCSS);
  console.log(`%cUser copied: %c${selection.toString()}`, boldTextCSS, textCSS);
  console.log(`%cFrom element: %c${copiedElement!.id}`, boldTextCSS, textCSS);
  console.log(`%cTracking properties:`, boldTextCSS);
  console.log(properties);
  console.groupEnd();
};

const titleCSS =
  'color: #ddd; font-weight: bold; background-color: rgb(20,20,20); width: 100%; padding: 0.25rem 0.5rem;';
const textCSS = 'color: #b1afaf; font-weight: lighter; font-style: italic;';
const boldTextCSS = 'color: #b1afaf; font-weight: bold;';

export default ProfileInformationTracker;
