import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import media from 'styles/media';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import CompanyLogosRow from '../companylogosrow';

export type Props = {
  cta: string;
  title: string;
  subtitle?: string;
};

export default function LeftColumn({ cta, title, subtitle, children }: PropsWithChildren<Props>) {
  const isTablet = useMedia([mediaQueries.tablet]);

  return (
    <Wrapper className="left_column">
      <ContentWrapper className="left_column_content">
        <h4 className="lightblue_text">{cta}</h4>
        <h2 className="page_section_title">{title}</h2>
        <ConditionalRender predicate={subtitle}>
          <SectionSubtitle className="page_section_subtitle">{subtitle}</SectionSubtitle>
        </ConditionalRender>
        {children}
      </ContentWrapper>
      <CompanyLogosRow show={!isTablet} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 480px;
  padding: 12px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-basis: calc(45% - 20px);

  ${media.tablet`
    min-height: auto;
    padding: 0 48px;
  `}

  ${media.mobile`
    min-height: auto;
    padding: 0 12px;
  `}

  ${media.tabletLandscape`
    .company_logos {
      column-gap: 8px;

      .company_logo {
        max-height: 20px;
      }
    }
  `}
`;

const ContentWrapper = styled.div`
  padding-right: 50px;

  .lightblue_text {
    font-weight: ${({ theme: { typography } }) => typography.semibold};
  }

  .page_section_title {
    padding: 8px 0;

    ${media.tabletLandscape`
      font-size: 46px;
    `}

    ${media.mobile`
      font-size: 36px;
    `}
  }

  ${media.tablet`
    text-align: center;
    padding: 0 48px;
  `}

  ${media.mobile`
    padding: 0;
  `}
`;

const SectionSubtitle = styled.h3`
  &.page_section_subtitle {
    margin-bottom: 30px;
    font-size: 24px;

    ${media.smallLaptop`
      font-size: 20px;
    `}

    ${media.tablet`
      margin-bottom: 16px;
    `}

    ${media.mobile`
      font-size: 18px;
    `}
  }
`;
