// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { configureStore, Draft, PreloadedState } from '@reduxjs/toolkit';

import setup from './setup';
import getCustomMiddleware from './middleware';
// Slices
import admin from './admin';
import user from './user';
import positions from './positions';
import ui from './ui';
import discover from './discover';
import navigatablePositions from './navigatablePositions';
import candidateSearch from './candidateSearch';
import candidateATS from './candidateATS';
import common from './common';

const store = configureStore({
  reducer: {
    admin,
    user,
    positions,
    ui,
    discover,
    navigatablePositions,
    candidateSearch,
    candidateATS,
    common
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(getCustomMiddleware()).concat(setup.logger),
  devTools: setup.devTools
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Export the hooks for type-safe use throughout our application
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** Cast WritableDraft state to its regular counterpart.
 * This should only be used when you know the state is not being mutated!
 */
export function castDraft<T>(draft: Draft<T>): T {
  return draft as T;
}

export default store;

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: {
      admin,
      user,
      positions,
      ui,
      discover,
      navigatablePositions,
      candidateSearch,
      candidateATS,
      common
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(getCustomMiddleware()).concat(setup.logger),
    devTools: setup.devTools,
    preloadedState
  });
}
