import { CandidateCVSettings } from '@cohiretech/common-types';

import { DEFAULT_CV_SETTINGS } from 'consts';
import { CVOptions, CandidateCVSettingsAdapted, CandidateCVSettingsFE } from 'types';
import { mapObject, reduceObject } from 'utils/object';

/* Map CV settings from BE to FE
 * timeOffset replaces timezone */
export const cvSettingsMapToFE = (cvSettings: CandidateCVSettings): CandidateCVSettingsAdapted => {
  const { timezone, ...rest } = cvSettings;
  const mappedCVSettings: CandidateCVSettingsAdapted = {
    ...rest,
    timeOffset: { show: timezone?.show }
  };

  return mappedCVSettings;
};

/* Map CV settings from FE to BE
 * timezone replaces timeOffset */
export const cvSettingsMapToBE = (cvOptions: CandidateCVSettingsFE): CandidateCVSettings => {
  const { timeOffset, ...rest } = cvOptions;
  const mappedCVSettings = {
    ...rest,
    timezone: { show: timeOffset?.show, disabled: false }
  };

  return reduceObject(
    mappedCVSettings,
    (acc, value, key) => (value?.disabled ? acc : { ...acc, [key]: { show: value.show } }),
    {} as CandidateCVSettings
  );
};

/* Object from BE doesn't necessarily have all the keys
 * so we need to merge it with the default settings */
export const mergeCVSettings = (newSettings: CandidateCVSettings | null): CandidateCVSettingsFE => {
  if (!newSettings) return DEFAULT_CV_SETTINGS;

  const mergedSettings = { ...DEFAULT_CV_SETTINGS };
  const adaptedNewSettings = cvSettingsMapToFE(newSettings);

  mapObject(adaptedNewSettings, (value, key) => {
    mergedSettings[key] = { ...mergedSettings[key], ...value };
  });
  return mergedSettings;
};

/* Creates a CVOptions object, which is a simpler version of CVSettingsFE */
export const parseCVOptions = (cvSettingsBE?: CandidateCVSettings | null) => {
  const cvSettings = cvSettingsBE ? mergeCVSettings(cvSettingsBE) : DEFAULT_CV_SETTINGS;

  const cvOptions = reduceObject(
    cvSettings,
    (acc, { show }, key) => {
      acc[key] = !!show;
      return acc;
    },
    {} as CVOptions
  );

  return cvOptions;
};
