import { ReactNode, RefObject, useEffect } from 'react';

export const useTallestChildElement = (
  ref: RefObject<HTMLDivElement>,
  predicate?: boolean,
  children?: ReactNode
) => {
  const findTallestChildHeight = (element: HTMLElement): number => {
    let maxHeight = 0;

    for (let i = 0; i < element.children.length; i++) {
      const child = element.children[i] as HTMLElement;

      const { marginTop, marginBottom } = getComputedStyle(child);

      const childHeight = child.offsetHeight + parseFloat(marginTop) + parseFloat(marginBottom);
      if (childHeight > maxHeight) {
        maxHeight = childHeight;
      }
    }

    return maxHeight;
  };

  useEffect(() => {
    if (!ref.current || !predicate) return;

    const height = findTallestChildHeight(ref.current);
    ref.current?.style.setProperty('--max-height', height ? `${height}px` : '100vh');
  }, [ref.current, children]);
};
