import React from 'react';

import { RemoteOption } from '@cohiretech/common-types';

import { isRemote } from 'v2/services/company/position';
import { getRemoteOptions } from 'fetcher';

import SelectQuestion from 'components/selectquestion';
import LocationInput from 'components/locationinput';
import RemoteLocationsField from 'components/remotelocationsfield';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { useCandidateATPosition } from './CandidateATPositionForm.hooks';

const REMOTE_OPTIONS = getRemoteOptions();

type CandidateATPositionLocationFieldsProps = ReturnType<typeof useCandidateATPosition>;

export default function CandidateATPositionLocationFields({
  position: { remote = [], locationLabel },
  formSubmitted,
  isLocationRequired,
  remoteLocationsRef,
  updatePosition
}: CandidateATPositionLocationFieldsProps) {
  const selectRemoteWork = (selection: string) => {
    const index = remote.findIndex(selected => selected === selection);

    if (index > -1) remote.splice(index, 1);
    else remote.push(selection);

    updatePosition({ remote });
  };

  return (
    <>
      <SelectQuestion
        question="Work type"
        options={REMOTE_OPTIONS}
        multiple
        columns={3}
        selected={remote}
        toggle={selectRemoteWork}
        noneSelectedMessage="Work type must be selected"
        formSubmitted={formSubmitted}
      />
      <ConditionalRender predicate={isLocationRequired}>
        <div className="field">
          <label className="question" htmlFor="location_input">
            Position location
          </label>
          <LocationInput
            allowAddress
            noRegions
            defaultValue={locationLabel}
            onSelect={locationLabel => updatePosition({ locationLabel })}
            noInputMessage="Please select the location of this position"
            formSubmitted={formSubmitted}
          />
        </div>
      </ConditionalRender>
      <ConditionalRender predicate={isRemote(remote as RemoteOption[])}>
        <RemoteLocationsField
          ref={remoteLocationsRef}
          question="Remote locations"
          disabledPlaceholder="Candidates can be based anywhere"
        />
      </ConditionalRender>
    </>
  );
}
