import { SearchCategoryViewTypeEnum as SCVTE } from '@cohiretech/common-types';

import { findingWorkSearchViewTypes } from 'fetcher';

export const getFindingWorkSearchViewType = (
  searchViewType: Extract<SCVTE, 'company' | 'listing' | 'people'>
) => findingWorkSearchViewTypes.find(({ value }) => value === searchViewType)!;

export const getFindingWorkSearchDefaultViewType = () =>
  getFindingWorkSearchViewType(SCVTE.Listing);
