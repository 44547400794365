import styled from 'styled-components';

import { NoScrollbar } from 'v2/components/utility/Scroll';

export const PositionPreviewSection = styled.div`
  --position-profile-height: 700px;
  --negative-profile-height-minus-button-panel: -500px;
  --scale-value: 0.8;

  .page_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3.75rem 5rem;
    border-radius: 5rem;
    background: var(--gradient-grey-soft);
    align-items: center;

    ${({ theme: { media } }) => media.tablet` 
      flex-direction: column;
      padding: 3.75rem;
      border-radius: 3rem;

      .page_section_subtitle {
        margin-bottom: 0;
      }
    `}

    .position_wrapper {
      position: relative;
      max-width: 60%;
      box-sizing: border-box;
      transform: scale(var(--scale-value));
      transform-origin: 100% 0;
      // Remove unused space caused by the scale down
      margin: 0 auto
        calc(var(--negative-profile-height-minus-button-panel) * (1 - var(--scale-value)));

      ${({ theme: { media } }) => media.tablet` 
        display: none;
      `}

      .position_profile {
        max-height: var(--position-profile-height);
        overflow: scroll;
        ${NoScrollbar}

        #imageSlider {
          height: 300px;
        }

        .company_photo {
          width: 120px;
          height: 120px;
        }
      }

      .button_panel {
        display: flex;
        background: var(--background-block-primary);
        border-radius: 2.5rem;
        position: absolute;
        width: 100%;
        max-width: none;
        bottom: -3.5rem;
        left: -2rem;
        box-shadow: 0 -4px 32px var(--shadow-soft);
        padding: 1.5rem 2rem;
      }
    }
  }
`;
