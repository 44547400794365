import { DialogWindowProps } from 'components/dialogwindow';

export const POSITION_DOES_NOT_EXIST = 'position does not exist';
export const FAILED_TO_IMPORT_LISTING = 'Failed to import listing';
export const DUPLICATE_RECORD_FOUND = 'duplicate record found';

export type URLCheckState = 'idle' | 'finding' | 'extracting' | undefined;
export type InputMethod = 'manual' | 'found' | 'extracted';

export const getFormHeading = (
  inputMethod: Exclude<InputMethod, 'found'>
): Pick<DialogWindowProps, 'title' | 'subTitle'> => {
  switch (inputMethod) {
    case 'manual':
      return {
        title: 'Enter position information',
        subTitle: 'Import a position to your application management board'
      };
    case 'extracted':
      return {
        title: 'Review and save position',
        subTitle: 'Check that the generated position information is right'
      };
    default:
      return {};
  }
};
