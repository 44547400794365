import React from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';
import { useHistory } from 'react-router';

import { useCandidateProfile, useToggler } from 'store';

import {
  openStatusPopupProps,
  StyledWelcomeBackPopup
} from 'views/candidate/discover/WelcomeBackPopup';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import Avatar from 'v2/components/ui/atoms/Avatar';

import AccountDropdown from './AccountDropdown';

export default function AccountMenuDropdown() {
  const { photoURL, firstName, lastName } = useCandidateProfile() || {};
  const { value: active, toggle } = useToggler('candidate.accountMenuDropdown');
  const { value: showOpenStatusPopup, toggle: toggleOpenStatusPopup } = useToggler(
    'candidate.openStatusPopup'
  );
  const history = useHistory();

  return (
    <DropdownWrapper className="candidate_menu_item">
      <DropdownSelector
        role="button"
        tabIndex={0}
        className={ClassNames('account_dropdown_selector', { active })}
        onClick={() => toggle()}
      >
        <ProfilePicture
          photoURL={photoURL}
          firstname={firstName}
          lastname={lastName}
          width={30}
          resizePhotoDimensions={{ width: 50 }}
        />
        <ArrowDownIcon className="icon_simple_arrow_down" />
      </DropdownSelector>
      <ConditionalRender predicate={active}>
        <AccountDropdown />
      </ConditionalRender>
      <ConditionalRender predicate={showOpenStatusPopup}>
        <StyledWelcomeBackPopup
          id="status_confirmation_popup"
          visible={showOpenStatusPopup}
          {...openStatusPopupProps}
          primaryButton={{
            text: 'Update your profile',
            action: () => {
              history.push('/candidate/account?cv_prompt=true');
              toggleOpenStatusPopup(false);
            }
          }}
          showCloseIcon
          onClose={() => toggleOpenStatusPopup(false)}
        />
      </ConditionalRender>
    </DropdownWrapper>
  );
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownSelector = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 2rem;
  cursor: pointer;

  &:hover {
    background: var(--background-menuitem-hover);
  }

  &.active {
    background: var(--background-menuitem-selected);
  }
`;

const ProfilePicture = styled(Avatar)`
  padding: 1px;
  border: 2px solid var(--text-body-primary);

  .profile_picture_initials {
    opacity: 0.7;
  }
`;

const ArrowDownIcon = styled.span`
  &[class*='icon_'] {
    font-size: 0.5rem;
    font-weight: ${({ theme: { typography } }) => typography.black};
    ${({ theme: { fn } }) => fn.slightlyBolderIcon()}
  }
`;
