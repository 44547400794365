import { useEffect, useRef } from 'react';

export const usePrevious = <V = any>(value: V): V => {
  const ref = useRef<V>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current!;
};
