import React, { FocusEvent } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { debounce } from 'utils';
import { addDimensionsOnImages } from 'v2/services/image';
import { mobile, isMobileWidth } from 'responsiveConfig';
import { Stream } from 'types';

import SelectableButton from 'components/selectablebutton';
import IconDropdown from 'components/icondropdown';
import TourBubble, { TourBubbleProps } from 'components/tourbubble';
import Tooltip from 'components/tooltip';
import MultiAvatarPhotos from 'components/multiavatarphotos';

import DeleteStreamPopup from 'v2/components/DeleteStreamPopup';

import colours from 'css/base/_colours.module.scss';

const sanitizeHtml = require('sanitize-html');

type Props = {
  featureName: 'Stream' | 'Search';
  scrollWidth?: string;
  streamOf: 'candidates' | 'positions';
  streams: Stream[];
  showTourBubbles: boolean;
  tourBubbleDetails?: TourBubbleProps;
  onTourBubbleClose?: () => void;
  onTourBubbleFollow?: () => void;
  darkMode: boolean;
  onEdit: (stream: Stream) => void;
  onDuplicate?: (stream: Stream) => void;
  onDelete: (id: number, deleteForAll: boolean) => Promise<void>;
  onDeletePopupShow?: () => void;
  onDeletePopupHide?: () => void;
  moreOptions: 'settings' | 'delete';
  editableName: boolean;
  showNotificationSettings?: boolean;
  onNotifications?: () => void;
  onSelect: (id: number) => void;
  onStreamsOrderUpdate?: (streams: Stream[]) => void;
  isStreamSelected: (id: number) => boolean;
  isStreamBeingEdited: (id: number) => boolean;
  newStreamURL: string;
  rounded?: boolean;
  showSavedAndHidden?: boolean;
  saveName?: (stream: Stream) => Promise<void>;
};

type State = {
  marginLeft: number;
  showLeftArrow: boolean;
  showRightArrow: boolean;
  showBubble: boolean;
  streams: Stream[];
  streamDraggedIndex: number;
  streamDraggingOverIndex: number;
  deleteStreamPopup: boolean;
  streamDetails: Stream | null;
  allowDelete: boolean;
};

export default class StreamsList extends React.Component<Props, State> {
  editable: $TSFixMe;
  isCompMounted: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      marginLeft: 0,
      showLeftArrow: false,
      showRightArrow: false,
      showBubble: true,
      streams: props.streams || [],
      streamDraggedIndex: -1,
      streamDraggingOverIndex: -1,
      deleteStreamPopup: false,
      streamDetails: null,
      allowDelete: true
    };

    this.checkForScroller = this.checkForScroller.bind(this);
    this.handleScroll = debounce(this.handleScroll.bind(this), 400);
    this.saveName = this.saveName.bind(this);
    this.editable = React.createRef();
    this.isCompMounted = false;
  }

  closeBubble() {
    const { onTourBubbleClose } = this.props;

    this.setState({ showBubble: false });

    onTourBubbleClose?.();
  }

  onTourBubbleButton() {
    const { onTourBubbleFollow } = this.props;

    this.setState({ showBubble: false });

    onTourBubbleFollow?.();
  }

  async onSettingsChanged(value: $TSFixMe, id: $TSFixMe, allowDelete: $TSFixMe) {
    const { onEdit, onNotifications, onDuplicate } = this.props;
    const { streams } = this.state;
    const stream = streams.filter(stream => stream.id === id)[0];

    switch (value) {
      case 'duplicate':
        onDuplicate?.(stream);
        break;
      case 'notifications':
        onNotifications?.();
        break;
      case 'edit':
        onEdit(stream);
        break;
      case 'delete':
        this.onDeleteSelect(stream, null, allowDelete);
        break;
      default:
        break;
    }
  }

  onDeleteSelect(stream: $TSFixMe, id: $TSFixMe, allowDelete = true) {
    const { onDeletePopupShow } = this.props;
    const { streams } = this.state;

    let streamDetails = stream;

    if (!stream) streamDetails = streams.filter(stream => stream.id === id)[0];

    if (onDeletePopupShow) onDeletePopupShow();
    this.setState({ deleteStreamPopup: true, streamDetails, allowDelete });
  }

  async onDeleteStream() {
    const { onDelete, onDeletePopupHide } = this.props;
    const { streamDetails, allowDelete } = this.state;

    await onDelete(streamDetails!.id, allowDelete);

    this.setState({
      deleteStreamPopup: false,
      streamDetails: null,
      allowDelete: true
    });

    if (onDeletePopupHide) onDeletePopupHide();
  }

  onDeletePopupClose() {
    const { onDeletePopupHide } = this.props;

    this.setState({ deleteStreamPopup: false, streamDetails: null, allowDelete: true });

    if (onDeletePopupHide) onDeletePopupHide();
  }

  onStreamSelected(e: $TSFixMe, id: $TSFixMe) {
    const { onSelect } = this.props;

    if (
      !e.target.className.includes('icon') &&
      !e.target.className.includes('dropdown') &&
      e.target.className !== 'item' &&
      e.target.className !== 'item_text' &&
      !e.target.className.includes('settings')
    ) {
      onSelect(id);
    } else return;
  }

  checkForScroller() {
    const scrollingCont = document.getElementById('scrolling_cont');

    if (
      scrollingCont &&
      scrollingCont.scrollWidth > scrollingCont.clientWidth + 30 &&
      this.isCompMounted
    ) {
      this.setState({ showRightArrow: true });
    } else if (this.isCompMounted) this.setState({ showRightArrow: false });

    if (scrollingCont) scrollingCont.addEventListener('scroll', e => this.handleScroll(e.target));
  }

  componentDidMount() {
    const { streams } = this.props;

    this.isCompMounted = true;

    this.setState({ streams }, () => setTimeout(this.checkForScroller, 500));

    window.addEventListener('resize', this.checkForScroller);
  }

  componentDidUpdate(prevProps: Props) {
    const { streams } = this.props;

    if (streams !== prevProps.streams) {
      this.setState({ streams }, () => setTimeout(this.checkForScroller, 500));
    }
  }

  componentWillUnmount() {
    const scrollingCont = document.getElementById('scrolling_cont');

    this.isCompMounted = false;

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    scrollingCont.removeEventListener('scroll', e => this.handleScroll(e));
    window.removeEventListener('resize', this.checkForScroller);
  }

  scrollToStreamItem(direction: $TSFixMe) {
    const scrollingCont = document.getElementById('scrolling_cont');
    const scrollPos = scrollingCont?.scrollLeft || 0;
    const contWidth = scrollingCont?.clientWidth || scrollingCont?.offsetWidth || 0;

    if (direction === 'right') scrollingCont?.scrollTo(scrollPos + contWidth / 2, 0);
    else scrollingCont?.scrollTo(scrollPos - contWidth / 2, 0);
  }

  handleScroll(element: $TSFixMe) {
    const marginLeft = this.state.marginLeft;
    const scrollPos = element.scrollLeft;
    const scrollWidth = element.scrollWidth;
    const contWidth = element.clientWidth || element.offsetWidth;
    const reachedEnd = scrollPos + contWidth >= scrollWidth - 5;
    const reachedStart = scrollPos === 0;

    let newMarginLeft = marginLeft;

    //scrolled back to the start
    if (reachedStart) newMarginLeft = 0;
    else newMarginLeft = 0 - scrollPos;

    this.setState({
      showLeftArrow: !reachedStart,
      showRightArrow: !reachedEnd,
      marginLeft: newMarginLeft
    });
  }

  trackDragOverIndex(e: $TSFixMe, index: $TSFixMe) {
    e.preventDefault();

    this.setState({ streamDraggingOverIndex: index });
  }

  allowDrop(e: $TSFixMe) {
    e.preventDefault();
  }

  onDrop(e: $TSFixMe, newIndex: $TSFixMe) {
    const { streamDraggedIndex } = this.state;
    const { onStreamsOrderUpdate } = this.props;

    e.preventDefault();

    if (newIndex > -1 && streamDraggedIndex > -1) {
      const streams = [...this.state.streams];
      const stream = streams[streamDraggedIndex];

      streams.splice(streamDraggedIndex, 1);
      streams.splice(newIndex, 0, stream);

      this.setState({ streams, streamDraggingOverIndex: -1, streamDraggedIndex: -1 });

      if (onStreamsOrderUpdate) onStreamsOrderUpdate(streams);
    }
  }

  onDrag(index: number) {
    this.setState({ streamDraggedIndex: index });
  }

  getSettingsList(allowEdit = true, allowDelete = true) {
    const { showNotificationSettings, featureName } = this.props;
    const settings = [
      {
        label: [<span className="icon_edit" key="edit" />, `Edit ${featureName.toLowerCase()}`],
        value: 'edit',
        disabled: !allowEdit
      },
      {
        label: [
          <span className="icon_duplicate" key="duplicate" />,
          `Duplicate ${featureName.toLowerCase()}`
        ],
        value: 'duplicate'
      },
      {
        label: [
          <span className="icon_notifications" key="notifications" />,
          'Manage notifications'
        ],
        value: 'notifications',
        dontHide: true
      },
      {
        label: [
          <span className="icon_delete" key="delete" />,
          `${allowDelete ? 'Delete' : 'Remove'} ${featureName.toLowerCase()}`
        ],
        value: 'delete',
        dontHide: true
      }
    ];

    if (!showNotificationSettings) settings.splice(1, 1);

    return settings;
  }

  saveName(e: FocusEvent, elementID: string, streamID: number) {
    const { saveName, streams } = this.props;
    const stream = streams.find(({ id }) => streamID === id) as Stream;
    const editableElement = document.getElementById(elementID);

    if (e) e.preventDefault();

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const newName = e ? e.target.innerHTML : editableElement.innerHTML;
    const sanitizedName = sanitizeHtml(newName, { allowedTags: [] });
    const decodedName = sanitizedName.replace(/&amp;/g, '&');

    if (!decodedName && editableElement) editableElement.innerHTML = stream.searchName;

    if (decodedName) {
      stream.searchName = decodedName;
      saveName!(stream);
    }
  }

  selectText(elementID: string) {
    const element = document.getElementById(elementID);

    // Clear any current selection
    const selection = window.getSelection();
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    selection.removeAllRanges();

    // Select text inside node
    const range = document.createRange();
    // @ts-expect-error TS(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
    range.selectNodeContents(element);
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    selection.addRange(range);
  }

  onKeyPress(e: $TSFixMe) {
    const editable = this.editable.current;

    if (e.key === 'Enter' && editable) editable.blur();
    else if (e.key === ' ') e.nativeEvent.stopImmediatePropagation();
  }

  render() {
    const {
      showLeftArrow,
      streams,
      showRightArrow,
      marginLeft,
      showBubble,
      streamDraggingOverIndex,
      streamDraggedIndex,
      deleteStreamPopup,
      streamDetails,
      allowDelete
    } = this.state;
    const {
      newStreamURL,
      featureName,
      streamOf,
      isStreamSelected,
      darkMode,
      showTourBubbles,
      tourBubbleDetails,
      scrollWidth,
      rounded,
      moreOptions,
      showSavedAndHidden,
      editableName,
      isStreamBeingEdited
    } = this.props;

    const scrollOn = showLeftArrow || showRightArrow;

    return (
      <StreamItems className="stream_items">
        {showTourBubbles && tourBubbleDetails && (
          <TourBubble
            show={showBubble}
            fixedOnMobile
            {...tourBubbleDetails}
            onButtonClick={() => this.onTourBubbleButton()}
            onClose={() => this.closeBubble()}
          />
        )}
        <ArrowWrapper className="arrow_wrapper" rounded={rounded}>
          {showLeftArrow && (
            <div
              className={ClassNames('icon_arrow_left', { dark: darkMode })}
              onClick={() => this.scrollToStreamItem('left')}
            />
          )}
          <ScrollingCont className="streams" id="scrolling_cont" scrollWidth={scrollWidth}>
            <StreamsWrapper
              className="streams_wrapper"
              rounded={rounded}
              onDragOver={e => this.allowDrop(e)}
              onDrop={e => this.onDrop(e, streamDraggingOverIndex)}
            >
              {streams.map(
                (
                  { id, searchName, allowDelete, allowEdit, streamMembers }: $TSFixMe,
                  index: $TSFixMe
                ) => (
                  <SelectableButton
                    key={id}
                    size={rounded ? 'large' : undefined}
                    color={darkMode ? 'light_grey' : 'dark_blue'}
                    draggingOver={
                      streamDraggedIndex > -1 &&
                      streamDraggingOverIndex === index &&
                      streamDraggingOverIndex !== streamDraggedIndex
                    }
                    makeSpaceLeft={streamDraggedIndex > streamDraggingOverIndex}
                    makeSpaceRight={streamDraggedIndex < streamDraggingOverIndex}
                    onDragOver={e => this.trackDragOverIndex(e, index)}
                    onDragStart={() => this.onDrag(index)}
                    draggable
                    rounded={rounded}
                    className={ClassNames('stream_item', {
                      selected: isStreamSelected(id),
                      hover_on: scrollOn,
                      with_photos: streamMembers && streamMembers.length > 1,
                      show_delete: isStreamBeingEdited(id) && moreOptions !== 'settings'
                    })}
                    onClick={e => {
                      if (
                        (e.target as HTMLButtonElement).id !== 'editable_search_name' &&
                        !isStreamSelected(id)
                      ) {
                        this.onStreamSelected(e, id);
                      }
                    }}
                  >
                    {streamMembers?.length > 1 && (
                      <MultiAvatarPhotos
                        photosData={addDimensionsOnImages(streamMembers, {
                          type: 'logo',
                          dimensions: { width: 30 }
                        })}
                        maxPhotosShown={2}
                      />
                    )}
                    {isStreamSelected(id) && editableName ? (
                      <div
                        ref={this.editable}
                        id="editable_search_name"
                        className={ClassNames('editable', { dark: darkMode })}
                        contentEditable="true"
                        onBlur={e => this.saveName(e, e.target.id, id)}
                        onKeyPress={e => this.onKeyPress(e)}
                        suppressContentEditableWarning
                      >
                        {searchName}
                      </div>
                    ) : (
                      searchName
                    )}
                    {moreOptions === 'settings' ? (
                      <IconDropdown
                        icon="icon_settings"
                        iconFontSize="24px"
                        width="30px"
                        height="30px"
                        lineHeight="30px"
                        className="settings_wrapper"
                        dropdownID={`stream_options_${id}`}
                        dropdownInlineStyle={{ marginLeft }}
                        list={this.getSettingsList(allowEdit, allowDelete)}
                        onSelect={(item: $TSFixMe) =>
                          this.onSettingsChanged(item.value, id, allowDelete)
                        }
                      />
                    ) : (
                      <span className="icon_delete" onClick={() => this.onDeleteSelect(null, id)}>
                        <Tooltip position="left" text="Delete" />
                      </span>
                    )}
                  </SelectableButton>
                )
              )}
              {showSavedAndHidden && (
                <>
                  <SelectableButton
                    className="stream_item"
                    rounded={rounded}
                    size={rounded ? 'large' : undefined}
                    color={darkMode ? 'light_grey' : 'dark_blue'}
                    to="/candidate/search/saved"
                  >
                    <span className={ClassNames('icon_thumbs_up', 'icon')} /> Liked
                  </SelectableButton>
                  <SelectableButton
                    className="stream_item"
                    rounded={rounded}
                    size={rounded ? 'large' : undefined}
                    color={darkMode ? 'light_grey' : 'dark_blue'}
                    to="/candidate/search/hidden"
                  >
                    <span className={ClassNames('icon_thumbs_down', 'icon')} /> Not right
                  </SelectableButton>
                </>
              )}
            </StreamsWrapper>
          </ScrollingCont>
          {showRightArrow && (
            <div
              className={ClassNames('icon_arrow_right', { dark: darkMode })}
              onClick={() => this.scrollToStreamItem('right')}
            />
          )}
        </ArrowWrapper>
        <SelectableButton
          size={rounded ? 'large' : undefined}
          className={ClassNames('new_stream', { no_streams: streams.length === 0 })}
          rounded={rounded}
          color={darkMode ? 'light_grey' : 'dark_blue'}
          to={newStreamURL}
          ariaLabel={`New ${featureName}`}
        >
          <span className="icon_add" />
          {`${isMobileWidth ? '' : ` New ${featureName}`}`}
        </SelectableButton>
        <DeleteStreamPopup
          visible={deleteStreamPopup}
          featureName={featureName}
          streamOf={streamOf}
          streamDetails={streamDetails}
          allowDelete={allowDelete}
          onDelete={() => this.onDeleteStream()}
          onClose={() => this.onDeletePopupClose()}
        />
      </StreamItems>
    );
  }
}

const StreamItems = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .tour_bubble_wrapper {
    top: calc(100% + 20px);

    @media only screen and (max-width: ${mobile}) {
      top: inherit;
    }
  }

  .selectable_wrapper.new_stream {
    width: max-content;
    margin-left: 10px;

    .icon_add {
      font-weight: ${({ theme: { typography } }) => typography.bold};
      vertical-align: middle;
      margin-right: 5px;
      font-size: 20px;
      display: inline-block;
      margin-top: -2px;
    }

    @media only screen and (max-width: ${mobile}) {
      padding-left: 15px;
      padding-right: 15px;

      .icon_add {
        margin-right: 0;
        font-size: 20px;
      }
    }

    &.no_streams {
      margin-left: 0;
    }
  }
`;

const ArrowWrapper = styled.div<{ rounded?: boolean }>`
  position: relative;
  display: inherit;
  overflow: hidden;

  .icon_arrow_left,
  .icon_arrow_right {
    position: absolute;
    z-index: 6;
    cursor: pointer;
    top: ${({ rounded }) => (rounded ? '-3px' : '-4px')};
    width: ${({ rounded }) => (rounded ? '55px' : '50px')};
    height: ${({ rounded }) => (rounded ? '56px' : '54px')};
    line-height: ${({ rounded }) => (rounded ? '36px' : '34px')};
    padding: 10px;
    box-sizing: border-box;
    background-color: ${({ rounded }) => (rounded ? 'white' : colours.lightBgColour)};
    box-shadow: 0 0 20px 5px ${colours.dropshadow};
    border-radius: 50%;
    font-weight: bold;
    font-size: 20px;
    transition: all 0.2s ease-in-out;

    &.dark {
      background-color: ${colours.darkerBgColour};
      box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.6);
    }

    &:hover {
      width: ${({ rounded }) => (rounded ? '58px' : '52px')};
    }
  }

  .icon_arrow_right {
    right: -16px;
  }

  .icon_arrow_left {
    left: -16px;
    text-align: right;
  }

  @media only screen and (max-width: ${mobile}) {
    .icon_arrow_left,
    .icon_arrow_right {
      display: none;
    }
  }
`;

const ScrollingCont = styled.div<{ scrollWidth?: string }>`
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  display: inline-block;
  scroll-behavior: smooth;
  max-width: ${({ scrollWidth }) => scrollWidth || '50vw'};
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 11 */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }
`;

const StreamsWrapper = styled.div<{ rounded?: boolean }>`
  overflow: visible;
  white-space: nowrap;

  .selectable_wrapper.stream_item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-left: 0;
    z-index: 3;

    > .icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -2px;
      font-weight: bold;
    }

    &:active {
      cursor: grabbing;
    }

    &.make_space_left {
      margin-left: 80px;

      &:before {
        position: absolute;
        content: '';
        width: 60px;
        height: 100%;
        left: -80px;
        top: -2px;
        display: inline-block;
        border: 2px dashed ${colours.fontColour};
        border-radius: 5px;
      }
    }

    &.make_space_right {
      margin-right: 90px;

      &:after {
        position: absolute;
        content: '';
        width: 60px;
        height: 100%;
        right: -80px;
        top: -2px;
        display: inline-block;
        border: 2px dashed ${colours.fontColour};
        border-radius: 5px;
      }
    }

    .editable {
      outline: none;
      cursor: text;
      display: inline-block;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.4);

      &:hover {
        border-bottom: 1px dotted;
      }

      &:focus {
        border-bottom: 2px dotted;
      }

      &::selection {
        background-color: rgba(113, 179, 234, 0.5);
      }

      &.dark {
        border-bottom: 1px dotted rgba(11, 54, 88, 0.4);

        &::selection {
          background-color: ${colours.fontColour};
        }
      }
    }

    > .icon_delete {
      font-weight: bold;
      font-size: 18px;
      position: absolute;
      display: none;
      right: 20px;
      top: calc(50% - 10px);
      transition: all 0.2s ease-in-out;

      &:hover {
        color: ${colours.errorColour};

        .tooltip_wrapper {
          display: block;
          margin-top: -20px;
          margin-right: 5px;

          .tooltip {
            background: ${colours.errorColour};

            &:after {
              background: ${colours.errorColour};
            }
          }
        }
      }
    }

    .settings_wrapper {
      display: none;
      margin-left: 5px;
      vertical-align: middle;
      padding-left: 5px;
      padding-right: 3px;
      width: 25px !important;
      height: 0 !important;

      .icon_settings {
        position: absolute;
        top: -26px;
        left: 0;
        padding: 10px 8px;
        z-index: 1;
      }

      .dropdown_icon {
        color: ${colours.fontColour};
        margin-top: -18px;
        position: fixed;
        z-index: 4;

        .dropdown_list {
          width: 230px;
          right: -102px;
          margin-top: 0;

          .item {
            text-align: left;
            font-weight: ${({ theme: { typography } }) => typography.semibold};
            padding: 12px 16px;
            border-radius: 5px;

            .item_text > span {
              display: inline-block;
              margin-right: 15px;
              vertical-align: text-bottom;
              font-weight: bold;
            }

            &:last-child {
              color: ${colours.errorColour};
            }
          }
        }
      }
    }

    &.selected .settings_wrapper {
      display: inline-block;

      .icon_settings {
        border-left-color: white;
      }
    }

    &.selected.show_delete {
      padding-right: 55px;

      > .icon_delete {
        display: block;
      }
    }

    &.light_grey .settings_wrapper {
      .dropdown_icon {
        color: ${colours.lightFontColour};
      }
    }

    &:last-child {
      margin-right: 0;

      &.make_space_right {
        margin-right: 90px;
      }
    }
  }

  ${props =>
    props &&
    (props as $TSFixMe).rounded &&
    css`
      .selectable_wrapper.stream_item {
        &.make_space_left:before {
          border-radius: 25px;
        }

        &.make_space_right:after {
          border-radius: 25px;
        }

        .settings_wrapper .icon_settings {
          border: none;
          padding: 19px 6px;
        }

        &.light_grey .settings_wrapper .icon_settings,
        &.light_grey.selected .settings_wrapper .icon_settings {
          border-left: 0;
        }
      }
    `}
`;
