import React, { ReactNode, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ClassNames from 'classnames';

import { isNil } from 'utils/fn';

import { ListItem } from 'components/dropdown';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import RangeInput, { InputAttributes, RangeType } from './RangeInput';
import { RangeContainer, Range } from './RangeField.styled';

type RangeInputAttributes = Omit<InputAttributes, 'name' | 'value' | 'onChange'> & {
  value?: number;
  onChange?: (value: number) => void;
};

type RangeInputProps = {
  minInput: RangeInputAttributes;
  maxInput?: RangeInputAttributes;
};

type RangeFieldProps = {
  question: string;
  optional?: boolean;
  tooltip?: string;
  additionalInfo?: ReactNode;
  namePrefix?: string;
  options?: ListItem<string, number>[];
} & RangeInputProps;

export type RangeFieldHandler = RangeType;

const RangeField = forwardRef<RangeFieldHandler, RangeFieldProps>(
  (
    { question, optional, tooltip, minInput, maxInput, additionalInfo, namePrefix = '', options },
    ref
  ) => {
    const [min, setMin] = useState(minInput.value);
    const [max, setMax] = useState<RangeFieldHandler['max']>(maxInput?.value);

    useEffect(() => {
      if (maxInput?.disabled && !isNil(max)) setMax('');
    }, [maxInput]);

    useImperativeHandle(ref, () => ({ min, max }), [min, max]);

    const handleChange = (name: string, value: string) => {
      const isMinInput = name.endsWith('min');
      const num = Number(value);

      if (isMinInput) {
        minInput.onChange?.(num);
        setMin(num);
      } else {
        maxInput!.onChange?.(num);
        setMax(num);
      }
    };

    return (
      <div className="field">
        <label className="question">
          {question}
          <ConditionalRender predicate={optional}>
            <span>(optional)</span>
          </ConditionalRender>
          <ConditionalRender predicate={tooltip}>
            <span className="icon_help">
              <Tooltip text={tooltip} position="right" tooltipStyle="light" />
            </span>
          </ConditionalRender>
        </label>
        <RangeContainer
          className={ClassNames(namePrefix, 'range_container')}
          isFlexbox={!!additionalInfo}
        >
          <Range className="range" isFlexbox={!!maxInput}>
            <RangeInput
              {...minInput}
              name={`${namePrefix}_min`}
              range={{ min, max }}
              options={options}
              onChange={handleChange}
            />
            <ConditionalRender predicate={maxInput}>
              <span className="range_to">to</span>
              <RangeInput
                {...maxInput}
                name={`${namePrefix}_max`}
                range={{ min, max }}
                options={options}
                onChange={handleChange}
              />
            </ConditionalRender>
          </Range>
          {additionalInfo}
        </RangeContainer>
      </div>
    );
  }
);

export default RangeField;
