import functions from 'css/base/_functions.module.scss';

export const tinyMobile = `${functions.tinyMobileMaxWidth}px`;
export const smallMobile = `${functions.smallMobileMaxWidth}px`;
export const mobile = `${functions.mobileMaxWith}px`;
export const tablet = `${functions.tabletMaxWidth}px`;
export const tabletLandscape = `${functions.tabletLandscapeMaxWidth}px`;
export const smallLaptop = `${functions.smallLaptopMaxWidth}px`;
export const laptop = `${functions.laptopMaxWidth}px`;
export const macBookScreen = `${functions.macBookScreenMaxWidth}px`;
export const smallDesktop = `${functions.smallDesktopMaxWidth}px`;
export const normalScreen = `${functions.normalScreenMaxWidth}px`;
export const largeScreen = `${functions.largeScreenMaxWidth}px`;

export const mediaQueries = {
  tinyMobile: `(max-width: ${tinyMobile})`,
  smallMobile: `(max-width: ${smallMobile})`,
  mobile: `(max-width: ${mobile})`,
  tablet: `(max-width: ${tablet})`,
  tabletLandscape: `(max-width: ${tabletLandscape})`,
  smallLaptop: `(max-width: ${smallLaptop})`,
  laptop: `(max-width: ${laptop})`,
  macBookScreen: `(max-width: ${macBookScreen})`,
  smallDesktop: `(max-width: ${smallDesktop})`,
  normalScreen: `(max-width: ${normalScreen})`,
  largeScreen: `(max-width: ${largeScreen})`,
  mobileMin: `(min-width: ${mobile})`,
  tabletMin: `(min-width: ${tablet})`,
  tabletLandscapeMin: `(min-width: ${tabletLandscape})`,
  smallLaptopMin: `(min-width: ${smallLaptop})`,
  shortScreen: `(max-height: ${smallMobile})`,
  prefersDarkMode: '(prefers-color-scheme: dark)'
} as const;

// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isTinyMobileWidth = window.innerWidth <= functions.tinyMobileMaxWidth;
// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isSmallMobileWidth = window.innerWidth <= functions.smallMobileMaxWidth;
// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isMobileWidth = window.innerWidth <= functions.mobileMaxWith;
// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isTabletWidth = window.innerWidth <= functions.tabletMaxWidth;
// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isTabletLandscapeWidth = window.innerWidth <= functions.tabletLandscapeMaxWidth;
// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isSmallLaptopWidth = window.innerWidth <= functions.smallLaptopMaxWidth;
// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isLaptopWidth = window.innerWidth <= functions.laptopMaxWidth;
// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isMackBookWidth = window.innerWidth <= functions.macBookScreenMaxWidth;
// @ts-expect-error TS(2365) FIXME: Operator '<=' cannot be applied to types 'number' ... Remove this comment to see the full error message
export const isSmallDesktopWidth = window.innerWidth <= functions.smallDesktopMaxWidth;

export const tinyMobileMaxWidth = Number(functions.tinyMobileMaxWidth);
export const smallMobileMaxWidth = Number(functions.smallMobileMaxWidth);
export const mobileMaxWidth = Number(functions.mobileMaxWith);
export const tabletMaxWidth = Number(functions.tabletMaxWidth);
export const tabletLandscapeMaxWidth = Number(functions.tabletLandscapeMaxWidth);
export const smallLaptopMaxWidth = Number(functions.smallLaptopMaxWidth);
export const laptopMaxWidth = Number(functions.laptopMaxWidth);
export const macBookScreenMaxWidth = Number(functions.macBookScreenMaxWidth);
export const smallDesktopMaxWidth = Number(functions.smallDesktopMaxWidth);
export const normalScreenMaxWidth = Number(functions.normalScreenMaxWidth);
export const largeScreenMaxWidth = Number(functions.largeScreenMaxWidth);
