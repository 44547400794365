import React, { Suspense, useEffect, useState } from 'react';
import classNames from 'classnames';

import { FeedInterface, OperatingCountry } from '@cohiretech/common-types';

import {
  createCompanyStream,
  updateCompanyStream,
  updateCompanyStreamsOrder,
  removeCompanyStream
} from 'fetcher';
import { formatNumber, incrementTextVersion, prettyTextFromArray } from 'utils';
import { getPrimarySkillFilters } from 'utils/companyUtils';
import { determineLocalisationCountry } from 'utils/localisationUtils';
import { getPricingCountry } from 'utils/pricingUtils';
import { getShowBubbleInfo } from 'v2/services/company/tour';
import { removeLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { decodeJobTitleValue } from 'v2/services/common';
import { ACTIVE_COUNT_SEARCH_ITEMS } from 'consts';
import { useDarkMode } from 'hooks/useDarkMode';
import { updateTotalActiveCount, useAppDispatch, useCompanyProfile } from 'store';
import { isAShortListingPage } from 'v2/services/tools/url';
import { getCandidateSearchCount } from 'v2/services/fetchers/company/searchCount';

import StreamsList from 'components/streamslist';
import SelectableButton from 'components/selectablebutton';
import ErrorBoundary from 'components/errorboundary';
import { Props } from 'components/topnav/companymenu';

import NotificationCounterBubble from 'v2/components/ui/atoms/NotificationCounterBubble';

import { getShortlistsCounterColour, getTotalShortlistedCandidatesCount } from './helpers';

export default function DiscoverMenu({
  demoCompany,
  demoCompanyTour,
  history,
  pathname,
  onDemoTourBubbleShown,
  onStreamsOrderUpdate,
  reloadCompanyStreams,
  bringTopNavForward
}: Props) {
  const dispatch = useAppDispatch();
  const [searchButtonText, setSearchButtonText] = useState('Search candidates');
  const profile = useCompanyProfile()!;
  const { companyStreams = [], companyUser = {}, isUnpaidAccount } = profile;
  const { shortlists = [] } = companyUser;
  const darkMode = useDarkMode();

  useEffect(() => {
    const localisation = determineLocalisationCountry(
      demoCompany,
      profile?.locationObject?.country,
      getPricingCountry(profile)
    );
    if (localisation === OperatingCountry.US) return;

    getActiveCandidatesCount();
  }, []);

  const getActiveCandidatesCount = async () => {
    const { data } = await getCandidateSearchCount({
      searchTerms: ACTIVE_COUNT_SEARCH_ITEMS,
      requestID: 'active_candidates_count'
    });

    if (data?.activeCount) {
      setSearchButtonText(`Search ${formatNumber(data?.activeCount)} candidates`);
      dispatch(updateTotalActiveCount(data?.activeCount));
    }
  };

  const onCloseDemoBubble = () => {
    const nextBubbleLocation = demoCompanyTour.find(
      ({ name, shown }) => name !== 'stream' && !shown
    )?.page;

    onDemoTourBubbleShown('stream', nextBubbleLocation);
  };

  const onNextDemoBubble = () => {
    const nextBubbleLocation = demoCompanyTour.find(
      ({ name, shown }) => name !== 'stream' && !shown
    )?.page;

    onDemoTourBubbleShown('stream');

    if (nextBubbleLocation) history.push({ hash: nextBubbleLocation?.hash });
  };

  const onStreamEdit = (stream: FeedInterface) => {
    const { id, searchName, listingIDs, streamMembers, searchParam } = stream;

    removeLocalStorageItem('createNewStream');
    setLocalStorageItem('selectedStreamDetails', {
      title: searchName,
      id,
      positions: listingIDs,
      members: streamMembers || [],
      searchParam: searchParam.params
    });
    history.push(`${pathname}/edit`);
  };

  const onStreamDuplicate = async (stream: FeedInterface) => {
    const { searchName, listingIDs, searchParam } = stream;
    const newStreamParams = {
      searchName: incrementTextVersion(searchName),
      listingIDs,
      streamUserMembers: null,
      searchParam
    };
    const { data } = await createCompanyStream(newStreamParams);
    const streamID = data?.feedID;

    await reloadCompanyStreams();

    if (streamID) {
      history.push(`/company/discover/stream/${streamID}/edit`);
    }
  };

  const onStreamDelete = async (id: number, deleteForAll: boolean) => {
    const nextAvailableFeed = companyStreams.find(stream => stream.id !== id);

    let status = '';

    if (deleteForAll) {
      const { status: apiStatus } = await removeCompanyStream(id);

      status = apiStatus;
    } else {
      // delete user from invited members
      const accountID = companyUser.accountID;
      const selectedStream = companyStreams.find(stream => stream.id === id) as FeedInterface;
      const newMembers = selectedStream
        ? selectedStream.streamMembers
            .filter(({ id, isOwner }) => id !== accountID && !isOwner)
            .map(({ id }) => id)
        : [];

      const { status: apiStatus } = await updateCompanyStream(
        id,
        selectedStream.searchName,
        selectedStream.listingIDs,
        newMembers,
        selectedStream.searchParam.params
      );

      status = apiStatus;
    }

    if (status === 'success') {
      reloadCompanyStreams();

      history.push(`/company/discover/stream/${nextAvailableFeed ? nextAvailableFeed.id : 'new'}`);
    }
  };

  const onStreamOrderUpdate = async (streams: FeedInterface[]) => {
    const streamsIDs = streams.map(({ id }) => id);

    if (onStreamsOrderUpdate) onStreamsOrderUpdate(streams);

    await updateCompanyStreamsOrder(streamsIDs);
  };

  const primarySkillsInStream = getPrimarySkillFilters(
    companyStreams[0]?.searchParam || { params: [] }
  );
  const totalCandidatesSaved = getTotalShortlistedCandidatesCount(shortlists);
  const { showBubble, bubbleShown } = getShowBubbleInfo('stream', demoCompanyTour, {
    demoCompany,
    isUnpaidAccount
  });
  const isOnAShortlistingPage = isAShortListingPage(pathname);

  return (
    <div className={classNames('discover_menu', { dark: darkMode })}>
      <ErrorBoundary inline>
        <Suspense fallback="">
          <StreamsList
            featureName="Stream"
            scrollWidth="46vw"
            streamOf="candidates"
            streams={companyStreams}
            showTourBubbles={showBubble && !bubbleShown}
            tourBubbleDetails={{
              title: 'Streams of active candidates',
              body: [
                "You're looking at a stream of ",
                <strong key="strong_title">
                  {decodeJobTitleValue(companyStreams[0]?.searchName)}s
                </strong>,
                `${
                  primarySkillsInStream.length > 0
                    ? ` with ${prettyTextFromArray(primarySkillsInStream)} core skills.`
                    : '.'
                }`,
                ' Click ',
                <span className="inline_icon icon_settings" key="icon_settings" />,
                ' and then "Edit Stream" to update the filters.'
              ],
              position: 'bottom',
              align: 'left',
              buttonText: 'Next'
            }}
            onTourBubbleClose={() => onCloseDemoBubble()}
            onTourBubbleFollow={() => onNextDemoBubble()}
            darkMode={darkMode}
            onEdit={streamDetails => onStreamEdit(streamDetails as FeedInterface)}
            onDuplicate={streamDetails => onStreamDuplicate(streamDetails as FeedInterface)}
            onDelete={(id, deleteForAll) => onStreamDelete(id, deleteForAll)}
            onDeletePopupShow={() => bringTopNavForward(true)}
            onDeletePopupHide={() => bringTopNavForward(false)}
            moreOptions="settings"
            editableName={false}
            showNotificationSettings
            onNotifications={() => history.push('/company/account/notifications')}
            onSelect={id => history.push(`/company/discover/stream/${id}`)}
            onStreamsOrderUpdate={streams => onStreamOrderUpdate(streams as FeedInterface[])}
            isStreamSelected={id =>
              new RegExp(`/company/discover/stream/${id}/`).test(`${pathname}/`)
            }
            isStreamBeingEdited={id =>
              new RegExp(`/company/discover/stream/${id}/edit`).test(`${pathname}`)
            }
            newStreamURL="/company/discover/stream/new"
          />
        </Suspense>
      </ErrorBoundary>
      <div
        className={classNames('search_items', { few_streams_visible: companyStreams.length < 3 })}
      >
        <SelectableButton
          color={darkMode ? 'dark' : 'white'}
          to="/company/discover/search"
          ariaLabel="Search candidates"
        >
          <span className="icon icon_search" />
          <span className="search_button_label">{searchButtonText}</span>
        </SelectableButton>
        <SelectableButton
          color={darkMode ? 'dark' : 'white'}
          to="/company/discover/shortlists"
          ariaLabel="Shortlists"
        >
          <span className="icon icon_bookmark" />
          <span className="search_button_label">Shortlists</span>
          <NotificationCounterBubble
            colour={getShortlistsCounterColour(darkMode, isOnAShortlistingPage)}
          >
            {totalCandidatesSaved}
          </NotificationCounterBubble>
        </SelectableButton>
      </div>
    </div>
  );
}
