/* eslint-disable no-console */
import Pusher, { Channel } from 'pusher-js';

import { OperatingCountry, UserType } from '@cohiretech/common-types';

import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { AnyAsyncFunction, AnyFunction, PropertyChain } from 'types';
import { assocPath, ensureArrayForm, ensureStringForm, isBoolean, isEmpty, path } from 'utils/fn';
import { flatten } from 'utils/object';
import {
  AppDispatch,
  isCompanyProfile,
  RootState,
  selectCandidatePro,
  selectDarkMode,
  selectToggler,
  setCandidatePro,
  setLocalisationCountry,
  setToggler,
  toggleDarkMode
} from 'store';
import { isDevelopment, isStageDevelopment } from 'v2/services/app';
import { last } from 'utils/array';

import { utils, UtilsPackage } from './DevTools.utils';

const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_AUTH = process.env.REACT_APP_PUSHER_AUTH;

export type DevTools = {
  getFeatures: () => Record<string, any>;
  getAppVersion: () => string;
  getBuildVersion: () => string;
  getEnv: (name: string) => string | undefined;
  getState: () => RootState;
  localStorage: {
    stash: (key?: string) => void;
    pop: ({ key, force }?: { key?: string; force?: boolean }) => void;
    apply: ({ key, force }?: { key?: string; force?: boolean }) => void;
    info: () => void;
    get: (key: string) => string | undefined;
    set: (key: string, value: string) => void;
    remove: (key: string) => void;
  };
  listenToPusher: () => void;
  unlistenToPusher: () => void;
  setCountry: (country?: OperatingCountry) => void;
  toggle: (featureRoute: string) => void;
  utils: UtilsPackage;
};

export type DevFeatures = {
  candidatePro: boolean;
  darkMode: boolean;
  devToggle: boolean;
  logger: {
    copyTracker: boolean;
    redux: boolean;
    reducer: Record<string, boolean>;
  };
  toolbar: boolean;
};

type FeatureEffectMap = {
  [K in keyof DevFeatures]?: any;
};

export const DEV_FEATURES_INITIAL_STATE: DevFeatures = {
  candidatePro: false,
  darkMode: false,
  devToggle: false,
  logger: {
    copyTracker: false,
    redux: false,
    reducer: { messages: false, adminsignup: false }
  },
  toolbar: false
};

export default class DevToolsManager {
  private FEATURE_EFFECTS: FeatureEffectMap = {
    candidatePro: () => this.toggleCandidatePro(),
    darkMode: () => this.toggleDarkMode()
  };

  private state: RootState;
  private dispatch: AppDispatch;
  private pusher: Pusher;
  private channels: { [key: string]: Channel } = {};
  private localStorage: { key: string; value: Storage }[] = [];

  constructor(state: RootState, dispatch: AppDispatch) {
    this.init(state, dispatch);

    this.state = state;
    this.dispatch = dispatch;

    this.pusher = new Pusher(PUSHER_KEY!, {
      cluster: 'eu',
      encrypted: true,
      authEndpoint: PUSHER_AUTH
    });
  }

  private init(state: RootState, dispatch: AppDispatch = this.dispatch) {
    if (isDevelopment()) {
      this.loadPersistedFeatures(state);
      this.syncInitialFeaturesWithRedux(state, dispatch);
      this.mapToWindow();
    } else {
      // @ts-ignore: Not adding auth to type on purpose
      window.dev = { auth: this.stageLogin };
    }
  }

  private stageLogin = (password: string) => {
    setLocalStorageItem('stagePass', password);
    this.init(this.state);
  };

  private mapToWindow() {
    window.dev = {
      getFeatures: this.getFeatures.bind(this),
      getAppVersion: this.getAppVersion.bind(this),
      getBuildVersion: this.getBuildVersion.bind(this),
      getEnv: this.getEnv.bind(this),
      getState: this.getState.bind(this),
      localStorage: {
        stash: this.stashLocalStorage.bind(this),
        pop: this.restoreLocalStorage.bind(this),
        apply: this.applyLocalStorage.bind(this),
        info: this.localStorageInfo.bind(this),
        get: this.getFromLocalStorage.bind(this),
        set: this.setLocalStorage.bind(this),
        remove: this.removeLocalStorage.bind(this)
      },
      listenToPusher: this.subscribeToPusher.bind(this),
      unlistenToPusher: this.unsubscribeFromPusher.bind(this),
      setCountry: this.setCountry.bind(this),
      toggle: this.toggle.bind(this),
      utils
    };
  }

  private loadPersistedFeatures(state: RootState) {
    const flattenedMap = flatten(state?.ui.togglers.dev) || {};
    const hasPersistedFeatures = Object.values(flattenedMap).some(Boolean);
    if (hasPersistedFeatures) {
      Object.entries(flattenedMap)
        .filter(([route, value]) => !!value && !route.includes('darkMode'))
        .forEach(([route, value]) => {
          this.runFeatureEffect(route, value);
        });
    }
  }

  private syncInitialFeaturesWithRedux(state: RootState, dispatch: AppDispatch) {
    const darkMode = selectDarkMode(state);
    const candidatePro = selectCandidatePro(state);

    dispatch(setToggler({ route: ['dev', 'darkMode'], value: darkMode }));
    dispatch(setToggler({ route: ['dev', 'candidatePro'], value: candidatePro }));
  }

  private runFeatureEffect(route: PropertyChain, value: boolean) {
    if (isBoolean(value)) {
      const effect = path<AnyFunction | AnyAsyncFunction | undefined>(this.FEATURE_EFFECTS, route);
      if (effect) effect();
    }
  }

  private setCountry(country?: OperatingCountry) {
    if (!country) {
      this.dispatch(setLocalisationCountry(undefined));
      removeLocalStorageItem('country');
      return;
    }
    this.dispatch(setLocalisationCountry(country));
    setLocalStorageItem('country', country);
  }

  private subscribeToPusher() {
    const { userType, profile } = this.state.user;

    switch (userType) {
      case UserType.Admin: {
        this.channels.admin = this.pusher.subscribe('private-admin');
        break;
      }
      case UserType.Candidate: {
        this.channels.candidate = this.pusher.subscribe(`private-candidate-${profile?.id}`);
        break;
      }
      case UserType.Company: {
        if (!isCompanyProfile(profile)) break;
        const { companyID } = profile;

        if (!companyID) break;
        this.channels.company = this.pusher.subscribe(`private-company-${companyID}`);
        this.channels.companyAccount = this.pusher.subscribe(
          `private-companyaccount-${profile?.companyUser?.accountID}`
        );
        break;
      }
      default:
        console.warn('No pusher channel for', userType);
    }

    Object.entries(this.channels).forEach(([name, channel]) => {
      channel.bind_global(logPusherEvents(name));
    });
  }

  private unsubscribeFromPusher() {
    Object.entries(this.channels).forEach(([name, channel]) => {
      channel.unbind_global();
      this.pusher.unsubscribe(channel.name);
      console.log(`Unsubscribed from ${name}`);
    });
  }

  public update(state: RootState, dispatch: AppDispatch) {
    this.state = state;
    this.dispatch = dispatch;
  }

  public toggle(route: PropertyChain, value?: boolean) {
    const persistedMap =
      getLocalStorageItem<DevFeatures>('dev-features') || DEV_FEATURES_INITIAL_STATE;
    const arrayRoute = ensureArrayForm(route);
    const stringRoute = ensureStringForm(route);
    const togglePath = ['dev', ...arrayRoute];

    if (!(stringRoute in this.getFeatures())) {
      console.warn('No feature found for', stringRoute);
      return;
    }

    const currentValue = selectToggler(togglePath)(this.getState());
    const newValue = value ?? !currentValue;

    this.runFeatureEffect(route, newValue);
    this.dispatch(setToggler({ route: togglePath, value: newValue }));

    const newMap = assocPath(persistedMap, route, newValue);
    setLocalStorageItem('dev-features', newMap);

    return `Feature ${stringRoute} set to ${newValue}`;
  }

  private stashLocalStorage(id?: string) {
    if (isEmpty(window.localStorage)) {
      console.warn('No local storage to stash');
      return;
    }

    let key = id ?? new Date().getTime();
    key = `stash-${key}`;

    const data = {} as any;
    for (let i = 0; i < window.localStorage.length; i++) {
      const lsKey = window.localStorage.key(i)!;
      data[lsKey] = window.localStorage.getItem(lsKey);
    }

    this.localStorage.push({ key, value: data });
    window.localStorage.clear();
  }

  private restoreLocalStorage({
    id,
    force,
    apply
  }: { id?: string; force?: boolean; apply?: boolean } = {}) {
    if (isEmpty(this.localStorage)) {
      console.warn('No local storage to restore');
      return;
    }
    if (!isEmpty(window.localStorage) && !force) {
      console.warn(
        `Local storage not empty, not restoring. Use 'force = true' to override.\n\trestoreLocalStorage({ force: true })`
      );
      return;
    }

    let data;
    if (id) {
      const i = this.localStorage.findIndex((item: any) => item.key === `stash-${id}`);
      data = apply ? this.localStorage[i] : this.localStorage.splice(i, 1)[0];
    } else {
      data = apply ? last(this.localStorage) : this.localStorage.pop();
    }

    if (id && !data) {
      console.warn(`No local storage found with key ${id?.toString()}`);
      return;
    }

    Object.entries(data?.value || {}).forEach(([key, value]) => {
      window.localStorage.setItem(key, value);
    });
  }

  private applyLocalStorage({ id, force }: { id?: string; force?: boolean } = {}) {
    this.restoreLocalStorage({ id, force, apply: true });
  }

  private getFromLocalStorage(key: string) {
    return getLocalStorageItem(key);
  }

  private setLocalStorage(key: string, value: any) {
    return setLocalStorageItem(key, value);
  }

  private removeLocalStorage(key: string) {
    return removeLocalStorageItem(key);
  }

  private localStorageInfo() {
    if (isEmpty(this.localStorage)) {
      console.warn('No local storage stash found');
      return;
    }

    console.group('Stashed localStorage info');
    const output = [];
    const sizes: number[] = [];
    Object.entries(this.localStorage[0].value).forEach(([key, value]) => {
      const valueString = JSON.stringify(value);
      let sizeInBytes = 0;
      for (let i = 0; i < valueString.length; i++) {
        sizeInBytes += valueString.charCodeAt(i);
      }
      const sizeInKb = sizeInBytes / 1024;
      sizes.push(sizeInKb);
      const nbrOfItems = Object.keys(value).length;

      output.push({ key, sizeInKb, nbrOfItems });
    });
    output.push({ key: 'Total', sizeInKb: sizes.reduce((a, b) => a + b, 0) });
    console.table(output);
    console.groupEnd();
  }

  private toggleCandidatePro() {
    this.dispatch?.(setCandidatePro());
  }

  private toggleDarkMode() {
    this.dispatch(toggleDarkMode());
  }

  public getFeatures() {
    const featureMap = this.getState().ui.togglers.dev;
    return flatten(featureMap);
  }

  private getAppVersion() {
    return process.env.REACT_APP_BUILD_NUMBER!;
  }

  private getBuildVersion() {
    if (!isStageDevelopment()) return 'local dev';

    return process.env.REACT_APP_STAGE_BUILD_VERSION!;
  }

  private getState() {
    return this.state;
  }

  private getEnv(name?: string) {
    if (!name) return process.env.NODE_ENV;
    return process.env[`REACT_APP_${name.toUpperCase()}`];
  }
}

const logPusherEvents =
  (channel: string) =>
  (eventName: string, { message }: { message: string }) => {
    if (eventName === 'pusher:subscription_succeeded') {
      console.log('Subscribed to', channel);
      return;
    }
    console.groupCollapsed(`${channel} ${eventName}`);
    try {
      if (message) console.log(JSON.parse(message));
    } catch (e) {
      console.log(message);
    }
    console.groupEnd();
  };

export const isReduxLoggerEnabled = (getState: () => RootState) => {
  return selectToggler(['dev', 'logger', 'redux'])(getState());
};

export const isLoggingReducer = (name: string) => {
  const { dev } = window;
  if (!dev?.getFeatures) return false;

  const features = dev.getFeatures();
  return features[`logger.reducer.${name}`];
};
