import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

const selectCandidateSearch = (state: RootState) => state.candidateSearch;

export const selectSearchOptions = createSelector(
  selectCandidateSearch,
  state => state.searchOptions
);

export const selectFilterOptions = createSelector(
  selectCandidateSearch,
  state => state.filterOptions
);

export const selectCurrentSearch = createSelector(
  selectCandidateSearch,
  state => state.currentSearch
);

export const selectSavedSearches = createSelector(
  selectCandidateSearch,
  state => state.savedSearches
);

export const selectSaveSearchPopup = createSelector(
  selectCandidateSearch,
  state => state.saveSearchPopup
);

export const selectSavedSearchToDelete = createSelector(
  selectCandidateSearch,
  state => state.savedSearchToDelete
);
