import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { SkillRetrievalKey } from '@cohiretech/common-types';

import * as fetcher from 'fetcher';
import { isEmpty } from 'utils';
import { useSkillOptions } from 'hooks/useSkillOptions';

import AutoComplete from 'components/autocomplete';
import SelectedItems from 'components/selecteditems';
import SelectableButton from 'components/selectablebutton';
import { FormInput } from 'components/selectquestion';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { StyledNote } from 'v2/components/ui/styles/FieldValidation';

type Props = {
  showQuestion?: boolean;
  question?: $TSFixMe[] | string;
  defaultSkills?: string[] | null;
  otherSkills?: string[];
  skillExample?: string;
  showRecommendationsFor?: 'primary' | 'secondary';
  hideRecommendationsLabel?: boolean;
  onSkillUpdate?: $TSFixMeFunction;
  skillType?: string;
  maxSelection?: number;
  disableItemsLabel?: string;
  columns?: number;
  rows?: number;
  sameAs?: string;
  skillsToCopy?: string[];
  optional?: boolean;
  selectedJobTitles?: string[];
  excludeSalesExperienceSkills?: boolean;
  footnote?: string;
  noneSelectedMessage?: string;
  formSubmitted?: boolean;
  placeholder?: string;
};

export default function SkillAutocompleteField({
  showQuestion = true,
  defaultSkills = [],
  otherSkills = [],
  skillExample,
  showRecommendationsFor,
  hideRecommendationsLabel,
  onSkillUpdate,
  question,
  skillType,
  maxSelection,
  disableItemsLabel,
  columns = 3,
  rows,
  sameAs,
  skillsToCopy = [],
  optional = false,
  selectedJobTitles,
  noneSelectedMessage,
  formSubmitted,
  excludeSalesExperienceSkills,
  footnote,
  placeholder
}: Props) {
  const [selectedSkills, setSelectedSkills] = useState(defaultSkills || []);
  const [recommendedSkills, setRecommendedSkills] = useState([]);
  const [recommendedSkillsShown, setRecommendedSkillsShown] = useState<string[]>([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const category: SkillRetrievalKey = excludeSalesExperienceSkills ? 'SkillToCategory' : 'All';
  const { skillOptions, salesExperienceOptions } = useSkillOptions(category);
  const skillPlaceholder = placeholder || `Type and select ${skillType}`;

  const getFilteredRecommendations = (rS: string[], oS: string[], sS: string[]) => {
    const unselectedRecommendations = rS.filter(skill => {
      const salesSkillCondition =
        !excludeSalesExperienceSkills || !salesExperienceOptions.includes(skill);

      return !oS.includes(skill) && !sS.includes(skill) && salesSkillCondition;
    });

    return unselectedRecommendations;
  };

  useEffect(() => {
    if (recommendedSkills.length > 0) {
      let rS = getFilteredRecommendations(recommendedSkills, otherSkills, selectedSkills);

      if (rows) {
        rS = rS.slice(0, rows * columns);
      }

      setRecommendedSkillsShown(rS);
    }
  }, [otherSkills.length, recommendedSkills, selectedSkills, salesExperienceOptions]);

  useEffect(() => {
    if (!isEmpty(selectedJobTitles) && isEmpty(selectedSkills)) {
      const firstSelectedJobTitle = selectedJobTitles![0];

      getSkillRecommendations({ type: 'jobTitle', value: firstSelectedJobTitle });
    }
  }, [selectedJobTitles, selectedSkills]);

  useEffect(() => {
    onSkillsChange(defaultSkills);
  }, [(defaultSkills || []).join(',')]);

  const getSkillRecommendations = async ({ type, value }: { type?: string; value: string }) => {
    const { data } = await fetcher.getRecommendedSkills(
      value,
      showRecommendationsFor,
      showRecommendationsFor,
      type
    );

    const filteredRecommendations = getFilteredRecommendations(data, otherSkills, selectedSkills);
    if (isEmpty(filteredRecommendations)) return;

    setRecommendedSkills(data);
    setRecommendedSkillsShown(filteredRecommendations);
  };

  const onSkillsChange = (skills: $TSFixMe = []) => {
    setSelectedSkills(skills);

    if (onSkillUpdate) onSkillUpdate(skills);
  };

  const selectSkill = (skill: $TSFixMe) => {
    const skills = [...selectedSkills];

    skills.push(skill);

    onSkillsChange(skills);

    if (showRecommendationsFor && skills.length === 1) getSkillRecommendations({ value: skill });
  };

  const removeSkill = (skill: $TSFixMe) => {
    const skills = [...selectedSkills];
    const index = skills.indexOf(skill);

    skills.splice(index, 1);

    onSkillsChange(skills);
  };

  const disableSelection = maxSelection ? selectedSkills.length > maxSelection - 1 : false;

  return (
    <div className="field">
      {showQuestion && (
        <label className="question">
          {question}
          {skillExample && <span>(e.g. {skillExample})</span>}
          {optional && <span>(optional)</span>}
          <StyledNote
            className="note"
            invalidInput={(formSubmitted || showErrorMessage) && isEmpty(selectedSkills)}
          >
            Choose {maxSelection ? `up to ${maxSelection}` : 'multiple'}
          </StyledNote>
        </label>
      )}
      {skillOptions && (
        <AutoComplete
          id="skill_interests_autocomplete"
          list={skillOptions}
          onSelect={(skill: $TSFixMe) => selectSkill(skill)}
          selectedItems={selectedSkills}
          disabledItems={otherSkills}
          removeSelected
          placeholder={skillPlaceholder}
          disableItemsLabel={disableItemsLabel}
          disabled={disableSelection}
          disabledPlaceholder={`You’ve selected${
            maxSelection ? ` ${maxSelection} ` : ' '
          }${skillType}. To add another, remove one of the skills selected.`}
          overlayLink={
            sameAs
              ? { text: `Copy ${sameAs}`, action: () => onSkillsChange(skillsToCopy) }
              : undefined
          }
          noneSelectedMessage={noneSelectedMessage}
          formSubmitted={formSubmitted}
          showErrorMessage={showErrorMessage}
          onBlur={() => setShowErrorMessage(true)}
        />
      )}
      <SelectedItems
        selectedItems={selectedSkills}
        columns={columns}
        removeItem={(skill: $TSFixMe) => removeSkill(skill)}
        ordered
        onOrderUpdate={(skills: $TSFixMe) => onSkillsChange(skills)}
      />
      <ConditionalRender predicate={footnote}>
        <Footnote className="footnote grey_text">{footnote}</Footnote>
      </ConditionalRender>
      {showRecommendationsFor && recommendedSkillsShown.length > 0 && !disableSelection && (
        <>
          <ConditionalRender predicate={!hideRecommendationsLabel}>
            <label className="question">
              {selectedSkills.length > 0
                ? `People with "${selectedSkills[0]}" skill also have:`
                : ''}
            </label>
          </ConditionalRender>
          <div className="select_options">
            {recommendedSkillsShown
              .filter(skill => !selectedSkills.includes(skill))
              .map(skill => (
                <SelectItem
                  key={skill}
                  className={getColumnClassName(columns)}
                  formInput
                  onClick={() => selectSkill(skill)}
                  tooltip={skill}
                >
                  {skill}
                </SelectItem>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

const getColumnClassName = (columns: number) => {
  switch (columns) {
    case 2:
      return 'two_cols';
    case 3:
      return 'three_cols';
    default:
      return 'four_cols';
  }
};

const SelectItem = styled(SelectableButton)`
  ${FormInput}
`;

const Footnote = styled.em`
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: ${({ theme: { typography } }) => typography.small};
`;
