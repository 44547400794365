import React from 'react';
import { Link } from 'react-router-dom';

import { COMPANIES_HUB_LINK, PEOPLE_HUB_LINK } from 'consts';

const supportEmailLink = (
  <a
    key="support_email_link"
    href="mailto:support@cord.co"
    aria-label="Go to your email client"
    className="link"
  >
    support@cord.co
  </a>
);

export const getFAQsOnFounders = () => [
  {
    id: 'faq_reason_for_founder',
    title: 'Why does cord offer a Founder Program?',
    description: (
      <span>
        Since 2016, thousands of people have used cord to find their best work in companies across
        the UK, USA and Europe, including in hundreds of early-stage companies. Whole teams are
        built on cord that wouldn't otherwise exist.
        <br />
        <br />
        We understand the challenges that early-stage companies face hiring their first employees,
        especially on tight budgets. Our Founder Program is designed to help the start-up ecosystem
        grow by giving early-stage companies access to cord with a 25% discount.
      </span>
    )
  },
  {
    id: 'faq_requirements_for_founder',
    title: 'Who is eligible for the Founder Program?',
    description:
      "cord's Founder Program is designed to support early-stage companies hiring their first engineering and product positions. It enables founders to find people actively looking for work and make direct hires. The program is open to early-stage companies who are hiring in the UK, USA, or remote across Europe, and that have less than 25 employees."
  },
  {
    id: 'faq_founder_features',
    title: 'What is included in the Founder Program?',
    description: [
      'Eligible companies get full access to whichever plan they choose. For specific details, see ',
      <Link to="/pricing" aria-label="Go to pricing page" className="link">
        Pricing and plans
      </Link>,
      '.'
    ]
  },
  {
    id: 'faq_after_period_of_founder',
    title: 'What happens after six months?',
    description:
      "After six months, your Founder Program eligibility will expire and the Customer Success Team will work with you to find the right plan for your continued hiring needs. If you're still eligible for the Founder Program, we encourage you to re-apply and your application will be re-assessed."
  },
  {
    id: 'faq_pause_founder',
    title: 'Can I pause my membership?',
    description:
      "Yes, you can pause your membership at any point and return at any point. If you choose to return more than six months after you were initially enrolled on the program, you'll need to re-apply to the Program in order to verify that your company still meets the criteria."
  },
  {
    id: 'faq_founder_onboarding',
    title: 'Will there be onboarding support?',
    description: [
      "Yes! We provide onboarding to all companies on the Founder Program. You'll be invited to attend an onboarding call with a Customer Success Manager, who will take you through how to make the most of your cord account. You'll also have access to our self-serve resources on our ",
      <a
        key="knowledge_hub"
        href={COMPANIES_HUB_LINK}
        aria-label="Open company knowledge hub in a new tab"
        className="link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Knowledge Hub
      </a>,
      '. For any technical questions, our Support Team is available at ',
      supportEmailLink,
      '.'
    ]
  }
];

export const getFAQsOnSanFranPage = () => [
  {
    id: 'faq_what_is_cord',
    title: 'What is cord?',
    description: (
      <span>
        cord is the hiring tool used by thousands of technology companies to find quality candidates
        and make direct hires. See the{' '}
        <Link to="/stories" aria-label="Go to success stories page" className="link">
          success stories
        </Link>{' '}
        of companies.
      </span>
    )
  },
  {
    id: 'faq_who_qualifies',
    title: 'Who qualifies for the San Francisco Startup Program?',
    description:
      'If you are a technology startup of 25 people or less, based in the San Francisco Bay area & hiring for a software development position, you can use cord for free.'
  },
  {
    id: 'faq_why_is_it_free',
    title: 'Why is it free for early adopters?',
    description:
      'The SF Startup Program is our investment in the startup ecosystem. We aim to give small, resource-limited companies the chance to grow without price getting in the way.'
  },
  {
    id: 'faq_give_card_details',
    title: 'Do I need to make any commitments or give card details?',
    description:
      'No, the San Francisco Startup Program is no commitment and is entirely free for as long as your company is less than 25 people. No commitment or card details are required.'
  },
  {
    id: 'faq_charge_per_hire',
    title: 'Will you charge per hire?',
    description:
      'No, our standard pricing is paid monthly with no pay per hire, but for San Francisco Bay Area companies on the Startup Program, the standard plan is entirely free.'
  }
];

export const getFAQsOnESSUPage = () => [
  {
    id: 'faq_reason_for_es_su_program',
    title: 'Why does cord offer an Early-Stage and Start-Up Programmes?',
    description:
      'We believe that by enabling small, ambitious companies to hire great people, we are investing in the future of the tech economy. We therefore discount the price of cord for smaller companies to ensure they have access to the tools they need to build great teams.'
  },
  {
    id: 'faq_requirements_for_es_su_program',
    title: 'Who is eligible for the Early-Stage or Start-Up Programmes?',
    description: (
      <span>
        Companies between 1-25 employees qualify for the early Early-Stage Programme.
        <br />
        Companies between 26-100 employees qualify for the Start-Up programme.
      </span>
    )
  },
  {
    id: 'faq_es_su_program_features',
    title: 'What is included in the Early-Stage or Start-Up Programmes?',
    description:
      "The Early-Stage and Start-Up Programmes include every features available in cord's Standard plan, at a lower cost.  Early Stage companies (between 1-25 employees) will get a 40% discount on the standard price. Start-Up companies (between 26-100 employees) will get a 20% discount on the standard price."
  },
  {
    id: 'faq_es_su_program_annual_subscription',
    title: 'Can I buy an annual subscription through the Early-Stage or Start-Up Programmes?',
    description:
      'Yes, companies on either the Early-Stage or Start-Up Programmes can save more money by buying cord on annual subscription. They will get an additional 25% discount on the monthly cost for doing so.'
  },
  {
    id: 'faq_pause_es_su_program',
    title: 'Can I pause my subscription?',
    description:
      "Yes, cord's plans are designed to be flexible for companies with occasional hiring needs. On a monthly plan, you can pause your subscription at any point with immediate effect."
  },
  {
    id: 'faq_es_su_program_onboarding',
    title: 'Will there be onboarding support?',
    description:
      "Yes, cord's Early-Stage and Start-Up Programmes include the same support as cord's Standard plan. You'll have 1-1 onboarding available with one of our experts, and ongoing support at no extra cost."
  }
];

const getPaymentMethod = (pricingVer?: number) => {
  switch (pricingVer) {
    case 5:
      return 'On the Standard Plan, payment is taken automatically as a card payment. This can be cancelled at any time. Invoicing is only available on Custom and Annual plans.';
    case 4:
    case 3:
      return 'On the Growth Plan and Founder Program, payment is taken automatically as a card payment. This can be cancelled at any time. Invoicing is only available on Customised and Annual plans.';
    default:
      return 'On the Starter, Growth and Scaling plans, payment is taken automatically as a card payment. This can be cancelled at any time. Invoicing is available on all Annual plans.';
  }
};

const getDiscountDescription = (pricingVer?: number) => {
  switch (pricingVer) {
    case 5:
      return (
        <span>
          Yes. By choosing the annual option, every company can get a 25% discount.
          <br />
          <br />
          To help invest in the future of technology companies, cord also offers 2 programmes for
          smaller companies. Companies of 1-25 employees get a 40% Early-Stage discount and
          companies of 26-100 employees get a 20% Start-Up Discount.{' '}
          <Link
            to="/es-su-programme"
            aria-label="Go to Early-Stage and Start-Up Programmes page"
            className="link"
          >
            Find out more
          </Link>
          .
          <br />
          <br />
          To help support charitable causes, cord also offers non-profits and charities can use cord
          with a 50% discount. Please email {supportEmailLink} for more information.
        </span>
      );
    default:
      return [
        "Yes. By choosing the annual option, you'll get 25% off. Also, non-profits and charities can use cord with a 50% discount. Please email ",
        supportEmailLink,
        ' for more information.'
      ];
  }
};

export const getPayPerHireFAQs = () => {
  const faqs = [
    {
      id: 'faq_people_on_cord',
      title: 'What is the difference between the people on cord and people on Linkedin?',
      description: [
        'People on cord perform better in your pipeline because they are high intent and high quality. Every person on cord is actively looking for work and pre-qualified through a verification process. Read more about the verification process ',
        <a
          key="profile_verification_link"
          href={`${PEOPLE_HUB_LINK}/getting-started/qpeAQUcf8ufAsYg3bSkLXQ/profile-verification/7mAjfkm9jGqQ6riKvieXC5`}
          className="link"
          aria-label="Open profile verification support page in a new tab"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>,
        '.'
      ]
    },
    {
      id: 'faq_how_to_get_started',
      title: 'How do I get started?',
      description: [
        <a
          key="book_a_call_link"
          href="https://cord.co/request-demo"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a call
        </a>,
        ' with one of the team.'
      ]
    },
    {
      id: 'faq_upfront_cost',
      title: 'Is there any upfront cost?',
      description: 'No, you can join for free and only pay when you hire someone.'
    },
    {
      id: 'faq_payments',
      title: 'How does cord take payments?',
      description:
        'Once you’ve made a hire, an invoice will be issued on the candidate’s employment start date. The invoice will be due within 30 days.'
    },
    {
      id: 'faq_cancel',
      title: 'What if I want to cancel?',
      description: 'You can cancel anytime by contacting your Customer Success Manager.'
    },
    {
      id: 'faq_discounts',
      title: 'Do you offer discounts?',
      description:
        'Discounts are not available on the pay per hire pricing. Please look at the monthly and annual subscription options for discounts available on those plans.'
    },
    {
      id: 'faq_questions',
      title: 'What if I have more questions?',
      description: [
        'For new customers, please reach out to ',
        <a
          key=""
          href="mailto:sales@cord.co"
          className="link"
          aria-label="Send email to sales@cord.co"
          target="_blank"
          rel="noopener noreferrer"
        >
          sales@cord.co
        </a>,
        '.',
        <br />,
        'For existing customers, please reach out to ',
        <a
          key=""
          href="mailto:support@cord.co"
          className="link"
          aria-label="Send email to support@cord.co"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@cord.co
        </a>,
        '.'
      ]
    }
  ];

  return faqs;
};

export const getFAQsOnPricing = (overagePrice: string, pricingVer?: number) => {
  const faqs = [
    {
      id: 'faq_person_sourced_definition',
      title: 'When is a sourcing credit used?',
      description: [
        'On cord you can message candidates, or they can message you. If either of you accepted each others message request, this signals mutual interest and a sourcing credit is used.',
        <p key="second_paragraph">
          When a person is sourced on cord, you open a message thread where you can continue to
          speak to the person on cord. You get access to their contact information: CV, email
          address and phone number (when provided) so you can continue to communicate outside of
          cord. You will also be able to send their information directly to your ATS if using one of
          the ATS integrations.
        </p>
      ]
    },
    {
      id: 'faq_how_many_people',
      title: 'How many sourcing credits should I buy?',
      description: [
        'The number of sourcing credits you need to buy depends on how many people you typically source to make a hire. To see how other companies perform, you can use our ',
        <Link
          key="sourcing_calculator_link"
          to="/hires#sourcing_calculator"
          aria-label="Go to live hires page"
          className="link"
        >
          sourcing calculator
        </Link>,
        '. As it stands, it typically takes 11 sourcing credits on average to make a hire on cord.'
      ]
    },
    {
      id: 'faq_reach_sourcing_limit',
      title: 'What happens if I use all of my sourcing credits?',
      description: `We'll notify you via email when you've used 80% of your sourcing credits. When you reach your limit, the search tool and streams will be closed and you won't be able to message new candidates until your billing cycle refreshes. However, if you would like to continue sourcing, you can choose to upgrade your plan to get more sourcing credits, or you can continue sourcing on a “Pay as you go” basis where every extra credit you use will be charged at ${overagePrice}. You'll be invoiced for the extra sourcing credits at the end of your billing cycle.`
    },
    {
      id: 'faq_unused_sourcing_credits',
      title: "What happens if I don't use all my sourcing credits?",
      description:
        "As long as your subscription is active you can roll over up to 2x your monthly/yearly sourcing credits. For instance, if you have 30 sourcing credits/month in your plan and you use only 20, you'll have 40 sourcing credits available to use in your next month. If you stop using cord for 2 consecutive months but keep your subscription active, you'll accumulate 60 sourcing credits and start your hiring the next month with 90 sourcing credits available."
    },
    {
      id: 'faq_recredit',
      title: "What happens if someone replies back saying they're not interested?",
      description: [
        'Your account will be recredited in any of the following scenarios:',
        <ul key="recredit_reasons" className="disc_style">
          <li>The person replied saying they're not looking for work</li>
          <li>
            The person has accepted your message request, only to say they are not interested in
            your position or already found a job
          </li>
          <li>The person is not looking for permanent employment</li>
          <li>
            The information available to you on the person's profile was misleading or incomplete
            when you first message them.
          </li>
        </ul>,
        'Recrediting happens in one of two ways:',
        <ul key="recredit_ways" className="disc_style">
          <li>
            <strong>Manually:</strong> If you wish to request a recredit you can do so via your
            messaging inbox. Go to More Settings {'>'} Request recredit and your request will be
            processed within 1 working day.
          </li>
          <li>
            <strong>Automatically:</strong> There are also occasions where our algorithms are able
            to spot a potential recredit. When this happens, your account will be instantly
            recredited.
          </li>
        </ul>
      ]
    },
    {
      id: 'faq_people_on_cord',
      title: 'What is the difference between the people on cord and people on Linkedin?',
      description: [
        'People on cord perform better in your pipeline because they are high intent and high quality. Every person on cord is actively looking for work and pre-qualified through a verification process. Read more about the verification process ',
        <a
          key="profile_verification_link"
          href={`${PEOPLE_HUB_LINK}/getting-started/qpeAQUcf8ufAsYg3bSkLXQ/profile-verification/7mAjfkm9jGqQ6riKvieXC5`}
          className="link"
          aria-label="Open profile verification support page in a new tab"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>,
        '.'
      ]
    },
    {
      id: 'faq_how_to_get_started',
      title: 'How do I get started on cord?',
      description:
        "To get started on cord, all you need to do is choose your plan and click create account. You'll then be able to set up your company's profile on cord, and within a few minutes start sourcing people."
    },
    {
      id: 'faq_how_take_payments',
      title: 'How does cord take payments?',
      description: getPaymentMethod(pricingVer)
    },
    {
      id: 'faq_change_plan',
      title: 'Can I change my plan later?',
      description:
        "Yes, you can upgrade or downgrade your plan on your billing page in two clicks. If you upgrade your plan halfway through your billing cycle we will pro-rata the changes so you don't pay for any un-used time on the new plan."
    },
    {
      id: 'faq_cancel_subscription',
      title: 'What if I want to cancel?',
      description:
        "You can cancel your monthly subscription at any point via the Settings page in your cord account. You'll need to cancel before your subscription is due to renew. Once you've cancelled, you'll have access to cord up until the end of the current billing period. After that you won't be able to source more people on cord, but you will still be able to log in and access your message inbox and continue any conversations with people you've already sourced."
    },
    {
      id: 'faq_discounts',
      title: 'Do you offer discounts?',
      description: getDiscountDescription(pricingVer)
    },
    {
      id: 'faq_support',
      title: 'What if I have more questions?',
      description: [
        'For new customers, please reach out to ',
        <a
          key="sales_email_link"
          href="mailto:sales@cord.co"
          aria-label="Go to your email client"
          className="link"
        >
          sales@cord.co
        </a>,
        '. For existing customers, please reach out to ',
        supportEmailLink,
        '.'
      ]
    }
  ];

  if (pricingVer === 4 || pricingVer === 5) {
    faqs.splice(8, 1); // Remove a question about changing plan
    return faqs.slice(5); // Remove all questions related to sourcing credits
  }

  if (pricingVer === 3) {
    faqs.splice(2, 0, {
      id: 'faq_buy_extra_credits',
      title: 'Can I buy additional sourcing credits?',
      description:
        "You can buy extend your sourcing credits at any point by upgrading your plan. If you're subscribed to our monthly plan, your credits will renew every month and any unused credits of up to 2x of your plan limit will rollover to the next month. If you're subscribed to our annual plan, you get all of your credits upfront and can purchase more by upgrading your plan. Any unused credits will also rollover to the next year."
    });
  }

  return faqs;
};
