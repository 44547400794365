import { css } from 'styled-components';

export const RadioButtonStyling = css`
  ${({ theme: { typography } }) => css`
    &.selected {
      font-weight: ${typography.bold};
    }

    .icon_help {
      font-size: ${typography.normal};
    }

    // Override the current v2/RadioButtons styles
    padding: 0;
    padding-left: 1.75rem;
    height: unset;

    &:not(.selected):hover .radio_mark:after {
      background: var(--text-body-primary);
      opacity: 0.24;
    }

    .radio_text {
      line-height: normal;
      margin-top: 0;
    }

    .radio_mark {
      left: 0;
      border-width: 2px;

      &:after {
        width: 45%;
        height: 45%;
      }
    }
  `}
`;
