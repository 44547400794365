import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import styled from 'styled-components';

import { useDarkMode } from 'hooks/useDarkMode';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { usePositionProfile } from '../../PositionProfile.context';
import { InsightsBlock, InsightsTitle } from '../../InsightsBlock';

import {
  getSalaryComparisonBlockTitle,
  getSalaryComparisonChartProps,
  shouldShowSalaryComparisonBlock
} from './PositionSalaryComparisonBlock.helpers';
import SalaryComparisonChartLegend from './SalaryComparisonChartLegend';

const SUB_TITLE = 'Position salary comparison';
const DEFAULT_TITLE = 'See if the position is paying less or more than the industry average';

type ApexChartProps = {
  series: ApexOptions['series'];
  options: ApexOptions;
};

export default function PositionSalaryComparisonBlock() {
  const darkMode = useDarkMode();
  const { currency, insightsData } = usePositionProfile();
  const { salaryRanges } = insightsData.position || {};

  const [title, setTitle] = useState(DEFAULT_TITLE);
  const [apexChartProps, setApexChartProps] = useState<ApexChartProps>();
  const [showBlock, setShowBlock] = useState(
    shouldShowSalaryComparisonBlock(currency, salaryRanges)
  );

  useEffect(() => {
    if (salaryRanges) {
      setTitle(getSalaryComparisonBlockTitle(salaryRanges));
      setApexChartProps(getSalaryComparisonChartProps(salaryRanges, darkMode));
    }

    setShowBlock(shouldShowSalaryComparisonBlock(currency, salaryRanges));
  }, [salaryRanges]);

  const { market, position } = salaryRanges || {};
  const { series = [], options = {} } = apexChartProps || {};

  return (
    <ConditionalRender predicate={showBlock}>
      <SalaryComparisonBlock
        size="large"
        subTitle={salaryRanges?.position?.visible ? SUB_TITLE : 'Average salary range'}
        defaultTitle={DEFAULT_TITLE}
        title={title}
        cta={{ text: 'View salary benchmarks', link: '/candidate/insights' }}
      >
        <SalaryComparisonChartWrapper>
          <SalaryComparisonChartLegend
            isSalaryUndisclosed={!position?.visible}
            metadata={market?.metadata}
          />
          <ReactApexChart type="bar" series={series} height={260} options={options} />
        </SalaryComparisonChartWrapper>
      </SalaryComparisonBlock>
    </ConditionalRender>
  );
}

const SalaryComparisonBlock = styled(InsightsBlock)`
  padding: 1.5rem 1.75rem;

  ${InsightsTitle} {
    margin: auto;

    ${({ theme: { media } }) => media.mobileMin`
      width: 60%;
    `}

    ${({ theme: { media } }) => media.tabletLandscapeMin`
      width: 40%;
    `}
  }
`;

const SalaryComparisonChartWrapper = styled.div`
  width: 100%;
  margin-top: 1.25rem;
`;
