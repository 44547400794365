import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { CandidateStatus } from '@cohiretech/common-types';

import * as fetcher from 'fetcher';
import { prettyTextFromArray } from 'utils';
import { useCandidateProfile } from 'store';
import { useCandidateData } from 'store/services';

import Toggle from 'components/toggle';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

const { Active, Passive } = CandidateStatus;

// CORD-4597: After review - Move to under AccountDropdown
export default function StatusToggle({ onStatusActive }: { onStatusActive: () => void }) {
  const profile = useCandidateProfile()!;
  const { reloadCandidateData } = useCandidateData();
  const {
    status: currentStatus,
    jobType,
    visible: currentVisible,
    verified,
    rejected,
    previouslyVerified
  } = profile;

  const [status, setStatus] = useState(currentStatus || Active);
  const [visible, setVisible] = useState(currentVisible || false);
  const isActive = status === Active;
  const isWrongJobType = !fetcher.VALID_JOB_TYPES.includes(jobType);

  useEffect(() => {
    setStatus(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    setVisible(currentVisible);
  }, [currentVisible]);

  const onStatusChange = async (active: boolean) => {
    if (blockStatusToggle()) return;

    const newStatus = active ? Active : Passive;

    setStatus(newStatus);
    if (active) onStatusActive();

    const { status: apiStatus } = await fetcher.editProfileInfo({ status: newStatus });

    if (apiStatus === 'success') reloadCandidateData?.();
    else setStatus(active ? Passive : Active);
  };

  const onVisibilityChange = async (visible: boolean) => {
    if (blockStatusToggle() && visible) return;
    setVisible(visible);
    const { status: apiStatus } = await fetcher.setVisible(visible);

    if (apiStatus === 'success') reloadCandidateData?.();
    else setVisible(!visible);
  };

  const blockStatusToggle = () => (isWrongJobType && !isActive) || (previouslyVerified && verified);

  const getTooltipDescription = () => {
    if (isWrongJobType) {
      const jobTypeText =
        jobType === fetcher.JOB_TYPES.INTERNSHIP ? 'intern' : jobType.toLowerCase();
      const validLowerCaseJobTypes = fetcher.VALID_JOB_TYPES.map(type =>
        type === fetcher.JOB_TYPES.FTC ? type : type.toLowerCase()
      );
      const validJobTypesText = prettyTextFromArray(validLowerCaseJobTypes, 'or');

      return [
        <strong key="bold">Job type interest is not supported</strong>,
        `cord does not currently support ${jobTypeText} positions.
      For this reason, your profile has been automatically hidden from companies. 
      If you're looking for ${validJobTypesText} positions you can update your profile to continue using cord.`
      ];
    } else if (previouslyVerified) {
      return 'Your profile is being reviewed and it shouldn\'t take long. Your status will change to "Open to opportunities" once the review is complete.';
    }
  };

  return (
    <ConditionalRender predicate={!rejected}>
      <StatusWrapper className="status_wrapper">
        <ConditionalRender predicate={blockStatusToggle()}>
          <Tooltip text={getTooltipDescription()} width="250px" position="bottom" />
        </ConditionalRender>
        <Toggle
          type="inverted_bg"
          active={isActive}
          onToggle={active => onStatusChange(active)}
          disabled={blockStatusToggle()}
        />
        <div className="status_description">
          <strong
            className={ClassNames({
              disabled: blockStatusToggle(),
              blue_gradient_text: isActive,
              red_text: !isActive
            })}
          >
            {isActive ? 'Open to opportunities' : 'Not looking'}
          </strong>
          <p>
            Your profile {!verified ? 'will be ' : 'is '}
            <button className="toggle_button" onClick={() => onVisibilityChange(!visible)}>
              <span
                className={ClassNames('toggle_icon', {
                  icon_visible: visible,
                  icon_invisible: !visible
                })}
              />
              {visible ? 'visible' : 'invisible'}
            </button>
          </p>
        </div>
      </StatusWrapper>
    </ConditionalRender>
  );
}

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-divider-soft);

  strong {
    display: block;

    &.disabled {
      opacity: 0.5;
    }
  }

  p {
    margin: 0;
    font-size: 14px;
    color: var(--text-body-primary);
  }

  .toggle_button {
    vertical-align: middle;
    align-items: center;
    margin-left: 2px;
    padding: 0 0.25rem;
    border-radius: 20px;
    background: var(--background-tag-soft);

    span {
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  &:hover > .tooltip_wrapper {
    display: block;
    padding-top: 0;
  }
`;
