import React from 'react';
import ClassNames from 'classnames';

import { getMatchingResults, isEmpty, scrollToElementInList, sortAutocompleteResults } from 'utils';
import { getIsDarkMode_FIXME } from 'cookieManager';
import { isMobileWidth } from 'responsiveConfig';

import './style.scss';
import ConditionalRender from 'v2/components/utility/ConditionalRender';
import FieldErrorMessage from 'v2/components/ui/molecules/FieldErrorMessage';
import { StyledInput } from 'v2/components/ui/styles/FieldValidation';

export type ListItem = string | number | { [key: string]: any };

export type AutoCompleteProps = {
  placeholder: string;
  onChange?: (value: string) => void;
  onSelect: (item: ListItem) => void;
  onBlur?: () => void;
  list?: ListItem[];
  id: string;
  searchThroughTags?: boolean;
  showSelectionInInput?: boolean;
  removeSelected?: boolean;
  selectedItems: ListItem[];
  disabledItems?: ListItem[];
  disableItemsLabel?: string;
  disabled?: boolean;
  disabledPlaceholder?: string;
  showListonFocus?: boolean;
  allowUnknownWordSelection?: boolean;
  selectValueOnChange?: boolean;
  className?: string;
  attributes?: { [key: string]: string };
  clearInputOnFocus?: boolean;
  overlayLink?: {
    text: string;
    action: () => void;
  };
  showArrow?: boolean;
  noneSelectedMessage?: string;
  formSubmitted?: boolean;
  showErrorMessage?: boolean;
};

type State = $TSFixMe;

export default class AutoComplete extends React.Component<AutoCompleteProps, State> {
  constructor(props: AutoCompleteProps) {
    super(props);

    const { showSelectionInInput, selectedItems } = props;
    const selectedWord = this.getSelectedWord(selectedItems);

    this.state = {
      cursor: 0,
      searchWord: showSelectionInInput ? selectedWord : '',
      focused: false
    };

    this.handleArrowKeys = this.handleArrowKeys.bind(this);
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = { showListonFocus: true, allowUnknownWordSelection: false };

  getSelectedWord(selectedItems: $TSFixMe) {
    if (selectedItems && !isEmpty(selectedItems)) {
      const isSelectedString = typeof selectedItems[0] === 'string';
      const selectedWord = isSelectedString ? selectedItems[0] : selectedItems[0].label;

      // This check could be removed once we type the ListItem to definately have a label
      return selectedWord || '';
    }
    return '';
  }

  componentDidUpdate(prevProps: AutoCompleteProps, prevState: State) {
    const { selectedItems, showSelectionInInput, clearInputOnFocus } = this.props;
    const { searchWord, focused } = this.state;

    const selectedItemsChanged = selectedItems !== prevProps.selectedItems;
    const inputUnfocused = !focused && prevState.focused;

    if ((selectedItemsChanged && showSelectionInInput) || (clearInputOnFocus && inputUnfocused)) {
      if (!searchWord) {
        const selectedWord = this.getSelectedWord(selectedItems);
        this.setState({ searchWord: selectedWord });
      } else if (isEmpty(selectedItems)) this.setState({ searchWord: '' });
    }
  }

  autocomplete(list: $TSFixMe, word: $TSFixMe) {
    const { allowUnknownWordSelection, searchThroughTags, removeSelected, selectedItems } =
      this.props;
    const isStringOptions = typeof list[0] === 'string';
    const isObjectOptions = typeof list[0] === 'object';
    const isObjectSelected = typeof selectedItems[0] === 'object';
    const resultsType = isStringOptions ? 'string' : searchThroughTags ? 'tags' : 'label';
    const parsedList =
      removeSelected && (selectedItems as $TSFixMe).length > 0
        ? list.filter((option: $TSFixMe) => {
            if (!isObjectOptions) {
              return selectedItems.findIndex((item: $TSFixMe) => item === option) === -1;
            } else if (!isObjectSelected) {
              return selectedItems.findIndex((value: $TSFixMe) => value === option.value) === -1;
            } else {
              return (
                selectedItems.findIndex((item: $TSFixMe) => item.value === option.value) === -1
              );
            }
          })
        : list;

    let results = getMatchingResults(parsedList, word, resultsType);

    if (word && results.length > 0) results = sortAutocompleteResults(word, results, resultsType);

    if (results.length === 0 && allowUnknownWordSelection && word !== '') {
      return isStringOptions ? [word] : [{ label: word }];
    }
    return results;
  }

  handleArrowKeys(e: $TSFixMe, word: $TSFixMe, autocompleteResults: $TSFixMe) {
    const { cursor } = this.state;
    const { id, list } = this.props;

    if (e.key === 'ArrowUp' && cursor > 0) {
      const result = this.autocomplete(list, word)[cursor - 1];
      scrollToElementInList(`${id}_list`, `${id}_${cursor - 1}`, 'top');

      this.setState((prevState: $TSFixMe) => ({
        cursor: prevState.cursor - 1
      }));

      e.target.value = typeof result === 'string' ? result : result.label;
    } else if (e.key === 'ArrowDown' && cursor < this.autocomplete(list, word).length - 1) {
      const result = this.autocomplete(list, word)[cursor + 1];

      scrollToElementInList(`${id}_list`, `${id}_${cursor + 1}`, 'bottom');

      this.setState((prevState: $TSFixMe) => ({
        cursor: prevState.cursor + 1
      }));

      e.target.value = typeof result === 'string' ? result : result.label;
    } else if (e.key === 'Enter') {
      const selectedOption = autocompleteResults[cursor];

      this.onItemSelect(selectedOption);
    }
  }

  onItemSelect(selectedItem: $TSFixMe) {
    const { onSelect, showSelectionInInput, disabledItems } = this.props;

    if (disabledItems && (disabledItems as $TSFixMe).indexOf(selectedItem) > -1) return false;
    else if (selectedItem) {
      const selectedWord = typeof selectedItem === 'string' ? selectedItem : selectedItem.label;

      onSelect(selectedItem);

      this.setState({ focused: false, searchWord: showSelectionInInput ? selectedWord : '' });
    }
  }

  hideAutocompleteResults() {
    const { onBlur } = this.props;

    onBlur?.();
    setTimeout(() => {
      this.setState({ focused: false, cursor: 0 });
    }, 500);
  }

  onInputFocus() {
    const { showListonFocus, clearInputOnFocus } = this.props;

    if (showListonFocus) this.setState({ focused: true });
    if (clearInputOnFocus) this.setState({ searchWord: '' });
  }

  render() {
    const { cursor, focused, searchWord } = this.state;
    const {
      id,
      list,
      selectedItems,
      disabledItems,
      placeholder,
      disableItemsLabel,
      disabled,
      disabledPlaceholder,
      overlayLink,
      className,
      selectValueOnChange,
      onSelect,
      onChange,
      attributes,
      showArrow,
      noneSelectedMessage,
      formSubmitted,
      showErrorMessage
    } = this.props;
    const isStringOptions = list && typeof list[0] === 'string';
    const darkMode = getIsDarkMode_FIXME();
    const autocompleteResults = this.autocomplete(list, searchWord || '');
    const showNoneSelected = Boolean(
      (formSubmitted || showErrorMessage) && isEmpty(selectedItems) && noneSelectedMessage
    );

    return (
      <div className={ClassNames(className, 'autocomplete_cont', { dark: darkMode })}>
        <StyledInput
          className="search_input"
          type="text"
          {...attributes}
          placeholder={disabled ? disabledPlaceholder : placeholder}
          onChange={e => {
            if (onChange) onChange(e.target.value);
            if (selectValueOnChange) onSelect(e.target.value);
            this.setState({ cursor: 0, focused: true, searchWord: e.target.value });
          }}
          value={searchWord || ''}
          onKeyDown={e => this.handleArrowKeys(e, searchWord, autocompleteResults)}
          onFocus={() => this.onInputFocus()}
          onBlur={() => this.hideAutocompleteResults()}
          disabled={disabled}
          invalidInput={showNoneSelected}
        />
        <FieldErrorMessage show={showNoneSelected} message={noneSelectedMessage} />
        {overlayLink && !disabled && !isMobileWidth && (
          <button type="button" className="link dark_blue" onClick={() => overlayLink.action()}>
            {overlayLink.text}
          </button>
        )}
        <ConditionalRender predicate={showArrow}>
          <span className="icon_simple_arrow_down" />
        </ConditionalRender>
        {focused && autocompleteResults && autocompleteResults.length > 0 && (
          <div className="autocomplete" data-testid="autocomplete" id={`${id}_list`}>
            {autocompleteResults.map((option: $TSFixMe, i: number) => (
              <div
                key={i}
                id={`${id}_${i}`}
                className={ClassNames(
                  'row_item',
                  { focused: cursor === i },
                  {
                    selected:
                      (selectedItems as $TSFixMe).indexOf(option) > -1 ||
                      (selectedItems as $TSFixMe).indexOf(option.value) > -1,
                    disabled: disabledItems && (disabledItems as $TSFixMe).indexOf(option) > -1
                  }
                )}
                onClick={() => this.onItemSelect(option)}
              >
                {option.image && option.image}
                {isStringOptions ? option : option.HTMLLabel || option.label}
                {disabledItems &&
                  (disabledItems as $TSFixMe).indexOf(option) >= 0 &&
                  disableItemsLabel && <span>{disableItemsLabel}</span>}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

AutoComplete.defaultProps = { showListonFocus: true, allowUnknownWordSelection: false };
