import styled, { css } from 'styled-components';

import Button, { Props as ButtonProps } from 'components/button';

export type FilterSelectorProps = ButtonProps & { hasSelection?: boolean };

const FilterSelectorWithoutSelection = css`
  border-width: 1px;

  &,
  &:hover {
    border-color: var(--border-divider-soft);
  }
`;

export const getFilterSelectorStyling = (hasSelection?: boolean) => css`
  ${({ theme: { typography, media } }) => css`
    min-width: max-content;
    padding: 0.75rem 1rem;
    border-radius: 1.5rem;
    border: 2px solid var(--border-accent-secondary-base);
    background: transparent;
    line-height: 1;

    &,
    span {
      color: var(--text-body-primary);
      font-size: var(--type-sm-md);
    }

    [class*='icon_'] {
      font-size: var(--type-sm);
      font-weight: ${typography.black};
    }

    .icon_close {
      ${media.mobile`
        font-size: var(--type-xs);
      `}
    }

    ${!hasSelection && FilterSelectorWithoutSelection}

    &:hover {
      background: var(--background-option-primary-hover);
    }

    ${media.tablet`
        font-size: var(--type-sm);

        [class*='icon_'].left {
          margin-right: 10px;
          font-size: var(--type-sm-md);
        }
      `}
  `}
`;

export const FilterSelector = styled(Button)<FilterSelectorProps>`
  ${({ hasSelection }) => css`
    &&.button {
      ${getFilterSelectorStyling(hasSelection)}
    }
  `};
`;
