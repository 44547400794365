import React from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { CompanyProfile, PrimaryMember } from 'types';
import { storeDraftPosition } from 'utils/companyUtils';

import {
  PositionEditContext,
  PositionEditContextState
} from 'views/company/position/PositionEdit.context';

import SelectableButton from 'components/selectablebutton';
import Tooltip from 'components/tooltip';
import { FormInput } from 'components/selectquestion';

import Avatar from 'v2/components/ui/atoms/Avatar';

type Props = {
  id?: string;
  members: number[];
  primaryMember: PrimaryMember;
  allMembers: CompanyProfile['members'];
  onSetPosition?: (members: number[], primaryMember: PrimaryMember) => void;
};

type State = Pick<Props, 'members' | 'primaryMember' | 'allMembers'>;

export default class CompanyEditPositionHiringTeam extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { members = [], allMembers = [] } = props;
    const primaryMember = props.primaryMember || members?.[0] || allMembers?.[0]?.id || 0;
    this.state = {
      members,
      primaryMember,
      allMembers
    };
  }

  validate() {
    const { members } = this.state;

    let errorMessage = '';

    if (members.length === 0) {
      errorMessage = 'Please select at least one team member for this position.';
    }

    return errorMessage;
  }

  selectTeam(e: $TSFixMe, id: $TSFixMe) {
    const { primaryMember } = this.state;

    const members = [...this.state.members];

    if (!e.target.className.includes('icon_key_contact')) {
      const index = members.findIndex(member => member === id);

      if (index >= 0 && members.length > 1) members.splice(index, 1);
      else members.push(id);

      const primaryMemberInMembers = members.findIndex(id => id === primaryMember) !== -1;

      const updatedPrimaryMember =
        members.length === 1 ? members[0] : primaryMemberInMembers ? primaryMember : 0;

      this.setPosition({ members, primaryMember: updatedPrimaryMember });
    }
  }

  setPosition(state: Pick<State, 'members' | 'primaryMember'>) {
    const { id, onSetPosition } = this.props;
    const { handlePrimaryMemberChange } = (this.context as PositionEditContextState) || {};

    // if creating new position
    if (!id) storeDraftPosition(state);

    handlePrimaryMemberChange?.(state.primaryMember);
    onSetPosition?.(state.members, state.primaryMember);
    this.setState(state);
  }

  componentDidMount() {
    const { primaryMember } = this.state;
    const { handlePrimaryMemberChange } = (this.context as PositionEditContextState) || {};
    handlePrimaryMemberChange?.(primaryMember);
  }

  render() {
    const { members, allMembers, primaryMember } = this.state;

    return (
      <div className="team_block">
        <div className="section">
          {allMembers.map(({ photoURL, memberName, id }) => (
            <FormButton
              key={id}
              className={ClassNames('three_cols', 'with_image')}
              formInput
              selected={members.indexOf(id) >= 0}
              onClick={e => {
                this.selectTeam(e, id);
              }}
            >
              <Avatar
                photoURL={photoURL}
                resizePhotoDimensions={{ width: 40 }}
                width={32}
                firstname={memberName}
              />
              <Label className="label">{memberName}</Label>
              {members.includes(id) && (
                <KeyContact
                  className={ClassNames('icon_key_contact', { selected: primaryMember === id })}
                  onClick={() =>
                    this.setPosition({ members, primaryMember: primaryMember === id ? 0 : id })
                  }
                >
                  <Tooltip
                    position="left"
                    text={primaryMember === id ? 'Unset primary contact' : 'Set primary contact'}
                  />
                </KeyContact>
              )}
            </FormButton>
          ))}
        </div>
      </div>
    );
  }
}

CompanyEditPositionHiringTeam.contextType = PositionEditContext;

const FormButton = styled(SelectableButton)`
  ${FormInput}
`;

const Label = styled.span`
  width: calc(100% - 70px) !important;
`;

const KeyContact = styled.span`
  position: relative;
  opacity: 0.3;
  float: right;
  margin-top: 5px;
  cursor: pointer;

  &:hover {
    opacity: 1;

    > .tooltip_wrapper {
      display: block;
      opacity: 1;
    }
  }

  &.selected {
    opacity: 1;
  }
`;
