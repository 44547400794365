import { changeLanguage } from 'i18next';

import { OperatingCountry } from '@cohiretech/common-types';

import { Locale } from 'types';

export const getSupportedLocaleFromCountry = (country: OperatingCountry | '') => {
  switch (country) {
    case OperatingCountry.US:
      return Locale.en_US;
    default:
      return Locale.en_UK;
  }
};

const setI18nLanguageByCountry = (country: OperatingCountry | '') => {
  const locale = getSupportedLocaleFromCountry(country);
  changeLanguage(locale);
};

export default setI18nLanguageByCountry;
