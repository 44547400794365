import { batch } from 'react-redux';

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AdminAccountInterface,
  CandidateSignupVerificationStatus as CSVS
} from '@cohiretech/common-types';

import { removeLocalStorageItem } from 'cookieManager';
import { getAdmin, getNewCompanies } from 'fetcher';
import {
  AppDispatch,
  RootState,
  setCandidatesToVerify,
  setCompaniesToVerify,
  setUserType,
  setAdminAccount
} from 'store';
import { getCandidatesSignedUp } from 'v2/services/fetchers/admin/profileVerification';
import { setUser } from 'v2/services/app';

export const fetchAdminData = createAsyncThunk<
  { admin: AdminAccountInterface | null },
  void,
  { dispatch: AppDispatch; state: RootState }
>('services/admin/fetchData', async (_, { dispatch }) => {
  const [admin, companies, candidates] = await Promise.all([
    getAdmin(),
    getNewCompanies(true),
    getCandidatesSignedUp(CSVS.Pending)
  ]);

  removeLocalStorageItem('queuedActions');
  if (admin) setUser({ name: admin.name });

  batch(() => {
    dispatch(setAdminAccount(admin));
    dispatch(setUserType('admin'));
    dispatch(setCompaniesToVerify(companies.resultsCount));
    if (candidates.status === 'success') {
      dispatch(setCandidatesToVerify(candidates.data.resultsCount));
    }
  });

  return { admin };
});
