import React, { PropsWithChildren, ReactNode } from 'react';
import ClassNames from 'classnames';

import Button, { Props as ButtonProps } from 'components/button';
import ArrowLink from 'components/arrowlink';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

export type PageSectionProps = {
  id?: string;
  title?: string | (string | JSX.Element)[];
  titleClassName?: string;
  underlinedTitle?: boolean;
  subTitle?: ReactNode;
  subTitleClassName?: string;
  widthClassName?: string;
  className?: string;
  button?: ButtonProps;
  secondaryButton?: ButtonProps;
  arrowLink?: any;
  backdropClassName?: string;
};

export default function PageSection({
  title,
  titleClassName,
  id,
  underlinedTitle,
  subTitle,
  subTitleClassName,
  widthClassName,
  className,
  children,
  button,
  secondaryButton,
  arrowLink,
  backdropClassName
}: PropsWithChildren<PageSectionProps>) {
  const titleStyle = underlinedTitle ? 'underlined_section_title' : 'page_section_title';
  const isBackdrop = !!backdropClassName;
  return (
    <div
      id={id}
      className={ClassNames('page_section', className, { position_relative: isBackdrop })}
    >
      <Backdrop
        isBackdrop={isBackdrop}
        widthClassName={widthClassName}
        backdropClassName={backdropClassName}
      />

      <div className={ClassNames(widthClassName, { position_relative: isBackdrop })}>
        <ConditionalRender predicate={title}>
          <h2 className={ClassNames(titleStyle, titleClassName)}>{title}</h2>
        </ConditionalRender>
        <ConditionalRender predicate={subTitle}>
          <h3 className={ClassNames('page_section_subtitle', subTitleClassName)}>{subTitle}</h3>
        </ConditionalRender>
        {children}
        <Buttons button={button} secondaryButton={secondaryButton} arrowLink={arrowLink} />
      </div>
    </div>
  );
}

type BackdropProps = {
  isBackdrop: boolean;
} & Pick<PageSectionProps, 'backdropClassName' | 'widthClassName'>;

const Backdrop = ({ backdropClassName, widthClassName, isBackdrop }: BackdropProps) => {
  return (
    <ConditionalRender predicate={isBackdrop}>
      <div className={ClassNames('page_section_backdrop', widthClassName, backdropClassName)} />
    </ConditionalRender>
  );
};

type ButtonsProps = Pick<PageSectionProps, 'button' | 'secondaryButton' | 'arrowLink'>;

const Buttons = ({ button, secondaryButton, arrowLink }: ButtonsProps) => {
  return (
    <div className="multiple_buttons">
      <ConditionalRender predicate={button}>
        <Button {...button} />
      </ConditionalRender>
      <ConditionalRender predicate={secondaryButton}>
        <Button {...secondaryButton} />
      </ConditionalRender>
      <ConditionalRender predicate={arrowLink}>
        <ArrowLink {...arrowLink} />
      </ConditionalRender>
    </div>
  );
};
