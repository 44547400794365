import React, { useEffect } from 'react';
import { Location } from 'history';

import { checkIfUserIsInvited, isEmpty, storeReferrerTrackingDetails } from 'utils';
import { mediaQueries } from 'responsiveConfig';
import { useAppDispatch, useAppSelector, selectDarkMode, toggleDarkMode } from 'store';
import useMedia from 'hooks/useMedia';

import Button from 'components/button';
import IconButton from 'components/iconbutton';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import LandingNavigation from './landingnavigation';
import CTAButton from './ctabutton';

type Props = {
  queryString?: string;
  admin?: $TSFixMe;
  profile?: $TSFixMe;
  isPublicNavPage?: boolean;
  darkMode: boolean;
  location: Location;
};

export default function LandingMenu({
  queryString,
  admin,
  profile,
  isPublicNavPage,
  darkMode,
  location
}: Props) {
  const isDarkMode = useAppSelector(selectDarkMode);
  const isTablet = useMedia([mediaQueries.tablet]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    storeReferrerTrackingDetails(queryString);
  }, []);

  const handleThemeChange = () => {
    dispatch(toggleDarkMode(!isDarkMode));
  };

  const isUserInvited = checkIfUserIsInvited(profile);
  const isUserLoggedin = !isEmpty(profile) || !isEmpty(admin);

  return (
    <>
      <nav aria-label="menu" id="landing_menu">
        <LandingNavigation
          location={location}
          darkMode={darkMode}
          isUserLoggedin={isUserLoggedin}
        />
        <ConditionalRender predicate={!isTablet}>
          {/* Todo: below could be a component that returns a different button depending on the props */}
          {isPublicNavPage && isUserLoggedin ? (
            <Button
              text="Your account"
              className="eighteen_px_font"
              link={getCTALink(admin, profile)}
            />
          ) : (
            <CTAButton text={isUserInvited ? 'Activate your invite' : undefined} />
          )}
          <IconButton
            className="menu_icon lightblue_text"
            ariaLabel="toggle theme"
            icon={isDarkMode ? 'icon_sun' : 'icon_moon'}
            onClick={handleThemeChange}
            tooltip={
              <Tooltip text={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`} position="bottom" />
            }
          />
        </ConditionalRender>
      </nav>
    </>
  );
}

const getCTALink = (admin: any, profile: any) => {
  if (!admin === null) return '/admin/dashboard';
  return profile?.isCandidate ? '/candidate/search' : '/company/discover';
};
