import React, { Fragment, useMemo } from 'react';

import {
  checkIfSalaryFilter,
  checkIfWorkingTimezoneFilter,
  hasFilterWithAttribute
} from 'v2/services/tools/positionSearchFilter';
import { useCandidateProfile } from 'store';
import { getCurrencySymbolFromProfile } from 'v2/services/tools/salary';
import { formatNumber } from 'utils';
import { lastIndex } from 'utils/array';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { BoldText } from 'v2/components/ui/styles/StyledText';

import { getWorkingTimezoneLabel, shouldHideTooltip } from './FilterDropdownSelector.helpers';

export function SelectedItemsTooltip() {
  const {
    operator: { operator },
    selectedItems,
    filterName
  } = useExpandableFilter();
  const profile = useCandidateProfile();

  const count = selectedItems?.length;

  const { value, attribute } = selectedItems?.[0] || {};

  const simpleTooltip = useMemo(() => {
    if (checkIfSalaryFilter(attribute)) {
      const currencySymbol = getCurrencySymbolFromProfile(profile);
      return ` is ${currencySymbol}${formatNumber(value, true)}`;
    }

    const isWorkingTimezoneFilter = checkIfWorkingTimezoneFilter(attribute);
    if (isWorkingTimezoneFilter) return ` is between ${getWorkingTimezoneLabel(selectedItems!)}`;
  }, [selectedItems]);

  const getConnector = (idx: number) => {
    if (operator === 'or') return idx ? ` ${operator} ` : count !== 1 && 'any of ';
    return idx === lastIndex(selectedItems!) ? ` ${operator} ` : idx > 0 && ', ';
  };

  const hideTooltip = shouldHideTooltip(attribute);
  const matchVerb = attribute && getMatchVerb(filterName, attribute);

  return (
    <ConditionalRender predicate={!hideTooltip && count}>
      <Tooltip>
        <BoldText>{filterName}</BoldText>
        <ConditionalRender predicate={!simpleTooltip} fallback={simpleTooltip}>
          {' '}
          {matchVerb}{' '}
          {selectedItems?.map(({ attribute, label, value }, idx) => {
            const connector = getConnector(idx);

            return (
              <Fragment key={`${attribute}_${value}`}>
                {connector}
                <BoldText>{label}</BoldText>
              </Fragment>
            );
          })}
        </ConditionalRender>
      </Tooltip>
    </ConditionalRender>
  );
}

const getMatchVerb = (filterName: string, attribute: string) => {
  if (hasFilterWithAttribute(['location', 'companySize'], attribute)) return 'is';
  return new RegExp(/skills$/i).test(filterName) ? 'include' : 'includes';
};
