/* eslint-disable no-console */
import { isArray } from 'utils/fn';

/**
 * Get the first element of an array
 *
 * @param arr the array to get the first element from
 * @returns the first element of the input array or undefined if the input is empty or not an array
 */
export const head = <T = any>(arr: T[]): T | undefined => {
  if (!isArray(arr)) {
    console.warn(`head() expects an array but got ${typeof arr}`);
    return undefined;
  }
  return arr[0];
};
