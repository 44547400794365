import { OperatingCountry } from '@cohiretech/common-types';

import { isAgenciesPage } from 'utils/url';
import { S3_IMAGE_BUCKET_URL } from 'consts';
import { isUSByLocalStorage } from 'utils/localisationUtils';
import { getForCompaniesPageLocalisation } from 'utils/companyUtils';

import ExploreGroup from 'images/blue-explore-group.svg';
import OliverBernard from 'images/blue-oliver-bernard.svg';
import Searchability from 'images/blue-searchability.svg';
import Edge1 from 'images/testimonial-edge-1-logo.svg';
import Formula from 'images/testimonial-formula-logo.svg';
import SR2 from 'images/testimonial-sr2-logo.svg';
import Emtec from 'images/testimonial-emtec-logo.svg';
import IqbalExploreGroup from 'images/iqbal-explore-group.png';
import RyanSearchability from 'images/ryan-searchability.png';
import AdamOliverBernard from 'images/adam-oliver-bernard.png';
import GeorgeSR2 from 'images/george-sr2.png';
import MatthewEdge1 from 'images/matthew-edge-1.png';
import JackEmtec from 'images/jack-emtec.png';
import MarcusFormula from 'images/marcus-formula.png';
import YouNow from 'images/younow-testimonial-logo.svg';
import FanDuel from 'images/fanduel-testimonial-logo.svg';
import Mars from 'images/mars-testimonial-logo.svg';
import MediData from 'images/medidata-testimonial-logo.svg';
import Moodys from 'images/moodys-analytics-testimonial-logo.svg';
import CPS from 'images/cps-testimonial-logo.svg';
import AppleCart from 'images/applecart-testimonial-logo.svg';

const COMPANY_LOGOS_URL = 'https://assets.co-hire.com/company-logos';

export const scrollToBookDemoForm = () => {
  const bookDemoForm = document.getElementsByClassName('with_form')[0] as HTMLElement;
  bookDemoForm.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const getFeaturedQuotes = (pathname: string) => {
  if (isAgenciesPage()) return AGENCIES_QUOTES;

  if (isUSByLocalStorage()) {
    return US_COMPANIES_QUOTES;
  }

  const isUSLocalisedURL = getForCompaniesPageLocalisation(pathname) === OperatingCountry.US;
  return isUSLocalisedURL ? US_COMPANIES_QUOTES : COMPANIES_QUOTES;
};

export const COMPANIES_QUOTES = [
  {
    id: 861,
    firstName: 'Daisy',
    lastName: 'West',
    companyName: 'AWS',
    jobTitle: 'Technical Recruiter (FTC)',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/79679916d0cfbc65db6cdea0ee109215/1074015681-1670110076745.jpeg`,
    testimonial:
      '"Cord is a great platform that connects you with candidates that are actively looking for new opportunities.\n\nThe search filters are fantastic, ensuring I am targeting the right candidate pool.\n\nI would recommend Cord to any TA professional."',
    videoTestimonialURL: null,
    companyID: 5965,
    createDate: '2023-01-22T21:19:01.801Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-aws.svg`
  },
  {
    id: 722,
    firstName: 'Max ',
    lastName: 'Peel',
    companyName: 'Just Eat Takeaway.com',
    jobTitle: 'Talent Acquisition Associate',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/2aad9fcc17fdf5c146dcb019df04bef4/456191838-max-just-eat.jpeg`,
    testimonial:
      '"cord is a great way to engage with candidates! People on cord are responsive and actively looking for new roles. We also have more of an opportunity to build our Employer brand too."',
    videoTestimonialURL: null,
    companyID: 4165,
    createDate: '2021-09-15T07:39:11.773Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-just-eat.svg`
  },
  {
    id: 714,
    firstName: 'Nirmala',
    lastName: 'Cherukat Amiyurkalam',
    companyName: 'Sky',
    jobTitle: 'Sourcing Business Partner',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/86d21694139833d66a6e08d8a67f0357/1590078686-nirmala-sky.jpg`,
    testimonial:
      '"On cord I’m able to get profiles quickly without having to source, this saves my time which can be utilised effectively elsewhere, I like how quick it is as well as the quality of the candidates"',
    videoTestimonialURL: null,
    companyID: 4403,
    createDate: '2021-08-13T11:24:09.660Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-sky.svg`
  },
  {
    id: 712,
    firstName: 'George',
    lastName: 'Willoughby',
    companyName: 'Checkout.com',
    jobTitle: 'People Acquisition Associate',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/aaa247a476a94b381da62f4bf7ccd61a/-1874504487-george-checkout.jpeg`,
    testimonial:
      '"I love the diverse pool of candidates, it’s really enabled me to source for Data Science and Machine Learning hires. \n\nHiring more diverse candidates from different backgrounds has also been a great benefit - Thank you cord!"',
    videoTestimonialURL: null,
    companyID: 2471,
    createDate: '2021-07-20T15:20:08.759Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-checkout.svg`
  },
  {
    id: 632,
    firstName: 'Stewart',
    lastName: 'Scott',
    companyName: 'Virgin Red',
    jobTitle: 'Recruiter',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/b60097eb1c6c07121b405c5241d1657e/1343612020-stewart.webp`,
    testimonial:
      '"cord helps us find Engineers much more quickly than LinkedIn. My time is now spent engaging in quality conversations, rather than hours of sourcing. If you are a business looking for Tech candidates cord is amazing, simple to use, great support, great functionality."',
    videoTestimonialURL: null,
    companyID: 3344,
    createDate: '2021-03-03T09:31:20.148Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-virgin-red.svg`
  },
  {
    id: 862,
    firstName: 'Tom',
    lastName: 'Middleton',
    companyName: 'Lyst',
    jobTitle: 'Talent Acquisition Partner',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/ed46555440ae6c873c94cbd9e27a5460/432799146-tom-middleston.jpeg`,
    testimonial:
      '"An invaluable source for tech hiring.\ncord has been a particularly useful tool for us during periods of growth within our engineering chapter, and has contributed to several critical hires for us as a business.\nWe have seen greater hiring success with cord than when using other similar platforms."',
    videoTestimonialURL: null,
    companyID: 454,
    createDate: '2023-01-22T21:31:13.490Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-lyst.svg`
  },
  {
    id: 778,
    firstName: 'Tom',
    lastName: 'Miller',
    companyName: 'Spill',
    jobTitle: 'Talent & People Lead',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/f9c0af6b8352c820552e846b593ad737/-1418241377-Tom-miller.jpeg`,
    testimonial:
      '"cord has a huge and diverse candidate pool as well as amazing data insights!\n\nWe have found hiring time has been cut down since using cord. We have also realised the data insights have been incredibly helpful when adusting salaries and being closer to market rate!"',
    videoTestimonialURL: null,
    companyID: 3466,
    createDate: '2022-05-19T13:59:02.978Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-spill.svg`
  },
  {
    id: 845,
    firstName: 'Verona',
    lastName: 'Hasani',
    companyName: 'Moneybox',
    jobTitle: 'Talent Sourcer',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/720a1a8dc9198237ed80ce42ca02b445/206558466-verona.jpeg`,
    testimonial:
      '"cord has been great for finding relevant and quality talent! It is so user-friendly which makes hiring for hard-to-fill roles easier and stress-free. The ability to edit streams and filters ensures that you are constantly being introduced to suitable and new candidates which makes the hiring process much more efficient."',
    videoTestimonialURL: null,
    companyID: 6069,
    createDate: '2022-12-15T20:01:34.407Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-moneybox.svg`
  },
  {
    id: 781,
    firstName: 'Rose',
    lastName: 'Wardle',
    companyName: 'Trussle',
    jobTitle: 'Talent Partner',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/f538cdc640af75d9eb3b70ec9ffd28e9/-1319762827-1633953570087.jpeg`,
    testimonial:
      "cord has been so excellent for Product & Tech hires. Older platforms such as LinkedIn have such a low response rate/lack of features - so we decided to pursue cord a few months ago and haven't looked back! It makes my job so much easier, so grateful!\n\n\n",
    videoTestimonialURL: null,
    companyID: 10375,
    createDate: '2022-05-30T13:41:12.981Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-trussle.svg`
  },
  {
    id: 737,
    firstName: 'Craig',
    lastName: 'Law',
    companyName: 'TrueLayer',
    jobTitle: 'Talent Lead',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/3765201891ab31756e194bffb4ad0d09/-730185979-craig-truelayer.jpeg`,
    testimonial:
      '"cord allows us to find high-quality engineers in a much more efficient way. Candidates on the platform are actively searching for roles and the engagement rate is high. \n\nIt’s efficient - I don’t need to spend all day searching, and a big plus is potential candidates can also reach out to us as well."',
    videoTestimonialURL: null,
    companyID: 982,
    createDate: '2021-10-12T10:48:54.162Z',
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-truelayer.svg`
  }
];

export const AGENCIES_QUOTES = [
  {
    id: 1,
    firstName: 'Adam',
    lastName: 'Scott',
    companyName: 'Oliver Bernard',
    jobTitle: 'Associate Director',
    photoURL: AdamOliverBernard,
    testimonial:
      '"We found candidates for our roles that we couldn\'t find from other sources! cord is easy to use and very quick; I can search through shortlists and respond to applications in seconds. The AI features are also much better than other tools."',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: OliverBernard
  },
  {
    id: 2,
    firstName: 'Ryan',
    lastName: 'Horne',
    companyName: 'Searchability',
    jobTitle: 'Recruitment Consultant',
    photoURL: RyanSearchability,
    testimonial:
      '"cord has made my life as a recruiter so much easier! The pool of candidates seems to be everlasting, my response rate is much higher on cord than other platforms and the interview scheduler means booking calls is effortless!"',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: Searchability
  },
  {
    id: 3,
    firstName: 'Iqbal',
    lastName: 'Miah',
    companyName: 'Explore Group',
    jobTitle: 'Team Lead',
    photoURL: IqbalExploreGroup,
    testimonial:
      '"I had responses from quality candidates which you wouldn\'t find on standard job boards! The quality of candidates on cord combined with the detailed information on candidate profiles, including salary expectations, makes cord stand out"',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: ExploreGroup
  },
  {
    id: 4,
    firstName: 'George',
    lastName: 'Booth',
    companyName: 'SR2',
    jobTitle: 'Head of IT',
    photoURL: GeorgeSR2,
    testimonial:
      '"cord helps us find hidden gems! We\'ve found great candidates that aren\'t available elsewhere. I love how user-friendly it is. Everything from filtering, to AI messaging, to the interview scheduler that allows candidates to book straight into my diary. No time wasted!"',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: SR2
  },
  {
    id: 5,
    firstName: 'Matthew',
    lastName: 'Jackson',
    companyName: 'Edge 1',
    jobTitle: 'Director',
    photoURL: MatthewEdge1,
    testimonial:
      '"Within our first month we had an offer accepted from a candidate found on cord who wasn\'t on LinkedIn! The response rates are very high, and the detail on profiles makes finding candidates much better than alternative products"',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: Edge1
  },
  {
    id: 6,
    firstName: 'Jack',
    lastName: 'Emmingham',
    companyName: 'Emtec',
    jobTitle: 'Director',
    photoURL: JackEmtec,
    testimonial:
      '"The quality of candidates on cord is much higher than other job boards. The UI and simplicity of cord is refreshing, compared with a lot of legacy product otherwise available. It\'s been great to use a modern tool with automations built-in"',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: Emtec
  },
  {
    id: 7,
    firstName: 'Marcus',
    lastName: 'Tansey',
    companyName: 'Formula Recruitment',
    jobTitle: 'Senior Tech Consultant',
    photoURL: MarcusFormula,
    testimonial:
      '"The response rates on cord are way above other platforms like LinkedIn. I was able to reach a larger number of quality engineers and massively increase my response rate."',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: Formula
  }
];

export const US_COMPANIES_QUOTES = [
  {
    id: 861,
    firstName: 'Daisy',
    lastName: 'West',
    companyName: 'AWS',
    jobTitle: 'Technical Recruiter (FTC)',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/79679916d0cfbc65db6cdea0ee109215/1074015681-1670110076745.jpeg`,
    testimonial:
      '"Cord is a great platform that connects you with candidates that are actively looking for new opportunities.\n\nThe search filters are fantastic, ensuring I am targeting the right candidate pool.\n\nI would recommend Cord to any TA professional."',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-aws.svg`
  },
  {
    id: 722,
    firstName: 'Max ',
    lastName: 'Peel',
    companyName: 'Just Eat Takeaway.com',
    jobTitle: 'Talent Acquisition Associate',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/2aad9fcc17fdf5c146dcb019df04bef4/456191838-max-just-eat.jpeg`,
    testimonial:
      '"cord is a great way to engage with candidates! People on cord are responsive and actively looking for new roles. We also have more of an opportunity to build our Employer brand too."',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-just-eat.svg`
  },
  {
    id: 823,
    firstName: 'Lilly',
    lastName: 'Jenkins',
    companyName: 'FanDuel',
    jobTitle: ' Talent Acquisition Recruiter',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/6c3dea00e16f550b0ed10a0df5ec2dea/-205888097-thumb_square_4cdc4679e22110a6150358cfe69d2c8a.jpeg`,
    testimonial:
      '"Engineer candidates rarely respond to reach-outs on Linkedin - but I have not faced this issue, as much, since I have been using cord.\nThe fact that it shows their right to work eligibility, their salary expectations, their willingness to work on-site or hybrid, and the fact that they actually respond to our messages!"',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: FanDuel
  },
  {
    id: 817,
    firstName: 'Oleh',
    lastName: 'Sokyrka',
    companyName: 'Medidata Solutions',
    jobTitle: 'Talent Acquisition Specialist',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/ed2d9784f9ff84e05247b53253cc18ec/-35339630-1656005606021.jpeg`,
    testimonial:
      '"Cord is focusing on the tech side of recruitment as well as salary expectation transparency which can save a lot of time for both the recruiter and the candidate. The UI design is intuitive and easy to use, candidates come to you, and cord is cost effective."',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: MediData
  },
  {
    id: 632,
    firstName: 'Stewart',
    lastName: 'Scott',
    companyName: 'Virgin Red',
    jobTitle: 'Recruiter',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/b60097eb1c6c07121b405c5241d1657e/1343612020-stewart.webp`,
    testimonial:
      '"cord helps us find Engineers much more quickly than LinkedIn. My time is now spent engaging in quality conversations, rather than hours of sourcing. If you are a business looking for Tech candidates cord is amazing, simple to use, great support, great functionality."',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: `${COMPANY_LOGOS_URL}/blue-virgin-red.svg`
  },
  {
    id: 772,
    firstName: 'Sara',
    lastName: 'Cornwall',
    companyName: 'YouNow',
    jobTitle: 'Recruitment Manager',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/609c220d41e14ccf92f19dcc60486c3c/-950599978-sara-cornwall.jpeg`,
    testimonial:
      '"cord saves me time sourcing, and the response rate has been pretty good. Also, I’ve had Software Engineers reach out to me - cord’s active candidates are genuinely looking to connect with companies. \n\nFeature-wise, I like receiving notifications via Slack that a new candidate has entered one of my streams."',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: YouNow
  },
  {
    id: 777,
    firstName: 'Phil',
    lastName: 'Reynolds',
    companyName: "Moody's Analytics ",
    jobTitle: 'Talent Acquisition Manager',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/0c607d49bbf4c2aff8c613f1402484fc/-1705132516-phil-reynolds.jpeg`,
    testimonial: `"cord is a very simple and easy-to-use direct messaging platform that gives you access to great candidates who are actively considering new opportunities.\n\nI used to always uses standard job boards when I worked in agency recruitment but now I haven't need to use them in a while!"`,
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: Moodys
  },
  {
    id: 794,
    firstName: 'Finy',
    lastName: 'Jochems',
    companyName: 'Mars',
    jobTitle: 'Senior Talent Acquisition Partner',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/83cfb48ed6bf316f66efe432a098335b/2068571103--1011352929-finy.jpg`,
    testimonial: `"cord is a great platform where Data and Analytics people are hanging out actively looking for new roles, which makes the recruitment process easy and quick. It's super easy to use and I would highly recommend to anyone. We have just made our cord hire and looking forward to more! "`,
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: Mars
  },
  {
    id: 795,
    firstName: 'Mitchell',
    lastName: 'Fein',
    companyName: 'Consumer Priority Service',
    jobTitle: 'Director of Talent',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/4e05f69ae6b86bc5599e2abea9b0f90b/760557639-MF.jpeg`,
    testimonial:
      '"cord makes it easy to communicate with candidates in realtime through the platform itself. Easy to use format and easy to connect to candidates - cord allows you to easily search for candidates and see resumes and contact info."',
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: CPS
  },
  {
    id: 812,
    firstName: 'Ken',
    lastName: 'Abe',
    companyName: 'Applecart',
    jobTitle: 'VP of Engineering',
    photoURL: `${S3_IMAGE_BUCKET_URL}/guest/1353073ee262b2fcb6a940617083b936/720223021-1637792765491.jpeg`,
    testimonial: `"Finding talent in 2022 is very challenging and on the surface your choices are either spending lots of time recruiting or lots of money paying someone to do it for you. And then there's cord, which is affordable, time-efficient and easy to use."`,
    videoTestimonialURL: null,
    companyID: undefined,
    createDate: undefined,
    companyLogoURL: AppleCart
  }
];
