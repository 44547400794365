import React, { useEffect, useState } from 'react';
import ClassNames from 'classnames';
import { useLocation } from 'react-router';
import { Location } from 'history';

import { SearchResponseCompany } from '@cohiretech/common-types';

import { ImageDimensionsType, ImageResizeParam } from 'types';
import { addDimensionsOnImages, getImageDimensions } from 'v2/services/image';
import { adminVerifyUser } from 'fetcher';
import { useDarkMode } from 'hooks/useDarkMode';
import { removeObjectItem } from 'utils/array';
import { isEmpty } from 'utils';

import EmptyBox, { EmptyBoxProps } from 'components/emptybox';
import CompanyItem from 'components/companyitem';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

type Props = {
  columns: 1 | 2 | 3 | 4;
  items?: SearchResponseCompany[];
  empty?: EmptyBoxProps;
  isVerifyPage?: boolean;
  showVideos?: boolean;
  resultsCount?: number;
  showResultsCount?: boolean;
  loading?: boolean;
  loadingNewItems?: boolean;
  location?: Location;
  onCompanyVerified?: () => void;
  maximum?: number;
};

export default function CompanyBlockList({
  columns = 3,
  items = [],
  loading,
  empty,
  resultsCount,
  isVerifyPage,
  showVideos,
  showResultsCount,
  loadingNewItems,
  onCompanyVerified,
  maximum
}: Props) {
  const bannerDimensions = getImageDimensions(`columns-${columns}` as ImageDimensionsType);
  const logoDimensions = getImageDimensions('no-crop-logo-small');

  const location = useLocation();
  const darkMode = useDarkMode();

  const [companyItems, setCompanyItems] = useState(items);
  const imageParams: ImageResizeParam[] = [
    { type: 'columns', dimensions: bannerDimensions },
    { type: 'logo', dimensions: logoDimensions }
  ];

  useEffect(() => {
    const newItems = addDimensionsOnImages(items, imageParams);
    setCompanyItems(newItems);
  }, [items]);

  const verifyCompany = async (companyID: number, verify: boolean) => {
    const { status } = await adminVerifyUser(companyID, 'company', { verify, invisible: !verify });

    if (status === 'success') onCompanyVerified?.();
  };

  const removeItemfromList = (companyID: number) => {
    const itemsCopy = removeObjectItem(companyItems, 'companyID', companyID);

    setCompanyItems(itemsCopy);
  };

  const resultsToRender = maximum ? companyItems.slice(0, maximum) : companyItems;

  return (
    <div className={ClassNames('company_block_list_wrapper', { dark: darkMode })}>
      {isEmpty(companyItems) && !loading ? (
        <EmptyBox
          icon={empty?.icon}
          title={empty?.title}
          description={empty?.description}
          button={empty?.button}
        />
      ) : (
        <div className="company_block_list">
          <ConditionalRender predicate={showResultsCount && resultsCount}>
            <div className="results_info">{resultsCount} results</div>
          </ConditionalRender>
          <ConditionalRender
            predicate={!loading}
            fallback={Array.from({ length: 9 }).map((_, idx) => (
              <CompanyItem
                key={`loading_item_${idx}`}
                loading
                darkMode={darkMode}
                columns={columns}
              />
            ))}
          >
            {resultsToRender.map(({ companyID, ...rest }: SearchResponseCompany, index) => {
              return (
                <CompanyItem
                  key={`company_${companyID}_${index}`}
                  companyID={companyID}
                  locationData={location}
                  darkMode={darkMode}
                  showVideos={showVideos}
                  isVerifyPage={isVerifyPage}
                  columns={columns}
                  verifyCompany={(verify: boolean) => verifyCompany(companyID, verify)}
                  removeItem={() => removeItemfromList(companyID)}
                  {...rest}
                />
              );
            })}
            <ConditionalRender predicate={loadingNewItems}>
              <CompanyItem
                key="loading_item"
                loading={loadingNewItems}
                darkMode={darkMode}
                columns={columns}
              />
            </ConditionalRender>
          </ConditionalRender>
        </div>
      )}
    </div>
  );
}
