import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const InlineLoader = styled.span`
  display: inline-block;
  vertical-align: middle;
  border: 0.25em solid
    ${({ theme: { colours, darkMode, fn } }) =>
      fn.opaqueColour(colours.lightPrimaryColour, darkMode ? 0.5 : 1)};
  border-top: 0.25em solid ${({ theme: { colours } }) => colours.primaryColour};
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: ${spin} 1s linear infinite;
`;

export default InlineLoader;
