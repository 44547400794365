import React from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { mobile } from 'responsiveConfig';
import { getTimeAgo } from 'utils';

import Tooltip from 'components/tooltip';

import colours from 'css/base/_colours.module.scss';

type Props = {
  lastActive: string;
  large?: boolean;
};

export default function ActiveBubble({ lastActive, large }: Props) {
  const shortLastActiveTime = getTimeAgo(lastActive, true, true);
  const longLastActiveTime = getTimeAgo(lastActive, true);
  const showActiveBubble =
    shortLastActiveTime === 'now' ||
    (shortLastActiveTime.includes('m') && shortLastActiveTime.length < 4);

  if (showActiveBubble) {
    return (
      <Bubble
        large={large}
        className={ClassNames('active_bubble', {
          with_text: shortLastActiveTime !== 'now' ? shortLastActiveTime : ''
        })}
      >
        {shortLastActiveTime !== 'now' ? shortLastActiveTime : ''}
        <Tooltip text={`Active ${longLastActiveTime}`} position="right" />
      </Bubble>
    );
  } else return null;
}

type StyleProps = Pick<Props, 'large'>;

const Bubble = styled.span<StyleProps>`
  position: absolute;
  bottom: ${props => (props.large ? '7%' : '3%')};
  right: ${props => (props.large ? '7%' : '3%')};
  padding: ${props => (props.large ? '6%' : '11%')};
  background: ${colours.activeDarkShade};
  color: white;
  border-radius: 50%;
  font-size: ${props => (props.large ? '11px' : '10px')};
  z-index: 2;

  &.with_text {
    padding: ${props => (props.large ? '4px 7px' : '2px 5px')};
    border-radius: 25px;
    bottom: ${props => (props.large ? '7%' : '1%')};
    right: ${props => (props.large ? '7%' : '1%')};
  }

  @media only screen and (max-width: ${mobile}) {
    font-size: ${props => (props.large ? '11px' : '8px')};
    padding: ${props => (props.large ? '6%' : '13%')};

    &.with_text {
      padding: ${props => (props.large ? '4px 7px' : '2px 5px')};
      bottom: ${props => (props.large ? '7%' : '0')};
      right: ${props => (props.large ? '7%' : '0')};
    }
  }

  &:hover > .tooltip_wrapper {
    display: block;
    opacity: 1;
    margin-left: 6px;
  }
`;
