/**
 * Creates a debounced function that delays invoking `func` until after `delay` number of milliseconds
 * have passed since the last time the debounced function was invoked.
 *
 * @template F - The type of the function.
 * @param {F} func - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay.
 * @returns A new debounced function
 *
 * @example
 * // Avoid costly calculations while the window size is in flux.
 * window.onresize = debounce(calculateLayout, 200);
 */
export function debounce<F extends (...args: any[]) => any>(
  func: F,
  delay: number
): (...args: Parameters<F>) => ReturnType<F> | void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<F>): ReturnType<F> | void => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}
