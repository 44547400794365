import { Currency, ListingCleanInterface } from '@cohiretech/common-types';

import { ProfileState, isCandidateProfile } from 'store';
import { formatNumber, formatSalary, getCurrencySymbol } from 'utils';

export const getVisibleSalaryRange = ({
  currency = Currency.GBP,
  salaryMin = 0,
  salaryMax = 1,
  oteSalaryMaxUncapped,
  format = 'long'
}: Pick<ListingCleanInterface, 'salaryMin' | 'salaryMax' | 'currency' | 'oteSalaryMaxUncapped'> & {
  format?: 'short' | 'long';
}) => {
  const currencySymbol = getCurrencySymbol(currency);
  const formattedSalary = [salaryMin, salaryMax].map((salary, idx) => {
    if (idx && oteSalaryMaxUncapped) return 'Uncapped';

    return format === 'long'
      ? formatSalaryWithCommas(salary, currency)
      : formatSalary(salary, currencySymbol);
  });

  return formattedSalary.join(' - ');
};

export const formatSalaryWithCommas = (salary: number, currency: Currency) =>
  `${getCurrencySymbol(currency)}${formatNumber(salary, true)}`;

export const getCurrencySymbolFromProfile = (profile: ProfileState) => {
  const currency = (isCandidateProfile(profile) && profile.currency) || '£';
  return getCurrencySymbol(currency);
};
