import { createAsyncThunk } from '@reduxjs/toolkit';

import { getShortlistData } from 'fetcher';
import {
  AppDispatch,
  RootState,
  selectCompanyProfile,
  setCompanyShortlists,
  setToggler,
  useAppDispatch
} from 'store';
import { CompanyUser } from 'types';

export const reloadCompanyShortlists = createAsyncThunk<
  { shortlists: CompanyUser | null },
  void,
  { dispatch: AppDispatch; state: RootState }
>('services/company/reloadShortlists', async (initialLoad, { dispatch, getState }) => {
  dispatch(setToggler({ route: 'loaders.reloadCompanyShortlists', value: true }));
  const state = getState();
  const profile = selectCompanyProfile(state);

  if (!profile) return { shortlists: null };

  const shortlists = await getShortlistData();
  dispatch(setCompanyShortlists(shortlists));

  dispatch(setToggler({ route: 'loaders.reloadCompanyShortlists', value: false }));
  return { shortlists };
});

export const useCompanyShortlistsData = () => {
  const dispatch = useAppDispatch();
  return async () => {
    await dispatch(reloadCompanyShortlists());
  };
};
