import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { setLocalStorageItem } from 'cookieManager';
import { randomInt } from 'utils/math';

import InsightsLoaderSVG from 'images/animations/InsightsLoader';

import { usePositionProfile } from './PositionProfile.context';

const TEXT_INTERVAL = 2000;
const ANIMATION_INTERVAL = 0.5;
const GLOBAL_FIRST_LOAD_INTERVAL = 8000;
const FIRST_LOAD_INTERVAL = { MIN: 4000, MAX: 4500 };
const PREV_LOADED_INTERVAL = { MIN: 2000, MAX: 3000 };

const INSIGHTS_LOADER_TEXT = [
  'Calculating your profile match score...',
  'Comparing your score with other applicants...',
  'Analysing the skills of people accepted...',
  'Comparing offered salary with industry average...'
];

const InsightsLoader = ({ firstLoad }: { firstLoad: boolean }) => {
  const { loadingInfo, setLoadingDetails } = usePositionProfile();

  useEffect(() => {
    if (!loadingInfo.isLoading) return;

    const interval = getInterval(firstLoad, loadingInfo.hasLoadedOnce);

    setTimeout(() => {
      setLoadingDetails({
        hasLoadedOnce: true,
        isLoading: false
      });

      if (firstLoad) {
        setLocalStorageItem(btoa('globalFirstLoadDone'), true);
      }
    }, interval);
  }, []);

  return (
    <InsightsLoaderWrapper>
      <InsightsLoaderSVG />
      <InsightsLoadingText messages={INSIGHTS_LOADER_TEXT} />
      <span>Advanced position insights to increase your chances of success.</span>
    </InsightsLoaderWrapper>
  );
};

const getInterval = (globalFirstLoad: boolean, hasLoadedOnce: boolean) => {
  if (globalFirstLoad) return GLOBAL_FIRST_LOAD_INTERVAL;
  if (hasLoadedOnce) return randomInt([PREV_LOADED_INTERVAL.MIN, PREV_LOADED_INTERVAL.MAX]);
  return randomInt([FIRST_LOAD_INTERVAL.MIN, FIRST_LOAD_INTERVAL.MAX]);
};

const InsightsLoaderWrapper = styled.div`
  padding: 75px 0 100px;
`;

const slideIn = keyframes`
  0% { transform: translate(-50%, 100%); }
  100% { transform: translate(-50%, 0); }
`;

const slideOut = keyframes`
  0% { transform: translate(-50%, 0); }
  100% { transform: translate(-50%, -100%); }
`;

const TextSlideIn = styled.div<{ initial: boolean }>`
  width: 100%;
  ${({ initial }) =>
    !initial &&
    css`
      animation: ${slideIn} ${ANIMATION_INTERVAL}s forwards;
    `}
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const TextSlideOut = styled.div<{ initial: boolean }>`
  width: 100%;
  ${({ initial }) =>
    !initial
      ? css`
          animation: ${slideIn} ${ANIMATION_INTERVAL}s forwards;
        `
      : css`
          visibility: hidden;
        `}
  animation: ${slideOut} ${ANIMATION_INTERVAL}s forwards;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const InsightsLoadingTextWrapper = styled.div`
  ${({ theme: { typography } }) => `
    font-size: ${typography.smallheader};
    font-weight: ${typography.bold};
    height: 1.5rem;
    margin: 1rem 0 0.25rem;
    width: 100%;
    position: relative;
    overflow: hidden;
  `}
`;

const InsightsLoadingText = ({ messages }: { messages: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(randomInt(messages.length));
  const next = (currentIndex + 1) % messages.length;
  const hasRendered = useRef(false);

  useEffect(() => {
    const id = setTimeout(() => {
      hasRendered.current = true;
      setCurrentIndex(next);
    }, TEXT_INTERVAL);

    return () => clearTimeout(id);
  }, [currentIndex, messages]);

  return (
    <InsightsLoadingTextWrapper>
      <TextSlideOut key={messages[currentIndex]} initial={!hasRendered.current}>
        {messages[currentIndex]}
      </TextSlideOut>
      <TextSlideIn key={messages[next]} initial={!hasRendered.current}>
        {messages[next]}
      </TextSlideIn>
    </InsightsLoadingTextWrapper>
  );
};

export default InsightsLoader;
