/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'mome... Remove this comment to see the full error message
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { History, Location } from 'history';
import { ComponentType } from 'react';
import { validateEmailAddress } from 'config';
import setI18nLanguageByCountry from 'i18n/i18nUtils';
import { INDUSTRY_OPTIONS, COUNTRIES } from 'consts/common';

import structuredClone from '@ungap/structured-clone';
import {
  OperatingCountry,
  REPORT_REASON_OPTIONS,
  EmailValidationContext,
  LocationInterface,
  GooglePlaceAddressComponentType,
  Currency,
  CompanyCategory,
  JobTitleOrderingValues,
  RemoteOption,
  ApplicationCandidateStartStatus as ACSS,
  Seniority,
  ApplicationStatus
} from '@cohiretech/common-types';

import {
  AnyFunction,
  CandidateProfile,
  CompanyProfile,
  DateArgument,
  ExtendedApplicationDetails,
  GooglePlaceDetails,
  KeywordOptions,
  LocationLabel,
  TimeIntervalPlural
} from 'types';
import * as fetcher from 'fetcher';
import { getSkillOptions } from 'v2/services/fetchers/public/skillOptions';
import { getJobTitleOptions } from 'v2/services/fetchers/public/jobTitles';
import * as consts from 'consts';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import {
  isLaptopWidth,
  isSmallDesktopWidth,
  isMobileWidth,
  isTabletLandscapeWidth
} from 'responsiveConfig';
import { getWorkEligibilityMatch } from 'v2/services/common/matchingCriteria';
import { unique } from 'utils/array';
import { capitalizeWords, capitalizeSentences } from 'utils/string';
import { hasDatePassed, isToday } from 'utils/time';
import { trackWebinarLinkClicks } from 'tracking-utils';
import { isEmpty, is, isNumber, isNil } from 'utils/fn';
import { getVisibleSalaryRange } from 'v2/services/tools/salary';
import { getRemoteFilterOptions } from 'v2/services/tools/positionSearchFilter';

import { isInsightsType } from 'views/landing/insights/helpers';

// @ts-expect-error TS(2307) FIXME: Cannot find module 'images/notification-mark.ico' ... Remove this comment to see the full error message
import Mark from 'images/mark.ico';
// @ts-expect-error TS(2307) FIXME: Cannot find module 'images/mark.ico' or its corres... Remove this comment to see the full error message
import NotificationMark from 'images/notification-mark.ico';

// Moved to utils/fn - but imports throughout the codebase need to be updated
export { isEmpty };

export const allSpacesRegex = /\s+/g;
export const allForwardSlashesRegex = /\//g;
export const emailFormat = /^\w+([.\-+]?\w)*@\w+([.-]?\w)*(\.\w{2,})+$/;
export const phoneFormat = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
export const emailRegex = /\w+([.\-+]?\w)*@\w+([.-]?\w)*(\.\w{2,})+/gm;
export const parsedEmailRegex = /[\w+||]{2,}[.\-+]?\w+@\w+[.-]?[\w+||]{2,}/gm;
export const urlRegex =
  /[https?://www.?a-zA-Z0-9-@:%._+~#=]{2,256}\.(?!js|md|json|ts|tsx)[a-z]{2,6}\b([-a-zA-Z0-9@:%_+~#?&//=^]*)/gm;
export const positionURLRegex =
  /\/u\/([a-z]|[0-9]|\W|[%2F])+\/jobs\/[0-9]+-([a-z]|[0-9]|\W|[%2F])[^/]+/;
export const companyURLRegex = /\/u\/[0-9]+-([a-z]|[0-9]|\W|[%2F])[^/]+/;
export const fullPersonURLRegex =
  /\/u\/([a-z]|[0-9]|\W|[%2F])+\/people\/[0-9]+-([a-z]|[0-9]|\W|-)[^/]+/;
export const personURLRegex = /\/u\/([a-z]|[0-9]|\W|[%2F])+\/people\/[0-9]+/;

export const doubledigit = (number: $TSFixMe) => (number < 10 ? `0${number}` : number);

export const getEarliestTime = (time1 = 0, time2 = 0) =>
  new Date(
    Math.min(
      !time1 ? new Date().getTime() : new Date(time1).getTime(),
      !time2 ? new Date().getTime() : new Date(time2).getTime()
    )
  );

export const isObjectEmpty = (obj: $TSFixMe) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * @deprecated Use utils/fn/isObject instead
 */
export const isObject_Deprecated = (i: $TSFixMe) => {
  return !!i && i.constructor === Object;
};

/**
 * @deprecated Use utils/time/getTimeDifference.ts instead
 */
export const getTimeDifference = (dateString: $TSFixMe, fromDate?: $TSFixMe) => {
  const from = fromDate ? new Date(fromDate).getTime() : new Date().getTime();
  const date = dateString === 'now' ? new Date().getTime() : new Date(dateString).getTime();

  return from - date;
};

export const getMinsHrsDaysWeeksMonthsfromTime = (time: $TSFixMe) => {
  const minutes = Math.floor(time / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);

  return { minutes, hours, days, weeks, months };
};

export const monthFromNowinTime = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);

  return date.getTime() - new Date().getTime();
};

export const getDaysSince = (date: $TSFixMe) => {
  const timeDifference = getTimeDifference(date);
  const { days } = getMinsHrsDaysWeeksMonthsfromTime(timeDifference);
  return days;
};

export const isDateWithinDays = (days: number, date: string) => {
  const daysAgo = getDaysSince(date);

  return daysAgo <= days;
};

export const getTimeAgo = (dateString: $TSFixMe, lastActive = false, short = false) => {
  const timeDifference = getTimeDifference(dateString);
  const { minutes, hours, days, weeks, months } = getMinsHrsDaysWeeksMonthsfromTime(timeDifference);

  if (months > 6 || dateString === 'N/A') {
    return 'over 6 months ago';
  } else if (months > 1) {
    return `${months} months ago`;
  } else if (months === 1) {
    return 'a month ago';
  } else if (weeks >= 1) {
    return `${short ? `${weeks}w` : `${weeks === 1 ? 'a week ago' : `${weeks} weeks ago`}`}`;
  } else if (days >= 1) {
    return `${short ? `${days}d` : `${days === 1 ? '2 days ago' : `${days} days ago`}`}`;
  } else if (hours >= 1) {
    return `${short ? `${hours}h` : `${hours === 1 ? '1 hour ago' : `${hours} hours ago`}`}`;
  } else if (lastActive === true) {
    return `${minutes <= 3 ? 'now' : short ? `${minutes}m` : `${minutes} minutes ago`}`;
  } else {
    return `${minutes <= 1 ? 'just now' : short ? `${minutes}m` : `${minutes} minutes ago`}`;
  }
};

export const getShortLastActiveText = (dateString: $TSFixMe) => {
  const timeDifference = getTimeDifference(dateString);
  const { minutes, hours, days, weeks, months } = getMinsHrsDaysWeeksMonthsfromTime(timeDifference);

  if (months > 1 || dateString === 'N/A') {
    return 'over 1m ago';
  } else if (months === 1) {
    return '1mon ago';
  } else if (weeks >= 1) {
    return `${weeks}w ago`;
  } else if (days >= 1) {
    return days === 1 ? 'yesterday' : `${days}d ago`;
  } else if (hours >= 1) {
    return `${hours}h ago`;
  } else {
    return minutes <= 3 ? 'now' : `${minutes}m ago`;
  }
};

export const getDaysPassedFromDate = (dateString: $TSFixMe, fromDate = new Date()) => {
  const timeDifference = getTimeDifference(dateString, fromDate);
  const { days } = getMinsHrsDaysWeeksMonthsfromTime(timeDifference);

  if (days > 1) return `${days} days ago`;
  else if (days === 1) return 'yesterday';
  else return 'Today';
};

/* istanbul ignore next */
export const getLastActiveTime = (dateString: $TSFixMe) => {
  const timeDifference = getTimeDifference(dateString);
  const { minutes, hours, days, weeks, months } = getMinsHrsDaysWeeksMonthsfromTime(timeDifference);

  if (months > 1) {
    return 'over a month ago';
  } else if (months === 1) {
    return 'a month ago';
  } else if (weeks >= 1) {
    return `${weeks === 1 ? 'a week ago' : `${weeks} weeks ago`}`;
  } else if (days >= 1) {
    return `${days === 1 ? 'yesterday' : `${days} days ago`}`;
  } else if (hours >= 1) {
    return 'today';
  } else {
    return `${minutes <= 3 ? 'now' : 'just now'}`;
  }
};

/* istanbul ignore next */
export const getTimeLeftToDate = (date: $TSFixMe) => {
  const timeDifference = getTimeDifference(new Date(), new Date(date));
  const { minutes, hours, days, weeks, months } = getMinsHrsDaysWeeksMonthsfromTime(timeDifference);

  if (months > 1) {
    return 'in over a month';
  } else if (months === 1) {
    return 'in a month';
  } else if (weeks >= 1) {
    return `${weeks === 1 ? 'in a week' : `in ${weeks} weeks`}`;
  } else if (days >= 1) {
    return `${days === 1 ? 'in a day' : `in ${days} days`}`;
  } else if (hours >= 1) {
    return `${hours === 1 ? 'in an hour' : `in ${hours} hours`}`;
  } else {
    return `${minutes <= 1 ? 'now' : `in ${minutes} minutes`}`;
  }
};

/* istanbul ignore next */
export const getShortDateFormat = (date: $TSFixMe) => {
  const pastDate = hasDatePassed(date);
  const timeDifference = pastDate
    ? getTimeDifference(date)
    : getTimeDifference(new Date(), new Date(date));
  const { days } = getMinsHrsDaysWeeksMonthsfromTime(timeDifference);

  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  if (days === 0) return 'today';
  else if (days === 1) return `${pastDate ? 'yesterday' : 'tomorrow'}`;
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  else return new Date(date).toLocaleDateString('en-GB', dateOptions);
};

export const getShortResponseTime = (responseTime: $TSFixMe) => {
  if (responseTime && responseTime !== 'N/A') {
    const days = responseTime.days || 0;
    const hours = responseTime.hours || 0;
    const minutes = responseTime.minutes || 0;
    const seconds = responseTime.seconds || 0;

    if (days >= 1) {
      return `${days}d ${hours}h`;
    } else if (hours >= 1) {
      return `${hours}h ${minutes}m`;
    } else if (minutes >= 1) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  }

  return 'N/A';
};

export const getDuration = (timeInterval: $TSFixMe) => {
  if (timeInterval && timeInterval !== 'N/A') {
    let days = timeInterval.days || 0;
    const hours = timeInterval.hours || 0;
    let minutes = timeInterval.minutes || 0;
    const seconds = timeInterval.seconds || 0;

    days += hours > 12 ? 1 : 0;
    minutes += seconds > 30 ? 1 : 0;

    if (days > 7) {
      return 'over a week';
    } else if (days >= 1) {
      return `${days === 1 ? 'a day' : `${days} days`}`;
    } else if (hours >= 1) {
      return `${hours}h ${minutes > 1 ? `${minutes}m` : ''}`;
    } else if (minutes >= 1) {
      return `${minutes === 1 ? 'a minute' : `${minutes} minutes`}`;
    } else {
      return 'under a minute';
    }
  }

  return 'N/A';
};

export const getResponseTimeColour = (responseTime: $TSFixMe) => {
  let days = responseTime.days || 0;
  const hours = responseTime.hours || 0;

  days += hours > 12 ? 1 : 0;

  if (responseTime === 'N/A') return 'grey';
  else if (days > 5) return 'red';
  else if (days > 3) return 'orange';
  else return 'blue';
};

export const getPendingRequests = (pendingRequests: number, conversations: number): string => {
  if (pendingRequests === 0) {
    if (conversations > 0) return '0 pending';
    return 'None yet. Be the first one!';
  }
  if (pendingRequests <= 10) return `${pendingRequests} pending`;
  if (pendingRequests > 10) return 'More than 10';
  return '0 pending';
};

export const getResponseTime = (timeInterval: $TSFixMe) => {
  if (timeInterval) {
    let days = timeInterval.days || 0;
    const hours = timeInterval.hours || 0;
    let minutes = timeInterval.minutes || 0;
    const seconds = timeInterval.seconds || 0;

    days += hours > 12 ? 1 : 0;
    minutes += seconds > 30 ? 1 : 0;

    if (days > 7) {
      return 'within weeks';
    } else if (days >= 1) {
      return `${days === 1 ? 'in a day' : 'within days'}`;
    } else if (hours >= 1) {
      return `${hours === 1 ? 'in an hour' : 'within hours'}`;
    } else if (minutes >= 1) {
      return `${minutes === 1 ? 'in a minute' : 'within minutes'}`;
    } else {
      return 'within seconds';
    }
  }
};

export const getWorkDuration = (
  startMonth: $TSFixMe,
  startYear: $TSFixMe,
  endMonth: $TSFixMe,
  endYear: $TSFixMe,
  current: $TSFixMe
) => {
  let months = 0;
  let years = 0;
  let yearsDiff;
  let monthDiff;

  if (current) {
    const now = new Date();
    monthDiff = now.getMonth() - startMonth + 1;
    yearsDiff = now.getFullYear() - startYear;
  } else {
    yearsDiff = endYear - startYear;
    monthDiff = endMonth - startMonth + 1; // Oct(10) - Dec(12) 3 months period
  }

  if (monthDiff < 0) {
    const yearsToMonths = yearsDiff * 12;
    const overallDiff = yearsToMonths + monthDiff;
    years = Math.floor(overallDiff / 12);
    months = overallDiff % 12;
  } else {
    months = monthDiff;
    years = yearsDiff;
  }

  const yearsDisplayed = `${years} year${years > 1 ? 's' : ''}`;
  const monthsDisplayed = `${months} month${months > 1 ? 's' : ''}`;

  if (years > 0 && months === 0) return `(${yearsDisplayed})`;
  else if (years === 0 && months > 0) return `(${monthsDisplayed})`;
  else if (years > 0 && months > 0) return `(${yearsDisplayed} ${monthsDisplayed})`;
  else if (years > 0 && !months) return `(${yearsDisplayed})`;
  return '(1 month)';
};

export const getCurrencySymbol = (currency: Currency | string = Currency.GBP) => {
  switch (currency) {
    case Currency.GBP:
      return '£';
    case Currency.USD:
      return '$';
    case Currency.EUR:
      return '€';
    default:
      return currency;
  }
};

export const getCurrencyIcon = (currency = Currency.GBP) => {
  switch (currency) {
    case Currency.GBP:
      return 'icon_pound';
    case Currency.USD:
      return 'icon_dollar';
    case Currency.EUR:
      return 'icon_euro';
    default:
      return 'icon_pound';
  }
};

export const getCurrencyFromLocation = (location = '') => {
  if (
    location?.includes('United States') ||
    location?.includes('US') ||
    location?.includes('USA')
  ) {
    return '$';
  }
  return '£';
};

export const getCurrencyFromPricingCountry = (pricingCountry?: OperatingCountry) => {
  if (pricingCountry === OperatingCountry.US) return '$';
  if (pricingCountry === OperatingCountry.EU) return '€';
  return '£';
};

export const formatNumber = (number = 0, isSalaryOption?: boolean) => {
  if (isSalaryOption && number === fetcher.HIGHEST_SALARY_BAND_VALUE) return '500,000+';

  const intNumber = number !== null ? `${number.toFixed(0)}` : '0';
  const x = intNumber.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;

  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }

  return x1 + x2;
};

export const formatDate = (date: $TSFixMe) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const getPercentage = (float: $TSFixMe = 0, withPercentSign?: boolean) => {
  if (float === 'N/A') return float;
  // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  const percentage = Math.round(parseFloat(float * 100) * 100) / 100;
  return withPercentSign ? `${percentage}%` : percentage;
};

export const getNumericalForm = (number: $TSFixMe) => {
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
  return parseFloat(number / 100, 2);
};

export const hasParentWithClass = (childNode: $TSFixMe, className: $TSFixMe) => {
  let node = childNode;

  while (node !== null) {
    if (` ${node.className} `.indexOf(` ${className} `) !== -1) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
};

/**
 * parses the city based on the address breakdown by Google
 * also has an override function for addresses that are incorrect
 * @param {object} place place object as returned by Google
 */
/* istanbul ignore next */
export const createLabelFromGooglePlace = (place?: GooglePlaceDetails) => {
  const country = place?.addressComponents?.find(
    component => component.types[0] === GooglePlaceAddressComponentType.country
  );

  const preferredOrderOfAddressComponents = getGooglePlaceLabelPreference(
    country?.shortText || undefined
  );

  const allowedComponents = place?.addressComponents?.filter(component =>
    preferredOrderOfAddressComponents.includes(
      component.types[0] as GooglePlaceAddressComponentType
    )
  );

  const sortedAddressComponents = allowedComponents?.sort((curr, prev) => {
    return (
      preferredOrderOfAddressComponents.indexOf(curr.types[0] as GooglePlaceAddressComponentType) -
      preferredOrderOfAddressComponents.indexOf(prev.types[0] as GooglePlaceAddressComponentType)
    );
  });

  const labelComponents = [];
  if (!isEmpty(sortedAddressComponents)) {
    labelComponents.push(sortedAddressComponents![0].longText);
  }
  if (country) labelComponents.push(country.longText);

  return labelComponents.join(', ');
};

const getGooglePlaceLabelPreference = (country: string | undefined) => {
  const { postal_town, locality, admin_level_1, admin_level_2, colloquial_area } =
    GooglePlaceAddressComponentType;

  switch (country) {
    case 'US':
      return [postal_town, locality, admin_level_1, admin_level_2];
    case 'GB':
      return [postal_town, locality, admin_level_2, colloquial_area, admin_level_1];
    default:
      return [postal_town, locality, admin_level_2, admin_level_1];
  }
};

export const createLocationObjectFromGooglePlace = (
  place?: GooglePlaceDetails
): Partial<LocationInterface> => {
  const components = place?.addressComponents;
  const coorindates = getCoordinatesFromGooglePlace(place);
  const locationObject: Partial<LocationInterface> = {};

  components?.forEach(item => {
    const type = item.types[0];

    if (['continent', 'colloquial_area'].includes(type)) {
      locationObject.region = [item.longText || ''];
    } else locationObject[item.types[0]] = item.longText || undefined;
  });

  locationObject.formatted_address = place?.formattedAddress || undefined;
  locationObject.label = createLabelFromGooglePlace(place);
  if (coorindates?.lat) locationObject.lat = coorindates?.lat;
  if (coorindates?.lng) locationObject.lng = coorindates?.lng;

  return locationObject;
};

export const getTimeDifferenceObject = (date1: $TSFixMe, date2: $TSFixMe) => {
  const timeDifference = getTimeDifference(date2, date1);
  const minutes = Math.floor(timeDifference / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);

  if (months > 1) return { time: months, type: `month${months > 1 ? 's' : ''}` };
  else if (weeks > 1) return { time: weeks, type: `week${weeks > 1 ? 's' : ''}` };
  else if (days > 1) return { time: days, type: `day${days > 1 ? 's' : ''}` };
  else if (hours > 1) return { time: hours, type: `hour${hours > 1 ? 's' : ''}` };
  else if (minutes > 0) return { time: minutes, type: `minute${minutes > 1 ? 's' : ''}` };
};

export const getMillisecondsByType = (type: TimeIntervalPlural): number => {
  if (type === 'seconds') return 1000;
  else if (type === 'minutes') return 60 * 1000;
  else if (type === 'hours') return 60 * 60 * 1000;
  else if (type === 'days') return 24 * 60 * 60 * 1000;
  else if (type === 'weeks') return 7 * 24 * 60 * 60 * 1000;
  else if (type === 'months') return 30 * 7 * 24 * 60 * 60 * 1000;
  return 0;
};

export const addTime = (date: string | Date, value: number, type: TimeIntervalPlural) => {
  const dateObject = new Date(date);
  const milliseconds = getMillisecondsByType(type);

  return dateObject.setTime(dateObject.getTime() + value * milliseconds);
};

export const removeTime = (date: string | Date, value: number, type: TimeIntervalPlural) => {
  const dateObject = new Date(date);
  const milliseconds = getMillisecondsByType(type);

  return dateObject.setTime(dateObject.getTime() - value * milliseconds);
};

export const millisToDays = (millis: number) => {
  const days = Math.floor(millis / 1000 / 60 / 60 / 24);
  const hours = Math.floor((millis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // calculate hours from the remainder of days
  const minutes = Math.floor((millis % (1000 * 60 * 60)) / (1000 * 60)); // calculate hours from the remainder of hour
  const seconds = Math.floor((millis % (1000 * 60)) / (1000 * 60)); // calculate hours from the remainder of minutes

  if (days > 0) return `${days}d ${hours}h`;
  else if (hours > 0) return `${hours}h ${minutes}m`;
  else return `${minutes}m ${seconds}s`;
};

export const secondsToDays = (secs: $TSFixMe) => {
  const days = Math.floor(secs / 60 / 60 / 24);
  const hours = Math.floor((secs % (60 * 60 * 24)) / (60 * 60)); // calculate hours from the remainder of days
  const minutes = Math.floor((secs % (60 * 60)) / 60); // calculate minutes from the remainder of hour
  const seconds = Math.floor((secs % 60) / 60); // calculate seconds from the remainder of minutes

  if (days > 0) return `${days}d ${hours}h`;
  else if (hours > 0) return `${hours}h ${minutes}m`;
  else return `${minutes}m ${seconds}s`;
};

/**
 * @param {dateObject} date
 * @returns {string} "10.07.2023"
 */
/* istanbul ignore next */
export const getDateString = (date: $TSFixMe, returnJustNowMessage = true) => {
  const timeDifference = new Date().getTime() - new Date(date).getTime();
  const minutes = Math.floor(timeDifference / 60000);

  if (returnJustNowMessage && minutes < 1) return 'just now';
  else return new Date(date).toLocaleDateString('en-GB').replace(allForwardSlashesRegex, '.');
};

/**
 * @param {dateObject} date
 * @returns {string} "10.07.2023 15:34"
 */
/* istanbul ignore next */
export const getDateTimeString = (date = new Date()) => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return date.toLocaleDateString('en-GB', options).replace(allForwardSlashesRegex, '.');
};

/* istanbul ignore next */
export const getShortDateString = (date: DateArgument) => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'numeric', year: '2-digit' };
  return new Date(date).toLocaleDateString('en-GB', options);
};

/**
 * @param {dateObject} date
 * @returns {string} "Thursday, 10 June 2021, 12:00"
 */
/* istanbul ignore next */
export const getFullDateTimeString = (date: string | Date = new Date()) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  if (date === 'N/A') return 'N/A';
  return new Date(date).toLocaleDateString('en-GB', options);
};

/**
 * @param {dateObject} date
 * @returns {string} "Thursday, 10 June, 12:00 pm BST"
 */
/* istanbul ignore next */
export const getFullDateAndTimezoneString = (date: string | Date = new Date()) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
    hourCycle: 'h12'
  };

  if (date === 'N/A') return 'N/A';
  return new Date(date).toLocaleDateString('en-GB', options);
};

/**
 * @param {dateObject} date
 * @returns {string} "Thursday, 10 June 2021"
 */
/* istanbul ignore next */
export const getFullDateString = (date: Date | string = new Date()) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  } as Intl.DateTimeFormatOptions;

  if (date === 'N/A') return 'N/A';
  return new Date(date).toLocaleDateString('en-GB', options);
};

/* istanbul ignore next */
export const getHoursMinutesSeconds = (date: $TSFixMe) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };
  return new Date(date).toLocaleTimeString('en-GB', options);
};

/* istanbul ignore next */
export const getDayFromDate = (dateString: $TSFixMe) => {
  const dayNumber = new Date(dateString).getDay();

  switch (dayNumber) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

export const getMessageTime = (date: $TSFixMe, short?: boolean) => {
  const timeDifference = getTimeDifference(date);
  const { minutes, days, weeks } = getMinsHrsDaysWeeksMonthsfromTime(timeDifference);
  const dayMonth = new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });

  if (weeks >= 1) {
    return short ? dayMonth : getDateString(date);
  } else if (days > 0 || !isToday(date)) {
    return short ? dayMonth : getDayFromDate(date);
  } else if (minutes < 1) {
    return `${short ? '' : 'just '}now`;
  } else {
    const time = getHoursMinutesSeconds(date);
    const timeArray = time.split(':');

    return `${timeArray[0]}:${timeArray[1]}`;
  }
};

export const isValidGooglePlace = (place?: GooglePlaceDetails) => {
  return place && place.addressComponents;
};

export const getCoordinatesFromGooglePlace = (place: any) => {
  if (place && place.location) {
    return { lat: place.location.latitude, lng: place.location.longitude };
  } else {
    return null;
  }
};

export const getResponsiveness = (responseRate: $TSFixMe) => {
  if (responseRate === 'N/A') return 'N/A';

  const percentage = getPercentage(responseRate);
  let responsiveness = '';

  if (percentage >= 95) responsiveness = 'Very Responsive';
  else if (percentage >= 80) responsiveness = 'Highly Responsive';
  else if (percentage >= 60) responsiveness = 'Fairly Responsive';
  else if (percentage < 60) responsiveness = 'Poor';

  return `${responsiveness} (${percentage}%)`;
};

export const getResponseRate = (responseRate: $TSFixMe) => {
  const percentage = getPercentage(responseRate);

  if (responseRate === 'N/A') return 'N/A';
  return `${percentage}% of requests`;
};

export const getResponsivenessColour = (responseRate: $TSFixMe) => {
  if (responseRate === 'N/A') return 'grey_text';

  const percentage = getPercentage(responseRate);

  if (percentage < 100 && percentage >= 90) return 'orange_text';
  if (percentage < 90) return 'red_text';
  return 'green_text';
};

export const loadImage = (logo: string, fallbackLogo?: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = fallbackLogo ? () => resolve(fallbackLogo) : () => reject();
    img.onload = () => resolve(logo);
    img.src = logo;
  });
};

export const convertSpacesToUnderscoreCase = (string = '') => {
  return string.replace(allSpacesRegex, '_').toLowerCase();
};

export const getDate = (timestamp: $TSFixMe) => {
  //returns date in following format "Aug '15"
  const year = new Date(timestamp).getFullYear().toString();
  const formattedYear = year.substring(2, 4); // 17
  let month = timestamp !== null && timestamp.substring(5, 7);
  let formattedDate = '';

  if (timestamp !== null) {
    switch (month) {
      case '01':
        month = 'Jan';
        break;
      case '02':
        month = 'Feb';
        break;
      case '03':
        month = 'Mar';
        break;
      case '04':
        month = 'Apr';
        break;
      case '05':
        month = 'May';
        break;
      case '06':
        month = 'Jun';
        break;
      case '07':
        month = 'Jul';
        break;
      case '08':
        month = 'Aug';
        break;
      case '09':
        month = 'Sept';
        break;
      case '10':
        month = 'Oct';
        break;
      case '11':
        month = 'Nov';
        break;
      case '12':
        month = 'Dec';
        break;
      default:
        month = timestamp.substring(5, 7);
    }
    formattedDate = `${month} '${formattedYear}`;
    return formattedDate;
  } else {
    return 'Present';
  }
};

/**
 *
 * @param {date} date
 * @param {integer} month
 * @param {integer} year
 */
export const getMonthAndYear = (date: $TSFixMe, month?: $TSFixMe, year?: $TSFixMe) => {
  //returns date in following format "August, 2015"
  const newMonth = date ? new Date(date).getMonth() : month;
  const newYear = date ? new Date(date).getFullYear() : year;

  const parsedMonth = parseInt(newMonth, 10) + 1;
  let stringMonth = '';

  switch (parsedMonth) {
    case 1:
      stringMonth = 'January';
      break;
    case 2:
      stringMonth = 'February';
      break;
    case 3:
      stringMonth = 'March';
      break;
    case 4:
      stringMonth = 'April';
      break;
    case 5:
      stringMonth = 'May';
      break;
    case 6:
      stringMonth = 'June';
      break;
    case 7:
      stringMonth = 'July';
      break;
    case 8:
      stringMonth = 'August';
      break;
    case 9:
      stringMonth = 'September';
      break;
    case 10:
      stringMonth = 'October';
      break;
    case 11:
      stringMonth = 'November';
      break;
    case 12:
      stringMonth = 'December';
      break;
    default:
      stringMonth = 'January';
  }

  return `${stringMonth}, ${newYear}`;
};

/**
 * @deprecated use utils/fn/debounce instead
 */
export const debounce = (fn: $TSFixMe, delay: number) => {
  let timer: NodeJS.Timeout;
  return function (this: $TSFixMe) {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  } as AnyFunction;
};

export const getDeviceType = () => {
  if (isMobileWidth) return 'mobile';
  else if (isTabletLandscapeWidth) return 'tablet-landscape';
  else if (isLaptopWidth) return 'laptop';
  else if (isSmallDesktopWidth) return 'small-desktop';
  else return 'desktop';
};

/**
 * @description Injects any tag into the head or body tags
 * @param {object} tagProperties
 * @param {string} tagProperties.tagName
 * @param {object} tagProperties.attributes
 * @param {string} tagProperties.textNode
 * @param {object} tagProperties.style
 * @param {string} tagProperties.parentNode
 */
export const injectTag = (tagProperties: $TSFixMe) => {
  const { tagName, attributes, textNode, style, parentNode } = tagProperties;

  try {
    const tag = document.createElement(tagName);

    for (const key in attributes) tag[key] = attributes[key];

    if (textNode) {
      const inlineHTML = document.createTextNode(textNode);
      tag.appendChild(inlineHTML);
    }

    if (style) {
      for (const key in style) tag.style[key] = style[key];
    }

    if (parentNode === 'head') document.head.appendChild(tag);
    else document.body.appendChild(tag);
  } catch (error) {
    console.log(error);
  }
};

/**
 * @description Injects a javascrpt script tag
 * @param {object} scriptProperties
 * @param {string} scriptProperties.src
 * @param {string} scriptProperties.id
 * @param {boolean} scriptProperties.async
 * @param {boolean} scriptProperties.defer
 * @param {string} scriptProperties.textNode
 * @param {string} scriptProperties.type
 * @param {string} scriptProperties.parentNode
 * @param {boolean} scriptProperties.returnScript
 */
export const addScriptTag = (scriptProperties: $TSFixMe) => {
  const { src, id, async, defer, type, textNode, parentNode, returnScript } = scriptProperties;

  try {
    const script = document.createElement('script');
    script.id = id;

    if (async) script.async = true;
    if (defer) script.defer = true;
    if (type) script.type = type;

    if (textNode) {
      const inlineScript = document.createTextNode(textNode);
      script.appendChild(inlineScript);
    }

    if (src) script.src = src;

    if (parentNode === 'head') document.head.appendChild(script);
    else document.body.appendChild(script);

    if (returnScript) return script;
  } catch (error) {
    console.log(error);
  }
};

/**
 * @description Iterates through all script tags and removes those that the atribute type and value inputted
 * @param {string} tagName
 * @param {string} attributeType
 * @param {string} attributeValue
 */
export function removeTag(tagName: $TSFixMe, attributeType: $TSFixMe, attributeValue: $TSFixMe) {
  const tags = document.getElementsByTagName(tagName);

  // REMOVE SCRIPTS WITH CERTAIN ATTRIBUTE
  for (let e = tags.length; e >= 0; e--) {
    const tag = tags[e];
    if (
      tag &&
      tag.getAttribute(attributeType) !== null &&
      tag.getAttribute(attributeType).indexOf(attributeValue) !== -1
    ) {
      tag.parentNode.removeChild(tag);
    }
  }
}

export const isIfScriptLoaded = (attributeType: $TSFixMe, attributeValue: $TSFixMe) => {
  const scripts = document.getElementsByTagName('script');

  // RETURN TRUE IF SCRIPT WITH CERTAIN ATTRIBUTE IS DEFINED
  for (let e = scripts.length; e >= 0; e--) {
    const script = scripts[e];
    if (
      script &&
      script.getAttribute(attributeType) !== null &&
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      script.getAttribute(attributeType).indexOf(attributeValue) !== -1
    ) {
      return true;
    }
  }
};

export const setAcronym = (location = '') => {
  const acronyms = [
    {
      longNames: ['United Kingdom'],
      acronym: 'UK'
    },
    {
      longNames: ['United States of America', 'United States'],
      acronym: 'USA'
    }
  ];

  if (location) {
    for (const { longNames, acronym } of acronyms) {
      const matchedLongName = longNames.find(longName => location.includes(longName));

      if (matchedLongName) return location.replace(matchedLongName, acronym);
    }
  }

  return location || '';
};

/**
 * @description Iterates through searchItems and returns an object with string and array params
 * @param {array} searchItems array of objects [{ attribute: '', label: '', value: '' }, ...]
 * @param {array} stringParameters array of string attributes
 */
export const getSearchTerms = (searchItems: $TSFixMe, stringParameters: $TSFixMe) => {
  const object = {};

  for (const item of searchItems) {
    const { attribute, value } = item;

    if (stringParameters.indexOf(attribute) < 0) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (object[attribute] === undefined) object[attribute] = [];
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      object[attribute].push(value);
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    } else if (object[attribute] === undefined) object[attribute] = value;
  }

  return object;
};

export const isProfileURL = (pathname: $TSFixMe) => {
  const positionURLRegex = /\/u\/[a-z0-9_%.]+(-|[a-z])+\/jobs\/[0-9]+-/g;
  const companyURLRegex = /\/u\/[0-9]+-/g;
  const personURL = /\/u\/[a-z0-9_%.]+(-|[a-z])+\/people\/[0-9]+-/g;

  if (
    positionURLRegex.test(pathname) ||
    companyURLRegex.test(pathname) ||
    personURL.test(pathname)
  ) {
    return true;
  } else return false;
};

export const getURLFriendlyString = (text: $TSFixMe) => {
  return encodeURIComponent(text.replace(allSpacesRegex, '-').toLowerCase());
};

export const generatePositionURL = (
  location: Location,
  positionData: {
    companyName: string;
    positionID: number;
    positionName: string;
  },
  exact?: boolean
) => {
  const { companyName = '', positionID, positionName = '' } = positionData;
  const { pathname = '', hash = '' } = location;
  const url = `/u/${getURLFriendlyString(companyName)}/jobs/${positionID}-${getURLFriendlyString(
    positionName
  )}${hash}`;

  if (exact) return url;
  return `${pathname === '/' ? '' : pathname.split('/u/')[0]}${url}`;
};

export const generateCompanyURL = (
  location: Location,
  companyData: { companyName: string; companyID: number },
  exact?: boolean
) => {
  const { companyName = '', companyID } = companyData;
  const { pathname = '', hash = '' } = location;
  const url = `/u/${companyID}-${getURLFriendlyString(companyName)}${hash}`;

  if (exact) return url;
  return `${pathname === '/' ? '' : pathname.split('/u/')[0]}${url}`;
};

export const generatePersonsURL = (
  location: Location,
  data: { personsName: string; personsID: number; companyName: string },
  exact?: boolean
) => {
  const { personsName = '', personsID, companyName = '' } = data;
  const { pathname = '', hash = '' } = location;

  if (personsName && personsID && companyName) {
    const url = `/u/${getURLFriendlyString(companyName)}/people/${personsID}-${getURLFriendlyString(
      personsName
    )}${hash}`;

    if (exact) return url;
    return `${pathname === '/' ? '' : pathname.split('/u/')[0]}${url}`;
  }

  return pathname;
};

export const generateStoryURL = (
  location: Location,
  storyData: { id: number; companyName?: string },
  exact: boolean
) => {
  const { id, companyName = '' } = storyData;
  const { pathname = '', hash = '' } = location;
  const url = `/stories/${id}-${getURLFriendlyString(companyName)}${hash}`;

  if (exact) return url;
  return `${pathname === '/' ? '' : pathname.split('/u/')[0]}${url}`;
};

export const generatePartnershipURL = (
  location: $TSFixMe,
  partnerData: $TSFixMe,
  exact: $TSFixMe
) => {
  const { id, companyName = '' } = partnerData;
  const { pathname = '', hash = '' } = location;
  const url = `/partners/${id}-${getURLFriendlyString(companyName)}${hash}`;

  if (exact) return url;
  else return `${pathname === '/' ? '' : pathname.split('/u/')[0]}${url}`;
};

export const generateCandidateURL = (location: Location, candidateID: number) => {
  const { pathname = '', hash = '', search = '' } = location;
  const url = `/u/candidate/${candidateID}${search}${hash}`;

  return `${pathname === '/' ? '' : pathname.split('/u/')[0]}${url}`;
};

/**
 * @deprecated use randomInt from v2/utils/math instead
 * Todo: tech-sprint, replace instances of this with randomInt
 */
export const getRandomNumber = (totalNumber: $TSFixMe) => {
  return Math.floor(Math.random() * totalNumber);
};

export const getRandomItemFromArray = (arr: any[] = []) => {
  return arr[getRandomNumber(arr.length)] || null;
};

export const getQueryStringObject = (queryString = ''): { [key: string]: any } | null => {
  if (queryString.length === 0) return {};
  try {
    const valuesAndKeys = queryString.split('&'); // generates an array of queryParameters
    const queryStringObj = {};

    //loops through the array and generates the keys & values
    // @ts-expect-error TS(2802) FIXME: Type 'IterableIterator<[number, string]>' can only... Remove this comment to see the full error message
    for (const [index, value] of valuesAndKeys.entries()) {
      if (index === 0) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        queryStringObj[value.split('=')[0].substring(1)] = decodeURIComponent(value.split('=')[1]);
      }
      // removes the ? from the key name
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      else queryStringObj[value.split('=')[0]] = decodeURIComponent(value.split('=')[1]);
    }

    return queryStringObj;
  } catch (error) {
    return null;
  }
};

// Returns lists of params as object keys, like ==> { param1: [...], param2: [...] }
export const getQueryStringObjectArrays = (queryString = '') => {
  if (queryString.length === 0) return null;
  try {
    const valuesAndKeys = queryString.split('&'); // generates an array of queryParameters
    const queryStringObj = {};

    //loops through the array and generates the keys & values
    // @ts-expect-error TS(2802) FIXME: Type 'IterableIterator<[number, string]>' can only... Remove this comment to see the full error message
    for (const [index, value] of valuesAndKeys.entries()) {
      if (index === 0) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (queryStringObj[value.split('=')[0].substring(1)]) {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          queryStringObj[value.split('=')[0].substring(1)].push(
            decodeURIComponent(value.split('=')[1])
          );
        } else {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          queryStringObj[value.split('=')[0].substring(1)] = [
            decodeURIComponent(value.split('=')[1])
          ];
        }
      } else {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // eslint-disable-next-line no-lonely-if
        if (queryStringObj[value.split('=')[0]]) {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          queryStringObj[value.split('=')[0]].push(decodeURIComponent(value.split('=')[1]));
        } else {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          queryStringObj[value.split('=')[0]] = [decodeURIComponent(value.split('=')[1])];
        }
      }
    }

    return queryStringObj;
  } catch (error) {
    return null;
  }
};

export function getInitials(firstName?: string | null, lastName?: string) {
  const firstInitial = (firstName || '?').substring(0, 1).toUpperCase();
  const secondInitial = (lastName || '').substring(0, 1).toUpperCase();

  return `${firstInitial}${secondInitial}`;
}

/**
 * @description Returns a string based on the status of the application.
 */
export const getPositionButtonNote = (
  applicationData: ExtendedApplicationDetails,
  companyName: string
) => {
  const {
    companyInterested: coI,
    candidateInterested: caI,
    companyDate: coD,
    candidateDate: caD,
    companyContact: coC,
    candidateContact: caC,
    candidateContactDate: caCoD,
    companyContactDate: coCoD,
    conversationArchived: conAr,
    archivedDate: arcD,
    applicationCandidateStartStatus: caStaS,
    appliedExternallyDate: caAExD
  } = applicationData;

  if (coC || (caI && coI && caD! < coD!)) {
    return {
      icon: 'icon_conversation',
      note: `Conversation started`,
      time: getTimeAgo(coCoD || coD)
    };
  }
  if (caC || (caI && coI && caD! > coD!)) {
    return {
      icon: 'icon_conversation',
      note: `Conversation started`,
      time: getTimeAgo(caCoD || caD)
    };
  }
  if (conAr) {
    return {
      icon: 'icon_archive',
      note: 'Conversation archived',
      time: getTimeAgo(arcD)
    };
  }

  const appliedOnCord = caI && isNil(coI);
  const appliedExternally = caStaS === ACSS.AppliedExternally;

  if (appliedOnCord || appliedExternally) {
    return { icon: 'icon_apply', note: 'Applied', time: getTimeAgo(appliedOnCord ? caD : caAExD) };
  }
  if (coI && isNil(caI)) {
    return { icon: 'icon_unread', note: `Request received`, time: getTimeAgo(coD) };
  }
  if (caI && coI === false) {
    return {
      icon: 'icon_failed_message',
      note: `${companyName} declined`,
      time: getTimeAgo(coD)
    };
  }
  if (caI === false && coI) {
    return {
      icon: 'icon_failed_message',
      note: 'You declined',
      time: getTimeAgo(caD)
    };
  }
  return {};
};

/**
 * @description Returns a string based on the status of the application.
 * @param {object} applicationData
 * @param {boolean} applicationData.companyInterested
 * @param {boolean} applicationData.candidateInterested
 * @param {timestamp} applicationData.companyDate
 * @param {timestamp} applicationData.candidateDate
 * @param {boolean} applicationData.companyContact
 * @param {boolean} applicationData.candidateContact
 * @param {timestamp} applicationData.candidateContactDate
 * @param {timestamp} applicationData.companyContactDate
 * @param {boolean} applicationData.conversationArchived
 * @param {boolean} applicationData.archivedDate
 * @param {string} candidateName
 */
export const getCandidateButtonNote = (applicationData: $TSFixMe, candidateName: $TSFixMe) => {
  const {
    companyInterested: coI,
    candidateInterested: caI,
    companyDate: coD,
    candidateDate: caD,
    companyContact: coC,
    candidateContact: caC,
    candidateContactDate: caCoD,
    companyContactDate: coCoD,
    conversationArchived: conAr,
    archivedDate: arcD
  } = applicationData;

  if (coC || (caI && coI && caD < coD)) {
    return { note: 'You started a conversation', time: getTimeAgo(coCoD || coD) };
  } else if (caC || (caI && coI && caD > coD)) {
    return { note: 'You started a conversation', time: getTimeAgo(caCoD || caD) };
  } else if (conAr) return { note: 'Conversation archived', time: getTimeAgo(arcD) };
  else if (caI && (coI === null || coI === undefined)) {
    return { note: `${candidateName} sent you a message request`, time: getTimeAgo(caD) };
  } else if (coI && (caI === null || caI === undefined)) {
    return { note: 'You sent a message request', time: getTimeAgo(coD) };
  } else if (caI && coI === false) return { note: 'You declined', time: getTimeAgo(coD) };
  else if (caI === false && coI) {
    return { note: `${candidateName} declined`, time: getTimeAgo(caD) };
  }
  return { note: '', time: '' };
};

/**
 * @description Returns a string based on the status of the application.
 * @param {ApplicationDetails} applicationData
 */
export const getApplicationStatus = (applicationData: $TSFixMe) => {
  const {
    companyInterested: coI,
    candidateInterested: caI,
    companyDate: coD,
    candidateDate: caD,
    companyContact: coC,
    candidateContact: caC,
    conversationArchived: conAr
  } = applicationData;

  if (coC) return 'company_contacted';
  else if (caC) return 'candidate_contacted';
  else if (coC === false || conAr) return 'conversation_archived';
  else if (caI && (coI === null || coI === undefined)) return 'candidate_applied';
  else if (coI && (caI === null || caI === undefined)) return 'company_offered';
  else if (caI && coI && caD < coD) return 'company_accepted';
  else if (caI && coI && caD > coD) return 'candidate_accepted';
  else if (caI && coI === false) return 'company_declined';
  else if (coI && caI === false) return 'candidate_declined';
  else if ((coI === null || coI === undefined) && caI === false) return 'candidate_skipped';
  else if ((caI === null || caI === undefined) && coI === false) return 'company_skipped';
  return '';
};

export const getMessageActionAndStage = (
  applicationStatus: $TSFixMe,
  currentUser: $TSFixMe,
  companyDeclineReplyDate: $TSFixMe,
  candidateDeclineReplyDate: $TSFixMe
) => {
  if (applicationStatus === 'candidate_apply_pending' && currentUser === 'candidate') {
    return { action: 'apply', stage: 'candidate_sending_request' };
  } else if (applicationStatus === 'company_offered' && currentUser === 'company') {
    return { action: 'follow-up', stage: 'company_sent_request' };
  } else if (
    applicationStatus === 'conversation_started' ||
    applicationStatus === 'company_hired' ||
    applicationStatus === 'candidate_contacted' ||
    applicationStatus === 'company_contacted' ||
    (applicationStatus === 'company_accepted' && currentUser === 'company') ||
    (applicationStatus === 'candidate_accepted' && currentUser === 'candidate')
  ) {
    return { action: 'post-convo', stage: 'conversation_started' };
  } else if (
    (applicationStatus === 'candidate_accepted' && currentUser === 'company') ||
    (applicationStatus === 'company_accepted' && currentUser === 'candidate')
  ) {
    return { action: 'confirm', stage: 'pending_confirmation' };
  } else if (
    (applicationStatus === 'candidate_applied' && currentUser === 'company') ||
    (applicationStatus === 'company_offered' && currentUser === 'candidate')
  ) {
    return { action: 'accept', stage: 'incoming_request' };
  } else if (
    (applicationStatus === 'candidate_declined' &&
      currentUser === 'company' &&
      !companyDeclineReplyDate) ||
    (applicationStatus === 'company_declined' &&
      currentUser === 'candidate' &&
      !candidateDeclineReplyDate)
  ) {
    return { action: 'decline_reply', stage: 'other_declined' };
  } else if (
    (applicationStatus === 'company_declined' && currentUser === 'company') ||
    (applicationStatus === 'candidate_declined' && currentUser === 'candidate')
  ) {
    return { action: 'accept', stage: 'you_declined' };
  } else if (
    (applicationStatus === 'candidate_declined' &&
      currentUser === 'company' &&
      companyDeclineReplyDate) ||
    (applicationStatus === 'company_declined' &&
      currentUser === 'candidate' &&
      candidateDeclineReplyDate)
  ) {
    return { action: '', stage: 'you_decline_replied' };
  }
  return { action: '', stage: '' };
};

const processSelectedCompanyRemoteWorkOptions = (remoteFirstOption: $TSFixMe) => {
  const remoteFirstOptions = fetcher.getRemoteFirstOptions();
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const optionLabel = remoteFirstOptions
    .find(option => option.value === remoteFirstOption)
    .value.toLowerCase();
  const output = ` (${
    optionLabel === 'no_onsite' ? 'no onsite' : `~ ${optionLabel} office visits`
  })`;
  return output;
};

export const getCompanyRemoteWorkInfo = (
  remote: $TSFixMe,
  remoteDays: $TSFixMe,
  remoteDaysPreference: $TSFixMe,
  remoteFirstOption: $TSFixMe
) => {
  if (!remote) return '';

  const remoteOptions = fetcher.getRemoteOptions();
  const selectedRemoteOptions = remoteOptions.filter(({ value }) => remote.includes(value));

  const selectedRemoteWork = selectedRemoteOptions.map(({ label, value }) => {
    let output = label;

    if (value === 'hybrid' && remoteDays) {
      output += ` (${remoteDaysPreference} ${remoteDays} remote day${
        remoteDays > 1 ? 's' : ''
      } p/w)`;
    }

    if (value === 'remote' && remoteFirstOption) {
      output += processSelectedCompanyRemoteWorkOptions(remoteFirstOption);
    }
    return output;
  });
  return selectedRemoteWork.join(', ');
};

export const processSelectedEngineerRemoteWorkOptions = (
  remoteFirstOption: $TSFixMe,
  remoteLocations = ''
) => {
  const remoteEngineerVisitOptions = fetcher.getRemoteEngineerVisitOptions();
  const engineerSideLabel =
    remoteEngineerVisitOptions.find(option => option.value === remoteFirstOption)?.value || '';

  switch (engineerSideLabel) {
    case 'no_onsite':
      return ` (No onsite${remoteLocations.length > 0 ? ` (${remoteLocations})` : ''})`;
    case 'infrequent_onsite':
      return ` (Infrequent onsite visits${
        remoteLocations.length > 0 ? ` (${remoteLocations})` : ''
      })`;
    case 'no_preference':
    default:
      return remoteLocations.length > 0 ? ` (${remoteLocations})` : '';
  }
};

export function getEngineerRemoteWorkInfo(
  remote: $TSFixMe,
  remoteDays: $TSFixMe,
  remoteFirstOption: $TSFixMe,
  remoteLocations = ''
) {
  if (!remote) return '';

  const remoteOptions = fetcher.getRemoteOptions();
  const selectedRemoteOptions = remoteOptions.filter(({ value }) => remote.includes(value));

  const selectedRemoteWork = selectedRemoteOptions.map(({ label, value }) => {
    let output = label;

    if (value === 'hybrid' && remoteDays && !remoteDays.includes('0')) {
      output += ` (${remoteDays} remote days p/w)`;
    }

    if (value === 'remote' && (remoteFirstOption || remoteLocations)) {
      output += processSelectedEngineerRemoteWorkOptions(remoteFirstOption, remoteLocations);
    }
    return output;
  });
  return selectedRemoteWork.join(', ');
}

/** To-be deprecated in favor of generateMatchingCriteria */
export const getMatchingCriteria = (
  candidateDetails = {},
  positionDetails = {},
  user = 'candidate'
) => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type '{}'.
    location: cl,
    // @ts-expect-error TS(2339) FIXME: Property 'primarySkills' does not exist on type '{... Remove this comment to see the full error message
    primarySkills: cps,
    // @ts-expect-error TS(2339) FIXME: Property 'secondarySkills' does not exist on type ... Remove this comment to see the full error message
    secondarySkills: css,
    // @ts-expect-error TS(2339) FIXME: Property 'jobTitles' does not exist on type '{}'.
    jobTitles: cjt,
    // @ts-expect-error TS(2339) FIXME: Property 'seniorities' does not exist on type '{}'... Remove this comment to see the full error message
    seniorities: cs,
    // @ts-expect-error TS(2339) FIXME: Property 'companyIndustries' does not exist on typ... Remove this comment to see the full error message
    companyIndustries: ci,
    // @ts-expect-error TS(2339) FIXME: Property 'remote' does not exist on type '{}'.
    remote: cr,
    // @ts-expect-error TS(2339) FIXME: Property 'salary' does not exist on type '{}'.
    salary: csalary,
    // @ts-expect-error TS(2339) FIXME: Property 'currency' does not exist on type '{}'.
    currency: cc,
    // @ts-expect-error TS(2339) FIXME: Property 'skillPreferences' does not exist on type... Remove this comment to see the full error message
    skillPreferences: csp,
    // @ts-expect-error TS(2339) FIXME: Property 'workEligibilities' does not exist on typ... Remove this comment to see the full error message
    workEligibilities: cwe
  } = candidateDetails;
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type '{}'.
    location: pl,
    // @ts-expect-error TS(2339) FIXME: Property 'primarySkills' does not exist on type '{... Remove this comment to see the full error message
    primarySkills: pps,
    // @ts-expect-error TS(2339) FIXME: Property 'secondarySkills' does not exist on type ... Remove this comment to see the full error message
    secondarySkills: pss,
    // @ts-expect-error TS(2339) FIXME: Property 'jobTitles' does not exist on type '{}'.
    jobTitles: pjt,
    // @ts-expect-error TS(2339) FIXME: Property 'seniorities' does not exist on type '{}'... Remove this comment to see the full error message
    seniorities: ps,
    // @ts-expect-error TS(2339) FIXME: Property 'industries' does not exist on type '{}'.
    industries: pi,
    // @ts-expect-error TS(2339) FIXME: Property 'remote' does not exist on type '{}'.
    remote: pr,
    // @ts-expect-error TS(2339) FIXME: Property 'salaryMin' does not exist on type '{}'.
    salaryMin: psmin,
    // @ts-expect-error TS(2339) FIXME: Property 'salaryMax' does not exist on type '{}'.
    salaryMax: psmax,
    // @ts-expect-error TS(2339) FIXME: Property 'techStack' does not exist on type '{}'.
    techStack: pts,
    // @ts-expect-error TS(2339) FIXME: Property 'workEligibilities' does not exist on typ... Remove this comment to see the full error message
    workEligibilities: pwe,
    // @ts-expect-error TS(2339) FIXME: Property 'isVisaSponsorshipAvailable' does not exi... Remove this comment to see the full error message
    isVisaSponsorshipAvailable
  } = positionDetails;

  const commonCriteria: $TSFixMe = [];
  const nonCommonCriteria = [];
  let showWarningMessage = false;

  const visaMatch = getWorkEligibilityMatch(cwe, pwe, isVisaSponsorshipAvailable);
  const currency = getCurrencySymbol(cc);

  if (user === 'candidate') {
    // JOB TITLES
    if (cjt) {
      cjt.forEach((t: $TSFixMe) => {
        if (pjt.includes(t)) commonCriteria.push(t);
      });
    }
    // SENIORITIES
    if (cs) {
      cs.forEach((s: $TSFixMe) => {
        if (ps.includes(s)) commonCriteria.push(capitalizeSentences(s));
      });
    }
    // CORE SKILLS
    if (cps) {
      const uniqueValues = unique(cps.concat(pps));
      cps.forEach((s: $TSFixMe) => {
        if (pps.includes(s)) commonCriteria.push(s);
      });
      if (uniqueValues.length === cps.length + pps.length) {
        nonCommonCriteria.push('No core skills in common. Explain why you are still a good fit.');
      }
    }
    // OTHER SKILLS
    if (css) {
      css.forEach((s: $TSFixMe) => {
        if (pss.includes(s)) commonCriteria.push(s);
      });
    }
    // INDUSTRIES
    if (ci && ci.length > 0) {
      ci.forEach((i: $TSFixMe) => {
        if (pi.includes(i)) commonCriteria.push(i);
      });
    }
    // REMOTE
    if (cr) {
      cr.forEach((i: $TSFixMe) => {
        if (pr.includes(i)) commonCriteria.push(capitalizeSentences(i));
      });
    }
    // SALARY
    if (psmin && psmax && csalary && csalary <= psmax) {
      commonCriteria.push(`${currency}${formatNumber(csalary)}`);
    }
    // LOCATION
    if (cl && pl && (cl?.label || cl).indexOf(pl) > -1) commonCriteria.push(setAcronym(pl));
    // VISA
    if (!visaMatch) nonCommonCriteria.push('No visa sponsorship available.');
  } else {
    // JOB TITLES
    if (pjt) {
      pjt.forEach((t: $TSFixMe) => {
        if (cjt.includes(t)) commonCriteria.push(t);
      });
    }
    // SENIORITIES
    if (ps) {
      const uniqueValues = unique(ps.concat(cs));
      ps.forEach((s: $TSFixMe) => {
        if (cs.includes(s)) commonCriteria.push(capitalizeSentences(s));
      });
      if (uniqueValues.length === cs.length + ps.length) {
        nonCommonCriteria.push('No seniority match');
      }
    }
    // CORE SKILLS
    if (pps) {
      const uniqueValues = unique(pps.concat(cps));
      pps.forEach((s: $TSFixMe) => {
        if (cps.includes(s)) commonCriteria.push(s);
      });
      if (uniqueValues.length === cps.length + pps.length) {
        nonCommonCriteria.push('No core skills match');
        showWarningMessage = true;
      }
    }
    // OTHER SKILLS
    if (pss) {
      pss.forEach((s: $TSFixMe) => {
        if (css.includes(s)) commonCriteria.push(s);
      });
    }
    // TECH STACK
    if (pts && csp) {
      pts.forEach((s: $TSFixMe) => {
        if (csp.includes(s) && !commonCriteria.includes(s)) commonCriteria.push(s);
      });
    }
    // INDUSTRIES
    if (pi && ci && ci.length > 0) {
      pi.forEach((i: $TSFixMe) => {
        if (ci.includes(i)) commonCriteria.push(i);
      });
    }
    // REMOTE
    if (pr && cr) {
      pr.forEach((i: $TSFixMe) => {
        // @ts-expect-error TS(2554) FIXME: Expected 3-4 arguments, but got 1.
        if (cr.includes(i)) commonCriteria.push(getEngineerRemoteWorkInfo([i]));
      });
      const uniqueValues = unique(pr.concat(cr));
      // @ts-expect-error TS(2554) FIXME: Expected 4 arguments, but got 1.
      const prettyRemote = getCompanyRemoteWorkInfo(cr);

      if (uniqueValues.length === pr.length + cr.length) {
        nonCommonCriteria.push(prettyRemote);
        showWarningMessage = true;
      }
    }
    // SALARY
    if (psmin && psmax && csalary && csalary <= psmax) {
      commonCriteria.push(`${currency}${formatNumber(csalary)}`);
    } else if (csalary > psmax) {
      nonCommonCriteria.push('Over salary bracket');
      showWarningMessage = true;
    }
    // LOCATION
    if (cl && pl && (cl?.label || cl).indexOf(pl) > -1) commonCriteria.push(setAcronym(pl));
    // VISA
    if (!visaMatch) {
      nonCommonCriteria.push('Visa required');
      showWarningMessage = true;
    }
  }

  return { commonCriteria, nonCommonCriteria: nonCommonCriteria.flat(), showWarningMessage };
};

export const conversationsCategoriesMap = (user: $TSFixMe) => {
  switch (user) {
    case 'company':
      return [
        {
          label: 'All Messages',
          value: 'allConversation',
          emptyMessage: 'No conversations yet',
          uri: '',
          noResultsMessage: 'No conversations found for selected positions'
        },
        {
          label: 'You Sent',
          value: 'outgoingRequest',
          emptyMessage: 'No outgoing requests',
          uri: 'outgoing-requests',
          noResultsMessage: 'No sent messages found for selected positions'
        },
        {
          label: 'People Sourced',
          value: 'conversationStarted',
          emptyMessage: 'No people sourced yet',
          uri: 'conversations-started',
          noResultsMessage: 'No people sourced found for selected positions'
        },
        {
          label: 'Declined (by person)',
          value: 'candidateDeclined',
          emptyMessage: 'No request has been declined',
          uri: 'candidate-declined',
          noResultsMessage: 'No conversations found for selected positions'
        },
        {
          label: 'Declined (by you)',
          value: 'companyDeclined',
          emptyMessage: 'No request declined',
          uri: 'company-declined',
          noResultsMessage: 'No conversations found for selected positions'
        },
        {
          label: 'Expired Requests',
          value: 'expiredRequest',
          emptyMessage: 'No requests expired',
          uri: '',
          noResultsMessage: 'No incoming requests have expired'
        },
        {
          label: 'People Hired',
          value: 'conversationHired',
          emptyMessage: 'No hired candidates',
          uri: '',
          noResultsMessage: 'No hired candidates found'
        },
        {
          label: 'Archived Messages',
          value: 'conversationArchived',
          emptyMessage: 'No archived conversations',
          uri: '',
          noResultsMessage: 'No archived conversations found for selected positions'
        },
        {
          label: 'Incoming Requests',
          value: 'incomingRequest',
          emptyMessage: 'No incoming requests',
          uri: '',
          noResultsMessage: 'No incoming requests found for selected positions'
        }
      ];
    default:
      return [
        {
          label: 'All',
          value: 'allConversation',
          emptyMessage: 'No conversations yet',
          uri: '',
          noResultsMessage: 'No conversations found for selected positions'
        },
        {
          label: 'You Sent',
          value: 'outgoingRequest',
          emptyMessage: 'No outgoing requests',
          uri: 'outgoing-requests',
          noResultsMessage: 'No sent messages found for selected positions'
        },
        {
          label: 'Conversations Started',
          value: 'conversationStarted',
          emptyMessage: 'No conversations started yet',
          uri: 'conversations-started',
          noResultsMessage: 'No conversations found for selected positions'
        },
        {
          label: 'You Declined',
          value: 'candidateDeclined',
          emptyMessage: 'No request has been declined',
          uri: 'candidate-declined',
          noResultsMessage: 'No conversations found for selected positions'
        },
        {
          label: 'Company Declined',
          value: 'companyDeclined',
          emptyMessage: 'No request declined',
          uri: 'company-declined',
          noResultsMessage: 'No conversations found for selected positions'
        },
        {
          label: 'Archived Conversations',
          value: 'conversationArchived',
          emptyMessage: 'No archived conversations',
          uri: '',
          noResultsMessage: 'No archived conversations found for selected positions'
        },
        {
          label: 'Incoming Requests',
          value: 'incomingRequest',
          emptyMessage: 'No incoming requests',
          uri: '',
          noResultsMessage: 'No incoming requests found for selected positions'
        }
      ];
  }
};

export const messagesUriCategoryMap = (categoryURI: $TSFixMe) => {
  switch (categoryURI) {
    case 'conversations-started':
      return 'conversationStarted';
    case 'candidate-declined':
      return 'candidateDeclined';
    case 'company-declined':
      return 'companyDeclined';
    case 'outgoing-requests':
      return 'outgoingRequest';
    case 'pending-confirmation':
      return 'conversationStarted';
    default:
      return '';
  }
};

export const companyApplicationStatusTitlesMap = () => {
  return [
    { status: 'company_contacted', previewMessage: 'Sourced for', topMessage: '' },
    { status: 'candidate_contacted', previewMessage: 'Sourced for', topMessage: '' },
    { status: 'conversation_started', previewMessage: 'Sourced for', topMessage: '' },
    { status: 'candidate_post_conversation', previewMessage: 'Sourced for', topMessage: '' },
    { status: 'company_post_conversation', previewMessage: 'Sourced for', topMessage: '' },
    { status: 'conversation_archived', previewMessage: 'Archived about', topMessage: '' },
    { status: 'company_declined', previewMessage: 'Declined for', topMessage: 'was declined for' },
    { status: 'candidate_declined', previewMessage: 'Declined', topMessage: 'declined the' },
    {
      status: 'candidate_applied',
      previewMessage: 'Request about',
      topMessage: 'wants to speak with you about the'
    },
    {
      status: 'candidate_accepted',
      previewMessage: 'Accepted your request about',
      topMessage: 'accepted your request about the'
    },
    { status: 'company_offered', previewMessage: 'Request about', topMessage: '' },
    { status: 'company_offered_follow_up', previewMessage: 'Request about', topMessage: '' },
    {
      status: 'candidate_applied_follow_up',
      previewMessage: 'Request about',
      topMessage: 'wants to speak with you about the'
    },
    { status: 'company_accepted', previewMessage: 'Sourced for', topMessage: '' },
    { status: 'candidate_booked_invite', previewMessage: 'Booked an event about', topMessage: '' },
    { status: 'company_hired', previewMessage: 'Hired for', topMessage: '' }
  ];
};

export const isCandidateApplication = (applicationStatus: string) => {
  return (
    applicationStatus === 'candidate_applied' || applicationStatus === 'candidate_applied_follow_up'
  );
};

export const isConversationStarted = (applicationStatus: string) => {
  return (
    applicationStatus === ApplicationStatus.CandidateContacted ||
    applicationStatus === ApplicationStatus.CompanyContacted ||
    applicationStatus === 'conversation_started'
  );
};

export const getApplicationScoreLabel = (candidateMatchOnApply?: number | null) => {
  if (!candidateMatchOnApply) return '';
  if (candidateMatchOnApply > 0.9) return 'Top fit';
  if (candidateMatchOnApply >= 0.8 && candidateMatchOnApply < 1) return 'Great fit';
  if (candidateMatchOnApply >= 0.5 && candidateMatchOnApply < 0.8) return 'Average fit';
  return 'Poor fit';
};

export const isGreatOrTopFit = (candidateMatchOnApply?: number | null) => {
  if (!candidateMatchOnApply) return false;
  return candidateMatchOnApply >= 0.8;
};

export const candidateApplicationStatusTitlesMap = () => {
  return [
    {
      status: 'company_contacted',
      previewMessage: 'Conversation started',
      topMessage: '· Speaking about'
    },
    {
      status: 'candidate_contacted',
      previewMessage: 'Conversation started',
      topMessage: '· Speaking about'
    },
    {
      status: 'conversation_started',
      previewMessage: 'Conversation started',
      topMessage: '· Speaking about'
    },
    {
      status: 'candidate_post_conversation',
      previewMessage: 'Conversation started',
      topMessage: '· Speaking about'
    },
    {
      status: 'company_post_conversation',
      previewMessage: 'Conversation started',
      topMessage: '· Speaking about'
    },
    {
      status: 'conversation_archived',
      previewMessage: 'Conversation archived',
      topMessage: '· Archived conversation about'
    },
    {
      status: 'company_declined',
      previewMessage: 'Your message request has been declined',
      topMessage: 'declined your message request about'
    },
    {
      status: 'candidate_declined',
      previewMessage: 'You declined their request',
      topMessage: '· You declined the message request about'
    },
    {
      status: 'candidate_applied',
      previewMessage: 'You sent a message request',
      topMessage: '· You sent a message about'
    },
    {
      status: 'candidate_accepted',
      previewMessage: 'Conversation started',
      topMessage: '· Speaking about'
    },
    {
      status: 'company_offered',
      previewMessage: 'Request from {{N}}',
      topMessage: '· Messaging you about'
    },
    {
      status: 'company_offered_follow_up',
      previewMessage: 'Request from {{N}}',
      topMessage: '· Messaging you about'
    },
    {
      status: 'company_offered_auto_follow_up',
      previewMessage: 'Request from {{N}}',
      topMessage: '· Messaging you about'
    },
    {
      status: 'candidate_applied_follow_up',
      previewMessage: 'You sent a message request',
      topMessage: '· You sent a message about'
    },
    {
      status: 'company_accepted',
      previewMessage: 'Pending your confirmation',
      topMessage: '· Speaking about'
    },
    {
      status: 'candidate_booked_invite',
      previewMessage: 'You booked a time to speak with {{N}}',
      topMessage: '· Speaking about'
    }
  ];
};

export const getLatestDateFromArray = (lastActiveTimestamps: $TSFixMe) => {
  const lastActive = new Date(
    Math.max(...lastActiveTimestamps.map((time: $TSFixMe) => new Date(time)))
  );

  return lastActive;
};

export const getMessageNoteBlock = (
  name: $TSFixMe,
  applicationStatus: $TSFixMe,
  currentUser: $TSFixMe,
  options = {}
) => {
  // @ts-expect-error TS(2339) FIXME: Property 'inviteDetails' does not exist on type '{... Remove this comment to see the full error message
  const { inviteDetails, verified } = options;

  switch (applicationStatus) {
    case 'company_offered':
      return `${name} sent a message request`;
    case 'company_offered_follow_up':
      return `${name} sent a follow up message`;
    case 'company_offered_auto_follow_up':
      return `${name} sent a follow up message`;
    case 'candidate_applied':
      if (currentUser === 'candidate') {
        return `Your profile ${verified ? 'has been' : 'will be'} shared`;
      } else return `${name} sent a message request`;
    case 'candidate_accepted':
      if (currentUser === 'candidate') return 'You opened the message thread';
      else return `${name} accepted the message request`;
    case 'company_accepted':
      if (currentUser === 'company') return `${name} accepted the message request`;
      else return `${name} accepted your message request`;
    case 'candidate_declined':
      if (currentUser === 'candidate') return 'You declined the message request';
      else return `${name} declined the message request`;
    case 'company_declined':
      if (currentUser === 'company') return `${name} declined the message request`;
      else return `${name} declined your message request`;
    case 'company_marked_contacted':
      if (currentUser === 'company') {
        return 'You marked that you are already speaking directly outside of cord';
      } else return `${name} marked that you are already speaking directly outside of cord`;
    case 'candidate_booked_invite':
      if (currentUser === 'company') {
        return `${name} booked a ${inviteDetails.title} for ${inviteDetails.time}`;
      } else return `You booked a ${inviteDetails.title} for ${inviteDetails.time}`;
    default:
      return '';
  }
};

export const getThreadTitle = (currentUser: $TSFixMe, applicationStatus: $TSFixMe) => {
  switch (applicationStatus) {
    case 'company_offered':
      if (currentUser === 'candidate') return 'Message request about';
      else return 'You sent a message request about';
    case 'company_offered_follow_up':
      if (currentUser === 'candidate') return 'Message request about';
      else return 'You sent a message request about';
    case 'company_offered_auto_follow_up':
      if (currentUser === 'candidate') return 'Message request about';
      else return 'You sent a message request about';
    case 'candidate_accepted':
      return 'Speaking about';
    case 'company_accepted':
      return 'Speaking about';
    case 'company_contacted':
      return 'Speaking about';
    case 'candidate_contacted':
      return 'Speaking about';
    case 'candidate_declined':
      if (currentUser === 'candidate') return 'You declined the message request about';
      else return 'Message request has been declined about';
    case 'company_declined':
      if (currentUser === 'company') return 'You declined the message request about';
      else return 'Message request has been declined about';
    case 'candidate_applied':
      if (currentUser === 'company') return 'Message request about';
      else return 'You sent a message request about';
    case 'conversation_archived':
      return 'Archived conversation about';
    default:
      if (currentUser === 'company') return 'Message request about';
      else return 'Write a message about';
  }
};

/**
 * @param {object} optionTypes
 * @param {object} searchFilters
 * @param {array} optionTypes.jobTitles array of objects
 * @param {array} optionTypes.skills array of strings
 * @param {array} optionTypes.companies array of strings
 * @param {array} optionTypes.seniorities array of objects
 * @param {array} optionTypes.industries array of objects
 */
export const generateKeywordOptions = (
  optionTypes: $TSFixMe,
  searchFilters: $TSFixMe,
  lessOptions?: boolean
) => {
  const { jobTitles, skills, companies, seniorities, industries } = optionTypes;
  const keywordOptions: KeywordOptions = [];

  jobTitles.forEach(({ label, tags, value }: $TSFixMe) => {
    keywordOptions.push({ label, tags, value, attribute: searchFilters.jobTitle.attribute });
  });

  skills.forEach((skill: $TSFixMe) => {
    keywordOptions.push({
      label: skill,
      tags: [skill],
      value: skill,
      attribute: searchFilters.orPrimarySkill.attribute
    });
  });

  if (lessOptions) return keywordOptions;

  companies.forEach((company: $TSFixMe) => {
    keywordOptions.push({
      label: company,
      tags: [company],
      value: company,
      attribute: searchFilters.keyword.attribute
    });
  });

  seniorities.forEach(({ label, value }: $TSFixMe) => {
    keywordOptions.push({
      label,
      tags: [label],
      value,
      attribute: searchFilters.seniority.attribute
    });
  });

  industries.forEach(({ label }: $TSFixMe) => {
    keywordOptions.push({
      label,
      tags: [label],
      value: label,
      attribute: searchFilters.industry.attribute
    });
  });

  return keywordOptions;
};

export const parseSalaryOptions = (
  data: ReturnType<typeof fetcher.getSalaryOptions>,
  currency = '£'
) => {
  return data.map(option => ({
    label: `${currency}${formatNumber(option, true)}`,
    value: option
  }));
};

export const parseSkillOptions = (data: $TSFixMe) => {
  return data.map((option: $TSFixMe) => ({
    label: option,
    id: convertSpacesToUnderscoreCase(option),
    value: option
  }));
};

export const parseCompanySizeOptions = (data: $TSFixMe, searchFilters: $TSFixMe) => {
  return data.map((option: $TSFixMe) => ({
    label: option,
    id: convertSpacesToUnderscoreCase(option),
    attribute: searchFilters.companySize.attribute,
    value: option
  }));
};

export const parseIndustryOptions = (data: $TSFixMe, searchFilters: $TSFixMe) => {
  return data.map(({ label, path }: $TSFixMe) => ({
    label,
    id: convertSpacesToUnderscoreCase(path),
    attribute: searchFilters.industry.attribute,
    value: label
  }));
};

export const parseExperienceOptions = (data: $TSFixMe, searchFilters: $TSFixMe) => {
  return data.map(({ label, value, tooltip }: $TSFixMe) => ({
    label,
    id: convertSpacesToUnderscoreCase(label),
    attribute: searchFilters.seniority.attribute,
    value,
    description: tooltip
  }));
};

export const parseJobTitleOptions = (data: $TSFixMe, searchFilters: $TSFixMe) => {
  return data.map(({ value, label, tags }: $TSFixMe) => ({
    label,
    tags,
    id: convertSpacesToUnderscoreCase(value),
    attribute: searchFilters.jobTitle.attribute,
    value
  }));
};

export const parseExperienceLevelOptions = (data: $TSFixMe, searchFilters: $TSFixMe) => {
  return data.map(({ label, value, tooltip }: $TSFixMe) => {
    return {
      label,
      id: convertSpacesToUnderscoreCase(label),
      attribute: searchFilters.experienceLevel.attribute,
      value,
      description: tooltip
    };
  });
};

/**
 * Implemented temporary changes that will be reverted until an API becomes available that provides
 * all filter options per search category for the new search bar (Phase 2).
 * 1. New parameters have been added. Since these changes are temporary, parameters have not been encapsulated within an object.
 * 2. 'companyOptions' has been included in the return value.
 */

type GetFilterOptionsParams = {
  isCandidate?: boolean;
  currency: $TSFixMe;
  isHiringInsights?: boolean;
  companyCategory?: CompanyCategory;
  jobTitleSortBy?: JobTitleOrderingValues;
};

export const getFilterOptions = async ({
  isCandidate,
  currency,
  isHiringInsights,
  companyCategory,
  jobTitleSortBy
}: GetFilterOptionsParams) => {
  const searchFilters = fetcher.candidateSearchFilters;

  const [skills, companies, jobTitleOptions] = await Promise.all([
    getSkillOptions(),
    fetcher.getCompanyNames(companyCategory),
    getJobTitleOptions(jobTitleSortBy)
  ]);

  const filterOptions = [
    fetcher.getSalaryOptions(),
    fetcher.getCompanySizeOptions(),
    INDUSTRY_OPTIONS,
    fetcher.getSeniorityOptions()
  ];

  return {
    salaryOptions: {
      title: 'Salary (minimum)',
      attribute: (searchFilters as $TSFixMe).salary.attribute,
      options: parseSalaryOptions(filterOptions[0] as number[], currency)
    },
    skillsOptions: parseSkillOptions(skills.data),
    companySizeOptions: parseCompanySizeOptions(filterOptions[1], searchFilters),
    industryOptions: parseIndustryOptions(filterOptions[2], searchFilters),
    experienceOptions: parseExperienceOptions(filterOptions[3], searchFilters),
    jobTitleOptions: parseJobTitleOptions(jobTitleOptions, searchFilters),
    ...(!isCandidate && {
      keywordOptions: generateKeywordOptions(
        {
          jobTitles: jobTitleOptions,
          skills: skills.data,
          companies: companies.data,
          seniorities: filterOptions[3],
          industries: filterOptions[2]
        },
        searchFilters,
        isHiringInsights
      )
    }),
    companyOptions: companies.data.map((company: string) => ({
      label: company,
      tags: [company],
      value: company,
      attribute: searchFilters.keyword.attribute
    })),
    remoteWorkOptions: getRemoteFilterOptions(),
    lastActiveOptions: {
      attribute: searchFilters.lastActive.attribute,
      options: [
        { label: '3 days', value: '3 days' },
        { label: '7 days', value: '7 days' },
        { label: '14 days', value: '14 days' },
        { label: '1 month', value: '1 month' },
        { label: '3 months', value: '3 months' }
      ]
    }
  };
};

export const addURLProtocol = (url: string, addHTTPS?: boolean) => {
  const httpCheck = new RegExp('http://').test(url);
  const httpsCheck = new RegExp('https://').test(url);

  if (httpCheck || httpsCheck) return url;
  return `${addHTTPS ? 'https' : 'http'}://${url}`;
};

export const getDomainFromURL = (url: string) => {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?([^/]+)\/?/i, '$1');
};

/**
 * @description Validate a url against a certain domain
 * @param {string} url
 * @param {array} domains
 */
export const isURLSubset = (url: string, domains: string[] = []) => {
  const pattern = new RegExp(
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
  ); // port and path

  let validDomain = !!isEmpty(domains);

  if (!isEmpty(domains)) {
    domains.forEach(d => {
      if (url.includes(d)) validDomain = true;
    });
  }

  if (pattern.test(url) && validDomain) return true;
  return false;
};

function isValidURL(url: string) {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * returns true if the url is a subset of the domains. ie - subdomains are allowed
 * @param url valid URL
 * @param domains valud URL
 * @returns boolean
 */
export function isURLStrictSubset(url: string, domains: string[] = []) {
  function isURLStrictSubsetSingle(urlHost: string, domain: string) {
    if (isValidURL(domain)) {
      const domainHost = new URL(domain).host;

      return urlHost.endsWith(domainHost);
    }
    return false;
  }

  if (isValidURL(url)) {
    const urlHost = new URL(url).host;

    return domains.every(d => isURLStrictSubsetSingle(urlHost, d));
  }
  return false;
}

export const checkIfCompanyLandingPage = (pathname: string) => {
  const companyVisitedURLs = getLocalStorageItem('companyVisitedURLs') || [];
  const noPreviousCompanyRoute =
    (companyVisitedURLs.length === 1 && companyVisitedURLs[0] === pathname) ||
    companyVisitedURLs.length === 0;

  return noPreviousCompanyRoute;
};

export const getLengthOfPreviousCompanyPages = () => {
  const companyVisitedURLs = getLocalStorageItem('companyVisitedURLs') || [];

  return companyVisitedURLs.length;
};

export const prepareFiltersQuery = (filters: $TSFixMe) => {
  const parsedFilters = filters.map(({ attribute, label, value }: $TSFixMe) => {
    return { a: attribute, l: label, v: value };
  });
  const stringFilters = JSON.stringify(parsedFilters);

  return encodeURIComponent(stringFilters);
};

export const getFiltersFromQueryString = (filtersString: $TSFixMe) => {
  const filtersArray = JSON.parse(filtersString);

  const filters = filtersArray.map(({ a, l, v }: $TSFixMe) => {
    return { attribute: a, label: l, value: v };
  });

  return filters;
};

// used to properly escape strings before they are used in `new RegExp()`
export function escapeRegExp(string: $TSFixMe) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& refers to the whole matched string
}

/**
 * @description Returns a list with all the options matching the search word
 * @param {object} list
 * @param {string} word
 * @param {string} resultsType One of 'tags', 'label', 'string'
 * @returns {object}
 */
export const getMatchingResults = (list: $TSFixMe, word: $TSFixMe, resultsType: $TSFixMe) => {
  switch (resultsType) {
    case 'tags':
      return list.filter(
        ({ tags }: $TSFixMe) =>
          tags.findIndex((tag: $TSFixMe) =>
            new RegExp(escapeRegExp(word.toLowerCase())).test(tag.toLowerCase())
          ) !== -1
      );
    case 'label':
      return list.filter(
        ({ label }: $TSFixMe) => label.toLowerCase().indexOf(word.toLowerCase()) !== -1
      );
    case 'string':
      return list.filter(
        (option: $TSFixMe) => option.toLowerCase().indexOf(word.toLowerCase()) !== -1
      );
    default:
      return list;
  }
};

/**
 * Loops through availability and builds ranges of times that have the same availability
 * @returns {array} [{ range: 'Mon-Fri', rangeCode: 5, times: [{startTime: '09:00', endTime: '12:30'}, {startTime: '13:30', endTime: '17:00'}] }]
 * @param {array} availability i.e. [
    { dCode: 1, day: 'Mon', startTime: '09:00', endTime: '12:30'},
    { dCode: 1, day: 'Mon', startTime: '13:30', endTime: '17:00'},
    { dCode: 2, day: 'Tue', startTime: '09:00', endTime: '12:30'},
    { dCode: 2, day: 'Tue', startTime: '13:30', endTime: '17:00'},
    { dCode: 3, day: 'Wed', startTime: '09:00', endTime: '12:30'},
    { dCode: 3, day: 'Wed', startTime: '13:30', endTime: '17:00'},
    { dCode: 4, day: 'Thu', startTime: '09:00', endTime: '12:30'},
    { dCode: 4, day: 'Thu', startTime: '13:30', endTime: '17:00'},
    { dCode: 5, day: 'Fri', startTime: '09:00', endTime: '12:30'},
    { dCode: 5, day: 'Fri', startTime: '13:30', endTime: '17:00'},
  ]
 */
export const getAvailabilityPreview = (availability: $TSFixMe) => {
  let range: $TSFixMe = null;
  const output: $TSFixMe = [];

  const sortedAvailability = availability.sort((a: $TSFixMe, b: $TSFixMe) => a.dCode - b.dCode);

  sortedAvailability.forEach(({ dCode, day, startTime, endTime }: $TSFixMe, index: $TSFixMe) => {
    const from = startTime.substring(0, 5);
    const to = endTime.substring(0, 5);

    if (range === null) {
      range = {
        range: capitalizeSentences(day),
        rangeCode: dCode,
        times: [{ startTime: from, endTime: to }]
      };
    } else if (range !== null) {
      const d = range.range;
      const rC = range.rangeCode;
      const timesExist =
        range.times.findIndex(
          (time: $TSFixMe) => time.startTime === from && time.endTime === to
        ) !== -1;

      // if next day is the same but times are different from initial then push new times
      if (capitalizeSentences(day) === d && !timesExist) {
        range.times.push({ startTime: from, endTime: to });
      }
      // otherwise if next day is the following day and times are the same with the previous day then create a range i.e. Mon-Tue
      else if (dCode === rC + 1 && timesExist) {
        range.range = `${d.split('-')[0]}-${capitalizeSentences(day)}`;
        range.rangeCode = dCode;
      } else if (dCode === rC && timesExist) {
        // otherwise if same day and times already exist, then don't update
        // eslint-disable-next-line no-self-assign
        range = range;
      } else {
        //otherwise if new date, and new times then create a new range
        output.push(range);
        range = {
          range: capitalizeSentences(day),
          rangeCode: dCode,
          times: [{ startTime: from, endTime: to }]
        };
      }
    }

    if (index === availability.length - 1) output.push(range);
  });

  return output;
};

export const getInterviewIconByType = (type: $TSFixMe) => {
  switch (type) {
    case 'phone':
      return 'icon_phone';
    case 'video-google':
      return 'icon_hangouts';
    case 'in-person':
      return 'icon_map';
    default:
      return 'icon_calendar';
  }
};

export const getMeetingDateDuration = (date: $TSFixMe, minutesDuration: $TSFixMe) => {
  const endDate = addTime(date, minutesDuration, 'minutes');
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const dateString = new Date(date).toLocaleDateString('en-GB', dateOptions);
  const fromTimeString = getHoursMinutesSeconds(date);
  const toTimeString = getHoursMinutesSeconds(endDate);

  return `${fromTimeString.substring(0, 5)}-${toTimeString.substring(0, 5)}, ${dateString}`;
};

// Fixes a bug with moment.tz() where it converts offsets incorrectly for all Etc/GMT-/+ timzones.
export const getGMTTimeOffset = (timeZone: $TSFixMe) => {
  if (timeZone.startsWith('GMT-')) {
    const offset = timeZone.split('GMT-')[1];
    return -(offset * 60);
  } else if (timeZone.startsWith('GMT+')) {
    const offset = timeZone.split('GMT+')[1];
    return offset * 60;
  }
  return 0;
};

export const getMeetingDurationInTimezone = (
  date: $TSFixMe,
  minutesDuration: $TSFixMe,
  timezone: $TSFixMe
) => {
  const dateISOString = new Date(date).toISOString();
  const offset = getGMTTimeOffset(timezone);

  let meetingDate = '';
  let endMeetingDate = '';

  if (offset) {
    meetingDate = moment(date).utcOffset(offset);
    endMeetingDate = moment(meetingDate).add(minutesDuration, 'minutes');
  } else {
    meetingDate = moment.tz(dateISOString, timezone);
    endMeetingDate = moment.tz(dateISOString, timezone).add(minutesDuration, 'minutes');
  }

  const startTime = (meetingDate as $TSFixMe).format('HH:mm');
  const endTime = (endMeetingDate as $TSFixMe).format('HH:mm');
  const meetingDateString = (meetingDate as $TSFixMe).format('ddd, MMM Do YYYY');

  return `${startTime}-${endTime}, ${meetingDateString}`;
};

export const getShortDateTimeInTimezone = (date: $TSFixMe, timezone: $TSFixMe) => {
  const dateISOString = new Date(date).toISOString();
  const dateInTimezone = moment.tz(dateISOString, timezone);

  const time = dateInTimezone.format('h:mm a');
  const dateString = dateInTimezone.format('MMM Do');

  return `${time}, ${dateString} (${timezone.replace(/_/g, ' ')} Time)`;
};

export const convertToCSV = (objectArray: $TSFixMe) => {
  let str = '';

  for (const item of objectArray) {
    let line = '';

    for (const index in item) {
      if (line !== '') line += ',';

      line += item[index];
    }

    str += `${line}\r\n`;
  }

  return str;
};

export const exportCSVFile = (headers: $TSFixMe, data: $TSFixMe, fileTitle: $TSFixMe) => {
  if (headers) data.unshift(headers);

  const csv = convertToCSV(data);

  const exportedFilename = `${fileTitle}.csv` || 'export.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');

  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export type PreviousApplicationLabels = {
  label: string;
  date: string;
  position: string;
}[];

export const getPreviousConversationsWithCandidate = (
  appliedListing = []
): PreviousApplicationLabels => {
  const previous: PreviousApplicationLabels = [];

  if (isEmpty(appliedListing)) return previous;

  for (const a of appliedListing) {
    const {
      company_date,
      candidate_date,
      company_interested,
      candidate_interested,
      position: applied_position
    } = a;

    if (company_date && candidate_date) {
      const companyTime = new Date(company_date).getTime();
      const candidateTime = new Date(candidate_date).getTime();

      if (companyTime > candidateTime) {
        previous.push({
          label: `${
            company_interested ? 'Started conversation about' : 'Declined for'
          } ${applied_position}`,
          date: company_date,
          position: applied_position
        });
      } else {
        previous.push({
          label: `${
            candidate_interested ? 'Started conversation about' : 'Rejected your request'
          } ${applied_position}`,
          date: candidate_date,
          position: applied_position
        });
      }
    } else if (company_date && company_interested) {
      previous.push({
        label: `Messaged about ${applied_position}`,
        date: company_date,
        position: applied_position
      });
    } else if (candidate_date && candidate_interested) {
      previous.push({
        label: `Applied for ${applied_position}`,
        date: candidate_date,
        position: applied_position
      });
    }
  }

  return previous;
};

// numerically sorts in ASC with N/A values being last
export const numericalSortWithNA = (a: $TSFixMe, b: $TSFixMe, column: $TSFixMe) => {
  if (a[column] === 'N/A') return -1;
  if (b[column] === 'N/A') return 1;
  return a[column] - b[column];
};

export const sortAlphabetically = (
  data: $TSFixMe,
  column: $TSFixMe,
  sorting: $TSFixMe,
  originalData: $TSFixMe
) => {
  let sortedData = [...data];

  if (sorting === 'ascending') {
    sortedData.sort((a, b) => a[column] && a[column].localeCompare(b[column]));
  } else if (sorting === 'descending') {
    sortedData.sort((a, b) => b[column] && b[column].localeCompare(a[column]));
  } else sortedData = JSON.parse(originalData);

  return sortedData;
};

export const sortBoolean = (
  data: $TSFixMe,
  column: $TSFixMe,
  sorting: $TSFixMe,
  originalData: $TSFixMe
) => {
  let sortedData = [...data];

  if (sorting === 'ascending') {
    sortedData.sort(
      (a, b) => a[column].toString() && a[column].toString().localeCompare(b[column].toString())
    );
  } else if (sorting === 'descending') {
    sortedData.sort(
      (a, b) => b[column].toString() && b[column].toString().localeCompare(a[column].toString())
    );
  } else sortedData = JSON.parse(originalData);

  return sortedData;
};

export const sortNumerically = (
  data: $TSFixMe,
  column: $TSFixMe,
  sorting: $TSFixMe,
  originalData: $TSFixMe
) => {
  let sortedData = [...data];

  if (sorting === 'ascending') sortedData.sort((a, b) => numericalSortWithNA(a, b, column));
  else if (sorting === 'descending') sortedData.sort((a, b) => numericalSortWithNA(b, a, column));
  else sortedData = JSON.parse(originalData);

  return sortedData;
};

export const sortDates = (
  data: $TSFixMe,
  column: $TSFixMe,
  sorting: $TSFixMe,
  originalData: $TSFixMe
) => {
  let sortedData = [...data];

  if (sorting === 'ascending') {
    sortedData.sort((a, b) => new Date(a[column]).getTime() - new Date(b[column]).getTime());
  } else if (sorting === 'descending') {
    sortedData.sort((a, b) => new Date(b[column]).getTime() - new Date(a[column]).getTime());
  } else sortedData = JSON.parse(originalData);

  return sortedData;
};

export const sortStringArray = (
  data: $TSFixMe,
  column: $TSFixMe,
  sorting: $TSFixMe,
  originalData: $TSFixMe
) => {
  let sortedData = [...data];

  const emptyRows = data.filter((row: $TSFixMe) => !row[column] || row[column].length === 0);
  const otherRows = data.filter((row: $TSFixMe) => row[column] && row[column].length > 0);

  if (sorting === 'ascending') {
    otherRows.sort(
      (a: $TSFixMe, b: $TSFixMe) => a[column][0] && a[column][0].localeCompare(b[column][0])
    );
    sortedData = emptyRows.concat(otherRows);
  } else if (sorting === 'descending') {
    otherRows.sort(
      (a: $TSFixMe, b: $TSFixMe) => b[column][0] && b[column][0].localeCompare(a[column][0])
    );
    sortedData = otherRows.concat(emptyRows);
  } else {
    sortedData = JSON.parse(originalData);
  }

  return sortedData;
};

export function getRandomArbitrary(min: $TSFixMe, max: $TSFixMe) {
  return Math.random() * (max - min) + min;
}

export const getRandomIntInclusive = (min: $TSFixMe, max: $TSFixMe) =>
  Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1) + Math.ceil(min)); //The maximum is inclusive and the minimum is inclusive

export function getSavedSearchIDFromURL(pathname: Location['pathname'], isAgency = false) {
  let streamID = '-1';
  if (isAgency) {
    streamID = /^\/company\/live-leads\/search\/[0-9]+/.test(pathname)
      ? pathname.match(/\d+/)?.[0] || '-1'
      : '-1';
  } else {
    streamID = /^\/candidate\/search\/[0-9]+/.test(pathname)
      ? pathname.match(/\d+/)?.[0] || '-1'
      : '-1';
  }

  return Number(streamID);
}

export function checkIfUserIsInvited(profile: CandidateProfile | undefined) {
  const inviteReferrers = ['cohire-invite', 'user-invite'];
  const referrer = getLocalStorageItem('referrer');

  if (profile) return inviteReferrers.includes(profile.referrer!);

  return inviteReferrers.includes(referrer);
}

export function storeReferrerTrackingDetails(queryString: $TSFixMe) {
  const urlParams = new URLSearchParams(queryString);
  const queryParams = getQueryStringObject(queryString);
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  const isUserInvited = checkIfUserIsInvited();
  const hasNoRefferrer = getLocalStorageItem('referrer') === null;
  const referrerDetails = getLocalStorageItem('referrerDetails') || {};

  const returnedFromStripe = urlParams.has('status') && urlParams.has('session_id');
  if (returnedFromStripe) return;

  // add new tracking info to referrerDetails local storage
  if (queryString) {
    Object.assign(referrerDetails, queryParams);
    setLocalStorageItem('referrerDetails', referrerDetails);
  }

  // add source and referrer to referrer local storage
  if (urlParams.has('utm_source') || urlParams.has('invitation_code')) {
    const source = (queryParams as $TSFixMe).utm_source;
    const referrer =
      (queryParams as $TSFixMe).invitation_code || isUserInvited ? 'cohire-invite' : source;
    setLocalStorageItem('referrer', referrer);
  } else if (
    urlParams.has('listing_id') &&
    ['https://www.linkedin.com/', 'android-app://com.linkedin.android/'].includes(
      document?.referrer
    )
  ) {
    // Set referrer to linkedin_position when the referrer is https://www.linkedin.com/ or android-app://com.linkedin.android/  and a listing_id is present in referrer_details
    setLocalStorageItem('referrer', 'linkedin_position');
  } else if (!isUserInvited && hasNoRefferrer && document?.referrer) {
    setLocalStorageItem('referrer', document.referrer);
  }
}

export function setUserInviteReferrer() {
  setLocalStorageItem('referrer', 'user-invite');
}

export function removeReferrerTrackingDetails() {
  removeLocalStorageItem('referrer');
  removeLocalStorageItem('referrerDetails');
}

export function getReferrerTrackingDetails() {
  const referrer = getLocalStorageItem('referrer');
  const referrerDetails = getLocalStorageItem('referrerDetails');

  return { referrer, referrerDetails };
}

export function getInviteCode(queryString: $TSFixMe) {
  let inviteCode = '';

  if (queryString) {
    const queryParams = getQueryStringObject(queryString);
    inviteCode = (queryParams as $TSFixMe).invitation_code || '';
  }

  return inviteCode;
}

export function getEmbeddedURL(videoURL: $TSFixMe) {
  let embeddedURL = '';

  const youtubeTabURL = new RegExp('youtube.com/watch?').test(videoURL);
  const youtubeShareURL = new RegExp('youtu.be/').test(videoURL);
  const youtubeEmbeddedURL = new RegExp('youtube.com/embed/').test(videoURL);
  const vimeoTabandShareURL = new RegExp('https://vimeo.com/').test(videoURL);
  const vimeoEmbeddedURL = new RegExp('player.vimeo.com/video/').test(videoURL);
  const loomShareURL = new RegExp('loom.com/share/').test(videoURL);
  const loomEmbedURL = new RegExp('loom.com/embed/').test(videoURL);

  if (youtubeTabURL) {
    const queryParams = getQueryStringObject(videoURL.split('watch')[1]);
    const videoIdentifier = (queryParams as $TSFixMe)?.v;
    embeddedURL = `https://www.youtube.com/embed/${videoIdentifier}`;
  } else if (youtubeShareURL) {
    const videoIdentifier = videoURL.split('youtu.be/')[1].split('&')[0];
    embeddedURL = `https://www.youtube.com/embed/${videoIdentifier}`;
  } else if (youtubeEmbeddedURL) {
    embeddedURL = videoURL;
  } else if (vimeoTabandShareURL) {
    const videoIdentifier = videoURL.split('vimeo.com/')[1].split('&')[0];
    embeddedURL = `https://player.vimeo.com/video/${videoIdentifier}`;
  } else if (vimeoEmbeddedURL) {
    embeddedURL = videoURL;
  } else if (loomShareURL) {
    const videoIdentifier = videoURL.split('loom.com/share/')[1];
    embeddedURL = `https://www.loom.com/embed/${videoIdentifier}`;
  } else if (loomEmbedURL) {
    embeddedURL = videoURL;
  }

  return embeddedURL || videoURL;
}

/**
 * @description Sorts the autocomplete results based on closest match with keyword, i.e. if keyword is "lead", first results shown is "Lead", "Leadership", "Lead Developer", etc.
 * @param {string} word
 * @param {array} list
 * @param {string} resultsType one of 'string', 'label' or 'tags'
 */
export function sortAutocompleteResults(word: $TSFixMe, list: $TSFixMe, resultsType: $TSFixMe) {
  const sortedList = list.sort((a: $TSFixMe, b: $TSFixMe) => {
    const labelA =
      resultsType === 'label' || resultsType === 'tags' ? a.label.toLowerCase() : a.toLowerCase();
    const labelB =
      resultsType === 'label' || resultsType === 'tags' ? b.label.toLowerCase() : b.toLowerCase();
    const indexOfWordinLabelA = labelA.indexOf(word.toLowerCase());
    const indexOfWordinLabelB = labelB.indexOf(word.toLowerCase());

    if (indexOfWordinLabelA === -1 && indexOfWordinLabelB === -1) return 0;
    //remain unchanged
    else if (indexOfWordinLabelA === -1 && indexOfWordinLabelB > -1) return 1;
    //b comes first
    else if (indexOfWordinLabelB === -1 && indexOfWordinLabelA > -1) return -1;
    //a comes first
    else if (indexOfWordinLabelA < indexOfWordinLabelB) return -1;
    //a comes first
    else if (indexOfWordinLabelA > indexOfWordinLabelB) return 1;
    //b comes first
    else return 0; //remain unchanged
  });

  return sortedList;
}

export function getCandidateNotificationsCount(notifications = {}) {
  // @ts-expect-error TS(2339) FIXME: Property 'companyDeclinedCount' does not exist on ... Remove this comment to see the full error message
  const { companyDeclinedCount, interestCount, unreadConvoMessageCount } = notifications;

  return companyDeclinedCount + interestCount + unreadConvoMessageCount;
}

export function getSelectedCompanyNotificationsCount(notifications: $TSFixMe) {
  if (notifications) {
    const selectedNotifications = notifications.filter(({ selected }: $TSFixMe) => selected);
    const totalNotifications = selectedNotifications.reduce((ac: $TSFixMe, cu: $TSFixMe) => {
      return (
        ac +
        cu.receivedCount +
        cu.unreadConvoMessageCount +
        cu.candidateDeclinedCount +
        cu.unreadInviteBookedCount
      );
    }, 0);

    return totalNotifications;
  }

  return 0;
}

export function getAssociatedCompanyNotificationsCount(notifications: $TSFixMe) {
  if (notifications) {
    // @ts-expect-error
    const associatedNotifications = notifications.filter(is('associated'));
    const totalNotifications = associatedNotifications.reduce((ac: $TSFixMe, cu: $TSFixMe) => {
      return (
        ac +
        cu.receivedCount +
        cu.unreadConvoMessageCount +
        cu.candidateDeclinedCount +
        cu.unreadInviteBookedCount
      );
    }, 0);

    return totalNotifications;
  }

  return 0;
}

export function getMessageNotificationSEOTitle(totalNotifications: $TSFixMe) {
  if (totalNotifications === 0) return '';
  else return `${totalNotifications} message notification${totalNotifications > 1 ? 's' : ''}`;
}

/**
 * @description Recursive function which triggers a given function which if it throws an errors, it retries again X times (retriesLeft) with X delay between each attempt (interval)
 * @param {function} fn
 * @param {integer} retriesLeft
 * @param {integer} interval milliseconds
 * @param {integer} maximumBackoff milliseconds
 *
 * @deprecated Use utils/fn/retry or utils/fn/retryImport instead
 */
export function retry(
  fn: () => Promise<void | { default: ComponentType<any> }>,
  retriesLeft = 7,
  interval = 500,
  maximumBackoff = 24000
) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: $TSFixMe) => {
        setTimeout(() => {
          const newRetriesLeft = retriesLeft - 1;
          // Iincrease the waiting time between retries up to a maximum backoff time, otherwise continue retrying but do not increase the wait period between retries.
          const newInterval = 2 * interval > maximumBackoff ? interval : 2 * interval;

          if (newRetriesLeft === 0) {
            reject(error);
            return;
          }

          retry(fn, newRetriesLeft, newInterval).then(resolve, reject);
        }, interval);
      });
  });
}

/**
 * Returns an object of array of numbers, i.e { livePositionIDs: [1233, 4553], selectedPositionIDs: [4224, 5321]}
 * @param {array} positions
 */
export function getSelectedAndLivePositionIDs(positions: $TSFixMe) {
  const selectedPositions = positions ? positions.filter(({ selected }: $TSFixMe) => selected) : [];
  const livePositions = positions
    ? positions.filter(({ status }: $TSFixMe) => status === 'active')
    : [];

  const livePositionIDs =
    livePositions.length > 0 ? livePositions.map(({ id }: $TSFixMe) => id) : [];
  const selectedPositionIDs =
    selectedPositions.length > 0
      ? selectedPositions.map(({ id }: $TSFixMe) => id)
      : livePositionIDs;

  return { livePositionIDs, selectedPositionIDs };
}

/**
 * @description
 * 1.Parses notifications with the global message category namings,
 * 2.Updates the filters array of objects with the notification count for each category,
 * 3.Returns the new notifications object
 * @param {array} newNotifications
 * @param {array} positionIDs
 * @param {array} conversationFilteredCategories
 */
export function getCompanyCategorisedNotifications(
  newNotifications: $TSFixMe,
  positionIDs: $TSFixMe,
  conversationFilteredCategories: $TSFixMe
) {
  const notifications = {
    incomingRequest: 0,
    candidateDeclined: 0,
    conversationStarted: 0,
    allConversation: 0
  };

  for (const notification of newNotifications) {
    const {
      listingID,
      candidateDeclinedCount,
      receivedCount,
      unreadConvoMessageCount,
      unreadInviteBookedCount
    } = notification;
    const positionIsSelected = positionIDs.includes(listingID);

    if (positionIsSelected) {
      notifications.incomingRequest += receivedCount;
      notifications.candidateDeclined += candidateDeclinedCount;
      notifications.conversationStarted += unreadConvoMessageCount + unreadInviteBookedCount;
      notifications.allConversation +=
        candidateDeclinedCount + unreadConvoMessageCount + unreadInviteBookedCount;
    }
  }

  for (const key in notifications) {
    const category = conversationFilteredCategories.find(
      ({
        // @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
        value
      }) => key === value
    );
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (category) category.notification = notifications[key];
  }

  return { notifications, conversationFilteredCategories };
}

export function saveMessageAsDraft(message: $TSFixMe, messageParams = {}) {
  // @ts-expect-error TS(2339) FIXME: Property 'listingID' does not exist on type '{}'.
  const { listingID, userID } = messageParams;

  if (listingID && userID) setLocalStorageItem(`draft_message_${listingID}_${userID}`, message);
}

export function removeDraftMessage(messageParams = {}) {
  // @ts-expect-error TS(2339) FIXME: Property 'listingID' does not exist on type '{}'.
  const { listingID, userID } = messageParams;

  if (listingID && userID) removeLocalStorageItem(`draft_message_${listingID}_${userID}`);
}

export function passwordRequirementsChecker(password: $TSFixMe) {
  return [
    { label: 'Min. 8 characters', check: password.length >= 8 },
    { label: 'Uppercase', check: /[A-Z]/g.test(password) },
    { label: 'Lowercase', check: /[a-z]/g.test(password) },
    { label: 'Number', check: /[0-9]/g.test(password) }
  ];
}

export function passwordMeetsRequirements(passwordRequirements: $TSFixMe) {
  return passwordRequirements.reduce((ac: $TSFixMe, cu: $TSFixMe) => {
    return ac && cu.check;
  }, true); // valid if all checks are true
}

export function updateTabInformation(ico: $TSFixMe, title: $TSFixMe) {
  const link = document.createElement('link');
  link.rel = 'shortcut icon';
  link.href = ico;
  document.title = title;

  document.getElementsByTagName('head')[0].appendChild(link);
}

export function setNotificationsOnTab(notifications: $TSFixMe, pathname = '') {
  const pageName = capitalizeSentences(pathname.split('/')[2]);
  const ico = notifications > 0 ? NotificationMark : Mark;
  const title =
    notifications > 0
      ? `${getMessageNotificationSEOTitle(notifications)} - cord`
      : pageName
      ? `${pageName} - cord`
      : 'cord';

  updateTabInformation(ico, title);
}

export function setHotjarEvent(eventName: string) {
  try {
    // @ts-expect-error TS(2339) FIXME: Property 'hj' does not exist on type 'Window & typ... Remove this comment to see the full error message
    window?.hj?.('event', eventName);
  } catch (error) {
    console.log('Failed to track Hotjar event: ', error);
  }
}

export function isArrayEqual<T = any>(arrA: T[] = [], arrB: T[] = []) {
  const uniqueItems = arrA && arrB ? unique(arrA.concat(arrB)) : [];

  // TODO: Fix if arrays have duplicates already but they are equal, it will evaluate to false
  if (
    arrA &&
    arrB &&
    arrA.length === arrB.length &&
    arrA.length === uniqueItems.length &&
    arrB.length === uniqueItems.length
  ) {
    return true;
  }
  return false;
}

export function arrayIncludesSomeOptions(arrA = [], arrB = []) {
  let includes = false;

  if (!isEmpty(arrA) && !isEmpty(arrB)) {
    arrB.forEach(i => {
      if (arrA.includes(i)) includes = true;
    });
  }

  return includes;
}

export function arrayIncludesAllOptions(arrA = [], arrB = []) {
  let includedItems = [];

  if (!isEmpty(arrA) && !isEmpty(arrB)) includedItems = arrA.filter(i => arrB.includes(i));

  return arrB.length === includedItems.length;
}

export function getNextTourBubblePage(bubbles: $TSFixMe, bubbleName: $TSFixMe) {
  const nextBubbles = bubbles.filter(({ name }: $TSFixMe) => name !== bubbleName);

  if (nextBubbles && nextBubbles.length > 0) return nextBubbles[0].page;
  else return '';
}

export function playNotificationSound(soundURL: $TSFixMe) {
  const audio = new Audio(soundURL || fetcher.notificationSounds[0].value);

  if (audio) audio.play();
}

export function prettyTextFromArray(arr: string[], connector = 'and') {
  const arrayCopy = arr ? [...arr] : [];

  if (arrayCopy.length === 0) return '';
  else if (arrayCopy.length > 2) {
    const lastItem = arrayCopy.pop();

    return `${arrayCopy.join(', ')} ${connector} ${lastItem}`;
  } else return arrayCopy.join(` ${connector} `);
}

export function getSelectedSeniorityLabels(seniorities: $TSFixMe) {
  const seniorityOptions = fetcher.getSeniorityOptions();

  return seniorities
    ? seniorityOptions.filter(({ value }) => seniorities.includes(value)).map(({ label }) => label)
    : [];
}

export function getSeniorityRange(seniorities = []) {
  const seniorityLabels = getSelectedSeniorityLabels(seniorities);

  if (seniorityLabels.length === 0) return '';
  else if (seniorityLabels.length === 1) return seniorityLabels[0];
  return `${seniorityLabels[0]}-${seniorityLabels[seniorityLabels.length - 1]}`;
}

export const getSalaryRange = (
  min = 0,
  max = 1,
  currency = Currency.GBP,
  salaryVisible?: $TSFixMe,
  seniorities?: $TSFixMe,
  format: 'short' | 'long' = 'long'
) => {
  if ((salaryVisible === false || min === null || max === null) && seniorities) {
    return getSelectedSeniorityLabels(seniorities).join(', ');
  } else if (salaryVisible === false || min === null || max === null) {
    return 'Undisclosed';
  }

  return getVisibleSalaryRange({ currency, salaryMin: min, salaryMax: max, format });
};

/**
 * @description returns true if user scrolled 80% of the page
 * @returns {boolean}
 */
export function checkIfUserApproachedEndOfPage(offset = 0) {
  const pageHeight = window.innerHeight;
  const scrollPosition = window.scrollY;
  const scrollHeight = document.body.offsetHeight;

  return pageHeight + scrollPosition + offset >= (scrollHeight * 80) / 100;
}

/**
 * @description Combines all continuous ranges into a single one
 * @param {array} selectedSizes array of strings
 * @returns {array}
 */
export function getContinuousCompanySizeRange(selectedCompanySizes: string[] = []) {
  const selectedSizes = structuredClone(selectedCompanySizes);
  if (selectedSizes && selectedSizes.length > 0) {
    // sort in ascending order
    const sortedSizes = selectedSizes.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    // parse to matrix
    const sizesMatrix = sortedSizes.map(size => (size as $TSFixMe).split(' - '));
    const continuousRangeMatrix = [];
    let continuousRangeStrings: $TSFixMe = [];

    for (const range of sizesMatrix) {
      const lastIndex = continuousRangeMatrix.length - 1;
      const firstNumber = range[0] ? parseInt(range[0], 10) : 0;
      const lastSelectedNumber =
        continuousRangeMatrix.length > 0 ? parseInt(continuousRangeMatrix[lastIndex][1], 10) : 0;

      if (continuousRangeMatrix.length === 0) continuousRangeMatrix.push(range);
      // if number is continuous or equal to the last selected one
      else if (firstNumber - 1 === lastSelectedNumber || firstNumber === lastSelectedNumber) {
        continuousRangeMatrix[lastIndex][1] = range[1] || range[0];
      } else continuousRangeMatrix.push(range);
    }

    // parse back to string ranges
    if (continuousRangeMatrix.length) {
      continuousRangeStrings = continuousRangeMatrix.map(arr => {
        if (arr[1]) return `${arr[0]} - ${arr[1]}`;
        return `${arr[0]}`;
      });
    }

    return continuousRangeStrings;
  }

  return [];
}

export const populateLocationList = (locationLabel: LocationLabel) => {
  switch (locationLabel) {
    case 'Anywhere':
      return [];
    case 'Continent/Country':
      return [...fetcher.getContinents(), ...COUNTRIES];
    default:
      break;
  }
};

export const getCompanyLocationLabel = (
  remoteLocationContinents: $TSFixMe,
  remoteLocationCountries: $TSFixMe
): LocationLabel => {
  if (
    remoteLocationContinents &&
    remoteLocationCountries &&
    remoteLocationContinents.length + remoteLocationCountries.length === 0
  ) {
    return 'Anywhere';
  } else {
    return 'Continent/Country';
  }
};

export const getRemoteLocations = (
  remoteLocationContinents: $TSFixMe,
  remoteLocationCountries: $TSFixMe
) => {
  if (!remoteLocationCountries || !remoteLocationContinents) return '';

  const remoteLocations = remoteLocationContinents.concat(remoteLocationCountries);
  if (remoteLocations.length === 0) {
    return 'Anywhere';
  } else if (remoteLocations.length === 1) {
    return `within ${remoteLocations}`;
  } else {
    return `${remoteLocations.join(', ')}`;
  }
};

export const isExclusivelyRemote = (remote: RemoteOption[] = []) => {
  if (remote) return remote.includes(RemoteOption.Remote) && remote.length === 1;
  return false;
};

export const getRemoteLocationsLabel = (
  remote: $TSFixMe,
  remoteLocationContinents: $TSFixMe,
  remoteLocationCountries: $TSFixMe
) => {
  const hasRemoteOption = remote.includes(RemoteOption.Remote);

  if (!hasRemoteOption) return '';

  if (!remoteLocationCountries || !remoteLocationContinents) {
    return 'Remote anywhere';
  }

  const remoteLocations = remoteLocationContinents.concat(remoteLocationCountries);

  if (remoteLocations.length === 0) return 'Remote anywhere';
  else if (remoteLocations.length === 1) return `Remote in ${setAcronym(remoteLocations[0])}`;
  else return `Remote in ${setAcronym(remoteLocations[0])} +${remoteLocations.length - 1}`;
};

/**
 * @param {array} visaDetails of objects [{ origin: 'EU residents', destination: 'UK' }]
 * @returns {array} of strings ['eu_to_uk']
 */
export const convertVisaObjectToCode = (visaDetails = []) => {
  if (!isEmpty(visaDetails)) {
    const visaCodes = visaDetails
      .filter(({ origin, destination }) => origin && destination)
      .map(({ origin, destination }) => fetcher.visaDetailsByOrigin[origin][destination]);
    return unique(visaCodes);
  }

  return [];
};

/**
 * @param {array} visaCodes of strings ['eu_to_uk']
 * @returns {array} of objects [{ origin: 'EU residents', destination: 'UK' }]
 */
export const convertVisaCodeToObject = (visaCodes = []) => {
  if (!isEmpty(visaCodes)) {
    const deDupeCodes = unique(visaCodes).filter(code => !!code);
    return deDupeCodes.map(code => fetcher.visaDetailsByCode[code]);
  }

  return [];
};

export const getVisaOptions = (visaAvailable: $TSFixMe, visaCodes: $TSFixMe) => {
  if (visaAvailable && isEmpty(visaCodes)) return 'Available';
  if (visaAvailable && !isEmpty(visaCodes)) {
    const visaDetails = convertVisaCodeToObject(visaCodes);
    return visaDetails
      .map(
        ({ origin, destination }) =>
          `For ${
            origin === 'Everyone' ? 'everyone' : `${origin} residents`
          } relocating to ${destination}`
      )
      .join(', ');
  } else return 'Not available';
};

export const parseTimezoneObject = (timezones = []) => {
  const parsedTimezones = timezones
    .filter(({ baseCity }) => baseCity !== '')
    .map(({ baseCity, timeOffset, id }) => {
      return { baseCity, timeOffset, id };
    });

  return parsedTimezones;
};

export const getPWADisplayMode = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)')?.matches || false;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa'; //trusted web app
  } else if ((navigator as $TSFixMe).standalone || isStandalone) {
    return 'standalone'; //pwa
  }
  return 'browser';
};
export const goToBookDemoLink = ({
  companySize = 0,
  demoCompany = false
}: {
  companySize?: number;
  demoCompany?: boolean;
}) => {
  if (demoCompany) {
    window.open(consts.SELF_SERVE_DEMO_LINK, '_blank');
  } else if (companySize < 25 && companySize > 0) {
    window.open(consts.FOUNDERS_BOOK_DEMO_LINK, '_blank');
  } else if (companySize > 500) {
    window.open(consts.ENTERPRISE_BOOK_DEMO_LINK, '_blank');
  } else window.open(consts.GENERIC_BOOK_DEMO_LINK, '_blank');
};

export const goToMasterclassWebinar = (locationInProduct: string) => {
  window.open(consts.MASTERCLASS_WEBINAR_LINK, '_blank');
  trackWebinarLinkClicks({
    type: 'streams_masterclass',
    url: consts.MASTERCLASS_WEBINAR_LINK,
    locationInProduct
  });
};

/**
 * @description Scroll to the element on the page
 * @param {string} hash location hash
 * @param {string} search location search
 * @param {object} queryStringObject
 * @param {string} queryStringObject.paramKey
 * @param {string} queryStringObject.paramValue
 * @param {string} queryStringObject.id
 */
export const scrollToSection = (
  hash: Location['hash'] = '',
  search?: Location['search'],
  queryStringObject = { paramKey: '', paramValue: '', id: '' }
) => {
  let id = '';
  const urlParams = search ? getQueryStringObject(search) : null;

  if (
    !isEmpty(urlParams) &&
    !isEmpty(queryStringObject) &&
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    urlParams[queryStringObject.paramKey] === queryStringObject.paramValue
  ) {
    id = queryStringObject?.id;
  } else if (hash) id = hash.replace('#', '');

  const element = document.getElementById(id);

  if (id && element) element.scrollIntoView({ block: 'center' });
};

export const incrementTextVersion = (str: $TSFixMe) => {
  let copyNumber = 1;
  let newSearchName = str;
  const number = /\((\d+)\)$/gm.exec(str);
  if (number) {
    copyNumber = parseInt(number[1]) + 1;
    const temp = str.split(' ');
    temp.pop();
    newSearchName = temp.join(' ');
  }
  return `${newSearchName} (${copyNumber})`;
};

export const pluralise = (
  count: number,
  singularlabel: string,
  pluralLabel: string,
  showCount = true
) => {
  const formattedNumber = isNumber(count) ? formatNumber(count) : count;
  return `${showCount ? `${formattedNumber} ` : ''}${count === 1 ? singularlabel : pluralLabel}`;
};

export const convertArgumentsToArray = (...args: $TSFixMe[]) => [...args].filter(arg => arg);

export const getMonthsOfExperience = (seniorities: Seniority[]) => {
  // Google job postings can't accept 0
  if (seniorities.includes(Seniority.Entry)) return 1;
  else if (seniorities.includes(Seniority.Junior)) return 12;
  else if (seniorities.includes(Seniority.Mid)) return 36;
  else if (seniorities.includes(Seniority.Senior)) return 60;
  else if (seniorities.includes(Seniority.Lead)) return 84;
  else if (seniorities.includes(Seniority.Leadership)) return 120;
  return 1;
};

export const getLabelFromGroupedOptions = (
  groupedListOptions: $TSFixMe,
  selectedValue: $TSFixMe
) => {
  return (
    groupedListOptions
      .map(
        ({ options }: $TSFixMe) =>
          options.find(({ value }: $TSFixMe) => value === selectedValue)?.label
      )
      ?.find((item: $TSFixMe) => item !== undefined) || ''
  );
};

export const parseSearchItemsIntoTags = async (
  searchItems: $TSFixMe,
  viewType: $TSFixMe,
  isHiringInsights: boolean
) => {
  if (isInsightsType(viewType)) {
    const allInsightsTags = await fetcher.getInsightsTags(isHiringInsights);

    const isFilterNotAllowed = (attribute: $TSFixMe) =>
      attribute !== 'topic' && attribute !== 'keyword' && attribute !== 'sortBy';

    const parsedFilters = searchItems.map(({ attribute, ...rest }: $TSFixMe) => {
      return { attribute: isFilterNotAllowed(attribute) ? 'tags' : attribute, ...rest };
    });

    const keywordsConvertedToTags: $TSFixMe = [];

    parsedFilters.map(
      ({ attribute, value }: $TSFixMe) =>
        attribute === 'keyword' &&
        allInsightsTags.includes(capitalizeWords(value)) &&
        keywordsConvertedToTags.push({ attribute: 'tags', value: capitalizeWords(value) })
    );

    return [...parsedFilters, ...keywordsConvertedToTags];
  }

  return searchItems;
};

export const getFullName = (firstname: string, lastname: string) => {
  if (!firstname && !lastname) return 'Anonymous';
  if (!firstname) return lastname;
  return lastname ? `${firstname} ${lastname}` : firstname;
};

export const getCountryText = (
  country: { label: string; value: OperatingCountry },
  key: 'label' | 'value'
) => (country.value === OperatingCountry.EU ? country[key] : `the ${country[key]}`);

export const formatSalary = (salary: number, currency: string | '£' | '$' | '€') =>
  `${currency}${Math.round(salary / 1000)}K`;
export const getReportReasonsText = (reasons: string[]) => {
  if (!reasons || isEmpty(reasons)) return '';

  const reportLabels = reasons.map(
    value => `'${REPORT_REASON_OPTIONS.find(reason => value === reason.value)?.label}'`
  );

  return prettyTextFromArray(reportLabels);
};

export const getBlockedMessage = (
  isCompanyUser: number | undefined,
  companyHasBlocked = false,
  candidateHasBlocked = false,
  companyName = '',
  candidateName = ''
) => {
  if (isCompanyUser) {
    if (companyHasBlocked) return `You have blocked ${candidateName}.`;
    if (candidateHasBlocked) return `You have been blocked by ${candidateName}.`;
  }

  if (candidateHasBlocked) return `You have blocked ${companyName}.`;
  if (companyHasBlocked) return `You have been blocked by ${companyName}.`;

  return '';
};

export const getProgress = (completedLength = 0, totalLength = 0) => {
  if (completedLength > totalLength) return 100;
  return (completedLength / totalLength) * 100;
};

const PERSONAL_EMAIL_PROVIDERS = [
  'gmail',
  'yahoo',
  'hotmail',
  'outlook',
  'aol',
  'icloud',
  'mail',
  'protonmail',
  'zoho',
  'yandex',
  'gmx',
  'live',
  'msn',
  'googlemail',
  'qq',
  '163',
  '126',
  'me',
  'mac',
  'sina',
  'ymail',
  'rocketmail',
  'inbox',
  'fastmail',
  'btinternet',
  'sky',
  'virginmedia',
  'talktalk',
  'orange',
  'web',
  'libero',
  'comcast',
  'verizon'
];

// Note that this function assumes that email has been validated with emailFormat
export const verifyWorkEmailNaive = (email: string): boolean => {
  const domain = email.split('@')[1].toLowerCase();
  const mainDomainPart = domain.split('.')[0];

  return !PERSONAL_EMAIL_PROVIDERS.includes(mainDomainPart);
};

export const getEmailCheckMessage = async (
  email: string,
  validateEmailKey: keyof typeof validateEmailAddress,
  emailValidationContext?: EmailValidationContext
) => {
  if (!emailFormat.test(email)) return 'Email address is not valid.';

  if (validateEmailKey === 'companyLiveDemo' && !verifyWorkEmailNaive(email)) {
    return 'Please enter your work email.';
  }

  const type = validateEmailAddress[validateEmailKey] ? 'ev' : '';
  const result = await fetcher.checkEmail(email, type, emailValidationContext);

  if (result.status === 'failure') return "We couldn't validate your email. Please try again.";

  const { exists, valid } = result.data;

  if (exists) return 'Email has already been registered.';
  if (validateEmailAddress[validateEmailKey] && !valid) return 'Email address is not valid.';
  return '';
};

export const getElementOffset = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return {
    bottom: window.scrollY - rect.bottom,
    top: rect.top + window.scrollY
  };
};

export const scrollToElementInList = (
  listElementID: string,
  elementID: string,
  direction: 'top' | 'bottom'
) => {
  const dropdownList = document.getElementById(listElementID);
  const item = document.getElementById(elementID);
  if (!item || !dropdownList) return;

  let itemYPosition = item.offsetTop;
  const isValidOffset =
    getElementOffset(item)[direction] < getElementOffset(dropdownList)[direction];
  if (isValidOffset) {
    itemYPosition += direction === 'bottom' ? item.offsetHeight - dropdownList.offsetHeight : 0;
    dropdownList.scrollTop = itemYPosition;
  }
};

// Todo: Is deprecated?
export const locationLabelToAbbreviationMap: { [key: string]: string } = {
  'London, United Kingdom': 'UK',
  'New York, United States': 'US'
};

// Todo: Is deprecated?
export const abbreviationToLocationLabelMap: { [key: string]: string } = {
  UK: 'London, United Kingdom',
  US: 'New York, United States'
};

export const setLocalisation = (country: OperatingCountry | '') => {
  const dayMilliseconds = getMillisecondsByType('days');

  setLocalStorageItem('country', country, 30 * dayMilliseconds);
  setI18nLanguageByCountry(country);
};

/**
 * @description Check if login token exists in query params and use to log in the candidate
 * @fires onLoginStatusChange if API successfully returns a role
 */
export const checkForLoginToken = async (
  location: Location,
  history: History,
  onLoginStatusChange: () => Promise<void>
) => {
  const userType = getLocalStorageItem('role');
  const queryParams = getQueryStringObject(location?.search || '');

  if (queryParams?.token && !userType) {
    const { status } = await fetcher.candidateLoginUsingToken(queryParams.token);

    if (status === 'success') await onLoginStatusChange?.();
    else history?.push({ pathname: location?.pathname || '', search: '' }); // clear query params if token is invalid
  }
};

export const commify = (num: string | number) =>
  (num && `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || '';

export const getVariantID = (experimentID: string) => {
  return (window as $TSFixMe)?.gaData?.['UA-87272922-3']?.experiments?.[experimentID];
};

export const isInViewport = (element: HTMLElement, verticalOffset = 0) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight - verticalOffset || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export function checkIfSourceReferrerExists(search: string, source: string) {
  const { referrer } = getReferrerTrackingDetails();
  const { utm_source } = (getQueryStringObject(search) as { [key: string]: string }) || {};

  return utm_source === source || referrer === source;
}

export function isPublicUser(profile: CandidateProfile | CompanyProfile) {
  return !profile?.isCandidate && !(profile as CompanyProfile)?.companyID;
}

export function checkIfWeekend(date?: string) {
  const dateString = date ? new Date(date) : new Date();
  const day = dateString.getDay();

  return day === 6 || day === 0;
}

export function checkIfFridayToMonday(date?: string) {
  const dateString = date ? new Date(date) : new Date();
  const day = dateString.getDay();

  return (day >= 0 && day <= 1) || (day >= 5 && day <= 6);
}
