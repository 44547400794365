import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { CompanySubscriptionPlan, Frequency } from '@cohiretech/common-types';

import { V3PricingOptions, PricingCountry } from 'types';
import { checkIfSubscriptionExpired } from 'utils/companyUtils';
import { getV3BasicPrice } from 'utils/pricingUtils';
import media from 'styles/media';

import Button, { Props as ButtonProps } from 'components/button';
import Table from 'components/table';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

import { getButtonLink, getActivateButtonProps } from '../helper';

import { getAllFeatures } from './helper';
import FeatureList from './featurelist';

type Props = {
  pricingCountry: PricingCountry;
  currentPlan?: V3PricingOptions | CompanySubscriptionPlan.Basic | CompanySubscriptionPlan.Expired;
  darkMode: boolean;
  showSourcingPopup: () => void;
  showNewPositionCount: boolean;
};

const FeatureComparisonTable = forwardRef<HTMLDivElement, Props>(
  ({ pricingCountry, currentPlan, darkMode, showSourcingPopup, showNewPositionCount }, ref) => {
    const allFeatures = getAllFeatures(darkMode, showSourcingPopup, showNewPositionCount);
    const price = getV3BasicPrice(pricingCountry);
    const isPlanExpired = checkIfSubscriptionExpired(currentPlan);

    const getButtonProps = (planID: V3PricingOptions): ButtonProps => {
      if (currentPlan) {
        const currentPlanBtnProps = { text: 'Your current plan', disabled: true };

        if (planID === currentPlan) return currentPlanBtnProps;
        // All the Growth plans
        if (
          currentPlan !== V3PricingOptions.V3Unlimited &&
          planID === V3PricingOptions.V3Growth20
        ) {
          return currentPlan === CompanySubscriptionPlan.Basic
            ? getActivateButtonProps(planID, Frequency.monthly, pricingCountry)
            : currentPlanBtnProps;
        }
      }

      return { link: getButtonLink(planID, pricingCountry) };
    };

    // WIP - After release: [Refactoring] Use map to render <th>
    return (
      <FeaturesTable containerRef={ref} highlightRowOnHover={false} dark={darkMode}>
        <thead>
          <tr>
            <th />
            <th className="text_center">
              <div className="plan_price">Starting at {price}/mo</div>
              <div className="plan_title">Growth</div>
              <ConditionalRender predicate={!isPlanExpired}>
                <Button
                  className="eighteen_px_font"
                  text="Start your trial"
                  {...getButtonProps(V3PricingOptions.V3Growth20)}
                />
              </ConditionalRender>
            </th>
            <th className="text_center">
              <div className="plan_price">Custom pricing</div>
              <div className="plan_title">Customised</div>
              <ConditionalRender predicate={!isPlanExpired}>
                <Button
                  className="eighteen_px_font"
                  text="Get in touch"
                  buttonStyle="stroke_blue"
                  {...getButtonProps(V3PricingOptions.V3Unlimited)}
                />
              </ConditionalRender>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(allFeatures).map(([key, featureList]) => (
            <FeatureList key={key} {...featureList} />
          ))}
        </tbody>
      </FeaturesTable>
    );
  }
);

export default FeatureComparisonTable;

const FeaturesTable = styled(Table)<{ dark: boolean }>`
  max-width: 945px;
  margin: 72px auto 0;

  th {
    padding: 8px 16px;
    border-bottom: 0;

    &:first-child {
      min-width: 235px;
    }

    .plan_price {
      font-weight: ${typography.regular};
    }

    .plan_title {
      margin: 4px auto 16px;
    }

    ${media.tabletLandscapeMin`
      &:not(:first-child), .button {
        width: 210px;
      }
    `}
  }

  tbody {
    border-bottom: 0;

    td {
      border: 1px solid;
      border-right-color: ${({ dark }) => (dark ? 'rgba(49, 71, 95, 0.4)' : colours.lightBgColour)};
      border-bottom-color: ${({ dark }) => (dark ? colours.fontColour : colours.lightBgColour)};

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
`;
