import React, { lazy, Suspense } from 'react';
import ClassNames from 'classnames';
import CountUp from 'react-countup';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { RootState, selectDarkMode } from 'store';
import {
  debounce,
  retry,
  getInviteCode,
  isProfileURL,
  removeReferrerTrackingDetails,
  formatNumber,
  getPercentage,
  checkIfUserIsInvited,
  setUserInviteReferrer
} from 'utils';
import { capitalizeSentences } from 'utils/string';
import { getPageTrackingContext, trackSignupClicks } from 'tracking-utils';
import {
  lauraJobTitles,
  lauraSeniorities,
  getCandidateInviteDetails,
  getPublicCompanyStats,
  searchPeople,
  getTestimonials,
  candidateSearchFilters
} from 'fetcher';
import { search } from 'v2/services/fetchers/candidate/search';
import { isMobileWidth, isTabletLandscapeWidth } from 'responsiveConfig';
import { CORD_MARK } from 'consts';

import CompanySection from 'views/landing/forpeople/companysection';
import ReviewsSection from 'views/landing/forpeople/reviewssection';

import ArrowLink from 'components/arrowlink';
import Button from 'components/button';
import DataSection from 'components/datasection';
import ListingSlider from 'components/listingslider';
import Loader from 'components/loader';
import NotificationBar from 'components/notificationbar';
import PageSection from 'components/pagesection';
import PeopleBubbles from 'components/peoplebubbles';
import Seo from 'components/seo';
import VideoCarousel from 'components/videocarousel';

import MessagingBG from 'images/messaging-bg.svg';
import StartConversations from 'images/start-conversations.svg';
import SendMessages from 'images/send-receive-messages.svg';
import BookInterviews from 'images/book-interviews.svg';

import './styles.scss';

// @ts-expect-error TS(2322) FIXME: Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
const MockMessenger = lazy(() => retry(() => import('components/mockmessenger')));

type State = $TSFixMe;

class InvitePage extends React.Component<{} & RouteComponentProps, State> {
  constructor(props: {} & RouteComponentProps) {
    super(props);

    const location = (props as $TSFixMe).location;
    const invitePage = location.pathname === '/invited';
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    const isUserInvited = checkIfUserIsInvited();

    if (invitePage && location.search === '') this.appendQueryParam('?utm_source=LinkedinInvite');
    else if (isUserInvited) this.appendQueryParam('&utm_source=Invite');

    this.state = {
      trustpilotReviews: 1035,
      trustpilotScore: '4.8',
      people: [],
      positions: [],
      videoTestimonials: [
        {
          videoTestimonialURL: 'https://www.youtube.com/embed/tu73kfQk5bM',
          id: 0,
          photoURL: CORD_MARK,
          title: "Watch other people's stories using cord"
        }
      ],
      companyStats: null,
      inviteDetails: {},
      inviteDetailsLoaded: false,
      inviteError: '',
      invitePage,
      showInviteBar: false
    };

    this.onScroll = debounce(this.onScroll.bind(this), 100);
  }

  appendQueryParam(search: $TSFixMe) {
    const { history, location } = this.props;

    if (!location?.search.includes(search)) {
      history.push({ search: `${location?.search || ''}${search}` });
    }
  }

  /* istanbul ignore next */
  onScroll() {
    const { showInviteBar } = this.state;
    const pageYOffset = window.pageYOffset;

    if (pageYOffset >= 100 && !showInviteBar) this.setState({ showInviteBar: true });
    else if (pageYOffset < 100 && showInviteBar) this.setState({ showInviteBar: false });
  }

  async getImportantData() {
    const { location, history } = this.props;
    const inviteCode = getInviteCode(location.search);

    const { data: inviteDetails, message }: $TSFixMe = inviteCode
      ? await getCandidateInviteDetails(inviteCode)
      : { data: {} };

    const inviteError = capitalizeSentences(message) || '';
    const remote = inviteDetails?.payload?.remote || null;
    const inviteeDetails = inviteDetails?.payload || null;
    const jobTitles = inviteeDetails?.jobTitles || lauraJobTitles;
    const seniorities = inviteeDetails?.seniorities || lauraSeniorities;
    const searchLocation = inviteeDetails?.location || '';
    const parsedJobTitles =
      (jobTitles.length &&
        jobTitles.map((item: $TSFixMe) => {
          if (item.value) return item.value;
          else return item;
        })) ||
      [];

    if (!inviteError) inviteDetails.jobTitles = parsedJobTitles;

    const { values } = await searchPeople(0, seniorities, parsedJobTitles, remote);

    if (inviteError) {
      removeReferrerTrackingDetails();
      history.push({ pathname: location.pathname, search: '' });

      this.setState({ people: values });
    } else {
      if (inviteDetails?.taggingLevelOne === 'CI') setUserInviteReferrer();
      if (inviteeDetails) {
        this.setAdConversion();
        this.loadPositions(parsedJobTitles, seniorities, remote, searchLocation);
      }

      this.setState({ inviteDetails, inviteDetailsLoaded: true, inviteError, people: values });
    }
  }

  async loadPositions(
    jobTitles: $TSFixMe,
    seniorities: $TSFixMe,
    remote: $TSFixMe,
    searchLocation: $TSFixMe
  ) {
    const searchFilters = [
      {
        attribute: candidateSearchFilters.sortBy.attribute,
        label: 'Most responsive',
        value: 'response_rate'
      }
    ];

    jobTitles.map((jt: $TSFixMe) =>
      searchFilters.push({
        label: jt,
        value: jt,
        attribute: candidateSearchFilters.jobTitle.attribute
      })
    );
    seniorities.map((s: $TSFixMe) =>
      searchFilters.push({
        label: s,
        value: s,
        attribute: candidateSearchFilters.seniority.attribute
      })
    );
    if (remote) {
      searchFilters.push({
        label: 'Remote',
        value: remote,
        attribute: candidateSearchFilters.remoteWorking.attribute
      });
    }
    if (searchLocation) {
      searchFilters.push({
        label: searchLocation,
        value: searchLocation,
        attribute: candidateSearchFilters.location.attribute
      });
    }

    const { values } = await search({
      searchItems: searchFilters,
      passiveSearch: true,
      publicSearch: true
    });

    this.setState({ positions: values });
  }

  componentDidMount() {
    this.getDynamicData();

    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  async getDynamicData() {
    const videoTestimonials = [...this.state.videoTestimonials];

    this.getImportantData();

    const [stats, videos] = await Promise.all([
      getPublicCompanyStats(),
      // @ts-expect-error TS(2345) FIXME: Argument of type '"candidate"' is not assignable t... Remove this comment to see the full error message
      getTestimonials('candidate', 0, 'video')
    ]);

    const companyStats = stats?.data || null;
    const trustpilotReviews = companyStats?.trustpilotReview || 3000;
    const trustpilotScore = companyStats?.trustpilotScore || 4.8;

    this.setState({
      companyStats,
      trustpilotReviews,
      trustpilotScore,
      videoTestimonials: videoTestimonials.concat(videos?.values || [])
    });
  }

  setAdConversion() {
    // Google tracking snippet
    window.gtag('event', 'conversion', { send_to: 'AW-649514007/ttwbCIbEzu8BEJeY27UC' });

    // Facebook tracking snippet
    (window as $TSFixMe).fbq('track', 'Lead');
  }

  renderInvitationGreeting(payload: $TSFixMe) {
    const { inviteDetails, inviteError } = this.state;
    const inviteeName = payload?.firstname || '';

    if (payload) {
      const welcomeMessage =
        payload?.inviter === 'developertea'
          ? 'Hello, welcome to cord!'
          : `Hi ${inviteeName}, ${
              payload?.inviter || inviteDetails.inviterName
            } invited you to join cord`;
      return <div className="invitation_title">{welcomeMessage}</div>;
    } else if (inviteError) {
      return (
        <div className="invitation_title">
          {inviteError === 'Invite code expired' ? (
            <>
              Your invite link has expired.{' '}
              <Link className="lightblue_link" to="/signup">
                Get started
              </Link>
            </>
          ) : (
            inviteError
          )}
          .
        </div>
      );
    } else return null;
  }

  renderTopSectionContent(payload: $TSFixMe, isUserInvited: $TSFixMe) {
    const { people, companyStats, inviteDetails } = this.state;
    const { location } = this.props;
    const peopleShown =
      people.length > 0 ? people.filter(({ photoURL }: $TSFixMe) => photoURL).slice(0, 4) : [];

    switch (inviteDetails?.endpoint) {
      case 'conversationsstarted':
        return (
          <div className="page_view center_alignment">
            {this.renderInvitationGreeting(payload)}
            <h1 className="heading">
              {companyStats?.convo ? (
                <CountUp
                  end={companyStats?.convo}
                  start={33100}
                  formattingFn={value => formatNumber(value)}
                  duration={5}
                />
              ) : (
                33100
              )}
              <br /> conversations started
            </h1>
            <h2 className="subheading min_extended_view">
              cord is the job search platform that gives you direct access to hiring teams at
              technology companies in the UK, USA and remote across Europe
            </h2>
            <Button
              text="Activate your invite"
              icon="icon_arrow_right"
              link="/signup"
              className="key_cta"
              iconPosition="right"
              action={() => this.trackSignupClicksWithContext('Main CTA')}
            />
            {peopleShown.length > 0 && !isTabletLandscapeWidth && (
              <PeopleBubbles people={peopleShown} location={location} />
            )}
          </div>
        );
      default:
        return (
          <div className="page_view center_alignment">
            {this.renderInvitationGreeting(payload)}
            <h1 className="heading">Direct message{isMobileWidth ? ' ' : <br />}people hiring</h1>
            <h2 className="subheading min_extended_view">
              cord is the job search platform that gives you direct access to hiring teams at
              technology companies in the UK, USA and remote across Europe
            </h2>
            <Button
              text={isUserInvited ? 'Activate your invite' : 'Get started'}
              icon="icon_arrow_right"
              link="/signup"
              className="key_cta"
              iconPosition="right"
              action={() => this.trackSignupClicksWithContext('Main CTA')}
            />
            {peopleShown.length > 0 && !isTabletLandscapeWidth && (
              <PeopleBubbles people={peopleShown} location={location} />
            )}
          </div>
        );
    }
  }

  trackSignupClicksWithContext(label: string) {
    const { location } = this.props;
    trackSignupClicks(label, {}, getPageTrackingContext(location));
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'darkMode' does not exist on type 'Readon... Remove this comment to see the full error message
    const { darkMode, location } = this.props;
    const {
      trustpilotReviews,
      trustpilotScore,
      companyStats,
      people,
      inviteDetails,
      inviteDetailsLoaded,
      videoTestimonials,
      showInviteBar,
      invitePage,
      positions
    } = this.state;
    const { payload = null } = inviteDetails;
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    const isUserInvited = checkIfUserIsInvited();

    return (
      <div id="invite_page" style={{ backgroundImage: `url(${MessagingBG})` }}>
        <div className="intro_wrapper">
          {(payload || isUserInvited) && (
            <NotificationBar
              show={showInviteBar}
              title={<span className="icon_like" />}
              text={[
                "You've been invited to cord. ",
                <a
                  key="link"
                  href="/signup"
                  onClick={() => this.trackSignupClicksWithContext('Activate your invite')}
                >
                  Activate your invite
                </a>
              ]}
            />
          )}
          {this.renderTopSectionContent(payload, isUserInvited)}
        </div>
        {people.length > 0 && (
          <PageSection
            className={ClassNames('people_hiring_section', 'center_alignment', {
              grey_background: !darkMode,
              dark_blue_background: darkMode
            })}
            title="Message people hiring"
            widthClassName="max_view"
          >
            <ListingSlider
              itemType="people_item"
              items={people.slice(0, 10)}
              columns={5}
              showArrows
              {...this.props}
            />
          </PageSection>
        )}
        {payload && positions.length > 2 && (
          <PageSection
            id="positions_section"
            className={ClassNames('center_alignment', {
              grey_background: !isMobileWidth && !darkMode,
              gradient_blue_background: isMobileWidth && !darkMode,
              dark_blue_background: darkMode
            })}
            widthClassName="page_view"
            title={isMobileWidth ? 'Positions for you' : "People hiring positions you'll like"}
            button={{
              text: 'See all positions',
              icon: 'icon_arrow_right',
              iconPosition: 'right',
              className: 'twenty_px_font',
              link: '/signup',
              action: () => this.trackSignupClicksWithContext('See all positions')
            }}
          >
            <ListingSlider
              items={positions ? positions.slice(0, 10) : []}
              itemType="position_item"
              columns={3}
              showVideos
              {...this.props}
            />
          </PageSection>
        )}
        <PageSection className="messaging_section center_alignment" widthClassName="max_view">
          {!isTabletLandscapeWidth && (
            <div id="candidate_message_view" className={ClassNames({ dark: darkMode })}>
              <Suspense fallback={<Loader className={ClassNames('medium', { dark: darkMode })} />}>
                <MockMessenger {...this.props} />
              </Suspense>
            </div>
          )}
          <div className="messaging_tool_details">
            <div>
              <img alt="Send and receive messages" src={SendMessages} height="60" width="65" />
              <h3>Send & Receive Messages</h3>
              <p>
                Message people hiring your skillset in two clicks. Receive messages in under{' '}
                {companyStats ? companyStats.fastestMessage : 22} seconds of signing up.
              </p>
            </div>
            <div>
              <img
                alt="Send and receive messages"
                src={StartConversations}
                height="60"
                width="84"
              />
              <h3>Start Conversations</h3>
              <p>
                Accept a request to start a conversation with the person hiring. Everything happens
                in a simple messaging tool.
              </p>
            </div>
            <div>
              <img alt="Send and receive messages" src={BookInterviews} height="60" width="179" />
              <h3>Book calls & Interviews</h3>
              <p>
                Book all your interviews directly from the messaging tool. Choose your preferred
                date and time and click Confirm.
              </p>
            </div>
          </div>
        </PageSection>
        <ReviewsSection
          // @ts-expect-error TS(2322) FIXME: Type '{ history: History<unknown>; location: Locat... Remove this comment to see the full error message
          trustpilotReviews={trustpilotReviews}
          trustpilotScore={trustpilotScore}
          button={{
            link: '/signup',
            text: 'Join 100,000+ people on cord',
            className: 'twenty_px_font',
            action: () => this.trackSignupClicksWithContext('Join 100,000+ people on cord')
          }}
          {...this.props}
        />
        <PageSection className="gradient_blue_background" widthClassName="page_view">
          <div className="left_cont">
            <h2 className="page_section_title">Get direct access to Founders and Hiring Teams</h2>
            <h3 className="page_section_subtitle">
              Everything is end to end. Messaging is instant and in real-time. No applications and
              no blackholes. All data is live and transparent, including salary, gender diversity,
              tech stack, interview process & response times.
            </h3>
            <ArrowLink
              to="/signup"
              onClick={() => this.trackSignupClicksWithContext('Access hiring teams')}
            >
              Access hiring teams
            </ArrowLink>
          </div>
          <div className="right_cont">
            <VideoCarousel videos={videoTestimonials} />
          </div>
          <DataSection
            margin="80px 0 0"
            items={[
              {
                number: companyStats ? companyStats.activeCompanyAccount : 532,
                description: 'people are actively hiring on cord right now'
              },
              {
                number: companyStats ? formatNumber(companyStats.totalMessage) : '178,032',
                description: 'messages exchanged on cord so far'
              },
              {
                number: companyStats ? formatNumber(companyStats.convo) : '22,000',
                description: 'conversations started on cord so far'
              },
              {
                number: `${
                  companyStats && companyStats.averageResponseRate
                    ? getPercentage(companyStats.averageResponseRate)
                    : 87
                }%`,
                description: 'average response rate from people hiring'
              }
              // { /** Hidden because stat is too low - used to be 56% now, it dropped down to ~15% */
              //   number: `${
              //     companyStats && companyStats.positiveResponse
              //       ? getPercentage(companyStats.positiveResponse)
              //       : 56
              //   }%`,
              //   description: 'of messages sent result in an interview'
              // }
            ]}
          />
        </PageSection>
        <PageSection
          title="Do your best work"
          subTitle="cord is used by hiring teams inside the world's most advanced technology companies. Speak to people developing covid-19 vaccines, tackling climate change and building autonomous vehicles."
          subTitleClassName="min_view"
          className="center_alignment"
          widthClassName="page_view"
          button={{
            text: 'See companies hiring',
            icon: 'icon_arrow_right',
            iconPosition: 'right',
            className: 'twenty_px_font',
            link: '/signup',
            action: () => this.trackSignupClicksWithContext('See companies hiring')
          }}
        >
          <CompanySection
            {...this.props}
            // @ts-expect-error TS(2322) FIXME: Type '{ loadData: any; remote: any; history: Histo... Remove this comment to see the full error message
            loadData={inviteDetailsLoaded}
            remote={inviteDetails?.payload?.remote}
          />
        </PageSection>
        {!isProfileURL(location.pathname) && (
          <Seo
            title={invitePage ? 'Your invitation to join' : 'The direct job search platform'}
            description="cord is a job search platform that gives you direct access to hiring teams at technology companies in the UK, USA and remote across Europe."
            path={location.pathname}
            contentType="website"
            image="https://assets.co-hire.com/seo-image-cord.jpg"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  darkMode: selectDarkMode(state)
});

export default connect(mapStateToProps)(InvitePage);
