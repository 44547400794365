import React, { PropsWithChildren, useRef } from 'react';
import { usePopper } from 'react-popper';

import { Placement } from '@popperjs/core';

import { useClickDetector } from 'hooks/useClickDetector';
import { noop } from 'utils/fn';

import { BubbleWrap, BubbleArrow } from './Bubble.styled';

export type BubbleProps = {
  show: boolean;
  anchorElem: React.RefObject<HTMLElement>;
  placement?: Placement;
  noPadding?: boolean;
  className?: string;
  onClose?: () => void;
};

const getCurrentPlacement = (attributes: any) => {
  return (attributes.popper ? attributes.popper['data-popper-placement'] : '') as Placement;
};

const Bubble = ({
  show,
  children,
  placement = 'bottom',
  anchorElem,
  noPadding,
  className,
  onClose = noop
}: PropsWithChildren<BubbleProps>) => {
  const bubbleElement = useRef(null);
  const arrowElement = useRef(null);
  useClickDetector(bubbleElement, onClose);

  const { styles, attributes } = usePopper(anchorElem?.current, bubbleElement?.current, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement?.current } },
      { name: 'offset', options: { offset: [0, 16] } }
    ]
  });
  const currentPlacement = getCurrentPlacement(attributes);

  return (
    <BubbleWrap
      show={show}
      ref={bubbleElement}
      noPadding={noPadding}
      className={className}
      style={styles.popper}
      {...attributes.popper}
    >
      <BubbleArrow ref={arrowElement} style={styles.arrow} placement={currentPlacement} />
      {children}
    </BubbleWrap>
  );
};

export default Bubble;
