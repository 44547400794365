/**
 * Returns the intersection of two arrays.
 * Not omptimised for very large arrays.
 * @template T - The type of elements in the arrays.
 * @param {T[]} arr1 - The first array.
 * @param {T[]} arr2 - The second array.
 * @returns {T[]} - The intersection of the two arrays.
 */
export function intersect<T>(arr1: T[] | unknown, arr2: T[] | unknown): T[] {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return [];
  }

  const intersection: T[] = [];

  for (const value of arr1) {
    if (arr2.includes(value) && !intersection.includes(value)) {
      intersection.push(value);
    }
  }

  return intersection;
}

/**
 * Returns true if arrays intersect, false otherwise.
 * @template T - The type of elements in the arrays.
 * @param {T[]} arr1 - The first array.
 * @param {T[]} arr2 - The second array.
 * @returns {boolean} - True if arrays intersect, false otherwise.
 */
export function arraysIntersect<T>(arr1: T[] | unknown, arr2: T[] | unknown): boolean {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  return intersect(arr1, arr2).length > 0;
}
