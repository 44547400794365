import { PricingCountry, V4PricingOptions } from 'types';
import { getV4BasicPrice } from 'utils/pricingUtils';

export const getCompareFeaturesTableHeaderContent = (pricingType: PricingCountry) => ({
  standard: {
    plan: V4PricingOptions.V4Growth,
    label: 'Growth',
    price: getV4BasicPrice(pricingType, V4PricingOptions.V4Growth)
  },
  enterprise: {
    plan: V4PricingOptions.V4Unlimited,
    label: 'Customised',
    price: `Starting at ${getV4BasicPrice(pricingType, V4PricingOptions.V4Unlimited)}`,
    buttonStyle: 'stroke_blue'
  }
});
