import { SubsetOf } from 'types';

export const filterValues =
  <T extends {}>(evalFn: (key: keyof T, value: T[keyof T]) => boolean) =>
  (obj: T) => {
    const newObj = {} as SubsetOf<T>;
    const keys = Object.keys(obj) as (keyof T)[];
    keys.forEach(key => {
      if (evalFn(key, obj[key])) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  };
