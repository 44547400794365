import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Location } from 'history';
import ClassNames from 'classnames';

import { NavigationLink } from 'types';
import { partition } from 'utils/array';
import { isAbsoluteURL } from 'utils/url';

import LinkWithIcon from 'components/linkwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

type NavigationProps = {
  links: NavigationLink[];
  open: boolean;
  darkMode: boolean;
  location: Location;
  handleClick: () => void;
};

const NavigationBubble = ({ links, open, darkMode, location, handleClick }: NavigationProps) => {
  const [[mainLink], rest] = partition(links, (link: NavigationLink) => !!link.main);

  return (
    <BubbleWrap>
      <Bubble open={open} darkMode={darkMode}>
        <BubbleHeader link={mainLink} />
        {rest.map((link: NavigationLink) => (
          <ConditionalRender
            key={link.id}
            predicate={isAbsoluteURL(link.path)}
            fallback={
              <RelativeLink
                link={link}
                active={location.pathname === link.path}
                handleClick={handleClick}
              />
            }
          >
            <AbsoluteLink link={link} handleClick={handleClick} />
          </ConditionalRender>
        ))}
      </Bubble>
    </BubbleWrap>
  );
};

const RelativeLink = ({
  link,
  active,
  handleClick
}: {
  link: NavigationLink;
  active: boolean;
  handleClick: () => void;
}) => (
  <BubblePill active={active} onClick={handleClick}>
    <LinkWithIcon text={link.label} key={link.id} link={link.path} icon={link.icon || ''} />
  </BubblePill>
);

const AbsoluteLink = ({ link, handleClick }: { link: NavigationLink; handleClick: () => void }) => (
  <BubblePill onClick={handleClick}>
    <a href={link.path} target="_blank" rel="noopener noreferrer">
      <span className={ClassNames('icon', link.icon, 'lightblue_text')} />
      <span className="lightblue_text">{link.label}</span>
    </a>
  </BubblePill>
);

const BubbleHeader = ({ link }: { link: NavigationLink }) => (
  <HeaderWrap to={link.path}>
    <LargeIcon>
      <img src={link.img} alt={`large icon representing ${link.label}`} width="25px" />
    </LargeIcon>
    <Headers>
      <strong className="menu_item_title">{link.label}</strong>
      <span className="menu_item_desc">{link.msg}</span>
    </Headers>
  </HeaderWrap>
);

const BubbleWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const LargeIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 20px;
  background-color: ${({ theme: { colours } }) => colours.transparentPrimaryColour};
`;

const Headers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  color: ${colours.primaryColour};

  .menu_item_title {
    margin-bottom: 2px;
  }
`;

const Bubble = styled.ul<{ open: boolean; darkMode: boolean }>`
  display: ${({ open }) => (open ? 'grid' : 'none')};
  position: absolute;
  padding: 1rem;
  border-radius: 30px;
  color: ${colours.primaryColour};

  ${({ darkMode }) =>
    darkMode
      ? css`
          background-color: ${colours.darkerBgColour};
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.6);
        `
      : css`
          background-color: white;
          box-shadow: 0px 0px 20px rgb(85 164 230 / 35%);
        `}

  grid-auto-columns: max-content;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

  animation: fadeIn 0.5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  :before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    top: -10px;
    background: ${({ darkMode }) => (darkMode ? colours.darkBgColour : 'white')};
    box-shadow: -5px -5px 15px -5px
      ${({ darkMode }) => (darkMode ? colours.darkDropshadow : colours.primaryColourShadow)};
    border-radius: 2px;
    left: calc(50% - 10px);
  }
`;

const HeaderWrap = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 70px;
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  border-radius: 25px;

  &:hover {
    background-color: ${({ theme: { colours, fn } }) =>
      fn.opaqueColour(colours.primaryColour, 0.05)};
  }
`;

const BubblePill = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  height: 38px;
  border-radius: 15px;
  background-color: ${({ active, theme: { colours, fn } }) =>
    active ? fn.opaqueColour(colours.primaryColour, 0.05) : 'transparent'};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme: { colours, fn } }) =>
      fn.opaqueColour(colours.primaryColour, 0.05)};
  }

  > a {
    display: flex;
    flex-grow: 1;
    padding: 8px 24px 8px 12px;
    color: ${colours.primaryColour};
    font-weight: ${typography.semibold};
    gap: 8px;
  }
`;

export default NavigationBubble;
