import { useEffect, useState } from 'react';

import { SelectedPricing, StandardPlan, SubscriptionPlan } from 'types';
import { PaymentData } from 'v2/services/company/subscription';
import { STANDARD_PLAN_LIST } from 'consts';
import { getCompanyUpgradeOptions } from 'fetcher';
import { checkIfSubscriptionExpired } from 'utils/companyUtils';
import { checkIfSourcingBasic, isCurrentPlanSelected } from 'v2/services/tools/pricing';
import { useDemoCompany } from 'store';

import {
  PaymentPopup,
  PricingPlansSectionProps,
  StandardTiers,
  getUpdatedStandardTiers
} from './PricingPlansSection.helpers';

export const useStandardTiers = (
  props: PricingPlansSectionProps,
  { plan: selectedPlan, frequency, pricingCountry }: SelectedPricing<StandardPlan>
) => {
  const [standardTiers, setStandardTiers] = useState<StandardTiers>();

  const { currentPlanDetails: { plan: currentPlan } = {}, availablePlans } = props;
  const isExpiredAccount = checkIfSubscriptionExpired(currentPlan);

  useEffect(() => {
    const getStandardTiers = async () => {
      let potentialPlans = availablePlans;
      const isSourcingBasic = checkIfSourcingBasic(currentPlan);

      if (currentPlan && !isSourcingBasic && !isExpiredAccount) {
        const { upgrade } = await getCompanyUpgradeOptions();
        potentialPlans = upgrade;
      }

      setStandardTiers(
        getUpdatedStandardTiers({
          ...props,
          pricingCountry,
          availablePlans: potentialPlans
        })
      );
    };

    getStandardTiers();
  }, [pricingCountry]);

  const updateEnquiredStatus = () => {
    setStandardTiers(prevStandardTiers => {
      const copiedStandardTiers = { ...(prevStandardTiers as StandardTiers) };
      Object.assign(copiedStandardTiers[frequency][selectedPlan], { enquired: true });

      return copiedStandardTiers;
    });
  };

  return { standardTiers, updateEnquiredStatus };
};

export const useSelectedPlan = (
  { pricingVer, currentPlanDetails, availablePlans }: PricingPlansSectionProps,
  { plan: defaultPlan, frequency, pricingCountry }: SelectedPricing<StandardPlan>
) => {
  const demoCompany = useDemoCompany();
  const [selectedPlan, setSelectedPlan] = useState<StandardPlan>(defaultPlan);

  const currentPlan = currentPlanDetails?.plan;
  const isSourcingBasic = checkIfSourcingBasic(currentPlan);
  const isExistingUser = currentPlan && !demoCompany && !isSourcingBasic;
  const availablePlan = availablePlans?.[0];

  useEffect(() => {
    const isExpiredAccount = checkIfSubscriptionExpired(currentPlan);

    // Keep the default plan
    if (!isExistingUser || (isExpiredAccount && !availablePlan)) {
      return;
    }

    let planDetails = currentPlanDetails;

    if (isExpiredAccount) {
      const {
        pricing: { urlPath, billingFrequency },
        subscriptionPlan: { plan }
      } = availablePlan!;

      planDetails = {
        plan: plan as SubscriptionPlan,
        billingFrequency,
        urlPath
      };
    }

    const standardPlans = STANDARD_PLAN_LIST[pricingVer];
    const currentTier = standardPlans.find(plan =>
      isCurrentPlanSelected({ plan, frequency, pricingCountry }, planDetails)
    );

    if (currentTier) setSelectedPlan(currentTier);
  }, [isExistingUser, availablePlan, pricingCountry]);

  return { isExistingUser, selectedPlan, setSelectedPlan };
};

export const useCardPaymentPopup = () => {
  const [paymentPopup, setPaymentPopup] = useState<Partial<PaymentPopup>>({ show: false });

  const togglePaymentPopup = (paymentData?: PaymentData) => {
    setPaymentPopup(prevPaymentPopup => {
      if (paymentData) return { show: true, ...paymentData };
      return { ...prevPaymentPopup, show: false };
    });
  };

  return { paymentPopup, togglePaymentPopup };
};
