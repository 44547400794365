import React from 'react';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type Props = {
  title: string;
  isTablet?: true;
  children: React.ReactNode;
};

/** Added as a last-minute 'hack'. Conditionally renders the header
 * 1. IF the device isTabletWidth AND it receives the parameter isTablet.
 * 2. IF the device is larger than a tablet AND does NOT receive the isTablet parameter
 */
export const HeaderContent = ({ title, isTablet, children }: Props) => {
  const isTabletWidth = useMedia([mediaQueries.tablet]);
  return (
    <ConditionalRender predicate={(isTabletWidth && isTablet) || (!isTablet && !isTabletWidth)}>
      <h2 className="page_section_title">{title}</h2>
      <h3 className="page_section_subtitle">{children}</h3>
    </ConditionalRender>
  );
};
