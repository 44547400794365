import React from 'react';
import ClassNames from 'classnames';

import { CardDetails } from '@cohiretech/common-types';

import { getLocalStorageItem } from 'cookieManager';
import { getTimeDifference, removeTime } from 'utils';
import { capitalizeSentences } from 'utils/string';

import Button, { Props as ButtonProps } from 'components/button';
import ErrorMessage from 'components/errormessage';
import LinkWithIcon from 'components/linkwithicon';
import RadioButtons from 'components/radiobuttons';

import './style.scss';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

type PaymentMethodProps = {
  cards: CardDetails[];
  className?: string;
  errorMessage: string;
  loading?: boolean;
  submitButton?: Pick<ButtonProps, 'text' | 'action' | 'className'>;
  selected: string;
  proceedToPayment: () => void;
  onSelect: (card: string) => void;
  newCardLabel?: string;
  isReactivatingUSCompanyFromUKStripe?: boolean;
};

export default function PaymentMethod({
  cards,
  className,
  selected,
  loading,
  errorMessage,
  submitButton,
  proceedToPayment,
  onSelect,
  newCardLabel = 'Choose a new card',
  isReactivatingUSCompanyFromUKStripe
}: PaymentMethodProps) {
  const failedCardIDs = getLocalStorageItem('failedCardIDs') || [];
  const isExpired = (endMonth: number, endYear: number): boolean => {
    const timeDifference = getTimeDifference(
      Date.now(),
      removeTime(new Date(endYear, endMonth), 1, 'days')
    );

    return timeDifference < 0;
  };

  const cardOptions = cards.map(
    ({ cardID, brand, expMonth, expYear, last4digits }: CardDetails) => {
      const error = failedCardIDs.includes(cardID) ? 'Payment failed' : '';
      const disabled = isExpired(expMonth!, expYear!);
      return {
        value: cardID || '',
        label: `${capitalizeSentences(brand)} **** **** **** ${last4digits}`,
        errorMessage: error,
        disabled,
        subLabel: disabled
          ? 'Expired'
          : `Exp. ${expMonth! < 10 ? '0' : ''}${expMonth}/${expYear!.toString().slice(-2)}`
      };
    }
  );

  return (
    <div className={ClassNames('payment_method', className)}>
      <ConditionalRender predicate={!isReactivatingUSCompanyFromUKStripe}>
        <RadioButtons
          name="payment_cards"
          options={cardOptions}
          selected={selected}
          view="tiles"
          onSelect={card => onSelect(card as string)}
        />
      </ConditionalRender>
      <LinkWithIcon
        className={ClassNames('new_card_link_with_icon', {
          align_self_center: isReactivatingUSCompanyFromUKStripe
        })}
        text={newCardLabel}
        icon="icon_card"
        action={() => proceedToPayment?.()}
      />
      {errorMessage && <ErrorMessage type="critical">{errorMessage}</ErrorMessage>}
      <ConditionalRender predicate={!isReactivatingUSCompanyFromUKStripe && submitButton}>
        <Button {...submitButton} loading={loading} />
      </ConditionalRender>
    </div>
  );
}
