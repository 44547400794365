import React, { useState } from 'react';
import ClassNames from 'classnames';

import Dropdown from 'components/dropdown';
import Tooltip from 'components/tooltip';
import Badge from 'components/badge';

import AILoader from 'images/animations/AILoader';

import { useTextEnhancer } from '../../TextEnhancer.context';
import { AIAction, aiActions } from '../../TextEnhancer.helpers';

import CyclingText from './CyclingText';

const Menu = () => {
  const [selected, setSelected] = useState(aiActions[0]);
  const { enhanceText, isLoading } = useTextEnhancer();

  const handleSelect = async (item: AIAction) => {
    setSelected(item);
    await enhanceText(item);
  };

  return (
    <>
      <Dropdown
        className={ClassNames('inline_dropdown', 'tool_item', 'text_enhancer', {
          dark: false,
          svg: isLoading
        })}
        hideArrow
        selectMessage={<Message message={isLoading ? selected.text : ''} />}
        list={aiActions}
        id="ai_enhance_dropdown"
        onSelect={item => handleSelect(item as AIAction)}
        hideSelectedLabel
      />
    </>
  );
};

const Message = ({ message = '' }: { message: string }) => {
  return message ? (
    <>
      <AILoader animate />
      <CyclingText strings={[message, 'Hold tight', 'Refining your text', 'AI is working']} />
    </>
  ) : (
    <>
      <span className="icon_wand" />
      <Badge text="ai-driven" type="info_light" />
      <Tooltip text="BETA Enhance text" />
    </>
  );
};

export default Menu;
