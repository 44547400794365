import styled, { css, keyframes } from 'styled-components';

import { ConditionalHideProps } from './ConditionalHide';

type AnimationProps = Pick<ConditionalHideProps, 'fade' | 'expand'>;

const DEFAULT_ANIMATION_DURATION = 0.5;

export const StyledContainer = styled.div<ConditionalHideProps>`
  overflow: hidden;
  transition: all ${DEFAULT_ANIMATION_DURATION}s ease-in-out;

  ::-webkit-scrollbar {
    width: 0px;
  }

  ${({ fade, expand, predicate }) => {
    const isAnimated = fade || expand;
    if (!isAnimated) return predicate ? Visible : Hidden;

    return predicate ? AnimateIn({ fade, expand }) : AnimateOut({ fade, expand });
  }}
`;

const Hidden = css`
  display: none;
`;

const Visible = css`
  display: block;
`;

const AnimateIn = ({ fade, expand }: AnimationProps) => css`
  overflow: auto;
  animation: ${animateIn({ fade, expand })} ${DEFAULT_ANIMATION_DURATION}s forwards;
`;

const AnimateOut = ({ fade, expand }: AnimationProps) => css`
  overflow: auto;
  animation: ${animateOut({ fade, expand })} ${DEFAULT_ANIMATION_DURATION}s forwards;
`;

// prettier-ignore
const animateIn = ({ fade, expand }: AnimationProps) => keyframes`
  0% {
    ${fade && css`opacity: 0;`}
    ${expand && css`height: 0;`}
  }
  100% {
    ${fade && css`opacity: 1;`}
    ${expand && css`height: var(--max-height);`}
  }
`;

// prettier-ignore
const animateOut = ({ fade, expand }: AnimationProps) => keyframes`
  0% {
    ${fade && css`opacity: 1;`}
    ${expand && css`height: var(--max-height);`}
  }
  100% {
    ${fade && css`opacity: 0;`}
    ${expand && css`height: 0;`}
  }
`;
