import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Location } from 'history';

import {
  AgencyClientCompanyInterface,
  CandidateApplicationTrackerInterface as CandidateATInterface,
  CompanyInterface,
  ListingCleanInterface,
  SearchResponseListing
} from '@cohiretech/common-types';

import { generatePersonsURL } from 'utils';
import { S3_IMAGE_BUCKET_URL } from 'consts';
import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { ExtendedApplicationDetails } from 'types';

import { MultiAvatarPhotosProps } from 'components/multiavatarphotos';

import Company from 'images/company.png';

type PartialCandidateATInterface = Partial<
  Pick<CandidateATInterface, 'candidateApplicationTrackerCreatedListingID' | 'lastUpdatedStageDate'>
>;

export type PositionProfileProps = {
  profile: $TSFixMe;
  positionLocation: $TSFixMe;
  positionLastActive: $TSFixMe;
  relevantSkills: $TSFixMe;
  recentConvoStartedCount: number;
  recentRequestsReceivedCount: number;
  outstandingRequestCount: number;
  companyURL: string;
  equityMin: $TSFixMe;
  equityMax: $TSFixMe;
  companyContact?: $TSFixMe;
  candidateContact?: $TSFixMe;
  updateProfileHistory?: $TSFixMe;
  shortlistID?: $TSFixMe;
  shortlistType?: $TSFixMe;
  onShortlisted?: $TSFixMe;
  goToSignup?: $TSFixMe;
  isCompanyUser: boolean;
  showPersonInfo?: $TSFixMe;
  goToPersonsProfile?: $TSFixMe;
  associatedMemberDetails: SearchResponseListing['associatedMemberDetails'];
  onProfileClose?: $TSFixMe;
  loading?: $TSFixMe;
  onMessage?: $TSFixMe;
  personsURL?: string;
  personsName?: string;
  personsPhotoURL?: string;
  applicationDetails?: ExtendedApplicationDetails;
  showEditCandidateATPositionPopup?: () => void;
} & Required<ListingCleanInterface> &
  Required<CompanyInterface> &
  PartialCandidateATInterface;

export const getDataToRender = (
  props: PositionProfileProps,
  isAgency: boolean,
  location: Location
) => {
  const { companyName, benefit, associatedMemberDetails, agencyClientCompany } = props;

  let memberProfileURL = '';

  if (isAgency) {
    const { accountID = 0, memberName = '' } = associatedMemberDetails || {};

    memberProfileURL = generatePersonsURL(location, {
      personsName: memberName,
      personsID: accountID,
      companyName
    });
  }

  return {
    photoData: getPhotoData(props, isAgency, memberProfileURL),
    smallHeading: getSmallHeading(props, isAgency, memberProfileURL),
    employerInfo: isAgency ? getEmployerInfo(agencyClientCompany) : [],
    benefits: isAgency ? agencyClientCompany.benefit : benefit
  };
};

const getPhotoData = (
  {
    companyName,
    companyLogo,
    companyURL,
    updateProfileHistory,
    associatedMemberDetails,
    loading
  }: PositionProfileProps,
  isAgency: boolean,
  memberProfileURL: string
) => {
  const photoData: MultiAvatarPhotosProps['photosData'] = [
    {
      loading,
      onClick: updateProfileHistory,
      photoURL: companyLogo,
      photoLink: companyURL,
      fallbackPhotoURL: Company,
      linkTitle: `View ${companyName}'s profile`
    }
  ];

  if (isAgency) {
    const { memberName, photoURL } = associatedMemberDetails || {};

    photoData.unshift({
      loading,
      onClick: updateProfileHistory,
      photoURL,
      photoLink: memberProfileURL,
      memberName,
      linkTitle: `View ${memberName}'s profile`,
      ...(photoURL?.startsWith(S3_IMAGE_BUCKET_URL)
        ? { resizePhotoDimensions: { width: 300 } }
        : {})
    });
  }

  return photoData;
};

const getSmallHeading = (
  { companyName, companyURL, updateProfileHistory, associatedMemberDetails }: PositionProfileProps,
  isAgency: boolean,
  memberProfileURL: string
) => {
  const { memberName } = associatedMemberDetails || {};

  if (!companyURL) return companyName;

  const CompanyName = (
    <Link to={companyURL} onClick={updateProfileHistory}>
      {companyName}
    </Link>
  );

  if (isAgency && memberName) {
    return (
      <>
        <Link to={memberProfileURL} onClick={updateProfileHistory}>
          {memberName}
        </Link>{' '}
        from {CompanyName} is recruiting for
      </>
    );
  }

  return CompanyName;
};

const getEmployerInfo = ({
  tagline,
  location,
  industries,
  fundingStage,
  size
}: AgencyClientCompanyInterface) => [
  { header: 'One line pitch', value: tagline },
  { header: 'Based in', value: location?.label },
  { header: 'Industry', value: industries?.join(' · ') },
  { header: 'Funding stage', value: fundingStage },
  { header: 'Company size', value: `${size} employees` }
];

export const getFromLocalStorage = (position: string, id: number) => {
  const key = btoa(encodeURIComponent(`position-insights-${id}-${position}`));
  const value = getLocalStorageItem(key) || false;
  return value;
};

export const saveToLocalStorage = (position: string, id: number) => {
  const key = btoa(encodeURIComponent(`position-insights-${id}-${position}`));
  setLocalStorageItem(key, true);
};
