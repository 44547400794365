import React, { MouseEventHandler, useEffect } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { generateBreadcrumbSchema } from 'schema/breadcrumbs';

import { removeTag } from 'utils';
import media from 'styles/media';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type BreadcrumbsProps = {
  className?: string;
  breadcrumbs: {
    label: string;
    pathname?: string;
    current?: boolean;
    onClick?: MouseEventHandler;
  }[];
};

const Breadcrumbs = ({ className, breadcrumbs }: BreadcrumbsProps) => {
  useEffect(() => {
    generateBreadcrumbSchema(breadcrumbs);

    return () => {
      removeTag('script', 'id', 'breadcrumbs_schema');
    };
  }, []);

  return (
    <BreadcrumbsWrapper className={className} aria-label="breadcrumb">
      <ol>
        {breadcrumbs.map(({ label, pathname, current, onClick }) => (
          <ConditionalRender
            key={label}
            predicate={!current}
            fallback={<li key={label}>{label}</li>}
          >
            <li>
              <ConditionalRender
                predicate={pathname}
                fallback={
                  <button className="breadcrumbs_link" onClick={onClick}>
                    {label}
                  </button>
                }
              >
                <Link className="breadcrumbs_link" to={pathname || '#'} onClick={onClick}>
                  {label}
                </Link>
              </ConditionalRender>
              <span className="icon_simple_arrow_right" />
            </li>
          </ConditionalRender>
        ))}
      </ol>
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;

const BreadcrumbsWrapper = styled.nav`
  ol {
    list-style: none;
    display: flex;
    margin-bottom: 50px;
    padding: 0;
    align-items: center;

    ${media.mobile`
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    `}

    .breadcrumbs_link {
      font-weight: ${typography.bold};

      &:hover {
        color: ${colours.primaryColour};
      }
    }

    .icon_simple_arrow_right {
      font-size: 0.75rem;
      font-weight: bold;
      margin: 0 1.25rem;
      ${({ theme: { fn } }) => fn.slightlyBolderIcon()}
    }
  }
`;
