import React from 'react';
import ClassNames from 'classnames';

import { CandidateShortlist, CompanyType } from '@cohiretech/common-types';

import { isEmpty } from 'utils';
import { identifyCompanyType } from 'v2/services/company/companyType';
import { useNavigatablePositionsStore } from 'store/navigatablePositions';

import { useNavigatablePositionsContext } from 'v2/pages/candidate/NavigatablePositions/NavigatablePositions.context';

import Button, { Props as ButtonProps } from 'components/button';
import PositionActions from 'components/positionactions';
import PositionOverview from 'components/positionoverview';
import ErrorMessage from 'components/errormessage';
import { LabelWithIconProps } from 'components/labelwithicon';
import IconButton from 'components/iconbutton';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

type Props = {
  blockNote: string | (string | React.ReactNode)[];
  applicationNote?: React.FC<LabelWithIconProps>;
  userLoggedIn: boolean;
  publicButton: ButtonProps;
  primaryButton: ButtonProps;
  personDetails: {
    memberName?: string;
    photoURL?: string;
    jobTitle?: string;
    lastActive?: string;
  };
  companyName: string | undefined;
  accountBeingVerified: boolean;
  responseTime: Record<string, any> | string;
  errorMessage: string;
  showMessageIcon?: boolean;
  className: string;
  personsURL?: string;
  companyURL?: string;
  companyLogo?: string;
  companyPosition?: string;
  companyType?: CompanyType;
  positionID: number;
  shortlistType: CandidateShortlist;
  shortlistID: number;
  updateProfileHistory: () => void;
  onShortlisted: (s: number | null, t: string | null) => void;
  xPosition?: 'left' | 'right' | 'middle';
  showPersonOnly?: boolean;
};

export default function ButtonPanel({
  blockNote,
  applicationNote,
  userLoggedIn,
  publicButton,
  primaryButton,
  personDetails,
  companyName,
  accountBeingVerified,
  responseTime,
  errorMessage,
  companyLogo,
  companyPosition,
  className,
  companyType,
  shortlistType,
  shortlistID,
  positionID,
  xPosition,
  onShortlisted,
  companyURL,
  personsURL,
  updateProfileHistory,
  showPersonOnly
}: Props) {
  const accountName = personDetails?.memberName || '';
  const accountPhoto = personDetails?.photoURL || '';
  const accountJobTitle = personDetails?.jobTitle || '';
  const accountLastActive = personDetails?.lastActive || '';

  const isFirstItemStringOrNoteIsString = Array.isArray(blockNote)
    ? typeof blockNote[0] === 'string'
    : true;
  const hasBlockNote =
    !isEmpty(blockNote) && isFirstItemStringOrNoteIsString && (blockNote[0] as string)?.length > 0;
  const { isExternal } = identifyCompanyType(companyType);

  const { shouldShowNavigatablePositions } = useNavigatablePositionsStore();
  const { navigateToPrevious, navigateToNext, isMobile } = useNavigatablePositionsContext();

  if (hasBlockNote) {
    return (
      <div className={ClassNames('button_panel', 'note_block', className)}>
        <ConditionalRender predicate={isMobile && shouldShowNavigatablePositions}>
          <IconButton
            icon="icon_arrow_left"
            ariaLabel="navigate to previous position"
            onClick={navigateToPrevious}
          />
        </ConditionalRender>
        <div className="button_note">
          {Array.isArray(blockNote) ? blockNote.map(e => e) : blockNote}
        </div>
        <ConditionalRender predicate={isMobile && shouldShowNavigatablePositions}>
          <IconButton
            icon="icon_arrow_right"
            ariaLabel="navigate to next position"
            onClick={navigateToNext}
          />
        </ConditionalRender>
      </div>
    );
  } else if (userLoggedIn) {
    return (
      <div
        className={ClassNames('button_panel', className, {
          external_listing: isExternal
        })}
      >
        <ConditionalRender predicate={isMobile && shouldShowNavigatablePositions}>
          <IconButton
            icon="icon_arrow_left"
            ariaLabel="navigate to previous position"
            onClick={navigateToPrevious}
          />
        </ConditionalRender>
        <PositionOverview
          companyLogo={companyLogo}
          personsPhotoURL={accountPhoto}
          companyType={companyType}
          personsName={accountName}
          personsJobTitle={accountJobTitle}
          companyName={companyName}
          positionName={companyPosition}
          lastActive={accountLastActive}
          responseTime={responseTime}
          companyURL={companyURL}
          personsURL={personsURL}
          onProfileClick={updateProfileHistory}
          showTourBubble={accountBeingVerified && !isExternal}
          showPersonOnly={showPersonOnly}
          isUserLoggedIn
        />
        <div className="interactions_section">
          {applicationNote}
          <PositionActions
            listingID={positionID}
            shortlistID={shortlistID}
            type={shortlistType}
            onShortlisted={onShortlisted}
            primaryButton={primaryButton}
            isOnButtonPanel
            xPosition={xPosition}
          />
        </div>
        <ConditionalRender predicate={isMobile && shouldShowNavigatablePositions}>
          <IconButton
            icon="icon_arrow_right"
            ariaLabel="navigate to next position"
            onClick={navigateToNext}
          />
        </ConditionalRender>
        {errorMessage && <ErrorMessage align="center">{errorMessage}</ErrorMessage>}
      </div>
    );
  } else {
    return (
      <div className={ClassNames('button_panel', className)}>
        <PositionOverview
          companyLogo={companyLogo}
          personsPhotoURL={accountPhoto}
          companyType={companyType}
          personsName={accountName}
          personsJobTitle={accountJobTitle}
          companyName={companyName}
          positionName={companyPosition}
          lastActive={accountLastActive}
          responseTime={responseTime}
          companyURL={companyURL}
          personsURL={personsURL}
          onProfileClick={updateProfileHistory}
        />
        <Button
          className={ClassNames('twenty_px_font', 'primary_button')}
          {...publicButton}
          buttonStyle="fill_cord_gradient"
        />
      </div>
    );
  }
}
