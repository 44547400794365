import React from 'react';
import { NavLink as Link } from 'react-router-dom';

import { generatePersonsURL } from 'utils';

import Avatar from 'v2/components/ui/atoms/Avatar';

import './style.scss';

export default function PeopleBubbles({ people, location }: $TSFixMe) {
  return (
    <div className="people_hiring_bubbles">
      {people.map(
        ({
          id,
          name,
          jobTitle,
          companyName,
          photoURL,
          hiringForListings,
          lastActive
        }: $TSFixMe) => {
          const profileURL = generatePersonsURL(location, {
            personsName: name,
            personsID: id,
            companyName
          });

          return (
            <Link key={id} to={profileURL} className="person_bubble_container">
              <div className="details_wrapper">
                <Avatar
                  photoURL={photoURL}
                  resizePhotoDimensions={{ width: 200 }}
                  firstname={name}
                  width={110}
                  lastActive={lastActive}
                />
                <div className="details_bubble">
                  <span className="icon_message" />
                  <div className="person_details">
                    <strong>
                      {name}, {jobTitle} at {companyName}
                    </strong>
                    <div>Hiring a {hiringForListings[0]}</div>
                  </div>
                </div>
              </div>
            </Link>
          );
        }
      )}
    </div>
  );
}
