import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { CandidateProfile, CompanyProfile, ImageResizeParam } from 'types';
import { generateStoryURL } from 'utils';
import { getImageDimensions, addDimensionsOnImages } from 'v2/services/image';
import { isMobileWidth, isTabletLandscapeWidth } from 'responsiveConfig';

import CompanyItem from 'components/companyitem';
import InsightsArticleItem from 'components/insights/articleitem';
import InsightsClipItem from 'components/insights/clipitem';
import InsightsDiscussionCard from 'components/insights/discussioncard';
import PeopleItem from 'components/peopleitem';
import PositionItem from 'components/positionitem';
import StoryItem from 'components/storyitem';
import TestimonialItem from 'components/testimonialitem';
import SliderContainer, { marginOptions } from 'components/listingslider/SliderContainer';

const logoDimensions = getImageDimensions('no-crop-logo-small');
const peopleLogoDimensions = getImageDimensions('no-crop-logo-large');
const peopleImageParams: ImageResizeParam[] = [{ type: 'logo', dimensions: peopleLogoDimensions }];

type Props = {
  className?: string;
  profile?: CandidateProfile | CompanyProfile | null;
  columns: 1 | 2 | 3 | 4 | 5;
  itemType: string;
  items?: $TSFixMe;
  testimonialType?: string;
  margin?: number;
  title?: string;
  seeMoreLink?: { text: string; onClick?: () => void; path?: string };
  showArrows?: boolean;
  tooltip?: ReactNode;
  showVideos?: boolean;
  showSaveHideActions?: boolean;
  reloadResults?: () => void;
} & Partial<RouteComponentProps>;

type OwnState = $TSFixMe;

type State = OwnState & typeof ListingSlider.defaultProps;

export default class ListingSlider extends React.Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = { margin: 1, title: '', showArrows: true };

  constructor(props: Props) {
    super(props);

    const bannerDimensions = getImageDimensions(`columns-${props.columns}`);

    const imageParams = [
      { type: 'columns', dimensions: bannerDimensions },
      { type: 'logo', dimensions: logoDimensions }
    ];

    this.state = {
      isLoading: true,
      items: props.items,
      itemWidth: (100 - 1 * (props.columns - 1)) / props.columns,
      columns: props.columns,
      imageParams,
      isSwipeDisabled: false
    };
  }

  componentDidMount() {
    const { items, itemType } = this.props;
    const { imageParams, columns } = this.state;

    const margin = marginOptions[itemType] || marginOptions.default;

    const mobileOptions = {
      default: {
        items: addDimensionsOnImages(items || [], imageParams)
      },
      people_item: {
        items: addDimensionsOnImages(items || [], peopleImageParams),
        imageParams: peopleImageParams
      },
      testimonial_item: {
        items: addDimensionsOnImages(items || [], imageParams)
      }
    };

    const tabletOptions = {
      default: {
        items: addDimensionsOnImages(items || [], imageParams),
        itemWidth: (100 - margin) / 2,
        columns: 2
      },
      people_item: {
        items: addDimensionsOnImages(items || [], peopleImageParams),
        itemWidth: 100 / 3 - 1,
        columns: 4,
        imageParams: peopleImageParams
      },
      testimonial_item: {
        items: addDimensionsOnImages(items || [], imageParams),
        itemWidth: (100 - 4) / 2,
        columns: 2
      }
    };

    const desktopOptions = {
      default: {
        items: addDimensionsOnImages(items || [], imageParams),
        itemWidth: (100 - margin * (columns - 1)) / columns
      },
      people_item: {
        items: addDimensionsOnImages(items || [], peopleImageParams),
        itemWidth: 100 / columns - 1,
        imageParams: peopleImageParams
      },
      testimonial_item: {
        items: addDimensionsOnImages(items || [], imageParams),
        itemWidth: (100 - 4 * (columns - 1)) / columns
      }
    };

    if (isMobileWidth) {
      this.setState({
        ...this.state,
        isLoading: false,
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        items: (mobileOptions[itemType] || mobileOptions.default).items,
        itemWidth: 100,
        columns: 1
      });
    } else if (isTabletLandscapeWidth) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const tabletOption = tabletOptions[itemType] || tabletOptions.default;

      this.setState({
        ...this.state,
        isLoading: false,
        items: tabletOption.items,
        itemWidth: tabletOption.itemWidth,
        columns: tabletOption.columns
      });
    } else {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const desktopOption = desktopOptions[itemType] || desktopOptions.default;

      this.setState({
        ...this.state,
        isLoading: false,
        items: desktopOption.items,
        itemWidth: desktopOption.itemWidth
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { items } = this.props;
    const { imageParams } = this.state;

    if (prevProps.items.length !== items.length) {
      this.setState({ items: addDimensionsOnImages(items || [], imageParams) });
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'itemIndex' implicitly has an 'any' type... Remove this comment to see the full error message
  updateItemDetails(itemIndex, newItemDetails) {
    const items = [...this.state.items];

    if (itemIndex > -1) {
      for (const key in newItemDetails) {
        if (Object.prototype.hasOwnProperty.call(newItemDetails, key)) {
          items[itemIndex][key] = newItemDetails[key];
        }
      }

      this.setState({ items });
    }
  }

  removeItemFromList(itemIndex: number) {
    const items = [...this.state.items];

    if (itemIndex > -1) {
      items.splice(itemIndex, 1);

      this.setState({ items });
    }
  }

  openChat(listingID: number, companyID: number) {
    const { history } = this.props;
    const { items } = this.state;
    const itemIndex = items.findIndex((item: $TSFixMe) => item.listingID === listingID);

    history?.push({ search: `?chat=${listingID},${companyID}&status=open` });

    this.removeItemFromList(itemIndex);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  getItemComponent(item, index) {
    const {
      itemType,
      // @ts-expect-error TS(2339) FIXME: Property 'storyType' does not exist on type 'Reado... Remove this comment to see the full error message
      storyType,
      location,
      showVideos,
      history,
      showSaveHideActions,
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'Readonly<Pro... Remove this comment to see the full error message
      id,
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Readonly<P... Remove this comment to see the full error message
      name,
      // @ts-expect-error TS(2339) FIXME: Property 'companyLogo' does not exist on type 'Rea... Remove this comment to see the full error message
      companyLogo,
      // @ts-expect-error TS(2339) FIXME: Property 'jobTitle' does not exist on type 'Readon... Remove this comment to see the full error message
      jobTitle,
      // @ts-expect-error TS(2339) FIXME: Property 'photoURL' does not exist on type 'Readon... Remove this comment to see the full error message
      photoURL,
      // @ts-expect-error TS(2339) FIXME: Property 'lastActive' does not exist on type 'Read... Remove this comment to see the full error message
      lastActive,
      // @ts-expect-error TS(2339) FIXME: Property 'responseRate' does not exist on type 'Re... Remove this comment to see the full error message
      responseRate,
      // @ts-expect-error TS(2339) FIXME: Property 'hiringForListings' does not exist on typ... Remove this comment to see the full error message
      hiringForListings,
      // @ts-expect-error TS(2339) FIXME: Property 'firstName' does not exist on type 'Reado... Remove this comment to see the full error message
      firstName,
      // @ts-expect-error TS(2339) FIXME: Property 'lastName' does not exist on type 'Readon... Remove this comment to see the full error message
      lastName,
      // @ts-expect-error TS(2339) FIXME: Property 'companyID' does not exist on type 'Reado... Remove this comment to see the full error message
      companyID,
      // @ts-expect-error TS(2339) FIXME: Property 'companyName' does not exist on type 'Rea... Remove this comment to see the full error message
      companyName,
      // @ts-expect-error TS(2339) FIXME: Property 'testimonial' does not exist on type 'Rea... Remove this comment to see the full error message
      testimonial,
      // @ts-expect-error TS(2339) FIXME: Property 'createDate' does not exist on type 'Read... Remove this comment to see the full error message
      createDate,
      testimonialType,
      // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      loading
    } = this.props;
    const { itemWidth, columns } = this.state;
    const pathname = location?.pathname;
    const itemIndex = item.searchPosition || index;

    switch (itemType) {
      case 'testimonial_item':
        return (
          <TestimonialItem
            key={index}
            firstName={firstName}
            lastName={lastName}
            photoURL={photoURL}
            companyLogo={companyLogo}
            companyID={companyID}
            companyName={companyName}
            jobTitle={jobTitle}
            testimonial={testimonial}
            createDate={createDate}
            testimonialType={testimonialType}
            itemsColumns={columns}
            location={location}
            pathname={pathname}
            itemWidth={itemWidth}
            slider
            {...item}
          />
        );
      case 'people_item':
        return (
          <PeopleItem
            key={item.id}
            columns={columns}
            style={{ width: `${itemWidth}%` }}
            id={id}
            name={name}
            companyLogo={companyLogo}
            jobTitle={jobTitle}
            photoURL={photoURL}
            lastActive={lastActive}
            responseRate={responseRate}
            hiringForListings={hiringForListings}
            location={location}
            loading={loading}
            {...item}
          />
        );
      case 'company_item':
        return (
          <CompanyItem
            key={item.companyID}
            width={itemWidth}
            locationData={location}
            showVideos={showVideos}
            isVerifyPage={false}
            columns={columns}
            {...item}
          />
        );
      case 'story_item':
        return (
          <StoryItem
            key={item.storyID || item.id}
            type={storyType}
            columns={columns}
            style={{ width: `${itemWidth}%` }}
            history={history}
            generateURL={(storyData: $TSFixMe) => generateStoryURL(location!, storyData, true)}
            {...item}
          />
        );
      case 'position_item':
        return (
          <PositionItem
            key={`${item.listingID}_${index}`}
            searchPosition={itemIndex}
            style={{ width: `${itemWidth}%` }}
            columns={columns}
            updateItemDetails={(newItemDetails: $TSFixMe) =>
              this.updateItemDetails(index, newItemDetails)
            }
            removeItemFromList={() => this.removeItemFromList(index)}
            openChat={(positionURL: $TSFixMe) =>
              // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 3.
              this.openChat(item.listingID, item.companyID, positionURL)
            }
            showSaveHideActions={showSaveHideActions}
            {...item}
          />
        );
      case 'insights_article_item':
      case 'insights_event-recording_item':
      case 'insights_story_item':
      case 'insights_collection_item':
        return (
          <InsightsArticleItem
            key={item.id || item.uid}
            style={{ width: `${itemWidth}%` }}
            article={item}
          />
        );
      case 'insights_clip_item':
        return <InsightsClipItem key={item.uid} style={{ width: `${itemWidth}%` }} clip={item} />;
      case 'insights_discussion_item':
        return (
          <InsightsDiscussionCard
            key={item.uid}
            // @ts-expect-error TS(2322) FIXME: Type '{ key: any; style: { width: string; }; discu... Remove this comment to see the full error message
            style={{ width: `${itemWidth}%` }}
            discussion={item}
          />
        );
      default:
        break;
    }
  }

  render() {
    const { className, title, showArrows, itemType, tooltip, seeMoreLink } = this.props;
    const { isLoading, items, itemWidth, columns } = this.state;

    return (
      <SliderContainer
        className={className}
        title={title}
        showArrows={showArrows}
        itemType={itemType}
        tooltip={tooltip}
        seeMoreLink={seeMoreLink}
        isLoading={isLoading}
        itemsCount={items.length}
        itemWidth={itemWidth}
        columns={columns}
      >
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type. */}
        {items.map((item, index) => this.getItemComponent(item, index))}
      </SliderContainer>
    );
  }
}
