import React, { useState, ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ClassNames from 'classnames';
import styled from 'styled-components';
import {
  companyGetForm,
  companyClearForm,
  companyUpdateForm,
  CompanySignUpForm
} from 'companySignupForm';
import { validateEmailAddress } from 'config';

import { EmailValidationContext } from '@cohiretech/common-types';

import { setLocalStorageItem } from 'cookieManager';
import { checkEmail, companySignUp, login, addCompanyUserEmailSubscription } from 'fetcher';
import {
  emailFormat,
  passwordRequirementsChecker,
  passwordMeetsRequirements,
  getMillisecondsByType
} from 'utils';
import { setInitialSetupModeLS } from 'utils/localStorage';
import { useUpdateLoginStatus } from 'store/services';

import Button from 'components/button';
import Checkbox from 'components/checkbox';
import ErrorMessage from 'components/errormessage';
import Field from 'components/field';
import LineDivider from 'components/linedivider';

import colours from 'css/base/_colours.module.scss';

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

type CreateAccountProfileProps = {
  sessionID: string;
  companyName: string;
  isAgency: boolean;
};

export default function CreateAccountProfile({
  sessionID,
  companyName,
  isAgency
}: CreateAccountProfileProps) {
  const history = useHistory();

  const [companySignupForm, setCompanySignupForm] = useState<CompanySignUpForm>({
    ...companyGetForm(),
    name: companyName
  });
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] =
    useState<string | ReactElement | Array<string | ReactElement>>('');
  const updateLoginStatus = useUpdateLoginStatus();

  const updateForm = (form: CompanySignUpForm) => {
    const newForm = { ...companySignupForm, ...form };

    companyUpdateForm(newForm);
    setCompanySignupForm(newForm);
  };

  const validate = async () => {
    const { name, firstName, jobTitle, email, termsAccepted } = companySignupForm;
    const passwordRequirements = passwordRequirementsChecker(password);
    const passwordIsValid = passwordMeetsRequirements(passwordRequirements);

    if (!name) return "Please enter your company's name";
    if (!firstName) return 'Please enter your first name';
    if (!email) return 'Please enter your email address';
    if (!jobTitle) return 'Please enter your job title';
    if (!password) return 'Please create a password';
    if (!passwordIsValid) return 'Password must tick all security requirements';
    if (!termsAccepted) return 'Please accept the terms of service and privacy policy to proceed';

    const result = await checkEmail(
      email,
      validateEmailAddress.companySignup ? 'ev' : '',
      EmailValidationContext.CompanyPaidSignup
    );

    if (result.status === 'failure') return "We couldn't validate your email. Please try again.";

    const { exists, companyExists, valid } = result.data;

    if (exists && companyExists) {
      setLocalStorageItem('sessionID', sessionID, getMillisecondsByType('days'));
      return [
        'Email address already exists on cord. Please try to ',
        <Link key="link" to={{ pathname: '/login', state: { sessionID } }} className="link">
          Log in
        </Link>,
        '.'
      ];
    }
    if (!emailFormat.test(email!) || (validateEmailAddress.companySignup && !valid)) {
      return 'Email address is not valid.';
    }

    return '';
  };

  const createProfile = async () => {
    setLoading(true);

    const validationMessage = await validate();

    if (validationMessage) {
      setErrorMessage(validationMessage);
      setLoading(false);
      return;
    }

    const form = { ...companySignupForm, password, checkoutSessionID: sessionID };
    const { status, message } = await companySignUp(form);

    if (status === 'success') {
      companyClearForm();
      setInitialSetupModeLS();

      if (errorMessage) setErrorMessage('');
      await companyLogin(form.email!, password);
    } else {
      setErrorMessage(message.message);
    }

    setLoading(false);
  };

  const companyLogin = async (email: string, password: string) => {
    const { status } = await login(email, password, false);

    if (status === 'failure') {
      history.push('/login');
    } else {
      await updateLoginStatus();
      await Promise.all([
        addCompanyUserEmailSubscription('product', 'add'),
        addCompanyUserEmailSubscription('marketing', 'add'),
        addCompanyUserEmailSubscription('product_monthly_recap', 'add')
      ]);

      history.push('/company/profile/edit');
    }
  };

  const { name, firstName, jobTitle, email, termsAccepted } = companySignupForm;
  const typeformURL = isAgency
    ? 'https://v7x6ybnej9n.typeform.com/to/Y9wOlrby'
    : 'https://v7x6ybnej9n.typeform.com/to/OfWsMIzL';
  const typeformEmailBody = `We've successfully paid for using cord and it would be great if you can help set-up our account by filling in this form: ${typeformURL}`;

  return (
    <UserProfileCont className="user_profile" data-testid="company_signup_form">
      <div className="profile_details">
        <Field
          label="Company name"
          attributes={{ 'aria-label': 'company name' }}
          placeholder="Eg. Slack"
          value={name}
          onChange={(value: string) => updateForm({ name: value })}
        />
        <div className={ClassNames('field_row', 'two_cols')}>
          <Field
            label="First name"
            attributes={{ 'aria-label': 'first name' }}
            placeholder="Eg. George"
            value={firstName}
            onChange={(value: string) => updateForm({ firstName: value })}
          />
          <Field
            label="Job title"
            attributes={{ 'aria-label': 'job title' }}
            placeholder="Eg. Head of Engineering"
            value={jobTitle}
            onChange={(value: string) => updateForm({ jobTitle: value })}
          />
        </div>
        <Field
          label="Email address"
          attributes={{ 'aria-label': 'email address' }}
          placeholder="Eg. george@moon.com"
          value={email}
          type="email"
          onChange={(value: string) => updateForm({ email: value })}
        />
        <Field
          label="Create your password"
          attributes={{ 'aria-label': 'create password' }}
          onChangeValidation
          placeholder="Make it strong"
          value={password}
          type="password"
          onChange={(value: string) => setPassword(value)}
        />
        <Checkbox
          checkboxID="terms_policy"
          className="accept_terms"
          label={[
            "I agree to cord's ",
            <a
              href={`${domain}/privacy`}
              key="privacy"
              aria-label="Open Privacy Policy in a new tab"
              target="_blank"
              rel="noopener noreferrer"
              className="lightblue_link"
            >
              Privacy Policy
            </a>,
            ' and ',
            <a
              href={`${domain}/employer-terms`}
              key="terms"
              aria-label="Open Terms of Service in a new tab"
              target="_blank"
              rel="noopener noreferrer"
              className="lightblue_link"
            >
              Terms of Service
            </a>
          ]}
          checked={termsAccepted}
          onChange={checked => updateForm({ termsAccepted: checked })}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </div>
      <Button text="Create your account" action={() => createProfile()} loading={loading} />
      <div className="bottom_note">
        We will send you a monthly product recap email to enrich your experience using cord. You can
        opt-out at any point in your account settings.
      </div>
      <LineDivider text="or" />
      <div className="other_setup_options">
        <p>Want us to setup your account instead?</p>
        <a
          href={typeformURL}
          className="link"
          title="Open Typeform in a new tab"
          target="_blank"
          rel="noopener noreferrer"
        >
          Complete this Typeform
        </a>
        <span>or</span>
        <a
          href={`mailto:?subject=Setup our cord account&body=${typeformEmailBody}`}
          className="link"
          title="Open a survey in a new tab"
          target="_blank"
          rel="noopener noreferrer"
        >
          Share with a colleague
        </a>
      </div>
    </UserProfileCont>
  );
}

const UserProfileCont = styled.div`
  .button {
    width: 100%;
    margin: 20px auto 16px;
  }

  .bottom_note {
    text-align: left;
    font-size: 14px;
    font-style: italic;
    color: ${colours.inputsColour};
  }

  .other_setup_options {
    padding: 10px 0 20px;
    background-color: ${colours.lightBgColour};
    border-radius: 20px;

    p {
      color: ${colours.fontColour};
      font-weight: ${({ theme: { typography } }) => typography.semibold};
    }

    span {
      margin: auto 8px;
    }
  }
`;
