import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { SpecialEnquiryFormType, PricingCountry } from 'types';
import { isSanFranLandingPage } from 'utils/url';
import { useSearchParams } from 'hooks/useSearchParams';
import { isEmpty } from 'utils';
import { filterKeys } from 'utils/object';
import useEventListener from 'hooks/useEventListener';
import { debounce } from 'utils/fn';

import Block from 'components/block';
import Checkbox from 'components/checkbox';
import ErrorMessage from 'components/errormessage';
import Field from 'components/field';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import typography from 'css/base/_typography.module.scss';

import CVSuccessIcon from 'images/upload-cv-success.svg';

import { FieldItem, getFieldGroupKeys, getFormInfo, validate } from './helper';

const TIME_BEFORE_INIT_FORCED = 15000; // after 15s we run initialize if it hasn't been run yet

export default function SpecialEnquiryForm() {
  const [form, setForm] = useState<SpecialEnquiryFormType>({});
  const onInitCalled = useRef(false);
  // const paramsInFormRef = useRef<Record<string, string | undefined>>({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { searchParams, resetSearchParams } = useSearchParams();

  const location = useLocation<{ pricingType?: PricingCountry }>();
  const pricingType = location.state?.pricingType;
  const isSanFranPage = isSanFranLandingPage();
  const formType = isSanFranPage ? 'san-fran' : 'standard';
  const { fieldGroups, checkbox, buttonText, title, onApply, onInit, getConfirmationProps } =
    useMemo(() => getFormInfo(formType), [formType]);
  const fieldGroupKeys = useMemo(() => getFieldGroupKeys(fieldGroups), [fieldGroups]);
  const paramsInForm = useMemo(() => filterKeys(fieldGroupKeys)(searchParams), []);
  const confirmationProps = getConfirmationProps({ pricingType });

  const handleInit = useCallback(
    debounce(() => {
      if (!onInit || onInitCalled.current || isEmpty(paramsInForm)) return;

      const initialize = async () => {
        onInitCalled.current = true;
        await onInit({ ...paramsInForm });
      };

      initialize();
    }, 200),
    [onInit, paramsInForm]
  );

  useEffect(() => {
    if (!searchParams) return;

    Object.entries(paramsInForm).forEach(([key, value]) => {
      setForm(prevForm => ({ ...prevForm, [key as keyof SpecialEnquiryFormType]: value }));
    });

    resetSearchParams();
    setTimeout(() => {
      handleInit();
    }, TIME_BEFORE_INIT_FORCED);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleInit, 200));

    return () => {
      window.removeEventListener('scroll', handleInit);
    };
  }, [onInit, paramsInForm]);

  useEventListener('scroll', debounce(handleInit, 200));

  const submit = async () => {
    setLoading(true);

    const validationMessage = validate(form, fieldGroupKeys);

    if (!validationMessage) {
      const { status } = await onApply(form);

      if (status === 'success') {
        setSubmitted(true);
        return;
      }
    }

    setErrorMessage(validationMessage || 'Something went wrong. Try again.');
    setLoading(false);
  };

  const renderFieldGroup = (fieldGroup: FieldItem[]) =>
    fieldGroup.map(({ key, label, placeholder }) => (
      <Field
        key={key}
        label={label}
        attributes={{ 'aria-label': label.toLowerCase() }}
        placeholder={placeholder}
        value={(form[key] as string) || ''}
        onChange={value => setForm({ ...form, [key]: value })}
      />
    ));

  if (submitted) {
    const { description, button, title } = confirmationProps;
    return (
      <ConfirmationBlock
        title={[<img key="submitted" src={CVSuccessIcon} alt={title} width="70" />, title]}
        description={description}
        button={button}
        className="center_alignment"
      />
    );
  }

  return (
    <FormBlock
      title={title}
      button={{
        text: buttonText,
        action: submit,
        loading,
        className: 'twenty_px_font'
      }}
    >
      {fieldGroups.map((fieldGroup, idx) => {
        if (fieldGroup.length === 1) return renderFieldGroup(fieldGroup);

        return (
          <div key={`field_row_${idx}`} className="field_row two_cols">
            {renderFieldGroup(fieldGroup)}
          </div>
        );
      })}
      <ConditionalRender predicate={checkbox}>
        <Checkbox onChange={checked => setForm({ ...form, confirm: checked })} {...checkbox!} />
      </ConditionalRender>
      <ConditionalRender predicate={errorMessage}>
        <ErrorMessage type="critical">{errorMessage}</ErrorMessage>
      </ConditionalRender>
    </FormBlock>
  );
}

const FormBlock = styled(Block)`
  margin-top: 0;
  padding: 24px 32px 32px;

  > h2 {
    text-align: center;
  }

  .field {
    padding: 8px 0;
  }

  .checkbox_wrapper {
    margin: 8px 0 24px;
  }

  .button {
    width: 100%;
  }
`;

export const ConfirmationBlock = styled(Block)`
  padding: 40px 32px;

  img {
    margin: 0 auto 24px;
    display: block;
  }

  .block_description {
    margin-bottom: 32px;
    font-size: ${typography.smallheader};
  }
`;
