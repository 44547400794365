import React, { PropsWithChildren } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import Tooltip, { Props as TooltipProps, TooltipWrapper } from 'components/tooltip';

import NotificationCounterBubble, {
  NotificationCounterBubbleProps
} from 'v2/components/ui/atoms/NotificationCounterBubble';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

type MenuIconProps = {
  className: string;
  icon: string;
  tooltip?: TooltipProps;
  count?: NotificationCounterBubbleProps['count'];
} & Pick<NavLinkProps, 'to' | 'onClick'>;

export default function MenuIcon({
  className,
  icon,
  tooltip,
  count,
  to,
  children,
  onClick
}: PropsWithChildren<MenuIconProps>) {
  return (
    <MenuLink className={ClassNames('menu_icon', className)} to={to} onClick={onClick}>
      <Icon className={icon}>
        <ConditionalRender predicate={tooltip?.text}>
          <Tooltip {...tooltip} />
        </ConditionalRender>
      </Icon>
      <NotificationCountBubble count={count} />
      {children}
    </MenuLink>
  );
}

export const MENU_ICON_PADDING = '0.75rem';

export const MENU_ICON_FONT_SIZE = '1.5rem';

export const MenuIconStyling = css`
  ${({ theme: { typography, media } }) => css`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${MENU_ICON_PADDING};
    border-radius: 1.5rem;

    [class*='icon_'] {
      font-weight: ${typography.black};
      font-size: ${MENU_ICON_FONT_SIZE};
      color: var(--text-accent-secondary-base);
    }

    &:hover {
      background: var(--background-menuitem-hover);
    }

    &.active {
      background: var(--background-menuitem-selected);
    }

    ${media.tinyMobile`
      padding: 0.5rem;
    `}
  `}
`;

const MenuLink = styled(NavLink)`
  ${MenuIconStyling}
`;

const Icon = styled(TooltipWrapper)`
  .tooltip .badge {
    margin-right: 0;
  }
`;

const NotificationCountBubble = styled(NotificationCounterBubble)`
  position: absolute;
  top: 2px;
  right: 0;
  margin-left: 0;
`;
