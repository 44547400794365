import React, { ReactNode, useEffect, useState } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { mobile } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';

import ErrorMessage from 'components/errormessage';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

type Props = {
  name?: string;
  options: {
    disabled?: boolean;
    errorMessage?: string;
    id?: string;
    label: string;
    subLabel?: ReactNode;
    desc?: string;
    helpTooltip?: { text: string; link?: string; target?: string };
    value: string | number | boolean;
  }[];
  selected?: string | number | boolean | Object;
  selectObject?: boolean;
  view?: 'tiles' | 'list';
  className?: string;
  onSelect?: (value: string | number | boolean | Object) => void;
};

type StyledLabel = Pick<Props, 'selected' | 'view'>;

function RadioButtons({
  selected,
  onSelect,
  selectObject = false,
  options,
  name,
  view = 'list',
  className
}: Props) {
  const [selectedOption, setSelectedOption] = useState(selected);

  const darkMode = useDarkMode();

  const onChangeOption = (label: string, value: string | number | boolean) => {
    setSelectedOption(value || label);

    if (onSelect && selectObject) onSelect({ label, value });
    else if (onSelect) onSelect(value === undefined ? label : value);
  };

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <>
      {options.map(({ label, subLabel, desc, id, value, helpTooltip, errorMessage, disabled }) => {
        const checked = label === selectedOption || value === selectedOption;

        return (
          <Label
            aria-label={label}
            aria-disabled={disabled}
            className={ClassNames('radio_button', className, {
              selected: !disabled && checked,
              dark: darkMode,
              disabled
            })}
            key={id || value.toString()}
            selected={checked}
            aria-selected={checked}
            view={view}
          >
            {label}
            {helpTooltip && helpTooltip.text && (
              <a
                href={helpTooltip.link ? helpTooltip.link : '#'}
                target={helpTooltip.target || '_self'}
                className="icon_help"
              >
                <Tooltip text={helpTooltip.text} position="right" />
              </a>
            )}
            {errorMessage && <ErrorMessage type="critical">{errorMessage}</ErrorMessage>}
            {subLabel && <span className="sub_label">{subLabel}</span>}
            <ConditionalRender predicate={desc}>
              <div className="desc">{desc}</div>
            </ConditionalRender>
            <input
              disabled={disabled}
              type="radio"
              id={id || value.toString()}
              value={label}
              name={name}
              onChange={() => {
                if (disabled) {
                  return undefined;
                }
                onChangeOption(label, value);
              }}
              checked={checked}
            />
            <span className={ClassNames('checkmark', { dark: darkMode, disabled })} />
          </Label>
        );
      })}
    </>
  );
}

export default RadioButtons;

const Label = styled.label<StyledLabel>`
  display: block;
  position: relative;
  margin: 10px 0;
  max-width: 390px;
  font-size: 16px;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none;
  }

  ${props =>
    props &&
    props.view === 'tiles' &&
    css`
      font-size: 18px;
      font-weight: ${({ theme: { typography } }) => typography.regular};
      padding: 18px 20px 20px 50px;
      box-shadow: 0 0 15px -5px ${colours.dropshadow};
      border-radius: 10px;

      &:hover {
        background-color: ${colours.lightBgColour};
        box-shadow: 0 0 20px -5px ${colours.dropshadow};
        outline: none;

        input ~ .checkmark {
          background-color: white;
        }
      }
    `}

  ${props =>
    props &&
    props.view !== 'tiles' &&
    !props.selected &&
    css`
      &:hover input ~ .checkmark {
        border: 2px solid;
        outline: none;

        &:after {
          display: block;
          opacity: 0.3;
        }
      }
    `}

  ${props =>
    props &&
    props.selected &&
    props.view !== 'tiles' &&
    css`
      font-weight: ${({ theme: { typography } }) => typography.semibold};
    `}

  ${props =>
    props &&
    props.selected &&
    props.view === 'tiles' &&
    css`
      background-color: ${colours.primaryColour};
      color: white;
      font-weight: ${({ theme: { typography } }) => typography.semibold};

      &:hover,
      &:focus {
        background-color: ${colours.primaryColour};
        font-weight: ${({ theme: { typography } }) => typography.regular};
        outline: none;
      }
    `}

  @media only screen and (max-width: ${mobile}) {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  input {
    position: absolute;
    height: auto;
    width: auto;
    line-height: 1.3;
    opacity: 0;
    cursor: pointer;

    &:focus ~ .checkmark {
      border: 2px solid;
      outline: none;

      &:after {
        display: block;
      }
    }

    &:checked ~ .checkmark {
      ${props =>
        props &&
        props.view === 'tiles' &&
        css`
          background-color: white;
        `}

      ${props =>
        props &&
        props.view !== 'tiles' &&
        css`
          border: 2px solid;
        `}

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      border-radius: 50%;
    }

    ${props =>
      props &&
      props.view === 'tiles' &&
      css`
        top: calc(50% - 12px);
        left: 15px;
        height: 25px;
        width: 25px;
        background-color: ${colours.lightBgColour};

        &:after {
          top: 5px;
          left: 5px;
          width: 15px;
          height: 15px;
          background: ${colours.primaryColour};
        }
      `}

    ${props =>
      props &&
      props.view !== 'tiles' &&
      css`
        box-sizing: border-box;
        width: 21px;
        height: 21px;
        border: 1px solid;
        left: 0;
        top: calc(50% - 10px);

        &:after {
          top: 3px;
          left: 3px;
          width: 11px;
          height: 11px;
          background: ${colours.fontColour};
        }

        &.dark:after {
          background: ${colours.lightFontColour};
        }
      `}

    &.disabled {
      &:after,
      &:focus {
        outline: none;
        background-color: ${colours.lightBgColour};
      }
    }
  }

  ${props =>
    props &&
    props.view === 'tiles' &&
    css`
      &:hover input ~ .checkmark,
      &:focus input ~ .checkmark {
        background-color: white;
        outline: none;
      }
    `}

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
