import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { DeepReadonly } from 'types';
import { mapObject, pick, pluck, reduceObject } from 'utils/object';
import {
  CandidatesInStreamCount,
  PositionViewConversationsGraph,
  UserScope
} from 'v2/services/fetchers/company/positionview';

import {
  DataLoadState,
  PositionsState,
  PositionViewData,
  PositionViewLoadQueue
} from './positions.types';

const selectPositions = (state: RootState): DeepReadonly<PositionsState> => state.positions;

const selectFresh = (userScope: UserScope) =>
  createSelector(selectPositions, positions => positions.fresh[userScope]);

const selectCached = (userScope: UserScope) =>
  createSelector(selectPositions, positions => positions.cached[userScope]);

export const selectActivePositionsTab = createSelector(
  selectPositions,
  positions => positions.activeTab
);

const selectLoadStates = (userScope: UserScope) =>
  createSelector(selectFresh(userScope), fresh => fresh.loadStates);

export const selectTypeOfLoadState = (userScope: UserScope) => (type: keyof DataLoadState) =>
  createSelector(selectLoadStates(userScope), loadStates =>
    reduceObject(
      loadStates,
      (acc, loadState, key) => ({ ...acc, [key]: loadState[type] }),
      {} as Record<PositionViewData, boolean>
    )
  );

export const selectPerListingLoadState = (listingID: number) => (userScope: UserScope) =>
  createSelector(
    selectLoadStates(userScope),
    loadStates => !!loadStates.candidateCount.perListing?.[listingID]
  );

export const selectPositionsLoader = (userScope: UserScope) => (typeKey: PositionViewData) =>
  createSelector(selectLoadStates(userScope), loadStates => loadStates[typeKey].loading);

export const selectPositionsHasFetched = (userScope: UserScope) => (typeKey: PositionViewData) =>
  createSelector(selectLoadStates(userScope), loadStates => loadStates[typeKey].hasFetched);

export const selectPositionsHasNextPage = (userScope: UserScope) => (typeKey: PositionViewData) =>
  createSelector(selectLoadStates(userScope), loadStates => !!loadStates[typeKey].hasNextPage);

export const selectListings = (userScope: UserScope) => {
  const selectFreshListings = selectFresh(userScope);
  const selectCachedListings = selectCached(userScope);

  return createSelector(selectFreshListings, selectCachedListings, (fresh, cached) => {
    return fresh.listings?.values || cached.listings?.values;
  });
};

export const selectListingIDs = (userScope: UserScope) =>
  createSelector(selectListings(userScope), listings =>
    (listings || []).map(listing => listing.listingID)
  );

export const selectActivity = (userScope: UserScope) => {
  const selectFreshActivity = selectFresh(userScope);
  const selectCachedActivity = selectCached(userScope);

  return createSelector(selectFreshActivity, selectCachedActivity, (fresh, cached) => {
    return fresh.activity || cached.activity;
  });
};

export const selectFreshActivityIDs = (userScope: UserScope) => {
  const selectFreshActivity = selectFresh(userScope);

  return createSelector(selectFreshActivity, fresh => {
    return Object.keys(fresh.activity || {}).map(Number);
  });
};

export const selectActivityForListing = (userScope: UserScope, listingID: number) => {
  return createSelector(selectActivity(userScope), activity => activity?.[listingID]);
};

export const selectConversationsForListing = (userScope: UserScope, listingID: number) => {
  return createSelector(
    selectConversations(userScope),
    conversations => conversations?.[listingID]
  );
};

export const selectMaxConversations = (userScope: UserScope) => {
  return createSelector(selectConversations(userScope), conversations => {
    const conversationCounts = [] as number[];
    mapObject(conversations as PositionViewConversationsGraph, value =>
      value.map(({ conversationCount }) => conversationCounts.push(conversationCount))
    );
    return Math.max(...conversationCounts);
  });
};

export const selectConversations = (userScope: UserScope) => {
  const selectFreshConversations = selectFresh(userScope);
  const selectCachedConversations = selectCached(userScope);

  return createSelector(selectFreshConversations, selectCachedConversations, (fresh, cached) => {
    return fresh.conversations || cached.conversations;
  });
};

export const selectStreams = (userScope: UserScope) => {
  const selectFreshStreams = selectFresh(userScope);
  const selectCachedStreams = selectCached(userScope);

  return createSelector(selectFreshStreams, selectCachedStreams, (fresh, cached) => {
    return fresh.streams || cached.streams;
  });
};

export const selectStreamsForListing = (userScope: UserScope, listingID: number) => {
  return createSelector(selectStreams(userScope), streams => {
    return streams?.[listingID] || [];
  });
};

export const selectCandidateCount = (userScope: UserScope) => {
  const selectFreshCandidateCount = selectFresh(userScope);
  const selectCachedCandidateCount = selectCached(userScope);

  return createSelector(selectFreshCandidateCount, selectCachedCandidateCount, (fresh, cached) => {
    return fresh.candidateCount || cached.candidateCount;
  });
};

export const selectCachedCandidateCount = (userScope: UserScope) =>
  createSelector(selectFresh(userScope), pluck('candidateCount'));

export const selectCandidateCountForStreamsInListing = (
  userScope: UserScope,
  listingID: number,
  feedIDs: number[]
) =>
  createSelector(selectCandidateCount(userScope), candidateCount => {
    const count = candidateCount?.[listingID];
    if (count === undefined) return count;
    return pick(feedIDs)(count) as CandidatesInStreamCount;
  });

export const selectFilterParams = createSelector(selectPositions, positions => positions.params);

export const selectFilterParamsByName = (name: keyof PositionsState['params']) =>
  createSelector(selectFilterParams, params => params[name]);

export const selectHasRetrievedCached = createSelector(
  selectPositions,
  positions => positions.cached?.hasRetrievedCached
);

export const selectIsListingsReloading = createSelector(
  selectPositions,
  positions => !!positions.fresh.isReloading
);

export const selectNbrOfFreshListings = (userScope: UserScope) =>
  createSelector(selectFresh(userScope), fresh => fresh.listings?.values?.length || 0);

export const selectLoadQueue = (userScope: UserScope, typeKey: keyof PositionViewLoadQueue) =>
  createSelector(selectFresh(userScope), fresh => fresh.loadQueue[typeKey]);
