import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useCandidateSearchOptions, SearchCategory } from 'store';

import SearchOptionList, { SearchOption } from 'v2/components/ui/molecules/SearchOptionList';

import SavedSearches from '../SavedSearches';
import { useSearchBarContext } from '../../SimpleSearchBar.context';

import { SEARCH_CATEGORIES } from './AutoSuggest.helpers';

const SKELETON_PROPS = { labelWidth: 200, countWidth: 18, height: 20, count: 12 };

export default function AutoSuggest() {
  const [selectedSearchCategory, setSelectedSearchCategory] = useState<SearchCategory>('jobTitles');
  const { searchOptionsByCategory } = useCandidateSearchOptions();
  const { startNewSearch } = useSearchBarContext();

  const searchOptions = useMemo(
    () => searchOptionsByCategory?.[selectedSearchCategory],
    [searchOptionsByCategory, selectedSearchCategory]
  );

  return (
    <AutoSuggestFlexbox className="auto_suggest">
      <AutoSuggestCategories className="auto_suggest_categories">
        <div className="search_categories_section">
          <SearchCategoriesSectionTitle className="section_title small_text">
            Search by
          </SearchCategoriesSectionTitle>
          <SearchOptionList<SearchCategory>
            searchOptions={SEARCH_CATEGORIES}
            selected={selectedSearchCategory}
            sidePadding="1rem"
            onClick={category => setSelectedSearchCategory(category)}
          />
        </div>
        <SavedSearches />
      </AutoSuggestCategories>
      <AutoSuggestOptionList
        searchOptions={searchOptions}
        loadingState={{ loading: !searchOptions, skeletonProps: SKELETON_PROPS }}
        onClick={(_, option) => startNewSearch(option as SearchOption)}
        maxHeight="400px"
      />
    </AutoSuggestFlexbox>
  );
}

const AutoSuggestFlexbox = styled.div`
  display: flex;
  gap: 1rem;
`;

const AutoSuggestCategories = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SearchCategoriesSectionTitle = styled.h3`
  margin: 0.5rem 0 0.5rem 1rem;
`;

const AutoSuggestOptionList = styled(SearchOptionList)`
  .search_option_label_container {
    gap: 0.5rem;

    .search_option_label {
      font-weight: ${({ theme: { typography } }) => typography.regular};
    }
  }
`;
