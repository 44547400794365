import uiReducer, { actions as uiActions } from './ui';

export const {
  decrementCompaniesToVerify,
  removeCandidateTourBubble,
  setCandidateTour,
  setCandidatesToVerify,
  setCompaniesToVerify,
  setCSSReduxVariable,
  setDarkMode,
  setDemoCompanyTour,
  setDemoTourBubbleShown,
  setInitialSetupMode,
  setLoader,
  setToggler,
  setReceivedRequestsPending,
  setSetupMode,
  toggleInitialSetupMode,
  toggleSessionEndedPopup,
  toggleSetupMode,
  toggleShowCandidateTour,
  toggleShowOfflineNotification,
  toggleShowReactivationModal,
  toggleShowStartUsingCordPopup
} = uiActions;

export * from './ui.selectors';
export * from './ui.thunks';
export * from './ui.hooks';

export type {
  UIState,
  DemoCompanyTour,
  CandidateBubbleName,
  CandidateTour,
  DemoCompanyTourPage
} from './ui';

export default uiReducer;
