import React from 'react';
import styled from 'styled-components';

import ErrorMessage from 'components/errormessage';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { useTextEnhancer } from '../../TextEnhancer.context';

const Error = () => {
  const { errorMessage } = useTextEnhancer();
  return (
    <ConditionalRender predicate={errorMessage}>
      <StyledErrorMessage type="critical" align="center">
        <span className="icon_warning" />
        {errorMessage}
      </StyledErrorMessage>
    </ConditionalRender>
  );
};

const StyledErrorMessage = styled(ErrorMessage)`
  display: flex;
  align-items: center;
  font-size: 14px;

  button {
    margin-left: 0.125em;
    color: ${({ theme }) => theme.colours.errorColour} !important;
  }

  .icon_warning {
    font-size: 16px;
    margin-right: 0.25em;
  }
`;

export default Error;
