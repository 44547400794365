import { createSlice } from '@reduxjs/toolkit';
import { JobTitleOption } from '@cohiretech/common-types';

import { fetchJobTitleOptions } from './common.thunks';

const commonSlice = createSlice({
  name: 'common',
  initialState: { jobTitleOptions: [] as JobTitleOption[] },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchJobTitleOptions.fulfilled, (state, { payload }) => {
      state.jobTitleOptions = payload;
    });
  }
});

export default commonSlice.reducer;
