import { isEmpty } from 'utils';

export function flattenDependencies<T extends Record<string, unknown>>(array: T[]): string {
  if (isEmpty(array)) return '';
  const keys = Object.keys(array[0]) as (keyof T)[];

  const comparisonFunction = (a: T, b: T): number => {
    for (const key of keys) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
    }
    return 0;
  };

  const sortedArray = [...array].sort(comparisonFunction);
  const flattenedArray = sortedArray.map(obj =>
    keys.reduce((acc, cur) => `${String(acc)}${(obj as T)[cur]}-`, '')
  );

  return flattenedArray.join();
}
