import React from 'react';
import styled, { css } from 'styled-components';

import Toggle, { ToggleProps } from 'components/toggle';

type LabelToggleProps = Pick<
  ToggleProps,
  'size' | 'type' | 'onToggle' | 'active' | 'description' | 'title'
>;

const LabelToggle = ({
  onToggle,
  active,
  description,
  size = 'xsmall',
  type = 'normal'
}: LabelToggleProps) => {
  return (
    <LabelToggleCont on={active} className="label_toggle">
      <Toggle
        type={type}
        size={size}
        active={active}
        onToggle={onToggle}
        offColour="grey"
        description={description}
      />
    </LabelToggleCont>
  );
};

const LabelToggleCont = styled.div<{ on: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  font-weight: ${({ theme: { typography } }) => typography.bold};
  ${({ on, theme: { typography } }) => css`
    font-size: ${typography.mobile};
    color: ${on ? 'var(--text-accent-primary-base)' : 'var(--text-accent-tertiary-base)'};
    background: ${on
      ? 'var(--background-accent-primary-soft)'
      : 'var(--background-accent-tertiary-soft)'};

    .toggle_wrapper {
      margin: 0.25rem 0 0.25rem 0.25rem;
      padding: 0.125rem;
    }

    .toggle_parent {
      .toggle_label {
        margin-right: 0.5rem;
        padding: 0;
      }
    }
  `};
`;

export default LabelToggle;
