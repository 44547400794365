import styled, { css } from 'styled-components';

import DialogWindow from 'components/dialogwindow';
import { StepsRightPanel } from 'components/dialogwindow/DialogWindow.styled';

import SparklesLoader from 'images/animations/SparklesLoader';

export const CandidateATPositionPopupStyling = css`
  &.pop_up_cont .pop_up_dialog {
    ${({ theme: { typography, media } }) => css`
      display: flex;
      flex-direction: column;

      .pop_up_title {
        margin-bottom: 0.25rem;
      }

      .pop_up_sub_title {
        margin: 0.25rem auto 1rem;
      }

      .position_item {
        ${PositionCardStyling}
      }

      .field {
        padding: 0.5rem 0;

        .question {
          margin-bottom: 0.5rem;
        }

        input,
        .dropdown_wrapper {
          font-size: ${({ theme: { typography } }) => typography.normal};
        }
      }

      ${StepsRightPanel}, .buttons_wrapper {
        .link {
          padding: 0.75rem 1.25rem;
          font-weight: ${typography.semibold};
          color: var(--text-accent-tertiary-base);
          border-bottom: none;
        }

        .button {
          margin: 0;
          font-size: var(--type-sm-md);

          .icon {
            margin-right: 0.5rem;
            font-size: var(--type-md);
          }
        }
      }

      ${media.mobileMin`
        .pop_up_title {
          font-size: 2rem;
        }
      `}
    `}
  }
`;

export const ImportATSPositionPopup = styled(DialogWindow)`
  &#import_ats_position_popup {
    ${CandidateATPositionPopupStyling}
  }
`;

export const TopLoader = styled(SparklesLoader)`
  margin: 0 auto 0.25rem;
`;

export const PositionCardStyling = css`
  width: 100%;
  margin: 0.5rem auto 0;

  .card_elements_wrapper {
    box-shadow: var(--drop-shadow-soft);
  }
`;
