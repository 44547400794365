import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useLocation } from 'react-router';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { noop } from 'utils/fn';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import SavedSearches from 'v2/components/SimpleSearchBar/components/SavedSearches';
import ClickDetector from 'v2/components/utility/ClickDetector';

import CandidateTourBubble from '../CandidateTourBubble';

import SearchBarProvider, { useSearchBarContext } from './SimpleSearchBar.context';
import SearchBarControls from './components/SearchBarControls';
import AutoSuggest, { MobileAutoSuggest } from './components/AutoSuggest';
import AutoComplete from './components/AutoComplete';

function SimpleSearchBar() {
  const isMobile = useMedia([mediaQueries.mobile]);
  const location = useLocation();

  const { focused, searchValue, currentTab, savedSearchToDelete, setFocused } =
    useSearchBarContext();
  const showDeleteSavedSearchPopup = !!savedSearchToDelete;

  const renderSearchBarContent = () => {
    if (currentTab === 'saved') return <SavedSearches />;
    if (searchValue) return <AutoComplete />;
    return isMobile ? <MobileAutoSuggest /> : <AutoSuggest />;
  };

  return (
    <SearchBarWrapper className="simple_search_bar_wrapper" focused={focused}>
      <ClickDetector onClickOutside={showDeleteSavedSearchPopup ? noop : () => setFocused(false)}>
        <SearchBar className="simple_search_bar" focused={focused}>
          <SearchBarControls />
          <ConditionalRender predicate={focused}>{renderSearchBarContent()}</ConditionalRender>
        </SearchBar>
      </ClickDetector>
      <ConditionalRender predicate={new RegExp(`/candidate/search`).test(location.pathname)}>
        <CandidateTourBubble bubbleName="search" align="center" centeredOnMobile />
      </ConditionalRender>
    </SearchBarWrapper>
  );
}

export default function SearchBarWithContext() {
  return (
    <SearchBarProvider>
      <SimpleSearchBar />
    </SearchBarProvider>
  );
}

const SearchBarWrapper = styled.div<{ focused: boolean }>`
  ${({ focused }) =>
    focused &&
    css`
      position: fixed;
      z-index: 102;
      width: 100%;
      height: 100%;
      background-color: var(--background-popup-overlay);
      top: 0;
      left: 0;
    `}
`;

const SearchBar = styled.div<{ focused: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: ${({ focused }) => (focused ? '2.5rem' : '2rem')};

  ${({ focused }) => focused && ActiveSearchBar}
`;

const fadeIn = keyframes`
  0% { 
    opacity: 0; 
    transform: scale(0.95);
  }

  100% { 
    opacity: 1; 
    transform: scale(1);
  }
`;

const ActiveSearchBar = css`
  background: var(--background-block-primary);
  position: fixed;
  width: 640px;
  padding: 1rem;
  left: 50%;
  translate: -50%;
  box-shadow: var(--drop-shadow-focused);
  animation: ${fadeIn} 0.2s ease-out 0.6;

  ${({ theme: { media } }) => media.mobile`
    width: 100%;
    height: 100%; 
    box-sizing: border-box;
    border-radius: 0;

    // Override the default styles
    .tabs_wrapper {
      white-space: normal;
      overflow: visible;
    }
  `}
`;
