import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import { HiresJobTitleConversationStatistics } from '@cohiretech/common-types';

import { getHiresGraphData } from 'fetcher';
import { getJobTitleOptions } from 'v2/services/fetchers/public/jobTitles';
import { isEmpty, debounce } from 'utils';
import { mobile } from 'responsiveConfig';

import AutoComplete from 'components/autocomplete';
import ErrorMessage from 'components/errormessage';
import Loader from 'components/loader';
import MultiSeries from 'components/multiseries';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import functions from 'css/base/_functions.module.scss';
import typography from 'css/base/_typography.module.scss';
import colours from 'css/base/_colours.module.scss';

import getHiresGraphProps from './hiresGraphUtils';

export type GraphData = Omit<HiresJobTitleConversationStatistics, 'jobTitle'>;

type JobTitleItem = {
  label: string;
  value: string;
  tags: string[];
};

// PHASE: ROI
// type CompanySizeItem = {
//   label: string;
//   value: string;
// } | null;

// maxWrapperWidth is based on min_view width of 750px
const maxGraphWrapperWidth = 750;
const mobileGraphWrapperPaddingX = 60;
const graphWrapperPaddingX = 110;
const maxGraphWidth = maxGraphWrapperWidth - graphWrapperPaddingX;

// PHASE 2: ROI
// const companySizeOptions = [
//   { label: 'All company sizes', value: 'all' },
//   ...getCompanySizeOptions().map(option => { return { label: option, value: option }; })
// ];

const HiresGraph = ({ darkMode }: { darkMode: boolean }) => {
  const [availableJobTitles, setAvailableJobTitles] = useState<JobTitleItem[]>([]);
  const [error, setError] = useState('');
  const [graphData, setGraphData] = useState<GraphData | null>(null);
  const [loading, setLoading] = useState(true);
  const allPositionsItem = { label: 'All positions', value: '', tags: ['All positions'] };
  const [selectedJobTitle, setSelectedJobTitle] = useState<JobTitleItem>(allPositionsItem);
  // const [selectedSize, setSelectedSize] = useState<CompanySizeItem>(null);
  const [graphWidth, setGraphWidth] = useState(0);

  const graphWrapperRef = useRef(document.createElement('div'));
  const graphWidthRef = useRef(graphWidth);
  graphWidthRef.current = graphWidth;

  useEffect(() => {
    fetchPeopleSourcedGraphData(selectedJobTitle);
  }, []);

  useLayoutEffect(() => {
    adjustGraphWidth();

    const onWindowResize = () => {
      const graphWrapperSquashed = graphWrapperRef.current.offsetWidth < maxGraphWrapperWidth;
      const graphNotMaxWidth = graphWidthRef.current < maxGraphWidth;

      if (graphWrapperSquashed || graphNotMaxWidth) adjustGraphWidth();
    };

    const debouncedOnWindowResize = debounce(onWindowResize, 50);

    window.addEventListener('resize', debouncedOnWindowResize);
    return () => window.removeEventListener('resize', debouncedOnWindowResize);
  }, []);

  const adjustGraphWidth = () => {
    const graphWrapperWidth = graphWrapperRef.current.offsetWidth;

    const padding =
      window.innerWidth <= Number(functions.mobileMaxWith)
        ? mobileGraphWrapperPaddingX
        : graphWrapperPaddingX;
    setGraphWidth(graphWrapperWidth - padding);
  };

  const fetchPeopleSourcedGraphData = async (jobTitle: JobTitleItem) => {
    if (!loading) setLoading(true);

    const res = await getHiresGraphData(jobTitle.value);

    if (res.status === 'success') {
      const { statistics, jobTitles } = res.data;
      setGraphData(statistics);

      if (isEmpty(availableJobTitles)) {
        const fetchedJobTitles = await getAvailableJobTitles(jobTitles);
        setAvailableJobTitles([allPositionsItem, ...fetchedJobTitles]);
      }
    } else {
      setError("Couldn't get data. Try to refresh the page.");
    }
    setLoading(false);
  };

  const getAvailableJobTitles = async (fetchedJobTitles: string[]) => {
    const allJobTitles = (await getJobTitleOptions()) || [];

    return allJobTitles.filter(jobTitle => fetchedJobTitles.includes(jobTitle.value));
  };

  const onJobTitleSelect = (jobTitle: JobTitleItem) => {
    setSelectedJobTitle(jobTitle);
    setError('');
    fetchPeopleSourcedGraphData(jobTitle);
  };
  // PHASE 2: ROI
  // const onCompanySizeSelect = (size: CompanySizeItem) => {
  //   setSelectedSize(size);
  //   setError('');
  //   fetchPeopleSourcedGraphData(selectedJobTitle, size);
  // };

  return (
    <GraphWrapper
      ref={graphWrapperRef}
      className="people_sourced_graph_wrapper"
      darkMode={darkMode}
    >
      <Header>
        <h3>Typical cost per hire on cord*</h3>
        <div className="graph_filters">
          {/* PHASE 2: ROI */}
          {/* <Dropdown
            id="company_size"
            className={ClassNames('blue_border_dropdown', 'small_arrow')}
            selected={selectedSize?.value}
            selectMessage="Choose company size"
            list={companySizeOptions}
            onSelect={(item) => onCompanySizeSelect(item as CompanySizeItem)}
          /> */}
          <AutoComplete
            id="people_sourced_graph_dropdown"
            className="blue_border large"
            placeholder="Choose a position"
            list={availableJobTitles}
            selectedItems={[selectedJobTitle.value ? selectedJobTitle : '']}
            onSelect={jobTitleItem => onJobTitleSelect(jobTitleItem as JobTitleItem)}
            showSelectionInInput
            showListonFocus
            clearInputOnFocus
            disabled={loading}
            disabledPlaceholder={selectedJobTitle?.label || 'Choose a position'}
            searchThroughTags
            showArrow
          />
        </div>
      </Header>
      {graphData && !loading ? (
        // @ts-expect-error TS(2322) FIXME: Type '{ id: string; xAxisProps: { tickTotal: numbe... Remove this comment to see the full error message
        <MultiSeries
          className="people_sourced_graph"
          darkMode={darkMode}
          width={graphWidth}
          height={180}
          {...getHiresGraphProps(graphData)}
        />
      ) : (
        <Loader className="graph_loader" />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <ConditionalRender predicate={graphData}>
        <div className="graph_footnote">
          *The average cost per hire on cord based on{' '}
          <strong>
            {graphData?.hiredCount} hires reported
            {selectedJobTitle.label !== 'All positions'
              ? ` for ${selectedJobTitle?.label} positions `
              : ' '}
            in the last 12 months
          </strong>
          . The 25th and 75th percentiles are also shown on the graph.
        </div>
      </ConditionalRender>
    </GraphWrapper>
  );
};

export default HiresGraph;

const GraphWrapper = styled.div<{ darkMode: boolean }>`
  background-color: ${colours.fontColour};
  box-shadow: 0 0 20px -5px ${colours.darkDropshadow};

  padding: 30px ${graphWrapperPaddingX / 2}px;
  border-radius: 30px;

  .people_sourced_graph {
    margin: 35px 0 30px;
  }

  .graph_footnote strong {
    font-weight: ${typography.bold};
  }

  .loader_wrapper {
    height: 180px;
    margin: 35px 0 30px;
  }

  @media only screen and (max-width: ${mobile}) {
    padding: 25px ${mobileGraphWrapperPaddingX / 2}px;

    .people_sourced_graph {
      margin: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  font-size: ${typography.smallheader};

  .graph_filters {
    display: flex;
    gap: 12px;
    align-items: center;

    .dropdown_wrapper {
      width: 205px;

      .dropdown_selector {
        height: 55px;
      }

      .dropdown_list {
        background-color: ${colours.fontColour};
      }
    }
  }

  h3 {
    padding: 0;
    margin: 0;
    align-self: center;
    text-align: left;
  }

  .autocomplete_cont {
    width: 240px;
  }

  @media only screen and (max-width: ${mobile}) {
    flex-direction: column;
    margin: 0px;

    .graph_filters,
    .autocomplete_cont {
      width: 100%;
    }
  }
`;
