import React, { lazy, Suspense, useState } from 'react';
import { useLocation } from 'react-router';
import SanitizedHTML from 'react-sanitized-html';
import ClassNames from 'classnames';

import { Currency } from '@cohiretech/common-types';

import { useDarkMode } from 'hooks/useDarkMode';
import { identifyCompanyType } from 'v2/services/company/companyType';
import { isRemote } from 'v2/services/company/position';
import { toArray } from 'utils/array';
import { isDefined } from 'utils/fn';
import { editPosition } from 'fetcher';
import {
  getRemoteLocations,
  getVisaOptions,
  isEmpty,
  getCompanyRemoteWorkInfo,
  getSelectedSeniorityLabels
} from 'utils';
import { retryImport } from 'utils/fn/retry';
import { capitalizeFirst } from 'utils/string';
import { getVisibleSalaryRange } from 'v2/services/tools/salary';
import { ALLOWED_COMPANY_PASTE_TAGS } from 'consts';
import { useCandidatePro, useCompanyProfile } from 'store';

import Table from 'components/table';
import ErrorBoundary from 'components/errorboundary';
import Loader from 'components/loader';
import Tags from 'components/tags';
import Tooltip from 'components/tooltip';
import LinkWithIcon from 'components/linkwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import MessagePrompt from 'v2/components/ui/molecules/MessagePrompt';

import { getDataToRender } from './PositionProfile.helpers';
import { usePositionProfile } from './PositionProfile.context';
import InsightsCallout from './insightscallout';

const ProfileVideo = lazy(() => retryImport(() => import('components/profilevideo')));

export default function PositionOverview({ goToInsightsTab }: { goToInsightsTab: () => void }) {
  const notProps = usePositionProfile();
  const { members } = useCompanyProfile() || {};
  const { isCandidateProEnabled } = useCandidatePro();
  const {
    id,
    remote,
    companyType,
    companyID,
    seniorities,
    remoteDays,
    remoteFirstOption,
    jobTitles,
    techStack,
    primarySkills,
    secondarySkills,
    equityMax,
    equityMin,
    jobType,
    remoteLocationContinents,
    remoteLocationCountries,
    timezones,
    visaSponsorshipAvailable,
    visaSponsorship,
    videoURL,
    position,
    companyName,
    description,
    interviewProcess,
    relevantSkills,
    salaryVisible,
    salaryMin,
    salaryMax,
    currency,
    oteSalaryMin,
    oteSalaryMax,
    oteSalaryMaxUncapped,
    primaryMember,
    associatedMemberDetails,
    messagePrompt,
    showInsights,
    isCandidateCreatedATPosition
  } = notProps;
  const [videoRequested, setVideoRequested] = useState(false);
  const [videoErrorMessage, setVideoErrorMessage] = useState('');

  const location = useLocation();
  const darkMode = useDarkMode();

  const { isAgency } = identifyCompanyType(companyType);
  const isRemoteJob = isRemote(remote);

  const requestVideo = async () => {
    const { status } = await editPosition({ requestVideo: true }, id);

    if (status === 'success') {
      setVideoRequested(true);
    } else {
      setVideoErrorMessage('Something went wrong. Try again.');

      setTimeout(() => setVideoErrorMessage(''), 2000);
    }
  };

  const hideSalary = companyID?.toString() === process.env.REACT_APP_HIDDEN_COMPANY_SALARY_ID;
  const selectedSeniorities = getSelectedSeniorityLabels(seniorities);
  const selectedRemoteWork = getCompanyRemoteWorkInfo(
    remote,
    remoteDays,
    'up to',
    remoteFirstOption
  );

  const { employerInfo, benefits } = getDataToRender(notProps, isAgency, location);
  const remoteLocations = capitalizeFirst(
    getRemoteLocations(remoteLocationContinents, remoteLocationCountries)
  );
  const oteRange =
    oteSalaryMin &&
    getVisibleSalaryRange({
      currency,
      salaryMin: oteSalaryMin,
      salaryMax: oteSalaryMax,
      oteSalaryMaxUncapped,
      format: 'short'
    });
  const primaryMemberDetails =
    associatedMemberDetails || members?.find(({ id }) => id === primaryMember);

  return (
    <>
      <h1 className="title">Skills & Experience</h1>
      <Table bordered={false} highlightRowOnHover={false}>
        <tbody>
          <PositionSection
            value={jobTitles}
            title="Job roles"
            showAddButtonOnEmpty={isCandidateCreatedATPosition}
          />
          <PositionSection value={selectedSeniorities} title="Experience level" />
          <PositionSection value={techStack} title="Tech stack/tooling used" />
          <PositionSection
            value={primarySkills}
            title="Core skills considered"
            showAddButtonOnEmpty={isCandidateCreatedATPosition}
          />
          <PositionSection
            value={secondarySkills}
            title="Other skills considered"
            showAddButtonOnEmpty={isCandidateCreatedATPosition}
          />
        </tbody>
      </Table>
      <h1 className="title">Logistics</h1>
      <Table bordered={false} highlightRowOnHover={false}>
        <tbody>
          <ConditionalRender predicate={isCandidateCreatedATPosition ? currency : !hideSalary}>
            <tr>
              <td className="text_bold" width="220">
                Base salary:{' '}
              </td>
              <td>
                {salaryInformation(salaryMin, salaryMax, currency, salaryVisible)}
                <ConditionalRender predicate={salaryVisible && oteRange}>
                  <span className="grey_text"> ({oteRange} OTE)</span>
                </ConditionalRender>
                <ConditionalRender
                  predicate={
                    equityMin && equityMax && parseFloat(equityMin) + parseFloat(equityMax) !== 0
                  }
                >
                  <span>
                    {' '}
                    &nbsp; · &nbsp; {parseFloat(equityMin).toString()}% -{' '}
                    {parseFloat(equityMax).toString()}% Equity
                  </span>
                </ConditionalRender>
              </td>
            </tr>
          </ConditionalRender>
          <PositionSection value={jobType} title="Employment type" />
          <PositionSection value={selectedRemoteWork} title="Remote working" />
          <PositionSection
            value={remoteLocations}
            title="Remote locations"
            predicate={isRemoteJob && remoteLocationContinents && remoteLocationCountries}
          />
          <ConditionalRender predicate={!isEmpty(timezones) && isRemoteJob}>
            <tr>
              <td className="text_bold" width="220">
                Working Timezones:{' '}
              </td>
              <td>
                {timezones?.map(
                  (timezone: $TSFixMe, index: $TSFixMe) =>
                    `${timezone?.baseCity} +/- ${timezone?.timeOffset} hours${
                      index !== timezones.length - 1 ? ', ' : ''
                    }`
                )}
              </td>
            </tr>
          </ConditionalRender>
          <ConditionalRender predicate={!isCandidateCreatedATPosition}>
            <tr id="visa_question_row">
              <td className="text_bold" width="220">
                Visa sponsorship:{' '}
              </td>
              <td>{getVisaOptions(visaSponsorshipAvailable, visaSponsorship)}</td>
            </tr>
          </ConditionalRender>
        </tbody>
      </Table>
      <ConditionalRender predicate={isAgency}>
        <h1 className="title">About the employer</h1>
        <Table bordered={false} highlightRowOnHover={false}>
          <tbody>
            {employerInfo.map(({ header, value }) => (
              <ConditionalRender key={header} predicate={value}>
                <tr>
                  <td className="text_bold" width="220">
                    {header}:{' '}
                  </td>
                  <td>{value}</td>
                </tr>
              </ConditionalRender>
            ))}
          </tbody>
        </Table>
      </ConditionalRender>
      <ErrorBoundary>
        <Suspense fallback={<Loader className={ClassNames('medium', { dark: darkMode })} />}>
          {videoURL && (
            <ProfileVideo
              videoURL={videoURL}
              videoTitle={`${position} at ${companyName}`}
              onVideoRequest={() => requestVideo()}
              videoExamplesURL="https://www.notion.so/cordians/cord-Position-Videos-Everything-you-need-to-know-617ee2a77daa489e8682b976892e7146"
              videoRequested={videoRequested}
              videoErrorMessage={videoErrorMessage}
              darkMode={darkMode}
            />
          )}
        </Suspense>
      </ErrorBoundary>
      <ConditionalRender predicate={showInsights && isCandidateProEnabled}>
        <InsightsCallout goToInsightsTab={goToInsightsTab} />
      </ConditionalRender>
      <ConditionalRender predicate={description}>
        <>
          <h1 className="title">Job Description</h1>
          <SanitizedHTML
            className={ClassNames('pre_wrap_description', 'rich_editor_text')}
            allowedTags={ALLOWED_COMPANY_PASTE_TAGS}
            html={description}
          />
        </>
      </ConditionalRender>
      <ConditionalRender predicate={benefits}>
        <div className="section">
          <h3 className="sub_title">Company Benefits</h3>
          <SanitizedHTML
            className={ClassNames('pre_wrap_description', 'rich_editor_text')}
            allowedTags={ALLOWED_COMPANY_PASTE_TAGS}
            html={benefits}
          />
        </div>
      </ConditionalRender>
      {interviewProcess && (
        <div className="section">
          <h3 className="sub_title">Interview Process</h3>
          <SanitizedHTML
            className={ClassNames('pre_wrap_description', 'rich_editor_text')}
            allowedTags={ALLOWED_COMPANY_PASTE_TAGS}
            html={interviewProcess}
          />
        </div>
      )}
      <MessagePrompt
        messagePrompt={messagePrompt}
        primaryMemberDetails={primaryMemberDetails}
        appearance="section"
      />
      {relevantSkills && relevantSkills.length > 0 && (
        <div className="section">
          <h3 className="sub_title">Skills of candidates in conversation</h3>
          <Tags tags={relevantSkills} showAll />
        </div>
      )}
    </>
  );
}

type PositionSectionProps = {
  value: unknown | unknown[];
  predicate?: any;
  title: string;
  showAddButtonOnEmpty?: boolean;
};

const PositionSection = ({
  value,
  predicate,
  title,
  showAddButtonOnEmpty
}: PositionSectionProps) => {
  const { showEditCandidateATPositionPopup } = usePositionProfile();
  const shouldRender = isDefined(predicate) ? predicate : !isEmpty(value);

  if (!(shouldRender || showAddButtonOnEmpty)) return null;

  return (
    <tr>
      <td className="text_bold" width="220">
        {title}:{' '}
      </td>
      <td>
        <ConditionalRender
          predicate={shouldRender}
          fallback={
            <LinkWithIcon
              icon="icon_add"
              text={`Add ${title.replace(' considered', '').toLowerCase()}`}
              color="dark"
              action={showEditCandidateATPositionPopup}
            />
          }
        >
          {toArray(value).join(', ')}
        </ConditionalRender>
      </td>
    </tr>
  );
};

const salaryInformation = (
  salaryMin: number,
  salaryMax: number,
  currency: Currency,
  salaryVisible?: boolean
) => {
  if (salaryVisible === false) {
    return (
      <>
        Undisclosed
        <span className="icon_help">
          <Tooltip text="Some companies on cord are unable to disclose salaries publicly due to internal company policies. Message the company for salary information." />
        </span>
      </>
    );
  }

  return getVisibleSalaryRange({ currency, salaryMin, salaryMax, format: 'short' });
};
