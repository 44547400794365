import React from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { AdminAccount, DefaultCV, UserType } from '@cohiretech/common-types';

import { addURLProtocol } from 'utils';
import { trackEvent } from 'tracking-utils';
import { downloadCVFromURL } from 'v2/services/common';
import { useUserType } from 'store';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import InlineLoader from 'v2/components/ui/atoms/InlineLoader';
import CVDropdown from 'v2/components/CVDropdown';

type Props = {
  isApplicationStarted?: boolean;
  github?: string;
  website?: string;
  behance?: string;
  stackOverflow?: string;
  originalCVURL?: string;
  admin?: AdminAccount;
  isCandidate?: boolean;
  facebookURL?: string;
  twitterURL?: string;
  instagramURL?: string;
  linkedinURL?: string;
  linkedin?: string;
  colour?: string;
  isLoadingCordCV?: boolean;
  className?: string;
  defaultCV?: DefaultCV;
  cordCVURL?: string;
  openCordCV?: () => Promise<void>;
  toggleCVUploaderPopUp?: () => void;
  toggleCVSettings?: (value: boolean) => void;
  verified?: boolean;
  rejected?: boolean;
  dataID?: string;
  profileType?: UserType;
};

export default function SocialMedia({
  isApplicationStarted,
  github,
  website,
  behance,
  stackOverflow,
  originalCVURL,
  admin,
  isCandidate,
  facebookURL,
  twitterURL,
  instagramURL,
  linkedinURL,
  linkedin,
  colour,
  isLoadingCordCV,
  defaultCV,
  cordCVURL,
  openCordCV,
  toggleCVUploaderPopUp,
  toggleCVSettings,
  verified,
  rejected,
  className,
  dataID,
  profileType
}: Props) {
  const downloadCV = async () => {
    const url = defaultCV === DefaultCV.Cord ? cordCVURL : originalCVURL;
    await downloadCVFromURL(url!);
  };
  const { isCompany } = useUserType();

  return (
    <SocialIcons id={dataID} className={ClassNames('social_media', className)} colour={colour}>
      {website && (
        <a
          title="Go to website"
          target="_blank"
          rel="noopener noreferrer"
          href={addURLProtocol(website)}
        >
          <span className="icon_website" />
        </a>
      )}
      {isCandidate ? (
        <ConditionalRender predicate={!isLoadingCordCV} fallback={<InlineLoader />}>
          <CVDropdown
            defaultCV={defaultCV}
            openCordCV={openCordCV!}
            originalCVURL={originalCVURL}
            toggleCVUploaderPopUp={toggleCVUploaderPopUp!}
            toggleCVSettings={toggleCVSettings}
            isVerified={!!verified && !rejected}
          />
        </ConditionalRender>
      ) : (
        (admin || isApplicationStarted) && <span className="icon_cv" onClick={downloadCV} />
      )}
      {(linkedinURL || linkedin) && (
        <a
          title="Go to Linkedin"
          target="_blank"
          rel="noopener noreferrer"
          href={addURLProtocol(linkedinURL || linkedin || '')}
          onClick={() => {
            if (isCompany && profileType === UserType.Candidate) {
              trackEvent('click', 'LinkedIn', 'Candidate Profile');
            }
          }}
        >
          <span className="icon_linkedin_square" />
        </a>
      )}
      {github && (
        <a
          title="Go to Github"
          target="_blank"
          rel="noopener noreferrer"
          href={addURLProtocol(github)}
        >
          <span className="icon_github" />
        </a>
      )}
      {stackOverflow && (
        <a
          title="Go to StackOverflow"
          target="_blank"
          rel="noopener noreferrer"
          href={addURLProtocol(stackOverflow)}
        >
          <span className="icon_stack_overflow" />
        </a>
      )}
      {behance && (
        <a
          title="Go to Behance"
          target="_blank"
          rel="noopener noreferrer"
          href={addURLProtocol(behance)}
        >
          <span className="icon_behance" />
        </a>
      )}
      {twitterURL && (
        <a
          title="Go to Twitter"
          target="_blank"
          rel="noopener noreferrer"
          href={addURLProtocol(twitterURL)}
        >
          <span className="icon_twitter_square" />
        </a>
      )}
      {facebookURL && (
        <a
          title="Go to Facebook"
          target="_blank"
          rel="noopener noreferrer"
          href={addURLProtocol(facebookURL)}
        >
          <span className="icon_facebook_square" />
        </a>
      )}
      {instagramURL && (
        <a
          title="Go to Instagram"
          target="_blank"
          rel="noopener noreferrer"
          href={addURLProtocol(instagramURL)}
        >
          <span className="icon_instagram" />
        </a>
      )}
    </SocialIcons>
  );
}

const SocialIcons = styled.div<{ colour?: string }>`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: ${({ colour }) => colour};

  a {
    vertical-align: middle;
  }

  [class^='icon_'] {
    margin: 0 5px;
    opacity: 0.8;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &.icon_cv {
      font-size: 22px;
      vertical-align: sub;
    }

    &.icon_globe {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .cv_dropdown {
    display: inline-block;
    vertical-align: text-bottom;
    margin: -3px 5px 0 5px;
    opacity: 1;

    .icon_cv {
      margin: 0;
      vertical-align: baseline;
    }
  }
`;
