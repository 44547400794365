/* eslint-disable @typescript-eslint/no-unused-vars */

/**
 * Repeat function to create an array with the provided value repeated the given number of times.
 * When given an object or an array, a new instance will be created for each repetition.
 *
 * @example
 * repeat("#fff", 5); // ['#fff', '#fff', '#fff', '#fff', '#fff']
 *
 * @template T The type of the value to repeat.
 * @param {T} val The value to repeat.
 * @param {number} times The number of times to repeat the value.
 * @returns {T[]} An array of the repeated values.
 */
export function repeat<T>(val: T, times: number): T[] {
  if (typeof val === 'object' && val !== null) {
    return Array.from({ length: times }, () => structuredClone(val));
  }
  return Array(times).fill(val);
}
