import React, { useState, useEffect } from 'react';
import ClassNames from 'classnames';
import { History } from 'history';
import { companyGetForm, companyClearForm, companyUpdateForm } from 'companySignupForm';

import { EmailValidationContext } from '@cohiretech/common-types';

import { JobTypeOption } from 'types';
import { demoCompanySignUp } from 'fetcher';
import { setLocalStorageItem } from 'cookieManager';
import { useUpdateLoginStatus } from 'store/services';
import { getEmailCheckMessage, isEmpty } from 'utils';
import { useToggler } from 'store';
import { useJobTitleOptionsAndSkills } from 'store/common';

import AutoComplete from 'components/autocomplete';
import Button from 'components/button';
import ErrorMessage from 'components/errormessage';
import Tooltip from 'components/tooltip';

import 'components/createorclaimprofileform/style.scss';

export type Props = {
  expandable: boolean;
  color: 'white' | 'dark';
  submitText: string;
  onExpand?: (expand: boolean) => void;
  history: History;
  submitTooltipText?: string;
};

type State = {
  email: string;
  demoJobTitle: string;
  errorMessage: string;
  sending: boolean;
  expanded: boolean;
};

type PositionOption = JobTypeOption;

const HelpIconTooltip = ({ text }: { text: string }) => (
  <span className="icon_help">
    <Tooltip text={text} position="bottom" width="150px" />
  </span>
);

export default function SelfServeDemoForm({
  expandable,
  color,
  submitText,
  submitTooltipText,
  history,
  onExpand
}: Props) {
  const [state, setState] = useState<State>({
    email: '',
    demoJobTitle: '',
    errorMessage: '',
    sending: false,
    expanded: false
  });

  const updateLoginStatus = useUpdateLoginStatus();
  const { toggle: toggleShowDemoWelcomePopup } = useToggler('company.showDemoWelcomePopup');
  const { jobTitleOptions } = useJobTitleOptionsAndSkills();

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = () => {
    const { demoJobTitle, email } = companyGetForm();

    setState({ ...state, demoJobTitle: demoJobTitle || '', email: email || '' });
  };

  const submitRequest = async () => {
    setState({ ...state, sending: true });
    const errorMessage = await validateForm();

    if (errorMessage) setState({ ...state, errorMessage, sending: false });
    else {
      createDemoAccount();
      toggleShowDemoWelcomePopup(true);
    }
  };

  const createDemoAccount = async () => {
    const form = companyGetForm();
    const { status, message } = await demoCompanySignUp(form);

    if (status === 'success') {
      companyClearForm();

      await updateLoginStatus();
      history.push({
        pathname: '/company/discover',
        search: '?utm_source=livedemo'
      });

      setLocalStorageItem('referrerDetails', { ...form.referrerDetails, hadSelfServeDemo: true });
    } else {
      setState({ ...state, sending: false, errorMessage: message });
    }
  };

  const updateForm = (form: { demoJobTitle?: string; email?: string }) => {
    companyUpdateForm(form);

    setState({ ...state, ...form });
  };

  const validateForm = async () => {
    const { demoJobTitle, email } = state;

    if (isEmpty(demoJobTitle)) return 'Please select a position.';
    if (isEmpty(email)) return 'Please fill in your company email.';

    return getEmailCheckMessage(email, 'companyLiveDemo', EmailValidationContext.CompanyLiveDemo);
  };

  const handleExpand = () => {
    if (onExpand) onExpand(!state.expanded);

    setState({ ...state, expanded: !state.expanded });
  };

  const handleOnEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') submitRequest();
  };

  const { errorMessage, demoJobTitle, email, sending, expanded } = state;

  if (expandable) {
    return (
      <div
        className={ClassNames('lead_capture_form', 'self_serve_demo_form', 'inline', { expanded })}
      >
        <div className={ClassNames('inputs_section', { show: expanded })}>
          <AutoComplete
            className={ClassNames(
              'border_input',
              { blue_border_dropdown: color === 'dark' },
              { dark_blue: color === 'dark' },
              { white_border_dropdown: color === 'white' }
            )}
            id="show_active_select_position_autocomplete"
            list={jobTitleOptions}
            showSelectionInInput
            onSelect={item => updateForm({ demoJobTitle: (item as PositionOption).value })}
            selectedItems={[demoJobTitle]}
            placeholder="Select a position*"
            searchThroughTags
          />
          <input
            placeholder="Enter work email"
            type="text"
            className={ClassNames(
              'border_input',
              { white_border: color === 'white' },
              'email_input'
            )}
            onKeyPress={e => handleOnEnter(e)}
            value={email}
            onChange={({ target }) => updateForm({ email: target.value.trim() })}
          />
        </div>
        <Button
          text={submitText}
          className="twenty_px_font"
          loading={sending}
          buttonStyle={color === 'white' ? 'fill_white' : 'fill_blue'}
          action={expanded ? () => submitRequest() : () => handleExpand()}
          tooltip={submitTooltipText ? <HelpIconTooltip text={submitTooltipText} /> : null}
        />
        <div
          className={ClassNames('icon_close', { show: expanded }, { white: color === 'white' })}
          onClick={() => handleExpand()}
        />
      </div>
    );
  } else {
    return (
      <div className="lead_capture_form self_serve_demo_form">
        <AutoComplete
          className={ClassNames(
            'border_input',
            { blue_border_dropdown: color === 'dark' },
            { dark_blue: color === 'dark' },
            { white_border_dropdown: color === 'white' }
          )}
          id="show_active_select_position_autocomplete"
          list={jobTitleOptions}
          showSelectionInInput
          onSelect={item => updateForm({ demoJobTitle: (item as PositionOption).value })}
          selectedItems={[demoJobTitle]}
          placeholder="Select a position*"
          searchThroughTags
        />
        <input
          placeholder="Enter work email*"
          name="email"
          type="text"
          className={ClassNames('border_input', { white_border: color === 'white' }, 'email_input')}
          onKeyPress={e => handleOnEnter(e)}
          value={email}
          onChange={({ target }) => updateForm({ email: target.value.trim() })}
        />
        <Button
          text={submitText}
          className="twenty_px_font"
          buttonStyle="fill_cord_gradient"
          loading={sending}
          action={() => submitRequest()}
          tooltip={submitTooltipText ? <HelpIconTooltip text={submitTooltipText} /> : null}
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }
}
