import { CANDIDATE_SEARCH_FILTERS } from 'consts/candidate/positionSearch';

import { CandidateSearchFilterKey } from 'store';
import {
  checkIfAnywhere,
  checkIfLastActiveFilter,
  checkIfRemoteWorkType,
  hasFilterWithAttribute
} from 'v2/services/tools/positionSearchFilter';
import { groupBy, pluck } from 'utils/object';
import { isEmpty, prettyTextFromArray } from 'utils';
import { SearchItem, SearchItems } from 'types';

import { FilterOption } from 'components/expandablefilter/ExpandableFilter.helpers';

const FILTERS_WITH_LABELS: CandidateSearchFilterKey[] = [
  'primarySkill',
  'orPrimarySkill',
  'skill',
  'orSkill',
  'techStack',
  'orTechStack',
  'jobTitle',
  'companySize',
  'companyName',
  'industry',
  'lastActiveValue',
  'lastActiveType'
];

export const getSelectorLabel = (
  filterName: string,
  selectedItemsLabel: string,
  attribute?: string
) => {
  if (!selectedItemsLabel) return filterName;

  const shouldShowFilterLabel = hasFilterWithAttribute(FILTERS_WITH_LABELS, attribute);
  return `${shouldShowFilterLabel ? `${filterName}: ` : ''}${selectedItemsLabel}`;
};

export const getWorkingTimezoneLabel = (selectedItems: FilterOption[]) =>
  selectedItems.reduce((acc, { label, attribute }) => {
    const isLowerTimeOffset = attribute === CANDIDATE_SEARCH_FILTERS.lowerTimeOffset.attribute;
    return isLowerTimeOffset ? label + acc : `${acc} - ${label}`;
  }, '');

const getRemoteLocationLabel = (remoteLocations: SearchItems) => {
  if (isEmpty(remoteLocations)) return '';

  const isAnywhereSelected = remoteLocations.find(checkIfAnywhere);

  if (isAnywhereSelected) return ' anywhere';
  return ` in ${prettyTextFromArray(remoteLocations.map(pluck('label')), 'or')}`;
};

export const getRemoteLabel = (selectedItems: SearchItems) => {
  const { remoteWorking, remoteLocationContinents, remoteLocationCountries } =
    CANDIDATE_SEARCH_FILTERS;

  const {
    [remoteWorking.attribute]: remote,
    [remoteLocationContinents.attribute]: continents = [],
    [remoteLocationCountries.attribute]: countries = []
  } = groupBy<SearchItem>('attribute')(selectedItems);

  const remoteLocations = continents.concat(countries);

  return remote
    .map(item => {
      const isRemoteWorkType = checkIfRemoteWorkType(item);
      return item.label + (isRemoteWorkType ? getRemoteLocationLabel(remoteLocations) : '');
    })
    .join(' / ');
};

export const shouldHideTooltip = (attribute?: string) =>
  hasFilterWithAttribute(['keyword', 'companyName', 'remoteWorking'], attribute) ||
  checkIfLastActiveFilter(attribute);
