import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { CompanySizeType, CompanyStoryOrderBy, PublicStoryDTO } from '@cohiretech/common-types';

import { ImageResizeParam, PricingCountry } from 'types';
import { isEmpty } from 'utils';
import { addDimensionsOnImages, getImageDimensions } from 'v2/services/image';
import { isFoundersPage } from 'utils/url';
import { useDarkMode } from 'hooks/useDarkMode';
import { mediaQueries } from 'responsiveConfig';
import { getCompanyStories } from 'v2/services/fetchers/public/companyStories';
import useLocationName from 'hooks/useLocationName';
import { toArray } from 'utils/array';

import ArrowLink from 'components/arrowlink';
import CompanyLogos from 'components/companylogos';
import EmptyBox from 'components/emptybox';
import Loader from 'components/loader';
import PageSection from 'components/pagesection';
import StorySlider from 'components/storyslider';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

const imageParams: ImageResizeParam[] = [
  { type: 'view-page', dimensions: getImageDimensions('view-page') },
  { type: 'logo', dimensions: getImageDimensions('no-crop-logo') }
];

type Props = {
  preferredCountry?: PricingCountry;
  darkModeBgColour?: 'dark' | 'gradient_blue_background';
  showStoriesButton?: boolean;
  showCompanyLogos?: boolean;
  className?: string;
};

const TITLES = {
  all: "Where the world's most advanced tech companies make their direct hires",
  founder: 'Where Founders & CTOs build their Tech & Product teams'
};

export default function StorySection({
  preferredCountry,
  darkModeBgColour = 'gradient_blue_background',
  showStoriesButton,
  showCompanyLogos = true,
  className
}: Props) {
  const location = useLocation();
  const darkMode = useDarkMode();

  const [loading, setLoading] = useState(true);
  const [stories, setStories] = useState<PublicStoryDTO[]>([]);
  const isFounderPage = isFoundersPage(location.pathname);
  const bgColor = darkMode ? darkModeBgColour : 'grey_background';

  const locationName = useLocationName(preferredCountry);

  useEffect(() => {
    const getStories = async () => {
      const params: Parameters<typeof getCompanyStories>[0] = {
        type: 'all',
        containsHighlights: true,
        orderBy: CompanyStoryOrderBy.PriorityScore,
        locations: toArray(locationName)
      };

      if (isFounderPage) params.companySizeTypes = toArray(CompanySizeType.StartUp);

      const { data = [] } = await getCompanyStories(params);
      const storiesWithResizedImages = data.map(story => addDimensionsOnImages(story, imageParams));

      setStories(storiesWithResizedImages.slice(0, 10));
      setLoading(false);
    };

    getStories();
  }, []);

  return (
    <StoriesCont
      className={ClassNames(className, 'center_alignment', bgColor)}
      widthClassName="page_view"
      title={TITLES[isFounderPage ? 'founder' : 'all']}
      subTitle={[
        <img
          key="image"
          height="26"
          src="https://assets.co-hire.com/g2-stars.svg"
          alt="cord has a five stars rating on G2"
        />,
        <ArrowLink key="link_to_g2" absoluteURL="https://www.g2.com/products/cord/reviews">
          4.7 "High Performer" on G2
        </ArrowLink>
      ]}
      button={getSeeMoreButtonProps(!!showStoriesButton)}
    >
      <ConditionalRender predicate={!loading} fallback={<Loader />}>
        <ConditionalRender
          predicate={!isEmpty(stories)}
          fallback={<EmptyBox description="No customer stories are available currently" />}
        >
          <StorySlider stories={stories} location={location} darkMode={darkMode} />
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender predicate={showCompanyLogos}>
        <CompanyLogos preferredCountry={preferredCountry} limit={7} />
      </ConditionalRender>
    </StoriesCont>
  );
}

const getSeeMoreButtonProps = (isVisible: boolean) =>
  isVisible
    ? {
        text: 'See all 160+ customer stories',
        className: 'twenty_px_font story_button',
        link: '/stories'
      }
    : undefined;

const StoriesCont = styled(PageSection)`
  &:not(.no_radius) {
    border-top-right-radius: 200px;

    @media ${mediaQueries.smallLaptop} {
      border-top-right-radius: 100px;
    }
  }

  .page_section_title {
    max-width: 750px;
    text-align: center;

    ${({ theme: { media } }) => media.mobileMin`
      font-size: 40px;  
    `}
  }

  .arrow_link {
    margin-left: 10px;
    color: ${colours.fontColour};
  }

  .company_logos_section {
    padding-top: 60px !important;
  }

  &.dark,
  &.gradient_blue_background {
    .page_section_subtitle img {
      filter: brightness(0) invert(1);
    }

    .arrow_link {
      color: ${colours.lightBgColour};
    }
  }

  .button.story_button {
    margin-top: 50px;
    color: white !important;
  }
`;
