import React, { useState } from 'react';

import DialogWindow from 'components/dialogwindow';

import ConditionalRender from '../utility/ConditionalRender';

import { DeleteStreamPopupProps, getTitleAndSubtitle } from './DeleteStreamPopup.helpers';

export default function DeleteStreamPopup(props: DeleteStreamPopupProps) {
  const { visible, featureName, onDelete, onClose } = props;

  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);

    await onDelete();

    setDeleting(false);
  };

  const { title, subtitle } = getTitleAndSubtitle(props);
  const primaryButton = { text: `Yes, ${title}`, action: handleDelete, buttonStyle: 'fill_red' };

  return (
    <ConditionalRender predicate={visible}>
      <DialogWindow
        id="deleteStream"
        visible
        title={`${title}?`}
        subTitle={subtitle}
        showCloseIcon
        onClose={onClose}
        primaryButton={primaryButton}
        loading={deleting}
        skipLink={{ text: `No, Keep ${featureName}` }}
      />
    </ConditionalRender>
  );
}
