import React, { CSSProperties, ReactElement } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { useDarkMode } from 'hooks/useDarkMode';
import { pluck } from 'utils/object';
import { tablet } from 'responsiveConfig';

import Button, { Props as ButtonProps } from 'components/button';

import colours from 'css/base/_colours.module.scss';

type Color = 'blue' | 'dark_blue' | 'red' | 'cord_gradient';

export type Props = {
  className?: string;
  title?: string | ReactElement<HTMLSpanElement> | Array<string | ReactElement>;
  text?: string | ReactElement | Array<string | ReactElement>;
  icon?: string | Array<string>;
  button?: Pick<ButtonProps, 'text' | 'buttonStyle' | 'action'>;
  show: boolean;
  onHide?: React.MouseEventHandler<HTMLSpanElement>;
  color?: Color;
  large?: boolean;
  justifyContent?: CSSProperties['justifyContent'];
  type?: 'top' | 'bottom';
  zIndex?: number;
};

function NotificationBar({
  className,
  title,
  text,
  icon,
  button,
  show,
  onHide,
  color = 'blue',
  large = false,
  justifyContent,
  type = 'bottom',
  zIndex = 12
}: Props) {
  const darkMode = useDarkMode();
  const parsedColor = darkMode && color === 'dark_blue' ? 'blue' : color;

  return (
    <Bar
      aria-hidden={!show}
      className={ClassNames('notification_bar', className, { show })}
      color={parsedColor}
      large={large}
      justifyContent={justifyContent || (onHide ? 'left' : 'center')}
      type={type}
      zIndex={zIndex}
    >
      {icon && <span className={ClassNames('icon', icon)} />}
      <span>
        {title && <strong>{title}</strong>}
        {text && <span className="bar_subtitle">{text}</span>}
      </span>
      {button && (
        <Button
          text={button.text}
          className="button"
          buttonStyle={button.buttonStyle}
          action={button.action}
        />
      )}
      {onHide && <span className="icon_close" onClick={onHide} />}
    </Bar>
  );
}

export default NotificationBar;

type BarProps = Pick<Props, 'color' | 'large' | 'justifyContent' | 'type' | 'zIndex'>;

const BottomBarStyling = css`
  position: fixed;
  bottom: -300px;
  &.show {
    bottom: 0;
  }

  ${({ theme: { media } }) => media.tablet`
    padding: 1rem 2.5rem;
    flex-direction: column;
  `}
`;

const TopBarStyling = css`
  box-sizing: border-box;
  position: fixed;
  top: 85px;
  overflow: hidden;
  height: 0;
  max-height: 0;
  padding: 0 1rem;
  justify-content: center;
  z-index: 10;

  &.show {
    height: 50px;
    max-height: 50px;
  }

  ${({ theme: { media } }) => media.tablet`
    padding: 0 1.5rem;
    top: 60px;
    &.show {
      height: 4rem;
      max-height: 4rem;
    }
  `}

  ${({ theme: { media } }) => media.mobile`
  padding: 1rem 3rem 1rem 1.5rem;
  &.show {
    height: auto;
    max-height: none;
  }

  .icon_close {
    top: calc(50% - 0.5rem);
  }
  `}
`;

const Bar = styled.div<BarProps>`
  @media print {
    display: none;
  }
  display: flex;
  justify-content: ${pluck('justifyContent')};
  align-items: center;
  column-gap: 10px;
  z-index: ${props => props.zIndex};
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  color: white;
  text-align: center;
  transition: all 0.3s ease-in-out;

  .icon_close {
    position: absolute;
    right: 1.5rem;
    font-size: 1rem;
    color: white;

    @media only screen and (max-width: ${tablet}) {
      top: 1.5rem;
    }
  }

  ${({ type }) => (type === 'top' ? TopBarStyling : BottomBarStyling)};

  .button {
    padding: 0.5rem 1rem;
    margin: 0 1.5rem;

    @media only screen and (max-width: ${tablet}) {
      margin: 0;
    }
  }

  ${props =>
    props.large &&
    css`
      font-size: 1.125rem;
    `}

  ${props =>
    props.color === 'blue' &&
    css`
      background-color: ${colours.primaryColour};
    `}

	${props =>
    props.color === 'dark_blue' &&
    css`
      background-color: ${colours.fontColour};
    `}

  ${props =>
    props.color === 'red' &&
    css`
      background-color: ${colours.errorColour};
    `}

  ${props =>
    props.color === 'cord_gradient' &&
    css`
      background: ${({ theme: { colours } }) => colours.gradientBgColour};
    `}

  strong {
    margin-right: 0.25rem;
  }

  @media only screen and (max-width: ${tablet}) {
    font-size: 1rem;
    row-gap: 0.5rem;
  }

  a,
  .link {
    color: white;
    border-bottom: 1px solid;
    margin-left: 0.5rem;

    &:hover {
      color: ${props => {
        switch (props.color) {
          case 'blue':
            return colours.fontColour;
          case 'cord_gradient':
            return 'white';
          default:
            return colours.primaryColour;
        }
      }};
    }
  }

  .icon_warning {
    vertical-align: text-bottom;
  }

  .icon_gift {
    font-size: 24px;
  }
`;
