/**
 * Returns a new array that contains all the unique values from the input arrays.
 *
 * @template T - The type of the elements in the arrays.
 * @param {...T[][]} arrays - The input arrays.
 * @returns {T[]} - A new array that contains all the unique values from the input arrays.
 */
export function unique<T>(...arrays: T[][]): T[] {
  const set = new Set<T>();
  for (const arr of arrays) {
    for (const value of arr) {
      set.add(value);
    }
  }
  return Array.from(set);
}
