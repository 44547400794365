import React, { useState } from 'react';

import { noop } from 'utils/fn';
import { downloadCordCV } from 'v2/services/candidate';

import LinkWithIcon from 'components/linkwithicon';

type DownloadCordCVProps = {
  onFinish?: () => void;
  onError?: (error: string) => void;
};

const DownloadCordCV = ({ onFinish = noop, onError = noop }: DownloadCordCVProps) => {
  const [cvURL, setCVURL] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCordCV = async () => {
    if (cvURL) {
      window.open(cvURL, '_blank');
      return;
    }

    setLoading(true);
    const url = await downloadCordCV(true, onError);
    setLoading(false);
    if (url) {
      setCVURL(url);
    }
    onFinish();
  };

  return (
    <LinkWithIcon
      className="download_cv_link"
      icon="icon_download"
      action={fetchCordCV}
      text={loading ? 'Downloading...' : cvURL ? 'Downloaded' : 'Download CV'}
      loading={loading}
    />
  );
};

export default DownloadCordCV;
