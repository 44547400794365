/* eslint-disable no-console */
import { getLocalStorageItem } from 'cookieManager';

export const isStageDevelopment = () =>
  !!window?.location?.href.match(/^https:\/\/\w+\.dev\.cord\.co.*/);

export const isLocalDevelopment = () => process.env.NODE_ENV !== 'production';

export const isDevelopment = () =>
  isLocalDevelopment() || (isStageDevelopment() && hasBackStagePass());

export const hasBackStagePass = () => {
  const storedPassword = getLocalStorageItem('stagePass');

  const envPassword = process.env.REACT_APP_STAGE_PASS;

  if (storedPassword !== envPassword) {
    if (storedPassword) {
      console.log('Your stage pass is invalid.');
    } else {
      console.log(`You don't have a stage pass.`);
    }
    return false;
  }

  return true;
};
