import { createContext, PropsWithChildren, RefObject, useContext, useRef } from 'react';

import {
  CandidateATPositionFormHandler,
  CandidateATPositionFormProps,
  SaveCandidateATPosition
} from './CandidateATPositionForm.helpers';
import { useSaveCandidateATPosition } from './CandidateATPositionForm.hooks';

type CandidateATPositionFormContextValue = {
  saving: boolean;
  candidateATPositionFormRef: RefObject<CandidateATPositionFormHandler>;
  saveCandidateATPosition: SaveCandidateATPosition;
};

const CandidateATPositionFormContext =
  createContext<CandidateATPositionFormContextValue | null>(null);

export default function CandidateATPositionFormProvider({
  initialPosition,
  children
}: PropsWithChildren<CandidateATPositionFormProps>) {
  const candidateATPositionFormRef = useRef<CandidateATPositionFormHandler>(null);

  const { loading: saving, saveCandidateATPosition } = useSaveCandidateATPosition({
    initialPosition,
    candidateATPositionFormRef
  });

  return (
    <CandidateATPositionFormContext.Provider
      value={{ saving, candidateATPositionFormRef, saveCandidateATPosition }}
    >
      {children}
    </CandidateATPositionFormContext.Provider>
  );
}

export const useCandidateATPositionFormContext = () => {
  const value = useContext(CandidateATPositionFormContext);

  if (!value) {
    throw new Error('useCandidateATPositionFormContext must be used within a Provider');
  }
  return value;
};
