import { CompanySize } from '@cohiretech/common-types';

import { getReferrerTrackingDetails } from 'utils';
import { capitalizeSentences } from 'utils/string';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from 'cookieManager';

export type CompanySignUpForm = {
  name?: string;
  firstName?: string;
  email?: string;
  password?: string;
  phoneCountryCode?: number;
  phoneNumber?: string;
  jobTitle?: string;
  demoJobTitle?: string;
  subscriptionInterest?: string;
  emailSubscription_marketing?: boolean;
  termsAccepted?: boolean;
  referrer?: { type: string; id: number };
  referrerDetails?: { [key: string]: any };
  size?: CompanySize;
};

export const companyGetForm = (): CompanySignUpForm => {
  const form = JSON.parse(getLocalStorageItem('company_signup_form') || '{}') || {};

  const { referrer, referrerDetails } = getReferrerTrackingDetails();

  if (referrer) form.referrer = referrer;
  if (referrerDetails) form.referrerDetails = referrerDetails;

  return form;
};

export const companyClearForm = () => {
  removeLocalStorageItem('company_signup_form');
};

export const companyUpdateForm = ({
  name,
  firstName,
  email,
  password,
  phoneCountryCode,
  phoneNumber,
  jobTitle,
  demoJobTitle,
  subscriptionInterest,
  emailSubscription_marketing,
  termsAccepted,
  referrer,
  referrerDetails,
  size
}: CompanySignUpForm) => {
  const form = companyGetForm();

  Object.assign(
    form,
    name && { name },
    firstName && { firstName: capitalizeSentences(firstName) },
    jobTitle && { jobTitle },
    demoJobTitle && { demoJobTitle },
    email && { email: email.toLowerCase() },
    password && { password },
    phoneNumber && { phoneNumber },
    phoneCountryCode && { phoneCountryCode },
    subscriptionInterest && { subscriptionInterest },
    referrer && { referrer },
    referrerDetails && { referrerDetails },
    termsAccepted !== undefined && { termsAccepted }, //boolean
    emailSubscription_marketing !== undefined && { emailSubscription_marketing }, //boolean
    size && { size }
  );

  setLocalStorageItem('company_signup_form', JSON.stringify(form));
};
