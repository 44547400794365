import {
  OperatingCountry,
  PricingSubscriptionPlan,
  Frequency,
  CompanySubscriptionPlan
} from '@cohiretech/common-types';

import {
  NewPricingOptions,
  PricingCountry,
  PricingPlanType,
  SubscriptionPlan,
  PricingPlanDetails
} from 'types';
import { formatNumber, getCurrencyFromPricingCountry } from 'utils';
import { trackBookDemoEvents, trackCreateAccountEvents } from 'tracking-utils';
import {
  getPricingPlanDetails,
  getAvailableUpgradePlans,
  isNewPricingPlanV1,
  isUnlimitedPlan
} from 'utils/companyUtils';
import { getAnnualPlanName } from 'utils/pricingUtils';
import { CHECKOUT_URL_PATHS, FALLBACK_PRICES, EXCLUSIVE_FEATURES } from 'consts';

export const getNewPricingOptions = (
  currentPlan: { plan: SubscriptionPlan; billingFrequency: Frequency } | undefined,
  availablePlans: PricingSubscriptionPlan[] | undefined,
  isSubscriptionExpired: boolean
) => {
  const upgradePlans = getAvailableUpgradePlans(currentPlan?.plan);
  const isSourcingBasic = currentPlan?.plan === CompanySubscriptionPlan.Basic;

  const getPriceInfo = (
    plan: Exclude<NewPricingOptions, NewPricingOptions.Unlimited | NewPricingOptions.UnlimitedV2>,
    pricingCountry: PricingCountry,
    period?: Frequency
  ) => {
    const planDetails = availablePlans?.find(
      ({ subscriptionPlan }) => subscriptionPlan.plan === plan
    );

    const {
      price = FALLBACK_PRICES[plan][pricingCountry],
      hasDiscount = false,
      discountedPrice = FALLBACK_PRICES[plan][pricingCountry]
    } = planDetails?.pricing?.[pricingCountry] || {};
    const currency = getCurrencyFromPricingCountry(pricingCountry);
    const isV1Pricing = isNewPricingPlanV1(plan);

    return {
      price: price
        ? `${currency}${formatNumber(
            isV1Pricing && period === Frequency.annual ? Math.round(price * 0.75) : price
          )}`
        : '',
      hasDiscount,
      discountedPrice: discountedPrice
        ? `${currency}${formatNumber(
            isV1Pricing && period === Frequency.annual
              ? Math.round(discountedPrice * 0.75)
              : discountedPrice
          )}`
        : ''
    };
  };

  const getTrackedDemoLink = (
    text: string,
    plan: NewPricingOptions,
    period: Frequency,
    country: PricingCountry
  ) => {
    return {
      text,
      link: {
        pathname: '/request-demo',
        search: `?plan=${plan}`,
        state: { from: window?.location?.pathname, period }
      },
      action: () => {
        trackBookDemoEvents(
          `${isUnlimitedPlan(plan) ? 'Unlimited' : plan} | ${period} | ${country}`,
          'click'
        );
      }
    };
  };

  const getButtonProperties = (
    plan: NewPricingOptions,
    period: Frequency,
    country: PricingCountry
  ) => {
    const planDetails = availablePlans?.find(
      ({ subscriptionPlan }) => subscriptionPlan.plan === plan
    );
    const urlPath = planDetails?.pricing?.urlPath || CHECKOUT_URL_PATHS[plan as NewPricingOptions];
    const mode = planDetails?.pricing?.mode || 'test';

    if (isUnlimitedPlan(plan)) return getTrackedDemoLink('Book a demo', plan, period, country);
    if (isSubscriptionExpired) return { text: 'Reactivate subscription', urlPath, mode };
    if (period === Frequency.monthly && isSourcingBasic) {
      return {
        text: 'Activate account',
        urlPath,
        mode,
        onTrack: () =>
          trackCreateAccountEvents(`${plan} | ${period} | ${country}`, 'click_to_activate')
      };
    }
    if (currentPlan?.billingFrequency === Frequency.annual && period === Frequency.monthly) {
      return { text: 'Not available', disabled: true };
    }
    if (
      currentPlan?.plan &&
      plan !== currentPlan.plan &&
      upgradePlans.includes(plan) &&
      !isSourcingBasic
    ) {
      return { text: 'Upgrade plan', urlPath, mode, upgradeAction: 'upgrade' };
    }
    if (
      currentPlan?.plan &&
      plan !== currentPlan.plan &&
      !upgradePlans.includes(plan) &&
      !isSourcingBasic
    ) {
      return { text: 'Downgrade plan', urlPath, mode, upgradeAction: 'downgrade' };
    }
    if (
      currentPlan?.plan &&
      plan === currentPlan.plan &&
      period === currentPlan?.billingFrequency &&
      !isSourcingBasic
    ) {
      return { text: 'Current plan', disabled: true };
    }
    return getTrackedDemoLink('Book a demo', plan, period, country);
  };

  const getLinkProperties = (
    plan: NewPricingOptions,
    period: Frequency,
    country: PricingCountry
  ) => {
    const planDetails = availablePlans?.find(
      ({ subscriptionPlan }) => subscriptionPlan.plan === plan
    );
    const urlPath = planDetails?.pricing?.urlPath || CHECKOUT_URL_PATHS[plan as NewPricingOptions];

    if (
      period === Frequency.monthly &&
      (!currentPlan?.plan || isSourcingBasic) &&
      !isUnlimitedPlan(plan)
    ) {
      if (isSourcingBasic) {
        return getTrackedDemoLink('Book a demo', plan, period, country);
      }
      return {
        text: 'Buy cord',
        to: `/${country.toLowerCase()}/checkout/${urlPath}`,
        onClick: () => trackCreateAccountEvents(`${plan} | ${period} | ${country}`, 'click_to_buy')
      };
    }

    return null;
  };

  const getPlanFeatures = (plan: PricingPlanType, positions: number, users: number) => {
    const isUnlimited = isUnlimitedPlan(plan);

    const basicFeatures = [
      {
        name: 'Unlimited people views',
        tooltip: { text: 'Access all people, run unlimited searches, build unlimited Streams.' }
      },
      {
        name: 'Unlimited hires',
        tooltip: { text: 'Make unlimited hires without paying hiring fees.' }
      },
      {
        name: `${isUnlimited ? 'Unlimited' : positions} active positions`,
        tooltip: {
          text: `Have ${
            isUnlimited ? 'unlimited' : `up to ${positions}`
          } positions live at the same time. Messages on cord are tied to live positions.`
        }
      },
      {
        name: `${isUnlimited ? 'Unlimited' : users} user seats`,
        tooltip: {
          text: `${
            isUnlimited ? 'Unlimited' : `Up to ${users}`
          } Owner/Full Access users. Unlimited Viewer users.`
        }
      },
      {
        name: 'Advanced message templating',
        tooltip: {
          text: 'Use variables to personalise your messages. Schedule follow-ups to be sent if no reply is received.',
          wider: true
        },
        popup: {
          subtitle:
            'Build your own templates using variables to personalise your outreach messages. Schedule automatic follow-ups to be sent if no reply is received.',
          image: 'advanced_message_templating_screenshot.png'
        }
      },
      {
        name: 'Interview scheduler',
        tooltip: {
          text: 'Integrate with your Google or Outlook Calendar and get interviews booked straight into your diary.',
          wider: true
        },
        popup: {
          subtitle:
            'Integrate your Google or Outlook calendar, set your availability and get calls & interviews booked directly in your diary.',
          image: 'interview_scheduler_screenshot.png'
        }
      }
    ];

    switch (plan) {
      case NewPricingOptions.Starter:
      case NewPricingOptions.StarterV2:
        return basicFeatures;
      case NewPricingOptions.Growth:
      case NewPricingOptions.GrowthV2:
        return [...basicFeatures, ...EXCLUSIVE_FEATURES.Growth];
      case NewPricingOptions.Scaling:
      case NewPricingOptions.ScalingV2:
        return [...basicFeatures, ...EXCLUSIVE_FEATURES.Growth, ...EXCLUSIVE_FEATURES.Scaling];
      case NewPricingOptions.Unlimited:
      case NewPricingOptions.UnlimitedV2:
        return [
          ...basicFeatures,
          ...EXCLUSIVE_FEATURES.Growth,
          ...EXCLUSIVE_FEATURES.Scaling,
          ...EXCLUSIVE_FEATURES.Unlimited
        ];
      default:
        return [];
    }
  };

  const getConversationInfo = (
    plan: NewPricingOptions,
    country: PricingCountry,
    frequency: Frequency,
    isUnlimitedPlan?: boolean
  ) => {
    const { conversations } = getPricingPlanDetails(plan) as PricingPlanDetails<NewPricingOptions>;
    const conversationsNumber = frequency === Frequency.annual ? conversations * 12 : conversations;
    const planValue = frequency === Frequency.annual ? getAnnualPlanName(plan) : plan;

    return {
      value: conversationsNumber,
      label: isUnlimitedPlan
        ? 'Unlimited sourcing credits'
        : `${conversationsNumber} sourcing credits/${
            frequency === Frequency.annual ? 'year' : 'month'
          }`,
      plan: planValue,
      button: getButtonProperties(planValue, frequency, country),
      link: getLinkProperties(planValue, frequency, country),
      ...(isUnlimitedPlan
        ? { price: 'Custom', hasDiscount: false, discountedPrice: '' }
        : getPriceInfo(
            planValue as Exclude<
              NewPricingOptions,
              NewPricingOptions.Unlimited | NewPricingOptions.UnlimitedV2
            >,
            country,
            frequency
          ))
    };
  };

  const getPriceDetails = (plan: NewPricingOptions, isUnlimitedPlan: boolean) => {
    const countries: PricingCountry[] = [OperatingCountry.UK, OperatingCountry.US];
    const frequencies = [Frequency.monthly, Frequency.annual];

    return Object.assign(
      {},
      ...countries.map(country => ({
        [country]: Object.assign(
          {},
          ...frequencies.map(frequency => {
            const conversations = [getConversationInfo(plan, country, frequency, isUnlimitedPlan)];

            if ([NewPricingOptions.Growth, NewPricingOptions.GrowthV2].includes(plan)) {
              conversations.push(
                getConversationInfo(
                  plan === NewPricingOptions.Growth
                    ? NewPricingOptions.GrowthPlus
                    : NewPricingOptions.GrowthPlusV2,
                  country,
                  frequency
                )
              );
            }

            return {
              [frequency]: { conversations }
            };
          })
        )
      }))
    );
  };

  const newPricingOptions: PricingPlanType[] = isNewPricingPlanV1(currentPlan?.plan)
    ? [
        NewPricingOptions.Starter,
        NewPricingOptions.Growth,
        NewPricingOptions.Scaling,
        NewPricingOptions.Unlimited
      ]
    : [
        NewPricingOptions.StarterV2,
        NewPricingOptions.GrowthV2,
        NewPricingOptions.ScalingV2,
        NewPricingOptions.UnlimitedV2
      ];

  const result = Object.assign(
    {},
    ...newPricingOptions.map(plan => {
      const pricingPlanDetails = getPricingPlanDetails(
        plan
      ) as PricingPlanDetails<NewPricingOptions>;
      const { positions, users } = pricingPlanDetails;

      return {
        [plan]: {
          ...pricingPlanDetails,
          value: plan,
          ...getPriceDetails(plan, isUnlimitedPlan(plan)),
          features: getPlanFeatures(plan, positions, users)
        }
      };
    })
  );

  return result;
};
