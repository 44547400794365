import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import ClassNames from 'classnames';

import { getResizedImageURL } from 'v2/services/image';
import { S3_IMAGE_BUCKET_URL } from 'consts';

import PageSection from 'components/pagesection';
import Seo from 'components/seo';

const imageDimensions = { width: 510 };

const About = ({ location, darkMode }: $TSFixMe) => {
  return (
    <div id="about">
      <div className="intro_wrapper">
        <div className="min_view center_alignment">
          <h1 className="heading">Our mission</h1>
          <h2 className="subheading">To increase the number of people doing their best work.</h2>
          <div className="description">
            <p>
              Your gift to society is your best work. Progress has relied on us all developing
              individual skills and diverse talents that move us forward together. We all benefit
              when each one of us makes our unique contribution and shares our gifts with each
              other. Where we work, matters, but finding it in the first place is still unreasonably
              difficult. Making applications can be like throwing your CV into a black hole, using a
              sales-driven recruitment agent removes your control. The experience is difficult and
              painful.
            </p>
            <p>
              cord is a direct job search platform that enables the most simple transaction on earth
              - a conversation - to happen between someone and their future colleagues. Underneath
              the hood, facilitating these conversations isn't so simple. We are rebuilding the
              underlying infrastructure that underpins it all, able to handle recruiting complexity
              and distribute people and opportunities at scale.
            </p>
            <p>
              Hiring teams inside the world's most advanced technology companies use cord to hire,
              from recent Y-Combinator alumni to publicly listed technology companies. Whole teams
              are built on cord that wouldn't otherwise exist, inside companies whose work develops
              vaccines, tackles climate change and builds autonomous vehicles. To date we’ve helped
              thousands of people find their best work, but this is only the start...
            </p>
            <p>
              If you are interested in joining us, you can read more about the{' '}
              <Link to="/careers">life at cord</Link> and <Link to="/team">meet the team</Link>.
            </p>
          </div>
        </div>
      </div>
      <PageSection
        title="Our Values"
        className={ClassNames('center_alignment', {
          grey_background: !darkMode,
          dark_blue_background: darkMode
        })}
        widthClassName="min_view"
      >
        <div className="two_cols_items_cont">
          <div className="two_cols_item">
            <h3>Put people first</h3>
            <p>We build what people want. We put people before revenue.</p>
          </div>
          <div className="two_cols_item">
            <h3>Make it simple</h3>
            <p>
              We believe quality is achieved not when there is nothing left to add, but when there
              is no longer anything to take away.
            </p>
          </div>
          <div className="two_cols_item">
            <h3>Be real</h3>
            <p>
              We give people open access to all the information we can. We believe that informed
              people make great decisions.
            </p>
          </div>
          <div className="two_cols_item">
            <h3>Create change</h3>
            <p>
              We hire inspiring people who take risks and innovate. We believe there is a better
              way.
            </p>
          </div>
        </div>
      </PageSection>
      <PageSection
        title="The story so far..."
        className="story_wrapper center_alignment"
        widthClassName="page_view"
      >
        <div className="zig_zag_cont">
          <div className="zig_zag_item">
            <div className="left_panel text_panel">
              cord was started in a bedroom by two friends (Ben and Will) who believed that finding
              work, didn’t work.
            </div>
            <div className="right_panel image_panel">
              <img
                src={getResizedImageURL(
                  `${S3_IMAGE_BUCKET_URL}/co-hire/co-hire-photo-04.jpg`,
                  imageDimensions
                )}
                alt="cord back in the days"
                loading="lazy"
              />
            </div>
          </div>
          <div className="zig_zag_item">
            <div className="right_panel text_panel">
              Talented people didn’t want to sift through faceless job boards or get sold off by a
              third party. They wanted something more simple: to speak directly to someone who is
              hiring someone like them.
            </div>
            <div className="left_panel image_panel">
              <img
                src={getResizedImageURL(
                  `${S3_IMAGE_BUCKET_URL}/co-hire/co-hire-photo-08.jpg`,
                  imageDimensions
                )}
                alt="cord always says thank you to its users"
                loading="lazy"
              />
            </div>
          </div>
          <div className="zig_zag_item">
            <div className="left_panel text_panel">
              In the Autumn of 2016, we built a basic system that allowed people and companies to
              message eachother, somewhere they trust, on their own terms. Without investment or
              start-up experience cord grew.
            </div>
            <div className="right_panel image_panel">
              <img
                src={getResizedImageURL(
                  `${S3_IMAGE_BUCKET_URL}/co-hire/co-hire-photo-01.jpg`,
                  imageDimensions
                )}
                alt="cord is growing"
                loading="lazy"
              />
            </div>
          </div>
          <div className="zig_zag_item">
            <div className="right_panel text_panel">
              We’ve hired inspiring people who believe in our mission. We’ve innovated and built
              through feedback. We've put people first.
            </div>
            <div className="left_panel image_panel">
              <img
                src={getResizedImageURL(
                  `${S3_IMAGE_BUCKET_URL}/co-hire/katerina-ben.jpg`,
                  imageDimensions
                )}
                alt="the team behind cord"
                loading="lazy"
              />
            </div>
          </div>
          <div className="zig_zag_item">
            <div className="left_panel text_panel">
              By building a unique experience that gave people direct access to the hiring teams
              inside some of the world’s most advanced technology companies, cord’s user base grew.
              To date, we’ve enabled over 150,000 direct conversations to happen, helping thousands
              of people find their best work.
            </div>
            <div className="right_panel image_panel">
              <img
                src={getResizedImageURL(
                  `${S3_IMAGE_BUCKET_URL}/co-hire/team-cord.png`,
                  imageDimensions
                )}
                alt="cord is growing"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </PageSection>
      <PageSection
        widthClassName="min_view"
        className="center_alignment"
        subTitle={[
          <p key="strong_p">
            <strong>The clearest route to a good life is doing your best work.</strong>
          </p>,
          'To date, we are a team of 40, who have enabled thousands of people to do their best work. And this is only the start...'
        ]}
      />
      <Seo
        title="Our mission"
        description={"cord's mission is to increase the number of people doing their best work."}
        image="https://assets.co-hire.com/co-hire/team-cord.png"
        path={location.pathname}
        contentType="website"
      />
    </div>
  );
};

export default About;
