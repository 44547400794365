import React from 'react';
import sanitizeHtml from 'sanitize-html';
import diffMatchPatch from 'diff-match-patch';

import { AnyFunction, StringOrElements } from 'types';
import { ALLOWED_CANDIDATE_DESCRIPTION_TAGS, STRIPPED_CANDIDATE_DESCRIPTION_TAGS } from 'consts';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { EditorError } from './components/Menu/Menu.styled';

export type AIAction = { label: StringOrElements; text: string; value: AIPrompt };

export type AIPrompt =
  | 'improveWriting'
  | 'fixSpellingGrammar'
  | 'shorten'
  | 'lengthen'
  | 'simplify'
  | 'custom';

export const aiActions: AIAction[] = [
  {
    label: [<span className="icon_wand" />, 'Improve writing'],
    value: 'improveWriting',
    text: 'Improving writing'
  },
  {
    label: [<span className="icon_success" />, 'Fix spelling & grammar'],
    value: 'fixSpellingGrammar',
    text: 'Fixing spelling & grammar'
  },
  {
    label: [<span className="icon_short_text" />, 'Make shorter'],
    value: 'shorten',
    text: 'Making shorter'
  },
  {
    label: [<span className="icon_long_text" />, 'Make longer'],
    value: 'lengthen',
    text: 'Making longer'
  },
  {
    label: [<span className="icon_click" />, 'Simplify language'],
    value: 'simplify',
    text: 'Simplifying language'
  },
  {
    label: [<span className="icon_edit" />, 'Enter custom prompt'],
    value: 'custom',
    text: 'Working your magic'
  }
];

// eslint-disable-next-line new-cap
const dmp = new diffMatchPatch.diff_match_patch();

export function markDifferences(original: string, edited: string, showDeletion = false) {
  const diffs = dmp.diff_main(original, edited);
  dmp.diff_cleanupSemantic(diffs);

  let result = '';
  for (const [op, text] of diffs) {
    switch (op) {
      case 1: // Insertion
        result += `<ins>${text}</ins>`;
        break;
      case 0: // No change
        result += text;
        break;
      case -1: // Deletion
        if (showDeletion) result += `<del>${text}</del>`;
        break;
      default:
        break;
    }
  }

  return result;
}

type ErrorOnEnhanceTextProps = {
  handleClick: AnyFunction;
  text?: string;
  tryAgain?: boolean;
};

export const ErrorOnEnhanceText = ({
  handleClick,
  text = 'Sorry, an error occured.',
  tryAgain = true
}: ErrorOnEnhanceTextProps) => (
  <>
    {text}&nbsp;
    <ConditionalRender predicate={tryAgain}>
      <EditorError onClick={handleClick}>Try again</EditorError>
    </ConditionalRender>
  </>
);

export const getUserSelection = () => window.getSelection() || document.getSelection();

export const copySelectionAsHTML = () => {
  let selectedText = '';
  const selection = getUserSelection();

  // Make sure copied text is proper HTML by injecting it into a div
  if (selection?.rangeCount) {
    const container = document.createElement('div');
    for (let i = 0, len = selection.rangeCount; i < len; ++i) {
      container.appendChild(selection.getRangeAt(i).cloneContents());
    }
    selectedText = container.innerHTML;
  }
  return selectedText;
};

export const removeSpacesAndNewlinesBetweenTags = (htmlString: string) => {
  return htmlString.replace(/>\s+|\n\s*</g, match => match.trim());
};

const isFullSelection = (div: HTMLElement) => {
  const fullRange = document.createRange();
  fullRange.selectNodeContents(div);

  const selection = getUserSelection();
  if (selection && selection.rangeCount > 0) {
    const selectedRange = selection.getRangeAt(0);
    // Compare the start and end points of the two ranges
    return (
      fullRange.compareBoundaryPoints(Range.START_TO_START, selectedRange) === 0 &&
      fullRange.compareBoundaryPoints(Range.END_TO_END, selectedRange) === 0
    );
  }
  return false;
};

export const selectAllContent = (div: HTMLElement, currentSelection?: Selection) => {
  const selection = currentSelection || getUserSelection();
  const range = document.createRange();
  range.selectNodeContents(div);
  selection?.removeAllRanges();
  selection?.addRange(range);
};

export const replaceSelectionWithHTML = (html: string, textbox: HTMLElement) => {
  const isAllSelected = isFullSelection(textbox);
  const selection = getUserSelection();

  if (selection?.rangeCount) {
    const ranges = [];
    for (let i = 0; i < selection.rangeCount; i++) {
      ranges[i] = selection.getRangeAt(i);
    }
    document.execCommand('insertHTML', false, html);

    selection.removeAllRanges();
    ranges.map(range => selection.addRange(range));

    if (isAllSelected) {
      selectAllContent(textbox);
    } else if (selection.isCollapsed && selection.focusNode) {
      selection.extend(selection.focusNode, selection.focusNode?.textContent?.length);
    }
  }
};

export const saveSelection = (selection: Selection) => {
  const currentRange = selection?.getRangeAt(0);

  const savedSel = {
    startContainer: currentRange.startContainer,
    startOffset: currentRange.startOffset,
    endContainer: currentRange.endContainer,
    endOffset: currentRange.endOffset
  };
  return savedSel;
};

export const removeHighlightedTags = (html: string) => {
  return sanitizeHtml(html, {
    allowedTags: STRIPPED_CANDIDATE_DESCRIPTION_TAGS
  });
};

export const cleanHTML = (html: string) => {
  const enhancedHTML = removeSpacesAndNewlinesBetweenTags(html);
  const sanitizedHTML = sanitizeHtml(enhancedHTML, {
    allowedTags: ALLOWED_CANDIDATE_DESCRIPTION_TAGS
  });

  return sanitizedHTML;
};

export const restoreSelection = (selection: Selection, savedSelection: any) => {
  const newRange = document.createRange();
  newRange.setStart(savedSelection.startContainer, savedSelection.startOffset);
  newRange.setEnd(savedSelection.endContainer, savedSelection.endOffset);
  selection.removeAllRanges();
  selection.addRange(newRange);
};

export const removeEmptyElements = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  function traverseAndClean(node: Node) {
    for (let i = node.childNodes.length - 1; i >= 0; i--) {
      const childNode = node.childNodes[i];

      if (childNode.nodeType === Node.ELEMENT_NODE && childNode.childNodes.length === 0) {
        node.removeChild(childNode);
      } else if (childNode.nodeType !== Node.TEXT_NODE) {
        traverseAndClean(childNode);
      }
    }
  }

  traverseAndClean(doc.body);

  return doc.body.innerHTML;
};
