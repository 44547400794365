import React from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import useMedia from 'hooks/useMedia';
import { mediaQueries, mobile } from 'responsiveConfig';
import { StringOrElements } from 'types';
import { equals } from 'utils/fn';

import Badge, { BadgeProps } from 'components/badge';
import Tooltip, { Props as TooltipProps } from 'components/tooltip';

import { TabStyleProps, getTabStyling, getTabsWrapperStyling } from './Tabs.styled';

type TabValue = number | string;

export type TabType<V extends TabValue = TabValue> = {
  title: StringOrElements;
  value: V;
  url?: string;
  badge?: BadgeProps;
  icon?: string;
  tooltip?: TooltipProps;
};

type TabStyle = 'default' | 'boxed';

type Props<V extends TabValue = TabValue> = {
  className?: string;
  current?: string | number;
  tabs: TabType<V>[];
  tabStyle?: TabStyle;
  disabled?: boolean;
  inverted?: boolean;
  onChange: (tab: Pick<TabType<V>, 'title' | 'value' | 'url'>) => void;
} & Omit<TabStyleProps, 'active'>;

type StyledTab = {
  active: boolean;
  tabStyle?: TabStyle;
};

function Tabs<V extends TabValue = TabValue>({
  className,
  current,
  tabs,
  tabStyle = 'default',
  disabled,
  onChange,
  ...styleProps
}: Props<V>) {
  const isMobile = useMedia([mediaQueries.mobile]);
  const tabSize = getTabSize(styleProps, isMobile);

  return (
    <TabsWrapper
      className="tabs_wrapper"
      appearance={styleProps.appearance}
      size={tabSize}
      tabStyle={tabStyle}
      disabled={disabled}
    >
      {tabs.map(({ title, value, url, badge, icon, tooltip }) => (
        <Tab
          key={value.toString()}
          onClick={() => onChange({ title, value, url })}
          className={ClassNames('tab_item', className, { active: current === value })}
          active={current === value}
          {...{ ...styleProps, size: tabSize }}
          tabStyle={tabStyle}
          hasTooltip={!!tooltip}
        >
          {icon && <span className={icon} />}
          {Array.isArray(title) ? title.map(item => item) : title}
          {badge && <Badge {...badge} />}
          {tooltip && <Tooltip {...tooltip} />}
        </Tab>
      ))}
    </TabsWrapper>
  );
}
export default Tabs;

const getTabSize = (
  { appearance, size }: TabStyleProps,
  isMobile: boolean
): TabStyleProps['size'] => {
  if (equals(size)('2xsmall')) return size;

  // Will use this value temporarily until refactoring the component to avoid overwriting current styles.
  const applyNewStyling = !!appearance;
  return applyNewStyling && isMobile ? 'xsmall' : size;
};

const TabsWrapper = styled.div<TabStyleProps & { tabStyle?: TabStyle; disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin: 25px 0;
  justify-content: center;

  ${props => {
    const {
      appearance,
      theme: { media }
    } = props;

    if (appearance) return getTabsWrapperStyling(props);
    return media.mobile`
      display: block;
      text-align: center;
    `;
  }}

  ${({ tabStyle }) =>
    tabStyle === 'boxed' &&
    css`
      margin: 0;
      justify-content: flex-start;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}

  .tab_item {
    align-items: center;
  }
`;

const DefaultStyle = (active: boolean) => css`
  &:hover {
    opacity: 1;
    outline: none;
    border: none;
  }

  &:focus-visible {
    border: none;
    outline: none;
    border-bottom: 4px solid;
    padding-bottom: 9px;
  }

  ${active &&
  css`
    border-bottom: 4px solid;
    padding-bottom: 9px;
    font-weight: ${({ theme: { typography } }) => typography.bold};
    opacity: 1;

    &:hover,
    &:focus-visible {
      border-bottom: 4px solid;
    }
  `}
`;

const BoxedStyle = (active: boolean) => css`
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  gap: 0.5rem;

  &:hover {
    opacity: 1;
    outline: none;
    border: none;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }

  ${active &&
  css`
    box-shadow: ${({ theme: { darkMode } }) =>
      darkMode
        ? '0px 0px 24px 0px rgba(0, 0, 0, 0.40)'
        : '0px 4px 24px 0px rgba(32, 62, 90, 0.08)'};
    background-color: ${({ theme: { darkMode, colours } }) =>
      darkMode ? colours.darkBgColour : 'white'};
    border-radius: 0.5rem;
    font-weight: ${({ theme: { typography } }) => typography.semibold};

    [class^='icon_'] {
      font-weight: bold;
    }

    opacity: 1;

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  `}
`;

const Tab = styled.button<TabStyleProps & StyledTab>`
  ${props =>
    props.appearance
      ? getTabStyling(props)
      : css`
          text-align: center;
          font-size: 18px;
          font-weight: ${({ theme: { typography } }) => typography.regular};
          padding: 14px 26px;
          transition: all 0.2s ease-in-out;
          opacity: 0.8;
          cursor: pointer;

          @media only screen and (max-width: ${mobile}) {
            padding: 12px 10px;
            margin: 5px 0;
            display: inline-block;
          }

          .badge {
            margin-right: 0;
          }

          ${props.tabStyle === 'default' && DefaultStyle(props.active)}

          ${props.tabStyle === 'boxed' && BoxedStyle(props.active)}
        `}
`;
