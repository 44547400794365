import styled from 'styled-components';

import LinkWithIcon from 'components/linkwithicon';
import Loader from 'components/loader';

import { Props } from './ClaimCompanyProfile';

export const Wrapper = styled.div<Pick<Props, 'isCompanyProfile'>>`
  display: flex;
  gap: 4px;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.small};
  // Opportunity: remove the below dependency by making context or surrounding components less situationally dependant
  margin: ${({ isCompanyProfile }) => (isCompanyProfile ? '0' : '-0.5rem')} auto 1rem;
`;

export const ClaimProfileLink = styled(LinkWithIcon)`
  span.icon_claim {
    font-size: ${({ theme }) => theme.typography.normal};
  }
`;

export const ClaimTopIcon = styled.span`
  &&& {
    display: block;
    margin-bottom: 2rem;
    font-size: 5rem;
    color: ${({ theme }) => theme.colours.primaryColour};
  }
`;

export const NoSubscriptionNote = styled.p`
  color: ${({ theme }) => theme.colours.inputsColour};
  margin-bottom: 1.5rem;
`;

export const ResendEmailNote = styled.p`
  margin-bottom: 1.5rem;
`;

export const ResendLoader = styled(Loader)`
  &&& {
    height: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const ResendLink = styled.a`
  cursor: pointer;
`;
