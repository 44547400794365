import React from 'react';
import ClassNames from 'classnames';

type Props = {
  className?: string;
  uploaderID?: string;
  multiple?: boolean;
  labelMessage?: string;
  accept?: string;
  attributes?: { [key: string]: any };
  disabled?: boolean;
  onChange: (filesUploaded: File[]) => void;
  onSelect: (files: FileList) => boolean;
};

type FileSelected = (
  files: FileList | null,
  onChange: (files: any) => void,
  onSelect: (files: FileList) => boolean
) => Promise<any>;

const fileSelected: FileSelected = async (files, onChange, onSelect) => {
  if (files?.length && onSelect) {
    const validFiles = onSelect(files);

    if (validFiles) {
      await onChange(files);
    }
  }
};

function FileUploader({
  className,
  uploaderID,
  multiple,
  labelMessage,
  accept,
  attributes,
  onChange,
  onSelect
}: Props) {
  return (
    <div className={ClassNames(className, 'file_uploader_wrapper')}>
      <input
        type="file"
        id={uploaderID}
        onChange={e => fileSelected(e.target.files, onChange, onSelect)}
        multiple={multiple}
        accept={accept}
        {...attributes}
      />
      <label htmlFor={uploaderID}>{labelMessage}</label>
    </div>
  );
}

export default FileUploader;
