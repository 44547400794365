/**
 *
 * @param arr the array to partition
 * @param predicate the predicate to use for partitioning
 * @returns a tuple of two arrays where [predicate(item) === true, predicate(item) === false]
 */
export const partition = <T>(arr: T[], predicate: (item: T) => boolean) => {
  const result: T[][] = [[], []];
  arr.forEach(item => {
    result[predicate(item) ? 0 : 1].push(item);
  });
  return result;
};
