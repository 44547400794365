import {
  CandidateHydratedOccupationInterface,
  CandidateOccupation,
  CompanySizeAbridged
} from '@cohiretech/common-types';

import { ABRIDGED_COMPANY_SIZE_OPTIONS } from '../company/size';

export const decodeCandidateExperience = (e: CandidateOccupation) => ({
  id: e.co_id,
  company: e.company,
  position: e.position,
  location: e.location,
  description: e.summary,
  current: e.current,
  unlisted: e.unlisted,
  startMonth: e.start_date ? new Date(e.start_date).getMonth() : -1,
  startYear: e.start_date ? new Date(e.start_date).getFullYear() : -1,
  endMonth: e.end_date ? new Date(e.end_date).getMonth() : -1,
  endYear: e.end_date ? new Date(e.end_date).getFullYear() : -1,
  companyIndustries: e.company_industries || [],
  companySizes: e.company_sizes || [],
  dealroomID: e.dealroom_id,
  dealroomURL: e.dealroom_url
});

export const decodeCandidateExperienceForCandidate = (e: CandidateHydratedOccupationInterface) => ({
  id: e.coID,
  company: e.company,
  position: e.position,
  location: e.location,
  description: e.summary,
  current: e.current,
  unlisted: e.unlisted,
  startMonth: e.startDate ? new Date(e.startDate).getMonth() : -1,
  startYear: e.startDate ? new Date(e.startDate).getFullYear() : -1,
  endMonth: e.endDate ? new Date(e.endDate).getMonth() : -1,
  endYear: e.endDate ? new Date(e.endDate).getFullYear() : -1,
  companyIndustries: e.companyIndustries || [],
  companySizes: e.companySizes || [],
  dealroomID: e.dealroomID,
  dealroomURL: e.dealroomURL
});

export const getCompanySizeAbridgedLabels = (occupationCompanySizes: CompanySizeAbridged[]) =>
  ABRIDGED_COMPANY_SIZE_OPTIONS.filter(({ value }) => occupationCompanySizes?.includes(value))?.map(
    ({ label }) => label
  );
