import styled, { css } from 'styled-components';

import { NoScrollbar } from 'v2/components/utility/Scroll';

const FLEXBOX_GAP = '0.5rem';

export const ATPositionFormWrapper = styled.div`
  margin: 0 -30px;
  flex-shrink: 1;
  overflow: auto;
  ${NoScrollbar};
`;

const RemoteLocationsFieldStyling = css`
  .question + div {
    margin-bottom: 0;
  }

  #remote_location_dropdown {
    width: 190px;

    .icon_simple_arrow_down {
      font-size: var(--type-sm);
    }

    #remote_location_dropdown_list .item {
      font-size: var(--type-sm-md);
    }
  }
`;

export const ATPositionForm = styled.div`
  margin: 0 30px;
  overflow: visible;

  .field {
    .select_options {
      display: flex;
      gap: ${FLEXBOX_GAP};
      flex-wrap: wrap;

      .selectable_wrapper {
        margin: 0;
        padding: 0.75rem 1rem;

        &.three_cols {
          width: calc((100% - ${FLEXBOX_GAP} * 2) / 3);
        }

        &.four_cols {
          width: calc((100% - ${FLEXBOX_GAP} * 3) / 4);
        }
      }
    }

    .selected_items {
      margin-bottom: 0;

      &.empty {
        margin-top: 0;
      }

      & + .select_options {
        margin-top: 0.5rem;
      }
    }

    &.remote_locations_field {
      ${RemoteLocationsFieldStyling}
    }
  }
`;
