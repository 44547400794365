/**
 * extract key from s3 url
 * @param inputUrl
 */
export function extractS3Key(inputUrl: string): string {
  try {
    if (!inputUrl.match('https://')) return inputUrl;

    let keyPathname = '';
    const url = new URL(inputUrl);
    const { pathname, host } = url;
    // if the bucketname has `.` the SDK it will return v1 (path style urls)
    // else it will return v2 (virtual style)
    // https://aws.amazon.com/blogs/aws/amazon-s3-path-deprecation-plan-the-rest-of-the-story/
    // some of our buckets have . in their name
    // v1 will always start with `s3.`
    if (host.startsWith('s3')) {
      keyPathname = pathname.split('/').slice(2).join('/');
    } else {
      keyPathname = pathname.split('/').slice(1).join('/');
    }
    // decodeURI retains spaces instead of replacing with %20
    // replacing + with space because AWS converts spaces in keys to + sign
    // ref: https://docs.aws.amazon.com/lambda/latest/dg/with-s3-example-deployment-pkg.html#with-s3-example-deployment-pkg-nodejs
    return decodeURIComponent(keyPathname.replace(/\+/g, ' ')) || '';
  } catch (error) {
    return '';
  }
}
