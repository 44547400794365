import React from 'react';
import styled from 'styled-components';

import BulletList, { Bullet } from 'components/bulletlist';

const VALUE_PROPOSITIONS = [
  'Preview 15,000+ verified candidates actively looking for work',
  'Post positions & redirect applications to your career pages',
  'Enhance & showcase your employer brand',
  "Benchmark your positions with cord's salary tool"
];
export default function ValueProposition() {
  return (
    <ValuePropositionContainer>
      <WithCordProfileNote>With a cord profile you can:</WithCordProfileNote>
      <StyledBulletList>
        {VALUE_PROPOSITIONS.map((title, idx) => (
          <StyledBullet
            key={`value_proposition_${idx}`}
            title={title}
            iconSize={24}
            iconCircleColour="light"
          />
        ))}
      </StyledBulletList>
    </ValuePropositionContainer>
  );
}

export const ValuePropositionContainer = styled.div`
  margin: 0 -45px -45px;
  padding: 2rem 3rem;
  background-color: ${({ theme }) => theme.bg};

  p {
    text-align: left;
  }
`;

export const WithCordProfileNote = styled.p`
  font-size: ${({ theme }) => theme.typography.normal};
  font-weight: ${({ theme }) => theme.typography.bold};
  margin-bottom: 1rem;
`;

const StyledBulletList = styled(BulletList)`
  padding-right: 0;
`;

const StyledBullet = styled(Bullet)`
  h3 {
    font-size: ${({ theme }) => theme.typography.normal};
    font-weight: ${({ theme }) => theme.typography.regular};
  }
`;
