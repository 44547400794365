import React from 'react';

import { DefaultCV } from '@cohiretech/common-types';

import { downloadCVFromURL } from 'v2/services/common';
import { AnchorClickEvent } from 'types';

import { BadgeProps } from 'components/badge';
import IconButton from 'components/iconbutton';
import Tooltip, { TooltipPosition } from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { RadioButtonOption } from '../ui/molecules/RadioButtons/RadioButtons';

import { CVDropdownProps } from './CVDropdown';
import { CVOptions } from './CVDropdown.styled';

export const onCordCV = (option: RadioButtonOption) => option.value !== DefaultCV.Cord;

export const markSelected = (
  options: RadioButtonOption[],
  selected: RadioButtonOption['value']
) => {
  return options.map(({ value, badge, ...rest }) =>
    value === selected ? { value, badge: DEFAULT_BADGE, ...rest } : { value, ...rest }
  );
};

const helpTooltip = {
  text: 'Generated using your profile information',
  position: 'top' as TooltipPosition
};

const handleClickHelper = (onClick: () => Promise<any>) => (e: AnchorClickEvent) => {
  e.preventDefault();
  e.stopPropagation();
  onClick();
};

const ICON_BUTTON_PROPS = {
  fontSize: '22px',
  fontWeight: '400'
};

const CordCVOptions = ({
  openCordCV,
  toggleCVSettings
}: Pick<CVDropdownProps, 'openCordCV' | 'toggleCVSettings'>) => {
  return (
    <CVOptions>
      <IconButton
        ariaLabel="cord CV Settings"
        icon="icon_settings"
        onClick={() => toggleCVSettings?.(true)}
        tooltip={<Tooltip text="Settings for cord CV" position="bottom" />}
        {...ICON_BUTTON_PROPS}
      />
      <IconButton
        ariaLabel="Download cord CV"
        icon="icon_download"
        onClick={handleClickHelper(openCordCV)}
        tooltip={<Tooltip text="Download cord CV" position="bottom" />}
        {...ICON_BUTTON_PROPS}
      />
    </CVOptions>
  );
};

const UploadedCVOptions = ({ originalCVURL }: Pick<CVDropdownProps, 'originalCVURL'>) => {
  return (
    <ConditionalRender predicate={originalCVURL}>
      <CVOptions>
        <IconButton
          ariaLabel="Download your CV"
          icon="icon_download"
          onClick={handleClickHelper(() => downloadCVFromURL(originalCVURL!))}
          tooltip={<Tooltip text="Download your CV" position="bottom" />}
          {...ICON_BUTTON_PROPS}
        />
      </CVOptions>
    </ConditionalRender>
  );
};

export const DEFAULT_BADGE: BadgeProps = { text: 'Default', type: 'info_light' };

export const createCVMenuOptions = ({
  openCordCV,
  toggleCVSettings,
  originalCVURL
}: Pick<CVDropdownProps, 'openCordCV' | 'toggleCVSettings' | 'originalCVURL'>) => [
  {
    label: 'cord CV',
    value: 'cord',
    id: 'cord_cv',
    helpTooltip,
    children: <CordCVOptions openCordCV={openCordCV} toggleCVSettings={toggleCVSettings} />
  },
  {
    label: 'Uploaded CV',
    value: 'original',
    id: 'download_cv',
    children: <UploadedCVOptions originalCVURL={originalCVURL} />
  }
];

export const UPLOAD_CV_OPTION = { label: 'Upload new CV', value: 'original', id: 'upload_cv' };
