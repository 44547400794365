import { CSSProperties } from 'react';
import styled from 'styled-components';

import { Wrapper } from './Wrapper';

type FlexContainerProps = Partial<
  Pick<
    CSSProperties,
    | 'width'
    | 'height'
    | 'flexDirection'
    | 'justifyContent'
    | 'alignItems'
    | 'gap'
    | 'columnGap'
    | 'rowGap'
    | 'flexWrap'
  >
>;

export const FlexContainer = styled(Wrapper)<FlexContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'normal'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  column-gap: ${({ columnGap }) => columnGap || 'normal'};
  row-gap: ${({ rowGap }) => rowGap || 'normal'};
  gap: ${({ gap }) => gap || 'normal'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
`;
