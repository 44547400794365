import React, { ReactNode } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { ColourWay, CSSValueUnit } from 'types';
import { pluck } from 'utils/object';

type Colour = Extract<
  ColourWay,
  'lightblue' | 'darkblue' | 'white' | 'purpleGradient' | 'cordGradient'
>;

type ProgressbarProps = {
  progress: number;
  completionMessage?: string;
  progressLabel?: ReactNode;
  hideProgressLabel?: boolean;
  colour?: Colour;
  className?: string;
  height?: CSSValueUnit<'px'>;
};

export default function ProgressBar({
  progress,
  progressLabel,
  hideProgressLabel,
  completionMessage,
  colour = 'lightblue',
  height = '12px',
  className
}: ProgressbarProps) {
  const roundedProgress = Math.round(progress);
  return (
    <>
      <ProgressBarWrapper className={ClassNames(className, 'progress_bar')}>
        <ProgressBarLine
          role="progressbar"
          className="progress_bar_line"
          colour={colour}
          height={height}
        >
          <Progress style={{ width: `${progress}%` }} colour={colour} />
        </ProgressBarLine>
        {!hideProgressLabel && (
          <ProgressLabel colour={colour}>
            {progressLabel || `${roundedProgress}%`}{' '}
            {roundedProgress === 100 ? <span className="tada">🎉</span> : ''}
          </ProgressLabel>
        )}
      </ProgressBarWrapper>
      {completionMessage && roundedProgress === 100 ? (
        <CompletionMessage className="lightblue_text progress_completion_label">
          {completionMessage}
        </CompletionMessage>
      ) : (
        ''
      )}
    </>
  );
}

type StyledProps = {
  colour: Colour;
  height: string;
};

const LightBlue = css`
  color: ${({ theme: { colours } }) => colours.primaryColour};
`;

const ProgressLabel = styled.strong<Pick<ProgressbarProps, 'colour'>>`
  ${({ colour, theme: { fn } }) => css`
    ${colour === 'lightblue' && LightBlue}
    ${colour === 'cordGradient' || colour === 'purpleGradient'
      ? fn.getRadialGradientTextStyling(colour)
      : ''}
  `}
`;

const CompletionMessage = styled.span`
  font-size: 14px;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: baseline;
`;

const ProgressBarLine = styled.div<StyledProps>`
  position: relative;
  flex: 2;
  height: ${pluck('height')};
  border-radius: 25px;
  background-color: ${({ colour, theme: { colours } }) =>
    colour === 'lightblue'
      ? colours.semiTransparentPrimaryColour
      : colour === 'white'
      ? colours.transparentLightFontColour
      : colour === 'purpleGradient' || colour === 'cordGradient'
      ? 'none'
      : colours.semiTransparentFontColour};
  ${({ colour, theme: { colours } }) =>
    colour === 'purpleGradient' &&
    css`
      background: ${colours.transparentPurpleGradient};
    `}
  ${({ colour, theme: { colours } }) =>
    colour === 'cordGradient' &&
    css`
      background: ${colours.transparentCordGradient};
    `}
`;

const Progress = styled.div<Pick<ProgressbarProps, 'colour'>>`
  position: absolute;
  width: 0;
  left: 0;
  height: 100%;
  border-radius: 25px;
  background-color: ${({ colour, theme: { colours } }) =>
    colour === 'lightblue'
      ? colours.primaryColour
      : colour === 'white'
      ? colours.lightFontColour
      : colour === 'purpleGradient'
      ? 'none'
      : colours.fontColour};
  transition: width 0.2s ease-in-out;
  ${({ colour, theme: { colours } }) =>
    colour === 'purpleGradient' &&
    css`
      background: ${colours.purpleGradient};
    `}
  ${({ colour, theme: { colours } }) =>
    colour === 'cordGradient' &&
    css`
      background: ${colours.cordGradient};
    `}
`;
