import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { PositionSalaryRanges } from '@cohiretech/common-types';

import { getSeniorityOptions } from 'fetcher';
import { ThemeColors } from 'types';

import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type Metadata = PositionSalaryRanges['market']['metadata'];

type SalaryComparisonChartLegendProps = {
  isSalaryUndisclosed: boolean;
  metadata?: Metadata;
};

const SENIORITY_OPTIONS = getSeniorityOptions();

export default function SalaryComparisonChartLegend({
  isSalaryUndisclosed,
  metadata
}: SalaryComparisonChartLegendProps) {
  const [tooltip, setTooltip] = useState<string>();

  useEffect(() => {
    if (metadata) setTooltip(getTooltip(metadata));
  }, [metadata]);

  return (
    <CustomLegend>
      <CustomLegendItem>
        <Marker colour="transparentCordGradient" />
        Avg. salary range
        <ConditionalRender predicate={tooltip}>
          <IconHelp className="icon_help">
            <Tooltip text={tooltip} position="bottom" />
          </IconHelp>
        </ConditionalRender>
      </CustomLegendItem>
      <ConditionalRender predicate={!isSalaryUndisclosed}>
        <CustomLegendItem>
          <Marker colour="cordGradient" />
          Position's salary range
        </CustomLegendItem>
      </ConditionalRender>
    </CustomLegend>
  );
}

const getTooltip = (metadata: Metadata) => {
  const { totalDataPoints, jobTitle } = metadata;
  const seniority = SENIORITY_OPTIONS.find(({ value }) => value === metadata.seniority)?.label;

  return `Based on ${totalDataPoints} ${seniority} ${jobTitle} positions`;
};

const CustomLegend = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  ${({ theme: { typography } }) => css`
    font-size: ${typography.mobile};
    font-weight: ${typography.bold};
  `}
`;

const CustomLegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const Marker = styled.div<{ colour: keyof ThemeColors }>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.5rem;

  ${({ colour, theme: { colours } }) => css`
    background: ${colours[colour]};
  `}
`;

const IconHelp = styled.span`
  &&.icon_help {
    ${({ theme: { typography } }) => css`
      margin-left: 0;
      font-size: ${typography.mobile};
    `}

    &:hover .tooltip_wrapper .tooltip {
      text-align: center;
    }
  }
`;
