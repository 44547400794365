/* eslint-disable jsx-a11y/no-autofocus */
import React, { KeyboardEvent } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Location } from 'history';
import ClassNames from 'classnames';

import * as fetcher from 'fetcher';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from 'cookieManager';
import { emailFormat } from 'utils';
import { capitalizeSentences } from 'utils/string';

import Button from 'components/button';
import Checkbox from 'components/checkbox';
import ErrorMessage from 'components/errormessage';
import LineDivider from 'components/linedivider';
import Seo from 'components/seo';

import './style.scss';

type Props = {
  className: string;
  location: Location<{ errorMessage?: string; sessionID?: number }>;
  onLoginStatusChange: () => Promise<void>;
};

type State = {
  errorMessage: string;
  loggingIn: boolean;
  email: string;
  rememberMe: boolean;
  mfaParams: { [key: string]: any };
  mfaCode: string;
  showPassword: boolean;
};

export default class Login extends React.Component<Props, State> {
  password: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);

    this.state = {
      errorMessage: props?.location?.state?.errorMessage || '',
      loggingIn: false,
      email: '',
      rememberMe: false,
      mfaParams: getLocalStorageItem<any>('mfaParams'),
      mfaCode: '',
      showPassword: false
    };

    this.onKeyPress = this.onKeyPress.bind(this);
    this.onMFAKeyPress = this.onMFAKeyPress.bind(this);
    this.password = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;
    this.setState({
      email: getLocalStorageItem('userEmail') || '',
      errorMessage: location?.state?.errorMessage || ''
    });
  }

  submitLogin() {
    const { email, rememberMe } = this.state;
    const password = this.password.current?.value || '';
    const errorMessage = this.validateLogin(email, password);

    if (errorMessage) {
      this.setState({ errorMessage, loggingIn: false });
    } else {
      this.setState({ errorMessage: '', loggingIn: true });
      this.login(email, password, rememberMe);
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.key === 'Enter') this.submitLogin();
  }

  onMFAKeyPress(e: KeyboardEvent) {
    if (e.key === 'Enter') this.validateMFACode();
  }

  validateLogin(email: string, password: string) {
    if (email === undefined || email.length === 0) {
      return 'Please enter your email and password';
    } else if (!emailFormat.test(email)) {
      return 'Invalid email';
    } else if (password === undefined || password.length === 0) {
      return 'Please enter your password';
    } else {
      return '';
    }
  }

  async login(email: string, password: string, remember: boolean) {
    const { onLoginStatusChange, location } = this.props;
    const stripeSessionID = location?.state?.sessionID || getLocalStorageItem('sessionID') || '';
    const { status, message, role, loginToken, id } = await fetcher.login(
      email,
      password,
      remember,
      `${stripeSessionID}`
    );
    if (status === 'failure' && message) {
      this.setState({
        errorMessage: capitalizeSentences(
          (message as { message: string })?.message || (message as string)
        ),
        loggingIn: false
      });
    } else if (loginToken && id) {
      const mfaParams = { loginToken, id, role };
      setLocalStorageItem('mfaParams', mfaParams);
      this.setState({ mfaParams, loggingIn: false });
    } else await onLoginStatusChange();
  }

  async validateMFACode() {
    const { mfaParams, mfaCode } = this.state;
    const { onLoginStatusChange } = this.props;

    this.setState({ loggingIn: true });

    const { status, message } = await fetcher.validateMFA(mfaParams, mfaCode);

    if (status === 'success') {
      setLocalStorageItem('role', mfaParams.role);
      removeLocalStorageItem('mfaParams');
      await onLoginStatusChange();
    } else {
      this.setState({ errorMessage: capitalizeSentences(message), loggingIn: false });
    }
  }

  componentWillUnmount() {
    removeLocalStorageItem('mfaParams');
  }

  render() {
    const { className, location } = this.props;
    const { errorMessage, loggingIn, email, rememberMe, mfaParams, mfaCode, showPassword } =
      this.state;

    return (
      <div id="login" className={ClassNames(className, 'page_view', 'center_alignment')}>
        <h1 className="heading">Sign in</h1>
        {mfaParams && mfaParams.loginToken ? (
          <div className="sign_in_form">
            <input
              type="number"
              value={mfaCode}
              placeholder="MFA Code"
              onChange={({ target }) => this.setState({ mfaCode: target.value })}
              onKeyDown={this.onMFAKeyPress}
            />
            <Link id="forgotpassword" to="forgot-password" />
            <span className={ClassNames('forgot', 'lost_mfa')}>
              Lost MFA device?{' '}
              <Link className="lightblue_link" to="contact">
                Contact us
              </Link>
            </span>
            <Button
              text={loggingIn ? 'Logging in...' : 'Log in'}
              action={() => this.validateMFACode()}
            />
            <ErrorMessage align="center">{errorMessage}</ErrorMessage>
          </div>
        ) : (
          <div className="sign_in_form">
            <input
              id="email_login"
              type="email"
              placeholder="Email"
              autoFocus
              value={email}
              onChange={({ target }) => this.setState({ email: target.value.toLowerCase().trim() })}
              onKeyDown={this.onKeyPress}
            />
            <div className="password_wrapper">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                ref={this.password}
                placeholder="Password"
                onKeyDown={this.onKeyPress}
              />
              <span
                className={showPassword ? 'icon_hide' : 'icon_show'}
                onMouseDown={() => this.setState({ showPassword: true })}
                onMouseUp={() => this.setState({ showPassword: false })}
                onTouchStart={() => this.setState({ showPassword: true })}
                onTouchEnd={() => this.setState({ showPassword: false })}
              />
            </div>
            <div className="login_options">
              <Checkbox
                checkboxID="remember"
                label="Keep me signed in"
                checked={rememberMe}
                onChange={checked => this.setState({ rememberMe: checked })}
              />
              <Link className="forgot" to="forgot-password">
                Forgot Password?
              </Link>
            </div>
            <Button
              text={loggingIn ? 'Logging in...' : 'Log in'}
              action={() => this.submitLogin()}
            />
            <ErrorMessage align="center">{errorMessage}</ErrorMessage>
            <LineDivider text="Or" />
            <div className="sign_up_block">
              Not on cord yet?{' '}
              <Link className="lightblue_link" to="/signup">
                {' '}
                Get started
              </Link>
            </div>
            <span className="small_line" />
            <div className="sign_up_block">
              If you're hiring and want to join cord,
              <Link className="lightblue_link" to="/request-demo">
                book a demo
              </Link>
              <br /> and start filling your pipeline
            </div>
          </div>
        )}
        <Seo
          title="Login"
          description="Login to cord and start speaking directly with technology companies hiring in the UK, USA and remote across Europe."
          path={location.pathname}
          contentType="website"
        />
      </div>
    );
  }
}
