import React, { PropsWithChildren, forwardRef } from 'react';
import { NavLink as Link, NavLinkProps } from 'react-router-dom';
import ClassNames from 'classnames';

import { useDarkMode } from 'hooks/useDarkMode';
import { AnchorClickEvent, ButtonClickEvent } from 'types';

import Loader from 'components/loader';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

const buttonStyles = [
  'fill_dark_blue',
  'fill_blue',
  'fill_white',
  'fill_red',
  'fill_grey',
  'stroke_dark_blue',
  'stroke_blue',
  'stroke_white',
  'stroke_red',
  'stroke_grey',
  'whatsapp_button',
  'linkedin_button',
  'facebook_button',
  'twitter_button',
  'ghost_dark_blue',
  'ghost_blue',
  'ghost_white',
  'ghost_red',
  'ghost_grey',
  'fill_cord_gradient',
  'stroke_cord_gradient',
  'fill_secondary_gradient'
] as const;

export type Props = {
  text?: string | string[];
  buttonStyle?: typeof buttonStyles[number] | string;
  icon?: string;
  iconPosition?: 'left' | 'right';
  image?: {
    src?: string;
    alt?: string;
  };
  className?: string;
  link?: NavLinkProps['to'];
  action?: (e?: AnchorClickEvent | ButtonClickEvent) => void;
  onHover?: (bool: boolean) => void;
  loading?: boolean;
  tooltip?: JSX.Element | null;
  disabled?: boolean;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
};

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    {
      text,
      buttonStyle,
      icon,
      iconPosition = 'left',
      image,
      className,
      link,
      action,
      onHover,
      loading,
      disabled,
      id,
      children,
      type = 'button',
      tooltip
    },
    ref
  ) => {
    const darkMode = useDarkMode();

    const classNames = ClassNames('button', buttonStyle, className, {
      disabled,
      with_icon: icon || image,
      icon_only: icon && !text && !children,
      dark: darkMode
    });

    if (link) {
      return (
        <Link
          id={id}
          to={link}
          className={classNames}
          onClick={
            loading || disabled
              ? () => {
                  return;
                }
              : action
          }
        >
          {image && <img className="icon" src={image.src} alt={image.alt} />}
          {icon && iconPosition === 'left' && <span className={ClassNames('icon', 'left', icon)} />}
          {text || children}
          {icon && iconPosition === 'right' && (
            <span className={ClassNames('icon', 'right', icon)} />
          )}
          <ConditionalRender predicate={tooltip}>{tooltip}</ConditionalRender>
        </Link>
      );
    } else {
      return (
        <button
          ref={ref}
          id={id}
          type={type}
          className={classNames}
          onClick={
            loading || disabled
              ? () => {
                  return;
                }
              : action
          }
          onMouseEnter={
            onHover
              ? () => onHover(true)
              : () => {
                  return;
                }
          }
          onMouseLeave={
            onHover
              ? () => onHover(false)
              : () => {
                  return;
                }
          }
        >
          {loading ? (
            <>
              <Loader className={ClassNames('button_loader', 'small')} />
              <div className="hidden">{text || children}</div>
            </>
          ) : icon ? (
            <>
              {iconPosition === 'left' && <span className={ClassNames('icon', 'left', icon)} />}
              {text || children}
              {iconPosition === 'right' && <span className={ClassNames('icon', 'right', icon)} />}
            </>
          ) : image ? (
            <>
              <img className="icon" src={image.src} alt={image.alt} />
              {text || children}
            </>
          ) : (
            text || children
          )}
          <ConditionalRender predicate={tooltip && !loading}>{tooltip}</ConditionalRender>
        </button>
      );
    }
  }
);

export default Button;
