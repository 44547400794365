import styled, { css } from 'styled-components';

const StretchToAspectRatio = (
  aspectRatio: string | number,
  maxWidth: string | number = '100%'
) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: ${aspectRatio};
  max-width: ${maxWidth};
`;

const FixedHeight = (height: string | number) => css`
  height: ${height}px;
`;

export const VideoWrapper = styled.div<{
  height: string | number;
  aspectRatio?: string | number;
  maxWidth?: string | number;
  width?: string | number;
}>`
  ${({ theme: { colours, darkMode, typography }, height, aspectRatio, maxWidth }) => css`
    position: relative;
    text-align: center;
    background-color: var(--background-body-secondary);
    color: ${darkMode ? colours.fontColour : colours.inputsColour};
    border-radius: 20px;
    overflow: hidden;

    ${aspectRatio ? StretchToAspectRatio(aspectRatio, maxWidth) : FixedHeight(height)}

    &:before {
      position: absolute;
      content: 'Loading ...';
      font-weight: ${typography.bold};
      font-size: 18px;
      left: 0;
      width: 100%;
      top: 45%;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  `}
`;

export const PausedContainer = styled.div<{ height: number | string }>`
  position: absolute;
  width: 100%;
  height: ${({ height }) => height}px;
`;

export const PlayIcon = styled.img`
  position: absolute;
  z-index: 1;
  right: 50%;
  bottom: 50%;
  margin-right: -37px;
  margin-bottom: -38px;
  cursor: pointer;
`;

export const VideoImage = styled.img`
  position: relative;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
