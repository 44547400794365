import { LocationInterface, ParsedCandidateLocationPreference } from '@cohiretech/common-types';

export type onChangeLocation = (
  locationName: string,
  locationObject: Partial<LocationInterface>,
  coordinates: null | {
    lat: number;
    lng: number;
  }
) => void;

export interface ILocationValue {
  label?: string;
  tooltip?: string;
  value?: LocationInterface;
}

export enum Locale {
  en_UK = 'en-GB',
  en_US = 'en-US'
}

export type LocationLabel = 'Anywhere' | 'Continent/Country';

export type ExtendedCandidateLocationPreference = ParsedCandidateLocationPreference & {
  isAnywhere?: boolean;
};

export type RemoteLocationData = Pick<
  ExtendedCandidateLocationPreference,
  'remoteLocationCountries' | 'remoteLocationContinents' | 'isAnywhere'
>;

export type GooglePlaceDetails = Pick<
  google.maps.places.Place,
  'addressComponents' | 'adrFormatAddress' | 'formattedAddress' | 'location' | 'types' | 'viewport'
> & {
  shortFormattedAddress: string | null;
};
