import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { smallMobile } from 'responsiveConfig';

import typography from 'css/base/_typography.module.scss';
import colours from 'css/base/_colours.module.scss';

type Props = {
  show?: boolean;
  title?: string;
  darkMode?: boolean;
  position?: 'center' | 'left' | 'right';
  align?: 'left' | 'center' | 'right';
  width?: string;
};

export default function PopOver({
  show,
  title,
  children,
  darkMode,
  position = 'center',
  align = 'left',
  width = '385px'
}: PropsWithChildren<Props>) {
  return (
    <TooltipWrapper
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      position={position}
      data-tooltip="true"
      className="productivity_tooltip_wrapper"
      show={show}
    >
      <Tooltip
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        position={position}
        align={align}
        data-tooltip="true"
        className="productivity_tooltip"
        dark={darkMode}
        width={width}
      >
        {title && <h3 className="tooltip_title">{title}</h3>}
        {children}
      </Tooltip>
    </TooltipWrapper>
  );
}

const TooltipWrapper = styled.div`
  font-family: ${typography.fontFamily}, Helvetica, Arial, sans-serif;
  position: absolute;
  margin-top: 25px;
  z-index: 102;
  display: none;

  ${props =>
    props &&
    (props as $TSFixMe).position === 'right' &&
    css`
      right: -20px;
    `}

  ${props =>
    props &&
    (props as $TSFixMe).position === 'center' &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `}

  ${props =>
    props &&
    (props as $TSFixMe).position === 'left' &&
    css`
      left: -20px;
    `}

  ${props =>
    props &&
    (props as $TSFixMe).show &&
    css`
      display: block;
    `}
`;

const Tooltip = styled.div`
  padding: 20px;
  border-radius: 15px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 15px -5px ${props => ((props as $TSFixMe).dark ? colours.darkDropshadow : colours.dropshadow)};
  background: ${props => ((props as $TSFixMe).dark ? colours.darkBgColour : 'white')};
  width: ${props => (props as $TSFixMe).width};
  text-align: ${props => (props as $TSFixMe).align};
  color: ${props => ((props as $TSFixMe).dark ? colours.lightFontColour : colours.fontColour)};
  font-size: 16px;
  font-weight: ${typography.regular};

  ${props =>
    (props as $TSFixMe).dark &&
    css`
      .copy_text_wrapper {
        color: ${colours.lightFontColour};
        background-color: transparent;
      }
    `}

  @media only screen and (max-width: ${smallMobile}) {
    width: 95vw;
  }

  > strong {
    display: block;
    margin-bottom: 5px;
  }

  h3 {
    margin: 0 auto 15px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    top: -10px;
    background: ${props => ((props as $TSFixMe).dark ? colours.darkBgColour : 'white')};
    box-shadow: -5px -5px 15px -5px
      ${props => ((props as $TSFixMe).dark ? colours.darkDropshadow : colours.dropshadow)};

    ${props =>
      props &&
      (props as $TSFixMe).position === 'right' &&
      css`
        right: 20px;
      `}

    ${props =>
      props &&
      (props as $TSFixMe).position === 'center' &&
      css`
        left: calc(50% - 10px);
      `}

    ${props =>
      props &&
      (props as $TSFixMe).position === 'left' &&
      css`
        left: 20px;
      `}
  }
`;
