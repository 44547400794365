import React, { useState } from 'react';

import { CandidateTracking } from '@cohiretech/common-types';

import { trackCandidateAction } from 'fetcher';
import { segmentTrack } from 'tracking-utils';

import LinkWithIcon from 'components/linkwithicon';
import DialogWindow from 'components/dialogwindow';
import CopyText from 'components/copytext';
import ShareButtons from 'components/sharebuttons';

type Props = {
  darkMode: boolean;
  shareURL: string;
  companyName?: string;
  position: string | undefined;
};

function ShareProfileButton({ darkMode, shareURL, companyName = '', position }: Props) {
  const [showSharingPopup, setShowSharingPopup] = useState(false);

  const sendToFriend = () => {
    segmentTrack('click', {
      category: 'Send to a friend',
      label: `Send a ${profile}`
    });

    setShowSharingPopup(true);
  };

  const profile = position ? 'position' : 'company';
  const message = `${
    position
      ? `I've been using cord and saw this ${position} position at ${companyName} that I thought you might like. You can message the hiring manager directly.`
      : `I've been using cord and I saw ${companyName} hiring and I thought you might be interested. You can message the hiring managers directly about their positions.`
  }
  Here's the link to the ${profile}: ${encodeURIComponent(shareURL)}`;
  const candidateAction = `send_${profile}` as CandidateTracking;

  return (
    <>
      <LinkWithIcon icon="icon_share" text="Send to a friend" action={sendToFriend} />
      {showSharingPopup && (
        <DialogWindow
          id="sharing_popup"
          title={`Send this ${profile} to a friend`}
          subTitle={`${position ? `${position} at ` : ''}${companyName}`}
          visible
          showCloseIcon
          onClose={() => setShowSharingPopup(false)}
        >
          <CopyText
            darkMode={darkMode}
            text={shareURL}
            id={companyName}
            onTrackAction={() => trackCandidateAction(candidateAction)}
          />
          <ShareButtons
            shareURL={shareURL}
            messenger={{ text: 'Send' }}
            linkedinMessenger={{ text: 'Send' }}
            email={{ text: 'Email', message, subject: `A ${profile} you might like` }}
            sms={{ text: 'SMS', message }}
            whatsapp={{ text: 'Send', message }}
            onTrackAction={() => trackCandidateAction(candidateAction)}
          />
        </DialogWindow>
      )}
    </>
  );
}

export default ShareProfileButton;
