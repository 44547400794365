import { APICancelledResponse, APIFailureResponse, APIResponse, APISuccessResponse } from 'types';

export const isSuccess = <T>(response: APIResponse<T>): response is APISuccessResponse<T> =>
  response.status === 'success';

export const isFailure = <T>(response: APIResponse<T>): response is APIFailureResponse =>
  response.status === 'failure';

export const unwrapAPIResponse = async <T>(
  request: Promise<APIResponse<T> | APICancelledResponse>
): Promise<T> => {
  const response = await request;
  if (response.status === 'success' && response.data) {
    return response.data;
  }
  throw new Error(`API request failed with status: ${response.status}`);
};
