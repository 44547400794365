import { OperatingCountry } from '@cohiretech/common-types';

import {
  checkIfESSUProgramPage,
  isAgenciesPage,
  isFoundersPage,
  isSanFranLandingPage,
  isForCompaniesPage,
  isRequestDemoPage
} from 'utils/url';

import CheckoutcomDark from 'images/blue-checkout.png';
import DepopDark from 'images/blue-depop.png';
import GoustoDark from 'images/blue-gousto.svg';
import OcadoDark from 'images/blue-ocado.svg';
import PlaytechDark from 'images/blue-playtech.png';
import SkyDark from 'images/blue-sky.png';
import AWSDark from 'images/blue-aws.svg';
import Checkoutcom from 'images/white-checkout.png';
import Depop from 'images/depop.png';
import Gousto from 'images/white-gousto.png';
import Ocado from 'images/white-ocado.png';
import Playtech from 'images/white-playtech.png';
import Sky from 'images/white-sky.png';
import AWS from 'images/white-aws.svg';
import AmexDark from 'images/blue-amex.svg';
import BlockDark from 'images/blue-block.png';
import FanduelDark from 'images/blue-fanduel.png';
import IvantiDark from 'images/blue-ivanti.png';
import LystDark from 'images/blue-lyst.svg';
import MarsDark from 'images/blue-mars.png';
import RoktDark from 'images/blue-rokt.png';
import Amex from 'images/white-amex.png';
import Block from 'images/white-block.png';
import Fanduel from 'images/white-fanduel.png';
import Ivanti from 'images/white-ivanti.png';
import Lyst from 'images/white-lyst.png';
import Mars from 'images/white-mars.png';
import Rokt from 'images/white-rokt.png';
import OrdaosDark from 'images/blue-ordaos.png';
import RibbonDark from 'images/blue-ribbon.png';
import OpplyDark from 'images/blue-opply.png';
import AztecDark from 'images/blue-aztec.png';
import Ordaos from 'images/white-ordaos.png';
import Ribbon from 'images/white-ribbon.png';
import Opply from 'images/white-opply.png';
import Aztec from 'images/white-aztec.png';
import MomentumDark from 'images/blue-momentum.svg';
import SpillDark from 'images/blue-spill.svg';
import Momentum from 'images/white-momentum.svg';
import Spill from 'images/white-spill.svg';
import LorienDark from 'images/blue-lorien.svg';
import Lorien from 'images/white-lorien.svg';
import FormulaDark from 'images/blue-formula.svg';
import Formula from 'images/white-formula.svg';
import ExploreGroupDark from 'images/blue-explore-group.svg';
import ExploreGroup from 'images/white-explore-group.svg';
import OliverBernardDark from 'images/blue-oliver-bernard.svg';
import OliverBernard from 'images/white-oliver-bernard.svg';
import SR2Dark from 'images/blue-sr2.svg';
import SR2 from 'images/white-sr2.svg';
import Cleo from 'images/white-cleo.svg';
import CleoDark from 'images/blue-cleo.svg';
import Starling from 'images/white-starling.svg';
import StarlingDark from 'images/blue-starling.svg';
import Virgin from 'images/white-virgin.svg';
import VirginDark from 'images/blue-virgin.svg';
import Visa from 'images/white-visa.svg';
import VisaDark from 'images/blue-visa.svg';
import ThoughtMachine from 'images/white-thoughtmachine.svg';
import ThoughtMachineDark from 'images/blue-thoughtmachine.svg';
import Zopa from 'images/white-zopa.svg';
import ZopaDark from 'images/blue-zopa.svg';
import Octopus from 'images/white-octopus.svg';
import OctopusDark from 'images/blue-octopus.svg';
import OVO from 'images/white-ovo.svg';
import OVODark from 'images/blue-ovo.svg';
import Fresha from 'images/white-fresha.svg';
import FreshaDark from 'images/blue-fresha.svg';
import Motorway from 'images/white-motorway.svg';
import MotorwayDark from 'images/blue-motorway.svg';

type LogoItem = {
  name: string;
  dark: string;
  light: string;
};

type LogoList = Record<OperatingCountry, LogoItem[]>;

const FOR_COMPANIES_LOGOS = [
  { name: 'American Express', dark: AmexDark, light: Amex },
  { name: 'AWS', dark: AWSDark, light: AWS },
  { name: 'Ocado Technologys', dark: OcadoDark, light: Ocado },
  { name: 'OVO Energy', dark: OVODark, light: OVO },
  { name: 'Starling Bank', dark: StarlingDark, light: Starling },
  { name: 'Virgin', dark: VirginDark, light: Virgin },
  { name: 'Visa', dark: VisaDark, light: Visa },
  { name: 'Thought Machine', dark: ThoughtMachineDark, light: ThoughtMachine },
  { name: 'Zopa', dark: ZopaDark, light: Zopa },
  { name: 'Octopus Investments', dark: OctopusDark, light: Octopus },
  { name: 'Gousto', dark: GoustoDark, light: Gousto },
  { name: 'Fresha', dark: FreshaDark, light: Fresha },
  { name: 'Lyst', dark: LystDark, light: Lyst },
  { name: 'Motorway', dark: MotorwayDark, light: Motorway }
];

const UK_LOGOS = [
  { name: 'AWS', dark: AWSDark, light: AWS },
  { name: 'Depop', dark: DepopDark, light: Depop },
  { name: 'Gousto', dark: GoustoDark, light: Gousto },
  { name: 'Sky', dark: SkyDark, light: Sky },
  { name: 'Checkout.com', dark: CheckoutcomDark, light: Checkoutcom },
  { name: 'Ocado Technology', dark: OcadoDark, light: Ocado },
  { name: 'Playtech', dark: PlaytechDark, light: Playtech }
];

const US_LOGOS = [
  { name: 'AWS', dark: AWSDark, light: AWS },
  { name: 'Amex', dark: AmexDark, light: Amex },
  { name: 'Block', dark: BlockDark, light: Block },
  { name: 'Fanduel', dark: FanduelDark, light: Fanduel },
  { name: 'Ivanti', dark: IvantiDark, light: Ivanti },
  { name: 'Lyst', dark: LystDark, light: Lyst },
  { name: 'Mars', dark: MarsDark, light: Mars },
  { name: 'Rokt', dark: RoktDark, light: Rokt }
];

const SAN_FRAN_LOGOS = [
  { name: 'AWS', dark: AWSDark, light: AWS },
  { name: 'Amex', dark: AmexDark, light: Amex },
  { name: 'Cleo', dark: CleoDark, light: Cleo },
  { name: 'Fanduel', dark: FanduelDark, light: Fanduel },
  { name: 'Starling', dark: StarlingDark, light: Starling }
];

const FOUNDER_LOGOS = [
  { name: 'Ordaos', dark: OrdaosDark, light: Ordaos },
  { name: 'Ribbon', dark: RibbonDark, light: Ribbon },
  { name: 'Opply', dark: OpplyDark, light: Opply },
  { name: 'CVPartner', dark: AztecDark, light: Aztec }
];

const EARLY_STAGE_START_UP_LOGOS = [
  { name: 'Ribbon', dark: RibbonDark, light: Ribbon },
  { name: 'Ordaos', dark: OrdaosDark, light: Ordaos },
  { name: 'Momentum', dark: MomentumDark, light: Momentum },
  { name: 'Spill', dark: SpillDark, light: Spill }
];

const AGENCY_LOGOS = [
  { name: 'Lorien', dark: LorienDark, light: Lorien },
  { name: 'Formula', dark: FormulaDark, light: Formula },
  { name: 'Explore Group', dark: ExploreGroupDark, light: ExploreGroup },
  { name: 'Oliver Bernard', dark: OliverBernardDark, light: OliverBernard },
  { name: 'SR2', dark: SR2Dark, light: SR2 }
];

export const getLogos = (country?: OperatingCountry): LogoItem[] => {
  if (isFoundersPage()) return FOUNDER_LOGOS;
  if (checkIfESSUProgramPage()) return EARLY_STAGE_START_UP_LOGOS;
  if (isAgenciesPage()) return AGENCY_LOGOS;
  if (isSanFranLandingPage() || (isRequestDemoPage() && country === OperatingCountry.US)) {
    return SAN_FRAN_LOGOS;
  }
  if (isForCompaniesPage()) return FOR_COMPANIES_LOGOS;

  const logos: LogoList = {
    UK: UK_LOGOS,
    US: US_LOGOS,
    // This needs to be added if we want to use this type to access logos
    EU: UK_LOGOS
  };

  return logos[country || OperatingCountry.UK];
};

export const getLogosDescription = () => {
  if (isFoundersPage()) {
    return 'Join 200+ early-stage companies using cord to hire their first positions';
  }

  if (isAgenciesPage()) {
    return 'Join the 1,000+ companies and agencies making hires on cord';
  }

  if (checkIfESSUProgramPage()) return 'Join 400+ small companies using cord to make direct hires';

  return 'Join the 1,000+ companies using cord to make direct hires';
};
