import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Tooltip from 'components/tooltip';

type Props = {
  children: ReactNode;
  count: number;
  color: string;
  title: string;
  characterStyle?: 'text' | 'box';
  tooltipPosition?: 'top' | 'left' | 'right' | 'bottom';
};

export default function CharacterCountTooltip({
  count,
  characterStyle = 'text',
  title,
  color,
  tooltipPosition = 'top',
  children
}: Props) {
  return (
    <Counter className="character_count" characterStyle={characterStyle} color={color}>
      {count} characters
      <Tooltip position={tooltipPosition} bgColor={color}>
        <strong>{title}</strong>
        {children}
      </Tooltip>
    </Counter>
  );
}

const Counter = styled.span<Pick<Props, 'color' | 'characterStyle'>>`
  position: relative;

  background: ${props => (props.characterStyle === 'text' ? 'inherit' : props.color)};
  color: ${props => (props.characterStyle === 'text' ? props.color : 'inherit')};

  .tooltip_wrapper {
    text-align: left;
    width: 320px;

    .link {
      font-weight: ${({ theme: { typography } }) => typography.semibold};
      color: white;
    }

    b {
      font-weight: ${({ theme: { typography } }) => typography.semibold};
    }

    strong {
      display: block;
      margin-bottom: 5px;
    }
  }

  &:hover .tooltip_wrapper,
  .tooltip_wrapper:hover {
    display: block;
  }
`;
