import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { mediaQueries } from 'responsiveConfig';
import useMedia from 'hooks/useMedia';
import { isCompaniesLandingPage } from 'utils/url';
import useLockedScroll from 'hooks/useLockScroll';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

const TOP_NAV_HEIGHT = 70;

type MenuModalProps = {
  visible?: boolean;
  darkMode: boolean;
};

const MenuModal = ({ visible = true, children, darkMode }: PropsWithChildren<MenuModalProps>) => {
  const isTablet = useMedia([mediaQueries.tablet]);
  const isCompaniesPage = isCompaniesLandingPage();
  useLockedScroll(visible);

  return (
    <ConditionalRender predicate={isTablet}>
      <MenuModalWrap visible={visible} darkMode={darkMode} isForCompaniesPage={isCompaniesPage}>
        {children}
      </MenuModalWrap>
    </ConditionalRender>
  );
};

type StyleProps = { isForCompaniesPage: boolean } & Pick<MenuModalProps, 'visible' | 'darkMode'>;

const MenuModalWrap = styled.div<StyleProps>`
  position: fixed;
  top: ${TOP_NAV_HEIGHT}px;
  left: 0;
  height: ${({ visible }) => (visible ? `calc(100vh - ${TOP_NAV_HEIGHT}px)` : '0')};
  width: 100%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  padding-top: 0;
  background-color: ${({ darkMode }) => (darkMode ? colours.darkerBgColour : '#fff')};

  ${({ visible }) =>
    visible &&
    `
    height: calc(100vh - ${TOP_NAV_HEIGHT}px);
    overflow: auto;
  `}
  ${({ isForCompaniesPage, darkMode }) =>
    isForCompaniesPage &&
    css`
      background-color: ${darkMode ? colours.fontColourDarkShade : colours.fontColour};
    `}
`;

export default MenuModal;
