import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useAppDispatch, useAppSelector } from 'store/store';
import { isUndefined } from 'utils/fn';
import { getSavedSearchIDFromURL, prepareFiltersQuery } from 'utils';
import { isNewSearch } from 'utils/url';
import { getDefaultSorting } from 'v2/services/candidate';
import { getFindingWorkSearchDefaultViewType } from 'v2/services/tools/searchViewType';
import { SearchItem } from 'types';

import { SearchOption } from 'v2/components/ui/molecules/SearchOptionList';

import {
  selectCurrentSearch,
  selectSaveSearchPopup,
  selectSavedSearchToDelete,
  selectSavedSearches,
  selectSearchOptions
} from './candidateSearch.selectors';
import {
  deleteCandidateSavedSearch,
  fetchCandidateSavedSearches,
  fetchCandidateSearchOptions,
  saveCandidateSavedSearch
} from './candidateSearch.thunks';
import { setCurrentSearch, setSavedSearchToDelete, toggleSaveSearchPopup } from './candidateSearch';
import {
  CandidateSavedSearch,
  ToggleSaveSearchPopupParams,
  UpdateCandidateSavedSearchParams,
  findSavedSearchById
} from './candidateSearch.helpers';

export const useCandidateSearchOptions = () => {
  const dispatch = useAppDispatch();
  const { byCategory, keyword } = useAppSelector(selectSearchOptions) || {};

  useEffect(() => {
    dispatch(fetchCandidateSearchOptions());
  }, []);

  return { searchOptionsByCategory: byCategory, keywordOptions: keyword };
};

export const useNavigateToNewSearch = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const navigateToNewSearch = (option?: SearchOption) => {
    dispatch(setCurrentSearch()); // Clear the current search

    if (!option) return history.push('/candidate/search/new');

    const { value, label, attribute } = option;
    const isNewSearchPage = isNewSearch(history.location.pathname);
    const filter = { label: label || value, value, attribute } as SearchItem;

    if (isNewSearchPage) return history.push({ state: filter });

    const parsedFilters = prepareFiltersQuery([
      getDefaultSorting({ newSearchItem: filter }),
      filter
    ]);

    const viewType = getFindingWorkSearchDefaultViewType();

    history.push({
      pathname: '/candidate/search/new',
      search: `filters=${parsedFilters}&v=${encodeURIComponent(JSON.stringify(viewType))}`
    });
  };

  return { navigateToNewSearch };
};

export const useCandidateSavedSearches = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentSearch = useAppSelector(selectCurrentSearch);
  const savedSearches = useAppSelector(selectSavedSearches);
  const saveSearchPopup = useAppSelector(selectSaveSearchPopup);
  const savedSearchToDelete = useAppSelector(selectSavedSearchToDelete);

  const { pathname } = history.location;
  const savedSearchID = getSavedSearchIDFromURL(pathname);

  useEffect(() => {
    dispatch(fetchCandidateSavedSearches());
  }, []);

  useEffect(() => {
    const savedSearch = selectSavedSearchById(savedSearchID);

    if (savedSearch) dispatch(setCurrentSearch(savedSearch));
  }, [savedSearchID, savedSearches]);

  const selectSavedSearchById = useCallback(
    (id?: number) => {
      if (id && id > 0 && savedSearches) return findSavedSearchById(savedSearches, id).value;
    },
    [savedSearches]
  );

  const toggleDeleteSavedSearchPopup = (savedSearchID?: number) => {
    const searchToDelete = isUndefined(savedSearchID)
      ? savedSearchID
      : selectSavedSearchById(savedSearchID);

    dispatch(setSavedSearchToDelete(searchToDelete));
  };

  return {
    currentSearch,
    savedSearches,
    saveSearchPopup,
    savedSearchToDelete,
    setCurrentSearch: (search?: CandidateSavedSearch) => dispatch(setCurrentSearch(search)),
    fetchCandidateSavedSearches: (reload?: boolean) =>
      dispatch(fetchCandidateSavedSearches(reload)),
    saveCandidateSavedSearch: (params: UpdateCandidateSavedSearchParams) =>
      dispatch(saveCandidateSavedSearch(params)),
    toggleSaveSearchPopup: (params?: ToggleSaveSearchPopupParams) =>
      dispatch(toggleSaveSearchPopup(params)),
    toggleDeleteSavedSearchPopup,
    handleDeleteSavedSearch: () => dispatch(deleteCandidateSavedSearch(history))
  };
};
