import React, { useEffect, useRef, useState } from 'react';
import ClassNames from 'classnames';

import {
  GooglePlaceAddressComponentType,
  GooglePrimaryTypes,
  LocationAutocompleteItem,
  LocationInterface
} from '@cohiretech/common-types';

import {
  createLocationObjectFromGooglePlace,
  getCoordinatesFromGooglePlace,
  isEmpty,
  isValidGooglePlace
} from 'utils';
import { onChangeLocation } from 'types';
import { useArrowKeysNavigation } from 'hooks/useArrowKeysNavigation';
import {
  getLocationAutocompleteResults,
  getLocationPlaceDetails
} from 'v2/services/fetchers/common/googlePlaces';
import { isArray } from 'utils/fn';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import SubmitArrowButton from 'v2/components/ui/atoms/SubmitArrowButton';
import { InputWrapper } from 'v2/components/ui/styles';
import FieldErrorMessage from 'v2/components/ui/molecules/FieldErrorMessage';

import { getRegionsAutocomplete } from './locationinput.helpers';
import { LocationInputWrapper, LocationList } from './locationinput.styles';

export type LocationInputProps = {
  id?: string;
  placeType?: GooglePrimaryTypes | GooglePlaceAddressComponentType[];
  allowAddress?: boolean;
  noRegions?: boolean;
  placeholder?: string;
  showSubmitArrow?: boolean;
  clearInputOnPlaceSelected?: boolean;
  onSelect: onChangeLocation;
  focus?: boolean;
  icon?: string;
  defaultValue?: string;
  noInputMessage?: string;
  formSubmitted?: boolean;
  disabled?: boolean;
  disabledPlaceholder?: string;
};

const LOCATION_LIST_ID = 'location_list';
const AUTOCOMPLETE_DEBOUNCE_DELAY = 300;

export default function LocationInput({
  id,
  placeType,
  allowAddress,
  noRegions,
  placeholder = 'Select location',
  showSubmitArrow,
  clearInputOnPlaceSelected,
  onSelect,
  focus,
  icon,
  defaultValue = '',
  noInputMessage,
  formSubmitted,
  disabled,
  disabledPlaceholder
}: LocationInputProps) {
  const [value, setValue] = useState(defaultValue);
  const [focused, setFocused] = useState(focus);
  const [sessionToken, setSessionToken] = useState(crypto.randomUUID());
  const [places, setPlaces] = useState<LocationAutocompleteItem[]>([]);
  const [locationParams, setLocationParams] = useState<Parameters<onChangeLocation>>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const invalidInput = Boolean(
    (formSubmitted || showErrorMessage) && (!value || !defaultValue) && noInputMessage
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const focusAutocomplete = () => {
      const input = inputRef.current;
      input?.focus();

      setFocused(true);
    };

    if (focus) focusAutocomplete();
  }, [focus]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let debounceTimer: ReturnType<typeof setTimeout> | undefined;

    if (focused && isEmpty(locationParams)) {
      debounceTimer = setTimeout(() => onAutocomplete(value), AUTOCOMPLETE_DEBOUNCE_DELAY);
    }
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [value, locationParams, focused]);

  const onAutocomplete = async (input: string) => {
    if (input) {
      const types = isArray(placeType)
        ? placeType
        : [placeType || (allowAddress ? GooglePrimaryTypes.Geocode : GooglePrimaryTypes.Regions)];

      const { data } = await getLocationAutocompleteResults({
        input,
        sessionToken,
        types
      });
      const matchingRegions = getRegionsAutocomplete(input);
      const autocompleteResults = noRegions ? data || [] : matchingRegions.concat(data || []);

      setPlaces(autocompleteResults);
    } else {
      setPlaces([]);
    }
  };

  const onPlaceSelect = async (place: LocationAutocompleteItem | null) => {
    let locationName = place?.name || '';
    let locationObject: Partial<LocationInterface> = { locationName };
    let coordinates = null;

    if (place?.id) {
      const { data } = await getLocationPlaceDetails({ placeId: place.id, sessionToken });

      if (isValidGooglePlace(data)) {
        locationObject = createLocationObjectFromGooglePlace(data);
        locationName = data?.formattedAddress || locationName;
        coordinates = getCoordinatesFromGooglePlace(data);
      }
    } else if (place?.id === '') {
      // Regions in REGIONS_ARRAY that don't have placeId
      locationObject.region = [locationName || ''];
    }

    if (!showSubmitArrow) onSelect(locationName, locationObject, coordinates);

    setLocationParams([locationName, locationObject, coordinates]);
    setValue(clearInputOnPlaceSelected ? '' : locationName);
    setPlaces([]);
    setSessionToken(crypto.randomUUID()); //update session token
  };

  const onSubmit = () => {
    if (locationParams) onSelect(...locationParams);
  };

  const { focusedIdx, handleArrowKeys, resetFocusedIdx } = useArrowKeysNavigation({
    listLength: places.length,
    listID: LOCATION_LIST_ID
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setLocationParams(undefined);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const placePrediction = places[focusedIdx];

    handleArrowKeys(e);

    if (e.key === 'Enter' && placePrediction) {
      onPlaceSelect(placePrediction);
      resetFocusedIdx();
    } else if (e.key === 'Enter' && showSubmitArrow && value) onSubmit();
  };

  const handleOnBlur = () => {
    setShowErrorMessage(true);

    setTimeout(() => {
      setPlaces([]);
      setFocused(false);
      resetFocusedIdx();
    }, 500);
  };

  return (
    <LocationInputWrapper className="location_autocomplete_wrapper">
      <InputWrapper className="location_input_wrapper" invalidInput={invalidInput}>
        <ConditionalRender predicate={icon}>
          <label htmlFor={id || 'location_input'}>
            <span className={icon} />
          </label>
        </ConditionalRender>
        <input
          ref={inputRef}
          type="text"
          id={id || 'location_input'}
          name="location_input"
          className="location_input"
          placeholder={disabled ? disabledPlaceholder || placeholder : placeholder}
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          onFocus={() => setFocused(true)}
          disabled={disabled}
        />
        <ConditionalRender predicate={showSubmitArrow}>
          <SubmitArrowButton valid={!!locationParams} onSubmit={onSubmit} />
        </ConditionalRender>
      </InputWrapper>
      <FieldErrorMessage show={invalidInput} message={noInputMessage} />
      <ConditionalRender predicate={!isEmpty(places)}>
        <LocationList className="location_list" id={LOCATION_LIST_ID}>
          {places &&
            places.map(({ id, name }, index) => {
              if (name) {
                return (
                  <div
                    className={ClassNames('location_list_item', { focused: focusedIdx === index })}
                    id={`${LOCATION_LIST_ID}_item_${index}`}
                    key={`${id}_${index}`}
                    onClick={() => onPlaceSelect({ id, name })}
                  >
                    <span className="icon_map" />
                    {name}
                  </div>
                );
              }
              return null;
            })}
        </LocationList>
      </ConditionalRender>
    </LocationInputWrapper>
  );
}
