import React, { useState } from 'react';

import { addURLProtocol } from 'utils';

import Field from 'components/field';
import DialogWindow from 'components/dialogwindow';
import ErrorMessage from 'components/errormessage';

type Props = {
  hyperLink: string;
  hyperLinkText: string;
  onButtonClick: (fixedLink: string, fixedText: string) => void;
  onSecondButtonClick: (link: string, text: string) => void;
  visible: boolean;
  title?: string;
  buttonText?: string;
  secondButtonText?: string;
  cancel?: boolean;
  onClose?: () => void;
};

function AddHyperlink({
  hyperLink,
  hyperLinkText,
  onButtonClick,
  onSecondButtonClick,
  visible,
  title,
  buttonText,
  secondButtonText,
  cancel,
  onClose
}: Props) {
  const [link, setLink] = useState(hyperLink || '');
  const [text, setText] = useState(hyperLinkText || '');
  const [errorMessage, setErrorMessage] = useState('');

  const checkLinkValues = (link: string, text: string) => {
    if (link === '') setErrorMessage('Please enter a link and then click Insert');
    else {
      const fixedLink = addURLProtocol(link);
      const fixedText = text === '' ? link : text;
      onButtonClick(fixedLink, fixedText);

      setLink('');
      setText('');
      setErrorMessage('');
    }
  };

  const onSecondButtonClickFn = (link: string, text: string) => {
    onSecondButtonClick(link, text);

    setLink('');
    setText('');
    setErrorMessage('');
  };

  return (
    <DialogWindow
      id="addHyperlink"
      title={title}
      visible={visible}
      showCloseIcon
      onClose={onClose}
      primaryButton={{ text: buttonText, action: () => checkLinkValues(link, text) }}
      secondaryButton={{ text: secondButtonText, action: () => onSecondButtonClickFn(link, text) }}
      skipLink={{ text: cancel ? 'Cancel' : '' }}
      exitAlert="Cancel"
    >
      <Field
        label="Link"
        placeholder="https://example.com"
        value={link}
        onChange={(value: string) => setLink(value)}
      />
      <Field
        label="Text"
        placeholder="Example"
        value={text}
        onChange={(value: string) => setText(value)}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </DialogWindow>
  );
}

export default AddHyperlink;
