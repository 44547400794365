import React, { useEffect, useState } from 'react';

import { editPosition } from 'fetcher';

import DialogWindow from 'components/dialogwindow';
import ErrorMessage from 'components/errormessage';
import Field from 'components/field';

/**
 * JY: There may be no need to create a new component for this,
 * but the CompanyMenu component is quite complex so I've decided to separate this.
 */

type Props = {
  show?: boolean;
  positionID: number;
  resumePosition: () => Promise<void>;
};

export default function AddCareerPagePopup({ show, positionID, resumePosition }: Props) {
  const [visible, setVisible] = useState<boolean>();
  const [externalURL, setExternalURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [resumed, setResumed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setVisible(show);
  }, [show]);

  const saveExternalURL = async () => {
    if (!externalURL) {
      setErrorMessage('Please enter your career page URL.');
      return;
    }

    setLoading(true);

    const { status, message } = await editPosition({ externalURL, external: true }, positionID);

    if (status === 'success') {
      await resumePosition();
      setResumed(true);
    } else {
      setErrorMessage(message);
    }
  };

  if (!visible) return null;

  if (resumed) {
    return (
      <DialogWindow
        visible
        title="Your position is now live!"
        subTitle={
          'Candidates will now be able to find your position on cord, and visit your career page to apply. We will automatically save every candidate who visits your career page from cord, so you can easily find them in your "Saved candidates" page.'
        }
        primaryButton={{ text: 'Got it!', action: () => setVisible(false) }}
        showCloseIcon
        onClose={() => setVisible(false)}
      />
    );
  }

  return (
    <DialogWindow
      visible
      title="Add your career page URL"
      subTitle="Positions from companies with no direct messaging access require a career page URL to redirect candidates applications."
      primaryButton={{ text: 'Save & open position', action: saveExternalURL }}
      loading={loading}
      showCloseIcon
      onClose={() => setVisible(false)}
    >
      <Field
        label="Career page URL for applications"
        placeholder="Eg. www.workable.com/cord/front-end-developer-234"
        value={externalURL}
        onChange={(value: string) => setExternalURL(value)}
        footnote="For companies with no direct messaging access, cord will redirect candidates to your career page to apply."
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </DialogWindow>
  );
}
