import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CompanySearchTotalDTO } from '@cohiretech/common-types';

import { SearchItems } from 'types';
import { setLocalStorageItem } from 'cookieManager';
import { capitalizeSentences } from 'utils/string';
import { wrapAndModifySearchResults } from 'v2/services/company/searchResults';
import { hideScrollbar } from 'v2/services/ui';

import {
  getNextCandidateResults,
  searchCandidates,
  toggleCandidateVisibility
} from './discover.thunks';
import { SearchCandidatesResponse, SearchType } from './discover.types';
import { PAGE_SIZE, getInitialDiscoverState } from './discover.helpers';

const discover = createSlice({
  name: 'discover',
  initialState: getInitialDiscoverState(),
  reducers: {
    resetDiscoverState: (_, action: PayloadAction<SearchType>) =>
      getInitialDiscoverState(action.payload),
    updateSearchItems: (state, action: PayloadAction<SearchItems>) => {
      state.searchItems = action.payload;
    },
    updateCandidateVisibilityStatus: (state, action) => {
      const { id, status } = action.payload;

      const searchResultsClone = [...state.searchResults];
      const newResultCount =
        status === 'visible'
          ? state.searchResultsCounts.resultCount + 1
          : state.searchResultsCounts.resultCount - 1;
      const currentCandidate = searchResultsClone?.find(({ candidate }) => candidate.id === id);

      if (currentCandidate) currentCandidate.status = status;

      state.searchResultsCounts.resultCount = newResultCount;
      state.searchResults = searchResultsClone;
    },
    updateSearchResultsCount: (state, action: PayloadAction<CompanySearchTotalDTO>) => {
      state.searchResultsCounts = {
        ...action.payload,
        resultCount: state.searchResultsCounts.resultCount // don't update the actual result count
      };
    },
    updateCandidateSearchState: (state, action: PayloadAction<SearchCandidatesResponse>) => {
      const {
        ids = [],
        values = [],
        ui,
        message,
        newOrReactivatedCandidateCount = 0,
        searchResultsCounts = {
          resultCount: 0
        }
      } = action.payload;

      // save the uinique identifier for skip/apply next resutls
      setLocalStorageItem('uniqueIdentifier', ui);

      state.searchResultsCounts = {
        resultCount: searchResultsCounts.resultCount,
        activeCount: state.searchResultsCounts.activeCount,
        passiveCount: state.searchResultsCounts.passiveCount,
        totalCount: state.searchResultsCounts.totalCount
      };
      state.searchResultIds = ids;
      state.searchResults = wrapAndModifySearchResults(values);
      state.newOrReactivatedCandidateCount = newOrReactivatedCandidateCount;
      state.loading = false;
      state.nextPage = 1;
      state.endPage = values ? values.length < PAGE_SIZE : false;
      state.uniqueIdentifier = ui || '';
      state.errorMessage = message ? capitalizeSentences(message) : '';
      if (state.searchType === 'search') state.passiveSearch = false;
    },
    updateTotalActiveCount: (state, action: PayloadAction<number>) => {
      state.totalActiveCount = action.payload;
    },
    toggleFiltersMobile: state => {
      hideScrollbar(!state.mobileFiltersOn);
      state.mobileFiltersOn = !state.mobileFiltersOn;
    },
    setShowHidePerStreamPrompt: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) setLocalStorageItem('hidePerStreamShown', true);
      state.showHidePerStreamPrompt = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(searchCandidates.pending, (state, action) => {
      state.searchItems = action.meta.arg.searchItems;
      state.loading = true;
    });
    builder.addCase(getNextCandidateResults.pending, state => {
      state.loadingMore = true;
    });
    builder.addCase(getNextCandidateResults.fulfilled, (state, action) => {
      const {
        nextPage,
        searchResults,
        searchResultsCounts: { resultCount }
      } = state;
      const values = action.payload;

      const newResults = wrapAndModifySearchResults(values);
      const nextResults = values ? searchResults.concat(newResults) : newResults;

      state.searchResults = nextResults;
      state.loadingMore = false;
      state.nextPage = nextPage + 1;
      state.endPage = action.meta.shouldBeEndPage || nextResults.length >= resultCount;
    });
    builder.addCase(toggleCandidateVisibility.fulfilled, (state, action) => {
      const shouldShowHidePerStreamPrompt = !action.payload;

      if (shouldShowHidePerStreamPrompt) {
        state.pendingHideCandidateID = action.meta.arg.candidateID;
        state.showHidePerStreamPrompt = true;
        return;
      }

      const { status, newResults, newCount } = action.payload!;

      if (status === 'success') {
        state.searchResults = newResults;
        state.searchResultsCounts.resultCount = newCount;
        state.pendingHideCandidateID = undefined;
      }
    });
  }
});

export const {
  resetDiscoverState,
  updateSearchItems,
  updateCandidateSearchState,
  updateSearchResultsCount,
  updateCandidateVisibilityStatus,
  updateTotalActiveCount,
  toggleFiltersMobile,
  setShowHidePerStreamPrompt
} = discover.actions;
export default discover.reducer;
