import { Location } from 'history';

import { getQueryStringObject } from 'utils';

import { isMessagesPage } from './isPage';

const currentPageLocation = () => window.location;

export const getMessageThreadListingID = (
  location: Omit<Location, 'state'> = currentPageLocation()
) => {
  const queryParams = getQueryStringObject(location.search) || {};
  return queryParams.listing_id || location.pathname.match(/\d+/)?.[0] || ''; // support for old messaging
};

export const getMessageThreadUserID = (
  location: Omit<Location, 'state'> = currentPageLocation()
) => {
  const queryParams = getQueryStringObject(location.search) || {};
  return queryParams.candidate_id || location.pathname.match(/\d+\/\d+/)?.[0].split('/')[1] || ''; // support for old messaging
};

export const isMessageThreadPage = (
  location: Omit<Location, 'state'> = currentPageLocation(),
  listingID: number,
  candidateID?: number
) => {
  const currentListingID = parseInt(getMessageThreadListingID(location), 10);
  const currentCandidateID = candidateID
    ? parseInt(getMessageThreadUserID(location), 10)
    : undefined;

  return (
    isMessagesPage(location.pathname) &&
    currentListingID === listingID &&
    currentCandidateID === candidateID
  );
};
