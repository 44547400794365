import React from 'react';
import ClassNames from 'classnames';

import { useDarkMode } from 'hooks/useDarkMode';

import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import './style.scss';

export type BadgeProps = {
  className?: string;
  icon?: string;
  text: string;
  type?:
    | 'new_feature'
    | 'beta_feature'
    | 'info'
    | 'info_light'
    | 'table_tag_live'
    | 'table_tag_active'
    | 'table_tag_inactive'
    | 'warning'
    | 'ai_light'
    | 'ai_dark'
    | 'ai_plain'
    | 'pro'
    | 'pro_dark';
  tooltip?: string;
  size?: 'small' | 'medium' | 'large';
};

function Badge({ className, text, type = 'new_feature', tooltip, icon, size }: BadgeProps) {
  const darkMode = useDarkMode();
  return (
    <span className={ClassNames('badge', className, type, size, { dark: darkMode })}>
      <ConditionalRender predicate={icon}>
        <span className={icon} />
      </ConditionalRender>
      <span>{text}</span>
      {tooltip && <Tooltip text={tooltip} />}
    </span>
  );
}

export default Badge;
