import React, { ReactNode, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { convertSpacesToUnderscoreCase, isEmpty } from 'utils';
import { equalsPickByKey } from 'utils/fn';

import { FilterOption, FilterOptions } from 'components/expandablefilter/ExpandableFilter.helpers';
import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { BoldText } from 'v2/components/ui/styles/StyledText';

import OperatorToggle from '../OperatorToggle';

import FilterLabel from './FilterLabel';
import { OperatorToggleCont, Option, OptionCont, OptionsTitle } from './CheckboxOptions.styled';

type CheckboxOptionProps = FilterOption & { addCheckboxItem: (option: FilterOption) => void };

function CheckboxOption({ addCheckboxItem, ...option }: CheckboxOptionProps) {
  const { label, attribute, value, description } = option;
  const {
    operator,
    selectedItems,
    id,
    appearance,
    showSearchInput,
    clearSearchKeyword,
    removeSearchItem
  } = useExpandableFilter();

  const checked = useMemo(
    () => (selectedItems ? !!equalsPickByKey(selectedItems)('value')(value) : false),
    [selectedItems]
  );

  return (
    <Option
      checkboxID={`${id}_${convertSpacesToUnderscoreCase(label)}`}
      className="attribute_checkbox"
      label={
        <FilterLabel
          label={label}
          visible={checked}
          attribute={operator.attribute || attribute}
          description={description}
        />
      }
      type={checked ? 'bold' : 'normal'}
      appearance={appearance}
      checked={checked}
      onChange={checked => {
        if (showSearchInput) clearSearchKeyword();
        if (checked) addCheckboxItem(option);
        else {
          const selectedIndex = selectedItems?.findIndex(item => item.value === value) ?? -1;
          const selectedAttribute = selectedItems![selectedIndex].attribute;
          removeSearchItem!(selectedAttribute!, value);
        }
      }}
    />
  );
}

export type CheckboxOptionsProps = {
  isSelectionOnly?: boolean;
  isExpandable?: boolean;
  title?: ReactNode;
  items?: FilterOptions;
};

export default function CheckboxOptions({ title, items, ...props }: CheckboxOptionsProps) {
  const { isSelectionOnly } = props;
  const { pathname } = useLocation();
  const {
    isExpanded,
    operator,
    selectedItems,
    showOperatorToggle,
    visibleCheckboxesCount,
    showSearchInput,
    labelTitle,
    appearance,
    addSearchItem
  } = useExpandableFilter();

  const [isCandidateSearch] = useState(pathname.startsWith('/candidate/search'));

  const options = isSelectionOnly ? selectedItems : items;
  const shouldShowOperatorToggle = showOperatorToggle && isSelectionOnly && appearance;

  // Calculate the number of checkboxes to display below selected items
  const showSelection = showSearchInput;
  const numberOfSelectedItems = selectedItems?.length || 0;
  const showUpTo = visibleCheckboxesCount - (showSelection ? numberOfSelectedItems : 0);

  const addCheckboxItem = ({ label, value, attribute }: FilterOption) => {
    const stringLabel = isCandidateSearch ? label : `${labelTitle} ${label}`;

    addSearchItem!(operator.attribute || attribute!, stringLabel, value);
  };

  return (
    <ConditionalRender predicate={!isEmpty(options)}>
      <ConditionalRender predicate={title}>
        <OptionsTitle className="small_text">{title}</OptionsTitle>
      </ConditionalRender>
      <ConditionalRender predicate={shouldShowOperatorToggle}>
        <OperatorToggleCont>
          <BoldText>Include:</BoldText>
          <OperatorToggle />
        </OperatorToggleCont>
      </ConditionalRender>
      <OptionCont
        className="option_cont"
        showUpTo={showUpTo > 0 ? showUpTo : 1}
        isExpanded={isExpanded}
        appearance={appearance}
        {...props}
      >
        {options?.map((option, index) => {
          const { attribute, label, value } = option;
          const attr = operator.attribute || attribute;
          return (
            <CheckboxOption
              key={`${label}_${attr}_${value}_${index}`}
              addCheckboxItem={addCheckboxItem}
              {...option}
            />
          );
        })}
      </OptionCont>
    </ConditionalRender>
  );
}
