import { ApexOptions } from 'apexcharts';

import { CandidateInsightsResponse } from '@cohiretech/common-types';

import { colours, commonFns, typography } from 'styles/theme/common';

const CANDIDATE_DATA_COLOUR = colours.primaryColour;
const AVERAGE_DATA_COLOUR = commonFns.opaqueColour(CANDIDATE_DATA_COLOUR, 0.6);

export type ApexChartProps = {
  series: ApexOptions['series'];
  options: ApexOptions;
};

export const getMatchComparisonData = (insightsData: CandidateInsightsResponse) => {
  const { global, position, candidate } = insightsData;
  const { avg } = position?.acceptedConversations?.matchScoreSummary || {};
  const averageMatchScore = Number(avg || global?.acceptedCandidatesAverageMatchScore);

  return {
    candidate: Number(candidate?.matchScore?.matchScore),
    average: averageMatchScore,
    hasNoPositionData: !avg
  };
};

export const getComparison = (baseValue: number, comparisonValue: number) => {
  if (baseValue > comparisonValue) return 'higher than';
  if (baseValue < comparisonValue - 0.05) return 'below';
  return 'similarly to';
};

const getMatchComparisonChartOptionsFromData = (xValues: number[]) => {
  const data: ApexAxisChartSeries[number]['data'] = xValues.map(x => ({ x, y: 1 }));
  const xAxisAnnotations: XAxisAnnotations[] = xValues.map((x, index) => {
    switch (index) {
      case 0:
        return {
          x,
          strokeDashArray: 0,
          borderColor: CANDIDATE_DATA_COLOUR,
          borderWidth: 2
        };
      default:
        return {
          x,
          strokeDashArray: 2,
          borderColor: AVERAGE_DATA_COLOUR,
          borderWidth: 2
        };
    }
  });
  const xAxisMinMax = [...xValues].sort().map((x, index) => {
    let num = Number(x);
    const isMultipleOf10 = (num * 100) % 10 === 0;

    switch (index) {
      case 0:
        if (isMultipleOf10) num -= 0.1;
        return Math.floor(Math.max(num, 0) * 10) / 10;
      default:
        if (isMultipleOf10) num += 0.1;
        return Math.ceil(Math.min(num, 1) * 10) / 10;
    }
  });

  return { data, xAxisAnnotations, xAxisMinMax };
};

const formatPercentage = (percentage: string, decimalsInFloat = 0) => {
  const numberValue = Number(percentage) * 100;
  const scaleFactor = 10 ** decimalsInFloat;
  const roundedValue = Math.round(numberValue * scaleFactor) / scaleFactor;

  return roundedValue.toString();
};

export const getMatchComparisonChartProps = (
  xValues: number[],
  isDummyData: boolean,
  darkMode: boolean
): ApexChartProps => {
  const {
    data,
    xAxisAnnotations,
    xAxisMinMax: [min, max]
  } = getMatchComparisonChartOptionsFromData(xValues);

  return {
    series: [{ data }],
    options: {
      theme: { mode: darkMode ? 'dark' : 'light' },
      annotations: { xaxis: xAxisAnnotations },
      chart: {
        fontFamily: `${typography.fontFamily}, sans-serif`,
        toolbar: { show: false },
        zoom: { enabled: false }
      },
      dataLabels: {
        enabled: true,
        formatter: (_, { seriesIndex, dataPointIndex, w }) =>
          isDummyData
            ? '00'
            : formatPercentage(w.config.series[seriesIndex].data[dataPointIndex].x),
        textAnchor: 'start',
        offsetX: 2,
        offsetY: 6,
        style: {
          colors: [
            ({ dataPointIndex }: any) =>
              dataPointIndex ? AVERAGE_DATA_COLOUR : CANDIDATE_DATA_COLOUR
          ]
        },
        background: { enabled: false }
      },
      grid: {
        borderColor: darkMode ? colours.fontColour : colours.lightFontColour,
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: false } },
        padding: { top: -20, right: 6, bottom: -10 }
      },
      stroke: { show: false },
      tooltip: { enabled: false },
      xaxis: {
        type: 'numeric',
        tickAmount: 3,
        min,
        max,
        labels: {
          formatter: value => (isDummyData ? '00' : formatPercentage(value, 1))
        },
        axisBorder: { show: false },
        axisTicks: { show: false }
      },
      yaxis: { show: false }
    }
  };
};
