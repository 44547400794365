import {
  TextEnhancerProvider,
  useTextEnhancer,
  TextEnhancerContextState
} from './TextEnhancer.context';
import CustomPrompt from './components/CustomPrompt';
import Menu from './components/Menu';
import Error from './components/Error';
import Controls from './components/Controls';

const TextEnhancer = {
  Provider: TextEnhancerProvider,
  useTextEnhancer,
  CustomPrompt,
  Menu,
  Error,
  Controls
};

export type TextEnhancerContextProps = TextEnhancerContextState;

export default TextEnhancer;
