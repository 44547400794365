import { OperatingCountry } from '@cohiretech/common-types';

import { getCompanyStoryLocationName } from 'v2/services/common';

import useLocalisation from './useLocalisation';

export default function useLocationName(preferredCountry?: OperatingCountry) {
  const localisationCountry = useLocalisation(preferredCountry);

  return getCompanyStoryLocationName(localisationCountry);
}
