import { History } from 'history';

import { CompanySearchCandidateStatusEnum, CompanySearchTotalDTO } from '@cohiretech/common-types';

import { CandidateSearchResult, SearchItem, SearchItems } from 'types';
import { prepareFiltersQuery } from 'utils';
import { candidatePoolOptions } from 'consts';

import { DiscoverState, SearchType } from './discover.types';

export const INITIAL_CANDIDATE_SEARCH_STATE = {
  searchItems: [] as SearchItems,
  loading: false,
  searchResultIds: [] as number[],
  searchResults: [] as CandidateSearchResult[],
  searchResultsCounts: {
    resultCount: 0,
    activeCount: 0,
    passiveCount: 0,
    totalCount: 0
  } as CompanySearchTotalDTO,
  totalActiveCount: 0,
  newOrReactivatedCandidateCount: 0,
  nextPage: 0,
  loadingMore: false,
  endPage: false,
  uniqueIdentifier: '',
  errorMessage: '',
  mobileFiltersOn: false,
  passiveSearch: true
};

export const PAGE_SIZE = 10;

export const getInitialDiscoverState = (
  searchType = window.location.pathname.match(/\/company\/discover\/([a-z]+)/)?.[1] as SearchType
): DiscoverState => ({ ...INITIAL_CANDIDATE_SEARCH_STATE, searchType });

export const getCandidatePoolSearchItem = (status: CompanySearchCandidateStatusEnum) => {
  return candidatePoolOptions.find(option => option.value === status) as SearchItem;
};
export const getCandidatePoolSearchItems = (status: CompanySearchCandidateStatusEnum[]) => {
  return status.map(value =>
    candidatePoolOptions.find(option => option.value === value)
  ) as SearchItems;
};

export const updateQueryParams = (history: History, filters: SearchItems) => {
  const parsedFilters = prepareFiltersQuery(filters);

  history.push({ search: `filters=${parsedFilters}` });
};
