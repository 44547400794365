import { isMobileWidth } from 'responsiveConfig';

export const CATEGORIES_MAP = [
  { title: 'Last active positions', queryStr: 'lastActive', url: 'last-active-jobs' },
  {
    title: 'Positions most suited to your skills',
    queryStr: 'mostRelevant',
    url: 'relevant-positions'
  },
  {
    title: 'New positions suited to your skills',
    queryStr: 'mostRelevantSinceLogin',
    url: 'new-relevant-positions'
  },
  { title: "Positions you've saved", queryStr: 'savedPositions', url: 'saved-positions' },
  {
    title: 'Positions searching for people like you',
    queryStr: 'relevantListing',
    url: 'positions-searching-you'
  },
  {
    title: 'Positions most suited to your interests',
    queryStr: 'skillPreferences',
    url: 'positions-you-may-like'
  },
  {
    title: `CTOs and Co-Founders hiring${isMobileWidth ? '' : ' people like you'}`,
    queryStr: 'relevantSeniorPeople',
    url: 'ctos-hiring-people-like-you'
  },
  { title: 'Most popular companies', queryStr: 'popularCompany', url: 'popular-companies' },
  { title: 'Most popular positions', queryStr: 'popularPosition', url: 'popular-jobs' },
  { title: 'Most popular people hiring on cord', queryStr: 'popularPeople' },
  { title: 'COVID-19 Responders', queryStr: 'covidResponder', url: 'covid-19-companies' },
  {
    title: 'People hiring you can talk to',
    queryStr: 'relevantPeople',
    url: 'relevant-people-hiring'
  },
  {
    title: 'Companies hiring people like you',
    queryStr: 'relevantCompany',
    url: 'relevant-companies'
  },
  { title: 'Inside the companies', queryStr: 'startupLife', url: 'startup-life' },
  { title: 'Positions closest to home', queryStr: 'nearestJobs', url: 'positions-near-you' },
  { title: 'Positions with {{SKILL}}', queryStr: 'tech-{{SKILL}}' },
  {
    title: '{{INDUSTRY}} companies hiring people like you',
    queryStr: 'industry-{{INDUSTRY}}-company'
  },
  {
    title: '{{INSIGHT_TYPE}} you may like',
    queryStr: 'insight-{{INSIGHT_TYPE}}-{{JOB_CATEGORY}}',
    url: '{{JOB_CATEGORY}}'
  },
  {
    title: 'HealthTech Companies',
    queryStr: 'industry-healthtech-company',
    url: 'healthtech-companies'
  },
  {
    title: 'Front End Developer positions',
    queryStr: 'jobTitle-frontend',
    url: 'front-end-developer-jobs'
  },
  {
    title: 'Back End Developer positions',
    queryStr: 'jobTitle-backend',
    url: 'back-end-developer-jobs'
  },
  {
    title: 'Full Stack Developer positions',
    queryStr: 'jobTitle-fullstack',
    url: 'full-stack-developer-jobs'
  },
  { title: 'Lead Developer positions', queryStr: 'jobTitle-lead', url: 'lead-developer-jobs' },
  { title: 'DevOps Engineer positions', queryStr: 'jobTitle-devops', url: 'devops-engineer-jobs' },
  { title: 'Data Scientist positions', queryStr: 'jobTitle-data', url: 'data-scientist-jobs' },
  { title: 'Mobile Developer positions', queryStr: 'jobTitle-mobile', url: 'mobile-developer-jobs' }
];

const { REACT_APP_US_DEMO_COMPANY_ID, REACT_APP_UK_DEMO_COMPANY_ID } = process.env;
export const DEMO_COMPANY_IDS = [REACT_APP_UK_DEMO_COMPANY_ID, REACT_APP_US_DEMO_COMPANY_ID];

export const US_LOCATION_SEARCH_FILTER = {
  attribute: 'location',
  label: 'United States',
  value: 'United States'
};

export const IMAGE_BUCKET_URL = 'https://assets.co-hire.com';
export const S3_IMAGE_BUCKET_URL = 'https://cord-image-bucket.s3.amazonaws.com';
export const SALES_DEMO_COMPANY_IDS = [1223, 15421, 30883];
export const ERROR_MESSAGE = 'Something went wrong. Try again.';
