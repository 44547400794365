import { StripeTaxType } from '@cohiretech/common-types';

export const STRIPE_TAX_TYPES: { label: string; value: StripeTaxType }[] = [
  {
    label: 'Australian Business Number (AU ABN)',
    value: 'au_abn'
  },
  {
    label: 'Brazilian CNPJ number',
    value: 'br_cnpj'
  },
  {
    label: 'Brazilian CPF number',
    value: 'br_cpf'
  },
  {
    label: 'Canadian BN',
    value: 'ca_bn'
  },
  {
    label: 'Canadian GST/HST number',
    value: 'ca_gst_hst'
  },
  {
    label: 'Canadian PST number (British Columbia)',
    value: 'ca_pst_bc'
  },
  {
    label: 'Canadian PST number (Manitoba)',
    value: 'ca_pst_mb'
  },
  {
    label: 'Canadian PST number (Saskatchewan)',
    value: 'ca_pst_sk'
  },
  {
    label: 'Canadian QST number (Québec)',
    value: 'ca_qst'
  },
  {
    label: 'Chilean TIN',
    value: 'cl_tin'
  },
  {
    label: 'European VAT number',
    value: 'eu_vat'
  },
  {
    label: 'Hong Kong BR number',
    value: 'hk_br'
  },
  {
    label: 'Israel VAT',
    value: 'il_vat'
  },
  {
    label: 'Indian GST number',
    value: 'in_gst'
  },
  {
    label: 'Indonesian NPWP number',
    value: 'id_npwp'
  },
  {
    label: 'Japanese Corporate Number (*Hōjin Bangō*)',
    value: 'jp_cn'
  },
  {
    label:
      'Japanese Registered Foreign Businesses Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)',
    value: 'jp_rn'
  },
  {
    label: 'Korean BRN',
    value: 'kr_brn'
  },
  {
    label: 'Liechtensteinian UID number',
    value: 'li_uid'
  },
  {
    label: 'Mexican RFC number',
    value: 'mx_rfc'
  },
  {
    label: 'Malaysian FRP number',
    value: 'my_frp'
  },
  {
    label: 'Malaysian ITN',
    value: 'my_itn'
  },
  {
    label: 'Malaysian SST number',
    value: 'my_sst'
  },
  {
    label: 'Norwegian VAT number',
    value: 'no_vat'
  },
  {
    label: 'New Zealand GST number',
    value: 'nz_gst'
  },
  {
    label: 'Russian INN',
    value: 'ru_inn'
  },
  {
    label: 'Russian KPP',
    value: 'ru_kpp'
  },
  {
    label: 'Saudi Arabia VAT',
    value: 'sa_vat'
  },
  {
    label: 'Singaporean GST',
    value: 'sg_gst'
  },
  {
    label: 'Singaporean UEN',
    value: 'sg_uen'
  },
  {
    label: 'Spanish CIF number',
    value: 'es_cif'
  },
  {
    label: 'Switzerland VAT number',
    value: 'ch_vat'
  },
  {
    label: 'Thai VAT',
    value: 'th_vat'
  },
  {
    label: 'Taiwanese VAT',
    value: 'tw_vat'
  },
  {
    label: 'United States EIN',
    value: 'us_ein'
  },
  {
    label: 'South African VAT number',
    value: 'za_vat'
  },
  {
    label: 'United Arab Emirates TRN',
    value: 'ae_trn'
  },
  {
    label: 'United Kingdom VAT number',
    value: 'gb_vat'
  },
  {
    label: 'Northern Ireland VAT number',
    value: 'eu_vat'
  }
];
