/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios';
import { retrieveIndexedDBKeys, storeIndexedDBData } from 'indexedDB';

import {
  SkillByCategory,
  SkillLowerCaseToPretty,
  SkillRetrievalKey,
  SkillWithCategory,
  TrunkAndBranchDetailedInterface
} from '@cohiretech/common-types';

import { APIResponse, IndexDBTable } from 'types';
import { addTime, isEmpty } from 'utils';
import { MaxRetriesExceededError, retry } from 'utils/fn/retry';
import { hasDatePassed } from 'utils/time';

const LIST_OF_SKILLS = process.env.REACT_APP_LIST_OF_SKILLS;

export type Skills<C extends SkillRetrievalKey> = C extends 'DetailedTrunkAndBrunch'
  ? TrunkAndBranchDetailedInterface[]
  : C extends 'LowerToPretty'
  ? SkillLowerCaseToPretty
  : C extends 'SkillToCategory'
  ? SkillWithCategory
  : C extends 'CategoryToSkill'
  ? SkillByCategory
  : string[];
type SkillsResponse<C extends SkillRetrievalKey> = APIResponse<Skills<C>>;
type SkillsResponseWithExpiryDate<C extends SkillRetrievalKey> = [SkillsResponse<C>, number];

const KEYS = ['dataKey', 'expiryDate'] as const;

export const getSkillOptions = async <C extends SkillRetrievalKey = 'All'>(
  params: {
    abortSignal?: AbortSignal;
    category?: C;
  } = {}
): Promise<SkillsResponse<C>> => {
  const { abortSignal, category = 'All' } = params;
  const table: IndexDBTable = category ? `skills${category}` : 'skills';

  // Check if skills exist in indexedDB and verify if the data is fresh and valid
  const stored = (await retrieveIndexedDBKeys(table, KEYS)) as SkillsResponseWithExpiryDate<C>;
  if (stored) {
    const [response] = stored;
    if (isStoredAndFresh(stored)) return response;
  }

  // If skills don't exist in indexedDB or if the data is stale, fetch from API
  try {
    const getListOfSkills = async () =>
      axios.get<SkillsResponse<C>>(LIST_OF_SKILLS!, { params: { category } });
    // Retry 5 times with a delay of 500ms between each attempt, if failed or if the data is invalid
    const { data } = await retry(getListOfSkills, validator, {
      stopOnError: false,
      signal: abortSignal
    });

    // Data should be valid at this point, so store it in indexedDB
    const newExpiryDate = addTime(new Date(), 1, 'days');
    storeIndexedDBData(table, data);
    storeIndexedDBData(table, newExpiryDate, 'expiryDate');

    return data;
  } catch (error: MaxRetriesExceededError | any) {
    console.error(error);
    return { status: 'failure', message: error?.message || 'Something went wrong' };
  }
};

const validator = <C extends SkillRetrievalKey>(response: AxiosResponse<SkillsResponse<C>>) => {
  if (response.data?.status === 'failure') return false;
  return !isEmpty(response?.data?.data);
};

const isStoredAndFresh = <C extends SkillRetrievalKey>(stored: SkillsResponseWithExpiryDate<C>) => {
  const [response, expiryDate] = stored;
  return !(isEmpty(response?.data) || hasDatePassed(expiryDate));
};
