import styled from 'styled-components';

import { TooltipWrapper } from 'components/tooltip';

export const RecommendedBadgeWrapper = styled(TooltipWrapper)`
  position: absolute;
  right: 12px;
  bottom: -10px;

  .tooltip_wrapper {
    left: 60%;
  }
`;
