import axios from 'axios';

const COMPANY_TRACKING_ACTIVATE = process.env.REACT_APP_COMPANY_TRACKING_ACTIVATE;

export const trackExternalCompanyActivate = async () => {
  try {
    await axios.post(`${COMPANY_TRACKING_ACTIVATE}`);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
