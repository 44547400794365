import { Frequency } from '@cohiretech/common-types';

import { CurrencySymbol, NewPricingOptions, PricingCountry } from 'types';
import { trackBookDemoEvents } from 'tracking-utils';
import { isUnlimitedPlan, setBookDemoTracking } from 'utils/companyUtils';
import { getLocalStorageItem } from 'cookieManager';

export const trackBooking = (
  e: Event,
  plan: NewPricingOptions,
  { from = '', period }: { from: string; period?: Frequency },
  pricingCountry: PricingCountry,
  isCompanyLoggedIn: boolean
) => {
  const { data, origin } = e as MessageEvent<any>;

  if (!origin.includes('hubspot.com')) return;

  const label = `${isUnlimitedPlan(plan) ? 'Unlimited' : plan} |${
    period ? ` ${period} |` : ''
  } ${pricingCountry}`;

  if (data.meetingBookSucceeded) {
    if (isCompanyLoggedIn) setBookDemoTracking('book', true);
    else {
      trackBookDemoEvents(label, 'book', {
        plan,
        period,
        pricingCountry,
        from
      });
    }
  }
};

export const isReferredByCompany = (referrer: string) => {
  return referrer === 'referral' || getLocalStorageItem('referrerDetails')?.referrer === 'referral';
};

const getReferredContent = (currency: CurrencySymbol) => {
  return {
    title: "You've been invited to use cord",
    subtitle: `Book a 1-1 demo with a cord expert and you'll get ${currency}100 off your first month on cord.`
  };
};

export const getHeading = (
  isTrial: boolean,
  referredByCompany: boolean,
  price: string,
  isBookACall: boolean,
  currency: CurrencySymbol
) => {
  if (isTrial) {
    return {
      cta: 'BOOK A DEMO TO TRIAL CORD',
      title: 'Unlimited sourcing in your first month',
      subtitle: `Experience the full value of cord for ${price} with unlimited sourcing credits in the first month.`
    };
  }

  if (referredByCompany) {
    return {
      cta: 'BOOK A DEMO',
      ...getReferredContent(currency)
    };
  }

  if (isBookACall) {
    return {
      cta: 'Speak with an expert',
      title: 'Find out how cord can supercharge your hiring'
    };
  }

  return {
    cta: 'Book a personalised 1:1 demo',
    title: 'See how cord helps you make direct hires'
  };
};

export const getSEOContent = (
  referredByCompany: boolean,
  isBookACall: boolean,
  currency: CurrencySymbol
) => {
  if (referredByCompany) {
    return getReferredContent(currency);
  }

  if (isBookACall) {
    return {
      title: 'Book a call with an expert',
      description: 'Find out how cord can supercharge your hiring'
    };
  }

  return {
    title: 'Book a demo - Hire the best people',
    description:
      'Join over 700 technology companies already on cord and start conversations with great people looking for work in the UK, USA and remote across Europe.'
  };
};

export const REQUEST_DEMO_FEATURES = [
  {
    title: 'Only verified candidates:',
    description: `Access thousands of quality, active candidates you won't find elsewhere`
  },
  {
    title: 'Engage highly responsive candidates:',
    description: `All candidates are active, making messaging 5x more effective than LinkedIn`
  },
  {
    title: 'Save 10x on your recruitment costs:',
    description: `Simple & affordable pricing enables hires at a fraction of the typical cost`
  }
];

export const BOOK_A_CALL_FEATURES = [
  {
    title: 'Explore the product:',
    description:
      'See the 18,000+ quality, active candidates that use cord to find work. Explore features and integrations.'
  },
  {
    title: 'Understand the pricing:',
    description: 'Get a personalised quote based on your company’s hiring volume.'
  },
  {
    title: 'Get your questions answered:',
    description: 'Speak with an expert about your requirements and get the answers you need.'
  }
];

export const FEATURE_TITLES = [
  'Access every feature & integration',
  'List up to 10 active positions',
  'Cancel anytime. No hiring fees.'
];
