import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { AnyFunction } from 'types';

import Tooltip, { Props as TooltipProps } from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import typography from 'css/base/_typography.module.scss';
import colours from 'css/base/_colours.module.scss';

type RadialBarChartProps = {
  series: ApexOptions['series'];
  height?: number;
  width?: number;
  hollowSize?: string;
  fillType?: 'solid' | 'gradient';
  fillColour?: string;
  trackColour?: string;
  valueColour?: string;
  labelColour?: string;
  gradientColours?: string[];
  labels: string[];
  showLabel?: boolean;
  prefix?: string;
  suffix?: string;
  valueFontSize?: string;
  valueOffsetY?: number;
  labelOffsetY?: number;
  tooltip?: TooltipProps;
  noPadding?: boolean;
  onClick?: AnyFunction;
};

export default function RadialBarChart({
  series,
  width,
  height,
  hollowSize,
  fillType,
  gradientColours,
  fillColour,
  trackColour,
  labels,
  showLabel = true,
  prefix = '',
  suffix = '',
  valueFontSize,
  valueOffsetY,
  valueColour,
  labelColour,
  labelOffsetY,
  tooltip,
  noPadding,
  onClick
}: RadialBarChartProps) {
  const [chartOptions] = useState<ApexOptions>({
    chart: {
      height: height || 200,
      fontFamily: `${typography.fontFamily}, Helvetica, Arial, sans-serif`,
      type: 'radialBar',
      sparkline: {
        enabled: noPadding
      }
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: hollowSize || '80%',
          margin: 0
        },
        track: {
          background: trackColour || '#8f97f144'
        },
        dataLabels: {
          name: {
            show: showLabel,
            color: labelColour || colours.primaryColour,
            fontWeight: '400',
            fontSize: '12px',
            offsetY: labelOffsetY || undefined
          },
          value: {
            show: true,
            color: valueColour || colours.primaryColour,
            fontSize: valueFontSize || '18px',
            fontWeight: '700',
            formatter: val => {
              return `${prefix}${val}${suffix}`;
            },
            offsetY: valueOffsetY || undefined
          }
        }
      }
    },
    fill: {
      type: fillType || 'solid',
      colors: fillColour ? [fillColour] : undefined,
      gradient:
        fillType === 'gradient'
          ? {
              shade: 'dark',
              type: 'horisontal',
              shadeIntensity: 0.5,
              gradientToColors: gradientColours || [
                colours.primaryColourShadow,
                colours.primaryColour
              ],
              stops: [0, 100]
            }
          : undefined
    },
    stroke: {
      lineCap: 'round'
    },
    legend: {
      show: false
    },
    labels
  });
  return (
    <RadialChart
      className="radial_chart"
      height={height || 200}
      width={width || 160}
      onClick={onClick}
      tooltip={tooltip}
    >
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="radialBar"
        width={width}
        height={height}
      />
      <ConditionalRender predicate={tooltip}>
        <Tooltip {...tooltip} />
      </ConditionalRender>
    </RadialChart>
  );
}

const RadialChart = styled.div<
  Pick<RadialBarChartProps, 'height' | 'width' | 'onClick' | 'tooltip'>
>`
  position: relative;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};

  ${({ tooltip }) =>
    tooltip &&
    css`
      &:hover {
        cursor: pointer;

        .tooltip_wrapper {
          display: block;
        }
      }
    `};
`;
