import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { OperatingCountry } from '@cohiretech/common-types';

import { PricingCountry, SubscriptionPlan } from 'types';
import { ProfileState } from 'store';
import { getTotalNumberOfCandidates } from 'fetcher';
import { getQueryStringObject, scrollToSection, goToBookDemoLink, commify } from 'utils';
import { trackBookDemoEvents } from 'tracking-utils';
import {
  getForCompaniesPageLocalisation,
  getPricingComponent,
  getSubscriptionInfo
} from 'utils/companyUtils';
import { isUSByLocalStorage } from 'utils/localisationUtils';
import { checkIfInactivePlan } from 'v2/services/company/pricing';
import { useDarkMode } from 'hooks/useDarkMode';

import DialogWindow from 'components/dialogwindow';
import Seo from 'components/seo';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import DemoButtonSection from 'v2/components/DemoButtonSection';

import DemoExpired from 'images/demo-expired.svg';

import PipelineHero from './pipelinehero';
import SourcingStories from './sourcingstories';
import SourcingPipeline from './sourcingpipeline';
import TechAndProductPositions from './positions';
import Testimonials from './testimonials';
import './style.scss';

type Props = Pick<RouteComponentProps, 'history' | 'location'> & {
  profile: ProfileState;
  demoCompany: boolean;
};

function ForCompaniesPage({ history, location, profile, demoCompany }: Props) {
  const darkMode = useDarkMode();
  const [pricingTitle, setPricingTitle] = useState('');
  const [showBookDemoPopup, setShowBookDemoPopup] = useState(false);
  const [noOfActiveUsers, setNoOfActiveUsers] = useState('');
  const PricingComponent = useRef<any>(null);
  const Pricing = PricingComponent.current;
  const pricingCountry = getForCompaniesPageLocalisation(location.pathname) as PricingCountry;

  const { plan, billingFrequency, mode } =
    // @ts-expect-error: Todo: Fix this
    getSubscriptionInfo(profile?.subscriptionDetailsAndPricing)?.currentPlanDetails || {};
  const signupDate = profile?.signupDate;
  const { isUnpaidAccount } = checkIfInactivePlan(profile, demoCompany) || {};

  useEffect(() => {
    const { component, title } = getPricingComponent({ plan });
    PricingComponent.current = component;
    setPricingTitle(title);
  }, [plan]);

  useEffect(() => {
    // Changed this to also match /<country>/companies/invite
    if (location.pathname.match(/\/companies($|\/$|\/invite)/)) {
      setAdConversion();
    }

    checkQueryForExpiredInvite();
    scrollToSection(location.hash);
  }, []);

  useEffect(() => {
    async function getNoOfActiveUsers() {
      const country = isUSByLocalStorage() ? OperatingCountry.US : undefined;

      const activeCandidates = await getTotalNumberOfCandidates('active_count', country);
      setNoOfActiveUsers(commify(activeCandidates) || '');
    }

    getNoOfActiveUsers();
  }, []);

  const checkQueryForExpiredInvite = useCallback(() => {
    const urlParams = getQueryStringObject(location?.search);

    if (urlParams?.utm_source === 'expiredinvite') {
      setShowBookDemoPopup(true);
      history.push({ pathname: location.pathname, search: '' });
    }
  }, []);

  return (
    <>
      <PipelineHero noOfActiveUsers={noOfActiveUsers} history={history} />
      <SourcingStories history={history} location={location} darkMode={darkMode} />
      <SourcingPipeline noOfActiveUsers={noOfActiveUsers} />
      <TechAndProductPositions />
      <Testimonials location={location} darkMode={darkMode} />
      <ConditionalRender predicate={!!Pricing}>
        <Pricing
          className="page_section"
          title={pricingTitle}
          location={location}
          smallBottomCaption
          darkMode={darkMode}
          demoCompany={demoCompany}
          showVideoSection
          pricingCountry={pricingCountry}
          tabsInURL={false}
          currentPlanDetails={{
            plan: plan as SubscriptionPlan,
            billingFrequency,
            mode,
            signupDate,
            isUnpaidAccount
          }}
        />
      </ConditionalRender>
      <DemoButtonSection type="company_logos" color="dark" />
      <DialogWindow
        visible={showBookDemoPopup}
        id="invite_expired_popup"
        topIcon={{ src: DemoExpired, alt: 'Demo link has expired', height: 120 }}
        title="Personalised demo expired"
        subTitle="The link to your personalised demo has expired. Reply to the email for another link, or book a product tour for full access."
        primaryButton={{
          text: 'Book 1-1 Demo',
          action: () => {
            trackBookDemoEvents('Demo Expired Popup', 'click');
            goToBookDemoLink({ demoCompany });
          }
        }}
        showCloseIcon
        onClose={() => setShowBookDemoPopup(false)}
      />
      <Seo
        title="Make direct hires"
        description="cord enables Founders and Hiring teams to make direct hires by sourcing and messaging active, pre-verified people who are looking for permanent work in the UK, USA and remote across Europe."
        path="/companies"
        contentType="website"
        script={[{ src: 'https://www.googleoptimize.com/optimize.js?id=OPT-PJSHB2Z' }]}
        image="https://assets.co-hire.com/make-direct-hires.png"
      />
    </>
  );
}

const setAdConversion = () => {
  // Google tag snippet
  window.gtag('event', 'conversion', {
    send_to: 'AW-649514007/7gyoCKPG3-8BEJeY27UC'
  });

  // Facebook tracking snippet
  (window as $TSFixMe).fbq('track', 'Lead');
};

export default ForCompaniesPage;
