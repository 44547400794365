import { batch } from 'react-redux';
import { getPermissions } from 'permissions';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLocalStorageItem, removeLocalStorageItem } from 'cookieManager';
import {
  MESSAGE_VIEW,
  getCompanyNotifications,
  getCompanyPlanUsage,
  getCompanyProfile,
  getCompanyStreams,
  getCompanyUser,
  getShortlistData,
  logout,
  updateAssociatedBulkPositions
} from 'fetcher';
import { DEMO_COMPANY_IDS } from 'consts';
import {
  AppDispatch,
  RootState,
  isCompanyProfile,
  setAssociatedSelfTotalNotifications,
  setCompanyNotifications,
  setCompanyShortlists,
  setCompanyStreams,
  setCompanyUser,
  setDemoCompany,
  setInactivePlan,
  setInitialSetupMode,
  setIsUnpaidAccount,
  setPermissions,
  setPlanUsage,
  setPositions,
  setProfile,
  setSetupMode,
  setToggler,
  setTotalNotifications,
  setUserType,
  useAppDispatch
} from 'store';
import { identifyCompanyAccount } from 'tracking-utils';
import { CompanyProfile, PositionInProfile } from 'types';
import { getSelectedCompanyNotificationsCount } from 'utils';
import { pluck } from 'utils/object';
import { setUser } from 'v2/services/app';
import { checkIfInactivePlan } from 'v2/services/company/pricing';

import { checkPWAOpened, getAssociatedSelfTotalNotifications } from '..';

async function setDefaultPositionAssociation(positions: PositionInProfile[], accountID: number) {
  const positionIDs = positions.map(pluck('id'));
  const { status } = await updateAssociatedBulkPositions('remove', positionIDs);

  if (status !== 'success') return positions;

  const newPositions = positions.map(({ members, ...rest }) => {
    return { members: members.filter(id => id !== accountID), ...rest };
  });

  return newPositions;
}

export const fetchCompanyData = createAsyncThunk<
  { profile: CompanyProfile | null },
  boolean | undefined,
  { dispatch: AppDispatch; state: RootState }
>('services/company/fetchdata', async (initialLoad, { dispatch, getState }) => {
  dispatch(setToggler({ route: 'loaders.fetchCompanyData', value: true }));

  const state = getState();
  const { setupMode, initialSetupMode } = state.ui;
  const setupStep = getLocalStorageItem<number>('setup_step') || undefined;
  const [profile = {}, companyUser = {}, shortlists, companyStreams, planUsage] = await Promise.all(
    [
      getCompanyProfile(),
      getCompanyUser(),
      getShortlistData(),
      getCompanyStreams(),
      getCompanyPlanUsage()
    ]
  );

  if (!isCompanyProfile(profile)) await logout();

  const subscriptionPlan = profile.subscriptionDetailsAndPricing.subscriptionPlan?.plan;
  const permissions = getPermissions(companyUser.companyRole, subscriptionPlan);
  dispatch(setPermissions(permissions));

  const isGodmode = !!getLocalStorageItem('isGodmode');

  const demoCompany = DEMO_COMPANY_IDS.includes(`${profile.companyID}`) && !isGodmode;
  const messageFilter = demoCompany
    ? MESSAGE_VIEW.TEAM
    : companyUser.messageFilter || MESSAGE_VIEW.USER;

  const { data: notifications } = await getCompanyNotifications(messageFilter);
  const totalNotifications = getSelectedCompanyNotificationsCount(notifications);

  const associatedSelfTotalNotifications = await getAssociatedSelfTotalNotifications(
    notifications,
    messageFilter
  );

  const { inactivePlan, isUnpaidAccount = false } = checkIfInactivePlan(profile, demoCompany) || {};

  const isSetupFirstStep = setupMode && setupStep === 1 && initialLoad;
  if (isSetupFirstStep) {
    profile.positions = await setDefaultPositionAssociation(
      profile.positions,
      companyUser.accountID
    );
  }

  if (companyUser) {
    const { companyID, companyName } = profile;

    if (initialLoad) {
      identifyCompanyAccount({ companyID, companyName, companyUser });
      setUser({
        name: companyUser.memberName,
        email: companyUser.email,
        id: companyUser.accountID
      });
    }

    if (companyUser.setupDone) removeLocalStorageItem('setup_mode');
  }

  dispatch(checkPWAOpened(profile));

  batch(() => {
    dispatch(setProfile(profile));

    dispatch(setCompanyUser(companyUser));
    dispatch(setCompanyShortlists(shortlists));
    dispatch(setCompanyStreams(companyStreams));

    dispatch(setCompanyNotifications(notifications));
    dispatch(setTotalNotifications(totalNotifications));
    dispatch(setAssociatedSelfTotalNotifications(associatedSelfTotalNotifications));

    if (isSetupFirstStep) setPositions(profile.positions);

    dispatch(setPlanUsage(planUsage));
    dispatch(setInactivePlan(inactivePlan));
    dispatch(setIsUnpaidAccount(isUnpaidAccount));

    dispatch(setDemoCompany(demoCompany));
    dispatch(setUserType('company'));

    dispatch(setSetupMode(setupMode));
    dispatch(setInitialSetupMode(initialSetupMode || !!getLocalStorageItem('initial_setup_mode')));
  });

  dispatch(setToggler({ route: 'loaders.fetchCompanyData', value: false }));
  return { profile };
});

export const useCompanyData = () => {
  const dispatch = useAppDispatch();
  return async (initialLoad?: boolean) => {
    await dispatch(fetchCompanyData(initialLoad));
  };
};
