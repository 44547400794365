import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { getCandidateATItemByID } from 'v2/services/fetchers/candidate/applicationTrackerPosition';
import { useCandidateProfile } from 'store';
import { isSuccess } from 'v2/services/fetchers/apiTools';
import { useDarkMode } from 'hooks/useDarkMode';

import PositionProfileTopNav from 'views/positionprofile/PositionProfileTopNav';

import PositionProfile from 'components/positionprofile';
import Loader from 'components/loader';
import { PositionProfileProps } from 'components/positionprofile/PositionProfile.helpers';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { CandidateATCreatedPosition } from 'v2/components/CandidateATPositionForm';

import { cleanCandidateATPosition } from './ApplicationTrackerPosition.helpers';
import EditApplicationTrackerPositionPopup from './EditApplicationTrackerPositionPopup';

import 'views/positionprofile/style.scss';

type Params = { company: string; id: string; position: string };

export default function ApplicationTrackerPosition() {
  const history = useHistory();
  const params = useParams<Params>();
  const id = Number(params.id);

  const darkMode = useDarkMode();
  const profile = useCandidateProfile();

  const [extendedPosition, setExtendedPosition] =
    useState<ReturnType<typeof cleanCandidateATPosition>>();
  const [showEditPositionPopup, setShowEditPositionPopup] = useState(false);

  const { lastUpdatedStageDate, ...position } = extendedPosition || {};

  const fetchCandidateATPosition = async () => {
    const response = await getCandidateATItemByID(id);

    if (isSuccess(response)) setExtendedPosition(cleanCandidateATPosition(response.data));
    else closeProfile();
  };

  useEffect(() => {
    fetchCandidateATPosition();
  }, []);

  const closeProfile = () => {
    const [pathname] = history.location.pathname.split('/u/c/');

    history.push(pathname);
  };

  const handleClickView: MouseEventHandler = e => {
    if ((e.target as HTMLDivElement).id === 'position_view') closeProfile();
  };

  const { title = '', locationLabel, ...data } = extendedPosition || {};
  const positionProfileProps = {
    profile,
    loading: !extendedPosition,
    position: title,
    positionLocation: locationLabel,
    showEditCandidateATPositionPopup: () => setShowEditPositionPopup(true),
    ...data
  } as PositionProfileProps;

  return (
    <CandidateATPositionView
      id="position_view"
      className={ClassNames('candidate_logged_in', { dark: darkMode })}
      onClick={handleClickView}
    >
      <ConditionalRender
        predicate={position}
        fallback={<Loader className="position_loader large" />}
      >
        <div className="position_dialog profile_view">
          <PositionProfileTopNav
            loading={!extendedPosition}
            companyName={extendedPosition?.companyName || ''}
            position={title}
            profileHistoryLength={1}
            closeProfile={closeProfile}
          />
          <div className="position_content">
            <PositionProfile {...positionProfileProps} />
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender predicate={showEditPositionPopup}>
        <EditApplicationTrackerPositionPopup
          position={position as CandidateATCreatedPosition}
          fetchCandidateATPosition={fetchCandidateATPosition}
          closeEditPositionPopup={() => setShowEditPositionPopup(false)}
        />
      </ConditionalRender>
    </CandidateATPositionView>
  );
}

const CandidateATPositionView = styled.div`
  .stats_wrapper {
    display: none;
  }
`;
