import { HydratedCandidateApplicationTrackerInterface as HydratedCandidateATInterface } from '@cohiretech/common-types';

import { CandidateATCreatedPosition } from 'v2/components/CandidateATPositionForm';

/**
 * Cleans the candidate AT listing data by removing disallowed properties
 * and converting null values to undefined, as the API does not permit them.
 */
export const cleanCandidateATPosition = (
  position: HydratedCandidateATInterface
): CandidateATCreatedPosition & Pick<HydratedCandidateATInterface, 'lastUpdatedStageDate'> => {
  const {
    id,
    candidateApplicationTrackerCreatedListingID,
    name,
    position: title,
    jobTitles,
    seniorities,
    primarySkills,
    secondarySkills,
    remote,
    label,
    remoteLocationCountries,
    remoteLocationContinents,
    salaryMin,
    salaryMax,
    currency,
    description,
    stage,
    externalURL,
    lastUpdatedStageDate
  } = position;

  return {
    id,
    candidateApplicationTrackerCreatedListingID,
    companyName: name,
    title,
    jobTitles,
    seniorities,
    primarySkills,
    secondarySkills,
    remote,
    locationLabel: label || undefined,
    remoteLocationCountries: remoteLocationCountries || undefined,
    remoteLocationContinents: remoteLocationContinents || undefined,
    salaryMin: salaryMin || undefined,
    salaryMax: salaryMax || undefined,
    currency: currency || undefined,
    description,
    stage: stage!,
    url: externalURL || undefined,
    lastUpdatedStageDate
  };
};
