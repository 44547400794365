import { Frequency, StripeRegion, CardDetails, PricingMode } from '@cohiretech/common-types';

import { PricingCountry, SubscriptionPlan } from 'types';
import {
  companyUpgradePlan,
  companyEnquireAnnualPlan,
  companyRequestPlanChange,
  companyReactivateAccount,
  getPaymentSessionDetails,
  getPaymentCards
} from 'fetcher';
import { handleUpgradeCompletion, createStripeModule } from 'utils/companyUtils';
import { setLocalStorageItem } from 'cookieManager';

type StripeSessionDetails = {
  sessionID: string;
  stripeRegion: StripeRegion;
  isReactivatingUSCompanyFromUKStripe: boolean;
};

export type PaymentData = Pick<StripeSessionDetails, 'sessionID' | 'stripeRegion'> & {
  cards: CardDetails[];
};

export type Result = {
  status: string;
  errorMsg?: string;
  paymentData?: PaymentData;
};

export const handleUpgrade = async (
  plan: SubscriptionPlan,
  urlPath: string,
  mode?: PricingMode
): Promise<Result> => {
  const { status } = await companyUpgradePlan(urlPath, mode);

  if (status === 'success') {
    handleUpgradeCompletion(plan, window.location.pathname);
    return { status };
  }

  return { status: 'failure' };
};

export const handlePlanEnquiry = async (
  urlPath: string,
  planPeriod: Frequency,
  action: 'annual' | 'downgrade',
  isSubscriptionExpired: boolean,
  mode?: PricingMode
): Promise<Result> => {
  switch (action) {
    case 'annual':
      return isSubscriptionExpired
        ? companyReactivateAccount(urlPath, mode)
        : companyEnquireAnnualPlan();
    case 'downgrade':
      return companyRequestPlanChange({ urlPath, frequency: planPeriod, action });
    default:
      return { status: 'failure' };
  }
};

export const handleReactivation = async (
  plan: SubscriptionPlan,
  urlPath: string,
  pricingType: PricingCountry
): Promise<Result> => {
  const [sessionIDData, cardsData] = await Promise.all([
    getPaymentSessionDetails(urlPath, pricingType, 'company/discover', 'reactivation'),
    getPaymentCards()
  ]);

  const { sessionID, stripeRegion, isReactivatingUSCompanyFromUKStripe } =
    sessionIDData as StripeSessionDetails;

  if (!sessionID || !stripeRegion || cardsData.status === 'failure') {
    return {
      status: 'failure',
      errorMsg: !sessionID || !stripeRegion ? sessionIDData?.message : cardsData.message
    };
  }

  setLocalStorageItem('reactivationPlan', plan);

  if (!cardsData.length || isReactivatingUSCompanyFromUKStripe) {
    return proceedToPayment(sessionID, stripeRegion);
  } else {
    return {
      status: 'success',
      paymentData: { cards: cardsData as CardDetails[], sessionID, stripeRegion }
    };
  }
};

export const proceedToPayment = async (
  sessionId: string,
  stripeRegion: StripeRegion
): Promise<Result> => {
  const stripe = createStripeModule(stripeRegion);
  const result = await stripe.redirectToCheckout({ sessionId });

  return { status: result.error ? 'failure' : 'success', errorMsg: result.error.message };
};
