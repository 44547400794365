import React, { ReactNode, PropsWithChildren } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { CSSValueUnit } from 'types';
import { useDarkMode } from 'hooks/useDarkMode';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type TooltipPosition = 'top' | 'bottom' | 'left' | 'right';

export type Props = {
  tooltipStyle?: 'dark' | 'light' | 'cord_gradient' | 'grey' | 'light_primary';
  text?: string | (ReactNode | string)[];
  position?: TooltipPosition;
  width?: CSSValueUnit;
  fixed?: boolean;
  bgColor?: string;
  onClose?: (e: React.MouseEvent) => void;
  size?: string;
  className?: string;
  keyboardShortcut?: string;
};

function Tooltip({
  tooltipStyle = 'dark',
  text,
  position = 'top',
  width = '220px',
  fixed = false,
  children,
  bgColor,
  onClose,
  size,
  className,
  keyboardShortcut
}: PropsWithChildren<Props>) {
  const applyNewStyling = ['cord_gradient', 'grey', 'light_primary'].includes(tooltipStyle);
  const darkMode = applyNewStyling ? false : useDarkMode();

  return (
    <TooltipInternalWrapper
      className={ClassNames('tooltip_wrapper', className)}
      position={position}
      width={width}
      fixed={fixed}
      dark={darkMode}
      size={size}
    >
      <TooltipBox
        className="tooltip"
        tooltipStyle={tooltipStyle}
        bgColor={bgColor}
        position={position}
        fixed={fixed}
        dark={darkMode}
        size={size}
      >
        {onClose && <span data-testid="close" className="icon_close" onClick={e => onClose(e)} />}
        {text || children}
        <ConditionalRender predicate={keyboardShortcut}>
          <KeyboardShortcut tooltipStyle={tooltipStyle}>{keyboardShortcut}</KeyboardShortcut>
        </ConditionalRender>{' '}
      </TooltipBox>
    </TooltipInternalWrapper>
  );
}

export default Tooltip;

export const TooltipWrapper = styled.span`
  position: relative;
  :hover {
    .tooltip_wrapper {
      display: block;
    }
  }
`;

const TopTooltipStyles = css`
  bottom: 100%;
  left: 50%;
  padding-bottom: 15px;
  transform: translateX(-50%);
  text-align: center;
`;

const BottomTooltipStyles = css`
  top: 100%;
  left: 50%;
  padding-top: 15px;
  transform: translateX(-50%);
  text-align: center;
`;

const LeftTooltipStyles = css`
  bottom: 50%;
  right: 100%;
  padding-right: 15px;
  transform: translateY(50%);
  text-align: right;
`;

const RightTooltipStyles = css`
  bottom: 50%;
  left: 100%;
  padding-left: 15px;
  transform: translateY(50%);
  text-align: left;
`;

type StyledTooltipProps = Props & { dark: boolean };

const TooltipInternalWrapper = styled.div<StyledTooltipProps>`
  font-family: ${typography.fontFamily}, Helvetica, Arial, sans-serif;
  position: absolute;
  z-index: 102;
  width: ${props => props.width};
  display: none;
  line-height: 1.3;
  ${props => props.position === 'top' && TopTooltipStyles}
  ${props => props.position === 'bottom' && BottomTooltipStyles}
  ${props => props.position === 'left' && LeftTooltipStyles}
  ${props => props.position === 'right' && RightTooltipStyles}

  ${props =>
    props.fixed &&
    css`
      position: fixed;
      bottom: inherit;
      top: inherit;
      left: inherit;
      right: inherit;
      transform: translateX(-45%) translateY(0);
    `}
`;

const NegativePosition = (props: StyledTooltipProps) => {
  return props.size === 'small' ? '-4px' : '-6px';
};

const NegativeCalcPosition = (props: StyledTooltipProps) => {
  return props.size === 'small' ? 'calc(100% - 4px)' : 'calc(100% - 6px)';
};

const BackgroundStyling = css<StyledTooltipProps>`
  background: ${props =>
    props.bgColor
      ? props.bgColor
      : props.dark
      ? colours.lightFontColour
      : props.tooltipStyle === 'dark'
      ? colours.darkBgColour
      : props.tooltipStyle === 'cord_gradient'
      ? 'var(--gradient-primary-base)'
      : props.tooltipStyle === 'grey'
      ? 'var(--background-accent-tertiary-soft)'
      : props.tooltipStyle === 'light_primary'
      ? 'var(--gradient-primary-light)'
      : colours.lightBgColour};
`;

const TooltipBox = styled.div<StyledTooltipProps>`
  padding: ${props =>
    props.size === 'small'
      ? '6px 5px'
      : props.size === 'large'
      ? '8px 12px 10px'
      : '8px 10px 10px'};
  ${BackgroundStyling}

  color: ${props =>
    props.bgColor
      ? 'white'
      : props.dark
      ? colours.darkBgColour
      : props.tooltipStyle === 'dark'
      ? 'white'
      : props.tooltipStyle === 'cord_gradient'
      ? 'var(--text-body-invert)'
      : props.tooltipStyle === 'grey'
      ? 'var(--text-body-secondary)'
      : props.tooltipStyle === 'light_primary'
      ? 'var(--text-body-brand)'
      : colours.fontColour};
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  box-shadow: 0 0 15px -5px ${props => (props.dark ? colours.darkDropshadow : colours.dropshadow)};
  font-size: ${props =>
    props.size === 'small'
      ? 'var(--type-xs)'
      : props.size === 'large'
      ? 'var(--type-md)'
      : 'var(--type-sm)'};
  font-weight: normal;
  white-space: normal;
  text-transform: initial;
  letter-spacing: -0.05px;

  ${props =>
    props.bgColor &&
    css`
      .link {
        color: white;
        opacity: 0.9;

        &:hover {
          color: white;
          opacity: 1;
        }
      }
    `}

  > .icon_close {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 14px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  ul {
    list-style-type: disc;
    margin: 5px 0;
    padding-left: 25px;
  }

  &:after {
    position: absolute;
    content: '';
    width: ${props => (props.size === 'small' ? '8px' : '12px')};
    height: ${props => (props.size === 'small' ? '8px' : '12px')};
    ${BackgroundStyling}

    transform: ${props =>
      props.position === 'top' || props.position === 'bottom'
        ? 'translateX(-50%) rotate(45deg)'
        : 'translateY(50%) rotate(45deg)'};
    bottom: ${props =>
      props.position === 'top'
        ? NegativePosition(props)
        : props.position === 'bottom'
        ? NegativeCalcPosition(props)
        : '50%'};
    left: ${props =>
      props.position === 'top' || props.position === 'bottom'
        ? '50%'
        : props.position === 'left'
        ? NegativeCalcPosition(props)
        : NegativePosition(props)};

    ${props =>
      props.fixed &&
      css`
        display: none;
      `}
  }
`;

const KeyboardShortcut = styled.div<Pick<Props, 'tooltipStyle'>>`
  vertical-align: text-bottom;
  box-sizing: border-box;
  margin-left: 0.5rem;
  display: inline-flex;
  width: var(--spacing-05);
  height: var(--spacing-05);
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.375rem;
  opacity: 0.4;
  font-size: ${({ theme }) => theme.typography.mobile};
  border: 1px solid
    ${({ tooltipStyle }) =>
      tooltipStyle === 'cord_gradient'
        ? 'var(--text-body-invert)'
        : tooltipStyle === 'grey'
        ? 'var(--border-accent-tertiary-base)'
        : tooltipStyle === 'light_primary'
        ? 'var(--text-accent-primary-base)'
        : 'var(--border-accent-tertiary-base)'};
`;
