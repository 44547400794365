import ClassNames from 'classnames';

import { getIsDarkMode_FIXME } from 'cookieManager';

import { PageSectionProps } from 'components/pagesection';

export type PageSectionType = 'make_direct_hires' | 'company_logos' | 'stream_of_people';

export const getPageSectionPropsByType = (
  type: PageSectionType,
  className?: string
): PageSectionProps => {
  switch (type) {
    case 'make_direct_hires':
      return {
        title: 'Start making direct hires',
        subTitle: 'Browse the thousands of active people using cord to find work right now',
        className: ClassNames(className, 'center_alignment blue_background'),
        widthClassName: 'page_view'
      };
    case 'company_logos':
      const darkMode = getIsDarkMode_FIXME();

      return {
        className: ClassNames(
          className,
          'center_alignment',
          'position_relative',
          'company_logos_background',
          { dark: darkMode }
        ),
        title: 'Join the tech companies building their teams on cord',
        titleClassName: 'min_view no_padding',
        subTitle:
          'Book a demo with a cord expert or create an account to start making direct hires',
        widthClassName: 'min_extended_view',
        backdropClassName: ClassNames('company_logos_backdrop', { dark: darkMode })
      };
    case 'stream_of_people':
      return {
        title: 'Take a look at the stream of people',
        className: ClassNames(className, 'center_alignment blue_background'),
        widthClassName: 'page_view'
      };
    default:
      return {};
  }
};
