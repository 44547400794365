import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CompanyAccountShortlistInterface,
  FeedInterface,
  ListingStatus,
  MessageFilter,
  NotificationResult,
  OperatingCountry,
  StripeRegion
} from '@cohiretech/common-types';

import {
  CandidateNotificationResult,
  CompanyOnboardingItem,
  CompanyUser,
  InactivePlan,
  Permissions,
  PositionInProfile,
  UsageStatistics,
  UserProfileType
} from 'types';
import { getLocalStorageItem } from 'cookieManager';

import { ProfileState } from './user.types';
import { isCandidateProfile, isCompanyProfile } from './user.helpers';

export interface UserState {
  demoCompany?: boolean;
  profile: ProfileState;
  permissions: Permissions;
  isGodmode?: boolean;
  isProfileUpdated: boolean;
  userType: UserProfileType;
  integrationRedirectURL: string;
  candidatePro: boolean;
  pricingRegion?: StripeRegion;
  localisationCountry?: OperatingCountry;
}

const initialState: UserState = {
  profile: null,
  permissions: {} as Permissions,
  integrationRedirectURL:
    getLocalStorageItem('integrationRedirectURL') || '/company/account/integrations',
  isGodmode: getLocalStorageItem<boolean>('isGodmode') || undefined,
  isProfileUpdated: false,
  userType: null,
  candidatePro: true
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDemoCompany: (state, action: PayloadAction<boolean>) => {
      state.demoCompany = action.payload;
    },
    setProfile: (state, action: PayloadAction<ProfileState>) => {
      state.profile = action.payload;
    },
    setPermissions: (state, action: PayloadAction<Permissions>) => {
      state.permissions = action.payload;
    },
    setIntegrationRedirectURL: (state, action: PayloadAction<string>) => {
      state.integrationRedirectURL = action.payload;
    },
    setIsGodmode: (state, action: PayloadAction<boolean>) => {
      state.isGodmode = action.payload;
    },
    setIsProfileUpdated: (state, action: PayloadAction<boolean>) => {
      state.isProfileUpdated = action.payload;
    },
    setShouldPromptStatus: (state, action: PayloadAction<boolean>) => {
      if (!isCandidateProfile(state.profile)) return;
      state.profile.shouldPromptReactivation = action.payload;
    },
    setMessageFilter: (state, action: PayloadAction<MessageFilter>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.companyUser.messageFilter = action.payload;
    },
    setPlanUsage: (state, action: PayloadAction<UsageStatistics>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.planUsage = action.payload;
    },
    setInactivePlan: (state, action: PayloadAction<InactivePlan | undefined>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.inactivePlan = action.payload;
    },
    setIsUnpaidAccount: (state, action: PayloadAction<boolean>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.isUnpaidAccount = action.payload;
    },
    setPreferences: (state, action: PayloadAction<Record<string, any>>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.companyUser.preferences = action.payload;
    },
    setCompanyUser: (state, action: PayloadAction<CompanyUser>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.companyUser = action.payload;
    },
    setCompanyShortlists: (state, action: PayloadAction<CompanyAccountShortlistInterface[]>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.shortlists = action.payload;
    },
    setCompanyStreams: (state, action: PayloadAction<FeedInterface[]>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.companyStreams = action.payload;
    },
    setOnboardingItemCompletion: (state, action: PayloadAction<CompanyOnboardingItem>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.companyUser = {
        ...state.profile.companyUser,
        onboardingChecklist: {
          ...state.profile.companyUser.onboardingChecklist,
          [action.payload]: true
        }
      };
    },
    setPositions: (state, action: PayloadAction<PositionInProfile[]>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.positions = action.payload;
    },
    setPositionStatus: (state, action: PayloadAction<{ positionID: number; resume: boolean }>) => {
      if (!isCompanyProfile(state.profile)) return;
      const { positionID, resume } = action.payload;
      const { positions } = state.profile;

      if (!positions) return;

      const position = positions.find(({ id }) => id === positionID)!;
      position.status = resume ? ListingStatus.Active : ListingStatus.Archived;
    },
    setPositionSearchVisibility: (
      state,
      action: PayloadAction<{ positionID: number; hidden: boolean }>
    ) => {
      if (!isCompanyProfile(state.profile)) return;
      const { positionID, hidden } = action.payload;
      const { positions } = state.profile;

      if (!positions) return;

      const position = positions.find(({ id }) => id === positionID)!;
      position.searchHidden = hidden;
    },
    setCompanyResponseRate: (state, action: PayloadAction<{ responseRate: string }>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.responseRate = action.payload.responseRate;
    },
    setCompanyResponseTime: (state, action: PayloadAction<{ responseTime: any }>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.responseTime = action.payload.responseTime;
    },
    setUserResponseRate: (state, action: PayloadAction<{ responseRate: any }>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.companyUser.responseRate = action.payload.responseRate;
    },
    setUserResponseTime: (state, action: PayloadAction<{ responseTime: any }>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.companyUser.responseTime = action.payload.responseTime;
    },
    setUserType: (state, action: PayloadAction<UserState['userType']>) => {
      state.userType = action.payload;
    },
    setCandidateNotifications: (state, action: PayloadAction<CandidateNotificationResult>) => {
      if (!isCandidateProfile(state.profile)) return;
      state.profile.notifications = action.payload;
    },
    setCandidatePro: (state, action: PayloadAction<boolean | undefined>) => {
      state.candidatePro = action.payload ?? !state.candidatePro;
    },
    setCompanyNotifications: (state, action: PayloadAction<NotificationResult[]>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.notifications = action.payload;
    },
    setTotalNotifications: (state, action: PayloadAction<number>) => {
      if (state.profile === null) return;
      state.profile.totalNotifications = action.payload;
    },
    setAssociatedSelfTotalNotifications: (state, action: PayloadAction<number>) => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.associatedSelfTotalNotifications = action.payload;
    },
    setPricingRegion: (state, action: PayloadAction<StripeRegion>) => {
      state.pricingRegion = action.payload;
    },
    setLocalisationCountry: (state, action: PayloadAction<OperatingCountry | undefined>) => {
      state.localisationCountry = action.payload;
    },
    incrementHiringSeats: state => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.totalHiringSeat++;
    },
    decrementHiringSeats: state => {
      if (!isCompanyProfile(state.profile)) return;
      state.profile.totalHiringSeat--;
    },
    updateMemberPosition: (state, action: PayloadAction<number>) => {
      if (!isCompanyProfile(state.profile)) return;
      const { companyUser, positions } = state.profile;
      const userID = companyUser.accountID;

      const position = positions.find(p => p.id === action.payload);
      if (!position) return;

      const index = position.members.findIndex(id => id === userID);

      if (index !== -1) {
        position.members.splice(index, 1);
        if (userID === position.primaryMember) position.primaryMember = 0;
      } else position.members.push(userID!);
    }
  }
});

// Action creators
export const {
  decrementHiringSeats,
  incrementHiringSeats,
  setCandidatePro,
  setCandidateNotifications,
  setCompanyNotifications,
  setCompanyResponseRate,
  setCompanyResponseTime,
  setCompanyShortlists,
  setCompanyStreams,
  setCompanyUser,
  setDemoCompany,
  setInactivePlan,
  setIntegrationRedirectURL,
  setIsGodmode,
  setIsProfileUpdated,
  setIsUnpaidAccount,
  setMessageFilter,
  setOnboardingItemCompletion,
  setPermissions,
  setPlanUsage,
  setPositionSearchVisibility,
  setPositionStatus,
  setPositions,
  setPreferences,
  setProfile,
  setShouldPromptStatus,
  setTotalNotifications,
  setAssociatedSelfTotalNotifications,
  setUserResponseRate,
  setUserResponseTime,
  setUserType,
  setPricingRegion,
  setLocalisationCountry,
  updateMemberPosition
} = user.actions;

// Thunks (async actions & side-effects)

export default user.reducer;
