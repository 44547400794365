import { CandidateApplicationTrackerStage as CandidateATStage } from '@cohiretech/common-types';

const { Liked, ApplicationStarted, Applied, Rejected, Interviewing, Hired, Offered } =
  CandidateATStage;

export const ATS_STAGES = [
  Liked,
  ApplicationStarted,
  Applied,
  Interviewing,
  Offered,
  Hired,
  Rejected
];

export const AMB_CLOSED_BETA_USERS = new Set([
  356480, 343161, 362608, 298134, 16440, 377502, 18902, 213049, 379258, 379472, 359520, 362672,
  380153, 378942, 135757, 194254, 12715, 381150, 352436, 356646, 380570, 165533, 379732, 100448,
  8613, 357376, 194041, 342373, 374021, 137631, 122702, 365044, 274301, 355871, 21464, 379669,
  380853, 378945, 370013, 380039, 275427, 367016, 382836, 29208, 339535, 58341, 61485, 352626,
  380559, 379074, 373406, 378905, 42561, 353463, 135533, 191334, 318369, 134978, 373247, 341201,
  377954, 367881, 369652, 382495, 380830, 357682, 191513, 347300, 371987, 237069, 358389, 379796,
  378195, 278251, 380461, 28036, 178964, 351953, 197547, 341200, 378114, 173494, 333793, 364204,
  2983, 286350, 373338, 183776, 321120, 211256, 370431, 124656, 15104, 342352, 39457, 149363,
  376358, 339007, 148157, 241672, 379517, 66398, 305354, 333576, 93148, 362536, 372737, 380913,
  313915, 379440, 216105, 380397, 378650, 335971, 362010, 42641, 342571, 190459, 359371, 381604,
  355779, 319491, 375877, 376209, 75839, 369039, 85272, 380717, 334896, 381447, 158828, 271767,
  379054, 17877, 358258, 330206, 381460, 380291, 185506, 382314, 375854, 228788, 377710, 111959,
  118695, 223715, 282336, 24889, 281718, 354626, 363545, 174005, 76055, 381453, 207769, 366420,
  346046, 263368, 350688, 380851, 339738, 364534, 360391, 377551, 44325, 305129, 343970, 183556,
  379718, 295649, 381464, 379702, 379702, 381410, 345407, 7233, 375438, 223843, 329022, 10555,
  375714, 25179, 113695, 315055, 106313, 370779, 350323, 90716, 288799, 92299, 376519, 248897,
  364586, 227057, 282424, 379169, 88720, 374711, 32461, 46886, 307971, 381487, 382236, 98293, 32586,
  358948, 267884, 229120, 365687, 147243, 309088, 192280, 39700, 369182, 52819, 375305, 43776,
  375128, 354009, 353026, 207837, 373166, 3529, 360381, 382332, 314931, 382066, 354928, 381808,
  307376, 376112, 285814, 380123, 202973, 381297, 352115, 59539, 379780, 339971, 379090, 315530,
  338407, 310677, 178371, 356677, 374957, 23750, 381471, 183238, 363693, 357040, 378440, 6153,
  381300, 150052, 383072, 357364, 381712, 169324, 74108, 365447, 375915, 374722, 360112, 62256,
  359461, 310576, 119229, 373233, 47083, 377658, 271485, 368168, 89250, 374080, 378748, 380143,
  363450, 343733, 346509, 58720, 186685, 79879, 350431, 238397, 136361, 103582, 379240, 10083,
  339012, 291011, 256166, 330151, 371382, 190191, 297252, 229173, 70110, 18293, 90726, 362302,
  89939, 262912, 327269, 152963, 380281, 374941, 236561, 243965, 303638, 85596, 381362, 135921,
  350789, 76747, 345679, 364030, 343185, 372718, 364915, 13915, 36315, 309997, 352615, 287707,
  355746, 355631, 162213, 71026, 371949, 92975, 276373, 334528, 381041, 359316, 378850, 377080,
  377598, 380395, 356372, 378288, 40068, 343340, 73323, 380714, 135074, 374480, 372218, 301400,
  293864, 375342, 259357, 119274, 383015, 20957, 223528, 380827, 230893, 128587, 379188, 379707,
  383134, 105953, 313819, 360645, 20512, 377387, 294675, 27436, 119216, 265739, 362901, 309276,
  82865, 353807, 319826, 191884, 322861, 212633, 358648, 151082, 327018, 264128, 277784, 363157,
  358662, 96236, 294033, 360435, 28288, 352900, 349008, 310532, 247171, 156806, 291825, 26608,
  359569, 378665, 362826, 221990, 367536, 235428, 260610, 349499, 30029, 354333, 353075, 357686,
  381164, 177034, 118927, 356170, 54969, 68100, 366317, 219018, 378072, 379541, 26677, 251736,
  44736, 269528, 307109, 329197, 109266, 376921, 328712, 160875, 221261, 191048, 188921, 248482,
  296484, 176857, 30708, 381639, 352170, 37828, 369058, 383284, 382736, 382696, 207808, 344632,
  328134, 382414, 305999, 381989, 381918, 381862, 381843, 381730, 195762, 251671, 368846, 56587,
  378625, 149647, 338449, 159386, 344693, 356674, 366962, 381177, 135049, 379793, 382218, 356541,
  374416, 380817, 42444, 352162, 342070, 38517, 176503, 380823, 379784, 49255, 382458, 332254,
  332254, 190966, 333467, 45244, 10862, 271169, 234270, 139467, 92029, 379135, 356714, 298620,
  381082, 315082, 319354, 349029, 166024, 154392, 302294, 16803, 360570, 315164, 282822, 353730,
  358905, 358314, 19599, 367923, 369355, 371031, 19299, 159834, 66089, 188904, 365729, 151213,
  258144, 373204, 363961, 59328, 29798, 130009, 383494, 40579, 149159, 368132, 51973, 347656,
  286066, 299417, 274366, 367676, 357901, 348197, 368295, 373745, 379044, 35125, 77968, 27492,
  302942, 35880, 377669, 118051, 383586, 261334, 318950, 372593, 43261, 302605, 378314, 191777,
  311585, 358298, 166715, 51203, 344222, 380106, 26440, 31823, 303942, 341800, 361917, 369072,
  380310, 42348, 132717, 375250, 376647, 1, 359913, 330290, 68657, 349094, 332879, 34220, 232731,
  330761, 229572, 23931, 70737, 346778, 189720, 379390, 379428, 162521, 368657, 96890, 369655,
  55025, 339954, 367192, 199680, 380630, 303696, 245536, 333406, 368786, 20502, 60136, 374154,
  361487, 321896, 109288, 24072, 369335, 25548, 33248, 33190, 321367, 319306, 352481, 15284, 70313
]);
