import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { PropertyChain } from 'types';
import { path, isBoolean } from 'utils/fn';

// Selectors
const selectUI = (state: RootState) => state.ui;
export const selectDarkMode = createSelector(selectUI, ui => ui.darkMode);
export const selectCandidateTour = createSelector(selectUI, ui => ui.candidateTour);
export const selectDemoCompanyTour = createSelector(selectUI, ui => ui.demoCompanyTour);
export const selectCompaniesToVerify = createSelector(selectUI, ui => ui.companiesToVerify);
export const selectCandidatesToVerify = createSelector(selectUI, ui => ui.candidatesToVerify);
export const selectInitialSetupMode = createSelector(selectUI, ui => ui.initialSetupMode);
export const selectReceivedRequestsPending = createSelector(
  selectUI,
  ui => ui.receivedRequestsPending
);
export const selectSetupMode = createSelector(selectUI, ui => ui.setupMode);
export const selectShowReactivationModal = createSelector(selectUI, ui => ui.showReactivationModal);
export const selectShowCandidateTour = createSelector(selectUI, ui => ui.showCandidateTour);
export const selectShowOfflineNotification = createSelector(
  selectUI,
  ui => ui.showOfflineNotification
);
export const selectShowStartUsingCordPopup = createSelector(
  selectUI,
  ui => ui.showStartUsingCordPopup
);
export const selectSessionEndedPopup = createSelector(selectUI, ui => ui.sessionEndedPopup);
export const selectIsDemoTourFinished = createSelector(selectDemoCompanyTour, demoCompanyTour =>
  demoCompanyTour.every(({ shown }) => shown)
);
export const selectLoader = (route?: string[]) => (state: RootState) => {
  if (!route) return state.ui.loaders['/'].main;

  const maybeLoader = !!path(state.ui.loaders, route);
  if (isBoolean(maybeLoader)) return maybeLoader;

  throw new Error(`No loader found for route: ${route}`);
};
export const selectToggler = (route?: PropertyChain) => (state: RootState) => {
  if (!route) return false;

  const maybeToggler = !!path(state.ui.togglers, route);
  if (isBoolean(maybeToggler)) return maybeToggler;

  throw new Error(`No toggler found for route: ${route}`);
};
export const selectCustomCSSVariables = createSelector(selectUI, ui => ui.cssVariables);
