import styled from 'styled-components';

import { LeftIconSelectOptionStyling } from 'v2/components/ui/styles';

export const LocationInputWrapper = styled.div`
  position: relative;
`;

export const LocationList = styled.div`
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--background-block-primary);
  box-shadow: var(--drop-shadow-focused);
  z-index: 100;
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-top: 0.25rem;
  text-align: left;

  .location_list_item {
    ${LeftIconSelectOptionStyling({
      padding: '0.5rem 0.75rem',
      borderRadius: '0',
      iconSize: '1.375rem'
    })}
  }
`;
