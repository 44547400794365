import Dexie from 'dexie';

import { SkillRetrievalKey } from '@cohiretech/common-types';

import { CordDexie, IndexDBTable } from 'types';

const INDEX_DB_DATA_KEY = 'dataKey';

const db = new Dexie('data-store') as CordDexie;

const SCHEMA: { [key in IndexDBTable]?: string } = {
  incomingRequest: '',
  allConversation: '',
  messageDetails: '',
  sourcePositions: '',
  positionDetails: '',
  skills: '',
  profile: '',
  'positions.user.listings': '',
  'positions.other.listings': '',
  'positions.all.listings': '',
  'positions.user.activity': '',
  'positions.other.activity': '',
  'positions.all.activity': '',
  'positions.user.streams': '',
  'positions.other.streams': '',
  'positions.all.streams': '',
  'positions.user.candidateCount': '',
  'positions.other.candidateCount': '',
  'positions.all.candidateCount': ''
};

const SKILL_RETRIEVAL_KEYS: SkillRetrievalKey[] = [
  'DetailedTrunkAndBrunch',
  'OnlyTrunk',
  'OnlyBranch',
  'All',
  'LowerToPretty',
  'LowerTrunkToBranches',
  'LowerBranchToTrunk',
  'SkillToCategory',
  'CategoryToSkill'
];

const getSchema = () =>
  SKILL_RETRIEVAL_KEYS.reduce(
    (acc, key) => {
      acc[`skills${key}`] = '';
      return acc;
    },
    { ...SCHEMA }
  );

db.version(10.1).stores(getSchema());

export const retrieveIndexedDBKeys = async (table: IndexDBTable, keys: any) => {
  if (db) {
    const response = await db[table]?.bulkGet(keys);
    return response;
  }

  return null;
};

export const retrieveIndexedDBData = async (table: IndexDBTable, key = INDEX_DB_DATA_KEY) => {
  if (db) {
    const response = await db[table]?.get(key);
    return response;
  }

  return null;
};

export const storeIndexedDBData = async (
  table: IndexDBTable,
  data: any,
  key = INDEX_DB_DATA_KEY
) => {
  if (db) {
    if (key === INDEX_DB_DATA_KEY) await db[table]?.clear();
    await db[table]?.put(data, key);
  }

  return null;
};

export const clearIndexedDBData = async (table: IndexDBTable) => {
  if (db) {
    await db[table]?.clear();
  }

  return null;
};
