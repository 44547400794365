import React from 'react';
import ClassNames from 'classnames';
import SanitizedHTML from 'react-sanitized-html';

import { ImageResizeParam } from 'types';
import { generateCompanyURL, addURLProtocol } from 'utils';
import {
  addDimensionsOnImages,
  getImageDimensions,
  getImageDimensionsObj
} from 'v2/services/image';
import { getCompanyStory } from 'v2/services/fetchers/public/companyStories';

import ArrowLink from 'components/arrowlink';
import Loader from 'components/loader';
import PageSection from 'components/pagesection';
import Seo from 'components/seo';
import SplitStepsSection from 'components/splitstepssection';
import StoryCarousel from 'components/storycarousel';
import VideoItem from 'components/videoitem';

import Avatar from 'v2/components/ui/atoms/Avatar';
import DemoButtonSection from 'v2/components/DemoButtonSection';

import './style.scss';

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

const imageParams: ImageResizeParam[] = [
  { type: 'logo', dimensions: getImageDimensions('no-crop-logo-small') }
];

type State = $TSFixMe;

export default class CustomerStory extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
      story: []
    };
  }

  getStoryId() {
    // @ts-expect-error TS(2339) FIXME: Property 'match' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { match } = this.props;
    const { id } = match.params;

    return parseInt(id, 10);
  }

  componentDidMount() {
    this.getCustomerStory();
  }

  async getCustomerStory() {
    // @ts-expect-error TS(2339) FIXME: Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { history } = this.props;
    const id = this.getStoryId();

    if (id > -1) {
      const { data } = await getCompanyStory(id);

      this.setState({ story: addDimensionsOnImages(data, imageParams), loading: false });
    } else {
      history.push('/stories');
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className, location, darkMode } = this.props;
    const { story, loading } = this.state;
    const {
      companyID,
      companyLogo,
      companyName,
      coverPhotoURL,
      companyDescription,
      title,
      goal,
      situation,
      experience,
      result,
      quote,
      photoURL,
      fullName,
      jobTitle,
      websiteURL,
      videoURL,
      publishStatus,
      highlights,
      storyLogo
    } = story;

    const companyURL = companyName
      ? generateCompanyURL(location, { companyName, companyID }, true)
      : '';
    const imageDimensions = companyName
      ? getImageDimensionsObj(coverPhotoURL)
      : { width: '', height: '' };
    const isStoryUpToDate = highlights?.length === 2 && storyLogo;

    return (
      <div id="story" className={ClassNames(className, { dark: darkMode })}>
        {loading ? (
          <Loader className="story_loader medium" />
        ) : (
          <>
            <div className="intro_wrapper">
              <div className="page_view center_alignment">
                <img
                  className="intro_logo"
                  src={companyLogo}
                  alt={`${companyName}'s logo`}
                  loading="lazy"
                />
                <h1 className="heading">{title}</h1>
                {quote && (
                  <h2 className={ClassNames('subheading', 'intro_testimonial')}>"{quote}"</h2>
                )}
                {quote && (
                  <div className="quote_details">
                    <Avatar
                      photoURL={photoURL}
                      width={40}
                      className="quote_photo"
                      firstname={fullName}
                    />
                    <strong>
                      {fullName}, {jobTitle} at {companyName}
                    </strong>
                  </div>
                )}
              </div>
            </div>
            <div className="company_wrapper">
              <div className="page_view">
                {isStoryUpToDate ? (
                  <StoryCarousel stories={[story]} darkMode={darkMode} isStoryPage />
                ) : videoURL ? (
                  <VideoItem
                    videoUrl={videoURL}
                    showVideoPlayer
                    fallbackPhotoURL={coverPhotoURL}
                    youtubeOptions={{
                      width: '100%',
                      height: '320',
                      title: `${companyName} story video`
                    }}
                  />
                ) : (
                  <img
                    className="cover_photo"
                    src={coverPhotoURL}
                    alt={companyName}
                    loading="lazy"
                  />
                )}
                <div className="company_description_wrapper">
                  <h2 className="underlined_section_title">About {companyName}</h2>
                  <SanitizedHTML
                    className="company_description rich_editor_text"
                    allowedTags={[
                      'a',
                      'b',
                      'i',
                      'em',
                      'u',
                      'blockquote',
                      'div',
                      'p',
                      'span',
                      'ul',
                      'ol',
                      'li',
                      'strong',
                      'br',
                      'pre'
                    ]}
                    html={companyDescription}
                  />
                  <ArrowLink absoluteURL={addURLProtocol(websiteURL)}>View website</ArrowLink>
                  <ArrowLink absoluteURL={`${domain}${companyURL}`}>View cord profile</ArrowLink>
                </div>
              </div>
            </div>
            {goal.length > 10 && (
              <PageSection
                title="Our Goal"
                underlinedTitle
                subTitle={goal}
                className="goal_wrapper blue_background center_alignment"
                widthClassName="min_view"
              />
            )}
            {(situation || experience) && (
              <SplitStepsSection
                largeTitle
                steps={[
                  situation.length > 10 && {
                    step: 'Our Situation',
                    content: (
                      <SanitizedHTML
                        className="pre_wrap_description rich_editor_text"
                        allowedTags={[
                          'a',
                          'b',
                          'i',
                          'em',
                          'u',
                          'blockquote',
                          'div',
                          'p',
                          'span',
                          'ul',
                          'ol',
                          'li',
                          'strong',
                          'br',
                          'pre'
                        ]}
                        html={situation}
                      />
                    )
                  },
                  experience.length > 10 && {
                    step: 'Using cord',
                    content: (
                      <SanitizedHTML
                        className="pre_wrap_description rich_editor_text"
                        allowedTags={[
                          'a',
                          'b',
                          'i',
                          'em',
                          'u',
                          'blockquote',
                          'div',
                          'p',
                          'span',
                          'ul',
                          'ol',
                          'li',
                          'strong',
                          'br',
                          'pre'
                        ]}
                        html={experience}
                      />
                    )
                  }
                ]}
              />
            )}
            {result.length > 10 && (
              <PageSection
                title="Our Result"
                underlinedTitle
                subTitle={result}
                className={ClassNames('result_wrapper', 'center_alignment', {
                  gradient_blue_background: !darkMode,
                  dark_blue_background: darkMode
                })}
                widthClassName="min_view"
              />
            )}
          </>
        )}
        <DemoButtonSection type="make_direct_hires" />
        <Seo
          title={companyName ? `${companyName}'s Story` : 'Customer Story'}
          description={
            quote ||
            "Don't worry, you're in good company. Hundreds of companies have hired people through cord. Read some of their stories & feedback below."
          }
          image={coverPhotoURL || ''}
          width={imageDimensions ? imageDimensions.width : ''}
          height={imageDimensions ? imageDimensions.height : ''}
          path={location.pathname}
          robots={publishStatus === 'draft' ? 'none' : ''}
          contentType="website"
        />
      </div>
    );
  }
}
