import React, { useEffect } from 'react';
import ClassNames from 'classnames';
import { History } from 'history';
import { RouteComponentProps } from 'react-router';

import * as fetcher from 'fetcher';
import { setLocalStorageItem } from 'cookieManager';
import { useDarkMode } from 'hooks/useDarkMode';
import { useToggler } from 'store';

import Loader from 'components/loader';
import Seo from 'components/seo';

interface MatchParam {
  code?: string;
}
interface Props extends RouteComponentProps<MatchParam> {
  onLoginStatusChange: () => Promise<void>;
  history: History;
}

export default function CompanyInvitePage({ onLoginStatusChange, history, match }: Props) {
  const darkMode = useDarkMode();
  const { toggle: toggleShowDemoWelcomePopup } = useToggler('company.showDemoWelcomePopup');

  const signupForPersonalisedDemo = async () => {
    const inviteCode = match?.params?.code || '';
    const { status } = await fetcher.companySignupDemo(match.params.code);

    if (status === 'success') {
      setLocalStorageItem('companyInviteCode', inviteCode);
      toggleShowDemoWelcomePopup(true);

      await onLoginStatusChange();
      history.push({
        pathname: '/company/discover',
        search: '?utm_source=livedemo'
      });
    } else if (status === 'failure') {
      history.push('/companies/invite?utm_source=expiredinvite');
    }
  };

  useEffect(() => {
    // match pathname on /company/invite. Should only run if there's a match
    if (!isInviteURL(history)) return;

    signupForPersonalisedDemo();
  }, []);

  return (
    <>
      <Loader className={ClassNames('large page_loader', { dark: darkMode })} />
      <Seo
        title="Make direct hires"
        description="cord enables Founders and Hiring teams to make direct hires by sourcing and messaging active people who are looking for permanent work in the UK, USA and remote across Europe."
        path="/companies"
        contentType="website"
        robots="none"
        script={[{ src: 'https://www.googleoptimize.com/optimize.js?id=OPT-PJSHB2Z' }]}
        image="https://assets.co-hire.com/make-direct-hires.png"
      />
    </>
  );
}

const isInviteURL = (history: History) => history.location.pathname.match(/\/company\/invite/);
