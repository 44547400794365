import axios from 'axios';
import { retrieveIndexedDBData, storeIndexedDBData } from 'indexedDB';

import { CandidateFullProfileDTO, UserType } from '@cohiretech/common-types';

import { getLocalStorageItem, removeLocalStorageItem } from 'cookieManager';
import { getCandidateNotifications, logout } from 'fetcher';
import { identifyCandidate } from 'tracking-utils';
import { APIResponse, CandidateProfile } from 'types';
import { getCandidateNotificationsCount } from 'utils';
import { decodeProfileForCandidate } from 'v2/services/candidate';

const CANDIDATE_PROFILE = process.env.REACT_APP_CANDIDATE_PROFILE!;

export const getCandidateProfile = async (
  initialLoad?: boolean
): Promise<CandidateProfile | null> => {
  let profile: CandidateProfile = {} as CandidateProfile;
  const showHomepageSetup = getLocalStorageItem('show_homepage_setup');

  try {
    const [{ data }, notifications] = await Promise.all([
      axios.get<APIResponse<CandidateFullProfileDTO>>(CANDIDATE_PROFILE),
      getCandidateNotifications()
    ]);

    if (data?.data?.email) {
      // @ts-expect-error Missing notifications and totalNotifications
      // but we are about to add them below
      profile = decodeProfileForCandidate(data.data, UserType.Candidate);

      // If user is on homepage setup mode and accepted and has core skills filled in (invited users are accepted but do not have core skills filled in)
      // then remove setup mode local storage so it will fallback to homepage results
      if (
        showHomepageSetup &&
        profile.accepted &&
        profile.primarySkills &&
        profile.primarySkills.length > 0
      ) {
        removeLocalStorageItem('show_homepage_setup');
      }
    }
    const totalNotifications = getCandidateNotificationsCount(notifications);
    if (profile) {
      profile.notifications = notifications;
      profile.totalNotifications = totalNotifications;

      if (initialLoad) identifyCandidate(profile);
    }

    storeIndexedDBData('profile', data);
    return profile;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message;
    if (errorMessage === 'unauthorized') {
      logout(false, false);
      return null;
    }
    const response = await retrieveIndexedDBData('profile');
    if (response) return response;

    return null;
  }
};
