import React from 'react';

import SearchOptionList from 'v2/components/ui/molecules/SearchOptionList';

import { useSearchBarContext } from '../../SimpleSearchBar.context';

export default function AutoComplete() {
  const { cursor, searchKeywordResults, startNewSearch } = useSearchBarContext();

  return (
    <SearchOptionList
      searchOptions={searchKeywordResults}
      focusedIndex={cursor}
      maxHeight="400px"
      onClick={(_, option) => startNewSearch(option)}
    />
  );
}
