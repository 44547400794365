import React from 'react';
import styled from 'styled-components';

import {
  ApplicantsMatchScoresBlock,
  DeclineReasonsInsightsBlock,
  MatchScoreBlock,
  MatchScoreBreakdownBlock,
  SkillsBreakdownBlock,
  MatchComparisonBlock,
  PositionSalaryComparisonBlock
} from './insights';

export default function PositionInsights() {
  return (
    <InsightsContainer>
      <MatchScoreBlock />
      <MatchComparisonBlock />
      <ApplicantsMatchScoresBlock />
      <MatchScoreBreakdownBlock />
      <SkillsBreakdownBlock />
      <DeclineReasonsInsightsBlock />
      <PositionSalaryComparisonBlock />
    </InsightsContainer>
  );
}

const InsightsContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 3.5rem;
`;
