import axios from 'axios';

import { APIResponse } from 'types';

const COMPANY_CANDIDATE_SKIP = process.env.REACT_APP_COMPANY_CANDIDATE_SKIP;

type HideParams = {
  candidateID: number;
  companyID?: number;
  ui?: string;
  searchPosition: number;
  skipped: boolean;
  savedSearchID?: number;
};

/* istanbul ignore next */
export const hideOrUnhideCandidateAllPositions = async (
  candidateID: number,
  ui: string,
  searchPosition: number,
  skipped = true,
  streamID?: number
): Promise<APIResponse> => {
  try {
    const params: HideParams = { candidateID, searchPosition, skipped };

    if (ui) params.ui = ui;
    if (streamID) params.savedSearchID = streamID;

    await axios.post(`${COMPANY_CANDIDATE_SKIP}`, params);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
