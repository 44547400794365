import { AnyFunction } from 'types';

/**
 * Runs the provided function with the (optionally) provided arguments.
 *
 * @example
 * execute(fn, 1, 2, 3); // fn(1, 2, 3)
 * execute(fn, 1); // fn(1)
 * execute(fn); // fn()
 *
 **/
export const execute = <T, A>(fn: AnyFunction<T, A>, ...args: A[]): T => {
  return fn(...args);
};

/**
 * Runs the provided function with the arguments provided first (curried).
 *
 * @example
 * executeArgs(1, 2, 3)(fn); // fn(1, 2, 3)
 * executeArgs(1)(fn); // fn(1)
 *
 **/
export const executeArgs =
  <T, A>(...args: A[]) =>
  (fn: AnyFunction<T, A>) => {
    return execute(fn, ...args);
  };
