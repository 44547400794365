import React from 'react';

import { CandidateStatus } from '@cohiretech/common-types';

import { setVisible } from 'fetcher';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

export type StatusProps = {
  className?: string;
  status: CandidateStatus;
  visible: boolean;
  getCandidateData?: () => Promise<void>;
  updateStatusOnChange?: boolean;
};

const ToggleVisibilityButton = ({ visible, getCandidateData }: Omit<StatusProps, 'status'>) => {
  const onVisibilityChange = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const response = await setVisible(!visible);

    if (response.status === 'success') getCandidateData?.();
  };

  return (
    <button type="button" className="link" onClick={e => onVisibilityChange(e)}>
      {visible ? 'Make your profile invisible' : 'Make your profile visible'}
    </button>
  );
};

export const getToggleLabel = ({ status, visible, getCandidateData }: StatusProps) => {
  if (status === CandidateStatus.Active) {
    return {
      title: `Open to opportunities${!visible ? ' (invisible)' : ''}`,
      description: 'Open to hearing from companies hiring and wish to be visible to them.'
    };
  }

  return {
    title: `Not looking${!visible ? ' (invisible)' : ''}`,
    description: (
      <>
        Not open to hearing from companies and no access to messaging.{' '}
        <ConditionalRender
          predicate={visible}
          fallback={
            <>
              Your profile is invisible.
              <br />
              Want to be visible to companies hiring?
            </>
          }
        >
          Limited profile visibility.
          <br />
          Want to be completely invisible?
        </ConditionalRender>{' '}
        <ToggleVisibilityButton visible={visible} getCandidateData={getCandidateData} />
      </>
    )
  };
};
