import styled from 'styled-components';

import { NoScrollbar } from 'v2/components/utility/Scroll';

export const CompanySection = styled.div`
  padding: 5rem 0.75rem;

  ${({ theme: { media } }) => media.tablet` 
  padding: 3rem 0.75rem;
    `}
`;

export const CompanySectionHeader = styled.div`
  background: var(--background-body-primary);
  position: sticky;
  top: 4rem;
  padding-top: 0.5rem;
  z-index: 3;

  .page_view {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    position: relative;
  }
`;

export const CompanyCategoriesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  overflow: auto;
  ${NoScrollbar}

  .company_category_tab {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1.5rem;
    border: 1px solid var(--border-divider-soft);

    ${({ theme: { media, typography } }) => media.mobile`
      font-size: ${typography.normal};
      padding: 0.5rem 0.75rem;

      [class^='icon_'] {
        font-size: ${typography.smallheader};
      }
    `}

    &:hover {
      border-color: var(--border-accent-secondary-base);
    }

    &.active {
      border: 2px solid var(--border-accent-secondary-base);
      font-weight: ${({ theme: { typography } }) => typography.semibold};

      [class^='icon_'] {
        font-weight: bold;
      }
    }
  }
`;

export const Arrow = styled.button`
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  background: var(--background-block-primary);
  border: 1px solid var(--border-divider-soft);
  box-shadow: 0 0 0.5rem 1rem var(--background-body-primary);

  &.arrow_right {
    right: 0;
    [class^='icon_'] {
      margin-right: -2px; //optimal center
    }
  }

  &.arrow_right [class^='icon_'] {
    margin-left: -2px; //optimal center
  }

  [class^='icon_'] {
    font-weight: bold;
    font-size: ${({ theme: { typography } }) => typography.small};
  }
`;

export const CompanyResults = styled.div`
  .page_view {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
