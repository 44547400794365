import { retrieveIndexedDBKeys, storeIndexedDBData } from 'indexedDB';

import { DateArgument, IndexDBTable } from 'types';
import { addTime } from 'utils';
import { hasDatePassed } from 'utils/time';

const KEYS = ['dataKey', 'expiryDate'] as const;
const DEFAULT_EXPIRY = () => addTime(new Date(), 1, 'hours');

export const persistState = async <T>(
  state: T,
  id: IndexDBTable,
  expiryDate?: DateArgument
): Promise<boolean> => {
  try {
    const newExpiryDate = DEFAULT_EXPIRY();
    storeIndexedDBData(id, JSON.stringify(state));
    storeIndexedDBData(id, expiryDate || newExpiryDate, 'expiryDate');
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(
      `Unable to persist ${id}. This might affect load times. Try clearing your cookies and cache.\nFull: ${error}`
    );
    return false;
  }
};

export const retrieveState = async <T>(id: IndexDBTable): Promise<T | null> => {
  const stored = await retrieveIndexedDBKeys(id, KEYS);
  if (stored) {
    const [response] = stored;
    if (isStoredAndFresh(stored)) return JSON.parse(response) as T;
    return null;
  }

  return null;
};

const isStoredAndFresh = (
  stored: any,
  validateFn: (state: any) => boolean = (state: any) => !!state
): boolean => {
  const [state, expiryDate] = stored;
  return validateFn(state) && !hasDatePassed(expiryDate);
};
