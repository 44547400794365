import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { JobTitleOption } from '@cohiretech/common-types';

import { storeDraftPosition } from 'utils/companyUtils';
import { checkIfTechCategory } from 'v2/services/common';
import { isEmpty, isString } from 'utils/fn';
import { selectJobTitleOptions } from 'store/common/common.selectors';
import { isCandidateProfile, RootState } from 'store';
import { fetchJobTitleOptions } from 'store/common/common.thunks';

import {
  PositionEditContext,
  PositionEditContextState
} from 'views/company/position/PositionEdit.context';

import SkillAutocompleteField from 'components/skillautocompletefield';
import JobTitleSelectField from 'components/jobtitleselectfield';
import SelectSeniorityLevel from 'components/selectquestion/SelectSeniorityLevel';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type CompanyEditPositionInterestsState = {
  jobTitles: string[];
  primarySkills: string[];
  secondarySkills: string[];
  seniorities: string[];
  techStack: string[];
  exampleSkills: string[];
};

type CompanyEditPositionInterestsProps = {
  id?: number;
  jobTitles?: string[];
  primarySkills?: string[];
  secondarySkills?: string[];
  seniorities?: string[];
  techStack?: string[];
  formSubmitted: boolean;
} & ConnectedProps<typeof connector>;

class CompanyEditPositionInterests extends React.Component<
  CompanyEditPositionInterestsProps,
  CompanyEditPositionInterestsState
> {
  constructor(props: CompanyEditPositionInterestsProps) {
    super(props);

    this.state = {
      jobTitles: props.jobTitles || [],
      seniorities: props.seniorities || [],
      primarySkills: props.primarySkills || [],
      secondarySkills: props.secondarySkills || [],
      techStack: props.techStack || [],
      exampleSkills: []
    };
  }

  validate() {
    const { jobTitles, seniorities, primarySkills } = this.state;

    if (isEmpty(seniorities)) return 'Please select at least one seniority.';

    const { isCandidateATPosition } = this.props;

    if (!isCandidateATPosition) {
      if (isEmpty(jobTitles)) return 'Please select at least one job role.';
      if (isEmpty(primarySkills)) return 'Please select at least one core skill.';
    }

    return '';
  }

  selectPosition(option: string | JobTitleOption) {
    const jobTitles = [...this.state.jobTitles];
    const index = jobTitles.findIndex(title => title === option);

    if (index > -1) jobTitles.splice(index, 1);
    else if (jobTitles.length < 3 && !isString(option)) jobTitles.push(option.value);

    this.setPosition({ jobTitles });
  }

  updateSkillExamples() {
    const { jobTitleOptions } = this.props;
    const { jobTitles } = this.state;
    const exampleSkills = jobTitleOptions
      .filter(({ value }) => jobTitles.includes(value))
      .map(({ popularSkill }) => popularSkill);

    this.setState({ exampleSkills });
  }

  updateOTEFieldVisibility() {
    const { jobTitleOptions, isCandidateATPosition } = this.props;

    if (isCandidateATPosition) return;

    const { jobTitles } = this.state;
    const { showOTEField, setShowOTEField } = (this.context as PositionEditContextState) || {};
    const shouldShowOTEField = jobTitles.some(jobTitle => {
      const option = jobTitleOptions?.find(({ value }) => value === jobTitle);

      return option?.jobCategoryID ? !checkIfTechCategory(option?.jobCategoryID) : false;
    });

    if (showOTEField !== shouldShowOTEField) setShowOTEField(shouldShowOTEField);
  }

  async componentDidMount() {
    const { fetchJobTitleOptions } = this.props;

    await fetchJobTitleOptions();

    this.updateSkillExamples();
    this.updateOTEFieldVisibility();
  }

  componentDidUpdate(
    prevProps: CompanyEditPositionInterestsProps,
    prevState: CompanyEditPositionInterestsState
  ) {
    const { jobTitleOptions } = this.props;
    const { jobTitles } = this.state;

    if (prevState.jobTitles !== jobTitles || prevProps.jobTitleOptions !== jobTitleOptions) {
      this.updateSkillExamples();
      this.updateOTEFieldVisibility();
    }
  }

  setPosition(state: $TSFixMe) {
    const { id } = this.props;

    // if creating new position
    if (!id) storeDraftPosition(state);

    this.setState(state);
  }

  render() {
    const { jobTitles, seniorities, primarySkills, secondarySkills, techStack, exampleSkills } =
      this.state;
    const { formSubmitted, isCandidateATPosition } = this.props;

    return (
      <div className="interests_block">
        <JobTitleSelectField
          question="Job roles"
          multiple
          maxSelection={3}
          columns={3}
          selected={jobTitles}
          onSelect={selection => this.selectPosition(selection)}
          formSubmitted={formSubmitted}
          optional={isCandidateATPosition}
          hideExpandedRoles={isCandidateATPosition}
        />
        <SelectSeniorityLevel
          question="Seniority levels"
          selected={seniorities}
          noneSelectedMessage="Please select seniority level"
          formSubmitted={formSubmitted}
          setSeniorities={seniorities => this.setPosition({ seniorities })}
        />
        <SkillAutocompleteField
          defaultSkills={primarySkills}
          otherSkills={secondarySkills}
          onSkillUpdate={(skills: $TSFixMe) => this.setPosition({ primarySkills: skills })}
          question={
            isCandidateATPosition
              ? 'Core skills considered:'
              : 'Considering people with core skills of:'
          }
          maxSelection={5}
          skillType="core skills"
          showRecommendationsFor="primary"
          columns={5}
          disableItemsLabel="Other"
          skillExample={exampleSkills[0]}
          selectedJobTitles={jobTitles}
          noneSelectedMessage="Please select core skills"
          formSubmitted={formSubmitted}
          hideRecommendationsLabel={isCandidateATPosition}
          optional={isCandidateATPosition}
        />
        <SkillAutocompleteField
          defaultSkills={secondarySkills}
          otherSkills={primarySkills}
          onSkillUpdate={(skills: $TSFixMe) => this.setPosition({ secondarySkills: skills })}
          question={
            isCandidateATPosition
              ? 'Other skills considered:'
              : 'Considering people with other skills of:'
          }
          maxSelection={5}
          skillType="other skills"
          showRecommendationsFor="secondary"
          columns={5}
          disableItemsLabel="Core"
          hideRecommendationsLabel={isCandidateATPosition}
          optional
        />
        <ConditionalRender predicate={!isCandidateATPosition}>
          <SkillAutocompleteField
            defaultSkills={techStack}
            onSkillUpdate={(skills: $TSFixMe) => this.setPosition({ techStack: skills })}
            question="Tech stack/tooling used in position"
            skillType="tech stack/tooling"
            columns={5}
            sameAs="core skills"
            skillsToCopy={primarySkills}
            optional
          />
        </ConditionalRender>
      </div>
    );
  }
}

CompanyEditPositionInterests.contextType = PositionEditContext;

const mapStateToProps = (state: RootState) => ({
  isCandidateATPosition: isCandidateProfile(state.user.profile),
  jobTitleOptions: selectJobTitleOptions(state)
});

const mapDispatchToProps = { fetchJobTitleOptions };

const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });

export default connector(CompanyEditPositionInterests);
