import { CompanySearchCandidateStatusEnum } from '@cohiretech/common-types';

export const companySearchFilters = {
  jobTitle: { attribute: 'job_title' },
  orPrimarySkill: { label: 'Core skill', attribute: 'or_primary_skill' },
  primarySkill: { label: 'Core skill', attribute: 'primary_skill' },
  orSecondarySkill: { label: 'Other skill', attribute: 'or_secondary_skill' },
  secondarySkill: { label: 'Other skill', attribute: 'secondary_skill' },
  orSkill: { label: 'Skill', attribute: 'or_skill' },
  skill: { label: 'Skill', attribute: 'skill' },
  experienceLevel: { label: 'Experience level', attribute: 'experience_level' },
  seniority: { attribute: 'seniority' },
  companySize: { label: 'Company experience', attribute: 'occupation_company_sizes' },
  companyIndustry: { label: 'Industry experience', attribute: 'occupation_company_industries' },
  lowerTimeOffset: { label: 'Lower Time Offset', attribute: 'lower_time_offset' },
  upperTimeOffset: { label: 'Upper Time Offset', attribute: 'upper_time_offset' },
  minSalary: { label: 'Min', attribute: 'min_salary' },
  maxSalary: { label: 'Max', attribute: 'max_salary' },
  relocate: { label: 'Include people looking to relocate/commute', attribute: 'include_relocate' },
  hidden: { label: 'Hidden candidates', attribute: 'include_hidden' },
  location: { attribute: 'location' },
  radius: { attribute: 'radius_km' },
  keyword: { label: 'Keyword', attribute: 'keyword' },
  remoteWorking: { attribute: 'remote' },
  workEligibility: { label: 'Work eligibility', attribute: 'work_eligibility' },
  previouslyDealtWith: {
    label: 'Candidates previously dealt with',
    attribute: 'exclude_dealt_with'
  },
  previouslySkipped: { label: 'Hidden candidates', attribute: 'exclude_skipped' },
  candidatePool: { label: 'Candidate pool', attribute: 'candidate_status' },
  newCandidates: { label: "New candidates I haven't seen", attribute: 'new_candidates_only' },
  sortBy: { label: 'Sort by', attribute: 'sort_by' },
  lastActive: { label: 'Last active', attribute: 'last_active' },
  gender: { attribute: 'gender' },
  ethnicity: { label: 'People from minority backgrounds', attribute: 'minority_background' },
  cordLive: { label: 'cordLive registered attendees', attribute: 'cordlive_jan_24' },
  lastActiveValue: { attribute: 'last_active_value' },
  lastActiveType: { attribute: 'last_active_type' }
};

export const candidatePoolOptions = [
  {
    attribute: companySearchFilters.candidatePool.attribute,
    label: 'Active',
    value: CompanySearchCandidateStatusEnum.Active
  },
  {
    attribute: companySearchFilters.candidatePool.attribute,
    label: 'Passive',
    value: CompanySearchCandidateStatusEnum.Passive
  },
  {
    attribute: companySearchFilters.candidatePool.attribute,
    label: 'All',
    value: CompanySearchCandidateStatusEnum.All
  }
];

const sf = companySearchFilters;

export const COMPANY_FILTER_GROUPS = [
  {
    title: 'jobTitle',
    label: 'Job title',
    attributes: [sf.jobTitle.attribute],
    relation: 'includes'
  },
  {
    title: 'primarySkill',
    label: 'Core skills',
    attributes: [sf.orPrimarySkill.attribute, sf.primarySkill.attribute],
    relation: 'include'
  },
  {
    title: 'secondarySkill',
    label: 'All skills',
    attributes: [sf.orSecondarySkill.attribute, sf.secondarySkill.attribute],
    relation: 'include'
  },
  {
    title: 'skill',
    label: 'Skills',
    attributes: [sf.orSkill.attribute, sf.skill.attribute],
    relation: 'includes'
  },
  {
    title: 'experienceLevel',
    label: 'Experience level',
    attributes: [sf.experienceLevel.attribute],
    relation: 'is'
  },
  { title: 'seniority', label: 'Seniority', attributes: [sf.seniority.attribute], relation: 'is' },
  {
    title: 'companySize',
    label: 'Company experience',
    attributes: [sf.companySize.attribute],
    relation: 'is'
  },
  {
    title: 'companyIndustry',
    label: 'Industry experience',
    attributes: [sf.companyIndustry.attribute],
    relation: 'is'
  },
  {
    title: 'timeOffset',
    label: 'Working timezones',
    attributes: [sf.lowerTimeOffset.attribute, sf.upperTimeOffset.attribute],
    relation: 'is'
  },
  {
    title: 'salary',
    label: 'Expected salary',
    attributes: [sf.minSalary.attribute, sf.maxSalary.attribute],
    relation: 'is'
  },
  { title: 'hidden', label: 'Hidden', attributes: [sf.hidden.attribute], relation: 'is' },
  { title: 'location', label: 'Location', attributes: [sf.location.attribute], relation: 'is' },
  { title: 'radius', label: 'Radius', attributes: [sf.radius.attribute], relation: 'is' },
  { title: 'keyword', label: 'Keyword', attributes: [sf.keyword.attribute], relation: 'is' },
  {
    title: 'remoteWorking',
    label: 'Remote working',
    attributes: [sf.remoteWorking.attribute],
    relation: 'is'
  },
  {
    title: 'relocate',
    label: 'Relocate',
    attributes: [sf.relocate.attribute],
    relation: 'includes'
  },
  {
    title: 'workEligibility',
    label: 'Work eligibility',
    attributes: [sf.workEligibility.attribute],
    relation: 'is'
  },
  { title: 'languages', label: 'Language', attributes: ['languages'], relation: 'is' },
  {
    title: 'previouslySkipped',
    label: '',
    attributes: [sf.previouslySkipped.attribute, sf.previouslyDealtWith.attribute],
    relation: 'include'
  },
  {
    title: 'candidatePool',
    label: 'Candidate pool',
    attributes: [sf.candidatePool.attribute],
    relation: 'is'
  },
  {
    title: 'newCandidates',
    label: 'New candidates',
    attributes: [sf.newCandidates.attribute],
    relation: 'is'
  },
  {
    title: 'showOnly',
    label: 'Show only',
    attributes: [sf.gender.attribute, sf.ethnicity.attribute, sf.cordLive.attribute],
    relation: ''
  },
  {
    title: 'lastActive',
    label: 'Last active',
    attributes: [sf.lastActive.attribute],
    relation: 'is'
  },
  {
    title: 'lastActiveValue',
    label: 'Last active value',
    attributes: [sf.lastActiveValue.attribute],
    relation: 'is'
  },
  {
    title: 'lastActiveType',
    label: 'Last active type',
    attributes: [sf.lastActiveType.attribute],
    relation: 'is'
  }
];

const AND_ATTRIBUTES = [
  'primary_skill',
  'secondary_skill',
  'skill',
  'minority_background',
  'gender',
  'cordlive_jan_24'
];

const BETWEEN_ATTRIBUTES = ['lower_time_offset', 'upper_time_offset', 'min_salary', 'max_salary'];

export const isAndOr = (attribute?: string) => {
  if (!attribute) return 'OR';

  if (BETWEEN_ATTRIBUTES.includes(attribute)) return '–';
  return AND_ATTRIBUTES.includes(attribute) ? 'AND' : 'OR';
};

export const ACTIVE_COUNT_SEARCH_ITEMS = [
  {
    attribute: companySearchFilters.candidatePool.attribute,
    value: 'active',
    label: ''
  }
];
