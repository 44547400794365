import React from 'react';
import ClassNames from 'classnames';

import PageSection from 'components/pagesection';
import Tags from 'components/tags';

import Data from 'images/data-science.svg';
import Design from 'images/design.svg';
import Engineering from 'images/engineering.svg';
import Commercial from 'images/sales-marketing.svg';
import Management from 'images/management.svg';

const SupportedPositionCategories = [
  {
    name: 'Engineering',
    image: Engineering,
    examples: <Tags tags={['Software Development', 'Infra & DevOps', 'QA']} />
  },
  {
    name: 'Data',
    image: Data,
    examples: <Tags tags={['Data Science', 'Data Analysis', 'Machine Learning', 'AI']} />
  },
  {
    name: 'Product & Design',
    image: Design,
    examples: <Tags tags={['Product Management', 'UI/UX', 'Research']} />
  },
  {
    name: 'Commercial',
    image: Commercial,
    examples: <Tags tags={['Sales', 'Marketing', 'CS', 'Support']} />
  },
  {
    name: 'Operations',
    image: Management,
    examples: <Tags tags={['Operations', 'Finance', 'Legal', 'HR', 'Accounting']} />
  }
];

const TechAndProductPositions = () => (
  <PageSection
    title="Every position in one place"
    className={ClassNames('center_alignment', 'gradient_blue_background')}
    widthClassName="page_view"
  >
    <div className="supported_positions_wrapper">
      {SupportedPositionCategories.map(({ image, name, examples }, index) => (
        <div key={`${index}_${name}`} className="supported_position">
          <h4>{name}</h4>
          <img src={image} alt={name} loading="lazy" />
          {examples}
        </div>
      ))}
    </div>
  </PageSection>
);

export default TechAndProductPositions;
