import React from 'react';
import ClassNames from 'classnames';

import * as fetcher from 'fetcher';
import { debounce, checkIfUserApproachedEndOfPage } from 'utils';

import Loader from 'components/loader';
import TestimonialBlocklist from 'components/testimonialblocklist';
import Seo from 'components/seo';

type State = $TSFixMe;

export default class Testimonials extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    const pathname = (props as $TSFixMe).location.pathname;

    this.state = {
      items: [],
      loading: true,
      userType: new RegExp('/companies').test(pathname) ? 'company' : 'candidate',
      nextPage: 0,
      endPage: false,
      loadingMore: false
    };

    this.handleNextResults = debounce(this.handleNextResults.bind(this), 200);
  }

  handleNextResults() {
    const { loadingMore, endPage, loading } = this.state;
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location } = this.props;
    const userApproachedEndOfPage = checkIfUserApproachedEndOfPage(500);

    // Load next results if user:
    // 1. approached the end of the page,
    // 2. it's not already loading more or loading the first results
    // 3. it's not the last page
    // 4. it's not on a profile page (load on top)
    if (
      userApproachedEndOfPage &&
      !loadingMore &&
      !loading &&
      !endPage &&
      !location.pathname.includes('/u/')
    ) {
      this.getNextPageResults();
    }
  }

  async getNextPageResults() {
    const { nextPage, endPage, loadinMore, loading, userType } = this.state;

    if (!loadinMore && !loading && !endPage) {
      this.setState({ loadingMore: true });

      // @ts-expect-error TS(2345) FIXME: Argument of type '"text"' is not assignable to par... Remove this comment to see the full error message
      const { values } = await fetcher.getTestimonials(userType, nextPage, 'text', undefined, 12);

      this.setState(({ items }: $TSFixMe) => ({
        items: items.concat(values) || items,
        loadingMore: false,
        nextPage: nextPage + 1,
        endPage: values.length < 12
      }));
    }
  }

  async getTestimonials() {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location } = this.props;
    const { pathname } = location;

    const userType = new RegExp('/companies').test(pathname) ? 'company' : 'candidate';

    // @ts-expect-error TS(2345) FIXME: Argument of type '"candidate" | "company"' is not ... Remove this comment to see the full error message
    const { values } = await fetcher.getTestimonials(userType, 0, 'text', undefined, 12);

    this.setState({
      items: values || [],
      loading: false,
      userType,
      nextPage: 1,
      endPage: values.length < 10
    });
  }

  componentDidMount() {
    this.getTestimonials();
    window.addEventListener('scroll', this.handleNextResults);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleNextResults);
  }

  componentDidUpdate(prevProps: {}) {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { location } = this.props;
    const { pathname } = location;

    if (pathname !== (prevProps as $TSFixMe).location.pathname) this.getTestimonials();
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className, location } = this.props;
    const { items, loading, loadingMore, userType } = this.state;

    return (
      <div id="testimonials" className={className}>
        <div className="intro_wrapper">
          <div className="page_view center_alignment">
            <h1 className="heading">
              What {userType === 'company' ? 'companies' : 'people'} are saying
            </h1>
          </div>
        </div>
        <div className="page_view">
          {loading ? (
            <Loader className={ClassNames('medium')} />
          ) : (
            <TestimonialBlocklist
              items={items}
              className="testimonial_block_list"
              columns={3}
              testimonials
              testimonialType={userType}
              {...this.props}
            />
          )}
          {!loading && loadingMore && <Loader className={ClassNames('medium')} />}
        </div>
        <Seo
          title={`Testimonials - What ${
            userType === 'company' ? 'companies' : 'people'
          } are saying`}
          description={`${
            userType === 'company'
              ? 'Read what founders and hiring managers are saying about their experience using cord.'
              : 'Read what people are saying about their experience using cord to find work.'
          }`}
          path={location.pathname}
          contentType="website"
          image={userType === 'company' ? 'https://assets.co-hire.com/make-direct-hires.png' : ''}
        />
      </div>
    );
  }
}
