import React from 'react';
import ClassNames from 'classnames';

import { useDarkMode } from 'hooks/useDarkMode';

import ButtonPanel from 'views/positionprofile/buttonpanel';

import PositionProfile from 'components/positionprofile';

import { mockPositionProfile } from './positionsection.helpers';
import { PositionPreviewSection } from './positionsection.styled';

export default function PositionSection() {
  const darkMode = useDarkMode();

  return (
    <PositionPreviewSection className="position_preview_section page_view">
      <div className="page_view">
        <div className="body_wrapper">
          <h2 className="page_section_title">
            Direct.
            <br />
            Transparent.
            <br />
            Human.
          </h2>
          <h3 className="page_section_subtitle">
            On cord you can message people hiring directly, see when they were last active, how
            responsive they are and access transparent information that you wouldn't be able to find
            anywhere else.
          </h3>
        </div>
        <div className="position_wrapper">
          <PositionProfile
            // @ts-expect-error
            companyURL="#"
            personsURL="#"
            {...mockPositionProfile}
          />
          <ButtonPanel
            className={ClassNames('profile_view', {
              dark: darkMode
            })}
            primaryButton={{
              text: 'Send direct message',
              action: () => {
                return;
              },
              icon: 'icon_apply',
              iconPosition: 'right'
            }}
            companyURL="#"
            personsURL="#"
            userLoggedIn
            // @ts-expect-error
            personDetails={mockPositionProfile.associatedMemberDetails}
            companyName={mockPositionProfile.companyName}
            responseTime={mockPositionProfile.responseTime}
            companyType={mockPositionProfile.companyType}
            companyLogo={mockPositionProfile.companyLogo}
            companyPosition={mockPositionProfile.position}
            shortlistType={mockPositionProfile.shortlistType}
            shortlistID={mockPositionProfile.shortlistID}
            onShortlisted={() => {
              return;
            }}
            positionID={mockPositionProfile.id}
            showPersonOnly
          />
        </div>
      </div>
    </PositionPreviewSection>
  );
}
