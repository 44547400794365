import { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';

import { isArray } from 'utils/fn';
import { getImageDimensions, getResizedImageURL } from 'v2/services/image';

const imgURLValidator: Middleware<{}, any, Dispatch<AnyAction>> = () => next => action => {
  if (action.type === 'positions/setListings') {
    const payload = modifyListings(action);
    const newAction = {
      ...action,
      payload
    };
    return next(newAction);
  }

  return next(action);
};

const modifyListings = (action: any) => {
  return {
    ...action.payload,
    data: {
      ...action.payload.data,
      values: action.payload.data.values.map((listing: any) => {
        if (!isArray(listing.companyAccounts)) return listing;
        const companyAccounts = listing.companyAccounts.map((companyAccount: any) => {
          return {
            ...companyAccount,
            photoURL: getResizedImageURL(
              companyAccount.photoURL,
              getImageDimensions('no-crop-logo')
            )
          };
        });
        return { ...listing, companyAccounts };
      })
    }
  };
};

export default imgURLValidator;
