import React from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { pluralise } from 'utils';
import { isPartialMatch } from 'utils/object';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';
import LinkWithIcon from 'components/linkwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import CheckboxOptions from './CheckboxOption';

export default function MultiselectList() {
  const {
    id,
    isOpen,
    isExpanded,
    matchingOptions,
    filterOptionsGroups,
    selectedItems,
    visibleCheckboxesCount,
    showSearchInput,
    appearance,
    totalSelection,
    maxSelection,
    expandCollapseCheckboxes
  } = useExpandableFilter();

  const isExpandable =
    isOpen && Number(matchingOptions?.length) > visibleCheckboxesCount && !appearance;
  const totalSelected = totalSelection || 0;

  return (
    <>
      <ConditionalRender predicate={showSearchInput}>
        <CheckboxOptions isSelectionOnly />
      </ConditionalRender>
      {filterOptionsGroups?.map(({ title, options, dependency }, index) => {
        const show = !dependency || selectedItems?.some(isPartialMatch(dependency));

        return (
          <ConditionalRender key={`${id}_checkbox_options_${index}`} predicate={show}>
            <CheckboxOptions
              isExpandable={isExpandable}
              title={title}
              items={index ? options : matchingOptions}
            />
          </ConditionalRender>
        );
      })}
      {isOpen && maxSelection && (
        <TotalSelection>
          {totalSelected}/{maxSelection} {pluralise(totalSelected, 'option', 'options', false)}{' '}
          selected
        </TotalSelection>
      )}
      <ConditionalRender predicate={isExpandable}>
        <ShowMoreLink
          icon={isExpanded ? 'icon_simple_arrow_up' : 'icon_simple_arrow_down'}
          text={`Show ${isExpanded ? 'fewer' : 'more'}`}
          color="dark"
          action={expandCollapseCheckboxes}
          className={ClassNames('show_all', { show_less: isExpanded })}
        />
      </ConditionalRender>
    </>
  );
}

const TotalSelection = styled.div`
  padding: 0 0.5rem;
  color: var(--text-body-tertiary);
  font-size: var(--type-xs);
  font-weight: ${({ theme: { typography } }) => typography.bold};
`;

const ShowMoreLink = styled(LinkWithIcon)`
  gap: 0.75rem;
  align-items: center;
  padding: 0 0.75rem;

  .icon {
    font-size: var(--type-sm-md);
  }
`;
