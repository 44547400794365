import axios from 'axios';

import {
  CandidateUnlistedInterface,
  ListingApplicationBlockCriteriaInterface
} from '@cohiretech/common-types';

import { APIResponse } from 'types';
import { MatchInfoKey } from 'v2/services/company/ApplicationBlockCriteria';

const POSITION = process.env.REACT_APP_POSITION!;
const EXTERNAL_POSITION = process.env.REACT_APP_EXTERNAL_POSITION!;
const DUPLICATE_REPORT_SUBMITTED = 'duplicate report submitted';

export const addExternalPositionReport = async (listingID: number): Promise<APIResponse> => {
  try {
    await axios.post<{ data: CandidateUnlistedInterface[] }>(
      `${EXTERNAL_POSITION}/report/${listingID}`
    );

    return { status: 'success' };
  } catch (error: any) {
    const message = error?.response?.data?.message;

    // The same position may be flagged multiple times by the same candidate, as flagged positions
    // are saved in local storage. Treat the error as successful in order to show the flagged label
    if (message === DUPLICATE_REPORT_SUBMITTED) return { status: 'success' };
    return { status: 'failure', message };
  }
};

type ApplicationBlockStatus = {
  block: boolean;
  blockingCriteria: ListingApplicationBlockCriteriaInterface;
  matchInfo: { [key in MatchInfoKey]?: { match: false } };
};

export const checkBlockingCriteria = async (
  listingID: number,
  candidateID: number
): Promise<APIResponse<ApplicationBlockStatus>> => {
  try {
    const { data } = await axios.get<{ data: ApplicationBlockStatus }>(
      `${POSITION}/${listingID}/candidate/${candidateID}/criteria-check`
    );

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
