import React from 'react';
import ClassNames from 'classnames';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';

import BulletList, { Bullet } from 'components/bulletlist';
import BadgeWithIcon from 'components/badgewithicon';
import ResponsiveImage from 'components/responsiveimage';
import ReverseContainerOrder from 'components/reversecontainerorder';

import { HeaderContent } from './pageheader';

type SourcingSectionProps = {
  src: { desktop: string; mobile: string };
  noOfActiveUsers: string;
};

const SourcingSection = ({ src, noOfActiveUsers }: SourcingSectionProps) => {
  const darkMode = useDarkMode();
  const isMobile = useMedia([mediaQueries.mobile]);
  const isTablet = useMedia([mediaQueries.tablet]);
  return (
    <div className="pipeline_chapter">
      <SourcingSectionHeader isTablet />
      <ReverseContainerOrder predicate={isMobile}>
        <div className="left_cont image_resp resp_cont">
          <ResponsiveImage
            src={src.desktop}
            alt="Candidate profile sourced with No bias & Diversity filters"
            smallSrc={src.mobile}
            className="sourcing_img"
            width="100%"
          />
        </div>
        <div className="right_cont resp_cont">
          <SourcingSectionHeader />
          <BulletList>
            <Bullet title="Streams of pre-verified active people ">
              Be the first to know when someone starts looking for work with real-time Slack and
              email notifications.
            </Bullet>
            <Bullet title="Diversity & No bias filters">
              Source without bias and diversity filters help you find people from underrepresented
              backgrounds.
            </Bullet>
          </BulletList>
          <div className={ClassNames('multiple_badges', { dark_badges: darkMode })}>
            <BadgeWithIcon
              icon="icon_profile"
              title={noOfActiveUsers || '10,690'}
              text="people active right now"
            />
            <BadgeWithIcon
              icon="icon_arrow_trending_up"
              title={isTablet ? '2x more' : '2x more diverse'}
              text={isTablet ? 'diverse conversations' : 'conversations with anti-bias tool'}
            />
          </div>
        </div>
      </ReverseContainerOrder>
    </div>
  );
};

const SourcingSectionHeader = ({ isTablet }: { isTablet?: true }) => (
  <HeaderContent title="Source active people" isTablet={isTablet}>
    Every person on cord is pre-verified, experienced and actively looking for work. Hundreds of
    candidates join cord every day.
  </HeaderContent>
);

export default SourcingSection;
