/* eslint-disable no-console */
import { ScriptHTMLAttributes, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useToggler } from 'store';

type EmbedScriptProps = ScriptHTMLAttributes<HTMLScriptElement> & {
  predicate?: boolean;
};

const EmbedScript = (props: EmbedScriptProps) => {
  const { id, predicate = false } = props;
  const { value, toggle } = useToggler(`company.embeds.${toSnakeCase(id || '')}`);

  useEffect(() => {
    if (predicate && !value) {
      toggle(true);
    } else if (!predicate && value) {
      toggle(false);
    }
  }, [predicate, value]);

  if (!props.id) {
    console.warn('No id provided to EmbedScript');
    return null;
  }

  switch (value) {
    case true:
      return (
        <Helmet>
          <script {...props} />
        </Helmet>
      );

    case false:
      // There are cases where we want to overwrite the scripts already in the DOM
      return (
        <Helmet>
          <script id={id} type={props.type} src="" />
        </Helmet>
      );
    default:
      return null;
  }
};

const toSnakeCase = (str: string) => str.replace(/-/g, '_').toLowerCase();

export default EmbedScript;
