import React from 'react';
import ClassNames from 'classnames';

import Loader from 'components/loader';
import Button from 'components/button';
import ErrorMessage from 'components/errormessage';
import CandidateSkillsForm from 'components/candidateskillsform';

import CandidateQualityScoreForm, { ReactivateVerifyData } from '../candidatequalityscoreform';

type VerifyButtonsProps = {
  secondarySkills: string[];
};

type State = $TSFixMe;

export default class VerifyButtons extends React.Component<VerifyButtonsProps, State> {
  constructor(props: VerifyButtonsProps) {
    super(props);
    this.state = {
      verifying: false,
      savingInfo: false,
      verified: false,
      accepted: false,
      rejected: false,
      waitlisted: false,
      showForm: false
    };
  }

  async verifyUser(e: $TSFixMe, verify: boolean, data?: ReactivateVerifyData) {
    // @ts-expect-error TS(2339) FIXME: Property 'verifyUserAccount' does not exist on typ... Remove this comment to see the full error message
    const { verifyUserAccount, rejectUserAccount, removeItem } = this.props;

    e?.preventDefault();

    this.setState({ verifying: true, savingInfo: true });

    if (verify) {
      await verifyUserAccount(data);

      this.setState({ verifying: false, verified: true, savingInfo: false });
    } else {
      await rejectUserAccount();

      this.setState({ verifying: false, rejected: true, savingInfo: false });
    }

    setTimeout(() => removeItem(), 2000);
  }

  acceptUser(e: $TSFixMe) {
    e.preventDefault();

    this.setState({ showForm: true });
  }

  async addUserInfo(data: $TSFixMe) {
    // @ts-expect-error TS(2339) FIXME: Property 'addUserInfo' does not exist on type 'Rea... Remove this comment to see the full error message
    const { addUserInfo, removeItem } = this.props;

    this.setState({ savingInfo: true });

    await addUserInfo(data);

    this.setState({ accepted: true, showForm: false, savingInfo: false });

    setTimeout(() => removeItem(), 2000);
  }

  async markReactivationReviewComplete() {
    // @ts-expect-error TS(2339) FIXME: Property 'addAdminProfileNote' does not exist on ty... Remove this comment to see the full error message
    const { completeReactivationReview, removeItem } = this.props;

    this.setState({ savingInfo: true });

    await completeReactivationReview();

    this.setState({ savingInfo: false });

    setTimeout(() => removeItem(), 2000);
  }

  async waitlistUser(e: $TSFixMe) {
    // @ts-expect-error TS(2339) FIXME: Property 'waitlistUserAccount' does not exist on t... Remove this comment to see the full error message
    const { waitlistUserAccount, removeItem } = this.props;

    e.preventDefault();

    this.setState({ verifying: true });

    await waitlistUserAccount();

    this.setState({ verifying: false, waitlisted: true });

    setTimeout(() => removeItem(), 2000);
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      user,
      // @ts-expect-error TS(2339) FIXME: Property 'errorMessage' does not exist on type 'Re... Remove this comment to see the full error message
      errorMessage,
      // @ts-expect-error TS(2339) FIXME: Property 'primarySkills' does not exist on type 'R... Remove this comment to see the full error message
      primarySkills,
      secondarySkills,
      // @ts-expect-error TS(2339) FIXME: Property 'seniorities' does not exist on type 'Rea... Remove this comment to see the full error message
      seniorities,
      // @ts-expect-error TS(2339) FIXME: Property 'jobTitles' does not exist on type 'Reado... Remove this comment to see the full error message
      jobTitles,
      // @ts-expect-error TS(2339) FIXME: Property 'waitlistUserAccount' does not exist on t... Remove this comment to see the full error message
      waitlistUserAccount,
      // @ts-expect-error TS(2339) FIXME: Property 'verifyUserAccount' does not exist on typ... Remove this comment to see the full error message
      verifyUserAccount,
      // @ts-expect-error TS(2339) FIXME: Property 'rejectUserAccount' does not exist on typ... Remove this comment to see the full error message
      rejectUserAccount,
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339) FIXME: Property 'waitlisted' does not exist on type 'Read... Remove this comment to see the full error message
      waitlisted,
      // @ts-expect-error TS(2339) FIXME: Property 'rejected' does not exist on type 'Readon... Remove this comment to see the full error message
      rejected,
      // @ts-expect-error TS(2339) FIXME: Property 'accepted' does not exist on type 'Readon... Remove this comment to see the full error message
      accepted,
      // @ts-expect-error TS(2339) FIXME: Property 'reactivated' does not exist on type 'Readon... Remove this comment to see the full error message
      reactivated,
      // @ts-expect-error TS(2339) FIXME: Property 'pendingRebuild' does not exist on type 'Readon... Remove this comment to see the full error message
      pendingRebuild
    } = this.props;
    const { verifying, showForm, savingInfo } = this.state;

    if (this.state.verified) {
      return (
        <div className={ClassNames('account_verification', className)}>
          <div className="user_verified_message">The account is now verified.</div>
        </div>
      );
    } else if (this.state.accepted) {
      return (
        <div className={ClassNames('account_verification', className)}>
          <div className="user_verified_message">The account has been accepted.</div>
        </div>
      );
    } else if (this.state.rejected) {
      return (
        <div className={ClassNames('account_verification', className)}>
          <div className="user_verified_message">The account has been rejected.</div>
        </div>
      );
    } else if (this.state.waitlisted) {
      return (
        <div className={ClassNames('account_verification', className)}>
          <div className="user_verified_message">The account has been waitlisted.</div>
        </div>
      );
    } else if (showForm) {
      return (
        <div className={ClassNames('account_verification', className)}>
          {reactivated ? (
            <CandidateQualityScoreForm onSubmit={data => this.verifyUser(undefined, true, data)} />
          ) : (
            <CandidateSkillsForm
              // @ts-expect-error TS(2322) FIXME: Type '{ jobTitleOptions: any; onSubmit: (data: any... Remove this comment to see the full error message
              onSubmit={(data: $TSFixMe) => this.addUserInfo(data)}
              primarySkills={primarySkills}
              secondarySkills={secondarySkills}
              loading={savingInfo}
              jobTitles={jobTitles}
              seniorities={seniorities}
            />
          )}
        </div>
      );
    } else if (pendingRebuild && user === 'candidate') {
      return (
        <div className={ClassNames('account_verification', className)}>
          <Button
            text="Mark as complete"
            action={() => this.markReactivationReviewComplete()}
            loading={savingInfo}
          />
        </div>
      );
    } else if (user === 'candidate') {
      return (
        <div className={ClassNames('account_verification', className)}>
          {errorMessage && <ErrorMessage align="center">{errorMessage}</ErrorMessage>}
          {verifying ? (
            <Loader className={ClassNames('verify_user', 'small')} />
          ) : (
            <div className="buttons_wrapper">
              {rejectUserAccount && !rejected && (
                <Button
                  text="Decline account"
                  buttonStyle="fill_red"
                  className={ClassNames('reject', 'verification_button')}
                  action={e => this.verifyUser(e, false)}
                />
              )}
              {waitlistUserAccount && !waitlisted && !reactivated && (
                <Button
                  text="Waitlist account"
                  buttonStyle="fill_grey"
                  className={ClassNames('waitlist', 'verification_button')}
                  action={e => this.waitlistUser(e)}
                />
              )}
              {(!accepted || reactivated) && (
                <Button
                  text="Verify account"
                  className={ClassNames('accept', 'verification_button')}
                  action={e => this.acceptUser(e)}
                />
              )}
              {verifyUserAccount && accepted && !reactivated && (
                <Button
                  text="Go Live"
                  className={ClassNames('accept', 'verification_button')}
                  action={e => this.verifyUser(e, true)}
                />
              )}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={ClassNames('account_verification', className)}>
          {errorMessage && <ErrorMessage align="center">{errorMessage}</ErrorMessage>}
          {verifying ? (
            <Loader className={ClassNames('verify_user', 'small')} />
          ) : (
            <div className="buttons_wrapper">
              {rejectUserAccount && !rejected && (
                <Button
                  text="Reject user account"
                  buttonStyle="fill_red"
                  className={ClassNames('reject', 'verification_button')}
                  action={e => this.verifyUser(e, false)}
                />
              )}
              <Button
                text="Verify user account"
                className={ClassNames('accept', 'verification_button')}
                action={e => this.verifyUser(e, true)}
              />
            </div>
          )}
        </div>
      );
    }
  }
}
