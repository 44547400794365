import { useEffect, useLayoutEffect, useState } from 'react';

type LockedScrollReturn = [boolean, (locked: boolean) => void];

/**
 * Hook that locks the scroll on mount and unlocks it on unmount
 * @param initLocked should the scroll be locked on mount
 * @returns [locked, setLocked]
 */
export default function useLockedScroll(initLocked = false): LockedScrollReturn {
  const [locked, setLocked] = useState(initLocked);

  useLayoutEffect(() => {
    if (!locked) {
      return;
    }
    const { overflow } = document.body.style;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = overflow;
    };
  }, [locked]);

  useEffect(() => {
    if (locked !== initLocked) {
      setLocked(initLocked);
    }
  }, [initLocked]);

  return [locked, setLocked];
}
