import { CandidateTracking } from '@cohiretech/common-types';

import { getLocalStorageItem } from 'cookieManager';
import { trackCandidateAction } from 'fetcher';
import { CandidateBubbleName } from 'store/ui';

import { BadgeProps } from 'components/badge';

type NavLink = {
  key: 'messages' | 'insights' | 'techhub' | 'tracker';
  title: string;
  icon: string;
  bubbleName?: CandidateBubbleName;
  badge?: string;
  badgeType?: BadgeProps['type'];
  onClick?: () => void;
};

const CANDIDATE_NAV_LINKS: NavLink[] = [
  {
    key: 'messages',
    icon: 'message',
    title: 'Messages'
  },
  {
    key: 'tracker',
    icon: 'board',
    title: 'Application Tracker',
    bubbleName: 'tracker',
    badge: 'beta',
    badgeType: 'beta_feature'
  },
  {
    key: 'insights',
    icon: 'insights',
    title: 'Insights',
    bubbleName: 'insights',
    onClick: () => trackCandidateAction(CandidateTracking.ViewSalaryInsights)
  }
];

export const getCandidateNavLinks = ({
  accepted,
  hasMessages
}: {
  accepted?: boolean;
  hasMessages: boolean;
}): NavLink[] =>
  CANDIDATE_NAV_LINKS.filter(({ key }) => {
    switch (key) {
      case 'messages':
        return getLocalStorageItem('message_queued')?.message || hasMessages || accepted;
      default:
        return true;
    }
  });
