import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CandidateSavedSearchInterface } from '@cohiretech/common-types';

import { isSuccess } from 'v2/services/fetchers/apiTools';

import {
  fetchCandidateFilterOptions,
  fetchCandidateSavedSearches,
  fetchCandidateSearchOptions,
  saveCandidateSavedSearch
} from './candidateSearch.thunks';
import {
  CandidateSavedSearch,
  CandidateSearchState,
  ToggleSaveSearchPopupParams,
  findSavedSearchById
} from './candidateSearch.helpers';
import { parseCategorisedOptions } from './helpers/parseSearchOptions';

const initialState = {} as CandidateSearchState;

const candidateSearchSlice = createSlice({
  name: 'candidateSearch',
  initialState,
  reducers: {
    setCurrentSearch: (state, action: PayloadAction<CandidateSavedSearch | undefined>) => {
      state.currentSearch = action.payload;
    },
    toggleSaveSearchPopup: (
      state,
      action: PayloadAction<ToggleSaveSearchPopupParams | undefined>
    ) => {
      const { saveSearchPopup, currentSearch } = action.payload || {};

      state.saveSearchPopup = saveSearchPopup;
      state.currentSearch = { ...state.currentSearch, ...currentSearch };
    },
    setSavedSearchToDelete: (
      state,
      action: PayloadAction<CandidateSavedSearchInterface | undefined>
    ) => {
      state.savedSearchToDelete = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCandidateFilterOptions.fulfilled, (state, action) => {
        state.filterOptions = action.payload;
      })
      .addCase(fetchCandidateSearchOptions.fulfilled, (state, action) => {
        const { payload } = action;

        if (isSuccess(payload)) state.searchOptions = parseCategorisedOptions(payload.data);
      })
      .addCase(fetchCandidateSavedSearches.fulfilled, (state, action) => {
        state.savedSearches = action.payload;
      })
      .addCase(saveCandidateSavedSearch.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const { id, searchName, searchParam, notificationSettings } = meta.arg;

        if (isSuccess(payload)) {
          const { index, value } = findSavedSearchById(state.savedSearches, id);

          state.savedSearches[index] = {
            ...value,
            searchName: searchName || value.searchName,
            searchParam: searchParam ? { params: searchParam } : value.searchParam,
            ...notificationSettings
          };
        }
      });
  }
});

export const { setCurrentSearch, toggleSaveSearchPopup, setSavedSearchToDelete } =
  candidateSearchSlice.actions;
export default candidateSearchSlice.reducer;
