import styled, { DefaultTheme, css } from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

import typography from 'css/base/_typography.module.scss';

import { Props } from '../MultiStageToggle.helpers';

type StyleProps = Pick<Props, 'size' | 'color' | 'width'>;

const getSizeStyles = (size: StyleProps['size']) => {
  switch (size) {
    case 'small':
      return css`
        padding: 8px 12px;
        font-size: 16px;
        box-sizing: border-box;
      `;
    case 'large':
      return css`
        padding: 12px 16px;
        font-size: 18px;
      `;
    default:
      return css`
        padding: 12px 16px;
        font-size: 16px;
        box-sizing: border-box;
      `;
  }
};

const getColourStyling = (
  color: StyleProps['color'],
  darkMode: boolean,
  colors: DefaultTheme['colours']
) => {
  if (color === 'blue') {
    return css`
      border: 2px solid ${colors.primaryColour};
      color: ${colors.primaryColour};

      &.active {
        background-color: ${colors.primaryColour};
      }
    `;
  }

  if (color === 'grey') {
    return css`
      border: 2px solid ${colors.inputsColour};
      color: ${colors.inputsColour};

      &.active {
        background-color: ${colors.inputsColour};
      }
    `;
  }

  if (darkMode) {
    return css`
      border: 2px solid ${colors.lightFontColour};

      &.active {
        background-color: ${colors.lightFontColour};
        color: ${colors.darkBgColour};
      }
    `;
  }

  return css`
    border: 2px solid ${colors.fontColour};

    &.active {
      background-color: ${colors.fontColour};
      color: white;
    }
  `;
};

const getToggleStyling = (
  { size, color, width }: StyleProps,
  { darkMode, colours, typography, media }: DefaultTheme
) => {
  return css`
    &.multi_stage_toggle_item {
      font-weight: ${({ theme: { typography } }) => typography.regular};
      text-align: center;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      line-height: 1;
      width: ${width};
      ${getSizeStyles(size)}
      ${getColourStyling(color, darkMode, colours)}

      ${media.mobile`
        padding: 8px 12px;
        box-sizing: border-box;
      `}

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:not(:last-child) {
        border-right: 0;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &.active {
        font-weight: ${typography.semibold};
      }
    }
  `;
};

export const ToggleLink = styled(Link)<StyleProps>`
  ${({ size, color, width, theme }) => getToggleStyling({ size, color, width }, theme)}
`;

export const ToggleText = styled.span<StyleProps>`
  ${({ size, color, width, theme }) => getToggleStyling({ size, color, width }, theme)}
`;

export const TogglePills = styled.span`
  padding: 12px 16px;
  font-size: ${typography.smallheader};
  border-radius: 25px;

  &.basic {
    padding: 8px 16px;
  }

  > .icon {
    font-size: ${typography.small};
  }

  ${({ theme: { darkMode, colours, typography } }) => css`
    &:hover {
      background-color: ${darkMode ? colours.darkerBgColour : colours.lightBgColour};

      &.basic {
        background-color: ${darkMode ? colours.darkBgColour : colours.lightBgColour};
      }
    }

    &.active {
      background-color: var(--background-accent-secondary-base);
      color: var(--text-body-invert);
      font-weight: ${typography.semibold};

      > .icon {
        font-weight: ${typography.black};
      }

      &.basic {
        background-color: ${darkMode ? colours.darkBgColour : 'white'};
        color: ${darkMode ? colours.lightFontColour : colours.fontColour};
        box-shadow: 0px 0px 20px ${darkMode ? colours.darkDropshadow : colours.dropshadow};
      }
    }

    &:not(.active) {
      cursor: pointer;
      color: ${darkMode ? 'rgba(228, 230, 235, 0.4)' : 'rgba(49, 71, 95, 0.4)'};

      &:hover {
        color: ${!darkMode && 'rgba(49, 71, 95, 0.6)'};
      }
    }
  `}
`;
