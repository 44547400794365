import React, { ReactNode } from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';

import { pluck } from 'utils/object';
import media from 'styles/media';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import typography from 'css/base/_typography.module.scss';

interface IProps {
  title?: string;
  children?: ReactNode;
  className?: string;
  iconSize?: number;
  iconCircleColour?: 'light' | 'dark';
}

const BulletList = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <BulletedList className={ClassNames(className, 'bullet_list')}>{children}</BulletedList>
);

export const Bullet = ({ title, children, className, iconSize = 22, iconCircleColour }: IProps) => (
  <BulletItem className={ClassNames(className, 'bullet_item')}>
    <BulletIcon
      className="icon icon_bullet blue_circle"
      size={iconSize}
      bgColour={iconCircleColour}
    />
    <div className="bullet_content">
      <h3>{title}</h3>
      <ConditionalRender predicate={children}>
        <p>{children}</p>
      </ConditionalRender>
    </div>
  </BulletItem>
);

export default BulletList;

const BulletedList = styled.ul`
  padding-right: 40px;
  margin-bottom: 6px;

  ${media.mobile`
    padding-right: 15px;
  `}

  h3 {
    margin: 0;
    font-weight: ${({ theme: { typography } }) => typography.semibold};
  }
`;

const BulletItem = styled.li`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;

  .bullet_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    ${media.tabletLandscape`
      > h3, p {
        font-size: ${typography.normal};
      }
    `}
  }
`;

const BulletIcon = styled.span<{ size: IProps['iconSize']; bgColour: IProps['iconCircleColour'] }>`
  padding-right: 16px;

  &.icon_bullet.blue_circle {
    font-size: ${pluck('size')}px;

    &:before {
      background: ${({ bgColour, theme: { colours, fn } }) =>
        fn.opaqueColour(colours.primaryColour, bgColour === 'light' ? 0.1 : 0.3)};
    }
  }
`;
