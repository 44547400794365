import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { mobile } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';

import Dropdown, { ListItem } from 'components/dropdown';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

type Props = {
  icon: string;
  iconFontSize?: string;
  width?: string;
  height?: string;
  lineHeight?: string;
  className?: string;
  dropdownID: string;
  list: ListItem[];
  selected?: string | boolean | number;
  onSelect: (item: any) => void;
  dropdownInlineStyle?: {
    [key: string]: string | number;
  };
  tooltip?: React.ReactNode;
  stopEventPropagation?: boolean;
};

export default function IconDropdown({
  icon,
  width = '35px',
  height = '35px',
  lineHeight = '35px',
  iconFontSize,
  className,
  dropdownID,
  tooltip,
  dropdownInlineStyle,
  children,
  list,
  selected,
  onSelect,
  stopEventPropagation
}: PropsWithChildren<Props>) {
  const darkMode = useDarkMode();

  return (
    <IconDropdownWrapper
      className={ClassNames('dropdown_icon_wrapper', className, { dark: darkMode })}
      width={width}
      height={height}
      style={{ width, height }}
    >
      <Dropdown
        id={dropdownID}
        className="dropdown_icon"
        selected={selected}
        list={list}
        onSelect={(item: object) => onSelect(item)}
        inlineStyle={dropdownInlineStyle}
        stopEventPropagation={stopEventPropagation}
      />
      <Icon className={icon} style={{ lineHeight, width, height, fontSize: iconFontSize }} />
      {tooltip}
      {children}
    </IconDropdownWrapper>
  );
}

const IconDropdownWrapper = styled.div<Pick<Props, 'width' | 'height'>>`
  position: relative;

  &:hover > .tooltip_wrapper {
    display: block;
    opacity: 1;
  }

  .dropdown_icon {
    position: absolute;
    width: ${props => props.width};
    height: ${props => props.height};
    margin-top: -2px;

    @media only screen and (max-width: ${mobile}) {
      z-index: 102;
    }

    .dropdown_selector {
      color: transparent;
      background-color: transparent;
      border: 0;

      &:focus-visible,
      &:focus-visible + .dropdown_list {
        border: 0 !important;
      }

      .icon_simple_arrow_down,
      > span {
        display: none;
      }
    }

    &.dark {
      .dropdown_selector {
        background-color: transparent;
      }

      .dropdown_list {
        background-color: ${colours.darkBgColour};
        box-shadow: 0 0 15px -2px ${colours.darkDropshadow};
        left: unset;
        border: 0;

        &:before {
          background-color: ${colours.darkBgColour};
          box-shadow: -5px -4px 15px -5px ${colours.darkDropshadow};
        }

        .item:hover {
          background-color: ${colours.darkerBgColour};
        }

        .item.disabled {
          color: ${colours.transparentLightFontColour};
        }
      }
    }

    .dropdown_list {
      box-shadow: 0 0 20px -2px ${colours.dropshadow};
      max-height: inherit;
      width: 250px;
      right: -110px;
      overflow: visible;
      border: 0;
      font-size: ${typography.normal};

      &:before {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: white;
        top: -10px;
        left: 50%;
        margin-left: -10px;
        z-index: -1;
        box-shadow: -5px -4px 15px -5px ${colours.darkDropshadow};
      }

      @media only screen and (max-width: ${mobile}) {
        position: fixed;
        top: 50%;
        left: 50%;
        bottom: inherit;
        transform: translate(-50%, -50%);
        width: 80%;
        z-index: 101;
        box-shadow: 0 0 0 100vh var(--background-popup-overlay);

        &:before {
          z-index: 102;
          bottom: unset;
          left: unset;
          top: -10px;
          right: -10px;
          margin: 0;
          border-radius: 50%;
          padding: 6px;
          background-color: ${colours.primaryColour};
          color: white;
          content: '+';
          font-size: 36px;
          line-height: 20px;
          text-align: center;
        }
      }

      .item {
        text-align: center;
        padding: 15px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.disabled {
          color: ${colours.semiTransparentFontColour};
        }

        @media only screen and (max-width: ${mobile}) {
          font-size: ${typography.smallheader};
          padding: 20px 15px;
        }

        .lighter {
          opacity: 0.7;
        }

        &.selected,
        &.selected .item_text {
          font-weight: ${typography.black};
          background-color: transparent;
        }

        .item_text {
          display: inline;
        }

        &:hover {
          background-color: ${colours.lightBgColour};
        }

        &:first-child {
          border-top: 0;
        }
      }
    }
  }
`;

const Icon = styled.span`
  display: inline-block;
  text-align: center;
`;
