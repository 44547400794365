import { ConversationInCandidateItem } from 'types';
import { isEmpty } from 'utils';

export const getLatestTime = (time1: Date | string = '', time2: Date | string = '') =>
  new Date(Math.max(new Date(time1).getTime(), new Date(time2).getTime()));

export const parseCandidateConversations = (conversations: ConversationInCandidateItem[] = []) => {
  if (!isEmpty(conversations)) {
    return conversations.map(({ name, company_date, candidate_date }) => ({
      label: name,
      date: getLatestTime(company_date, candidate_date)
    }));
  }
  return [];
};
