import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Frequency, OperatingCountry } from '@cohiretech/common-types';

import { StandardPlan } from 'types';
import { PRICING_PLAN_OPTIONS } from 'consts';
import { getDisplayPrice } from 'v2/services/tools/pricing';

import Loader from 'components/loader';
import Tabs from 'components/tabs';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import PricingPlanCard from 'v2/components/ui/organisms/PricingPlansSection/components/PricingPlanCard';
import { GradientText, GradientTextProps, PricingPlans } from 'v2/components/ui/styles';

import {
  useCardPaymentPopup,
  useSelectedPlan,
  useStandardTiers
} from './PricingPlansSection.hooks';
import {
  FREQUENCY_TABS,
  PaymentPopup,
  PricingPlansSectionProps
} from './PricingPlansSection.helpers';
import PricingV5PlansControl from './components/PricingV5PlansControl';
import CardPaymentPopup from './components/CardPaymentPopup';
import PayPerHireCard from './components/PayPerHireCard';

export default function PricingPlansSection(props: PricingPlansSectionProps) {
  const { pricingVer, pricingCountry, currentPlanDetails, isPayPerHire, hidePrice } = props;

  const [defaultPricingOptions, setDefaultPricingOptions] = useState(
    PRICING_PLAN_OPTIONS[pricingVer][pricingCountry]
  );

  const { defaultPlan, label: standardLabel } = defaultPricingOptions.standard;
  const [frequency, setFrequency] = useState(
    currentPlanDetails?.billingFrequency || Frequency.monthly
  );

  const { isExistingUser, selectedPlan, setSelectedPlan } = useSelectedPlan(props, {
    plan: defaultPlan as StandardPlan,
    frequency,
    pricingCountry
  });

  const [selectedPricing, setSelectedPricing] = useState({
    plan: selectedPlan,
    frequency,
    pricingCountry
  });
  const isUSPricingV5 = pricingVer === 5 && pricingCountry === OperatingCountry.US;

  const { standardTiers, updateEnquiredStatus } = useStandardTiers(props, selectedPricing);
  const { paymentPopup, togglePaymentPopup } = useCardPaymentPopup();

  const selectedTier = standardTiers?.[frequency][selectedPlan];

  useEffect(() => {
    setDefaultPricingOptions(PRICING_PLAN_OPTIONS[pricingVer][pricingCountry]);
    setSelectedPricing({ plan: selectedPlan, frequency, pricingCountry });
  }, [pricingVer, pricingCountry]);

  const getSelectedPlanInfo = () => {
    const { price, hasDiscount, discountedPrice, urlPath = '' } = selectedTier || {};

    return {
      label: standardLabel,
      price: getDisplayPrice(pricingCountry, hasDiscount ? discountedPrice : price),
      urlPath
    };
  };

  if (!standardTiers) {
    return (
      <PlansSection>
        <Loader className="medium" />
      </PlansSection>
    );
  }

  return (
    <PlansSection>
      <ConditionalRender
        predicate={!isPayPerHire}
        fallback={<PayPerHireCard hidePrice={hidePrice} />}
      >
        <ConditionalRender predicate={!hidePrice && !isUSPricingV5}>
          <PricingPlansControls
            className="pricing_plans_controls"
            gradientTextColour="cordGradient"
          >
            <PricingV5PlansControl
              show={!isExistingUser}
              standardTiers={standardTiers?.[frequency]}
              selectedPlan={selectedPlan}
              handleSelect={plan => setSelectedPlan(plan)}
            />
            <Tabs<Frequency>
              current={frequency}
              tabs={FREQUENCY_TABS}
              onChange={({ value }) => setFrequency(value)}
              appearance="pill"
              size="small"
              inverted
            />
          </PricingPlansControls>
        </ConditionalRender>
        <PricingPlans>
          {Object.entries(defaultPricingOptions).map(([planType, pricingOption]) => {
            const selected = planType === 'enterprise' ? pricingOption.defaultPlan : selectedPlan;

            return (
              <PricingPlanCard
                key={`${planType}-${pricingCountry}`}
                pricingOption={pricingOption}
                selectedPricing={{ ...selectedPricing, plan: selected }}
                currentPlanDetails={currentPlanDetails}
                selectedTier={planType === 'standard' ? selectedTier : undefined}
                updateEnquiredStatus={updateEnquiredStatus}
                showPaymentPopup={togglePaymentPopup}
                hidePrice={hidePrice}
              />
            );
          })}
        </PricingPlans>
        <ConditionalRender predicate={paymentPopup.show}>
          <CardPaymentPopup
            plan={{
              planInfo: getSelectedPlanInfo(),
              cycle: frequency,
              pricingType: pricingCountry
            }}
            onClose={togglePaymentPopup}
            {...(paymentPopup as PaymentPopup)}
          />
        </ConditionalRender>
      </ConditionalRender>
    </PlansSection>
  );
}

const PlansSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const LightPrimaryTabsStyling = css<GradientTextProps>`
  padding: 0;
  background: var(--background-input-brand);
  align-items: stretch;
  box-shadow: none;

  .tab_item {
    padding: 0.75rem 1rem;

    &.active {
      background: var(--background-block-primary);
      box-shadow: 0 0 1.5rem 0 var(--shadow-soft);
    }

    .frequency_label {
      ${GradientText}
    }

    &:not(.active) .frequency_label {
      opacity: 0.6;
    }
  }
`;

const PricingPlansControls = styled.div<GradientTextProps>`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;

  ${({ theme: { media } }) => media.mobile`
    flex-direction: column-reverse;
    align-items: center;
    gap: 1.5rem;
  `}

  .tabs_wrapper {
    ${LightPrimaryTabsStyling}
  }
`;
