/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-danger */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import ClassNames from 'classnames';

import {
  ALLOWED_CANDIDATE_DESCRIPTION_TAGS,
  ALLOWED_CANDIDATE_PASTE_TAGS,
  ALLOWED_COMPANY_PASTE_TAGS,
  JOB_DESCRIPTION_GUIDE
} from 'consts';
import { getIsDarkMode_FIXME } from 'cookieManager';
import { AnyFunction } from 'types';
import { getJobDescriptionLengthTooltip, openArticleAndTrack } from 'utils/companyUtils';

import AddHyperlink from 'components/addhyperlink';
import Tooltip from 'components/tooltip';
import CharacterCountTooltip from 'components/charactercountertooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import TextEnhancer from 'v2/components/TextEnhancer';

import './style.scss';

type RichEditorProps = {
  id: string;
  bold?: boolean;
  italics?: boolean;
  orderedList?: boolean;
  unorderedList?: boolean;
  link?: boolean;
  underline?: boolean;
  code?: boolean;
  quote?: boolean;
  user: string;
  placeholder?: string;
  sanitisePaste?: boolean;
  value?: string | null;
  showCharacterCount?: boolean;
  enhance?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
};

type RichEditorState = {
  editorValue: string;
  activeStyles: string[];
  shouldAddHyperLink: boolean;
  linkSelected: boolean;
  linkURL: string;
  linkText: string;
};

export interface RichEditorHandles {
  getPlainTextValue: () => string;
  getValue: () => string;
  getIsTextAIEnhanced: () => boolean;
}

const RichEditorWithTextEnhancerContext = forwardRef<RichEditorHandles, RichEditorProps>(
  (props, ref: any) => {
    return (
      <TextEnhancer.Provider>
        <RichEditor {...props} ref={ref} />
      </TextEnhancer.Provider>
    );
  }
);

const RichEditor = forwardRef<RichEditorHandles, RichEditorProps>(
  (
    {
      id,
      bold,
      italics,
      orderedList,
      unorderedList,
      link,
      underline,
      code,
      quote,
      user,
      placeholder,
      sanitisePaste = true,
      value = '',
      showCharacterCount,
      enhance,
      onChange,
      onBlur
    }: RichEditorProps,
    ref
  ) => {
    const [editorState, setEditorState] = useState<RichEditorState>({
      editorValue: value || '',
      activeStyles: [],
      shouldAddHyperLink: false,
      linkSelected: false,
      linkURL: '',
      linkText: ''
    });
    const { activeStyles, shouldAddHyperLink, linkURL, linkText, linkSelected, editorValue } =
      editorState;
    const textBox = useRef<HTMLDivElement | null>(null);
    const darkMode = getIsDarkMode_FIXME();

    const { setTextEditor, highlightMarked, isEnhanced } = TextEnhancer.useTextEnhancer();

    const getIsTextAIEnhanced = () => {
      return Boolean(isEnhanced);
    };

    useImperativeHandle(ref, () => ({
      getValue,
      getPlainTextValue,
      getIsTextAIEnhanced
    }));

    useEffect(() => {
      if (textBox.current) {
        setTextEditor(textBox);
      }
    }, [textBox]);

    const formatText = (command: string, value?: string, activateCommand?: string) => {
      const activeStyles = [...editorState.activeStyles];
      const textBoxRef = textBox.current;
      const index = activeStyles.findIndex(style => style === command || style === activateCommand);

      if (index > -1) activeStyles.splice(index, 1);
      else activeStyles.push(activateCommand || command);

      setEditorState(prevState => ({ ...prevState, activeStyles }));

      document.execCommand(command, false, value);

      if (textBoxRef) textBoxRef.focus();
    };

    const addHyperLink = () => {
      const sText = window.getSelection() || document.getSelection();

      if (sText?.anchorNode && sText.rangeCount) {
        const selectedElement = sText.anchorNode.parentNode;

        if ((selectedElement as any).tagName === 'A') {
          (selectedElement as any).id = 'newHyperlink';
          const aElement = document.getElementById('newHyperlink');
          if (!aElement) return;

          setEditorState(prevState => ({
            ...prevState,
            addHyperLink: true,
            shouldAddHyperLink: true,
            linkText: aElement.innerText,
            linkURL: (aElement as any).href
          }));
        } else {
          formatText('createLink', ' ');
          if (!sText.anchorNode.parentElement) return;
          sText.anchorNode.parentElement.id = 'newHyperlink';

          setEditorState(prevState => ({
            ...prevState,
            shouldAddHyperLink: true,
            linkText: sText.toString()
          }));
        }
      }
    };

    const addBlockQuote = () => {
      const sText = window.getSelection() || document.getSelection();

      if (sText?.anchorNode && sText.rangeCount) {
        const selectedElement = sText.anchorNode.parentNode;

        if ((selectedElement as any).tagName === 'BLOCKQUOTE') {
          formatText('formatblock', 'div');
        } else {
          formatText('formatblock', 'blockquote', 'blockquote');
        }
      }
    };

    const addCodeBlock = () => {
      const sText = window.getSelection() || document.getSelection();

      if (sText?.anchorNode && sText.rangeCount) {
        const selectedElement = sText.anchorNode.parentNode;

        if ((selectedElement as any).tagName === 'PRE') {
          (selectedElement as any).remove();
          formatText('formatblock', 'div');
        } else {
          formatText('formatblock', 'pre', 'codeblock');
        }
      }
    };

    /**
     * for future investigation:
     * 1. always, when pressing ESC, the dialog window closes, but the selected text is incorrectly converted into a hyperlink
     * this is because when the dialog window is initiated, the selected text is immediately set as a href
     * 2. occasionally, when clicking cancel or X, the selected text is incorrectly converted
     * some kind of race condition with linkSelected being out of date?
     */
    const cancelLinkInsertion = (linkSelected: boolean) => {
      const selectedText = document.getElementById('newHyperlink');
      if (!selectedText) return;

      setEditorState(prevState => ({
        ...prevState,
        shouldAddHyperLink: false,
        linkText: '',
        linkURL: ''
      }));

      selectElementContents(selectedText);

      if (linkSelected) {
        formatText(
          'insertHTML',
          `<a href="${(selectedText as any).href}">${selectedText.innerText}</a>`
        );
      } else formatText('insertHTML', `<span>${selectedText.innerText}</span>`);
    };

    const insertHyperLink = (linkURL: string, linkText: string, unlink: boolean) => {
      const selectedText = document.getElementById('newHyperlink');
      if (!selectedText) return;

      setEditorState(prevState => ({
        ...prevState,
        addHyperLink: false,
        shouldAddHyperLink: false,
        linkText: '',
        linkURL: ''
      }));

      selectElementContents(selectedText);

      if (unlink) formatText('unlink');
      else {
        formatText(
          'insertHTML',
          `<a href="${linkURL}" aria-label="Open ${linkText} in a new tab" target="_blank">${linkText}</a>`,
          'link'
        );
      }
    };

    const selectElementContents = (el: HTMLElement) => {
      const range = document.createRange();
      range.selectNodeContents(el);

      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
    };

    const getValue = () => {
      const textBoxRef = textBox.current;
      const html = textBoxRef?.innerHTML || '';

      const allowedTags = ALLOWED_CANDIDATE_DESCRIPTION_TAGS;
      const cleanHTML = sanitizeHtml(html, { allowedTags });

      const decodedHtml = cleanHTML.replace(/&amp;/g, '&');

      return decodedHtml;
    };

    const getPlainTextValue = () => {
      const textBoxRef = textBox.current;
      return textBoxRef?.innerText || '';
    };

    useEffect(() => {
      setEditorState(state => ({ ...state, value: editorValue || '' }));

      onPaste();
      onCaretChange();
    }, []);

    const onPaste = () => {
      const editor = document.getElementById(id);
      if (!editor) return;
      const allLineBreaks = /\r?\n|\r/g;

      editor.addEventListener('paste', e => {
        e.preventDefault();
        e.stopPropagation();
        const htmlData = (e.clipboardData || (window as any).clipboardData).getData('text/html');
        const plainTextData = (e.clipboardData || (window as any).clipboardData).getData(
          'text/plain'
        );

        if (sanitisePaste) {
          let pastedData = '';

          if (new RegExp('Word.Document').test(htmlData)) {
            pastedData = htmlData.replace(allLineBreaks, ' ');
          } else pastedData = htmlData || plainTextData;

          const companyAllowedTags = ALLOWED_COMPANY_PASTE_TAGS;
          const candidateAllowedTags = ALLOWED_CANDIDATE_PASTE_TAGS;

          const cleanHTML = sanitizeHtml(pastedData, {
            allowedTags: user === 'company' ? companyAllowedTags : candidateAllowedTags,
            textFilter(text: string) {
              return text.replace(/^\s+|\s+$/g, '');
            },
            exclusiveFilter(frame: $TSFixMe) {
              return frame.tag === 'span' && !frame.text.trim();
            }
          });

          document.execCommand('insertHTML', false, cleanHTML);
        } else {
          document.execCommand('insertText', false, plainTextData);
        }
      });
    };

    const addListenerMulti = (element: HTMLElement, eventNames: string[], fn: AnyFunction) => {
      eventNames.forEach(e => element.addEventListener(e, fn));
    };

    const onCaretChange = () => {
      const editor = document.getElementById(id);
      if (!editor) return;
      const eventNames = ['mousedown', 'mouseup', 'keydown', 'keyup'];

      addListenerMulti(editor, eventNames, () => checkElementAtCaretPosition());
    };

    const checkElementAtCaretPosition = () => {
      const selectedText = window.getSelection();
      let selectedElement;
      const activeStyles = [];
      let linkSelected = false;

      if (selectedText?.anchorNode) {
        selectedElement = selectedText.anchorNode.parentNode;
        switch ((selectedElement as $TSFixMe).tagName) {
          case 'A':
            const linkParentNode = (selectedElement as $TSFixMe).parentElement.tagName;

            activeStyles.push('link');
            linkSelected = true;

            if (linkParentNode === 'I') activeStyles.push('italic');
            if (linkParentNode === 'U') activeStyles.push('underline');
            if (linkParentNode === 'B') activeStyles.push('bold');
            if (linkParentNode === 'BLOCKQUOTE') activeStyles.push('blockquote');

            break;
          case 'B':
            const boldParentNode = (selectedElement as $TSFixMe).parentElement.tagName;

            activeStyles.push('bold');
            if (boldParentNode === 'I') activeStyles.push('italic');
            if (boldParentNode === 'U') activeStyles.push('underline');
            if (boldParentNode === 'A') activeStyles.push('link');
            if (boldParentNode === 'BLOCKQUOTE') activeStyles.push('blockquote');
            if (boldParentNode === 'PRE') activeStyles.push('codeblock');

            break;
          case 'LI':
            const liParentNode = (selectedElement as $TSFixMe).parentElement.tagName;

            if (liParentNode === 'UL') activeStyles.push('insertunorderedlist');
            else activeStyles.push('insertorderedlist');

            break;
          case 'I':
            const italicParentNode = (selectedElement as $TSFixMe).parentElement.tagName;

            activeStyles.push('italic');
            if (italicParentNode === 'B') activeStyles.push('bold');
            if (italicParentNode === 'U') activeStyles.push('underline');
            if (italicParentNode === 'A') activeStyles.push('link');
            if (italicParentNode === 'BLOCKQUOTE') activeStyles.push('blockquote');

            break;
          case 'U':
            const underlineParentNode = (selectedElement as $TSFixMe).parentElement.tagName;

            activeStyles.push('underline');
            if (underlineParentNode === 'B') activeStyles.push('bold');
            if (underlineParentNode === 'I') activeStyles.push('italic');
            if (underlineParentNode === 'A') activeStyles.push('link');
            if (underlineParentNode === 'BLOCKQUOTE') activeStyles.push('blockquote');

            break;
          case 'BLOCKQUOTE':
            activeStyles.push('blockquote');

            break;
          case 'PRE':
            activeStyles.push('codeblock');

            break;
          default:
            break;
        }

        setEditorState({ ...editorState, activeStyles, linkSelected });
      }
    };

    const checkForShortcuts = (e: React.KeyboardEvent) => {
      let ret = true;
      if (e.ctrlKey || e.metaKey) {
        switch (e.key) {
          case 'b': //ctrl+B or ctrl+b
          case 'B':
            if (!bold) ret = false;
            break;
          case 'i': //ctrl+I or ctrl+i
          case 'I':
            if (!italics) ret = false;
            break;
          case 'u': //ctr+U or ctrl+u
          case 'U':
            if (!underline) ret = false;
            break;
          default:
            break;
        }
      }

      if (!ret && e.key !== 'tab') e.preventDefault();
    };

    const handleInput = () => {
      if (onChange) {
        const updatedValue = getValue();
        onChange(updatedValue);
      }
    };

    const text = getValue();
    const { color, tooltip } = getJobDescriptionLengthTooltip(text.length);

    return (
      <div className={ClassNames('rich_editor', { dark: darkMode, highlightMarked })}>
        <div className="rich_editor_tool_bar">
          <div className="rich_editor_tools">
            {bold && (
              <button
                type="button"
                tabIndex={-1}
                className={ClassNames('tool_item', {
                  active: activeStyles.findIndex((style: $TSFixMe) => style === 'bold') > -1
                })}
                onClick={() => formatText('bold')}
              >
                <span className="icon_bold" />
                <Tooltip position="bottom" text="Bold" />
              </button>
            )}
            {italics && (
              <button
                type="button"
                tabIndex={-1}
                className={ClassNames('tool_item', {
                  active: activeStyles.findIndex((style: $TSFixMe) => style === 'italic') > -1
                })}
                onClick={() => formatText('italic')}
              >
                <span className="icon_italic" />
                <Tooltip position="bottom" text="Italic" />
              </button>
            )}
            {underline && (
              <button
                type="button"
                tabIndex={-1}
                className={ClassNames('tool_item', {
                  active: activeStyles.findIndex((style: $TSFixMe) => style === 'underline') > -1
                })}
                onClick={() => formatText('underline')}
              >
                <span className="icon_underline" />
                <Tooltip position="bottom" text="Underline" />
              </button>
            )}
            {orderedList && (
              <button
                type="button"
                tabIndex={-1}
                className={ClassNames('tool_item', {
                  active:
                    activeStyles.findIndex((style: $TSFixMe) => style === 'insertunorderedlist') >
                    -1
                })}
                onClick={() => formatText('insertunorderedlist')}
              >
                <span className="icon_unordered_list" />
                <Tooltip position="bottom" text="Unordered List" />
              </button>
            )}
            {unorderedList && (
              <button
                type="button"
                tabIndex={-1}
                className={ClassNames('tool_item', {
                  active:
                    activeStyles.findIndex((style: $TSFixMe) => style === 'insertorderedlist') > -1
                })}
                onClick={() => formatText('insertorderedlist')}
              >
                <span className="icon_ordered_list" />
                <Tooltip position="bottom" text="Ordered List" />
              </button>
            )}
            {link && (
              <button
                type="button"
                tabIndex={-1}
                className={ClassNames('tool_item', {
                  active: activeStyles.findIndex((style: $TSFixMe) => style === 'link') > -1
                })}
                onClick={() => addHyperLink()}
              >
                <span className="icon_link" />
                <Tooltip position="bottom" text={linkSelected ? 'Edit Link' : 'Link'} />
              </button>
            )}
            {quote && (
              <button
                type="button"
                tabIndex={-1}
                className={ClassNames('tool_item', {
                  active: activeStyles.findIndex((style: $TSFixMe) => style === 'blockquote') > -1
                })}
                onClick={() => addBlockQuote()}
              >
                <span className="icon_quote" />
                <Tooltip position="bottom" text="Quote" />
              </button>
            )}
            {code && (
              <button
                type="button"
                tabIndex={-1}
                className={ClassNames('tool_item', {
                  active: activeStyles.findIndex((style: $TSFixMe) => style === 'codeblock') > -1
                })}
                onClick={() => addCodeBlock()}
              >
                <span className="icon_code" />
                <Tooltip position="bottom" text="Code block" />
              </button>
            )}
            <button
              type="button"
              tabIndex={-1}
              className={ClassNames('tool_item')}
              onClick={() => formatText('removeformat')}
            >
              <span className="icon_clear" />
              <Tooltip position="bottom" text="Clear formats" />
            </button>
            <button
              type="button"
              tabIndex={-1}
              className={ClassNames('tool_item')}
              onClick={() => formatText('undo')}
            >
              <span className="icon_undo" />
              <Tooltip position="bottom" text="Undo" />
            </button>
            <button
              type="button"
              tabIndex={-1}
              className={ClassNames('tool_item')}
              onClick={() => formatText('redo')}
            >
              <span className="icon_redo" />
              <Tooltip position="bottom" text="Redo" />
            </button>
            <ConditionalRender predicate={enhance}>
              <TextEnhancer.Menu />
            </ConditionalRender>
          </div>
          <ConditionalRender predicate={enhance}>
            <TextEnhancer.Error />
            <TextEnhancer.Controls />
          </ConditionalRender>
          {showCharacterCount && (
            <CharacterCountTooltip
              count={text.length}
              color={color}
              title={tooltip}
              tooltipPosition="bottom"
            >
              Recommended description length is <b>2000-3500 characters</b>. Descriptions inside
              that range get higher response rates. Learn how to{' '}
              <button
                className="link guide_link"
                onClick={() => openArticleAndTrack(JOB_DESCRIPTION_GUIDE, 'character_limit_link')}
              >
                write better descriptions
              </button>
              .
            </CharacterCountTooltip>
          )}
        </div>
        <ConditionalRender predicate={enhance}>
          <TextEnhancer.CustomPrompt placeholder={'Eg. Change tone to "professional"'} />
        </ConditionalRender>
        <div
          id={id}
          ref={textBox}
          className="rich_editor_input"
          tabIndex={0}
          onKeyDown={checkForShortcuts}
          onInput={handleInput}
          contentEditable="true"
          dangerouslySetInnerHTML={{ __html: editorValue || '' }}
          onBlur={onBlur}
        />
        {placeholder && !text && <span className="rich_editor_placeholder">{placeholder}</span>}
        {shouldAddHyperLink && (
          <AddHyperlink
            key={`${linkURL}_${linkText}`}
            visible={shouldAddHyperLink}
            hyperLink={linkURL}
            hyperLinkText={linkText}
            title={linkSelected ? 'Edit Link' : 'Insert Link'}
            buttonText={linkSelected ? 'Update' : 'Insert'}
            secondButtonText={linkSelected ? 'Unlink' : ''}
            cancel={!linkSelected}
            onClose={() => cancelLinkInsertion(linkSelected)}
            onButtonClick={(l, t) => insertHyperLink(l, t, false)}
            onSecondButtonClick={(l, t) => insertHyperLink(l, t, true)}
          />
        )}
      </div>
    );
  }
);

export default RichEditorWithTextEnhancerContext;
