import styled, { css } from 'styled-components';

import Tabs from 'components/tabs';

import SearchInput from 'v2/components/ui/molecules/SearchInput';
import {
  MENU_ICON_FONT_SIZE,
  MENU_ICON_PADDING,
  MenuIconStyling
} from 'v2/components/ui/atoms/MenuIcon';

const SEARCH_INPUT_BUTTON_WIDTH = '280px';
const SEARCH_INPUT_BUTTON_HEIGHT = `(${MENU_ICON_PADDING} * 2 + ${MENU_ICON_FONT_SIZE})`;

export const SearchInputButton = styled.button`
  ${MenuIconStyling};

  ${({ theme: { media } }) => css`
    ${media.mobileMin`
      width: ${SEARCH_INPUT_BUTTON_WIDTH};
      border-radius: 2rem;
      border: 1px solid var(--border-divider-soft);
      box-shadow: 0 0.25rem 0.75rem 0 var(--shadow-ultra-soft);
      background: var(--background-block-primary);

      [class^='icon_'] {
        font-size: 1.25rem;
      }

      &:hover {
        background: var(--background-body-primary);
        box-shadow: 0 0.25rem 1rem 0 var(--shadow-soft);
      }
    `}

    ${media.tabletMin`
      position: absolute;
      top: calc(50% - ${SEARCH_INPUT_BUTTON_HEIGHT} / 2);
      left: calc(50% - 200px);
    `}

    ${media.tabletLandscapeMin`
      left: calc(50% - ${SEARCH_INPUT_BUTTON_WIDTH} / 2);
    `}
  `}
`;

export const SearchInputButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: ${({ theme: { typography } }) => typography.semibold};
`;

export const SearchInputContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  .button.ghost_dark_blue {
    min-width: unset;
    padding: 0.5rem;
    opacity: 1;

    &:hover {
      background: var(--background-accent-secondary-soft);
    }
  }
`;

const INPUT_PADDING_LEFT = '1rem';
const ICON_SIZE = '1.5rem';
const GAP = '0.75rem';

export const SearchBox = styled(SearchInput)`
  &.search_box {
    flex: 1;

    &,
    input {
      margin: 0;
    }

    input {
      padding: 0.75rem calc(${INPUT_PADDING_LEFT} + ${ICON_SIZE} + ${GAP});
      border-radius: 2rem;
      background: var(--background-body-secondary);

      &,
      &:focus {
        border: none;
      }
    }

    .icon_search {
      left: ${INPUT_PADDING_LEFT};
      font-size: ${ICON_SIZE};
      font-weight: ${({ theme: { typography } }) => typography.semibold};
    }
  }
`;

export const SearchBarTabs = styled(Tabs)`
  &&.tab_item {
    flex-grow: 1;
    justify-content: center;
    font-size: ${({ theme: { typography } }) => typography.normal};
  }
`;
