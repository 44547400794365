import React from 'react';
import ClassNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { swipeXAxisOnStart, swipeXAxisOnMove, swipeXAxisOnEnd } from 'swipeControl';

import PositionActions from 'components/positionactions';
import ShareProfileButton from 'components/shareprofilebutton';

import './style.scss';

type Props = {
  imageURLs: string[];
  fallbackImage: string;
  darkArrows?: string | boolean;
  className?: string;
};

type State = $TSFixMe;

export default class ImageSlider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pictureIndex: 0
    };
  }

  onPrev = (numPictures: $TSFixMe) => {
    const { pictureIndex } = this.state;

    this.setState({
      pictureIndex: pictureIndex === 0 ? numPictures - 1 : (pictureIndex - 1) % numPictures
    });
  };

  onNext = (numPictures: $TSFixMe) => {
    const { pictureIndex } = this.state;

    this.setState({ pictureIndex: (pictureIndex + 1) % numPictures });
  };

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      loading,
      imageURLs,
      fallbackImage,
      darkArrows,
      // @ts-expect-error TS(2339) FIXME: Property 'showSaveHideActions' does not exist on t... Remove this comment to see the full error message
      showSaveHideActions,
      // @ts-expect-error TS(2339) FIXME: Property 'showSendAction' does not exist on type '... Remove this comment to see the full error message
      showSendAction,
      // @ts-expect-error TS(2339) FIXME: Property 'listingID' does not exist on type 'Reado... Remove this comment to see the full error message
      listingID,
      // @ts-expect-error TS(2339) FIXME: Property 'shortlistID' does not exist on type 'Rea... Remove this comment to see the full error message
      shortlistID,
      // @ts-expect-error TS(2339) FIXME: Property 'shortlistType' does not exist on type 'R... Remove this comment to see the full error message
      shortlistType,
      // @ts-expect-error TS(2339) FIXME: Property 'onShortlisted' does not exist on type 'R... Remove this comment to see the full error message
      onShortlisted,
      // @ts-expect-error TS(2339) FIXME: Property 'goToSignup' does not exist on type 'Read... Remove this comment to see the full error message
      goToSignup,
      // @ts-expect-error TS(2339) FIXME: Property 'companyName' does not exist on type 'Rea... Remove this comment to see the full error message
      companyName,
      // @ts-expect-error TS(2339) FIXME: Property 'darkMode' does not exist on type 'Readon... Remove this comment to see the full error message
      darkMode,
      // @ts-expect-error TS(2339) FIXME: Property 'shareURL' does not exist on type 'Readon... Remove this comment to see the full error message
      shareURL,
      // @ts-expect-error TS(2339) FIXME: Property 'position' does not exist on type 'Readon... Remove this comment to see the full error message
      position,
      className
    } = this.props;
    const { pictureIndex } = this.state;

    const pictures = (imageURLs?.length && imageURLs) || [fallbackImage];
    const numPictures = pictures.length;

    if (loading) {
      return (
        <div id="imageSlider" className={ClassNames('picture_container', className)}>
          <Skeleton height={420} duration={2} />
        </div>
      );
    } else {
      return (
        <div id="imageSlider" className={ClassNames('picture_container', className)}>
          {numPictures > 1 && (
            <span
              className={ClassNames('icon_arrow_left', 'left_arrow', { dark: darkArrows })}
              onClick={() => this.onPrev(numPictures)}
            />
          )}
          {numPictures > 1 && (
            <span
              className={ClassNames('icon_arrow_right', 'right_arrow', { dark: darkArrows })}
              onClick={() => this.onNext(numPictures)}
            />
          )}
          <div className="picture_wrapper" style={{ width: `${numPictures * 100}%` }}>
            {pictures.map((picture, index) => (
              <img
                key={picture}
                className={ClassNames('picture', {
                  no_picture: picture === null || picture === undefined
                })}
                src={picture}
                alt={companyName ? `Photos people at ${companyName}` : 'Image slider photo'}
                style={{ left: `${(index - pictureIndex) * 100}%` }}
                onTouchStart={e => swipeXAxisOnStart(e)}
                onTouchMove={e => swipeXAxisOnMove(e)}
                onTouchEnd={e =>
                  swipeXAxisOnEnd(
                    e,
                    () => this.onNext(numPictures),
                    () => this.onPrev(numPictures)
                  )
                }
                width="800"
                height="420"
                loading="lazy"
              />
            ))}
          </div>
          {showSaveHideActions && (
            <PositionActions
              white
              listingID={listingID}
              shortlistID={shortlistID}
              type={shortlistType}
              onShortlisted={onShortlisted}
              goToSignup={goToSignup}
            />
          )}
          {showSendAction && (
            <ShareProfileButton
              darkMode={darkMode}
              shareURL={shareURL}
              companyName={companyName}
              position={position}
            />
          )}
        </div>
      );
    }
  }
}
