import { CandidateSavedSearchInterface } from '@cohiretech/common-types';

import { Stream } from 'types';

import { DialogWindowProps } from 'components/dialogwindow';

export type DeleteStreamPopupProps = {
  featureName: 'Stream' | 'Search';
  streamOf: 'candidates' | 'positions';
  streamDetails: Stream | null;
  allowDelete?: boolean;
  onDelete: () => Promise<void>;
} & Pick<DialogWindowProps, 'visible' | 'onClose'>;

export const getTitleAndSubtitle = ({
  featureName,
  streamOf,
  streamDetails,
  allowDelete = true
}: DeleteStreamPopupProps) => {
  const popupText = allowDelete ? 'Delete' : 'Remove';
  const { searchName = '', subscribe } = (streamDetails as CandidateSavedSearchInterface) || {};

  let subtitle = [
    `${allowDelete ? 'Deleting' : 'Removing'} your `,
    <strong key="streamName">{searchName}</strong>,
    ` ${featureName.toLowerCase()} will also stop you receiving email notifications about new ${streamOf} ${
      featureName === 'Stream' ? 'appearing in this' : 'that match your search criteria'
    }. Are you sure you want to ${popupText.toLowerCase()} it?`
  ];

  if (subscribe === false) {
    subtitle = [
      `Are you sure you want to ${popupText.toLowerCase()} your `,
      <strong key="streamName">{searchName}</strong>,
      ` ${featureName}?`
    ];
  }

  return { title: `${popupText} ${featureName}`, subtitle };
};
