import axios, { AxiosResponse } from 'axios';

import { APIResponse } from 'types';

const SIGN_CV_URL = process.env.REACT_APP_SIGN_CV_URL!;

type SignCVURLResponse = APIResponse<{
  signedUrl: string;
}>;

export const getSignedURL = async (url: string): Promise<SignCVURLResponse> => {
  try {
    const { data } = await axios.post<{ url: string }, AxiosResponse<SignCVURLResponse>>(
      SIGN_CV_URL,
      {
        url
      }
    );

    if (data.status !== 'success' || !data?.data?.signedUrl) throw new Error('No signed URL found');
    return { status: data.status, data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
