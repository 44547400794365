import React from 'react';
import ClassNames from 'classnames';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { ConditionalClickDetector } from 'v2/components/utility/ClickDetector';

import { ExpandableFilterProvider, useExpandableFilter } from './ExpandableFilter.context';
import { ExpandableFilterProps } from './ExpandableFilter.helpers';
import ExpandableFilterTitle from './components/ExpandableFilterTitle';
import MultiselectList from './components/MultiselectList';
import SearchBar from './components/SearchBar';
import RadioList from './components/RadioList';
import FilterDropdownSelector from './components/FilterDropdownSelector';
import LocationList from './components/LocationList';
import { ExpandableFilterCont, FilterContent, RangeSelector } from './ExpandableFilter.styled';

function FilterList() {
  const { locationInputProps, radioOptions, filterName, filterOptions, rangeFilter } =
    useExpandableFilter();

  if (radioOptions) return <RadioList />;
  if (filterOptions) return <MultiselectList />;
  if (locationInputProps) return <LocationList />;
  if (rangeFilter) {
    const { styledProps, ...rangeFilterProps } = rangeFilter;

    return (
      <RangeSelector
        filterName={filterName}
        showRange
        expandable={false}
        styledProps={{ ...styledProps, colour: 'secondary', labelPosition: 'top' }}
        {...rangeFilterProps}
      />
    );
  }

  return null;
}

function ExpandableFilter() {
  const {
    isOpen,
    isExpanded,
    filtersContRef,
    id,
    showSearchInput,
    overflowAuto,
    radioOptions,
    filterOptions,
    appearance,
    className,
    filterSelector,
    toggleFilterPanel
  } = useExpandableFilter();

  return (
    <ConditionalClickDetector active={appearance && isOpen} onClickOutside={toggleFilterPanel}>
      <ExpandableFilterCont
        className={ClassNames('expandable_filter', className, { expanded: isOpen })}
        id={id}
      >
        <ConditionalRender predicate={!filterSelector} fallback={filterSelector}>
          <ConditionalRender
            predicate={appearance !== 'filterTagDropdown'}
            fallback={<FilterDropdownSelector />}
          >
            <ExpandableFilterTitle />
          </ConditionalRender>
        </ConditionalRender>
        <FilterContent
          ref={filtersContRef}
          showScroll={overflowAuto}
          showSearchInput={showSearchInput}
          appearance={appearance}
          hasOptionList={!!(filterOptions || radioOptions)}
          className={ClassNames('filter_content', { open: isOpen }, { show: isOpen && isExpanded })}
        >
          <SearchBar />
          <FilterList />
        </FilterContent>
      </ExpandableFilterCont>
    </ConditionalClickDetector>
  );
}

export default function ExpandableFilterWithContext(props: ExpandableFilterProps) {
  return (
    <ExpandableFilterProvider {...props}>
      <ExpandableFilter />
    </ExpandableFilterProvider>
  );
}

// CORD-4879 After review: Change the file name and create a index file.
export { FilterContent } from './ExpandableFilter.styled';
export type {
  ExpandableFilterProps,
  FilterOption,
  RadioOption,
  FilterOptions,
  FilterOptionsGroups
} from './ExpandableFilter.helpers';
