/* eslint-disable no-console */
import { removeTag } from 'utils';

// Example format
/* <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Books",
      "item": "https://example.com/books"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Science Fiction",
      "item": "https://example.com/books/sciencefiction"
    },{
      "@type": "ListItem",
      "position": 3,
      "name": "Award Winners"
    }]
}
</script> */

export const generateBreadcrumbSchema = (breadcrumbs: $TSFixMe) => {
  try {
    const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;
    const structuredData = {};
    removeTag('script', 'id', 'breadcrumbs_schema');

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.id = 'breadcrumbs_schema';

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    structuredData['@context'] = 'http://schema.org';
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    structuredData['@type'] = 'BreadcrumbList';

    const itemListElement = breadcrumbs.map(({ label, pathname }: $TSFixMe, index: $TSFixMe) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: label,
        item: `${domain}${pathname}`
      };
    });

    (structuredData as $TSFixMe).itemListElement = itemListElement;

    script.innerText = JSON.stringify(structuredData);

    document.head.appendChild(script);
  } catch (error) {
    console.log(error, 'Failed to generate structured data for article');
  }
};
