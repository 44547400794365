import { AwardCompanySize, CompanySize, CompanySizeAbridged } from '@cohiretech/common-types';

export const getCompanyAwardSize = (size: CompanySize) => {
  switch (size) {
    case '1 - 10':
    case '11 - 50':
      return AwardCompanySize.Small;
    case '51 - 200':
    case '201 - 500':
      return AwardCompanySize.Midsize;
    default:
      return AwardCompanySize.Large;
  }
};

export const ABRIDGED_COMPANY_SIZE_OPTIONS: { label: string; value: CompanySizeAbridged }[] = [
  { label: 'Early-stage', value: CompanySizeAbridged['1to10'] },
  { label: 'Start-up', value: CompanySizeAbridged['11to50'] },
  { label: 'Scale-up', value: CompanySizeAbridged['51to500'] },
  { label: 'Enterprise', value: CompanySizeAbridged['501+'] }
];

export const ABRIDGED_COMPANY_SIZE_OPTIONS_WITH_COMBINED_LABEL = ABRIDGED_COMPANY_SIZE_OPTIONS.map(
  ({ label, value }) => ({ label: `${label} (${value.replace(/\s/g, '')})`, value })
);

export const COMPANY_SIZE_OPTIONS_WITH_DESCRIPTION = ABRIDGED_COMPANY_SIZE_OPTIONS.map(
  ({ label, value }) => ({ label, value, description: `(${value.replace(/\s/g, '')} employees)` })
);
