import React, { forwardRef } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { CompanySubscriptionPlan, Frequency } from '@cohiretech/common-types';

import { StandardPlan, SubscriptionPlan } from 'types';
import { checkIfSubscriptionExpired, isUnlimitedPlan } from 'utils/companyUtils';
import { getActivateBtnProps, getRequestDemoLink } from 'v2/services/tools/pricing';
import { useDemoCompany } from 'store';

import Button, { Props as ButtonProps } from 'components/button';
import Table from 'components/table';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { PricingPlanCardType } from 'v2/components/ui/organisms/PricingPlansSection/components/PricingPlanCard';

import FeatureList from './FeatureList/FeatureList';
import {
  FeatureComparisonTableProps,
  getAllFeatures,
  getCompareFeaturesTableHeaderContent
} from './FeatureComparisonTable.helpers';

const PLAN_TYPES: PricingPlanCardType[] = ['standard', 'enterprise'];

const FeatureComparisonTable = forwardRef<HTMLDivElement, FeatureComparisonTableProps>(
  (
    {
      pricingVer,
      pricingCountry,
      headerContent = getCompareFeaturesTableHeaderContent(pricingVer!, pricingCountry),
      currentPlan,
      textAlign,
      tickColour
    },
    ref
  ) => {
    const demoCompany = useDemoCompany();

    const allFeatures = getAllFeatures();
    const isPlanExpired = checkIfSubscriptionExpired(currentPlan);

    const getButtonProps = (planID: SubscriptionPlan): ButtonProps => {
      if (currentPlan) {
        const currentPlanBtnProps = { text: 'Your current plan', disabled: true };

        if (planID === currentPlan && !demoCompany) return currentPlanBtnProps;
        // All the Standard plans
        if (
          !isUnlimitedPlan(currentPlan) &&
          planID === headerContent.standard.plan &&
          !demoCompany
        ) {
          return currentPlan === CompanySubscriptionPlan.Basic
            ? getActivateBtnProps({
                plan: planID as StandardPlan,
                frequency: Frequency.monthly,
                pricingCountry
              })
            : currentPlanBtnProps;
        }
      }

      return { link: getRequestDemoLink({ plan: planID, pricingCountry }) };
    };

    const isCenterAligned = textAlign === 'center';

    return (
      <FeaturesTable
        containerRef={ref}
        highlightRowOnHover={false}
        isCenterAligned={isCenterAligned}
      >
        <thead>
          <tr>
            <th className="table_title">Compare plans & features</th>
            {PLAN_TYPES.map(type => {
              const { plan, label, price, buttonStyle } = headerContent[type];

              return (
                <th
                  key={`header_content_${type}`}
                  className={ClassNames({ text_center: isCenterAligned, no_price: !price })}
                >
                  <ConditionalRender predicate={price}>
                    <div className="plan_price">{price}/mo</div>
                  </ConditionalRender>
                  <div className="plan_title">{label}</div>
                  <ConditionalRender predicate={!isPlanExpired}>
                    <Button
                      text={type === 'standard' ? 'Get started' : 'Get in touch'}
                      buttonStyle={buttonStyle}
                      {...getButtonProps(plan)}
                    />
                  </ConditionalRender>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Object.entries(allFeatures).map(([key, featureList]) => (
            <FeatureList key={key} {...featureList} tickColour={tickColour} />
          ))}
        </tbody>
      </FeaturesTable>
    );
  }
);

export default FeatureComparisonTable;

const CenterAlignedTableStyling = css`
  ${({ theme: { colours, fontColour, darkMode, fn } }) => css`
    th {
      padding: 8px 16px;
    }

    tbody td {
      border: 1px solid;
      border-right-color: ${darkMode ? fn.opaqueColour(fontColour, 0.4) : colours.lightBgColour};
      border-bottom-color: ${darkMode ? fontColour : colours.lightBgColour};

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  `}

  ${({ theme: { media } }) => media.tabletLandscapeMin`
    &:not(:first-child), .button {
      width: 210px;
    }
  `}
`;

const FeaturesTable = styled(Table)<{ isCenterAligned: boolean }>`
  ${({ theme: { typography }, isCenterAligned }) => css`
    max-width: 945px;
    margin: 72px auto 0;

    th {
      padding: 1.5rem 2.5rem 1.5rem 0;
      border-bottom: 0;
      vertical-align: top;

      &:first-child.table_title {
        max-width: 235px;
        font-size: 2rem;
        white-space: normal;
      }

      &.no_price {
        font-size: ${typography.header};
      }

      .plan_price {
        margin-bottom: 0.25rem;
        font-weight: ${typography.regular};
      }

      .plan_title {
        margin: 4px auto 16px;
      }

      .fill_cord_gradient {
        padding: 0.75rem 1.25rem;
      }
    }

    tbody {
      border-bottom: 0;
    }

    ${isCenterAligned && CenterAlignedTableStyling}
  `}

  ${({ theme: { media } }) => media.mobile`
    th {
      padding-right: 1.5rem;

      &:first-child.table_title {
        min-width: 200px;
        font-size: 26px;
      }
    }
  `}
`;
