import { css } from 'styled-components';

import { colours } from 'styles/theme/common';

export const ApexChartsCommonStyling = css`
  ${({ theme: { typography } }) => css`
    .apexcharts-xaxis-label tspan,
    .apexcharts-xaxis-title-text {
      fill: #869db1;
      font-size: ${typography.tiny};
      font-weight: ${typography.bold};
    }

    .apexcharts-datalabel {
      font-size: ${typography.small};
      font-weight: ${typography.bold};
    }
  `}
`;

export const BAR_COLOR_LIGHT = [
  {
    offset: 0,
    color: 'rgb(95, 170, 231)',
    opacity: 0.12
  },
  {
    offset: 110,
    color: colours.primaryColour,
    opacity: 0.12
  }
];

export const BAR_COLOR_DARK = [
  {
    offset: 0,
    color: 'rgb(95, 170, 231)',
    opacity: 1
  },
  {
    offset: 110,
    color: colours.primaryColour,
    opacity: 1
  }
];
