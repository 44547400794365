import React, { useEffect } from 'react';

import { LogicalOperator, Operator } from 'types';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';
import Tabs, { TabType } from 'components/tabs';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

const OPERATOR_TABS: TabType<LogicalOperator>[] = [
  { title: 'Any of', value: 'or' },
  { title: 'All', value: 'and' }
];

export default function OperatorToggle() {
  const {
    operator,
    selectedItems,
    showOperatorToggle,
    orAttribute,
    andAttribute,
    setOperator,
    updateOperator
  } = useExpandableFilter();

  const handleOperatorChange = (isAnd: boolean, updateSearchResults = true) => {
    const oldAttribute = operator.attribute;
    const selectedOperator: Operator = isAnd
      ? { operator: 'and', attribute: andAttribute }
      : { operator: 'or', attribute: orAttribute };

    if (selectedOperator !== operator) setOperator(selectedOperator);
    if (updateSearchResults) updateOperator?.(oldAttribute, selectedOperator.attribute);
  };

  useEffect(() => {
    if (!showOperatorToggle || !orAttribute || !andAttribute) return;

    const isAnd = !!selectedItems?.find(item => item.attribute === andAttribute);
    handleOperatorChange(isAnd, false);
  }, [selectedItems]);

  return (
    <ConditionalRender predicate={showOperatorToggle}>
      <Tabs
        className="operator"
        current={operator.operator}
        tabs={OPERATOR_TABS}
        onChange={({ value }) => handleOperatorChange(value === 'and')}
        appearance="pill"
        size="2xsmall"
      />
    </ConditionalRender>
  );
}
