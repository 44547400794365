import { RemoteOption, SearchResponseListing } from '@cohiretech/common-types';

import { VisaRemoteInfo } from 'types';
import { getRemoteLocationsLabel, isEmpty, setAcronym } from 'utils';
import { capitalizeWords } from 'utils/string';

const NO_ONSITE_WORK = 'No onsite work';

export const getOnsiteOrHybridLocationLabel = (
  positionLocation: SearchResponseListing['locationCity'],
  remote: SearchResponseListing['remote']
) => {
  const onsiteHybrid = remote.filter(value => value !== RemoteOption.Remote);

  if (isEmpty(onsiteHybrid)) return NO_ONSITE_WORK;
  return `${capitalizeWords(onsiteHybrid.join(' / '))} in ${setAcronym(positionLocation)}`;
};

export const getFullPositionLocationLabel = (visaRemoteInfo: VisaRemoteInfo) => {
  const {
    locationObject,
    locationCity = locationObject?.label || '',
    remoteLocationContinents,
    remoteLocationCountries,
    remote = []
  } = visaRemoteInfo;

  const onsiteOrHybridLocationLabel = getOnsiteOrHybridLocationLabel(locationCity, remote);
  const remoteLocationsLabel = getRemoteLocationsLabel(
    remote,
    remoteLocationContinents,
    remoteLocationCountries
  );

  if (onsiteOrHybridLocationLabel === NO_ONSITE_WORK) return remoteLocationsLabel;
  return onsiteOrHybridLocationLabel + (remoteLocationsLabel ? ` / ${remoteLocationsLabel}` : '');
};
