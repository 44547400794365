import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { CVOptions } from 'types';
import { useDarkMode } from 'hooks/useDarkMode';

import { darkTheme, lightTheme } from './themes';

type Props = {
  children: React.ReactNode;
  cvOptions?: CVOptions;
};

const CordTheme = ({ children, cvOptions }: Props) => {
  const darkMode = useDarkMode();
  const [theme, setTheme] = useState(darkMode ? darkTheme : lightTheme);

  useEffect(() => {
    const cordTheme = darkMode ? darkTheme : lightTheme;
    const cvTheme = { ...cordTheme, cvOptions };
    setTheme(cvTheme);
  }, [cvOptions, darkMode]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CordTheme;
