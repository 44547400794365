import { CompanySearchTotalDTO } from '@cohiretech/common-types';

import { APIResponse } from 'types';
import { companySearchFilters } from 'consts';
import { getSearchTerms } from 'utils';

import { axiosGetWithID } from '../axiosWithRequestID';

const COMPANY_SEARCH_COUNT = process.env.REACT_APP_COMPANY_SEARCH_COUNT!;

type CompanySearchParams = {
  searchTerms: $TSFixMe;
  otherTerms?: $TSFixMe;
  admin?: boolean;
  requestID?: string;
  streamName?: string;
  streamID?: number;
};

type CompanySearchCountResults = APIResponse<CompanySearchTotalDTO>;

// Todo: refactor the company search from fetcher.ts together with this
export const getCandidateSearchCount = async ({
  searchTerms,
  otherTerms,
  requestID,
  streamName,
  streamID
}: CompanySearchParams): Promise<CompanySearchCountResults> => {
  const requestId = requestID || `company_search_count_request_${JSON.stringify(searchTerms)}`;
  try {
    const params = { ...otherTerms };
    const stringParameters = [
      companySearchFilters.relocate.attribute,
      companySearchFilters.previouslyDealtWith.attribute,
      companySearchFilters.previouslySkipped.attribute,
      companySearchFilters.candidatePool.attribute,
      companySearchFilters.lastActiveValue.attribute,
      companySearchFilters.lastActiveType.attribute,
      companySearchFilters.sortBy.attribute,
      companySearchFilters.newCandidates.attribute,
      companySearchFilters.ethnicity.attribute,
      companySearchFilters.lowerTimeOffset.attribute,
      companySearchFilters.upperTimeOffset.attribute,
      companySearchFilters.radius.attribute,
      companySearchFilters.cordLive.attribute
    ];
    const searchTermsObj = { ...params, ...getSearchTerms(searchTerms, stringParameters) };

    if (streamName) searchTermsObj.stream_name = streamName;
    if (streamID) searchTermsObj.saved_search_id = streamID;

    const url = `${COMPANY_SEARCH_COUNT}`;

    const { data } = await axiosGetWithID<CompanySearchCountResults>({
      url,
      params: searchTermsObj,
      requestId
    });

    if (!data?.data) throw new Error('No data');

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error.message };
  }
};
