import React, { useState, useEffect, ReactNode, SVGProps } from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';
import {
  XYPlot,
  DiscreteColorLegend,
  VerticalGridLines,
  HorizontalGridLines,
  GradientDefs,
  XAxis,
  YAxis,
  ChartLabel,
  AreaSeries,
  LabelSeries,
  LineSeries,
  LineMarkSeries,
  MarkSeries,
  VerticalRectSeries,
  Hint,
  XYPlotProps,
  DiscreteColorLegendProps,
  VerticalGridLinesProps,
  HorizontalGridLinesProps,
  XAxisProps,
  YAxisProps,
  ChartLabelProps,
  AreaSeriesProps,
  LabelSeriesProps,
  LineSeriesProps,
  LineMarkSeriesProps,
  MarkSeriesProps,
  MarkSeriesPoint,
  VerticalRectSeriesProps,
  HintProps
} from 'react-vis';

import { tablet } from 'responsiveConfig';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type SeriesProps = {
  areaSeries?: AreaSeriesProps[];
  labelSeries?: LabelSeriesProps;
  lineSeries?: LineSeriesProps[];
  lineMarkSeries?: LineMarkSeriesProps[];
  markSeries?: MarkSeriesProps[];
  markSeriesOnHover?: MarkSeriesProps[];
  verticalRectSeries?: VerticalRectSeriesProps;
};

export type Props = {
  id: string;
  darkMode: boolean;
  legendProps?: DiscreteColorLegendProps;
  gradientCommands?: SVGProps<SVGLinearGradientElement | SVGRadialGradientElement>;
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;
  chartLabelProps?: ChartLabelProps;
  verticalGridLines?: VerticalGridLinesProps[];
  horizontalGridLines?: HorizontalGridLinesProps[];
  seriesProps: SeriesProps;
  addlSeriesProps?: SeriesProps;
  showOtherSeriesOnHover?: boolean;
  positionX?: number | null;
  handleValueMouseOver?: (x: number) => void;
  handleValueMouseOut?: () => void;
  hint?: {
    data?: Required<HintProps>['value'][];
    getHint: (x: number, isAddlSeries?: boolean) => ReactNode;
  };
} & Partial<Pick<XYPlotProps, 'className' | 'width' | 'height' | 'margin'>>;

const getSeriesDefaultProps = (
  isAddlSeries: boolean,
  fontColor: string,
  mainColor: string,
  invertedMainColor: string
) => ({
  verticalRectSeriesProps: {
    className: 'vertical_rect_series',
    color: colours.primaryColour
  },
  areaSeriesProps: {
    className: 'area_series',
    color: isAddlSeries ? colours.darkPrimaryColour : fontColor
  },
  lineSeriesProps: {
    className: 'line_series',
    stroke: isAddlSeries ? colours.darkPrimaryColour : mainColor,
    style: { fill: 'transparent', strokeWidth: 2 }
  },
  lineMarkSeriesProps: {
    className: 'line_mark_series',
    stroke: colours.primaryColour,
    strokeWidth: 2,
    fill: invertedMainColor,
    lineStyle: { fill: 'transparent' }
  },
  markSeriesProps: {
    className: 'mark_series',
    stroke: isAddlSeries ? colours.darkPrimaryColour : mainColor,
    strokeWidth: 2,
    fill: invertedMainColor
  }
});

export default function MultiSeries({
  id,
  darkMode,
  className,
  width = 300,
  height = 300,
  margin = { left: 40, right: 10, top: 10, bottom: 40 },
  legendProps,
  gradientCommands,
  xAxisProps,
  yAxisProps,
  chartLabelProps,
  seriesProps,
  addlSeriesProps,
  verticalGridLines,
  horizontalGridLines,
  showOtherSeriesOnHover,
  positionX,
  handleValueMouseOver,
  handleValueMouseOut,
  hint
}: Props) {
  const mainColor = 'url(#cord_graph_gradient)';
  const invertedMainColor = darkMode ? '#283d54' : colours.lightBgColour;
  const fontColor = darkMode ? colours.secondaryColour : colours.fontColour;
  const gridColor = darkMode ? '#22313F' : colours.lightBgColour;

  const [markSeriesPropsOnHover, setMarkSeriesPropsOnHover] =
    useState<MarkSeriesProps | null>(null);
  const [showOtherAddlSeries, setShowOtherAddlSeries] = useState(false);

  useEffect(() => {
    if (showOtherSeriesOnHover) {
      const correspondingMarkSeries = showOtherAddlSeries
        ? addlSeriesProps!.markSeriesOnHover
        : seriesProps.markSeriesOnHover;
      setMarkSeriesPropsOnHover(
        positionX || positionX === 0 ? correspondingMarkSeries![positionX] : null
      );
    }
  }, [positionX, showOtherAddlSeries]);

  const renderHint = (
    val: Required<HintProps>['value'],
    idx: number,
    isAddlSeries: boolean = false
  ) =>
    val.x === positionX && (
      <Hint
        key={`${id}-hint-${idx}`}
        value={val}
        style={{ background: darkMode ? colours.darkerBgColour : 'white', color: mainColor }}
      >
        {hint!.getHint(val.x as number, isAddlSeries)}
      </Hint>
    );

  const renderSeries = (isAddlSeries: boolean = false) => {
    const { areaSeries, labelSeries, lineSeries, lineMarkSeries, markSeries, verticalRectSeries } =
      isAddlSeries ? addlSeriesProps! : seriesProps;
    const { areaSeriesProps, lineSeriesProps, lineMarkSeriesProps, verticalRectSeriesProps } =
      getSeriesDefaultProps(isAddlSeries, fontColor, mainColor, invertedMainColor);
    const showOnHover = (positionX || positionX === 0) && isAddlSeries === showOtherAddlSeries;

    return [
      verticalRectSeries && (
        <VerticalRectSeries
          key={`${id}-vertical-rect-series`}
          {...verticalRectSeriesProps}
          {...verticalRectSeries}
        />
      ),
      areaSeries?.map((props, idx) => (
        <AreaSeries
          key={`${id}-area-series-${idx}`}
          {...areaSeriesProps}
          className={ClassNames(areaSeriesProps.className, {
            show: !addlSeriesProps || showOnHover
          })}
          {...props}
        />
      )),
      lineSeries?.map((props, idx) => (
        <LineSeries key={`${id}-line-series-${idx}`} {...lineSeriesProps} {...props} />
      )),
      lineMarkSeries?.map((props, idx) => (
        <LineMarkSeries key={`${id}-line-mark-series-${idx}`} {...lineMarkSeriesProps} {...props} />
      )),
      markSeriesPropsOnHover && (
        <MarkSeries
          key={`${id}-mark-series-on-hover`}
          stroke={mainColor}
          fill={mainColor}
          {...markSeriesPropsOnHover}
        />
      ),
      labelSeries && (
        <LabelSeries key={`${id}-label-series`} className="label_series" {...labelSeries} />
      ),
      hint &&
        showOnHover &&
        (hint.data
          ? hint.data.map((val, idx) => renderHint(val, idx, isAddlSeries))
          : markSeries?.map(({ data }) =>
              (data as MarkSeriesPoint[]).map((val, idx) => renderHint(val, idx, isAddlSeries))
            ))
    ];
  };

  const renderMarkSeries = (isAddlSeries: boolean = false) => {
    const { markSeries } = isAddlSeries ? addlSeriesProps! : seriesProps;
    const { markSeriesProps } = getSeriesDefaultProps(
      isAddlSeries,
      fontColor,
      mainColor,
      invertedMainColor
    );

    return markSeries?.map((props, idx) => {
      if (handleValueMouseOver) {
        props.onValueMouseOver = ({ x }) => {
          handleValueMouseOver(x as number);
          if (showOtherAddlSeries !== isAddlSeries) setShowOtherAddlSeries(isAddlSeries);
        };
      }
      if (handleValueMouseOut) props.onValueMouseOut = () => handleValueMouseOut();

      return <MarkSeries key={`${id}-mark-series-${idx}`} {...markSeriesProps} {...props} />;
    });
  };

  return (
    <MultiSeriesWrapper
      id={id}
      className={ClassNames('multi_series_wrapper', className)}
      dark={darkMode}
    >
      <XYPlot width={width} height={height} margin={margin}>
        {legendProps && <DiscreteColorLegend {...legendProps} />}
        {verticalGridLines?.map((props, idx) => {
          return (
            <VerticalGridLines
              key={`${id}-vertical-grid-line-${idx}`}
              {...props}
              style={{ stroke: gridColor, ...props.style }}
            />
          );
        })}
        {horizontalGridLines?.map((props, idx) => {
          return (
            <HorizontalGridLines
              key={`${id}-horizontal-grid-line-${idx}`}
              {...props}
              style={{ stroke: gridColor, ...props.style }}
            />
          );
        })}
        {gradientCommands && <GradientDefs>{gradientCommands}</GradientDefs>}
        {xAxisProps && <XAxis {...xAxisProps} />}
        {yAxisProps && <YAxis {...yAxisProps} />}
        {chartLabelProps && <ChartLabel {...chartLabelProps} />}
        {renderSeries()}
        {addlSeriesProps && renderSeries(true)}
        {/* They should be the last elements to be added, otherwise they will be covered with other graphs and users won't be able to interact with them */}
        {seriesProps.markSeries && renderMarkSeries()}
        {addlSeriesProps?.markSeries && renderMarkSeries(true)}
      </XYPlot>
    </MultiSeriesWrapper>
  );
}

const MultiSeriesWrapper = styled.div<{ dark: boolean }>`
  &#salary_exp_chart {
    @media only screen and (max-width: ${tablet}) {
      padding: 0 25px 0 5px;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: 5px;
      }
    }

    .rv-xy-plot {
      .rv-xy-plot__inner {
        display: block;
        overflow: visible;

        .area_series {
          &.show {
            transition: all 0.3s ease-in-out;
          }

          &:not(.show) {
            opacity: 0 !important;
          }
        }

        .mark_series.show circle {
          opacity: 1 !important;
        }

        .mark_series circle {
          cursor: pointer;
        }
      }

      .rv-hint {
        min-width: 191px;
        margin: 10px;
        padding: 8px 14px;
        border-radius: 20px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      }

      @media only screen and (min-width: ${tablet}) {
        .rv-xy-plot__axis--horizontal .rv-xy-plot__axis__tick:last-child text {
          text-anchor: end;
        }
      }
    }
  }

  &.discover_salary_exp {
    margin-top: 40px;

    .rv-xy-plot .rv-discrete-color-legend {
      color: ${props => (props.dark ? colours.lightFontColour : colours.fontColour)};
    }
  }

  &.comparing .rv-discrete-color-legend-item:first-child {
    color: ${colours.primaryColour};
  }

  &#salary_growth_chart {
    position: relative;

    .rv-hint .tooltip_wrapper {
      width: 109px;
      margin-left: 36px;
      padding-bottom: 24px;
      display: block;
      bottom: 50%;
      text-align: left;

      .tooltip {
        padding: 8px 4px;
        color: ${colours.warningColour};
        font-size: 12px;

        &:after {
          width: 10px;
          height: 10px;
          bottom: -5px;
          left: 20%;
        }
      }
    }
  }

  &#salary_range_chart .label_series text:nth-child(2) {
    text-anchor: start;
  }

  .rv-discrete-color-legend {
    margin-top: 5px;
    position: absolute;
    top: -20px;
    right: 0px;
    color: ${colours.fontColour};

    .rv-discrete-color-legend-item {
      height: 100%;
      display: flex;
      align-items: center;

      svg {
        height: 100%;
      }

      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: ${typography.bold};
      }
    }
  }
`;
