import { CompanyStoryLocation, OperatingCountry } from '@cohiretech/common-types';

export const getCompanyStoryLocationName = (country: OperatingCountry): CompanyStoryLocation => {
  switch (country) {
    case OperatingCountry.EU:
      return CompanyStoryLocation.Remote;
    case OperatingCountry.US:
      return CompanyStoryLocation.UnitedStates;
    default:
      return CompanyStoryLocation.UnitedKingdom;
  }
};

export const isLocalisedURL = (path: string) => path?.match(/\/u[ks]\//i);

export const getLocalisationCountryFromURL = (path: string) => {
  if (isLocalisedURL(path)) {
    return path.split('/')[1].toUpperCase() as OperatingCountry;
  }

  return null;
};
