import React, { CSSProperties } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import colours from 'css/base/_colours.module.scss';

type Props = {
  prevEnabled?: boolean;
  nextEnabled?: boolean;
  onPrev: () => void;
  onNext: () => void;
  color?: CSSProperties['color'];
  haveBorder?: boolean;
};

export default function CarouselArrows({
  prevEnabled = true,
  nextEnabled = true,
  onPrev,
  onNext,
  color,
  haveBorder
}: Props) {
  return (
    <ArrowCont className="carousel_arrows" color={color} border={haveBorder}>
      <span
        className={ClassNames('icon_arrow_left', { disabled: !prevEnabled })}
        onClick={onPrev}
      />
      <span
        className={ClassNames('icon_arrow_right', { disabled: !nextEnabled })}
        onClick={onNext}
      />
    </ArrowCont>
  );
}

const ArrowCont = styled.div<{ color?: CSSProperties['color']; border?: boolean }>`
  position: absolute;
  z-index: 2;
  bottom: 20px;
  right: 20px;

  > span {
    font-weight: bold;
    color: ${({ color }) => color || colours.primaryColour};
    opacity: 0.9;
    cursor: pointer;

    ${({ border }) =>
      border &&
      css`
        padding: 10px;
        border: 2px solid white;
        border-radius: 50px;
      `}

    &:hover {
      opacity: 1;
      color: ${({ color }) => color || colours.primaryDarkShade};
    }

    &:first-child {
      margin-right: 15px;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;

      &:hover {
        opacity: 0.4;
        color: ${({ color }) => color || colours.primaryColour};
      }
    }
  }
`;
