/**
 * Returns a function that checks truthiness of attribute
 * @template K - The attribute name.
 * @param {T} O - The object to check against.
 * @returns {(b: T) => boolean} A function that takes a value and returns true if b[a] is truthy, false otherwise.
 */
export const is =
  <K extends keyof O, O>(a: K) =>
  (b: O): boolean =>
    !!b[a];

/**
 * Returns a function that checks falsyness of attribute
 * @template K - The attribute name.
 * @param {T} O - The object to check against.
 * @returns {(b: T) => boolean} A function that takes a value and returns true if b[a] is falsy, true otherwise.
 */
export const isNot =
  <K extends keyof O, O>(a: K) =>
  (b: O): boolean =>
    !b[a];
