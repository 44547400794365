import React from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';

import { Currency, SearchResponseListing } from '@cohiretech/common-types';

import { setAcronym, getSalaryRange, getRemoteLocationsLabel } from 'utils';
import { getVisibleSalaryRange } from 'v2/services/tools/salary';

import LabelWithIcon from 'components/labelwithicon';

type Props = {
  className?: string;
  location?: string;
  remoteOptions?: string[];
  remoteLocationContinents?: string[];
  remoteLocationCountries?: string[];
  salary?: {
    salaryMin?: number;
    salaryMax?: number;
    currency?: Currency;
    salaryVisible?: boolean;
    seniorities?: string[];
  };
  ote?: Partial<
    Pick<SearchResponseListing, 'oteSalaryMin' | 'oteSalaryMax' | 'oteSalaryMaxUncapped'>
  >;
};

function PositionDetailsPreview({
  className,
  location,
  remoteOptions = [],
  salary,
  remoteLocationContinents = [],
  remoteLocationCountries = [],
  ote
}: Props) {
  const { salaryMin, salaryMax, currency, salaryVisible, seniorities } = salary || {};
  const { oteSalaryMin, oteSalaryMax, oteSalaryMaxUncapped } = ote || {};
  const remoteLabel = getRemoteLocationsLabel(
    remoteOptions,
    remoteLocationContinents,
    remoteLocationCountries
  );
  const salaryLabel = getSalaryRange(
    salaryMin,
    salaryMax,
    currency,
    salaryVisible,
    seniorities,
    'short'
  );
  const oteRange =
    oteSalaryMin &&
    getVisibleSalaryRange({
      currency,
      salaryMin: oteSalaryMin,
      salaryMax: oteSalaryMax,
      oteSalaryMaxUncapped,
      format: 'short'
    });

  return (
    <PositionDetails className={ClassNames('position_details', className)}>
      {location && !(remoteOptions.length === 1 && remoteOptions.includes('remote')) && (
        <>
          <LabelWithIcon icon="icon_map" text={setAcronym(location)} />
          &nbsp; · &nbsp;
        </>
      )}
      {remoteOptions.includes('remote') && (
        <>
          <LabelWithIcon icon="icon_earth" text={remoteLabel} />
          &nbsp; · &nbsp;
        </>
      )}
      <LabelWithIcon
        icon={salaryVisible ? 'icon_salary' : 'icon_experience'}
        text={`${salaryLabel}${oteRange ? `(${oteRange} OTE)` : ''}`}
      />
    </PositionDetails>
  );
}

const PositionDetails = styled.div`
  .label_with_icon {
    margin: 0;
  }
`;

export default PositionDetailsPreview;
