import React from 'react';
import { Location } from 'history';

import { useDarkMode } from 'hooks/useDarkMode';

import Seo from 'components/seo';
import CustomerStorySliderSection from 'components/customerstoryslidersection';

import DemoButtonSection from 'v2/components/DemoButtonSection';
import IntroWithTagline from 'v2/components/IntroWithTagline';

import PulseIcon from 'images/dot-pulse-animation.svg';

import HiresCompaniesSection from './hirescompaniessection';
import HiresGraphSection from './graphsection';

type Props = {
  location: Location;
};

const HiresPage = ({ location }: Props) => {
  const darkMode = useDarkMode();
  return (
    <>
      <div className="min_view center_alignment">
        <IntroWithTagline
          tagline={{ icon: { src: PulseIcon, name: 'Pulse Icon' }, text: 'LIVE HIRES' }}
          heading="Direct hires happening right now on cord"
          subheading="Understand the value of cord through live data."
        />
        <HiresCompaniesSection />
      </div>
      <HiresGraphSection darkMode={darkMode} />
      <CustomerStorySliderSection darkModeBgColour="dark" />
      <DemoButtonSection type="make_direct_hires" />
      <Seo
        title="Live Hires"
        description="Direct hires happening right now on cord."
        path={location.pathname}
        contentType="website"
      />
    </>
  );
};

export default HiresPage;
