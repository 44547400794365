import { clearIndexedDBData } from 'indexedDB';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from 'store';
import { persistState, retrieveState } from 'store/persist';
import { IndexDBTable } from 'types';
import { isEmpty } from 'utils';
import { assocPath } from 'utils/fn';
import { capitalizeFirst } from 'utils/string';
import { isSuccess } from 'v2/services/fetchers/apiTools';
import {
  CandidatesInStreamCount,
  getCandidatesInStreamCount,
  getCompanyPositionViewApplications,
  getCompanyPositionViewConversationsGraph,
  getCompanyPositionViewListings,
  getCompanyPositionViewStreams,
  PositionViewConversationsGraph,
  PositionViewListingActivity,
  PositionViewListings,
  PositionViewStreams,
  UserScope
} from 'v2/services/fetchers/company/positionview';

import {
  getInitialPositionsState,
  resetPositionsCached,
  setHasFetched,
  setPositionStreamCandidateCount,
  setListings,
  setActivity,
  setStreams,
  setUnsafeCached,
  togglePositionsLoader,
  setHasRetrievedCached,
  setConversations
} from './positions';
import {
  selectCachedCandidateCount,
  selectFilterParamsByName,
  selectPerListingLoadState,
  selectPositionsLoader
} from './positions.selectors';
import { PositionViewData, PositionViewUserSlice } from './positions.types';

const DATA_STRATEGY = {
  listings: {
    fetch: getCompanyPositionViewListings,
    set: setListings
  },
  activity: {
    fetch: getCompanyPositionViewApplications,
    set: setActivity
  },
  streams: {
    fetch: getCompanyPositionViewStreams,
    set: setStreams
  },
  conversations: {
    fetch: getCompanyPositionViewConversationsGraph,
    set: setConversations
  }
};

export const loadPositionsViewData = (typeKey: Exclude<PositionViewData, 'candidateCount'>) =>
  createAsyncThunk<
    | PositionViewListings
    | PositionViewListingActivity[]
    | PositionViewStreams
    | PositionViewConversationsGraph
    | undefined,
    { userScope: UserScope; listingIDs?: number[]; page?: number; status?: 'open' | 'closed' },
    { dispatch: AppDispatch; state: RootState }
  >(
    `positions/loadData/${typeKey}`,
    async ({ userScope, listingIDs = [], page = 0 }, { dispatch, getState }) => {
      const state = getState();
      if (isCategoryLoading(state, userScope, typeKey)) return;

      const status = selectFilterParamsByName('status')(state) ? 'open' : 'closed';
      dispatch(togglePositionsLoader({ loader: typeKey, userScope, value: true }));
      const strategy = DATA_STRATEGY[typeKey];
      const response = await strategy.fetch({ userScope, listingIDs, page, status });

      if (isSuccess(response as any)) {
        const data = (response as any).data;
        dispatch(strategy.set({ userScope, data, page }));

        await dispatch(persistPositionsData(typeKey)({ userScope, data }));
        finishLoading(dispatch)(userScope, typeKey);
        return data;
      }
      finishLoading(dispatch)(userScope, typeKey);
    }
  );

export const loadPositionsViewCandidateCount = createAsyncThunk<
  CandidatesInStreamCount | undefined,
  { userScope: UserScope; listingID: number; feedIDs: number[] },
  { dispatch: AppDispatch; state: RootState }
>(
  `positions/loadData/candidateCount`,
  async ({ feedIDs, listingID, userScope }, { dispatch, getState }) => {
    const typeKey: PositionViewData = 'candidateCount';
    if (isCategoryLoading(getState(), userScope, typeKey, listingID)) return;

    dispatch(togglePositionsLoader({ loader: typeKey, userScope, value: true, listingID }));
    const response = await getCandidatesInStreamCount({ searchIDs: feedIDs });

    if (isSuccess(response) && response.data) {
      dispatch(setPositionStreamCandidateCount({ userScope, listingID, value: response.data }));
      const candidateCounts = selectCachedCandidateCount(userScope)(getState()) || {};
      const countsToCache = { ...candidateCounts, [listingID]: response.data };

      await dispatch(persistPositionsData(typeKey)({ userScope, data: countsToCache }));

      finishLoading(dispatch)(userScope, typeKey, listingID);
      return response.data;
    }
    finishLoading(dispatch)(userScope, typeKey, listingID);
  }
);

const persistPositionsData = (typeKey: PositionViewData) =>
  createAsyncThunk<
    boolean,
    { userScope: UserScope; data?: PositionViewUserSlice[PositionViewData] },
    { dispatch: AppDispatch; state: RootState }
  >(`positions/persist${capitalizeFirst(typeKey)}`, async ({ userScope, data }) => {
    if (!data) return false;
    const response = await persistState(data, `positions.${userScope}.${typeKey}` as IndexDBTable);
    return response;
  });

const isCategoryLoading = (
  state: RootState,
  userScope: UserScope,
  type: PositionViewData,
  listingID?: number
) => {
  if (listingID && type === 'candidateCount') {
    return selectPerListingLoadState(listingID)(userScope)(state);
  }
  return selectPositionsLoader(userScope)(type)(state);
};

const finishLoading =
  (dispatch: AppDispatch) => (userScope: UserScope, type: PositionViewData, listingID?: number) => {
    dispatch(togglePositionsLoader({ loader: type, userScope, value: false, listingID }));
    dispatch(setHasFetched({ userScope, status: type }));
  };

export const resetPositionsCache = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>('positions/resetPositionsCache', async (_, { dispatch }) => {
  const keys = [
    'positions.user.listings',
    'positions.other.listings',
    'positions.all.listings',
    'positions.user.activity',
    'positions.other.activity',
    'positions.all.activity'
  ];

  await Promise.all(keys.map(async key => clearIndexedDBData(key as IndexDBTable)));
  dispatch(resetPositionsCached());
});

// Todo: clean up this function
export const loadCachedPositions = createAsyncThunk<
  boolean,
  void,
  { dispatch: AppDispatch; state: RootState }
>('positions/loadCachedPositions', async (_, { dispatch }) => {
  let emptyState = getInitialPositionsState({});
  const hadCached: [UserScope, PositionViewData, any][] = [];

  const promiseArray = ['user', 'other', 'all'].map(async userScope => {
    const promises = ['listings', 'activity', 'conversations', 'streams', 'candidateCount'].map(
      async loader => {
        const data = await retrieveState(`positions.${userScope}.${loader}` as IndexDBTable);
        if (data) {
          hadCached.push([userScope as UserScope, loader as PositionViewData, data]);
          emptyState = assocPath(emptyState, ['cached', userScope, loader], data);
          // }
        }
        return data;
      }
    );
    return Promise.all(promises);
  });

  await Promise.all(promiseArray);

  if (!isEmpty(hadCached)) {
    hadCached.forEach(([userScope, loader, value]) => {
      dispatch(setUnsafeCached({ userScope, loader, value }));
    });
  }

  dispatch(setHasRetrievedCached(true));

  return !isEmpty(hadCached);
});
