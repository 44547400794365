import React, { useRef, useState } from 'react';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useDarkMode } from 'hooks/useDarkMode';
import { useOnScreen } from 'hooks/useOnScreen';
import { generateCompanyURL, setAcronym } from 'utils';
import { getPricingPlanDetails } from 'utils/companyUtils';
import { setLocalStorageItem } from 'cookieManager';
import { identifyCompanyType } from 'v2/services/company/companyType';

import ActivityInfo, { InfoWrapper } from 'components/activityinfo';
import Tooltip from 'components/tooltip';
import VerifyButtons from 'components/verifybuttons';
import VideoItem from 'components/videoitem';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import Avatar from 'v2/components/ui/atoms/Avatar';

import NoImage from 'images/no-image-cover.png';

export default function CompanyItem({
  verifyCompany,
  companyID,
  companyEmail,
  pictureUrls,
  companyName,
  companyLogo,
  locationCity,
  width,
  companyLastActive,
  responseRate,
  columns,
  industry,
  fundingStage,
  videoUrl,
  locationData,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  isVerifyPage,
  showVideos,
  loading,
  removeItem,
  subscriptionPlan,
  size,
  companyType
}: $TSFixMe) {
  const [emailCopied, setEmailCopied] = useState(false);
  const coverPhotoRef = useRef(document.createElement('img'));
  const coverPhotoOnScreen = useOnScreen(coverPhotoRef);
  const darkMode = useDarkMode();

  const { isDirect, isExternal } = identifyCompanyType(companyType);

  const onCopy = () => {
    setEmailCopied(true);

    setTimeout(() => setEmailCopied(false), 1000);
  };

  const onItemClicked = (e: $TSFixMe) => {
    if (
      !e.target.className.includes('video') &&
      e.target.id !== 'reject_account' &&
      e.target.id !== 'accept_account'
    ) {
      setLocalStorageItem('profileHistoryLength', 1);
    } else {
      e.preventDefault();
    }
  };

  const getCompanyInfo = () => {
    if (isExternal) return `${locationCity}`;

    const info = isVerifyPage
      ? [
          getPricingPlanDetails(subscriptionPlan).label || 'No Active Plan',
          size,
          setAcronym(locationCity)
        ]
      : [setAcronym(locationCity), industry?.join(', '), fundingStage];

    return info.filter(val => !!val).join('\u00A0 · \u00A0');
  };

  const companyInfo = getCompanyInfo();

  if (loading) {
    return (
      <div
        className={ClassNames('square_card_item', 'company_item', {
          four_cols_item: columns === 4,
          three_cols_item: columns === 3,
          two_cols_item: columns === 2,
          dark: darkMode
        })}
      >
        <div className="cover_photo">
          <Skeleton height={250} duration={2} />
        </div>
        <div className="card_details">
          {!isDirect && (
            <div className="loading_picture">
              <Skeleton circle width={75} height={75} duration={2} />
            </div>
          )}
          <div className="card_info">
            <h3 className="card_title loading_title">
              <Skeleton height={25} duration={2} />
            </h3>
            <div className="card_subtitle">
              <Skeleton height={15} duration={2} />
            </div>
            <InfoWrapper className="activity_info">
              <Skeleton width={150} height={15} duration={2} />
            </InfoWrapper>
          </div>
        </div>
      </div>
    );
  } else {
    const picture = (pictureUrls && pictureUrls[0]) || NoImage;
    const companyData = { companyName, companyID };
    const url = generateCompanyURL(locationData, companyData);

    return (
      <Link
        className={ClassNames('square_card_item', 'company_item', 'clickable', {
          four_cols_item: columns === 4,
          three_cols_item: columns === 3,
          two_cols_item: columns === 2,
          dark: darkMode
        })}
        onClick={e => onItemClicked(e)}
        to={isVerifyPage ? '#' : { pathname: url, search: locationData.search }}
        style={{ width: `${width}%` }}
        onTouchStart={
          onTouchStart ||
          (() => {
            return;
          })
        }
        onTouchMove={
          onTouchMove ||
          (() => {
            return;
          })
        }
        onTouchEnd={
          onTouchEnd ||
          (() => {
            return;
          })
        }
      >
        <ConditionalRender predicate={isDirect}>
          {showVideos ? (
            <VideoItem
              videoUrl={videoUrl}
              fallbackPhotoURL={picture}
              youtubeOptions={{
                width: '100%',
                height: '250',
                title: `${companyName} thumbnail video`
              }}
            />
          ) : (
            <img
              ref={coverPhotoRef}
              className="cover_photo"
              src={picture}
              alt={`${companyName} is hiring on cord`}
              loading={coverPhotoOnScreen ? 'eager' : 'lazy'}
              width="390"
              height="250"
            />
          )}
        </ConditionalRender>
        <div className={ClassNames('card_details', { secondary: !isDirect })}>
          <Avatar
            className="card_profile_picture company_logo"
            photoURL={companyLogo}
            alt={`${companyName} logo`}
            width={75}
            lastActive={companyLastActive}
          />
          <div className="card_info">
            <h3 className="card_title">
              {companyName}
              <ConditionalRender predicate={isVerifyPage}>
                {' '}
                <span className="light">(ID: {companyID})</span>
              </ConditionalRender>
            </h3>
            {companyInfo && <div className="card_subtitle">{companyInfo}</div>}
            <ConditionalRender
              predicate={isVerifyPage}
              fallback={
                <ActivityInfo
                  industries={isExternal ? industry : undefined}
                  lastActive={companyLastActive}
                  responseRate={responseRate}
                  noResponseRateFallbackText="Recently joined!"
                />
              }
            >
              <InfoWrapper>
                <CopyToClipboard text={companyEmail} onCopy={onCopy}>
                  <span className="lightblue_text">{emailCopied ? 'Copied!' : companyEmail}</span>
                </CopyToClipboard>
                <Tooltip text={emailCopied ? 'Copied!' : 'Click to copy'} width="130px" />
              </InfoWrapper>
            </ConditionalRender>
          </div>
        </div>
        {isVerifyPage && (
          <VerifyButtons
            // @ts-expect-error TS(2322) FIXME: Type '{ user: string; signupComplete: true; verify... Remove this comment to see the full error message
            user="company"
            signupComplete
            verifyUserAccount={() => verifyCompany(true)}
            rejectUserAccount={() => verifyCompany(false)}
            removeItem={() => removeItem()}
          />
        )}
      </Link>
    );
  }
}
