import styled, { css } from 'styled-components';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

// prettier-ignore
type Numbers1To30 =
  '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' |
  '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' |
  '21' | '22' | '23' | '24' | '25' | '26' | '27' | '28' | '29' | '30';

export type SpacingProps = {
  height?: Numbers1To30;
  width?: Numbers1To30;
  predicate?: unknown;
};

export default function Spacing({ height, width, predicate = true }: SpacingProps) {
  return (
    <ConditionalRender predicate={predicate}>
      <SpacingWrapper
        height={height}
        width={width}
        className={`component-spacing-${width || 0}-${height || 0}`}
      />
    </ConditionalRender>
  );
}

const SpacingWrapper = styled.div<{ height?: string; width?: string }>`
  ${({ height, width }) => css`
    ${height && `height: var(--spacing-${height})`};
    ${width && `width: var(--spacing-${width})`};
  `}
`;
