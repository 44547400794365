import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PropertyChain, UserProfileType } from 'types';
import { getLocalStorageItem } from 'cookieManager';
import { assocPath, ensureArrayForm, isNull, isUndefined, path } from 'utils/fn';
import { last, lastIndex } from 'utils/array';
import { BubbleName, getDemoCompanyTour } from 'v2/services/company/tour';

import {
  CANDIDATE_TOUR,
  ComponentLoaders,
  ComponentTogglers,
  CSSVariables,
  LOADER_TREE
} from './ui.consts';
import { loadPersistedDevFeatures } from './ui.helpers';

export type DemoCompanyTourPage = { hash: string; pathname: string };

export type DemoCompanyTour = {
  name: string;
  page: DemoCompanyTourPage;
  shown: boolean;
  show?: boolean;
}[];

export type CandidateBubbleName =
  | 'discover'
  | 'search'
  | 'savedsearch'
  | 'map'
  | 'insights'
  | 'messages'
  | 'tracker'
  | 'profile'
  | 'templates'
  | 'settings';

export type CandidateTourBubble = {
  name: CandidateBubbleName;
  page:
    | string
    | {
        hash: string;
        pathname: string;
      };
  shown: boolean;
};

export type CandidateTour = CandidateTourBubble[];

export interface UIState {
  candidatesToVerify?: number;
  candidateTour: CandidateTour;
  companiesToVerify?: number;
  cssVariables: CSSVariables;
  darkMode: boolean;
  demoCompanyTour: DemoCompanyTour;
  initialSetupMode: boolean;
  loaders: ComponentLoaders;
  receivedRequestsPending?: number;
  sessionEndedPopup: boolean;
  setupMode: boolean;
  showCandidateTour: boolean;
  showOfflineNotification: boolean;
  showReactivationModal: boolean;
  showStartUsingCordPopup: boolean;
  togglers: ComponentTogglers;
}

const initialState: UIState = {
  candidateTour: getLocalStorageItem<CandidateTour>('candidateTour') || CANDIDATE_TOUR,
  cssVariables: {},
  darkMode: false,
  demoCompanyTour: getDemoCompanyTour(),
  initialSetupMode: getLocalStorageItem('initial_setup_mode') || false,
  loaders: LOADER_TREE,
  receivedRequestsPending: -1,
  sessionEndedPopup: false,
  setupMode: getLocalStorageItem('setup_mode') || false,
  showCandidateTour: false,
  showOfflineNotification: false,
  showReactivationModal: false,
  showStartUsingCordPopup: false,
  togglers: loadPersistedDevFeatures()
};

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    persistTheme: state => state, // This is a no-op/signal -> shows in redux devtools
    decrementCompaniesToVerify: state => {
      if (state.companiesToVerify) state.companiesToVerify -= 1;
    },
    toggleShowCandidateTour: (state, action: PayloadAction<boolean | undefined>) => {
      state.showCandidateTour = action.payload ?? !state.showCandidateTour;
    },
    toggleShowReactivationModal: (state, action: PayloadAction<boolean | undefined>) => {
      state.showReactivationModal = action.payload ?? !state.showReactivationModal;
    },
    toggleShowOfflineNotification: (state, action: PayloadAction<boolean | undefined>) => {
      state.showOfflineNotification = action.payload ?? !state.showOfflineNotification;
    },
    toggleShowStartUsingCordPopup: (state, action: PayloadAction<boolean | undefined>) => {
      state.showStartUsingCordPopup = action.payload ?? !state.showStartUsingCordPopup;
    },
    toggleSessionEndedPopup: (state, action: PayloadAction<boolean | undefined>) => {
      state.sessionEndedPopup = action.payload ?? !state.sessionEndedPopup;
    },
    setCandidateTour: (state, action: PayloadAction<CandidateTour>) => {
      state.candidateTour = action.payload;
    },
    removeCandidateTourBubble: (state, action: PayloadAction<string>) => {
      const { candidateTour } = state;
      const bubbleIndex = candidateTour.findIndex(({ name }) => name === action.payload);

      if (bubbleIndex === -1) return;

      candidateTour.splice(bubbleIndex, 1);
    },
    setCSSReduxVariable: (
      state,
      action: PayloadAction<{
        name: string;
        value: string;
        type: UserProfileType;
      }>
    ) => {
      const { name, value, type = 'public' } = action.payload;
      if (isNull(type)) return;

      const variableName = name.replace('--', '');

      const cssVariables = assocPath(state.cssVariables, [type, variableName], value);

      state.cssVariables = cssVariables;
    },
    setDemoCompanyTour: (state, action: PayloadAction<DemoCompanyTour>) => {
      state.demoCompanyTour = action.payload;
    },
    setDemoTourBubbleShown: (state, action: PayloadAction<BubbleName>) => {
      const bubble = state.demoCompanyTour.find(({ name }) => name === action.payload);
      if (!bubble) return;

      bubble.shown = true;
    },
    setSetupMode: (state, action: PayloadAction<boolean>) => {
      state.setupMode = action.payload;
    },
    setInitialSetupMode: (state, action: PayloadAction<boolean>) => {
      state.initialSetupMode = action.payload;
    },
    setCompaniesToVerify: (state, action: PayloadAction<number>) => {
      state.companiesToVerify = action.payload;
    },
    setCandidatesToVerify: (state, action: PayloadAction<number>) => {
      state.candidatesToVerify = action.payload;
    },
    setLoader: (state, action: PayloadAction<{ route?: string[]; value: boolean }>) => {
      const { route, value } = action.payload;
      let loaderCrawler: any = state.loaders;

      if (!route) {
        loaderCrawler['/'].main = value;
        return;
      }
      const loaderName = last(route);
      if (path(loaderCrawler, route) === undefined || !loaderName) return;

      route.forEach((level, i) => {
        if (i === lastIndex(route)) return;
        loaderCrawler = loaderCrawler[level];
      });

      loaderCrawler[loaderName] = value;
    },
    setToggler: (state, action: PayloadAction<{ route?: PropertyChain; value?: boolean }>) => {
      const { route: propertyRoute, value } = action.payload;
      let toggleCrawler: any = state.togglers;

      if (!propertyRoute) {
        return;
      }
      const route = ensureArrayForm(propertyRoute);

      const togglerName = last(route);
      if (!togglerName) return;

      if (isUndefined(path(state.togglers, route))) {
        // the toggle doesn't exist yet, create it
        state.togglers = assocPath(state.togglers, route, false);
        toggleCrawler = state.togglers;
      }

      route.forEach((level, i) => {
        if (i === lastIndex(route)) return;
        toggleCrawler = toggleCrawler[level];
      });

      toggleCrawler[togglerName] = value ?? !toggleCrawler[togglerName];
    },
    setReceivedRequestsPending: (state, action: PayloadAction<number>) => {
      state.receivedRequestsPending = action.payload;
    },
    toggleSetupMode: (state, action: PayloadAction<boolean | undefined>) => {
      state.setupMode = action.payload ?? !state.setupMode;
    },
    toggleInitialSetupMode: (state, action: PayloadAction<boolean | undefined>) => {
      state.initialSetupMode = action.payload ?? !state.initialSetupMode;
    }
  }
});

// Action creators
export const { persistTheme, setDarkMode } = ui.actions; // used within store/ui
export const { actions } = ui; // used outside of store/ui

export default ui.reducer;
