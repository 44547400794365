import { getProInsights } from 'v2/services/fetchers/candidate/insights';

export const getPositionInsightsData = async (listingID: number) => {
  const data = await getProInsights({
    listingID,
    globalAcceptedCandidatesAverageMatchScore: true,
    positionConversationsInsights: true,
    positionDeclinedConversationsReasons: true,
    positionMatchScoresRanges: true,
    positionAcceptedConversationsPrimarySkills: true,
    positionAcceptedConversationsMatchScoreSummary: true,
    positionSalaryRanges: true,
    candidateMatchScore: true,
    candidateTopRank: true,
    candidatePositionMatchScoreAboveYourScorePercentage: true
  });

  return data;
};

export const getProfileMatchScore = async (listingID: number) => {
  const data = await getProInsights({
    listingID,
    candidateMatchScore: true
  });

  return data;
};
