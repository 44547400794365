/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import { AnyAsyncFunction, MilliSeconds } from 'types';

import {
  MaxRetriesExceededError,
  OperationCancelledError,
  delayWithCancellation
} from './retry.helpers';

export interface RetryOptions {
  maxRetries?: number;
  delay?: MilliSeconds;
  maxDelay?: MilliSeconds;
  stopOnError?: boolean;
  silent?: boolean;
  signal?: AbortSignal;
}

/**
 * Retries the async function passed as the argument, with the given options and validation.
 *
 * @template T - The expected return type of the async function.
 * @param {AnyAsyncFunction<T>} fn - The async function to be executed and retried.
 * @param {(data: T) => boolean} [validate=() => true] - Optional validation function to check if the output of the async function is valid.
 * @param {RetryOptions} [options={}] - Optional options object to customize retry behavior.
 * @param {number} [options.maxRetries=7] - Number of maximum retries to be attempted before giving up.
 * @param {MilliSeconds} [options.delay=500] - Delay (in milliseconds) between retry attempts.
 * @param {MilliSeconds} [options.maxDelay=2 ** 15] - Maximum delay (in milliseconds) between retry attempts.
 * @param {boolean} [options.stopOnError=true] - Whether to stop retrying if an error occurs in the validation function.
 * @param {boolean} [options.silent=true] - If set to false, logs each failed attempt along with errors.
 * @param {AbortSignal} [options.signal] - Optional AbortSignal to be used to control the cancellation of the async calls.
 * @returns {Promise<T>} - The resolved value from the async function, after it passes validation.
 * @throws {MaxRetriesExceededError} - Throws a MaxRetriesExceededError when function retry exceeds the maxRetries value.
 * @throws {OperationCancelledError} - Throws an OperationCancelledError when the signal aborts the operation.
 *
 * @example
 * // An example of how to use the retry function with an async fetch operation:
 * const fetchFunction = async () => axios.get(SKILL_LIST_URL);
 * const validateFunction = (response) => response?.data?.data?.length > 0;
 *
 * const requestData = await retry(fetchFunction, validateFunction, { maxRetries: 3, delay: 1000 });
 */
export async function retry<T = any>(
  fn: AnyAsyncFunction<T>,
  validate: (data: T) => boolean = () => true,
  {
    maxRetries = 7,
    delay = 500,
    maxDelay = 2 ** 15,
    stopOnError = true,
    silent = true,
    signal
  }: RetryOptions = {}
): Promise<T> {
  let lastError: Error | null = null;

  for (let i = 0; i < maxRetries + 1; i++) {
    if (signal?.aborted) {
      throw new OperationCancelledError();
    }
    let result;
    try {
      result = await fn();
    } catch (error) {
      lastError = error as Error;
      if (!silent) console.log(`Attempt ${i + 1} failed, retrying...`, error);
    }

    if (result) {
      try {
        if (validate(result)) {
          return result;
        }
      } catch (error) {
        if (stopOnError) {
          throw error;
        }
        if (!silent) console.log('Validation failed:', error);
      }
    }

    // Only delay if it's not the last attempt
    if (i < maxRetries) {
      await delayWithCancellation(i, delay, maxDelay, signal);
    }
  }

  throw new MaxRetriesExceededError(maxRetries, lastError);
}
