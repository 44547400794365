import { createSelector } from '@reduxjs/toolkit';
import { CompanySearchCandidateStatusEnum } from '@cohiretech/common-types';

import { RootState } from 'store';
import { isEmpty } from 'utils';
import { companySearchFilters as searchFilters } from 'fetcher';
import { existsInSearchItems } from 'v2/services/company/searchFilters';

import { getCandidatePoolSearchItems } from './discover.helpers';

const selectDiscover = (state: RootState) => state.discover;

export const selectSearchType = createSelector(selectDiscover, state => state.searchType);

export const selectSearchItems = createSelector(selectDiscover, state => state.searchItems);

export const selectVisibleFiltersCount = createSelector(
  selectSearchItems,
  searchItems => searchItems.length - 1 //minus sorting
);

const selectIsSearchResultsEmpty = createSelector(selectDiscover, state =>
  isEmpty(state.searchResults)
);

export const selectAllSearchResultsCounts = createSelector(
  selectDiscover,
  state => state.searchResultsCounts
);

export const selectSearchResultsCount = createSelector(
  selectDiscover,
  state => state.searchResultsCounts.resultCount
);

export const selectPassiveResultsCount = createSelector(
  selectDiscover,
  state => state.searchResultsCounts.passiveCount
);

export const selectActiveResultsCount = createSelector(
  selectDiscover,
  state => state.searchResultsCounts.activeCount
);

export const selectTotalResultsCount = createSelector(
  selectDiscover,
  state => state.searchResultsCounts.totalCount
);

export const selectNewOrReactivatedCandidateCount = createSelector(
  selectDiscover,
  state => state.newOrReactivatedCandidateCount
);

const selectPassivePeopleInResults = createSelector(selectSearchItems, searchItems =>
  existsInSearchItems(
    getCandidatePoolSearchItems([
      CompanySearchCandidateStatusEnum.Passive,
      CompanySearchCandidateStatusEnum.All
    ]),
    searchItems
  )
);

export const selectShowIncludePassivePeople = createSelector(
  [selectSearchType, selectPassiveResultsCount, selectPassivePeopleInResults],
  (searchType, passiveResultsCount, passivePeopleInResults) =>
    !passivePeopleInResults && (searchType === 'search' || passiveResultsCount > 0)
);

export const selectShowIncludePassivePeopleInEndingMessage = createSelector(
  [selectShowIncludePassivePeople, selectIsSearchResultsEmpty],
  (showIncludePassivePeople, isSearchResultsEmpty) =>
    showIncludePassivePeople && !isSearchResultsEmpty
);

export const selectIsNextResultsLoadEnabled = createSelector(
  selectDiscover,
  ({ loadingMore, endPage, loading }) => !loading && !endPage && !loadingMore
);

export const selectSearchErrorMessage = createSelector(selectDiscover, state => state.errorMessage);

export const selectDiscoverUIState = createSelector(selectDiscover, state => ({
  loading: state.loading,
  searchResultIds: state.searchResultIds,
  searchResults: state.searchResults,
  loadingMore: state.loadingMore
}));

export const selectMobileFiltersOn = createSelector(selectDiscover, state => state.mobileFiltersOn);

export const selectPendingHideCandidateID = createSelector(
  selectDiscover,
  state => state.pendingHideCandidateID
);

export const selectShowHidePerStreamPrompt = createSelector(
  selectDiscover,
  state => state.showHidePerStreamPrompt
);

export const selectCandidatePool = createSelector(selectSearchItems, searchItems => {
  const filter = searchItems.find(item => item.attribute === searchFilters.candidatePool.attribute);
  return filter?.value || 'active';
});

export const selectDiscoverLoading = createSelector(selectDiscover, state => state.loading);

export const selectTotalActiveCount = createSelector(
  selectDiscover,
  state => state.totalActiveCount
);
