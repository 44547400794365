import { useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { setAcronym, getRemoteLocationsLabel } from 'utils';
import { isUserLoggedIn } from 'utils/profile';
import { identifyCompanyType } from 'v2/services/company/companyType';
import { isRemote } from 'v2/services/company/position';
import { useDarkMode } from 'hooks/useDarkMode';
import { useCompanyType } from 'store';

import ImageSlider from 'components/imageslider';
import LabelWithIcon, { CompanyTypeLabelWithIcon } from 'components/labelwithicon';
import SocialMedia from 'components/socialmedia';
import Stats from 'components/profilestats';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import ClaimCompanyProfile from 'v2/components/ClaimCompanyProfile';
import MarkApplicationPopup from 'v2/components/MarkApplicationPopup';

import { getDataToRender } from './PositionProfile.helpers';
import PositionProfileTopSection from './components/PositionProfileTopSection';
import PositionContent from './PositionContent';
import { usePositionProfile } from './PositionProfile.context';

import './style.scss';

export default function PositionProfile() {
  const notProps = usePositionProfile();
  const {
    profile,
    companyName,
    position,
    pictureUrls,
    companyLogo,
    companyID,
    positionLocation,
    tagline,
    positionLastActive,
    lastActive,
    responseTime,
    responseRate,
    relevantSkills,
    recentConvoStartedCount,
    recentRequestsReceivedCount,
    outstandingRequestCount,
    description,
    primarySkills,
    secondarySkills,
    techStack,
    currency,
    salaryMin,
    salaryMax,
    salaryVisible,
    remote,
    remoteDays,
    remoteFirstOption,
    visaSponsorshipAvailable,
    visaSponsorship,
    companyURL,
    industry,
    equityMin,
    equityMax,
    interviewProcess,
    jobType,
    companyContact,
    candidateContact,
    jobTitles,
    updateProfileHistory,
    seniorities,
    url,
    twitterUrl,
    instagramUrl,
    facebookUrl,
    linkedinUrl,
    id,
    shortlistID,
    shortlistType,
    onShortlisted,
    goToSignup,
    videoURL,
    isCompanyUser,
    showPersonInfo,
    goToPersonsProfile,
    associatedMemberDetails,
    onProfileClose,
    loading,
    remoteLocationContinents,
    remoteLocationCountries,
    timezones,
    status,
    external,
    datePosted,
    claimState,
    lastCheckedDate,
    companyType,
    agencyClientCompany,
    personsURL,
    personsName,
    personsPhotoURL,
    isCandidateCreatedATPosition,
    ...rest
  } = notProps;
  const location = useLocation();
  const darkMode = useDarkMode();
  const isLoggedIn = isUserLoggedIn(profile);

  const { isDirect, isAgency } = identifyCompanyType(companyType);

  const hideCoverPhotos = !isDirect || profile?.inactivePlan;
  const isRemoteJob = isRemote(remote);

  const { smallHeading } = getDataToRender(notProps, isAgency, location);

  const { isAgency: userIsAgency } = useCompanyType();
  const hideDateInfo = userIsAgency && external;

  return (
    <PositionProfileCont
      className={ClassNames('position_profile', {
        dark: darkMode,
        external_listing: external,
        company_page: isCompanyUser,
        no_cover_photos: hideCoverPhotos
      })}
      isAgency={isAgency}
    >
      <ConditionalRender predicate={!hideCoverPhotos}>
        <div>
          <StyledImageSlider
            // @ts-expect-error
            loading={loading}
            imageURLs={pictureUrls || []}
            fallbackImage={companyLogo}
            companyName={companyName}
            darkArrows={darkMode}
            showSaveHideActions={false}
            showSendAction={profile?.isCandidate}
            listingID={id}
            shortlistID={shortlistID}
            shortlistType={shortlistType}
            goToSignup={goToSignup}
            onShortlisted={(s: $TSFixMe, t: $TSFixMe) => onShortlisted(id, s, t)}
            darkMode={darkMode}
            position={position}
            {...rest}
          />
        </div>
      </ConditionalRender>
      <PositionProfileTopSection />
      {loading ? (
        <div className="profile_content">
          <h2 className="position_name">
            <Skeleton width={300} height={30} duration={2} />
          </h2>
          <div className="location">
            <Skeleton width={300} height={15} duration={2} />
          </div>
          <div className="company_description">
            <Skeleton height={20} count={2} duration={2} />
          </div>
          <Stats loading={loading} />
          <h1 className="title">
            <Skeleton width={250} height={30} duration={2} />
          </h1>
          <div className="description">
            <Skeleton height={20} count={5} duration={2} />
          </div>
        </div>
      ) : (
        <div className="profile_content">
          <h3 className="company_name">{smallHeading}</h3>
          <h2 className="position_name">{position}</h2>
          <div className="location">
            <ConditionalRender
              predicate={!isCandidateCreatedATPosition}
              fallback={
                <LabelWithIcon
                  icon="icon_web_search"
                  text="Manually added"
                  tag
                  size="small"
                  color="blue"
                />
              }
            >
              <CompanyTypeLabelWithIcon companyType={companyType} profileType="position" />
            </ConditionalRender>
            {positionLocation && !(isRemoteJob && remote.length === 1) && (
              <LabelWithIcon icon="icon_map" text={setAcronym(positionLocation)} tag size="small" />
            )}
            {isRemoteJob && (
              <LabelWithIcon
                icon="icon_earth"
                text={getRemoteLocationsLabel(
                  remote,
                  remoteLocationContinents,
                  remoteLocationCountries
                )}
                tag
                size="small"
              />
            )}
            <ConditionalRender predicate={!isAgency && !isCandidateCreatedATPosition}>
              <SocialMedia
                website={url}
                linkedinURL={linkedinUrl}
                facebookURL={facebookUrl}
                twitterURL={twitterUrl}
                instagramURL={instagramUrl}
              />
            </ConditionalRender>
          </div>
          <ConditionalRender predicate={external && !isLoggedIn}>
            <ClaimCompanyProfile
              companyID={companyID}
              companyName={companyName}
              claimState={claimState}
            />
          </ConditionalRender>
          <ConditionalRender predicate={!isAgency && !isCandidateCreatedATPosition}>
            <div className="industries">{industry?.join(' · ')}</div>
            <div className="company_description">{tagline}</div>
          </ConditionalRender>
          {status !== 'archived' ? (
            <Stats
              loading={loading}
              lastActive={{
                label: showPersonInfo || hideDateInfo ? '' : positionLastActive || lastActive,
                tooltip: isCompanyUser
                  ? 'Last time a member of the hiring team of this position was active on cord'
                  : ''
              }}
              avgResponseTime={
                !external
                  ? {
                      label: showPersonInfo ? '' : responseTime,
                      tooltip: isCompanyUser
                        ? 'Average time taken to respond to incoming requests in the last 30 days.'
                        : ''
                    }
                  : undefined
              }
              responseRate={
                !external
                  ? {
                      label: responseRate,
                      tooltip: isCompanyUser
                        ? 'Percentage of incoming message requests that hiring team members have responded to in the last 30 days.'
                        : ''
                    }
                  : undefined
              }
              conversations={{
                label: recentConvoStartedCount,
                tooltip: isCompanyUser
                  ? 'Total number of conversations started about this position'
                  : ''
              }}
              outstandingApplications={
                !external
                  ? {
                      label: outstandingRequestCount,
                      tooltip: isCompanyUser
                        ? 'Total number of pending applications for this position'
                        : ''
                    }
                  : undefined
              }
              datePosted={external && !hideDateInfo ? { label: datePosted } : undefined}
              lastCheckedDate={
                external && lastCheckedDate
                  ? {
                      label: lastCheckedDate,
                      tooltip: 'cord regularly checks that external positions are still open'
                    }
                  : undefined
              }
            />
          ) : (
            <ArchivedMessage className="notification_message" isAgency={isAgency}>
              The company is no longer hiring for this position.
            </ArchivedMessage>
          )}
          <PositionContent />
        </div>
      )}
      <MarkApplicationPopup id={id} companyID={companyID} />
    </PositionProfileCont>
  );
}

const PositionProfileCont = styled.div<{ isAgency: boolean }>`
  &.position_profile {
    ${({ isAgency }) =>
      isAgency &&
      css`
        .location {
          margin-bottom: 0;
        }
      `}
  }
`;

const ArchivedMessage = styled.div<{ isAgency: boolean }>`
  ${({ theme: { typography }, isAgency }) => css`
    font-weight: ${typography.bold};
    padding: 15px 10px;
    border-radius: 25px;
    background-color: var(--background-body-secondary);
    color: var(--text-body-tertiary);
    margin-top: ${isAgency ? '1.25rem' : 0};
  `}
`;

const StyledImageSlider = styled(ImageSlider)`
  border-radius: 2rem;
`;
