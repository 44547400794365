import {
  MouseEvent,
  PropsWithChildren,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { ATTRIBUTE_PARENT_MAP } from 'consts/candidate/positionSearch';

import { getMatchingResults, isEmpty } from 'utils';
import { Operator, OptionalToRequired, SearchItem } from 'types';
import { checkIfTextFilter, parseSearchItemLabel } from 'v2/services/tools/positionSearchFilter';

import { SearchInputInstance } from 'v2/components/ui/molecules/SearchInput';

import {
  ExpandableFilterProps,
  FilterOption,
  FilterOptions,
  FilterOptionsGroups,
  RadioOption
} from './ExpandableFilter.helpers';

type ExpandableFilterContextValue = {
  isOpen: boolean;
  isExpanded: boolean;
  operator: Operator;
  filterOptionsGroups?: FilterOptionsGroups;
  selectedItems?: FilterOption[];
  matchingOptions?: FilterOption[];
  filtersContRef: RefObject<HTMLDivElement>;
  searchInputRef: RefObject<SearchInputInstance>;
  handleInputUpdate: (searchWord: string) => void;
  toggleFilterPanel: () => void;
  expandCollapseCheckboxes: () => void;
  setOperator: (selectedOperator: Operator) => void;
  clearSearchKeyword: () => void;
} & OptionalToRequired<ExpandableFilterProps, 'visibleCheckboxesCount' | 'labelTitle'>;

const ExpandableFilterContext = createContext<ExpandableFilterContextValue | null>(null);

export function ExpandableFilterProvider({
  children,
  ...props
}: PropsWithChildren<ExpandableFilterProps>) {
  const {
    visibleCheckboxesCount = 7,
    locationInputProps: showLocationInput,
    radioOptions,
    attribute,
    orAttribute,
    andAttribute,
    filterOptions,
    searchItems,
    labelTitle = '',
    tagsAvailable,
    forceOpen
  } = props;

  const { filterOptionsGroups, baseFilterOptions } = useMemo(() => {
    const groups = getFilterOptionsGroups(filterOptions);

    return { filterOptionsGroups: groups, baseFilterOptions: groups?.[0].options };
  }, [filterOptions]);

  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [operator, setOperator] = useState<Operator>({
    operator: 'or',
    attribute: orAttribute || attribute
  });
  const [matchingOptions, setMatchingOptions] = useState(baseFilterOptions);

  const filtersContRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<SearchInputInstance>(null);

  const isMultiselectList = !!filterOptions;

  useEffect(() => {
    setMatchingOptions(baseFilterOptions);
  }, [baseFilterOptions]);

  useEffect(() => {
    if (forceOpen) setIsOpen(true);
  }, [forceOpen]);

  const toggleFilterPanel = (e?: MouseEvent) => {
    const target = e?.target as Element;
    const selectedLocation = target?.closest('.pac-container');

    if (!selectedLocation) setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const expandCollapseCheckboxes = () => {
    const filtersCont = filtersContRef.current;

    if (filtersCont) filtersCont.scrollTo(0, 0);

    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  const clearSearchKeyword = () => {
    searchInputRef.current?.setInput('');
  };

  const handleInputUpdate = (searchWord: string) => {
    if (checkIfTextFilter(attribute)) return;

    const list = isMultiselectList ? filterOptions : (radioOptions as RadioOption).options;

    setMatchingOptions(
      searchWord ? getMatchingResults(list, searchWord, tagsAvailable ? 'tags' : 'label') : list
    );
  };

  const hasMatchingAttribute = (item: SearchItem) => {
    const baseAttribute = baseFilterOptions?.[0].attribute;
    const parentAttribute = ATTRIBUTE_PARENT_MAP[item.attribute];
    const attributes = [orAttribute, andAttribute, attribute, operator.attribute, baseAttribute];

    return attributes.some(attr => attr && (attr === item.attribute || attr === parentAttribute));
  };

  const selectedItems = useMemo(() => {
    if (props.selectedItems) return props.selectedItems;
    if (isMultiselectList || showLocationInput) {
      const selected = searchItems?.filter(hasMatchingAttribute);

      if (showLocationInput) return selected;
      return selected?.map(item => ({ ...item, label: parseSearchItemLabel(item.label) }));
    }
  }, [props.selectedItems, searchItems, filterOptions]);

  const value = {
    isOpen,
    isExpanded,
    operator,
    selectedItems,
    matchingOptions,
    filtersContRef,
    searchInputRef,
    handleInputUpdate,
    toggleFilterPanel,
    expandCollapseCheckboxes,
    setOperator,
    clearSearchKeyword,
    ...props,
    visibleCheckboxesCount,
    labelTitle,
    filterOptionsGroups
  };

  return (
    <ExpandableFilterContext.Provider value={value}>{children}</ExpandableFilterContext.Provider>
  );
}

export const useExpandableFilter = () => {
  const expandableFilterContext = useContext(ExpandableFilterContext);
  if (!expandableFilterContext) {
    throw new Error('useExpandableFilter must be used within a Provider');
  }
  return expandableFilterContext;
};

const getFilterOptionsGroups = (
  filterOptions: ExpandableFilterProps['filterOptions']
): FilterOptionsGroups | undefined => {
  if (isEmpty(filterOptions)) return undefined;

  const isFilterOption = (filterOptions![0] as FilterOption).value;

  if (isFilterOption) return [{ options: filterOptions as FilterOptions }];
  return filterOptions as FilterOptionsGroups;
};
