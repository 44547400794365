import axios, { AxiosResponse } from 'axios';

import { OperatingCountry } from '@cohiretech/common-types';

import { APIResponse } from 'types';

const PUBLIC_LOCALISATION = process.env.REACT_APP_PUBLIC_LOCALISATION!;

type LocalisationResponse = APIResponse<{ preferCountry: OperatingCountry | null }>;

export const getLocalisationFromServer = async () => {
  try {
    const { data } = await axios.post<LocalisationResponse, AxiosResponse<LocalisationResponse>>(
      PUBLIC_LOCALISATION
    );

    if (!hasLocalisationData(data.data)) throw new Error('No data returned');

    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

const hasLocalisationData = (
  data: LocalisationResponse['data']
): data is { preferCountry: OperatingCountry } => {
  if (data?.preferCountry) return true;
  return false;
};
