import * as array from 'utils/array';
import * as fn from 'utils/fn';
import * as math from 'utils/math';
import * as object from 'utils/object';
import * as string from 'utils/string';
import * as time from 'utils/time';
import * as url from 'utils/url';

export const utils = { array, fn, math, object, string, time, url };

export type UtilsPackage = typeof utils;
