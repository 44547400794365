import { Location } from 'history';
import { useLocation } from 'react-router';

import { useUserType } from 'store';
import { HS_PUBLIC_SCRIPT_ROUTES } from 'v2/services/app/hubspotWidget';

import EmbedScript from './EmbedScript';

export const PublicEmbedScripts = () => {
  const { isCompany, isCandidate } = useUserType();
  const location = useLocation();

  if (isCompany || isCandidate) return null;

  return (
    <EmbedScript
      predicate={isHubspotWidgetEnabled(location)}
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="https://js.hs-scripts.com/3937375.js"
    />
  );
};

const isHubspotWidgetEnabled = (location: Location) => {
  if (HS_PUBLIC_SCRIPT_ROUTES.includes(location.pathname)) {
    return true;
  }
  return false;
};
