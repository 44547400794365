export const isCandidateProfilePage = (pathname = '') => {
  return pathname.includes('/u/candidate/');
};

export function getCompanyStreamIDFromURL(pathname: string) {
  const isStreamPage = /^\/company\/discover\/stream\/[0-9]+/.test(pathname);
  const streamID = isStreamPage && pathname.match(/\d+/)?.[0];

  return Number(streamID);
}

export const isAShortListingPage = (path: string) => {
  return new RegExp('/company/discover/shortlists').test(path);
};

// taken from src/utils.ts
export function isValidURL(url: string) {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export function isValidLinkedinPersonURL(url: string): boolean {
  const linkedinPattern = /^https:\/\/(www\.)?linkedin\.com\/in\/[^/]+/i;
  return linkedinPattern.test(url) && isValidURL(url);
}
