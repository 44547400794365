import axios from 'axios';

import { APIResponse } from 'types';

const EXTERNAL_COMPANIES_AUTOCOMPLETE =
  process.env.REACT_APP_PUBLIC_EXTERNAL_COMPANIES_AUTOCOMPLETE!;

export type ExternalCompanyAutocomplete = {
  companyID: number;
  name: string;
  url: string;
};

export const getExternalCompaniesAutocomplete = async (
  term: string
): Promise<APIResponse<ExternalCompanyAutocomplete[]>> => {
  try {
    const { data } = await axios.get(EXTERNAL_COMPANIES_AUTOCOMPLETE, { params: { term } });
    return data;
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
