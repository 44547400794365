import React, { useState, ReactElement, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ClassNames from 'classnames';
import styled from 'styled-components';
import {
  companyGetForm,
  companyClearForm,
  companyUpdateForm,
  CompanySignUpForm
} from 'companySignupForm';
import { validateEmailAddress } from 'config';

import { EmailValidationContext } from '@cohiretech/common-types';

import { setLocalStorageItem } from 'cookieManager';
import { checkEmail, companySignUp, login, logout } from 'fetcher';
import { emailFormat, passwordRequirementsChecker, passwordMeetsRequirements } from 'utils';

import Button from 'components/button';
import Checkbox from 'components/checkbox';
import ErrorMessage from 'components/errormessage';
import Field from 'components/field';

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

type Props = {
  onLoginStatusChange: () => Promise<void>;
};

export default function DemoCreateAccountProfile({ onLoginStatusChange }: Props) {
  const history = useHistory();

  const [companySignupForm, setCompanySignupForm] = useState<CompanySignUpForm>({
    ...companyGetForm()
  });
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] =
    useState<string | ReactElement | Array<string | ReactElement>>('');

  const updateForm = (form: CompanySignUpForm) => {
    const newForm = { ...companySignupForm, ...form };

    companyUpdateForm(newForm);
    setCompanySignupForm(newForm);
  };

  const validate = async () => {
    const { name, firstName, jobTitle, email, termsAccepted } = companySignupForm;
    const passwordRequirements = passwordRequirementsChecker(password);
    const passwordIsValid = passwordMeetsRequirements(passwordRequirements);

    if (!name) return "Please enter your company's name";
    if (!firstName) return 'Please enter your first name';
    if (!email) return 'Please enter your email address';
    if (!jobTitle) return 'Please enter your job title';
    if (!password) return 'Please create a password';
    if (!passwordIsValid) return 'Password must tick all security requirements';
    if (!termsAccepted) return 'Please accept the terms of service and privacy policy to proceed';

    const result = await checkEmail(
      email!,
      validateEmailAddress.companySignup ? 'ev' : '',
      EmailValidationContext.CompanyPaidSignup
    );

    if (result.status === 'failure') return "We couldn't validate your email. Please try again.";

    const { exists, companyExists, valid } = result.data;

    if (exists && companyExists) {
      return [
        'Email address already exists on cord. Please try to ',
        <Link key="link" className="link" to="#" onClick={() => demoLogout()}>
          Log in
        </Link>,
        '.'
      ];
    }
    if (!emailFormat.test(email!) || (validateEmailAddress.companySignup && !valid)) {
      return 'Email address is not valid.';
    }

    return '';
  };

  const createProfile = async () => {
    setLoading(true);

    const validationMessage = await validate();

    if (validationMessage) {
      setErrorMessage(validationMessage);
      setLoading(false);
      return;
    }

    const form = { ...companySignupForm, password, referrer: 'self-serve-demo' };
    setLocalStorageItem('companyName', form.name);
    const { status, message } = await companySignUp(form, 'demo');

    if (status === 'success') {
      companyClearForm();

      if (errorMessage) setErrorMessage('');
      await companyLogin(form.email!, password);
    } else {
      setErrorMessage(message.message);
    }

    setLoading(false);
  };

  const companyLogin = async (email: string, password: string) => {
    const { status } = await login(email, password, false);

    if (status === 'failure') {
      setErrorMessage('An unexpected error has occurred.');
    } else {
      await onLoginStatusChange();
      history.push('/company/discover');
    }
  };

  const demoLogout = async () => {
    await logout(true, false);
    await onLoginStatusChange();
    history.push('/login');
  };

  useEffect(() => {
    document.body.classList.remove('company');
    document.body.classList.add('public');
  }, []);

  const { name, firstName, jobTitle, email, termsAccepted } = companySignupForm;

  return (
    <CompanySignUpCont className="min_view center_alignment">
      <h1 className="heading">Get started on cord</h1>
      <h2>Create your cord account and start making direct hires</h2>
      <UserProfileCont className="user_profile" data-testid="company_signup_form">
        <div className="profile_details">
          <Field
            label="Company name"
            attributes={{ 'aria-label': 'company name' }}
            placeholder="Eg. Slack"
            value={name}
            onChange={(value: string) => updateForm({ name: value })}
          />
          <div className={ClassNames('field_row', 'two_cols')}>
            <Field
              label="First name"
              attributes={{ 'aria-label': 'first name' }}
              placeholder="Eg. George"
              value={firstName}
              onChange={(value: string) => updateForm({ firstName: value })}
            />
            <Field
              label="Job title"
              attributes={{ 'aria-label': 'job title' }}
              placeholder="Eg. Head of Engineering"
              value={jobTitle}
              onChange={(value: string) => updateForm({ jobTitle: value })}
            />
          </div>
          <Field
            label="Email address"
            attributes={{ 'aria-label': 'email address' }}
            placeholder="Eg. george@moon.com"
            value={email}
            type="email"
            onChange={(value: string) => updateForm({ email: value })}
          />
          <Field
            label="Create your password"
            attributes={{ 'aria-label': 'create password' }}
            onChangeValidation
            placeholder="Make it strong"
            value={password}
            type="password"
            onChange={(value: string) => setPassword(value)}
          />
          <Checkbox
            checkboxID="terms_policy"
            className="accept_terms"
            label={[
              "I agree to cord's ",
              <a
                href={`${domain}/privacy`}
                key="privacy"
                aria-label="Open Privacy Policy in a new tab"
                target="_blank"
                rel="noopener noreferrer"
                className="lightblue_link"
              >
                Privacy Policy
              </a>,
              ' and ',
              <a
                href={`${domain}/employer-terms`}
                key="terms"
                aria-label="Open Terms of Service in a new tab"
                target="_blank"
                rel="noopener noreferrer"
                className="lightblue_link"
              >
                Terms of Service
              </a>
            ]}
            checked={termsAccepted}
            onChange={checked => updateForm({ termsAccepted: checked })}
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
        <Button text="Create your account" action={() => createProfile()} loading={loading} />
        <div className="login_link">
          Already have an account?{' '}
          <Link className="lightblue_link" to="#" onClick={() => demoLogout()}>
            Log in
          </Link>
        </div>
      </UserProfileCont>
    </CompanySignUpCont>
  );
}

const CompanySignUpCont = styled.div`
  h2 {
    margin-bottom: 35px;
  }

  .size_form,
  .user_profile {
    max-width: 450px;
    margin: auto;
  }

  .size_form .button {
    width: 100%;
    margin-top: 4px;
  }

  .link {
    margin-left: 5px;
  }
`;

const UserProfileCont = styled.div`
  .button {
    width: 100%;
    margin: 20px auto 16px;
  }
`;
