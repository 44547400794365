import {
  CompanySubscriptionPlan,
  SubscriptionPlanInterface,
  OperatingCountry
} from '@cohiretech/common-types';

import {
  CompanyProfile,
  SubscriptionPlan,
  NewPricingOptions,
  V3PricingOptions,
  V4PricingOptions,
  PricingCountry
} from 'types';
import { getCurrencyFromPricingCountry, commify } from 'utils';
import { V3_PRICE_LIST, V3_TIER_PLAN_MAP, V4_GROWTH_PRICE, V4_UNLIMITED_MIN_PRICE } from 'consts';

export const getPricingCountry = (profile: CompanyProfile) =>
  profile?.subscriptionDetailsAndPricing?.subscription?.pricingCountry as
    | PricingCountry
    | undefined;

const getPricingCurrency = (profile: CompanyProfile) => {
  const pricingCountry = getPricingCountry(profile);

  return pricingCountry === OperatingCountry.US ? '$' : '£';
};

export const getOveragePrice = (profile: CompanyProfile) => {
  const pricingCountry = getPricingCountry(profile);
  const pricing = profile?.subscriptionDetailsAndPricing?.pricing;
  const currency = getPricingCurrency(profile);
  const overageAmount = pricing[pricingCountry || OperatingCountry.UK]?.conversationsOveragePrice;

  return `${currency}${overageAmount}`;
};

export const getV3BasicPrice = (country: PricingCountry) => {
  const currency = getCurrencyFromPricingCountry(country);
  const price = V3_PRICE_LIST[country][20].price;

  return `${currency}${price}`;
};

export const getV4BasicPrice = (
  country: PricingCountry,
  plan: V4PricingOptions = V4PricingOptions.V4Growth
) => {
  const currency = getCurrencyFromPricingCountry(country);
  const price =
    plan === V4PricingOptions.V4Growth
      ? V4_GROWTH_PRICE[country].price
      : V4_UNLIMITED_MIN_PRICE[country];

  return `${currency}${commify(Math.round(price))}`;
};

export const getCurrentPlan = (profile?: CompanyProfile | null) =>
  profile?.subscriptionDetailsAndPricing?.subscriptionPlan?.plan as SubscriptionPlan | undefined;

export const getV3PricingOptions = (isMobile?: boolean) => ({
  growth: {
    label: 'Growth',
    description: 'Built for people hiring inside start-ups and scale-ups.',
    features: [
      'Flexible pricing built around your usage',
      'Every feature for streamlined, superfast sourcing',
      'Instant set-up and onboarding'
    ],
    button: {
      text: `Try unlimited ${isMobile ? 'for a month' : 'in your first month'}`,
      buttonStyle: 'fill_blue'
    }
  },
  enterprise: {
    plan: 'enterprise',
    label: 'Customised',
    description: 'Built for larger teams continually hiring at scale.',
    features: [
      'Unlimited sourcing',
      'Bespoke procurement & security',
      'Volume discounts & invoiced billing'
    ],
    button: { text: 'Get in touch', buttonStyle: 'stroke_blue' }
  }
});

export const getV4PricingOptions = () => ({
  growth: {
    label: 'Growth',
    description: 'Built for talent teams inside start-ups and scale-ups',
    featureTitle: 'Includes:',
    features: [
      'Full access to advertising & sourcing',
      'Unlimited users, messages & hires',
      'Access to every feature & integration',
      'Instant setup & onboarding'
    ],
    button: {
      text: 'Book a demo',
      buttonStyle: 'fill_blue'
    }
  },
  enterprise: {
    plan: 'enterprise',
    label: 'Customised',
    description: 'Built for larger companies continually hiring at scale',
    featureTitle: 'Everything from Growth, plus:',
    features: [
      'Dedicated account manager',
      'Bespoke procurement & security',
      'Invoice billing & volume discounts',
      'SLAs (Service level agreements)'
    ],
    button: { text: 'Get in touch', buttonStyle: 'stroke_blue' }
  }
});

export const getPlanUsageLimit = (subscriptionPlan?: SubscriptionPlanInterface) => {
  const { conversations, positions, userSeats } = subscriptionPlan?.configuration || {};

  return {
    sourcingLimit: conversations?.limit || 0,
    positionLimit: positions?.limit || 0,
    userSeatsLimit: userSeats?.limit || 0
  };
};

export const getAnnualPlanName = (plan: NewPricingOptions) => {
  switch (plan) {
    case NewPricingOptions.StarterV2:
      return NewPricingOptions.StarterV2Annual;
    case NewPricingOptions.GrowthV2:
      return NewPricingOptions.GrowthV2Annual;
    case NewPricingOptions.GrowthPlusV2:
      return NewPricingOptions.GrowthPlusV2Annual;
    case NewPricingOptions.ScalingV2:
      return NewPricingOptions.ScalingV2Annual;
    default:
      return plan;
  }
};

export const checkIfAnnualPlanName = (plan: SubscriptionPlan) => {
  return [
    NewPricingOptions.StarterV2Annual,
    NewPricingOptions.GrowthV2Annual,
    NewPricingOptions.GrowthPlusV2Annual,
    NewPricingOptions.ScalingV2Annual
  ].includes(plan as NewPricingOptions);
};

export const getV3PlanCreditLimit = (plan?: V3PricingOptions | CompanySubscriptionPlan.Basic) => {
  const isV3Pricing = plan && plan !== CompanySubscriptionPlan.Basic;

  const credits =
    isV3Pricing && Object.keys(V3_TIER_PLAN_MAP).find(key => V3_TIER_PLAN_MAP[+key] === plan);

  return Number(credits || 0);
};

export function centsToDollars(cents: number): string {
  return (cents / 100).toFixed(2);
}
