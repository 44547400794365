import React from 'react';
import { History, Location } from 'history';
import styled from 'styled-components';

import { EmailVerificationUserType } from '@cohiretech/common-types';

import * as fetcher from 'fetcher';
import {
  getQueryStringObject,
  passwordMeetsRequirements,
  passwordRequirementsChecker
} from 'utils';
import { setInitialSetupModeLS, setSetupModeLS } from 'utils/localStorage';
import { setLocalStorageItem } from 'cookieManager';

import Button from 'components/button';
import Checkbox from 'components/checkbox';
import ErrorMessage from 'components/errormessage';
import Field from 'components/field';
import Loader from 'components/loader';
import Seo from 'components/seo';

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

type Props = {
  className?: string;
  location: Location;
  history: History;
  onLoginStatusChange: () => Promise<void>;
};

type State = $TSFixMe;

export default class CreatePassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      verifyError: '',
      verificationCode: null,
      isNewExternalCompany: false,
      companyName: '',
      email: '',
      password: '',
      errorMessage: '',
      termsAccepted: false
    };
  }

  async componentDidMount() {
    const { location, history } = this.props;
    const { new: isNewExternalCompany, token: verificationCode } =
      getQueryStringObject(location.search) || {};

    if (verificationCode) {
      const { status } = await fetcher.verifyUserEmail(
        verificationCode,
        EmailVerificationUserType.CompanyTeamAccount
      );

      if (status === 'success') {
        const userProfile = await fetcher.getPublicCompanyUser(verificationCode);
        const { companyName, email } = userProfile;
        setLocalStorageItem('userEmail', email);

        this.setState({
          companyName,
          email,
          loading: false,
          verificationCode,
          isNewExternalCompany: isNewExternalCompany === 'true'
        });
      } else {
        this.setState({
          loading: false,
          verifyError: 'Oops, something went wrong :('
        });
      }
    } else {
      history.push('/');
    }
  }

  async login(email: string, password: string) {
    const { status } = await fetcher.login(email, password, false);
    const { onLoginStatusChange, history } = this.props;

    if (status === 'failure') history.push('/login');
    else await onLoginStatusChange();
  }

  validatePassword(password: string) {
    if (password === '') return 'Please enter a new password';

    const passwordRequirements = passwordRequirementsChecker(password);
    const passwordIsValid = passwordMeetsRequirements(passwordRequirements);

    if (!passwordIsValid) return 'Password must tick all security requirements';
    else return '';
  }

  async savePasswordAndLogin() {
    const { password, verificationCode, email, termsAccepted, isNewExternalCompany } = this.state;

    this.setState({ saving: true });

    const errorMessage = this.validatePassword(password);

    if (errorMessage) {
      this.setState({ errorMessage, saving: false });
    } else if (!termsAccepted) {
      this.setState({
        errorMessage: 'Please accept the terms of service and privacy policy to proceed',
        saving: false
      });
    } else {
      if (isNewExternalCompany) {
        setInitialSetupModeLS();
      } else {
        setSetupModeLS(false);
      }
      const { status, message } = await fetcher.setUserPassword(verificationCode, password);
      if (status === 'success') {
        this.setState({ saving: false });
        this.login(email, password);
      } else {
        this.setState({ errorMessage: message, saving: false });
      }
    }
  }

  render() {
    const { className, location } = this.props;
    const {
      verifyError,
      loading,
      email,
      password,
      errorMessage,
      companyName,
      saving,
      termsAccepted,
      isNewExternalCompany
    } = this.state;

    if (loading) {
      return <Loader className="verify_new_users medium" />;
    } else {
      return (
        <div id="join" className={className}>
          <div className="intro_wrapper">
            {verifyError !== '' ? (
              <div className="min_view">
                <h1 className="heading">{verifyError}</h1>
                <h2 className="subheading">We couldn't verify your account. Can you try again?</h2>
              </div>
            ) : (
              <div className="min_view center_alignment">
                <h1 className="heading">Welcome to cord!</h1>
                <h2 className="subheading">
                  Create your password and {isNewExternalCompany ? 'set up' : 'get access to'}{' '}
                  {companyName}'s profile on cord.
                </h2>
                <FormContainer className="description">
                  <div className="profile_details">
                    <Field
                      label="Email address"
                      className="email_field"
                      value={email}
                      type="email"
                      disabled
                    />
                    <Field
                      className="password_field"
                      type="password"
                      label="Create your password"
                      placeholder="Make it strong"
                      onChange={(value: $TSFixMe) => this.setState({ password: value })}
                      onChangeValidation
                      loading={loading}
                      value={password}
                    />
                    <div className="checkboxes">
                      <div className="field">
                        <Checkbox
                          checkboxID="terms"
                          className="accept_terms"
                          label={[
                            "I agree to cord's ",
                            <a
                              key="Privacy Policy Link"
                              className="link"
                              href={`${domain}/privacy`}
                              aria-label="Open Privacy Policy in a new tab"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>,
                            ' and ',
                            <a
                              key="Terms of Service Link"
                              className="link"
                              href={`${domain}/employer-terms`}
                              aria-label="Open Terms of Service in a new tab"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms of Service
                            </a>
                          ]}
                          checked={termsAccepted}
                          onChange={checked => this.setState({ termsAccepted: checked })}
                        />
                      </div>
                    </div>
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    <Button
                      text="Get access"
                      loading={saving}
                      action={() => this.savePasswordAndLogin()}
                    />
                  </div>
                </FormContainer>
              </div>
            )}
          </div>
          <Seo
            title="Create your password"
            description="Create your password and get access to your company's profile on cord."
            path={location.pathname}
            contentType="website"
          />
        </div>
      );
    }
  }
}

const FormContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;

  .email_field,
  .password_field {
    padding-bottom: 0;
  }

  .checkboxes {
    margin-bottom: 0.5rem;
  }
`;
