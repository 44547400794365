import React from 'react';
import styled from 'styled-components';
import { CANDIDATE_SEARCH_FILTERS } from 'consts/candidate/positionSearch';

import { isEmpty } from 'utils';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';
import LocationInput from 'components/locationinput';
import SelectedItems from 'components/selecteditems';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

export default function LocationList() {
  const { attribute, locationInputProps, isOpen, selectedItems, addSearchItem, removeSearchItem } =
    useExpandableFilter();
  const attr = attribute || CANDIDATE_SEARCH_FILTERS.location.attribute;
  const hasSelection = !isEmpty(selectedItems);

  return (
    <LocationPicker className="location_list" hasSelection={hasSelection}>
      <LocationInput
        placeType={locationInputProps?.placeType}
        onSelect={(locationName, locationObject) =>
          addSearchItem!(attr, locationName, locationName, locationObject)
        }
        clearInputOnPlaceSelected
        icon={locationInputProps?.icon}
        focus={isOpen}
      />
      <ConditionalRender predicate={hasSelection}>
        <SelectedItems
          selectedItems={selectedItems || []}
          size="xsmall"
          rounded
          removeItem={item => removeSearchItem?.(attr, item)}
        />
      </ConditionalRender>
    </LocationPicker>
  );
}

const LocationPicker = styled.div<{ hasSelection: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .location_input_wrapper {
    border: 0;
    padding: 0;
  }

  // To adjust .filter_content padding
  ${({ hasSelection }) => !hasSelection && 'margin-bottom: -0.5rem;'}

  .selected_items {
    padding: 0;

    .icon_close {
      line-height: normal;
    }
  }
`;
