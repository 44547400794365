import React, { InputHTMLAttributes } from 'react';
import ClassNames from 'classnames';

import { OptionalToRequired } from 'types';

import { ListItem } from 'components/dropdown';

import { RangeDropdown } from './RangeField.styled';

export type InputAttributes = InputHTMLAttributes<HTMLInputElement>;

type RangeInputAttributes = Omit<InputAttributes, 'value' | 'onChange'> & {
  onChange: (name: string, value: string) => void;
};

export type RangeType = { min?: number; max?: number | '' };

type RangeInputProps = {
  range: RangeType;
  options?: ListItem<string, number>[];
} & OptionalToRequired<RangeInputAttributes, 'name'>;

export default function RangeInput({ range, options, onChange, ...input }: RangeInputProps) {
  if (!input) return null;

  const isMinInput = input.name.endsWith('min');
  const inputValue = range[isMinInput ? 'min' : 'max'];
  const min = Number(input.min || 0);
  const className = ClassNames(input.className, 'range_input');

  if (options) {
    const filteredList = options.filter(({ value }) => {
      if (isMinInput) return range.max ? value! < range.max : true;
      return range.min ? value! > range.min : true;
    });

    return (
      <RangeDropdown
        id={input.name}
        className={className}
        selected={inputValue}
        selectMessage={input.placeholder}
        list={filteredList}
        onSelect={({ value }) => onChange(input.name, value)}
        disabled={input.disabled}
      />
    );
  }

  return (
    <input
      {...input}
      className={className}
      type="number"
      min={isMinInput ? min : Math.max(range.min || 0, min)}
      value={inputValue}
      onChange={({ target: { value } }) => onChange(input.name, value)}
    />
  );
}
