import React from 'react';
import ClassNames from 'classnames';

import { isAgenciesPage } from 'utils/url';
import { useDarkMode } from 'hooks/useDarkMode';

import BulletList, { Bullet } from 'components/bulletlist';
import BadgeWithIcon from 'components/badgewithicon';
import ResponsiveImage from 'components/responsiveimage';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { HeaderContent } from './pageheader';

type MakeDirectHiresProps = {
  src: { desktop: string; mobile: string };
};

const MakeDirectHires = ({ src }: MakeDirectHiresProps) => {
  const darkMode = useDarkMode();
  const isForAgenciesPage = isAgenciesPage();
  return (
    <div className="pipeline_chapter">
      <DirectHiresHeader isTablet isForAgenciesPage={isForAgenciesPage} />
      <div className={ClassNames('left_cont', 'sourcing_pipeline', 'resp_cont')}>
        <DirectHiresHeader isForAgenciesPage={isForAgenciesPage} />
        <BulletList>
          <Bullet title="No hiring fees">
            Pay a flat monthly subscription and cancel anytime. Companies on cord save up to 10x on
            their traditional recruitment costs.
          </Bullet>
          <Bullet title="Scale your team">
            Entire tech and product team are built on cord. From early-stage start-ups through to
            Fortune 500s.
          </Bullet>
        </BulletList>
        <div className={ClassNames('multiple_badges', { dark_badges: darkMode })}>
          <BadgeWithIcon icon="icon_hired" title="10:1" text="people sourced to hires ratio" />
          <BadgeWithIcon icon="icon_clock" title="2 days" text="the fastest hire made on cord" />
        </div>
      </div>
      <div className={ClassNames('right_cont', 'image_resp', 'sourcing_pipeline', 'resp_cont')}>
        <ResponsiveImage
          src={src.desktop}
          alt="Direct hire from cord"
          smallSrc={src.mobile}
          className="sourcing_img"
          width="100%"
        />
      </div>
    </div>
  );
};

const DirectHiresHeader = ({
  isTablet,
  isForAgenciesPage
}: {
  isTablet?: true;
  isForAgenciesPage: boolean;
}) => (
  <HeaderContent
    title={isForAgenciesPage ? 'Make placements' : 'Make direct hires'}
    isTablet={isTablet}
  >
    <ConditionalRender
      predicate={isForAgenciesPage}
      fallback="Active, qualified people perform better in your pipeline than other sources. Thousands of hires
have been made on cord to date."
    >
      Active, qualified people perform better in your clients' pipelines. Thousands of hires have
      been made on cord to date.
    </ConditionalRender>
  </HeaderContent>
);

export default MakeDirectHires;
