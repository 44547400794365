import React from 'react';

import { AwardCompanySize, AwardSeason, AwardType } from '@cohiretech/common-types';

import { capitalizeWords } from 'utils/string';
import { getResizedImageURL } from 'v2/services/image';
import { S3_IMAGE_BUCKET_URL } from 'consts';
import { getMillisecondsByType, getMinsHrsDaysWeeksMonthsfromTime } from 'utils';

import { Props as SeoProps } from 'components/seo';
import LabelWithIcon from 'components/labelwithicon';

import { IntroWithTaglineProps } from 'v2/components/IntroWithTagline';

export type AwardSeasonYear = `${AwardSeason}-${number}`;

export const AWARD_SEASON_AVERAGE: { [key: AwardSeasonYear]: { [key in AwardType]: number } } = {
  [`${AwardSeason.Spring}-2023`]: {
    [AwardType.MostResponsive]: 79.97,
    [AwardType.FastestToRespond]: 73.8,
    [AwardType.DiversityAdvocate]: 20.5
  },
  [`${AwardSeason.Summer}-2023`]: {
    [AwardType.MostResponsive]: 76.61,
    [AwardType.FastestToRespond]: 69.5,
    [AwardType.DiversityAdvocate]: 20
  },
  [`${AwardSeason.Autumn}-2023`]: {
    [AwardType.MostResponsive]: 76.87,
    [AwardType.FastestToRespond]: 66,
    [AwardType.DiversityAdvocate]: 20
  },
  [`${AwardSeason.Winter}-2023`]: {
    [AwardType.MostResponsive]: 72,
    [AwardType.FastestToRespond]: 65,
    [AwardType.DiversityAdvocate]: 17
  },
  [`${AwardSeason.Spring}-2024`]: {
    [AwardType.MostResponsive]: 76,
    [AwardType.FastestToRespond]: 65,
    [AwardType.DiversityAdvocate]: 20
  },
  [`${AwardSeason.Summer}-2024`]: {
    [AwardType.MostResponsive]: 72,
    [AwardType.FastestToRespond]: 67,
    [AwardType.DiversityAdvocate]: 17
  }
};

export const PARAM_MAP = {
  award: {
    'most-responsive': AwardType.MostResponsive,
    'fastest-to-respond': AwardType.FastestToRespond,
    'diversity-advocate': AwardType.DiversityAdvocate
  },
  size: {
    'small-companies': AwardCompanySize.Small,
    'midsize-companies': AwardCompanySize.Midsize,
    'large-companies': AwardCompanySize.Large
  }
};

type ParamMap = typeof PARAM_MAP;

export type SizeParam = keyof ParamMap['size'];
type AwardParam = keyof ParamMap['award'];

export type AwardsPageParams = {
  season: AwardSeasonYear;
  award: keyof ParamMap['award'];
  size: SizeParam;
};

export const getAwardSeasonText = (season: string) => capitalizeWords(season.replace('-', ' '));

export const getIntro = (
  award: AwardType,
  season: AwardSeasonYear
): Omit<IntroWithTaglineProps, 'tagline'> => {
  const awardSeason = getAwardSeasonText(season);
  const average = AWARD_SEASON_AVERAGE[season]?.[award];

  switch (award) {
    case AwardType.MostResponsive:
      return {
        heading: 'Most responsive companies',
        subheading:
          'The top small, medium and large sized companies that have responded to the highest % of applications within 14 days.',
        description: (
          <LabelWithIcon
            icon="icon_line_bars"
            text={`*The average response rate of companies on cord in "${awardSeason}" is `}
            tag
          >
            <strong>{Math.round(average)}%</strong>.
          </LabelWithIcon>
        )
      };
    case AwardType.FastestToRespond:
      const milliseconds = getMillisecondsByType('hours');
      const { days } = getMinsHrsDaysWeeksMonthsfromTime(average * milliseconds);
      const hours = average - days * 24;

      return {
        heading: 'Fastest to respond companies',
        subheading:
          'The top small, medium and large sized companies ranked by the average time taken to respond to applications.',
        description: (
          <LabelWithIcon
            icon="icon_line_bars"
            text={`*The average response time of companies on cord in "${awardSeason}" is `}
            tag
          >
            <strong>
              {days ? `${days}d` : ''} {hours}hrs
            </strong>
            .
          </LabelWithIcon>
        )
      };
    case AwardType.DiversityAdvocate:
      return {
        heading: 'Gender Diversity Sourcing',
        subheading:
          'The top small, medium and large sized companies ranked by percentage of females they sourced over the industry average.',
        description: (
          <LabelWithIcon
            icon="icon_line_bars"
            text={`*The median % of female candidates sourced by companies on cord for "${awardSeason}" is `}
            tag
          >
            <strong>{average}%</strong>.
          </LabelWithIcon>
        )
      };
    default:
      return {} as IntroWithTaglineProps;
  }
};

export const getSEOContent = (
  awardParam: AwardParam,
  prettySeason: string,
  season: string,
  sizeParam: SizeParam
): SeoProps => {
  const awardType = PARAM_MAP.award[awardParam];
  const size = sizeParam.replace('-companies', '').replace(AwardCompanySize.Midsize, 'mid-sized');
  const image = getResizedImageURL(
    `${S3_IMAGE_BUCKET_URL}/awards/${awardParam}-${sizeParam}-${season}-og-image.jpg`,
    { width: 1200 }
  );

  switch (awardType) {
    case AwardType.MostResponsive:
      return {
        title: `Top Most Responsive - ${capitalizeWords(size)} Companies - ${prettySeason} Awards`,
        description: `The Top ${size} companies with the highest % of applications responded to within 14 days.`,
        image
      };
    case AwardType.FastestToRespond:
      return {
        title: `Top Fastest to Respond - ${capitalizeWords(
          size
        )} Companies - ${prettySeason} Awards`,
        description: `The Top ${size} companies with the fastest response time to applications.`,
        image
      };
    case AwardType.DiversityAdvocate:
      return {
        title: `Top Leaders in Diversity Sourcing - ${capitalizeWords(
          size
        )} Companies - ${prettySeason} Awards`,
        description: `The Top ${size} companies that source more than 19% of female candidates ranked by the highest % of female candidates sourced.`,
        image
      };
    default:
      return {} as SeoProps;
  }
};
