import styled, { css } from 'styled-components';

import CompanyLogos from 'components/companylogos';

const BannerAdjustments = css`
  flex-direction: column;
  padding-top: 100px;
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;

  ${({ theme: { media } }) => media.tabletLandscape`
    flex-direction: column;
  `}

  .video_wrapper {
    height: 100%;
  }

  margin-bottom: 60px;
`;

export const TopSection = styled.div<{ showBanner: boolean }>`
  box-sizing: border-box;
  display: flex;
  padding: 148px var(--spacing-08) var(--spacing-30) var(--spacing-08);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
  margin-top: calc(-1 * var(--public-topnav-height));

  ${({ showBanner }) =>
    showBanner
      ? BannerAdjustments
      : css`
          gap: 60px;
        `}

  color: white;
  overflow: hidden;
  border-radius: 0 0 0 180px;
  background: var(--gradient-grey-dark, linear-gradient(121deg, #0b3658 -6.52%, #062741 107.93%));
`;

export const LeadCaptureForm = css`
  ${({ theme: { colours, media, typography } }) => css`
    .lead_capture_form {
      display: flex;
      flex-direction: column;
      width: 320px;
      margin: 0;

      ${media.mobile` 
        align-items: center;
        width: 100%;
      `}

      .email_input,
      .search_input {
        height: 52px;
        padding: 12px 20px;
        margin: 0 0 var(--spacing-03);
        border: 2px solid transparent;
        background-color: var(--color-transparent-light-primary-10);
        color: var(--color-background-body-secondary);
        border-radius: 0.5em;
        font-weight: ${typography.medium};

        &::placeholder {
          color: var(--text-body-brand);
          font-weight: ${typography.medium};
        }

        &:focus {
          border: 2px solid ${colours.fontColour};
        }
      }

      .button {
        width: 100%;
      }

      .border_input {
        margin-right: 0;
        width: 100%;

        .search_input {
          border: 2px solid transparent;

          &:focus {
            border: 2px solid ${colours.fontColour};
          }
        }

        .autocomplete {
          background-color: #375572;
          scrollbar-color: rgba(${colours.primaryColour}, 0.8);
          border-color: transparent;

          &::-webkit-scrollbar-track {
            background: rgba(${colours.primaryColour}, 0.8);
          }

          .row_item:hover {
            background-color: rgba(${colours.primaryColour}, 0.8);
          }
        }
      }
    }
  `}
`;

export const TitleSection = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;

  &.page_view {
    padding: 0;
    margin: 0 auto;
  }

  ${({ theme: { media } }) => media.tabletLandscape`
    flex-direction: column;
  `}

  .left_cont {
    min-width: 495px;
    margin: 0;

    ${({ theme: { media } }) => media.mobile`
      min-width: unset;
    `}
  }

  .arrow_link {
    margin-top: var(--spacing-03);
    padding: var(--spacing-02) 0;
    .story_arrow_link {
      font-size: 20px;
    }

    img {
      vertical-align: sub;
    }

    @include respond-to(mobile) {
      margin-top: 24px;
    }
  }
  &&& {
    ${LeadCaptureForm}
  }
`;

export const ForCompaniesLogoSection = styled(CompanyLogos)<{ limit: number }>`
  padding: 0;

  .company_logos {
    display: flex;
    ${({ limit }) => limit > 7 && 'flex-wrap: wrap'};
    justify-content: space-between;
    column-gap: var(--spacing-10);
    row-gap: var(--spacing-06);

    .company_logo {
      flex: 0 0 146px;
      margin: 0;
      max-height: 40px;
    }

    ${({ theme: { media } }) => media.tabletLandscape`
      flex-wrap: wrap;
    `}

    ${({ theme: { media } }) => media.mobile`
      justify-content: space-around;
    `}
  }

  .company_logos_description {
    text-align: center;
    margin-bottom: var(--spacing-08);
  }
`;
