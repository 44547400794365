export const ISO_6393_LANGUAGES = [
  {
    name: 'Afrikaans',
    type: 'living',
    scope: 'individual',
    iso6393: 'afr',
    iso6392B: 'afr',
    iso6392T: 'afr',
    iso6391: 'af'
  },
  {
    name: 'Albanian',
    type: 'living',
    scope: 'individual',
    iso6393: 'sqi'
  },
  {
    name: 'Arabic',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'ara',
    iso6392B: 'ara',
    iso6392T: 'ara',
    iso6391: 'ar'
  },
  {
    name: 'Armenian',
    type: 'living',
    scope: 'individual',
    iso6393: 'hye',
    iso6392B: 'arm',
    iso6392T: 'hye',
    iso6391: 'hy'
  },
  {
    name: 'Basque',
    type: 'living',
    scope: 'individual',
    iso6393: 'eus',
    iso6392B: 'baq',
    iso6392T: 'eus',
    iso6391: 'eu'
  },
  {
    name: 'Bengali',
    type: 'living',
    scope: 'individual',
    iso6393: 'ben',
    iso6392B: 'ben',
    iso6392T: 'ben',
    iso6391: 'bn'
  },
  {
    name: 'Bulgarian',
    type: 'living',
    scope: 'individual',
    iso6393: 'bul',
    iso6392B: 'bul',
    iso6392T: 'bul',
    iso6391: 'bg'
  },
  {
    name: 'Catalan',
    type: 'living',
    scope: 'individual',
    iso6393: 'cat',
    iso6392B: 'cat',
    iso6392T: 'cat',
    iso6391: 'ca'
  },
  {
    name: 'Chinese (Mandarin)',
    type: 'living',
    scope: 'individual',
    iso6393: 'cmn'
  },
  {
    name: 'Chinese (Cantonese)',
    type: 'living',
    scope: 'individual',
    iso6393: 'yue'
  },
  {
    name: 'Croatian',
    type: 'living',
    scope: 'individual',
    iso6393: 'hrv',
    iso6392B: 'hrv',
    iso6392T: 'hrv',
    iso6391: 'hr'
  },
  {
    name: 'Czech',
    type: 'living',
    scope: 'individual',
    iso6393: 'ces',
    iso6392B: 'cze',
    iso6392T: 'ces',
    iso6391: 'cs'
  },
  {
    name: 'Danish',
    type: 'living',
    scope: 'individual',
    iso6393: 'dan',
    iso6392B: 'dan',
    iso6392T: 'dan',
    iso6391: 'da'
  },
  {
    name: 'Dutch',
    type: 'living',
    scope: 'individual',
    iso6393: 'nld',
    iso6392B: 'dut',
    iso6392T: 'nld',
    iso6391: 'nl'
  },
  {
    name: 'English',
    type: 'living',
    scope: 'individual',
    iso6393: 'eng',
    iso6392B: 'eng',
    iso6392T: 'eng',
    iso6391: 'en'
  },
  {
    name: 'Estonian',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'est',
    iso6392B: 'est',
    iso6392T: 'est',
    iso6391: 'et'
  },
  {
    name: 'Fijian',
    type: 'living',
    scope: 'individual',
    iso6393: 'fij',
    iso6392B: 'fij',
    iso6392T: 'fij',
    iso6391: 'fj'
  },
  {
    name: 'Finnish',
    type: 'living',
    scope: 'individual',
    iso6393: 'fin',
    iso6392B: 'fin',
    iso6392T: 'fin',
    iso6391: 'fi'
  },
  {
    name: 'French',
    type: 'living',
    scope: 'individual',
    iso6393: 'fra',
    iso6392B: 'fre',
    iso6392T: 'fra',
    iso6391: 'fr'
  },
  {
    name: 'Georgian',
    type: 'living',
    scope: 'individual',
    iso6393: 'kat',
    iso6392B: 'geo',
    iso6392T: 'kat',
    iso6391: 'ka'
  },
  {
    name: 'German',
    type: 'living',
    scope: 'individual',
    iso6393: 'deu',
    iso6392B: 'ger',
    iso6392T: 'deu',
    iso6391: 'de'
  },
  {
    name: 'Greek',
    type: 'living',
    scope: 'individual',
    iso6393: 'ell',
    iso6392B: 'gre',
    iso6392T: 'ell',
    iso6391: 'el'
  },
  {
    name: 'Gujarati',
    type: 'living',
    scope: 'individual',
    iso6393: 'guj',
    iso6392B: 'guj',
    iso6392T: 'guj',
    iso6391: 'gu'
  },
  {
    name: 'Hebrew',
    type: 'living',
    scope: 'individual',
    iso6393: 'heb',
    iso6392B: 'heb',
    iso6392T: 'heb',
    iso6391: 'he'
  },
  {
    name: 'Hindi',
    type: 'living',
    scope: 'individual',
    iso6393: 'hin',
    iso6392B: 'hin',
    iso6392T: 'hin',
    iso6391: 'hi'
  },
  {
    name: 'Hungarian',
    type: 'living',
    scope: 'individual',
    iso6393: 'hun',
    iso6392B: 'hun',
    iso6392T: 'hun',
    iso6391: 'hu'
  },
  {
    name: 'Icelandic',
    type: 'living',
    scope: 'individual',
    iso6393: 'isl',
    iso6392B: 'ice',
    iso6392T: 'isl',
    iso6391: 'is'
  },
  {
    name: 'Indonesian',
    type: 'living',
    scope: 'individual',
    iso6393: 'ind',
    iso6392B: 'ind',
    iso6392T: 'ind',
    iso6391: 'id'
  },
  {
    name: 'Irish',
    type: 'living',
    scope: 'individual',
    iso6393: 'gle',
    iso6392B: 'gle',
    iso6392T: 'gle',
    iso6391: 'ga'
  },
  {
    name: 'Italian',
    type: 'living',
    scope: 'individual',
    iso6393: 'ita',
    iso6392B: 'ita',
    iso6392T: 'ita',
    iso6391: 'it'
  },
  {
    name: 'Japanese',
    type: 'living',
    scope: 'individual',
    iso6393: 'jpn',
    iso6392B: 'jpn',
    iso6392T: 'jpn',
    iso6391: 'ja'
  },
  {
    name: 'Javanese',
    type: 'living',
    scope: 'individual',
    iso6393: 'jav',
    iso6392B: 'jav',
    iso6392T: 'jav',
    iso6391: 'jv'
  },
  {
    name: 'Korean',
    type: 'living',
    scope: 'individual',
    iso6393: 'kor',
    iso6392B: 'kor',
    iso6392T: 'kor',
    iso6391: 'ko'
  },
  {
    name: 'Kurdish',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'kur',
    iso6392B: 'kur',
    iso6392T: 'kur',
    iso6391: 'ku'
  },
  {
    name: 'Latin',
    type: 'ancient',
    scope: 'individual',
    iso6393: 'lat',
    iso6392B: 'lat',
    iso6392T: 'lat',
    iso6391: 'la'
  },
  {
    name: 'Latvian',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'lav',
    iso6392B: 'lav',
    iso6392T: 'lav',
    iso6391: 'lv'
  },
  {
    name: 'Lithuanian',
    type: 'living',
    scope: 'individual',
    iso6393: 'lit',
    iso6392B: 'lit',
    iso6392T: 'lit',
    iso6391: 'lt'
  },
  {
    name: 'Macedonian',
    type: 'living',
    scope: 'individual',
    iso6393: 'mkd',
    iso6392B: 'mac',
    iso6392T: 'mkd',
    iso6391: 'mk'
  },
  {
    name: 'Malay',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'msa',
    iso6392B: 'may',
    iso6392T: 'msa',
    iso6391: 'ms'
  },
  {
    name: 'Malayalam',
    type: 'living',
    scope: 'individual',
    iso6393: 'mal',
    iso6392B: 'mal',
    iso6392T: 'mal',
    iso6391: 'ml'
  },
  {
    name: 'Maltese',
    type: 'living',
    scope: 'individual',
    iso6393: 'mlt',
    iso6392B: 'mlt',
    iso6392T: 'mlt',
    iso6391: 'mt'
  },
  {
    name: 'Maori',
    type: 'living',
    scope: 'individual',
    iso6393: 'mri',
    iso6392B: 'mao',
    iso6392T: 'mri',
    iso6391: 'mi'
  },
  {
    name: 'Marathi',
    type: 'living',
    scope: 'individual',
    iso6393: 'mar',
    iso6392B: 'mar',
    iso6392T: 'mar',
    iso6391: 'mr'
  },
  {
    name: 'Mongolian',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'mon',
    iso6392B: 'mon',
    iso6392T: 'mon',
    iso6391: 'mn'
  },
  {
    name: 'Nepali',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'nep',
    iso6392B: 'nep',
    iso6392T: 'nep',
    iso6391: 'ne'
  },
  {
    name: 'Norwegian',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'nor',
    iso6392B: 'nor',
    iso6392T: 'nor',
    iso6391: 'no'
  },
  {
    name: 'Panjabi',
    type: 'living',
    scope: 'individual',
    iso6393: 'pan',
    iso6392B: 'pan',
    iso6392T: 'pan',
    iso6391: 'pa'
  },
  {
    name: 'Persian',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'fas',
    iso6392B: 'per',
    iso6392T: 'fas',
    iso6391: 'fa'
  },
  {
    name: 'Polish',
    type: 'living',
    scope: 'individual',
    iso6393: 'pol',
    iso6392B: 'pol',
    iso6392T: 'pol',
    iso6391: 'pl'
  },
  {
    name: 'Portuguese',
    type: 'living',
    scope: 'individual',
    iso6393: 'por',
    iso6392B: 'por',
    iso6392T: 'por',
    iso6391: 'pt'
  },
  {
    name: 'Quechua',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'que',
    iso6392B: 'que',
    iso6392T: 'que',
    iso6391: 'qu'
  },
  {
    name: 'Romanian',
    type: 'living',
    scope: 'individual',
    iso6393: 'ron',
    iso6392B: 'rum',
    iso6392T: 'ron',
    iso6391: 'ro'
  },
  {
    name: 'Russian',
    type: 'living',
    scope: 'individual',
    iso6393: 'rus',
    iso6392B: 'rus',
    iso6392T: 'rus',
    iso6391: 'ru'
  },
  {
    name: 'Samoan',
    type: 'living',
    scope: 'individual',
    iso6393: 'smo',
    iso6392B: 'smo',
    iso6392T: 'smo',
    iso6391: 'sm'
  },
  {
    name: 'Serbian',
    type: 'living',
    scope: 'individual',
    iso6393: 'srp',
    iso6392B: 'srp',
    iso6392T: 'srp',
    iso6391: 'sr'
  },
  {
    name: 'Slovak',
    type: 'living',
    scope: 'individual',
    iso6393: 'slk',
    iso6392B: 'slo',
    iso6392T: 'slk',
    iso6391: 'sk'
  },
  {
    name: 'Slovenian',
    type: 'living',
    scope: 'individual',
    iso6393: 'slv',
    iso6392B: 'slv',
    iso6392T: 'slv',
    iso6391: 'sl'
  },
  {
    name: 'Spanish',
    type: 'living',
    scope: 'individual',
    iso6393: 'spa',
    iso6392B: 'spa',
    iso6392T: 'spa',
    iso6391: 'es'
  },
  {
    name: 'Swahili',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'swa',
    iso6392B: 'swa',
    iso6392T: 'swa',
    iso6391: 'sw'
  },
  {
    name: 'Swedish',
    type: 'living',
    scope: 'individual',
    iso6393: 'swe',
    iso6392B: 'swe',
    iso6392T: 'swe',
    iso6391: 'sv'
  },
  {
    name: 'Tamil',
    type: 'living',
    scope: 'individual',
    iso6393: 'tam',
    iso6392B: 'tam',
    iso6392T: 'tam',
    iso6391: 'ta'
  },
  {
    name: 'Tagalog',
    type: 'living',
    scope: 'individual',
    iso6393: 'tgl',
    iso6392B: 'tgl',
    iso6392T: 'tgl',
    iso6391: 'tl'
  },
  {
    name: 'Tatar',
    type: 'living',
    scope: 'individual',
    iso6393: 'tat',
    iso6392B: 'tat',
    iso6392T: 'tat',
    iso6391: 'tt'
  },
  {
    name: 'Thai',
    type: 'living',
    scope: 'individual',
    iso6393: 'tha',
    iso6392B: 'tha',
    iso6392T: 'tha',
    iso6391: 'th'
  },
  {
    name: 'Tibetan',
    type: 'living',
    scope: 'individual',
    iso6393: 'bod',
    iso6392B: 'tib',
    iso6392T: 'bod',
    iso6391: 'bo'
  },
  {
    name: 'Tonga',
    type: 'living',
    scope: 'individual',
    iso6393: 'ton',
    iso6392B: 'ton',
    iso6392T: 'ton',
    iso6391: 'to'
  },
  {
    name: 'Turkish',
    type: 'living',
    scope: 'individual',
    iso6393: 'tur',
    iso6392B: 'tur',
    iso6392T: 'tur',
    iso6391: 'tr'
  },
  {
    name: 'Ukrainian',
    type: 'living',
    scope: 'individual',
    iso6393: 'ukr',
    iso6392B: 'ukr',
    iso6392T: 'ukr',
    iso6391: 'uk'
  },
  {
    name: 'Urdu',
    type: 'living',
    scope: 'individual',
    iso6393: 'urd',
    iso6392B: 'urd',
    iso6392T: 'urd',
    iso6391: 'ur'
  },
  {
    name: 'Uzbek',
    type: 'living',
    scope: 'macrolanguage',
    iso6393: 'uzb',
    iso6392B: 'uzb',
    iso6392T: 'uzb',
    iso6391: 'uz'
  },
  {
    name: 'Vietnamese',
    type: 'living',
    scope: 'individual',
    iso6393: 'vie',
    iso6392B: 'vie',
    iso6392T: 'vie',
    iso6391: 'vi'
  },
  {
    name: 'Welsh',
    type: 'living',
    scope: 'individual',
    iso6393: 'cym',
    iso6392B: 'wel',
    iso6392T: 'cym',
    iso6391: 'cy'
  },
  {
    name: 'Xhosa',
    type: 'living',
    scope: 'individual',
    iso6393: 'xho',
    iso6392B: 'xho',
    iso6392T: 'xho',
    iso6391: 'xh'
  }
];
