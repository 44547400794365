/**
 * Returns a function that checks if a given value falls between the specified min and max values.
 *
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @param {boolean} [inclusive=true] - A flag indicating whether the range should be inclusive.
 * @returns {function(number): boolean} - A function that takes a value and returns true if the value is within the specified range, false otherwise.
 */
export const between =
  (min: number, max: number, inclusive: boolean = true) =>
  (value: number) => {
    if (inclusive) {
      return value >= min && value <= max;
    }
    return value > min && value < max;
  };
