import React, { lazy, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Pusher, { Channel } from 'pusher-js';
import ClassNames from 'classnames';
import { AppState } from 'app';

import { AdminAccountInterface, CandidateSignupVerificationStatus } from '@cohiretech/common-types';

import { PositionInProfile } from 'types';
import { retry } from 'utils';
import { RootState, selectDarkMode } from 'store';

import Loader from 'components/loader';

// @ts-expect-error TS(2322) FIXME: Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
const AdminSideBar = lazy(() => retry(() => import('components/adminsidebar')));

const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_AUTH = process.env.REACT_APP_PUSHER_AUTH;

type AdminRouteProps = {
  component: React.ComponentType<any>;
  admin: AdminAccountInterface | null;
  positions?: PositionInProfile[];
  isPrintView?: boolean;
  path: string | string[];
  exact?: boolean;
  onCompanyVerified?: () => void;
  onCandidateVerified?: (count: number) => void;
  initTab?: CandidateSignupVerificationStatus;
} & ReturnType<typeof mapStateToProps> &
  Partial<AppState>;

type State = {
  adminChannel: Channel | null;
};

class AdminRoute extends React.Component<AdminRouteProps, State> {
  pusher: Pusher;
  constructor(props: AdminRouteProps) {
    super(props);

    this.state = { adminChannel: null };
    this.pusher = new Pusher(PUSHER_KEY!, {
      cluster: 'eu',
      encrypted: true,
      authEndpoint: PUSHER_AUTH
    });
  }

  componentDidMount() {
    const { admin } = this.props;
    const adminChannel = this.pusher.subscribe('private-admin');
    this.setState({
      adminChannel
    });

    if (admin) document.body.classList.remove('public');
  }

  componentWillUnmount() {
    if (this.state.adminChannel) {
      this.pusher.unsubscribe('private-admin');
    }
  }

  render() {
    const { component: Component, admin, positions, ...rest } = this.props;
    const { adminChannel } = this.state;

    return (
      <Route
        render={props =>
          admin ? (
            <div className="admin_container">
              <AdminSideBar admin={admin} positions={positions} {...props} {...rest} />
              <Suspense
                fallback={
                  <Loader
                    className={ClassNames('large', 'page_loader', {
                      dark: this.props.darkMode
                    })}
                  />
                }
              >
                <Component
                  admin={admin}
                  positions={positions}
                  adminChannel={adminChannel}
                  {...props}
                  {...rest}
                />
              </Suspense>
            </div>
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  darkMode: selectDarkMode(state)
});

export default connect(mapStateToProps)(AdminRoute);
