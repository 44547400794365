import { RefObject, useMemo, useRef, useState } from 'react';

import {
  CandidateApplicationTrackerCreatedListingInterface as CandidateATCreatedListing,
  RemoteOption
} from '@cohiretech/common-types';

import { isEmpty } from 'utils';
import { arraysIntersect } from 'utils/array';
import { APIResponse } from 'types';
import {
  addCandidateCreatedApplicationTrackerListing,
  addListingToCandidateApplicationTracker,
  updateCandidateCreatedATListing
} from 'v2/services/fetchers/candidate/applicationTrackerPosition';
import { isFailure } from 'v2/services/fetchers/apiTools';
import { useCandidateATS } from 'store';

import { RemoteLocations } from 'components/remotelocationsfield';
import CompanyEditPositionLogisticsSalaryRange from 'components/companyeditposition/logistics/salaryrange';
import CompanyEditPositionInterests from 'components/companyeditposition/interests';

import {
  CandidateATPositionFormHandler,
  CandidateATCreatedPosition,
  ValidateAndPreparePosition,
  SaveCandidateATPosition
} from './CandidateATPositionForm.helpers';

const { Onsite, Hybrid } = RemoteOption;

const REQUIRED_FIELDS: (keyof CandidateATCreatedListing)[] = [
  'title',
  'description',
  'seniorities',
  'remote',
  'companyName'
];

const REQUIRED_FIELDS_WITH_LOCATION: (keyof CandidateATCreatedListing)[] = [
  ...REQUIRED_FIELDS,
  'locationLabel'
];

export const useCandidateATPosition = (initialPosition: Partial<CandidateATCreatedPosition>) => {
  const [position, setPosition] = useState(initialPosition);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const remoteLocationsRef = useRef<RemoteLocations>(null);
  const salaryRangeRef = useRef<CompanyEditPositionLogisticsSalaryRange>(null);
  const interestsRef =
    useRef<InstanceType<typeof CompanyEditPositionInterests.WrappedComponent>>(null);

  const isLocationRequired = useMemo(
    () => arraysIntersect(position.remote, [Onsite, Hybrid]),
    [position]
  );

  const validateAndPreparePosition: ValidateAndPreparePosition = () => {
    const valid = (isLocationRequired ? REQUIRED_FIELDS_WITH_LOCATION : REQUIRED_FIELDS).every(
      field => !isEmpty(position[field])
    );

    setFormSubmitted(!valid);

    if (!valid) return;

    const { id, candidateApplicationTrackerCreatedListingID, stage, ...editablePosition } =
      position;
    const { techStack, exampleSkills, ...interests } = interestsRef.current?.state || {};

    const updatedPosition = {
      ...editablePosition,
      ...interests,
      ...remoteLocationsRef.current
    } as CandidateATCreatedListing;

    const { salaryMin, salaryMax, currency } = salaryRangeRef.current?.getAnswer() || {};

    if (salaryMin === -1 && salaryMax === -1) return updatedPosition;
    return {
      ...updatedPosition,
      salaryMin: salaryMin === -1 ? salaryMax : salaryMin,
      salaryMax: salaryMax === -1 ? salaryMin : salaryMax,
      currency
    };
  };

  return {
    position,
    formSubmitted,
    isLocationRequired,
    interestsRef,
    salaryRangeRef,
    remoteLocationsRef,
    updatePosition: (position: Partial<CandidateATCreatedListing>) => {
      setPosition(prevPosition => ({ ...prevPosition, ...position }));
    },
    validateAndPreparePosition
  };
};

type UseCreateOrEditCandidateATPositionParams = {
  initialPosition: CandidateATCreatedPosition;
  candidateATPositionFormRef: RefObject<CandidateATPositionFormHandler>;
};

export const useSaveCandidateATPosition = ({
  initialPosition,
  candidateATPositionFormRef
}: UseCreateOrEditCandidateATPositionParams) => {
  const { loadATSStageData } = useCandidateATS();

  const [loading, setLoading] = useState(false);

  const saveCandidateATPosition: SaveCandidateATPosition = async ({
    setErrorMessage,
    closePopup
  }) => {
    const {
      id: listingID,
      candidateApplicationTrackerCreatedListingID: createdListingID,
      stage,
      ...importedPosition
    } = initialPosition;
    let listing = importedPosition as Omit<CandidateATCreatedListing, 'id'>;

    const edit = createdListingID;
    const create = !edit && !listingID;

    if (create || edit) {
      const updatedPosition = candidateATPositionFormRef.current?.validateAndPreparePosition();

      if (!updatedPosition) return; // invalid
      listing = updatedPosition;
    }

    setLoading(true);

    let response: APIResponse;

    if (create) {
      response = await addCandidateCreatedApplicationTrackerListing({ listing, stage });
    } else if (edit) {
      response = await updateCandidateCreatedATListing(listingID!, listing);
    } else {
      response = await addListingToCandidateApplicationTracker({ listingID: listingID!, stage });
    }

    if (isFailure(response)) {
      setErrorMessage(response.message);
      setLoading(false);
      return;
    }

    await loadATSStageData(stage, true);

    setLoading(false);
    closePopup();
  };

  return { loading, saveCandidateATPosition };
};
