import { ReactNode } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import styled from 'styled-components';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { LabelContainer, SearchOptionContent } from './SearchOption.styled';

type SkeletonWidth = SkeletonProps['width'];

export type SkeletonSearchOptionProps = {
  labelWidth: SkeletonWidth;
  countWidth?: SkeletonWidth;
} & Pick<SkeletonProps, 'count' | 'height'>;

type ExtendedSkeletonSearchOptionProps = Partial<SkeletonSearchOptionProps> & {
  iconOrLogo: ReactNode;
};

export default function SkeletonSearchOption({
  iconOrLogo,
  labelWidth,
  countWidth,
  height
}: ExtendedSkeletonSearchOptionProps) {
  return (
    <SkeletonOption>
      <SearchOptionContent className="search_option_content">
        <LabelContainer className="search_option_label_container">
          {iconOrLogo}
          <Skeleton width={labelWidth} height={height} duration={2} />
        </LabelContainer>
        <ConditionalRender predicate={countWidth}>
          <Skeleton width={countWidth} duration={2} />
        </ConditionalRender>
      </SearchOptionContent>
    </SkeletonOption>
  );
}

const SkeletonOption = styled.div`
  padding: 0.5rem 1rem;
`;
