import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { useDarkMode } from 'hooks/useDarkMode';

import { InsightsBlock } from '../../InsightsBlock';
import { usePositionProfile } from '../../PositionProfile.context';

import {
  ApexChartProps,
  getComparison,
  getMatchComparisonChartProps,
  getMatchComparisonData
} from './MatchComparisonBlock.helpers';

const SUB_TITLE = 'Profile match comparison';
const DEFAULT_TITLE = 'See the average score of people accepted';
const DEFAULT_DISCLAIMER =
  'Comparing your score with the average score of people who have been accepted for this position.';

export default function MatchComparisonBlock() {
  const [title, setTitle] = useState(DEFAULT_TITLE);
  const [disclaimer, setDisclaimer] = useState(DEFAULT_DISCLAIMER);
  const [apexChartProps, setApexChartProps] = useState<ApexChartProps>();
  const darkMode = useDarkMode();

  const { insightsData, isDummyData } = usePositionProfile();

  useEffect(() => {
    const { candidate, average, hasNoPositionData } = getMatchComparisonData(insightsData);
    const comparison = getComparison(candidate, average);

    setTitle(
      `You score ${comparison} successful ${hasNoPositionData ? 'candidates' : 'applicants'}`
    );
    setApexChartProps(getMatchComparisonChartProps([candidate, average], isDummyData, darkMode));
    if (hasNoPositionData) {
      setDisclaimer(DEFAULT_DISCLAIMER.replace('for this position', 'on cord'));
    }
  }, [insightsData]);

  const { series = [], options = {} } = apexChartProps || {};

  return (
    <InsightsBlock
      size="small"
      subTitle={SUB_TITLE}
      defaultTitle={DEFAULT_TITLE}
      title={title}
      disclaimer={disclaimer}
    >
      <div>
        <ReactApexChart type="line" series={series} options={options} />
      </div>
    </InsightsBlock>
  );
}
