import { createAsyncThunk } from '@reduxjs/toolkit';
import { OperatingCountry } from '@cohiretech/common-types';

import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { CompanyOnboardingItem } from 'types';
import { AppDispatch, RootState } from 'store';
import { getLocalisationFromServer } from 'v2/services/fetchers/public/localisation';

import { setOnboardingItemCompletion, selectCompanyProfile, setLocalisationCountry } from '.';

export const persistCompanyOnboardingItemCompleted = createAsyncThunk<
  void,
  CompanyOnboardingItem,
  { dispatch: AppDispatch; state: RootState }
>('user/persistOnboardingItemCompleted', async (item, { dispatch, getState }) => {
  const state = getState();
  const profile = selectCompanyProfile(state);

  if (!profile) return;

  dispatch(setOnboardingItemCompletion(item));
  setLocalStorageItem(`onboarding_${item}_complete`, true);
});

// Thunks (async actions & side-effects)
export const initLocalisation = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
  'ui/initLocalisation',
  async (_, { dispatch }) => {
    let country = getLocalStorageItem<OperatingCountry | ''>('country');
    if (!country) {
      const { status, data } = await getLocalisationFromServer();
      if (status === 'failure') country = OperatingCountry.UK;
      else country = data?.preferCountry as OperatingCountry;
    }

    dispatch(setLocalisationCountry(country));
    setLocalStorageItem('country', country); // for legacy support
  }
);
