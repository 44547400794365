import React from 'react';

type ScoreInputProps = {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
};

const ScoreInput: React.FC<ScoreInputProps> = ({ value, onChange }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const intValue = parseInt(e.target.value, 10) || undefined;
    onChange(intValue);
  };

  return (
    <input
      type="number"
      placeholder="Enter quality score (1-10)"
      value={value || undefined}
      onChange={handleInputChange}
      min="1"
      max="10"
    />
  );
};

export default ScoreInput;
