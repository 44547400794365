import styled from 'styled-components';

export const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.25rem 1rem;

  input {
    padding: 0.5rem 2rem 0.5rem 2.5rem;
    border-radius: 0.5rem;
    width: 100%;
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  left: 0.675rem;
  font-size: 1rem;
`;

export const ClearIcon = styled.span`
  position: absolute;
  right: 0.675rem;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem;

  &[class*='icon_'] {
    font-size: ${({ theme: { typography } }) => typography.normal};
  }

  &:not([class*='icon_']) {
    text-decoration: underline;
  }

  &.icon_close {
    position: absolute;
  }
`;
