import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import useLocalisation from 'hooks/useLocalisation';
import { useDarkMode } from 'hooks/useDarkMode';
import { getPublicCompanyStats } from 'fetcher';
import { commify } from 'utils';

import PageSection from 'components/pagesection';

import { getImageMap } from './helpers';
import { CordAnimationLTR, CordAnimationRTL } from './animations';
import SourcingSection from './sourcing';
import SuperchargePipeline from './superchargepipeline';
import MakeDirectHires from './makedirecthires';
import GetApplicants from './getapplicants';
import MessagingSection from './messaging';

import '../style.scss';

type Props = {
  noOfActiveUsers: string;
};

const initialCompanyStats = {
  peopleSourced: '12,345',
  companiesSourcing: '723',
  activeCompanyAccount: '453'
};

export type PipelineCompanyStats = typeof initialCompanyStats;

const SourcingPipeline = ({ noOfActiveUsers }: Props) => {
  const [data, setData] = useState(initialCompanyStats);
  const darkMode = useDarkMode();
  const country = useLocalisation();
  const imageMap = getImageMap(country, darkMode);

  useEffect(() => {
    async function getPublicData() {
      const publicData = await getPublicCompanyStats();
      if (publicData?.data) {
        const { companiesSourcingThisMonth, peopleSourcedThisMonth, activeCompanyAccount } =
          publicData.data;
        const newData = {
          peopleSourced: commify(peopleSourcedThisMonth),
          companiesSourcing: commify(companiesSourcingThisMonth),
          activeCompanyAccount: commify(activeCompanyAccount)
        };
        setData(newData);
      }
    }
    getPublicData();
  }, []);

  return (
    <PageSection
      className={classNames('sourcing_pipeline', 'grey_background', { dark_pipe: darkMode })}
      widthClassName="page_view"
    >
      <GetApplicants src={imageMap.applicants} />
      <CordAnimationRTL />
      <SourcingSection src={imageMap.sourcing} noOfActiveUsers={noOfActiveUsers} />
      <CordAnimationLTR />
      <MessagingSection src={imageMap.messaging} stats={data} />
      <CordAnimationRTL margin="-41px auto -63px" />
      <SuperchargePipeline src={imageMap.pipeline} stats={data} />
      <CordAnimationLTR margin="-73px auto -67px" />
      <MakeDirectHires src={imageMap.directHire} />
    </PageSection>
  );
};

export default SourcingPipeline;
