// DEMO LINKS
export const ENTERPRISE_BOOK_DEMO_LINK = 'https://meetings.hubspot.com/growth9/cord-3';
export const UNLIMITED_BOOK_DEMO_LINK = 'https://meetings.hubspot.com/growth9/cord-4';
export const GENERIC_BOOK_DEMO_LINK = 'https://meetings.hubspot.com/growth9/cord-2';
export const FOUNDERS_BOOK_DEMO_LINK =
  'https://meetings.hubspot.com/growth9/founders-programme-demo';
export const NO_FORM_BOOK_DEMO_LINK = 'https://meetings.hubspot.com/growth9/demos';
export const REFERRALS_BOOK_DEMO_LINK = 'https://meetings.hubspot.com/growth9/referral-calendar';
export const PAY_PER_HIRE_DEMO_LINK = 'https://meetings.hubspot.com/growth9/pay-per-hire-demo-link';
export const SELF_SERVE_DEMO_LINK =
  'https://meetings.hubspot.com/growth9/self-serve-demo-only-june-2024';
export const SAN_FRAN_DEMO_LINK = 'https://meetings.hubspot.com/angus-kennedy/angus-sf-demo';
export const REFRESHER_DEMO_LINK = 'https://meetings.hubspot.com/sorrel/cord-refresher';

// BOOK A CALL LINKS
export const BOOK_A_CALL_LINK = 'https://meetings.hubspot.com/growth9/book-a-call';

// ONBOARD LINKS
export const SAN_FRAN_ONBOARD_LINK = 'https://meetings.hubspot.com/patrick-charles/us-onboard';
export const CS_ROUND_ROBIN_LINK = 'https://meetings.hubspot.com/patrick-charles/onboarding';
export const WILL_ONBOARD_LINK = 'https://meetings.hubspot.com/will145/cord-onboard';
export const SORREL_ONBOARD_LINK = 'https://meetings.hubspot.com/sorrel/onboarding';
export const AGENCIES_ROUND_ROBIN_LINK = 'https://meetings.hubspot.com/sorrel/agency-';

// WEBINAR LINKS
export const MASTERCLASS_WEBINAR_LINK =
  'https://us02web.zoom.us/webinar/register/WN_OMbRSgYoRxmZsC4mJFlEsA';

// RELEASES
export const APPLICATION_BLOCKING =
  'https://releases.cord.co/releases/oc1LsPRergfLZa5YNYQLXQ/%F0%9F%95%B9-block-applications-from-people-that-require-a-visa/mLaKphepyRVWEVHDp3NaWe';
