import React, { useState, useEffect } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { CompanySubscriptionPlan, Frequency } from '@cohiretech/common-types';

import { V3PricingOptions, PricingCountry, CurrentPlanDetails, UpgradeAction } from 'types';
import { getCurrencyFromPricingCountry, commify } from 'utils';
import { checkIfSubscriptionExpired } from 'utils/companyUtils';
import { getV3PlanCreditLimit } from 'utils/pricingUtils';
import { V3_TIER_PLAN_MAP } from 'consts';
import media from 'styles/media';

import Badge from 'components/badge';
import BulletList, { Bullet } from 'components/bulletlist';
import Button, { Props as ButtonProps } from 'components/button';
import ErrorMessage from 'components/errormessage';
import PaymentMethodPopup, { Props as PaymentPopupProps } from 'components/paymentmethodpopup';
import PersonSourcedExplanationTooltip from 'components/personsourcedexplanationtooltip';
import RangeFilter from 'components/rangefilter';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

import { GrowthTiers, getButtonLink, getActivateButtonProps } from '../helper';

import {
  handleUpgrade,
  handlePlanEnquiry,
  handleReactivation,
  proceedToPayment,
  Result
} from './helper';

type PricingOption = {
  plan: string;
  label: string;
  description: string;
  features: string[];
  button: ButtonProps;
};

type Props = {
  pricingOption: PricingOption;
  pricingType: PricingCountry;
  planPeriod: Frequency;
  currentPlanDetails?: CurrentPlanDetails<V3PricingOptions | CompanySubscriptionPlan.Basic>;
  growthTiers: GrowthTiers;
  darkMode: boolean;
  showSourcingPopup: () => void;
};

type PaymentPopup = { show: boolean } & Result['paymentData'];

type Action = UpgradeAction | 'annual' | 'reactivation';

const PEOPLE_MIN = 20;
const PEOPLE_MAX = 50;
const PEOPLE_STEP = 10;
const ERROR_MESSAGE = 'Something went wrong. Try again.';
const CONFIRMATION_MESSAGE = 'A member of the team will be in touch soon.';

export default function PricingPlanCard({
  pricingOption,
  pricingType,
  planPeriod,
  currentPlanDetails,
  growthTiers: tiers,
  darkMode,
  showSourcingPopup
}: Props) {
  const { plan, label, description, features, button } = pricingOption;
  const { plan: currentPlan, billingFrequency } = currentPlanDetails || {};
  const currentCreditLimit = getV3PlanCreditLimit(currentPlan);

  const [peopleSourced, setPeopleSourced] = useState(currentCreditLimit || PEOPLE_MIN);
  const [growthTiers, setGrowthTiers] = useState(tiers);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentPopup, setPaymentPopup] = useState<Partial<PaymentPopup>>({ show: false });

  const isMonthlyPlan = planPeriod === Frequency.monthly;
  const isEnterprise = plan === 'enterprise';
  const isSubscriptionExpired = checkIfSubscriptionExpired(currentPlan);
  const selectedPlan = isEnterprise
    ? V3PricingOptions.V3Unlimited
    : V3_TIER_PLAN_MAP[peopleSourced];
  const {
    price = 0,
    hasDiscount,
    discountedPrice = 0,
    urlPath = '',
    mode,
    enquired
  } = growthTiers?.[planPeriod][selectedPlan] || {};

  useEffect(() => {
    if (errorMessage) setErrorMessage('');
  }, [peopleSourced]);

  useEffect(() => {
    setGrowthTiers(tiers);
  }, [tiers]);

  const getPrice = (isDiscounted?: boolean) => {
    const currency = getCurrencyFromPricingCountry(pricingType);

    return `${currency}${commify(Math.round(isDiscounted ? discountedPrice : price))}`;
  };

  const getPeopleSourced = () => {
    if (isEnterprise) return 'Unlimited sourcing credits';
    if (isMonthlyPlan) return `${peopleSourced} sourcing credits/month`;
    return `${peopleSourced * 12} sourcing credits/year`;
  };

  const isCurrentPlanSelected = () => {
    if (!currentPlanDetails || billingFrequency !== planPeriod) return false;
    return currentPlanDetails?.plan === selectedPlan;
  };

  const handleSelect = async (action: Action) => {
    setLoading(true);

    let result: Result;

    switch (action) {
      case 'upgrade':
        result = await handleUpgrade(selectedPlan, urlPath, mode);
        break;
      case 'reactivation':
        result = await handleReactivation(selectedPlan, urlPath, pricingType);
        break;
      default:
        result = await handlePlanEnquiry(urlPath, planPeriod, action, isSubscriptionExpired, mode);

        if (result.status === 'success') {
          const copiedGrowthTiers = { ...growthTiers };
          Object.assign(copiedGrowthTiers[planPeriod][selectedPlan], { enquired: true });

          setGrowthTiers(copiedGrowthTiers);
        }

        break;
    }

    const { status, errorMsg, paymentData } = result;

    if (paymentData) setPaymentPopup({ show: true, ...paymentData });
    setErrorMessage(status === 'failure' ? errorMsg || ERROR_MESSAGE : '');
    setLoading(false);
  };

  // For existing users
  const getButtonAction = (): { text: string; action: Action } | undefined => {
    if (isSubscriptionExpired) {
      return { text: 'Reactivate subscription', action: isMonthlyPlan ? 'reactivation' : 'annual' };
    }
    if (!isMonthlyPlan) return { text: 'Enquire now', action: 'annual' };
    if (currentCreditLimit < peopleSourced) return { text: 'Upgrade plan', action: 'upgrade' };
    if (currentCreditLimit > peopleSourced) return { text: 'Downgrade plan', action: 'downgrade' };
  };

  const getButtonProps = (): ButtonProps => {
    if (isCurrentPlanSelected()) return { ...button, text: 'Your current plan', disabled: true };
    if (!currentPlan || isEnterprise) {
      return { ...button, link: getButtonLink(selectedPlan, pricingType, planPeriod) };
    }
    if (currentPlan === CompanySubscriptionPlan.Basic) {
      return {
        ...button,
        ...getActivateButtonProps(selectedPlan, planPeriod, pricingType)
      };
    }

    const { text, action } = getButtonAction() || {};

    return {
      ...button,
      text,
      action: action ? () => handleSelect(action) : undefined,
      disabled: enquired
    };
  };

  const isFounderProgram = new RegExp(/(-discount-25$)|(^v4-growth-founder)/).test(
    currentPlanDetails?.urlPath || ''
  );
  const formattedPrice = getPrice(hasDiscount);

  return (
    <PricingPlan className={ClassNames(plan, 'pricing_plan_card')} dark={darkMode}>
      <h3 className="plan_title">{label}</h3>
      <p className="plan_description">{description}</p>
      <ConditionalRender predicate={hasDiscount && isMonthlyPlan && !isEnterprise}>
        <OriginalPrice>{getPrice()}</OriginalPrice>
        <ConditionalRender predicate={isFounderProgram}>
          <Badge key="discount" text="25% Discount" />
        </ConditionalRender>
      </ConditionalRender>
      <h4 className="plan_price lightblue_text">
        <ConditionalRender predicate={!isEnterprise} fallback="Custom">
          {formattedPrice}
          <span>/month{isMonthlyPlan ? '' : ', billed annually'}</span>
        </ConditionalRender>
      </h4>
      <ConditionalRender predicate={!isEnterprise}>
        <PeopleSourcedInput
          minValue={PEOPLE_MIN}
          maxValue={PEOPLE_MAX}
          range={peopleSourced}
          step={PEOPLE_STEP}
          filterName={label}
          onSelect={value => setPeopleSourced(value)}
          selectOnChange
          showRange
          showMarkers
          getLabel={value => (isMonthlyPlan ? value : (value as number) * 12)}
          disabled={loading}
          dark={darkMode}
        />
      </ConditionalRender>
      <PeopleSourced className="lightblue_text">
        {getPeopleSourced()}
        <PersonSourcedExplanationTooltip
          darkMode={darkMode}
          showSourcingPopup={showSourcingPopup}
        />
      </PeopleSourced>
      <FeatureList>
        {features.map((feature, idx) => (
          <Bullet key={`${plan}_feature_${idx}`} iconCircleColour="light">
            {feature}
          </Bullet>
        ))}
      </FeatureList>
      <Button className="eighteen_px_font" loading={loading} {...getButtonProps()} />
      <ConditionalRender predicate={errorMessage || enquired}>
        <ErrorMessage type={errorMessage ? 'critical' : undefined}>
          {errorMessage || CONFIRMATION_MESSAGE}
        </ErrorMessage>
      </ConditionalRender>
      <ConditionalRender predicate={paymentPopup.show}>
        <CardPaymentPopup
          plan={{
            planInfo: { label, price: formattedPrice, urlPath },
            cycle: planPeriod,
            pricingType
          }}
          onClose={() => setPaymentPopup({ ...paymentPopup, show: false })}
          {...(paymentPopup as PaymentPopup)}
        />
      </ConditionalRender>
    </PricingPlan>
  );
}

type PopupProps = {
  plan: Pick<PaymentPopupProps, 'planInfo' | 'cycle' | 'pricingType'>;
  onClose: () => void;
} & Omit<PaymentPopup, 'show'>;

const CardPaymentPopup = ({ plan, cards, sessionID, stripeRegion, onClose }: PopupProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handlePayment = async () => {
    const { status, errorMsg } = await proceedToPayment(sessionID, stripeRegion);

    if (status === 'failure') setErrorMessage(errorMsg || ERROR_MESSAGE);
  };

  const { planInfo, cycle, pricingType } = plan;

  return (
    <PaymentMethodPopup
      planInfo={planInfo}
      cycle={cycle}
      pricingType={pricingType}
      cards={cards}
      handleClose={onClose}
      proceedToPayment={() => handlePayment()}
      errorMessage={errorMessage}
      setErrorMessage={message => setErrorMessage(message)}
    />
  );
};

const PricingPlan = styled.div<{ dark: boolean }>`
  width: calc(50% + 30px);
  padding: 35px;
  box-sizing: border-box;
  background: ${({ dark }) => (dark ? colours.darkBgColour : 'white')};
  box-shadow: 0px 0px 30px ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(49, 71, 95, 0.2)')};
  border-radius: 20px;

  .plan_description {
    margin: 0 0 20px;
  }

  .plan_price {
    margin-bottom: 6px;
    font-size: 36px;

    span {
      font-size: ${typography.normal};
    }
  }

  &.growth {
    z-index: 2;
  }

  &.enterprise {
    width: calc(50% - 30px);
    background: ${({ dark }) => (dark ? '#283d54' : colours.fontColour)};
    color: white;
    border-radius: 0 20px 20px 0;
    box-shadow: 0px 0px 20px rgba(49, 71, 95, 0.1);
  }

  ${media.mobile`
    width: 100% !important;
    padding: 30px;

    &.enterprise {
      border-radius: 20px;
    }

    &.growth .button {
      width: 100%;
    }
  `}

  ${media.tabletLandscapeMin`
    max-width: 490px; 

    &.enterprise {
      max-width: 460px;
    }
  `}
`;

const OriginalPrice = styled.span`
  color: ${colours.errorColour};
  text-decoration: line-through solid ${colours.errorColour};
`;

const PeopleSourcedInput = styled(RangeFilter)<{ dark: boolean }>`
  padding-bottom: 6px;

  .range {
    height: auto;
    padding-top: 20px;
  }

  .min_value {
    left: -6px;
  }

  .min_value,
  .max_value {
    top: 0;
    padding: 0;
    font-size: ${typography.small};
    color: ${({ dark }) => (dark ? colours.inputsColour : 'rgba(49, 71, 95, 0.4)')};
  }

  .input-range__track--background {
    ${({ dark }) =>
      dark &&
      css`
        background: ${colours.fontColourDarkShade};
      `}
  }
`;

const PeopleSourced = styled.div`
  font-size: ${typography.smallheader};
  font-weight: ${typography.bold};

  .icon_help {
    font-size: ${typography.normal};
    color: ${({ theme: { colours, fn } }) => fn.opaqueColour(colours.primaryColour, 0.6)};
  }

  ${media.mobile`
    font-size: ${typography.normal};
  `}
`;

const FeatureList = styled(BulletList)`
  margin: 35px 0 45px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .bullet_item {
    padding-bottom: 0;

    .icon_bullet {
      padding-right: 10px;
    }

    .bullet_content p {
      margin: 0;
    }

    ${media.mobile`
      .icon_bullet {
        font-size: ${typography.normal};
      }

      .bullet_content p {
        font-size: ${typography.small};
      }
    `}
  }
`;
