const CORDLIVE_JANUARY_24_LISTINGS = process.env.REACT_APP_CORDLIVE_LISTING_IDS!;

export const isListingInvisible = (listingID: number, listingInvisible: boolean) => {
  if (CORDLIVE_JANUARY_24_LISTINGS.includes(String(listingID))) return false; // always show listing
  return listingInvisible;
};

export const getPositionViewElement = () => {
  return document.getElementById('position_view')!;
};
