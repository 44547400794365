import styled from 'styled-components';

export const BentoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-flow: dense;
  gap: 2rem;

  .bento_box_primary {
    grid-column: span 8;
    grid-row: span 4;

    ${({ theme: { media } }) => media.tablet` 
      grid-column: span 12;
    `}
  }

  .bento_box_secondary {
    grid-column: span 6;
    grid-row: span 4;

    ${({ theme: { media } }) => media.tablet` 
      grid-column: span 12;
    `}
  }

  .bento_box_tertiary {
    grid-column: span 4;
    grid-row: span 4;

    ${({ theme: { media } }) => media.tablet` 
      grid-column: span 12;
    `}
  }

  .bento_box {
    transition: all 0.3s ease-in-out;
  }
`;
