import React, { useState, useEffect } from 'react';
import { Location } from 'history';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import {
  CompanySubscriptionPlan,
  PricingSubscriptionPlan,
  Frequency
} from '@cohiretech/common-types';

import { PricingCountry, CurrentPlanDetails, SubscriptionPlan } from 'types';
import { getCompanyUpgradeOptions } from 'fetcher';
import { checkIfSubscriptionExpired } from 'utils/companyUtils';
import { getV4PricingOptions } from 'utils/pricingUtils';
import media from 'styles/media';

import Badge from 'components/badge';
import Loader from 'components/loader';
import MultiStageToggle from 'components/multistagetoggle';
import PricingBottomSections from 'components/pricingbottomsections';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { getPricingType } from 'v2/components/ui/templates/Pricing';
import { PricingPlans } from 'v2/components/ui/styles';

import typography from 'css/base/_typography.module.scss';

import PricingMiddleSection from './pricingmiddlesection';
import PricingPlanCard from './pricingplancard';
import { GrowthPlan, getUpdatedGrowthPlan } from './helper';

type Props = {
  title?: string;
  subtitle?: string;
  pricingCountry?: PricingCountry;
  isPopup?: boolean;
  location: Location;
  darkMode: boolean;
  showVideoSection?: boolean;
  showStorySection?: boolean;
  showCharitySection?: boolean;
  currentPlanDetails?: CurrentPlanDetails;
  availablePlans?: PricingSubscriptionPlan[];
  previousPlan?: SubscriptionPlan;
  showReactivationModal?: boolean;
  className?: string;
  demoCompany: boolean;
};

export const FREQUENCIES = [
  { component: 'Monthly', stage: Frequency.monthly },
  { component: ['Annual', <Badge key="discount" text="25% off" />], stage: Frequency.annual }
];

export default function PricingPlansV4({
  title,
  subtitle,
  pricingCountry,
  isPopup,
  location,
  darkMode,
  showVideoSection,
  showStorySection,
  showCharitySection,
  currentPlanDetails,
  availablePlans,
  previousPlan,
  showReactivationModal,
  className,
  demoCompany
}: Props) {
  const { plan: currentPlan, billingFrequency, hasDiscount } = currentPlanDetails || {};
  const [frequency, setFrequency] = useState(billingFrequency || Frequency.monthly);
  const [growthTiers, setGrowthTiers] = useState<GrowthPlan>();
  const isExpiredAccount = checkIfSubscriptionExpired(currentPlan);

  const pricingType = pricingCountry || getPricingType(location?.pathname);
  const isNewUser = !currentPlan || currentPlan === CompanySubscriptionPlan.Basic;

  useEffect(() => {
    getGrowthTiers();
  }, [pricingType]);

  const getGrowthTiers = async () => {
    let potentialPlans = availablePlans;

    if (currentPlan && currentPlan !== CompanySubscriptionPlan.Basic && !isExpiredAccount) {
      const { upgrade } = await getCompanyUpgradeOptions();
      potentialPlans = upgrade;
    }

    setGrowthTiers(getUpdatedGrowthPlan(pricingType, potentialPlans, hasDiscount));
  };

  return (
    <PricingV4 id="plans" className={className}>
      <div className={ClassNames('center_alignment', { page_view: !isPopup })}>
        {title && <h1 className="heading">{title}</h1>}
        <h2 className={isPopup ? 'pop_up_sub_title' : 'subheading'}>
          {subtitle || 'No hiring fees. No long term commitments. Cancel anytime.'}
        </h2>
        <FrequencyToggle
          type="pills_toggle"
          darkMode={darkMode}
          currentStage={frequency}
          stages={FREQUENCIES}
          onStageSelect={stage => setFrequency(stage)}
          hideBadge={hasDiscount}
        />
        <ConditionalRender
          predicate={growthTiers}
          fallback={<Loader className={ClassNames('medium', { dark: darkMode })} />}
        >
          <PricingPlans className="pricing_plans_container">
            {Object.entries(getV4PricingOptions()).map(([plan, pricingOption]) => (
              <PricingPlanCard
                key={`pricing_v4_${plan}`}
                darkMode={darkMode}
                pricingOption={{ plan, ...pricingOption }}
                pricingType={pricingType}
                planPeriod={frequency}
                currentPlanDetails={currentPlanDetails}
                growthTiers={growthTiers!}
                demoCompany={demoCompany}
              />
            ))}
          </PricingPlans>
        </ConditionalRender>
        <PricingMiddleSection
          pricingType={pricingType}
          location={location}
          currentPlanDetails={currentPlanDetails}
          previousPlan={previousPlan}
          showReactivationModal={showReactivationModal}
        />
      </div>
      <PricingBottomSections
        showVideoSection={showVideoSection}
        showStorySection={showStorySection}
        showCharitySection={showCharitySection}
        pricingCountry={pricingType}
        isNewUser={isNewUser}
        pricingVer={4}
      />
    </PricingV4>
  );
}

const PricingV4 = styled.div`
  padding-top: 30px;

  .subheading,
  .pop_up_sub_title {
    margin: 0.5rem auto 1.5rem !important;
  }

  .pricing_plans_container {
    margin: 3.5rem auto 3rem;
  }

  .plan_title {
    margin: 0 0 2px;
    font-size: 24px;
  }

  .badge,
  .arrow_link {
    margin-right: 0;
  }

  ${media.mobile`
    .icon_help {
      display: none;
    }
  `}
`;

// WIP - After release: Some styles should be moved to the MultiStageToggle component
const FrequencyToggle = styled(MultiStageToggle)<{ darkMode: boolean; hideBadge?: boolean }>`
  padding: 8px 10px;

  .multi_stage_toggle_item {
    padding: 8px 16px;
    font-size: ${typography.normal};

    &.active {
      cursor: auto;
    }

    &:not(.active) {
      color: rgba(228, 230, 235, 0.4);

      ${({ darkMode }) =>
        !darkMode &&
        css`
          color: rgba(49, 71, 95, 0.4);

          &:hover {
            color: rgba(49, 71, 95, 0.6);
          }
        `}
    }
  }

  .badge {
    ${({ hideBadge }) =>
      hideBadge &&
      css`
        display: none;
      `}
  }

  ${media.mobile`
    gap: 4px;
  `}
`;
