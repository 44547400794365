import axios from 'axios';

import { APIResponse, SpecialEnquiryFormType } from 'types';

const PUBLIC_SAN_FRANCISCO_APPLICATION = process.env.REACT_APP_PUBLIC_SAN_FRANCISCO_ACCESS!;
const PUBLIC_SAN_FRANCISCO_INIT = process.env.REACT_APP_PUBLIC_SAN_FRANCISCO_ENQUIRY!;

export const applyToSanFrancisco = async (form: SpecialEnquiryFormType): Promise<APIResponse> => {
  try {
    await axios.post(PUBLIC_SAN_FRANCISCO_APPLICATION, form);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

export const initSanFranciscoEnquiry = async (
  form: SpecialEnquiryFormType
): Promise<APIResponse> => {
  try {
    await axios.post(PUBLIC_SAN_FRANCISCO_INIT, form);

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
