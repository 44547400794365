/**
 * Wraps the input in an array and returns it.
 * If the input is already an array, it returns the input as is.
 *
 * @template T - The type of the input.
 * @param {T | T[]} input - The input to wrap in an array
 * @returns {T[]} - The input wrapped in an array or the input as is if it's already an array.
 */
export function toArray<T>(input: T | T[]): T[] {
  if (Array.isArray(input)) {
    return input;
  }

  return [input];
}
