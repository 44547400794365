import React, { PropsWithChildren, ReactNode } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { macBookScreen, mobile } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';

import Badge from 'components/badge';
import Button, { Props as ButtonProps } from 'components/button';
import Tooltip from 'components/tooltip';
import LinkWithIcon, { LinkWithIconProps } from 'components/linkwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type BlockProps = {
  title?: string | ReactNode;
  helpTooltip?: { text: string; link?: string; target?: string; onClick?: () => void };
  description?: string | ReactNode;
  saving?: boolean;
  newFeature?: boolean;
  saved?: boolean;
  button?: ButtonProps;
  link?: LinkWithIconProps;
  className?: string | string[];
  id?: string;
  error?: string;
  success?: string;
  background?: 'white' | 'blue' | 'grey' | 'red' | 'dark_blue' | 'light_grey';
  notificationBox?: boolean;
  linkWithIcon?: ReactNode;
};

function Block({
  title,
  helpTooltip,
  description,
  saving,
  newFeature,
  saved,
  button,
  link,
  children,
  className,
  id,
  error,
  success,
  background,
  notificationBox,
  linkWithIcon
}: PropsWithChildren<BlockProps>) {
  const darkMode = useDarkMode();
  const helpElement = getHelpTooltipElement(helpTooltip);

  return (
    <BlockBox
      className={ClassNames('block', className, background, { dark: darkMode })}
      id={id}
      notificationBox={notificationBox}
    >
      {linkWithIcon}
      {title && (
        <Title className="block_title" notificationBox={notificationBox}>
          {Array.isArray(title) ? title.map(e => e) : title}
          {helpElement}
          {newFeature && <Badge text="new" />}
          {saving && !button && <span>Saving...</span>}
          {saved && !saving && !button && <span>Saved!</span>}
          {error && !saving && !button && <span>{error}</span>}
          {success && !saving && !button && <span>{success}</span>}
        </Title>
      )}
      {description && (
        <Desc
          className="block_description"
          notificationBox={notificationBox}
          linkAndButton={Boolean(link && button)}
        >
          {Array.isArray(description) ? description.map(e => e) : description}
        </Desc>
      )}
      {children && (
        <Content className="content" buttonIsShown={!!button?.text}>
          {children}
        </Content>
      )}
      <ConditionalRender predicate={button}>
        <ConditionalRender
          predicate={link}
          fallback={button?.text && <Button {...button} loading={saving} />}
        >
          <LinkAndButton>
            {link && <LinkWithIcon {...link} />}
            <Button {...button} />
          </LinkAndButton>
        </ConditionalRender>
      </ConditionalRender>
    </BlockBox>
  );
}

const getHelpTooltipElement = (helpTooltip?: BlockProps['helpTooltip']) => {
  if (!helpTooltip?.text) return null;

  if (helpTooltip.link) {
    return (
      <a href={helpTooltip.link} target={helpTooltip.target || '_self'} className="icon_help">
        <Tooltip text={helpTooltip.text} />
      </a>
    );
  }

  if (helpTooltip.onClick) {
    return (
      <button type="button" onClick={helpTooltip?.onClick} className="icon_help">
        <Tooltip text={helpTooltip.text} />
      </button>
    );
  }

  return (
    <span className="icon_help">
      <Tooltip text={helpTooltip.text} />
    </span>
  );
};

export default Block;

const LinkAndButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  height: 45px;
  right: 30px;
  top: 30px;

  ${({ theme: { media } }) => media.mobile`
    top: unset;
    right: unset;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;

    .button, .link_with_icon {
      justify-content: center;
    }
  `}

  .button, .link_with_icon {
    opacity: 0.8;
    margin: 0 !important;

    &:hover {
      opacity: 1;
    }
  }
`;

const BlockBox = styled.div<{ notificationBox?: boolean }>`
  position: relative;
  padding: 50px 60px;
  border-radius: 10px;
  background-color: white;
  margin: 20px 0;
  box-shadow: 0 0 20px -5px ${colours.dropshadow};

  &.table_block {
    padding: 30px 40px;
  }

  &.settings_block {
    padding: 40px 50px;
  }

  &.csv_upload_block {
    width: 500px;
    margin: 20px auto 40px;
  }

  &.dark {
    background-color: ${colours.darkBgColour};
    box-shadow: 0 0 20px -5px ${colours.darkDropshadow};
  }

  &.block.dark_blue {
    background-color: ${colours.fontColour};
    color: white;

    &.dark {
      background-color: ${colours.primaryColour};
      box-shadow: 0 0 20px -5px ${colours.darkDropshadow};
    }
  }

  &.blue {
    background-color: ${colours.primaryColour} !important;
    color: white;

    a,
    span:not(.badge),
    a:hover {
      color: white;
    }
  }

  &.grey {
    background-color: ${colours.lightBgColour};

    &.dark {
      background-color: ${colours.darkBgColour};
      box-shadow: 0 0 20px -5px ${colours.darkDropshadow};
    }
  }

  &:not(.dark).light_grey {
    background-color: ${colours.lightBgColour};
    box-shadow: none;
  }

  &.red {
    background-color: ${colours.errorColour} !important;
    color: white;

    a {
      color: white;
    }
  }

  &.ats_position_block .content {
    float: right;
  }

  ${props =>
    props.notificationBox &&
    css`
      padding: 30px;

      .button,
      a.right {
        float: right;
      }

      .button {
        margin-top: -30px;
      }

      a.right {
        font-weight: ${({ theme: { typography } }) => typography.bold};
        border-bottom: 2px solid;

        &:hover {
          opacity: 0.9;
          border-bottom: 3px solid;
        }
      }
    `}

  &.three_cols_item:not(.double_width) .right_label {
    @media only screen and (max-width: ${macBookScreen}) {
      display: block;
      float: none;
      margin-left: 0;
    }
  }

  @media only screen and (max-width: ${mobile}) {
    padding: 30px;

    &.table_block,
    &.settings_block {
      padding: 20px;
    }

    ${props =>
      props.notificationBox &&
      css`
        padding: 25px;

        .button {
          float: none;
          width: 100%;
        }
      `}
  }

  .block_link_with_icon {
    float: right;
  }
`;

const Content = styled.div<{ buttonIsShown?: boolean }>`
  ${props =>
    props &&
    props.buttonIsShown &&
    css`
      margin-bottom: 20px;
    `}

  .section_title {
    font-size: ${({ theme: { typography } }) => typography.smallheader};
  }
`;

const Desc = styled.div<{ notificationBox?: boolean; linkAndButton?: boolean }>`
  line-height: 1.4;
  margin: 20px 0;

  &:last-child {
    margin-bottom: 0;
  }

  .bold {
    font-weight: bold;
  }

  ${({ notificationBox }) =>
    notificationBox &&
    css`
      margin: 0;
      max-width: 70%;
      display: inline-block;

      @media only screen and (max-width: ${mobile}) {
        display: block;
        max-width: 100%;
        margin: 0 0 30px;
      }
    `}

  ${({ linkAndButton }) =>
    linkAndButton &&
    css`
      max-width: 420px;
    `}
`;

const Title = styled.h2<{ notificationBox?: boolean }>`
  font-size: 24px;
  font-weight: ${({ theme: { typography } }) => typography.bold};
  margin: 0 auto 20px;

  ${props =>
    props.notificationBox &&
    css`
      font-size: 20px;
      margin: 0 auto 10px;
    `}

  span:not(.badge, .badge span) {
    display: inline-block;
    margin-left: 15px;
    color: ${colours.primaryColour};
    font-weight: ${typography.regular};
    font-size: 18px;
  }

  .icon_gift {
    font-size: 32px;
    font-weight: bold;
    margin-right: 8px;
    vertical-align: text-top;
  }

  .right_label {
    float: right;
    padding-top: 5px;

    &.link_with_icon {
      .icon {
        vertical-align: middle;
        font-weight: ${typography.black};
      }

      span {
        margin-left: 0;
        font-weight: ${typography.bold};
      }
    }

    @media only screen and (max-width: ${mobile}) {
      display: block;
      float: none;
    }
  }

  .icon_button {
    margin-left: 0.5rem;
  }

  .icon_help {
    vertical-align: middle;
    margin-left: 10px;
    font-size: ${({ theme: { typography } }) => typography.header};
    margin-top: -2px;

    &:hover .tooltip_wrapper {
      display: block;
    }
  }

  @media only screen and (max-width: ${mobile}) {
    margin: 0 0 10px;
  }
`;
