import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { getEmbeddedURL } from 'utils';
import { mobile } from 'responsiveConfig';

import VideoItem from 'components/videoitem';

type Props = {
  videoURL?: string;
  label: string | Array<ReactNode | string>;
  description?: string;
  note?: ReactNode | string;
  attributes?: HTMLAttributes<HTMLInputElement>;
  placeholder?: string;
  youtubeOnly?: boolean;
};

type State = { videoURL: string };

export default class VideoInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      videoURL: props.videoURL || ''
    };
  }

  validate(mandatory: boolean) {
    const { videoURL } = this.state;
    const { youtubeOnly } = this.props;

    const acceptedDomains = youtubeOnly
      ? ['youtube.com']
      : ['youtube.com', 'vimeo.com', 'loom.com'];
    const videoPlatform = acceptedDomains.find(domain => videoURL.indexOf(domain) > 0);

    if (!videoPlatform && videoURL !== '' && youtubeOnly) {
      return 'Only Youtube Video URLs are allowed for your video.';
    } else if (!videoPlatform && videoURL !== '') {
      return 'Please enter either a Youtube, Vimeo or Loom URL for your video.';
    } else if (mandatory && videoURL === '') return 'Please enter a video.';
    return '';
  }

  getVideoURL(value: $TSFixMe) {
    // @ts-expect-error TS(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    const { onChange } = this.props;
    const embeddedURL = getEmbeddedURL(value);

    this.setState({ videoURL: embeddedURL });

    if (onChange) onChange();
  }

  render() {
    const { videoURL } = this.state;
    const { label, description, note, placeholder, attributes } = this.props;

    return (
      <div className="videos_block">
        <Field className="field">
          <label className="question" htmlFor="video_input">
            {Array.isArray(label) ? label.map(e => e) : label}
            {description && <span>({description})</span>}
            <span className="note">{note}</span>
          </label>
          <input
            className="video_input"
            id="video_input"
            type="text"
            {...attributes}
            placeholder={placeholder || 'Enter a Youtube or a Vimeo URL'}
            value={videoURL}
            onChange={e => this.getVideoURL(e.target.value)}
          />
          {videoURL && <VideoItem videoUrl={videoURL} showVideoPlayer />}
        </Field>
      </div>
    );
  }
}

const Field = styled.div`
  position: relative;

  .video_wrapper {
    height: 340px;
    margin: 15px 0;

    @media only screen and (max-width: ${mobile}) {
      height: 220px;
    }
  }
`;
