import { NavigationHeaders } from 'types';
import { NAV_MAP } from 'consts';

const currentPage = () => window.location.pathname;

export const isPublicNavPage = (path = currentPage()) => {
  const isPublic = Object.values(NAV_MAP).some(({ links }) =>
    links.some(({ path: linkPath }) => linkPath === path)
  );

  return isPublic;
};

export const isForCompaniesPage = (path = currentPage()) => {
  const OUTSIDE_NAVIGATION = [
    '/testimonials/companies',
    '/request-demo',
    '/trial',
    '/founder-program'
  ];
  const isForCompanies = NAV_MAP[NavigationHeaders.ForCompanies].links.some(
    ({ path: linkPath }) => {
      const regex = `^(${linkPath}|/u[ks]${linkPath})`;
      return path.match(regex) || OUTSIDE_NAVIGATION.includes(path);
    }
  );

  return isForCompanies;
};

export const isForPeoplePage = (path = currentPage()) => {
  const isForPeople = NAV_MAP[NavigationHeaders.ForPeople].links.some(
    ({ path: linkPath }) => linkPath === path
  );

  return isForPeople;
};

export const isSalaryToolPage = (path = currentPage()) => path === '/salary-tool';
export const isHomePage = (path = currentPage()) => path === '/';
export const isLoginPage = (path = currentPage()) => path === '/login';
export const isCompaniesLandingPage = (path = currentPage()) =>
  /^(\/u[ks])?\/companies$/i.test(path);
export const isRequestDemoPage = (path = currentPage()) => new RegExp(/^\/request-demo/).test(path);
export const isTrialPage = (path = currentPage()) => new RegExp(/^\/trial/).test(path);
export const isFoundersPage = (path = currentPage()) => new RegExp(/\/founder-program/).test(path);
export const isSanFranLandingPage = (path = currentPage()) =>
  new RegExp(/\/san-francisco/).test(path);
export const checkIfESSUProgramPage = (path = currentPage()) =>
  new RegExp(/\/es-su-programme/).test(path);
export const isAgenciesPage = (path = currentPage()) => new RegExp(/\/agencies/).test(path);

export const isPublicPositionPage = (path: string) => {
  return (
    new RegExp('/u/').test(path) &&
    new RegExp('/jobs/').test(path) &&
    !new RegExp('/candidate').test(path) &&
    !new RegExp('/company').test(path) &&
    !new RegExp('/admin').test(path)
  );
};

export const isPublicPage = (path = currentPage()) => {
  return new RegExp('^/(?!admin|candidate|company).*').test(path);
};
