import styled from 'styled-components';

import PageSection from 'components/pagesection';

export const HomepageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopSection = styled.section`
  z-index: 0;
  margin-top: -94px;
  padding: 140px 0 80px;
  position: sticky;
  top: 0;

  ${({ theme: { media } }) => media.mobile` 
    padding: 80px 0;
  `}

  .heading {
    font-size: 100px;
    margin: 0;
    line-height: 1;

    ${({ theme: { media } }) => media.mobile` 
      font-size: 64px;
    `}
  }

  .sub_heading {
    margin: 0;
    color: var(--text-body-secondary);

    ${({ theme: { media } }) => media.mobile` 
      margin: 0;
    `}
  }

  .button {
    margin-top: 1.5rem;
  }
`;

export const BlobBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: calc(100vh + 8rem);
  overflow: hidden;

  .top_blob {
    position: absolute;
    z-index: -1;
    top: -20px;
    right: -20px;
  }

  .bottom_blob {
    position: absolute;
    z-index: -1;
    bottom: -20px;
    left: -20px;
  }
`;

export const BlurBox = styled.div`
  background: var(--gradient-soft-transparent);
  backdrop-filter: blur(100px);
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;

  ${({ theme: { media } }) => media.mobile`
    gap: 1rem;
  `}
`;

export const OverlaySectionsWrapper = styled.div`
  z-index: 1;
  background-color: var(--background-body-primary);
  border-top-right-radius: 7.5rem;
  border-top-left-radius: 7.5rem;
  box-shadow: 0px -20px 80px 0px var(--shadow-soft);
  padding-bottom: 3rem;

  ${({ theme: { media } }) => media.tablet` 
    border-top-right-radius: 5rem;
    border-top-left-radius: 5rem;
  `}
`;

export const BottomCTASection = styled(PageSection)`
  .page_view {
    padding: 3.75rem !important;
    border-radius: 5rem;

    ${({ theme: { media } }) => media.mobile`
      border-radius: 3rem;
    `}

    .people_joining_wrapper {
      margin-top: 2rem;
    }
  }
`;
