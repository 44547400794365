import React from 'react';

import { mobile } from 'responsiveConfig';

type ResponsiveImageProps = {
  src: string;
  alt: string;
  smallSrc: string;
  media?: string;
  className?: string;
  loading?: 'lazy' | 'eager';
  width?: string;
};

// A very naive implementation of a responsive image component. Can be expanded.
const ResponsiveImage = ({
  src,
  alt,
  smallSrc,
  media = `(max-width: ${mobile})`,
  width,
  className,
  loading = 'lazy'
}: ResponsiveImageProps) => {
  return (
    <picture>
      <source media={media} srcSet={smallSrc} />
      <img src={src} alt={alt} className={className} loading={loading} width={width} />
    </picture>
  );
};

export default ResponsiveImage;
