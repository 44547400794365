import { CandidateApplicationTrackerStage as CandidateATStage } from '@cohiretech/common-types';

import { useAppDispatch, useAppSelector } from 'store/store';
import { CandidateATSItem } from 'v2/services/fetchers/candidate/ats';

import { selectCandidateATS } from './candidateATS.selectors';
import {
  fetchATSStageData,
  updateATSItem,
  UpdateATSItemParams,
  updateATSItemStage,
  UpdateATSItemStageParams
} from './candidateATS.thunks';
import { setATSItemVisibility } from './candidateATS';

export const useCandidateATS = () => {
  const dispatch = useAppDispatch();
  const candidateATS = useAppSelector(selectCandidateATS);

  const loadATSStageData = async (stage: CandidateATStage, initialLoad?: boolean) => {
    await dispatch(fetchATSStageData({ stage, initialLoad }));
  };

  const handleUpdateATSItemStage = (params: UpdateATSItemStageParams) => {
    dispatch(updateATSItemStage(params));
  };

  const handleUpdateATSItem = (params: UpdateATSItemParams) => {
    dispatch(updateATSItem(params));
  };

  const toggleATSItemVisibility = (item: CandidateATSItem, hidden: boolean) => {
    dispatch(setATSItemVisibility({ item, hidden }));
    handleUpdateATSItem({ item, update: { hidden } });
  };

  return {
    ...candidateATS,
    loadATSStageData,
    handleUpdateATSItemStage,
    toggleATSItemVisibility
  };
};
