import React, { useState, useEffect, createRef } from 'react';
import { Location } from 'history';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import {
  CompanySubscriptionPlan,
  PricingSubscriptionPlan,
  Frequency
} from '@cohiretech/common-types';

import { V3PricingOptions, PricingCountry, CurrentPlanDetails, SubscriptionPlan } from 'types';
import { companyReactivateAccount, getCompanyUpgradeOptions } from 'fetcher';
import { checkIfSubscriptionExpired, isNewPricingPlan } from 'utils/companyUtils';
import { getV3PricingOptions } from 'utils/pricingUtils';
import { FOUNDER_PROGRAM_URL_PATH } from 'consts';
import media from 'styles/media';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import { FlexContainer } from 'components/commonstyles';
import ArrowLink from 'components/arrowlink';
import Badge from 'components/badge';
import Block from 'components/block';
import Loader from 'components/loader';
import MultiStageToggle from 'components/multistagetoggle';
import PricingBottomSections from 'components/pricingbottomsections';
import SourcingCreditsPopup from 'components/sourcingcreditspopup';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import typography from 'css/base/_typography.module.scss';

import PricingPlanCard from './pricingplancard';
import FeatureComparisonTable from './featurecomparisontable';
import { getPricingType, GrowthTiers, getUpdatedGrowthTiers } from './helper';

type Props = {
  title?: string;
  pricingCountry?: PricingCountry;
  isPopup?: boolean;
  location: Location;
  darkMode: boolean;
  showVideoSection?: boolean;
  showStorySection?: boolean;
  showCharitySection?: boolean;
  currentPlanDetails?: CurrentPlanDetails;
  availablePlans?: PricingSubscriptionPlan[];
  previousPlan?: SubscriptionPlan;
};

export const FREQUENCIES = [
  { component: 'Monthly', stage: Frequency.monthly },
  { component: ['Annual', <Badge key="discount" text="25% off" />], stage: Frequency.annual }
];

export default function PricingPlansV3({
  title,
  pricingCountry,
  isPopup,
  location,
  darkMode,
  showVideoSection,
  showStorySection,
  showCharitySection,
  availablePlans,
  previousPlan,
  ...others
}: Props) {
  const featuresTableRef = createRef<HTMLDivElement>();
  const currentPlanDetails = others.currentPlanDetails as
    | CurrentPlanDetails<V3PricingOptions | CompanySubscriptionPlan.Basic>
    | undefined;
  const { plan: currentPlan, billingFrequency, hasDiscount } = currentPlanDetails || {};
  const [frequency, setFrequency] = useState(billingFrequency || Frequency.monthly);
  const [growthTiers, setGrowthTiers] = useState<GrowthTiers>();
  const [requestFounderProgramState, setRequestFounderProgramState] = useState({
    loading: false,
    requested: false,
    errorMessage: ''
  });
  const [sourcingPopupState, setSourcingPopupState] = useState({
    visible: false,
    triggerElement: ''
  });
  const isMobile = useMedia([mediaQueries.mobile]);
  const isExpiredAccount = checkIfSubscriptionExpired(currentPlan);

  const pricingType = pricingCountry || getPricingType(location?.pathname);
  const isNewUser = !currentPlan || currentPlan === CompanySubscriptionPlan.Basic;
  const shouldShowSixPositions =
    isNewUser || isExpiredAccount || new Date(currentPlanDetails?.signupDate || '') >= new Date();

  useEffect(() => {
    getGrowthTiers();
  }, [pricingType]);

  const getGrowthTiers = async () => {
    let potentialPlans = availablePlans;

    if (currentPlan && currentPlan !== CompanySubscriptionPlan.Basic && !isExpiredAccount) {
      const { upgrade } = await getCompanyUpgradeOptions();
      potentialPlans = upgrade;
    }

    setGrowthTiers(getUpdatedGrowthTiers(pricingType, potentialPlans, hasDiscount));
  };

  const scrollToAllFeatures = () => {
    featuresTableRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const requestFounderProgramReactivation = async () => {
    setRequestFounderProgramState({ ...requestFounderProgramState, loading: true });

    const { status, message } = await companyReactivateAccount(
      FOUNDER_PROGRAM_URL_PATH,
      currentPlanDetails?.mode,
      true
    );

    setRequestFounderProgramState({
      requested: status === 'success',
      errorMessage: message,
      loading: false
    });
  };

  const getFounderBlockContent = (hasRequestedReactivation: boolean) => {
    return {
      title: hasRequestedReactivation
        ? 'Verifying your company size...'
        : 'Growing an early-stage team?',
      description: hasRequestedReactivation
        ? [
            "We're checking your company is 25 people or less. Once verified, your account will be reactivated. This normally takes less than a few hours. If you have any questions, please contact us at ",
            <a key="support_link" className="link white_text" href="mailto:support@cord.co">
              support@cord.co
            </a>,
            '.'
          ]
        : 'Founders & CTOs hiring inside companies of 25 people or less can qualify for a 25% discount through our Founder Program.'
    };
  };

  const getFounderProgramDynamicButtonProps = () => {
    if (isExpiredAccount) {
      return {
        text: requestFounderProgramState.requested ? 'Request received!' : 'Request reactivation',
        action: () => requestFounderProgramReactivation(),
        loading: requestFounderProgramState.loading,
        disabled: requestFounderProgramState.requested
      };
    }

    return {
      text: 'Apply for Program',
      link: { pathname: '/founder-program', state: { pricingType } }
    };
  };

  return (
    <PricingV3 id="plans">
      <div className={ClassNames('center_alignment', { page_view: !isPopup })}>
        {title && <h1 className="heading">{title}</h1>}
        <h2 className={isPopup ? 'pop_up_sub_title' : 'subheading'}>
          Pay for the people you source. Flexible plans. No hiring fees.
        </h2>
        <FrequencyToggle
          type="pills_toggle"
          darkMode={darkMode}
          currentStage={frequency}
          stages={FREQUENCIES}
          onStageSelect={stage => setFrequency(stage)}
          hideBadge={hasDiscount}
        />
        <ConditionalRender
          predicate={growthTiers}
          fallback={<Loader className={ClassNames('medium', { dark: darkMode })} />}
        >
          <PricingPlansContainer justifyContent="center" gap="-8px">
            {Object.entries(getV3PricingOptions(isMobile)).map(([plan, pricingOption]) => (
              <PricingPlanCard
                key={`pricing_v3_${plan}`}
                darkMode={darkMode}
                pricingOption={{ plan, ...pricingOption }}
                pricingType={pricingType}
                planPeriod={frequency}
                currentPlanDetails={currentPlanDetails}
                growthTiers={growthTiers!}
                showSourcingPopup={() =>
                  setSourcingPopupState({ visible: true, triggerElement: 'pricing_v3_plan_card' })
                }
              />
            ))}
          </PricingPlansContainer>
        </ConditionalRender>
        <ConditionalRender predicate={isNewUser || isNewPricingPlan(previousPlan)}>
          <ArrowLink onClick={scrollToAllFeatures}>View all features</ArrowLink>
          <FounderBlock
            {...getFounderBlockContent(isExpiredAccount && requestFounderProgramState.requested)}
            notificationBox
            className={isMobile ? 'center_alignment' : 'left_alignment'}
            background="blue"
            button={{
              ...getFounderProgramDynamicButtonProps(),
              buttonStyle: 'stroke_white',
              className: 'eighteen_px_font'
            }}
          />
        </ConditionalRender>
        <FeatureComparisonTable
          ref={featuresTableRef}
          pricingCountry={pricingType}
          currentPlan={currentPlan}
          darkMode={darkMode}
          showSourcingPopup={() =>
            setSourcingPopupState({ visible: true, triggerElement: 'pricing_v3_features_table' })
          }
          showNewPositionCount={shouldShowSixPositions}
        />
      </div>
      <PricingBottomSections
        showVideoSection={showVideoSection}
        showStorySection={showStorySection}
        showCharitySection={showCharitySection}
        pricingCountry={pricingType}
        isNewUser={isNewUser}
        pricingVer={3}
      />
      <SourcingCreditsPopup
        {...sourcingPopupState}
        onClose={() => setSourcingPopupState({ visible: false, triggerElement: '' })}
      />
    </PricingV3>
  );
}

const PricingV3 = styled.div`
  padding-top: 30px;

  .subheading,
  .pop_up_sub_title {
    margin-bottom: 24px;
  }

  .plan_title {
    margin: 0 0 2px;
    font-size: 24px;
  }

  .badge,
  .arrow_link {
    margin-right: 0;
  }

  ${media.mobile`
    .icon_help {
      display: none;
    }
  `}
`;

// WIP - After release: Some styles should be moved to the MultiStageToggle component
const FrequencyToggle = styled(MultiStageToggle)<{ darkMode: boolean; hideBadge?: boolean }>`
  padding: 8px 10px;

  .multi_stage_toggle_item {
    padding: 8px 16px;
    font-size: ${typography.normal};

    &.active {
      cursor: auto;
    }

    &:not(.active) {
      color: rgba(228, 230, 235, 0.4);

      ${({ darkMode }) =>
        !darkMode &&
        css`
          color: rgba(49, 71, 95, 0.4);

          &:hover {
            color: rgba(49, 71, 95, 0.6);
          }
        `}
    }
  }

  .badge {
    ${({ hideBadge }) =>
      hideBadge &&
      css`
        display: none;
      `}
  }

  ${media.mobile`
    gap: 4px;
  `}
`;

const PricingPlansContainer = styled(FlexContainer)`
  margin: 56px auto 48px;

  ${media.mobile`
    flex-direction: column;
    gap: 24px;
  `}
`;

const FounderBlock = styled(Block)`
  &.block.blue {
    max-width: 885px;
    margin: 48px auto 0;

    .block_title {
      font-size: 24px;
    }

    .block_description {
      font-size: ${typography.smallheader};
    }

    ${media.mobile`
      .block_description {
        max-width: 100%;
      }

      .button {
        margin-top: 30px;
      }
    `}
  }
`;
