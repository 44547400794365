import React, { useState } from 'react';

import { ERROR_MESSAGE } from 'consts';
import { PaymentData, proceedToPayment } from 'v2/services/company/subscription';

import PaymentMethodPopup, { Props as PaymentPopupProps } from 'components/paymentmethodpopup';

type CardPaymentPopupProps = {
  plan: Pick<PaymentPopupProps, 'planInfo' | 'cycle' | 'pricingType'>;
  onClose: () => void;
} & PaymentData;

export default function CardPaymentPopup({
  plan,
  cards,
  sessionID,
  stripeRegion,
  onClose
}: CardPaymentPopupProps) {
  const [errorMessage, setErrorMessage] = useState('');

  const handlePayment = async () => {
    const { status, errorMsg } = await proceedToPayment(sessionID, stripeRegion);

    if (status === 'failure') setErrorMessage(errorMsg || ERROR_MESSAGE);
  };

  const { planInfo, cycle, pricingType } = plan;

  return (
    <PaymentMethodPopup
      planInfo={planInfo}
      cycle={cycle}
      pricingType={pricingType}
      cards={cards}
      handleClose={onClose}
      proceedToPayment={() => handlePayment()}
      errorMessage={errorMessage}
      setErrorMessage={message => setErrorMessage(message)}
    />
  );
}
