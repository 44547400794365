export const PROMPT_IDEAS = [
  'Try “Group all skills & technologies at the bottom of the text, comma separated”',
  'Try "Add two sentence summary at the top"',
  'Try "Use active voice"',
  'Try "Use more positive language"',
  'Try "Change to bullet points"',
  'Try "Summarise how this experience is valuable to a role within fintech"',
  'Try "Add two paragraphs',
  'Try "Emphasize leadership roles and responsibilities"',
  'Try "Include quantifiable achievements"',
  'Try "Use industry-specific keywords"',
  'Try "Add a description of the company and industry"',
  'Try "Rephrase for a non-technical audience"',
  'Try "Translate technical tasks to business outcomes"',
  'Try "Emphasize collaboration and teamwork"',
  'Try "Frame tasks in relation to business growth or revenue"',
  'Try "Summarize how this experience is relevant for a remote work setting"',
  'Try "Demonstrate cultural competence or experience with diversity"',
  'Try "Describe how this role enhanced your communication skills"',
  'Try "Add a one-sentence tagline at the top"'
];
