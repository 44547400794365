import React, { useState, createRef } from 'react';
import { Location } from 'history';
import styled from 'styled-components';

import { CompanySubscriptionPlan } from '@cohiretech/common-types';

import { PricingCountry, CurrentPlanDetails, SubscriptionPlan } from 'types';
import { companyReactivateAccount } from 'fetcher';
import { checkIfSubscriptionExpired, isNewPricingPlan } from 'utils/companyUtils';
import { isCompaniesLandingPage } from 'utils/url';
import { FOUNDER_PROGRAM_URL_PATH } from 'consts';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import LinkWithIcon from 'components/linkwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { DiscountBlock } from 'v2/components/ui/styles';
import FeatureComparisonTable from 'v2/components/ui/templates/Pricing/components/FeatureComparisonTable';

import typography from 'css/base/_typography.module.scss';

import { getCompareFeaturesTableHeaderContent } from './PricingMiddleSection.helpers';

type Props = {
  pricingType: PricingCountry;
  location: Location;
  currentPlanDetails?: CurrentPlanDetails;
  previousPlan?: SubscriptionPlan;
  showReactivationModal?: boolean;
};

export default function PricingMiddleSection({
  pricingType,
  location,
  currentPlanDetails,
  previousPlan,
  showReactivationModal
}: Props) {
  const featuresTableRef = createRef<HTMLDivElement>();
  const isCompaniesPage = isCompaniesLandingPage(location.pathname);
  const { plan: currentPlan, mode } = currentPlanDetails || {};
  const isFeaturesTableToggleable = !!(isCompaniesPage || showReactivationModal);

  const [showFeaturesTable, setShowFeaturesTable] = useState(!isFeaturesTableToggleable);
  const [requestFounderProgramState, setRequestFounderProgramState] = useState({
    loading: false,
    requested: false,
    errorMessage: ''
  });

  const isMobile = useMedia([mediaQueries.mobile]);
  const isExpiredAccount = checkIfSubscriptionExpired(currentPlan);

  const isNewUser = !currentPlan || currentPlan === CompanySubscriptionPlan.Basic;
  const showFounderBlock = isNewUser || isNewPricingPlan(previousPlan);

  const getViewFeaturesIconAndAction = () => {
    if (isFeaturesTableToggleable) {
      return {
        icon: `icon_simple_arrow_${showFeaturesTable ? 'up' : 'down'}`,
        action: () => setShowFeaturesTable(!showFeaturesTable)
      };
    }

    return {
      icon: 'icon_arrow_right',
      action: () => featuresTableRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
    };
  };

  const requestFounderProgramReactivation = async () => {
    setRequestFounderProgramState({ ...requestFounderProgramState, loading: true });

    const { status, message } = await companyReactivateAccount(
      FOUNDER_PROGRAM_URL_PATH,
      mode,
      true
    );

    setRequestFounderProgramState({
      requested: status === 'success',
      errorMessage: message,
      loading: false
    });
  };

  const getFounderBlockContent = (hasRequestedReactivation: boolean) => {
    if (hasRequestedReactivation) {
      return {
        title: 'Verifying your company size...',
        description: [
          "We're checking your company is 25 people or less. Once verified, your account will be reactivated. This normally takes less than a few hours. If you have any questions, please contact us at ",
          <a key="support_link" className="link white_text" href="mailto:support@cord.co">
            support@cord.co
          </a>,
          '.'
        ]
      };
    }

    return {
      title: 'Growing an early-stage team?',
      description:
        'Founders, CTOs, CEOs and COOs hiring inside companies of 25 people or less can qualify for a 25% discount through our Founder Program.'
    };
  };

  const getFounderProgramDynamicButtonProps = () => {
    if (isExpiredAccount) {
      return {
        text: requestFounderProgramState.requested ? 'Request received!' : 'Request reactivation',
        action: () => requestFounderProgramReactivation(),
        loading: requestFounderProgramState.loading,
        disabled: requestFounderProgramState.requested
      };
    }

    return {
      text: 'Apply for Program',
      link: { pathname: '/founder-program', state: { pricingType } }
    };
  };

  const compareFeaturesTableHeaderContent = getCompareFeaturesTableHeaderContent(pricingType);

  return (
    <div>
      <ConditionalRender predicate={isCompaniesPage || showFounderBlock || showReactivationModal}>
        <ViewFeatures
          {...getViewFeaturesIconAndAction()}
          iconPosition="right"
          text="View all features"
        />
      </ConditionalRender>
      <BlockTableCont reverse={isFeaturesTableToggleable}>
        <ConditionalRender predicate={showFounderBlock}>
          <DiscountBlock
            {...getFounderBlockContent(isExpiredAccount && requestFounderProgramState.requested)}
            notificationBox
            className={isMobile ? 'center_alignment' : 'left_alignment'}
            background="blue"
            button={{
              ...getFounderProgramDynamicButtonProps(),
              buttonStyle: 'stroke_white',
              className: 'eighteen_px_font'
            }}
          />
        </ConditionalRender>
        <ConditionalRender expand predicate={showFeaturesTable}>
          <FeatureComparisonTable
            ref={featuresTableRef}
            pricingCountry={pricingType}
            currentPlan={currentPlan}
            headerContent={compareFeaturesTableHeaderContent}
            textAlign="center"
          />
        </ConditionalRender>
      </BlockTableCont>
    </div>
  );
}

const ViewFeatures = styled(LinkWithIcon)`
  margin: auto;
  font-size: ${typography.smallheader};
  align-items: center;

  .icon {
    margin-left: 0.5rem;
  }
`;

const BlockTableCont = styled.div<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
`;
