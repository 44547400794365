import { AnyFunction } from 'types';
import { getSignedURL } from 'v2/services/fetchers/common/getSignedURL';
import { noop } from 'utils/fn';

export const downloadCVFromURL = async (
  url: string,
  openInNewTab = true,
  onError: AnyFunction = noop
) => {
  const response = await getSignedURL(url);

  if (response?.status === 'failure') {
    onError(response.message);
    return;
  }

  const { signedUrl } = response.data;

  if (openInNewTab) window.open(signedUrl, '_blank');
  return signedUrl;
};
