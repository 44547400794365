/**
 * Returns the value passed to it
 * @template T - The type of the value
 * @param {T} value - The value to return
 * @returns {T} - The value passed to it
 * @example
 * identity(1) // 1
 * identity('foo') // 'foo'
 **/
export const identity = <T>(value: T): T => value;
