import React from 'react';
import styled from 'styled-components';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';
import { RadioOption } from 'components/expandablefilter/ExpandableFilter.helpers';
import RadioButtons from 'components/radiobuttons';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { OptionCont } from '../MultiselectList/CheckboxOptions.styled';

function RadioGroup({ radioOptions }: { radioOptions: RadioOption }) {
  const { matchingOptions, id, selected, addSearchItem } = useExpandableFilter();

  const { attribute, title, isGroupedList } = radioOptions;
  const options = matchingOptions || radioOptions.options;

  return (
    <ConditionalRender predicate={attribute && options}>
      <div key={`${id}_${attribute}`}>
        <ConditionalRender predicate={isGroupedList && title}>
          <p className="group_title">{title}</p>
        </ConditionalRender>
        <OptionCont className="option_cont" showUpTo={6}>
          <RadioOptions
            name={attribute}
            selected={radioOptions.selected || selected}
            options={options}
            selectObject
            onSelect={item =>
              addSearchItem!(attribute, (item as $TSFixMe).label, (item as $TSFixMe).value)
            }
          />
        </OptionCont>
      </div>
    </ConditionalRender>
  );
}

export default function RadioList() {
  const { radioOptions } = useExpandableFilter();

  return (
    <>
      {radioOptions &&
        (Array.isArray(radioOptions) ? (
          radioOptions.map(option => (
            <RadioGroup key={`${option.attribute}_radio_group`} radioOptions={option} />
          ))
        ) : (
          <RadioGroup radioOptions={radioOptions} />
        ))}
    </>
  );
}

const RadioOptions = styled(RadioButtons)`
  &.radio_button {
    margin: 0.5rem;

    input {
      height: 0;
    }
  }
`;
