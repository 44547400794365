import { History } from 'history';

import { CandidateProfile } from 'types';
import { getQueryStringObject, getReferrerTrackingDetails, isEmpty } from 'utils';
import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { startApplication } from 'fetcher';

import { arraysIntersect } from './array';

const domain = process.env.REACT_APP_CORD_MAIN_DOMAIN;

export const getProfileShareURL = (pathname: string, referrer: string): string => {
  const path = pathname.match(/\/u\/.*/);

  return `${domain}/get-access${path}?utm_source=profile_share_invite&utm_referrer=${referrer}`;
};

export const checkIfProfileShareURL = (source: string, referrer: string): boolean => {
  return referrer ? source === 'profile_share_invite' : false;
};

export const checkIfJobAdvertistingSource = (search: string) => {
  const { referrer } = getReferrerTrackingDetails();
  const { utm_source } = (getQueryStringObject(search) as { [key: string]: string }) || {};
  const jobAdvertisingSources = ['recruitics', 'google_jobs_apply', 'linkedin_position', 'cwjobs'];

  return jobAdvertisingSources.includes(referrer) || jobAdvertisingSources.includes(utm_source);
};

export const isSoftwareEngineer = (candidateJobTitles: CandidateProfile['jobTitles'] = []) => {
  const softwareEngineerJobTitles = [
    'Back End',
    'Front End',
    'Full Stack',
    'BI Developer',
    'CRM Developer',
    'CTO',
    'Embedded Developer',
    'Engineering Manager',
    'Games Developer',
    'Graphics Developer',
    'Head of Engineering',
    'Integration Developer',
    'Mobile Developer',
    'Quantitative Developer',
    'Solutions Engineer',
    'Support Engineer',
    'Technical Architect',
    'Technical Director',
    'VP of Engineering',
    'Automation Engineer',
    'CIO',
    'Cloud Engineer',
    'CSO',
    'DevOps Engineer',
    'Head of Infrastructure',
    'Network Engineer',
    'Security',
    'Security',
    'Security Engineer',
    'Site Reliability Engineer',
    'System Administrator',
    'System Engineer',
    'System Engineer'
  ];

  return arraysIntersect(candidateJobTitles, softwareEngineerJobTitles);
};

export type ApplyActionDetails = {
  listingID: number;
  companyID: number;
  companyAccountID: number;
  companyName?: string;
  positionName?: string;
  personsName?: string;
};

export const storeApplicationAction = (actionDetails: ApplyActionDetails) => {
  const applicationDetails = {
    action: 'apply',
    actionDetails: {
      ...actionDetails
    }
  };
  const queuedActions =
    getLocalStorageItem<{ action: string; actionDetails: ApplyActionDetails }[]>('queuedActions') ||
    [];

  // replace previous application
  const newQueuedActions = queuedActions.filter(({ action }) => action !== 'apply');
  newQueuedActions.push(applicationDetails);

  setLocalStorageItem('queuedActions', newQueuedActions);
};

export const getApplicationQueueDetails = (): ApplyActionDetails | null => {
  const queuedActions =
    getLocalStorageItem<{ action: string; actionDetails: ApplyActionDetails }[]>('queuedActions') ||
    [];

  return queuedActions.find(({ action }) => action === 'apply')?.actionDetails || null;
};

export const checkIfHomepageCompleted = (candidateProfile: CandidateProfile) => {
  const homepageMinimumCriteriaComplete =
    !isEmpty(candidateProfile.primarySkills) &&
    !isEmpty(candidateProfile.jobTitles) &&
    !isEmpty(candidateProfile.seniorities);
  return (
    candidateProfile?.homepageSetupComplete ||
    (candidateProfile?.accepted && homepageMinimumCriteriaComplete)
  );
};

export const openChat = async (
  listingID: number,
  companyID: number,
  companyAccountID: number,
  history: History
) => {
  const applyDetails = {
    listingID,
    companyID,
    companyAccountID
  };

  const uniqueIdentifier = getLocalStorageItem('uniqueIdentifier') || '';
  const jobSearchPosition = getLocalStorageItem('jobSearchPosition') || 0;

  const { status } = await startApplication(applyDetails, uniqueIdentifier, jobSearchPosition);

  if (status === 'success') {
    history.push({
      search: `?chat=${applyDetails.listingID},${applyDetails.companyID}&status=open`
    });
  }
};
