import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Frequency, OperatingCountry } from '@cohiretech/common-types';

import { StandardPlan, V5PricingOptions } from 'types';
import { getDisplayPrice } from 'v2/services/tools/pricing';

import { ListItem } from 'components/dropdown';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { GradientText, LightPrimaryDropdown } from 'v2/components/ui/styles';

import { StandardTiers } from '../../PricingPlansSection.helpers';

type PricingV5PlansControlProps = {
  show: boolean;
  standardTiers?: StandardTiers[Frequency];
  selectedPlan: StandardPlan;
  handleSelect: (plan: StandardPlan) => void;
};

export default function PricingV5PlansControl({
  show,
  standardTiers,
  selectedPlan,
  handleSelect
}: PricingV5PlansControlProps) {
  const pricingHeadcountList = useMemo(
    () => (standardTiers ? getPricingHeadcountList(standardTiers) : []),
    [standardTiers]
  );

  return (
    <ConditionalRender predicate={show}>
      <PricingHeadcountDropdown
        id="pricing_headcount"
        selected={selectedPlan}
        selectMessage="Select company size"
        list={pricingHeadcountList}
        onSelect={item => handleSelect(item.value as StandardPlan)}
      />
    </ConditionalRender>
  );
}

const PricingHeadcountDropdown = styled(LightPrimaryDropdown).attrs(props => ({
  ...props,
  gradientTextColour: 'cordGradient'
}))`
  width: 300px;

  #selected_label span:last-child {
    display: none;
  }

  .item_text {
    justify-content: space-between;
  }

  .item:not(.selected) .gradient_text_wrapper_with_opacity {
    opacity: 0.6;

    span {
      ${GradientText}
    }
  }

  .item .gradient_text_wrapper_with_opacity {
    span {
      padding-left: var(--spacing-02);
    }
  }

  ${({ theme: { media } }) => media.mobile`
    width: 100%;
  `}
`;

type Headcount = '1-25' | '26-100' | '101-500' | '500+';

const V5_PLAN_TIER_MAP: { [key in StandardPlan<5>]: Headcount } = {
  [V5PricingOptions.V5Standard1]: '1-25',
  [V5PricingOptions.V5Standard2]: '26-100',
  [V5PricingOptions.V5Standard3]: '101-500',
  [V5PricingOptions.V5Standard4]: '500+'
};

const getPricingHeadcountList = (standardTiers: StandardTiers[Frequency]): ListItem[] =>
  Object.entries(V5_PLAN_TIER_MAP).map(([plan, headcount]) => {
    const tier = standardTiers[plan as StandardPlan];
    const price = tier.discountedPrice || tier.price;

    return {
      label: [
        <span key="headcount">{headcount} employees</span>,
        <span key="price_wrapper" className="gradient_text_wrapper_with_opacity">
          <span>{price ? getDisplayPrice(OperatingCountry.UK, price) : 'Contact us'}</span>
        </span>
      ],
      value: plan
    };
  });
