import React, { useState } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClassNames from 'classnames';

import colours from 'css/base/_colours.module.scss';

type Props = {
  className?: string;
  text: string;
  darkMode: boolean;
  id: string;
  onTrackAction?: $TSFixMe;
};

type StyledWrapper = {
  className: string;
  dark: boolean;
};

function CopyText({ className, text, darkMode, id, onTrackAction }: Props) {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);

    setTimeout(() => setCopied(false), 1000);

    if (onTrackAction) onTrackAction();
  };

  return (
    <CopyTextWrapper
      className={ClassNames(className, 'copy_text_wrapper')}
      dark={darkMode}
      text={text}
      onCopy={onCopy}
    >
      <span id={id}>
        {copied ? 'Copied!' : text}
        <span className="icon_copy" />
      </span>
    </CopyTextWrapper>
  );
}

export default CopyText;

const CleanCopyToClipboard = ({ dark, ...props }: any) => <CopyToClipboard {...props} />;

const CopyTextWrapper = styled(CleanCopyToClipboard)<StyledWrapper>`
  display: block;
  text-align: left;
  padding: 15px 45px 15px 15px;
  line-height: 22px;
  border-radius: 10px;
  background-color: ${props => (props.dark ? colours.darkBgColour : 'white')};
  border: 2px solid;
  cursor: copy;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: ${({ theme: { typography } }) => typography.regular};
  position: relative;

  .icon_copy {
    cursor: pointer;
    position: absolute;
    right: 15px;

    @media only screen and (max-width: ${colours.tabletLandscape}) {
      display: none;
    }
  }
`;
