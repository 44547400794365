import React, { ReactNode } from 'react';

import ConditionalRenderPure from './ConditionalRender';
import ConditionalHide from './ConditionalHide';

type Props = {
  predicate: any;
  children: any;
  fallback?: ReactNode;
  hide?: boolean;
  fade?: boolean;
  expand?: boolean;
};

const ConditionalRender = (props: Props) => {
  const { hide, fade, expand } = props;
  const unmount = !(hide || fade || expand);

  return unmount ? <ConditionalRenderPure {...props} /> : <ConditionalHide {...props} />;
};

export default ConditionalRender;
