import React from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import './style.scss';

export type StepBubblesProps = {
  steps: any[];
  currentStep: number;
  onStepChange: (i: number) => void;
  color?: 'blue' | 'white' | 'dark_blue' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  align?: 'center' | 'left' | 'right';
};

export default function StepBubbles({
  steps,
  currentStep,
  onStepChange,
  color = 'blue',
  size = 'medium',
  align = 'left'
}: StepBubblesProps) {
  return (
    <div className={ClassNames('steps', align)}>
      {steps?.map((s, i) => (
        <StepButton
          key={i}
          type="button"
          title={`step_${i + 1}`}
          tabIndex={0}
          onClick={s.onNextDisabled ? () => {} : () => onStepChange(i)}
          className={ClassNames('step', color, size, {
            active: currentStep === i,
            disabled: s.onNextDisabled
          })}
          color={color}
        />
      ))}
    </div>
  );
}

const StepButton = styled.button<Pick<StepBubblesProps, 'color'>>`
  ${({ color }) => {
    switch (color) {
      case 'secondary':
        return css`
          background: var(--gradient-secondary-base);

          &:not(.active) {
            opacity: 0.4;
          }
        `;
      default:
        break;
    }
  }}
`;
