import { useCallback } from 'react';

import {
  selectCompanyName,
  selectPositionMembers,
  selectPositionName,
  selectPositionSearchHidden,
  selectRemoteLocation,
  useAppDispatch,
  useAppSelector
} from 'store';
import { isEmpty } from 'utils';
import { is, throttle } from 'utils/fn';
import { UserScope } from 'v2/services/fetchers/company/positionview';

import { resetTabListings, setActivePositionsTab, setPositionsFilterParams } from './positions';
import {
  selectActivityForListing,
  selectCandidateCountForStreamsInListing,
  selectHasRetrievedCached,
  selectIsListingsReloading,
  selectListingIDs,
  selectListings,
  selectNbrOfFreshListings,
  selectPerListingLoadState,
  selectPositionsHasNextPage,
  selectStreamsForListing,
  selectTypeOfLoadState,
  selectLoadQueue,
  selectConversationsForListing,
  selectMaxConversations
} from './positions.selectors';
import { loadPositionsViewData, loadPositionsViewCandidateCount } from './positions.thunks';
import { PositionsFilterParams } from './positions.types';

export const usePositionsView = () => {
  const dispatch = useAppDispatch();
  const { activeTab } = useActivePositionsTab();
  const listings = useAppSelector(selectListings(activeTab));
  const listingIDs = useAppSelector(selectListingIDs(activeTab));
  const hasFetched = useAppSelector(selectTypeOfLoadState(activeTab)('hasFetched'));
  const loading = useAppSelector(selectTypeOfLoadState(activeTab)('loading'));
  const hasRetrievedCached = useAppSelector(selectHasRetrievedCached);
  const isReloading = useAppSelector(selectIsListingsReloading);
  const hasNextPage = useAppSelector(selectPositionsHasNextPage(activeTab)('listings'));
  const nbrOfFreshListings = useAppSelector(selectNbrOfFreshListings(activeTab));
  const nbrOfListings = listingIDs.length;
  const activityLoadQueue = useAppSelector(selectLoadQueue(activeTab, 'activity')) as number[];
  const streamsLoadQueue = useAppSelector(selectLoadQueue(activeTab, 'streams')) as number[];
  const conversationsLoadQueue = useAppSelector(
    selectLoadQueue(activeTab, 'conversations')
  ) as number[];
  const listingsLoadQueue = useAppSelector(selectLoadQueue(activeTab, 'listings')) as UserScope[];
  const resetTab = (userScope: UserScope) => dispatch(resetTabListings(userScope));

  const loadListings = useCallback(
    throttle(async () => {
      const isUsingCached = nbrOfListings > nbrOfFreshListings;
      const page = Math.floor((isUsingCached ? nbrOfListings : nbrOfFreshListings) / 10);
      await dispatch(loadPositionsViewData('listings')({ userScope: activeTab, page }));
    }, 1000),
    [activeTab, listingIDs.join(','), listingsLoadQueue.join(',')]
  );

  const loadActivity = useCallback(
    throttle(async (listingIDs: number[]) => {
      await dispatch(
        loadPositionsViewData('activity')({
          userScope: activeTab,
          listingIDs
        })
      );
    }, 1000),
    [activityLoadQueue.join(',')]
  );

  const loadStreams = useCallback(
    throttle(async (listingIDs: number[]) => {
      await dispatch(loadPositionsViewData('streams')({ userScope: activeTab, listingIDs }));
    }, 1000),
    [streamsLoadQueue.join(',')]
  );

  const loadConversations = useCallback(
    throttle(async (listingIDs: number[]) => {
      await dispatch(loadPositionsViewData('conversations')({ userScope: activeTab, listingIDs }));
    }, 1000),
    [streamsLoadQueue.join(',')]
  );

  return {
    activeTab,
    activityLoadQueue,
    hasFetched,
    hasRetrievedCached,
    hasNextPage,
    isReloading,
    listings,
    listingIDs,
    listingsLoadQueue,
    loading,
    streamsLoadQueue,
    conversationsLoadQueue,
    resetTab,
    loadListings,
    loadActivity,
    loadStreams,
    loadConversations
  };
};

export const useActivePositionsTab = () => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(state => state.positions.activeTab);
  const hasRetrievedCached = useAppSelector(selectHasRetrievedCached);
  const setActiveTab = (tab: UserScope) => dispatch(setActivePositionsTab(tab));

  return { activeTab, hasRetrievedCached, setActiveTab };
};

export const usePositionViewActivity = (listingID: number) => {
  const dispatch = useAppDispatch();
  const { activeTab } = useActivePositionsTab();
  const activity = useAppSelector(selectActivityForListing(activeTab, listingID));
  const conversations = useAppSelector(selectConversationsForListing(activeTab, listingID));
  const maxConversationsOnActiveTab = useAppSelector(selectMaxConversations(activeTab));
  const streams = useAppSelector(selectStreamsForListing(activeTab, listingID));
  const feedIDs = streams.map(s => s.id);
  const associatedFeedIDs = streams.filter(is('associated')).map(s => s.id);
  const hasFetched = useAppSelector(selectTypeOfLoadState(activeTab)('hasFetched'));
  const stateLoading = useAppSelector(selectTypeOfLoadState(activeTab)('loading'));
  const loadingListing = useAppSelector(selectPerListingLoadState(listingID)(activeTab));
  const members = useAppSelector(selectPositionMembers(listingID));
  const remoteLocation = useAppSelector(selectRemoteLocation(listingID));
  const companyName = useAppSelector(selectCompanyName);
  const searchHidden = useAppSelector(selectPositionSearchHidden(listingID));
  const name = useAppSelector(selectPositionName(listingID));
  const position = { name, searchHidden, companyName, remoteLocation, members };

  const candidatesInStreamCount = useAppSelector(
    selectCandidateCountForStreamsInListing(activeTab, listingID, feedIDs)
  );

  // Todo: refactor into a selector
  const totalCounts = feedIDs.reduce(
    (acc, feedID) => {
      const count = acc.count + (candidatesInStreamCount?.[feedID]?.candidateCount || 0);
      const newCount = acc.new + (candidatesInStreamCount?.[feedID]?.newCandidateCount || 0);
      return { count, new: newCount };
    },
    { count: 0, new: 0 }
  );

  const loadCandidatesInStreamCount = async () => {
    if (!associatedFeedIDs.length) return;
    await dispatch(
      loadPositionsViewCandidateCount({
        userScope: activeTab,
        listingID,
        feedIDs: associatedFeedIDs
      })
    );
  };

  const loading = {
    activity: {
      fresh: isEmpty(activity) && stateLoading.activity,
      cached: !isEmpty(activity) && stateLoading.activity
    },
    conversations: {
      fresh: isEmpty(conversations) && stateLoading.conversations,
      cached: !isEmpty(conversations) && stateLoading.conversations
    },
    candidateCount: {
      fresh: !candidatesInStreamCount && loadingListing,
      cached: !!(candidatesInStreamCount && loadingListing)
    }
  };

  return {
    activeTab,
    activity,
    conversations,
    maxConversationsOnActiveTab,
    streams,
    hasFetched,
    loading,
    position,
    candidatesInStreamCount,
    totalCounts,
    loadCandidatesInStreamCount
  };
};

export const usePositionViewFilterParams = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(state => state.positions.params);

  const getFilterParams = () => params;

  const setFilterParams = (params: Partial<PositionsFilterParams>) => {
    dispatch(setPositionsFilterParams(params));
  };

  return { getFilterParams, setFilterParams };
};
