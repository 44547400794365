import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { isFailure } from 'v2/services/fetchers/apiTools';
import { CandidateATSItem } from 'v2/services/fetchers/candidate/ats';

import { fetchATSStageData, updateATSItemStage } from './candidateATS.thunks';
import {
  INITIAL_APPLICATION_BOARD_STAGE_DATA,
  INITIAL_CANDIDATE_ATS_STATE
} from './candidateATS.helpers';

const candidateATSSlice = createSlice({
  name: 'candidateATS',
  initialState: INITIAL_CANDIDATE_ATS_STATE,
  reducers: {
    setATSItemVisibility: (
      state,
      { payload }: PayloadAction<{ item: CandidateATSItem; hidden: boolean }>
    ) => {
      const { item, hidden } = payload;
      const stageData = state.stagesState[item.stage];
      const index = stageData.items.findIndex(({ id }) => id === item.id);

      // Update the item
      stageData.items[index].hidden = hidden;

      if (hidden) {
        stageData.items.splice(index, 1);
        stageData.resultsCount -= 1;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchATSStageData.pending, (state, { meta }) => {
      const { stage, initialLoad } = meta.arg;

      if (initialLoad) state.stagesState[stage] = { ...INITIAL_APPLICATION_BOARD_STAGE_DATA };

      state.stagesState[stage].loading = true;
    });
    builder.addCase(fetchATSStageData.fulfilled, (state, { payload, meta }) => {
      const { stage } = meta.arg;
      const { items: prevItems, nextPage, reload } = state.stagesState[stage];

      if (isFailure(payload)) return;

      const { values, resultsCount } = payload.data;
      const items = reload ? values : prevItems.concat(values);
      state.stagesState[stage] = {
        items,
        resultsCount,
        loading: false,
        nextPage: nextPage + 1,
        endPage: items.length >= resultsCount,
        reload: false
      };
    });
    builder.addCase(updateATSItemStage.pending, (state, { meta }) => {
      const { item, stage: nextStage } = meta.arg;
      const { [item.stage]: currentStageData, [nextStage]: nextStageData } = state.stagesState;

      const currentStageItems = currentStageData.items;
      const index = currentStageItems.findIndex(({ id }) => id === item.id);

      // Update the item
      currentStageItems[index].stage = nextStage;
      currentStageItems[index].lastUpdatedStageDate = new Date().toISOString();

      nextStageData.items.unshift(currentStageItems[index]);
      nextStageData.resultsCount += 1;
      nextStageData.reload = true;

      // Remove the item from the items list of the current stage
      currentStageItems.splice(index, 1);
      currentStageData.resultsCount -= 1;
      currentStageData.reload = true;
    });
  }
});

export default candidateATSSlice.reducer;
export const { setATSItemVisibility } = candidateATSSlice.actions;
