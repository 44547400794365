import { CandidateProfile } from 'types';
import { SEARCH_CATEGORY_INFO, SearchCategory, getFilterAttributeByCategory } from 'store';

import { SearchOptions } from 'v2/components/ui/molecules/SearchOptionList';

export const SEARCH_CATEGORIES: SearchOptions<SearchCategory> = Object.entries(
  SEARCH_CATEGORY_INFO
).map(([value, { icon, label }]) => ({ value: value as SearchCategory, icon, label }));

const CATEGORY_TO_PROFILE_KEY_MAP: {
  [key in SearchCategory]?: Extract<
    keyof CandidateProfile,
    'jobTitles' | 'skillPreferences' | 'companyIndustries'
  >;
} = {
  jobTitles: 'jobTitles',
  skills: 'skillPreferences',
  industries: 'companyIndustries'
};

export const getRelavantSearchTerms = (profile: CandidateProfile | null): SearchOptions => {
  const searchOptionsList: SearchOptions[] = Object.entries(CATEGORY_TO_PROFILE_KEY_MAP).map(
    ([key, profileKey]) => {
      const category = key as SearchCategory;
      const values = profile?.[profileKey] || [];
      const { icon, label } = SEARCH_CATEGORY_INFO[category];

      return values?.map(value => ({
        value,
        description: label,
        icon,
        label: value,
        attribute: getFilterAttributeByCategory(category)
      }));
    }
  );

  return searchOptionsList.flat();
};
