import React, { useState, ReactNode } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import ArrowLink, { Props as ArrowLinkProps } from 'components/arrowlink';
import Badge from 'components/badge';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type AccordionProps = {
  title: string;
  icon?: string;
  badge?: string;
  description: string | ReactNode | Array<string | ReactNode>;
  link?: ArrowLinkProps & { text?: string };
  id: string;
  hoistedIsItemExpanded?: boolean;
  className?: string;
  darkMode?: boolean;
  onExpand?: (isItemExpanded?: boolean) => void;
};

export default function Accordion({
  title,
  icon,
  badge,
  description,
  link,
  id,
  hoistedIsItemExpanded,
  className,
  darkMode,
  onExpand
}: AccordionProps) {
  const componentLevelState = hoistedIsItemExpanded === undefined;
  const [isItemExpanded, setIsItemExpanded] = useState(
    componentLevelState ? false : hoistedIsItemExpanded
  );

  const handleExpand = () => {
    onExpand?.(isItemExpanded);
    setIsItemExpanded(!isItemExpanded);
  };

  const isAccordionExpanded = componentLevelState ? isItemExpanded : hoistedIsItemExpanded;

  return (
    <Details
      id={id}
      className={ClassNames(className, 'accordion')}
      open={hoistedIsItemExpanded}
      dark={darkMode}
    >
      <SummaryWrapper
        aria-controls={`${id}_desc`}
        aria-expanded={isAccordionExpanded ? 'true' : 'false'}
        onClick={handleExpand}
      >
        <SummaryDetails>
          <h3>
            {icon && <span className={icon} />} {title}{' '}
            {badge && <Badge text={badge} type="table_tag_active" />}
          </h3>
        </SummaryDetails>
        <span className="arrow_icon icon_simple_arrow_up" />
        <span className="arrow_icon icon_simple_arrow_down" />
      </SummaryWrapper>
      <DescriptionWrapper id={`${id}_desc`} hidden={!isAccordionExpanded} icon={icon}>
        {description}
        {link && (
          <p>
            <ArrowLink to={link.to} absoluteURL={link.absoluteURL} onClick={link.onClick}>
              {link.text}
            </ArrowLink>
          </p>
        )}
      </DescriptionWrapper>
    </Details>
  );
}

export const DetailsStyles = css`
  position: relative;
  margin: 5px 0;

  .arrow_icon {
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .icon_simple_arrow_up {
    display: none;
  }

  &[open] {
    .icon_simple_arrow_up {
      display: block;
    }

    .icon_simple_arrow_down {
      display: none;
    }
  }
`;

const Details = styled.details<{ dark?: boolean }>`
  ${DetailsStyles}
  margin: 0;
  padding: 5px 0;
  border-bottom: 1px solid
    ${({ dark }) => (dark ? 'rgba(49, 71, 95, 0.4)' : colours.semiTransparentFontColour)};
  text-align: left;

  &:first-child {
    border-top: 1px solid
      ${({ dark }) => (dark ? 'rgba(49, 71, 95, 0.4)' : colours.semiTransparentFontColour)};
  }
`;

export const SummaryWrapper = styled.summary`
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  border-radius: 5px;
  cursor: pointer;

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 10px 0 ${colours.primaryColourShadow};
  }

  &::-webkit-details-marker {
    display: none;
  }
`;

const SummaryDetails = styled.div`
  [class^='icon_'] {
    margin-right: 5px;
    vertical-align: bottom;
  }

  h3 {
    font-weight: ${typography.semibold};
    margin: 0;
  }

  .badge {
    padding: 2px 5px;
  }
`;

export const DescriptionWrapper = styled.div<{ icon?: string }>`
  ${({ icon }) => icon && 'padding-left: 30px'};
  margin-bottom: 10px;

  .arrow_link {
    font-size: 16px;
  }
`;
