import React from 'react';
import ClassNames from 'classnames';
import { NavLink as Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { generatePersonsURL, getRandomItemFromArray } from 'utils';
import { useDarkMode } from 'hooks/useDarkMode';
import { setLocalStorageItem } from 'cookieManager';

import ActivityInfo, { InfoWrapper } from 'components/activityinfo';
import ActiveBubble from 'components/activebubble';

import Avatar from 'v2/components/ui/atoms/Avatar';

export default function PeopleItem({
  id,
  name,
  companyLogo,
  jobTitle,
  companyName,
  photoURL,
  lastActive,
  responseRate,
  hiringForListings,
  location,
  columns,
  style,
  loading
}: $TSFixMe) {
  const darkMode = useDarkMode();
  if (loading) {
    return (
      <div
        className={ClassNames('vertical_card_item', 'people_item', {
          five_cols_item: columns === 5,
          four_cols_item: columns === 4,
          three_cols_item: columns === 3,
          two_cols_item: columns === 2,
          dark: darkMode
        })}
      >
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; widt... Remove this comment to see the full error message */}
        <div className="loading_picture" width="200" height="200">
          <Skeleton circle width={200} height={200} duration={2} />
        </div>
        <div className="card_details">
          <h3 className="card_title loading_title">
            <Skeleton height={25} duration={2} />
          </h3>
          <div className="card_subtitle">
            <Skeleton height={15} duration={2} count={2} />
          </div>
          <InfoWrapper className="activity_info">
            <Skeleton width={150} height={15} duration={2} />
          </InfoWrapper>
        </div>
      </div>
    );
  } else {
    const personData = { companyName, personsID: id, personsName: name };

    return (
      <Link
        key={id}
        style={style}
        to={{ pathname: generatePersonsURL(location, personData), search: location.search }}
        className={ClassNames('vertical_card_item', 'people_item', {
          five_cols_item: columns === 5,
          four_cols_item: columns === 4,
          three_cols_item: columns === 3,
          two_cols_item: columns === 2,
          dark: darkMode
        })}
        onClick={() => setLocalStorageItem('profileHistoryLength', 1)}
      >
        <Avatar photoURL={photoURL} width="75%" mobileWidth="75%" firstname={name}>
          <Avatar className="company_logo" width={60} photoURL={companyLogo} />
          <ActiveBubble large lastActive={lastActive} />
        </Avatar>
        <div className="card_details">
          <h3 className="card_title">
            {name} at {companyName}
          </h3>
          <h4 className="card_subtitle">{jobTitle}</h4>
          <div className="card_info">Hiring a {getRandomItemFromArray(hiringForListings)}</div>
          <ActivityInfo
            lastActive={lastActive}
            responseRate={responseRate}
            noResponseRateFallbackText="Recently joined!"
          />
        </div>
      </Link>
    );
  }
}
