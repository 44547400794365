/* eslint-disable */
import { SubsetOf } from 'types';

export const filterKeys =
  <K extends PropertyKey>(keys: K[]) =>
  <T extends Record<K, unknown>>(obj: T) => {
    const newObj = {} as SubsetOf<T>;
    keys.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key] as T[K];
      }
    });
    return newObj;
  };

export const excludeKeys =
  <K extends PropertyKey>(keys: K[]) =>
  <T extends Partial<Record<K, unknown>>>(obj: T): Omit<T, K> => {
    const newObj = { ...obj };
    keys.forEach(key => {
      if (newObj.hasOwnProperty(key)) {
        delete newObj[key];
      }
    });
    return newObj;
  };
