import { Location } from 'history';

import {
  CandidateProfile,
  CompaniesHubLinkProperties,
  WebinarLinkProperties,
  CompanyProfile,
  EventName,
  SalaryToolFilter
} from 'types';
import { COMPANIES_HUB_LINK } from 'consts';
import { getReferrerTrackingDetails, removeReferrerTrackingDetails } from 'utils';
import { isPublicPositionPage } from 'utils/url';
import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { checkIfJobAdvertistingSource } from 'utils/candidateUtils';

import { XAxisOption } from 'views/salarytool/utils';

const getCommonOptions = () => {
  const user = analytics.user?.() || {};
  const group = analytics.group?.() || {};

  const traits = user.traits?.();
  const groupId = group.id?.();
  const groupTraits = group.traits?.();

  return {
    context: {
      traits,
      groupId,
      groupTraits
    }
  };
};

export const segmentIdentify = (
  userId: string,
  traits = {},
  options = getCommonOptions(),
  callback?: () => void
) => {
  const isGodmode = getLocalStorageItem('isGodmode');
  if (isGodmode) return;

  analytics.identify(userId, traits, options, callback);
};

export const segmentGroup = (
  groupId: string,
  traits = {},
  options = getCommonOptions(),
  callback?: () => void
) => {
  const isGodmode = getLocalStorageItem('isGodmode');
  if (isGodmode) return;

  analytics.group(groupId, traits, options, callback);
};

export const segmentTrack = (
  event: EventName,
  properties: { locationInProduct?: string } & Record<string, any> = {},
  options?: Record<string, any>,
  callback?: () => void
) => {
  const isGodmode = getLocalStorageItem('isGodmode');
  if (isGodmode) return;

  const commonContext = getCommonOptions().context;

  const mergedContext = {
    ...commonContext,
    ...options?.context
  };

  analytics.track(event, properties, { context: mergedContext }, callback);
};

export const segmentPage = (
  properties = {},
  options = getCommonOptions(),
  callback?: () => void
) => {
  const isGodmode = getLocalStorageItem('isGodmode');
  if (isGodmode) return;

  analytics.page(properties, options, callback);
};

export const identifyCandidate = (profile: CandidateProfile) => {
  const { id, firstName, lastName } = profile;

  analytics.identify(`candidate-${id}`, {
    name: `${firstName} ${lastName}`,
    type: 'candidate'
  });
};

export const identifyCompanyAccount = (
  profile: CompanyProfile | Pick<CompanyProfile, 'companyID' | 'companyName' | 'companyUser'>
) => {
  const {
    companyID,
    companyName,
    companyUser: { memberName, accountID }
  } = profile;

  segmentGroup(`company-${companyID}`, {
    name: companyName
  });

  segmentIdentify(`company-account-${accountID}`, {
    name: memberName,
    type: 'company_account'
  });
};

export function trackSignupClicks(
  label: string,
  properties?: Record<string, any>,
  options?: Record<string, any>
) {
  segmentTrack(
    'click',
    {
      category: 'Candidate Signup',
      label,
      ...properties
    },
    options
  );
}

export function trackCompanyOnboardProgress(label: string) {
  segmentTrack('completed', {
    category: 'Company Onboard',
    label
  });
}

export const trackPageView = (location: Location) => {
  const { pathname, search, hash } = location;

  const path = pathname + search + hash;

  const candidateJustSignedUp = new RegExp(/\/candidate\/discover\?c=/).test(path);
  const isPublicPositionPageFromPaidAd =
    isPublicPositionPage(path) && checkIfJobAdvertistingSource(search);
  const isStartOfSignup = ['/signup', '/sign-up', '/salary-tool-signup'].includes(pathname);
  const isRequestDemoPage = pathname.includes('/request-demo');

  const sendReferrerAndReferrerDetails =
    isStartOfSignup || candidateJustSignedUp || isPublicPositionPageFromPaidAd || isRequestDemoPage;

  if (sendReferrerAndReferrerDetails) {
    const { referrer, referrerDetails } = getReferrerTrackingDetails();
    segmentPage({ path, referrer, referrerDetails });

    if (candidateJustSignedUp) removeReferrerTrackingDetails();
    return;
  }

  segmentPage({ path });
};

export const trackBookDemoEvents = (
  label: string,
  action: 'click' | 'book' = 'click',
  properties?: Record<string, any>
) => {
  segmentTrack(action, {
    category: 'Book a Demo',
    label,
    ...properties
  });
};

export const trackEvent = (
  action: EventName,
  label: string,
  category: string,
  properties?: Record<string, any>
) => {
  segmentTrack(action, {
    label,
    category,
    ...properties
  });
};

export const trackCreateAccountEvents = (
  label: string,
  action:
    | 'click_to_buy'
    | 'click_to_activate'
    | 'start_payment'
    | 'complete_payment' = 'click_to_buy'
) => {
  segmentTrack(action, {
    category: 'Company Account Creation',
    label
  });
};

export const trackCompaniesHubLinkClicks = (linkProperties: CompaniesHubLinkProperties) => {
  const urlPathParts = linkProperties?.url
    ? linkProperties.url.replace(COMPANIES_HUB_LINK, '').split('/')
    : [];

  // Example for: https://companies.cord.co/expert-guides/nzBaUwfPo49PPARWjh2SGt/reducing-your-time-to-message/5yehVuwzby2zFbTP4MyiDz
  // category = 'expert-guides', label = 'reducing-your-time-to-message'

  const category = urlPathParts?.[1] || '';
  const label = urlPathParts?.[3] || '';

  const properties = {
    category,
    label,
    ...linkProperties
  };

  segmentTrack('companies_hub_link_clicked', properties);
};

export const trackWebinarLinkClicks = (
  linkProperties: Omit<WebinarLinkProperties, 'category' | 'label'>
) => {
  const properties: WebinarLinkProperties = {
    category: 'Webinar Link',
    label: linkProperties.type,
    ...linkProperties
  };
  segmentTrack('webinar_link_clicked', properties);
};

export function trackSalaryToolCTAClicks(
  eventLabel: string,
  expectationsConfiguration?: { selected: SalaryToolFilter; xAxis: XAxisOption }
) {
  trackSignupClicks(eventLabel);

  const referrerDetails = getLocalStorageItem('referrerDetails');
  const salaryToolCTAsClicked = referrerDetails?.salaryToolCTAsClicked || [];

  if (!salaryToolCTAsClicked.includes(eventLabel)) salaryToolCTAsClicked.push(eventLabel);
  if (eventLabel === 'SBT - Expectations block') {
    referrerDetails.expectationsConfiguration = expectationsConfiguration;
  }

  setLocalStorageItem('referrerDetails', { ...referrerDetails, salaryToolCTAsClicked });
}

export const getPageTrackingContext = ({ search, pathname }: Location) => {
  return {
    context: {
      page: {
        path: pathname,
        search,
        referrer: document.referrer,
        title: document.title,
        url: document.location.href
      }
    }
  };
};
