import axios, { AxiosResponse, CancelToken } from 'axios';

import { APIResponse } from 'types';

import { AIPrompt } from 'v2/components/TextEnhancer/TextEnhancer.helpers';

const CANDIDATE_FORMAT_TEXT = process.env.REACT_APP_CANDIDATE_FORMAT_TEXT!;
const isDev = process.env.NODE_ENV === 'development';

type FormatGPTProps = {
  promptName: AIPrompt;
  input: string;
  userInput?: string;
  temperature?: number;
  cancelToken?: CancelToken;
};

type FormatGPTResponse =
  | APIResponse<{ formattedText: string }>
  | { status: 'cancel'; message: string };

export const formatTextGPT = async ({
  promptName,
  input,
  userInput = '',
  temperature = 0.7,
  cancelToken
}: FormatGPTProps): Promise<FormatGPTResponse> => {
  const params = { promptName, input, userInput, options: { temperature } };

  try {
    const start = performance.now();
    const { data } = await axios.post<typeof params, AxiosResponse<FormatGPTResponse>>(
      CANDIDATE_FORMAT_TEXT,
      params,
      { cancelToken }
    );
    const end = performance.now();

    if (isDev) {
      // eslint-disable-next-line no-console
      console.log(`Execution time: ${end - start} ms`);
    }

    if (data.status === 'success') {
      const isValidOutput = checkValidOutput(data.data.formattedText);
      if (!isValidOutput) {
        return { status: 'failure', message: 'That prompt was not valid' };
      }
      return { status: 'success', data: data.data };
    }

    return { status: 'failure', message: data.message };
  } catch (error: Error | unknown) {
    if (axios.isCancel(error)) {
      return { status: 'cancel', message: 'Request cancelled' };
    } else {
      return { status: 'failure', message: 'Sorry, an error occurred.' };
    }
  }
};

const checkValidOutput = (input: string) => {
  return !input.includes('~~ERROR~~');
};
