import React from 'react';

import { trackSalaryToolCTAClicks } from 'tracking-utils';
import { isSalaryToolPage } from 'utils/url';

import HeaderLink from 'components/headerlink';

const SigninLink = ({ handleClick = () => null }: { handleClick?: () => void }) => {
  const onClick = () => {
    trackSalaryPage();
    handleClick();
  };
  return (
    <HeaderLink to="/login" onClick={onClick} className="public_menu_item">
      Sign in
    </HeaderLink>
  );
};

const trackSalaryPage = () => {
  if (isSalaryToolPage()) trackSalaryToolCTAClicks('SBT - Sign in topnav');
};

export default SigninLink;
