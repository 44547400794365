import React, { PropsWithChildren, useRef } from 'react';

import { useTallestChildElement } from './ConditionalHide.hooks';
import { StyledContainer } from './CondtionalHide.styled';

export type ConditionalHideProps = {
  predicate: any;
  fade?: boolean;
  expand?: boolean;
};

const ConditionalHide: React.VFC<PropsWithChildren<ConditionalHideProps>> = ({
  predicate,
  children,
  fade,
  expand
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useTallestChildElement(ref, expand, children);

  return (
    <StyledContainer ref={ref} predicate={predicate} fade={fade} expand={expand}>
      {children}
    </StyledContainer>
  );
};

export default ConditionalHide;
