import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useDemoCompany, useSubscriptionInfo, useUserType } from 'store';
import { AnyFunction, OnRouteChangeProps } from 'types';
import { executeArgs } from 'utils/fn';
import { appendQueryParam } from 'utils/url';

import { handleHubspotWidget } from '../hubspotWidget';

// ============================================
//    On-Route-Change Function Definitions
// ============================================
// Synchronous functions that should be called on route change
// They can be of type AnyFunction<void, OnRouteChangeProps>

const setQueryParamExpired = ({ isExpired }: OnRouteChangeProps) => {
  if (isExpired) {
    // expired is defined in hubspot chatflows controls
    const url = appendQueryParam(window.location.href, { param: 'expired', value: 'true' });
    window.history.replaceState({}, '', url);
  }
};

// ============================================
//          On-Route-Change Logic
// ============================================

export function onRouteChange(props: OnRouteChangeProps) {
  const routines: AnyFunction<void, OnRouteChangeProps>[] = [
    setQueryParamExpired,
    handleHubspotWidget
  ];

  routines.forEach(executeArgs(props));
}

// ============================================
//         Route Monitor Component
// ============================================

export default function RouteMonitor() {
  const location = useLocation();
  const { isCompany, isCandidate } = useUserType();
  const { isExpired } = useSubscriptionInfo();
  const demoCompany = useDemoCompany();

  // On-Route-Change
  useEffect(() => {
    onRouteChange({ location, isCompany, isCandidate, isExpired, demoCompany });
  }, [location?.pathname]);

  return null;
}
