import styled from 'styled-components';

import Block from 'components/block';

export const PricingPlans = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { media } }) => media.mobile`
    flex-direction: column;
    gap: 1rem;
  `}
`;

export const DiscountBlock = styled(Block)`
  &.block {
    margin: 0;
    padding: 2rem;

    .block_title {
      margin-bottom: 0.25rem;
    }

    ${({ theme: { media } }) => media.mobile`
      .block_description {
        margin-bottom: 1.5rem;
      }

      .button {
        width: auto;
        margin-top: 0;
      }
    `}
  }

  // Pricing V4
  &.block.blue {
    max-width: 885px;
    margin: 48px auto 0;

    .block_title {
      font-size: 24px;
    }

    .block_description {
      font-size: ${({ theme: { typography } }) => typography.smallheader};
    }

    ${({ theme: { media } }) => media.mobile`
      .button {
        margin-top: 30px;
      }
    `}
  }

  ${({ theme: { media } }) => media.mobileMin`
    .block_description {
      max-width: 75%;
    }
  `}
`;
