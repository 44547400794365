import {
  createContext,
  Dispatch,
  DragEventHandler,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from 'react';

import { CandidateApplicationTrackerStage as CandidateATStage } from '@cohiretech/common-types';

import { CandidateATSItem } from 'v2/services/fetchers/candidate/ats';
import { useCandidateATS } from 'store';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import { CandidateATCreatedPosition } from 'v2/components/CandidateATPositionForm';

type ToggleImportPositionPopup = <T extends boolean>(
  show: T,
  stage?: T extends true ? CandidateATStage : undefined
) => void;

type ApplicationTrackerContextValue = {
  // Drag and drop
  draggingItem?: CandidateATSItem;
  dropTarget?: CandidateATStage;
  handleDragStart: (item: CandidateATSItem) => DragEventHandler;
  handleDragEnterColumn: (stage: CandidateATStage) => DragEventHandler;
  handleDropInColumn: (stage: CandidateATStage) => DragEventHandler;
  handleDragEnd: DragEventHandler;
  // Import position popup
  position: CandidateATCreatedPosition;
  showImportPositionPopup: boolean;
  setPosition: Dispatch<SetStateAction<CandidateATCreatedPosition>>;
  toggleImportPositionPopup: ToggleImportPositionPopup;
};

export const COLUMN_WIDTH = 412;
export const MOBILE_COLUMN_WIDTH = 280;

const ApplicationTrackerContext = createContext<ApplicationTrackerContextValue | null>(null);

export default function ApplicationTrackerProvider({ children }: PropsWithChildren<{}>) {
  const isMobile = useMedia(mediaQueries.mobile);
  const { handleUpdateATSItemStage } = useCandidateATS();

  const [draggingItem, setDraggingItem] = useState<CandidateATSItem>();
  const [dropTarget, setDropTarget] = useState<CandidateATStage>();

  const [showImportPositionPopup, setShowImportPositionPopup] = useState(false);
  const [position, setPosition] = useState<CandidateATCreatedPosition>({
    stage: CandidateATStage.Applied
  });

  const resetDragDropState = () => {
    setDropTarget(undefined);
    setDraggingItem(undefined);
  };

  const handleDragStart = (item: CandidateATSItem) => () => {
    setDraggingItem(item);
  };

  const handleDragEnterColumn =
    (stage: CandidateATStage): DragEventHandler =>
    e => {
      if (stage === dropTarget) return;

      setDropTarget(stage);

      if (isMobile) return;

      const { x } = e.currentTarget.getBoundingClientRect();

      if (x <= 0 || x >= window.innerWidth - COLUMN_WIDTH) {
        e.currentTarget.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }

      e.preventDefault(); // preventing the link from opening
    };

  const handleDropInColumn =
    (stage: CandidateATStage): DragEventHandler =>
    e => {
      if (draggingItem && stage !== draggingItem.stage) {
        handleUpdateATSItemStage({ item: draggingItem, stage });
      }

      resetDragDropState();
      e.preventDefault(); // preventing the link from opening
    };

  const handleDragEnd: DragEventHandler = () => {
    // Handle dropping an item not in a column
    setTimeout(() => {
      if (draggingItem || dropTarget) resetDragDropState();
    }, 100);
  };

  const toggleImportPositionPopup: ToggleImportPositionPopup = (show, stage) => {
    setShowImportPositionPopup(show);
    setPosition({ stage: (show && stage) || CandidateATStage.Applied });
  };

  return (
    <ApplicationTrackerContext.Provider
      value={{
        draggingItem,
        dropTarget,
        handleDragStart,
        handleDragEnterColumn,
        handleDropInColumn,
        handleDragEnd,
        position,
        showImportPositionPopup,
        setPosition,
        toggleImportPositionPopup
      }}
    >
      {children}
    </ApplicationTrackerContext.Provider>
  );
}

export const useApplicationTrackerContext = () => {
  const value = useContext(ApplicationTrackerContext);

  if (!value) {
    throw new Error('useApplicationTrackerContext must be used within a Provider');
  }
  return value;
};
