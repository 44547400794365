import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import media from 'styles/media';

import ProductScreenPopup from 'components/productscreenpopup';
import Tooltip, { Props as TooltipProps } from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type Feature = {
  name: string | Array<string | ReactNode>;
  growth?: string | boolean;
  enterprise?: string | boolean;
  tooltip?: TooltipProps;
  popup?: { subtitle: string; image: string };
};

function FeatureItem({
  name,
  tooltip,
  popup,
  defaultValue,
  ...values
}: Feature & { defaultValue: string | ReactNode }) {
  const [showPopup, setShowPopup] = useState(false);
  const { growth = defaultValue, enterprise = defaultValue } = values;
  const featureName = Array.isArray(name) ? (name[0] as string) : name;

  const tooltipText: TooltipProps['text'] = [tooltip?.text];

  if (popup) {
    tooltipText.push(
      <a key={`${featureName}_open_popup`} className="link" onClick={() => setShowPopup(true)}>
        View more
      </a>
    );
  }

  return (
    <FeatureRow className="feature_row">
      <td className="text_bold">
        {name}
        <ConditionalRender predicate={!!tooltip}>
          <span className="icon_help">
            <Tooltip text={tooltipText} position="right" />
          </span>
        </ConditionalRender>
      </td>
      <td className="text_center">{growth}</td>
      <td className="text_center">{enterprise}</td>
      <ProductScreenPopup
        visible={showPopup}
        title={name}
        subtitle={popup?.subtitle || ''}
        image={{ fileName: popup?.image || '', alt: featureName }}
        onClose={() => setShowPopup(false)}
      />
    </FeatureRow>
  );
}

export default function FeatureList({ category, list }: { category: string; list: Feature[] }) {
  const isUsageCategory = category === 'Usage';

  return (
    <>
      <CategoryTitle className="category_title" topBorder={!isUsageCategory}>
        <td>{category}</td>
        <td />
        <td />
      </CategoryTitle>
      {list.map((item, idx) => (
        <FeatureItem
          key={`${category}_${idx}`}
          defaultValue={isUsageCategory ? 'Unlimited' : <span className="icon_tick" />}
          {...item}
        />
      ))}
    </>
  );
}

const CategoryTitle = styled.tr<{ topBorder: boolean }>`
  &.category_title td {
    padding-top: 40px;
    font-size: ${typography.header};
    font-weight: ${typography.black};

    ${media.mobile`
      font-size: 20px;
    `}

    ${({ topBorder }) =>
      !topBorder &&
      css`
        border-top: 0;
        padding-top: 20px;
      `}
  }
`;

const FeatureRow = styled.tr`
  &.feature_row td {
    padding: 12px 4px;

    &:first-child {
      font-size: ${typography.smallheader};

      ${media.mobile`
        font-size: ${typography.normal};
      `}
    }

    .icon_help {
      font-size: ${typography.normal};
    }

    .icon_tick {
      font-size: 20px;
      color: ${colours.primaryColour};
    }
  }
`;
