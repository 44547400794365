import React, { useState } from 'react';
import styled from 'styled-components';

import { tabletLandscape, isTabletWidth, isTabletLandscapeWidth } from 'responsiveConfig';

import Button from 'components/button';

import colours from 'css/base/_colours.module.scss';

type Platform =
  | 'whatsapp'
  | 'email'
  | 'linkedin'
  | 'linkedinMessenger'
  | 'facebook'
  | 'twitter'
  | 'messenger'
  | 'sms';

type PlatformInfo = { text: string; message?: string; subject?: string };

type Props = {
  buttonType?: 'button' | 'icon';
  shareURL: string;
  showLink?: boolean;
  responsiveCheck?: { isMaxScreenWidth: boolean; maxScreenWidth: string };
  onTrackAction?: () => void;
} & Partial<Record<Platform, PlatformInfo>> &
  typeof defaultProps;

const defaultProps = {
  responsiveCheck: { isMaxScreenWidth: isTabletLandscapeWidth, maxScreenWidth: tabletLandscape }
};

const PLATFORMS: Platform[] = [
  'whatsapp',
  'email',
  'linkedin',
  'linkedinMessenger',
  'facebook',
  'twitter',
  'messenger',
  'sms'
];

function ShareButtons({
  buttonType = 'button',
  shareURL,
  showLink,
  responsiveCheck,
  onTrackAction,
  ...platforms
}: Props) {
  const [copied, setCopied] = useState(false);

  const copyLink = async () => {
    await navigator.clipboard.writeText(shareURL);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const getURL = (platform: Platform) => {
    const { message, subject } = platforms[platform] as PlatformInfo;

    switch (platform) {
      case 'whatsapp':
        return `https://wa.me?text=${message}`;
      case 'email':
        return `mailto:?subject=${subject}&body=${message}`;
      case 'linkedin':
        return `https://www.linkedin.com/sharing/share-offsite?url=${shareURL}`;
      case 'linkedinMessenger':
        return isTabletWidth
          ? 'https://www.linkedin.com/messaging'
          : 'https://www.linkedin.com/messaging/thread/new/';
      case 'facebook':
        return `https://www.facebook.com/sharer.php?u=${shareURL}`;
      case 'twitter':
        return `https://twitter.com/intent/tweet?text=${message}&url=${shareURL}`;
      case 'messenger':
        return isTabletWidth ? 'https://m.me' : 'https://www.messenger.com/new';
      case 'sms':
        return `sms:${window.navigator.userAgent.includes('iPhone') ? '&' : '?'}body=${message}`;
      default:
        break;
    }
  };

  const handleClick = async (platform: Platform) => {
    const url = getURL(platform);

    if (['linkedinMessenger', 'messenger'].includes(platform)) await copyLink();

    if (['linkedin', 'facebook', 'twitter', 'sms'].includes(platform)) window.open(url);
    else window.open(url, '_blank');

    if (onTrackAction) onTrackAction();
  };

  const getButtonStyle = (platform: Platform) => {
    switch (platform) {
      case 'email':
        return 'fill_dark_blue';
      case 'linkedinMessenger':
        return 'linkedin_button';
      case 'messenger':
        return 'facebook_button';
      case 'sms':
        return 'fill_grey';
      default:
        return `${platform}_button`;
    }
  };

  const platformsToRender = PLATFORMS.filter(platform => !!platforms[platform]);
  const { maxScreenWidth, isMaxScreenWidth } = responsiveCheck || {};

  if (buttonType === 'button') {
    return (
      <ButtonsWrapper className="share_buttons" maxScreenWidth={maxScreenWidth}>
        {platformsToRender.map(platform => {
          const { text } = platforms[platform] as PlatformInfo;
          const icon = platform.startsWith('linkedin')
            ? 'icon_linkedin_square'
            : `icon_${platform}`;
          const buttonStyle = getButtonStyle(platform);

          return (
            <Button
              key={platform}
              text={isMaxScreenWidth ? '' : text}
              icon={icon}
              buttonStyle={buttonStyle}
              action={() => handleClick(platform)}
            />
          );
        })}
      </ButtonsWrapper>
    );
  } else {
    return (
      <IconsWrapper className="share_buttons">
        {platformsToRender.map(platform => (
          <button
            type="button"
            className={platform === 'linkedinMessenger' ? 'icon_linkedin' : `icon_${platform}`}
            onClick={() => handleClick(platform)}
          />
        ))}
        {showLink && <button type="button" className="icon_link" onClick={copyLink} />}
        {copied && <span className="copied_text">Copied!</span>}
      </IconsWrapper>
    );
  }
}

ShareButtons.defaultProps = defaultProps;

export default ShareButtons;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 12px;

    &.icon_facebook,
    &.icon_link {
      font-size: 20px;
    }

    &.icon_link {
      font-weight: bold;
    }

    &:hover {
      color: ${colours.primaryColour};
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ButtonsWrapper = styled.div<{ maxScreenWidth: string }>`
  display: flex;
  justify-content: space-between;
  max-width: 600px;

  .button {
    padding: 13px;
    min-width: 105px;
    gap: 10px;
    margin: 10px 0;

    @media only screen and (max-width: ${props => props.maxScreenWidth}) {
      min-width: 45px !important;
      padding: 10px 5px !important;
      text-align: center;

      .icon {
        margin-right: 0 !important;
        vertical-align: unset !important;
      }
    }
  }
`;
