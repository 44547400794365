import styled, { css } from 'styled-components';

import { isMobileWidth, mobile, tablet } from 'responsiveConfig';

import { isClipOrDiscussion } from 'views/landing/insights/helpers';

import typography from 'css/base/_typography.module.scss';

import 'css/base/_functions.module.scss';
import { Wrapper } from '../commonstyles/Wrapper';

type SliderWrapperProps = {
  slideTo?: number;
  slideToLimit?: number;
  translateX: number;
  columns?: number;
  contentType?: string;
  itemType?: string;
  itemsCount?: number;
  showSeeMoreLink: boolean;
};

export const SliderWrapper = styled(Wrapper)<SliderWrapperProps>`
  .listing_slider_list {
    position: relative;
    text-align: left;

    .listing_slider_loader {
      min-height: 200px;
      background: none;
    }

    .listing_slider_header {
      ${({ title, showSeeMoreLink }) =>
        (title || showSeeMoreLink) &&
        css`
          min-height: 60px;
        `}

      ${({ itemType }) =>
        !isClipOrDiscussion(itemType) &&
        !isMobileWidth &&
        css`
          margin-bottom: -15px;
        `}

      ${({ itemType }) =>
        itemType === 'people_item' &&
        css`
          @media only screen and (max-width: ${mobile}) {
            margin: 15px 0;
          }
        `}

      .listing_slider_title {
        font-size: 20px;
        margin: 0 auto 0 0;

        @media only screen and (max-width: ${mobile}) {
          font-size: ${typography.smallheader};
        }
      }

      .link {
        @media only screen and (max-width: ${mobile}) {
          margin: 10px auto;
        }
      }

      .listing_slider_navigation {
        display: flex;
        align-items: center;
        padding: 5px;
        padding-right: 0;

        .link {
          cursor: pointer;
          margin-right: 20px;
          text-align: right;
        }

        .scroll_arrow {
          ${({ title }) =>
            title &&
            css`
              position: static;
            `};
          box-shadow: none;
          top: calc(50% - 45px);

          @media only screen and (max-width: ${mobile}) {
            top: calc(50% - 65px);
          }

          ${({ itemType }) =>
            itemType === 'testimonial_item' &&
            css`
              top: calc(50% - 10px);

              @media only screen and (max-width: ${mobile}) {
                top: calc(50% - 50px);
              }
            `}

          ${({ itemType }) =>
            itemType === 'insights_clip_item' &&
            css`
              top: calc(50% + 10px);

              @media only screen and (max-width: ${mobile}) {
                top: calc(50% - 20px);
              }
            `}

          @media only screen and (max-width: ${mobile}) {
            ${({ title }) =>
              title &&
              css`
                background-color: transparent;
              `};
          }

          &.left_arrow {
            ${({ slideTo, title }) =>
              (slideTo || 0) <= 0 &&
              css`
                ${!title &&
                css`
                  display: none;
                `};
                pointer-events: none;
                opacity: 50%;
              `}
          }

          &.right_arrow {
            ${({ slideTo, itemsCount, columns, title }) =>
              (slideTo || 0) >= (itemsCount || 0) - (columns || 3) &&
              css`
                ${!title &&
                css`
                  display: none;
                `};
                pointer-events: none;
                opacity: 50%;
              `}

            ${({ itemType, slideTo, slideToLimit }) =>
              itemType === 'insights_discussion_item' &&
              (slideTo || 0) >= (slideToLimit || 0) &&
              css`
                pointer-events: none;
                opacity: 50%;
              `}
          }
        }
      }
    }

    .listing_slider_overflow {
      overflow: hidden;
      box-sizing: border-box;
      width: 105%;
      margin-left: -2.5%;
      padding-right: 2%;

      ${({ itemType }) =>
        itemType === 'testimonial_item' &&
        css`
          box-sizing: initial;
          width: 100%;
          margin-left: 0;
          padding-right: 0;
        `}

      ${({ itemType }) =>
        itemType === 'people_item' &&
        css`
          width: 104%;
          margin-left: -2%;
          padding: 0 1.3% 0;
        `}

        ${({ itemType }) =>
        itemType === 'insights_clip_item' &&
        css`
          width: 102%;
          margin-left: -2%;
          padding-right: 2%;
        `}
        
        ${({ itemType }) =>
        itemType === 'insights_discussion_item' &&
        css`
          width: 100%;
          margin: 15px 0 35px;
          margin-left: 0;
          padding-right: 0;
        `}
    }

    .listing_slider_list_wrapper {
      transform: ${({ translateX }) => `translateX(${translateX}%)`};
      padding: 0;
      overflow: visible;
      transition: transform 0.5s ease-in-out;
      white-space: nowrap;
      margin: 0;

      ${({ itemType }) =>
        itemType === 'testimonial_item' &&
        css`
          margin: 20px 0;

          @media only screen and (max-width: ${mobile}) {
            margin: 10px 0;
          }
        `}

      ${({ itemType, translateX }) =>
        itemType === 'insights_discussion_item' &&
        css`
          margin: 10px 0;
          display: flex;
          gap: 1rem;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 25rem;
          transform: translateX(${2 * translateX}%);

          @media only screen and (max-width: ${tablet}) {
            max-height: 30rem;
          }

          @media only screen and (max-width: ${mobile}) {
            transform: translateX(${1 * translateX}%);
            max-height: 26rem;
          }

          .insights_discussion_item {
            border-radius: 20px;
            width: calc(50% - 0.5rem);

            @media only screen and (max-width: ${tablet}) {
              width: calc(100% - 0.5rem);
            }

            @media only screen and (max-width: ${mobile}) {
              height: 50%;
              width: calc(100% - 0.5rem);
            }
          }
        `}

        .square_card_item {
        display: inline-block;
        margin: 15px 0 15px 1%;
        cursor: pointer;
        padding: 20px;

        @include respond-to(mobile) {
          padding: 15px;
        }

        &:not(.insights_clip_item):nth-child(2n + 1),
        &:not(.insights_clip_item):nth-child(3n + 1),
        &:not(.insights_clip_item):nth-child(4n + 1),
        &:not(.insights_clip_item):nth-child(3n),
        &:not(.insights_clip_item):nth-child(4n) {
          margin: 15px 0 15px 1%;
        }

        &.insights_clip_item {
          margin-left: 2%;

          &:nth-child(2n + 1),
          &:nth-child(3n + 1),
          &:nth-child(4n + 1),
          &:nth-child(3n),
          &:nth-child(4n) {
            margin-left: 2%;
          }
        }
      }

      .people_item {
        display: inline-block;
        margin: 1% 1.3% 1% 0;

        &.four_cols_item:nth-child(4n + 1) {
          margin: 1% 1.3% 1% 0;
        }

        .persons_job,
        .persons_hiring_info {
          font-size: 16px;
        }

        &:hover {
          box-shadow: 0 0 20px -5px rgba($fontColour, 0.3);
        }
      }
    }
  }
`;
