import styled from 'styled-components';

import LinkWithIcon from 'components/linkwithicon';

export const FilterTitleCont = styled.div`
  min-height: 1.5rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tabs_wrapper {
    margin-right: 1rem;
  }
`;

export const FilterTitle = styled(LinkWithIcon)`
  font-weight: ${({ theme: { typography } }) => typography.semibold};
  padding: 0 0.5rem 0 1rem;
  align-items: center;
  gap: 0.5rem;

  .icon {
    font-size: var(--type-md);

    &[class*='icon_simple_arrow'] {
      font-size: var(---type-sm);
      ${({ theme: { fn } }) => fn.slightlyBolderIcon()}
    }
  }

  .link_text {
    display: flex;
    align-items: center;
    flex: 1;

    .filter_label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .total_selected {
    margin-left: 5px;
    color: ${({ theme: { colours } }) => colours.inputsColour};
    font-weight: ${({ theme: { typography } }) => typography.regular};
  }
`;
