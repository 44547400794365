import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeedInterface } from '@cohiretech/common-types';

import { getCompanyStreams } from 'fetcher';
import {
  AppDispatch,
  persistCompanyOnboardingItemCompleted,
  RootState,
  selectCompanyProfile,
  setCompanyStreams
} from 'store';

export const reloadCompanyStreams = createAsyncThunk<
  { companyStreams: FeedInterface[] | null },
  void,
  { dispatch: AppDispatch; state: RootState }
>('services/company/reloadStreams', async (_, { dispatch, getState }) => {
  const state = getState();
  const profile = selectCompanyProfile(state);

  if (!profile) return { companyStreams: null };

  const companyStreams = await getCompanyStreams();
  dispatch(setCompanyStreams(companyStreams));
  dispatch(persistCompanyOnboardingItemCompleted('stream'));

  return { companyStreams };
});
