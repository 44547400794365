import { useMemo, useState } from 'react';

import { OperatingCountry } from '@cohiretech/common-types';

import { PayPerHirePricingOptions, PricingBtnAction, SelectedPricing, StandardPlan } from 'types';
import { ERROR_MESSAGE } from 'consts';
import {
  Result,
  handlePlanEnquiry,
  handleReactivation,
  handleUpgrade
} from 'v2/services/company/subscription';
import { checkIfSubscriptionExpired, setBookDemoTracking } from 'utils/companyUtils';
import {
  checkIfSourcingBasic,
  getActivateBtnProps,
  getRequestDemoLink,
  isCurrentPlanSelected
} from 'v2/services/tools/pricing';
import { useDemoCompany } from 'store';

import { Props as ButtonProps } from 'components/button';

import {
  PricingPlanCardProps,
  checkIfMonthlyPlan,
  getBtnActionForExistingUser
} from './PricingPlanCard.helpers';

const { PayPerHire } = PayPerHirePricingOptions;

// Get button action/link
export const usePricingButtons = ({
  pricingOption,
  selectedPricing,
  currentPlanDetails,
  selectedTier,
  hidePrice,
  updateEnquiredStatus,
  showPaymentPopup
}: PricingPlanCardProps) => {
  const demoCompany = useDemoCompany();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { plan, defaultPlan, button } = pricingOption;
  const isUSPricingV5 = selectedPricing.pricingCountry === OperatingCountry.US;
  const isEnterprise = plan === 'enterprise';
  const {
    plan: selectedPlan,
    frequency: planPeriod,
    pricingCountry: pricingType
  } = selectedPricing;
  const { plan: currentPlan, isUnpaidAccount } = currentPlanDetails || {};
  const isSourcingBasic = checkIfSourcingBasic(currentPlan);
  const requestDemoLink = getRequestDemoLink(selectedPricing);
  const activateButtonProps = getActivateBtnProps(
    selectedPricing as SelectedPricing<StandardPlan>,
    isUnpaidAccount
  );

  const handlePlanSelect = async (action: PricingBtnAction) => {
    setLoading(true);

    let result: Result;
    const { urlPath, mode } = selectedTier!;

    switch (action) {
      case 'upgrade':
        result = await handleUpgrade(selectedPlan, urlPath, mode);
        break;
      case 'reactivation':
        result = await handleReactivation(selectedPlan, urlPath, pricingType);

        if (result.paymentData) showPaymentPopup(result.paymentData);
        break;
      default:
        const isSubscriptionExpired = checkIfSubscriptionExpired(currentPlanDetails?.plan);

        result = await handlePlanEnquiry(urlPath, planPeriod, action, isSubscriptionExpired, mode);

        if (result.status === 'success') updateEnquiredStatus();
        break;
    }

    const { status, errorMsg } = result;

    setErrorMessage(status === 'failure' ? errorMsg || ERROR_MESSAGE : '');
    setLoading(false);

    return result;
  };

  const getPricingBtnProps = (): ButtonProps => {
    if (isCurrentPlanSelected(selectedPricing, currentPlanDetails) && !demoCompany) {
      return { ...button, text: 'Your current plan', disabled: true };
    }

    if (demoCompany || !currentPlan || isEnterprise) {
      const link = isEnterprise
        ? getRequestDemoLink({ ...selectedPricing, plan: defaultPlan })
        : requestDemoLink;

      return { ...button, link };
    }

    if (isSourcingBasic) {
      return { ...button, ...activateButtonProps };
    }

    if (currentPlan === PayPerHire && selectedPlan !== PayPerHire) {
      const text = 'Get in touch';
      return { ...button, text, link: requestDemoLink };
    }

    const { text, action } =
      getBtnActionForExistingUser(currentPlan, selectedPricing.frequency) || {};

    return {
      ...button,
      text,
      action: action ? () => handlePlanSelect(action) : undefined,
      disabled: selectedTier?.enquired
    };
  };

  const getSecondaryLink = (): ButtonProps | undefined => {
    const isMonthlyPlan = checkIfMonthlyPlan(planPeriod);
    const showSecondaryLink =
      isMonthlyPlan &&
      !isEnterprise &&
      selectedTier?.price &&
      !isUSPricingV5 &&
      !hidePrice &&
      (!currentPlan || isSourcingBasic || demoCompany);

    if (!showSecondaryLink) return;

    if (!isSourcingBasic || demoCompany) {
      return { ...activateButtonProps, text: 'Create your account' };
    }

    return {
      text: 'Book a demo',
      icon: 'icon_demo',
      link: requestDemoLink,
      action: () => setBookDemoTracking('click', !!currentPlan)
    };
  };

  const pricingButtons = useMemo(
    () => ({
      button: { ...getPricingBtnProps(), loading } as ButtonProps,
      secondaryLink: getSecondaryLink()
    }),
    [[selectedPlan, planPeriod, pricingType, loading]]
  );

  return { ...pricingButtons, errorMessage };
};
