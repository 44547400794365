import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, LinkProps } from 'react-router-dom';

import { mediaQueries } from 'responsiveConfig';

import typography from 'css/base/_typography.module.scss';

type HeaderLinkProps = {
  to?: string;
  onClick?: () => void;
  type?: 'lightblue' | 'normal';
  children: React.ReactNode;
  className?: string;
} & Partial<LinkProps>;

const HeaderLink = ({
  to,
  children,
  type = 'lightblue',
  onClick = () => null,
  className
}: HeaderLinkProps) => {
  if (!to) {
    return (
      <StyledSpan className={className} onClick={onClick} type={type}>
        {children}
      </StyledSpan>
    );
  }
  return (
    <StyledLink className={className} to={to} onClick={onClick} type={type}>
      {children}
    </StyledLink>
  );
};

const HeaderItemStyling = css<Pick<HeaderLinkProps, 'type'>>`
  display: inline-block;
  font-size: ${typography.smallheader};
  font-weight: ${typography.semibold};
  padding: 0.5rem 0;
  cursor: pointer;
  ${({ type, theme: { colours } }) =>
    type === 'lightblue' &&
    css`
      color: ${colours.primaryColour};
    `};
  position: relative;
  vertical-align: middle;

  @media ${mediaQueries.tablet} {
    &:not(.candidate_menu_item) {
      margin: 10px 0;
      border-bottom: 2px solid transparent;
      font-weight: ${typography.semibold} !important;

      &:hover::after {
        display: none;
      }
    }
  }

  > [class^='icon_'],
  > [class*=' icon_'] {
    vertical-align: text-bottom;
    margin-right: 0.5rem;
    font-weight: bold;
  }

  .badge {
    margin-right: 0;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    background: currentColor;
    height: 2px;
    left: 0;
    width: 100%;
    bottom: -1px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }

  &:hover::after,
  &.active::after {
    transform: scaleX(1);
    transform-origin: left;
  }
`;

const StyledLink = styled(NavLink)<Pick<HeaderLinkProps, 'type'>>`
  &&& {
    ${HeaderItemStyling};
  }
`;

const StyledSpan = styled.span`
  &&& {
    ${HeaderItemStyling};
  }
`;

export default HeaderLink;
