import styled from 'styled-components';

import media from 'styles/media';

export const MaxView = styled.div`
  max-width: 1800px;
  margin: auto;

  ${media.normalScreen`
    max-width: 80%;
  `}

  ${media.smallDesktop`
    max-width: 95%;
    padding: 0 20px;
    box-sizing: border-box;
  `}
`;

export const PageView = styled.div`
  max-width: ${({ theme }) => theme.grid.normalpagewidth};
  margin: auto;
  padding: 20px;
`;

export const MinExtendedView = styled.div`
  max-width: ${({ theme }) => theme.grid.smallextendedpagewidth};
  margin: auto;
  padding: 20px;
  position: relative;
`;

export const ProfileView = styled.div`
  max-width: ${({ theme }) => theme.grid.positionpagewidth};
  margin: auto;
  padding: 20px;
`;

export const MinView = styled.div`
  max-width: ${({ theme }) => theme.grid.smallpagewidth};
  margin: auto;
  padding: 20px;
`;

export const FormView = styled.div`
  max-width: ${({ theme }) => theme.grid.formwidth};
  margin: auto;
`;
