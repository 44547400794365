import React, { ReactNode } from 'react';
import styled from 'styled-components';

import media from 'styles/media';

import DialogWindow from 'components/dialogwindow';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import typography from 'css/base/_typography.module.scss';

const IMAGE_BUCKET_URL = 'https://assets.co-hire.com';

type Props = {
  visible: boolean;
  title: string | Array<string | ReactNode>;
  subtitle: string;
  image: { fileName: string; alt: string };
  onClose: () => void;
};

export default function ProductScreenPopup({ visible, title, subtitle, image, onClose }: Props) {
  return (
    <ConditionalRender predicate={visible}>
      <FeaturePopup
        title={title}
        subTitle={subtitle}
        visible
        showCloseIcon
        onClose={onClose}
        bodyImage={{
          src: `${IMAGE_BUCKET_URL}/${image.fileName}`,
          alt: image.alt,
          style: { maxWidth: '95%', marginBottom: 0, borderRadius: '30px' }
        }}
      />
    </ConditionalRender>
  );
}

const FeaturePopup = styled(DialogWindow)`
  &.pop_up_cont .pop_up_dialog {
    border-radius: 30px;

    .close_pop_up {
      top: 16px;
      right: 20px;
    }

    .pop_up_title {
      font-size: ${typography.bigheader};
    }

    ${media.tabletLandscapeMin`
      width: 835px;

      .pop_up_sub_title {
        max-width: 80%;
      }
    `}
  }
`;
