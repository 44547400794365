export const capitalizeFirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const stringToUpperCase = (txt: string) => txt.toUpperCase();

export const capitalizeAll = (str: string = ''): string => stringToUpperCase(str);

export const capitalizeSentences = (string: string = '') =>
  string ? string.replace(/([.|?|!] [a-z]|^\w)/g, stringToUpperCase) : string;

export const capitalizeWords = (string: string = '') =>
  string ? string.replace(/([\s][a-z]|^\w)/g, stringToUpperCase) : string;
