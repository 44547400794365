import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { JobTitleOption } from '@cohiretech/common-types';

import { getJobCategoryOptions, JobCategoryOptions } from 'v2/services/fetchers/public/jobTitles';
import { pluck } from 'utils/object';
import { isEmpty } from 'utils';

import AutoComplete, { ListItem } from 'components/autocomplete';
import ExpandableSelectField from 'components/expandableselectfield';
import Loader from 'components/loader';
import SelectedItems from 'components/selecteditems';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { StyledNote } from 'v2/components/ui/styles/FieldValidation';

const PLACEHOLDER = 'Type and select job roles';

type Props = {
  showQuestion?: boolean;
  question?: string | $TSFixMe[];
  jobTitleExample?: string;
  optional?: boolean;
  description?: string | $TSFixMe[];
  columns?: number;
  multiple?: boolean;
  selected: string[];
  onSelect?: $TSFixMeFunction;
  maxSelection?: number;
  formSubmitted?: boolean;
  placeholder?: string;
  hideExpandedRoles?: boolean;
};

// Potential component re-usability enhancement (if needed in the future): Move categories to parent component, pass them as props and rename component to something generic
export default function JobTitleSelectField({
  showQuestion = true,
  question,
  jobTitleExample,
  optional,
  description,
  columns,
  multiple,
  selected,
  onSelect,
  maxSelection,
  formSubmitted,
  placeholder,
  hideExpandedRoles
}: Props) {
  const [loading, setLoading] = useState(false);
  const [jobTitles, setJobTitles] = useState<JobCategoryOptions>([]);
  const [isRolesByFunctionExpanded, setIsRolesByFunctionExpanded] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const getJobTitleOptions = () => jobTitles.map(pluck('options')).flat();

  const fetchJobTitles = async () => {
    setLoading(true);
    const response = await getJobCategoryOptions();
    if (response.status === 'success') {
      setJobTitles(formatJobTitlesForExpandableSelectField(response.data));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchJobTitles();
  }, []);

  const renderExpandableCategories = () => {
    return (
      <ConditionalRender predicate={!loading} fallback={<Loader className="medium" />}>
        <StyledAutocomplete
          id="select_job_titles_autocomplete"
          list={getJobTitleOptions() as ListItem[]}
          onSelect={value => onSelect?.(value)}
          selectedItems={selected}
          removeSelected
          placeholder={placeholder || PLACEHOLDER}
          disabled={selected.length === maxSelection}
          disabledPlaceholder={`You've selected${
            maxSelection ? ` ${maxSelection} ` : ' '
          }job roles. To add another, remove one of the job roles selected.`}
          searchThroughTags
          noneSelectedMessage="Please select job roles"
          formSubmitted={formSubmitted}
          showErrorMessage={showErrorMessage}
          onBlur={optional ? undefined : () => setShowErrorMessage(true)}
        />
        <SelectedItems
          selectedItems={selected}
          columns={columns}
          removeItem={(value: string) => onSelect?.(value)}
        />
        <ConditionalRender predicate={!hideExpandedRoles}>
          <StyledSpan onClick={() => setIsRolesByFunctionExpanded(!isRolesByFunctionExpanded)}>
            <StyledIcon className={isRolesByFunctionExpanded ? 'icon_minimize' : 'icon_add'} />
            {isRolesByFunctionExpanded
              ? 'Minimise roles grouped by functions'
              : 'Expand all roles grouped by functions'}
          </StyledSpan>
          {isRolesByFunctionExpanded && (
            <Categories>
              {jobTitles.map(({ category, options }) => (
                <ExpandableSelectField
                  key={category}
                  title={category}
                  options={options}
                  columns={columns}
                  selected={selected}
                  multiple
                  disabled={selected.length === maxSelection}
                  onSelect={(value: JobTitleOption) => onSelect?.(value)}
                  onDeselect={(value: JobTitleOption) => onSelect?.(value.value)}
                />
              ))}
            </Categories>
          )}
        </ConditionalRender>
      </ConditionalRender>
    );
  };

  if (showQuestion) {
    return (
      <div className="field">
        <label className="question">
          {question}
          {jobTitleExample && <span>(e.g. {jobTitleExample})</span>}
          {optional && <span>(optional)</span>}
          {description && <span className="description">{description}</span>}
          {multiple && (
            <StyledNote
              className="note"
              invalidInput={(formSubmitted || showErrorMessage) && isEmpty(selected)}
            >
              {maxSelection === 1 ? '' : `Choose up to ${maxSelection}`}
            </StyledNote>
          )}
        </label>
        {renderExpandableCategories()}
      </div>
    );
  } else {
    return renderExpandableCategories();
  }
}

const formatJobTitlesForExpandableSelectField = (jobTitles: JobCategoryOptions) => {
  return jobTitles.map(({ category, options }) => ({
    category,
    options: options.map(option => ({ ...option, attribute: 'job_title' }))
  }));
};

export const StyledAutocomplete = styled(AutoComplete)`
  text-align: left;
`;

const Categories = styled.div`
  margin: 10px 0;
`;

const StyledSpan = styled.span`
  text-align: left;
  display: block;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.small};
  font-weight: ${({ theme }) => theme.typography.bold};
`;

const StyledIcon = styled.span`
  &&& {
    font-size: ${({ theme }) => theme.typography.normal};
    font-weight: ${({ theme }) => theme.typography.black};
    margin-right: 0.5rem;
    vertical-align: bottom;
  }
`;
