import styled from 'styled-components';

import { LeftIconSelectOptionStyling } from 'v2/components/ui/styles';

export const CandidateDropdown = styled.div`
  position: absolute;
  width: 288px;
  background-color: var(--background-block-primary);
  box-shadow: var(--drop-shadow-focused);
  border-radius: 1.5rem;
  padding: 1rem 0.5rem 0.75rem;
  top: calc(100% + 0.5rem);
  right: -0.5rem;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .item {
    position: relative;
    line-height: 1.2;
    ${LeftIconSelectOptionStyling({})}
  }

  ${({ theme: { media } }) => media.macBookScreen`
    right: -30px;
  `}

  ${({ theme: { media } }) => media.laptop`
    right: -10px;
  `}

  ${({ theme: { colours, fn, media } }) => media.tablet`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    box-shadow: 0 0 0 200vw ${fn.opaqueColour(colours.fontColour, 0.9)};
  `}
`;

export const CloseIcon = styled.span`
  &.icon_close {
    position: absolute;
    top: -10px;
    right: -10px;
    color: white;
    transform: rotate(0);
    background-color: ${({ theme: { colours } }) => colours.fontColour};
    text-align: center;
    border-radius: 50%;
    z-index: 100;
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-size: ${({ theme: { typography } }) => typography.small};
    cursor: pointer;
  }
`;

export const MenuCont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    padding-top: 0.5rem;
    border-top: 1px solid var(--border-divider-soft);
  }
`;
