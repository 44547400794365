import React from 'react';
import styled from 'styled-components';

import { mobile } from 'responsiveConfig';

import colours from 'css/base/_colours.module.scss';

type State = $TSFixMe;

export default class Textarea extends React.Component<{}, State> {
  textArea: $TSFixMe;
  constructor(props: {}) {
    super(props);

    this.state = {
      rows: 1,
      value: (props as $TSFixMe).value || ''
    };

    this.textArea = React.createRef();
  }

  increaseRows(e: $TSFixMe) {
    // @ts-expect-error TS(2339) FIXME: Property 'onNext' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { onNext, optional } = this.props;
    const { rows, value } = this.state;
    const textArea = this.textArea.current;
    const startPosition = textArea.selectionStart;

    if (e.key === 'Enter' && !e.shiftKey) {
      if (value || optional) onNext();
      e.preventDefault();
      return false;
    } else if (e.key === 'Enter') this.setState({ rows: rows + 1 });
    else if (rows > 1 && e.key === 'Backspace' && value.charAt(startPosition - 1) === '\n') {
      this.setState({ rows: rows - 1 });
    } else if (value.length === 0) this.setState({ rows: 1 });
  }

  onChange(e: $TSFixMe) {
    // @ts-expect-error TS(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    const { onChange } = this.props;

    this.setState({ value: e.target.value });

    if (onChange) onChange(e.target.value);
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
    const { placeholder, elementID } = this.props;
    const { value, rows } = this.state;

    return (
      <Area
        ref={this.textArea}
        value={value}
        placeholder={placeholder}
        rows={rows}
        onKeyDown={e => this.increaseRows(e)}
        onChange={e => this.onChange(e)}
        id={elementID}
      />
    );
  }
}

const Area = styled.textarea`
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid;
  font-size: 18px;
  max-width: 600px;
  color: ${colours.primaryColour};

  &:focus {
    border: 0; //overwrite default focus styles
    border-bottom: 2px solid;
  }

  @media only screen and (max-width: ${mobile}) {
    width: 100%;
    max-width: 100%;
  }

  &::placeholder {
    color: ${colours.primaryColour};
  }
`;
