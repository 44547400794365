import React from 'react';
import styled from 'styled-components';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

type FieldErrorMessageProps = {
  show: boolean | undefined;
  message?: string;
  noMarginTop?: boolean;
};

const FieldErrorMessage = ({ show, message, noMarginTop = false }: FieldErrorMessageProps) => {
  return (
    <ConditionalRender predicate={show && message}>
      <FieldErrorMessageWrapper className="field_error_message_wrapper" noMarginTop={noMarginTop}>
        <span className="icon_warning" />
        <span>{message}</span>
      </FieldErrorMessageWrapper>
    </ConditionalRender>
  );
};

export default FieldErrorMessage;

export const FieldErrorMessageWrapper = styled.div<{ noMarginTop: boolean }>`
  margin-top: ${({ noMarginTop }) => (noMarginTop ? '0' : '0.5rem')};
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colours.errorColour};
  font-size: ${({ theme }) => theme.typography.mobile};

  [class^='icon_'] {
    font-size: ${({ theme }) => theme.typography.mobile};
  }
`;
