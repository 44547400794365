import styled from 'styled-components';

export const ContentWrapper = styled.div`
  margin: 4rem 0 3rem;

  ${({ theme: { media } }) => media.mobile` 
    margin: 3rem 0 1rem;
  `}
`;

export const TestimonialsWrapper = styled.div`
  column-count: 3;
  column-gap: 1.5rem;

  ${({ theme: { media } }) => media.tabletLandscape` 
    column-count: 2;
  `}

  ${({ theme: { media } }) => media.mobile` 
    column-count: 1;
  `}

  .testimonial_item,
  .metric_card {
    margin: 0 0 1.5rem;
    break-inside: avoid;
  }

  .testimonial_item {
    background-color: var(--background-block-primary);
    border: 1px solid var(--border-divider-soft);
    box-shadow: 0px 12px 48px 0px var(--shadow-soft);

    .testimonial_item_content_wrapper {
      min-height: unset;
    }
  }
`;

export const MetricsWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;
