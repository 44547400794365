/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import {
  resetAllPositions,
  resetPositionsCache,
  selectToggler,
  setToggler,
  useAppDispatch,
  useAppSelector,
  usePositionViewFilterParams
} from 'store';
import { identity } from 'utils/fn';
import {
  getCandidatesInStreamCount,
  getCompanyPositionViewApplications,
  getCompanyPositionViewListings,
  getCompanyPositionViewStreams,
  PositionViewStream,
  PositionViewStreams
} from 'v2/services/fetchers/company/positionview';

import Button from 'components/button';

import DevToolsManager from './DevToolsManager';

type DevToolsProps = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DevTools = (props: DevToolsProps) => {
  const isOpen = useAppSelector(selectToggler(['dev', 'toolbar']));
  const state = useAppSelector(identity);
  const dispatch = useAppDispatch();
  const devToolRef = useRef<DevToolsManager | null>(null);

  const toggleToolbar = (value?: boolean) => {
    const newValue = value ?? !isOpen;
    dispatch(setToggler({ route: ['dev', 'toolbar'], value: newValue }));
  };

  useEffect(() => {
    if (devToolRef.current) return;

    const devToolsManager = new DevToolsManager(state, dispatch);
    devToolRef.current = devToolsManager;
  }, []);

  useEffect(() => {
    devToolRef.current?.update(state, dispatch);
  }, [state, dispatch]);

  /** Above this line is the basic functionality of the component */

  const { getFilterParams, setFilterParams } = usePositionViewFilterParams();
  const { status } = getFilterParams();

  return (
    <DevToolsWrapper className="dev_toolbar" isOpen={isOpen}>
      <CloseIcon className="icon_simple_arrow_down" onClick={() => toggleToolbar(false)} />
      <ContentWrapper>
        <ButtonGroup>
          <DevButton
            action={() => {
              setFilterParams({ status: !status });
              console.log('setting status to', !status);
            }}
            text={`${status ? 'Open' : 'Closed'} positions`}
          />
        </ButtonGroup>
      </ContentWrapper>
    </DevToolsWrapper>
  );
};

interface ListItem {
  label: string;
  value: string;
}

interface DropdownProps {
  list: ListItem[];
  handleChange: (value: string) => void;
  selected: string;
}

const Dropdown: React.FC<DropdownProps> = ({ list, handleChange, selected }) => {
  return (
    <select value={selected} onChange={e => handleChange(e.target.value)}>
      {list.map(item => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
};

const DevToolsWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  z-index: 10000;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme: { colours } }) => colours.fontColour};
  padding: 0;
  height: 0;
  transition: height 0.3s ease-in-out;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      height: 100px;
    `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 32px 18px;
  margin: auto;
  gap: 0.5rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const CloseIcon = styled.div`
  width: 30px;
  padding-top: 10px;
  cursor: pointer;

  &.icon_simple_arrow_down {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const DevButton = styled(Button)`
  /* flex-grow: 0; */
  &&& {
    height: 30px;
    font-size: 12px;
    max-width: 300px;
    padding: 0;
  }
`;

export default DevTools;
