import { ATS_STAGES } from 'consts/candidate/ats';

import { CandidateApplicationTrackerStage as CandidateATStage } from '@cohiretech/common-types';

import { ListItem } from 'components/dropdown';
import LabelWithIcon from 'components/labelwithicon';

const { Liked, ApplicationStarted, Applied, Rejected, Interviewing, Hired, Offered } =
  CandidateATStage;

export type StageInfo = {
  stage: CandidateATStage;
  isHidden: boolean;
};

export const APPLICATION_STAGES: {
  [key in CandidateATStage]: { label: string; icon: string; action?: string; emptyState?: string };
} = {
  [Liked]: {
    label: 'Liked',
    icon: 'icon_thumbs_up',
    emptyState:
      "You haven't liked any positions yet. All positions you like on cord will appear here."
  },
  [ApplicationStarted]: {
    label: 'Application started',
    icon: 'icon_draft',
    emptyState:
      "You haven't clicked to apply to any positions yet. Any position you click to apply but not complete the application yet, will appear here."
  },
  [Applied]: {
    label: 'Applied',
    icon: 'icon_apply',
    emptyState:
      "You haven't applied to any positions yet. Any position you message or mark as applied on cord will appear here."
  },
  [Rejected]: {
    label: 'Rejected',
    icon: 'icon_cancel'
  },
  [Interviewing]: {
    label: 'Interviewing',
    icon: 'icon_calendar',
    action: 'Added',
    emptyState:
      "No interviews started yet. Any position you book an interview with through cord's messaging tool will appear here."
  },
  [Hired]: {
    label: 'Hired',
    icon: 'icon_hired'
  },
  [Offered]: {
    label: 'Offered',
    icon: 'icon_trophy'
  }
};

export const getStageDropdownList = (stages = ATS_STAGES): ListItem[] =>
  stages.map(stage => {
    const { label, icon } = APPLICATION_STAGES[stage];

    return {
      label: <LabelWithIcon icon={icon} text={label} />,
      value: stage
    };
  });
