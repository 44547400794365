import { removeLocalStorageItem } from 'cookieManager';

const CANDIDATE_LS_ITEMS = {
  notifications: ['queuedActions', 'signup_complete_popup'],
  userAuthentication: ['role', 'user', 'userEmail', 'isGodmode'],
  userExperience: [
    'requestedURL',
    'chats',
    'likedPositions',
    'skippedPositions',
    'show_like_and_skip',
    'positionURLs',
    'homepage_step'
  ],
  data: ['uniqueIdentifier', 'jobSearchPosition'],
  stopFunctionality: [
    // don't remove on logout
    'showAddMessagePopUp',
    'incomingRequestsPopUp',
    'conversationsToConfirmPopUp'
  ]
};

const COMPANY_LS_ITEMS = {
  notifications: ['messageBubbleNotificationSeen'],
  userAuthentication: [
    'role',
    'user',
    'userEmail',
    'companyInviteCode',
    'isGodmode',
    'requestDemoForm'
  ],
  userExperience: [
    'candidateIDs',
    'requestedURL',
    'messageToCandidate',
    'companyVisitedURLs',
    'pageLoadInitialised',
    'integrationRedirectURL',
    'companyChats',
    'demoCompanyTour',
    'nextDemoBubbleLocation',
    'demoBarClosed',
    //NEW ONBOARDING
    'show_onboarding_bubble',
    'onboarding_popup_viewed',
    'onboarding_stream_complete',
    'onboarding_template_complete',
    'onboarding_ats_complete',
    'onboarding_message_complete',
    'onboarding_guide_complete',
    // NEW INBOX TOUR
    'newInboxTour',
    'nextNewInboxBubbleLocation'
  ],
  data: [
    'candidateIDs',
    'uniqueIdentifier',
    'candidateSearchPos',
    'jobSearchPosition',
    'candidateSearchPos',
    'itemIndex'
  ],
  stopFunctionality: [
    'dialog_never_again',
    'changed_to_scaling',
    'newCohireStreams',
    'selectedPositionIDsMessages',
    'newProductUpdatesRead',
    'streamCriteriaBubbleShown',
    'otherCriteriaBubbleShown',
    'demoOnboardShown',
    'demoWelcomePopupViewed'
  ]
};

export const clearLSItemsOnLogout = (isGodmodeLogin?: boolean) => {
  const categoriesToClear: (keyof typeof CANDIDATE_LS_ITEMS)[] = [
    'notifications',
    'userExperience',
    'data'
  ];

  if (!isGodmodeLogin) categoriesToClear.push('userAuthentication');

  [CANDIDATE_LS_ITEMS, COMPANY_LS_ITEMS].forEach(items => {
    categoriesToClear.forEach(category => {
      items[category].forEach(item => removeLocalStorageItem(item));
    });
  });
};
