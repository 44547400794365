import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { ApplicationMatchScoreInterface, MatchScoreRangeType } from '@cohiretech/common-types';

import { useDarkMode } from 'hooks/useDarkMode';
import { isEmpty } from 'utils';
import { repeat } from 'utils/array';
import { pluck } from 'utils/object';
import { colours } from 'styles/theme/common';

import { InsightsBlock } from '../InsightsBlock';
import { usePositionProfile } from '../PositionProfile.context';

const SUB_TITLE = 'Scores of other applicants';
const DISCLAIMER = 'Count of applicants with a profile match score within each score banding.';
const DEFAULT_TITLE = 'See how you compare with other applicants';
const EMPTY_TITLE = 'No requests sent yet. Be the first one!';
const EMPTY_DATA = [
  { range: MatchScoreRangeType.ZeroToTwenty, total: 0 },
  { range: MatchScoreRangeType.TwentyOneToForty, total: 0 },
  { range: MatchScoreRangeType.FortyOneToSixty, total: 0 },
  { range: MatchScoreRangeType.SixtyOneToEighty, total: 0 },
  { range: MatchScoreRangeType.EightyOneToOneHundred, total: 0 }
];

export const ApplicantsMatchScoresBlock = () => {
  const { insightsData } = usePositionProfile();

  let matchData = insightsData.position?.matchScoresWithRanges?.sort(byRange) || [];
  const isEmptyState = isEmpty(matchData);

  const { topRank, positionMatchScoreAboveYourScorePercentage, matchScore } =
    insightsData.candidate || {};
  const title = getTitle(
    topRank,
    positionMatchScoreAboveYourScorePercentage,
    matchScore,
    isEmptyState
  );

  if (isEmptyState) {
    matchData = EMPTY_DATA;
  }

  return (
    <InsightsBlock
      size="small"
      subTitle={SUB_TITLE}
      title={title}
      defaultTitle={DEFAULT_TITLE}
      disclaimer={DISCLAIMER}
    >
      <ColumnChart
        data={matchData.map(pluck('total')) as number[]}
        categories={matchData.map(pluck('range')) as string[]}
      />
    </InsightsBlock>
  );
};

const ColumnChart = ({ data, categories }: { data?: number[]; categories?: string[] }) => {
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const { isDummyData, insightsData } = usePositionProfile();
  const darkMode = useDarkMode();

  const matchScore = insightsData.candidate?.matchScore?.matchScore || 0;

  useEffect(() => {
    setOptions(constructOptions(categories, data, matchScore, isDummyData, darkMode));
    if (!data) return;
    setSeries([{ name: 'Applicants Match Score', data }]);
  }, [data, categories]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={158} />
    </div>
  );
};

const constructOptions = (
  categories: string[] = [],
  data: number[] = [],
  matchScore: number,
  isDummyData: boolean,
  darkMode: boolean
): ApexOptions => {
  const labelFormatter = isDummyData ? () => '0' : (val: number) => `${val || ''}`;
  const isEmptyState = data.reduce((acc, curr) => acc + curr, 0) === 0;

  // Index of bar that represents the candidate's match score
  const candidateBarIndex = Math.floor(Math.max(matchScore - 0.01, 0) * 5);

  // Change color of bar that represents the candidate's match score
  const colorStops = repeat(barColorLight, categories.length);
  colorStops[candidateBarIndex] = barColorDark;
  const labelColors = repeat(colours.primaryColour as string, categories.length);
  labelColors[candidateBarIndex] = 'white';

  return {
    chart: {
      height: 158,
      parentHeightOffset: 0,
      type: 'bar',
      toolbar: {
        show: false
      },
      offsetY: 8
    },
    grid: {
      borderColor: darkMode ? colours.fontColour : colours.lightFontColour,
      show: false,
      padding: { left: 0, right: 0, top: -10, bottom: 0 }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: 'end',
        barHeight: '100%',
        distributed: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: labelFormatter,
      offsetY: 6,
      style: {
        fontSize: '12px',
        colors: labelColors,
        fontFamily: '"Figtree", Helvetica, Arial, sans-serif'
      }
    },
    legend: {
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        colorStops
      }
    },
    tooltip: {
      enabled: false
    },
    theme: { mode: darkMode ? 'dark' : 'light' },
    xaxis: {
      categories,
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        formatter: (val: string) => `${val?.replace(/\s+/g, '')}`,
        rotate: 0,
        offsetY: isEmptyState ? -30 : -5,
        style: {
          fontSize: '10px',
          colors: repeat('#869DB1', categories.length)
        }
      },
      crosshairs: {
        show: false
      }
    },
    yaxis: {
      max: getMax(data),
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        formatter: (val: number) => `${val}`,
        maxWidth: 0
      }
    }
  };
};

const getTitle = (
  topRank?: number,
  scoreAboveYou?: number,
  matchScore?: Partial<ApplicationMatchScoreInterface>,
  isEmptyState?: boolean
) => {
  if (isEmptyState) return EMPTY_TITLE;

  if (topRank && topRank > 0.5) {
    return `You're in the top ${Math.round((1 - topRank) * 100)}% of applicants`;
  }
  if (!scoreAboveYou || !matchScore?.matchScore) {
    return DEFAULT_TITLE;
  }

  return `${Math.round(scoreAboveYou * 100)}% of applicants have a score higher than ${Math.round(
    matchScore.matchScore * 100
  )}`;
};

const barColorLight = [
  {
    offset: 30,
    color: colours.primaryColour,
    opacity: 0.12
  },
  {
    offset: 111,
    color: '#7B7EE6',
    opacity: 0.12
  }
];

const barColorDark = [
  {
    offset: 30,
    color: colours.primaryColour,
    opacity: 1
  },
  {
    offset: 111,
    color: '#7B7EE6',
    opacity: 1
  }
];

const getMax = (data: number[]) => {
  const max = Math.max(...data);
  return max > 3 ? max : 3;
};

const byRange = (a: { range: string }, b: { range: string }) => {
  const aRangeStart = Number(a.range.split(' - ')[0]);
  const bRangeStart = Number(b.range.split(' - ')[0]);
  return aRangeStart - bRangeStart;
};
