import ClassNames from 'classnames';
import React from 'react';

import './style.scss';

type Props = {
  icon: string;
  title: string;
  text: string;
};

const BadgeWithIcon = ({ icon, title, text }: Props) => (
  <div className="badge_with_icon">
    <div className={ClassNames('icon', icon, 'badge_icon')} />
    <div className="badge_content">
      <h3 className="badge_title">{title}</h3>
      <p className="badge_text">{text}</p>
    </div>
  </div>
);

export default BadgeWithIcon;
