import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';
import { isEmpty } from 'utils';

const selectNavigatablePositions = (state: RootState) => state.navigatablePositions;

export const selectNavigatablePositionsIDs = createSelector(
  selectNavigatablePositions,
  state => state.navigatablePositionsIDs
);

export const selectCachedNavigatablePositions = createSelector(
  selectNavigatablePositions,
  state => state.cachedNavigatablePositions
);

export const selectShouldShowNavigatablePositions = createSelector(
  selectNavigatablePositions,
  state => !isEmpty(state.navigatablePositionsIDs) && state.navigatablePositionsIDs!.length > 1
);

export const selectCachedNavigatablePositionsMetadata = createSelector(
  selectNavigatablePositions,
  state => state.cachedNavigatablePositionsMetadata
);

export const selectMarkApplicationPopup = createSelector(
  selectNavigatablePositions,
  state => state.markApplicationPopup
);
