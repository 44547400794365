import React, { CSSProperties, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { generatePersonsURL, generatePositionURL } from 'utils';

import Badge, { BadgeProps } from 'components/badge';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import MetricsList, { Metrics } from './Metrics';

export type Subitem = {
  id: number;
  name: string;
  badge?: BadgeProps;
  label: string;
  metrics: Metrics;
  isAdditional?: false;
};

export type AdditionalItem = {
  text: ReactNode;
  isAdditional: true;
};

export type Props = {
  type: 'position' | 'person';
  item: Subitem | AdditionalItem;
  companyName: string;
};

export default function CompanySubcard({ type, item, companyName }: Props) {
  if (item.isAdditional) {
    return <Subcard justifyContent="center">{item.text}</Subcard>;
  }

  const location = useLocation();
  const { id, name, badge, label, metrics } = item;
  const url =
    type === 'position'
      ? generatePositionURL(location, { companyName, positionID: id, positionName: name })
      : generatePersonsURL(location, { companyName, personsID: id, personsName: name });

  return (
    <Subcard>
      <Details>
        <PreviewLinkCont>
          <Link to={url}>{name}</Link>
          <ConditionalRender predicate={badge}>
            <Badge {...badge!} />
          </ConditionalRender>
        </PreviewLinkCont>
        <div className="label">{label}</div>
      </Details>
      <MetricsList id={id} metrics={metrics} />
    </Subcard>
  );
}

export const Subcard = styled.div<{ justifyContent?: CSSProperties['justifyContent'] }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  border-top: 1px solid
    ${({ theme: { darkMode, colours } }) =>
      darkMode ? colours.fontColourDarkShade : colours.lightBgColour};
  margin-top: 1rem;
  padding-top: 1rem;

  ${({ theme: { media } }) => media.mobile`
    flex-direction: column;
  `}
`;

const Details = styled.div`
  text-align: left;

  ${({ theme: { media } }) => media.mobile`
    text-align: center;
  `}
`;

const PreviewLinkCont = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5px;

  a {
    font-size: ${({ theme: { typography } }) => typography.smallheader};
    font-weight: ${({ theme: { typography } }) => typography.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .badge {
    margin: 2px 0 0;
    vertical-align: top;
  }

  ${({ theme: { media } }) => media.mobile`
    justify-content: center;
  `}
`;
