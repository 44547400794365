import React from 'react';

import { CompanySizeType } from '@cohiretech/common-types';

export type StoryStage = CompanySizeType | 'notable' | 'all';

type TabItem = {
  name: string;
  stage: StoryStage;
  component?: React.ReactNode;
};

const AllStories = () => (
  <>
    <span>All 160+ stories</span>&nbsp;
    <span className="icon icon_simple_arrow_right" />
  </>
);

export const STORY_TABS: TabItem[] = [
  { name: 'Notable companies', stage: 'notable' },
  { name: 'Startups', stage: CompanySizeType.EarlyHire },
  { name: 'Scaleups', stage: CompanySizeType.StartUp },
  { name: 'Enterprise', stage: CompanySizeType.Enterprise },
  { name: 'All stories', stage: 'all', component: <AllStories /> }
];

export const getParams = (stage: Exclude<StoryStage, 'all'>) => {
  if (stage === 'notable') return { notable: true };
  return { companySizeTypes: [stage] };
};
