import styled, { css, keyframes } from 'styled-components';

export const EditorError = styled.button`
  border-bottom: 2px solid;
  text-shadow: 0 0 0 transparent;
  cursor: pointer;
  color: inherit;

  &:hover {
    font-weight: ${props => props.theme.typography.bold};
  }
`;

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const fadeOut = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`;

export const ellipsisAnim = keyframes`
  0% { content: "." }
  25% { content: ".." }
  50% { content: "..." }
  100% { content: "" }
`;

export const PromptWrapper = styled.div`
  position: relative;
  display: inline-block;
  white-space: nowrap;
`;

export const PromptText = styled.span<{
  fadingOut: boolean;
  fadeDuration: number;
  ellipsisDuration: number;
}>`
  position: absolute;
  font-size: 14px;
  font-weight: ${props => props.theme.typography.regular};
  color: ${props => props.theme.colours.primaryColour};
  margin-left: 0.25em;

  animation: ${({ fadingOut, fadeDuration }) =>
    fadingOut
      ? css`
          ${fadeOut} ${fadeDuration}s forwards
        `
      : css`
          ${fadeIn} ${fadeDuration}s forwards
        `};

  &::after {
    ${({ ellipsisDuration }) => css`
      content: '';
      animation: ${ellipsisAnim} ${ellipsisDuration}s steps(${ellipsisDuration}, end) infinite;
    `}
  }
`;
