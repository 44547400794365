import styled from 'styled-components';
import { useEffect } from 'react';

import { useCustomerStoriesContext } from 'views/landing/customerstories/CustomerStories.context';

import ExpandableFilter from 'components/expandablefilter';

import { PageView } from '../../styles';

import { initState } from './CustomerStoryFilters.helpers';

export default function CustomerStoryFilters() {
  const { filters, addFilter, removeFilter, setFilters } = useCustomerStoriesContext();

  useEffect(() => {
    setFilters(() => initState(filters));
  }, []);

  return (
    <StoryFiltersWrapper>
      {Object.values(filters).map(props => (
        <ExpandableFilter {...props} addSearchItem={addFilter} removeSearchItem={removeFilter} />
      ))}
    </StoryFiltersWrapper>
  );
}

const StoryFiltersWrapper = styled(PageView)`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-04);
  width: 100%;

  ${({ theme: { media } }) => media.tabletLandscape`
    grid-template-columns: repeat(2, minmax(225px, 1fr));
  `}

  ${({ theme: { media } }) => media.smallMobile`
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  `}

  .expandable_filter {
    display: inline-flex;
    align-items: center;
    flex: 1 0 0;

    padding: 0.75rem 1rem;

    border-radius: 0.5rem;
    background: var(--background-block-primary);
    box-shadow: var(--drop-shadow-soft);

    .header_cont {
      display: flex;
      width: 100%;

      button {
        width: 100%;
      }
    }

    .filter_content.open {
      position: absolute;
      top: 2.75rem;
      left: 0;
      background: var(--background-block-primary);
      z-index: 2;
      width: calc(100% - var(--spacing-05));
    }
  }
`;
