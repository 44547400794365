import React, { useEffect } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import Seo from 'components/seo';

import DemoButtonSection from 'v2/components/DemoButtonSection';
import IntroWithTagline from 'v2/components/IntroWithTagline';

import Leaderboard from './components/Leaderboard';
import {
  AwardsPageParams,
  PARAM_MAP,
  getAwardSeasonText,
  getIntro,
  getSEOContent
} from './Awards.helpers';

export default function AwardsPage() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<AwardsPageParams>();
  const { season, award, size } = params;
  const awardSeason = getAwardSeasonText(season);
  const awardType = PARAM_MAP.award[award];

  useEffect(() => {
    /* 
      Redirect from "/awards/winter-2024" (initially shared) to "/awards/winter-2023"
      This should be removed before announcing winter 2024 awards
      ref: https://cordians.slack.com/archives/C053MGF5AF7/p1708951421668889
    */

    if (season === 'winter-2024') {
      history.push(
        generatePath('/awards/:season/:award/:size', { ...params, season: 'winter-2023' })
      );
      return;
    }
  }, [season]);

  if (season === 'winter-2024') return null;
  return (
    <>
      <AwardsView className="center_alignment">
        <IntroWithTagline
          tagline={{
            icon: { name: 'icon_trophy' },
            text: [<div key="season">{awardSeason}</div>, 'HIRING LEADERS AWARDS']
          }}
          {...getIntro(awardType, season)}
        />
        <Leaderboard />
      </AwardsView>
      <DemoButtonSection type="company_logos" color="dark" />
      <Seo
        {...getSEOContent(award, awardSeason, season, size)}
        path={location.pathname}
        contentType="website"
      />
    </>
  );
}

const AwardsView = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 1.25rem;

  .tagline {
    font-size: ${({ theme: { typography } }) => typography.normal};

    div {
      display: inline-block;
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      border-right: 2px ${({ theme: { colours } }) => colours.primaryColour} solid;
      font-weight: ${({ theme: { typography } }) => typography.black};
    }
  }

  .label_with_icon {
    margin-top: 0.25rem;
  }
`;
