import React, { useState } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { CandidateShortlist } from '@cohiretech/common-types';

import { useCandidateProfile } from 'store';
import * as fetcher from 'fetcher';
import useEventListener from 'hooks/useEventListener';
import media from 'styles/media';
import { isUserLoggedIn } from 'utils/profile';

import { NavigatableXPosition } from 'v2/pages/candidate/NavigatablePositions/NavigatablePositions.helpers';

import Tooltip from 'components/tooltip';
import { Props as ButtonProps } from 'components/button';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import LikePositionLoader from 'images/animations/LikePositionLoader';

const ANIMATION_DURATION = 750;

type SaveHideActionsProps = {
  showHide?: boolean;
  showSave?: boolean;
  listingID: number;
  shortlistID?: number;
  type?: CandidateShortlist;
  onShortlisted: (s: number | null, t: string | null) => void;
  primaryButton?: ButtonProps;
  goToSignup?: () => void;
  white?: boolean;
  isOnButtonPanel?: boolean;
  xPosition?: NavigatableXPosition;
};

const PositionActions = ({
  type,
  shortlistID,
  onShortlisted,
  primaryButton,
  isOnButtonPanel,
  xPosition,
  showHide = true,
  showSave = true,
  listingID,
  goToSignup,
  white
}: SaveHideActionsProps) => {
  const profile = useCandidateProfile();
  const loggedinUser = isUserLoggedIn(profile);
  const [saved, setSaved] = useState(Boolean(type === fetcher.SHORTLIST_TYPES.LIKE && shortlistID));
  const [hidden, setHidden] = useState(
    Boolean(type === fetcher.SHORTLIST_TYPES.HIDE && shortlistID)
  );

  const [saving, setSaving] = useState(false);
  const [hiding, setHiding] = useState(false);
  const [applying, setApplying] = useState(false);

  const onSave = async (save: $TSFixMe) => {
    if (loggedinUser) setSaved(save);
    else goToSignup?.();

    if (save && loggedinUser) {
      if (isOnButtonPanel && hidden) {
        await fetcher.candidateRemoveShortlist(shortlistID);
        setHidden(false);
      }
      setSaving(true);
      setTimeout(() => setSaving(false), ANIMATION_DURATION);
      const { status, data } = await fetcher.candidateShortlistPosition(
        listingID,
        fetcher.SHORTLIST_TYPES.LIKE
      );

      if (status === 'success' && onShortlisted) {
        onShortlisted(data.shortlistID, fetcher.SHORTLIST_TYPES.LIKE);
      } else if (status !== 'success') setSaved(false);
    } else if (loggedinUser) {
      const { status } = await fetcher.candidateRemoveShortlist(shortlistID);

      if (status === 'success' && onShortlisted) onShortlisted(null, null);
      else if (status !== 'success') setSaved(true);
    }
  };

  const onHide = async (hide: $TSFixMe) => {
    if (loggedinUser) setHidden(hide);
    else goToSignup?.();

    if (hide && loggedinUser) {
      if (isOnButtonPanel && saved) {
        await fetcher.candidateRemoveShortlist(shortlistID);
        setSaved(false);
      }
      setHiding(true);
      setTimeout(() => setHiding(false), ANIMATION_DURATION);
      const { status, data } = await fetcher.candidateShortlistPosition(
        listingID,
        fetcher.SHORTLIST_TYPES.HIDE
      );

      if (status === 'success' && onShortlisted) {
        onShortlisted(data.shortlistID, fetcher.SHORTLIST_TYPES.HIDE);
      } else if (status !== 'success') setHidden(false);
    } else if (loggedinUser) {
      const { status } = await fetcher.candidateRemoveShortlist(shortlistID);

      if (status === 'success' && onShortlisted) onShortlisted(null, null);
      else if (status !== 'success') setHidden(true);
    }
  };

  const handleApply = () => {
    setApplying(true);
    setTimeout(() => setApplying(false), ANIMATION_DURATION);
    primaryButton?.action?.();
  };

  const handleKeydown = (event: Event | KeyboardEvent) => {
    const dontHandle =
      (event.target as HTMLElement).getAttribute('id') === 'message_template' ||
      !isOnButtonPanel ||
      xPosition !== 'middle';

    if (dontHandle) return;

    switch ((event as KeyboardEvent).key) {
      case 'l':
      case 'L':
        onSave(!saved);
        break;
      case 'n':
      case 'N':
        onHide(!hidden);
        break;
      case 'a':
      case 'A':
        primaryButton?.action?.();
        break;
      default:
        break;
    }
  };

  useEventListener('keydown', handleKeydown);

  if (isOnButtonPanel) {
    return (
      <ButtonPanelActionsWrapper>
        <NotRightButton
          className={ClassNames({ rotate: hiding })}
          onClick={() => onHide(!hidden)}
          aria-label="not right position"
        >
          <span className={hidden ? 'icon_thumbs_down_filled' : 'icon_thumbs_down'} />
          <Tooltip
            text="Not right"
            position="top"
            tooltipStyle="grey"
            size="large"
            keyboardShortcut="N"
          />
        </NotRightButton>
        {primaryButton && (
          <ApplyButton
            className={ClassNames({ animate: applying })}
            onClick={() => handleApply()}
            aria-label="apply to position"
            disabled={!!primaryButton.tooltip}
          >
            <span className={primaryButton.icon || 'icon_apply'} />
            {primaryButton.tooltip || (
              <Tooltip
                text={primaryButton.text}
                position="top"
                tooltipStyle="cord_gradient"
                size="large"
                keyboardShortcut="A"
              />
            )}
          </ApplyButton>
        )}
        <LikeButton
          className={ClassNames('blue_gradient_icon', { rotate: saving })}
          onClick={() => onSave(!saved)}
          aria-label="like position"
        >
          <span
            className={ClassNames('blue_gradient_icon', {
              icon_thumbs_up_filled: saved,
              icon_thumbs_up: !saved
            })}
          />
          <Tooltip
            text={saved ? 'Liked' : 'Like'}
            position="top"
            tooltipStyle="light_primary"
            size="large"
            keyboardShortcut="L"
          />
        </LikeButton>
        <ConditionalRender predicate={saving}>
          <StyledLikePositionLoader key="like_position_loader" width="100px" height="100px" />
        </ConditionalRender>
      </ButtonPanelActionsWrapper>
    );
  }

  return (
    <ActionsWrapper className="save_hide_wrapper" white={white}>
      {!saved && showHide && (
        <span
          className={ClassNames({
            hidden,
            icon_thumbs_down_filled: hidden,
            icon_thumbs_down: !hidden
          })}
          onClick={() => onHide(!hidden)}
        >
          <Tooltip text="Not right" width="80px" position="top" tooltipStyle="grey" />
        </span>
      )}
      {!hidden && showSave && (
        <span
          className={ClassNames({ saved, icon_thumbs_up_filled: saved, icon_thumbs_up: !saved })}
          onClick={() => onSave(!saved)}
        >
          <Tooltip
            text={saved ? 'Liked' : 'Like'}
            width="80px"
            position="top"
            tooltipStyle="light_primary"
          />
        </span>
      )}
    </ActionsWrapper>
  );
};

export default PositionActions;

const ActionsWrapper = styled.div<{ white: boolean | undefined }>`
  > span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 22px;
    padding: 0.25rem;
    opacity: 0.6;
    font-weight: bold;
    cursor: pointer;

    &:hover .tooltip_wrapper {
      display: block;
    }

    ${props =>
      props.white &&
      css`
        color: white;
      `}

    &:last-child:nth-child(2) {
      margin-left: 4px;
      margin-top: -8px;
    }

    &.icon_thumbs_down:hover {
      color: var(--text-accent-tertiary-base);
      opacity: 1;
    }

    &.icon_thumbs_up:hover {
      color: var(--text-body-brand);
      opacity: 1;
    }

    &.hidden,
    &.saved {
      color: var(--text-accent-tertiary-base);
      opacity: 1;

      &:hover {
        &:before {
          opacity: 0.8;
        }

        ${props =>
          props.white &&
          css`
            color: white;
          `}
      }
    }

    &.saved {
      color: var(--text-body-brand);
    }
  }
`;

const ButtonPanelActionsWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
  vertical-align: middle;

  ${media.mobile`
    width: 100%;
    justify-content: center;
  `}
`;

const CommonButtonStyling = css`
  position: relative;
  border-radius: 1.25rem;
  text-align: center;
  width: 3rem;
  height: 3rem;

  [class^='icon_'],
  [class*=' icon_'] {
    display: block;
    transition: all 0.2s ease-in-out;
    font-size: 20px;
    font-weight: bold;
  }

  &:hover {
    .tooltip_wrapper {
      display: block;
    }

    [class^='icon_'],
    [class*=' icon_'] {
      font-size: 24px;
    }
  }

  ${media.mobile`
    border-radius: 1.5rem;
    width: 3.25rem;
    height: 3.25rem;
    font-size: 22px !important;

    [class^=icon_], [class*=" icon_"] {
      font-size: 22px;
    }

    &:hover [class^=icon_], &:hover [class*=" icon_"] {
      font-size: 22px;
    }
  `};
`;

const RotationStyling = css`
  [class^='icon_'],
  [class*=' icon_'] {
    transform: rotate(-15deg);
    transform-origin: center;
  }

  .tooltip_wrapper {
    display: none;
  }
`;

const NotRightButton = styled.button`
  background: var(--background-accent-tertiary-soft);
  color: var(--text-accent-tertiary-base);
  ${CommonButtonStyling}

  &.rotate {
    ${RotationStyling}
  }
`;

const ApplyButton = styled.button`
  &&& {
    ${CommonButtonStyling}
    color: var(--text-body-invert);
    background: var(--gradient-primary-base);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 1.5rem;

    [class^='icon_'],
    [class*=' icon_'] {
      font-size: 24px;
    }

    ${media.mobile`
      width: 4rem;
      height: 4rem;
      border-radius: 1.75rem;

      [class^='icon_'],
      [class*=' icon_'] {
        font-size: 26px;
      }
    `};

    &.disabled {
      background: var(--background-accent-primary-disabled);
    }

    &:hover {
      [class^='icon_'],
      [class*=' icon_'] {
        font-size: 28px;
      }

      .tooltip_wrapper {
        display: block;
      }
    }
  }
`;

const LikeButton = styled.button`
  background: var(--gradient-primary-transparent);
  ${CommonButtonStyling}

  &.rotate {
    ${RotationStyling}

    [class^='icon_'],[class*=' icon_'] {
      font-size: 28px !important;

      ${media.mobile`
        font-size: 22px !important;
      `}
    }
  }
`;

const StyledLikePositionLoader = styled(LikePositionLoader)`
  position: absolute;
  right: -1.5rem;
  bottom: 100%;
`;
