import React from 'react';
import styled, { css } from 'styled-components';

import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { typography } from 'styles/theme/common';
import { isEmpty } from 'utils';

import IconButton from 'components/iconbutton';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import Breadcrumbs from 'v2/components/ui/molecules/Breadcrumbs';
import { BreadcrumbsProps } from 'v2/components/ui/molecules/Breadcrumbs/Breadcrumbs';

import { useSearchBarContext } from '../../SimpleSearchBar.context';

export const BREADCRUMBS_PADDING = '0.5rem';
export const BREADCRUMBS_FONT_SIZE = typography.small;

export default function SavedSearchesHeader() {
  const isMobile = useMedia([mediaQueries.mobile]);

  const { currentTab, savedSearches, setCurrentTab } = useSearchBarContext();

  const breadcrumbs: BreadcrumbsProps['breadcrumbs'] = [
    { label: 'New search', onClick: () => setCurrentTab('new') },
    { label: 'Your saved searches', current: true }
  ];

  if (currentTab === 'new') {
    return (
      <SavedSearchesTitle className="section_title small_text">
        Your saved searches
        <ConditionalRender predicate={!isEmpty(savedSearches)}>
          <IconButton
            icon="icon_expand"
            tooltip={<Tooltip text="Expand to see all" />}
            ariaLabel="Expand to see all saved searches"
            onClick={() => setCurrentTab('saved')}
            fontSize="0.75rem"
          />
        </ConditionalRender>
      </SavedSearchesTitle>
    );
  }

  return isMobile ? null : <BreadcrumbsCont breadcrumbs={breadcrumbs} />;
}

const BreadcrumbsCont = styled(Breadcrumbs)`
  ${({ theme: { typography } }) => css`
    padding: ${BREADCRUMBS_PADDING};
    color: var(--text-accent-secondary-base);
    font-size: ${BREADCRUMBS_FONT_SIZE};

    ol {
      margin: 0;

      .breadcrumbs_link {
        font-weight: ${typography.black};
        border-bottom: 1px solid transparent;

        &:hover {
          color: inherit;
          border-bottom-color: var(--text-accent-secondary-hover);
        }
      }

      .icon_simple_arrow_right {
        font-size: ${typography.tiny};
        margin: 0 0.5rem;
      }
    }
  `}
`;

const SavedSearchesTitle = styled.h3`
  margin: 0.25rem 0 0.25rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
