import React from 'react';
import ClassNames from 'classnames';

import { Props } from '../MultiStageToggle.helpers';

import { ToggleLink, ToggleText, TogglePills } from './MultiStageToggleItem.styled';

export default function MultiStageToggleItem({
  size = 'medium',
  color = 'dark_blue',
  width = 'auto',
  ...props
}: Props<false>) {
  const className = 'multi_stage_toggle_item';
  const stylingProps = { color, size, width, className };

  if (props.type === 'links_toggle') {
    const { name, url, exact } = props;

    return (
      <ToggleLink to={url} exact={exact} {...stylingProps}>
        {name}
      </ToggleLink>
    );
  }

  const { name, stage, component, currentStage, onStageSelect } = props;
  const otherProps = {
    className: ClassNames(className, {
      active: stage === currentStage,
      basic: props.type === 'basic_pills_toggle'
    }),
    onClick: () => onStageSelect(stage)
  };

  if (props.type === 'text_toggle') {
    return (
      <ToggleText {...stylingProps} {...otherProps}>
        {name}
      </ToggleText>
    );
  }

  return <TogglePills {...otherProps}>{component || name}</TogglePills>;
}
