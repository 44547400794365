import ClassNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { isAgenciesPage } from 'utils/url';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';
import { useDarkMode } from 'hooks/useDarkMode';

import BulletList, { Bullet } from 'components/bulletlist';
import BadgeWithIcon from 'components/badgewithicon';
import ResponsiveImage from 'components/responsiveimage';
import ReverseContainerOrder from 'components/reversecontainerorder';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { HeaderContent } from './pageheader';

import { PipelineCompanyStats } from '.';

type SuperchargePipelineProps = {
  src: { desktop: string; mobile: string };
  stats: PipelineCompanyStats;
};

const SuperchargePipeline = ({ src, stats }: SuperchargePipelineProps) => {
  const isMobileWidth = useMedia([mediaQueries.mobile]);
  const darkMode = useDarkMode();

  const isForAgenciesPage = isAgenciesPage();

  const { companiesSourcing, peopleSourced } = stats;
  return (
    <div className="pipeline_chapter">
      <SuperchargeHeader isTablet isForAgenciesPage={isForAgenciesPage} />
      <ReverseContainerOrder predicate={isMobileWidth}>
        <div className="left_cont image_resp resp_cont">
          <ResponsiveImage
            src={src.desktop}
            alt="Schedule interviews instantly"
            smallSrc={src.mobile}
            className="sourcing_img"
            width="100%"
          />
        </div>
        <div className="right_cont resp_cont">
          <SuperchargeHeader isForAgenciesPage={isForAgenciesPage} />
          <BulletList>
            <Bullet title="Interviews booked in seconds, not minutes">
              Cut the back and forth by using cord's Interview Scheduler. People can book directly
              into your diary, speeding up the hiring process.
            </Bullet>
            <Bullet title="People sent to your ATS instantly">
              Integrate your ATS, link your positions and get people sourced from cord sent straight
              into your ATS. No manual work needed.
            </Bullet>
          </BulletList>
          <div className={ClassNames('multiple_badges', { dark_badges: darkMode })}>
            <BadgeWithIcon
              icon="icon_hired"
              title={peopleSourced}
              text="people sourced this month"
            />
            <BadgeWithIcon
              icon="icon_company"
              title={companiesSourcing}
              text="companies sourcing this month"
            />
          </div>
        </div>
      </ReverseContainerOrder>
    </div>
  );
};

const SuperchargeHeader = ({
  isTablet,
  isForAgenciesPage
}: {
  isTablet?: true;
  isForAgenciesPage: boolean;
}) => {
  const [t] = useTranslation();
  return (
    <HeaderContent title="Supercharge your pipeline" isTablet={isTablet}>
      <ConditionalRender
        predicate={isForAgenciesPage}
        fallback={`People sourced from cord move straight into your pipeline. Download the ${t(
          'CV'
        )} or send the profile directly to your ATS.`}
      >
        People sourced from cord will move straight into your normal workflow.
      </ConditionalRender>
    </HeaderContent>
  );
};

export default SuperchargePipeline;
